import React, {useState} from 'react'
import {BaseInput} from '../../Input/BaseInput'
import {BasePopup} from '../../Popup/BasePopup'
import {ApiErrorStatusEnum, InputTypeEnum, RsisDocTypeEnum, stringIsEmpty, ToastTypeEnum} from 'edah_utils/dist'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {usePopup} from "../PopupProvider";

//import api
import {rsisEditFunctionDoc} from '../../../api/v1/RSIS'
import {t} from "i18next"

/**
 * 欄位標題
 * @param title {String}
 * @return {JSX.Element}
 */
const Title = ({title}) => <p className='flex justify-start items-center pr-4'>{title}</p>

/**
 * 欄位內容
 * @param content {String}
 * @param className {String}
 * @return {JSX.Element}
 */
const Content = ({content, className = ''}) =>
    <div className={`flex flex-row justify-start items-center pl-2 ${className}`}>{content}</div>

/**
 * 欄位組件
 * @param title {String}
 * @param content {String}
 * @param className {String}
 * @return {JSX.Element}
 */
const InputField = ({title, content, className}) => {
    return (
        <div className='flex flex-row h-[42px]'>
            <Title title={title}/>
            <Content content={content} className={className}/>
        </div>
    )
}

/**
 * 報備支援-功能操作說明維護彈出視窗
 * @param data {Object} 父層級帶入的顯示資料
 * @param handleOnClose {function} 彈出視窗關閉事件
 * @param handleOnUpdate {function} 彈出視窗更新事件
 * @return {JSX.Element}
 */
function FunctionOperateIntroMaintain({data, handleOnClose, handleOnUpdate}) {
    //Show Toast
    const {showToast} = usePopup()
    // 功能ID
    const [funNo, setFunNo] = useState(data.funNo)
    // 功能名稱
    const [functionName, setFunctionName] = useState(data.funcName)
    // 類別
    const [docType, setDocType] = useState(stringIsEmpty(data.docType) ? RsisDocTypeEnum.StandardDescription : data.docType)
    // 有效否
    const [valid, setValid] = useState(data.effFlag ? data.effFlag : 0)
    // CKEditor內容
    const [editorData, setEditorData] = useState(data.htmlContent)
    // Lock version
    const [lockVersion, setLockVersion] = useState(data.lockVersion)

    /**
     * 存檔按鈕點擊事件
     * @return {void}
     * */
    const handleOnSave = () => editFunctionDoc()

    /**
     * CKEditor改變時
     * @param event {Event}
     * @param editor {TEditor}
     * @return {void}
     */
    const handleCKEditorOnChange = (event, editor) => {
        const data = editor.getData()
        setEditorData(data)
    }

    /**
     * 彈窗關閉時
     * @return {void}
     */
    const handlePopupCloseButtonOnClick = () => handleOnClose(false)

    /**
     * 類別改變時
     * @param e {Event}
     * @return {void}
     */
    const handleDocTypeInputOnChange = (e) => setDocType(e.target.value)

    /**
     * 修改/儲存功能操作說明維護
     * @return {void}
     */
    const editFunctionDoc = () => {
        rsisEditFunctionDoc([{
            // Lock version
            lockVersion: lockVersion,
            // 功能代號
            funNo: funNo,
            // 說明類型
            docType: docType,
            // 內容
            htmlContent: editorData,
            // 有效否
            effFlag: Number(valid),
        }]).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //顯示提示訊息
                showToast({message: msg})
                // 更新主頁面資料
                handleOnUpdate()
                // 關閉彈窗
                handlePopupCloseButtonOnClick()
            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    return (
        <BasePopup
            title="功能操作說明維護"
            width="606px"
            closePopupButtonOnClick={handlePopupCloseButtonOnClick}
            content={
                <div className='px-4 py-4'>
                    <div className='flex flex-col gap-4'>
                        {/*第一列*/}
                        <div className='flex flex-row gap-10 justify-between'>
                            {/*功能ID*/}
                            <InputField
                                title='功能ID : '
                                content={<BaseInput disable={true} type={InputTypeEnum.Text} value={funNo}/>}/>
                            {/*有效否*/}
                            <InputField className='fit-content' title='有效否 : '
                                        content={<select
                                            className='w-[136px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-4 hover:cursor-pointer'
                                            value={valid} onChange={(e) => setValid(e.target.value)}>
                                            {/*是*/}
                                            <option value={1}>{t('general.yes')}</option>
                                            {/*否*/}
                                            <option value={0}>{t('general.no')}</option>
                                        </select>}/>
                        </div>
                        {/*第二列*/}
                        <div className='flex flex-row gap-10 justify-between'>
                            {/*功能名稱*/}
                            <InputField
                                title='功能名稱 : '
                                content={<BaseInput type={InputTypeEnum.Text} value={functionName} disable={true}/>}/>
                            {/*類別*/}
                            <InputField className='fit-content' title='類別 : ' content={
                                <select
                                    className='w-[136px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-4 hover:cursor-pointer'
                                    value={docType}
                                    onChange={handleDocTypeInputOnChange}>
                                    {/*A.常態說明*/}
                                    <option value={RsisDocTypeEnum.StandardDescription}>A.常態說明</option>
                                    {/*B.特別說明*/}
                                    <option value={RsisDocTypeEnum.SpecialDescription}>B.特別說明</option>
                                </select>
                            }/>
                        </div>
                        {/*第三列*/}
                        {/*操作說明*/}
                        <div className='flex flex-row gap-10'>
                            <InputField title='操作說明 : '/>
                        </div>
                    </div>
                    {/*操作說明*/}
                    <div className='h-[400px] overflow-y-scroll'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={editorData}
                            onChange={(event, editor) => handleCKEditorOnChange(event, editor)}/>
                        <style>
                            {`.ck-editor__editable_inline {
                              height: 300px;
                            }`}
                        </style>
                    </div>
                    {/* 分隔線 */}
                    <div className='w-full h-[1px] bg-[#D9D9D9] my-4'></div>
                    {/* 存檔按鈕 */}
                    <div className='flex justify-end items-center'>
                        <button className='w-[80px] h-[40px] bg-primary text-white rounded-[6px]'
                                onClick={handleOnSave}>
                            {t('general.saveFile')}
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default FunctionOperateIntroMaintain
