// Icon
import {ReactComponent as RefreshIcon} from "../../../assets/images/icons/refresh.svg"

// Import function
import {ApiErrorStatusEnum, fuzzySearchObjects, IccmInfoPageTypeEnum, stringIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {useEffect, useState} from "react"
import {NoHpcSection} from "./ActiveSection/NoHpcSection"
import {HasHpcSection} from "./ActiveSection/HasHpcSection"
import {CheckPregnantSection} from "./ActiveSection/CheckPregnantSection"
import {AllergyMedicineSection} from "./ActiveSection/AllergyMedicineSection"
import {PreventiveHealthInfoSection} from "./ActiveSection/PreventiveHealthInfoSection"
import {MedicalOrderSection} from "./ActiveSection/MedicalOrderSection"
import {LongTermPrescriptionSection} from "./ActiveSection/LongTermPrescriptionSection"
import {PrescriptionNoteSection} from "./ActiveSection/PrescriptionNoteSection"
import {VaccinationInfoSection} from "./ActiveSection/VaccinationInfoSection"
import {
    icHisGetInoculateData,
    icHisGetRegisterPregnant,
    icHisGetRegisterPrevent,
    icHisGetTreatmentNeedHPC,
    icHisGetTreatmentNoNeedHPC,
    icHisReadPrescriptHVE,
    icHisReadPrescriptLongTerm,
    icHisReadPrescriptMain
} from "../../../api/v1/LocalService"
import {
    vhcHisGetTreatmentNoNeedHPC,
    vhcHisGetTreatmentNeedHPC,
    vhcHisReadPrescriptMain,
    vhcHisReadPrescriptLongTerm,
    vhcHisReadPrescriptHVE
} from "../../../api/v1/ICCM"
import useToast from "../../../hooks/useToast"

/**
 *  Switch Container
 *  @return {JSX.Element}
 */
export const SwitchContainer = ({icCardData}) => {
    //資料
    const [data, setData] = useState([])

    //當前選取的Tab index
    const [currentActiveTab, setCurrentActiveTab] = useState(IccmInfoPageTypeEnum.noHpc)

    //當前讀取狀態
    const [readStatus, setReadStatus] = useState('')

    //讀取時間
    const [readDuration, setReadDuration] = useState(0)

    //搜尋文字
    const [searchText, setSearchText] = useState('')

    //Toast Message Hooks
    const showToast = useToast()

    //預設範圍筆數
    const defaultCountRange = {
        //欲讀取的起始資料
        iStartPos: '',
        //欲讀取的終止資料
        iEndPos: ''
    }

    //範圍筆數
    const [countRange, setCountRange] = useState(defaultCountRange)

    /**
     * Tab點擊時切換
     * @param activeSection {Number} activeSection
     * @return {void}
     */
    const handleTabOnClick = (activeSection) => {
        //設定當前選取的Tab index
        setCurrentActiveTab(activeSection)
        //清空搜尋文字
        setSearchText('')
        //清空讀取狀態
        setReadStatus('')
        //清空讀取時間
        setReadDuration(0)
        //清空範圍筆數
        setCountRange(defaultCountRange)
        //清空資料
        setData([])
    }

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => {
        setSearchText(e.target.value)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (oriData) => {
        return fuzzySearchObjects(oriData, searchText)
    }

    /**
     * Get button class
     * @param index {number} index
     * @return {string}
     */
    const getButtonClass = (index) => `flex items-center justify-center px-4 h-10 mr-2 rounded-[6px] whitespace-nowrap disabled:text-[#D4D4D8] ${currentActiveTab === index ? 'bg-[#2B6CB0] text-white' : 'bg-[#F4F4F5] font-bold'}`

    /**
     * 起始資料改變時
     * @param e {Event} event
     * @return {void}
     */
    const handleStartPosOnChange = (e) => {
        // 確認是否為空值
        const isEmpty = stringIsEmpty(e.target.value)
        // 取得新值並轉換為數字
        const newValue = isEmpty ? '' : parseInt(e.target.value, 10)

        // 確保新值為數字且小於終止值
        if (isEmpty || (newValue < countRange.iEndPos)) {
            // 設定起始資料
            setCountRange({...countRange, iStartPos: parseInt(newValue)})
        }
    }

    /**
     * 終止資料改變時
     * @param e {Event} event
     * @return {void}
     */
    const handleEndPosOnChange = (e) => {
        // 確認是否為空值
        const isEmpty = stringIsEmpty(e.target.value)
        // 取得新值並轉換為數字
        const newValue = isEmpty ? '' : parseInt(e.target.value, 10)

        // 確保新值為數字且大於起始值
        if (isEmpty || (newValue > countRange.iStartPos)) {
            setCountRange({...countRange, iEndPos: parseInt(newValue)})
        }
    }

    /**
     *  取得控制是否顯示範圍筆數
     *  @return {JSX.Element}
     */
    const getRangeCountSectionElement = () => {
        switch (currentActiveTab) {
            case IccmInfoPageTypeEnum.prescriptionNote:
            case IccmInfoPageTypeEnum.longTermPrescription:
            case IccmInfoPageTypeEnum.medicalOrder:
                return (
                    <div className="flex flex-row items-center justify-start px-4">
                        <p className="pr-4">筆數範圍</p>
                        <input
                            className="w-[60px] h-10 text-[16px] px-2 text-black border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type="text" value={countRange.iStartPos} onChange={(e) => handleStartPosOnChange(e)}/>
                        <p className="px-2"> ~ </p>
                        <input
                            className="w-[60px] h-10 text-[16px] px-2 text-black border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type="text" value={countRange.iEndPos} onChange={(e) => handleEndPosOnChange(e)}/>
                    </div>
                )
            default:
                return null
        }
    }

    /**
     * 控制過敏藥物不顯示讀取內容
     * @return {JSX.Element}
     */
    const getReadContentSectionElement = () => {
        switch (currentActiveTab) {
            case IccmInfoPageTypeEnum.allergyMedicine:
                return null
            default:
                return (
                    <div className="flex flex-row">
                        {/*卡片讀取*/}
                        <div className='flex items-center justify-center mr-4'>
                            <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.cardReading', {status: stringIsEmpty(readStatus) ? '--' : readStatus})}</p>
                        </div>
                        {/*讀取花費時間*/}
                        <div className='flex items-center justify-center h-11 mr-4'>
                            <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.readingTime', {duration: readDuration === 0 ? '--' : readDuration})}：</p>
                        </div>
                        {/*讀取按鈕*/}
                        <a className="cursor-pointer flex items-center justify-center w-[100px] h-10 mr-6 border-[2px] border-[#D4D4D8] rounded-[6px]"
                           onClick={handleReadButtonOnClick}>
                            <RefreshIcon className="mr-2"/>
                            {t('general.read')}
                        </a>
                    </div>
                )
        }
    }

    /**
     * 取得顯示進階搜尋區塊
     * @return {JSX.Element}
     */
    const getAdvancedSearchSectionElement = () => {
        switch (currentActiveTab) {
            case IccmInfoPageTypeEnum.noHpc:
            case IccmInfoPageTypeEnum.hasHpc:
            case IccmInfoPageTypeEnum.medicalOrder:
            case IccmInfoPageTypeEnum.prescriptionNote:
            case IccmInfoPageTypeEnum.longTermPrescription:
                return (
                    <div>
                        <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text"
                               placeholder="進階搜尋" value={searchText} onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>
                )
            default:
                return null
        }
    }

    /**
     * 取得區塊
     * @return {JSX.Element}
     */
    const getSection = () => {
        switch (currentActiveTab) {
            //就醫資料不需HPC卡
            case IccmInfoPageTypeEnum.noHpc:
                return <NoHpcSection data={getFilterData(data)}/>
            //就醫資料需HPC卡
            case IccmInfoPageTypeEnum.hasHpc:
                return <HasHpcSection 
                            severeIllness={data.severeIllness} 
                            treatment={data.treatment}
                            getFilterDataFunc={getFilterData}/>
            //就醫資料門診處方箋
            case IccmInfoPageTypeEnum.prescriptionNote:
                return <PrescriptionNoteSection data={getFilterData(data)}/>
            //就醫資料住院處方箋
            case IccmInfoPageTypeEnum.longTermPrescription:
                return <LongTermPrescriptionSection data={getFilterData(data)}/>
            //重要醫令
            case IccmInfoPageTypeEnum.medicalOrder:
                return <MedicalOrderSection data={getFilterData(data)}/>
            //預防保健掛號作業
            case IccmInfoPageTypeEnum.preventiveHealthInfo:
                return <PreventiveHealthInfoSection data={getFilterData(data)}/>
            //孕婦產前檢查掛號作業
            case IccmInfoPageTypeEnum.checkPregnant:
                return <CheckPregnantSection data={data}/>
            //過敏藥物
            case IccmInfoPageTypeEnum.allergyMedicine:
                return <AllergyMedicineSection info={{
                                                    //身分證
                                                    pPatientID: icCardData.idNo, 
                                                    //生日
                                                    pPatientBirthDate: icCardData.birthDate,
                                                    //是否為實體IC
                                                    isPhysical: icCardData.isPhysical
                                                }}/>
            //預防接種資料
            case IccmInfoPageTypeEnum.vaccinationInfo:
                return <VaccinationInfoSection data={data}/>
        }
    }

    /**
     * 讀取按鈕點擊時
     * @return {void}
     */
    const handleReadButtonOnClick = () => {
        switch (currentActiveTab) {
            //就醫資料不需HPC卡
            case IccmInfoPageTypeEnum.noHpc:
                getIcHisGetTreatmentNoNeedHPC()
                break
            //就醫資料需HPC卡
            case IccmInfoPageTypeEnum.hasHpc:
                getIcHisGetTreatmentNeedHPC()
                break
            //就醫資料門診處方箋
            case IccmInfoPageTypeEnum.prescriptionNote:
                getIcHisReadPrescriptMain()
                break
            //就醫資料住院處方箋
            case IccmInfoPageTypeEnum.longTermPrescription:
                getIcHisReadPrescriptLongTerm()
                break
            //就醫資料重要醫令
            case IccmInfoPageTypeEnum.medicalOrder:
                getIcHisReadPrescriptHVE()
                break
            //預防保健掛號作業
            case IccmInfoPageTypeEnum.preventiveHealthInfo:
                getIcHisGetRegisterPrevent()
                break
            //孕婦產前檢查掛號作業
            case IccmInfoPageTypeEnum.checkPregnant:
                getIcHisGetRegisterPregnant()
                break
            //預防接種資料
            case IccmInfoPageTypeEnum.vaccinationInfo:
                getIcHisGetInoculateData()
                break
        }
    }

    /**
     * 處理API回應
     * @param res {Object} res
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // API狀態
        const apiStatus = res.err === ApiErrorStatusEnum.Success
        // API回傳結果
        const result = res
        //設定讀取狀態
        setReadStatus(apiStatus ? t('general.success') : t('general.failed'))
        //設定讀取時間
        setReadDuration(res.runTime / 1000)
        //API狀態為成功
        if (apiStatus) {
            //設定資料
            setData(result.data.treatment)
        }
        else {
            // 顯示錯誤訊息
            showToast({message: res.msg, type: ToastTypeEnum.Error})
        }
    }

    /**
     * 處理API回應2
     * @param res {Object} res
     * @return {void}
     */
    const handleApiResponse2 = (res) => {
        // API狀態
        const apiStatus = res.err === ApiErrorStatusEnum.Success
        // API回傳結果
        const result = res
        //設定讀取狀態
        setReadStatus(apiStatus ? t('general.success') : t('general.failed'))
        //是否有執行時間
        const hasRunTime = !!res.runTime
        //設定讀取時間
        setReadDuration(hasRunTime ? (res.runTime / 1000) : 0)

        //API狀態為成功
        if (apiStatus) {
            //設定資料
            setData(result.data)
        }
        else {
            // 顯示錯誤訊息
            showToast({message: res.msg, type: ToastTypeEnum.Error})
        }
    }

    /**
     * 取得IC卡歷次就醫不需HPC
     * @return {void}
     */
    const getIcHisGetTreatmentNoNeedHPC = () => {
        if(icCardData.isPhysical) {
            icHisGetTreatmentNoNeedHPC({}).then(handleApiResponse)
        }
        else {
            vhcHisGetTreatmentNoNeedHPC({}).then(handleApiResponse)
        }
    }

    /**
     * 取得IC卡歷次就醫需HPC
     * @return {void}
     */
    const getIcHisGetTreatmentNeedHPC = () => {
        if(icCardData.isPhysical) {
            icHisGetTreatmentNeedHPC({}).then(handleApiResponse2)
        }
        else {
            vhcHisGetTreatmentNeedHPC({}).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-門診處方箋
     * @return {void}
     */
    const getIcHisReadPrescriptMain = () => {
        if(icCardData.isPhysical) {
            icHisReadPrescriptMain({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過60)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
        else {
            vhcHisReadPrescriptMain({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過60)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-長期處方箋
     * @return {void}
     */
    const getIcHisReadPrescriptLongTerm = () => {
        if(icCardData.isPhysical) {
            icHisReadPrescriptLongTerm({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過30)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
        else {
            vhcHisReadPrescriptLongTerm({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過30)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-重要醫令
     * @return {void}
     */
    const getIcHisReadPrescriptHVE = () => {
        if(icCardData.isPhysical) {
            icHisReadPrescriptHVE({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過10)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
        else {
            vhcHisReadPrescriptHVE({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過10)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
    }

    /**
     * 預防保健掛號作業
     * @return {void}
     */
    const getIcHisGetRegisterPrevent = () => {
        icHisGetRegisterPrevent({}).then(handleApiResponse2)
    }

    /**
     * 孕婦產前檢查掛號作業
     * @return {void}
     */
    const getIcHisGetRegisterPregnant = () => {
        icHisGetRegisterPregnant({}).then(handleApiResponse2)
    }

    /**
     * 預防接種資料
     * @return {void}
     */
    const getIcHisGetInoculateData = () => {
        icHisGetInoculateData({}).then(handleApiResponse2)
    }

    return (
        <div id="switchContainer" className="px-6 border-[1px] border-[#D4D4D8] rounded-[12px] py-2 bg-white">
            {/*內容切換按鈕*/}
            <div id="switchTab" className="w-full">
                <div className="flex flex-row justify-start items-center mb-2.5 py-2 overflow-scroll">
                    {
                        Array.from({length: 9}, (_, index) =>
                            {
                                let disable = false
                                if(icCardData.isPhysical === false && 
                                    (index === IccmInfoPageTypeEnum.preventiveHealthInfo || 
                                    index === IccmInfoPageTypeEnum.checkPregnant ||
                                    index === IccmInfoPageTypeEnum.vaccinationInfo)) {
                                        //虛擬卡時，沒有 預防保健、孕婦產前檢查、預防接種資料
                                        disable = true
                                    }
                                return (
                                    <button className={getButtonClass(index)} key={index} disabled={disable} 
                                            onClick={() => handleTabOnClick(index)}>
                                        {t(`ICCard.activeSection.${index}`)}
                                    </button>)
                            }
                        )
                    }
                </div>
            </div>
            {/*內容切換內文區*/}
            <div id="switchContain">
                <div className="flex flex-row w-full py-2 mb-4">
                    <h3 className="flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                        <span>{t(`ICCard.activeSection.${currentActiveTab}`)}</span>
                    </h3>
                    {/*控制是否顯示範圍筆數*/}
                    {getRangeCountSectionElement()}
                    {/*控制過敏藥物不顯示讀取內容*/}
                    {getReadContentSectionElement()}
                    {/*控制是否顯示進階搜尋*/}
                    {getAdvancedSearchSectionElement()}
                </div>
                {/*區塊*/}
                {getSection()}
            </div>
        </div>
    )
}
