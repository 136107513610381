import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得該搜尋條件之服務資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitor = (params) => v1Api.request({
    url: '/wdis/servicemonitor/queryServiceMonitor',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改服務資料內容
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisEditServiceMonitor = (data) => v1Api.request({
    url: '/wdis/servicemonitor/editServiceMonitor',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除服務資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisDelServiceMonitor = (data) => v1Api.request({
    url: '/wdis/servicemonitor/delServiceMonitor',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 取得該serviceId之服務資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitorByServiceId = (params) => v1Api.request({
    url: `/wdis/servicemonitor/queryServiceMonitor/${params.serviceId}`,
    method: HttpMethodEnum.GET,
})

/**
 * 新增服務資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisAddServiceMonitor = (data) => v1Api.request({
    url: '/wdis/servicemonitor/addServiceMonitor',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢服務對應通知人員
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceNotify = (params) => v1Api.request({
    url: '/wdis/servicenotify/queryServiceNotify',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 複製該筆服務通知人員
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisAddServiceNotify = (data) => v1Api.request({
    url: '/wdis/servicenotify/addServiceNotify',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改該筆服務通知人員
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisEditServiceNotify = (data) => v1Api.request({
    url: '/wdis/servicenotify/editServiceNotify',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 修改該筆服務通知人員
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisDelServiceNotify = (data) => v1Api.request({
    url: '/wdis/servicenotify/delServiceNotify',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢所有通知內容板模維護之內容
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryTagName = (params) => v1Api.request({
    url: '/wdis/tagname/queryTagName',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 模糊查詢通知內容板模維護之內容
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryTagNameByLike = (params) => v1Api.request({
    url: '/wdis/tagname/queryTagName/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增通知內容板模維護之內容
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisAddTagName = (data) => v1Api.request({
    url: '/wdis/tagname/addTagName',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改通知內容板模維護之內容
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisEditTagName = (data) => v1Api.request({
    url: '/wdis/tagname/editTagName',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 修改通知內容板模維護之內容
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisDelTagName = (data) => v1Api.request({
    url: '/wdis/tagname/delTagName',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢服務狀態
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitorStatus = (params) => v1Api.request({
    url: '/wdis/servicemonitor/queryServiceMonitor/status',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依服務名稱/代號模糊查詢服務狀態
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitorStatusByLike = (params) => v1Api.request({
    url: '/wdis/servicemonitor/queryServiceMonitor/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 服務狀態查詢-健康度計算
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceExecute = (params) => v1Api.request({
    url: '/wdis/serviceexecute/queryServiceExecute',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 服務Log查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitorOperlog = (params) => v1Api.request({
    url: '/wdis/servicemonitoroperlog/queryServiceMonitorOperlog',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依服務名稱做模糊查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const wdisQueryServiceMonitorOperlogByLike = (params) => v1Api.request({
    url: '/wdis/servicemonitoroperlog/queryServiceMonitorOperlog/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 提供API供外部服務執行時呼叫--啟動時
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisAddServiceExecuteCheck = (data) => v1Api.request({
    url: '/wdis/serviceexecute/addServiceExecute/check',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 提供API供外部服務執行時呼叫--結束或發生意外時
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const wdisEditServiceExecuteCheck = (data) => v1Api.request({
    url: '/wdis/serviceexecute/editServiceExecute/check',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 取得服務資料維護的相關參數初始值
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const WdisQueryBaseData = (params) => v1Api.request({
    url: '/wdis/servicemonitor/queryBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 取得通知人員維護的相關參數初始值
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const WdisServiceNotifyQueryBaseData = (params) => v1Api.request({
    url: '/wdis/servicenotify/queryBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 取得服務日誌維護的相關參數初始值
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const WdisServiceMonitorOperLogQueryBaseData = (params) => v1Api.request({
    url: '/wdis/servicemonitoroperlog/queryBaseData',
    method: HttpMethodEnum.GET,
    params
})

export {
    wdisQueryServiceMonitor,
    wdisEditServiceMonitor,
    wdisDelServiceMonitor,
    wdisQueryServiceMonitorByServiceId,
    wdisAddServiceMonitor,
    wdisQueryServiceNotify,
    wdisAddServiceNotify,
    wdisEditServiceNotify,
    wdisDelServiceNotify,
    wdisQueryTagName,
    wdisQueryTagNameByLike,
    wdisAddTagName,
    wdisEditTagName,
    wdisDelTagName,
    wdisQueryServiceMonitorStatus,
    wdisQueryServiceMonitorStatusByLike,
    wdisQueryServiceExecute,
    wdisQueryServiceMonitorOperlog,
    wdisQueryServiceMonitorOperlogByLike,
    wdisAddServiceExecuteCheck,
    wdisEditServiceExecuteCheck,
    WdisQueryBaseData,
    WdisServiceNotifyQueryBaseData,
    WdisServiceMonitorOperLogQueryBaseData,
}
