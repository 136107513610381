import React, { useMemo, useState } from 'react'
import { BasePopup } from '../../components/Popup/BasePopup'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { REFERRAL_LIST_MODAL } from '../../constants/referralSystem/maintainAreaHeader'
import {
    ApiErrorStatusEnum,
    ToastTypeEnum,
    arrayIsEmpty,
} from 'edah_utils/dist'
import Button from '../../components/Button/Button'
import { useEffect } from 'react'
import { queryEtranToOpd } from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'

const Layout = ({ children }) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

const ButtonStyle =
    'ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white'
/**
 * 電子轉診清單
 * @returns {JSX.Element}
 */
const ReferralListModalContent = ({ info, onConfirm }) => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    const showToast = useToast()

    // 儲存
    const handleConfirm = () => {
        const etranNo = dataList.find((item) => item.isSelected)?.etranNo ?? ''
        onConfirm(etranNo)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.originIndex === item.originIndex
                        ? isChecked
                        : false,
            }
        })
        setDataList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getReferralList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getReferralList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getReferralList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getReferralList(page, filter.pageSize)
    }

    /**
     * 取得電子轉診清單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const getReferralList = (pageNum, pageSize) => {
        queryEtranToOpd({
            encounterDate: info.encounterDate,
            patientId: info.patientId,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = arrayIsEmpty(res.data)
                    ? []
                    : res.data.map((item, index) => {
                          return {
                              ...item,
                              isSelected: false,
                              originIndex: index,
                          }
                      })
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    useEffect(() => {
        getReferralList(1, filter.pageSize)
    }, [])

    return (
        <Layout>
            <div className="flex justify-between p-2 items-center mb-3">
                <div className="flex items-center">
                    <Title>病歷號：</Title>
                    {info.patientId}
                    <Title extendClass={'ml-4'}>姓名：</Title>
                    {info.patientName}
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                isSingleSelect={true}
                fields={REFERRAL_LIST_MODAL}
                dataList={dataList}
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            <div className="px-2 pt-4 pb-4">
                <div className="mb-2">
                    ※若病人為電子轉診病人，請勾選後帶回。
                </div>
                <div className="mb-2">※打勾處反灰，代表此單已被使用。</div>
                <div className="mb-2">
                    ※取消打勾後，按確定，即可取消使用此單表。
                </div>
            </div>
            <div className="title w-full flex flex-row items-center justify-end py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
                <Button
                    classNames={`${ButtonStyle} mr-2`}
                    text={'確定'}
                    onClickFn={handleConfirm}
                />
            </div>
        </Layout>
    )
}

const ReferralListModal = ({ onClose, info, onConfirm }) => {
    return (
        <BasePopup
            title="電子轉診清單"
            width="80%"
            content={
                <ReferralListModalContent
                    info={info}
                    onConfirm={onConfirm}
                />
            }
            closePopupButtonOnClick={onClose}
        />
    )
}

export default ReferralListModal
