import { DefaultButton } from "../../Button/DefaultButton";
import React, {useEffect, useState} from "react"
import {stringIsEmpty, ToastTypeEnum, InputTypeEnum} from "edah_utils/dist"
import {t} from "i18next"
import { BaseInput } from "../../Input/BaseInput";
import useToast from "../../../hooks/useToast";

/**
 * 建立初診病人彈出框
 * @param show {Boolean} 是否顯示彈窗
 * @param {Function} closePopupButtonOnClick 關閉事件
 * @param {Function} onSave 存檔事件
 * @param {Object} data
 * @returns 
 */
const FirstVisitPatientFormPopup = ({
    show = false,
    closePopupButtonOnClick,
    onSave,
    data
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)
    /**
     * 輸入的姓名
     */
    const [inputName, setInputName] = useState("")
    /**
     * 輸入的身分證號
     */
    const [inputIdNo, setInputIdNo] = useState("")
    /**
     * 輸入的住家號碼
     */
    const [inputHomephonenumber, setInputHomephonenumber] = useState("")
    /**
     * 輸入的手機號碼
     */
    const [inputPhoneNumber, setInputPhoneNumber] = useState("")
    /**
     * 輸入的性別
     */
    const [inputGender, setInputGender] = useState("")
    /**
     * 輸入的出生日期
     */
    const [inputBirthDate, setInputBirthDate] = useState("")
    //Toast Message Hooks
    const showToast = useToast()
    /**
     * 性別list
     */
    const genderList = [
        {
            justnameNo: "1",
            justnameName: "男"
        },
        {
            justnameNo: "2",
            justnameName: "女"
        },
        {
            justnameNo: "3",
            justnameName: "不明"
        }
    ]

    /**
     * 按下存檔按鈕事件
     */
    const handleSaveOnClick = () => {
        
        if(stringIsEmpty(inputName)) {
            showToast({message:"姓名欄位填寫錯誤", type:ToastTypeEnum.Warning})
            return
        }

        if(stringIsEmpty(inputIdNo)) {
            showToast({message:"身分證號欄位填寫錯誤", type:ToastTypeEnum.Warning})
            return
        }

        if(stringIsEmpty(inputBirthDate)) {
            showToast({message:"出生日期欄位填寫錯誤", type:ToastTypeEnum.Warning})
            return
        }

        if(stringIsEmpty(inputHomephonenumber) && stringIsEmpty(inputPhoneNumber)) {
            showToast({message:"住家號碼或手機號碼至少填寫一個", type:ToastTypeEnum.Warning})
            return
        }
        
        if(!stringIsEmpty(inputPhoneNumber)) {
            //檢測手機號碼格式: 09開頭+後面8個數字
            const regex = /^09\d{8}$/;
            if(!regex.test(inputPhoneNumber)) {
                showToast({message:"手機號碼格式不正確", type:ToastTypeEnum.Warning})
                return
            }
        }

        let genderString = genderList[2].justnameName;
        if(!stringIsEmpty(inputGender)) {
            const gender = genderList.find(item => item.justnameNo === inputGender);
            if(gender != null)
            {
                genderString = gender.justnameName
            }
        }
            
        onSave(inputName, inputBirthDate,inputIdNo, genderString, inputHomephonenumber, inputPhoneNumber);
    }
    /**
     * 姓名輸入事件
     * @param {Event} e 
     * @returns 
     */
    const handleInputNameOnChange =(e) => setInputName(e.target.value)
    /**
     * 身分證號輸入事件
     * @param {Event} e 
     * @returns 
     */
    const handleInputIdNoOnChange =(e) => setInputIdNo(e.target.value)
    /**
     * 住家電話輸入事件
     * @param {Event} e 
     * @returns 
     */
    const handleInputHomephonenumberOnChange =(e) => setInputHomephonenumber(e.target.value)
    /**
     * 手機號碼輸入事件
     * @param {Event} e 
     * @returns 
     */
    const handleInputPhoneNumberOnChange =(e) => setInputPhoneNumber(e.target.value)

    /**
     * 性別選擇事件
     * @param {String} gender 
     * @returns 
     */
    const handleGenderOnClick = (gender) => setInputGender(gender)

    /**
     * 出生日期輸入事件
     * @param {String} e 
     * @returns 
     */
    const handleInputBirthDateOnChange = (e) => setInputBirthDate(e.target.value)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if(!show) {
            //清除欄位
            setInputName("")
            setInputIdNo("")
            setInputBirthDate("")
            setInputHomephonenumber("")
            setInputPhoneNumber("")
            setInputGender("")
        }
        else {
            setInputName(data.patientName)
            setInputIdNo(data.idNo)
            setInputBirthDate(data.birthDate)
            setInputHomephonenumber(data.homephonenumber)
            setInputPhoneNumber(data.mobilenumber)
        }
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <div
            className="fixed top-[-16px] left-0 flex items-center justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-[80]">
            <div className="w-[500px] bg-white rounded-[6px]">
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">初診病人資訊</h3>
                    {/*關閉按鈕*/}
                    <button className="flex items-center justify-center h-10 text-[18px] text-[#2B6CB0]"
                            onClick={closePopupButtonOnClick}>
                        {t('general.close')}
                    </button>
                </div>
                {/*Content*/}
                <div className="mb-4 px-6 space-y-2">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col text-left w-[35%] space-y-2 px-2">
                            <label className="flex items-center h-[40px] justify-end">
                                {t('general.username')}
                            </label>

                            <label className="flex items-center h-[40px] justify-end">
                                {t('general.idNumber')}
                            </label>

                            <label className="flex items-center h-[40px] justify-end">
                                {t('general.dateOfBirth')}
                            </label>
                            <label className="flex items-center h-[40px] justify-end">
                                {t('general.gender.name')}
                            </label>
                            <label className="flex items-center h-[40px] justify-end">
                                住家號碼
                            </label>
                            <label className="flex items-center h-[40px] justify-end">
                                手機號碼
                            </label>
                        </div>
                        {/*輸入方框*/}
                        <div className="flex flex-col text-left w-[65%] space-y-2 px-3 justify-center">
                            {/** 姓名 */}
                            <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                    value={inputName}
                                    onChange={handleInputNameOnChange}
                                    type="text"/>
                            {/** 身分證號 */}
                            <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                    value={inputIdNo}
                                    onChange={handleInputIdNoOnChange}
                                    type="text"/>
                            {/**出生日期 */}
                            <BaseInput 
                                className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] w-[calc(100%-30px)]"
                                type={InputTypeEnum.Date}
                                inputMode="date"
                                value={inputBirthDate}
                                onChange={handleInputBirthDateOnChange}/>

                            {/**性別 */}
                            <div className="flex flex-row space-x-5 items-center h-[40px]">
                            {
                                genderList.map((gender, index) => (
                                    <div 
                                        key={index}
                                        className={`flex flex-row space-x-1 mr-[2px] ${inputGender === gender.justnameNo ? "text-[#2b6cb0]":"text-[#11181c]" } cursor-pointer`}
                                        onClick={() => handleGenderOnClick(gender.justnameNo)}>
                                        <input 
                                            className="text-black"
                                            type="radio"
                                            checked={inputGender === gender.justnameNo}/>
                                        <div>{gender.justnameName}</div>
                                    </div>
                                ))
                            }
                            </div>
                            {/**住家號碼 */}
                            <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                    value={inputHomephonenumber}
                                    onChange={handleInputHomephonenumberOnChange}
                                    type="text"/>
                            {/**手機號碼 */}
                            <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                    value={inputPhoneNumber}
                                    onChange={handleInputPhoneNumberOnChange}
                                    type="text"/>
                        </div>
                    </div>
                </div>
                <div className="text-right border-t-[1px] border-[rgba(0,0,0,0.15)] px-6 py-3">
                    <DefaultButton 
                        type="primary" 
                        text="存檔"
                        onClickFn={handleSaveOnClick} 
                        />
                </div>
            </div>
        </div> :
        <></>
    )
}

export default FirstVisitPatientFormPopup;