//Import Css
import '../../TableList/tableList.scss';

// Import Function
import React, {useEffect, useState} from 'react';
import {BaseInput} from '../../Input/BaseInput';
import {ApiErrorStatusEnum, stringIsEmpty, InputTypeEnum, ToastTypeEnum} from 'edah_utils/dist';
import {CancelSlotBtn, FastEditSlotBtn, SaveIconSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {rsisEditSendLog} from "../../../api/v1/RSIS";


/**
 * RSIS衛福部案號回饋清單組件
 * @param role {boolean} 是否為承辦人員
 * @param item {Object} 清單資料
 * @param index {number} 清單索引
 * @param isSelectAll {boolean} 是否包含全選
 * @param handleOnUpdate {function} 更新清單資料
 * @return {JSX.Element}
 */
const ListItem = ({
                      role,
                      item,
                      index,
                      isSelectAll = false,
                      handleOnUpdate,
                  }) => {

    // 原始備註
    const [originMemo, setOriginMemo] = useState('')
    // 原始衛福部案號
    const [originSendCaseNo, setOriginSendCaseNo] = useState('')
    // 新備註
    const [newMemo, setNewMemo] = useState('')
    // 新衛福部案號
    const [newSendCaseNo, setNewSendCaseNo] = useState('')
    // 是否快速編輯
    const [isFastEdit, setFastEdit] = useState(false)

    // 使用上下文
    const {
        showToast,
    } = usePopup();

    /**
     * 取得要存擋匯出紀錄內容
     * @return {Object}
     */
    const getSendLogContent = () => {
        const data = {
            //send uuid
            sendUuid: item.sendUuid,
            //匯出人員代號 string
            sendUserNo: item.sendUserNo,
            // 匯出日期時間
            sendDatetime: item.sendDatetime,
            //匯出檔案名稱
            sendFileName: item.sendFileName,
            //備註
            memo: newMemo,
            //衛褔部案號
            sendCaseNo: newSendCaseNo
        }
        return data
    }

    /**
     * 快速儲存
     * @return {void}
     */
    const handleSave = () => {
        if (newMemo === originMemo && newSendCaseNo === originSendCaseNo) {
            showToast({message: '請確認修改內容', type: ToastTypeEnum.Warning})
            return
        }
        if (newSendCaseNo === '') {
            showToast({message: '衛福部案號內容不得為空', type: ToastTypeEnum.Warning})
            return
        }
        saveEditSendLog()
    }

    /**
     * 取消
     * @return {void}
     */
    const handleCancel = () => {
        setNewMemo(stringIsEmpty(originMemo) ? '' : originMemo)
        setNewSendCaseNo(stringIsEmpty(originSendCaseNo) ? '' : originSendCaseNo)
        setFastEdit(false)
    }

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        const style = 'flex flex-row gap-2'
        // 是否在快速編輯狀態
        if (isFastEdit) {
            return <span className={style}>
                <SaveIconSlotBtn onClick={handleSave}/>
                <CancelSlotBtn onClick={handleCancel}/>
            </span>
        }
        if (role) {
            return <span className={style}>
                <FastEditSlotBtn onClick={() => {
                    setFastEdit(true)
                }}/>
            </span>
        }
    }

    /**
     * 快速編輯狀態輸入框
     * @return {string}
     */
    const fastEditInput = () => {
        return isFastEdit
            ? 'min-w-[225px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300'
            : 'min-w-[225px] h-10 px-2 bg-transparent text-black hover:cursor-default'
    }

    /**
     * 儲存修改後結果
     * @return {void}
     */
    const saveEditSendLog = () => {

        // 若原始衛福部案號不為空值，且更新後的衛福部案號為空值，則提示錯誤訊息
        if (originSendCaseNo !== '' && newSendCaseNo === '') {
            showToast({message: '衛福部案號不能修改為空值', type: ToastTypeEnum.Warning})
            return
        }

        rsisEditSendLog(getSendLogContent()).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: msg, type: ToastTypeEnum.Success})
                // 退出快速編輯狀態
                setFastEdit(false)
                // 重新取得資料
                handleOnUpdate()
            } else {
                // 顯示錯誤訊息
                showToast({message: `儲存失敗，${msg}`, type: ToastTypeEnum.Error})
                // 還原備註
                setNewMemo(originMemo)
                // 還原衛福部案號
                setNewSendCaseNo(originSendCaseNo)
                // 退出快速編輯狀態
                setFastEdit(false)
            }
        })
    }

    // 監聽傳進來的資料變化，並更新畫面
    useEffect(() => {
        setOriginMemo(item.memo || '')
        setNewMemo(item.memo || '')
        setOriginSendCaseNo(item.sendCaseNo || '')
        setNewSendCaseNo(item.sendCaseNo || '')
        setFastEdit(false)
    },[item])

    return (
        <li key={index}>
            {/* 根據使用者權限顯示對應按鈕 */}
            <div className='flex justify-evenly'>
            <span className='flex items-center'>
                {renderSlotBtn()}
            </span>
            </div>
            {/* 匯出編號 */}
            <div>
                <BaseInput
                    className="min-w-[225px] h-10 px-2 bg-transparent text-black hover:cursor-default"
                    type={InputTypeEnum.Text}
                    value={item.sendFileName}
                    disable={true}
                />
            </div>
            {/* 備註 */}
            <div>
                <BaseInput
                    className={fastEditInput()}
                    disable={!isFastEdit}
                    type={InputTypeEnum.Text}
                    value={newMemo}
                    onChange={(e) => setNewMemo(e.target.value)}
                />
            </div>
            {/* 衛福部案號 */}
            <div>
                <BaseInput
                    className={fastEditInput()}
                    disable={!isFastEdit}
                    type={InputTypeEnum.Text}
                    value={newSendCaseNo}
                    onChange={(e) => setNewSendCaseNo(e.target.value)}
                />
            </div>
            {/* 匯出人員 */}
            <div>{`${item.sendUserNo ? item.sendUserNo : ''}${item.userName ? item.userName : ''}`}</div>
            {/* 匯出時間 */}
            <div>{`${item.sendDatetime ? item.sendDatetime : ''}`}</div>
        </li>
    );
}

export default ListItem
