import React, { useEffect, useRef, useState } from 'react'
import searchIcon from '../../assets/images/search-interface-symbol.png'
import useOutsideClick from '../../hooks/useOutsideClick'

/**
 * 醫師模糊查詢選單
 * @param {function} props.onSelect 選擇醫師callback
 * @param {array} props.doctorList 醫師清單
 * @param {object} props.value 醫師資料 { doctorNo:string, doctorName:string }
 * @returns {JSX.Element}
 */
const DoctorDatalist = ({
    onSelect,
    doctorList,
    value = { doctorNo: '', doctorName: '' },
}) => {
    const [keyword, setKeyword] = useState(
        `${value.doctorNo} ${value.doctorName}`
    )
    const [filteredDoctorList, setFilteredDoctorList] = useState([])
    const [isShowDoctorDropdown, setIsShowDoctorDropdown] = useState(false)
    const [selectedDoctor, setSelectedDoctor] = useState(value)
    const dropdownRef = useRef(null)

    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            const selectedDoctorString = selectedDoctor
                ? `${selectedDoctor.doctorNo} ${selectedDoctor.doctorName}`
                : null

            if (keyword.trim() !== selectedDoctorString) {
                setKeyword('')
                onSelect(null)
            }
            setIsShowDoctorDropdown(false)
        },
    })

    /**
     * focus input to show dropdown
     * @return {void}
     */
    const handleKeywordOnFocus = () => {
        setIsShowDoctorDropdown(true)
        setFilteredDoctorList(doctorList)
    }

    /**
     * 選擇醫師
     * @param {object} item
     * @return {void}
     */
    const handleDoctorOnClick = (item) => {
        setKeyword(`${item.doctorNo} ${item.doctorName}`)
        onSelect(item)
        setSelectedDoctor(item)
        setIsShowDoctorDropdown(false)
    }

    /**
     * 輸入醫師搜尋
     * @param {string} value keyword
     * @return {void}
     */
    const updateKeyword = (value) => {
        setKeyword(value)
        setSelectedDoctor(null)
    }

    /**
     * 輸入框顯示醫師字串 doctorNo + doctorName
     */
    useEffect(() => {
        setKeyword(`${value.doctorNo} ${value.doctorName}`)
        const defaultDoctor = value.doctorNo !== '' ? value : null
        setSelectedDoctor(defaultDoctor)
    }, [value])

    /**
     * 模糊搜尋醫師
     */
    useEffect(() => {
        const filteredList = doctorList.filter((item) => {
            const doctorString = `${item.doctorNo} ${item.doctorName}`
            return (
                doctorString.includes(keyword) || doctorString.includes(keyword)
            )
        })
        setFilteredDoctorList(filteredList)
    }, [keyword])

    return (
        <div className="w-full text-[#18181B]">
            <div
                ref={dropdownRef}
                className="w-full flex flex-row space-x-2 items-center relative"
            >
                <input
                    className="h-10 bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8]"
                    value={keyword}
                    onChange={(e) => updateKeyword(e.target.value)}
                    onFocus={handleKeywordOnFocus}
                />
                <img
                    className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                    alt="search-icon"
                    src={searchIcon}
                    role="button"
                />
                {isShowDoctorDropdown && (
                    <div className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                        {/*醫師下拉*/}
                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                            {filteredDoctorList.map((item, index) => (
                                <div
                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                    role="button"
                                    key={index}
                                    onClick={() => handleDoctorOnClick(item)}
                                >
                                    <p>{`${item.doctorNo}`}</p>
                                    <p>{`${item.doctorName}`}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DoctorDatalist
