import React, {useEffect, useState, useRef} from "react"
import searchIcon from "../../../../assets/images/search-interface-symbol.png";
import {arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String, ApiErrorStatusEnum, RegsWeekModeEnum, InputTypeEnum} from "edah_utils/dist"
import { regsQueryClinicOfTheWeek } from "../../../../api/v1/RegsMonth";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { now } from "moment/moment";
import { BaseInput } from "../../../Input/BaseInput";
import WeeklyScheduleItem from "./WeeklyScheduleItem";
import {t} from "i18next"

const WeeklySchedule = ({
    show = false,
    data,
    closePopupButtonOnClick,
    onSelectClinic,
    fowardRegFlag
}) => {
    const [showPopup, setShowPopup] = useState(show)
    //查詢條件
    const [queryInput, setQueryInput] = useState({
        encounterDate:"",
        divNo: "",
        doctorNo: ""
    })
    // 分院清單
    const [zonesetList, setZonesetList] = useState((data && data.zonesetList) ? data.zonesetList : [])
    // 科別清單
    const [divList, setDivList] = useState([])
    // 醫生清單
    const [doctorList, setDoctorList] = useState([])
    // 診所清單
    const [clinicList, setClinicList] = useState([])
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 當週班表
    const [queryData, setQueryData] = useState([])
    const [weekDates, setWeekDates] = useState([])
    const [selectedClinic, setSelectedClinic] = useState(null)
    // 是否顯示科別選項
    const [showDivOptions, setShowDivOptions] = useState(false)
    // 是否顯示醫師選項
    const [showDoctorOptions, setShowDoctorOptions] = useState(false)
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorOptions(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDivOptions(false),
    });

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const divText = queryInput.divNo.trim()
        const doctorText = queryInput.doctorNo.trim()
        //切割字串
        const splitArray = divText ? divText.split(" ") : []
        //已經有選擇科別
        if (splitArray.length === 2) {
            return divList.filter(div => div.divNo.includes(splitArray[0]) && div.divName.includes(splitArray[1]))
        } else { //透過輸入文字
            // 醫生選項為空時
            if (stringIsEmpty(doctorText)) {
                // 科別選項為空時
                if (stringIsEmpty(divText)) {
                    return divList
                } else { // 科別選項不為空時
                    return divList.filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                }
            } else { // 醫生選項不為空時
                //取得醫生切割字串
                const splitDoctorArray = doctorText.split(" ")
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    if(splitArray.length === 0) {
                        //科別為空
                        //求聯集
                        return divList
                            .filter(div => clinicList.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    else if(splitArray.length === 1) {
                        //科別有1段
                        return divList
                            .filter(div => clinicList.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    }

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const divText = queryInput.divNo.trim()
        const doctorText = queryInput.doctorNo.trim()
        //切割字串
        const splitArray = doctorText ? doctorText.split(" ") : []
        //已經有選擇醫師
        if (splitArray.length === 2) {
            return doctorList.filter(doctor => doctor.doctorNo.includes(splitArray[0]) && doctor.doctorName.includes(splitArray[1]))
        } else { //透過輸入文字
            // 科別選項為空時
            if (objectIsEmpty(divText)) {
                // 醫師選項為空時
                if (stringIsEmpty(doctorText)) {
                    return doctorList
                } else { // 醫師選項不為空時
                    return doctorList.filter(doctor => doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText))
                }
            } else { // 科別選項不為空時
                //取得科別切割字串
                const splitDepartmentArray = divText.split(" ")
                //已經有選擇科別
                if (splitDepartmentArray.length === 2) {
                    if(splitArray.length === 0) {
                        //醫生為空
                        //求聯集
                        return doctorList
                            .filter(doctor => clinicList.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .map(doctor => new Object({
                                //科別代碼
                                doctorNo: doctor.doctorNo,
                                //科別名稱
                                doctorName: doctor.doctorName,
                            }))
                    }
                    else if(splitArray.length === 1) {
                        //醫生有1段
                        return doctorList
                            .filter(doctor => clinicList.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .filter(doctor => doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText))
                            .map(doctor => new Object({
                                //科別代碼
                                doctorNo: doctor.doctorNo,
                                //科別名稱
                                doctorName: doctor.doctorName,
                            }))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    }

    const firstDateOfWeek = (dateString) => {
        const date = new Date(dateString)
        const dayOfWeek = date.getDay() // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date)
        startDate.setDate(date.getDate() - dayOfWeek + 1) // Start from Monday

        return startDate
    }
    
    /**
     * 日期變更事件
     * @param {Event} e 
     */
    const handleDateOnChange = (e) => {
        if(stringIsEmpty(e.target.value)) 
        {
            return
        }
        const year = new Date(e.target.value).getFullYear();

        if(year > 9999)
        {
            return
        }

        const date = time2String(e.target.value, 'YYYY-MM-DD')
        //找日期當週的第一天
        if(firstDateOfWeek(date).getTime() !== firstDateOfWeek(queryInput.encounterDate).getTime()) {
            //重新搜尋doctor list div list
            getBaseDoctorAndDivision(date)
        }
        
        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        }

        setQueryInput(newQueryInput)

        //搜尋
        queryQueryClinicOfTheWeek(newQueryInput)
    }

    /**
     * 查詢當週班表
     * @param {Object} searchInput 查詢條件
     */
    const queryQueryClinicOfTheWeek = (searchInput) => {
        //切割字串
        const date = time2String(searchInput.encounterDate, 'YYYY-MM-DD 00:00:00')

        regsQueryClinicOfTheWeek({
            encounterDate: date,
            doctorNo: searchInput.doctorNo ? searchInput.doctorNo.split(' ')[0] : null,
            divNo: searchInput.divNo ? searchInput.divNo.split(' ')[0] : null
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) { 
                setQueryData(res.data)
            }
        })
    }

    const getBaseDoctorAndDivision = (date) => {
        regsQueryClinicOfTheWeek({
            encounterDate: time2String(date, 'YYYY-MM-DD 00:00:00')
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) { 
                setDivList(res.data.divList)
                setDoctorList(res.data.doctorList)

                const allClinic = [
                    ...res.data.monList,
                    ...res.data.tueList,
                    ...res.data.wedList,
                    ...res.data.thuList,
                    ...res.data.friList,
                    ...res.data.satList,
                    ...res.data.sunList
                ]
                setClinicList(allClinic)

            }
        })
    }

    /**
     * 診間點選事件
     */
    const handleClinicOnClick = (disabled, clinicData) => {
        if(disabled)
            return
        setSelectedClinic(clinicData)
    }

    const handleConfirmOnClick = () => {
        if(!objectIsEmpty(selectedClinic))
        {
            onSelectClinic(selectedClinic.encounterDate, selectedClinic)
        }
        closePopupButtonOnClick()
    }

    /**
     * 處理科別搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value

        // 設定科別選項
        setQueryInput({
            ...queryInput,
            divNo: value,
        })
    }

    /**
     * 處理科別搜尋文字框焦點事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDivOptions(true)
    }

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo
            // 科別名稱
            const name = option.divName
            // 設定科別選項
            setQueryInput({
                ...queryInput,
                divNo: `${no} ${name}`,
            })
        }

        setShowDivOptions(false)
    }

    /**
     * 處理醫師搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value
        // 設定醫師選項
        setQueryInput({
            ...queryInput, 
            doctorNo: value
        })
    }

    /**
     * 處理醫師搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorSearchTextOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorOptions(true)
    }

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.doctorNo
            // 醫師名稱
            const name = option.doctorName
            // 設定醫師選項
            setQueryInput({
                ...queryInput,
                doctorNo: `${no} ${name}`,
            })
        }

        setShowDoctorOptions(false)
    }

    /**
     * 查詢紐點擊事件
     */
    const handleQueryOnClick = () => {
         queryQueryClinicOfTheWeek(queryInput)
    }

    /**
     * 點擊前一週
     * @return {void}
     */
    const handlePreviousWeekOnClick = () => {
        const date = new Date(queryInput.encounterDate)
        const prevWeekDate = new Date(date)
        prevWeekDate.setDate(date.getDate() - 7)

        //重新搜尋doctor list div list
        getBaseDoctorAndDivision(prevWeekDate)

        const newQueryInput = {
            encounterDate: time2String(prevWeekDate, 'YYYY-MM-DD'),
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        }

        setQueryInput(newQueryInput)

        //搜尋
        queryQueryClinicOfTheWeek(newQueryInput)
    }

    /**
     * 點擊下一週
     * @return {void}
     */
    const handleNextWeekOnClick = () => {
        const date = new Date(queryInput.encounterDate)
        const nextWeekDate = new Date(date)
        nextWeekDate.setDate(date.getDate() + 7)

        //重新搜尋doctor list div list
        getBaseDoctorAndDivision(nextWeekDate)

        const newQueryInput = {
            encounterDate: time2String(nextWeekDate, 'YYYY-MM-DD'),
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        }

        setQueryInput(newQueryInput)

        //搜尋
        queryQueryClinicOfTheWeek(newQueryInput)
    }
    
    const renderClinicOfDayListByApn = (apn, clinicArray) => {
        //找出時段(apn)的班表
        let filteredClinics = arrayIsEmpty(clinicArray) ? null : clinicArray.filter(clinic => clinic.apn === Number(apn))
    
        //判斷是否被選擇
        const isSelected = (data) => {
            if(!objectIsEmpty(data) && !objectIsEmpty(selectedClinic)) {
                if(data.divNo !== selectedClinic.divNo || 
                    data.doctorNo !== selectedClinic.doctorNo ||
                    data.clinicNo !== selectedClinic.clinicNo ||
                    data.apn !== selectedClinic.apn || 
                    data.encounterDate !== selectedClinic.encounterDate) 
                {
                        return false
                }

                return true
            }
            return false
        }
        /**
         * 是否為可以掛號的時段
         */
        const isRegistrationPeriod = (clinic) => {
            const currentDate = new Date()
            const targetDate = new Date(clinic.encounterDate)
            const tmpCurrentDate = new Date(currentDate)

            if(fowardRegFlag) {
                //補輸情況下
                return true
            }
            tmpCurrentDate.setHours(0, 0, 0, 0)
            if(targetDate < tmpCurrentDate) {
                return false
            }

            if(clinic.stopClinicFlag) {
                //停診
                return false
            }

            
            if(Number(time2String(targetDate, "YYYYMMDD")) === Number(time2String(tmpCurrentDate, "YYYYMMDD"))) {
                //同一天
                if(currentDate.getHours() >= 21) {
                    //晚診最後時間後
                    return false
                }

                if(clinic.apn === 1) {
                    //早診時段
                    if(currentDate.getHours() >= 12) {
                        //超過中午12點
                        
                        return false
                    }
                }
                else if(clinic.apn === 2) {
                    //午診時段
                    if(currentDate.getHours() >= 17) {
                        //超過中午12點
                        return false
                    }
                }
            }

            if(clinic.regFullFlag) {
                //額滿
                return false
            }

            return true
        }

        const clinicItemBkColor = (disabled, clinic) => {
            if(disabled) {
                return "bg-[#d4d4d8]"
            }

            if(isSelected(clinic)) {
                return "bg-[#bee3f8]"
            }

            return "bg-[#fff]"
        }

        return (
          <ul className="border rounded-md min-w-[235px] w-[235px] max-w-[235px] h-full overflow-y-auto bg-[#fff]">
            {/*控制class去區分類別 額滿給予disable的class*/}
            {
              arrayIsEmpty(filteredClinics) ? (
                  /*如果沒有資料則顯示此區塊*/
                    <p className = {`flex items-center justify-center text-xl font-normal text-[#a1a1aa] h-full`}
                    >
                        沒有醫生看診 <br/>
                        請選其他時間
                    </p>
                ) : (
                  filteredClinics.map((clinic, index) => {

                    let disabled = false
                    disabled = !isRegistrationPeriod(clinic)
                    return (
                        <WeeklyScheduleItem 
                            key={index} 
                            record={clinic} 
                            disabled={disabled} 
                            bgColor={clinicItemBkColor(disabled, clinic)}
                            onClickItem={handleClinicOnClick}/>
                    )
                  }
                  )
                )
            }
          </ul>
        )
      }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        if(show) {
            const firstDate = time2String((data != null && data.date) ? data.date : new Date(), 'YYYY-MM-DD')
            const firstDoctorNo = (objectIsEmpty(data) || objectIsEmpty(data.doctorNo)) ? "" : data.doctorNo
            const firstDivNo = (objectIsEmpty(data) || objectIsEmpty(data.divNo)) ? "" : data.divNo
            const newQueryInput = {
                encounterDate: firstDate,
                //醫生
                doctorNo: firstDoctorNo,
                //科別
                divNo: firstDivNo,
            }

            setQueryInput(newQueryInput)

            setZonesetList((data && data.zonesetList) ? data.zonesetList : [])
            //取得醫師 科別基本資料
            getBaseDoctorAndDivision(firstDate)
            //查詢日班表資料
            queryQueryClinicOfTheWeek(newQueryInput)
        }
        else {
            setQueryData([])
        }

        setShowPopup(show)
    }, [show])

    useEffect(() => {
        const calculateWeekDates = (dateString) => {
            const date = new Date(dateString)
            const dayOfWeek = date.getDay() // 0 (Sunday) to 6 (Saturday)
            const startDate = new Date(date)
            startDate.setDate(date.getDate() - dayOfWeek + 1) // Start from Monday
      
            const dates = []
            for (let i = 0; i < 7; i++) {
              const current = new Date(startDate)
              current.setDate(startDate.getDate() + i)
              dates.push(time2String(current, "MM/DD")) // Convert to YYYY-MM-DD format
            }
            
            return dates
        }
        
        setWeekDates(calculateWeekDates(queryInput.encounterDate))
    }, [queryInput.encounterDate])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [queryInput.divNo])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [queryInput.doctorNo])

    return (
        showPopup?
        <div className="fixed top-[-16px] left-0 flex items-center justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10">
            <div className="max-w-[95vw] bg-white rounded-md">
                <div className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(#111111,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">當週班表_選擇掛號</h3>
                    <button
                        onClick={closePopupButtonOnClick}
                        className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                    >
                        {t('general.close')}
                    </button>
                </div>
                {/**內容 */}
                <div>
                    {/*filter篩選*/}
                    <div className="filter flex flex-row items-center justify-between px-6 mb-[10px]">
                        <div className="flex flex-row space-x-8">
                            <select className="w-[166px] h-10 pl-4 border border-[#D4D4D8] rounded-md">
                            {
                                zonesetList.map((zone, index) =>
                                    <option key={index} value={zone.zone}>{zone.zoneName}</option>
                                )
                            }
                            </select>
                            
                            <div className="flex flex-row space-x-5 items-center">
                                {/*科別*/}
                                <div className="flex flex-row items-center justify-start mr-4 space-x-2">
                                    <label>{t('general.department')}</label>
                                    <div ref={dropdownDepartmentRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3 ">
                                        <input
                                            className="w-[166px] h-10 pl-9 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                            value={queryInput.divNo}
                                            onChange={(e) => handleDepartmentSearchTextOnChange(e)}
                                            onFocus={handleDepartmentSearchTextOnFocus}/>
                                        <img
                                            className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                            alt="search-icon"
                                            src={searchIcon}
                                            role="button"/>
                                        {
                                            showDivOptions && !arrayIsEmpty(departmentOptionList) && (
                                            <div className="bg-white z-10 absolute -left-2 border border-[#d4d4d8] bg-white rounded-md top-10 w-[220px] flex flex-col">
                                                {/*科別下拉*/}
                                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                    {
                                                        departmentOptionList.map((div, index) =>
                                                            <div
                                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                role="button" key={index}
                                                                onClick={() => handleDepartmentOptionOnClick(div)}>

                                                                <p>{`${div.divNo}`}</p>
                                                                <p>{`${div.divName}`}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                                {/*醫師*/}
                                <div className="flex flex-row items-center justify-start mr-4 space-x-2">
                                    <label>{t('general.doctor')}</label>
                                    <div ref={dropdownDoctorRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3">
                                        <input
                                            className="w-[166px] h-10 pl-9 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                            value={queryInput.doctorNo}
                                            onChange={(e) => handleDoctorSearchTextOnChange(e)}
                                            onFocus={handleDoctorSearchTextOnFocus}
                                        />
                                        <img
                                            className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                            alt="search-icon"
                                            src={searchIcon}
                                            role="button"/>
                                        {
                                            showDoctorOptions && !arrayIsEmpty(doctorOptionList) &&
                                            <div className="bg-white z-10 absolute -left-2 border border-[#d4d4d8] bg-white rounded-md top-10 w-[220px] flex flex-col">
                                                {/*醫師下拉*/}
                                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                    {
                                                        doctorOptionList.map((doctor, index) =>
                                                            <div
                                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                role="button" key={index}
                                                                onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                                <p className="">{`${doctor.doctorNo}`}</p>
                                                                <p>{`${doctor.doctorName}`}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <button 
                                className="flex items-center justify-center px-4 h-10 mr-10 rounded-md bg-[#2B6CB0] text-white"
                                onClick={handleQueryOnClick}>
                                {t('general.query')}
                            </button>
                        </div>

                        <BaseInput
                            className="w-[168px] h-10 pl-4 border border-[#D4D4D8] rounded-md mr-2"
                            type={InputTypeEnum.Date}
                            inputMode="date"
                            value={queryInput.encounterDate}
                            onChange={(e) => handleDateOnChange(e)}/>

                    </div>

                    {/*周班Content*/}
                    <div className="flex flex-col overflow-x-auto px-[16px] overflow-y-auto max-h-[calc(80vh-100px)] pt-[10px]">
                        {/** 區塊 - 日期 */}
                        <div className="w-full py-1 pl-[72px] pr-[16px] mb-[7px]">
                            <div className="w-full min-w-[1457px] flex flex-row gap-2.5">
                                <div className="w-full min-w-[233px] flex justify-center relative">
                                    <p>{weekDates[0]}(一)</p>
                                    <button 
                                        className="absolute -left-10 -top-2.5 h-10 px-4 mr-2 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[4px]"
                                        onClick={handlePreviousWeekOnClick}>
                                        {"< 前一週"}
                                    </button>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[1]}(二)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[2]}(三)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[3]}(四)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[4]}(五)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[5]}(六)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center relative">
                                    <p className="absolute left-[calc(50%-7px)] top-1/2 -translate-y-1/2 -translate-x-1/2">{weekDates[6]}(日)</p>
                                    <button 
                                        className="absolute -right-5 -top-2.5 h-10 px-4 mr-2 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[4px]"
                                        onClick={handleNextWeekOnClick}>
                                        {"下一週 >"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/** 區塊 - 早 */}
                        <div className="w-full min-w-[1688px] h-[272px] min-h-[272px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 flex flex-row justify-center items-center px-1">
                                <div className="w-10 text-base">
                                    <p className="w-12 text-center">
                                        早
                                    </p>
                                    <p className="w-12 text-center">08:30</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">12:00</p>
                                </div>
                            </div>

                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.monList)) ? null : queryData.monList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.tueList)) ? null : queryData.tueList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.wedList)) ? null : queryData.wedList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.thuList)) ? null : queryData.thuList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.friList)) ? null : queryData.friList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.satList)) ? null : queryData.satList)
                            }
                            {
                                renderClinicOfDayListByApn(1, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.sunList)) ? null : queryData.sunList)
                            }

                            <div className="h-full flex items-center">
                                <div
                                    className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                            </div>
                        </div>

                        {/** 區塊 - 午 */}
                        <div className="w-full min-w-[1688px] h-[272px] min-h-[272px] bg-customYellow flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                                <div className="w-10 h-24 text-base">
                                    <p className="w-12 text-center">
                                        午
                                    </p>
                                    <p className="w-12 text-center">13:30</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">17:00</p>
                                </div>
                            </div>
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.monList)) ? null : queryData.monList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.tueList)) ? null : queryData.tueList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.wedList)) ? null : queryData.wedList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.thuList)) ? null : queryData.thuList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.friList)) ? null : queryData.friList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.satList)) ? null : queryData.satList)
                            }
                            {
                                renderClinicOfDayListByApn(2, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.sunList)) ? null : queryData.sunList)
                            }

                            <div className="h-full flex items-center">
                                <div
                                    className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                            </div>
                        </div>

                        {/** 區塊 - 晚 */}
                        <div className="w-full min-w-[1688px] h-[272px] min-h-[272px] bg-[#FBF5FF] flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                                <div className="w-10 h-24 text-base">
                                    <p className="w-12 text-center">
                                        晚
                                    </p>
                                    <p className="w-12 text-center">18:00</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">21:00</p>
                                </div>
                            </div>
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.monList)) ? null : queryData.monList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.tueList)) ? null : queryData.tueList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.wedList)) ? null : queryData.wedList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.thuList)) ? null : queryData.thuList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.friList)) ? null : queryData.friList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.satList)) ? null : queryData.satList)
                            }
                            {
                                renderClinicOfDayListByApn(3, (objectIsEmpty(queryData) || arrayIsEmpty(queryData.sunList)) ? null : queryData.sunList)
                            }

                            <div className="h-full flex items-center">
                                <div
                                    className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-end p-[16px]">
                        <button
                            onClick={handleConfirmOnClick}
                            className="px-4 h-10 rounded-md bg-[#2B6CB0] text-white"
                        >
                            {t('general.confirm')}
                        </button>
                    </div>
                </div>
            </div>
        </div> :
        <></>
    )
}

export default WeeklySchedule;