//Import icons
import SearchIcon from "../../assets/images/search-interface-symbol.png"
import {ReactComponent as PdfJsonIcon} from '../../assets/images/ESSI/pdf-json.svg'
import {ReactComponent as SignInfoIcon} from '../../assets/images/ESSI/sign-info.svg'
import {ReactComponent as VersionInfoIcon} from '../../assets/images/ESSI/version-info.svg'

//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    createDateRange2String,
    fuzzySearchObjects,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    UserRoleEnum
} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import PdfJsonPopup from "../../components/ESSI/PdfJsonPopup"
import SignInfoPopup from "../../components/ESSI/SignInfoPopup"
import VersionInfoPopup from "../../components/ESSI/VersionInfoPopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiQueryEmrMaster} from "../../api/v1/ESSI"
import {BaseInput} from "../../components/Input/BaseInput"

/**
 * 電子病歷查詢及驗章
 * @return {JSX.Element}
 */
const EmrMaster = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    //建立時間起迄
    const [startDate, setStartDate] = useState(null)

    //建立時間迄
    const [endDate, setEndDate] = useState(null)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    //是否顯示PDF/JSON彈窗
    const [showPdfJsonPopup, setShowPdfJsonPopup] = useState(false)

    //是否顯示簽章資訊SignInfoPopup彈窗
    const [showSignInfoPopup, setShowSignInfoPopup] = useState(false)

    //是否顯示版次彈窗
    const [showVersionInfoPopup, setShowVersionInfoPopup] = useState(false)

    //簽章資訊SignInfoPopup data
    const [detailData, setDetailData] = useState([])

    // 初始資料
    const [data, setData] = useState([])

    //建立人員
    const [user, setUser] = useState("")

    // 是否顯示人員代號選項
    const [showUserDropdownOptions, setShowUserDropdownOptions] = useState(false)

    // 所有人員代號選項
    const [users, setUsers] = useState([])

    // 該筆點選資料
    const [rowData, setRowData] = useState([])

    // PDF/JSON文件內容
    const [docContent, setDocContent] = useState(false)

    //ref 用於指向人員代下拉菜單元素
    const doctorDropdownRef = useRef(null)

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    //當前使用者代號
    const [currentUserNo, setCurrentUserNo] = useState()

    // role角色>用於判斷是否可以查看他人資料
    const [isSysMaintain, setIsSysMaintain] = useState(false)

    /**
     * 處理人員搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorSearchTextOnFocus = () => setShowUserDropdownOptions(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setUser(`${option.userNo} ${option.userName}`)
        }
        // 關閉醫師下拉選單
        setShowUserDropdownOptions(false)
    }

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSelectUserOnChange = (e) => setUser(e.target.value)

    /**
     * 取得過濾後的人員代號選項
     * @return {Array<Object>}
     */
    const getFilterUser = () =>
        // 醫生是否有空格
        user.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(users, user.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(users, user)

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {NodeJS.Timeout}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 時間開始改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間結束改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 點選PDF JSON按鈕事件
     * @param data {Object} 物件
     * @return {void}
     */
    const handleCancelPdfJsonOnClick = (data) => {
        setShowPdfJsonPopup(true)
        setRowData(data)
        // setDocContent()
    }

    /**
     * PDF JSON彈窗關閉事件
     * @return {void}
     */
    const handlePdfJsonPopupOnClose = () => setShowPdfJsonPopup(false)

    /**
     * 點選版次紀錄versionInfo按鈕事件call API
     * @param data {Object} 物件
     * @return {void}
     */
    const handleCancelVersionInfoOnClick = (data) => {
        const arr = []
        for (let i = 0; i < 20; i++) {
            arr.push({
                transrecId: i,
                transDocId: "123456789",
                batchNo: "batchNo",
                transFromSys: "NIS",
                emrRpttype: "09876",
                emrTitle: "給藥紀錄單",
                patientId: "E111111111",
                patientName: "陳XX",
                encounterId: "encounterId",
                encounterDate: "encounterDate",
                inpOpd: "門診",
                zone: "zone",
                searchFields: "searchFields",
                createDatetime: "2024/5/31 15:00:00" + i,
                createUserNo: "王OO",
                createFromIp: "createFromIp",
                createFromMemo: "createFromMemo",
                signUserNo1: "signUserNo1",
                signDatetime1: "signDatetime1",
                signPcName1: "signPcName1",
                signPcIp1: "signPcIp1",
                signUserNo2: "signUserNo2",
                signDatetime2: "signDatetime2",
                signPcName2: "signPcName2",
                signPcIp2: "signPcIp2",
                transStatus: "00",
                actionCode: "actionCode",
                transMemo: "test",
                sensitiveCode: "sensitiveCode",
                contentType: "PDF",
                contentLocation: "contentLocation",
            })
        }
        setShowVersionInfoPopup(true)
        setDetailData(arr)
    }

    /**
     * 版次紀錄VersionInfoPopup彈窗關閉事件
     * @return {void}
     */
    const handleVersionInfoPopupOnClose = () => setShowVersionInfoPopup(false)

    /**
     * 點選簽章資訊SignInfoPopup按鈕事件call API
     * @param data {Object} 物件
     * @return {void}
     */
    const handleSignInfoOnClick = (data) => {
    }

    /**
     * 簽章資訊SignInfoPopup彈窗關閉事件
     * @return {void}
     */
    const handleSignInfoPopupOnClose = () => setShowSignInfoPopup(false)

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleSearchButtonOnClick = () => getQueryEmrMaster()

    /**
     * 取得進階搜尋後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定人員代號選項
                setUsers(data)
            }
        })
    }

    /**
     * 查詢電子病歷查詢及驗章
     * @return {void}
     */
    const getQueryEmrMaster = () => {
        // 正規表示式是否包含一個空白
        const regexWithSpace = /^[^\s]*\s[^\s]*$/
        // 取得Doctor是否有單個空格
        const hasSpace = regexWithSpace.test(user)
        // 取得醫生代號
        const doctorNo = isSysMaintain ?
            // 系統維護人員才有辦法取得所有醫生名單
            (hasSpace ? user.split(' ')[0] : user) :
            // 當前使用者代號
            currentUserNo

        essiQueryEmrMaster({
            // 使用者代號
            createUser: doctorNo,
            //建立時間
            createStartDate: `${startDate} 00:00:00`,
            // 結束時間
            createEndDate: `${endDate} 23:59:59`,
            //當前分頁
            pageNum: currentPage,
            //每頁資料筆數
            pageSize: pageSize,
        }).then(res => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(res.data)
            }
        })
    }

    /**
     * 第一次進入時去新增測試資料
     * @return {void}
     */
    useMemo(() => {
        // 取得當前使用者
        const currentUserNo = localStorage.getItem('userno')
        // 設定當前使用者
        setCurrentUserNo(currentUserNo)
        //取得Role資訊
        const roles = JSON.parse(getLocalStorage('role'))
        // 判斷是系統維護人員
        const hasSysMaintain = roles.some(role => role.roleNo === UserRoleEnum.SysMaintain)
        // 設定使用者角色
        setIsSysMaintain(hasSysMaintain)
        // 系統維護人員才有辦法取得所有醫生名單
        if (hasSysMaintain) {
            // 取得所有醫生
            getQueryAllDoctor()
        }
        //取得日期範圍
        const dateRange = createDateRange2String("", "", 2)
        // 設定起始時間
        setStartDate(dateRange[0])
        // 設定結束時間
        setEndDate(dateRange[1])
    }, [])

    /**
     * 當前起始時間或結束時間改變時
     * @return {void}
     */
    useEffect(() => {
        // 當前使用者編號 / 起始時間 / 結束時間不為空
        if (!stringIsEmpty(currentUserNo) && !stringIsEmpty(startDate) && !stringIsEmpty(endDate)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                // 查詢電子病歷查詢及驗章
                getQueryEmrMaster()
                // 設定已經取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [currentUserNo, startDate, endDate])

    /**
     * 處理選擇人員代號變更事件
     * @param obj {Object} 物件
     * @param obj.ref {React.RefObject} ref
     * @param obj.handler {Function} target
     */
    useOutsideClick({
        ref: doctorDropdownRef,
        handler: () => setShowUserDropdownOptions(false),
    })

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div>
                {/* <RadioButton mode={mode} modeChecked="department" handleModeChange={handleModeChange} label="24小時內未簽名單"/> */}
            </div>
            <div className="filterGroup flex flex-row flex-wrap items-center justify-between">
                <div className="flex flex-row flex-wrap items-center">
                    <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                        <p className="mr-4 w-[100px]">建立人員</p>
                        <div className="flex flex-row space-x-2 items-center relative w-full"
                             ref={doctorDropdownRef}>
                            <input
                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 pl-9"
                                value={user}
                                disabled={!isSysMaintain}
                                onChange={(e) => handleSelectUserOnChange(e)}
                                onFocus={handleDoctorSearchTextOnFocus}/>
                            <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                 src={SearchIcon} role="button"/>
                            {
                                // 建立人員選項存在且為系統維護人員
                                showUserDropdownOptions && isSysMaintain && (
                                    <div
                                        className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                getFilterUser().map((user, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleUserOptionOnClick(user)}>
                                                        <p>{`${user.userNo} ${user.userName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="date flex flex-row items-center justify-start mb-2 mr-2">
                        <p className="mr-4">開始日期起訖</p>
                        {/*開始時間*/}
                        <BaseInput inputMode="date"
                                   className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   value={startDate} type="date" onChange={handleStartDateOnChange}/>
                        <p className="m-1">~</p>
                        {/*終止時間*/}
                        <BaseInput inputMode="date"
                                   className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   value={endDate} type="date" onChange={handleEndDateOnChange}/>
                    </div>
                    <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                        {/*查詢按鈕*/}
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleSearchButtonOnClick}>
                            {t('general.query')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                <div className="flex flex-row items-center justify-between mb-2">
                    {/*進階搜尋*/}
                    <div className='flex flex-row relative'>
                        <BaseInput inputMode="search"
                                   className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   type="text" value={advancedSearchText} placeholder={t('general.advancedSearch')}
                                   onChange={handleAdvancedSearchTextOnChange}/>
                    </div>
                </div>
                <div className='w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[65vh]'>
                    <table
                        className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`w-[300px] ${tableElementDefaultType}`}>操作</th>
                            <th className={`${tableElementDefaultType}`}>單據類別 <br/> 表單抬頭</th>
                            <th className={`${tableElementDefaultType}`}>門/急/住 <br/> 病人資訊</th>
                            <th className={`${tableElementDefaultType}`}>狀態</th>
                            <th className={`${tableElementDefaultType}`}>表單代號<br/> 建立人員/日期</th>
                            <th className={`${tableElementDefaultType}`}>處理狀態<br/>說明</th>
                            <th className={`${tableElementDefaultType}`}>內容<br/>處理明細</th>
                            <th className={`${tableElementDefaultType}`}>Trace Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((data, index) =>
                                <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                    key={index}>

                                    <td className={`${tableElementDefaultType}`}>
                                        <div className=' flex flex-row'>
                                            <button onClick={() => handleCancelPdfJsonOnClick(data)}
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                <PdfJsonIcon className="mr-1"/>閱讀
                                            </button>
                                            <button onClick={() => handleSignInfoOnClick(data)}
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                <SignInfoIcon className="mr-1"/>簽章資訊
                                            </button>
                                            <button onClick={() => handleCancelVersionInfoOnClick(data)}
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                <VersionInfoIcon className="mr-1"/>版次
                                            </button>
                                        </div>
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.emrRpttype ? data.emrRpttype : ""}<br/>{data.emrTitle ? data.emrTitle : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.inpOpd ? data.inpOpd : ""}<br/>{data.patientName ? data.patientName : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.transStatus ? data.transStatus : ""}</td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.formCode ? data.formCode : ""}<br/>{data.createUser ? data.createUser : ""}{data.createDatetime ? data.createDatetime : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.fixStatue ? data.fixStatue : ""}<br/>{data.memo ? data.memo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.contentType ? data.contentType : ""}<br/>{data.transMemo ? data.transMemo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.transDocId ? data.transDocId : ""}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                {/*分頁*/}
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={currentPage} currentPage={currentPage} pageSize={pageSize}
                                totalSize={totalSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPreviousOnClick={onPaginationPreviousOnClick}
                                onNextOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}/>
                </div>
            </div>
            <PdfJsonPopup
                show={showPdfJsonPopup}
                docContent={docContent}
                rowData={rowData}
                closePopupButtonOnClick={handlePdfJsonPopupOnClose}/>
            <SignInfoPopup
                detailData={detailData}
                show={showSignInfoPopup}
                closePopupButtonOnClick={handleSignInfoPopupOnClose}/>
            <VersionInfoPopup
                versionInfoData={detailData}
                show={showVersionInfoPopup}
                closePopupButtonOnClick={handleVersionInfoPopupOnClose}/>
        </div>
    )
}

export default EmrMaster
