import React, { useEffect, useState } from 'react'
import RadioButton from '../../Input/RadioButton'
import {
    ApiErrorStatusEnum,
    objectIsEmpty,
    time2String,
    ToastTypeEnum,
} from 'edah_utils/dist'
import {
    queryTranTypeByEncounterId,
    trancaseQueryTranInByEncounterId,
} from '../../../api/v1/Rmis'
import { BaseInput } from '../../Input/BaseInput'
import { DATE_FORMAT } from '../../../constants/common'
import ReferralHospitalModal from '../ReferralHospitalModal'
import useToast from '../../../hooks/useToast'
import { t } from 'i18next'
import ReferralListModal from '../../../pages/ReferralSystem/ReferralListModal'
import { useSelector } from 'react-redux'
import store from '../../../redux/store'
import {
    completePrintMode,
    updatePrintContent,
} from '../../../redux/Slice/printSlice'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import {getBirthDayAndDetailedAge} from "../utils";

/**
 * 轉入原因: 6.其他
 */
const TRAN_PURPOSE_IN_OTHERS = '6'

/**
 * 轉入單個管作業_維護彈窗
 * @param {object} props
 * @param {object} props.info  資訊
 * @param {function} props.onClose 關閉方法
 * @param {function} props.onConfirm 存檔方法
 * @param {array} props.inpOpdList 轉入診別下拉選單
 * @param {array} props.inTrandataAgreeFlagList 轉診資料同意書下拉選單
 * @param {array} props.tranPurposeInList 轉入原因下拉選單
 * @param {array} props.inReplyStatusList 外院回覆狀態清單
 * @param {array} props.zipCodeList 院所查詢高雄地區下拉選單
 * @return {JSX.Element}
 */
const EditTranInModal = ({
    info,
    onClose,
    onConfirm,
    inpOpdList = [],
    inTrandataAgreeFlagList = [],
    tranPurposeInList = [],
    // divisionList = [],
    // doctorList = [],
    inReplyStatusList = [],
    inUnprintSummaryReasonList = [],
    inTranPaperReasonList = [],
    zipCodeList = [],
}) => {
    const defaultCaseDetail = {
        // 刪除此筆資料
        cancelFlag: false,
        // 就醫日期
        encounterDate: '',
        // TODO: 批價序號:門住資料待第二階段
        // payFeeNo: '',
        // 病歷號碼
        patientId: '',
        // 姓名
        patientName: '',
        // 性別
        genderName: '',
        // 生日
        birthDate: '',
        // 身分證號
        idNo: '',
        // 看診科別代碼
        divNo: '',
        // 看診科別
        divName: '',
        // 看診醫師
        doctorName: '',
        // 看診醫師代碼
        doctorNo: '',
        // 回覆單填寫
        inTraninCompleteFlag: false,
        // 病摘完成
        inSummaryCompleteFlag: false,
        // 診斷碼
        treatmentCode: '',
        treatmentName: '',
        treatmentCode1: '',
        treatmentName1: '',
        treatmentCode2: '',
        treatmentName2: '',
        // 轉入院所
        hospNoIn: '',
        // 轉入院所
        hospNameIn: '',
        // 轉入院所層
        hospGradeName: '',
        // 預住院單對應的就醫號
        inpEncounterId: '',
        // 住院日期
        admissionDate: '',
        // 預住院區塊的出院日期
        inpDischargeDate: '',
        // 住院醫生
        inpDoctorName: '',
        // 住院科別代碼
        inpDivNo: '',
        // 住院科別
        inpDivName: '',
        // 住院床位
        inpBedNo: '',
        // 轉入診別
        inpOpd: '',
        // 轉診資料同意書 0.拒絕/1.同意
        inTrandataAgreeFlag: '1',
        // 收到轉診單日期
        tranInReceiveDate: '',
        // 轉入原因待規劃
        tranPurposeIn: '',
        // 床號
        bedNo: '',
        // 出院日期
        dischargeDate: '',
        // 轉介回函回覆日期
        inReceiptDate: '',
        // 病摘回覆日期
        inSummaryReplyDate: '',
        // 回覆單回收日期
        inRecoveryDate: '',
        // 無法列印病摘原因
        inUnprintSummaryReason: '',
        // 對方醫院開單日期
        inOrderDate: '',
        // 收到外院回覆單日期
        inReplyDate: '',
        // 外院回覆狀態
        inReplyStatus: '',
        // 有無轉診單
        inTranPaperFlag: '',
        // 轉診單回覆日期
        replyDate: '',
        // 無轉診單原因
        inTranPaperReason: '',
        // 特殊報告回覆日期
        inSpecialReplyDate: '',
        // 癌症篩檢
        inCancerFlag: false,
        // 備註
        inRemark: '',
        // 轉入原因
        // tranPurposeIn: '',
        // 轉入轉診目(tranPurposeln)值為6時，讓使用登打備註
        tranPurposeRemarkIn: '',
        // 電子轉診單單號
        etranNo: '',
    }
    //資料
    const [caseDetail, setCaseDetail] = useState(defaultCaseDetail)

    // 補列印回覆單 - 列印數量
    const [printSetting, setPrintSetting] = useState({
        copies: 1,
        couplet: 2,
    })

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralListModal, setIsShowReferralListModal] =
        useState(false)

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    // toast message
    const showToast = useToast()

    /**
     * 更新轉入單個管資料
     * @param {event} e
     * @param {string} field
     */
    const updateCaseDetail = (e, field) => {
        const value =
            field === 'cancelFlag' || field === 'inCancerFlag'
                ? e.target.checked
                : e.target.value
        if (field === 'tranPurposeIn' && value !== TRAN_PURPOSE_IN_OTHERS) {
            setCaseDetail((prev) => ({
                ...prev,
                tranPurposeRemarkIn: '',
            }))
        }
        setCaseDetail((prev) => ({
            ...prev,
            [field]: value,
        }))
    }

    /**
     * 更新有無轉診單 radio button
     * @param {boolean} hasInTranPaper
     */
    const updateInTranPaperFlag = (hasInTranPaper) => {
        setCaseDetail({
            ...caseDetail,
            inTranPaperFlag: hasInTranPaper,
        })
    }

    /**
     * 轉換日期格式讓 datepicker 能夠顯示
     * @param {string|null} value
     * @returns 日期 YYYY-MM-DD
     */
    const generateDate = (datetime) => {
        return datetime ? time2String(datetime, DATE_FORMAT) : ''
    }

    /**
     * 查詢轉入單資料(單筆)
     * @return {void}
     */
    const getTranCaseQueryTranInByEncounterId = () => {
        trancaseQueryTranInByEncounterId({
            //就醫號
            encounterId: info.encounterId,
            //就醫日期
            encounterDate: info.encounterDate,
            //門住類別(O門診/E急診/I住院)
            inpOpd: info.inpOpd,
            //病歷號碼
            patientId: info.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const { data } = res
                const newData = {}
                Object.keys(data).forEach((key) => {
                    switch (key) {
                        case 'cancelFlag':
                        case 'inTranPaperFlag':
                        case 'inSummaryCompleteFlag':
                        case 'inTraninCompleteFlag':
                        case 'inCancerFlag':
                            newData[key] = !!data[key]
                            break
                        case 'inTrandataAgreeFlag':
                            newData[key] = data[key] ? '1' : '0'
                            break
                        case 'replyDate':
                        case 'inReceiptDate':
                        case 'inRecoveryDate':
                        case 'inReplyDate':
                        case 'inOrderDate':
                        case 'inSpecialReplyDate':
                        case 'inSummaryReplyDate':
                        case 'dischargeDate':
                        case 'tranInReceiveDate':
                            newData[key] = generateDate(data[key])
                            break
                        default:
                            newData[key] = data[key] || ''
                    }
                })
                setCaseDetail(newData)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 開啟院所查詢視窗
     */
    const showReferralHospital = () => {
        setIsShowReferralHospitalModal(true)
    }

    /**
     * 選擇院所
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setCaseDetail({
            ...caseDetail,
            hospNoIn: hospital.hospNo,
            hospNameIn: hospital.hospName,
            hospGradeName: hospital.hospGradeName,
        })
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 模糊搜尋選單-選擇的看診醫師
     * @param {object} doctor
     */
    const selectDoctor = (doctor) => {
        setCaseDetail((prev) => ({
            ...prev,
            doctorNo: doctor ? doctor.doctorNo : '',
            doctorName: doctor ? doctor.doctorName : '',
        }))
    }

    /**
     * 確認選擇電子轉診單號
     * @param {string}} etranNo 電子轉診單號
     */
    const handleSelectEtranNo = (etranNo) => {
        setCaseDetail({
            ...caseDetail,
            etranNo,
        })
        setIsShowReferralListModal(false)
    }

    /**
     * 轉入單列印
     * @param value
     */
    const handleTranInForm = (value) => {
        queryTranTypeByEncounterId({
            encounterId: info.encounterId,
            encounterDate: info.encounterDate,
            inpOpd: info.inpOpd,
            patientId: info.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const printData = res.data
                store.dispatch(
                    updatePrintContent({
                        reportType: FORM_TYPE.tranInForm,
                        printData,
                    })
                )
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 監聽新資料進來時呼叫查詢轉入單資料(單筆)
     */
    useEffect(() => {
        //確保資料不為空
        if (!objectIsEmpty(info)) {
            getTranCaseQueryTranInByEncounterId()
        }
    }, [info])

    return (
        <div className="fixed top-0 left-0 pt-[40px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10 overflow-auto">
            <div className={`w-[90%] bg-white rounded-[6px]`}>
                <div className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    <h3 className="text-[20px]">轉入單個管作業_維護</h3>
                    <button
                        className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                        onClick={onClose}
                    >
                        {t('general.close')}
                    </button>
                </div>
                <div className="flex justify-end items-center">
                    <div className="bg-[#F4F4F5] flex items-center p-1 mr-2 rounded-[6px]">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handleTranInForm}
                        >
                            補列印回覆單
                        </button>
                        <div>
                            <input
                                type="number"
                                className="w-[50px] mx-2 px-2 py-1 rounded-[6px]"
                                value={printSetting.copies}
                                onChange={(e) =>
                                    setPrintSetting({
                                        ...printSetting,
                                        copies: e.target.value,
                                    })
                                }
                            />
                            份
                            <input
                                type="number"
                                className="w-[50px] mx-2 px-2 py-1 rounded-[6px]"
                                value={printSetting.couplet}
                                onChange={(e) =>
                                    setPrintSetting({
                                        ...printSetting,
                                        couplet: e.target.value,
                                    })
                                }
                            />
                            聯
                        </div>
                    </div>
                    <div className="flex">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={() => setIsShowReferralListModal(true)}
                        >
                            帶入電子轉診單
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2">
                            留言系統
                        </button>
                        <div className="border-l-2 h-[30px] self-center"></div>
                        <input
                            style={{ accentColor: '#38A169' }}
                            id="cancelFlag"
                            name="cancelFlag"
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5 ml-2"
                            type="checkbox"
                            checked={caseDetail.cancelFlag}
                            onChange={(e) => updateCaseDetail(e, 'cancelFlag')}
                        />
                        <label
                            htmlFor="cancelFlag"
                            className="flex items-center justify-center font-bold px-3 h-10 rounded-[6px] mr-2 text-[#007CDB]"
                        >
                            刪除此筆資料
                        </label>
                    </div>
                </div>
                <div className="p-3">
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                就醫日期
                            </div>
                            <div>
                                {time2String(
                                    caseDetail.encounterDate,
                                    DATE_FORMAT
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                批價序號
                            </div>
                            <div>
                                {/* TODO:門/住資料是第二階段 */}
                                {/* {caseDetail.payFeeNo} */}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                {t('general.patientNo')}
                            </div>
                            <div>{caseDetail.patientId}</div>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                {t('general.username')}
                            </div>
                            <div>{caseDetail.patientName}</div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                {t('general.gender.name')}
                            </div>
                            <div>{caseDetail.genderName}</div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                {t('general.birthday')}
                            </div>
                            <div>
                                {getBirthDayAndDetailedAge(caseDetail.birthDate)}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                {t('general.idNumber')}
                            </div>
                            <div>{caseDetail.idNo}</div>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                看診科別
                            </div>
                            <div className="w-[calc(100%-160px)] text-left">
                                {`${caseDetail.divNo}${caseDetail.divName}`}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                看診醫師
                            </div>
                            <div className="w-[calc(100%-160px)] text-left">
                                {`${caseDetail.doctorNo}${caseDetail.doctorName}`}
                            </div>
                        </div>
                        <div className="row-span-2 flex">
                            <div className="flex flex-row items-center justify-start">
                                <input
                                    type="checkbox"
                                    style={{ accentColor: '#38A169' }}
                                    id="inTraninCompleteFlag"
                                    name="inTraninCompleteFlag"
                                    className="mr-2 bg-green-400 checked:bg-green-600 text-green-6002"
                                    checked={caseDetail.inTraninCompleteFlag}
                                    readOnly
                                />
                                <label
                                    className="w-[160px] mr-4 text-left"
                                    htmlFor="inTraninCompleteFlag"
                                >
                                    回覆單填寫
                                </label>
                            </div>
                            <div className="flex flex-row items-center justify-start">
                                <input
                                    type="checkbox"
                                    style={{ accentColor: '#38A169' }}
                                    id="inSummaryCompleteFlag"
                                    name="inSummaryCompleteFlag"
                                    className="mr-2 bg-green-400 checked:bg-green-600 text-green-600"
                                    checked={caseDetail.inSummaryCompleteFlag}
                                    readOnly
                                />
                                <label
                                    className="w-[160px] mr-4 text-left"
                                    htmlFor="inSummaryCompleteFlag"
                                >
                                    病摘完成
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="w-[160px] mr-4 text-right col-span-1 row-span-3">
                            診斷碼
                        </div>
                        <div className="w-[calc(100%-160px)]">
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName')
                                    }
                                    disabled
                                />
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode1}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode1')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName1}
                                    disabled
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName1')
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode2}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode2')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName2}
                                    disabled
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName2')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="w-[160px] mr-4 text-right col-span-1 row-span-3">
                            轉入院所
                        </div>
                        <div className="grid grid-cols-5 gap-2 flex-1">
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                type="text"
                                value={caseDetail.hospNoIn}
                                disabled
                            />
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                type="text"
                                value={caseDetail.hospNameIn}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                轉入院所層
                            </div>
                            <div className="grid grid-cols-5 gap-2 flex-1">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4"
                                    type="text"
                                    value={caseDetail.hospGradeName}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full border-b-2 my-4"></div>
                    <div className="flex flex-row items-center justify-start mb-4">
                        <div className="w-[200px] mr-4 text-right">
                            預住院單對應的就醫號
                        </div>
                        <input
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type="text"
                            value={caseDetail.inpEncounterId}
                            onChange={(e) =>
                                updateCaseDetail(e, 'inpEncounterId')
                            }
                        />
                    </div>
                    <div className="w-[80%] m-auto">
                        <div className="grid grid-cols-4 mb-4 gap-2">
                            <div className="flex">
                                <div className="mr-4 text-right w-[100px]">
                                    住院日期
                                </div>
                                <div>{caseDetail.admissionDate}</div>
                            </div>
                            <div className="flex">
                                <div className="mr-4 text-right w-[100px]">
                                    出院日期
                                </div>
                                <div>{caseDetail.inpDischargeDate}</div>
                            </div>
                            <div className="flex">
                                <div className="mr-4 text-right w-[100px]">
                                    住院醫生
                                </div>
                                <div>{caseDetail.inpDoctorName}</div>
                            </div>
                        </div>

                        <div className="grid grid-cols-4 mb-4 gap-2">
                            <div className="flex">
                                <div className="mr-4 text-right w-[100px]">
                                    住院科別
                                </div>
                                <div>
                                    {caseDetail.inpDivNo || ''}
                                    {caseDetail.inpDivName || ''}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="mr-4 text-right w-[100px]">
                                    住院床位
                                </div>
                                <div>{caseDetail.inpBedNo}</div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b-2 my-4"></div>
                    <div className="grid grid-cols-4 gap-2 mb-4">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                轉入診別
                            </div>
                            <select
                                className="w-[calc(100%-160px)] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                value={caseDetail.inpOpd}
                                onChange={(e) => updateCaseDetail(e, 'inpOpd')}
                            >
                                <option value=""></option>
                                {inpOpdList.map((item) => (
                                    <option
                                        key={item.inInpOpd}
                                        value={item.inInpOpd}
                                    >
                                        {item.inInpOpdName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                轉診資料同意書
                            </div>
                            <select
                                className="w-[calc(100%-160px)] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inTrandataAgreeFlag')
                                }
                                value={caseDetail.inTrandataAgreeFlag}
                            >
                                <option value=""></option>
                                {inTrandataAgreeFlagList.map((item) => (
                                    <option
                                        key={item.inTrandataAgreeFlagName}
                                        value={item.inTrandataAgreeFlag}
                                    >
                                        {item.inTrandataAgreeFlagName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                收到轉診單日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.tranInReceiveDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'tranInReceiveDate')
                                }
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 mb-4">
                        <div className="flex items-center">
                            <div className="w-[160px] mr-4 text-right">
                                轉入原因
                            </div>
                            <select
                                className="w-[calc(100%-160px)] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                name="tranPurposeIn"
                                id="tranPurposeIn"
                                value={caseDetail.tranPurposeIn}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'tranPurposeIn')
                                }
                            >
                                <option value=""></option>
                                {tranPurposeInList.map((item) => (
                                    <option
                                        key={item.tranPurposeIn}
                                        value={item.tranPurposeIn}
                                    >
                                        {item.tranPurposeRemarkIn}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {caseDetail.tranPurposeIn === TRAN_PURPOSE_IN_OTHERS ? (
                            <div className="flex items-center col-span-2">
                                <div className="w-[160px] mr-4 text-right">
                                    轉入原因備註
                                </div>
                                <input
                                    className="w-[calc(100%-160px)] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type="text"
                                    value={caseDetail.tranPurposeRemarkIn}
                                    onChange={(e) =>
                                        updateCaseDetail(
                                            e,
                                            'tranPurposeRemarkIn'
                                        )
                                    }
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                床號
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                value={caseDetail.bedNo}
                                onChange={(e) => updateCaseDetail(e, 'bedNo')}
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                出院日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.dischargeDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'dischargeDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                轉介回函回覆日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inReceiptDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inReceiptDate')
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                病摘回覆日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inSummaryReplyDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inSummaryReplyDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                回覆單回收日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inRecoveryDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inRecoveryDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                電子轉診單號
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                value={caseDetail.etranNo}
                                onChange={(e) => updateCaseDetail(e, 'etranNo')}
                            />
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                無法列印病摘原因
                            </div>
                            <select
                                className="w-[calc(100%-160px)] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                name="inUnprintSummaryReason"
                                id="inUnprintSummaryReason"
                                value={caseDetail.inUnprintSummaryReason}
                                onChange={(e) =>
                                    updateCaseDetail(
                                        e,
                                        'inUnprintSummaryReason'
                                    )
                                }
                            >
                                {inUnprintSummaryReasonList.map((item) => (
                                    <option
                                        key={item.inUnprintSummaryReason}
                                        value={item.inUnprintSummaryReason}
                                    >
                                        {item.inUnprintSummaryReasonName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                對方醫院開單日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inOrderDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inOrderDate')
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                收到外院回覆單日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inReplyDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inReplyDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                外院回覆狀態
                            </div>
                            <select
                                className="w-[calc(100%-160px)] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                name="inReplyStatus"
                                id="inReplyStatus"
                                value={caseDetail.inReplyStatus}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inReplyStatus')
                                }
                            >
                                <option value=""></option>
                                {inReplyStatusList.map((item) => (
                                    <option
                                        key={item.inReplyStatus}
                                        value={item.inReplyStatus}
                                    >
                                        {item.inReplyStatusName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4">
                        <div className="w-[160px] mr-4 text-right">
                            有無轉診單
                        </div>
                        <div className="flex gap-2 mr-6">
                            <RadioButton
                                label="有"
                                name="inTranPaperFlag"
                                mode={caseDetail.inTranPaperFlag}
                                modeChecked={true}
                                handleModeChange={updateInTranPaperFlag}
                            />
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.replyDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'replyDate')
                                }
                            />
                        </div>
                        <div className="flex gap-2 flex-auto">
                            <RadioButton
                                label="無，原因"
                                mode={caseDetail.inTranPaperFlag}
                                modeChecked={false}
                                handleModeChange={updateInTranPaperFlag}
                            />

                            <select
                                className="h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] w-[250px]"
                                name="inTranPaperReason"
                                id="inTranPaperReason"
                                value={caseDetail.inTranPaperReason}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inTranPaperReason')
                                }
                            >
                                <option value=""></option>
                                {inTranPaperReasonList.map((item) => (
                                    <option
                                        key={item.inTranPaperReason}
                                        value={item.inTranPaperReason}
                                    >
                                        {item.inTranPaperReasonName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                特殊報告回覆日期
                            </div>
                            <BaseInput
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.inSpecialReplyDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inSpecialReplyDate')
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-4 gap-2">
                        <div className="flex flex-row items-center justify-start">
                            <div className="w-[160px] mr-4 text-right">
                                癌症篩檢
                            </div>
                            <input
                                type="checkbox"
                                style={{ accentColor: '#38A169' }}
                                id="inCancerFlag"
                                name="inCancerFlag"
                                className="mr- bg-green-400 checked:bg-green-600 text-green-6002"
                                checked={caseDetail.inCancerFlag}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inCancerFlag')
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start">
                        <div className="w-[160px] mr-4 text-right">備註</div>
                        <textarea
                            value={caseDetail.inRemark}
                            className="w-full mt-2 shadow rounded border-2 p-2 h-[200px]"
                            onChange={(e) => updateCaseDetail(e, 'inRemark')}
                        />
                    </div>
                </div>
                <div className="w-full border-b-2 mt-3"></div>
                <div className="flex justify-end p-3">
                    <button
                        onClick={() => onConfirm(caseDetail)}
                        className="flex items-center justify-center font-bold px-4 h-10 mt-4 rounded-[6px] bg-[#2B6CB0] text-white"
                    >
                        {t('general.saveFile')}
                    </button>
                </div>
            </div>
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    zipCodeList={zipCodeList}
                    onConfirm={onSelectHospital}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
            {isShowReferralListModal && (
                <ReferralListModal
                    info={info}
                    onClose={() => setIsShowReferralListModal(false)}
                    onConfirm={handleSelectEtranNo}
                />
            )}
        </div>
    )
}

export default EditTranInModal
