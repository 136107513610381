import React, {useEffect, useMemo, useState} from 'react'
import {BaseInput} from '../../Input/BaseInput'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    enum2Array,
    getLocalStorage,
    InputTypeEnum,
    RsisStepStatusEnum,
    stringIsEmpty,
    time2String,
    ToastTypeEnum
} from "edah_utils/dist"
import {getCurrentZoneNo, Layout, Title} from '../utils'
import CustomTable from '../RSISCustomTable'
import {RegistryListItem, SubmitListItem} from './ListItem'
import {usePopup} from '../PopupProvider'
//所有人員查詢Modal
import QueryCreateUserList from '../Modal/QueryCreateUserList'
//人員清單查詢Modal
import QueryPersonList from '../Modal/QueryPersonList'
//支援機構查詢Modal
import QuerySupportOrg from "../Modal/QuerySupportOrg"
//刪除個案管理清單Popup
import DeletePopup from '../Popup/DeletePopup'
//整批變變更彈窗
import BatchesChange from '../Modal/BatchesChange'
//整批註銷彈窗
import BatchesLogOff from '../Modal/BatchesLogOff'
// 引入i18n
import {t} from 'i18next'
// 引入API
import {
    rsisDelMaster,
    rsisQueryFunctionDocHtml,
    rsisQueryMaster,
    rsisQueryOrganization,
    rsisQueryOwnerUser
} from "../../../api/v1/RSIS"
import {queryAllDoctor} from "../../../api/v1/Menu"
import {useSelector} from "react-redux"

const fields = {
    registryFields: [
        ['申請單號', '院區名稱'],
        ['申請人代號', '姓名'],
        ['支援醫師代號', '姓名'],
        ['支援機構代號', '名稱'],
        ['支援科別代號', '名稱'],
        ['報備期間', '開始-結束日期'],
        '狀態別',
        "匯出編號",
        '衛福部案號',
    ],
    submitFields: [
        ['申請單號', '院區名稱'],
        ['申請人代號', '姓名'],
        ['支援醫師代號', '姓名'],
        ['支援機構代號', '名稱'],
        ['支援科別代號', '名稱'],
        ['報備期間', '開始-結束日期'],
        '狀態別',
        "",
        "匯出編號",
        '衛福部案號',
    ],
}

const APPLY_STATUS = {
    DELETE: 'DELETE',
    CHANGE: 'CHANGE',
    LOGOFF: 'LOGOFF'
}

const ROLE_TYPE = {
    ADMIN: 'ADMIN',
    USER: 'USER'
}

/**
 * 整批變更註銷或刪除
 * @returns {JSX.Element}
 * */
const LogOffDeleteBatches = () => {
    // 是否為承辦人員
    const [isOwnUser, setIsOwnUser] = useState(false)
    // 取得當前TabId
    const activeTabId = useSelector(state => state.tab.activeTabId);

    const role = ROLE_TYPE.ADMIN
    // 個案管理查詢條件表單
    const [queryForm, setQueryForm] = useState({
        registerId: '', //申請人/工號
        supportDoc: '', //報備支援醫師/工號
        supportOrg: '', //支援機構/工號
        filterDep: '',  //醫院
        filingPeriodStart: '', //報備期間起
        filingPeriodEnd: '',   //報備期間迄
        ministryOfHealthAndWelfareNum: '', //衛福部案號
        status: ['', ''], //狀態
    })
    // 使用上下文全域狀態管理
    const {
        setBatchSelected,
        showBatchesChange,
        setShowBatchesChange,
        showBatchesLogOff,
        cleanBatchSelected,
        setCleanBatchSelected,
        setShowBatchesLogOff,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        showQueryPersonList,
        setShowQueryPersonList,
        showDeletePopup,
        setShowDeletePopup,
        focusIndex,
        showToast
    } = usePopup()

    // 支援機構查詢 support organizaion query
    const [showQuerySupportPopup, setShowQuerySupportPopup] = useState(false)
    // 申請表格整批刪除
    const [registryBatchSelected, setRegistryBatchSelected] = useState('')
    // 送審表格整批變更、整批註銷
    const [submitBatchSelected, setSubmitBatchSelected] = useState('')
    // 整批刪除選取項目
    const [selectDeleteItems, setSelectDeleteItems] = useState([])
    // 整批變更、整批註銷選取項目
    const [selectItems, setSelectItems] = useState([]);

    // 申請表格分頁
    const [registryPaginationProps, setRegistryPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 送審表格分頁
    const [submitPaginationProps, setSubmitPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    const today = new Date();

    // 支援人員
    const [createUser, setCreateUser] = useState('')
    // 醫師工號
    const [supportUserNo, setSupportUserNo] = useState('')
    // 支援機構
    const [supportOrgNo, setSupportOrgNo] = useState('')
    //分院類型
    const [branchType, setBranchType] = useState([])
    // 報備起始日期
    const [itemStartDate, setItemStartDate] = useState('')//useState(time2String(today, 'yyyy-MM-DD'))
    // 報備結束日期
    const [itemEndDate, setItemEndDate] = useState('')//useState(time2String(new Date(today.setMonth(today.getMonth() + 2)), 'yyyy-MM-DD'))
    //衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')

    // 申請人員清單
    const [createUserList, setCreateUserList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 機構清單
    const [organizationList, setOrganizationList] = useState([])
    // 承辦人員清單
    const [ownerList, setOwnerList] = useState([])
    //申請清單
    const [applyList, setApplyList] = useState([])
    //送審清單
    const [reviewList, setReviewList] = useState([])
    // 佈告欄內容
    const [htmlContent, setHtmlContent] = useState('')

    /**
     * 取得Input Style Class Name
     * @param width {number} 寬度
     * @return {string} Style Class Name
     */
    const getInputStyleClassName = (width) => `w-[${width}px] h-10 bg-white text-black rounded-[6px] border border-gray-300 pl-4`

    /**
     * 報備起始日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemStartDateOnChange = (e) => setItemStartDate(e.target.value)

    /**
     * 報備結束日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemEndDateOnChange = (e) => setItemEndDate(e.target.value)

    /**
     * 衛福部案號改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSendCaseNoOnChange = (e) => setSendCaseNo(e.target.value)

    /**
     * 整批變更表格更新
     * @param type
     */
    const handleBatchesChangeUpdate = (type) => {getQueryMaster('APPLY')}

    /**
     * 整批註銷表格更新
     * @param type
     */
    const handleBatchesLogOffUpdate = (type) => getQueryMaster('REVIEW')

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryMaster()

    /**
     * 申請人/工號方框點擊時
     * @return {void}
     */
    const handleOnCreateUserClick = () => setShowQueryCreateUserList(true)

    /**
     * 更新申請人/工號
     * @param {object} item 選取的項目
     * @return {void}
     */
    const handleCreateUserOnUpdate = (item) => {
        setCreateUser(`${item.userNo} ${item.userName}`)
        setShowQueryCreateUserList(false)
    }

    /**
     * 報備支援醫師/工號方框點擊時
     * @return {void}
     */
    const handleQueryDoctorOnClick = () => setShowQueryPersonList(true)

    /**
     * 更新報備支援醫師/工號
     * @param {object} item 選取的項目
     * @return {void}
     */
    const handleDoctorOnUpdate = (item) => {
        setSupportUserNo(`${item.userNo} ${item.userName}`)
        setShowQueryPersonList(false)
    }

    /**
     * 支援機構方框點擊時
     * @return {void}
     */
    const handleQuerySupportOrg = () => setShowQuerySupportPopup(true)

    /**
     * 更新支援機構
     * @param {object} item 選取的項目
     * @return {void}
     */
    const handleSupportOrgOnUpdate = (item) => {
        setSupportOrgNo(`${item.orgNo} ${item.orgName}`)
        setShowQuerySupportPopup(false)
    }

    /**
     * 申請表格表頭條件勾選欄位，整批刪除
     * @param {Array} items 選取的項目
     * @return {void}
     */
    const handleOnDeleteItemsSelected = (items) => setSelectDeleteItems(items)

    /**
     * 送審表格表頭條件勾選欄位，整批變更
     * @param {Array} items 選取的項目
     * @return {void}
     */
    const handleOnSelectedItems = (items) => setSelectItems(items)

    /**
     * 整批變更彈出視窗關閉時
     * @return {void}
     */
    const handleBatchesChangeOnClose = () => {
        setShowBatchesChange(false)
        init()
    }

    /**
     * 解構HTML
     * @return {String}
     */
    const decodeHtml = () => {
        const txt = document.createElement("textarea")
        txt.innerHTML = htmlContent
        return txt.value
    }

    /**
     * 支援人員查詢彈出視窗關閉時
     * @return {void}
     */
    const handleQueryCreateUserListOnClose = () => setShowQueryCreateUserList(false)

    /**
     * 人員清單查詢彈出視窗關閉時
     * @return {void}
     */
    const handleQueryPersonListOnClose = () => setShowQueryPersonList(false)

    /**
     * 支援機構查詢彈出視窗關閉時
     * @return {void}
     */
    const handleQuerySupportOrgOnClose = () => setShowQuerySupportPopup(false)

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 初始化
     * @return {void}
     */
    const init = () => {
        // 取得醫師清單
        getQueryAllDoctor()
        //取得機構清單
        getQueryOrganization()
        // 取得承辦人員清單
        getOwnerUserList()
    }

    /**
     * 整批刪除
     * @return {Promise<void>}
     */
    const deleteRow = async () => {
        if (arrayIsEmpty(selectDeleteItems)) {
            showToast({message: '請選擇刪除項目', type: ToastTypeEnum.Warning})
            return
        }
        const res = await rsisDelMaster(selectDeleteItems)
        const {err, data, msg} = res
        setShowDeletePopup(false)

        if (err === ApiErrorStatusEnum.Success) {
            showToast({message: '刪除成功', type: ToastTypeEnum.Success})
            handleQueryOnClick()
            setSelectDeleteItems([])
        } else {
            showToast({message: `刪除失敗，${msg}`, type: ToastTypeEnum.Error})
        }
    }

    /**
     * 申請區塊的整批執行按鈕點擊事件
     * @return {void}
     */
    const handleRegistryBatchImplementOnClick = () => {
        if (registryBatchSelected === null || registryBatchSelected !== APPLY_STATUS.DELETE) {
            showToast({
                message: '請勾選整批刪除',
                type: ToastTypeEnum.Warning
            })
            return
        }

        if (arrayIsEmpty(selectDeleteItems)) {
            showToast({
                message: '請勾選「刪除案件」！',
                type: ToastTypeEnum.Warning
            })
            return
        }
        setShowDeletePopup(true)
    }

    /**
     * 申請表格表頭條件勾選欄位，整批刪除
     * @param role {string}
     * @return {JSX.Element}
     */
    const registryTableHeader = (role) => {
        return (
            <div className='flex justify-start items-center gap-4'>
                <h3 className='flex items-center justify-center text-[20px] pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                    申請
                </h3>
                <p>要批次作業請先點選右方按鈕：</p>
                <div className='space-x-4 flex flex-row items-center'>
                    <input
                        className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                        id={APPLY_STATUS.DELETE}
                        checked={registryBatchSelected === APPLY_STATUS.DELETE}
                        type={InputTypeEnum.Checkbox}
                        style={{accentColor: '#38A169'}}
                        onChange={(e) => {
                            setRegistryBatchSelected(prev => prev === APPLY_STATUS.DELETE ? null : APPLY_STATUS.DELETE);
                        }}
                    />
                    <label htmlFor={APPLY_STATUS.DELETE}>整批刪除</label>
                    <button
                        className='flex items-center justify-center h-10 px-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]'
                        onClick={handleRegistryBatchImplementOnClick}
                    >
                        整批執行
                    </button>
                </div>
            </div>
        )
    }

    /**
     * 送審區塊的整批執行按鈕點擊事件
     * @return {void}
     */
    const handleSubmitBatchImplementOnClick = () => {
        if (submitBatchSelected === null || (submitBatchSelected !== APPLY_STATUS.CHANGE && submitBatchSelected !== APPLY_STATUS.LOGOFF)) {
            //未勾選 整批變更 及 整批註銷時
            showToast({
                message: '請勾選「整批變更」或「整批註銷」',
                type: ToastTypeEnum.Warning
            })
            return
        }
        if (submitBatchSelected === APPLY_STATUS.CHANGE) {
            //勾選 整批變更
            if (arrayIsEmpty(selectItems)) {
                //未勾選table項目
                showToast({
                    message: '請勾選「變更案件」！',
                    type: ToastTypeEnum.Warning
                })
            }
            else {
                setShowBatchesChange(true)
            }
            return
        }

        if (submitBatchSelected === APPLY_STATUS.LOGOFF) {
            //勾選 整批註銷
            if (arrayIsEmpty(selectItems)) {
                //未勾選table項目
                showToast({
                    message: '請勾選「註銷案件」！',
                    type: ToastTypeEnum.Warning
                })
            }
            else {
                setShowBatchesLogOff(true)
            }
            return
        }
    }

    /**
     * 表格表頭條件勾選欄位，收案確認，衛福部送審轉出，退回申請
     * @param role {string}
     * @return {JSX.Element}
     */
    const submitTableHeader = (role) => {
        return (
            <div className='flex justify-start items-center gap-4'>
                <h3 className='flex items-center justify-center text-[20px] pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                    送審
                </h3>
                <p>要批次作業請先點選右方按鈕：</p>
                <div className='space-x-4 flex flex-row items-center'>
                    <input
                        disabled={role === ROLE_TYPE.USER}
                        className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                        id={APPLY_STATUS.CHANGE}
                        checked={submitBatchSelected === APPLY_STATUS.CHANGE}
                        type={InputTypeEnum.Checkbox}
                        style={{accentColor: '#38A169'}}
                        onChange={(e) => {
                            setSubmitBatchSelected(prev => prev === APPLY_STATUS.CHANGE ? null : APPLY_STATUS.CHANGE);
                        }}
                    />
                    <label htmlFor={APPLY_STATUS.CHANGE}>整批變更</label>
                    <input
                        id={APPLY_STATUS.LOGOFF}
                        disabled={role === ROLE_TYPE.USER}
                        checked={submitBatchSelected === APPLY_STATUS.LOGOFF}
                        type={InputTypeEnum.Checkbox}
                        style={{accentColor: '#38A169'}}
                        className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                        onChange={(e) => {
                            setSubmitBatchSelected(prev => prev === APPLY_STATUS.LOGOFF ? null : APPLY_STATUS.LOGOFF);
                        }}
                    />
                    <label htmlFor={APPLY_STATUS.LOGOFF}>整批註銷</label>
                    <button
                        className='flex items-center justify-center h-10 px-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]'
                        onClick={handleSubmitBatchImplementOnClick}>
                        整批執行
                    </button>
                </div>
            </div>
        )
    }

    /**
     * 取得佈告欄
     * @return {void}
     */
    const getHTMLContent = () => {
        rsisQueryFunctionDocHtml({funNo: activeTabId}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setHtmlContent(data.htmlContent)
            } else {
                showToast({message: `佈告欄${msg}`, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 清除查詢按鈕點擊時
     * @return {void}
     */
    const handleResetSearchButtonOnClick = () => {
        //清除值
        setCreateUser('')
        setSupportUserNo('')
        setSupportOrgNo('')
        setItemStartDate('')
        setItemEndDate('')
        setSendCaseNo('')
    }

    /**
     * 查詢報備支援資料
     * @param type {string} 更新對應表格
     * @return {void}
     */
    const getQueryMaster = (type = null) => {
        // 送審案號為空時，判斷申請人與支援醫師是否為空
        if(stringIsEmpty(sendCaseNo)){
            // 當申請人與支援醫師皆為空時，顯示提示訊息
            if (stringIsEmpty(createUser) && stringIsEmpty(supportUserNo)) {
                showToast({message: t('RSIS.toast.queryDataNotComplete'), type: ToastTypeEnum.Warning})
                return
            }
        }

        //根據表格重置對應資料
        if (type === 'APPLY') {
            // 更新時清除原本資料
            setApplyList([])
        } else if (type === 'REVIEW') {
            // 更新時清除原本資料
            setReviewList([])
        }
        setCleanBatchSelected(!cleanBatchSelected)
        setSubmitBatchSelected(null)
        setRegistryBatchSelected(null)
        // 取得院區
        const zone = branchType.filter(item => item.selected).map(item => item.campus)
        // 支援機構代碼
        const orgNo = supportOrgNo ? (supportOrgNo.indexOf(' ') > 0 ? supportOrgNo.split(' ')[0] : supportOrgNo) : ''
        // 支援機構PK
        const org = organizationList.find(o => o.orgNo === orgNo)
        rsisQueryMaster({
            // 申請人/工號
            createUser: createUser ? (createUser.indexOf(' ') > 0 ? createUser.split(' ')[0] : createUser) : '',
            // 支援醫師代碼
            supportUserNo: supportUserNo ? (supportUserNo.indexOf(' ') > 0 ? supportUserNo.split(' ')[0] : supportUserNo) : '',
            // 支援機構代碼
            supportOrgNo: org ? org.orgPkey : '',
            //預設 0:全部/1:申請/2:確認/3:送
            // 審/刪除/9:失效
            stepStatus: RsisStepStatusEnum.All,
            // M:變更/D:刪除
            supportModifyStatus: null,
            // 送審案號
            sendCaseNo: sendCaseNo,
            //院區
            zone: zone.join(','),
            // 報備時間起
            itemStartDate: stringIsEmpty(itemStartDate) ? null : `${itemStartDate} 00:00:00`,
            // 報備時間迄
            itemEndDate: stringIsEmpty(itemEndDate) ? null : `${itemEndDate} 23:59:59`
        }).then(res => {
            //  狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 處理數組中的每個對象
                const processedData = data.map(item => {
                    return Object.entries(item).reduce((acc, [key, value]) => {
                        acc[key] = (value === null || value === undefined) ? '' : value;
                        return acc;
                    }, {});
                });

                // 當查詢資料為空時，顯示提示訊息
                if (processedData.length === 0) {
                    // 當有輸入申請人，沒有輸入支援醫師時
                    if (!stringIsEmpty(createUser) && stringIsEmpty(supportUserNo)) {
                        // 顯示查無此申請人資料
                        showToast({message: t('RSIS.toast.createUserNotFound'), type: ToastTypeEnum.Warning})
                    } else if (stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.supportUserNotFound'), type: ToastTypeEnum.Warning})
                    } else if (!stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.rsisDataNotFound'), type: ToastTypeEnum.Warning})
                    }
                }

                // 取得申請資料
                const applyList = processedData.filter(item => item.stepStatus === RsisStepStatusEnum.Apply.toString())
                setApplyList(applyList)
                // 取得送審資料
                const reviewList = processedData.filter(item => item.stepStatus === RsisStepStatusEnum.Review.toString())
                setReviewList(reviewList)
            } else { // 取得失敗
                // 清空資料
                setApplyList([])
                setReviewList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有承辦人員清單
     * @return {void}
     */
    const getQueryOwnerUser = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定承辦人員清單資料
                setCreateUserList(data)
            } else { // 取得失敗
                // 清空承辦人員清單
                setCreateUserList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師清單
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setDoctorList(data)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得機構清單
     * @return {void}
     */
    const getQueryOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單
                setOrganizationList(data)
            } else {
                // 清空機構清單
                setOrganizationList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦人員清單
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            const loginUser = getLocalStorage('userno')
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 過濾清單只取得userName
                const list = data.map(item => item.userNo + ' ' + item.userName)
                // 設定承辦人員清單
                setOwnerList(list)
                setIsOwnUser(list.some(owner => owner.split(' ')[0] === loginUser))
            } else { // 取得失敗
                // 清空承辦人員清單
                setOwnerList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        //分院
        const currentCampus = getLocalStorage('campus')
        //取得所有分院
        const allCampus = enum2Array(CampusEnum)
        // 產生分院選單
        const newCampus = allCampus.map(item => ({
            // 分院代碼
            campus: item.value,
            // 分院名稱
            campusName: t(`campus.shortName.${item.value}`),
            // 是否選取
            selected: currentCampus === item.value,
        }))
        // 設定分院類型
        setBranchType(newCampus)
        // 取得所有承辦人員清單
        getQueryOwnerUser()
        // 取得佈告欄內容
        getHTMLContent()
        // 若尚未取當前院區代號，則取得代號並儲存在localStorage
        if (getLocalStorage('campusId') === null) {
            // 取得院區代號
            getCurrentZoneNo()
        }
    }, [])

    /**
     * 分院類型方框改變時
     * @param index
     */
    const handleBranchOnChange = (index) => {
        const newBranchType = branchType.map((item, i) => ({
            ...item,
            selected: i === index ? !item.selected : item.selected
        }))
        setBranchType(newBranchType)
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        init()
        setBatchSelected('4')
    }, [])

    return (
        <Layout>
            {/* 查詢區塊 */}
            <div className='w-full flex flex-row justify-between items-center p-2 '>
                {/* 查詢條件 */}
                <div className='flex flex-col py-[22px]'>
                    {/* 第一列查詢條件 */}
                    <div className='flex items-center p-2 gap-6'>
                        {/* 支援人員：*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <Title
                                extendClass='pl-1'>{`${t('RSIS.general.applicant')}/${t('RSIS.general.jobNum')}：`} </Title>
                            <BaseInput className={getInputStyleClassName(186)}
                                       inputMode={InputTypeEnum.Text}
                                       type={InputTypeEnum.Text}
                                       value={createUser}
                                       onClick={handleOnCreateUserClick}/>
                        </div>
                        {/*報備支援醫師/工號*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <Title
                                extendClass='pl-1'>{`${t('RSIS.general.supportDoc')}/${t('RSIS.general.jobNum')}：`} </Title>
                            <BaseInput className={getInputStyleClassName(186)}
                                       inputMode={InputTypeEnum.Text}
                                       type={InputTypeEnum.Text}
                                       value={supportUserNo}
                                       onClick={handleQueryDoctorOnClick}/>
                        </div>
                        {/*支援機構/工號*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <Title>{`${t('RSIS.general.supportOrg')}/${t('RSIS.general.jobNum')}：`}</Title>
                            <BaseInput className={getInputStyleClassName(186)}
                                       inputMode={InputTypeEnum.Text}
                                       type={InputTypeEnum.Text}
                                       value={supportOrgNo}
                                       onClick={handleQuerySupportOrg}/>
                        </div>
                        {/*院區選項*/}
                        <div className='flex items-center space-x-4'>
                            {
                                branchType.map((item, index) =>
                                    <div className='flex space-x-3 justify-end' key={index}>
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                               checked={item.selected}
                                               style={{accentColor: '#38A169'}}
                                               type={InputTypeEnum.Checkbox} id={item.campus}
                                               onChange={() => handleBranchOnChange(index)}/>
                                        <label id={item.campus}>{item.campusName}</label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/* 第二列查詢條件 */}
                    <div className='flex items-center py-3 px-2 gap-6'>
                        <div className='flex items-center gap-1'>
                            <Title>{`${t('RSIS.general.accordingFilingPeriod')}：`}</Title>
                            {/*報備起始日期*/}
                            <BaseInput className={`${getInputStyleClassName(160)} pl-4`}
                                       type={InputTypeEnum.Date} value={itemStartDate}
                                       max="9999-12-31"
                                       onChange={handleItemStartDateOnChange}/>
                            ～
                            {/*報備結束日期*/}
                            <BaseInput className={`${getInputStyleClassName(160)} pl-4`}
                                       type={InputTypeEnum.Date} value={itemEndDate}
                                       max="9999-12-31"
                                       onChange={handleItemEndDateOnChange}/>
                        </div>
                        {/*衛福部案號*/}
                        <div className='flex items-center'>
                            <Title>{`${t('RSIS.general.ministryOfHealthAndWelfareNum')}：`}</Title>
                            <BaseInput
                                className="w-[102px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                type={InputTypeEnum.Text} value={sendCaseNo} onChange={handleSendCaseNoOnChange}/>
                        </div>
                        {/*查詢按鈕*/}
                        <div className="source flex flex-row items-center justify-start mr-4">
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                onClick={handleQueryOnClick}>
                                {t('general.query')}
                            </button>
                        </div>
                        {/*清除條件按鈕*/}
                        <div className="source flex flex-row items-center justify-start mr-4">
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#90CDF4] text-white border-[#90CDF4] rounded-[6px]"
                                onClick={handleResetSearchButtonOnClick}>
                                {t('general.clearQueryParams')}
                            </button>
                        </div>
                    </div>
                </div>
                {/* 佈告欄 */}
                <div className='w-1/6 h-[150px] bg-slate-300 flex flex-col justify-center items-center overflow-y-auto'
                     dangerouslySetInnerHTML={{__html: decodeHtml()}}>
                </div>
            </div>
            {/* 查詢結果 */}
            <div className='flex flex-col gap-4'>
                {/* 申請表格 */}
                <CustomTable
                    header={registryTableHeader(role)}
                    fields={fields.registryFields}
                    dataList={applyList}
                    ListItem={RegistryListItem}
                    hasSelectAll={true}
                    type="RSISLogOffDeleteBatchesRegistryTable"
                    paginationProps={registryPaginationProps}
                    handleGetSelectItem={handleOnDeleteItemsSelected}
                />
                {/* 送審表格 */}
                <CustomTable
                    header={submitTableHeader(role)}
                    fields={fields.submitFields}
                    dataList={reviewList}
                    ListItem={SubmitListItem}
                    hasSelectAll={true}
                    type="RSISLogOffDeleteBatchesSubmitTable"
                    paginationProps={submitPaginationProps}
                    handleGetSelectItem={handleOnSelectedItems}
                />
            </div>
            {/* 支援人員查詢彈出視窗 */}
            {
                showQueryCreateUserList && (
                    <QueryCreateUserList
                        handleOnClose={handleQueryCreateUserListOnClose}
                        handleOnUpdate={handleCreateUserOnUpdate}/>
                )
            }
            {/* 人員清單查詢彈出視窗 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        handleOnClose={handleQueryPersonListOnClose}
                        handleOnUpdate={handleDoctorOnUpdate}/>
                )
            }
            {/* 支援機構查詢彈出視窗 */}
            {
                showQuerySupportPopup && (
                    <QuerySupportOrg
                        handleOnClose={handleQuerySupportOrgOnClose}
                        handleOnUpdate={handleSupportOrgOnUpdate}/>
                )
            }
            {/* 刪除個案管理清單彈出視窗 */}
            {
                showDeletePopup && (
                    <DeletePopup
                        handleDeletePopupClose={handleDeletePopupOnClose}
                        handleNotificationDelete={deleteRow}
                        currentKey={focusIndex}/>
                )
            }
            {/* 整批變更彈出視窗 */}
            {
                showBatchesChange && (
                    <BatchesChange selectItems={selectItems} num={selectItems.length}
                                   isOwnUser={isOwnUser}
                                   handleOnClose={handleBatchesChangeOnClose}
                                   handleOnUpdate={handleBatchesChangeUpdate}/>
                )
            }
            {/* 整批註銷彈出視窗 */}
            {
                showBatchesLogOff && (
                    <BatchesLogOff
                        num={selectItems.length}
                        isOwnUser={isOwnUser}
                        handleOnClose={() => {
                            setShowBatchesLogOff(false)
                        }}
                        handleOnUpdate={handleBatchesLogOffUpdate}
                        selectItems={selectItems}
                    />
                )
            }
        </Layout>
    )
}

export default LogOffDeleteBatches
