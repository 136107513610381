import React, { useState } from 'react';
import { BasePopup } from "../../Popup/BasePopup";
import { BaseInput } from "../../Input/BaseInput";
import moment from 'moment';
import { InputTypeEnum } from "edah_utils/dist";
import useToast from '../../../hooks/useToast';
import {
    ToastTypeEnum
} from "edah_utils/dist"

/**
 * 編輯事件彈窗組件
 * @param {Object} props - 組件屬性
 * @param {Object} props.event - 要編輯的事件對象
 * @param {Object} props.apnTimeRange - 時段時間區間
 * @param {Function} props.onConfirm - 確認編輯的回調函數
 * @param {Function} props.onCancel - 取消編輯的回調函數
 * @returns {JSX.Element} 編輯事件彈窗組件
 */
const EditEventPopup = ({ 
    event, 
    apnTimeRange,
    onConfirm, 
    onCancel,
    onDelete
}) => {
    // 事件標題狀態
    const [title, setTitle] = useState(event.title);
    // 事件日期狀態
    const [date, setDate] = useState(moment(event.start).format('YYYY-MM-DD'));
    // 事件開始時間狀態
    const [startTime, setStartTime] = useState(moment(event.start).format('HH:mm'));
    // 事件結束時間狀態
    const [endTime, setEndTime] = useState(moment(event.end).format('HH:mm'));
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 處理表單提交
     * @param {React.FormEvent} e - 表單提交事件
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const startDateTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm').toDate();
        const endDateTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm').toDate();
        //檢查start、end時間
        if(startDateTime < apnTimeRange.start || startDateTime > apnTimeRange.end) {
            showToast({message:"起始時間不在時段內", type:ToastTypeEnum.Error})
            return
        }
        if(endDateTime < apnTimeRange.start || endDateTime > apnTimeRange.end) {
            showToast({message:"結束時間不在時段內", type:ToastTypeEnum.Error})
            return
        }
        if(startDateTime >= endDateTime) {
            showToast({message:"起始時間不能大於等於結束時間", type:ToastTypeEnum.Error})
            return
        }
        
        onConfirm({
            ...event,
            title,
            start: startDateTime,
            end: endDateTime
        });
    };

    /**
     * 刪除按鈕點擊事件
     */
    const handleRemoveOnClick = () => {
        onDelete(event.id)
    }

    // 彈窗內容
    const content = (
        <form className="px-4 pb-4" onSubmit={handleSubmit}>
            <p className="text-left mb-4 font-bold text-[24px]">{title}</p>
            <div className="flex flex-row justify-between items-center">
                <BaseInput
                    className="w-[165px] h-[40px] pl-4 mb-4 border rounded"
                    type={InputTypeEnum.Date}
                    value={date}
                    disable={true}
                    onChange={(e) => setDate(e.target.value)}
                />
                <BaseInput
                    className="w-[165px] h-[40px] pl-6 mb-4 border rounded"
                    type={InputTypeEnum.Time}
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                />
                <p className='mb-4'>~</p>
                <BaseInput
                    className="w-[165px] h-[40px] pl-6 mb-4 border rounded"
                    type={InputTypeEnum.Time}
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                />
            </div>
            <div className="flex justify-start">
                <button 
                    type="button" 
                    className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]"
                    onClick={handleRemoveOnClick}>
                    刪除
                </button>
                <button type="submit" className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">存檔</button>
            </div>
        </form>
    );

    return (
        <BasePopup
            title="編輯掛號"
            content={content}
            closePopupButtonOnClick={onCancel}
            width="575px"
        />
    );
};

export default EditEventPopup;