import React, { useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { ER_QUALITY_VPN_FIELDS } from '../../constants/referralSystem/erQualityVpnUploadExport'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { queryRmisEmgquality, tranRmisEmgqualityToTxt } from '../../api/v1/Rmis'
import { BaseInput } from '../../components/Input/BaseInput'
import {
    ApiErrorStatusEnum,
    InputTypeEnum,
    time2String,
    ToastTypeEnum,
    stringIsEmpty,
    downloadFile,
    FileExtensionsEnum,
    BlobTypeEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'

/**
 * 急診品質方案VPN上傳匯出作業
 * @returns {JSX.Element}
 */
const ErQualityVpnUploadExport = () => {
    const [encounterYearMonth, setEncounterYearMonth] = useState('')
    const [encounterYearMonthInputState, setEncounterYearMonthInputState] =
        useState(null)
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    const showToast = useToast()

    /**
     * 插入CustomTable的匯出按鈕
     * @returns {JSX.Element} 匯出Text按鈕
     */
    const ExportTextButton = () => {
        return (
            <button
                className="flex items-center justify-center px-4 h-10 rounded-[6px] text-[#38A169]"
                onClick={exportText}
            >
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 匯出
     */
    const exportText = () => {
        const month = encounterYearMonth ? encounterYearMonth.split('-')[1] : ''
        if (stringIsEmpty(month)) {
            return
        }
        const data = {
            //月份(only for file name)
            month,
            //需要轉換的資料
            rmisEmgualityData: dataList,
        }

        tranRmisEmgqualityToTxt(data).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const blob = new Blob([res.data.data], {
                    type: BlobTypeEnum.PlainText,
                })
                downloadFile(blob, res.data.fileName, FileExtensionsEnum.TXT)
            } else {
                showToast({
                    message: `匯出失敗: ${res.msg}`,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getErQualityList(currentPage, filter.pageSize)
    }

    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getErQualityList(filter.currentPage, pageSize)
    }
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getErQualityList(page, filter.pageSize)
    }
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getErQualityList(page, filter.pageSize)
    }

    const handleSearch = () => {
        getErQualityList(1, filter.pageSize)
    }

    const getErQualityList = (pageNum, pageSize) => {
        const encounterYearMonthIsValid = !stringIsEmpty(encounterYearMonth)
        setEncounterYearMonthInputState(encounterYearMonthIsValid)
        if (!encounterYearMonthIsValid) {
            return
        }
        queryRmisEmgquality({
            pageNum,
            pageSize,
            month: time2String(encounterYearMonth, 'YYYY-MM'),
        }).then((result) => {
            if (result.err === ApiErrorStatusEnum.Success) {
                const list = result.data || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalPage: result.totalPageSize,
                    total: result.totalItemSize,
                    pageNum,
                    pageSize,
                })
            } else {
                showToast({ message: result.msg, type: ToastTypeEnum.Warning })
            }
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex items-start p-2">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="mr-4">
                            就醫年月<span className="text-danger ">*</span>:
                        </div>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            type={InputTypeEnum.Month}
                            value={encounterYearMonth}
                            onChange={(e) =>
                                setEncounterYearMonth(e.target.value)
                            }
                            inputState={encounterYearMonthInputState}
                            errorMsg="必填"
                        />
                    </div>
                    <button
                        className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        onClick={handleSearch}
                    >
                        查詢
                    </button>
                </div>
                <CustomTable
                    fields={ER_QUALITY_VPN_FIELDS}
                    dataList={dataList}
                    totalPage={filter.page}
                    total={filter.total}
                    slotButton={<ExportTextButton />}
                ></CustomTable>
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.total}
                        currentPage={filter.currentPage}
                        totalPageSize={filter.totalPage}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}
export default ErQualityVpnUploadExport
