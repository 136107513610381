//Import css
import '../../components/TableList/tableList.scss'

//Import icons
import {ReactComponent as AddIcon} from '../../assets/images/ICCard/add_circle.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/ICCard/delete.svg'
import {ReactComponent as EditBtnIcon} from "../../assets/images/ICCard/edit_square.svg"

//Import function
import React, {useMemo, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    objectIsEmpty,
    PopupModeEnum,
    stringIsEmpty,
    time2String,
    ToastTypeEnum
} from "edah_utils/dist"
import AddEditSignAgentPopup from "../../components/ESSI/AddEditSignAgentPopup"
import {essiDelSignAgent, essiQuerySignAgent} from "../../api/v1/ESSI"
import {DeletePopup} from "../../components/Popup/DeletePopup"
import {t} from "i18next"
import {queryAllDoctor} from "../../api/v1/Menu"
import useToast from "../../hooks/useToast"

/**
 * 代簽人員維護
 * @return {JSX.Element}
 */
const SignAgentMaintenance = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    /**
     * Toast
     */
    const showToast = useToast()

    // 初始資料
    const [data, setData] = useState([])

    // 是否顯示刪除的彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    //是否顯示Add Edit彈窗
    const [showAddEditPopup, setShowAddEditPopup] = useState(false)

    //是否為新增或編輯
    const [addEditMode, setAddEditMode] = useState(PopupModeEnum.Add)

    //修改或刪除資料
    const [newData, setNewData] = useState({})

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //醫生選項清單
    const [doctors, setDoctors] = useState([])

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const advancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 點選新增內容按鈕時
     * @return {void}
     */
    const handleAddButtonOnClick = () => {
        // 顯示新增/修改的彈窗
        setShowAddEditPopup(true)
        // 設定為新增模式
        setAddEditMode(PopupModeEnum.Add)
        // 清空資料
        setNewData({})
    }

    /**
     * 點選Edit按鈕事件
     * @param data {Object} 資料
     * @return {void}
     */
    const handleEditButtonOnClick = (data) => {
        // 設定資料
        setNewData(data)
        // 顯示新增/修改的彈窗
        setShowAddEditPopup(true)
        // 設定為修改模式
        setAddEditMode(PopupModeEnum.Modify)
    }

    /**
     * 關閉新增/修改popup按鈕事件
     * @return {void}
     */
    const handleAddEditPopupOnClose = () => setShowAddEditPopup(false)

    /**
     * 點擊刪除按鈕時
     * @param rowData {Object} 資料
     * @return {void}
     */
    const handleDeleteButtonOnClick = (rowData) => {
        // 顯示刪除的彈窗
        setShowDeletePopup(true)
        // 設定資料
        setNewData(rowData)
    }

    /**
     * 刪除彈窗是按鈕事件
     * @return {void}
     */
    const handleDeletePopupYesButtonOnClick = () => delSignAgent()

    /**
     * 刪除彈窗否按鈕事件
     * @return {void}
     */
    const handleDeletePopupNoButtonOnClick = () => {
        //清空資料
        setNewData({})
        // 關閉刪除彈窗
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗關閉按鈕事件
     * @return {void}
     */
    const handleDeletePopupCloseButtonOnClick = () => handleDeletePopupNoButtonOnClick()

    /**
     * 取得人員名稱
     * @param userNo {String} 人員代號
     * @return {string}
     */
    const getUserName = (userNo) => {
        const doctor = doctors.find(doctor => doctor.userNo === userNo)
        return objectIsEmpty(doctor) ? "" : doctor.userName
    }

    /**
     * 取得代理人員名稱
     * @param agentUserNo {String} 代理人員代號
     * @return {string}
     */
    const getAgentUserName = (agentUserNo) => {
        const doctor = doctors.find(doctor => doctor.userNo === agentUserNo)
        return objectIsEmpty(doctor) ? "" : doctor.userName
    }

    /**
     * 取得有效日期
     * @param effStartDatetime {String} 開始日期
     * @param effEndDatetime {String} 結束日期
     * @return {string}
     */
    const getExpirationDate = (effStartDatetime, effEndDatetime) => {
        // 如果開始日期或結束日期為空
        if (stringIsEmpty(effStartDatetime) || stringIsEmpty(effEndDatetime)) {
            return ""
        }

        // 取得開始日期
        const startDate = time2String(effStartDatetime, "YYYY-MM-DD")
        // 取得結束日期
        const endDate = time2String(effEndDatetime, "YYYY-MM-DD")

        return `${startDate} ~ ${endDate}`
    }

    /**
     * 取得過濾後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            const {err, data} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //設定人員代號選項
                setDoctors(data)
            }
        })
    }

    /**
     * 取得所有代簽人員維護資料
     * @return {void}
     */
    const getQuerySignAgent = () => {
        essiQuerySignAgent({}).then(res => {
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            } else {
                // 清空資料
                setData({})
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除人員憑證維護資料
     * @return {void}
     */
    const delSignAgent = () => {
        essiDelSignAgent({
            // 人員代號
            userNo: newData.userNo,
            // 代理人員代號
            agentUserNo: newData.agentUserNo,
        }).then(res => {
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQuerySignAgent()
                // 顯示成功訊息
                showToast({message: msg})
                // 關閉刪除的彈窗
                setShowDeletePopup(false)
            } else { // 失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 查詢所有代簽人員維護資料
        getQuerySignAgent()
        // 查詢所有醫生
        getQueryAllDoctor()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*新增內容按鈕*/}
            <div className='mb-2'>
                <button onClick={handleAddButtonOnClick}
                        className="flex flex-row items-center justify-start text-[#38A169] text-[18px]">
                    <AddIcon/>新增內容
                </button>
            </div>
            <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                {/*進階搜尋*/}
                <div className="flex flex-row items-center justify-between mb-2">
                    <div>
                        <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type="text" placeholder={t('general.advancedSearch')} value={advancedSearchText}
                               onChange={advancedSearchTextOnChange}/>
                    </div>
                </div>
                <div className='w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[70vh]'>
                    <table
                        className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[70vh] max-h-[60vh] xl:max-h-[70vh]">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`${tableElementDefaultType}`}>操作</th>
                            <th className={`${tableElementDefaultType}`}>人員代號</th>
                            <th className={`${tableElementDefaultType}`}>人員名稱</th>
                            <th className={`${tableElementDefaultType}`}>代簽人員代號</th>
                            <th className={`${tableElementDefaultType}`}>代簽人員姓名</th>
                            <th className={`${tableElementDefaultType}`}>有效日期範圍</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((data, index) =>
                                <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                    key={index}>
                                    {/*操作*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        <div className='flex flex-row items-center justify-start'>
                                            {/*編輯按鈕*/}
                                            <button className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
                                                    onClick={() => handleEditButtonOnClick(data)}>
                                                <EditBtnIcon className="mr-1"/>
                                                {t('general.edit')}
                                            </button>
                                            {/*刪除按鈕*/}
                                            <button className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
                                                    onClick={() => handleDeleteButtonOnClick(data)}>
                                                <DeleteIcon className="mr-1"/>
                                                {t('general.delete')}
                                            </button>
                                        </div>
                                    </td>
                                    {/*人員代號*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.userNo ? data.userNo : ""}
                                    </td>
                                    {/*人員名稱*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        {getUserName(data.userNo)}
                                    </td>
                                    {/*代簽人員代號*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.agentUserNo ? data.agentUserNo : ""}
                                    </td>
                                    {/*代簽人員姓名*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        {getAgentUserName(data.agentUserNo)}
                                    </td>
                                    {/*有效日期範圍*/}
                                    <td className={`${tableElementDefaultType}`}>
                                        {getExpirationDate(data.effStartDatetime, data.effEndDatetime)}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {/*新增或修改視窗*/}
            <AddEditSignAgentPopup mode={addEditMode} rowData={newData} show={showAddEditPopup} doctors={doctors}
                                   closePopupOnClick={handleAddEditPopupOnClose}/>
            {/*刪除彈窗*/}
            <DeletePopup content="是否確定刪除?" show={showDeletePopup}
                         onClosePopupButtonClick={handleDeletePopupCloseButtonOnClick}
                         onYesButtonClick={handleDeletePopupYesButtonOnClick}
                         onNoButtonClick={handleDeletePopupNoButtonOnClick}/>
        </div>
    )
}
export default SignAgentMaintenance
