import { useState } from 'react'

const useDataGrid = () => {
    const [dataList, setDataList] = useState([])
    const [originDataList, setOriginDataList] = useState([])
    const [isEditMode, setIsEditMode] = useState(false)

    /**
     * 取得列表資料
     * @param {array} data 列表資料
     */
    const updateDataList = (data) => {
        const newData = data.map((item, index) => ({
            ...item,
            isEditable: false,
            originIndex: index,
            isNewData: false,
            isSelected:false,
        }))
        setDataList(newData)
        setOriginDataList(newData)
        setIsEditMode(false)
    }

    /**
     * 該列資料顯示編輯模式
     * @param {object} tableRow
     */
    const handleEdit = (tableRow) => {
        const getNewList = (prevDataList) => {
            const newDataList = prevDataList.map((item, index) => {
                if (index === tableRow.originIndex) {
                    return {
                        ...item,
                        ...tableRow,
                        isEditable: !item.isEditable,
                    }
                }
                return item
            })

            return newDataList
        }
        setIsEditMode(true)
        setDataList(getNewList)
        setOriginDataList(getNewList)
    }

    /**
     * 儲存該列資料
     * @param {object} tableRow
     */
    const handleSave = (tableRow) => {
        const getNewList = (prevDataList) => {
            const newDataList = prevDataList.map((item, index) => {
                if (index === tableRow.originIndex) {
                    return {
                        ...item,
                        isEditable: false,
                        isNewData: false,
                    }
                }
                return item
            })
            return newDataList
        }
        setIsEditMode(false)
        setDataList(getNewList)
        setOriginDataList(getNewList)
    }

    /**
     * 取消該列資料變動
     * @param {object} tableRow
     */
    const handleCancel = (tableRow) => {
        if (tableRow.isNewData) {
            setDataList((prev) => {
                const list = prev.filter(
                    (item) => item.originIndex !== tableRow.originIndex
                )
                return list
            })
            setOriginDataList((prev) => {
                const list = prev.filter(
                    (item) => item.originIndex !== tableRow.originIndex
                )
                return list
            })
        } else {
            setDataList((prev) => {
                return prev.map((item) => {
                    if (item.originIndex === tableRow.originIndex) {
                        return {
                            ...originDataList[tableRow.originIndex],
                            isEditable: false,
                        }
                    } else {
                        return item
                    }
                })
            })
        }

        setIsEditMode(false)
    }

    const deleteRow = (tableRow) => {
        const newDataList = dataList
            .filter((item) => item.originIndex !== tableRow.originIndex)
            .map((item, index) => {
                return {
                    ...item,
                    originIndex: index,
                }
            })
        setDataList(newDataList)
        setOriginDataList(newDataList)
    }

    /**
     * 新增一列資料
     * @param {object} row
     */
    const addNewData = (row) => {
        setDataList((prevDataList) => [
            ...prevDataList,
            {
                ...row,
                originIndex: prevDataList.length,
                isNewData: true,
                isEditable: true,
                isSelected: false,
            },
        ])
        setIsEditMode(true)
    }

    /**
     * 新增一列資料
     * @param {object} row
     */
    const dataOnChange = (row, key, value) => {
        setDataList((dataList) =>
            dataList.map((item, index) => {
                if (index === row.originIndex) {
                    return {
                        ...item,
                        [key]: value,
                    }
                }
                return item
            })
        )
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const handleToggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.originIndex === item.originIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    const handleToggleAll = (isChecked, selectedDataList) => {
        if (selectedDataList.length === dataList.length) {
            setDataList((prevDataList) =>
                prevDataList.map((item) => ({
                    ...item,
                    isSelected: isChecked,
                }))
            )
        } else {
            selectedDataList.map((item) => {
                const index = dataList.findIndex(
                    (data) => data.originIndex === item.originIndex
                )
                setDataList((prevDataList) => {
                    prevDataList[index] = {
                        ...prevDataList[index],
                        isSelected: isChecked,
                    }
                    return prevDataList
                })
            })
        }
    }

    return {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        dataOnChange,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
    }
}

export default useDataGrid
