import React, {useEffect, useState} from "react"
import {t} from "i18next"
import { DefaultButton } from "../Button/DefaultButton";

const DeleteRuleMessagePopup = ({ 
    show = false,
    closePopupButtonOnClick, 
    handleDeleteOnClick
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <div
            className="fixed top-[-16px] left-0 pt-[60px] flex items-center justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10">
            <div className="w-[600px] bg-white rounded-[6px]">
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">提醒</h3>
                    {/*關閉按鈕*/}
                    <button 
                        className="flex items-center justify-center h-10 text-[18px] text-[#2B6CB0]"
                        onClick={closePopupButtonOnClick}>
                        {t('general.close')}
                    </button>
                </div>

                <div className="text-left px-6 py-8 text-[18px] border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    是否確定刪除?
                </div>

                <div className="flex py-4 px-6 space-x-3 justify-end">
                    <DefaultButton 
                        type="outline-primary" 
                        text="否"
                        onClickFn={closePopupButtonOnClick}
                    />
                    <DefaultButton 
                        type="primary" 
                        text="是"
                        onClickFn={() => handleDeleteOnClick()}
                    />
                </div>
            </div>
        </div> :
        <></>
    )
}

export default DeleteRuleMessagePopup;