// MenuItem.js
import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow_drop_down.svg";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrow_right.svg";

const MenuItem = ({ item, userRole, onItemSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasChildren = item.children && item.children.length > 0;
  const paddingLevel = { paddingLeft: "32px" };
  const toggleDropdown = (event) => {
    // 防止事件冒泡到最底層的點擊事件
    // event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClick = (event) => {
    // 防止事件冒泡到父元素
    // event.stopPropagation();
    if (!item.children || item.children.length === 0) {
      if (onItemSelected) {
        onItemSelected(item.id, item.name);
      }
    }
  };

  return (
    <li className="px-2 mb-2 text-left" style={hasChildren ? {} : paddingLevel}>
      <div
        className="flex items-center cursor-pointer min-h-10"
        onClick={toggleDropdown}
      >
        {item.children && (
          <button className="focus:outline-none">
            {isOpen ? <ArrowDown /> : <Arrow />}
          </button>
        )}
        {/* 名稱始終顯示，但只有在沒有子項目時才處理點擊事件 */}
        <span
          onClick={handleClick}
          className="text-[#515151] hover:bg-gray-100 flex-1"
        >
          {item.name}
        </span>
      </div>
      {isOpen && item.children && (
        <ul className="pl-2">
          {item.children.map((child) => (
            <MenuItem
              key={child.id}
              item={child}
              userRole={userRole}
              onItemSelected={onItemSelected}
            >
              {isOpen && item.children && (
                // {item.children.map((child) => (
                <MenuItem
                  key={child.id}
                  item={child}
                  userRole={userRole}
                  onItemSelected={onItemSelected}
                />
                // ))}
              )}
            </MenuItem>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
