import {BaseInput} from "../../Input/BaseInput";
import {ApiErrorStatusEnum, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist";
import Button from "../../Button/Button";
import React, {useState} from "react";
import {TRAN_NO_FLAG} from "../../../constants/referralSystem/tranInCase";
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/del_square.svg'
import {BasePopup} from "../../Popup/BasePopup";

const Title = ({children, extendClass})=> {
    return (
        <div className={`flex flex-row items-center justify-start mr-8 ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}
const InputStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SelectStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * 轉診代碼維護表單
 * @returns {JSX.Element}
 */
const ReferralCodeMaintainForm = ({data})=>{
    // 類別代碼
    const [typeCode, setTypeCode] = useState("")
    const handleTypeCodeChange = (e) => setTypeCode(e.target.value)

    // 類別名稱
    const [typeName, setTypeName] = useState( "")
    const handleTypeNameChange = (e) => setTypeName(e.target.value)

    //  項目代碼
    const [itemCode, setItemCode] = useState( "")
    const handleItemCodeChange = (e) => setItemCode(e.target.value)


    //  項目名稱
    const [itemName, setItemName] = useState( "")
    const handleItemNameChange = (e) => setItemName(e.target.value)

    // 上傳碼
    const [uploadCode, setUploadCode] = useState( "")
    const handleUploadCodeChange = (e) => setUploadCode(e.target.value)


    // 開始日期
    const [startDate, setStartDate] = useState( "")
    const handleUpdateStartDate = (e) => {
        setStartDate(e.target.value)
    }
    const [endDate, setEndDate] = useState("")

    const handleUpdateEndDate = (e) => {
        setEndDate(e.target.value)
    }

    // 儲存
    const handleSave = async ()=>{
        const targetApi = data ? editTranCode : addTranCode
        const res = await targetApi({
            codeType: typeCode ,
            codeTypeName: typeName,
            codeNo: itemCode,
            codeName: itemName,
            uploadCodeNo: uploadCode,
            cancelFlag: "",
            sortBy: 1,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
        }
        else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message : msg, type:ToastTypeEnum.Error})
        }
    }

    return <>
        <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8">
            <div className="grid  grid-cols-[100px,300px,100px,1fr]">
                <div className='flex items-center '>
                    <Title>類別代碼</Title>
                </div>
                <div className='flex items-center'>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={typeCode}
                        onChange={handleTypeCodeChange}
                    />
                </div>
                <div className='flex items-center'>
                    <Title>
                        類別名稱
                    </Title>
                </div>
                <div className='flex items-center'>

                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={typeName}
                        onChange={handleTypeNameChange}
                    />
                </div>

            </div>

            <div className="mt-2 grid  grid-cols-[100px,300px,100px,300px,100px,300px]">
                <div className='flex items-center '>
                    <Title>項目代碼</Title>
                </div>
                <div className='flex items-center'>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={itemCode}
                        onChange={handleItemCodeChange}
                    />
                </div>
                <div className='flex items-center'>
                    <Title>
                        項目名稱
                    </Title>
                </div>
                <div className='flex items-center'>

                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={itemName}
                        onChange={handleItemNameChange}
                    />
                </div>
                <div className='flex items-center'>
                    <Title>
                        上傳碼
                    </Title>
                </div>
                <div className='flex items-center'>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={uploadCode}
                        onChange={handleUploadCodeChange}
                    />
                </div>

            </div>
            <div className="mt-2 grid  grid-cols-[100px,260px,100px,1fr]">
                <div className='flex items-center '>
                    <Title>開始日期</Title>
                </div>
                <input
                    className={InputStyle}
                    type="date"
                    value={startDate}
                    onChange={(e) => handleUpdateStartDate(e)}
                />
                <span className="m-1">~</span>
                <input
                    className={InputStyle}
                    value={endDate}
                    onChange={(e) => handleUpdateEndDate(e)}
                    type="date"
                />
            </div>

        </div>
        <div
            className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <Button classNames={`ml-4 ${ButtonStyle}`} text={"存檔"} onClickFn={handleSave}/>
        </div>

    </>
}

export default ReferralCodeMaintainForm