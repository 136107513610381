
import React, {useEffect, useMemo, useState} from 'react';
import addIcon from "../../../../assets/images/icons/add_circle.svg";
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import Button from "../../../Button/Button";
import LocalStorageFormSetting from "../../Share/LocalStorageFormSetting";
import {BaseInput} from "../../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import RadioButton from "../../../Input/RadioButton";
import {
    WOUND_RECORD_HEADER,
    WoundCategoryListMap
} from "./formOption";
import StrategicAllianceMaintainForm, {ModalStatus} from "../../../ReferralSystem/StrategicAllianceMaintain/StrategicAllianceMaintainForm";
import {STRATEGIC_ALLIANCE_MAINTAIN} from "../../../../constants/referralSystem/maintainAreaHeader";
import CustomTable from "../../../ReferralSystem/CustomTable";
import {Pagination} from "../../../Pagination/Pagination";
import {BasePopup} from "../../../Popup/BasePopup";
import WoundRecordForm from "./WoundRecordForm";

const InputStyle = "w-[140px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SecondButtonStyle = "bg-gray-50 flex items-center justify-center px-4 h-10 rounded-[6px] border-2"

const Layout = ({children})=> {
    return (
        <div className="p-4 w-full overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md h-full">
            {children}
        </div>
    )
}
/**
 * 分隔線
 * @return {JSX.Element}
 */
const Divider = ()=> {
    return  <div className="border-t-[1px] border-[rgba(0,0,0,0.15)] my-8"></div>
}
/**
 * 標題
 * @return {JSX.Element}
 */
const Title = ({children, extendClass})=> {
    return (
        <div className={`flex flex-row items-center justify-start ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}

/**
 * 迷你營養評估
 * @return {JSX.Element}
 */
const WoundRecord = () => {
    /**
     * 初始值
     * @type {{legLong: number, optionFour: number, kneeLong: number, hipLong: number, optionTwo: number, optionThree: number, optionOne: number, armLong: number, optionSix: number, evaluationDate: string, calorie: number, evaluator: string, optionFive: number}}
     */
    const initData = {
        evaluationDate: "", // 評估日期
        evaluator: "", // 評估人員
        wound: "",
        pressure: false,
        diabeticFoot: false,
        incontinenceAssociated: false,
        tumorWound: false,
        other: false,
        otherString: "",
        dataList: []
    }
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })

    // 預設表單資料
    const [formData, setFormData] = useState(null);
    // 選項們
    const [woundCategoryList, setWoundCategoryList] = useState({
        pressure: formData?.pressure || false ,
        diabeticFoot: formData?.diabeticFoot || false,
        incontinenceAssociated: formData?.incontinenceAssociated || false,
        tumorWound: formData?.tumorWound || false,
        other: formData?.other || false
    })
    /**
     * 表格狀態
     */
    const [modalInfo, setModalInfo] = useState({
        show: false,
        modalStatus: ModalStatus.ADD,
    })
    const [activeRow, setActiveRow] = useState({})

    /**
     * 更改表單資料
     * @param e
     * @param item
     */
    const handleInputChange = (e, item) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [item]: value
        });
    };
    /**
     * 更改 CheckBox 表單資料
     * @param item
     */
    const handleCheckBoxChange = (item) => {
        setFormData({
            ...formData,
            [item]: !formData[item]
        });
    };

    /**
     * 新增表單內容
     * @param data
     */
    const handleInitData = (data) =>{
        setFormData({...initData, ...data})
    };

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
            currentPage: 1
        })

    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }
    /**
     * 表格中的編輯按鈕
     * @param {object} tableRow 要編輯的單筆資料
     * @param field
     * @param index
     * @returns {JSX.Element}
     */
    const Edit = (tableRow,field, index) => (
        <div className="flex items-center justify-center">
            <button
                className="text-[#007CDB] flex"
                onClick={() => {
                    setActiveRow({index: index, tableRow: tableRow})
                    setModalInfo({
                        show: true,
                        modalStatus: ModalStatus.EDIT
                    })
                }}
            >
                <EditIcon/>
                <div className="ml-1">編輯</div>
            </button>
        </div>
    )
    /**
     * 新增欄位
     */
    const AddNewRowBtn = () => {
        return (
            <button className="flex items-center text-green-600" onClick={()=>{
                setModalInfo({
                    modalStatus: ModalStatus.ADD,
                    show: true
                })
            }}>
                <div className="py-[10px] h-10 mr-1">
                    <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                </div>
                新增
            </button>
        )
    }

    /**
     * 儲存表格
     * @param data
     */
    const handleSaveTableRow = (data) =>{
        let rawData = [...formData.dataList]
        if(modalInfo.modalStatus === ModalStatus.ADD){
            rawData = [...rawData, data ]
        }else{
            rawData[activeRow.index] = data
        }
        setFormData({...formData, dataList: rawData})
        handleClosePopup()
    }

    /**
     * 長寬高
     * @param {object} tableRow 要編輯的單筆資料
     * @param field
     * @param index
     * @returns {JSX.Element}
     */
    const LongWidthDepth = (tableRow,field, index) => (
        <div className="flex items-center justify-center">
            <div>
                {tableRow.long} / {tableRow.width} / {tableRow.depth}
            </div>
        </div>
    )

    /**
     * 關閉彈窗
     */
    const handleClosePopup = () =>{
        setModalInfo({
            modalStatus: ModalStatus.ADD,
            show: false
        })
    }
    /**
     * 轉出清單 table header
     */
    const renderFields = useMemo(() => {
        WOUND_RECORD_HEADER[0] = {
            ...WOUND_RECORD_HEADER[0],
            render: Edit,
        }
        WOUND_RECORD_HEADER[3] = {
            ...WOUND_RECORD_HEADER[3],
            render: LongWidthDepth,
        }
        return WOUND_RECORD_HEADER
    },[])

    /**
     * 取得表格分頁
     * @function getSplitDataList
     * @returns {Array}
     */
    const getSplitDataList = () => {
        const rawData = [...formData.dataList]
        const start = (filter.currentPage - 1) * filter.pageSize
        const end = filter.currentPage * filter.pageSize -1
        return rawData.slice(start, end)
    }

    /**
     * 刪除
     */
    const handleDeleteTableRow = () =>{
        const dataList = [...formData?.dataList]
        dataList.splice(activeRow.index, 1)
        setFormData({...formData, dataList: dataList})
        handleClosePopup()
    }

    useEffect(() => {
        const  total = formData?.dataList.length || 0
        setFilter({
            ...filter,
            total:total ,
            totalPage: Math.ceil(total / filter.pageSize)
        })
    },[formData?.dataList.length, filter.pageSize])
    return <Layout>
        {/* 標題 */}
        <div className="flex items-center">
            <div className="w-[8px] h-[20px] bg-[#2B6CB0]"/>
            <div className="ml-2 text-[#2B6CB0] text-[20px]">傷口紀錄單</div>
        </div>
        {/* 本地儲存 */}
        <LocalStorageFormSetting name="wound-record" data={formData} onClickForm={(data)=>handleInitData(data)}/>
        { formData && <>
            {/* 評估人員 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>評估日期：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Date}
                        value={formData.evaluationDate}
                        onChange={(e) => {
                            handleInputChange(e, "evaluationDate")
                        }}
                    />
                    <Title extendClass={'ml-4'}>
                        評估人員：
                    </Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.evaluator}
                        onChange={(e) => {
                            handleInputChange(e, "evaluator")
                        }}
                    />
                </div>
            </div>
            {/* 傷口部位 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>傷口部位：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.wound}
                        onChange={(e) => {
                            handleInputChange(e, "wound")
                        }}
                    />
                    <Title extendClass={'ml-4'}>
                        種類：
                    </Title>
                    <div className="flex items-center">
                        {
                            Object.keys(woundCategoryList).map((item, index) => {
                                    return (
                                        <div className={`flex space-x-3 mr-4`}>
                                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                                   type="checkbox"
                                                   style={{accentColor: "#38A169"}} checked={formData[item]}
                                                   onChange={() => handleCheckBoxChange(item)}/>
                                            <label className="text-base">{WoundCategoryListMap[item]}</label>
                                        </div>
                                    )
                                }
                            )
                        }
                        <Title>其他說明：</Title>
                        <BaseInput
                            className={InputStyle}
                            type={InputTypeEnum.Text}
                            value={formData.otherString}
                            onChange={(e) => {
                                handleInputChange(e, "otherString")
                            }}
                        />

                    </div>
                </div>
            </div>
            <CustomTable
                isSelectable={false}
                fields={renderFields}
                dataList={getSplitDataList()}
                totalPage={filter.page}
                total={filter.total}
                isShoeDataFilter={false}
                slotButton={<AddNewRowBtn/>}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            {modalInfo.show &&
                <BasePopup
                    title={"傷口處理明細"}
                    width="65%"
                    content={<WoundRecordForm
                        data={activeRow}
                        status={modalInfo.modalStatus}
                        onSave={(data)=>handleSaveTableRow(data)}
                        onDelete={() => handleDeleteTableRow()}
                    />}
                    closePopupButtonOnClick={()=>{
                        handleClosePopup()
                    }}
                />
            }
        </>
        }
    </Layout>
}
export default WoundRecord