import React, { useEffect, useState } from 'react'
import { BasePopup } from '../../Popup/BasePopup'
import RadioButton from '../../Input/RadioButton'
import Button from '../../Button/Button'
import { BaseInput } from '../../Input/BaseInput'
import { InputTypeEnum } from 'edah_utils/dist'
import ExtendBtnTextArea from '../../Input/ExtendBtnTextArea'
import ReferralTurnInModal from './ReferralTurnInModal'
import ReferralTurnOutModal from './ReferralTurnOutModal'
import { queryTranCodeList, trancaseQueryBasicData } from '../../../api/v1/Rmis'
import DoctorDatalist from '../DoctorDatalist'
import DivisionDatalist from '../DivisionDatalist'
import {
    time2String,
    ApiErrorStatusEnum,
    ToastTypeEnum,
    objectIsEmpty,
    stringIsEmpty
} from 'edah_utils/dist'
import { DATETIME_FORMAT, DATE_FORMAT } from '../../../constants/common'
import {
    queryTranTypeByEncounterId,
    saveTranTypeByEncounterId,
} from '../../../api/v1/Rmis'
import useToast from '../../../hooks/useToast'
import { t } from 'i18next'
import store from '../../../redux/store'
import {
    completePrintMode,
    updatePrintContent,
} from '../../../redux/Slice/printSlice'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import { useSelector } from 'react-redux'
import {getBirthDayAndDetailedAge} from "../utils";

const InputStyle =
    'w-[200px] h-10  px-2 text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const Layout = ({ children }) => {
    return (
        <div className="p-5 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}
const Divider = () => {
    return <div className="border-t-[1px] border-[rgba(0,0,0,0.15)] my-8"></div>
}
/**
 * 轉診開單作業
 * @returns {Element}
 */
const ReferralTicketModalContent = ({ selectedVisitInfo, onClose }) => {
    // 開單預設資料
    const defaultInfo = {
        // 開單內容
        tranType: '',
        // 病歷號
        patientId: '',
        // 姓名
        patientName: '',
        // 性別代號
        gender: '',
        // 性別
        genderName: '',
        // 出生日期
        birthDate: '',
        // 身分證號
        idNo: '',
        // 連絡電話
        tel: '',
        // 聯絡人
        lianluoren: '',
        // 聯絡地址
        lianluoAddress: '',
        // 開立醫師代號
        doctorNo: '',
        // 開立醫師
        doctorName: '',
        // 病情摘要
        subject: '',
        // 診斷
        treatmentCode: '',
        treatmentCode1: '',
        treatmentCode2: '',
        treatmentName: '',
        treatmentName1: '',
        treatmentName2: '',
        // 檢查及治療摘要
        clinicInfo: '',
        tranIn: {},
        tranOut: {},
    }
    // 開單資料
    const [ticketInfo, setTicketInfo] = useState(defaultInfo)
    // 基礎資料
    const [baseDropdownData, setBaseDropdownData] = useState({
        // 轉診原因
        tranPurposeList: [],
        // 轉出原因
        tranReasonList: [],
    })
    //基礎資料
    const [baseData, setBaseData] = useState({
        // 醫師清單
        doctorList: [],
        // 科別清單
        divList: [],
        // 開單內容選項
        tranTypeList: [],
        // 轉診結果下拉選單
        tranFindingList: [],
    })

    // 轉診單資料
    const [turnOutData, setTurnOutData] = useState(defaultInfo.tranOut)

    // 回覆單資料
    const [turnInData, setTurnInData] = useState(defaultInfo.tranIn)

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const showToast = useToast()

    // 更新開單資料
    const updateTicketInfo = (e, field) => {
        setTicketInfo((prev) => {
            return {
                ...prev,
                [field]: e.target.value,
            }
        })
    }

    // 更新開單內容
    const handleTranTypeChange = (value) => {
        setTicketInfo((prev) => {
            return {
                ...prev,
                tranType: value,
            }
        })
    }

    /**
     * 轉診回診證明單
     * @param value
     */
    const handleReturnTicket = (value) => {
        const printData = ticketInfo
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.returnTicket,
                printData,
            })
        )
    }

    /**
     * 轉入單列印
     * @param value
     */
    const handleTurnInTicket = (value) => {
        const printData = ticketInfo
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranInForm,
                printData,
            })
        )
    }

    /**
     * 轉診單列印
     * @param value
     */
    const handleTurnOutTicket = (value) => {
        const tranReason = baseDropdownData.tranReasonList.find(
            (item) => turnOutData.tranReason === item.codeNo
        )?.codeTypeName
        const printData = { ...ticketInfo, tranReason: tranReason }
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranOutForm,
                printData,
            })
        )
    }
    /**
     * Subject
     * @param value
     */
    const handleSubjectClick = (value) => {
        // TODO
    }
    /**
     * Object
     * @param value
     */
    const handleObjectClick = (value) => {
        // TODO
    }
    /**
     * ER
     * @param value
     */
    const handleErClick = (value) => {
        // TODO
    }
    /**
     * S/O
     * @param value
     */
    const handleSOClick = (value) => {
        // TODO
    }
    /**
     * 存檔
     */
    const handleSave = async () => {
        // [轉診原因-本院無該檢查]代碼
        const TRAN_REASON_NO_REVELANT_TEST = '46'
         // [轉診原因-本院無該治療]代碼
        const TRAN_REASON_NO_REVELANT_TREATMENT = '47'
        console.log(turnOutData.tranReason, stringIsEmpty(turnOutData.tranReason))
        if (
            (turnOutData.tranReason === TRAN_REASON_NO_REVELANT_TEST ||
            turnOutData.tranReason === TRAN_REASON_NO_REVELANT_TREATMENT) && stringIsEmpty(turnOutData.tranReasonRemark2)
        ) {
            return
        }
        const value = {
            encounterDate: ticketInfo.encounterDate,
            encounterId: ticketInfo.encounterId,
            patientId: ticketInfo.patientId,
            tranType: ticketInfo.tranType,
            divNo: ticketInfo?.divNo,
            inpOpd: ticketInfo?.inpOpd,
            doctorNo: ticketInfo.doctorNo,
            treatmentCode: ticketInfo.treatmentCode,
            treatmentCode1: ticketInfo.treatmentCode1,
            treatmentCode2: ticketInfo.treatmentCode2,
            lianluoren: ticketInfo.lianluoren,
            lianluoAddress: ticketInfo.lianluoAddress,
            idNo: ticketInfo.idNo,
            subject: ticketInfo.subject,
            clinicInfo: ticketInfo.clinicInfo,
            tranIn: turnInData
                ? {
                      ...turnInData,
                      tranDate: turnInData.tranDate
                          ? time2String(turnInData.tranDate, DATETIME_FORMAT)
                          : '',
                  }
                : {},
            tranOut: turnOutData || {},
        }

        const res = await saveTranTypeByEncounterId(value)

        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
            onClose()
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({ message: msg, type: ToastTypeEnum.Error })
        }
    }

    /**
     * 選擇科別
     * @param {object} division 科別
     */
    const selectDivision = (division) => {
        setTicketInfo((prev) => {
            return {
                ...prev,
                divNo: division?.divNo ? division.divNo : '',
                divName: division?.divName ? division.divName : '',
            }
        })
    }

    /**
     * 選擇開單醫師
     * @param {object} doctor 開單醫師
     */
    const selectDoctor = (doctor) => {
        if (!doctor) {
            return
        }
        setTicketInfo((prev) => {
            return {
                ...prev,
                doctorNo: doctor.doctorNo ? doctor.doctorNo : '',
                doctorName: doctor.doctorName ? doctor.doctorName : '',
            }
        })
    }

    /**
     * 取得轉診單資料
     * @return {void}
     */
    const getReferralInfo = () => {
        queryTranTypeByEncounterId({
            encounterId: selectedVisitInfo.patient.encounterId,
            encounterDate: selectedVisitInfo.patient.encounterDate,
            inpOpd: selectedVisitInfo.patient.inpOpd,
            patientId: selectedVisitInfo.patient.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = {}
                Object.keys(res.data).forEach((key) => {
                    if (key === 'tranIn' || key === 'tranOut') {
                        const newTranInfo = {}
                        const tranInfo = res.data[key]
                        Object.keys(tranInfo).forEach((field) => {
                            newTranInfo[field] = tranInfo[field] || ''
                        })
                        data[key] = newTranInfo
                    } else {
                        data[key] = res.data[key] || ''
                    }
                })
                setTicketInfo(() => ({ ...ticketInfo, ...data }))
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 取得基礎選單資料
     * @return {void}
     */
    const getBaseData = () => {
        trancaseQueryBasicData({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData({
                    ...res.data,
                    divList: res.data.divList || [],
                    doctorList: res.data.doctorList || [],
                })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 處理轉診單資料
     * @param data {object} 轉診單資料
     */
    const handleTurnOutData = (data) => {
        setTurnOutData((prev) => ({ ...prev, ...data }))
    }

    /**
     * 處理轉入單資料
     * @param data {object} 轉入單資料
     */
    const handleTurnInData = (data) => {
        setTurnInData((prev) => ({ ...prev, ...data }))
    }

    useEffect(() => {
        getReferralInfo()
        getBaseData()
    }, [])

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    useEffect(() => {
        Promise.all([
            queryTranCodeList({ codeType: 'TRAN_REASON' }),
            queryTranCodeList({ codeType: 'TRAN_PURPOSE' }),
        ]).then((res) => {
            const [tranReasonRes, tranPurposeRes] = res
            if (
                tranReasonRes.err === ApiErrorStatusEnum.Success &&
                tranPurposeRes.err === ApiErrorStatusEnum.Success
            ) {
                setBaseDropdownData((prev) => ({
                    ...prev,
                    tranPurposeList: tranPurposeRes.data || [],
                    tranReasonList: tranReasonRes.data || [],
                }))
            } else {
                showToast({
                    message: tranReasonRes.msg,
                    type: ToastTypeEnum.Error,
                })

                showToast({
                    message: tranPurposeRes.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }, [])

    return (
        <Layout>
            {/* 開單內容 */}
            <div className="grid grid-cols-[180px,630px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>開單內容</Title>
                </div>
                <div className="flex items-center justify-end ml-1">
                    {!objectIsEmpty(baseData) &&
                        baseData.tranTypeList.map((option, index) => (
                            <RadioButton
                                key={option.tranType}
                                wrapperClassName={
                                    index === 0 ? 'w-[330px]' : 'w-[300px]'
                                }
                                mode={ticketInfo.tranType}
                                modeChecked={option.tranType}
                                handleModeChange={(value) =>
                                    handleTranTypeChange(value)
                                }
                                label={option.tranTypeName}
                            />
                        ))}
                </div>

                <div className="flex items-center justify-end">
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'轉診回診證明單'}
                        onClickFn={handleReturnTicket}
                    />
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'回覆單列印'}
                        onClickFn={() => handleTurnInTicket()}
                    />

                    <Button
                        classNames={SecondButtonStyle}
                        text={'轉診單列印'}
                        onClickFn={() => handleTurnOutTicket()}
                    />
                </div>
            </div>
            {/* 病歷號 */}
            <div className="mt-2 grid grid-cols-[180px,220px,100px,220px,100px,220px,120px,300px]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>{t('general.patientNo')}</Title>
                </div>
                <div className="flex items-center">{ticketInfo.patientId}</div>
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>{t('general.username')}</Title>
                </div>
                <div className="flex items-center">
                    {ticketInfo.patientName}
                </div>
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>
                        {t('general.gender.name')}
                    </Title>
                </div>
                <div className="flex items-center">{ticketInfo.genderName}</div>
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>
                        {t('general.dateOfBirth')}
                    </Title>
                </div>
                <div className="flex items-center">
                    {getBirthDayAndDetailedAge(ticketInfo.birthDate)}
                </div>
            </div>
            {/* 身分證 */}
            <div className="mt-2 grid grid-cols-[180px,220px,100px,220px,100px,300px]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>{t('general.idNumber')}</Title>
                </div>
                <div className="flex items-center">{ticketInfo.idNo}</div>
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>{t('general.telephone')}</Title>
                </div>
                <div className="flex items-center">{ticketInfo.tel}</div>
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>聯絡人</Title>
                </div>
                <div className="flex items-center">{ticketInfo.lianluoren}</div>
            </div>
            {/* 聯絡地址 */}
            <div className="mt-2 grid grid-cols-[180px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>
                        {t('general.contactAddress')}
                    </Title>
                </div>
                <div className="flex items-center">
                    {ticketInfo.lianluoAddress}
                </div>
            </div>
            <Divider />
            <div className="grid grid-cols-[180px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>開單醫師</Title>
                </div>
                <DoctorDatalist
                    doctorList={baseData.doctorList}
                    onSelect={selectDoctor}
                    value={{
                        doctorNo: ticketInfo.doctorNo,
                        doctorName: ticketInfo.doctorName,
                    }}
                />
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>
                        {t('general.department')}
                    </Title>
                </div>
                <div className="flex items-center ml-1">
                    <DivisionDatalist
                        divisionList={baseData.divList}
                        onSelect={selectDivision}
                        value={{
                            divNo: ticketInfo?.divNo,
                            divName: ticketInfo.divName,
                        }}
                    />
                </div>
                <div className="flex items-center ">
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'Subject'}
                        onClickFn={handleSubjectClick}
                    />
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'Object'}
                        onClickFn={handleObjectClick}
                    />

                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'S/O'}
                        onClickFn={handleSOClick}
                    />
                    <Button
                        classNames={SecondButtonStyle}
                        text={'ER'}
                        onClickFn={handleErClick}
                    />
                </div>
            </div>
            {/* 病情摘要 */}
            <div className="mt-2 grid grid-cols-[180px,1fr]">
                <div className="flex flex-col items-end ">
                    <Title extendClass={'mr-8'}>
                        <div>A. 病情摘要</div>
                    </Title>
                    <Title extendClass={'mr-8'}>
                        <div>(主訴及簡短病史)</div>
                    </Title>
                </div>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={(e) => updateTicketInfo(e, 'subject')}
                    value={ticketInfo.subject}
                ></ExtendBtnTextArea>
            </div>
            {/* 診斷(ICD10) */}
            <div className="mt-2 grid grid-cols-[180px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>B. 診斷(ICD10)</Title>
                </div>
            </div>
            {/* 診斷1 */}
            <div className="mt-2 grid grid-cols-[180px,0.1fr,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>診斷1</Title>
                </div>
                <div className="mr-4">
                    <BaseInput
                        className="h-10 bg-white text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentCode}
                        onChange={(e) => updateTicketInfo(e, 'treatmentCode')}
                    />
                </div>
                <div>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentName}
                        disable
                    />
                </div>
            </div>
            {/* 診斷2 */}
            <div className="mt-2 grid grid-cols-[180px,0.1fr,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>診斷2</Title>
                </div>
                <div className="mr-4">
                    <BaseInput
                        className="h-10 bg-white text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentCode1}
                        onChange={(e) => updateTicketInfo(e, 'treatmentCode1')}
                    />
                </div>
                <div>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentName1}
                        disable
                    />
                </div>
            </div>
            {/* 診斷3 */}
            <div className="mt-2 grid grid-cols-[180px,0.1fr,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>診斷3</Title>
                </div>
                <div className="mr-4">
                    <BaseInput
                        className="h-10 bg-white text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentCode2}
                        onChange={(e) => updateTicketInfo(e, 'treatmentCode2')}
                    />
                </div>
                <div>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={ticketInfo.treatmentName2}
                        disable
                    />
                </div>
            </div>
            {/* 檢查與治療摘要 */}
            <div className="mt-2 grid grid-cols-[180px,1fr]">
                <div className="flex flex-col items-end ">
                    <Title extendClass={'mr-8'}>
                        <div>C. 檢查及治療摘要</div>
                    </Title>
                </div>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={(e) => updateTicketInfo(e, 'clinicInfo')}
                    value={ticketInfo.clinicInfo}
                ></ExtendBtnTextArea>
            </div>
            <Divider />
            {/* 回覆單 轉診單 */}
            <div className="flex">
                <ReferralTurnInModal
                    onUpdateData={handleTurnInData}
                    tranInInfo={ticketInfo?.tranIn}
                    tranFindingList={baseData?.tranFindingList}
                    divisionList={baseData?.divList}
                    zipCodeList={baseData?.zipCodeList}
                />
                <ReferralTurnOutModal
                    onUpdateData={handleTurnOutData}
                    tranOutInfo={ticketInfo?.tranOut}
                    divisionList={baseData?.divList}
                    vpnReasonList={baseData?.vpnReasonList}
                    zipCodeList={baseData?.zipCodeList}
                />
            </div>
            <Divider />
            {/* 存檔 */}
            <div className="flex justify-end">
                <Button
                    classNames={`ml-4 ${ButtonStyle}`}
                    text={t('general.saveFile')}
                    onClickFn={handleSave}
                />
            </div>
        </Layout>
    )
}
const ReferralTicketModal = ({ onClose, selectedVisitInfo }) => {
    return (
        <BasePopup
            title="轉診開單作業"
            width="90%"
            content={
                <ReferralTicketModalContent
                    selectedVisitInfo={selectedVisitInfo}
                    onClose={onClose}
                />
            }
            closePopupButtonOnClick={onClose}
        />
    )
}

export default ReferralTicketModal
