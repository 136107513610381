import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";

/**
 * 訪視紀錄
 * @returns {JSX.Element}
 */

const VisitRecord = () => {
    const [formData, setFormData] = useState({
        pipelineExpiry: false,
        wound: false,
        pipelineIssues: {
            pipelineIssues: false,
            nasalTube: {nasalTube: false, selfPull: false, slipOff: false, blockage: false},
            catheter: {catheter: false, selfPull: false, slipOff: false, blockage: false},
            tracheostomy: {tracheostomy: false, selfPull: false, slipOff: false, blockage: false},
            other: {
                boolean: false,
                input: ''
            },
        },

        // 訪視項目
        visitItems: '',

        // 心肺功能
        vitalSigns: {
            temperature: '',
            bloodPressure: '',
            pulseRate: {
                regular: true,
                rate: ''
            },
            respiration: {
                type: {
                    stable: false,
                    sitBreath: false,
                    openMouth: false,
                    airCut: false,
                    oxySupport: false
                },
                rate: '',
                frequency: ''
            },
            edema: {leftUpperLimb: '', leftLowerLimb: '', rightUpperLimb: '', rightLowerLimb: ''},
            breathSounds: {
                leftUpperLobe: '',
                leftLowerLobe: '',
                rightUpperLobe: '',
                rightMiddleLobe: '',
                rightLowerLobe: ''
            },
            sputum: {
                type: '',
                quantity: '',
                nature: {watery: false, sticky: false, dry: false},
                color: {
                    clear: false,
                    white: false,
                    yellow: false,
                    yellowGreen: false,
                    brown: false,
                    red: false,
                    other: false,
                    input: ''
                }
            },
        },
        consciousness: {
            externalConsciousness: '',
            comaIndex: {E: '', M: '', V: '', total: ''},
        },

        // 營養狀態及水分攝取
        nutrition: {
            route: {
                fromMouth: false,
                fromNose: false,
                stomach: false,
                intestinal: false
            },
            type: {
                normal: false,
                soft: false,
                halfLiquid: false,
                liquid: false,
                straw: false,
                handmade: false,
                commercial: false,
                handmadeCommercial: false
            },
            frequency: {
                meals: '',
                snacks: '',
                contents: '',
                straw: '',
                strawContents: ''
            },
            waterIntake: '',
            totalIntake: '',
            calories: {suggested: '', intake: ''},
            weight: {current: '', ideal: ''},
            skin: ''
        },

        // 排泄功能
        excretion: {
            urineHandling: {
                selfExcretion: false,
                diaper: false,
                urineSheath: false,
                catheter: false,
                catheterization: false,
                cystostomy: false,
                singleCatheterization: false
            },
            urineAmount: '',
            urineCondition: '',
            utiSigns: {
                hasUTI: false,
                burning: false,
                frequent: false,
                fever: false,
                hematuria: false,
                turbid: false,
                noUTI: false,
                other: ''
            },
            stoolCondition: '',
            bowelMovement: {sounds: '', distention: '', hardness: ''},
            bowelHandling: {
                selfExcretion: false,
                enema: false,
                manualEvacuation: false,
                stoolSoftener: false,
                other: false,
                input: ''
            },
        },

        // 情緒行為問題
        emotionalState: {
            emotion: {
                normal: false,
                negative: false,
                sadExpression: false,
                crying: false,
                withdrawal: false,
                other: false,
                input: ''
            },
            behavior: {
                normal: false,
                violentBehavior: false,
                unconsciousExtubation: false,
                inappropriateSpeech: false,
                other: false,
                input: ''
            },
            sleepPattern: '',
            pain: {
                no: false,
                yes: false,
                noComment: false
            },
        },

        // 護理措施
        nursingCare: {
            swallowingTraining: false,
            breathingTraining: false,
            catheterTraining: false,
            nasalTubeRemoval: false,
            tracheostomyRemoval: false,
            urinaryCatheterRemoval: false,
            dietitianVisit: false,
            woundCare: false,
            limbRehabilitation: false,
            bloodSugar: false,
            bloodSugarValue: '',
            beforeMeal: false,
            afterMeal: false,
            hours: '',
            other: false,
            input: ''
        },

        // 輔具評估
        equipment: {
            suctionMachine: {suctionMachine: false, good: false, bad: false, reason: ''},
            oxygenMachine: {oxygenMachine: false, good: false, bad: false, reason: ''},
            wheelchair: {wheelchair: false, good: false, bad: false, reason: ''},
            airBed: {airBed: false, good: false, bad: false, reason: ''},
            hospitalBed: {hospitalBed: false, good: false, bad: false, reason: ''},
            oximeter: {oximeter: false, good: false, bad: false, reason: ''},
            bloodPressureMonitor: {bloodPressureMonitor: false, good: false, bad: false, reason: ''},
            glucoseMeter: {glucoseMeter: false, good: false, bad: false, reason: ''},
            steamInhaler: {steamInhaler: false, good: false, bad: false, reason: ''},
            other: {other: false, good: false, bad: false, reason: ''},
        },

    });

    const equipmentLabels = {
        suctionMachine: '抽痰機',
        oxygenMachine: '氧氣製造機',
        wheelchair: '輪椅',
        airBed: '氣墊床',
        hospitalBed: '病床',
        oximeter: '血氧機',
        bloodPressureMonitor: '血壓機',
        glucoseMeter: '血糖機',
        steamInhaler: '蒸氣吸入器',
        other: '其他',
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleNestedChange = (category, field, subField, trdFiled, value) => {
        setFormData((prevState) => {
            if (trdFiled) {
                return {
                    ...prevState,
                    [category]: {
                        ...prevState[category],
                        [field]: {
                            ...prevState[category][field],
                            [subField]: {
                                ...prevState[category][field][subField],
                                [trdFiled]: value,
                            },
                        },
                    },
                };
            } else if (subField) {
                return {
                    ...prevState,
                    [category]: {
                        ...prevState[category],
                        [field]: {
                            ...prevState[category][field],
                            [subField]: value,
                        },
                    },
                };
            } else if (field) {
                return {
                    ...prevState,
                    [category]: {
                        ...prevState[category],
                        [field]: value,
                    },
                };
            } else {
                return {
                    ...prevState,
                    [category]: value,
                };
            }
        });
    };

    const handleComplexNestedChange = (section, subSection, field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [subSection]: {
                    ...prevState[section][subSection],
                    [field]: value,
                }
            },
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form submission
        console.log(formData);
    };

    const labelStyle = () => {
        return 'flex flex-row items-center justify-start mr-4'
    }

    return (
        <div className="w-full p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>訪視紀錄</span>
            </h3>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                    value={formData.evaluationDate}
                    onChange={(e) => setFormData({...formData, evaluationDate: e.target.value})}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                    value={formData.evaluator}
                    onChange={(e) => setFormData({...formData, evaluator: e.target.value})}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="">
                    <div className="h-[260px] flex flex-row items-center justify-start">
                        <h2 className="w-[346px] h-full flex-shrink-0 flex items-center justify-start border-x border-y border-[#111111]/15 px-2">訪視原因</h2>
                        <div className="w-full border-y border-r border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start px-2">
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineExpiry"
                                           checked={formData.pipelineExpiry}
                                           onChange={handleChange}
                                    />
                                    管路到期
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="wound"
                                           checked={formData.wound}
                                           onChange={handleChange}
                                    />
                                    傷口
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start px-2">
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.pipelineIssues"
                                           checked={formData.pipelineIssues.pipelineIssues}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'pipelineIssues', null, null, e.target.checked)}
                                    />
                                    管路異常
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.nasalTube.nasalTube"
                                           checked={formData.pipelineIssues.nasalTube.nasalTube}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'nasalTube', 'nasalTube', null, e.target.checked)
                                           }
                                    />
                                    鼻胃管
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.nasalTube.selfPull"
                                           checked={formData.pipelineIssues.nasalTube.selfPull}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'nasalTube', 'selfPull', null, e.target.checked)
                                           }
                                    />
                                    自拔
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.nasalTube.slipOff"
                                           checked={formData.pipelineIssues.nasalTube.slipOff}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'nasalTube', 'slipOff', null, e.target.checked)
                                           }
                                    />
                                    滑脫
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.nasalTube.blockage"
                                           checked={formData.pipelineIssues.nasalTube.blockage}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'nasalTube', 'blockage', null, e.target.checked)
                                           }
                                    />阻塞
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start px-2">
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.catheter.catheter"
                                           checked={formData.pipelineIssues.catheter.catheter}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'catheter', 'catheter', null, e.target.checked)
                                           }
                                    />
                                    導尿管
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.catheter.selfPull"
                                           checked={formData.pipelineIssues.catheter.selfPull}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'catheter', 'selfPull', null, e.target.checked)
                                           }
                                    />
                                    自拔
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.catheter.slipOff"
                                           checked={formData.pipelineIssues.catheter.slipOff}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'catheter', 'slipOff', null, e.target.checked)
                                           }
                                    />
                                    滑脫
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.catheter.blockage"
                                           checked={formData.pipelineIssues.catheter.blockage}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'catheter', 'blockage', null, e.target.checked)
                                           }
                                    />
                                    阻塞
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start px-2">
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.tracheostomy.tracheostomy"
                                           checked={formData.pipelineIssues.tracheostomy.tracheostomy}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'tracheostomy', 'tracheostomy', null, e.target.checked)
                                           }
                                    />
                                    氣切
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.tracheostomy.selfPull"
                                           checked={formData.pipelineIssues.tracheostomy.selfPull}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'tracheostomy', 'selfPull', null, e.target.checked)
                                           }
                                    />
                                    自拔
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.tracheostomy.slipOff"
                                           checked={formData.pipelineIssues.tracheostomy.slipOff}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'tracheostomy', 'slipOff', null, e.target.checked)
                                           }
                                    />
                                    滑脫
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.tracheostomy.blockage"
                                           checked={formData.pipelineIssues.tracheostomy.blockage}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'tracheostomy', 'blockage', null, e.target.checked)
                                           }
                                    />
                                    阻塞
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start px-2">
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="pipelineIssues.other.boolean"
                                           checked={formData.pipelineIssues.other.boolean}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'other', 'boolean', null, e.target.checked)
                                           }
                                    />
                                    其他:
                                </label>
                                <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                           type={InputTypeEnum.Text}
                                           name="pipelineIssues.other.input"
                                           value={formData.pipelineIssues.other.input}
                                           onChange={(e) =>
                                               handleNestedChange('pipelineIssues', 'other', 'input', null, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-start h-[52px]">
                        <h2 className="w-[346px] flex-shrink-0 h-full flex items-center justify-start border-b border-x border-[#111111]/15 px-2">
                            訪視項目
                        </h2>
                        <div
                            className="w-full h-[52px] px-2 flex items-center justify-start border-b border-r border-[#111111]/15">
                            <BaseInput
                                className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type={InputTypeEnum.Text}
                                name="visitItems"
                                value={formData.visitItems}
                                onChange={(e) =>
                                    handleNestedChange('visitItems', null, null, null, e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[572px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>心肺功能</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">體溫</p>
                                <BaseInput
                                    className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                    name="vitalSigns.temperature"
                                    value={formData.vitalSigns.temperature}
                                    onChange={(e) =>
                                        handleNestedChange('vitalSigns', 'temperature', null, null, e.target.value)
                                    }
                                />
                                度C
                            </div>
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">血壓</p>
                                <BaseInput
                                    className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                    name="vitalSigns.bloodPressure"
                                    value={formData.vitalSigns.bloodPressure}
                                    onChange={(e) =>
                                        handleNestedChange('vitalSigns', 'bloodPressure', null, null, e.target.value)
                                    }
                                />
                                mmHg
                            </div>
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">脈搏速率</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="vitalSigns.pulseRate.regular"
                                           checked={formData.vitalSigns.pulseRate.regular}
                                           onChange={() =>
                                               handleNestedChange('vitalSigns', 'pulseRate', null, null, {
                                                   ...formData.vitalSigns.pulseRate,
                                                   regular: true
                                               })
                                           }
                                    />
                                    規則
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="vitalSigns.pulseRate.regular"
                                           checked={!formData.vitalSigns.pulseRate.regular}
                                           onChange={() =>
                                               handleNestedChange('vitalSigns', 'pulseRate', null, null, {
                                                   ...formData.vitalSigns.pulseRate,
                                                   regular: false
                                               })
                                           }
                                    />
                                    不規則
                                </label>
                                <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                           type={InputTypeEnum.Text}
                                           name="vitalSigns.pulseRate.rate"
                                           value={formData.vitalSigns.pulseRate.rate}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'pulseRate', 'rate', null, e.target.value)
                                           }
                                />
                                次/分
                            </div>
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">呼吸</p>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="vitalSigns.respiration.type.stable"
                                           checked={formData.vitalSigns.respiration.type.stable}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'respiration', 'type', 'stable', e.target.checked)
                                           }
                                    />平穩
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="vitalSigns.respiration.type.sitBreath"
                                           checked={formData.vitalSigns.respiration.type.sitBreath}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'respiration', 'type', 'sitBreath', e.target.checked)
                                           }
                                    />端坐呼吸
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="vitalSigns.respiration.type.openMouth"
                                           checked={formData.vitalSigns.respiration.type.openMouth}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'respiration', 'type', 'openMouth', e.target.checked)
                                           }
                                    />張口呼吸
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="vitalSigns.respiration.type.airCut"
                                           checked={formData.vitalSigns.respiration.type.airCut}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'respiration', 'type', 'airCut', e.target.checked)
                                           }
                                    />氣切
                                </label>
                                <label className={labelStyle()}>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="vitalSigns.respiration.type.oxySupport"
                                           checked={formData.vitalSigns.respiration.type.oxySupport}
                                           onChange={(e) =>
                                               handleNestedChange('vitalSigns', 'respiration', 'type', 'oxySupport', e.target.checked)
                                           }
                                    />氧氣輔助
                                </label>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                    name="vitalSigns.respiration.rate"
                                    value={formData.vitalSigns.respiration.rate}
                                    onChange={(e) =>
                                        handleNestedChange('vitalSigns', 'respiration', 'rate', null, e.target.value)
                                    }
                                />
                                <span>次數</span>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] m-2"
                                    type={InputTypeEnum.Text}
                                    name="vitalSigns.respiration.frequency"
                                    value={formData.vitalSigns.respiration.frequency}
                                    onChange={(e) =>
                                        handleNestedChange('vitalSigns', 'respiration', 'frequency', null, e.target.value)
                                    }
                                />
                                <span>次/分</span>
                            </div>
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">肢體水腫</p>
                                <p className="mr-2">左上肢</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="vitalSigns.edema.leftUpperLimb"
                                        value={formData.vitalSigns.edema.leftUpperLimb}
                                        onChange={(e) =>
                                            handleNestedChange('vitalSigns', 'edema', 'leftUpperLimb', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                    <option value="有">有</option>
                                    <option value="無">無</option>
                                </select>
                                <p className="mr-2">左下肢</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="vitalSigns.edema.leftLowerLimb"
                                        value={formData.vitalSigns.edema.leftLowerLimb}
                                        onChange={(e) =>
                                            handleNestedChange('vitalSigns', 'edema', 'leftLowerLimb', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                    <option value="有">有</option>
                                    <option value="無">無</option>
                                </select>
                                <p className="mr-2">右上肢</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="vitalSigns.edema.rightUpperLimb"
                                        value={formData.vitalSigns.edema.rightUpperLimb}
                                        onChange={(e) =>
                                            handleNestedChange('vitalSigns', 'edema', 'rightUpperLimb', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                    <option value="有">有</option>
                                    <option value="無">無</option>
                                </select>
                                <p className="mr-2">右下肢</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                        name="vitalSigns.edema.rightLowerLimb"
                                        value={formData.vitalSigns.edema.rightLowerLimb}
                                        onChange={(e) =>
                                            handleNestedChange('vitalSigns', 'edema', 'rightLowerLimb', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                    <option value="有">有</option>
                                    <option value="無">無</option>
                                </select>
                            </div>
                            <div
                                className="h-[104px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">呼吸音</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="mr-2">左肺上葉</p>
                                        <select
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="vitalSigns.breathSounds.leftUpperLobe"
                                            value={formData.vitalSigns.breathSounds.leftUpperLobe}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'breathSounds', 'leftUpperLobe', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                            <option value="有">有</option>
                                            <option value="無">無</option>
                                        </select>
                                        <p className="mr-2">左肺下葉</p>
                                        <select
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="vitalSigns.breathSounds.leftLowerLobe"
                                            value={formData.vitalSigns.breathSounds.leftLowerLobe}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'breathSounds', 'leftLowerLobe', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                            <option value="有">有</option>
                                            <option value="無">無</option>
                                        </select>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="mr-2">右肺上葉</p>
                                        <select
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="vitalSigns.breathSounds.rightUpperLobe"
                                            value={formData.vitalSigns.breathSounds.rightUpperLobe}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'breathSounds', 'rightUpperLobe', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                            <option value="有">有</option>
                                            <option value="無">無</option>
                                        </select>
                                        <p className="mr-2">右肺中葉</p>
                                        <select
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="vitalSigns.breathSounds.rightMiddleLobe"
                                            value={formData.vitalSigns.breathSounds.rightMiddleLobe}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'breathSounds', 'rightMiddleLobe', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                            <option value="有">有</option>
                                            <option value="無">無</option>
                                        </select>
                                        <p className="mr-2">右肺下葉</p>
                                        <select
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            name="vitalSigns.breathSounds.rightLowerLobe"
                                            value={formData.vitalSigns.breathSounds.rightLowerLobe}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'breathSounds', 'rightLowerLobe', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                            <option value="有">有</option>
                                            <option value="無">無</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">排痰型態</p>
                                <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                        name="vitalSigns.sputum.type"
                                        value={formData.vitalSigns.sputum.type}
                                        onChange={(e) =>
                                            handleNestedChange('vitalSigns', 'sputum', 'type', null, e.target.value)
                                        }
                                />
                            </div>
                            <div
                                className="h-[156px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">痰液</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        量
                                        <BaseInput
                                            className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                            type="text"
                                            name="vitalSigns.sputum.quantity"
                                            value={formData.vitalSigns.sputum.quantity}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'sputum', 'quantity', null, e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        性質:
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.nature.watery"
                                                checked={formData.vitalSigns.sputum.nature.watery}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'nature', 'watery', {
                                                        ...formData.vitalSigns.sputum.nature,
                                                        watery: e.target.checked
                                                    })
                                                }
                                            />
                                            水樣
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.nature.sticky"
                                                checked={formData.vitalSigns.sputum.nature.sticky}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'nature', 'sticky', {
                                                        ...formData.vitalSigns.sputum.nature,
                                                        sticky: e.target.checked
                                                    })
                                                }
                                            />
                                            濕黏
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.nature.dry"
                                                checked={formData.vitalSigns.sputum.nature.dry}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'nature', 'dry', {
                                                        ...formData.vitalSigns.sputum.nature,
                                                        dry: e.target.checked
                                                    })
                                                }
                                            />
                                            乾燥
                                        </label>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        顏色:
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.clear"
                                                checked={formData.vitalSigns.sputum.color.clear}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'clear', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        clear: e.target.checked
                                                    })
                                                }
                                            />
                                            清澈
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.white"
                                                checked={formData.vitalSigns.sputum.color.white}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'white', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        white: e.target.checked
                                                    })
                                                }
                                            />
                                            白
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.yellow"
                                                checked={formData.vitalSigns.sputum.color.yellow}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'yellow', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        yellow: e.target.checked
                                                    })
                                                }
                                            />
                                            黃
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.yellowGreen"
                                                checked={formData.vitalSigns.sputum.color.yellowGreen}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'yellowGreen', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        yellowGreen: e.target.checked
                                                    })
                                                }
                                            />
                                            黃綠
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.brown"
                                                checked={formData.vitalSigns.sputum.color.brown}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'brown', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        brown: e.target.checked
                                                    })
                                                }
                                            />
                                            黃褐
                                        </label>
                                        <label className={labelStyle()}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.red"
                                                checked={formData.vitalSigns.sputum.color.red}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'red', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        red: e.target.checked
                                                    })
                                                }
                                            />
                                            紅
                                        </label>
                                        <label className={`${labelStyle()} mr-0`}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                style={{accentColor: "#38A169"}}
                                                type="checkbox"
                                                name="vitalSigns.sputum.color.other"
                                                checked={formData.vitalSigns.sputum.color.other}
                                                onChange={(e) =>
                                                    handleNestedChange('vitalSigns', 'sputum', 'color', 'other', {
                                                        ...formData.vitalSigns.sputum.color,
                                                        other: e.target.checked
                                                    })
                                                }
                                            />
                                            其他
                                        </label>
                                        <BaseInput
                                            className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                            type={InputTypeEnum.Text}
                                            name="vitalSigns.sputum.color.input"
                                            value={formData.vitalSigns.sputum.color.input}
                                            onChange={(e) =>
                                                handleNestedChange('vitalSigns', 'sputum', 'color', 'input', {
                                                    ...formData.vitalSigns.sputum.color,
                                                    input: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[104px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>意識狀態</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">外表意識</p>
                                <BaseInput
                                    className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="formData.consciousness.externalConsciousness"
                                    value={formData.consciousness.externalConsciousness}
                                    onChange={(e) =>
                                        handleNestedChange('consciousness', 'externalConsciousness', null, null, e.target.value)
                                    }
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">昏迷指數</p>
                                <p className="mr-2">E</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="formData.consciousness.comaIndex.E"
                                        value={formData.consciousness.comaIndex.E}
                                        onChange={(e) =>
                                            handleNestedChange('consciousness', 'comaIndex', 'E', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                                <p className="mr-2">M</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="formData.consciousness.comaIndex.M"
                                        value={formData.consciousness.comaIndex.M}
                                        onChange={(e) =>
                                            handleNestedChange('consciousness', 'comaIndex', 'M', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                                <p className="mr-2">V</p>
                                <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="formData.consciousness.comaIndex.V"
                                        value={formData.consciousness.comaIndex.V}
                                        onChange={(e) =>
                                            handleNestedChange('consciousness', 'comaIndex', 'V', null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                                <p>總分</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[624px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>營養狀態及水份攝取</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">營養途徑</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nutrition.route.fromMouth"
                                           checked={formData.nutrition.route.fromMouth}
                                           onChange={(e) =>
                                               handleNestedChange('nutrition', 'route', 'fromMouth', null, e.target.checked)
                                           }
                                    />
                                    由口進食
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nutrition.route.fromNose"
                                           checked={formData.nutrition.route.fromNose}
                                           onChange={(e) =>
                                               handleNestedChange('nutrition', 'route', 'fromNose', null, e.target.checked)
                                           }
                                    />
                                    鼻胃管
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nutrition.route.stomach"
                                           checked={formData.nutrition.route.stomach}
                                           onChange={(e) =>
                                               handleNestedChange('nutrition', 'route', 'stomach', null, e.target.checked)
                                           }
                                    />
                                    胃造口
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nutrition.route.intestinal"
                                           checked={formData.nutrition.route.intestinal}
                                           onChange={(e) =>
                                               handleNestedChange('nutrition', 'route', 'intestinal', null, e.target.checked)
                                           }
                                    />
                                    腸造口
                                </label>
                            </div>
                            <div className="h-[104px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">進食型態</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.normal"
                                                   checked={formData.nutrition.type.normal}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'normal', null, e.target.checked)
                                                   }
                                            />
                                            正常飲食
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.soft"
                                                   checked={formData.nutrition.type.soft}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'soft', null, e.target.checked)
                                                   }
                                            />
                                            軟質飲食
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.halfLiquid"
                                                   checked={formData.nutrition.type.halfLiquid}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'halfLiquid', null, e.target.checked)
                                                   }
                                            />
                                            半流質飲食
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.liquid"
                                                   checked={formData.nutrition.type.liquid}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'liquid', null, e.target.checked)
                                                   }
                                            />
                                            流質飲食
                                        </label>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.straw"
                                                   checked={formData.nutrition.type.straw}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'straw', null, e.target.checked)
                                                   }
                                            />
                                            管灌飲食
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.handmade"
                                                   checked={formData.nutrition.type.handmade}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'handmade', null, e.target.checked)
                                                   }
                                            />
                                            自製攪打飲食
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.commercial"
                                                   checked={formData.nutrition.type.commercial}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'commercial', null, e.target.checked)
                                                   }
                                            />
                                            商業配方
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.nutrition.type.handmadeCommercial"
                                                   checked={formData.nutrition.type.handmadeCommercial}
                                                   onChange={(e) =>
                                                       handleNestedChange('nutrition', 'type', 'handmadeCommercial', null, e.target.checked)
                                                   }
                                            />
                                            自製攪打飲食+商業配方
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[208px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">進食次數</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="px-2 flex items-center justify-start">由口進食正餐</p>
                                        <BaseInput
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            type={InputTypeEnum.Text}
                                            name="nutrition.frequency.meals"
                                            value={formData.nutrition.frequency.meals}
                                            onChange={(e) =>
                                                handleNestedChange('nutrition', 'frequency', 'meals', null, e.target.value)
                                            }
                                        />
                                        <p className="px-2 flex items-center justify-start">次及點心餐</p>
                                        <BaseInput
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            type={InputTypeEnum.Text}
                                            name="nutrition.frequency.snacks"
                                            value={formData.nutrition.frequency.snacks}
                                            onChange={(e) =>
                                                handleNestedChange('nutrition', 'frequency', 'snacks', null, e.target.value)
                                            }
                                        />
                                        次/天
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="px-2 flex items-center justify-start">進食內容</p>
                                        <BaseInput
                                            className="w-[436px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            type={InputTypeEnum.Text}
                                            name="nutrition.frequency.contents"
                                            value={formData.nutrition.frequency.contents}
                                            onChange={(e) =>
                                                handleNestedChange('nutrition', 'frequency', 'contents', null, e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="px-2 flex items-center justify-start">管灌飲食</p>
                                        <BaseInput
                                            className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            type={InputTypeEnum.Text}
                                            name="nutrition.frequency.straw"
                                            value={formData.nutrition.frequency.straw}
                                            onChange={(e) =>
                                                handleNestedChange('nutrition', 'frequency', 'straw', null, e.target.value)
                                            }
                                        />
                                        <p className="px-2 flex items-center justify-start">次/天</p>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="px-2 flex items-center justify-start">管灌內容</p>
                                        <BaseInput
                                            className="w-[436px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                            type={InputTypeEnum.Text}
                                            name="nutrition.frequency.strawContents"
                                            value={formData.nutrition.frequency.strawContents}
                                            onChange={(e) =>
                                                handleNestedChange('nutrition', 'frequency', 'strawContents', null, e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">水分攝取</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.waterIntake"
                                    value={formData.nutrition.waterIntake}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'waterIntake', null, null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start">CC/天</p>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">管灌總量</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.totalIntake"
                                    value={formData.nutrition.totalIntake}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'totalIntake', null, null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start">CC/天(需為管灌飲食才書寫)</p>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">熱量</p>
                                <p className="px-2 flex items-center justify-start">建議熱量Kcal</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.calories.suggested"
                                    value={formData.nutrition.calories.suggested}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'calories', 'suggested', null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start mr-7">大卡/天</p>
                                <p className="px-2 flex items-center justify-start">攝取熱量Kcal</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.calories.intake"
                                    value={formData.nutrition.calories.intake}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'calories', 'intake', null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start">大卡/天</p>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">體重</p>
                                <p className="px-2 flex items-center justify-start">目前體重</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.weight.current"
                                    value={formData.nutrition.weight.current}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'weight', 'current', null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start mr-7">公斤</p>
                                <p className="px-2 flex items-center justify-start">理想體重</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="nutrition.weight.ideal"
                                    value={formData.nutrition.weight.ideal}
                                    onChange={(e) =>
                                        handleNestedChange('nutrition', 'weight', 'ideal', null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start">公斤</p>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">皮膚完整性</p>
                                <select className="w-[524px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="nutrition.skin"
                                        value={formData.nutrition.skin}
                                        onChange={(e) =>
                                            handleNestedChange('nutrition', 'skin', null, null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[572px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>排泄功能</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            <div className="h-[104px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">尿液處理</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.selfExcretion"
                                                   checked={formData.excretion.urineHandling.selfExcretion}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'selfExcretion', null, e.target.checked)
                                                   }
                                            />
                                            自解
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.diaper"
                                                   checked={formData.excretion.urineHandling.diaper}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'diaper', null, e.target.checked)
                                                   }
                                            />
                                            使用尿布
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.urineSheath"
                                                   checked={formData.excretion.urineHandling.urineSheath}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'urineSheath', null, e.target.checked)
                                                   }
                                            />
                                            使用尿套
                                        </label>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.catheterization"
                                                   checked={formData.excretion.urineHandling.catheterization}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'catheterization', null, e.target.checked)
                                                   }
                                            />
                                            導尿
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.catheter"
                                                   checked={formData.excretion.urineHandling.catheter}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'catheter', null, e.target.checked)
                                                   }
                                            />
                                            導尿管
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.cystostomy"
                                                   checked={formData.excretion.urineHandling.cystostomy}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'cystostomy', null, e.target.checked)
                                                   }
                                            />
                                            膀胱造瘻
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.urineHandling.singleCatheterization"
                                                   checked={formData.excretion.urineHandling.singleCatheterization}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'urineHandling', 'singleCatheterization', null, e.target.checked)
                                                   }
                                            />
                                            單導
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">尿量</p>
                                <BaseInput
                                    className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    name="excretion.urineAmount"
                                    value={formData.excretion.urineAmount}
                                    onChange={(e) =>
                                        handleNestedChange('excretion', 'urineAmount', null, null, e.target.value)
                                    }
                                />
                                <p className="px-2 flex items-center justify-start">CC/天</p>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">小便性狀</p>
                                <select className="w-[524px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="excretion.urineCondition"
                                        value={formData.excretion.urineCondition}
                                        onChange={(e) =>
                                            handleNestedChange('excretion', 'urineCondition', null, null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                            </div>
                            <div className="h-[104px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">UTI徵兆</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.hasUTI"
                                                   checked={formData.excretion.utiSigns.hasUTI}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'hasUTI', null, e.target.checked)
                                                   }
                                            />
                                            有
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.burning"
                                                   checked={formData.excretion.utiSigns.burning}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'burning', null, e.target.checked)
                                                   }
                                            />
                                            小便有灼熱感
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.frequent"
                                                   checked={formData.excretion.utiSigns.frequent}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'frequent', null, e.target.checked)
                                                   }
                                            />
                                            頻尿
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.fever"
                                                   checked={formData.excretion.utiSigns.fever}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'fever', null, e.target.checked)
                                                   }
                                            />
                                            發燒
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.hematuria"
                                                   checked={formData.excretion.utiSigns.hematuria}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'hematuria', null, e.target.checked)
                                                   }
                                            />
                                            血尿
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.turbid"
                                                   checked={formData.excretion.utiSigns.turbid}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'turbid', null, e.target.checked)
                                                   }
                                            />
                                            尿液混濁
                                        </label>
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.other"
                                                   checked={formData.excretion.utiSigns.other}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'other', null, e.target.checked)
                                                   }
                                            />
                                            其他
                                        </label>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <label className={labelStyle()}>
                                            <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                                   style={{accentColor: "#38A169"}}
                                                   type="checkbox"
                                                   name="formData.excretion.utiSigns.noUTI"
                                                   checked={formData.excretion.utiSigns.noUTI}
                                                   onChange={(e) =>
                                                       handleNestedChange('excretion', 'utiSigns', 'noUTI', null, e.target.checked)
                                                   }
                                            />
                                            無
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">大便性狀</p>
                                <select className="w-[524px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="excretion.stoolCondition"
                                        value={formData.excretion.stoolCondition}
                                        onChange={(e) =>
                                            handleNestedChange('excretion', 'stoolCondition', null, null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                            </div>
                            <div className="h-[156px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">腸蠕動</p>
                                <div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="pr-2 flex items-center justify-start">腸音</p>
                                        <select
                                            className="w-[488px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="excretion.bowelMovement.sounds"
                                            value={formData.excretion.bowelMovement.sounds}
                                            onChange={(e) =>
                                                handleNestedChange('excretion', 'bowelMovement', 'sounds', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                        </select>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="pr-2 flex items-center justify-start">腹脹</p>
                                        <select
                                            className="w-[488px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="excretion.bowelMovement.distention"
                                            value={formData.excretion.bowelMovement.distention}
                                            onChange={(e) =>
                                                handleNestedChange('excretion', 'bowelMovement', 'distention', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                        </select>
                                    </div>
                                    <div className="h-[52px] flex flex-row items-center justify-start">
                                        <p className="pr-2 flex items-center justify-start">硬塊</p>
                                        <select
                                            className="w-[488px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                            name="excretion.bowelMovement.hardness"
                                            value={formData.excretion.bowelMovement.hardness}
                                            onChange={(e) =>
                                                handleNestedChange('excretion', 'bowelMovement', 'hardness', null, e.target.value)
                                            }
                                        >
                                            <option value=""></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">排便的處理</p>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.excretion.bowelHandling.selfExcretion"
                                               checked={formData.excretion.bowelHandling.selfExcretion}
                                               onChange={(e) =>
                                                   handleNestedChange('excretion', 'bowelHandling', 'selfExcretion', null, e.target.checked)
                                               }
                                        />
                                        自解
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.excretion.bowelHandling.enema"
                                               checked={formData.excretion.bowelHandling.enema}
                                               onChange={(e) =>
                                                   handleNestedChange('excretion', 'bowelHandling', 'enema', null, e.target.checked)
                                               }
                                        />
                                        灌腸
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.excretion.bowelHandling.manualEvacuation"
                                               checked={formData.excretion.bowelHandling.manualEvacuation}
                                               onChange={(e) =>
                                                   handleNestedChange('excretion', 'bowelHandling', 'manualEvacuation', null, e.target.checked)
                                               }
                                        />
                                        挖便
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.excretion.bowelHandling.stoolSoftener"
                                               checked={formData.excretion.bowelHandling.stoolSoftener}
                                               onChange={(e) =>
                                                   handleNestedChange('excretion', 'bowelHandling', 'stoolSoftener', null, e.target.checked)
                                               }
                                        />
                                        使用軟便劑
                                    </label>
                                    <label className={`${labelStyle()} mr-0`}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.excretion.bowelHandling.other"
                                               checked={formData.excretion.bowelHandling.other}
                                               onChange={(e) =>
                                                   handleNestedChange('excretion', 'bowelHandling', 'other', null, e.target.checked)
                                               }
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                        type={InputTypeEnum.Text}
                                        name="excretion.bowelHandling.input"
                                        value={formData.excretion.bowelHandling.input}
                                        onChange={(e) =>
                                            handleNestedChange('excretion', 'bowelHandling', 'input', null, e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[208px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>情緒行為問題</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">情緒</p>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.normal"
                                               checked={formData.emotionalState.emotion.normal}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'normal', null, e.target.checked)
                                               }
                                        />
                                        正常
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.negative"
                                               checked={formData.emotionalState.emotion.negative}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'negative', null, e.target.checked)
                                               }
                                        />
                                        負面
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.sadExpression"
                                               checked={formData.emotionalState.emotion.sadExpression}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'sadExpression', null, e.target.checked)
                                               }
                                        />
                                        憂傷表情
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.crying"
                                               checked={formData.emotionalState.emotion.crying}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'crying', null, e.target.checked)
                                               }
                                        />
                                        哭泣
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.withdrawal"
                                               checked={formData.emotionalState.emotion.withdrawal}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'withdrawal', null, e.target.checked)
                                               }
                                        />
                                        退縮
                                    </label>
                                    <label className={`${labelStyle()} mr-0`}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.emotion.other"
                                               checked={formData.emotionalState.emotion.other}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'emotion', 'other', null, e.target.checked)
                                               }
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                        type={InputTypeEnum.Text}
                                        name="emotionalState.emotion.input"
                                        value={formData.emotionalState.emotion.input}
                                        onChange={(e) =>
                                            handleNestedChange('emotionalState', 'emotion', 'input', null, e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">問題行為</p>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.behavior.normal"
                                               checked={formData.emotionalState.behavior.normal}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'behavior', 'normal', null, e.target.checked)
                                               }
                                        />
                                        正常
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.behavior.violentBehavior"
                                               checked={formData.emotionalState.behavior.violentBehavior}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'behavior', 'violentBehavior', null, e.target.checked)
                                               }
                                        />
                                        暴力行為
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.behavior.unconsciousExtubation"
                                               checked={formData.emotionalState.behavior.unconsciousExtubation}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'behavior', 'unconsciousExtubation', null, e.target.checked)
                                               }
                                        />
                                        無意識拔管
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.behavior.inappropriateSpeech"
                                               checked={formData.emotionalState.behavior.inappropriateSpeech}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'behavior', 'inappropriateSpeech', null, e.target.checked)
                                               }
                                        />
                                        言語不當
                                    </label>
                                    <label className={`${labelStyle()} mr-0`}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#38A169"}}
                                               type="checkbox"
                                               name="formData.emotionalState.behavior.other"
                                               checked={formData.emotionalState.behavior.other}
                                               onChange={(e) =>
                                                   handleNestedChange('emotionalState', 'behavior', 'other', null, e.target.checked)
                                               }
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                        type={InputTypeEnum.Text}
                                        name="emotionalState.behavior.input"
                                        value={formData.emotionalState.behavior.input}
                                        onChange={(e) =>
                                            handleNestedChange('emotionalState', 'behavior', 'input', null, e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">睡眠型態</p>
                                <select className="w-[524px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                        name="excretion.urineCondition"
                                        value={formData.emotionalState.sleepPattern}
                                        onChange={(e) =>
                                            handleNestedChange('emotionalState', 'sleepPattern', null, null, e.target.value)
                                        }
                                >
                                    <option value=""></option>
                                </select>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start border-b border-[#111111]/15">
                                <p className="w-[307px] px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">疼痛</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="emotionalState.pain"
                                           checked={formData.emotionalState.pain.no}
                                           onChange={(e) =>
                                               handleNestedChange('emotionalState', 'pain', 'no', null, e.target.checked)
                                           }
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="emotionalState.pain"
                                           checked={formData.emotionalState.pain.yes}
                                           onChange={(e) =>
                                               handleNestedChange('emotionalState', 'pain', 'yes', null, e.target.checked)
                                           }
                                    />
                                    有
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="emotionalState.pain"
                                           checked={formData.emotionalState.pain.noComment}
                                           onChange={(e) =>
                                               handleNestedChange('emotionalState', 'pain', 'noComment', null, e.target.checked)
                                           }
                                    />
                                    無法評估
                                </label>
                            </div>
                        </div>
                    </div>

                    {/*醫令*/}
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[188px] flex flex-shrink-0 items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>護理處置</h2>
                        <div className="w-full h-[188px] p-2 border-b border-r border-[#111111]/15">
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                                醫令
                            </button>
                        </div>
                    </div>

                    {/*護理措施*/}
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[104px] flex flex-shrink-0 items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>護理措施</h2>
                        <div className="w-full px-4 border-b border-r border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.swallowingTraining"
                                           checked={formData.nursingCare.swallowingTraining}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'swallowingTraining', null, null, e.target.checked)
                                           }
                                    />
                                    吞嚥訓練
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.breathingTraining"
                                           checked={formData.nursingCare.breathingTraining}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'breathingTraining', null, null, e.target.checked)
                                           }
                                    />
                                    呼吸訓練
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.catheterTraining"
                                           checked={formData.nursingCare.catheterTraining}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'catheterTraining', null, null, e.target.checked)
                                           }
                                    />
                                    尿管訓練
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.nasalTubeRemoval"
                                           checked={formData.nursingCare.nasalTubeRemoval}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'nasalTubeRemoval', null, null, e.target.checked)
                                           }
                                    />
                                    鼻胃管移除
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.tracheostomyRemoval"
                                           checked={formData.nursingCare.tracheostomyRemoval}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'tracheostomyRemoval', null, null, e.target.checked)
                                           }
                                    />
                                    氣切移除
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.urinaryCatheterRemoval"
                                           checked={formData.nursingCare.urinaryCatheterRemoval}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'urinaryCatheterRemoval', null, null, e.target.checked)
                                           }
                                    />
                                    尿管移除
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.dietitianVisit"
                                           checked={formData.nursingCare.dietitianVisit}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'dietitianVisit', null, null, e.target.checked)
                                           }
                                    />
                                    營養師訪視
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.woundCare"
                                           checked={formData.nursingCare.woundCare}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'woundCare', null, null, e.target.checked)
                                           }
                                    />
                                    傷口指導
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.limbRehabilitation"
                                           checked={formData.nursingCare.limbRehabilitation}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'limbRehabilitation', null, null, e.target.checked)
                                           }
                                    />
                                    肢體復健指導
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.bloodSugar"
                                           checked={formData.nursingCare.bloodSugar}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'bloodSugar', null, null, e.target.checked)
                                           }
                                    />
                                    測量血糖
                                </label>
                                <p className="px-2">血糖值</p>
                                <BaseInput
                                    className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                    type={InputTypeEnum.Text}
                                    name="emotionalState.behavior.input"
                                    value={formData.emotionalState.behavior.input}
                                    onChange={(e) =>
                                        handleNestedChange('emotionalState', 'behavior', 'input', null, e.target.value)
                                    }
                                />
                                <p className="px-2">mg/dl</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="nursingCare"
                                           checked={formData.nursingCare.beforeMeal}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'beforeMeal', null, null, e.target.checked)
                                           }
                                    />
                                    飯前
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="nursingCare"
                                           checked={formData.nursingCare.afterMeal}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'afterMeal', null, null, e.target.checked)
                                           }
                                    />
                                    飯後
                                </label>

                                <BaseInput
                                    className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                    type={InputTypeEnum.Text}
                                    name="emotionalState.behavior.input"
                                    value={formData.emotionalState.behavior.input}
                                    onChange={(e) =>
                                        handleNestedChange('emotionalState', 'behavior', 'input', null, e.target.value)
                                    }
                                />
                                <p className="px-2">小時</p>
                                <label className={`${labelStyle()} mr-0`}>
                                    <input className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                           style={{accentColor: "#38A169"}}
                                           type="checkbox"
                                           name="formData.nursingCare.other"
                                           checked={formData.nursingCare.other}
                                           onChange={(e) =>
                                               handleNestedChange('nursingCare', 'other', null, null, e.target.checked)
                                           }
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[154px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2"
                                    type={InputTypeEnum.Text}
                                    name="emotionalState.behavior.input"
                                    value={formData.emotionalState.behavior.input}
                                    onChange={(e) =>
                                        handleNestedChange('emotionalState', 'behavior', 'input', null, e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/*輔具評估*/}
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[520px] flex flex-shrink-0 items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>輔具評估</h2>
                        <div className="w-full border-r border-[#111111]/15">
                            {Object.keys(equipmentLabels).map((key) => (
                                <div key={key} className="h-[52px] px-4 flex flex-row items-center justify-start border-b border-[#111111]/15">
                                    <label className={`${labelStyle()} w-[230px] mr-4`}>
                                        <input
                                            className="w-5 h-5 text-[#38A169] checked:bg-[#38A169] mr-2"
                                            style={{accentColor: "#38A169"}}
                                            type="checkbox"
                                            name={`formData.equipment.${key}.${key}`}
                                            checked={formData.equipment[key][key]}
                                            onChange={(e) =>
                                                handleNestedChange('equipment', key, key, null, e.target.checked)
                                            }
                                        />
                                        {equipmentLabels[key]}
                                    </label>
                                    <label className={labelStyle()}>
                                        <input
                                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                            style={{accentColor: "#2B6CB0"}}
                                            type="radio"
                                            name={`equipment.${key}`}
                                            checked={formData.equipment[key].good}
                                            onChange={(e) =>
                                                handleNestedChange('equipment', key, 'good', null, e.target.checked)
                                            }
                                        />
                                        良好
                                    </label>
                                    <label className={labelStyle()}>
                                        <input
                                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                            style={{accentColor: "#2B6CB0"}}
                                            type="radio"
                                            name={`equipment.${key}`}
                                            checked={formData.equipment[key].bad}
                                            onChange={(e) =>
                                                handleNestedChange('equipment', key, 'bad', null, e.target.checked)
                                            }
                                        />
                                        差
                                    </label>
                                    <p className="px-2">原因/處置</p>
                                    <BaseInput
                                        className="w-[440px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                        type={InputTypeEnum.Text}
                                        name={`equipment.${key}.reason`}
                                        value={formData.equipment[key].reason}
                                        onChange={(e) =>
                                            handleNestedChange('equipment', key, 'reason', null, e.target.value)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*處方*/}
                    <div className="flex flex-row items-start justify-start">
                        <h2 className="w-[39px] h-[505px] flex flex-shrink-0 items-center justify-center border-x border-b border-[#111111]/15"
                            style={{writingMode: "vertical-lr"}}>處方</h2>
                        <div className="w-full h-[505px] p-2 border-r border-b border-[#111111]/15">
                            <div className="flex flex-row items-center justify-start mb-2">
                                <button
                                    className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                                    處方
                                </button>
                                <button
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                                    處方紀錄
                                </button>
                                <p className="mr-2">用藥情況:</p>
                                <select className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                                    <option value=""></option>
                                </select>
                            </div>
                            <textarea className="w-full h-[200px] px-4 py-2 border-[1px] border-[#D4D4D8] rounded-[6px] mb-4"></textarea>
                            <label className="w-[96px] flex items-center justify-center h-10 px-2 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                                <input className="hidden"
                                       type="file"/>
                                插入圖片
                            </label>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default VisitRecord;
