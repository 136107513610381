import searchIcon from "../../assets/images/search-interface-symbol.png"
import React, { useEffect, useState } from 'react'
import '../../components/TableList/tableList.scss'
import { Pagination } from "../../components/Pagination/Pagination"
import {ApiErrorStatusEnum, objectIsEmpty, InputTypeEnum, arrayIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import { queryCvisNotifyrecNoReply, queryCvisNotifyUnit } from "../../api/v1/Critical"
import { BaseInput } from "../../components/Input/BaseInput"
import CvisListItem from "../../components/CriticalValueManagement/CvisListItem"
import { fuseObject } from "../../components/CriticalValueManagement/utils"
import ToastMessage from "../../components/Toasts/ToastMessage"

import {t} from "i18next"


/**
 * 危急值逾時回覆查詢
 * @returns {JSX.Element}
 */
function CrisisAlertTimeoutReplyQuery() {
    // 通報單位選單
    const [unit, setUnit] = useState([])
    // 查詢條件
    const [cvisQueryData, setCvisQueryData] = useState({
        cvisNotifyUnit: '01',
        cvisClassA: 'Y',
        cvisClassB: 'N',
        cvisClassC: 'N',
        cvisNotifyStartDate: '',
        cvisNotifyEndDate: '',
    })
    // 顯示查詢結果
    const [showQueryResult, setShowQueryResult] = useState(false)
    // 危急值回覆清單
    const [cvisNotifyRecordList, setCvisNotifyRecordList] = useState([])
    // 過濾危急值回覆清單
    const [filteredItems, setFilteredItems] = useState([])
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // 顯示Toast
    const [showToast, setShowToast] = useState(false)
    // Toast訊息
    const [toastMessage, setToastMessage] = useState({
        message: '',
        type: ToastTypeEnum.Success
    })
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })

    /**
     * 控制Toast開啟事件
     * @param {Object} event
     * @returns {void}
     */
    const handleToastOnShow = (message, type) => {
        setToastMessage({
            message: message,
            type: type
        })
        setShowToast(true)
    }

    /**
     * 控制Toast關閉事件
     * @param {Object} event
     * @returns {void}
     */
    const handleToastOnClose = () => {
        setShowToast(false)
    }

    /**
     * 控制通報單位變動事件
     * @param {Object} event
     * @return {void}
     */
    const handleCvisNotifyUnitOnChange = (event) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: event.target.value
        })
    }

    /**
     * 控制通報日期起始變動事件
     * @param {Object} event
     * @return {void}
     */
    const handleStartDateOnChange = (event) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyStartDate: event.target.value
        })
    }

    /**
     * 控制通報日期結束變動事件
     * @param {Object} event
     * @return {void}
     */
    const handleEndDateOnChange = (event) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyEndDate: event.target.value
        })
    }

    /**
     * 控制Checkbox變動事件
     * @param {Object} event
     * @return {void}
     */
    const handleCheckboxOnChange = (event) => {
        const { id, checked } = event.target
        setCvisQueryData({
            ...cvisQueryData,
            [id]: checked ? 'Y' : 'N'
        })
    }
    
    /**
     * 進階搜尋按鈕事件
     */
    const handleTableQuerySearchClick = () => {
        console.log('進階搜尋')
    }

    /**
     * 搜尋Table的Input box觸發Key Dowon事件
     * @param {Object} event 
     */
    const handleTableQuerySearchInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleTableQuerySearchClick();
        }
    }

    /**
     * 控制Query輸入框內的值變動事件
     * @param {Object} event 
     */
    const handleQueryInputOnChange = (event) => {
        if(event.target.value === ' ')
            setQueryString('');
        else
            setQueryString(event.target.value.toUpperCase())
    }

    /**
     * 危急值逾時回覆查詢表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 危急值逾時回覆查詢表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        queryCvisNotifyList(paginationProps.pageNum, newSize);
    }

    /**
     * 危急值逾時回覆查詢表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 危急值逾時回覆查詢表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 取得危急值逾時回覆查詢結果
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @return {void}
     */
    const queryCvisNotifyList = (page, pageSize) => {
        queryCvisNotifyrecNoReply({
            "pageNum": page,
            "pageSize": pageSize,
            "cvisNotifyUnit": cvisQueryData.cvisNotifyUnit,
            "cvisClassA": cvisQueryData.cvisClassA,
            "cvisClassB": cvisQueryData.cvisClassB,
            "cvisClassC": cvisQueryData.cvisClassC,
            "cvisNotifyStartDate": `${cvisQueryData.cvisNotifyStartDate} 00:00:00`,
            "cvisNotifyEndDate":`${cvisQueryData.cvisNotifyEndDate} 00:00:00`,
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                setCvisNotifyRecordList(data)
                setFilteredItems(data)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.currentPageItemSize,
                    pageNum: page,
                    pageSize: pageSize
                })
                setShowQueryResult(true)
            }else{
                if(res.err === 1) {
                    setCvisNotifyRecordList([])
                    setFilteredItems([])
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }
                console.log(res)
            }
        })
    }


    /**
     * 查詢按鈕事件
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @return {void}
     */
    const handleQuerySubmit = () => {
        if (cvisQueryData.cvisNotifyEndDate && cvisQueryData.cvisNotifyStartDate > cvisQueryData.cvisNotifyEndDate) {
            handleToastOnShow('結束日期不可早於起始日期', ToastTypeEnum.Error)
        }else if(cvisQueryData.cvisNotifyEndDate==="" || cvisQueryData.cvisNotifyStartDate===""){
            handleToastOnShow('結束日期不可早於起始日期', ToastTypeEnum.Error)
        }else{
            queryCvisNotifyList(paginationProps.pageNum, paginationProps.pageSize)
        }
        
    }

    // 進階搜尋
    const fuse = fuseObject(cvisNotifyRecordList)

    // 當搜尋文字改變時，取得過濾後結果
    useEffect(()=>{
        if(queryString){
            const result = fuse.search(queryString)
            setFilteredItems(result.map(item => item.item))
        }else{
            setFilteredItems(cvisNotifyRecordList)
        }
    }, [queryString, cvisNotifyRecordList])

    // 初始化後取得通報單位
    useEffect(()=>{
        queryCvisNotifyUnit().then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                setUnit(res.data)
            }else{
                console.log(res)
            }
        })
    },[])

  return (
    <div className='w-full p-4'>
        {/* 主要內容 */}
        <div className='w-full flex flex-col gap-4'>
            {/* 醫生及回覆資訊 */}
            <div className='w-full flex flex-row justify-start items-center'>
                <div className='w-[210px] h-10 flex flex-row justify-start items-center'>
                    <p>通報單位：</p>
                    <select 
                        className="w-[120px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisNotifyUnit}
                        onChange={handleCvisNotifyUnitOnChange}
                    >
                        {!arrayIsEmpty(unit) && unit.map((item, index) => (
                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                        ))}
                    </select>
                </div>
                <div className="min-w-[467px] flex flex-row items-center gap-1">
                    <p>通報日期起迄：</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={cvisQueryData.cvisNotifyStartDate}
                        onChange={handleStartDateOnChange}
                    />
                    <p>~</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={cvisQueryData.cvisNotifyEndDate}
                        onChange={handleEndDateOnChange}
                    />
                </div>
                <div className="flex flex-row ml-4 space-x-4 items-center">
                    {/* A級 */}
                    <input 
                        id="cvisClassA"
                        className="w-[20px] h-[20px] hover:cursor-pointer"
                        type="checkbox"
                        checked={cvisQueryData.cvisClassA === 'Y'}
                        onChange={handleCheckboxOnChange}
                    />
                    <label id="LevelA" className="hover:cursor-pointer" htmlFor="LevelA">A級</label>
                    {/* B級 */}
                    <input 
                        id="cvisClassB"
                        className="w-[20px] h-[20px] hover:cursor-pointer"
                        type="checkbox"
                        checked={cvisQueryData.cvisClassB === 'Y'}
                        onChange={handleCheckboxOnChange}
                    />
                    <label id="LevelB" className="hover:cursor-pointer" htmlFor="LevelB">B級</label>
                    {/* C級 */}
                    <input 
                        id="cvisClassC"
                        className="w-[20px] h-[20px] hover:cursor-pointer"
                        type="checkbox"
                        checked={cvisQueryData.cvisClassC === 'Y'}
                        onChange={handleCheckboxOnChange}
                    />
                    <label id="LevelC" className="hover:cursor-pointer" htmlFor="LevelC">C級</label>
                </div>
                {/* 查詢按鈕 */}
                <button 
                    className="ml-4 w-[64px] h-10 bg-[#2B6CB0] text-white rounded-[6px] hover:cursor-pointer" 
                    onClick={handleQuerySubmit}
                >
                    查詢
                </button>
            </div>
            { showQueryResult && (
                <>
                    {/* 危急值逾時回覆查詢結果 */}
                    <div className=' flex flex-col w-full h-[864px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                        {/* 搜尋欄 */}
                        <div className='max-w-[320px] relative'>
                            <input
                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                type={InputTypeEnum.Text}
                                value={queryString}
                                onChange={(event) => handleQueryInputOnChange(event)}
                                onKeyDown={handleTableQuerySearchInputKeyDown}
                                placeholder="進階搜尋"
                            />
                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                            </div>
                        </div>
                        {/* 清單表格 */}
                        <div className='min-w-[1632px]'>
                            <ul className='tableList crisisAlertTimeoutQuerySearch max-h-[736px]'>
                                <li className='title'>
                                    <div>等級</div>
                                    <div>發送日期時間</div>
                                    <div>回覆時效</div>
                                    <div>回覆期限</div>
                                    <div>病歷號</div>
                                    <div>姓名</div>
                                    <div>主治醫師</div>
                                    <div>醫師姓名</div>
                                    <div>通報內容</div>
                                </li>
                                {!objectIsEmpty(cvisNotifyRecordList) && filteredItems.map((item, index) => {
                                    return (
                                        <CvisListItem 
                                            type="TimeoutReplyQuery"
                                            index={index}
                                            item={item}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                        {/* 分頁 */}
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            currentPage={paginationProps.pageNum}
                            onPageOnChange={onPaginationPageOnChange}
                            onPageSizeChange={onPaginationPageSizeOnChange}
                            onPrevPageOnClick={onPaginationPreviousOnClick}
                            onNextPageOnClick={onPaginationNextOnClick}
                        />
                    </div>
                </>
            )}
        </div>
        {/* Toast */}
        <ToastMessage 
          show={showToast}
          message={toastMessage.message}
          type={toastMessage.type}
          onClickClose={handleToastOnClose}
        />
    </div>
  )
}

export default CrisisAlertTimeoutReplyQuery;