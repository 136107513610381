import searchIcon from "../../assets/images/search-interface-symbol.png"
import React, { useEffect, useState } from 'react'
import '../../components/TableList/tableList.scss'
import { Pagination } from "../../components/Pagination/Pagination"
import { 
    queryCvisNotifyrecByStatus,
    queryCvisNotifyrecById,
    queryCvisRenotify,
    queryCvisNotifyUnit
} from "../../api/v1/Critical"
import { BasePopup } from "../../components/Popup/BasePopup"
import {ApiErrorStatusEnum, objectIsEmpty, arrayIsEmpty, InputTypeEnum, fuzzySearchObjects} from "edah_utils/dist"
import CvisListItem from "../../components/CriticalValueManagement/CvisListItem"
import { cvisNotifyUnitAdapter } from "../../components/CriticalValueManagement/utils"

import {t} from "i18next";


function CrisisAlertClosureMonitor() {
    // 通報單位選單
    const [unit, setUnit] = useState([])
    // 預設查詢條件
    const [cvisQueryData, setCvisQueryData] = useState({
        cvisNotifyUnit: '01',
        cvisNotifyQueryType: '0'
    })
    // 閉環通報查詢結果
    const [showQueryResult, setShowQueryResult] = useState(false)
    // 查詢狀態
    const [queryString, setQueryString] = useState('')
    // 閉環監控查詢資料清單
    const [cvisNotifyRecordList, setCvisNotifyRecordList] = useState([])
    // 過濾閉環監控查詢資料清單
    const [filteredCvisNotifyRecordList, setFilteredCvisNotifyRecordList] = useState([])
    // 彈出視窗
    const [isShowReportData, setIsShowReportData] = useState(false)
    // 彈出視窗頁籤
    const [popUpTab, setPopUpTab] = useState('ReportData')
    // 彈出視窗-通報資料
    const [popCvisNotifyData, setPopCvisNotifyData] = useState()
    // 彈出視窗-逾時回覆再通知紀錄
    const [popCvisRenotifyList, setPopCvisRenotifyList] = useState([])
    // 彈出視窗-過濾逾時回覆再通知紀錄
    const [filteredPopCvisRenotifyList, setFilteredPopCvisRenotifyList] = useState([])
    // 彈出視窗-逾時回覆搜尋字串
    const [popQueryString, setPopQueryString] = useState('')
    // 列表焦點索引
    const [focusIndex, setFocusIndex] = useState()
    // pagination 控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })
    // 逾時回覆再通知紀錄分頁控制變數
    const [renotifyPaginationProps, setRenotifyPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })

    /**
     * 通報單位變動事件
     * @param {e} event
     * @returns {void} 
     */
    const handleCvisNotifyUnitOnChange = (event) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: event.target.value
        })
    }

    /**
     * 控制查詢模式變動事件
     * @param {e} event 
     * @returns {void}
     */
    const handleQueryTypeOnChange = (event) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyQueryType: event.target.value
        })
    }

    /**
     * 控制Query輸入框內的值變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列
     * @param {Object} event 
     */
    const handleQueryInputOnChange = (event) => {
        setQueryString(event.target.value)
        setFilteredCvisNotifyRecordList(getFilterData(cvisNotifyRecordList,event.target.value))
    }

    /**
     * 控制Popup輸入框內的值變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列
     * @param {Object} event
     * @returns {void}
     * */
    const handlePopQueryInputOnChange = (event) => {
        setPopQueryString(event.target.value);
        const data = getFilterData(popCvisRenotifyList,event.target.value)
        setFilteredPopCvisRenotifyList(data)
    }

    /**
    * 取得模糊匹配後的資料陣列
    * @return {Array<Object>}
    */
    const getFilterData = (array, string) => fuzzySearchObjects(array, string)

    /**
     * 閉環監控表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }

    /**
     * 閉環監控表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        queryCvisList(paginationProps.pageNum, newSize);
    }

    /**
     * 閉環監控表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }

    /**
     * 閉環監控表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }
    
    /**
     * 逾時回覆再通知表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onRenotifyPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 逾時回覆再通知表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onRenotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisRenotify(focusIndex, paginationProps.pageNum, newSize);
    }

    /**
     * 逾時回覆再通知表格上一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 逾時回覆再通知表格下一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }
    /**
     * 取得閉環監控資料
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @returns {void}
     */
    const queryCvisList = (page, pageSize) => {
        queryCvisNotifyrecByStatus({
            cvisNotifyUnit: cvisQueryData.cvisNotifyUnit,
            cvisNotifyQueryType: cvisQueryData.cvisNotifyQueryType,
            pageNum: page,
            pageSize: pageSize
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                setCvisNotifyRecordList(data)
                setFilteredCvisNotifyRecordList(data)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.dataList.length,
                    pageNum: page,
                    pageSize: pageSize
                })
                setShowQueryResult(true)
            }else{
                if(res.err === 1) {
                    setCvisNotifyRecordList([])
                    setFilteredCvisNotifyRecordList([])
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0,
                        pageNum: page,
                        pageSize: pageSize
                    })
                    setShowQueryResult(false)
                }
                console.log(res)
            }
        }))
    }

    /**
     * 取得逾時回覆再通知紀錄
     * @param {number} index 當前焦點索引
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @returns {void}
     * */
    const handleQueryCvisRenotify = (index, page, pageSize) => {
        //取得逾時回覆再通知紀錄
        queryCvisRenotify({
            cvisNotifyId: cvisNotifyRecordList[index].cvisNotifyId,
            pageNum: page,
            pageSize: pageSize
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                setPopCvisRenotifyList(data)
                setFilteredPopCvisRenotifyList(data)
                setRenotifyPaginationProps({
                    ...renotifyPaginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.dataList.length,
                    pageNum: page,
                    pageSize: pageSize
                })
            }else{
                setFilteredPopCvisRenotifyList([])
            }
        })
    }
    
    /**
     * 控制查詢事件
     * @returns {void}
     */
    const handleQuerySubmit = () => {
        queryCvisList(paginationProps.pageNum, paginationProps.pageSize)
    }

     /**
     * 根據List id取得對應流水號，呼叫api popup視窗
     * @param {String} id
     * @return {void}
     */
     const handlePopupOnClick = (id) => {
        setFocusIndex(id)
        //取得通報資料
        queryCvisNotifyrecById({
            cvisNotifyId: cvisNotifyRecordList[id].cvisNotifyId
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setPopCvisNotifyData(res.data)
            }else{
                console.log(res)
            }
        })
        //取得逾時回覆再通知紀錄
        handleQueryCvisRenotify(id, paginationProps.pageNum, paginationProps.pageSize)

        setIsShowReportData(true)
    }

    /**
     * 按下關閉按鈕
     */
    const handlePopupOnClose = () => {
        setIsShowReportData(false)
        setPopUpTab('ReportData')
    }

    /**
     * 彈出視窗頁籤變更事件
     * @param {String} tab
     */
    const handlePopUpTabOnChange = (tab) => {
        setPopUpTab(tab)
    }

    // 第一次執行時
    useEffect(()=>{
        // 取得通報單位
        queryCvisNotifyUnit().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定通報單位
                setUnit(res.data)
            }else{
                console.log(res)
            }
        })
    },[])

  return (
    <div className='w-full p-4 relative'>
        {/* 主要內容 */}
        <div className='w-full flex flex-col gap-4'>
            {/* 醫生及回覆資訊 */}
            <div className='w-full flex flex-row justify-start items-center'>
            <div className='w-[210px] h-10 flex flex-row justify-start items-center'>
                    <p>通報單位：</p>
                    <select 
                        className="w-[120px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisNotifyUnit}
                        onChange={handleCvisNotifyUnitOnChange}
                    >
                        {!arrayIsEmpty(unit) && unit.map((item, index) => (
                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                        ))}
                    </select>
                </div>
                <div className="min-w-[620px] flex flex-row items-center justify-center space-x-4">
                    <input 
                        id="All"
                        name="all"
                        type="radio"
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                        checked={cvisQueryData.cvisNotifyQueryType === '0'}
                        value="0"
                        onChange={handleQueryTypeOnChange}
                        style={{cursor: "pointer"}}
                    />
                    <label 
                        className={`hover:cursor-pointer ${cvisQueryData.cvisNotifyQueryType === 'All' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                        htmlFor="All">
                            全部
                    </label>
                    <input 
                        id="unReplySearch"
                        name="unReplySearch"
                        type="radio"
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                        checked={cvisQueryData.cvisNotifyQueryType === '1'}
                        value="1"
                        onChange={handleQueryTypeOnChange}
                        style={{cursor: "pointer"}}
                    />
                    <label 
                        className={`hover:cursor-pointer ${cvisQueryData.cvisNotifyQueryType === 'unReplySearch' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                        htmlFor="unReplySearch">
                            未回覆查詢
                    </label>
                    <input 
                        id="LevelATimeoutReply"
                        name="LevelATimeoutReply"
                        type="radio"
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                        checked={cvisQueryData.cvisNotifyQueryType === '2'}
                        value="2"
                        onChange={handleQueryTypeOnChange}
                        style={{cursor: "pointer"}}
                    />
                    <label 
                        className={`hover:cursor-pointer ${cvisQueryData.cvisNotifyQueryType === 'LevelATimeoutReply' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                        htmlFor="LevelATimeoutReply">
                            A級危急值逾時回覆
                    </label>
                    <input 
                        id="LevelATimeoutAssignmentUnReply"
                        name="LevelATimeoutAssignmentUnReply"
                        type="radio"
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                        checked={cvisQueryData.cvisNotifyQueryType === '3'}
                        value="3"
                        onChange={handleQueryTypeOnChange}
                        style={{cursor: "pointer"}}
                    />
                    <label 
                        className={`hover:cursor-pointer ${cvisQueryData.cvisNotifyQueryType === 'LevelATimeoutAssignmentUnReply' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                        htmlFor="LevelATimeoutAssignmentUnReply">
                            A級危急值逾時再通知未回覆
                    </label>
                </div>
                {/* 查詢按鈕 */}
                <button 
                    className="ml-4 w-[64px] h-10 bg-[#2B6CB0] text-white rounded-[6px] hover:cursor-pointer" 
                    onClick={handleQuerySubmit}
                >
                    查詢
                </button>
            </div>
            { showQueryResult && (
                <>
                    {/* 危急值通報閉環監控 */}
                    <div className=' flex flex-col w-full h-[864px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                        {/* 搜尋欄 */}
                        <div className='max-w-[320px] relative'>
                            <input
                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                type={InputTypeEnum.Text}
                                value={queryString}
                                onChange={(event) => handleQueryInputOnChange(event)}
                                placeholder="進階搜尋"
                            />
                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                            </div>
                        </div>
                        {/* 清單表格 */}
                        <div className='min-w-[1632px]'>
                            <ul className='tableList crisisAlertClosureMonitor max-h-[736px]'>
                                <li className='title' key={0}>
                                    <div>通報單位</div>
                                    <div>通報項目</div>
                                    <div>事件等級</div>
                                    <div>通報日期時間</div>
                                    <div>病歷號</div>
                                    <div>姓名</div>
                                    <div>閉環狀態</div>
                                    <div>查看</div>
                                </li>
                                {!objectIsEmpty(filteredCvisNotifyRecordList) && filteredCvisNotifyRecordList.map((item, index) => {
                                    return (
                                        <CvisListItem 
                                            type="ClosureMonitorList"
                                            index={index}
                                            item={item}
                                            handleItemOnClick={handlePopupOnClick}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                        {/* 分頁 */}
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            currentPage={paginationProps.pageNum}
                            onPageOnChange={onPaginationPageOnChange}
                            onPageSizeChange={onPaginationPageSizeOnChange}
                            onPrevPageOnClick={onPaginationPreviousOnClick}
                            onNextPageOnClick={onPaginationNextOnClick}
                        />
                    </div>
                </>
            )}
        </div>
        {/* 危急值通報-彈出視窗 */}
        {isShowReportData && (
            <BasePopup
                title="危急值通報"
                closePopupButtonOnClick={handlePopupOnClose}
                width="909px"
                content={
                    <div className="w-full border-solid border-t-[1px] pt-4 px-6">
                        <div className="w-full flex flex-row justify-start items-center">
                            <button 
                                className={`w-[96px] px-4 py-2 hover:cursor-pointer ${popUpTab === 'ReportData' ? 'text-[#2B6CB0] border-b-2 border-primary' : 'text-black'}`}
                                onClick={()=>handlePopUpTabOnChange('ReportData')}
                            >
                                通報資料
                            </button>
                            <button 
                                className={`w-[176px] px-4 py-2 hover:cursor-pointer ${popUpTab === 'TimeoutReplyAssignmentRecord' ? 'text-[#2B6CB0] border-b-2 border-primary' : 'text-black'}`}
                                onClick={()=>handlePopUpTabOnChange('TimeoutReplyAssignmentRecord')}
                            >
                                逾時回覆再通知紀錄
                            </button>
                        </div>

                            {/* 通報資料 */}
                            {popUpTab === 'ReportData' && !objectIsEmpty(popCvisNotifyData) && (
                                <>
                                {/* 通報資料 */}
                                <div className="w-full mt-[29px] mb-[26px]">
                                    <ul>
                                        <li className="flex flex-row items-center border-solid border-b-[1px]">
                                            <div className="min-w-[160px] pr-4 py-[9px] text-end">通報單位</div>
                                            <div className="min-w-[681px] pl-4 py-[9px] text-start">{cvisNotifyUnitAdapter(popCvisNotifyData.cvisNotifyUnit)}</div>
                                        </li>
                                        <li className="flex flex-row items-center border-solid border-b-[1px]">
                                            <div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">通報項目</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">{popCvisNotifyData.cvisNotifyItem}</div>
                                            </div>
                                            <div div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">事件等級</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">{popCvisNotifyData.cvisNotifyClass}</div>
                                            </div>
                                        </li>
                                        <li className="flex flex-row items-center border-solid border-b-[1px]">
                                            <div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">病歷號</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">{popCvisNotifyData.patientId}</div>
                                            </div>
                                            <div div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">通報人員</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">{popCvisNotifyData.cvisNotifyUser}</div>
                                            </div>
                                        </li>
                                        <li className="flex flex-row items-center border-solid border-b-[1px]">
                                            <div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">通報對象</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">{popCvisNotifyData.cvisNotifyObject}</div>
                                            </div>
                                            <div div className="flex flex-row items-center">
                                                <div className="min-w-[160px] pr-4 py-[9px] text-end">通報電話</div>
                                                <div className="min-w-[260px] pl-4 py-[9px] text-start">0987654232</div>
                                            </div>
                                        </li>
                                        <li className="flex flex-row items-center border-solid border-b-[1px]">
                                            <div className="min-w-[160px] pr-4 py-[9px] text-end">通報內容</div>
                                            <div className="min-w-[681px] pl-4 py-[9px] text-start">{popCvisNotifyData.cvisNotifyContent}</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full border-solid border-t-[1px] flex justify-end pr-4 py-[10px]">
                                    <button className="w-[64px] h-10 bg-[#2B6CB0] text-white rounded-[6px] hover:cursor-pointer" onClick={handlePopupOnClose}>確定</button>
                                </div>
                                </>
                                )}
                            {/* 逾時回覆再通知紀錄 */}
                            {popUpTab === 'TimeoutReplyAssignmentRecord' && (
                                <div className=' flex flex-col w-full p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                                    {/* 搜尋欄 */}
                                    <div className='w-full relative'>
                                    <div className='max-w-[320px] relative'>
                                        <input
                                            className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                            type={InputTypeEnum.Text}
                                            value={popQueryString}
                                            onChange={handlePopQueryInputOnChange}
                                            placeholder="進階搜尋"
                                        />
                                        <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                            <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                        </div>
                                    </div>
                                    </div>
                                    {/* 清單表格 */}
                                    <div className='min-w-[850px]'>
                                        <ul className='tableList crisisAlertPopUpTable max-h-[736px]'>
                                            <li className='title'>
                                                <div>通報日期時間</div>
                                                <div>單號</div>
                                                <div>通報人員</div>
                                                <div>通報對象</div>
                                            </li>
                                            {!arrayIsEmpty(filteredPopCvisRenotifyList) && (
                                                filteredPopCvisRenotifyList.map((item, index) => {
                                                    return (
                                                        <CvisListItem 
                                                            type="ClosureMonitorPopupList"
                                                            index={index}
                                                            item={item}
                                                        />
                                                    )
                                                })
                                            )}
                                        </ul>
                                    </div>
                                    <Pagination
                                        totalPageSize={renotifyPaginationProps.totalPageSize}
                                        pageSize={renotifyPaginationProps.pageSize}
                                        totalSize={renotifyPaginationProps.totalItemSize}
                                        currentPage={renotifyPaginationProps.pageNum}
                                        onPageOnChange={onRenotifyPaginationPageOnChange}
                                        onPageSizeChange={onRenotifyPaginationPageSizeOnChange}
                                        onPrevPageOnClick={onRenotifyPaginationPreviousOnClick}
                                        onNextPageOnClick={onRenotifyPaginationNextOnClick}
                                    />
                                </div>
                            )}
                    </div>
                }
            />
        )}
    </div>
  )
}

export default CrisisAlertClosureMonitor;