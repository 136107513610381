import React, {useEffect, useState, useMemo} from "react"
import {BaseInput} from "../../components/Input/BaseInput"
import RadioButton from "../../components/Input/RadioButton"
import {SearchInput} from "../../components/Input/SearchInput"
import {DefaultButton} from "../../components/Button/DefaultButton"
import {
    InputTypeEnum,
    time2String,
    arrayIsEmpty,
    ToastTypeEnum,
    ApiErrorStatusEnum ,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"
import {Pagination} from "../../components/Pagination/Pagination"
import useToast from "../../hooks/useToast"
//Import Icon
import {ReactComponent as AddIcon} from '../../assets/images/icons/add_circle.svg';
import {ReactComponent as DeleteBtn} from "../../assets/images/icons/del_square.svg"
import {ReactComponent as CopyBtn} from '../../assets/images/SystemMonitoring/copy.svg';
import {ReactComponent as SaveBtn} from '../../assets/images/icons/save_default.svg';
import {ReactComponent as CancelBtn} from '../../assets/images/icons/cancel_default.svg';
import {ReactComponent as EditBtn} from "../../assets/images/ICCard/edit_square.svg";
import {CopySlotBtn} from "../../components/RSIS/SlotButton";
import { crudQueryTableData, crudSaveTableData, crudDelTableData } from "../../api/v1/CRUD"
import { deleteCategories } from "../../api/v1/Menu"
import { DeletePopup } from "../../components/Popup/DeletePopup"

const TypeMaintenance = () => {
    // 限資訊室維護資料
    const [itRoomMaintenance, setItRoomMaintenance] = useState(null)
    // 限資訊室維護資料的進階搜尋清單
    const [advItRoomMaintenance, setAdvItRoomMaintenance] = useState(null)
    // 限資訊室維護資料的新增Array
    const [insertItRoomDatas, setInsertItRoomDatas] = useState(null)
    // 單位維護資料
    const [unitMaintenance, setUnitMaintenance] = useState(null)
    // 單位維護資料的進階搜尋清單
    const [advUnitMaintenance, setAdvUnitMaintenance] = useState(null)
    // 限資訊室維護資料的新增Array
    const [insertUnitDatas, setInsertUnitDatas] = useState(null)
    // 限資訊室維護 pagination控制變數
    const [itRoomMaintenancePaginationProps, setItRoomMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 單位維護 pagination控制變數
    const [unitMaintenancePaginationProps, setUnitMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 類別輸入框搜尋文字
    const [inputTypeSearchText, setInputTypeSearchText] = useState('')
    // 類別搜尋的文字
    const [typeQueryText, setTypeQueryText] = useState('')
    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 限資訊室維護資料 進階搜尋文字
    const [advItRoomSearchText, setAdvItRoomSearchText] = useState('')
    //單位維護資料 進階搜尋文字
    const [advUnitSearchText, setAdvUnitSearchText] = useState('')
    //選擇的master資料
    const [selectedMasterData, setSelectedMasterData] = useState(null)
    //
    const [editItRoomDatas, setEditItRoomDatas] = useState(null)
    //
    const [editDetailDatas, setEditDetailDatas] = useState(null)
    //刪除的項目 (isMaster->是否為master, key->要刪除的項目key)
    const [delItem, setDelItem] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {

        setTypeQueryText(inputTypeSearchText)
        //master
        queryTypeDataForItRoom(inputTypeSearchText, 1, itRoomMaintenancePaginationProps.pageSize)
    }

    /**
     * 清除掉It room所有編輯狀態及新增中項目
     */
    const clearItRoomEditAndInsertItem = () => {
        //清除掉所有編輯狀態及新增中項目
        setInsertItRoomDatas(null)
        setEditItRoomDatas(null)
    }

    /**
     * 清除掉Detail所有編輯狀態及新增中項目
     */
    const clearDetailEditAndInsertItem = () => {
        setInsertUnitDatas(null)
        setEditDetailDatas(null)
    }

    /**
     * 查詢限資訊室維護的類別資料
     * @param {*} queryText 查詢的文字
     * @param {Number} page 查詢頁碼
     * @param {Number} pageSize 每頁資料筆數
     */
    const queryTypeDataForItRoom = (queryText, page, pageSize) => {
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()

        crudQueryTableData({
            //module name
            system: "menu",
            //compare operator
            compare: "like",
            tableName: "Justnamem",
            searchColumn: "categories",
            value: queryText,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return { ...item, key: index }
                }) : []
                setItRoomMaintenance(dataWithKeys)

                setSelectedMasterData(arrayIsEmpty(dataWithKeys) ? null : dataWithKeys[0])

                setItRoomMaintenancePaginationProps({
                    ...itRoomMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            }else{
                const msg = `取得限資訊室維護資料失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})

                setItRoomMaintenance(null)
                setItRoomMaintenancePaginationProps({
                    ...itRoomMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }

    /**
     * 查詢資訊室/單位維護的類別資料
     * @param {Number} page 查詢頁碼
     * @param {Number} pageSize 每頁資料筆數
     */
    const queryTypeDataForUnit = (page, pageSize) => {
    
        if(objectIsEmpty(selectedMasterData)) {
            //限資訊室維護資料(master)沒有選擇時
            setUnitMaintenance(null)
            setUnitMaintenancePaginationProps({
                ...unitMaintenancePaginationProps,
                totalItemSize: 0,
                totalPageSize: 0,
                pageSize: pageSize
            })
            return 
        }
        crudQueryTableData({
            //module name
            system: "menu",
            //compare operator
            compare: "==",
            tableName: "Justnamed",
            searchColumn: "categories",
            value: selectedMasterData.categories,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return { ...item, key: index }
                }) : []
                setUnitMaintenance(dataWithKeys)

                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            }else{
                const msg = `取得資訊室/單位維護資料失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
                setUnitMaintenance(null)
                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }

    /**
     * 限資訊室維護資料的進階搜尋
     */
    const getAdvanceItRoomSearchTypeData = () => {
        const tmpArray = 
            arrayIsEmpty(itRoomMaintenance) ? [] :
            itRoomMaintenance.map((item) => (
                {
                    //key
                    key: item.key,  //這個不要過濾，不在顯示欄位內

                    //只copy要進階搜尋的欄位
                    //類別代碼
                    categories: item.categories,
                    //類別名稱
                    name: item.name,
                    //停用日期
                    deactiveDate: time2String(item.deactiveDate, "YYYY-MM-DD")
                }
            ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advItRoomSearchText, ["key"]) //key不要搜尋
        setAdvItRoomMaintenance(findedDataArray) 

        if(!objectIsEmpty(selectedMasterData)) {
            //限資訊室維護資料有以選擇的資料時
            //進階搜尋後的資料是否包含被選擇的資料
            const findedSelected = arrayIsEmpty(findedDataArray) ? null : findedDataArray.find(item => item.key === selectedMasterData.key)
            if(objectIsEmpty(findedSelected)) {
                setSelectedMasterData(null)
                setUnitMaintenance(null)
                setAdvUnitSearchText('')
                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0
                })
            }
        }
    }

    /**
     * 單位維護資料的進階搜尋
     */
    const getAdvanceUnitSearchTypeData = () => {
        const tmpArray = 
            arrayIsEmpty(unitMaintenance) ? [] :
                unitMaintenance.map((item) => (
                {
                    //key
                    key: item.key,  //這個不要過濾，不在顯示欄位內

                    //只copy要進階搜尋的欄位
                    //類別代碼
                    justnameNo: item.justnameNo,
                    //類別名稱
                    justnameName: item.justnameName,
                    //排序
                    sortBy: item.sortBy,
                    //停用日期
                    deactiveDate: time2String(item.deactiveDate, "YYYY-MM-DD")
                }
            ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advUnitSearchText, ["key"]) //key不要搜尋
        setAdvUnitMaintenance(findedDataArray) 
    }

    /**
     * 限資訊室維護-頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onItRoomMaintenancePaginationPageOnChange = (page) => {
        queryTypeDataForItRoom(typeQueryText, page, itRoomMaintenancePaginationProps.pageSize)
    }

    /**
     * 限資訊室維護-上一頁點擊事件
     */
    const onItRoomMaintenancePaginationPreviousOnClick = () => {
        const page = (itRoomMaintenancePaginationProps.currentPage - 1) > 1 ? (itRoomMaintenancePaginationProps.currentPage - 1) : 1
        queryTypeDataForItRoom(typeQueryText, page, itRoomMaintenancePaginationProps.pageSize)
    }

    /**
     * 限資訊室維護-下一頁點擊事件
     */
    const onItRoomMaintenancePaginationNextOnClick = () => {
        const page = (itRoomMaintenancePaginationProps.currentPage + 1) < itRoomMaintenancePaginationProps.totalPageSize ? (itRoomMaintenancePaginationProps.currentPage + 1) : itRoomMaintenancePaginationProps.totalPageSize
        queryTypeDataForItRoom(typeQueryText, page, itRoomMaintenancePaginationProps.pageSize)
    }

    /**
     * 限資訊室維護-每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onItRoomMaintenancePaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        queryTypeDataForItRoom(typeQueryText, 1, e.target.value)
    }

    /**
     * 單位維護-頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onUnitMaintenancePaginationPageOnChange = (page) => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        //切換頁碼
        queryTypeDataForUnit(page, unitMaintenancePaginationProps.pageSize)
    }

    /**
     * 單位維護-上一頁點擊事件
     */
    const onUnitMaintenancePaginationPreviousOnClick = () => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        //切換頁碼
        const page = (unitMaintenancePaginationProps.currentPage - 1) > 1 ? (unitMaintenancePaginationProps.currentPage - 1) : 1
        queryTypeDataForUnit(page, unitMaintenancePaginationProps.pageSize)
    }

    /**
     * 單位維護-下一頁點擊事件
     */
    const onUnitMaintenancePaginationNextOnClick = () => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        //切換頁碼
        const page = (unitMaintenancePaginationProps.currentPage + 1) < unitMaintenancePaginationProps.totalPageSize ? (unitMaintenancePaginationProps.currentPage + 1) : unitMaintenancePaginationProps.totalPageSize
        queryTypeDataForUnit(page, unitMaintenancePaginationProps.pageSize)
    }

    /**
     * 單位維護-每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onUnitMaintenancePaginationPageSizeOnChange = (e) => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        // 設定每頁資料筆數
        queryTypeDataForUnit(1, e.target.value)
    }

    /**
     * 類別輸入框搜尋文字變更事件
     * @param e {Event} 事件
     */
    const handleInputTypeSearchTextOnChange = (e) => {
        setInputTypeSearchText(e.target.value)
    }

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupCloseOnClick = () => {
        setDelItem(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = () => {
        if(!objectIsEmpty(delItem) && delItem.isMaster !== null && delItem.isMaster !== undefined && delItem.key !== null && delItem.key !== undefined) {
            if(delItem.isMaster) {
                const fundedData = itRoomMaintenance.find(item => item.key === delItem.key)
                if(!objectIsEmpty(fundedData)) {
                    //master的項目存在，進行刪除
                    deleteCategories({
                        categories: fundedData.categories
                    }).then((res => {
                        if(res.err === ApiErrorStatusEnum.Success){
                            //更新左邊table
                            queryTypeDataForItRoom(typeQueryText, 1, itRoomMaintenancePaginationProps.pageSize)
                        }else{
                            const msg = `刪除失敗: ${res.msg}`
                            showToast({message:msg, type:ToastTypeEnum.Error})
                        }
                    }))
                }
            }
            else {
                const fundedData = unitMaintenance.find(item => item.key === delItem.key)
                if(!objectIsEmpty(fundedData)) {
                    //detail的項目存在，進行刪除
                    const tableData = [{
                        "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
                        "createUser": fundedData.createUser,
                        "modifyDatetime": fundedData.modifyDatetime,
                        "modifyUser": fundedData.modifyUser,
                        "lockVersion": fundedData.lockVersion,
                        "categories": fundedData.categories,
                        "justnameNo": fundedData.justnameNo,
                        "justnameName": fundedData.justnameName,
                        "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00'),
                        "sortBy": fundedData.sortBy
                    }]
                    //轉成json格式
                    const tableDataJSON = JSON.stringify(tableData)
                    
                    crudDelTableData({
                        "tableName": "tw.com.hismax.his.entity.menu.Justnamed",
                        "tableData": tableDataJSON
                    }).then((res => {
                        if(res.err === ApiErrorStatusEnum.Success){
                            //更新右邊table
                            queryTypeDataForUnit(1, itRoomMaintenancePaginationProps.pageSize)
                        }else{
                            const msg = `刪除失敗: ${res.msg}`
                            showToast({message:msg, type:ToastTypeEnum.Error})
                        }
                    }))
                }
            }
        }
        setDelItem(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupCloseOnClick()

    /**
     * 限資訊室維護(master)項目刪除按鈕點擊事件
     * @param {Number} keyToDel 
     */
    const handleItRoomDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItem({
            isMaster: true,
            key: keyToDel
        })
        setShowDeletePopup(true)
    }
    
    /**
     * 單位維護(detail)項目刪除按鈕點擊事件
     * @param {Number} keyToDel 
     */
    const handleUnitDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItem({
            isMaster: false,
            key: keyToDel
        })
        setShowDeletePopup(true)
    }

    /**
     * 限資訊室維護的進階搜尋輸入框文字變更事件
     * @param {Event} e 
     */
    const handleAdvItRoomSearchTextOnChange = (e) => {
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        setAdvItRoomSearchText(e.target.value)
    }

    /**
     * 單位維護的進階搜尋輸入框文字變更事件
     * @param {Event} e 
     */
    const handleAdvUnitSearchTextOnChange = (e) => {
        clearDetailEditAndInsertItem()
        setAdvUnitSearchText(e.target.value)
    }

    /**
     * It room 類別項目新增按鈕點擊事件
     */
    const handleAddItRoomTypeBtnOnClick = () => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()

        //清除右邊Detail
        setSelectedMasterData(null)

        const newType = {
            key: arrayIsEmpty(insertItRoomDatas)? 0 : insertItRoomDatas.length,
            categories: '',
            name: '',
            deactiveDate: ''
        }
 
        const updatedDatas = arrayIsEmpty(insertItRoomDatas) ? [newType] : [...insertItRoomDatas, newType]
        
        setInsertItRoomDatas(updatedDatas)
    }

    /**
     * 單位維護 類別項目新增按鈕點擊事件
     */
    const handleAddUnitTypeBtnOnClick = () => {
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
        
        const newType = {
            key: arrayIsEmpty(insertUnitDatas)? 0 : insertUnitDatas.length,
            justnameNo: '',
            justnameName: '',
            sortBy: null,
            deactiveDate: ''
        }

        const updatedDatas = arrayIsEmpty(insertUnitDatas) ? [newType] : [...insertUnitDatas, newType]
        
        setInsertUnitDatas(updatedDatas)
    }

    const isItRoomItemEdit = (item) => {
        if(objectIsEmpty(item) || arrayIsEmpty(editItRoomDatas)) {
            return false
        }

        return editItRoomDatas.find(data => data.key === item.key)
    }

    /**
     * It room 類別項目編輯按鈕點擊事件
     * @param {Number} key key
     */
    const handleItRoomEditBtnOnClick = (e, key) => {
        e.stopPropagation()
        //找出Type資料
        const fundedData = itRoomMaintenance.find(item => item.key === key)
        if(objectIsEmpty(fundedData)) {
            return
        }

        setSelectedMasterData(fundedData)

        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()

        setEditItRoomDatas([
            ...arrayIsEmpty(editItRoomDatas)? []:editItRoomDatas,
            fundedData
        ])
    }

    /**
     * 單位維護 類別項目編輯按鈕點擊事件
     * @param {Number} key key
     * @param {Event} e
     */
    const handleUnitEditBtnOnClick = (e, key) => {
        e.stopPropagation()
        //找出Type資料
        const fundedData = unitMaintenance.find(item => item.key === key)
        if(objectIsEmpty(fundedData)) {
            return
        }
        
        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()

        setEditDetailDatas([
            ...arrayIsEmpty(editDetailDatas)? []:editDetailDatas,
            fundedData
        ])
    }

    /**
     * It room 類別項目儲存按鈕點擊事件
     * @param {Number} keyToSave 儲存的項目key
     */
    const handleItRoomInsertSaveBtnOnClick = (keyToSave) => {
        const findedData = insertItRoomDatas.find(item => item.key === keyToSave)
        if(objectIsEmpty(findedData)) return
        
        const tableData = [{
            "createDatetime": null,
            "createUser": null,
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": 0,
            "categories": findedData.categories,
            "name": findedData.name,
            "deactiveDate": stringIsEmpty(findedData.deactiveDate) ? null : time2String(findedData.deactiveDate, 'YYYY-MM-DD 00:00:00')
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamem",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //更新右邊table
                queryTypeDataForItRoom(typeQueryText, 1, itRoomMaintenancePaginationProps.pageSize)
            }else{
                const msg = `儲存失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 單位維護 類別項目儲存按鈕點擊事件
     * @param {Number} keyToSave 儲存的項目key
     */
    const handleUnitInsertSaveBtnOnClick = (keyToSave) => {
        const findedData = insertUnitDatas.find(item => item.key === keyToSave)
        if(objectIsEmpty(findedData)) return

        const tableData = [{
            "createDatetime": null,
            "createUser": null,
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": 0,
            "categories": selectedMasterData.categories,
            "justnameNo": findedData.justnameNo,
            "justnameName": findedData.justnameName,
            "deactiveDate": stringIsEmpty(findedData.deactiveDate) ? null : time2String(findedData.deactiveDate, 'YYYY-MM-DD 00:00:00'),
            "sortBy": findedData.sortBy
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamed",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                //清除掉所有編輯狀態及新增中項目
                clearItRoomEditAndInsertItem()
                clearDetailEditAndInsertItem()
                //更新右邊table
                queryTypeDataForUnit(1, unitMaintenancePaginationProps.pageSize)
            }else{
                const msg = `儲存失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * It room 類別項目取消按鈕點擊事件
     * @param {Number} keyToDelete 取消的項目key
     */
    const handleItRoomInsertCancelBtnOnClick = (keyToDelete) => {
        //移除
        setInsertItRoomDatas(insertItRoomDatas.filter(item => item.key !== keyToDelete))
    }

    /**
     * 單位維護 類別項目取消按鈕點擊事件
     * @param {Number} keyToDelete 取消的項目key
     */
    const handleUnitInsertCancelBtnOnClick = (keyToDelete) => {
        //移除
        setInsertUnitDatas(insertUnitDatas.filter(item => item.key !== keyToDelete))
    }

    /**
     * It room 類別的類別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditItRoomTypeCategoriesTextOnChange = (e, key) => {
        setEditItRoomDatas(editItRoomDatas.map(item =>
            item.key === key ? { ...item, categories: e.target.value } : item
        ))
    }

    /**
     * It room 類別的類別名稱編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditItRoomTypeNameTextOnChange = (e, key) => {
        setEditItRoomDatas(editItRoomDatas.map(item =>
            item.key === key ? { ...item, name: e.target.value } : item
        ))
    }

    /**
     * It room 類別的停用日期編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditItRoomTypeDeactiveDateTextOnChange = (e, key) => {
        setEditItRoomDatas(editItRoomDatas.map(item =>
            item.key === key ? { ...item, deactiveDate: e.target.value } : item
        ))
    }

    /**
     * It room 類別編輯項目儲存按鈕點擊事件
     * @param {*} keyToSave 
     */
    const handleItRoomEditSaveBtnOnClick = (keyToSave) => {
        const fundedData = editItRoomDatas.find(item => item.key === keyToSave)
        if(objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
            "createUser": fundedData.createUser,
            "modifyDatetime": fundedData.modifyDatetime,
            "modifyUser": fundedData.modifyUser,
            "lockVersion": fundedData.lockVersion,
            "categories": fundedData.categories,
            "name": fundedData.name,
            "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00')
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamem",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //更新左邊table
                queryTypeDataForItRoom(typeQueryText, 1, itRoomMaintenancePaginationProps.pageSize)
            }else{
                const msg = `儲存失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    const handleItRoomEditCancelBtnOnClick = (keyToCancel) => {
        //移除
        setEditItRoomDatas(editItRoomDatas.filter(item => item.key !== keyToCancel))
    }

    /**
     * It room 預新增類別的類別代碼輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleItRoomTypeCategoriesTextOnChange = (e, key) => {
        setInsertItRoomDatas(insertItRoomDatas.map(item =>
            item.key === key ? { ...item, categories: e.target.value } : item
        ))
    }

    /**
     * It room 預新增類別的類別名稱輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleItRoomTypeNameTextOnChange = (e, key) => {
        setInsertItRoomDatas(insertItRoomDatas.map(item =>
            item.key === key ? { ...item, name: e.target.value } : item
        ))
    }
    
    /**
     * It room 預新增類別的停用日期輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleItRoomTypeDeactiveDateTextOnChange = (e, key) => {
        setInsertItRoomDatas(insertItRoomDatas.map(item =>
            item.key === key ? { ...item, deactiveDate: e.target.value } : item
        ))
    }

    /**
     * 單位維護 預新增類別的類別代碼輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleUnitTypeCategoriesTextOnChange = (e, key) => {
        setInsertUnitDatas(insertUnitDatas.map(item =>
            item.key === key ? { ...item, justnameNo: e.target.value } : item
        ))
    }

    /**
     * 單位維護 預新增類別的類別代碼輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleUnitTypeNameTextOnChange = (e, key) => {
        setInsertUnitDatas(insertUnitDatas.map(item =>
            item.key === key ? { ...item, justnameName: e.target.value } : item
        ))
    }

    /**
     *單位維護 預新增類別的排序輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleUnitTypeSortByTextOnChange = (e, key) => {
        setInsertUnitDatas(insertUnitDatas.map(item =>
            item.key === key ? { ...item, sortBy: e.target.value } : item
        ))
    }

    /**
     * 單位維護 預新增類別的停用日期輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleUnitTypeDeactiveDateTextOnChange = (e, key) => {
        setInsertUnitDatas(insertUnitDatas.map(item =>
            item.key === key ? { ...item, deactiveDate: e.target.value } : item
        ))
    }

    /**
     * 
     * @param {*} key 
     */
    const handleItRoomDataOnClick = (key) => {
        const findedData = itRoomMaintenance.find(item => item.key === key)
        setSelectedMasterData(findedData)

        //清除掉所有編輯狀態及新增中項目
        clearItRoomEditAndInsertItem()
        clearDetailEditAndInsertItem()
    }

    /**
     * Detail 類別的類別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleDetailEditTypeCategoriesTextOnChange = (e, key) => {
        setEditDetailDatas(editDetailDatas.map(item =>
            item.key === key ? { ...item, justnameNo: e.target.value } : item
        ))
    }

    /**
     * Detail 類別的類別名稱編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleDetailEditTypeNameTextOnChange = (e, key) => {
        setEditDetailDatas(editDetailDatas.map(item =>
            item.key === key ? { ...item, justnameName: e.target.value } : item
        ))
    }

    /**
     * Detail 類別的排序編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleDetailEditTypeSortByTextOnChange = (e, key) => {
        setEditDetailDatas(editDetailDatas.map(item =>
            item.key === key ? { ...item, sortBy: e.target.value } : item
        ))
    }

    /**
     * Detail 類別的停用日期編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleDetailEditTypeDeactiveDateTextOnChange = (e, key) => {
        setEditDetailDatas(editDetailDatas.map(item =>
            item.key === key ? { ...item, deactiveDate: e.target.value } : item
        ))
    }

    /**
     * Detail 類別編輯項目取消按鈕點擊事件
     * @param {Number} keyToCancel 
     */
    const handleDetailEditCancelBtnOnClick = (keyToCancel) => {
        //移除
        setEditDetailDatas(editDetailDatas.filter(item => item.key !== keyToCancel))
    }

    /**
     * Detail 類別編輯項目儲存按鈕點擊事件
     * @param {*} keyToSave 
     */
    const handleDetailEditSaveBtnOnClick = (keyToSave) => {
        const fundedData = editDetailDatas.find(item => item.key === keyToSave)
        if(objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
            "createUser": fundedData.createUser,
            "modifyDatetime": fundedData.modifyDatetime,
            "modifyUser": fundedData.modifyUser,
            "lockVersion": fundedData.lockVersion,
            "categories": fundedData.categories,
            "justnameNo": fundedData.justnameNo,
            "justnameName": fundedData.justnameName,
            "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00'),
            "sortBy": fundedData.sortBy
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamed",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //清除掉所有編輯狀態及新增中項目
                clearItRoomEditAndInsertItem()
                clearDetailEditAndInsertItem()
                //更新右邊table
                queryTypeDataForUnit(1, unitMaintenancePaginationProps.pageSize)
            }else{
                const msg = `儲存失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 檢查資料是否被選擇
     * @param {Object} data 
     * @returns 
     */
    const checkDataIsSelected = (data) => {
        if(objectIsEmpty(selectedMasterData)) {
            return false
        }
        if(selectedMasterData.key === data.key) {
            return true
        }
        return false
    }

    // 編輯按鈕
    const EditSlotBtn = ({disable = false, onClick}) => (
        <button 
            className={`flex items-center text-primary hover:cursor-pointer ${disable ? 'opacity-50':''}`}
            disabled={disable}
            onClick={onClick}>
            <EditBtn className='w-6 h-6 fill-[#A1A1AA]'/>
            <p>編輯</p>
        </button>
    );

    // 刪除按鈕
    const DelSlotBtn = ({disable = false, onClick}) => (
        <button 
            className={`flex items-center text-primary hover:cursor-pointer ${disable ? 'opacity-50':''}`}
            disabled={disable}
            onClick={onClick}>
            <DeleteBtn className='w-6 h-6'/>
            <p>刪除</p>
        </button>
    );

    //儲存按鈕
    const SaveIconSlotBtn = ({onClick}) => (
        <button 
            className="flex items-center text-primary hover:cursor-pointer" 
            onClick={onClick}>
            <SaveBtn className='w-6 h-6'/>
            儲存
        </button>
        /*<span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
            <button>
                <SaveBtn className='w-6 h-6 '/>
            </button>
            儲存
        </span>*/
    );

    //取消按鈕
    const CancelSlotBtn = ({onClick}) => (
        <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
            <button>
                <CancelBtn className='w-6 h-6 '/>
            </button>
            取消
        </span>
    );

    /**
     * 監聽advItRoomSearchText, itRoomMaintenance的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceItRoomSearchTypeData()
    }, [advItRoomSearchText, itRoomMaintenance])

    /**
     * advUnitSearchText, unitMaintenance的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceUnitSearchTypeData()
    }, [advUnitSearchText, unitMaintenance])

    /**
     * 監聽選擇的master資料變化
     */
    useEffect(() => {
        queryTypeDataForUnit(1, unitMaintenancePaginationProps.pageSize)
    }, [selectedMasterData])

    /**
     * 初始
     */
    useMemo(() => {
        handleSearchOnClick()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                {/*類別代碼*/}
                <div className="flex items-center">
                    <p>類別代碼：</p>
                    <SearchInput
                        searchText={inputTypeSearchText}
                        onChange={handleInputTypeSearchTextOnChange}
                    />
                </div>

                {/*查詢*/}
                <DefaultButton
                    type="primary" text={t('general.query')}
                    onClickFn={handleSearchOnClick}/>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*限資訊室維護*/}
                <div
                    className="w-1/2 p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                    <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                        <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                        <div className="text-xl text-[#2B6CB0] mr-3.5">限資訊室維護</div>
                        <SearchInput
                            placeholder="進階搜尋" searchText={advItRoomSearchText}
                            onChange={(e) =>handleAdvItRoomSearchTextOnChange(e)}/>
                    </div>
                    {/*Table*/}
                    <ul className="tableList typeMaintenanceTableItem min-h-[calc(100vh-325px)] max-h-[calc(100vh-325px)] overflow-x-auto overflow-y-auto">
                        <li className="title">
                            <div>
                                {/*新增按鈕*/}
                                <div className='w-[90px]'>
                                    <button 
                                        className={`flex flex-row items-center gap-1 my-4 disabled:opacity-50`}
                                        disabled={!arrayIsEmpty(editItRoomDatas) || !arrayIsEmpty(insertItRoomDatas) || !arrayIsEmpty(editDetailDatas) || !arrayIsEmpty(insertUnitDatas)}
                                        onClick={handleAddItRoomTypeBtnOnClick}>
                                        <AddIcon className="w-[33.33px] h-[33.33px]"/>
                                        <p className='text-[#38A169]'>{t('general.add')}</p>
                                    </button>
                                </div>
                            </div>
                            <div>類別代碼</div>
                            <div>類別名稱</div>
                            <div>停用日期</div>
                        </li>
                        {
                            !arrayIsEmpty(advItRoomMaintenance) && advItRoomMaintenance.map((item) => {
                                let funddedEditItem = null
                                const haveEditOrNewItem = (!arrayIsEmpty(editItRoomDatas) || !arrayIsEmpty(insertItRoomDatas) || !arrayIsEmpty(editDetailDatas) || !arrayIsEmpty(insertUnitDatas))
                                if(!objectIsEmpty(item) && !arrayIsEmpty(editItRoomDatas)) {
                                    funddedEditItem = editItRoomDatas.find(data => data.key === item.key)
                                }

                                return (
                                objectIsEmpty(funddedEditItem) ?
                                <li key={item.key}
                                    className={`${checkDataIsSelected(item) ? "selected" : ""}`}
                                    onClick={() => handleItRoomDataOnClick(item.key)}>
                                    {/*操作按鈕*/}
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4'>
                                            <EditSlotBtn disable={haveEditOrNewItem} onClick={(e) => handleItRoomEditBtnOnClick(e, item.key)}/>
                                            <DelSlotBtn disable={haveEditOrNewItem} onClick={() => handleItRoomDeleteTypeBtnOnClick(item.key)}/>
                                        </div>
                                    </div>
                                    {/*類別代碼*/}
                                    <div>{item.categories}</div>
                                    {/*類別名稱*/}
                                    <div>{item.name}</div>
                                    {/*停用日期*/}
                                    <div>{time2String(item.deactiveDate, "YYYY-MM-DD")}</div>
                                </li> :

                                <li key={funddedEditItem.key}
                                    className={`${checkDataIsSelected(funddedEditItem) ? "selected" : ""}`}>
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                            <SaveIconSlotBtn onClick={() => handleItRoomEditSaveBtnOnClick(funddedEditItem.key)}/>
                                            <CancelSlotBtn onClick={() => handleItRoomEditCancelBtnOnClick(funddedEditItem.key)}/>
                                        </div>
                                    </div>
                                    {/*類別代碼*/}
                                    <div>{funddedEditItem.categories}</div>
                                    {/*類別名稱*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.name}
                                            onChange={(e) => handleEditItRoomTypeNameTextOnChange(e, funddedEditItem.key)}/>
                                    </div>

                                    {/*停用日期*/}
                                    <div className="baseInput">
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="pl-8 bg-dateIcon relative bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat w-[150px] h-10 border-[1px] border-[#D4D4D8] rounded-md"
                                            type={InputTypeEnum.Date} max="9999-12-31" value={time2String(funddedEditItem.deactiveDate, "YYYY-MM-DD")}
                                            onChange={(e) => handleEditItRoomTypeDeactiveDateTextOnChange(e, funddedEditItem.key)}/>
                                        <style>
                                            {`input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                                        </style>
                                    </div>
                                </li>
                            )})
                        }
                        {
                            //預新增的項目
                            !arrayIsEmpty(insertItRoomDatas) && insertItRoomDatas.map((item) => (
                                <li key={item.key} className="selected">
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                            <SaveIconSlotBtn onClick={() => handleItRoomInsertSaveBtnOnClick(item.key)}/>
                                            <CancelSlotBtn onClick={() => handleItRoomInsertCancelBtnOnClick(item.key)}/>
                                        </div>
                                    </div>
                                    {/*類別代碼*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={item.categories}
                                            onChange={(e) => handleItRoomTypeCategoriesTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*類別名稱*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={item.name}
                                            onChange={(e) => handleItRoomTypeNameTextOnChange(e, item.key)}/>
                                    </div>
                                    
                                    {/*停用日期*/}
                                    <div className="baseInput">
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="pl-8 bg-dateIcon relative bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat w-[150px] h-10 border-[1px] border-[#D4D4D8] rounded-md"
                                            type={InputTypeEnum.Date} max="9999-12-31" value={time2String(item.deactiveDate, "YYYY-MM-DD")}
                                            onChange={(e) => handleItRoomTypeDeactiveDateTextOnChange(e, item.key)}/>
                                        <style>
                                            {`input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                                        </style>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={itRoomMaintenancePaginationProps.totalPageSize}
                            currentPage={itRoomMaintenancePaginationProps.currentPage}
                            pageSize={itRoomMaintenancePaginationProps.pageSize}
                            totalSize={itRoomMaintenancePaginationProps.totalItemSize}
                            onPageOnChange={(page) => onItRoomMaintenancePaginationPageOnChange(page)}
                            onPrevPageOnClick={onItRoomMaintenancePaginationPreviousOnClick}
                            onNextPageOnClick={onItRoomMaintenancePaginationNextOnClick}
                            onPageSizeChange={onItRoomMaintenancePaginationPageSizeOnChange}
                        />
                    </div>
                </div>
                {/*（資訊室/單位維護）*/}
                {
                    !objectIsEmpty(selectedMasterData) && 
                    <div
                        className="w-1/2 p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                        <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                            <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                            <div className="text-xl text-[#2B6CB0] mr-3.5">資訊室/單位維護</div>
                            <SearchInput placeholder="進階搜尋" searchText={advUnitSearchText}
                                onChange={(e) =>handleAdvUnitSearchTextOnChange(e)}/>
                        </div>
                        {/*Table*/}
                        <ul className="tableList typeMaintenanceTableItemRight min-h-[calc(100vh-325px)] max-h-[calc(100vh-325px)] overflow-x-auto overflow-y-auto">
                            <li className="title">
                                <div>
                                    {/*新增按鈕*/}
                                    <div className='w-[90px]'>
                                        <button 
                                            className={`flex flex-row items-center gap-1 my-4 disabled:opacity-50`}
                                            disabled={!arrayIsEmpty(editItRoomDatas) || !arrayIsEmpty(insertItRoomDatas) || !arrayIsEmpty(editDetailDatas) || !arrayIsEmpty(insertUnitDatas)}
                                            onClick={handleAddUnitTypeBtnOnClick}>
                                            <AddIcon className="w-[33.33px] h-[33.33px]"/>
                                            <p className='text-[#38A169]'>{t('general.add')}</p>
                                        </button>
                                    </div>
                                </div>
                                <div>代碼</div>
                                <div>名稱</div>
                                <div>排序</div>
                                <div>停用日期</div>
                            </li>
                            {
                                !arrayIsEmpty(advUnitMaintenance) && advUnitMaintenance.map((item) => {
                                    let funddedEditItem = null
                                    const haveEditOrNewItem = (!arrayIsEmpty(editItRoomDatas) || !arrayIsEmpty(insertItRoomDatas) || !arrayIsEmpty(editDetailDatas) || !arrayIsEmpty(insertUnitDatas))
                                    if(!objectIsEmpty(item) && !arrayIsEmpty(editDetailDatas)) {
                                        funddedEditItem = editDetailDatas.find(data => data.key === item.key)
                                    }

                                    return (
                                    objectIsEmpty(funddedEditItem) ?
                                    <li key={item.key}>
                                        {/*操作按鈕*/}
                                        <div>
                                            <div
                                                className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                                <EditSlotBtn disable={haveEditOrNewItem} onClick={(e) => handleUnitEditBtnOnClick(e, item.key)}/>
                                                <DelSlotBtn disable={haveEditOrNewItem} onClick={() => handleUnitDeleteTypeBtnOnClick(item.key)}/>
                                            </div>
                                        </div>
                                        {/*類別代碼*/}
                                        <div>{item.justnameNo}</div>
                                        {/*類別名稱*/}
                                        <div>{item.justnameName}</div>
                                        {/*排序*/}
                                        <div>{item.sortBy}</div>
                                        {/*停用日期*/}
                                        <div>{time2String(item.deactiveDate, "YYYY-MM-DD")}</div>
                                    </li> :
                                    <li key={funddedEditItem.key}>
                                        <div>
                                            <div
                                                className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                                <SaveIconSlotBtn onClick={() => handleDetailEditSaveBtnOnClick(funddedEditItem.key)}/>
                                                <CancelSlotBtn onClick={() => handleDetailEditCancelBtnOnClick(funddedEditItem.key)}/>
                                            </div>
                                        </div>
                                        {/*類別代碼*/}
                                        <div>{funddedEditItem.justnameNo}</div>
                                        {/*類別名稱*/}
                                        <div>
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.justnameName}
                                                onChange={(e) => handleDetailEditTypeNameTextOnChange(e, funddedEditItem.key)}/>
                                        </div>

                                        {/*排序*/}
                                        <div>
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="number" value={funddedEditItem.sortBy}
                                                onChange={(e) => handleDetailEditTypeSortByTextOnChange(e, funddedEditItem.key)}/>
                                            {/*Input hide number arrow*/}
                                            <style>
                                                {`
                                                input::-webkit-outer-spin-button,
                                                input::-webkit-inner-spin-button {
                                                    -webkit-appearance: none;
                                                    margin: 0;
                                                }
                                                input[type="number"] {
                                                    -moz-appearance: textfield;
                                                }
                                                `}
                                            </style>
                                        </div>

                                        {/*停用日期*/}
                                        <div className="baseInput">
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="pl-8 bg-dateIcon relative bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat w-[150px] h-10 border-[1px] border-[#D4D4D8] rounded-md"
                                                type={InputTypeEnum.Date} max="9999-12-31" value={time2String(funddedEditItem.deactiveDate, "YYYY-MM-DD")}
                                                onChange={(e) => handleDetailEditTypeDeactiveDateTextOnChange(e, funddedEditItem.key)}/>
                                            <style>
                                                {`input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                                            </style>
                                        </div>
                                    </li>
                               )})
                            }
                            {
                                //預新增的項目
                                !arrayIsEmpty(insertUnitDatas) && insertUnitDatas.map((item) => (
                                    <li key={item.key}>
                                        <div>
                                            <div
                                                className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                                <SaveIconSlotBtn onClick={() => handleUnitInsertSaveBtnOnClick(item.key)}/>
                                                <CancelSlotBtn onClick={() => handleUnitInsertCancelBtnOnClick(item.key)}/>
                                            </div>
                                        </div>
                                        {/*類別代碼*/}
                                        <div>
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={item.justnameNo}
                                                onChange={(e) => handleUnitTypeCategoriesTextOnChange(e, item.key)}/>
                                        </div>
                                        {/*類別名稱*/}
                                        <div>
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={item.justnameName}
                                                onChange={(e) => handleUnitTypeNameTextOnChange(e, item.key)}/>
                                        </div>
                                        {/*排序*/}
                                        <div>
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="number" value={item.sortBy}
                                                onChange={(e) => handleUnitTypeSortByTextOnChange(e, item.key)}/>
                                            {/*Input hide number arrow*/}
                                            <style>
                                                {`
                                                input::-webkit-outer-spin-button,
                                                input::-webkit-inner-spin-button {
                                                    -webkit-appearance: none;
                                                    margin: 0;
                                                }
                                                input[type="number"] {
                                                    -moz-appearance: textfield;
                                                }
                                                `}
                                            </style>
                                        </div>
                                        {/*停用日期*/}
                                        <div className="baseInput">
                                            {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                            <input
                                                className="pl-8 bg-dateIcon relative bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat w-[150px] h-10 border-[1px] border-[#D4D4D8] rounded-md"
                                                type={InputTypeEnum.Date} max="9999-12-31" value={time2String(item.deactiveDate, "YYYY-MM-DD")}
                                                onChange={(e) => handleUnitTypeDeactiveDateTextOnChange(e, item.key)}/>
                                            <style>
                                                {`input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                                            </style>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <Pagination
                                totalPageSize={unitMaintenancePaginationProps.totalPageSize}
                                currentPage={unitMaintenancePaginationProps.currentPage}
                                pageSize={unitMaintenancePaginationProps.pageSize}
                                totalSize={unitMaintenancePaginationProps.totalItemSize}
                                onPageOnChange={(page) => onUnitMaintenancePaginationPageOnChange(page)}
                                onPrevPageOnClick={onUnitMaintenancePaginationPreviousOnClick}
                                onNextPageOnClick={onUnitMaintenancePaginationNextOnClick}
                                onPageSizeChange={onUnitMaintenancePaginationPageSizeOnChange}
                            />
                        </div>
                    </div>
                }
            </div>
            {/* 刪除彈窗 */}
            <DeletePopup show={showDeletePopup} content="是否確定刪除?"
                         onClosePopupButtonClick={handleDeletePopupCloseOnClick}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </div>
    )
}

export default TypeMaintenance