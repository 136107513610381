//Import Icon
import {ReactComponent as AddIcon} from '../../../assets/images/icons/add_circle.svg'

//Import Function
import React, {useEffect, useMemo, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import ListItem from './ListItem'
import {usePopup} from '../PopupProvider'
import {BaseInput} from '../../Input/BaseInput'
import {ApiErrorStatusEnum, InputTypeEnum, stringIsEmpty, TimeslotEnum, ToastTypeEnum} from 'edah_utils/dist'
import DeletePopup from '../Popup/DeletePopup'
import SpecialPermissionMaintain from './SpecialPermissionMaintain'
import {t} from "i18next"
import {rsisDelSpecialRole, rsisQuerySpecialRole} from "../../../api/v1/RSIS"
import QueryPersonList from "../Modal/QueryPersonList";
import {FuzzySearchInput} from "../FuzzySearchInput";
import {queryAllDivision, queryAllDoctor} from "../../../api/v1/Menu"
import {Title} from "../utils";

// 表格欄位
const fields = [
    "",
    "科別",
    "醫師",
    "時段",
    "看診日期",
    "診室名稱",
]

/**
 * 報備支援-特殊權限設定頁面
 * @return {JSX.Element}
 */
function SpecialPermissionSetting() {
    // 科別代號
    const [div, setDiv] = useState('')
    // 醫師名稱
    const [user, setUser] = useState('')
    // 時段
    const [apn, setApn] = useState('')
    // 看診日期
    const [encountDate, setEncountDate] = useState('')
    // 診室號
    const [clinicNo, setClinicNo] = useState('')
    // 列表資料
    const [dataList, setDataList] = useState([])
    // 科別清單
    const [divisionList, setDivisionList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])

    // 人員清單查詢彈窗開關
    const [showQueryPersonList, setShowQueryPersonList] = useState(false)

    const List = ListItem

    // 分頁
    const paginationProps = useState({
        total: 100,
        pageSize: 10,
        current: 1,
        onChange: (page) => {
        }
    })

    // 使用上下文
    const {
        showDeletePopup,
        setShowDeletePopup,
        showSpecialPermissionMaintain,
        setShowSpecialPermissionMaintain,
        focusItem,
        setFocusItem,
        showToast,
    } = usePopup()

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = () => delSpecialRole(focusItem)

    /**
     * 新增列表事件，根據editable狀態新增一個可編輯或不可編輯的列表
     * @return {void}
     */
    const handleAddItemOnClick = () => {
        setFocusItem(null)
        setShowSpecialPermissionMaintain(true)
    }

    /**
     * 科別代號更新時
     * @return {void}
     */
    const handleDivChange = (value) => setDiv(value)

    /**
     * 科別名稱更新時
     * @return {void}
     */
    const handleUserChange = (value) => {
        setUser(`${value.userNo} ${value.userName}`)
        setShowQueryPersonList(false)
    }

    /**
     * 查詢
     * @return {void}
     */
    const handleQuery = () => {
        // EDAQA-415 不需輸入看診日期，即可查詢全部資料
        getQuerySpecialRole()
    }

    /**
     * 人員清單查詢表單關閉時
     * @return {void}
     */
    const handleQueryPersonListOnClose = () => setShowQueryPersonList(false)

    /**
     * 特殊權限維護表單關閉時
     * @return {void}
     */
    const handleSpecialPermissionMaintainOnClose = () => setShowSpecialPermissionMaintain(false)

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)


    /**
     * 取得所有科別
     * @return {void}
     */
    const getAllDivision = () => {
        queryAllDivision({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDivisionList(data)
            } else {
                setDivisionList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getAllDoctor = () => {
        queryAllDoctor().then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDoctorList(data)
            } else {
                setDoctorList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 新增或修改後更新列表
     * @return {void}
     */
    const handleOnUpdate = () => getQuerySpecialRole()


    /**
     * 重置搜尋條件按鈕點擊時
     * @return {void}
     */
    const handleResetSearchButtonOnClick = () => {
        // 清空值
        setDiv("")
        setUser("")
        setApn("")
        setEncountDate("")
        setClinicNo("")
    }

    /**
     * 看診日期改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEncountDateOnChange = (e) => setEncountDate(e.target.value)

    /**
     * 取得特殊權限
     * @return {void}
     */
    const getQuerySpecialRole = () => {
        rsisQuerySpecialRole({
            //部門代號
            divNo: div.indexOf(' ') > 0 ? div.split(' ')[0] : div,
            //人員代號
            userNo: user.indexOf(' ') > 0 ? user.split(' ')[0] : user,
            // 時段
            apn: apn,
            // 看診日期
            encountDate: !stringIsEmpty(encountDate) ? `${encountDate} 00:00:00` : '',
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setDataList(data)
            } else { // 取得失敗
                //清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除特殊權限設定(無需稽核報備設定)資料
     * @param {object} item 刪除的資料
     * @return {void}
     */
    const delSpecialRole = (item) => {
        rsisDelSpecialRole({
            specialRoleId: item.specialRoleId,
        }).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQuerySpecialRole()
                //清空資料
                setFocusItem(null)
                // 關閉刪除popup
                setShowDeletePopup(false)
                // 顯示成功訊息
                showToast({message: msg, type: ToastTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得所有科別
        getAllDivision()
        // 取得所有醫師
        getAllDoctor()
        // 取得所有特殊權限
        getQuerySpecialRole()
    }, []);

    return (
        <>
            <div className="flex flex-col justify-start px-4 py-2 gap-4">
                <div className='flex flex-row items-center justify-start gap-4'>
                    {/* 科別代號 */}
                    <div className="flex flex-row items-center justify-start">
                        <FuzzySearchInput
                            title='科別代號：'
                            titleWidth='75px'
                            list={divisionList}
                            valueKey={['divNo', 'divName']}
                            value={div}
                            handleValueChange={handleDivChange}
                            inputWidth={'w-[170px]'}
                        />
                    </div>
                    {/* 醫師代號 */}
                    <div className="flex flex-row items-center justify-start">
                        <Title>{'員工/醫師代碼：'} </Title>
                        <BaseInput
                            className='w-[186px] h-10 pl-4 bg-white text-black rounded-[6px] border border-gray-300'
                            inputMode={InputTypeEnum.Text}
                            type={InputTypeEnum.Text}
                            value={user}
                            onClick={() => setShowQueryPersonList(true)}
                        />
                    </div>
                    {/* 時段 */}
                    <div className="flex flex-row items-center justify-start gap-2">
                        <p className=''>時段 :</p>
                        <select
                            className="w-[102px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-4 hover:cursor-pointer"
                            value={apn}
                            onChange={(e) => setApn(e.target.value)}>
                            {/*早*/}
                            <option value={TimeslotEnum.Morning}>
                                {t('general.dateTime.timeslot.short.morning')}
                            </option>
                            {/*中*/}
                            <option value={TimeslotEnum.Afternoon}>
                                {t('general.dateTime.timeslot.short.afternoon')}
                            </option>
                            {/*晚*/}
                            <option value={TimeslotEnum.Night}>
                                {t('general.dateTime.timeslot.short.night')}
                            </option>
                            <option value="">全部</option>
                        </select>
                    </div>
                    {/* 看診日期 */}
                    <div className="flex flex-row items-center justify-start gap-2">
                        <p className="">看診日期 :</p>
                        <BaseInput
                            className="w-[200px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                            type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} value={encountDate}
                            max="9999-12-31"
                            onChange={handleEncountDateOnChange}/>
                    </div>
                    {/* 診室號 */}
                    <div className="flex flex-row items-center justify-start gap-2">
                        <p className="">診室名稱 :</p>
                        <input
                            className='w-[102px] h-[42px] border-[1px] border-[#D4D4D8] rounded-[6px] pl-4 hover:cursor-pointer'
                            value={clinicNo}
                            onChange={(e) => setClinicNo(e.target.value)}/>
                    </div>
                    <div className="source flex flex-row items-center justify-start">
                        {/* 查詢按鈕 */}
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleQuery}>
                            {t('general.query')}
                        </button>
                        {/* 清除查詢條件按鈕 */}
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#90CDF4] text-white rounded-[6px]"
                            onClick={handleResetSearchButtonOnClick}>
                            {t('general.clearQueryParams')}
                        </button>
                    </div>
                </div>
                {/*新增按鈕*/}
                <div className='w-[76px] flex flex-row items-center gap-1 ml-1 hover:cursor-pointer'
                     onClick={handleAddItemOnClick}>
                    <AddIcon className="w-[33.33px] h-[33.33px]"/>
                    <p className='text-[#38A169]'>{t('general.add')}</p>
                </div>
                {/* 表格 */}
                <CustomTable
                    advancedSearch={true}
                    fields={fields}
                    dataList={dataList}
                    ListItem={List}
                    hasSelectAll={false}
                    type="RSISSpecialSettingTable"
                    saveSlotBtn={false}
                    exportSlotBtn={false}
                    paginationProps={paginationProps}/>
            </div>
            {/* 刪除列表popup */}
            {
                showDeletePopup && (
                    <DeletePopup
                        handleDeletePopupClose={handleDeletePopupOnClose}
                        handleNotificationDelete={handleListItemOnDelete}
                        currentKey={focusItem}/>
                )
            }
            {/* 特殊權限維護 */}
            {
                showSpecialPermissionMaintain && (
                    <SpecialPermissionMaintain
                        doctorList={doctorList}
                        divList={divisionList}
                        item={focusItem}
                        handleOnClose={handleSpecialPermissionMaintainOnClose}
                        handleOnUpdate={handleOnUpdate}/>
                )
            }
            {/* 人員清單查詢 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        handleOnClose={handleQueryPersonListOnClose}
                        handleOnUpdate={handleUserChange}/>
                )
            }
        </>
    )
}

export default SpecialPermissionSetting
