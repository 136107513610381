import React, { useState } from 'react'
import ReferralFormSearchBar from '../../components/ReferralSystem/ReferralForm/ReferralFormSearchBar'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { REFERRAL_FORM_FIELDS } from '../../constants/referralSystem/referralForm'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import {
    ApiErrorStatusEnum,
    time2String,
    ToastTypeEnum,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import { downloadAllApi, downETranQueryBySearch } from '../../api/v1/Rmis'
import { DATETIME_FORMAT } from '../../constants/common'
import useToast from '../../hooks/useToast'
import moment from 'moment'
import {t} from 'i18next'

/**
 * 電子轉診單_下載
 * @returns {JSX.Element}
 */
const ReferralFormDownload = () => {
    /**
     * 電子轉診單清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    /**
     * 查詢欄位
     */
    const [searchParams, setSearchParams] = useState({
        // 有效日期(起日)
        effStartDate: '',
        // 有效日期(迄日)
        effEndDate: '',
        // 身分證號
        idNo: '',
    })

    /**
     * toast message
     */
    const showToast = useToast()

    /**
     * 匯出
     */ 
    const exportText = () => {
        const selectedDataList = dataList.filter((item) => item.isSelected)
        if (selectedDataList.length === 0) {
            return
        }
        const dataArray = []
        selectedDataList.forEach((item) => {
            const data = []
            REFERRAL_FORM_FIELDS.forEach((field) => {
                switch (field.key) {
                    case 'orderDate':
                    case 'orderDataUploadDatetime':
                        data.push(field.format(item[field.key])) // 時間格式
                        break
                    default:
                        data.push(item[field.key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: REFERRAL_FORM_FIELDS.map((item) => item.name),
                data: dataArray
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '電子轉診單',
            FileExtensionsEnum.XLSX
        )
    }

    // 插入CustomTable的匯出按鈕
    const ExportTextButton = () => {
        return (
            <button
                className="flex items-center justify-center px-4 h-10 rounded-[6px] text-[#38A169]"
                onClick={exportText}
            >
                <ExportText className="mr-1" />
                {t('general.export')}
            </button>
        )
    }

    /**
     * 查詢電子轉診單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const getETranList = (pageNum, pageSize) => {
        if (
            stringIsEmpty(searchParams.effStartDate) ||
            stringIsEmpty(searchParams.effEndDate)
        ) {
            return
        }
        const params = {
            ...searchParams,
            pageNum,
            pageSize,
        }
        downETranQueryBySearch(params).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                const list = response.data.dataList
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }

                const modifiedList = list.map((item) => {
                    const tranPurpose = item.tranPurpose || ''
                    const tranPurposeRemark = item.tranPurposeRemark || ''
                    return {
                        ...item,
                        tranPurposeContent: `${tranPurpose}.${tranPurposeRemark}`,
                        isSelected: false,
                    }
                })
                setDataList(modifiedList)
                setFilter({
                    ...filter,
                    totalItemSize: response.data.totalItemSize,
                    totalPageSize: response.data.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                showToast({
                    message: response.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 點選查詢按鈕
     */
    const handleQueryETranList = () => {
        getETranList(1, filter.pageSize)
    }

    /**
     * 更新查詢條件
     * @param {object} searchParams
     */
    const searchParamsOnChange = (searchParams) => {
        setSearchParams({
            effStartDate: time2String(
                searchParams.effStartDate,
                DATETIME_FORMAT
            ),
            effEndDate: time2String(searchParams.effEndDate, DATETIME_FORMAT),
            idNo: searchParams.idNo,
        })
    }

    /**
     * 點選頁碼
     * @param {number} page
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getETranList(currentPage, filter.pageSize)
    }

    /**
     * 更新每頁筆數
     * @param {event} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getETranList(1, pageSize)
    }

    /**
     * 下一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 上一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.etranNo === item.etranNo) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    const applyDownloadEtran = () => {
        const today = time2String(new Date(), DATETIME_FORMAT)
        const yesterday = time2String(
            moment(today).subtract(1, 'days'),
            DATETIME_FORMAT
        )
        const params = {
            tranApiStartDate: yesterday, // 系統前一天日期
            tranApiEndDate: today, // 系統當天日期
        }
        downloadAllApi(params).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: '申請下載成功',
                    type: ToastTypeEnum.Success,
                })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }
    return (
        <div className="p-2 w-full">
            <div className="flex justify-between p-2">
                <ReferralFormSearchBar
                    handleQuery={handleQueryETranList}
                    searchParamsOnChange={searchParamsOnChange}
                />
                <div>
                    <button
                        className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                        onClick={applyDownloadEtran}
                    >
                        {t('general.download')}
                    </button>
                </div>
            </div>
            <CustomTable
                fields={REFERRAL_FORM_FIELDS}
                slotButton={<ExportTextButton />}
                dataList={dataList}
                isSelectable={true}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
        </div>
    )
}

export default ReferralFormDownload
