import React, {useEffect, useMemo, useState} from "react"
import { BasePopup } from "../../Popup/BasePopup"
import { BaseInput } from "../../Input/BaseInput"
import {t} from "i18next"
import {ApiErrorStatusEnum, convertGregorianToMinGuo, convertMinGuoToGregorian, objectIsEmpty, stringIsEmpty, time2String, ToastTypeEnum} from "edah_utils/dist"
import { SwitchContainer } from "../../ICCard/InfoPage/SwitchContainer"
import {icHisGetHisRegisterBasic, icHisWriteNewBorn} from "../../../api/v1/LocalService"
import {ReactComponent as EditBtn} from "../../../assets/images/ICCard/edit_square.svg"
import {ReactComponent as EditCheck} from "../../../assets/images/ICCard/edit_check.svg"
import '../../ICCard/PatientInfo.scss'
import useToast from "../../../hooks/useToast"

const IcCardViewPopup = ({ show = false, closePopupButtonOnClick, icCardData }) => {
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)
    // 資料
    const [data, setData] = useState({})
    // 控制是否可編輯日期及胎兒數字
    const [isEditable, setIsEditable] = useState(false);
    // 控制是否可編輯電話
    const [isPhoneEdit, setIsPhoneEdit] = useState(false);
    // 新生兒資料修改部分
    const [modifyChildData, setModifyChildData] = useState({})
    // 新生兒資料
    const [info, setInfo] = useState({})
    //Toast Message Hooks
    const showToast = useToast()

    /**
     *  新生兒出生日期變更
     *  @param event {Event} 事件
     *  @return {void}
     */
    const handleChildBirthDateOnChange = (event) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒出生日期
        setModifyChildData({
            childBirthDate: convertGregorianToMinGuo(event.target.value),
            childBornNote: modifyChildData.childBornNote,
        })
    }

    /**
     * 新生兒胞胎註記數量變更
     * @param event {Event} 事件
     * @return {void}
     */
    const handleChildBornNoteOnChange = (event) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒胞胎註記
        setModifyChildData({
            childBirthDate: modifyChildData.childBirthDate,
            childBornNote: event.target.value
        })
    }

    /**
     * 新生兒Icon點擊事件
     * @return {void}
     */
    const handleChildIconOnClick = () => {
        // 取得新的狀態
        const newResult = !isEditable

        // 取得新生兒資料
        const newData = newResult ? {
            //新生兒出生日期
            childBirthDate: data.childBirthDate,
            //新生兒胞胎註記
            childBornNote: data.childBornNote
        } : {}

        // 如果上一步是儲存狀態
        if (!newResult) {
            //比較值是否有變更
            const keys = Object.keys(modifyChildData)
            //是否有被變更過資料
            const isModify = keys.every(key => modifyChildData[key] === data[key])
            //有修改過則呼叫API
            if (!isModify) {
                icHisWriteNewBorn({
                    //身分證號
                    pPatientID: data.idNo,
                    //生日(明國年月日)
                    pPatientBirthDate: data.birthDate,
                    //新生兒出生日期(明國年月日)
                    pNewBornDate: modifyChildData.childBirthDate,
                    //新生兒胞胎註記
                    pNoOfDelivered: modifyChildData.childBornNote
                }).then(res => {
                    if (res.err === ApiErrorStatusEnum.Success) {
                        // 成功的話直接修改當前的資料
                        setInfo({
                            ...info,
                            childBirthDate: modifyChildData.childBirthDate,
                            childBornNote: modifyChildData.childBornNote
                        })
                    }
                    else {
                        showToast({message:res.msg, type:ToastTypeEnum.Error})
                    }
                })
            }
        }

        // 設定新狀態
        setIsEditable(newResult)
        // 設定新生兒資料
        setModifyChildData(newData)
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="w-full px-5 pb-5">
                {/*病人資料*/}
                <div className="w-full px-6 border-[1px] border-[#D4D4D8] rounded-[12px] mb-4 py-4 bg-white">
                    <div className="flex flex-row w-full overflow-scroll">
                    {/*病人基本資料*/}
                        <div className="info min-w-[1680px]">
                            <ul className="infoTable1 flex flex-row items-center justify-start w-full h-[42px] mb-2">
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">卡號</div>
                                    <div>{objectIsEmpty(info)? "" : info.icCardNo}</div>
                                </li>
                                <li className="w-[310px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">姓名</div>
                                    <div>{objectIsEmpty(info)? "" : info.patientName}</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">身分證號</div>
                                    <div>{objectIsEmpty(info)? "" : info.idNo}</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">性別</div>
                                    <div>{objectIsEmpty(info)? "" : info.gender === 'F' ? t('general.gender.female') : t('general.gender.male')}</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">生日</div>
                                    <div>{objectIsEmpty(info)? "" : time2String(info.birthDate, "YYYY-MM-DD")}</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">發卡日期</div>
                                    <div>{objectIsEmpty(info)? "" : stringIsEmpty(info.cardIssueDate) ? "" : time2String(info.cardIssueDate, "YYYY-MM-DD")}</div>
                                </li>
                            </ul>
                            <ul className="infoTable1 flex flex-row items-center justify-start w-full h-[42px] mb-2">
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">卡片有效期限</div>
                                    <div>{objectIsEmpty(info)? "" : stringIsEmpty(info.cardExpirationDate) ? "" : time2String(info.cardExpirationDate, "YYYY-MM-DD")}</div>
                                </li>
                                <li className="w-[310px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">緊急聯絡電話</div>
                                    <div>{objectIsEmpty(info)? "" : info.emergentTel}</div>
                                    {/*<input onChange={handlePhoneChange}*/}
                                    {/*       value={phoneValue}*/}
                                    {/*       readOnly={!isPhoneEdit} // 根據狀態設定是否只讀*/}
                                    {/*/>*/}
                                    {/*{!isPhoneEdit && (*/}
                                    {/*    <button onClick={handlePhoneEdit}*/}
                                    {/*            className="flex flex-row items-center justify-start text-[#2B6CB0]">*/}
                                    {/*        <EditBtn/> 編輯</button>*/}
                                    {/*)}*/}
                                    {/*{isPhoneEdit && (*/}
                                    {/*    <button onClick={handlePhoneCheck}*/}
                                    {/*            className="flex flex-row items-center justify-start text-[#2B6CB0]">*/}
                                    {/*        <EditCheck/></button>*/}
                                    {/*)}*/}
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">卡片註銷註記</div>
                                    <div>正常卡</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">就醫可用次數</div>
                                    <div>{objectIsEmpty(info)? "" : info.availableTimesOfMed}</div>
                                </li>
                                {/*最後一次就醫序號*/}
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4">最後一次就醫序號</div>
                                    <div>{objectIsEmpty(info)? "" : info.clinicSeqNo}</div>
                                </li>
                                <li className="w-[200px] flex flex-row items-center justify-start text-left mr-[60px]">
                                    <div className="mr-4 min-w-[100px]">保險對象身份註記</div>
                                    <div className="">{objectIsEmpty(info)? "" : stringIsEmpty(info.insurerNote) ? "" : t(`ICCard.insurerNote.${info.insurerNote}`)}</div>
                                </li>
                            </ul>
                            <ul className="flex flex-row items-center justify-start w-full h-[48px] mb-2">
                                <div
                                    className="bg-[#F4F4F5] flex flex-row items-center justify-start h-full px-4 mr-4 min-w-[518px]">
                                    <div className="flex flex-row justify-start items-center mr-3">
                                        <p>新生兒出生日期</p>
                                        <input className="w-[125px] h-10 pl-2 border-[2px] border-[#D4D4D8] rounded-[6px] ml-2" type="date" name="birthDate" max="9999-12-31" disabled={!isEditable}
                                            value={isEditable ? 
                                                stringIsEmpty(modifyChildData.childBirthDate) ? "" : convertMinGuoToGregorian(modifyChildData.childBirthDate) : 
                                                (objectIsEmpty(info) || stringIsEmpty(info.childBirthDate)) ? "" : convertMinGuoToGregorian(info.childBirthDate)}
                                            onChange={(e) => handleChildBirthDateOnChange(e)} // 根據狀態設定是否只讀
                                            />
                                    </div>
                                    <div className="flex flex-row justify-start items-center">
                                        <p>新生兒胞胎註記</p>
                                        <input type="text"
                                            name="siblingCount" disabled={!isEditable}
                                            value={isEditable ? modifyChildData.childBornNote : (objectIsEmpty(info)? "" : info.childBornNote)}
                                            onChange={(e) => handleChildBornNoteOnChange(e)} // 根據狀態設定是否只讀
                                            className="w-10 h-10 pl-3 border-[2px] border-[#D4D4D8] rounded-[6px] ml-2"/>
                                        {
                                            !icCardData.isPhysical ?
                                            <></> :
                                            !isEditable ?
                                                <button onClick={handleChildIconOnClick}
                                                        className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                    <EditBtn className="mr-1"/>{t('general.edit')}</button> :
                                                <button onClick={handleChildIconOnClick}
                                                        className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                    <EditCheck className="mr-1"/>儲存</button>
                                        }
                                    </div>
                                </div>
                                <div
                                    className="bg-[#F4F4F5] flex flex-row items-center justify-start h-full px-4 min-w-[1068px]">
                                    <div className="mr-4">病人註記</div>
                                    <div className="items-center flex justify-center">
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2" style={{accentColor: "#38A169"}} id="agreement1" type="checkbox"
                                            checked={objectIsEmpty(info) ? false :info.organDonateFlag} disabled/>
                                        <label className="mr-6" htmlFor="agreement1">同意器官捐贈</label>
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2" style={{accentColor: "#38A169"}} id="agreement2" type="checkbox"
                                            checked={objectIsEmpty(info) ? false :info.hospiceCareFlag} disabled/>
                                        <label className="mr-6" htmlFor="agreement2">同意安寧緩和醫療</label>
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2" style={{accentColor: "#38A169"}} id="agreement3" type="checkbox"
                                            checked={objectIsEmpty(info) ? false :info.notUseCPRFlag} disabled/>
                                        <label className="mr-6" htmlFor="agreement3">同意不施行心肺復甦手術</label>
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2" style={{accentColor: "#38A169"}} id="agreement4" type="checkbox"
                                            checked={objectIsEmpty(info) ? false :info.notUseLifeSustainingMedicalFlag} disabled/>
                                        <label className="mr-6" htmlFor="agreement4">同意不施行維生醫療</label>
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2" style={{accentColor: "#38A169"}} id="agreement5" type="checkbox"
                                            checked={objectIsEmpty(info) ? false :info.advanceMedicalDecisionFlag} disabled/>
                                        <label className="mr-6" htmlFor="agreement5">同意預立醫療決定</label>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <SwitchContainer icCardData={icCardData}/>
            </div>
        </>
     )

     useMemo(() => {
    /*    //讀取IC卡基本資料
        icHisGetHisRegisterBasic({}).then(res => {
            //是否讀取成功
            const apiStatus = res.err === ApiErrorStatusEnum.Success
            //設定資料
            setData(apiStatus ? res.data : {})
        })*/
     }, [])

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if(show) {
            setData(icCardData)
        }
        else {
            setData(null)
            setIsEditable(false)
            setIsPhoneEdit(false)
            setModifyChildData({})
            setData({})
        }
        setShowPopup(show)
    }, [show])

    /**
     * 資料變更時監聽
     */
    useEffect(() => {
        setInfo(data)
    }, [data])

    return showPopup ?
        <BasePopup 
            title={"IC卡檢視"} 
            content={getContent()} 
            width="100%"
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default IcCardViewPopup