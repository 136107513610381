import React, {useEffect, useState, useCallback, useMemo} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import {
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum
} from "edah_utils/dist"
import { queryRegsClinicStatusByTime } from '../../../api/v1/RegsMonth';
import CalendarPopup from '../Popup/CalendarPopup';
import EditEventPopup from '../Popup/EditCalendarPopup';
import useToast from '../../../hooks/useToast';

// 設置本地化
moment.locale('zh-tw');
const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment);

/**
 * 預約時間日曆組件
 * @param {Object} props - 組件屬性
 * @param {Date} choiceDate - 日期
 * @param {Object} giveNumberTimeRangeRecord - 給號時間區間的紀錄
 * @param {Function} props.onSave - 儲存function
 * @param {String} clinicId - 診室id
 * @returns {JSX.Element} 預約日曆組件
 */
const TimeCalendar = ({choiceDate, apn, patientData, clinicId, giveNumberTimeRangeRecord, onSave}) => {
    // 預約資料
    const [events, setEvents] = useState([])
    // 新增掛號狀態
    const [showEventPrompt, setShowEventPrompt] = useState(false);
    // 拖拉選擇給號時間的區間
    const [selectedSlot, setSelectedSlot] = useState(null);
    // 控制編輯事件彈窗的顯示
    const [showEditEventPrompt, setShowEditEventPrompt] = useState(false);
    // 儲存選中的事件
    const [selectedEvent, setSelectedEvent] = useState(null);
    // 診間開診時間
    const [minDateTime, setMinDateTime] = useState("")
    // 診間結束時間
    const [maxDateTime, setMaxDateTime] = useState("")
    // 行事曆日期
    const [calenderDate, setCalenderDate] = useState("")
    // 每人看診時間(分)
    const [slotMin, setSlotMin] = useState(10)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 計算事件的樣式
     * @param {Object} event - 事件對象
     * @param {Date} start - 事件開始時間
     * @param {Date} end - 事件結束時間
     * @param {boolean} isSelected - 事件是否被選中
     * @returns {Object} 事件的樣式對象
     */
    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: '#BEE3F8',
                borderRadius: '5px',
                opacity: event.id === "new"? 1.0 : 0.7,
                color: '#111111',
                border: '0px',
                display: 'block',
            }
        };
    }

    /**
     * 存檔按鈕點擊事件
     */
    const handleSaveOnClick = () => {
        //從全部的event中找出新增的
        const findedEvent = events.find(item => item.id === "new")
        onSave(objectIsEmpty(findedEvent) ? null : {start:findedEvent.start, end:findedEvent.end})
    }

    /**
     * 新增事件到日曆
     * @param {Object} newEvent - 新事件對象
     */
    const addEvent = (newEvent) => {
        //setEvents([...events, { ...newEvent, id: Date.now() }]);
        setEvents([...events, { ...newEvent, id: "new" }])
    };

    /**
     * 處理新事件確認
     * @param eventInfo
     */
    const handleEventConfirm = (eventInfo) => {
        addEvent({
            start: eventInfo.startTime,
            end: eventInfo.endTime,
            title: eventInfo.title,
            editable:true
        });
        setShowEventPrompt(false);
        setSelectedSlot(null);
    };

    /**
     * 處理新事件取消
     */
    const handleEventCancel = () => {
        setShowEventPrompt(false);
        setSelectedSlot(null);
    };

    /**
     * 編輯處理事件刪除
     * @param {string} idToDelete
     */
    const handleEventDelete = (idToDelete) => {
        setEvents((prevEvents) =>
            prevEvents.filter((event) => event.id !== idToDelete)
        );
        setShowEditEventPrompt(false)
        setSelectedSlot(null)
    }

    /**
     * 處理編輯事件確認
     * @param {Object} updatedEvent - 更新後的事件對象
     */
    const handleEditEventConfirm = (updatedEvent) => {
        setEvents(events.map(e => e.id === updatedEvent.id ? updatedEvent : e));
        setShowEditEventPrompt(false);
        setSelectedEvent(null);
    };

    /**
     * 處理編輯事件取消
     */
    const handleEditEventCancel = () => {
        setShowEditEventPrompt(false);
        setSelectedEvent(null);
    };

    const getEventTitle = (patient) => {
        let titleText = ""
        if(!objectIsEmpty(patient)) {
            if(!stringIsEmpty(patient.patientId)) {
                titleText = `${patient.patientId} `
            }
            if(!stringIsEmpty(patient.patientName)) {
                titleText = `${titleText}${patient.patientName} `
            }
            if(!stringIsEmpty(patient.genderName)) {
                titleText = `${titleText}${patient.genderName} `
            }
        }
        return titleText
    }

    // 使用 useCallback 函數，定義 moveEvent 函數，用來處理事件的移動
    const moveEvent = useCallback(({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
        const { allDay } = event
        if (!allDay && droppedOnAllDaySlot) {
            event.allDay = true
        }
        if (allDay && !droppedOnAllDaySlot) {
            event.allDay = false;
        }

        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === event.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== event.id)
            return [...filtered, { ...existing, start, end, allDay: event.allDay }]
        })
    }, [setEvents])
    
    // 使用 useCallback 函數，定義 resizeEvent 函數，用來處理事件的調整大小
    const resizeEvent = useCallback(({ event, start, end }) => {
        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === event.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== event.id)
            return [...filtered, { ...existing, start, end }]
        })
    }, [setEvents])

    useEffect(() => {
        const choiceDateTime = new Date(choiceDate)
        //設定行事曆日期
        setCalenderDate(prev => choiceDateTime)
        let min = new Date(choiceDateTime)
        let max = new Date(choiceDateTime)
        //設定時間區間
        if(apn === 1) {
            min.setHours(8)
            min.setMinutes(30)
            max.setHours(12)
            setMinDateTime(prev => min)
            setMaxDateTime(prev => max)
        }
        else if(apn === 2) {
            min.setHours(13)
            min.setMinutes(30)
            max.setHours(17)
            setMinDateTime(prev => min)
            setMaxDateTime(prev => max)
        }
        else if(apn === 3) {
            min.setHours(18)
            max.setHours(21)
            setMinDateTime(prev => min)
            setMaxDateTime(prev => max)
        }

        let updateEvents = []
        const rangStart = time2String(giveNumberTimeRangeRecord.start)
        const rangeEnd = time2String(giveNumberTimeRangeRecord.end)
        if(!objectIsEmpty(giveNumberTimeRangeRecord) &&
         !stringIsEmpty(rangStart) && !stringIsEmpty(rangeEnd)) {
            let titleText = getEventTitle(patientData)

            updateEvents = [
                { 
                    start: giveNumberTimeRangeRecord.start,
                    end: giveNumberTimeRangeRecord.end,
                    title: titleText,
                    editable:true,
                    id: "new"
                }
            ]
        }

        // 取得診間給號紀錄
        queryRegsClinicStatusByTime({
            clinicId: clinicId
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if(!objectIsEmpty(res.data)) {
                    setSlotMin(res.data.slotMin)
                    if(!arrayIsEmpty(res.data.regList)) {
                        const data = res.data.regList.map((item, index) => {
                            const start = moment(item.startViewDatetime, 'YYYY-MM-DD HH:mm').toDate();
                            const end = moment(item.endViewDatetime, 'YYYY-MM-DD HH:mm').toDate();
                            return {
                                start: start,
                                end: end,
                                title: getEventTitle(item),
                                editable: false,
                                id: index.toString()
                            }
                        })

                        updateEvents = [...updateEvents, ...data]
                    }
                }
                setEvents(prev => updateEvents)
            }
            else {
                showToast({message:res.msg, type:ToastTypeEnum.Error})
            }
        })
    }, [clinicId])

    return (
        <div>
            <style>
                {`
                  .rbc-today { background-color: rgba(250, 250, 250, 1); }
                  .rbc-time-header { display: none; }
                `}
            </style>
            <div className="p-4 pt-0">
                <DragAndDropCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    style={{ height: 600, width: 640, backgroundColor: '#FFFFFF' }}
                    dayLayoutAlgorithm="no-overlap"
                    defaultView="day"
                    date={calenderDate}
                    toolbar={false}
                    step={10}
                    timeslots={1}
                    min={minDateTime} // 設置日曆開始時間
                    max={maxDateTime} // 設置日曆結束時間
                    selectable={true}
                    onSelectSlot={(slotInfo) => {
                        const findedNew = events.find(item => item.id === "new")
                        if(objectIsEmpty(findedNew)) {
                            //僅能新增一個
                            if(slotInfo.action === 'click') {
                                //click方式
                                let end = new Date(slotInfo.start)
                                //指定看診時間
                                end.setMinutes(end.getMinutes() + slotMin)
                                //判斷結束時間是否超過診間結束時間
                                if(end > new Date(maxDateTime)) {
                                    end = new Date(maxDateTime)
                                }
                                setSelectedSlot({...slotInfo, end: end})
                                setShowEventPrompt(true);
                            }
                            else {
                                setSelectedSlot(slotInfo);
                                setShowEventPrompt(true);
                            }
                        }
                    }}
                    onSelectEvent={(event) => {
                        if(event.editable) {
                            //有編輯權力
                            setSelectedEvent(event);
                            setShowEditEventPrompt(true);
                        }
                    }}
                    draggableAccessor={"editable"}
                    onEventDrop={moveEvent}
                    resizableAccessor={"editable"}
                    onEventResize={resizeEvent}
                />
            </div>
            <div className="flex justify-start pl-4 py-3">
                <button 
                    className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]
                                hover:bg-[#3182CE] hover:border-[#3182CE] active:bg-[#2C5282] active:border-[#2C5282]"
                    onClick={handleSaveOnClick}>
                    存檔
                </button>
            </div>

            {/*新增事件*/}
            {showEventPrompt && (
                <CalendarPopup
                    onConfirm={handleEventConfirm}
                    onCancel={handleEventCancel}
                    initialSelectTimeRange={{start: selectedSlot.start, end: selectedSlot.end}}
                    patientData={patientData}
                    apnTimeRange={{start: minDateTime,end: maxDateTime }}
                />
            )}
            {/*編輯事件*/}
            {showEditEventPrompt && selectedEvent && (
                <EditEventPopup
                    event={selectedEvent}
                    onConfirm={handleEditEventConfirm}
                    onCancel={handleEditEventCancel}
                    onDelete={handleEventDelete}
                    apnTimeRange={{start: minDateTime,end: maxDateTime }}
                />
            )}
        </div>
    )
}

export default TimeCalendar