import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty, arrayIsEmpty, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist"
import searchIcon from "../../assets/images/search-interface-symbol.png"
import { BaseInput } from "../../components/Input/BaseInput";
import CvisListItem from "../../components/CriticalValueManagement/CvisListItem";
import { getCurrentFormattedDateTime, fuseObject, cvisNotifyUnitAdapter, inpOpdToText } from "../../components/CriticalValueManagement/utils";
import UsePagination from "../../components/CriticalValueManagement/UsePagination";
import { Pagination } from "../../components/Pagination/Pagination";
import ToastMessage from "../../components/Toasts/ToastMessage";
import { 
    queryCvisNotifyrec,
    queryCvisNotifyobject,
    cancelCvisNotifyrec,
    queryCvisNotifyrecById,
    queryCvisNotifyUnit
} from "../../api/v1/Critical";
import { t } from "i18next";


/**
 * 感染管制通報記錄 => 主頁面
 * @returns {JSX.Element}
 */

const InflectionControlRecords = () => {
    // 通報單位
    const [unit, setUnit] = useState([])
    // 感染管制通報查詢條件
    const [icrQueryData, setIcrQueryData] = useState({
        //通報單位
        cvisNotifyUnit: '01',
        //通報日期起始
        cvisNotifyStartDate: '',
        //通報日期結束
        cvisNotifyEndDate: '',
        //取消狀態
        cvisCancelStuts: '',
        //讀取狀態
        cvisReadStuts: '',
        //回覆狀態
        cvisResponseStatus: '',
        //通報類別
        cvisNotifyType: 'B' //感染管制固定為B
    })
    // 顯示感染管制查詢結果
    const [showQueryResult, setShowQueryResult] = useState(false)
    // 感染管制通報紀錄物件
    const [inflectionControlRecordObject, setInflectionControlRecordObject] = useState([])
    // 過濾後的感染管制通報紀錄物件
    const [filteredItems, setFilteredItems] = useState([])
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // 感染管制通報紀錄索引
    const [inflectionControlRecordListId, setInflectionControlRecordListId] = useState("0")
    // 通報資料
    const [inflectionControlRecordData, setInflectionControlRecordData] = useState({})
    // 感染管制通報對象清單
    const [inflectionControlRecordList, setInflectionControlRecordList] = useState([])
    // 通報對象清單進階搜尋字串
    const [queryListString, setQueryListString] = useState('')
    // 過濾後的感染管制通報對象清單
    const [filteredListItems, setFilteredListItems] = useState([])
    // Toast開關
    const [toastShow, setToastShow] = useState(false)
    // Toast訊息
    const [toastMessage, setToastMessage] = useState({
        message: '',
        type: ToastTypeEnum.Success
    })
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0
    })
    // 通報對象清單 pagination控制變數
    const [paginationListProps, setPaginationListProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0
    })

    /**
     * 顯示Toast
     * @param {message} 訊息
     * @param {type} 類型
     */
    const handleToastOnShow = (message, type) => {
        setToastMessage({
            message: message,
            type: type
        })
        setToastShow(true)
    }

    /**
     * 關閉Toast
     * @returns {void}
     */
    const handleToastOnClose = () => {
        setToastShow(false)
    }

    // 切換基本資料 醫囑清單 檢核結果
    const [activeTab, setActiveTab] = useState('report');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const getTabClass = (tab) => {
        return `h-[42px] px-4 py-2 text-[16px] ${activeTab === tab ? 'border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]' : ''
        }`;
    };

    const [isEditable, setIsEditable] = useState(false);

    const getMaintainInfoClass = () => {
        return `maintainInfo CVRInfo ${isEditable ? '' : 'disabled'} h-[760px]`;
    }

    /**
     * 通報單位值改變事件
     * @param {e}
     * @returns {void}
     */
    const handleCvisNotifyUnitOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisNotifyUnit: e.target.value
        })
    }

    /**
     * 通報日期起始值改變事件
     * @param {e}
     * @returns {void}
     */
    const handleCvisNotifyStartDateOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisNotifyStartDate: e.target.value
        })
    }

    /**
     * 通報日期結束值改變事件
     * @param {e} 
     * @returns {void}
     */
    const handleCvisNotifyEndDateOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisNotifyEndDate: e.target.value
        })
    }

    /**
     * 取消狀態改變事件
     * @param {e} 
     * @returns {void}
     */
    const handleCvisCancelStutsOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisCancelStuts: e.target.value
        })
    }

    /**
     * 讀取狀態值改變事件
     * @param {e} 
     * @returns {void}
     */
    const handleCvisReadStutsOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisReadStuts: e.target.value
        })
    }

    /**
     * 回覆狀態值改變事件
     * @param {e}  
     * @returns {void}
     */
    const handleCvisResponseStatusOnChange = (e) => {
        setIcrQueryData({
            ...icrQueryData,
            cvisResponseStatus: e.target.value
        })
    }

    /**
     * 感染管制通報紀錄查詢按鈕事件
     * @param {number} page 頁碼
     * @param {number} pageSize 每頁筆數
     * @returns {void}
     */
    const handleOnQuery = () => {
        handleQueryInflectionControl(paginationListProps.pageNum, paginationListProps.pageSize)
    }

    /**
     * 感染管制通報紀錄表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }

    /**
     * 感染管制通報紀錄表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryInflectionControl(paginationProps.pageNum, newSize);
    }

    /**
     * 感染管制通報紀錄表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }

    /**
     * 感染管制通報紀錄表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }
    
    /**
     * 通報對象清單表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onNotifyPaginationPageOnChange = (page) => {
        setPaginationListProps({
            ...paginationListProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onNotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        updateInflectionRecordListTable(paginationProps.pageNum, newSize);
    }

    /**
     * 通報對象清單表格上一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格下一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize);
    }


    /**
     * 感染管制通報紀錄查詢按鈕事件
     * @param {number} page 頁碼
     * @param {number} pageSize 每頁筆數
     * @returns {void}
     */
    const handleQueryInflectionControl = (page, pageSize) => {
        console.log(icrQueryData.cvisNotifyStartDate)
        if (icrQueryData.cvisNotifyEndDate && icrQueryData.cvisNotifyStartDate > icrQueryData.cvisNotifyEndDate) {
            handleToastOnShow('結束日期不可早於起始日期', ToastTypeEnum.Error)
        }else if(icrQueryData.cvisNotifyEndDate==="" || icrQueryData.cvisNotifyStartDate===""){
            handleToastOnShow('請輸入日期', ToastTypeEnum.Error)
        }else{
            queryCvisNotifyrec({
                "cvisNotifyUnit": icrQueryData.cvisNotifyUnit,                               //Required 通報單位
                "cvisNotifyStartDate": `${icrQueryData.cvisNotifyStartDate} 00:00:00`,       //Required 通報日期起始
                "cvisNotifyEndDate": `${icrQueryData.cvisNotifyEndDate} 00:00:00`,           //Required 通報日期結束
                "cvisCancelStuts": icrQueryData.cvisCancelStuts,                         //Option   取消狀態(空白：全部 1:未取消 2:已取消)
                "cvisReadStuts": icrQueryData.cvisReadStuts,                                 //Option   讀取狀態(空白：全部 1:未讀 2:已讀)
                "cvisResponseStatus": icrQueryData.cvisResponseStatus,                       //Option   回覆狀態(空白：全部 1:未回覆 2:已回覆)
                "cvisNotifyType": "B",//icrQueryData.cvisNotifyType                                //Required 通報類別(A：危急值 B：感染管制)
                "pageNum": page,
                "pageSize": pageSize
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    const data = res.data.dataList
                    setInflectionControlRecordObject(data)
                    setFilteredItems(data)
                    setInflectionControlRecordListId("0")
                    setShowQueryResult(true)
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: res.data.totalItemSize,
                        totalPageSize: res.data.totalPageSize,
                        currentPageItemSize: res.data.currentPageItemSize,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }else{
                    if(res.err === 1){
                        setInflectionControlRecordObject([])
                        setFilteredItems([])
                        setPaginationProps({
                            ...paginationProps,
                            totalItemSize: 0,
                            totalPageSize: 0,
                            currentPageItemSize: 0,
                            pageNum: page,
                            pageSize: pageSize
                        })
                    }
                    console.log(res)
                }
            })
        }
    }

    /**
     * 更新通報資料
     * @returns {void}
     */
    const updateInflectionRecordTable = () => {
        try{
            // 根據通報流水號取得通報資料
            queryCvisNotifyrecById({
                "cvisNotifyId": inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    // 更新通報資料
                    setInflectionControlRecordData(res.data)
                }else{
                    console.log(res)
                }
            })
        }catch(e){
            console.log(e)
        }

    }

    /**
     * 取消通報按鈕事件
     * @returns {void}
     * */
    const handleCancelCvisNotify = () => {
        cancelCvisNotifyrec({
            cvisNotifyId: inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId,
            cvisCancelUser: localStorage.getItem("username"),
            cvisCancelDatetime: getCurrentFormattedDateTime()
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                updateInflectionRecordTable(inflectionControlRecordListId)
                handleToastOnShow('取消通報成功', ToastTypeEnum.Success)
            }else{
                handleToastOnShow('取消通報失敗', ToastTypeEnum.Error)
                console.log(res)
            }
        })
    }

    /**
     * 進階搜尋字串改變事件
     * @param {event}
     * @returns {void}
     */
    const handleQueryInputOnChange = (event) => {
        setQueryString(event.target.value)
    }

    /**
     * 進階搜尋字串按下Enter事件
     * @param {event}
     * @returns {void}
     */
    const handleTableQuerySearchInputKeyDown = (event) => {
        if(event.key === 'Enter') {
            // 進行過濾
            if(queryString) {
                const result = fuse.search(queryString)
                setFilteredItems(result.map(item => item.item))
            }else{
                setFilteredItems(inflectionControlRecordObject)
            }
        }
    }

    /**
     * 通報對象清單進階搜尋字串改變事件
     * @param {event}
     * @returns {void}
     */
    const handleQueryListInputOnChange = (event) => {
        setQueryListString(event.target.value)
    }

    /**
     * 通報對象清單進階搜尋字串按下Enter事件
     * @param {event}
     * @returns {void}
     */
    const handleTableListQuerySearchInputKeyDown = (event) => {
        if(event.key === 'Enter') {
            // 進行過濾
            if(queryListString) {
                const result = fuseList.search(queryListString)
                setFilteredListItems(result.map(item => item.item))
            }else{
                setFilteredListItems(inflectionControlRecordList)
            }
        }
    }

    /**
     * 更新通報對象清單
     * @param {index} 流水號
     * @returns {void}
     */
    const updateInflectionRecordListTable = (page, pageSize) => {
        queryCvisNotifyobject({
            "cvisNotifyId": inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId,
            "pageNum": page,
            "pageSize": pageSize
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                // 更新通報對象清單
                setInflectionControlRecordList(data)
                setFilteredListItems(data)
                setPaginationListProps({
                    ...paginationListProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.currentPageItemSize,
                    pageNum: page,
                    pageSize: pageSize
                })
            }else{
                if(res.err === 1){
                    setInflectionControlRecordList([])
                    setFilteredListItems([])
                    setPaginationListProps({
                        ...paginationListProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }
                console.log(res)
            }
        })
    }

    /**
     * 當通報紀錄被選取時觸發，取得通報資料、通報對象清單
     * @param {index} 被選取的通報紀錄索引 
     * @returns {void}
     */
    const handleListItemOnFocus = (index) => {
        setInflectionControlRecordListId(index)
        updateInflectionRecordListTable()
    }

    /**
     * 當當前focus的list index更新時，更新通報資料、通報對象清單
     * @returns {void}
     * */
    useEffect(() => {
        if(inflectionControlRecordObject[inflectionControlRecordListId] != undefined ) {
            setInflectionControlRecordData(inflectionControlRecordObject[inflectionControlRecordListId])
            updateInflectionRecordListTable()
        }
    }, [inflectionControlRecordListId])

    // 初始化感染管制通報紀錄fuse
    const fuse = fuseObject(inflectionControlRecordObject)
    // 初始化通報對象清單fuse
    const fuseList = fuseObject(inflectionControlRecordList)

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(()=>{
        if(queryString) {
            const result = fuse.search(queryString)
            setFilteredItems(result.map(item => item.item))
        }else{
            setFilteredItems(inflectionControlRecordObject)
        }
    }, [queryString, inflectionControlRecordObject])

    // 當進階搜尋字串及通報對象清單改變時觸發，更新過濾後的通報對象清單
    useEffect(()=>{
        if(queryListString) {
            const result = fuse.search(queryString)
            setFilteredListItems(result.map(item => item.item))
        }else{
            setFilteredListItems(inflectionControlRecordList)
        }
    }, [queryListString, inflectionControlRecordList])

    // 初始化通報單位
    useEffect(() => {
        queryCvisNotifyUnit().then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                setUnit(res.data)
            }else{
                handleToastOnShow('通報單位查詢失敗', ToastTypeEnum.Error)
                console.log(res)
            }
    })}, [])

    return(
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                {/* 通報單位 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">通報單位:</p>
                    <select 
                        className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={icrQueryData.cvisNotifyUnit}
                        onChange={handleCvisNotifyUnitOnChange}
                    >
                        {!arrayIsEmpty(unit) && unit.map((item, index) => (
                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                        ))}
                    </select>
                </div>
                {/* 通報日期 */}
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    {/* 通報日期起訖 */}
                    <p className="mr-4">通報日期起訖</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={icrQueryData.cvisNotifyStartDate}
                        onChange={handleCvisNotifyStartDateOnChange}
                    />
                    {/* 通報日期結束 */}
                    <p className="m-1">~</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={icrQueryData.cvisNotifyEndDate}
                        onChange={handleCvisNotifyEndDateOnChange}
                    />
                </div>
                {/* 取消狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">取消狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={icrQueryData.cvisCancelStuts}
                        onChange={handleCvisCancelStutsOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未取消</option>
                        <option value="2">已取消</option>
                    </select>
                </div>
                {/* 讀取狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">讀取狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={icrQueryData.cvisReadStuts}
                        onChange={handleCvisReadStutsOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未讀</option>
                        <option value="2">已讀</option>
                    </select>
                </div>
                {/* 回覆狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">回覆狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={icrQueryData.cvisResponseStatus}
                        onChange={handleCvisResponseStatusOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未回覆</option>
                        <option value="2">已回覆</option>
                    </select>
                </div>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleOnQuery}
                    >
                        查詢
                    </button>
                </div>
            </div>
            { showQueryResult && (
                <>
                    {/* 感染管制紀錄通報內容 */}
                    <div className="maintainContainer flex flex-row items-start justify-start">
                        <div className="p-4 bg-white w-[calc(50%-16px)] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4 max-h-[848px] overflow-y-clip">
                            {/* 取消通報按鈕 */}
                            <div className="btnGroup flex flex-row mb-4">
                                <button 
                                    className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] rounded-[6px] "
                                    onClick={handleCancelCvisNotify}
                                >
                                    取消通報
                                </button>
                            </div>
                            {/* 進階搜尋 */}
                            <div className='max-w-[320px] relative'>
                                <input
                                    className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                    type={InputTypeEnum.Text}
                                    value={queryString}
                                    onChange={(event) => handleQueryInputOnChange(event)}
                                    onKeyDown={handleTableQuerySearchInputKeyDown}
                                    placeholder="進階搜尋"
                                />
                                <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                    <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                </div>
                            </div>
                            {/* 感染管制紀錄列表 */}
                            <ul className="tableList ICRTableList mt-4 relative h-[800px]">
                                <li className="title scale-1.1" key={0}>
                                    <div>通報單位</div>
                                    <div>通報項目</div>
                                    <div>等級</div>
                                    <div>通報日期時間</div>
                                    <div>病例號</div>
                                    <div>姓名</div>
                                    <div>讀取</div>
                                    <div>取消</div>
                                    <div>門住</div>
                                    <div>科別</div>
                                    <div>通報對象</div>
                                    <div>通報電話</div>
                                    <div>通報編號</div>
                                </li>
                                {!objectIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId]) && filteredItems.map((item, index) => {
                                    return (
                                        // 列表項目組件
                                        <CvisListItem
                                            type="InflectionControlRecordList"
                                            index={index}
                                            item={item}
                                            handleItemOnClick={handleListItemOnFocus}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="p-4 bg-white w-[50%] border-[1px] border-[#D4D4D8] rounded-[12px] h-[848px]">
                            {/*點擊按鈕切換下方內容*/}
                            <div className="hasHpcSwitch mb-4">
                                {/*點擊tab切換內容 - 按鈕*/}
                                <div className="tabGroup w-full flex flex-row items-center justify-between mb-4 pr-4">
                                    <div className="flex flex-row items-center justify-start">
                                        <button onClick={() => handleTabClick('report')}
                                                className={getTabClass('report')}>
                                            通報資料
                                        </button>
                                        <button onClick={() => handleTabClick('list')}
                                                className={getTabClass('list')}>
                                            通報對象清單
                                        </button>
                                    </div>
                                </div>

                                {/*點擊tab切換內容 - 內容*/}
                                {/* 通報資料 */}
                                {activeTab === 'report' && (
                                    <div>
                                        {!objectIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId]) && (
                                        <ul className={getMaintainInfoClass()}>
                                            <li key={0}>
                                                <div>通報單位</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUnit) ? cvisNotifyUnitAdapter(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUnit) : "無資料"}</div>
                                                <div>通報編號</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId}</div>
                                            </li>
                                            <li key={1}>
                                                <div>通報項目</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyItem}</div>
                                                <div>等級</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyClass}</div>
                                            </li>
                                            <li key={2}>
                                                <div>病例號</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].patientId}</div>
                                                <div>姓名</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].patientName}</div>
                                            </li>
                                            <li key={3}>
                                                <div>門住</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].inpOpdName}</div>
                                                <div>科別</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].divisionName) ? inflectionControlRecordObject[inflectionControlRecordListId].divisionName : "無資料"}</div>
                                            </li>
                                            <li key={4}>
                                                <div>通報日期時間</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyDatetime}</div>
                                                <div>通報人員</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUser) ? `${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUser} : ${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUserName}` : "無資料" }</div>
                                            </li>
                                            <li key={5}>
                                                <div>通報對象</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObjectName) ? `${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObject} : ${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObjectName}` : "無資料"}</div>
                                                <div>通報電話</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].userMobile) ? inflectionControlRecordObject[inflectionControlRecordListId].userMobile : "無資料"}</div>
                                            </li>
                                            <li key={6}>
                                                <div>通報內容</div>
                                                <div className="!w-[650px] mr-2.5">{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyContent}</div>
                                            </li>
                                            <li key={7}>
                                                <div></div>
                                                <div className="flex flex-row items-center justify-start">
                                                    <input
                                                        className="bg-green-400 checked:bg-green-600 text-green-600 !w-5 !h-5 mr-3"
                                                        type="checkbox"
                                                        id="r4" 
                                                        style={{accentColor: "#38A169"}}
                                                        checked={!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelFlag === true ? true : false}
                                                    />
                                                    <label className="cursor-pointer" htmlFor="r4">取消通報</label>
                                                </div>
                                            </li>
                                            <li key={8}>
                                                <div>取消通報日期時間</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelDatetime}</div>
                                                <div>取消通報人員</div>
                                                <div>{!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelUser}</div>
                                            </li>
                                        </ul>
                                        )}
                                    </div>
                                )}
                                {/* 通報對象清單 */}
                                {activeTab === 'list' && (
                                    <div>
                                        <div className='max-w-[320px] relative'>
                                            <input
                                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                                type={InputTypeEnum.Text}
                                                value={queryListString}
                                                onChange={(event) => handleQueryListInputOnChange(event)}
                                                onKeyDown={handleTableListQuerySearchInputKeyDown}
                                                placeholder="進階搜尋"
                                            />
                                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                            </div>
                                        </div>
                                        <ul className="tableList CVRRightOneList mt-4 relative max-h-[800px]">
                                            <li className="title" key={0}>
                                                <div>類別名稱</div>
                                                <div>通報對象</div>
                                                <div>電話</div>
                                                <div>通報時間</div>
                                                <div>讀取時間</div>
                                            </li>
                                            { filteredListItems.map((item, index) => {
                                                return (
                                                    // 列表項目組件
                                                    <CvisListItem
                                                        type="CvisNotifyObjectList"
                                                        index={index}
                                                        item={item}
                                                    />
                                                )
                                            })}
                                        </ul>
                                        <Pagination
                                            totalPageSize={paginationListProps.totalPageSize}
                                            pageSize={paginationListProps.pageSize}
                                            totalSize={paginationListProps.totalItemSize}
                                            currentPage={paginationListProps.pageNum}
                                            onPageOnChange={onNotifyPaginationPageOnChange}
                                            onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                                            onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                                            onNextPageOnClick={onNotifyPaginationNextOnClick}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        currentPage={paginationProps.pageNum}
                        onPageOnChange={onPaginationPageOnChange}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}
                    />
                </>
            )}
            {/* Toast */}
            {toastShow && (
                <ToastMessage
                    message={toastMessage.message}
                    type={toastMessage.type}
                    handleToastOnClose={handleToastOnClose}
                />
            
            )}
        </div>
    )
}
export default InflectionControlRecords