import React, { useEffect, useState } from 'react'
import { APRV_STATUS_LIST } from '../../../constants/referralSystem/dischargeSummary'
import { t } from 'i18next'
import { validDateFormat, InputTypeEnum } from 'edah_utils'
import { BaseInput } from '../../Input/BaseInput'

const DischargeSummarySearchBar = ({
    handleQuery,
    isApplyPage,
    searchParamsOnChange,
}) => {
    // 起始日期
    const [startDate, setStartDate] = useState('')

    // 結束日期
    const [endDate, setEndDate] = useState('')

    // 出院病摘狀態: 預設[申請中]
    const [aprvStatus, setAprvStatus] = useState(isApplyPage ?  '' : APRV_STATUS_LIST[0].value)

    // 身分證號
    const [idNo, setIdNo] = useState('')

    // 病歷號
    const [patientId, setPatientId] = useState('')

    // 起始日期輸入框檢核狀態
    const [startDateInputState, setStartDateInputState] = useState(null)

    // 結束日期輸入框檢核狀態
    const [endDateInputState, setEndDateInputState] = useState(null)

    const updateStartDate = (e) => {
        setStartDate(e.target.value)
    }
    const updateEndDate = (e) => {
        setEndDate(e.target.value)
    }
    const updateAprvStatus = (e) => {
        setAprvStatus(e.target.value)
    }
    const updateIdNo = (e) => {
        setIdNo(e.target.value)
    }
    const updatePatientId = (e) => {
        setPatientId(e.target.value)
    }

    // 查詢
    const handleQuerOnClick = () => {
        const startDateIsValid = validDateFormat(startDate)
        const endDateIsValid = validDateFormat(endDate)
        setStartDateInputState(startDateIsValid)
        setEndDateInputState(endDateIsValid)

        if (!startDateIsValid || !endDateIsValid) {
            return
        }
        handleQuery()
    }

    useEffect(() => {
        searchParamsOnChange({
            startDate,
            endDate,
            patientId,
            idNo,
            aprvStatus,
        })
    }, [startDate, endDate, patientId, idNo, aprvStatus])

    return (
        <div className="flex">
            <div className="date flex items-center justify-start mb-4 mr-4">
                <div className="mr-4">
                    {isApplyPage ? '出院日期' : '就醫日期'}
                    <span className="text-danger ">*</span>:
                </div>
                <BaseInput
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    type={InputTypeEnum.Date}
                    value={startDate}
                    onChange={(e) => updateStartDate(e)}
                    inputState={startDateInputState}
                    errorMsg="必填"
                />
                <span className="m-1">~</span>
                <BaseInput
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    value={endDate}
                    onChange={(e) => updateEndDate(e)}
                    inputState={endDateInputState}
                    type={InputTypeEnum.Date}
                    errorMsg="必填"
                />
            </div>
            <div className="flex items-center justify-start mb-4 mr-4">
                <div className="mr-4">選項:</div>
                <select
                    className="w-[120px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                    value={aprvStatus}
                    onChange={(e) => updateAprvStatus(e)}
                >
                    <option value=""></option>
                    {APRV_STATUS_LIST.map((item) => {
                        return (
                            <option
                                value={item.value}
                                key={item.value}
                            >
                                {item.name}
                            </option>
                        )
                    })}
                </select>
            </div>
            <div className="flex items-center justify-start mb-4 mr-4">
                <div className="mr-4">{t('general.idNumber')}：</div>
                <input
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    type="text"
                    value={idNo}
                    onChange={(e) => updateIdNo(e)}
                />
            </div>

            <div className="flex items-center justify-start mb-4 mr-4">
                <div className="mr-4">{t('general.patientNo')}：</div>
                <input
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    type="text"
                    value={patientId}
                    onChange={(e) => updatePatientId(e)}
                />
            </div>
            <button
                className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                onClick={() =>
                    handleQuerOnClick({
                        startDate,
                        endDate,
                        aprvStatus,
                        idNo,
                        patientId,
                    })
                }
            >
                {t('general.query')}
            </button>
        </div>
    )
}

export default DischargeSummarySearchBar
