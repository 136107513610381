import React, {useEffect, useState, useRef} from "react"
import searchIcon from "../../../assets/images/search-interface-symbol.png"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    PopupModeEnum,
    RegsMedicalTypeEnum, RegsWeekModeEnum,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum, TimeslotEnum,
    ToastTypeEnum,
    stringIsEmpty,
    arrayIsEmpty,
    objectIsEmpty
} from "edah_utils/dist"
import {regsAddMonthClinic, regsEditMonthClinic} from "../../../api/v1/RegsMonth"
import { sloganQueryContentForWeekOrClinic } from "../../../api/v1/Slogn"
import useToast from "../../../hooks/useToast"
import useOutsideClick from "../../../hooks/useOutsideClick"

/**
 * @param mode {Number} 新增或編輯
 * @param filterMode {Number} 篩選模式
 * @param baseData {Object} 基本資料
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param handlePopupAppointmentOnSubmit {Function} 彈出視窗預約提交時
 */
const AppointmentPopup = ({
                              mode,
                              filterMode,
                              baseData,
                              data,
                              closePopup,
                              getMonthData,
                          }) => {
    //預設設定
    const baseSettings = {
        // 診間ID
        clinicId: data.clinicId ? data.clinicId : null,
        // 預約最大看診人次
        maxPatient: data.maxPatient ? data.maxPatient.toString() : '100',
        // 現場最大看診人次
        onsiteLimit: data.onsiteLimit ? data.onsiteLimit.toString() : '100',
        // 看診時間(人/分)
        consultationTime: data.consultationTime ? data.consultationTime.toString() : '5',
        // 預約方式
        reservationMethod: data.reservationMethod ? data.reservationMethod : RegsWeekReservationMethodEnum.ClinicNumber,
        // 調病歷
        canGetMedicalRecords: data.canGetMedicalRecords ? data.canGetMedicalRecords : false,
        // 給號方式
        numberGivingMethod: data.numberGivingMethod ? data.numberGivingMethod : 9,
        // 收費別
        chargeType: data.chargeType ? data.chargeType : '',
        // 預約限制
        reservationLimit: data.reservationLimit ? data.reservationLimit : RegsWeekReservationLimitEnum.Normal,
        // 診別
        medicalType: data.medicalType ? data.medicalType : "O",
        // 開放網掛/App掛號
        webOrAppBooking: data.webOrAppBooking ? data.webOrAppBooking : false,
        // 開放網掛/App取消看診進度
        webOrAppCancel: data.webOrAppCancel ? data.webOrAppCancel : false,
        //網路預約期限
        onlineBookingDeadline: data.onlineBookingDeadline ? data.onlineBookingDeadline : 30,
        //看診項目
        mediItem: data.mediItem ? data.mediItem : "empty",
        //診間地點
        clinicLocation: data.clinicLocation ? data.clinicLocation : "",
        //注意事項
        notes: data.notes ? data.notes : "",
        //診間標語
        clinicSlogan: data.clinicSlogan ? data.clinicSlogan : "",
        //醫生
        doctor: data.doctor ? data.doctor : null,
        //科別
        department: data.department ? data.department : null,
        //診室名稱
        clinicName: data.clinicName ? data.clinicName : null,
        //診室號
        clinicNo: data.clinicNo ? data.clinicNo : "",
        //時段
        timeslot: data.timeslot ? data.timeslot : null,
        //星期幾
        week: data.week !== null ? data.week : null,
        //當前日期
        date: data.date ? data.date : null,
        //代理醫師
        locumDoctor: data.locumDoctor ? data.locumDoctor : '',
        //停診
        stopClinicFlag: data.stopClinicFlag ? data.stopClinicFlag : false,
        //停診原因
        stopClinicReason: data.stopClinicReason ? data.stopClinicReason : null,
        //停代診公告
        stopClinicBulletinFlag: data.stopClinicBulletinFlag ? data.stopClinicBulletinFlag : false,
        //假日展班
        holidayexclFlag: data.holidayexclFlag ? data.holidayexclFlag : false,
        //看診項目
        //consultationItems: data.consultationItems ? data.consultationItems : "empty",

        lockVersion: data.lockVersion
    }
    //當前設定
    const [record, setRecord] = useState(baseSettings)
    //是否顯示醫生下拉選單
    const [showDoctorDropdown, setShowDoctorDropdown] = useState(false)
    //是否顯示科別下拉選單
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false)
    //是否顯示收費別下拉選單
    const [showChargeTypeDropdown, setShowChargeTypeDropdown] = useState(false)
    //是否顯示代班醫生下拉選單
    const [showLocumDoctorDropDown, setShowLocumDoctorDropDown] = useState(false)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 代理醫師下拉選項
    const [locumDoctorOptionList, setLocumDoctorOptionList] = useState(null)
    //是否可以修改醫生欄位
    const [canModifyDoctor, setCanModifyDoctor] = useState(true)
    //是否可以修改科別欄位
    const [canModifyDepartment, setCanModifyDepartment] = useState(true)
    // 顯示Toast
    const showToast = useToast()
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    //ref 用於指向代班醫師下拉菜單元素
    const dropdownLocumDoctorRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropdown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropdown(false),
    });
    useOutsideClick({
        ref: dropdownLocumDoctorRef,
        handler: () => setShowLocumDoctorDropDown(false),
    });

    /**
     * 醫生搜尋文字欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (e) => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //設定醫生值
            setRecord({...record, doctor: e.target.value})
        }
    }

    /**
     * 醫師搜尋文字取得焦點時
     */
    const handleDoctorOptionOnFocus = () => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //根據模式來決定是否顯示下拉選單
            setDoctorOptionList(getFilterDoctorOptions())
            setShowDoctorDropdown(true)
        }
    }

    /**
     * 醫生欄位失去焦點時
     */
    const handleDoctorOnBlur = () => {
        //可以修改醫生時
        if (canModifyDoctor) {
            setTimeout(() => setShowDoctorDropdown(false), 150)
        }
    }

    /**
     * 醫生欄位被點擊時
     * @param {Object|null} option
     * @return {void}
     */
    const handleDoctorOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            let sloganText = ''
            if(!stringIsEmpty(record.department) && record.department.split(' ')[0] !== null &&
                !stringIsEmpty(option.userNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(option.userNo, record.department.split(' ')[0])
            }
            //設定醫生
            setRecord({...record, doctor: `${option.userNo} ${option.userName}`, clinicSlogan: sloganText})
            //關閉醫生下拉選單
            setShowDoctorDropdown(false)
        }
    }

    /**
     * 根據搜尋文字過濾醫生選項
     * @return {Array}
     */
    const getFilterDoctorOptions = () => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //取得搜尋文字
            const searchText = record.doctor
            // 搜尋文字為空
            if (searchText === '' || searchText === null) {
                return baseData.doctorList
            } else { //搜尋文字不為空
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //分割搜尋文字
                const splitSearchTextArray = searchText.split(' ')

                return baseData.doctorList.filter(doctor => hasSpace ?
                    (doctor.userNo.includes(splitSearchTextArray[0]) || doctor.userName.includes(splitSearchTextArray[1])) :
                    (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
                )
            }
        } else { //不可以修改醫生
            return []
        }
    }

    /**
     * 根據搜尋文字過濾代診醫生選項
     * @return {Array}
     */
    const getFilterLocumDoctorOptions = () => {
        const searchText = record.locumDoctor
        // 搜尋文字為空
        if (searchText === '' || searchText === null) {
            return baseData.doctorList
        }
        else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.doctorList.filter(doctor => hasSpace ?
                (doctor.userNo.includes(splitSearchTextArray[0]) && doctor.userName.includes(splitSearchTextArray[1])) :
                (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
            )
        }
    }

    /**
     * 科別搜尋文字改變時
     * @param e {Event}
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (e) => {
        //可以修改科別時
        if (canModifyDepartment) {
            //設定科別值
            setRecord({...record, department: e.target.value})
        }
    }

    /**
     * 科別欄位取得焦點時
     */
    const handleDepartmentOnFocus = () => {
        //可以修改科別時
        if (canModifyDepartment) {
            setDepartmentOptionList(getFilterDepartmentOptions())
            setShowDepartmentDropdown(true)
        }
    }

    /**
     * 科別欄位失去焦點時
     */
    const handleDepartmentOnBlur = () => {
        //可以修改科別時
        if (canModifyDepartment) {
            setTimeout(() => setShowDepartmentDropdown(false), 150)
        }
    }

    /**
     * 科別欄位被點擊時
     * @param {Object|null} option
     */
    const handleDepartmentOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            const departmentText = `${option.divNo} ${option.divName}`
            let sloganText = ''
            if(!stringIsEmpty(record.doctor) && record.doctor.split(' ')[0] !== null &&
                !stringIsEmpty(option.divNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(record.doctor.split(' ')[0], option.divNo)
            }
            //設定科別
            setRecord({...record, department: departmentText, clinicSlogan: sloganText})
            //關閉科別下拉選單
            setShowDepartmentDropdown(false)
        }
    }

    /**
     * 根據搜尋文字過濾科別選項
     */
    const getFilterDepartmentOptions = () => {
        //取得搜尋文字
        const searchText = record.department

        //搜尋文字為空
        if (searchText === '' || searchText === null) {
            return baseData.divisionList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.divisionList.filter(department => hasSpace ?
                (department.divNo.includes(splitSearchTextArray[0]) || department.divName.includes(splitSearchTextArray[1])) :
                (department.divNo.includes(searchText) || department.divName.includes(searchText))
            )
        }
    }

    /**
     * 最大看診人次欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleMaxPatientOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定最大看診人次
            setRecord({...record, maxPatient: value.toString()})
        }
        else {
            setRecord({...record, maxPatient: ''})
        }
    }

    /**
     * 現場最大看診人次欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleOnsiteNumberOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定現場最大看診人次
            setRecord({...record, onsiteLimit: value.toString()})
        }
        else {
            setRecord({...record, onsiteLimit: ''})
        }
    }

    /**
     * 看診時間(分/人)欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleConsultationTimeOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {

            setRecord({...record, consultationTime: value.toString()})
        }
        else {
            setRecord({...record, consultationTime: ''})
        }
    }

    /**
     * 給號方式欄位改變時
     * @param e {Event}
     */
    const handleNumberGivingMethod = (e) => setRecord({...record, numberGivingMethod: e.target.value})

    /**
     * 預約方式欄位改變時
     * @param v {Number}
     * @return {void}
     */
    const handleReservationMethodOnChange = (v) => setRecord({...record, reservationMethod: v})

    /**
     * 預約限制欄位改變時
     * @param v {Number}
     * @return {void}
     */
    const handleReservationLimitOnChange = (v) => setRecord({...record, reservationLimit: v})

    /**
     * 診室名稱欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicNameOnChange = (e) => setRecord({...record, clinicName: e.target.value})

    /**
     * 診室地點欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicLocationOnChange = (e) => setRecord({...record, clinicLocation: e.target.value})

    /**
     * 診別欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleMedicalTypeOnChange = (e) => setRecord({...record, medicalType: e.target.value})

    /**
     * 調病例欄位改變時
     * @return {void}
     */
    const handleCanGetMedicalRecordsOnChange = () => setRecord({
        ...record,
        canGetMedicalRecords: !record.canGetMedicalRecords
    })

    /**
     * 收費別欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleChargeTypeSearchTextOnChange = (e) => setRecord({...record, chargeType: e.target.value})

    /**
     * 收費別欄位取得焦點時
     * @return {void}
     */
    const handleChargeTypeSearchTextOnFocus = () => setShowChargeTypeDropdown(true)

    /**
     * 收費別欄位失去焦點時
     * @return {void}
     */
    const handleChargeTypeSearchTextOnBlur = () => setTimeout(() => setShowChargeTypeDropdown(false), 150)

    /**
     * 根據搜尋文字過濾收費別選項
     * @return {Array}
     */
    const getFilterChargeTypeOptions = () => {
        //取得搜尋文字
        const searchText = record.chargeType

        //搜尋文字為空
        if (searchText === '') {
            return baseData.cashTypeList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.cashTypeList.filter(cash => hasSpace ?
                (cash.cashType.includes(splitSearchTextArray[0]) || cash.cashName.includes(splitSearchTextArray[1])) :
                (cash.cashType.includes(searchText) || cash.cashName.includes(searchText))
            )
        }
    }

    /**
     * 收費別下拉選單被點擊時
     * @param {Object|null} option
     */
    const handleChargeTypeOptionOnClick = (option) => {
        // 選項不為空
        if (option !== null) {
            //設定收費別
            setRecord({...record, chargeType: `${option.cashType} ${option.cashName}`})
            //關閉收費別下拉選單
            setShowChargeTypeDropdown(false)
        }
    }

    /**
     * 網掛/APP欄位改變時
     * @return {void}
     */
    const handleWebOrAppBookingOnChange = () => setRecord({...record, webOrAppBooking: !record.webOrAppBooking})

    /**
     * 網掛/APP取消看診進度欄位改變時
     * @return {void}
     */
    const handleWebOrAppCancelOnChange = () => setRecord({...record, webOrAppCancel: !record.webOrAppCancel})

    /**
     * 網掛預約期限欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleOnlineBookingDeadlineOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {

            setRecord({...record, onlineBookingDeadline: value})
        }
    }

    /**
     * 注意事項欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleNotesOnChange = (e) => setRecord({...record, notes: e.target.value})

    /**
     * 診間標語欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicSloganOnChange = (e) => setRecord({...record, clinicSlogan: e.target.value})

    /**
     * 看診項目欄位改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleConsultationItemsOnChange = (e) => setRecord({...record, mediItem: e.target.value})

    /**
     * 取得標題的內容
     * @return {String | JSX.Element}
     */
    const getHeaderContent = () => {
        //取得時段
        const timeslot = record.timeslot
        //取得時段字串
        const timeslotStr = timeslot === TimeslotEnum.Morning ? t('general.dateTime.timeslot.full.morning') : timeslot === TimeslotEnum.Afternoon ? t('general.dateTime.timeslot.full.afternoon') : t('general.dateTime.timeslot.full.night')
        //取得星期幾
        let week = record.week
        //取得日期
        const date = record.date
        //新增模式
        if (mode === PopupModeEnum.Add) {
            return `${date} ${t(`general.dateTime.weekRange.${week}`)}，${timeslotStr}`
        } else if (mode === PopupModeEnum.Modify) { //修改模式
            //科別
            const splitDepartment = record.department.split(' ')
            const department = !arrayIsEmpty(splitDepartment) && splitDepartment.length === 2 ? splitDepartment[1] : record.department
            //醫生
            const splitDoctor = record.doctor.split(' ')
            const doctor = !arrayIsEmpty(splitDoctor) && splitDoctor.length === 2 ? splitDoctor[1] : record.doctor
            //診室名稱
            const clinicName = record.clinicName

            if (week === 0) week = 7 //編輯狀態下的week 0代表是星期天........

            return (
                <>
                    {`${date} ${t(`general.dateTime.weekRange.${week}`)}，${timeslotStr}，${department}，`}
                    <span>{`醫師: ${doctor}，診室名稱: ${clinicName}`}</span>
                </>
            )
        }
    }

    /**
     * 儲存或編輯按鈕被點擊時
     */
    const handleSaveOrEditOnClick = () => {
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add


        // 新增模式
        if (isAdd) {
            if (record.doctor === null || record.doctor.split(' ').length !== 2) {
                showToast({type: ToastTypeEnum.Error, message: '醫師欄位有誤'})
                return
            }
            if (record.department === null || record.department.split(' ').length !== 2) {
                showToast({type: ToastTypeEnum.Error, message: '科別欄位有誤'})
                return
            }
            if (record.chargeType === null || record.chargeType.split(' ').length !== 2) {
                showToast({type: ToastTypeEnum.Error, message: '收費別欄位有誤'})
                return
            }
            if (stringIsEmpty(record.clinicName)) {
                showToast({type: ToastTypeEnum.Error, message: '診室名稱有誤'})
                return
            }
            if(stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '預約最大看診人次有誤'})
                return
            }
            if(stringIsEmpty(record.onsiteLimit) || parseInt(record.onsiteLimit) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '現場最大看診人次有誤'})
                return
            }
            if(stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '看診時間有誤'})
                return
            }
            //新增月班單一診間班表
            regsAddMonthClinic({
                //就醫日期
                "encounterDate": `${record.date.replaceAll('/', '-')} 00:00:00`,
                //時段:1.早,2.午,3.晚
                "apn": record.timeslot,
                //醫師代號
                "doctorNo": record.doctor.split(' ')[0],
                //診室名稱
                "clinicName": record.clinicName,
                //診間(診室號)
                //"clinicNo": "0221",
                //科別代號
                "divNo": record.department.split(' ')[0],
                //預約最大看診人次
                "limit": parseInt(record.maxPatient),
                //現場最大看診人次
                "onsiteLimit": parseInt(record.onsiteLimit),
                //看診時間(分/人)
                "slotMin": parseInt(record.consultationTime),
                //給號方式:1.診號,2.時間
                "giveNumType": `${record.reservationMethod}`,
                //給號代碼
                "noType": record.numberGivingMethod,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                "sentChartFlag": record.canGetMedicalRecords,
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                "reserveLimitType": record.reservationLimit,
                //診間類別  O:門診,T:外檢,P,預防保健
                "clinicType": record.medicalType,
                ////診間收費別
                "cashType": record.chargeType.split(' ')[0],
                ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                "webapptFlag": record.webOrAppBooking,
                //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                "showProcessFlag": record.webOrAppCancel,
                //網掛預約期限(天)
                "webapptDay": record.onlineBookingDeadline,
                //週班注意事項
                "weekNotice": record.notes,
                //假日展班, 0.FALSE , 1.TRUE
                "holidayexclFlag": record.holidayexclFlag,
                //代班醫師
                "locumDoctor": null,
                //停診
                "stopClinicFlag": false,
                //停診原因
                "stopClinicReason": null,
                //停代診公告
                "stopClinicBulletinFlag": false,
                //診間標語
                "clinicSlogan": record.clinicSlogan,
                "nurse1": null,
                "nurse2": null,
                "nurse3": null,
                "nurse4": null,
                "nurse5": null,
                "nurse6": null,
                "nurse7": null,
                //看診項目
                "clinicMarkNo": record.mediItem === "empty" ? null:record.mediItem,
                //診室地點
                "clinicLocation": record.clinicLocation,
                //樂觀鎖
                "lockVersion": 0
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    getMonthData()
                } else {
                    const msg = `新增失敗: ${res.msg}`
                    showToast({type: ToastTypeEnum.Error, message: msg})
                }
            })
        } else { //修改模式
            if (record.chargeType === null || record.chargeType.split(' ').length !== 2) {
                showToast({type: ToastTypeEnum.Error, message: '收費別欄位有誤'})
                return
            }
            if (stringIsEmpty(record.clinicName)) {
                showToast({type: ToastTypeEnum.Error, message: '診室名稱有誤'})
                return
            }
            if(stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '預約最大看診人次有誤'})
                return
            }
            if(stringIsEmpty(record.onsiteLimit) || parseInt(record.onsiteLimit) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '現場最大看診人次有誤'})
                return
            }
            if(stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                showToast({type: ToastTypeEnum.Error, message: '看診時間有誤'})
                return
            }
            regsEditMonthClinic({
                "clinicId": record.clinicId,
                //就醫日期
                "encounterDate": `${record.date.replaceAll('/', '-')} 00:00:00`,
                //時段:1.早,2.午,3.晚
                "apn": record.timeslot,
                //醫師代號
                "doctorNo": record.doctor.split(' ')[0],
                //診室名稱
                "clinicName": record.clinicName,
                //診間(診室號)
                "clinicNo": record.clinicNo,
                //科別代號
                "divNo": record.department.split(' ')[0],
                //預約最大看診人次
                "limit": parseInt(record.maxPatient),
                //現場最大看診人次
                "onsiteLimit": parseInt(record.onsiteLimit),
                //看診時間(分/人)
                "slotMin": parseInt(record.consultationTime),
                //給號方式:1.診號,2.時間
                "giveNumType": `${record.reservationMethod}`,
                //給號代碼
                "noType": record.numberGivingMethod,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                "sentChartFlag": record.canGetMedicalRecords,
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                "reserveLimitType": record.reservationLimit,
                //診間類別  O:門診,T:外檢,P,預防保健
                "clinicType": record.medicalType,
                ////診間收費別
                "cashType": record.chargeType.split(' ')[0],
                ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                "webapptFlag": record.webOrAppBooking,
                //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                "showProcessFlag": record.webOrAppCancel,
                //網掛預約期限(天)
                "webapptDay": record.onlineBookingDeadline,
                //週班注意事項
                "weekNotice": record.notes,
                //看診項目
                "clinicMarkNo": record.mediItem,
                //假日展班, 0.FALSE , 1.TRUE
                "holidayexclFlag": record.holidayexclFlag,
                //代班醫師
                "locumDoctor": !stringIsEmpty(record.locumDoctor) ? record.locumDoctor.split(' ')[0] : '',
                //停診
                "stopClinicFlag": record.stopClinicFlag,
                //停診原因
                "stopClinicReason": (record.stopClinicFlag !== null && record.stopClinicFlag === true) ? record.stopClinicReason : null,
                //停代診公告
                "stopClinicBulletinFlag": record.stopClinicBulletinFlag,
                //診間標語
                "clinicSlogan": record.clinicSlogan,
                "nurse1": null,
                "nurse2": null,
                "nurse3": null,
                "nurse4": null,
                "nurse5": null,
                "nurse6": null,
                "nurse7": null,
                //診室地點
                "clinicLocation": record.clinicLocation,
                // 建立時間
                "createDatetime": record.createDatetime,
                // 建立者
                "createUser": record.createUser,
                //修改時間
                "modifyDatetime": record.modifyDatetime,
                //修改者
                "modifyUser": record.modifyUser,
                //樂觀鎖
                "lockVersion": record.lockVersion
            }).then(res => {
                //更新成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    getMonthData()
                } else {
                    const msg = `儲存失敗: ${res.msg}`
                    showToast({type: ToastTypeEnum.Error, message: msg})
                }
            })
        }
    }

    /**
     * 代理醫生值改變時
     * @param e {Event}
     * @return {void}
     */
    const handleLocumDoctorOnChange = (e) => {
        let haveLocumDoctor = !stringIsEmpty(e.target.value)
        //代班醫師要連動停代診公告
        setRecord({
            ...record,
            locumDoctor: e.target.value,
            stopClinicBulletinFlag: haveLocumDoctor
        })
    }

    /**
     * 代理醫生方框取得焦點時
     * @return {void}
     */
    const handleInputLocumDoctorOnFocus = () => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions())
        setShowLocumDoctorDropDown(true)
    }

    /**
     * 選取代理醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleLocumDoctorOptionOnClick = (item) => {
        if(objectIsEmpty(item)) return
        setRecord({
            ...record,
            locumDoctor: `${item.userNo} ${item.userName}`,
            stopClinicBulletinFlag: true
        })

        setShowLocumDoctorDropDown(false)
    }

    /**
     * 取得代理醫生選項
     * @return {Array}
     */
    const getLocumDoctorOptions = () => {
        // 取得當前醫生
        const currentDoctor = record.doctor.split(' ')
        // 取得醫生代號
        const id = currentDoctor[0]
        // 取得醫生名稱
        const name = currentDoctor[1]

        return baseData.doctorList.filter(doctor => doctor.userNo !== id && doctor.userName !== name)
    }

    /**
     * 停診原因勾選變更
     * @return {void}
     */
    const handleStopClinicFlagOnChange = () => {

        let checked = !record.stopClinicFlag
        let stopClinicReason = record.stopClinicReason
        if (checked && stringIsEmpty(stopClinicReason)) {
            //勾選停診原因，但停診原因下拉值為空時
            stopClinicReason = baseData.stopClinicReasonList[0].justnameNo
        }
        else if(!checked) {
            //取消勾選停診原因時，清空停診原因選擇
            stopClinicReason = ""
        }

        //停診原因要連動停代診公告
        setRecord({
            ...record,
            stopClinicFlag: checked,
            stopClinicBulletinFlag: checked,
            stopClinicReason: stopClinicReason
        })
    }

    /**
     * 停診原因值改變時
     * @param {Event} e
     * @returns
     */
    const handleStopClinicReasonOnChange = (e) => setRecord({...record, stopClinicReason: e.target.value})

    /**
     * 停代診公告欄位改變時
     * @return {void}
     */
    const handleStopClinicBulletinFlagOnChange = () => setRecord({
        ...record,
        stopClinicBulletinFlag: !record.stopClinicBulletinFlag
    })

    /**
     * 取得診間標語
     * @param {String} doctoorNo 
     * @param {String} divNo 
     * @returns 
     */
    const getSloganContentForWeekOrClinic = async (doctoorNo, divNo) => {
        let sloganText = ''
        await sloganQueryContentForWeekOrClinic({
            //醫師代碼
            doctorNo: doctoorNo,
            //科別代碼
            divNo: divNo,
            //時段
            apn: record.timeslot
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //成功
                sloganText = stringIsEmpty(res.data) ? "" : res.data
            }
            else {
                //失敗時
                showToast({message:res.msg, type:ToastTypeEnum.Error})
            }
        })
        return sloganText
    }

    useEffect(() => {
        // 為新增模式
        if (mode === PopupModeEnum.Add) {
            //為醫生模式且有待醫生資料
            if (filterMode === RegsWeekModeEnum.Doctor && record.doctor) {
                //分割醫生資料
                const splitArray = record.doctor.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.doctorList.findIndex(doctor => doctor.userNo === splitArray[0] && doctor.userName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDoctor(true)
                }
            } else if (filterMode === RegsWeekModeEnum.Department && record.department) { //為科別模式且有待科別資料
                //分割科別資料
                const splitArray = record.department.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.divisionList.findIndex(department => department.divNo === splitArray[0] && department.divName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDepartment(false)
                }
            }
        } else if (mode === PopupModeEnum.Modify) { // 為修改模式
            // 不給予修改醫生
            setCanModifyDoctor(false)
            // 不給予修改科別
            setCanModifyDepartment(false)
        }
    }, [])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getFilterDoctorOptions())
    }, [record.doctor])
    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getFilterDepartmentOptions())
    }, [record.department])
    /**
     * 監聽代理醫生輸入框變化
     */
    useEffect(() => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions())
    }, [record.locumDoctor])

    return (
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont">
            <div
                className={`flex ${mode === PopupModeEnum.Add ? 'w-[910px]' : 'w-[1235px]'} bg-[#ffffff] rounded-md text-lg relative`}
                onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-col w-full text-left ">
                    {/**Title */}
                    <div
                        className="h-[60px] px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 justify-start items-center gap-2.5 inline-flex">
                        <div className="grow shrink basis-0 text-black text-xl font-normal leading-7">
                            {mode === PopupModeEnum.Add ? "新問診" : "班表進階設定"}
                        </div>
                        <div className="text-sky-700 text-lg font-normal leading-7 cursor-pointer"
                             onClick={closePopup}>
                            {t('general.close')}
                        </div>
                    </div>
                    {/**Content */}
                    <div className="px-[24px] py-[16px]">
                        <div
                            className={` ${mode === PopupModeEnum.Add ? "text-left justify-left flex flex-row justify-between" : "justify-center text-center"} w-full items-center`}>
                            <div className="text-[20px]">
                                {/*取得標題內容*/}
                                {getHeaderContent()}
                            </div>
                            {
                                mode === PopupModeEnum.Add && (
                                    <div className="flex flex-row justify-between text-[16px]">
                                        {/*醫師搜尋文字以及下拉式選單*/}
                                        <div className="flex flex-row text-[#18181B] items-center">
                                            <label>{t('general.doctor')}</label>
                                            <div className="px-2 flex flex-row space-x-2 items-center relative" ref={dropdownDoctorRef}>
                                                <input
                                                    className={`bg-white w-[full] h-[40px] px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8]`}
                                                    value={record.doctor}
                                                    onChange={(e) => handleDoctorSearchTextOnChange(e)}
                                                    onFocus={handleDoctorOptionOnFocus}/>
                                                <img className="w-4 h-4 absolute top-w-full left-0 translate-x-4"
                                                     alt="search-icon" src={searchIcon} role="button"/>
                                                {/*醫生下拉選單*/}
                                                <div
                                                    className="bg-white z-10 absolute left-0 top-10 max-h-20 w-full flex flex-col">
                                                    {
                                                        showDoctorDropdown && !arrayIsEmpty(doctorOptionList) && (
                                                            <div className="flex flex-col">
                                                                <div
                                                                    className=" border-2 bg-white max-h-[150px] overflow-y-scroll">
                                                                    {
                                                                        doctorOptionList.map((doctor, index) =>
                                                                            <div
                                                                                className=" bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                                key={index} role="button"
                                                                                onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                                                {`${doctor.userNo} ${doctor.userName}`}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*科別搜尋文字以及下拉式選單*/}
                                        <div className="flex flex-row items-center text-[#18181B]">
                                            <label>{t('general.department')}</label>
                                            <div className="px-2 flex flex-row space-x-2 items-center relative w-2/3" ref={dropdownDepartmentRef}>
                                                <input
                                                    className="bg-white w-full h-[40px] px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8]"
                                                    value={record.department}
                                                    onChange={(e) => handleDepartmentSearchTextOnChange(e)}
                                                    onFocus={handleDepartmentOnFocus}/>
                                                <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-4"
                                                     alt="search-icon" src={searchIcon} role="button"/>
                                                <div
                                                    className="bg-white z-10 absolute left-0 top-10 max-h-20 w-64 flex flex-col">
                                                    {
                                                        // 科別下拉選單
                                                        showDepartmentDropdown && !arrayIsEmpty(departmentOptionList) && (
                                                            <div className="flex flex-col">
                                                                <div
                                                                    className="max-h-[150px] border-2 bg-white overflow-y-scroll">
                                                                    {
                                                                        departmentOptionList.map((department, index) =>
                                                                            <div
                                                                                className="bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                                key={index} role="button"
                                                                                onClick={() => handleDepartmentOptionOnClick(department)}>
                                                                                {`${department.divNo} ${department.divName}`}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex flex-row justify-between w-full pt-4 space-x-1 text-[16px]">
                            <div
                                className={`flex flex-row justify-between space-x-2 ${mode === PopupModeEnum.Add ? 'w-[55%]' : 'w-[40%]'}`}>
                                {/*標籤*/}
                                <div className="flex flex-col text-left w-[35%] space-y-2 px-2">
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        預約最大看診人次
                                    </label>

                                    <label className="flex items-center h-[40px] justify-end" htmlFor="onsiteLimit">
                                        現場最大看診人次
                                    </label>

                                    <label className="flex items-center h-[40px] justify-end" htmlFor="time-per-person">
                                        看診時間(分/人)
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                           htmlFor="reservation-method">
                                        預約方式
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                           htmlFor="scheduling-method">
                                        給號方式
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                           htmlFor="reservation-restriction">
                                        預約限制
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        {t("Regs.general.clinicName")}
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        {t("Regs.general.clinicLocation")}
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        診別
                                    </label>
                                </div>

                                {/*輸入方框*/}
                                <div className="flex flex-col text-left w-[65%] space-y-2 px-3 justify-center">
                                    {/** 最大看診人次 */}
                                    <input className={`pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                           type="number" id="max-people" value={record.maxPatient} min={0}
                                           disabled={record.stopClinicFlag}
                                           onChange={(e) => handleMaxPatientOnChange(e)}/>

                                    {/** 現場掛號人次 */}
                                    <input className={`pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                           type="number" id="current-people-count"
                                           value={record.onsiteLimit}
                                           disabled={record.stopClinicFlag}
                                           min={0}
                                           onChange={(e) => handleOnsiteNumberOnChange(e)}/>

                                    {/**看診時間(分/人) */}
                                    <input className={`pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                           type="number" id="time-per-person" value={record.consultationTime} min={0}
                                           disabled={record.stopClinicFlag}
                                           onChange={(e) => handleConsultationTimeOnChange(e)}/>
                                    {/**預約方式 */}
                                    <div className="flex flex-row space-x-5 items-center h-[40px]">
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] disabled:checked:bg-[#286BAF]/50 rounded-lg checked:ring-[#286BAF] disabled:checked:ring-[#286BAF]/50 ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="radio" id="room-number" name="reservationMethodRadio"
                                                disabled={record.stopClinicFlag}
                                                onChange={() => handleReservationMethodOnChange(RegsWeekReservationMethodEnum.ClinicNumber)}
                                                checked={record.reservationMethod === RegsWeekReservationMethodEnum.ClinicNumber}/>
                                            <label
                                                className={`${record.reservationMethod === RegsWeekReservationMethodEnum.ClinicNumber ? "text-[#286CB0]" : ""}`}
                                                htmlFor="room-number">
                                                {t('Regs.general.viewNumber')}
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] disabled:checked:bg-[#286BAF]/50 rounded-lg checked:ring-[#286BAF] disabled:checked:ring-[#286BAF]/50 ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="radio" id="time" name="reservationMethodRadio"
                                                disabled={record.stopClinicFlag}
                                                onChange={() => handleReservationMethodOnChange(RegsWeekReservationMethodEnum.Time)}
                                                checked={record.reservationMethod === RegsWeekReservationMethodEnum.Time}/>
                                            <label
                                                className={`${record.reservationMethod === RegsWeekReservationMethodEnum.Time ? "text-[#286CB0]" : ""}`}
                                                htmlFor="time">
                                                {t('general.time')}
                                            </label>
                                        </div>
                                    </div>
                                    {/**給號方式 */}
                                    <select
                                        className="w-23 py-1 pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:opacity-100 disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]"
                                        id="scheduling-method" value={record.numberGivingMethod}
                                        disabled={record.stopClinicFlag}
                                        onChange={(e) => handleNumberGivingMethod(e)}>
                                        {
                                            baseData.noTypeList.map((option, index) => (
                                                <option key={index} value={option.noType}>{option.typeName}</option>))
                                        }
                                    </select>
                                    {/**預約限制 */}
                                    <div className="flex flex-row space-x-4 h-[40px]">
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] disabled:checked:bg-[#286BAF]/50 rounded-lg checked:ring-[#286BAF] disabled:checked:ring-[#286BAF]/50 ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                disabled={record.stopClinicFlag}
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.Normal)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.Normal}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.Normal ? "text-[#286CB0]" : ""}`}>
                                                {t('general.normal')}
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] disabled:checked:bg-[#286BAF]/50 rounded-lg checked:ring-[#286BAF] disabled:checked:ring-[#286BAF]/50 ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                disabled={record.stopClinicFlag}
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorOnly)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.DoctorOnly}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.DoctorOnly ? "text-[#286CB0]" : ""}`}>
                                                限醫師
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] disabled:checked:bg-[#286BAF]/50 rounded-lg checked:ring-[#286BAF] disabled:checked:ring-[#286BAF]/50 ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                disabled={record.stopClinicFlag}
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorSelfOnly)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.DoctorSelfOnly}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.DoctorSelfOnly ? "text-[#286CB0]" : ""}`}>
                                                限醫師本人
                                            </label>
                                        </div>
                                    </div>
                                    {/*診室名稱*/}
                                    <input className={`pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                           type="text" id="max-people" value={record.clinicName}
                                           disabled={record.stopClinicFlag}
                                           onChange={(e) => handleClinicNameOnChange(e)}/>
                                    {/*診室地點*/}
                                    <input className={`pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                           type="text" id="max-people" value={record.clinicLocation}
                                           disabled={record.stopClinicFlag}
                                           onChange={(e) => handleClinicLocationOnChange(e)}/>
                                    <div className="flex flex-row space-x-4 h-[40px]">
                                        {/*診別*/}
                                        <select className="w-1/2 py-1 pl-3 border border-[#D4D4D8] h-full rounded-[6px] disabled:opacity-100 disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]"
                                                id="medical-type" value={record.medicalType}
                                                disabled={record.stopClinicFlag}
                                                onChange={(e) => handleMedicalTypeOnChange(e)}>
                                            <option value={RegsMedicalTypeEnum.OutpatientClinic}>門診</option>
                                            <option value={RegsMedicalTypeEnum.MedicalCheckup}>外檢</option>
                                            <option value={RegsMedicalTypeEnum.PreventiveHealthcare}>預防保健</option>
                                        </select>
                                        {/*調病歷*/}
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                               type="checkbox" style={{accentColor: "#38A169"}}
                                               disabled={record.stopClinicFlag}
                                               checked={record.canGetMedicalRecords}
                                               onChange={handleCanGetMedicalRecordsOnChange}/>
                                        <label htmlFor="" className="h-full flex items-center">
                                            調病歷
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex flex-col ${mode === PopupModeEnum.Add ? 'w-[45%]' : 'w-[35%]'} items-start justify-start text-left space-y-2`}>
                                {/**收費別 */}
                                <div className="flex flex-row w-full justify-between text-center space-x-4 h-[40px]">
                                    <label className="w-[35%] flex justify-end items-center h-full"
                                           htmlFor="reservation-restriction">
                                        收費別
                                    </label>
                                    <div className="flex flex-row px-2 space-x-1 w-[65%] h-[40px] relative">
                                        <input
                                            className="bg-white w-full h-full px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]"
                                            value={record.chargeType}
                                            disabled={record.stopClinicFlag}
                                            onChange={(e) => handleChargeTypeSearchTextOnChange(e)}
                                            onFocus={handleChargeTypeSearchTextOnFocus}
                                            onBlur={handleChargeTypeSearchTextOnBlur}/>
                                        <img className="w-4 h-4 absolute top-[11px] left-0 translate-x-4"
                                             alt="search-icon" src={searchIcon} role="button"/>
                                        {/**收費別下拉選單*/}
                                        {
                                            showChargeTypeDropdown && (
                                                <div
                                                    className="bg-white z-10 absolute left-0 top-10 max-h-20 w-64 flex flex-col text-left">
                                                    <div className="flex flex-col">
                                                        <div className="max-h-[200px] border-2 bg-white overflow-y-scroll">
                                                            {
                                                                getFilterChargeTypeOptions().map((cash, index) => (
                                                                    <div
                                                                        className="bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                        key={index} role="button"
                                                                        onClick={() => handleChargeTypeOptionOnClick(cash)}>
                                                                        {`${cash.cashType} ${cash.cashName}`}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* 網掛/APP*/}
                                <div
                                    className="flex flex-row w-full h-[40px] justify-between items-center space-x-4">
                                    <div className="w-[35%]"></div>
                                    <div className="w-[65%] flex justify-left space-x-2">
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                               type="checkbox" checked={record.webOrAppBooking}
                                               style={{accentColor: "#38A169"}}
                                               disabled={record.stopClinicFlag}
                                               onChange={handleWebOrAppBookingOnChange}/>
                                        <label htmlFor="max-people" className="">
                                            網掛/APP
                                        </label>
                                    </div>
                                </div>
                                {/*網掛/APP取消看診進度*/}
                                <div
                                    className="flex flex-row w-full h-[40px] justify-between items-center text-left space-x-4">
                                    <div className="w-[35%]"></div>
                                    <div className="flex flex-row w-[65%] space-x-2">
                                        <input
                                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                            type="checkbox" checked={record.webOrAppCancel}
                                            style={{accentColor: "#38A169"}}
                                            disabled={record.stopClinicFlag}
                                            onChange={handleWebOrAppCancelOnChange}/>
                                        <label htmlFor="reservation-method">
                                            網掛/APP取消看診進度
                                        </label>
                                    </div>
                                </div>
                                {/*網掛預約期限*/}
                                <div
                                    className="flex flex-row w-full justify-between text-center space-x-4 h-[40px]">
                                    <label className="w-[35%] flex justify-end items-center h-full"
                                           htmlFor="reservation-restriction">
                                        網掛預約期限
                                    </label>
                                    <div className="flex flex-row px-2 space-x-1 w-[65%] h-full">
                                        <input className={`pl-3 rounded-[6px] border border-[#D4D4D8] w-[100px] h-full disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                               type="number" value={record.onlineBookingDeadline} min={0}
                                               disabled={record.stopClinicFlag}
                                               onChange={(e) => handleOnlineBookingDeadlineOnChange(e)}/>
                                        <label>天</label>
                                    </div>
                                </div>
                                {/*注意事項*/}
                                <div
                                    className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center"
                                           htmlFor="notes text-center">
                                        注意事項
                                    </label>
                                    <div className="px-2 w-[65%]">
                                        <textarea
                                            className={`border border-[#D4D4D8] h-full min-h-full px-1 w-full rounded-[6px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                            id="notes" value={record.notes}
                                            disabled={record.stopClinicFlag}
                                            onChange={(e) => handleNotesOnChange(e)}/>
                                    </div>
                                </div>
                                {/*診間標語*/}
                                <div
                                    className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center"
                                           htmlFor="notes text-center">
                                        診間標語
                                    </label>
                                    <div className="px-2 w-[65%]">
                                        <textarea
                                            className={`border border-[#D4D4D8] h-full min-h-full px-1 w-full rounded-[6px] disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]`}
                                            id="notes" value={record.clinicSlogan}
                                            disabled={record.stopClinicFlag}
                                            onChange={(e) => handleClinicSloganOnChange(e)}/>
                                    </div>
                                </div>
                                {/*看診項目*/}
                                <div
                                    className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center"
                                           htmlFor="notes text-center">
                                        看診項目
                                    </label>
                                    <div className="px-2 w-[65%]">
                                        <select
                                            className="py-1 pl-3 border border-[#D4D4D8] h-full w-full rounded-[6px] disabled:opacity-100 disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]"
                                            value={record.mediItem}
                                            disabled={record.stopClinicFlag}
                                            onChange={(e) => handleConsultationItemsOnChange(e)}>
                                            {/* 空白項 */}
                                            <option value="empty"></option>
                                            {
                                                baseData && baseData.clinicMarkNoList && baseData.clinicMarkNoList.map((option, index) => (
                                                    <option key={index}
                                                            value={option.justnameNo}>{option.justnameName}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                mode === PopupModeEnum.Modify && (
                                    <div className="flex flex-col w-[30%] items-start justify-start text-left space-y-2">
                                        <div className="flex flex-col items-start justify-start text-left space-y-2 w-full">
                                            
                                            <div
                                                className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                                <label
                                                    className={`flex justify-end items-center w-2/5 h-full ${record.stopClinicFlag ? "text-[#949498]" : ""}`}
                                                    htmlFor="locumDoctor">
                                                    代班醫師
                                                </label>
                                                {/*代班醫師下拉選單*/}
                                                <div ref={dropdownLocumDoctorRef} className="flex flex-row flex-1 items-center relative">
                                                <input
                                                    className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] disabled:bg-[#F4F4F5] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                                                    value={record.locumDoctor}
                                                    disabled={record.stopClinicFlag}
                                                    onChange={(e) => handleLocumDoctorOnChange(e)}
                                                    onFocus={handleInputLocumDoctorOnFocus}/>
                                                {
                                                    showLocumDoctorDropDown && !arrayIsEmpty(locumDoctorOptionList) &&
                                                    (
                                                        <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                            {
                                                                locumDoctorOptionList.map((item, index) =>
                                                                    <div
                                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                        role="button" key={index}
                                                                        onClick={() => handleLocumDoctorOptionOnClick(item)}
                                                                        >
                                                                        <p>{`${item.userNo} ${item.userName}`}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            </div>
                                            {/*停診原因*/}
                                            <div className="flex flex-row w-full text-center space-x-4 h-[40px]">
                                                <div className="flex flex-row w-2/5 justify-end space-x-2 h-full">
                                                    <input
                                                        className="bg-green-400 w-5 checked:bg-green-600 text-green-600 items-center"
                                                        type="checkbox" checked={record.stopClinicFlag}
                                                        onChange={handleStopClinicFlagOnChange}
                                                        style={{accentColor: "#38A169"}}
                                                        disabled={!stringIsEmpty(record.locumDoctor)}/>
                                                    <label htmlFor="medical-record-status "
                                                           className={`flex items-center ${!stringIsEmpty(record.locumDoctor) ? "text-[#949498]" : ""}`}>
                                                        停診原因
                                                    </label>
                                                </div>
                                                {/*停診原因下拉選單*/}
                                                <select
                                                    className="flex-1 px-2 p-1 text-[#18181B] text-base border border-[#D4D4D8] rounded-[6px] disabled:opacity-100 disabled:text-[#A1A1AA] disabled:bg-[#F4F4F5]"
                                                    value={record.stopClinicReason}
                                                    onChange={(e) => handleStopClinicReasonOnChange(e)}
                                                    disabled={!record.stopClinicFlag}
                                                >
                                                    <option value="" selected disabled hidden></option>
                                                    {
                                                        !arrayIsEmpty(baseData.stopClinicReasonList) && baseData.stopClinicReasonList.map((reason, index) => (
                                                            <option
                                                                key={index}
                                                                value={reason.justnameNo}>
                                                                {reason.justnameName}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {/*停代診公告*/}
                                            <div className="flex flex-row w-full text-center space-x-4 h-[40px]">
                                                <div className="w-2/5 h-full">
                                                </div>
                                                <div className="flex-1 flex flex-row h-full space-x-2">
                                                    <input
                                                        className="bg-green-400 w-5 checked:bg-green-600 text-green-600 items-center"
                                                        type="checkbox" checked={record.stopClinicBulletinFlag}
                                                        onChange={handleStopClinicBulletinFlagOnChange}
                                                        style={{accentColor: "#38A169"}}/>
                                                    <label htmlFor="reservation-method"
                                                           className="flex items-center">停代診公告</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/**下方區塊(存檔) */}
                    <div
                        className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                        <button
                            className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                            onClick={handleSaveOrEditOnClick}>
                            {t('general.saveFile')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentPopup
