import { DefaultButton } from "../../../Button/DefaultButton"
import React, {useEffect, useState} from "react"

/**
 * 提示彈出窗
 * @param {String} promptText 提示字串
 * @param {Function} closePopupButtonOnClick 關閉事件
 * @param show {Boolean} 是否顯示彈窗
 * @returns 
 */
const DeletePromptPopup = ({
    promptText, 
    confirmOnClick,
    cancelOnClick, 
    show = false
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <div
            className=
                "fixed top-[-16px] left-0 flex items-center justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10"
                onClick={(e) => e.stopPropagation()}>
            <div className="w-[500px] bg-white rounded-[6px]">
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">提醒</h3>
                </div>
                {/*Content*/}
                <div className="mb-4 px-6 space-y-2">
                    <div className="text-left font-medium">
                        是否確定刪除？
                        <div className="font-normal">{promptText}</div>
                    </div>

                    
                </div>
                <div className="flex w-full py-3 px-3.5 justify-end space-x-3 border-t border-[rgba(0,0,0,0.15)]">
                    <DefaultButton 
                        type="outline-primary" 
                        text="否" 
                        onClickFn={cancelOnClick}/>
                    <DefaultButton 
                        type="primary" 
                        text="是" 
                        onClickFn={confirmOnClick}/>
                </div>
            </div>
        </div> :
        <></>
    )
}

export default DeletePromptPopup;