import {BaseInput} from "../../Input/BaseInput";
import {BasePopup} from "../../Popup/BasePopup";
import {InputTypeEnum, time2String, ToastTypeEnum} from "edah_utils/dist";
import {useState} from "react";
import {Title} from "../utils";
import {usePopup} from "../PopupProvider";

const ExportFIleNameModal = ({handleOnClose, handleOnExport}) => {

    const {showToast} = usePopup()

    // 取得日期時間
    const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')

    // 檔案名稱
    const [fileName, setFileName] = useState('')

    // 匯出檔案名稱
    const [exportFileName, setExportFileName] = useState('')

    const handleExportOnClick = () => {
        if(exportFileName === ''){
            showToast({message: '請輸入檔案名稱', type: ToastTypeEnum.Warning})
            return
        }
        handleOnExport(exportFileName)
    }

    const formatExportDateName = (value) => {
        const result = ''
        const date = value.split(' ')[0]
        const time = value.split(' ')[1]

        const newDateString = date.split('-').join('')
        // 時間只取split後的前兩個
        const newTimeString = time.split('-').slice(0, 2).join('')

        return result+`${newDateString}${newTimeString}`
    }

    return (
        <BasePopup
            title="匯出編號名稱"
            width={'559px'}
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='flex flex-col gap-4 p-4'>
                    <div className='flex flex-row gap-4'>
                        <Title children={'當前日期時間'}/>
                        <BaseInput
                            type={InputTypeEnum.Text}
                            className={'bg-transparent'}
                            value={current}
                            onChange={() => {
                            }}
                            disable={true}
                        />
                    </div>
                    <div className='flex flex-row gap-4'>
                        <Title children={'備註說明'}/>
                        <BaseInput
                            type={InputTypeEnum.Text}
                            className={'border-[1px] border-[#D9D9D9] rounded-[6px] h-10 pl-4'}
                            placeholder={`請輸入匯出檔案名稱`}
                            value={`${fileName}`}
                            onChange={(e) => {
                                setFileName(e.target.value)
                                setExportFileName(`${formatExportDateName(current)}_${e.target.value}`)
                            }}
                        />
                    </div>
                    <div className='flex flex-row gap-4 w-full'>
                        <Title children={'匯出檔案名稱'}/>
                        <BaseInput
                            type={InputTypeEnum.Text}
                            className={'bg-transparent w-[300px]'}
                            value={exportFileName}
                            onChange={() => {}}
                            disable={true}
                        />
                    </div>
                    <div className='flex my-2 justify-end'>
                        <button
                            className="flex justify-center items-center w-[64px] ml-4 font-base bg-primary text-white h-10 border-[2px] rounded-[6px]"
                            onClick={handleExportOnClick}>
                            存檔
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default ExportFIleNameModal