import { BaseInput } from "../../components/Input/BaseInput";
import Button from "../../components/Button/Button";
import {
    ApiErrorStatusEnum,
    downloadFile,
    exportExcelAsBlob, FileExtensionsEnum,
    InputTypeEnum,
    time2String,
    ToastTypeEnum
} from "edah_utils/dist";
import React, {useEffect, useMemo, useState} from "react";
import CustomTable from "../../components/ReferralSystem/CustomTable";
import {
    LONG_TERM_CARE_CENTER
} from "../../constants/referralSystem/maintainAreaHeader";
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/del_square.svg'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { Pagination } from "../../components/Pagination/Pagination";
import { ReactComponent as AddBtn } from "../../assets/images/icons/add_circle.svg"
import {BasePopup} from "../../components/Popup/BasePopup";
import LongTermCareCenterForm from "../../components/ReferralSystem/LongTermCareCenter/LongTermCareCenterForm";
import {
    addRmisTranCareCenter, deleteRmisTranCareCenter, deleteTranUser, editRmisTranCareCenter,
    queryRmisTranCareCenter
} from "../../api/v1/Rmis";
import useDataGrid from "../../hooks/useDataGrid";
import {DATE_FORMAT, DATETIME_FORMAT} from "../../constants/common";
import useToast from "../../hooks/useToast";
import store from "../../redux/store";
import {completePrintMode, updatePrintContent} from "../../redux/Slice/printSlice";
import {FORM_TYPE} from "../../constants/referralSystem/print";
import {useSelector} from "react-redux";
/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({children})=> {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
/**
 * 標題 component
 * @returns {JSX.Element}
 */
const Title = ({children, extendClass})=> {
    return (
        <div className={`date flex flex-row items-center justify-start ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}

/**
 * Style
 */
const InputStyle = "w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SecondButtonStyle = "bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"

/**
 * 長照中心維護
 * @returns {JSX.Element}
 */
const LongTermCareCenter = () => {
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    const [searchParams, setSearchParams] = useState({
        tranOutStartDate: '',
        tranOutEndDate: '',
        patientId: '',
        hospGrade: '',
        acceptFlag: '',
        condition: '',
        aidGradeFlag: false,
        ruralType: false,
    })

    /**
     * 表單狀態
     */
    const [modalInfo, setModalInfo] = useState({
        show: false,
    })
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    /**
     * Show Toast
     */
    const showToast = useToast()
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 搜尋列 input
     */
    const [hospitalCode, setHospitalCode] = useState("")


    // data grid
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        dataOnChange,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
    } = useDataGrid()

    /**
     * 搜尋列 更新
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)
    const handleLongTermCenterChange = (e) => setLongTermCenter(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = async ()=>{
        const result =  await queryRmisTranCareCenter({
            hospNoOrName: hospitalCode,
            pageNum: filter.currentPage,
            pageSize: filter.pageSize
        })

        if (result.err === ApiErrorStatusEnum.Success) {
            updateDataList(result.data.dataList)
            setFilter({
                ...filter,
                totalPage: result.data.totalPageSize,
                total: result.data.totalItemSize
            })
        } else {
            showToast({message:result.msg, type:ToastTypeEnum.Warning})
        }
    }

    // 刪除單筆資料
    const handleConfirmDelete= () =>{
        const hospNo = JSON.stringify([{hospNo: activeRow.hospNo, lockVersion: activeRow.lockVersion}])
        deleteRmisTranCareCenter({ tableName: "tw.com.hismax.his.entity.rmis.RmisTranCareCenter", tableData: hospNo }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '刪除成功', type: ToastTypeEnum.Success })
                deleteRow(activeRow)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }

            setShowDeletePopup(false)
        })
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = (tableRow)=>{
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
        })
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }
    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = LONG_TERM_CARE_CENTER.map((item) => item.key)
        const titles = LONG_TERM_CARE_CENTER.map((item) => item.name)
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                data.push(item[key])
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '長照中心維護)',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button className="flex items-center text-green-600" onClick={handleExportExcel}>
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }
    /**
     * 表格中的刪除按鈕
     * @param {object} tableRow 要刪除的單筆資料
     * @returns {JSX.Element}
     */
    const Delete = (tableRow) => (
        <div className="flex items-center justify-center">
            <button
                className="text-[#007CDB] flex"
                onClick={() => handleDeletePopupOpen(tableRow)}
            >
                <DeleteIcon/>
                <div className="ml-1">刪除</div>
            </button>
        </div>
    )

    /**
     * 點擊編輯進入編輯模式
     * @param {object} row 當列資料
     */
    const handleEditMode = (tableRow) => {
        handleEdit({
            ...tableRow,
            //長照機構代碼
            hospNo: tableRow.hospNo || '',
            //長照機構名稱
            hospName: tableRow.hospName || '',
            //長照機構郵遞區號
            hospZipCode: tableRow.hospZipCode || '',
            //長照機構地址
            hospAddress: tableRow.hospAddress || '',
            //電話區域號碼
            hospAreaCode: tableRow.hospAreaCode || '',
            //醫事機構電話
            hospTel: tableRow.hospTel || '',
        })
    }


    /**
     * 編輯模式下的 input save
     * @param row
     */
    const handleSaveAction = async (tableRow) => {
        const data = {
            //長照機構代碼
            hospNo: tableRow.hospNo,
            //長照機構名稱
            hospName: tableRow.hospName,
            //長照機構郵遞區號
            hospZipCode: tableRow.hospZipCode,
            //長照機構地址
            hospAddress: tableRow.hospAddress,
            //電話區域號碼
            hospAreaCode: tableRow.hospAreaCode,
            //醫事機構電話
            hospTel: tableRow.hospTel,
            //版本鎖定
            lockVersion: tableRow.lockVersion
        }

        const res = await editRmisTranCareCenter(data)

        if (res.err === ApiErrorStatusEnum.Success) {
            handleSave(tableRow)
            handleClickQuery()
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
        } else {
            showToast({ message: res.msg, type: ToastTypeEnum.Error })
        }

    }
    /**
     * 新增一列資料
     */
    const handleAddNewRow = async () => {
        const newItem = {
            //長照機構代碼
            hospNo: "",
            //長照機構名稱
            hospName: "",
            //長照機構郵遞區號
            hospZipCode: "",
            //長照機構地址
            hospAddress: "",
            //電話區域號碼
            hospAreaCode: "",
            //醫事機構電話
            hospTel: "",
            //版本鎖定
            lockVersion: 0
        }
        const res = await addRmisTranCareCenter(newItem)
        if (res.err === ApiErrorStatusEnum.Success) {
            handleClickQuery()
            showToast({ message: '新增成功', type: ToastTypeEnum.Success })
        } else {
            showToast({ message: res.msg, type: ToastTypeEnum.Error })
        }
    }

    /**
     * 變更資料
     * @param {event} event
     * @param {object} row 該列資料
     * @param {string} key 欄位
     */
    const handleOnChange = (event, row, key) => {
        const value = event.target.value
        dataOnChange(row, key, value)
    }

    const addEmptyRow = () => {
        const newData = {
            lockVersion: 0,
            hospNo: '',
            hospAccount: '',
            hospPasswd: '',
            applicantName: '',
            applicantTel: '',
            applicantFax: '',
            applicantEmail: '',
            applicantDate: '',
            seqNo: '',
            sortBy: '',
            hospName: '',
        }
        addNewData(newData)
    }

    // 列印
    const handlePrintList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.longTermCareList,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])
    return (
        <Layout>
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button onClick={handleConfirmDelete}
                                        className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >是</button>
                                <button onClick={handleDeletePopupClose}
                                        className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >否</button>
                            </div>
                        </div>
                    }
                />
            )}
            <div className="flex justify-between p-2 items-center">
                {/*搜尋列*/}
                <div className="flex items-center">
                    <Title>院所代碼 / 長期照護單位名稱：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    <Button classNames={`ml-4 ${ButtonStyle}`} text={"查詢"} onClickFn={handleClickQuery}/>
                </div>
                <div>
                    <Button classNames={SecondButtonStyle} text={"列印"} onClickFn={handlePrintList}/>
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={LONG_TERM_CARE_CENTER}
                dataList={dataList}
                slotButton={<ExportExcelBtn/>}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                isCrud={true}
                handleAddRow={handleAddNewRow}
                isEditMode={isEditMode}
                handleEdit={handleEditMode}
                handleSave={handleSaveAction}
                handleCancel={handleCancel}
                addNewData={addEmptyRow}
                deleteRow={handleDeletePopupOpen}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            {modalInfo.show &&
                <BasePopup
                    title={"長照中心新增" }
                    width="80%"
                    content={<LongTermCareCenterForm data={activeRow} ></LongTermCareCenterForm>}
                    closePopupButtonOnClick={()=>{
                        setModalInfo({
                            show: false
                        })
                    }}
                />
            }
        </Layout>
    )
}

export default LongTermCareCenter