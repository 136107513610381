import {useState, useEffect, useRef} from "react"
import InfoCard from "../Appointment/InfoCard"
import {InputTypeEnum, time2String, objectIsEmpty, stringIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import editIcon from "../../assets/images/icons/edit.svg"
import saveIcon from "../../assets/images/icons/save_default.svg"
import cancelIcon from "../../assets/images/icons/cancel_default.svg"
import { BaseInput } from "../Input/BaseInput"
import {t} from "i18next"

/**
 * 病歷資訊
 * @param icCardData {Object} 健保IC卡資料
 * @param showData {Boolean} 是否顯示資料
 * @param saveEditPatient {Function} 儲存編輯的病人資料
 * @param isEditMode {Boolean} 是否為編輯模式
 * @param setIsEditMode {Function} 設定編輯模式
 */
const CaseInformation = ({icCardData, showData, saveEditPatient, isEditMode, setIsEditMode}) => {

    //編輯的資料
    const [editData, setEditData] = useState(null)
    const minguYearRef = useRef(null);
    const minguMonthRef = useRef(null);
    const minguDayRef = useRef(null);

    /**
     * 計算年齡
     * @param birthdate {String} 出生日期
     * @return {String} 年齡
     */
    const calculateDetailedAge = (birthdate) => {
        const birthDate = new Date(birthdate)
        const today = new Date()

        let ageYears = today.getFullYear() - birthDate.getFullYear()
        let ageMonths = today.getMonth() - birthDate.getMonth()

        // 調整月份和年份
        if (ageMonths < 0) {
            ageYears--
            ageMonths += 12 // 負數月份轉為正，並從年份中減去1
        }
        // 確保在當月的日期前出生不計入該月
        if (today.getDate() < birthDate.getDate()) {
            ageMonths--
            if (ageMonths < 0) {
                ageYears--
                ageMonths = 11
            }
        }

        return `${ageYears}歲 ${ageMonths}個月`
    }

    /**
     * 編輯按鈕事件
     */
    const handleEditPatientInformationOnClick = () => {
        let minguYears = 0;
        let birthDate = icCardData.birthDate;// === null ? new Date() : icCardData.birthDate
        minguYears = new Date(birthDate).getFullYear() - 1911

        setEditData({
            ...icCardData,
            birthDate: birthDate,
            birthMinguoYears:birthDate===null ? '' : `${String(minguYears).padStart(3, '0')}`,
            birthMinguoMonths:birthDate===null ? '' :  `${String(new Date(birthDate).getMonth()+1).padStart(2, '0')}`,
            birthMinguoDays:birthDate===null ? '' :  `${String(new Date(birthDate).getDate()).padStart(2, '0')}`
        })
        
        setIsEditMode(true)
    }

    /**
     * 存檔按鈕事件
     */
    const handleSavePatientInformationOnClick = () => {

        saveEditPatient(editData.patientName, editData.birthDate, editData.idNo, editData.homephonenumber, editData.phonenumber)
    }

    /**
     * 姓名輸入框變更事件
     * @param {Event} e 
     */
    const handleInputNameTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            patientName: e.target.value
        }));
    }

    /**
     * 出生日期輸入框變更事件
     * @param {Event} e 
     */
    const handleInputBirthDateTextOnChange = (e) => {
        let date = e.target.value
        if(date === null || date === "Invalid date" || date === "")
        {
            date = icCardData.birthDate
        }
        const minguYears = new Date(date).getFullYear() - 1911

        setEditData(prev => ({
            ...prev,
            birthDate: time2String( date, "YYYY-MM-DD 00:00:00"), 
            //同步民國
            birthMinguoYears:`${String(minguYears).padStart(3, '0')}`,
            birthMinguoMonths: `${String(new Date(date).getMonth() + 1).padStart(2, '0')}`,
            birthMinguoDays: `${String(new Date(date).getDate()).padStart(2, '0')}`
        }));
    }

    /**
     * 身分證號輸入框變更事件
     * @param {Event} e 
     */
    const handleInputIdNoTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            idNo: e.target.value
        }));
    }

    /**
     * 電話輸入框變更事件
     * @param {Event} e 
     */
    const handleInputHomephonenumberTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            homephonenumber: e.target.value
        }));
    }

    const formatDateToMinguo = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getFullYear() - 1911;
        const month = dateObj.getMonth() + 1; // 月份從0開始，所以需要+1
        const day = dateObj.getDate();

        return `${String(year).padStart(3, '0')}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }

    /**
     * 民國(年)輸入框Input事件
     * @param {Event} e 
     */
    const handleInputMinguoYearsOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過3位數且大於0
        if (e.target.value.length > 3) {
            e.target.value = e.target.value.slice(0, 3);
        }

        if (e.target.value.length === 3) {
            minguMonthRef.current.focus();
            minguMonthRef.current.select();
        }
        //if (e.target.value !== '' && parseInt(e.target.value, 10) < 1) {
        //    e.target.value = 1;
        //}
    }

    /**
     * 民國(月)輸入框Input事件
     * @param {Event} e 
     */
    const handleInputMinguoMonthsOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過2位數且大於0
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.slice(0, 2);
        }

        if (e.target.value.length === 2) {
            minguDayRef.current.focus();
            minguDayRef.current.select();
        }
    }

    /**
     * 民國(日)輸入框Input事件
     * @param {Event} e 
     */
    const handleInputMinguoDaysOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過2位數且大於0
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.slice(0, 2);
        }

    }

    /**
     * 民國(年)輸入框變更事件
     * @param {Event} e 
     */
    const handleInputMinguoYearsOnChange = (e) => {
        let date = new Date(editData.birthDate)
        date.setFullYear(Number(e.target.value) + 1911)

        setEditData(prev => ({
            ...prev,
            birthMinguoYears:e.target.value,
            //同步西元
            birthDate:time2String(date, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 民國(月)輸入框變更事件
     * @param {Event} e 
     */
    const handleInputMinguoMonthsOnChange = (e) => {
        let month = Number(e.target.value)
        let date = new Date(editData.birthDate)
        let year = date.getFullYear()
        let day = date.getDate()
        if(month < 1) {
            month = 1
        }
        else if (month > 12) {
            month = 12
        }
        const newDateString = `${year}-${String(month).padStart(2, '0')}-${day}`
        setEditData(prev => ({
            ...prev,
            birthMinguoMonths:e.target.value,
            //同步西元
            birthDate:time2String(newDateString, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 民國(日)輸入框變更事件
     * @param {Event} e 
     */
    const handleInputMinguoDaysOnChange = (e) => {
        let day = Number(e.target.value)
        let date = new Date(editData.birthDate)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        if(day < 1) {
            day = 1
        }
        else if(day > 31) {
            day = 31
        }
        const newDateString = `${year}-${String(month).padStart(2, '0')}-${day}`
        setEditData(prev => ({
            ...prev,
            birthMinguoDays:e.target.value,
            //同步西元
            birthDate:time2String(newDateString, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 手機號碼輸入框變更事件
     * @param {Event} e 
     */
    const handleInputPhoneNumberTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            phonenumber: e.target.value
        }));
    }

    /**
     * 取消編輯按鈕事件
     */
    const handleEditCancelOnClick = () => {
        setIsEditMode(false)
        
    }

    /**
     * 監聽編輯模式變化
     */
    useEffect(() => {
        if(!isEditMode) {
            setEditData(null)
        }
    }, [isEditMode])

    return (
        <InfoCard titles="病歷資訊" height="auto" className="relative">
            {/*沒有資料時*/}
            {
                !showData && (<p className="font-globalfont text-[20px] leading-[23px] font-normal text-[#9C9C9C] pb-3">請讀取健保IC卡或<br></br> 搜尋病人資訊</p>)
            }
            {
                showData && (
                    <>
                    {
                        isEditMode? (
                            <div className="absolute -top-[4px] right-0 flex space-x-2">
                                <div 
                                    className=" items-center flex text-[18px] text-[#2b6cb0] h-[24px] cursor-pointer"
                                    onClick={handleEditCancelOnClick}
                                >
                                    <>
                                        <img src={cancelIcon} alt="save-icon" />
                                        {t('general.cancel')}
                                    </>
                                </div>
                                <div 
                                    className="items-center flex text-[18px] text-[#2b6cb0] h-[24px] cursor-pointer"
                                    onClick={handleSavePatientInformationOnClick}
                                >
                                    <>
                                        <img src={saveIcon} alt="save-icon" />
                                        {t('general.saveFile')}
                                    </>
                                </div>
                            </div> 
                        ) : (
                            <div 
                                className="absolute -top-[4px] right-0 items-center flex text-[18px] text-[#2b6cb0] h-[24px] cursor-pointer"
                                onClick={handleEditPatientInformationOnClick}>
                                <>
                                    <img src={editIcon} alt="edit-icon" />
                                    {t('general.edit')}
                                </>
                            </div>
                        )
                    }
                    </>
                    
                )
            }
            {/*有資料時*/}
            {
                showData && (
                    <div className="w-full flex flex-col space-y-[6px] w-3/4 text-[#18181b] text-[16px]">
                        {/**病歷號 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('Regs.general.medicalRecordNumber')}</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p>{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.patientId)) ? icCardData.patientId : ""}</p>
                            </div>
                        </div>
                        {/**姓名 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.username')}</p>
                            </div>
                            <div className="w-[70%] text-left h-10">
                            {
                                    isEditMode ?
                                    <BaseInput 
                                        className="w-full h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                        type={InputTypeEnum.Text}
                                        value={editData.patientName}
                                        onChange={handleInputNameTextOnChange}/> :
                                    <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.patientName)) ? icCardData.patientName : ""}</p>
                            }
                            </div>
                        </div>
                        {/**性別 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.gender.name')}</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p>{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.genderName)) ? icCardData.genderName : ""}</p>
                            </div>
                        </div>
                        {/**出生日期 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.dateOfBirth')}</p>
                            </div>
                            <div className="w-[70%] text-left h-full">
                            {
                                    isEditMode ?
                                    <div className="space-y-1">
                                        <BaseInput 
                                            className="w-full h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                            type={InputTypeEnum.Date}
                                            inputMode="date"
                                            value={time2String(editData.birthDate, "YYYY-MM-DD")}
                                            onChange={handleInputBirthDateTextOnChange}/>
                                        <div className="flex items-center space-x-2">
                                            <p>{t('general.dateTime.mingu')}</p>
                                            <div className="flex items-center">
                                                <input
                                                    className="w-[50px] min-h-[34px] px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                                    ref={minguYearRef}
                                                    type="number"
                                                    value={editData.birthMinguoYears}
                                                    onInput={handleInputMinguoYearsOnInput}
                                                    onChange={handleInputMinguoYearsOnChange}
                                                />
                                                <p>-</p>
                                                <input
                                                    className="w-[40px] min-h-[34px] px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                                    ref={minguMonthRef}
                                                    type="number"
                                                    value={editData.birthMinguoMonths}
                                                    onInput={handleInputMinguoMonthsOnInput}
                                                    onChange={handleInputMinguoMonthsOnChange}
                                                />
                                                <p>-</p>
                                                <input
                                                    className="w-[40px] min-h-[34px] px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                                    ref={minguDayRef}
                                                    type="number"
                                                    value={editData.birthMinguoDays}
                                                    onInput={handleInputMinguoDaysOnInput}
                                                    onChange={handleInputMinguoDaysOnChange}
                                                />
                                                {/*Input hide number arrow*/}
                                                <style>
                                                    {`
                                                    input::-webkit-outer-spin-button,
                                                    input::-webkit-inner-spin-button {
                                                        -webkit-appearance: none;
                                                        margin: 0;
                                                    }
                                                    input[type="number"] {
                                                        -moz-appearance: textfield;
                                                    }
                                                    `}
                                                </style>
                                            </div>
                                            
                                        </div>
                                        
                                    </div> :
                                    <p className="h-full flex items-center">{icCardData === null || icCardData.birthDate === null ? "": `${time2String(icCardData.birthDate, "YYYY-MM-DD")} (民國${formatDateToMinguo(icCardData.birthDate)})`}</p>
                            }
                            </div>
                        </div>
                        {/**年齡 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.age')}</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p>{icCardData === null || icCardData.birthDate === null ? "" :`${calculateDetailedAge(icCardData.birthDate)}`}</p>
                            </div>
                        </div>
                        {/**身分證號 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.idNumber')}</p>
                            </div>
                            <div className="w-[70%] text-left h-full">
                            {
                                isEditMode ?
                                <BaseInput 
                                    className="w-full h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                    type={InputTypeEnum.Text}
                                    value={editData.idNo}
                                    onChange={handleInputIdNoTextOnChange}/> :
                                <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.idNo)) ? icCardData.idNo : ""}</p>
                            }
                            </div>
                        </div>
                        {/**通訊地址 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.correspondenceAddress')}</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p></p>
                            </div>
                        </div>
                        {/**電話 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.telephone')}</p>
                            </div>
                            <div className="w-[70%] text-left h-full">
                            {
                                isEditMode ?
                                <BaseInput 
                                    className="w-full h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                    type={InputTypeEnum.Text}
                                    value={editData.homephonenumber}
                                    onChange={handleInputHomephonenumberTextOnChange}/> :
                                <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.homephonenumber)) ? icCardData.homephonenumber : ""}</p>
                            }
                            </div>
                        </div>
                        {/**手機 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>{t('general.mobilePhone')}</p>
                            </div>
                            <div className="w-[70%] text-left h-full">
                            {
                                isEditMode ?
                                <BaseInput 
                                    className="w-full h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                                    type={InputTypeEnum.Text}
                                    value={editData.phonenumber}
                                    onChange={handleInputPhoneNumberTextOnChange}/> :
                                <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.phonenumber)) ? icCardData.phonenumber : ""}</p>
                            }
                            </div>
                        </div>
                        {/**優待身分 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>優待身分</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p></p>
                            </div>
                        </div>
                        {/**國籍 */}
                        <div className="justify-between flex flex-row space-x-[28px] min-h-[34px] items-center">
                            <div className="w-[30%] text-right flex justify-end">
                                <p>國籍</p>
                            </div>
                            <div className="w-[70%] text-left">
                                <p></p>
                            </div>
                        </div>
                    </div>
                )
            }
            {/*?????*/}
            {showData && <p></p>}

            
        </InfoCard>
    )
}
export default CaseInformation
