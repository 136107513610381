import React, { useEffect, useState, useRef } from 'react'
import RadioButton from '../../components/Input/RadioButton'
import { BaseInput } from "../../components/Input/BaseInput"
import { DefaultButton } from '../../components/Button/DefaultButton'
import { 
    regsMonthClinicBaseData, 
    regsQueryByDateRangeApnDocDiv,
    regsEditClinicList
} from '../../api/v1/RegsMonth';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Pagination } from '../../components/Pagination/Pagination';

import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    InputTypeEnum,
    time2String,
    arrayIsEmpty,
    stringIsEmpty,
    objectIsEmpty,
    fuzzySearchObjects,
    ToastTypeEnum
} from "edah_utils/dist"

//引入班表批次異動表單
import ScheduleBatchesUpdateForm from '../../components/Regs/ScheduleBatchesUpdate/ScheduleBatchesUpdateForm';

// 引入Lynx自定義TableList樣式
import '../../components/TableList/tableList.scss';
import useToast from '../../hooks/useToast';


const ScheduleBatchesUpdate = () => {
    // 預設建立時間起迄:起-今日往前兩天~迄-今日日期
    const today = new Date()
    //時段列表
    const apnList = [
        {
            justnameNo: "1,2,3",  justnameName: "全部"
        },
        {
            justnameNo: "1",  justnameName: "早"
        },
        {
            justnameNo: "2",  justnameName: "午"
        },
        {
            justnameNo: "3",  justnameName: "晚"
        },
    ]
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋醫師文字
        doctorText : "",
        //搜尋科別文字
        divText : "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
    }
    
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    // 是否顯示醫生下拉選單
    const [showDoctorFilterOptions, setShowDoctorFilterOptions] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentFilterOptions, setShowDepartmentFilterOptions] = useState(false)
    //班表批次異動參數初始值
    const [infoData, setInfoData] = useState(null)
    // 配置下拉選單
    const [allDept, setAllDept] = useState(null)
    const [allDoctor, setAllDoctor] = useState(null)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)

    const [isSearch, setIsSearch] = useState(false)
    //
    const [selectedTableData, setSelectedTableData] = useState([])
    // 班表查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null)
    // 班表進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    
    //顯示選取清單的現行資料
    const [currentData, setCurrentData] = useState(null)
    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState('')
    //all checked狀態
    const [checkedAll, setCheckedAll] = useState(false)
    // 停代診明細 pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorFilterOptions(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentFilterOptions(false),
    });
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 清單選中時
     * @param {String} id Key
     */
    const handleListOnSelect = (id) => {
        const findedData = filteredDataList.find(item => (item.key === id))
        setCurrentData(findedData)
    }

    /**
     * 模式改變時
     * @param {String} apn 
     */
    const handleApnModeOnChange = (apn) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: apn
        })
        //setSearchingMode(mode)
    }

    /**
     * 透過時段id取得時段字串
     * @param {Number} apn 
     * @returns 
     */
    const parseApnToString = (apn) => {
        switch(apn) {
            case 1:
                return "早"
            case 2:
                return "午"
            case 3:
                return "晚"
        }
        return ""
    }

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchSubmit = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message:"起始日期不可大於結束日期", type:ToastTypeEnum.Error})
            return
        }
        
        setIsSearch(true)

        //查詢第一頁
        searchSchedule(displayQueryInputData, 1, paginationProps.pageSize)
    }

    /**
     * 查詢班表
     */
    const searchSchedule = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)
        setCurrentData(null)

        let divNo = ""
        let doctorNo = ""
        let splitArray = queryInputObj.divText ? queryInputObj.divText.split(' ') : []
        if(queryInputObj.divText && splitArray.length === 2 ) {
            divNo = splitArray[0]
        }
        splitArray = queryInputObj.doctorText ? queryInputObj.doctorText.split(' ') : []
        if(queryInputObj.doctorText && splitArray.length === 2 ) {
            doctorNo = splitArray[0]
        }

        regsQueryByDateRangeApnDocDiv({
            //開始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            //科別
            divNo: divNo,
            //醫師
            doctorNo: doctorNo,
            //時段list
            apnList: queryInputObj.apn,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data.dataList ? res.data.dataList.map((item, index) => {
                    let doctorName = ''
                    let divName = ''
                    if(stringIsEmpty(item.doctorName)) {
                        
                        const findedDoctor = allDoctor.find(doctor => (doctor.userNo === item.doctorNo))
                        doctorName = !objectIsEmpty(findedDoctor)?findedDoctor.userName : ""
                    }
                    else {
                        doctorName = item.doctorName
                    }
                    
                    if(stringIsEmpty(item.divName)) {
                        const findedDiv = allDept.find(div => (div.divNo === item.divNo))
                        divName = !objectIsEmpty(findedDiv)?findedDiv.divName : ""
                    }
                    else {
                        divName = item.divName
                    }
                    
                    return { ...item, 
                            key: index,
                            //response資料沒有這3個。透過id來查找
                            apnName: stringIsEmpty(item.apnName)?parseApnToString(item.apn):item.apnName,
                            doctorName: doctorName,
                            divName: divName,

                            //加上勾選狀態
                            checked:false
                        }
                }) : []

                setFilteredDataList(dataWithKeys)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            }
            else {

            }
        })

    }

    /**
     * 取得進階搜尋清單
     */
    const getAdvanceSearchData = () => {
        const tmpArray = 
            arrayIsEmpty(filteredDataList) ? [] :
            filteredDataList.map((item) => (
            {
                //key
                key : item.key, //這個不要過濾，不在顯示欄位內
                checked: item.checked, //這個不要過濾，用來判斷勾選狀態

                //只copy要進階搜尋的欄位
                //門診日期
                encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                //時段
                apnName: item.apnName,
                //科別
                divName: item.divName,
                //問診醫師
                doctorName: item.doctorName,
                //診間
                clinicNo: item.clinicNo,
                //診室名稱
                clinicName: item.clinicName
            })
        )
        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advancedSearchText, ["key", "checked"])
        setAdvSearchDataList(findedData) 

        if(!objectIsEmpty(currentData)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData) ? false : findedData.some(item => item.key === currentData.key)
            if(!haveSelected) {
                //不存在的話將selected設為null
                setCurrentData(null)
            }
        }

        //判斷是否全勾選
        const allChecked = arrayIsEmpty(findedData) ? false : findedData.every(item => item.checked === true)
        setCheckedAll(allChecked)
    }

    /**
     * 處理醫師搜尋文字框焦點事件
     * @return {void}
     */
    const handleToggleDoctorFilterOptions = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorFilterOptions(true)
    }
    
    /**
     * 處理科別搜尋文字框焦點事件
     * @return {void}
     */
    const handleToggleDepartmentFilterOptions = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentFilterOptions(true)
    }

    /**
     * 搜尋條件改變時
     * @event event {Event}
     */
    const handleAdvancedSearchOnInput = (event) => {
        //搜尋文字存在
        if(event.target.value) {
            setAdvancedSearchText(event.target.value)
        } else {//搜尋文字不存在
            setAdvancedSearchText('')
        }
    }

    /**
     * 醫生輸入搜尋條件改變時
     * @event {Event} event 
     */
    const handleDoctorSearchOnInput = (event) => {
       //取得值
       const value = event.target.value
       // 設定醫師選項
       setDisplayQueryInputData({
           ...displayQueryInputData, 
           doctorText: value
       })
    }

    /**
     * 科別輸入搜尋條件改變時
     * @event {Event} event 
     */
    const handleDepartmentSearchOnInput = (event) => {
       //取得值
       const value = event.target.value
       // 設定科別選項
       setDisplayQueryInputData({
           ...displayQueryInputData, 
           divText: value
       })
    }

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.userNo
            // 醫師名稱
            const name = option.userName
            // 設定醫師選項
            setDisplayQueryInputData({
                ...displayQueryInputData,
                doctorText: `${no} ${name}`,
            })
        }

        setShowDoctorFilterOptions(false)
    }

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDivptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo
            // 科別名稱
            const name = option.divName
            // 設定科別選項
            setDisplayQueryInputData({
                ...displayQueryInputData,
                divText: `${no} ${name}`,
            })
        }

        setShowDepartmentFilterOptions(false)
    }

    /**
     * 開始時間輸入欄位變更事件
     */
    const handleDateStartOnChange = (e) => {
        //setStartDate(e.target.value)
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: e.target.value
        })
    }

    /**
     * 結束時間輸入欄位變更事件
     */
    const handleDateEndOnChange = (e) => {
        //setEndDate(e.target.value)
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateEnd: e.target.value
        })
    }

    /**
     * 勾選全部的Checkbox 變更事件
     */
    const handleBatchedUpdateSelectAll = () => {
        const isChecked = !checkedAll
        setCheckedAll(isChecked)

        const updateData = filteredDataList.map(item => {
            // 在advSearchDataList中查找匹配的 key
            const matchedItem = advSearchDataList.find(advItem => advItem.key === item.key);
            // 如果找到匹配的 key，則更新 checked
            if (matchedItem) {
                return {...item, checked: isChecked};
            }
            return item; // 否則保持原樣
        })
        setFilteredDataList(updateData)
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        searchSchedule(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        searchSchedule(queryInputData, 1, e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        searchSchedule(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        searchSchedule(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 勾選批次異動項目
     */
    const handleBatchedUpdateSelection = (key) => {
        const updateData = filteredDataList.map(item =>
            item.key === key ? {...item, checked: !item.checked} : item
        )
        setFilteredDataList(prev => updateData)
        
        //檢查每一筆是否checked都為true
        setCheckedAll(updateData.every(item => item.checked))
    }

    /**
     * 儲存批次更新事件
     * @param {Object} scheduleInfo 要儲存的班表資訊
     */
    const handleSaveBatchUpdate = (scheduleInfo) => {
        
        const update = selectedTableData.map((item) =>{
            const selectedData = filteredDataList.find(data => (data.key === item.key))
            return {
                //診間key
                "clinicId": selectedData.clinicId,
                //就醫日期 
                "encounterDate": selectedData.encounterDate,
                //時段:1.早,2.午,3.晚
                "apn": selectedData.apn,
                //醫師代號 
                "doctorNo": selectedData.doctorNo,
                //診室名稱 
                "clinicName": selectedData.clinicName,
                //診間(診室號)
                "clinicNo": selectedData.clinicNo,
                //科別代號
                "divNo": selectedData.divNo,
                //預約最大看診人次 
                "limit": scheduleInfo.limit !== null ? scheduleInfo.limit : selectedData.limit,
                //現場最大看診人次
                "onsiteLimit": scheduleInfo.onsiteLimit !== null ? parseInt(scheduleInfo.onsiteLimit) : selectedData.onsiteLimit,
                //看診時間(分/人) 
                "slotMin": scheduleInfo.slotMin !== null ? scheduleInfo.slotMin : selectedData.slotMin,
                //預約方式:1.診號,2.時間 
                "giveNumType": scheduleInfo.giveNumType !== null ? scheduleInfo.giveNumType : selectedData.giveNumType,
                //給號代碼 
                "noType": selectedData.noType,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE 
                "sentChartFlag": selectedData.sentChartFlag,
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人) 
                "reserveLimitType": scheduleInfo.reserveLimitType !== null ? scheduleInfo.reserveLimitType : selectedData.reserveLimitType,
                //診間類別  O:門診,T:外檢,P,預防保健
                "clinicType": selectedData.clinicType,
                ////診間收費別  
                "cashType": scheduleInfo.cashType !== null ? scheduleInfo.cashType : selectedData.cashType,
                ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE 
                "webapptFlag": scheduleInfo.webapptFlag !== null ? scheduleInfo.webapptFlag : selectedData.webapptFlag, 
                //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE 
                "showProcessFlag": scheduleInfo.showProcessFlag  !== null ? scheduleInfo.showProcessFlag : selectedData.showProcessFlag,
                //網掛預約期限(天) 
                "webapptDay": scheduleInfo.webapptDay !== null ? scheduleInfo.webapptDay : selectedData.webapptDay, 
                //週班注意事項 
                "weekNotice": scheduleInfo.weekNotice  !== null ? scheduleInfo.weekNotice : selectedData.weekNotice,
                //看診項目 
                "clinicMarkNo": selectedData.clinicMarkNo,
                //假日展班, 0.FALSE , 1.TRUE 
                "holidayexclFlag": selectedData.holidayexclFlag,
                //代班醫師
                "locumDoctor": scheduleInfo.locumDoctor !== null ? scheduleInfo.locumDoctor : selectedData.locumDoctor,
                //停診
                "stopClinicFlag": scheduleInfo.stopClinicFlag !== null ? scheduleInfo.stopClinicFlag : selectedData.stopClinicFlag,
                //停診原因
                "stopClinicReason": scheduleInfo.stopClinicReason  !== null ? scheduleInfo.stopClinicReason : selectedData.stopClinicReason,
                //停代診公告
                "stopClinicBulletinFlag": scheduleInfo.stopClinicBulletinFlag !== null ? scheduleInfo.stopClinicBulletinFlag : selectedData.stopClinicBulletinFlag,
                //診間標語 
                "clinicSlogan": scheduleInfo.clinicSlogan  !== null ? scheduleInfo.clinicSlogan : selectedData.clinicSlogan,
                //nurse1-7無用，傳null
                "nurse1": selectedData.nurse1,
                "nurse2": selectedData.nurse2,
                "nurse3": selectedData.nurse3,
                "nurse4": selectedData.nurse4,
                "nurse5": selectedData.nurse5,
                "nurse6": selectedData.nurse6,
                "nurse7": selectedData.nurse7,
                //診室地點
                "clinicLocation": scheduleInfo.clinicLocation !== null ? scheduleInfo.clinicLocation : selectedData.clinicLocation,
                "createDatetime": selectedData.createDatetime,
                "createUser": selectedData.createUser,
                "modifyDatetime": null,
                "modifyUser": null,
                "lockVersion": selectedData.lockVersion,
                "weekId": selectedData.weekId
            }
        })

        regsEditClinicList(
            update
        ).then(res => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //儲存成功
                showToast({message:"存檔成功", type:ToastTypeEnum.Success})

                //更新
                searchSchedule(queryInputData, 1, paginationProps.pageSize)
            }
            else {
                //儲存失敗
                const msg = `存檔失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 檢查資料是否被選擇
     * @param {Object} data 
     * @returns 
     */
    const checkDataIsSelected = (data) => {
        if(objectIsEmpty(currentData)) {
            return false
        }
        if(currentData.key === data.key) {
            return true
        }
        return false
    }

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const searchText = displayQueryInputData.divText
        //搜尋文字為空時
        if (searchText === '') {
            return allDept
        }
        else {
            return allDept.filter(div => {
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                return hasSpace ?
                    (div.divNo.includes(searchText.split(' ')[0]) && div.divName.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (div.divNo.includes(searchText) || div.divName.includes(searchText))
            })
        }
    }

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const searchText = displayQueryInputData.doctorText
        //搜尋文字為空時
        if (searchText === '') {
            return allDoctor
        }
        else {
            return allDoctor.filter(doctor => {
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                return hasSpace ?
                    (doctor.userNo.includes(searchText.split(' ')[0]) && doctor.userName.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
            })
        }
    }

    const getTableDoctorFieldContent = (scheduleData) => {
        if(arrayIsEmpty(filteredDataList)) {
            return ''
        }
        const fundedData = filteredDataList.find(item => item.key === scheduleData.key)
        if(objectIsEmpty(fundedData)) {
            return ''
        }
        let doctorFieldContent = ''
        if(!stringIsEmpty(fundedData.locumDoctor)) {
            //代班醫生號碼有值
            const locumDoctor = allDoctor.find(doctor => doctor.userNo === fundedData.locumDoctor)
            doctorFieldContent = `${locumDoctor.userName}[代]`
        }
        else {
            doctorFieldContent = scheduleData.doctorName
            if(fundedData.stopClinicFlag !== null && fundedData.stopClinicFlag) {
                doctorFieldContent = `${doctorFieldContent}[停]`
            }
        }
        return doctorFieldContent
    }

    //第一次執行時先取得到資料
    useEffect(() => {
        //取得週班中查詢的相關參數初始值
        regsMonthClinicBaseData({}).then(res => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success && infoData === null) {
                //取得資料
                const data = res.data
                //設定資料
                setInfoData(data)
                //預設將科別設定在下拉選單
                setAllDept(data.divisionList)
                setAllDoctor(data.doctorList)
                //預設將醫生給予ListCard選項
                // setListCardOptions(data.doctorList)
            }
        })
    }, [])

    /** 
     * 監聽advancedSearchText&filteredDataList的變化
    */
    useEffect(() => {
        //取得進階查詢的資料
       
        if(filteredDataList !== null) {
            getAdvanceSearchData()
        } else {
            setCurrentData(null)
            setCheckedAll(false)
        }
    }, [advancedSearchText, filteredDataList])

    /**
     * 監聽advSearchDataList 變化
     */
    useEffect(() => {
        if(!arrayIsEmpty(advSearchDataList)) {
            const selected = advSearchDataList.filter(obj => obj.checked === true)
            setSelectedTableData(selected)
        }
        else {
            setSelectedTableData([])
        }
    }, [advSearchDataList])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [displayQueryInputData.doctorText])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.divText])

  return (
    <div className='w-full'>
        {/*頂部區塊*/}
        <div className='flex flex-row flex-wrap justify-start items-center w-full gap-4 px-4 py-2'>
            {/*日期*/}
            <div className='flex flex-row justify-center items-center text-base gap-1'>
                <p className=' text-base font-normal mr-1'>{t('Regs.general.dateStartAndEnd')}：</p>
                <div className=''>
                <BaseInput 
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    value={displayQueryInputData.dateStart} 
                    type={InputTypeEnum.Date} 
                    inputMode="date"
                    onChange={handleDateStartOnChange}/>
                </div>
                <p className="m-1">~</p>
                <BaseInput 
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    value={displayQueryInputData.dateEnd} 
                    type={InputTypeEnum.Date} 
                    inputMode="date"
                    onChange={handleDateEndOnChange}/>
            </div>
            {/*醫師*/}
            <div className='flex flex-row justify-center items-center text-base font-normal'>
                {t('general.doctor')}：
                <div 
                    ref={dropdownDoctorRef} 
                    className="flex flex-row items-center relative w-[170px]">
                    <input 
                        value={displayQueryInputData.doctorText}
                        onChange={(e) => handleDoctorSearchOnInput(e)}
                        className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                        onFocus={handleToggleDoctorFilterOptions}/>
                    {showDoctorFilterOptions && !arrayIsEmpty(doctorOptionList) && (
                        /*搜尋或下拉選單*/
                        <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                {
                                    doctorOptionList.map((option, index) => (
                                        <div className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                key={index} role="button"
                                                onClick={() => handleDoctorOptionOnClick(option)}>
                                            {
                                                // 醫生編號 醫生
                                                `${option.userNo} ${option.userName}`
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/*科別*/}
            <div className='flex flex-row justify-center items-center text-base font-normal'>
                {t('general.department')}：
                <div 
                    ref={dropdownDepartmentRef}
                    className="flex flex-row items-center relative w-[170px]">
                    <input 
                        value={displayQueryInputData.divText}
                        type="text"
                        onChange={(e) => handleDepartmentSearchOnInput(e)}
                        className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                        onFocus={handleToggleDepartmentFilterOptions}/>
                    {showDepartmentFilterOptions && !arrayIsEmpty(departmentOptionList) && (
                        /*搜尋或下拉選單*/
                        <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                {
                                    departmentOptionList.map((option, index) => (
                                        <div className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                key={index} role="button"
                                                onClick={() => handleDivptionOnClick(option)}>
                                            {
                                                // 科別編號 科別
                                                `${option.divNo} ${option.divName}`
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/*時段*/}
            <div className='flex flex-row justify-center items-center gap-[12px] text-base font-normal'>
                {t('Regs.general.timeSlot')}：
                {
                    apnList.map((apn, index) => (
                        <RadioButton 
                            key={index} 
                            mode={displayQueryInputData.apn}
                            modeChecked={apn.justnameNo} 
                            handleModeChange={handleApnModeOnChange}
                            label={apn.justnameName} 
                            value={apn.justnameNo} 
                            name="scheduleBatchingUpdateSearch" />
                    ))
                }
            </div>
            {/*查詢按鈕*/}
            <DefaultButton type="primary" onClickFn={handleSearchSubmit} text="查詢" />
        </div>

        {/*班表清單內容區塊*/}
        {isSearch && 
        <div className='flex flex-row justify-center w-full  gap-[12px] px-4 py-2 '>
            {/*班表清單內容*/}
            <div className='w-full gap-[12px] border bg-white rounded-md border-gray-300'>
                <div>
                    {/*班表清單標題、進階搜尋*/}
                    <div className='flex flex-row justify-start items-center w-full min-w-[822px] px-4 py-[10px] text-start'>
                        <h3 className="flex items-center text-xl mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">{t('Schedule.scheduleList')}</h3>
                        <BaseInput 
                            className="w-[320px] h-10 pl-4 border border-[#D4D4D8] rounded-[6px]"
                            type="text" 
                            placeholder={t('general.advancedSearch')} 
                            inputMode={'search'}
                            value={advancedSearchText}
                            onChange={(e) => handleAdvancedSearchOnInput(e)}/>
                    </div>
                    {/*清單表格欄位名稱*/}
                    <div className='w-full py-2 pl-2 pr-4'>
                        <ul className="tableList batchUpdateTableItem max-h-[calc(100vh-285px)] w-full overflow-x-scroll overflow-y-auto">
                            <li className="bg-[#E4E4E7] flex flex-row title">
                                <div>
                                    <input
                                        className="form-checkbox text-green-500 h-6 w-6 rounded border-gray-300 focus:ring-green-500"
                                        type="checkbox" 
                                        style={{accentColor: "#38A169"}}
                                        checked={checkedAll}
                                        onChange={handleBatchedUpdateSelectAll}
                                    />
                                        <span className="checkmark"></span>
                                </div>
                                <div >{t("Regs.general.clinicDate")}</div>
                                <div >{t("Regs.general.timeSlot")}</div>
                                <div >{t('general.department')}</div>
                                <div >{t("Regs.general.attendingPhysician")}</div>
                                <div >{t("Regs.general.clinic")}</div>
                                <div >{t("Regs.general.clinicName")}</div>
                            </li>
                            {/*顯示清單資料*/}
                            { !arrayIsEmpty(advSearchDataList) && advSearchDataList.map((item) => (
                                <li 
                                    key={item.key}
                                    className={`flex flex-row ${checkDataIsSelected(item) ? "selected" : ""}`}
                                    onClick={()=>handleListOnSelect(item.key)}>
                                    <div>
                                        <input
                                            className="form-checkbox text-green-500 h-6 w-6 rounded border-gray-300 focus:ring-green-500"
                                            type="checkbox"
                                            style={{accentColor: "#38A169"}}
                                            checked={item.checked}
                                            onChange={() => handleBatchedUpdateSelection(item.key)}
                                        />
                                        <span className="checkmark"></span>
                                    </div>
                                        <div>{item.encounterDate}</div>
                                        <div>{item.apnName}</div>
                                        <div>{item.divName}</div>
                                        <div>{getTableDoctorFieldContent(item)}</div>
                                        <div>{item.clinicNo}</div>
                                        <div>{item.clinicName}</div>
                                </li>
                                )) 
                            }
                        </ul>
                        <div className="flex justify-end w-full mt-2">
                            <Pagination totalPageSize={paginationProps.totalPageSize} 
                                        currentPage={paginationProps.currentPage} 
                                        pageSize={paginationProps.pageSize}
                                        totalSize={paginationProps.totalItemSize}
                                        onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                        onPrevPageOnClick={onPaginationPreviousOnClick}
                                        onNextPageOnClick={onPaginationNextOnClick}
                                        onPageSizeChange={onPaginationPageSizeOnChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*更新清單表格內文*/}
            <div className='w-full flex border rounded-md border-gray-300 max-h-[calc(100vh-180px)] overflow-y-auto overflow-x-scroll'>
            {   
                <ScheduleBatchesUpdateForm 
                    previewData={currentData}
                    baseData={infoData}
                    selectableData={selectedTableData}
                    onSave={handleSaveBatchUpdate}/>
            }
            </div>
        </div>
        }
    </div>
  )
}

export default ScheduleBatchesUpdate