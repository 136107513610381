import React, {useEffect, useState} from "react"
import {BasePopup} from "../../Popup/BasePopup"
import {SearchInput} from "../../Input/SearchInput"
import {t} from "i18next"
import {Pagination} from "../../Pagination/Pagination"
import { queryPatientByField } from "../../../api/v1/Mris"
import {DefaultButton} from "../../../components/Button/DefaultButton"
import {ApiErrorStatusEnum, arrayIsEmpty, time2String, stringIsEmpty, objectIsEmpty} from "edah_utils/dist"

/**
 *  病人病歷資料彈出視窗
 *  @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 *  @param show {Boolean} 是否顯示彈窗
 *  @param choicePatientDate {Function} 選擇病人病歷事件
 *  @param ptSearchOptionList {Array} 查詢的欄位清單
 *  @param importFindedData {Object} 傳入找到的資料
 *  @return {JSX.Element} 病人病歷資料彈出視窗
 */
export const PatientMedicalRecordsPopup = ({
    closePopupButtonOnClick, 
    show = false, 
    choicePatientDate,
    ptSearchOptionList,
    importFindedData
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 搜尋文字狀態
     */
    const [advancedSearchText, setAdvancedSearchText] = useState('')

    /**
     * 病人們資料
     */
    const [arrayData, setArrayData] = useState([])
    // 病人資訊清單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([])
    //查詢欄位資料(已查詢後)
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: '',
        //查詢欄位
        field: ''
    })
    //查詢欄位資料
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: '',
        field: ''
    })

    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })

    /**
     * 搜尋按鈕點擊事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchButtonOnClick = (e) => {
        // 防止表單提交
        e.preventDefault()

        //搜尋Patient
        queryPatient()
    }

    /**
     * 搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputChange = (e) =>{ 
        setQueryInputTmpData({
            ...queryInputTmpData,
            text:e.target.value
        })
    }

    /**
     * 搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            //搜尋Patient
            if(stringIsEmpty(queryInputTmpData.text))
                return

            queryPatient(queryInputTmpData ,1, paginationProps.pageSize)
        }
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        queryPatient(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        queryPatient(queryInputData, 1, e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        queryPatient(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        queryPatient(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 選擇病人病歷
     * @param patient {Object} 病人病歷資訊
     */
    const handlePatientItemOnClick = (patient) => {
        choicePatientDate(patient)
    }

    /**
     * 欄位下拉選單改變時
     * @param e {Event} 事件
     */
    const handleFieldOnChange = (e) =>{ 
        setQueryInputTmpData({
            ...queryInputTmpData,
            field:e.target.value
        })
    }

    /**
     * 查詢Patient
     */
    const queryPatient = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        queryPatientByField({
            search: queryInputObj.text,
            field: queryInputObj.field,
            pageNum: page,
            pageSize: pageSize
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setArrayData(res.data.dataList)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                setArrayData([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        })
    }

    const handleSearchOnClick = () => {
        if(stringIsEmpty(queryInputTmpData.text))
            return
        queryPatient(queryInputTmpData ,1, paginationProps.pageSize)
    }

    /**
     * 取得病人進階搜尋後的結果
     * @returns 
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(advancedSearchText)) {
            return arrayData
        }
        else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = advancedSearchText.toLowerCase()
            return arrayData.filter(data => {
                    return (data.patientId !== null && data.patientId.toString().toLowerCase().includes(lowerCaseSearchText)) || 
                            (data.patientName !== null && data.patientName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.idNo !== null && data.idNo.toString().toLowerCase().includes(lowerCaseSearchText)) || 
                            (data.birthDate !== null && time2String(data.birthDate, "YYYYMMDD").includes(lowerCaseSearchText))
                }
            )
        }
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="filter flex flex-row items-center justify-start px-6 mb-[22px]">
                <select 
                    className="w-[110px] h-10 pl-3 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                    onChange={handleFieldOnChange}
                    value={queryInputTmpData.field}
                >
                    {
                        ptSearchOptionList && ptSearchOptionList.map((item) => (
                            <option 
                                key={item.justnameNo}
                                value={item.justnameNo}
                            >
                                {item.justnameName}
                            </option>
                        ))
                    }
                </select>
                {/*搜尋文字*/}
                <SearchInput 
                    searchText={queryInputTmpData.text} 
                    onChange={(e) => onSearchTextOnInputChange(e)}
                    onKeyDown={(e) => onSearchTextOnInputKeyDown(e)}/>
                <DefaultButton 
                    type="primary" 
                    text={t('general.query')}
                    onClickFn={()=>handleSearchOnClick()}/>
            </div>
            <div className="bg-white p-2 shadow rounded border-2 m-2">
                {/*進階搜尋*/}
                <SearchInput searchText={advancedSearchText} placeholder={t('general.advancedSearch')}
                             onChange={(e) => onAdvancedSearchTextOnInputChange(e)}/>
                {/*表單*/}
                <table className="min-w-full leading-normal mt-1.5 mb-1.5">
                    <thead>
                        <tr className="bg-[#e4e4e7] text-lg text-[#18181b]">
                            <th className="px-4 py-3 font-normal border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                {t('Regs.general.medicalRecordNumber')}
                            </th>
                            <th className="px-4 py-3 font-normal border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                {t('general.username')}
                            </th>
                            <th className="px-4 py-3 font-normal border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                {t('general.dateOfBirth')}
                            </th>
                            <th className="px-4 py-3 font-normal border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                {t('general.idNumber')}
                            </th>
                            <th className="px-4 py-3 font-normal border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                手機號
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        !arrayIsEmpty(advSearchArrayData) && advSearchArrayData.map((data, index) => (
                            <tr 
                                key={index}
                                onClick={() => handlePatientItemOnClick(data)}
                                className={`${index % 2 === 0 ? '':'bg-[#f4f4f5]'} text-base text-[#18181b] cursor-pointer hover:bg-[#BEE3F8]`}>
                                <td className="px-4 py-3 border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                    {data.patientId}
                                </td>
                                <td className="px-4 py-3 border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                    {data.patientName}
                                </td>
                                <td className="px-4 py-3 border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                    {data.birthDate === null ? "" : time2String(data.birthDate, "YYYY-MM-DD")}
                                </td>
                                <td className="px-4 py-3 border-r border-[#111111]/15 text-left uppercase tracking-wider">
                                    {data.idNo}
                                </td>
                                <td className="px-4 py-3 border-r border-[#111111]/15 text-left uppercase tracking-wider">

                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={paginationProps.totalPageSize} 
                                currentPage={paginationProps.currentPage} 
                                pageSize={paginationProps.pageSize}
                                totalSize={paginationProps.totalItemSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPrevPageOnClick={onPaginationPreviousOnClick}
                                onNextPageOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
                
            </div>
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 顯示彈窗時重置頁碼
        if (!show) {
            // 重置查詢欄位
            if(!arrayIsEmpty(ptSearchOptionList))
            {
                setQueryInputTmpData({
                    ...queryInputTmpData,
                    field:ptSearchOptionList[0].justnameNo
                })
                setQueryInputData({
                    ...queryInputData,
                    field:ptSearchOptionList[0].justnameNo
                })
            }    
            // 重置查詢文字
            setQueryInputTmpData({
                ...queryInputTmpData,
                text:''
            })
            setQueryInputData({
                ...queryInputData,
                text:''
            })
        }
        else {
            //重置進階搜尋文字
            setAdvancedSearchText('')
            setPaginationProps({
                ...paginationProps,
                currentPage: 1,
                totalItemSize: 0,
                totalPageSize: 0,
            })
            setQueryInputData({
                
            })
        }
        setShowPopup(show)
    }, [show])

    /**
     * 監聽ptSearchOptionList 變化時
     */
    useEffect(() => {
        if(!arrayIsEmpty(ptSearchOptionList))
        {
            setQueryInputTmpData({
                ...queryInputTmpData,
                field:ptSearchOptionList[0].justnameNo
            })
        }
    }, [ptSearchOptionList])

    /**
     * 監聽 importFindedData Pros變化
     */
    useEffect(() => {

        if(objectIsEmpty(importFindedData)) {
            setArrayData([])
        }
        else {
            if(arrayIsEmpty(importFindedData.dataList)) {
                setArrayData([])
            }
            else {
                setArrayData(importFindedData.dataList)

                //設定分頁狀態
                setPaginationProps({
                    //當前頁碼
                    currentPage: importFindedData.currentPage,
                    //每頁資料筆數
                    pageSize: importFindedData.pageSize,
                    //總資料筆數
                    totalItemSize: importFindedData.totalItemSize,
                    //總頁碼
                    totalPageSize: importFindedData.totalPageSize
                })

                //用於顯示的欄位值
                setQueryInputTmpData({
                    //搜尋文字
                    text: importFindedData.searchText,
                    //查詢欄位
                    field: 'ALL'
                })

                //用於紀錄真正查詢的欄位值
                setQueryInputData({
                    //搜尋文字
                    text: importFindedData.searchText,
                    //查詢欄位
                    field: 'ALL'
                })
            }
        }
        
    }, [importFindedData])

    /**
     * 監聽arrayData & advancedSearchText變化
     */
    useEffect(() => {
        if(arrayData !== null) {
            const list = getAdvSearchPatientList()
            setAdvSearchArrayData(list)
        }
        else {
            setAdvSearchArrayData(null)
        }
        
    }, [arrayData, advancedSearchText])


    return showPopup ?
        <BasePopup 
            title={"病人病歷資料"} 
            content={getContent()} 
            width={"80%"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}
