import React, { useState, useEffect, useMemo } from 'react'
import RadioButton from '../../Input/RadioButton'
import Button from '../../Button/Button'
import { BaseInput } from '../../Input/BaseInput'
import { objectIsEmpty, time2String, InputTypeEnum } from 'edah_utils/dist'
import ExtendBtnTextArea from '../../Input/ExtendBtnTextArea'
import ReferralHospitalModal from '../ReferralHospitalModal'
import DivisionDatalist from '../DivisionDatalist'
import { DATE_FORMAT } from '../../../constants/common'
import { t } from 'i18next'
const InputStyle =
    'w-[200px] h-10  px-2 text-black rounded-[6px] border border-gray-300'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}
const Layout = ({ children }) => {
    return (
        <div className="p-8 w-full h-[calc(100vh-98px)] overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md">
            {children}
        </div>
    )
}

// 用於區別轉入院所/處理情形:院所查詢
const REFERAL_HOSP_MODAL_MODE = {
    hospIn: 'hospIn', // 轉入院所
    hospOut: 'hospOut', // 處理情形:院所查詢
}

// 處理情形每個選項的類別，用於判斷該選項顯示病房下拉選單/院所查詢/科別查詢
const InHadleStatusType = {
    HOSPITAL: 'HOSPITAL', // 已予急診處置並移至{醫院}
    INP: 'INP', // 已安排本院病房治療中
    ER_TO_INP: 'ER_TO_INP', // 已予急診處置並住本院病房治療中
    DEPARTMENT: 'DEPARTMENT', // 已安排本院病{科別}門診治療
    TURN_BACK: 'TURN_BACK', // 已予適當處理並轉回原院所
    OTHER: 'OTHER', // 其他
    ER_OBS: 'ER_OBS', // 急診留觀(緊急傷病患)
    OHCA: 'OHCA', // 到院前心跳停止(緊急傷病患)
    ICU: 'ICU', // 加護病房治療(緊急傷病患)
}

// 處理情形選項
const InHandleStatusOptions = [
    {
        label: '已予急診處置並移至',
        subLabel: '',
        selectorType: InHadleStatusType.HOSPITAL,
        isHasInput: false,
        value: '1',
    },
    {
        label: '已予急診處置並住本院',
        subLabel: '病房治療中',
        selectorType: InHadleStatusType.ER_TO_INP,
        isHasInput: false,
        value: '2',
    },
    {
        label: '已安排本院',
        subLabel: '病房治療中',
        selectorType: InHadleStatusType.INP,
        isHasInput: false,
        value: '3',
    },
    {
        label: '已安排本院',
        subLabel: '科門診治療',
        selectorType: InHadleStatusType.DEPARTMENT,
        isHasInput: false,
        value: '4',
    },
    {
        label: '已予適當處理並轉回原院所',
        subLabel: '',
        selectorType: InHadleStatusType.TURN_BACK,
        isHasInput: false,
        value: '5',
    },
    {
        label: '其他',
        subLabel: '',
        selectorType: InHadleStatusType.OTHER,
        isHasInput: true,
        value: '6',
    },
    {
        label: '急診留觀(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.ER_OBS,
        isHasInput: false,
        value: '7',
    },
    {
        label: '到院前心跳停止(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.OHCA,
        isHasInput: false,
        value: '8',
    },
    {
        label: '加護病房治療(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.ICU,
        isHasInput: false,
        value: '9',
    },
]

// TODO:病房下拉選單
const inpList = [
    {
        label: '病房',
    },
]

/**
 * 回覆單
 * @param {object} tranInInfo 回覆單資料
 * @param {array} tranFindingList 轉診結果下拉選單
 * @param {array} divisionList 科別清單
 * @param {funciton} onUpdateData 更新輸入框資料
 * @param {array} zipCodeList 郵遞區號下拉選單
 * @returns {Element}
 * @constructor
 */
const ReferralTurnInModal = ({
    tranInInfo,
    tranFindingList,
    divisionList,
    onUpdateData,
    zipCodeList = [],
}) => {
    // 回覆單號
    const [tranNo, setTranNo] = useState('')
    // 註銷
    const [cancelFlag, setCancelFlag] = useState(true)
    // 轉入院所代號
    const [hospNoIn, setHospNoIn] = useState('')
    // 轉入院所名稱
    const [hospNameIn, setHospNameIn] = useState('')
    // 轉診結果
    const [tranFinding, setTranFinding] = useState('')
    // 轉出日期
    const [tranDate, setTranDate] = useState('')
    // 藥物過敏
    const [allergy, setAllergy] = useState('')
    // 建議事項
    const [advise, setAdvise] = useState('')
    // 處理情形選項後帶有輸入框的欄位
    const [inHandleStatusRemarkMap, setInHandleStatusRemarkMap] = useState({
        inHandleStatusHospRemark: '', // 已予急診處置並移至{醫院} - 醫院代碼
        inHandleStatusErInpRemark: '', // 已予急診處置並住本院病房治療中
        inHandleStatusInpRemark: '', // 已安排本院病房治療中
        inHandleStatusDivRemark: '', // 已安排本院病{科別}門診治療 - 科別帶碼
        inHandleStatusOtherRemark: '', // 其他
        inHandleStatusHospRemarkName: '', // 已予急診處置並移至{醫院} - 醫院名稱
        inHandleStatusDivRemarkName: '', // 已安排本院病{科別}門診治療 - 科別名稱
    })
    // 處理情形remark(依照inHandleStatus的value去填入inHandleStatusRemarkMap內的remark)
    const [inHandleStatusRemark, setInHandleStatusRemark] = useState('')

    // 處理情形
    const [inHandleStatus, setInHandleStatus] = useState(
        InHandleStatusOptions[0].value
    )

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    /**
     * 會開啟轉診院所查詢視窗的欄位分別有2個
     * 1. 轉入院所:REFERAL_HOSP_MODAL_MODE.hospIn
     * 3. 轉出院所: REFERAL_HOSP_MODAL_MODE.hospOut
     */
    const [referralHospitalMode, setReferralHospitalModalMode] = useState('')

    // 更新是否註銷
    const handleCancelFlagChange = (e) => setCancelFlag(e.target.checked)

    // 更新轉出原因
    const handleTranFindingChange = (e) => setTranFinding(e.target.value)

    // 更新轉出日期
    const handleTranDateChange = (e) => setTranDate(e.target.value)

    // 更新藥物過敏
    const handleAllergy = (e) => setAllergy(e.target.value)

    // 建議事項
    const handleAdvise = (e) => setAdvise(e.target.value)

    // 處理情形
    const handleInHandleStatus = (value) => {
        setInHandleStatus(value)
    }

    /**
     * 處理情形 - 院所查詢
     * @returns {JSX.Element}
     */
    const RenderHospital = useMemo(() => {
        return (
            <div className="flex">
                <div className="flex items-center justify-end">
                    <Button
                        classNames={`${SecondButtonStyle} mx-2`}
                        text={'院所查詢'}
                        onClickFn={() =>
                            showReferralHospital(
                                REFERAL_HOSP_MODAL_MODE.hospOut
                            )
                        }
                    />
                </div>
                <div className="mr-2">
                    <BaseInput
                        className="h-10 text-black rounded-[6px] border border-gray-300 w-[100px]"
                        type={InputTypeEnum.Text}
                        value={inHandleStatusRemarkMap.inHandleStatusHospRemark}
                        disable
                    />
                </div>
                <div>
                    <BaseInput
                        className={`${InputStyle} w-[120px]`}
                        type={InputTypeEnum.Text}
                        value={
                            inHandleStatusRemarkMap.inHandleStatusHospRemarkName
                        }
                        disable
                    />
                </div>
            </div>
        )
    }, [
        inHandleStatusRemarkMap.inHandleStatusHospRemark,
        inHandleStatusRemarkMap.inHandleStatusHospRemarkName,
    ])

    /**
     * 開啟院所查詢視窗
     */
    const showReferralHospital = (mode) => {
        setIsShowReferralHospitalModal(true)
        setReferralHospitalModalMode(mode)
    }

    /**
     * 選擇院所
     * @return {void}
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        if (referralHospitalMode === REFERAL_HOSP_MODAL_MODE.hospIn) {
            setHospNoIn(hospital.hospNo)
            setHospNameIn(hospital.hospName)
        } else {
            setInHandleStatusRemarkMap({
                ...inHandleStatusRemarkMap,
                inHandleStatusHospRemark: hospital.hospNo,
                inHandleStatusHospRemarkName: hospital.hospName,
            })
        }
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 處理情形選項內的remark
     * @param {event} e
     * @param {string} field
     * @return {void}
     */
    const inHandleStatusRemarkOnChange = (e, field) => {
        setInHandleStatusRemark(e.target.value)
        switch (field) {
            case InHadleStatusType.INP:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusInpRemark: e.target.value,
                }))
                break
            case InHadleStatusType.OTHER:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusOtherRemark: e.target.value,
                }))
                break
            case InHadleStatusType.ER_TO_INP:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusErInpRemark: e.target.value,
                }))
                break
        }
    }

    /**
     * 已安排本院{科別}門診治療
     * @param {object} division
     * @returns
     */
    const onSelectDiv = (division) => {
        if (!division) {
            return
        }
        setInHandleStatusRemarkMap((prev) => {
            return {
                ...prev,
                inHandleStatusDivRemark: division.divNo,
                inHandleStatusDivRemarkName: division.divName,
            }
        })
    }

    // mounting
    useEffect(() => {
        setTranNo(tranInInfo.tranNo || '')
        setCancelFlag(!!tranInInfo.cancelFlag)
        setHospNoIn(tranInInfo.hospNoIn || '')
        setHospNameIn(tranInInfo.hospNameIn || '')
        setTranFinding(tranInInfo.tranFinding || '')
        setTranDate(time2String(tranInInfo.tranDate, DATE_FORMAT) || '')
        setAllergy(tranInInfo.allergy || '')
        setAdvise(tranInInfo.advise || '')
        const remark = tranInInfo.inHandleStatusRemark || ''
        const remarkName = tranInInfo.inHandleStatusRemarkName || ''
        const defaultValue = {
            inHandleStatusHospRemark: '',
            inHandleStatusErInpRemark: '',
            inHandleStatusInpRemark: '',
            inHandleStatusDivRemark: '',
            inHandleStatusOtherRemark: '',
            inHandleStatusHospRemarkName: '',
        }

        setInHandleStatusRemark(remark)
        // inHandleStatus去mapping對應的remark
        switch (tranInInfo.inHandleStatus) {
            case InHandleStatusOptions[0].value: //1.已予急診處置並移至{醫院}
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusHospRemark: remark,
                    inHandleStatusHospRemarkName: remarkName,
                })
                break
            case InHandleStatusOptions[1].value: // 2.已予急診處置並住本院病房
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusErInpRemark: remark,
                })
                break
            case InHandleStatusOptions[2].value: // 3.已安排本院病房治療中
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusInpRemark: remark,
                })
                break
            case InHandleStatusOptions[3].value: // 4.已安排本院{科別}門診
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusDivRemark: remark,
                    inHandleStatusDivRemarkName: remarkName,
                })
                break
            case InHandleStatusOptions[5].value: // 6.其他
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusOtherRemark: remark,
                })
                break
        }
        setInHandleStatus(tranInInfo.inHandleStatus || '')
    }, [tranInInfo])

    // 轉入單資料更新回傳到父層
    useEffect(() => {
        let remark = ''
        let remarkName = ''
        switch (inHandleStatus) {
            case '1': //已予急診處置並移至{醫院}
                remark = inHandleStatusRemarkMap.inHandleStatusHospRemark
                remarkName =
                    inHandleStatusRemarkMap.inHandleStatusHospRemarkName
                break
            case '2': //已予急診處置並住本院病房
                remark = inHandleStatusRemarkMap.inHandleStatusErInpRemark
                remarkName = ''
                break
            case '3': // 已安排本院病房治療中
                remark = inHandleStatusRemarkMap.inHandleStatusInpRemark
                remarkName = ''
                break
            case '4': // 已安排本院{科別}門診
                remark = inHandleStatusRemarkMap.inHandleStatusDivRemark
                remarkName = inHandleStatusRemarkMap.inHandleStatusDivRemarkName
                break
            case '6': // 其他
                remark = inHandleStatusRemarkMap.inHandleStatusOtherRemark
                remarkName = ''
                break
        }

        onUpdateData &&
            onUpdateData({
                ...tranInInfo,
                lockVersion: tranInInfo.lockVersion,
                encounterId: tranInInfo.encounterId,
                encounterDate: tranInInfo.encounterDate,
                inpOpd: tranInInfo.inpOpd,
                patientId: tranInInfo.patientId,
                contact: tranInInfo.contact,
                contactTel: tranInInfo.contactTel,
                contactAddress: tranInInfo.contactAddress,
                divNo: tranInInfo.divN,
                doctorNo: tranInInfo.doctorNo,
                treatmentCode: tranInInfo.treatmentCode,
                treatmentCode1: tranInInfo.treatmentCode1,
                treatmentCode2: tranInInfo.treatmentCode2,
                subject: tranInInfo.subject,
                clinicInfo: tranInInfo.clinicInfo,
                reasonOther: tranInInfo.reasonOther,
                urgentReplyDatetime: tranInInfo.urgentReplyDatetime,
                orderDatetime: tranInInfo.orderDatetime,
                opdStatus: tranInInfo.opdStatus,
                etranNo: tranInInfo.etranNo,
                tranPurposeIn: tranInInfo.tranPurposeIn,
                tranPurposeRemarkIn: tranInInfo.tranPurposeRemarkIn,
                replyDate: tranInInfo.replyDate,
                inTraninCompleteFlag: tranInInfo.inTraninCompleteFlag,
                inSummaryCompleteFlag: tranInInfo.inSummaryCompleteFlag,
                inTrandataAgreeFlag: tranInInfo.inTrandataAgreeFlag,
                inReceiptDate: tranInInfo.inReceiptDate,
                inSummaryReplyDate: tranInInfo.inSummaryReplyDate,
                inRecoveryDate: tranInInfo.inRecoveryDate,
                inUnprintSummaryReason: tranInInfo.inUnprintSummaryReason,
                inOrderDate: tranInInfo.inOrderDate,
                inReplyDate: tranInInfo.inReplyDate,
                inReplyStatus: tranInInfo.inReplyStatus,
                inTranPaperFlag: tranInInfo.inTranPaperFlag,
                inTranPaperReason: tranInInfo.inTranPaperReason,
                inSpecialReplyDate: tranInInfo.inSpecialReplyDate,
                inCancerFlag: tranInInfo.inCancerFlag,
                inRemark: tranInInfo.inRemark,
                inPickOut: tranInInfo.inPickOut,
                inPickDate: tranInInfo.inPickDate,
                uploadDatetime: tranInInfo.uploadDatetime,
                tranInReceiveDate: tranInInfo.tranInReceiveDate,
                inpEncounterId: tranInInfo.inpEncounterId,
                tranInAcceptFlag: tranInInfo.tranInAcceptFlag,
                notTranBackReason: tranInInfo.notTranBackReason,
                bedNo: tranInInfo.bedNo,
                dischargeDate: tranInInfo.dischargeDate,
                admissionDate: tranInInfo.admissionDate,
                inpDischargeDate: tranInInfo.inpDischargeDate,
                inpBedNo: tranInInfo.inpBedNo,
                inpDivNo: tranInInfo.inpDivNo,
                inpDoctorNo: tranInInfo.inpDoctorNo,
                tranOut: tranInInfo.tranOut,
                advise: advise,
                allergy: allergy,
                tranFinding: tranFinding,
                tranDate: tranDate,
                tranNo: tranNo,
                hospNoIn: hospNoIn,
                cancelFlag: cancelFlag,
                inHandleStatus: inHandleStatus,
                inHandleStatusRemark: remark,
                inHandleStatusRemarkName: remarkName,
            })
    }, [
        advise,
        allergy,
        tranFinding,
        tranDate,
        tranNo,
        hospNoIn,
        cancelFlag,
        inHandleStatus,
        inHandleStatusRemark,
        inHandleStatusRemarkMap.inHandleStatusDivRemark,
        inHandleStatusRemarkMap.inHandleStatusHospRemark,
        inHandleStatusRemarkMap.inHandleStatusErInpRemark,
        inHandleStatusRemarkMap.inHandleStatusInpRemark,
        inHandleStatusRemarkMap.inHandleStatusOtherRemark,
        inHandleStatusRemarkMap.inHandleStatusHospRemarkName,
        inHandleStatusRemarkMap.inHandleStatusDivRemarkName,
    ])

    return (
        <Layout>
            {/* 標題 */}
            <div className="flex items-center">
                <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                <div className="ml-4 text-[#2B6CB0] text-[20px]">回覆單</div>
            </div>
            {/* 回覆單號 */}
            <div className="mt-2 grid grid-cols-[120px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>回覆單號</Title>
                </div>
                <div className="flex items-center">{tranNo}</div>
                <div className={`flex space-x-3 justify-end`}>
                    <input
                        className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                        type="checkbox"
                        style={{ accentColor: '#38A169' }}
                        checked={cancelFlag}
                        onChange={(e) => handleCancelFlagChange(e)}
                    />
                    <label className="text-center leading-[38px]">
                        {t('general.logoff')}
                    </label>
                </div>
            </div>
            {/* 轉入院所 */}
            <div className="mt-2 grid grid-cols-[120px,120px,0.1fr,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉入院所</Title>
                </div>
                <div className="flex items-center justify-end">
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'院所查詢'}
                        onClickFn={() =>
                            showReferralHospital(REFERAL_HOSP_MODAL_MODE.hospIn)
                        }
                    />
                </div>
                <div className="mr-4">
                    <BaseInput
                        className="h-10 text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={hospNoIn}
                        disable
                    />
                </div>
                <div>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={hospNameIn}
                        disable
                    />
                </div>
            </div>
            {/* 轉診結果 */}
            <div className="mt-2 grid grid-cols-[120px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉診結果</Title>
                </div>
                <div className="flex items-center">
                    <select
                        className={SelectStyle}
                        value={tranFinding}
                        onChange={(e) => {
                            handleTranFindingChange(e)
                        }}
                    >
                        <option value=""></option>
                        {tranFindingList.map((item) => (
                            <option
                                key={`result_${item.tranFinding}`}
                                value={item.tranFinding}
                            >
                                {item.tranFindingName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 轉出日期 */}
            <div className="mt-2 grid grid-cols-[120px,220px,100px,220px]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉出日期</Title>
                </div>
                <input
                    className={InputStyle}
                    type="date"
                    value={tranDate}
                    onChange={(e) => handleTranDateChange(e)}
                />
            </div>
            {/* 藥物過敏 */}
            <div className="mt-2 grid grid-cols-[120px,1fr]">
                <div className="flex flex-col items-end ">
                    <Title extendClass={'mr-8'}>
                        <div>藥物過敏</div>
                    </Title>
                </div>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={handleAllergy}
                    value={allergy}
                ></ExtendBtnTextArea>
            </div>
            {/* 建議事項 */}
            <div className="mt-2 grid grid-cols-[120px,1fr]">
                <div className="flex flex-col items-end ">
                    <Title extendClass={'mr-8'}>
                        <div>建議事項</div>
                    </Title>
                </div>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={handleAdvise}
                    value={advise}
                ></ExtendBtnTextArea>
            </div>
            {/* 處理情形 */}
            <div className="mt-2 grid grid-cols-[120px,1fr]">
                <div className="flex items-start justify-end">
                    <Title extendClass="mt-2 mr-8">處理情形</Title>
                </div>
                <div className="ml-1">
                    {InHandleStatusOptions.map((option, index) => (
                        <div
                            className="flex mb-4"
                            key={index}
                        >
                            <RadioButton
                                wrapperClassName={'w-auto'}
                                mode={inHandleStatus}
                                modeChecked={option.value}
                                handleModeChange={handleInHandleStatus}
                                label={option.label}
                                name={option.value}
                            />
                            {/* 已予急診處置並住本院病房治療中  */}
                            {option.selectorType ===
                                InHadleStatusType.HOSPITAL && RenderHospital}
                            {/* 已安排本院病房治療中 */}
                            {option.selectorType === InHadleStatusType.INP && (
                                <select
                                    className={`${SelectStyle} mx-4 w-[90px]`}
                                    value={
                                        inHandleStatusRemarkMap.inHandleStatusInpRemark
                                    }
                                    onChange={(e) =>
                                        inHandleStatusRemarkOnChange(
                                            e,
                                            option.selectorType
                                        )
                                    }
                                >
                                    <option value=""></option>
                                    {inpList.map((item) => (
                                        <option
                                            key={`deal_${item.label}`}
                                            value={item.label}
                                        >
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {/* 已予急診處置並於本院病房治療中 */}
                            {option.selectorType ===
                                InHadleStatusType.ER_TO_INP && (
                                <select
                                    className={`${SelectStyle} mx-4 w-[90px]`}
                                    value={
                                        inHandleStatusRemarkMap.inHandleStatusErInpRemark
                                    }
                                    onChange={(e) =>
                                        inHandleStatusRemarkOnChange(
                                            e,
                                            option.selectorType
                                        )
                                    }
                                >
                                    {inpList.map((item) => (
                                        <option
                                            key={`erinp_${item.label}`}
                                            value={item.label}
                                        >
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {/* 已安排本院科門診治療 */}
                            {option.selectorType ===
                                InHadleStatusType.DEPARTMENT && (
                                <div className="mx-4 w-[180px]">
                                    <DivisionDatalist
                                        divisionList={divisionList}
                                        onSelect={onSelectDiv}
                                        value={{
                                            divNo:
                                                inHandleStatusRemarkMap.inHandleStatusDivRemark ||
                                                '',
                                            divName:
                                                inHandleStatusRemarkMap.inHandleStatusDivRemarkName ||
                                                '',
                                        }}
                                    />
                                </div>
                            )}
                            {/* 其他 */}
                            {option.selectorType ===
                                InHadleStatusType.OTHER && (
                                <BaseInput
                                    className={`${InputStyle} ml-4 w-[100px]`}
                                    type={InputTypeEnum.Text}
                                    value={
                                        inHandleStatusRemarkMap.inHandleStatusOtherRemark
                                    }
                                    onChange={(e) =>
                                        inHandleStatusRemarkOnChange(
                                            e,
                                            InHadleStatusType.OTHER
                                        )
                                    }
                                />
                            )}
                            {option.subLabel && <div>{option.subLabel}</div>}
                        </div>
                    ))}
                </div>
            </div>
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    onConfirm={onSelectHospital}
                    zipCodeList={zipCodeList}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </Layout>
    )
}

export default ReferralTurnInModal
