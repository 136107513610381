//Import Function
import React, {useCallback, useEffect, useState} from 'react'
import {CancelSlotBtn, DelSlotBtn, SaveIconSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    InputTypeEnum,
    multiTermFuzzySearchObjects,
    ToastTypeEnum
} from 'edah_utils/dist'
import {BaseInput} from "../../Input/BaseInput";
import {queryDepartmentByUserNo} from "../../../api/v1/Menu";
import {rsisQueryAgentByUserNo} from "../../../api/v1/RSIS";

/**
 * 病房代理人員維護
 * @param item {Object} 列表組件物件
 * @param index {number} 列表索引
 * @param isSelectAll {boolean} 是否全選
 * @param onSave {function} 儲存事件
 * @param onCancel {function} 取消事件
 * @param onChange {function} 變更事件
 * @return {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                      onSave,
                      onCancel,
                      onChange,
                  }) => {
    const [localItem, setLocalItem] = useState(item);
    // 代理人部門
    const [agentDeptNo, setAgentDeptNo] = useState('')
    // 是否使用者列表
    const [showUserList, setShowUserList] = useState(false);
    // 是否顯示醫師列表
    const [showDoctorList, setShowDoctorList] = useState(false);
    // 代理人列表
    const [doctorList, setDoctorList] = useState([])

    const {setShowDeletePopup, setFocusIndex, focusIndex} = usePopup();

    // 使用上下文
    const {setShowQueryPersonList, focusItem, setFocusItem, showToast} = usePopup();

    useEffect(() => {
        setLocalItem(item);
    }, [item]);

    /**
     * 刪除列表
     * @return {void}
     */
    const handleOnDelete = () => {
        setFocusIndex(localItem.agentId);
        setShowDeletePopup(true);
    }

    /**
     * 取消新增列表
     * @return {void}
     */
    const handleOnCancel = () => {
        if (localItem.isNew) {
            onCancel(localItem.id);
        } else {
            setLocalItem(item);
            if (index !== focusIndex) setFocusIndex(index);
        }
    }

    /**
     * 儲存新增列表
     * @return {void}
     */
    const handleOnSave = () => {
        console.log(localItem)
        onSave(localItem);
    }

    /**
     * 支援醫師變更事件
     * @return {void}
     */
    const handleSupportDoctorChange = () => {
        // 若focusItem不為空值
        if (focusItem !== null && focusItem !== undefined) {
            setLocalItem(prev => ({
                ...prev,
                userNo: focusItem.userNo,
                userName: focusItem.userName
            }))
            getAgentByUserNo(focusItem.userNo)
        }
        // 關閉醫師查詢彈窗
        setShowQueryPersonList(false)
    }

    /**
     * 處理輸入框變更事件
     * @param field
     * @param value
     */
        // const handleInputChange = (field, value) => {
        //     setLocalItem(prev => ({...prev, [field]: value}));
        // }

    const handleInputChange = () => {
            console.log('handleInputChange')
    }

    /**
     * 代理人變更事件
     * @param item {string} 代理人物件
     * @return {void}
     */
    const handleAgentChange = (item) => {
        // console.log('agent', item)
        // setLocalItem(prev => ({
        //         ...prev,
        //         agentUserNo: item.agentUserNo,
        //         agentUserName: item.agentUserName
        //     })
        // )
        if(item.agentUserNo.indexOf(' ') > 0) {
            setLocalItem(prev => ({
                ...prev,
                agentUserNo: item.split(' ')[0],
                agentUserName: item.split(' ')[1]
            }))
        } else {
            setLocalItem(prev => ({
                ...prev,
                agentUserNo: item.agentUserNo,
                agentUserName: item.agentUserName
            }))
        }
    }


    /**
     * 處理輸入框失焦事件
     * @param field
     */
    const handleInputBlur = (field) => {
        console.log('handleInputBlur')
        // onChange(item.id, field, localItem[field]);
    }

    /**
     * 處理輸入框焦點事件
     */
    const handleOnFocus = (type) => {
        // console.log('handleOnFocus')
        setShowDoctorList(true);
    }

    /**
     * 取得過濾後的醫師清單
     * @return {Object<Array>}
     */
    const getDoctorAsAgentFilterList = () => {
        if (localItem.agentUserNo.indexOf(' ') > 0) {
            return multiTermFuzzySearchObjects(doctorList, localItem.agentUserNo.split(' '));
        } else {
            return fuzzySearchObjects(doctorList, localItem.agentUserNo);
        }
    }

    /**
     * 取得過濾後的使用者清單
     * @return {Object<Array>}
     */
    const getUserFIlterList = () => {
        if (localItem.userNo.indexOf(' ') > 0) {
            return multiTermFuzzySearchObjects(userList, localItem.userNo.split(' '));
        } else {
            return fuzzySearchObjects(userList, localItem.userNo);
        }
    }

    /**
     * 選擇列表
     * @return {void}
     */
    const handleOnMouseDown = (selectedItem) => {
        const newValue = `${selectedItem.userNo} ${selectedItem.userName}`;
        // 設定本地狀態
        handleInputChange('userNo', newValue);
        // 更新列表來源資料
        onChange(item.id, 'userNo', newValue);
        // 關閉醫師下拉選單
        setShowDoctorList(false);
    }

    /**
     * 選擇列表
     * @return {void}
     */
    const handleAgentOnMouseDown = (selectedItem) => {
        console.log('handle Agent Mouse Down')
        // const newValue = `${selectedItem.userNo} ${selectedItem.userName}`;
        // 設定本地狀態
        handleAgentChange(selectedItem)
        // 取得代理人部門
        getDepartmentByDoctor(selectedItem.agentUserNo)
        // 更新列表來源資料
        // onChange(item.id, 'agentUserNo', newValue);
        // 關閉醫師下拉選單
        setShowDoctorList(false);
    }

    /**
     * 渲染列表按鈕
     * @return {Element}
     */
    const renderSlotBtn = () => {
        if (item.editable) {
            return (
                <div className='flex flex-row gap-2'>
                    <SaveIconSlotBtn onClick={handleOnSave}/>
                    <CancelSlotBtn onClick={handleOnCancel}/>
                </div>
            );
        } else {
            return (
                <div className='flex flex-row gap-2'>
                    <DelSlotBtn onClick={handleOnDelete}/>
                </div>
            );
        }
    }

    /**
     * 根據醫師代碼取得代理人資料
     * @param userNo {string} 醫師代碼
     * @return {void}
     */
    const getAgentByUserNo = (userNo) => {
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDoctorList(data)
                if (data === null) {
                    showToast({message: '支援醫師查無代理人資料', type: ToastTypeEnum.Warning})
                }
                // 重置focusItem
                setFocusItem(null)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error});
            }
        })
    }

    /**
     * 取得醫師人員部門
     * @return {void}
     */
    const getDepartmentByDoctor = (userNo) => {
        queryDepartmentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                setLocalItem(prev => ({
                    ...prev,
                    agentDeptName: data.deptName,
                    agentDeptNo: data.deptNo
                }))
            } else {
                onChange(item.id, 'agentDeptNo', msg)
                showToast({message: msg, type: ToastTypeEnum.Error});
            }
        })
    }

    useEffect(() => {
        if (focusItem !== null && focusItem !== undefined) {
            handleSupportDoctorChange()
        }
    }, [focusItem]);

    return (
        <li key={index}>
            {renderSlotBtn()}
            {item.editable ? (
                <>
                    {/* 支援醫師代碼-姓名，本欄位的資料包含醫師及非醫師 */}
                    <div className='flex flex-row items-center relative'>
                        <BaseInput
                            className='ml-2 bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                            inputMode={InputTypeEnum.Search}
                            type={InputTypeEnum.Text}
                            value={`${localItem.userNo} ${localItem.userName}`}
                            // onChange={(e) => handleInputChange('userNo', e.target.value)}
                            onChange={() => {
                            }}
                            onClick={() => setShowQueryPersonList(true)}
                        />

                    </div>
                    {/* 代理醫師代碼-姓名，本欄位只會有醫師資料*/}
                    <div className='flex flex-row items-center relative'>
                        <BaseInput
                            className='ml-2 bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                            inputMode={InputTypeEnum.Search}
                            type={InputTypeEnum.Text}
                            value={`${localItem.agentUserNo} ${localItem.agentUserName}`}
                            // value={() => {console.log(localItem)}}
                            onChange={(e) => handleInputChange('agentUserNo', e.target.value)}
                            onBlur={() => handleInputBlur('agentUserNo')}
                            onFocus={() => handleOnFocus()}
                        />
                        {showDoctorList && !arrayIsEmpty(doctorList) && (
                            <span
                                className="bg-white z-50 absolute left-5 border border-[#d4d4d8] rounded-md top-12 w-64 flex flex-col">
                                <span className='max-h-[200px] transparent overflow-y-auto px-2.5'>
                                    {getDoctorAsAgentFilterList().map((doctorItem, doctorIndex) => (
                                        <span
                                            className='bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#181818] items-center hover:bg-[#BEE3F8]'
                                            role='button'
                                            key={doctorIndex}
                                            onMouseDown={() => handleAgentOnMouseDown(doctorItem)}
                                        >
                                            <p>{`${doctorItem.agentUserNo} ${doctorItem.agentUserName}`}</p>
                                        </span>
                                    ))}
                                </span>
                            </span>
                        )}
                    </div>
                    <div>
                        <BaseInput
                            className='ml-2 bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8 hover:cursor-not-allowed'
                            inputMode={InputTypeEnum.Text}
                            type={InputTypeEnum.Text}
                            value={`${localItem.agentDeptNo} ${localItem.agentDeptName}`}
                            disable={true}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div>{`${item.supportUserNo ? item. supportUserNo : ''} ${item.supportUserName ? item.supportUserName : ''}`}</div>
                    <div>{`${item.agentUserNo ? item.agentUserNo : ''} ${item.agentUserName ? item.agentUserName: ''}`}</div>
                    <div>{`${item.agentDeptNo ? item.agentDeptNo : ''} ${item.agentDeptName ? item.agentDeptName : ''}`}</div>
                </>
            )}
        </li>
    );
}


export default ListItem
