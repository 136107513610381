import { useState, useMemo, useEffect, useRef } from "react"
import { BaseInput } from "../../components/Input/BaseInput"
import { DefaultButton } from "../../components/Button/DefaultButton"
import {
    InputTypeEnum,
    ApiErrorStatusEnum,
    ToastTypeEnum,
    time2String,
    objectIsEmpty,
    arrayIsEmpty,
    fuzzySearchObjects,
    stringIsEmpty
} from "edah_utils/dist"
import { queryAllDoctor, queryAllDivision } from "../../api/v1/Menu"
import { queryPatientByPatientIdForClinic } from "../../api/v1/Mris"
import useOutsideClick from "../../hooks/useOutsideClick"
import AppointmentRegistrationPopup from "../../components/Regs/DoctorOrderAppointmentRegistration/AppointmentRegistrationPopup"
import useToast from "../../hooks/useToast"

const DoctorOrderAppointmentRegistration = () => {
    const [inputData, setInputData] = useState({
        //門診日期
        encounterDate: "",
        //病歷號
        patientId: "",
        //科別
        div: "",
        //醫師
        doctor: "",
        //天數
        day: 1,
        //病人身份(先強制設為健保)
        ptType:21
    })
    // 病人資料
    const [patientData, setPatientData] = useState(null)
    // 所有醫生
    const [allDoctor, setAllDoctor] = useState(null)
    // 所有科別
    const [allDept, setAllDept] = useState(null)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [deptOptionList, setDeptOptionList] = useState(null)
    //顯示醫師下拉選項
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    //顯示科別下拉選項
    const [showDeptDropDown, setShowDeptDropDown] = useState(false)
    //顯示預約掛號彈出窗
    const [showAppointmentRegistrationPopup, setShowAppointmentRegistrationPopup] = useState(false)
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDeptDropDown(false),
    });
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 欄位標題
     * @param title {string} 欄位標題
     * @return {JSX.Element}
     */
    const Title = ({title}) => <p className='w-[160px] flex justify-end items-center pr-1'>{title}</p>

    /**
     * 欄位組件
     * @param title {string} 欄位標題
     * @param content {JSX.Element} 欄位內容
     * @param className {string} 欄位內容樣式
     * @return {JSX.Element}
     */
    const InputField = ({title, content, className}) => {
        return (
            <div className='flex flex-row h-[40px]'>
                <Title title={title}/>
                <div className={`flex flex-row justify-start items-center pl-5 relative ${className}`}>{content}</div>
            </div>
        )
    }

    /**
     * 取得醫師清單
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setAllDoctor(data)
            } else {
                // 清空醫師清單
                setAllDoctor([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別清單
     * @return {void}
     */
    const getQueryAllDept = () => {
        queryAllDivision({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setAllDept(data)
            } else {
                // 清空醫師清單
                setAllDept([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDeptOptionList = () => {
        const splitArray = inputData.div ? inputData.div.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
            return allDept.filter(div => div.divNo.includes(splitArray[0]) && div.divName.includes(splitArray[1]))
       }
       else {
           return fuzzySearchObjects(allDept, inputData.div)
       }
    }

    /**
     * 取得醫師下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDoctorOptionList = () => {
        const splitArray = inputData.doctor ? inputData.doctor.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
             return allDoctor.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(allDoctor, inputData.doctor)
        }
    }

    /**
     * 開診日期方框的值變動時
     * @return {void}
     */
    const handleInputEncounterDateOnChange =(e) => {
        setInputData({ ...inputData, encounterDate: e.target.value})
    }

     /**
     * 病歷號方框的值變動時
     * @return {void}
     */
     const handleInputPatientIdOnChange =(e) => {
        setInputData({ ...inputData, patientId: e.target.value})
    }

    /**
     * 科別代號方框的值變動時
     * @return {void}
     */
    const handleInputDeptNoOnChange =(e) => {
        setInputData({ ...inputData, div: e.target.value})
    }

    /**
     * 科別代號方框取得焦點時
     * @return {void}
     */
    const handleInputDeptNoOnFocus =(e) => {
        setDeptOptionList(getDeptOptionList())
        setShowDeptDropDown(true)
    }

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange =(e) => {
        setInputData({ ...inputData, doctor: e.target.value})
    }

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDeptOptionOnClick = (item) => {
        setInputData({
            ...inputData,
            div: `${item.divNo} ${item.divName}`
        })

        setShowDeptDropDown(false)
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        setInputData({
            ...inputData,
            doctor: `${item.userNo} ${item.userName}`
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 天數輸入框Input事件
     * @return {void}
     */
    const handleInputDayOnInput = (e) => {
        const value = e.target.value
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '')
    }

    /**
     * 天數方框的值變動時
     * @return {void}
     */
    const handleInputDayOnChange = (e) => {
        setInputData({ ...inputData, day: e.target.value})
    }

    /**
     * 確認按鈕點擊事件
     * @returns 
     */
    const handleConfirmOnClick = () => {
        if(stringIsEmpty(inputData.encounterDate)) {
            showToast({message: '門診日期不可為空', type: ToastTypeEnum.Error})
            return
        }
        if(stringIsEmpty(inputData.patientId)) {
            showToast({message: '病歷號不可為空', type: ToastTypeEnum.Error})
            return
        }
        if(stringIsEmpty(inputData.div)) {
            showToast({message: '科別不可為空', type: ToastTypeEnum.Error})
            return
        }
        if(stringIsEmpty(inputData.doctor)) {
            showToast({message: '醫師不可為空', type: ToastTypeEnum.Error})
            return
        }
        
        if(stringIsEmpty(inputData.day.toString())) {
            showToast({message: '天數不可為空', type: ToastTypeEnum.Error})
            return
        }

        queryPatientByPatientIdForClinic({
            patientId:inputData.patientId
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {

                if(objectIsEmpty(data)) {
                    showToast({message: '找不到此病歷號', type: ToastTypeEnum.Warning})
                }
                else {
                    setPatientData({
                        patientId: data.patientId,
                        idNo: data.idNo,
                        patientName: data.patientName,
                        birthDate: data.birthDate,
                        age: data.age
                    })
                    setShowAppointmentRegistrationPopup(true)
                }
            } else { // 取得資料失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })

        
    }

    /**
     * 關閉預約掛號彈出窗
     */
    const handleCloseAppointmentRegistrationPopup = () => {
        setShowAppointmentRegistrationPopup(false)
    }

    // 第一次執行時
    useMemo(() => {
        // 取得醫師清單
        getQueryAllDoctor()

        getQueryAllDept()

        setInputData({
            ...inputData,
            encounterDate: time2String(new Date(), "YYYY-MM-DD")
        })
    }, [])

    /**
     * 監聽醫師代號輸入框變化
     */
     useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [inputData.doctor])

    /**
     * 監聽科別代號輸入框變化
     */
    useEffect(() => {
        setDeptOptionList(getDeptOptionList())
    }, [inputData.div])

    return (
        <div className="h-[calc(100vh-110px)] items-center flex justify-center w-full">
            <div className="flex flex-col gap-5 rounded-md py-14 pl-10 pr-10 border border-[#D4D4D8]">
                {/*門診日期*/}
                <div className='flex flex-row h-[40px]'>
                    <Title title='門診日期'/>
                    <div className={`flex flex-row justify-start items-center pl-5 relative`}>
                        <BaseInput 
                            className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-md mr-2.5"
                            type={InputTypeEnum.Date} 
                            value={inputData.encounterDate}
                            inputMode="date"
                            onChange={handleInputEncounterDateOnChange}/>
                    </div>
                </div>
                
                {/*病例號*/}
                <div className='flex flex-row h-[40px]'>
                    <Title title='病歷號'/>
                    <div className={`flex flex-row justify-start items-center pl-5 relative`}>
                        <BaseInput 
                            className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-md mr-2.5"
                            type={InputTypeEnum.Text}
                            value={inputData.patientId}
                            onChange={handleInputPatientIdOnChange}/>
                    </div>
                </div>
                {/*科別*/}
                <div className='flex flex-row h-[40px]'>
                    <Title title='科別'/>
                    <div className={`flex flex-row justify-start items-center pl-5 relative`}>
                        <div className="flex items-center relative" ref={dropdownDepartmentRef}>
                            <input
                                className="w-[235px] h-10 px-4 border border-[#D4D4D8] rounded-md mr-2.5 bg-arrowDown bg-[length:18px_18px] bg-[center_right_8px] bg-no-repeat"
                                type="text"
                                value={inputData.div}
                                onChange={handleInputDeptNoOnChange}
                                onFocus={handleInputDeptNoOnFocus}/>
                            {
                                showDeptDropDown && !arrayIsEmpty(deptOptionList) && 
                                (
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                deptOptionList.map((item, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleDeptOptionOnClick(item)}
                                                        >
                                                        <p>{`${item.divNo} ${item.divName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
               
                {/*醫師*/}
                <div className='flex flex-row h-[40px]'>
                    <Title title='醫師'/>
                    <div className={`flex flex-row justify-start items-center pl-5 relative`}>
                        <div className="flex items-center relative" ref={dropdownDoctorRef}>
                            <input 
                                className="w-[235px] h-10 px-4 border border-[#D4D4D8] rounded-md mr-2.5 bg-arrowDown bg-[length:18px_18px] bg-[center_right_8px] bg-no-repeat"
                                type="text"
                                value={inputData.doctor}
                                onChange={handleInputDoctorNoOnChange}
                                onFocus={handleInputDoctorNoOnFocus}/>
                            {
                                showDoctorDropDown && !arrayIsEmpty(doctorOptionList) && 
                                (
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                doctorOptionList.map((item, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleDoctorOptionOnClick(item)}
                                                        >
                                                        <p>{`${item.userNo} ${item.userName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                
                {/*天數*/}
                <div className='flex flex-row h-[40px]'>
                    <Title title='天數'/>
                    <div className={`flex flex-row justify-start items-center pl-5 relative`}>
                        <BaseInput 
                            className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-md mr-2.5"
                            type={InputTypeEnum.Number}
                            value={inputData.day}
                            min={0}
                            onInput={handleInputDayOnInput}
                            onChange={handleInputDayOnChange}/>
                    </div>
                </div>

                <div className="">
                    <DefaultButton 
                        type="primary" text="確認"
                        onClickFn={handleConfirmOnClick}/>
                </div>
                
            </div>
            <AppointmentRegistrationPopup
                show={showAppointmentRegistrationPopup}
                closePopupButtonOnClick={handleCloseAppointmentRegistrationPopup}
                patientData={patientData}
                baseQueryCondition={inputData}
                doctorArray={allDoctor}
                deptArray={allDept}/>
        </div>
    )
}

export default DoctorOrderAppointmentRegistration