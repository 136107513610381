import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 登入
 * @param data data
 * @returns {AxiosPromise}
 */
const login = (data) => v1Api.request({
    url: '/install/user/login',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 登出
 * @param data data
 * @return {AxiosPromise}
 */
const logout = (data) => v1Api.request({
    url: '/install/user/logout',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 根據帳號取得使用者名稱
 * @param params params
 * @return {AxiosPromise}
 */
const queryUserNameByUserNo = (params) => v1Api.request({
    url: '/install/user/queryUserNameByUserNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢員工資訊
 * @param params params
 * @return {AxiosPromise}
 */
const gueryEmployeeInfo = (params) => v1Api.request({
    url: 'install/user/queryEmp',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依搜尋欄位查詢員工資訊
 * @param params params
 * @return {AxiosPromise}
 */
const queryEmpBySearchField = (params) => v1Api.request({
    url: 'install/user/queryEmpBySearchField',
    method: HttpMethodEnum.GET,
    params
})



export {
    login,
    logout,
    queryUserNameByUserNo,
    gueryEmployeeInfo,
    queryEmpBySearchField
}
