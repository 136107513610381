import React from 'react'

/**
 *
 * @returns {JSX.Element}
 */
function SendWarningMailLayout() {
  return (
    <div>SendWarningMail</div>
  )
}

export default SendWarningMailLayout
