import React from 'react'
import {EditSlotBtn} from '../SlotButton'
import '../../TableList/tableList.scss'
import {usePopup} from '../PopupProvider'
import {t} from "i18next"
import {sanitizedData} from "../utils";

/**
 * RSIS功能操作維護列表組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @param isSelectedAll {Boolean} 是否包含全選
 * @return {JSX.Element}
 */
const ListItem = ({item, index, isSelectAll = null,}) => {
    // 取得html資料
    const data = item.htmlContent ? item.htmlContent : ''

    // 使用上下文
    const {setShowFunctionOperateIntroEditModal, setFocusItem} = usePopup();

    /**
     * 編輯事件，指定編輯index
     * @return {void}
     */
    const handleOnItemEdit = () => {
        setShowFunctionOperateIntroEditModal(true)
        setFocusItem(item)
    }

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => <EditSlotBtn onClick={handleOnItemEdit}/>

    return (
        <li key={index}>
            {/*操作*/}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {renderSlotBtn()}
                </span>
            </div>
            {/* 功能ID */}
            <div>{item.funNo}</div>
            {/* 功能名稱 */}
            <div>{item.funcName}</div>
            {/* 類別 */}
            <div>{item.docType ? t(`RSIS.DocTypeEnum.${item.docType}`) : ''}</div>
            {/* 操作說明 */}
            <div
                className='flex flex-col items-start justify-start operation-description prose max-w-none'
                dangerouslySetInnerHTML={{__html: sanitizedData(data)}}
            />
            {/* 有效否 */}
            <div>{item.effFlag ? t(`RSIS.effFlag.${Number(item.effFlag)}`) : t('RSIS.effFlag.0')}</div>
        </li>
    )
}

export default ListItem
