// Import Image
import {ReactComponent as EditBtnIcon} from "../../../assets/images/ICCard/edit_square.svg"
import {ReactComponent as EditCheck} from "../../../assets/images/ICCard/edit_check.svg"

// Import function
import React, {useEffect, useState} from "react"
import {ICCMMaintainPageActiveTabEnum, ICCMOperationModeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {InfoContent} from "./ActiveTab/InfoContent"
import {ListContent} from "./ActiveTab/ListContent"
import {ResultContent} from "./ActiveTab/ResultContent"

/**
 * Active Tab
 * @param activeTab {ICCMMaintainPageActiveTabEnum} Active Tab
 * @return {JSX.Element}
 */
export const ActiveTab = ({activeTab = ICCMMaintainPageActiveTabEnum.BasicData}) => {
    //當前點擊的Tab列舉
    const [currentActiveTab, setCurrentActiveTab] = useState(activeTab)

    // 操作模式
    const [operationMode, SetOperationMode] = useState(ICCMOperationModeEnum.Edit)

    // 啟用編輯功能
    const [isEditable, setIsEditable] = useState(false);

    /**
     * 取得 Tab Class Name
     * @param tab {ICCMMaintainPageActiveTabEnum} tab enum
     * @return {string}
     */
    const getTabClassName = (tab) =>
        `w-[96px] h-[42px] px-4 py-2 text-[16px] ${currentActiveTab === tab ?
            'border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]' : ''}`

    /**
     * 點擊分頁按鈕時
     * @param tab {ICCMMaintainPageActiveTabEnum} tab enum
     * @return {void}
     */
    const handleTabButtonOnClick = (tab) => setCurrentActiveTab(tab)

    /**
     * 取得Tab Content
     * @param tab {ICCMMaintainPageActiveTabEnum} tab enum
     */
    const getTabContent = (tab) => {

    }

    /**
     * 取得新增/儲存按鈕區塊
     * @return {JSX.Element}
     */
    const getAddEditButtonSection = () =>
        activeTab === ICCMMaintainPageActiveTabEnum.BasicData ?
            // 只顯示在基本資料Tab
            // <button className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
            //         onClick={handleAddSaveButtonOnClick}>
            //     <EditBtnIcon className="mr-1"/>
            //     {operationMode === ICCMOperationModeEnum.Edit ? t('general.edit') : t('general.save')}
            // </button>
            isEditable ?
                <button onClick={handleAddSaveButtonOnClick}
                        className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                    <EditCheck className="mr-1"/>儲存</button>
                :
                <button className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
                        onClick={handleAddSaveButtonOnClick}>
                    <EditBtnIcon className="mr-1"/>編輯</button>
            :
            null


    /**
     * 新增/儲存按鈕點擊時
     * @return {void}
     */
    const handleAddSaveButtonOnClick = () => {
        setIsEditable(!isEditable)
    }

    /**
     *  是否顯示
     *  @param activeTab {ICCMMaintainPageActiveTabEnum} Active Tab
     *  @return {boolean}
     */
    const getIsShow = (activeTab) => currentActiveTab === activeTab

    /**
     * 監聽到Active Tab改變時
     * @return {void}
     */
    useEffect(() => setCurrentActiveTab(activeTab), [activeTab])

    return (
        <div className="p-4 bg-white w-[867px] border-[1px] border-[#D4D4D8] rounded-[12px]">
            {/*點擊按鈕切換下方內容*/}
            <div className="hasHpcSwitch mb-4">
                {/*點擊tab切換內容 - 按鈕*/}
                <div className="tabGroup w-full flex flex-row items-center justify-between mb-4 pr-4">
                    <div className="flex flex-row items-center justify-start">
                        {/*基本資料Tab按鈕*/}
                        <button className={getTabClassName(ICCMMaintainPageActiveTabEnum.BasicData)}
                                onClick={() => handleTabButtonOnClick(ICCMMaintainPageActiveTabEnum.BasicData)}>
                            基本資料
                        </button>
                        {/*醫囑清單Tab按鈕*/}
                        <button className={getTabClassName(ICCMMaintainPageActiveTabEnum.MedicalOrderList)}
                                onClick={() => handleTabButtonOnClick(ICCMMaintainPageActiveTabEnum.MedicalOrderList)}>
                            醫囑清單
                        </button>
                        {/*檢核結果Tab按鈕*/}
                        <button className={getTabClassName(ICCMMaintainPageActiveTabEnum.CheckResult)}
                                onClick={() => handleTabButtonOnClick(ICCMMaintainPageActiveTabEnum.CheckResult)}>
                            檢核結果
                        </button>
                    </div>
                    {/*新增或儲存按鈕*/}
                    {getAddEditButtonSection()}
                </div>
                {/*基本資料 Tab Content*/}
                <InfoContent show={getIsShow(ICCMMaintainPageActiveTabEnum.BasicData)}
                             isEditable={isEditable}/>
                {/*醫囑清單 Tab Content*/}
                <ListContent show={getIsShow(ICCMMaintainPageActiveTabEnum.MedicalOrderList)}/>
                {/*檢核結果 Tab Content*/}
                <ResultContent show={getIsShow(ICCMMaintainPageActiveTabEnum.CheckResult)}/>
            </div>
        </div>
    )
}
