import React, {useState, useEffect, useMemo} from 'react'
import {CancelSlotBtn, DelSlotBtn, SaveIconSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {
    InputTypeEnum,
} from 'edah_utils/dist'
import {BaseInput} from "../../Input/BaseInput";
import QueryCreateUserList from "../Modal/QueryCreateUserList";

/**
 * 承辦部門人員維護列表項目
 * @param item {Object} 列表項目
 * @param index {Number} 索引
 * @param isSelectAll {Boolean} 是否全選
 * @param onSave {Function} 保存列表項目
 * @param onCancel {Function} 取消列表項目
 * @return {JSX.Element}
 * @constructor
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                      onSave,
                      onCancel,
                  }) => {

    // 使用上下文
    const {
        setShowQueryCreateUserList,
        setShowDeletePopup,
        setFocusItem,
        focusItem,
        showToast
    } = usePopup()
    // 可編輯物件
    const [editedItem, setEditedItem] = useState(item)


    /**
     * 人員查詢方框點擊時
     * @return {void}
     */
    const handlePersonSearchOnClick = () => setShowQueryCreateUserList(true)

    /**
     * 刪除可編輯列表項目
     * @return {void}
     */
    const handleOnDelete = () => {
        setFocusItem(item)
        setShowDeletePopup(true)
    }

    /**
     * 取消刪除列表項目，返回列表當前焦點索引
     * @return {void}
     */
    const handleOnCancel = () => {
        if (item.isNew) {
            onCancel(item.id)
        } else {
            setEditedItem(item) // 重置編輯狀態
        }
    }

    /**
     * 保存編輯的列表項目
     * @return {void}
     */
    const handleOnSave = () => {
        onSave(editedItem)
    }

    /**
     * 處理輸入框改變
     * @param item {object} 物件
     */
    const handleInputChange = ( item) => {
        setEditedItem(prev => ({
            ...prev,
            userNo: item.userNo,
            userName: item.userName,
            deptNo: item.depNo,
            deptName: item.depName
        }))
    }

    const renderSlotBtn = () => {
        if (item.editable) {
            return (
                <div className='flex flex-row gap-2'>
                    <SaveIconSlotBtn onClick={handleOnSave}/>
                    <CancelSlotBtn onClick={handleOnCancel}/>
                </div>
            )
        } else {
            return (
                <div className='flex flex-row gap-2'>
                    <DelSlotBtn onClick={handleOnDelete}/>
                </div>
            )
        }
    }

    // 監聽focusItemFunction
    useEffect(() => {
        if (focusItem) handleInputChange(focusItem)
    }, [focusItem])

    // 當item改變時，若為新增的ListItem確保初始化時資料為空值
    useEffect(() => {
        if (item.isNew) {
            setEditedItem({
                userNo: "",
                userName: "",
                deptNo: "",
                deptName: "",
                ...item
            });
        }
    }, [item]);

    return (
        <li key={index}>
            {renderSlotBtn()}
            {item.editable ? (
                <>
                    <div className='flex items-center relative'>
                        <BaseInput
                            className='bg-white w-[173px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                            type={InputTypeEnum.Text}
                            inputMode={InputTypeEnum.Text}
                            value={editedItem.userNo}
                            onClick={handlePersonSearchOnClick}
                        />
                    </div>
                    <div>
                        <input
                            className="w-[140px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                            type={InputTypeEnum.Text}
                            value={editedItem.userName}
                            disabled={true}
                            onChange={(e) => handleInputChange('userName', e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            className="w-[140px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                            type={InputTypeEnum.Text}
                            value={editedItem.deptNo}
                            disabled={true}
                            onChange={(e) => handleInputChange('deptNo', e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            className="w-[140px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                            type={InputTypeEnum.Text}
                            value={editedItem.deptName}
                            disabled={true}
                            onChange={(e) => handleInputChange('deptName', e.target.value)}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div>{item.userNo}</div>
                    <div>{item.userName}</div>
                    <div>{item.deptNo}</div>
                    <div>{item.deptName}</div>
                </>
            )}
        </li>
    )
}

export default ListItem