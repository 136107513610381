import React, { useEffect, useState } from "react";
import { BaseInput } from "../../components/Input/BaseInput";
import {ApiErrorStatusEnum, InputTypeEnum, ToastTypeEnum, arrayIsEmpty} from "edah_utils/dist"
import { getCurrentFormattedDateTime, generateMedicalId } from "../../components/CriticalValueManagement/utils";
import ToastMessage from "../../components/Toasts/ToastMessage";
import { cvnotify, queryCvisNotifyUnit } from "../../api/v1/Critical";

// 就醫號
// A院區 O門診 I住院
// AOYYMMDD - 流水號
const CriticalValueAlert = ({popUpOnClose}) => {
    // 危急值通報單位
    const [ unit, setUnit ] = useState([])
    // 危急值通報輸入資料
    const [cvisQueryData, setCvisQueryData] = useState({
        cvisNotifyUnit: '01',
        // encounterId: generateMedicalId(),
        encounterId: "I-240529",
        cvisNotifyItem: '',
        cvisNotifyClass: '',
        cvisNotifyType: "A",
        patientId: '',
        patientName: '',
        inpOpdNo: '',
        cvisNotifyDatetime: getCurrentFormattedDateTime(), //直接帶入當下時間
        cvisNotifyUser: '',
        cvisNotifyObject: '',
        cvisNotifyContent: '',
        cvisNotifyPhone:'',
        divName: ''
    })
    // Toast開關
    const [toastShow, setToastShow] = useState(false)
    // Toast訊息
    const [toastMessage, setToastMessage] = useState({
        message: '',
        type: ToastTypeEnum.Success
    })

    /**
     * 控制Toast開啟事件
     * @param {Object} event
     * @returns {void}
     */
    const handleToastOnShow = (message, type) => {
        setToastMessage({
            ...toastMessage,
            message: message,
            type: type
        })
        setToastShow(true)
    }

    /**
   * 控制Toast關閉事件
   * @param {Object} event
   * @returns {void}
   */
    const handleToastOnClose = () => {
        setToastShow(false)
    }

    // postman 假資料
    // encounterDate: "2024-05-11 00:00:00" ,//  Required 就醫日期
    // encounterId: "test encounter id" ,// Required 就醫號
    // inpOpd: "O",// Required 門/住/急 > O/I/E
    // patientId: "test painteid",// Required 病歷號
    // cvisNotifyDatetime: "2024-05-11 14:00:00",// Required 通報日期
    // cvisNotifyUser: "test user",// 通報人員
    // cvisNotifyType: "A",// Required 通報類別 > A/B
    // cvisNotifyUnit: "test unit",// 通報單位
    // cvisNotifyClass: "A",// Required 通報等級 > A/B/C
    // cvisNotifyItem: "test item",// Required 通報項目
    // cvisNotifyContent: "test content",// Required 通報內容
    // cvisNotifyObject: "test object"// Required 通報對象

    // encounterDate: getCurrentFormattedDateTime() ,//  Required 就醫日期
    // encounterId: cvisQueryData.encounterId ,// Required 就醫號
    // inpOpd: cvisQueryData.inpOpd,// Required 門/住/急 > O/I/E
    // patientId: cvisQueryData.patientId,// Required 病歷號
    // cvisNotifyDatetime: getCurrentFormattedDateTime(),// Required 通報日期
    // cvisNotifyUser: cvisQueryData.cvisNotifyUser,// 通報人員
    // cvisNotifyType: cvisQueryData.cvisNotifyType,// Required 通報類別 > A/B
    // cvisNotifyUnit: cvisQueryData.cvisNotifyUnit,// 通報單位
    // cvisNotifyClass: cvisQueryData.cvisNotifyClass,// Required 通報等級 > A/B/C
    // cvisNotifyItem: cvisQueryData.cvisNotifyItem,// Required 通報項目
    // cvisNotifyContent: cvisQueryData.cvisNotifyContent,// Required 通報內容
    // cvisNotifyObject: cvisQueryData.cvisNotifyObject// Required 通報對象

    /**
     * 送出危急值通報
     * @returns {void}
     */
    const handleCvisNotifySubmit = () => {
        console.log(cvisQueryData.encounterId)
        try {
            cvnotify({
                encounterDate: getCurrentFormattedDateTime(),//  Required 就醫日期
                encounterId: cvisQueryData.encounterId,
                inpOpdNo: cvisQueryData.inpOpdNo,// Required 門/住/急 > O/I/E
                patientId: cvisQueryData.patientId,// Required 病歷號
                cvisNotifyDatetime: getCurrentFormattedDateTime(),// Required 通報日期
                cvisNotifyUser: cvisQueryData.cvisNotifyUser,// 通報人員
                cvisNotifyType: cvisQueryData.cvisNotifyType,// Required 通報類別 > A/B
                cvisNotifyUnit: cvisQueryData.cvisNotifyUnit,// 通報單位
                cvisNotifyClass: cvisQueryData.cvisNotifyClass,// Required 通報等級 > A/B/C
                cvisNotifyItem: cvisQueryData.cvisNotifyItem,// Required 通報項目
                cvisNotifyContent: cvisQueryData.cvisNotifyContent,// Required 通報內容
                cvisNotifyObject: cvisQueryData.cvisNotifyObject,// Required 通報對象
                isInfection: 'N'
            }).then((res) => {
                console.log('res', res)
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 關閉視窗
                    // popUpOnClose()
                    handleToastOnShow("送出成功", ToastTypeEnum.Success)
                } else {
                    handleToastOnShow(res.msg, ToastTypeEnum.Error)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        queryCvisNotifyUnit()
            .then((res) => {
                if(res.err === ApiErrorStatusEnum.Success)
                {
                    setUnit(res.data)
                }else{
                    handleToastOnShow('通報單位清單取得失敗', ToastTypeEnum.error)
                }
            })
    }, [])

    return (
        <>
            <div className=" flex items-center justify-center bg-black/50 w-full h-full top-0 left-0">
                <div className="w-[910px] rounded-[8px] bg-white">
                    <div className="h-[60px] flex flex-row items-center justify-between px-6 border-b-[1px] border-[#111111]/15">
                        <h3 className="text-[20px] font-bold">危急值通報</h3>
                        <button className="text-[#2B6CB0] text-[18px]">關閉</button>
                    </div>
                    <div className="px-6 py-4">
                        <ul className="p-[10px]">
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報單位</p>
                                    <select
                                        className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                        value={cvisQueryData.cvisNotifyUnit}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyUnit: e.target.value})}
                                    >
                                        {!arrayIsEmpty(unit) && unit.map((item, index) => (
                                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                                        ))}
                                    </select>
                                </div>
                                就醫號
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">就醫號</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.encounterId}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, encounterId: e.target.value})}
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報項目</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyItem}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyItem: e.target.value})}
                                        placeholder="通報項目"
                                        required
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">事件等級</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyClass}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyClass: e.target.value})}
                                        placeholder="A/B/C"
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">病歷號</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.patientId}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, patientId: e.target.value})}
                                        placeholder="病歷號"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">姓名</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.patientName}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, patientName: e.target.value})}
                                        placeholder="姓名"
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">門住</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.inpOpdNo}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, inpOpdNo: e.target.value})}
                                        placeholder="O/I/E"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">科別</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.divName}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, divName: e.target.value})}
                                        placeholder="科別"
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報日期時間</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={getCurrentFormattedDateTime()}
                                        type={InputTypeEnum.Text}
                                        // onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyDatetime: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報人員</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyUser}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyUser: e.target.value})}
                                        placeholder="通報人員"
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報對象</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyObject}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyObject: e.target.value})}
                                        placeholder="通報對象"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-end w-1/2">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報電話</p>
                                    <input
                                        className="w-[235px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyPhone}
                                        type="tel"
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyPhone: e.target.value})}
                                        placeholder="通報電話"
                                    />
                                </div>
                            </li>
                            <li className="h-[42px] flex items-center justify-between mb-1">
                                <div className="flex flex-row items-center justify-end w-full">
                                    <p className="mr-4 w-[160px] text-right pr-4">通報內容</p>
                                    <input
                                        className="w-[655px] h-[40px] border-[1px] border-[#D4D4D8] rounded-[6px] px-3"
                                        value={cvisQueryData.cvisNotifyContent}
                                        type={InputTypeEnum.Text}
                                        onChange={(e) => setCvisQueryData({...cvisQueryData, cvisNotifyContent: e.target.value})}
                                        placeholder="通報內容"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="h-[60px] flex flex-row items-center justify-end px-6 border-t-[1px] border-[#111111]/15">
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleCvisNotifySubmit}
                        >
                            送出
                        </button>
                    </div>
                </div>
            </div>
            <ToastMessage
                message={toastMessage.message}
                type={toastMessage.type}
                show={toastShow}
                onClickClose={handleToastOnClose}
            />
        </>
    )
}
export default CriticalValueAlert
