import React, { useState } from 'react'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { NO_REFERRAL_REPORT_FIELDS } from '../../constants/referralSystem/noReferralReport'
import { queryNotTranBackReasonBySearch } from '../../api/v1/Rmis'
import { BaseInput } from '../../components/Input/BaseInput'
import {
    ApiErrorStatusEnum,
    ToastTypeEnum,
    validDateFormat,
    time2String,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../constants/common'

/**
 * 不轉診原因報表清單
 * @returns {JSX.Element}
 */
const NoReferralReport = () => {
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    const [searchParams, setSearchParams] = useState({
        patientId: '',
        encounterStartDate: '',
        encounterEndDate: '',
    })

    const [inputState, setInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })

    const showToast = useToast()

    // 插入PaginationTable的匯出按鈕
    const ExportTextButton = () => {
        const handleExportExcel = () => {
            const keys = NO_REFERRAL_REPORT_FIELDS.map((item) => item.key)
            const titles = NO_REFERRAL_REPORT_FIELDS.map((item) => item.name)
            const dataArray = []
            dataList.forEach((item) => {
                const data = []
                keys.forEach((key) => {
                    if (key === 'encounterDate') {
                        data.push(time2String(item[key], DATE_FORMAT))
                    } else {
                        data.push(item[key])
                    }
                })
                dataArray.push(data)
            })
            const sheets = [
                {
                    titles,
                    data: dataArray,
                },
            ]
            // 匯出Excel
            downloadFile(
                exportExcelAsBlob(sheets),
                '不轉診原因報表',
                FileExtensionsEnum.XLSX
            )
        }
        return (
            <button
                className="flex items-center justify-center px-4 h-10 rounded-[6px] text-[#38A169]"
                onClick={handleExportExcel}
            >
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 取得不轉診原因清單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const getNoTranBackReasonList = (pageNum, pageSize) => {
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        queryNotTranBackReasonBySearch({
            ...searchParams,
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (res.data.dataList?.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                const list = res.data.dataList || []
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageNum,
                    pageSize,
                })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 查詢
     */
    const handleQueryOnClick = () => {
        getNoTranBackReasonList(1, filter.pageSize)
    }

    /**
     * 更新查詢輸入框
     * @param {object} event
     * @param {string} field input欄位名稱
     */
    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 點選頁碼
     * @param {string} page
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getNoTranBackReasonList(currentPage, filter.pageSize)
    }

    /**
     * 每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getNoTranBackReasonList(filter.pageNum, pageSize)
    }

    /**
     * 上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getNoTranBackReasonList(page, filter.pageSize)
    }

    /**
     * 下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getNoTranBackReasonList(page, filter.pageSize)
    }

    return (
        <div className="p-2 w-full">
            <div className="flex p-2">
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <div className="mr-4">病歷號:</div>
                    <input
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type="text"
                        value={searchParams.patientId}
                        onChange={(e) => updateSearchParams(e, 'patientId')}
                    />
                </div>
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    <div className="mr-4">
                        就醫日期<span className="text-danger ">*</span>:
                    </div>
                    <BaseInput
                        inputState={inputState.encounterStartDate}
                        className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                        value={searchParams.encounterStartDate}
                        onChange={(e) =>
                            updateSearchParams(e, 'encounterStartDate')
                        }
                        type="date"
                        errorMsg="必填"
                    />
                    <span className="m-1">~</span>
                    <BaseInput
                        inputState={inputState.encounterEndDate}
                        className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                        value={searchParams.encounterEndDate}
                        onChange={(e) =>
                            updateSearchParams(e, 'encounterEndDate')
                        }
                        type="date"
                        errorMsg="必填"
                    />
                </div>
                <button
                    onClick={handleQueryOnClick}
                    className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                >
                    查詢
                </button>
            </div>
            <CustomTable
                fields={NO_REFERRAL_REPORT_FIELDS}
                slotButton={<ExportTextButton />}
                dataList={dataList}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
        </div>
    )
}

export default NoReferralReport
