import {BrowserRouter, Route, Routes} from "react-router-dom";
import routes from "./router/index";
import {RoutesAuth} from "./router/RoutesAuth";
import Loading from "./components/Loading/Loading"
import {useSelector} from "react-redux"
import ToastMessage from "./components/Toasts/ToastMessage"

/**
 * Create Routes
 */
const createRoutes = () =>
    routes.map((route, index) => (
        <Route
            key={index}
            path={route.path}
            element={
                //不為登入URL
                route.path !== "/login" ? (
                    //透過RoutesAuth判斷是否已經登入
                    <RoutesAuth>{route}</RoutesAuth>
                ) : (
                    //已經登入就不需要驗證直接回傳
                    route.element
                )
            }
        />
    ));

/**
 * App Routes
 */
const App = () => {
    //取得Loading狀態
    const isShow = useSelector(state => state.loadingPage)
    //取得ToastMessage狀態
    const {message, type, show, duration, onClickClose} = useSelector(state => state.toastMessage)

    return <BrowserRouter>
        {/*ToastMessage*/}
        <ToastMessage message={message} type={type} duration={duration} show={show} onClickClose={onClickClose}/>
        {/*Loading Page*/}
        <Loading isShow={isShow}/>
        <Routes>{createRoutes()}</Routes>
    </BrowserRouter>
}
export default App
