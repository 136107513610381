import InfoCard from "./InfoCard"
import searchIcon from "../../assets/images/search-interface-symbol.png"
import {useEffect, useState, useRef} from "react"
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty, time2String, TimeslotEnum, ToastTypeEnum, InputTypeEnum, arrayIsEmpty} from "edah_utils/dist"
import {regsAddReg, regsQueryBasicDataClinicRes} from "../../api/v1/Regs"
import { regsAddFirstview } from "../../api/v1/RegsFirstView"
import ClinicSchedule from "./AppointmentSchedule/AppointmentPopup/ClinicSchedule"
import {ShowGiveNumberPopup} from "./AppointmentSchedule/ShowGiveNumberPopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import ClinicVisitsNumberStatePopup from "./Popup/ClinicVisitsNumberStatePopup"
import WeeklySchedule from "./AppointmentSchedule/AppointmentPopup/WeeklySchedule"
import useToast from "../../hooks/useToast"
import { BaseInput } from "../Input/BaseInput"
import { isCurrentDateTimeWithinRange } from "./utils"
import {t} from "i18next"
import AppointmentCalendar from "../Regs/Popup/AppointmentCalendarPopup";

/**
 * 看診資料
 * @param patientData {Object} 病人資料
 * @param showData {Boolean} 是否顯示資料
 * @param baseData {Object} 基本資料
 * @param updateGetRegsQueryRegHistory {Function} 更新取得病人預約資訊(看診資料)的函數
 * @param setIsPhoneReg {Function} 設定是否為電話預約
*/
const AppointmentInfo = ({
                             patientData,
                             showData,
                             baseData,
                             updateGetRegsQueryRegHistory,
                             triggerResetDefault,
                             appointmentData,
                             setAppointmentData,
                             resetAppointmentData
                         }) => {

    // 醫師選項
    const [doctorOptions, setDoctorOptions] = useState([])
    // 是否顯示醫師選項
    const [showDoctorOptions, setShowDoctorOptions] = useState(false)
    // 診間選項
    const [clinicOptions, setClinicOptions] = useState([])
    // 科別選項
    const [divOptions, setDivOptions] = useState([])
    // 是否顯示科別選項
    const [showDivOptions, setShowDivOptions] = useState(false)
    // 是否顯示診間當日班表
    const [showClinicSchedule, setShowClinicSchedule] = useState(false)
    //當日班表資料
    const [clinicOfDayScheduleData, setClinicOfDayScheduleData] = useState(null)
    //是否顯示給診號彈窗
    const [showGiveNumberPopup, setShowGiveNumberPopup] = useState(false)
    //給給診號彈窗資料
    const [giveNumberData, setGiveNumberData] = useState({})
    //給診號按鈕是否不能按
    const [disableAddRegsButton, setDisableAddRegsButton] = useState(true)
    //有無診室
    const [haveClinic, setHaveClinic] = useState(false)
    //加掛按鈕是否不能按
    const [disableExtraAddRegsButton, setDisableExtraAddRegsButton] = useState(true)
    const [showClinicNoStatePopup, setShowClinicNoStatePopup] = useState(false)
    // 是否顯示當周班表彈出框
    const [showWeeklySchedulePopup, setShowWeeklySchedulePopup] = useState(false)
    // 周班表查詢基本資料
    const [weeklyScheduleBaseData, setWeeklyScheduleBaseData] = useState(null)
    // 掛號別List
    const [registrationList, setRegistrationList] = useState(null)
    // 過濾後的科別選項
    const [filterDivOptions, serFilterDivOptions] = useState([])
    // 過濾後的醫師選項
    const [filterDoctorOptions, serFilterDoctorOptions] = useState([])
    //Toast Message Hooks
    const showToast = useToast()
    // 顯示時間日曆
    const [showCalendar, setShowCalendar] = useState(false);
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorOptions(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDivOptions(false),
    });

    /**
     * 處理看診資料變更
     * @param v {Number} 時段
     * @return {void}
     */
    const handleApnOnClick = (v) => {
        setAppointmentData({
            ...appointmentData,
            apn: parseInt(v),
            //醫師代碼
            doctorNo: "",
            //科別代碼
            divNo: "",
            //診間id
            clinicId: "",
            //診間號
            clinicNo: "",
            //診室名稱
            clinicName: "",
            //診室地點
            clinicLocation: "",
            //停診
            stopClinicFlag: null,
            //給號方式
            giveNumType: ""
        })
    }

    /**
     * 處理院區變更
     * @param e {Event} 事件
     * @return {void}
     */
    const handleZoneOnChange = (e) => setAppointmentData({...appointmentData, zone: e.target.value})

    /**
     * 處理補輸變更時
     * @return {void}
     */
    const handleFowardRegFlagOnChange = () => setAppointmentData({
        ...appointmentData,
        fowardRegFlag: !appointmentData.fowardRegFlag
    })

    /**
     * 處理掛號別變更時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleReserveTypeOnChange = (e) => {
        setAppointmentData({...appointmentData, 
            reserveType: e.target.value,
            //掛號別為電話時，要勾選預約掛號
            phoneRegFlag: e.target.value === "3"
        })
    }

    /**
     * 處理身份別變更時
     * @param e {Event} 事件
     * @return {void}
     */
    const handlePtTypeOnChange = (e) => setAppointmentData({...appointmentData, ptType: e.target.value})

    /**
     * 處理電話預約變更時
     * @return {void}
     */
    const handlePhoneRegFlagOnChange = () => {
        const flag = !appointmentData.phoneRegFlag
        let reserveType = appointmentData.reserveType
        if(flag === false ) {
            //勾掉時，掛號別改為現掛或預約
            reserveType = isCurrentDateTimeWithinRange(appointmentData.encounterDate, parseInt(appointmentData.apn)) ? "1" : "2"
        }
        else {
            //勾選時，掛號別要改為電話
            reserveType = "3"
        }
        setAppointmentData({
            ...appointmentData,
            phoneRegFlag: flag,
            reserveType: reserveType
        })
    }

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const divText = appointmentData.divNo.trim()
        const doctorText = appointmentData.doctorNo.trim()
        //切割字串
        const splitArray = doctorText ? doctorText.split(" ") : []
        //已經有選擇醫師
        if (splitArray.length === 2) {
            return doctorOptions.filter(doctor => {
                //無代班醫生，比對醫生號碼&醫生名稱是否皆相同
                const doctorMatch = (stringIsEmpty(doctor.locumDoctor) || stringIsEmpty(doctor.locumDoctorName)) && doctor.doctorNo.includes(splitArray[0]) && doctor.doctorName.includes(splitArray[1]);
                //有代班醫生，比對代班醫生號碼&代班醫生名稱是否皆相同
                const locumMatch = !stringIsEmpty(doctor.locumDoctor) && !stringIsEmpty(doctor.locumDoctorName) && 
                                   doctor.locumDoctor.includes(splitArray[0]) && doctor.locumDoctorName.includes(splitArray[1]);
                //有一個符合就可以
                return doctorMatch || locumMatch
            })
        } else { //透過輸入文字
            // 科別選項為空時
            if (objectIsEmpty(divText)) {
                // 醫師選項為空時
                if (stringIsEmpty(doctorText)) {
                    return doctorOptions
                } else { // 醫師選項不為空時
                    return doctorOptions.filter(doctor => {
                        //無代班醫生，輸入的text與醫生號碼或醫生名稱是否與其中一個一樣
                        const doctorMatch = (stringIsEmpty(doctor.locumDoctor) || stringIsEmpty(doctor.locumDoctorName)) && 
                                            (doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText));
                        //代班醫生，輸入的text與代班醫生號碼或代班醫生名稱是否與其中一個一樣
                        const locumMatch = !stringIsEmpty(doctor.locumDoctor) && !stringIsEmpty(doctor.locumDoctorName) && 
                                           (doctor.locumDoctor.includes(doctorText) || doctor.locumDoctorName.includes(doctorText));
                        return doctorMatch || locumMatch
                    })
                }
            } else { // 科別選項不為空時
                //取得科別切割字串
                const splitDepartmentArray = divText.split(" ")
                //已經有選擇科別
                if (splitDepartmentArray.length === 2) {
                    if(splitArray.length === 0) {
                        //醫生為空
                        //求聯集
                        return doctorOptions
                            .filter(doctor => clinicOptions.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .map(doctor => new Object({...doctor}))
                    }
                    else if(splitArray.length === 1) {
                        //醫生有1段
                        return doctorOptions
                            .filter(doctor => clinicOptions.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .filter(doctor => {
                                const doctorMatch = (stringIsEmpty(doctor.locumDoctor) || stringIsEmpty(doctor.locumDoctorName)) && 
                                                    (doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText));
                                const locumMatch = !stringIsEmpty(doctor.locumDoctor) && !stringIsEmpty(doctor.locumDoctorName) && 
                                                    (doctor.locumDoctor.includes(doctorText) || doctor.locumDoctorName.includes(doctorText));
                                return doctorMatch || locumMatch
                            })
                            .map(doctor => new Object({...doctor}))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//clinicOptions.filter(div => div.doctorNo === appointmentData.doctorNo)
                }
            }
        }
    }

    /**
     * 依照日期與APN查詢診室(包含醫生與科別清單)
     */
    const getRegsQueryBasicDataClinicRes = () => {
        //依照日期與APN查詢診室(包含醫生與科別清單)
        regsQueryBasicDataClinicRes({
            //門診日期
            encounterDate: time2String(appointmentData.encounterDate, "YYYY-MM-DD 00:00:00"),
            //時段
            apn: appointmentData.apn
        }).then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //找出有代診醫師的醫師array
                const haveLocumDoctorArray = data.doctorList.filter(doctor => !stringIsEmpty(doctor.locumDoctor) && !stringIsEmpty(doctor.locumDoctorName))
                //找出有多診間的醫師且有兩個狀態 代診、一般看診
                const fundedDoctorList = haveLocumDoctorArray.filter(doctor => {
                    const funded = data.clinicList.find(clinic => clinic.doctorNo === doctor.doctorNo && stringIsEmpty(clinic.locumDoctor))
                    return !objectIsEmpty(funded)
                }).map(doctor => new Object({
                    "doctorNo": doctor.doctorNo,
                    "doctorName": doctor.doctorName,
                    "stopClinicFlag": doctor.stopClinicFlag,
                    "locumDoctor": null,
                    "locumDoctorName": null
                }))
                //設定醫師選項
                setDoctorOptions([...data.doctorList, ...fundedDoctorList])
                //設定科別選項
                setDivOptions(data.divList)
                //設定診間選項
                setClinicOptions(data.clinicList)
            }
        })
    }

    /**
     * 處理醫師搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value
        //值為空
        if (stringIsEmpty(value)) {
            // 設定醫師選項
            setAppointmentData({
                ...appointmentData,
                doctorNo: value,
                //是否有代診醫生
                isLocumDoctor: false,
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: ""
            })
        } else {
            // 設定醫師選項
            setAppointmentData({...appointmentData, doctorNo: value})
        }
    }

    /**
     * 處理醫師搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorSearchTextOnFocus = () => {
        serFilterDoctorOptions(getDoctorOptionList())
        setShowDoctorOptions(true)
    }

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            //是否有代診醫師
            const isLocumDoctor = !stringIsEmpty(option.locumDoctor) && !stringIsEmpty(option.locumDoctorName)
            // 醫師編號
            const no = isLocumDoctor ? option.locumDoctor : option.doctorNo
            // 醫師名稱
            const name = isLocumDoctor ? option.locumDoctorName : option.doctorName
            //取得科別切割字串
            const splitDepartmentArray = appointmentData.divNo.split(" ")
            //已經有選擇科別
            if (splitDepartmentArray.length === 2) {
                //取得科別編號
                const id = splitDepartmentArray[0]
                //取得相關的診間資訊
                const resultArray = clinicOptions.filter(clinic => {
                    let isMatch = clinic.doctorNo === option.doctorNo && clinic.divNo === id
                    if(isLocumDoctor && isMatch) {
                        isMatch = clinic.locumDoctor === option.locumDoctor
                    }
                    return isMatch
                })
                //只有數量等於1才顯示診間資訊
                if (resultArray.length === 1) {
                    
                    //取得診間資訊
                    const clinicInfo = resultArray[0]
                    // 設定醫師選項
                    setAppointmentData({
                        ...appointmentData,
                        //月班識別碼
                        clinicId: clinicInfo.clinicId,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo: `${no} ${name}`, 
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //診間號
                        clinicNo: clinicInfo.clinicNo,
                        //診室名稱
                        clinicName: clinicInfo.clinicName,
                        //診室地點
                        clinicLocation: clinicInfo.clinicLocation,
                        //停診
                        stopClinicFlag: clinicInfo.stopClinicFlag,
                        //給號方式
                        giveNumType: clinicInfo.giveNumType
                    })
                } else {
                    if(resultArray.length > 1) {
                        //有多個時顯示日班表
                        handleClinicScheduleOnClick()
                    }
                    else {
                        // 設定醫師選項
                        setAppointmentData({
                            ...appointmentData,
                            //醫師編號 (有代診醫師就代入代診醫師的)
                            doctorNo: `${no} ${name}`,
                            //是否有代診醫師
                            isLocumDoctor: isLocumDoctor
                        })
                    }
                }

            } else { // 科別選項為空時，找尋此醫師只有一個診間時自動帶入科別診間資料
                //找尋此醫師診間
                const findedClinic  = clinicOptions.filter(clinic => {
                    let isMatch = clinic.doctorNo === option.doctorNo
                    if(isLocumDoctor && isMatch) {
                        isMatch = clinic.locumDoctor === option.locumDoctor
                    }
                    return isMatch
                })
                //找尋科別
                let findedDiv = null
                if(!arrayIsEmpty(findedClinic) && findedClinic.length === 1) {
                    //當只有一個診間時，搜尋科別資料
                    findedDiv =  divOptions.find(item => item.divNo === findedClinic[0].divNo);
                }

                if(objectIsEmpty(findedDiv)) {
                    // 沒有科別資料時
                    // 設定醫師選項
                    setAppointmentData({
                        ...appointmentData,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo: `${no} ${name}`,
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor
                    })
                }
                else {
                    //有科別資料時，
                    //設定醫師、科別、診間資料
                    setAppointmentData({
                        ...appointmentData,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo: `${no} ${name}`,
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //科別編號
                        divNo: `${findedDiv.divNo} ${findedDiv.divName}`,

                        //月班識別碼
                        clinicId: findedClinic[0].clinicId,
                        //診間號
                        clinicNo: findedClinic[0].clinicNo,
                        //診室名稱
                        clinicName: findedClinic[0].clinicName,
                        //診室地點
                        clinicLocation: findedClinic[0].clinicLocation,
                        //停診
                        stopClinicFlag: findedClinic[0].stopClinicFlag,
                        //給號方式
                        giveNumType: findedClinic[0].giveNumType
                    })
                }
            }
        }

        setShowDoctorOptions(false)
    }

    /**
     * 處理看診日期變更
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEncounterDateOnChange = (e) => setAppointmentData({
        ...appointmentData,
        //門診日期
        encounterDate: e.target.value,
        //醫師代碼
        doctorNo: "",
        //是否有代理醫生
        isLocumDoctor: false,
        //科別代碼
        divNo: "",
        //診間id
        clinicId: "",
        //診間號
        clinicNo: "",
        //診室名稱
        clinicName: "",
        //診室地點
        clinicLocation: "",
        //停診
        stopClinicFlag: null,
        //給號方式
        giveNumType: ""
    })

    /**
     * 處理科別搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value
        //值為空
        if (stringIsEmpty(value)) {
            // 設定醫師選項
            setAppointmentData({
                ...appointmentData,
                divNo: value,
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: ""
            })
        } else { //值不為空
            // 設定醫師選項
            setAppointmentData({...appointmentData, divNo: value})
        }
    }

    /**
     * 處理科別搜尋文字框焦點事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnFocus = () => {
        serFilterDivOptions(getDepartmentOptionList())
        setShowDivOptions(true)
    }

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo
            // 科別名稱
            const name = option.divName
            // 醫師選項不為空時
            if (!stringIsEmpty(appointmentData.doctorNo)) {
                //取得醫生切割字串
                const splitDoctorArray = appointmentData.doctorNo.split(" ")
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    //是否有代診醫師
                    const isLocumDoctor = (appointmentData.isLocumDoctor === undefined || appointmentData.isLocumDoctor === null) ? false : appointmentData.isLocumDoctor
                    //取得醫生編號
                    const id = splitDoctorArray[0]
                    //取得相關的診間資訊
                    const resultArray = clinicOptions.filter(clinic => {
                        let isMatch = false
                        if(isLocumDoctor) {
                            isMatch = clinic.divNo === option.divNo && clinic.locumDoctor === id
                        }
                        else {
                            isMatch = clinic.divNo === option.divNo && clinic.doctorNo === id
                        }
                        return isMatch
                    })
                    //只有數量等於1才顯示診間資訊
                    if (resultArray.length === 1) {
                        //取得診間資訊
                        const clinicInfo = resultArray[0]
                        // 設定科別選項
                        setAppointmentData({
                            ...appointmentData,
                            //月班識別碼
                            clinicId: clinicInfo.clinicId,
                            //科別編號
                            divNo: `${no} ${name}`,
                            //診間號
                            clinicNo: clinicInfo.clinicNo,
                            //診室名稱
                            clinicName: clinicInfo.clinicName,
                            //診室地點
                            clinicLocation: clinicInfo.clinicLocation,
                            //停診
                            stopClinicFlag: clinicInfo.stopClinicFlag,
                            //給號方式
                            giveNumType: clinicInfo.giveNumType
                        })
                    } else { //數量不等於1時
                        if (resultArray.length > 1) {
                            //有多個時顯示日班表
                            handleClinicScheduleOnClick()
                        }
                        else {
                            //
                            setAppointmentData({
                            ...appointmentData,
                            divNo: `${no} ${name}`,
                        })
                        }

                    }
                }
            } else { // 醫師選項為空時，找尋此科別只有一個診間時自動帶入科別診間資料
                // 設定科別選項
                setAppointmentData({
                    ...appointmentData,
                    divNo: `${no} ${name}`,
                })

                //找尋此診間
                const findedClinic  = clinicOptions.filter(clinic => clinic.divNo === no)
                //找尋醫生
                let findedDoctor = null
                if(!arrayIsEmpty(findedClinic) && findedClinic.length === 1) {
                    //當只有一個診間時，搜尋科別資料
                    findedDoctor =  doctorOptions.find(item => item.doctorNo === findedClinic[0].doctorNo);
                }

                if(objectIsEmpty(findedDoctor)) {
                    // 沒有科別資料時
                    // 設定科別選項
                    setAppointmentData({
                        ...appointmentData,
                        divNo: `${no} ${name}`,
                    })
                }
                else {
                    const isLocumDoctor = !stringIsEmpty(findedDoctor.locumDoctor) && !stringIsEmpty(findedDoctor.locumDoctorName)
                    //有科別資料時
                    //設定醫師、科別、診間資料
                    setAppointmentData({
                        ...appointmentData,
                        //醫師編號
                        doctorNo: isLocumDoctor ? `${findedDoctor.locumDoctor} ${findedDoctor.locumDoctorName}` : `${findedDoctor.doctorNo} ${findedDoctor.doctorName}`,
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //科別編號
                        divNo: `${no} ${name}`,

                        //月班識別碼
                        clinicId: findedClinic[0].clinicId,
                        //診間號
                        clinicNo: findedClinic[0].clinicNo,
                        //診室名稱
                        clinicName: findedClinic[0].clinicName,
                        //診室地點
                        clinicLocation: findedClinic[0].clinicLocation,
                        //停診
                        stopClinicFlag: findedClinic[0].stopClinicFlag,
                        //給號方式
                        giveNumType: findedClinic[0].giveNumType
                    })
                }
            }
        }

        setShowDivOptions(false)
    }

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const divText = appointmentData.divNo.trim()
        const doctorText = appointmentData.doctorNo.trim()
        //切割科別字串
        const splitArray = divText ? divText.split(" ") : []
        //已經有選擇科別
        if (splitArray.length === 2) {
            return divOptions.filter(div => div.divNo.includes(splitArray[0]) && div.divName.includes(splitArray[1]))
        } else { //透過輸入文字
            // 醫生選項為空時
            if (stringIsEmpty(doctorText)) {
                // 科別選項為空時
                if (stringIsEmpty(divText)) {
                    return divOptions
                } else { // 科別選項不為空時
                    return divOptions.filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                }
            } else { // 醫生選項不為空時
                //取得醫生切割字串
                const splitDoctorArray = doctorText.split(" ")
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    if(splitArray.length === 0) {
                        //科別為空
                        //求聯集
                        return divOptions
                            .filter(div => clinicOptions.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    if(splitArray.length === 1) {
                        //科別有1段
                        return divOptions
                            .filter(div => clinicOptions.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//clinicOptions.filter(div => div.doctorNo === appointmentData.doctorNo)
                }
            }
        }
    }

    /**
     * 關閉診間當日班表時
     * @param item {Object|Null} 診間資訊
     * @return {void}
     */
    const handleClinicScheduleOnClose = (item = null) => {
        // 診間資訊不為空
        if (!objectIsEmpty(item)) {

        }
        // 關閉診間當日班表
        setShowClinicSchedule(false)
        // 設定診間當日班表資料
        setClinicOfDayScheduleData(null)
    }

    /**
     * 關閉診間當周班表時
     * @param item {Object|Null} 診間資訊
     * @return {void}
     */
    const handleWeeklySchedulePopupOnClose = () => {
        setShowWeeklySchedulePopup(false)
    }

    /**
     * 處理診間當日班表點擊事件
     */
    const handleClinicScheduleOnClick = () => {
        //設定診間當日班表資料
        setClinicOfDayScheduleData({
            //科別代碼
            divNo: appointmentData.divNo ? appointmentData.divNo : null,
            //醫生代碼
            doctorNo: appointmentData.doctorNo ? appointmentData.doctorNo : null,
            //時段
            apn: appointmentData.apn,
            //日期
            date: appointmentData.encounterDate ? appointmentData.encounterDate : null,
            //院區列表
            zonesetList: baseData.zonesetList,
            //院區
            zone: appointmentData.zone
        })

        //顯示診間當日班表
        setShowClinicSchedule(true)
    }

    /**
     * 處理診間當周班表點擊事件
     */
    const handleWeeklyScheduleOnClick = () => {
        setWeeklyScheduleBaseData({
            //科別代碼
            divNo: appointmentData.divNo ? appointmentData.divNo : null,
            //醫生代碼
            doctorNo: appointmentData.doctorNo ? appointmentData.doctorNo : null,
            //日期
            date: appointmentData.encounterDate ? appointmentData.encounterDate : null,
            //院區列表
            zonesetList: baseData.zonesetList,
            //院區
            zone: appointmentData.zone
        })
        
        setShowWeeklySchedulePopup(true)
    }

    /**
     * 新增給診號
     */
    const handleAddRegsOnClick = () => {
        let startViewDatetime = ""
        let endViewDatetime = ""
        if(appointmentData.giveNumType === "2") {
            if(appointmentData.giveNumStartTime === null || appointmentData.giveNumEndTime === null) {
                const msg = `給診失敗: 此診間為依時間給號，尚未設定時間`
                showToast({message:msg, type:ToastTypeEnum.Error})
                return
            }
            // 給號方式依時間
            startViewDatetime = time2String(appointmentData.giveNumStartTime, "YYYY-MM-DD HH:mm:00")
            endViewDatetime = time2String(appointmentData.giveNumEndTime, "YYYY-MM-DD HH:mm:00")
        }

        const fundedClinic = clinicOptions.find(item => item.clinicId === appointmentData.clinicId)

        let doctorNo = appointmentData.doctorNo ? appointmentData.doctorNo.split(' ')[0] : null
        if(!objectIsEmpty(fundedClinic)) {
            doctorNo = fundedClinic.doctorNo
        }

        //判斷有無病歷號
        if(stringIsEmpty(appointmentData.patientId))
        {
            //沒有病歷號執行初診預約存檔
            let genderNo = 3
            if(patientData.genderName == "男")
                genderNo = 1
            else if(patientData.genderName == "女")
                genderNo = 2
            else if(patientData.genderName == "不明")
                genderNo = 3
            
            regsAddFirstview({
                encounterDate: `${appointmentData.encounterDate} 00:00:00`, //看診日期
                apn: appointmentData.apn, //時段
                divNo: appointmentData.divNo ? appointmentData.divNo.split(' ')[0] : null, //科別
                doctorNo: doctorNo, //醫師
                zone: appointmentData.zone, //院區代碼
                clinicId: appointmentData.clinicId, //診間id
                clinicNo: appointmentData.clinicNo, //診間號
                clinicName: appointmentData.clinicName, //診室名稱
                idNo: patientData.idNo,
                patientName: patientData.patientName,
                birthDate: time2String(patientData.birthDate, "YYYY-MM-DD 00:00:00"),
                gender: genderNo,
                phonenumber: stringIsEmpty(patientData.phonenumber) ? "":patientData.phonenumber,
                homephonenumber: stringIsEmpty(patientData.homephonenumber) ? "":patientData.homephonenumber,
                ptType: appointmentData.ptType,
                startViewDatetime: startViewDatetime,
                endViewDatetime: endViewDatetime
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    let apnName;
                    if(res.data.apn === 1)
                        apnName = "早"
                    else if(res.data.apn ===2)
                        apnName = "午"
                    else
                        apnName = "晚"
                    //新增初診預約成功
                    const givenNumberData = {
                        //regId: "018653a7-237e-4095-bf75-2881546e5cf6",
                        patientId: stringIsEmpty(res.data.patientId)? "" : res.data.patientId,
                        patientName: res.data.patientName,
                        //zone: "ED",
                        zoneName: stringIsEmpty(res.data.zoneName)? "" : res.data.zoneName,
                        encounterDate: res.data.encounterDate,
                        apn: res.data.apn,
                        apnName: apnName,
                        //clinicNo: "6001",
                        clinicName: res.data.clinicName,
                        //doctorNo: "1669",
                        doctorName: res.data.doctorName,
                        // 代班醫生
                        locumDoctor: res.data.locumDoctor,
                        //divNo: "60",
                        divName: res.data.divName,
                        viewNo: res.data.viewNo,
                        startViewDatetime: res.data.startViewDatetime,
                        //endViewDatetime: "2024-04-17 13:50:00"
                    }

                    //設定給診號資料
                    setGiveNumberData(givenNumberData)

                    //新增成功
                    setTimeout(() => setShowGiveNumberPopup(true), 150)

                    triggerResetDefault(true)
                }
                else {
                    const msg = `給診失敗: ${res.msg}`
                    showToast({message:msg, type:ToastTypeEnum.Error})
                }
            })
        }
        else
        {
            //新增預約資料
            regsAddReg({
                "patientId": appointmentData.patientId, //病例ID
                "zone": appointmentData.zone, //院區代碼
                "clinicId": appointmentData.clinicId, //月班識別碼
                "encounterDate": `${appointmentData.encounterDate} 00:00:00`, //看診日期
                "apn": appointmentData.apn, //時段
                "divNo": appointmentData.divNo ? appointmentData.divNo.split(' ')[0] : null, //科別
                "doctorNo": doctorNo, //醫師
                "clinicNo": appointmentData.clinicNo, //診間號
                "clinicName": appointmentData.clinicName, //診室名稱
                "ptType": appointmentData.ptType, //身份別
                "reserveType": appointmentData.reserveType, //掛號別
                "forwardRegFlag": appointmentData.fowardRegFlag, //是否可往前掛號
                "startViewDatetime": startViewDatetime, //掛號看診開始時間 依時間給號時必填
                "endViewDatetime": endViewDatetime  //掛號看診結束時間 依時間給號時必填
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定給診號資料
                    setGiveNumberData(res.data)
                    
                    updateGetRegsQueryRegHistory(appointmentData.patientId)
                    //新增成功
                    setTimeout(() => setShowGiveNumberPopup(true), 150)

                    triggerResetDefault(true)
                }
                else {
                    const msg = `給診失敗: ${res.msg}`
                    showToast({message:msg, type:ToastTypeEnum.Error})
                }
            })
        }
        
    }

    /**
     * 加掛按鈕點擊事件
     */
    const handleExtraAddRegsOnClick = () => {
        if(stringIsEmpty(appointmentData.patientId))
            return

        const fundedClinic = clinicOptions.find(item => item.clinicId === appointmentData.clinicId)

        let doctorNo = appointmentData.doctorNo ? appointmentData.doctorNo.split(' ')[0] : null
        if(!objectIsEmpty(fundedClinic)) {
            doctorNo = fundedClinic.doctorNo
        }
        
        //新增預約資料
        regsAddReg({
            "patientId": appointmentData.patientId, //病例ID
            "zone": appointmentData.zone, //院區代碼
            "clinicId": appointmentData.clinicId, //月班識別碼
            "encounterDate": `${appointmentData.encounterDate} 00:00:00`, //看診日期
            "apn": appointmentData.apn, //時段
            "divNo": appointmentData.divNo ? appointmentData.divNo.split(' ')[0] : null, //科別
            "doctorNo": doctorNo, //醫師
            "clinicNo": appointmentData.clinicNo, //診間號
            "clinicName": appointmentData.clinicName, //診室名稱
            "ptType": appointmentData.ptType, //身份別
            "reserveType": appointmentData.reserveType, //掛號別
            "forwardRegFlag": appointmentData.fowardRegFlag, //是否可往前掛號
            "extraAddFlag": true
        }).then(res => {
            //新增成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //設定給診號資料
                setGiveNumberData(res.data)
                //重置資料
                resetAppointmentData()
                updateGetRegsQueryRegHistory(appointmentData.patientId)
                //新增成功
                setTimeout(() => setShowGiveNumberPopup(true), 150)
            }
            else {
                const msg = `加掛失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
            
        })
    }

    /**
     * 關閉掛號完成的彈窗
     */
    const handleCloseGiveNumberPopup = () => {
        setGiveNumberData({})
        setShowGiveNumberPopup(false)
    }

    /**
     * 點擊診號狀況按鈕事件
     */
    const handleClinicNoStateOnClick = () => {
        if(appointmentData.giveNumType === "2") {
            // 給號方式依時間
            setShowCalendar(true)
        }
        else {
            setShowClinicNoStatePopup(true)
        }
    }

    /**
     * 關閉診號狀況彈窗
     */
    const handleCloseClinicNoStatePopup = () => {
        setShowClinicNoStatePopup(false)
    }

    /**
     * 診間選擇事件
     * @param {Time} date 門診日期
     * @param {Object} clinicData 門診資料
     */
    const onSelectClinic = (date, clinicData) => {
        //是否有代診醫師
        const isLocumDoctor = !stringIsEmpty(clinicData.locumDoctor) && !stringIsEmpty(clinicData.locumDoctorName)
        // 設定科別選項
        setAppointmentData({
            ...appointmentData,
            //門診日期
            encounterDate: time2String(date, "YYYY-MM-DD"),
            //時段
            apn: `${clinicData.apn}`,
            //月班識別碼
            clinicId: clinicData.clinicId,
            //醫師
            doctorNo: isLocumDoctor ? `${clinicData.locumDoctor} ${clinicData.locumDoctorName}` : `${clinicData.doctorNo} ${clinicData.doctorName}`,
            //是否有代診醫師
            isLocumDoctor: isLocumDoctor,
            //科別編號
            divNo: `${clinicData.divNo} ${clinicData.divName}`,
            //診間號
            clinicNo: clinicData.clinicNo,
            //診室名稱
            clinicName: clinicData.clinicName,
            //診室地點
            clinicLocation: clinicData.clinicLocation,
            //停診
            stopClinicFlag: clinicData.stopClinicFlag,
            //給號方式
            giveNumType: clinicData.giveNumType
        })
    }

    /**
     * 檢查給診號按鈕狀態
     */
    const detectAddRegsButtonState = () => {
        if (!stringIsEmpty(appointmentData.divNo) && 
            !stringIsEmpty(appointmentData.doctorNo) && 
            (appointmentData.stopClinicFlag === undefined || appointmentData.stopClinicFlag === null || appointmentData.stopClinicFlag === false) &&
            !objectIsEmpty(patientData)) 
        {
            setDisableAddRegsButton(false)
        }
        else {
            setDisableAddRegsButton(true)
        }
    }

    /**
     * 掛號給號時間行事曆儲存事件
     * @param {Object} timeRangObj 時間區間
     */
    const handleAppointmentCalendarOnSave = (timeRangObj) => {
        setAppointmentData({
            ...appointmentData,
            //給號開始時間
            giveNumStartTime: objectIsEmpty(timeRangObj) ? null : timeRangObj.start,
            //給號結束時間
            giveNumEndTime: objectIsEmpty(timeRangObj) ? null : timeRangObj.end,
        })
    }

    const getGiveNumberTimeRangeText = () => {
        if(!objectIsEmpty(appointmentData)) {
            const start = time2String(appointmentData.giveNumStartTime, "HH:mm")
            const end = time2String(appointmentData.giveNumEndTime, "HH:mm")
            return (stringIsEmpty(start) || stringIsEmpty(end)) ? "" : `${start} ~ ${end}`
        }
        return ""
    }

    /**
     * 取得醫師option項目額外的字樣
     * @param {Object} doctorItem 
     */
    const getDoctorOptionItemExtendText = (doctorItem) => {
        let text = ''
        if(!objectIsEmpty(doctorItem)) {
            if(stringIsEmpty(doctorItem.locumDoctor) || stringIsEmpty(doctorItem.locumDoctorName)) {
                if(doctorItem.stopClinicFlag !== null && doctorItem.stopClinicFlag) {
                    text = '[停]'
                }
            }
            else {
                text = '[代]'
            }
        }
        return text
    }

    /**
     * 監聽基本資料變更
     * @return {void}
     */
    useEffect(() => {
        // 基本資料不為空時
        if (baseData !== null) {
            //找出有代診醫師的醫師array
            const haveLocumDoctorArray = baseData.doctorList.filter(doctor => !stringIsEmpty(doctor.locumDoctor) && !stringIsEmpty(doctor.locumDoctorName))
            //找出有多診間的醫師且有兩個狀態 代診、一般看診
            const fundedDoctorList = haveLocumDoctorArray.filter(doctor => {
                const funded = baseData.clinicList.find(clinic => clinic.doctorNo === doctor.doctorNo && stringIsEmpty(clinic.locumDoctor))
                return !objectIsEmpty(funded)
            }).map(doctor => new Object({
                "doctorNo": doctor.doctorNo,
                "doctorName": doctor.doctorName,
                "stopClinicFlag": doctor.stopClinicFlag,
                "locumDoctor": null,
                "locumDoctorName": null
            }))
            // 設定醫師選項
            setDoctorOptions([...baseData.doctorList, ...fundedDoctorList])
            // 設定科別選項
            setDivOptions(baseData.divList)
            // 設定診間選項
            setClinicOptions(baseData.clinicList)
            // 設定時段
            setAppointmentData({
                ...appointmentData,
                apn: baseData.apn
            })
            //在掛號別內加disabled的property
            const updateRegistrationList =  baseData.registrationList.map(obj => ({
                ...obj,
                disabled: false
            }));
            setRegistrationList((prev) => updateRegistrationList)
        }
    }, [baseData])

    /**
     * 監聽門診時段變更時
     * @return {void}
     */
    useEffect(() => {
        // 門診資料以及門診日期不為空且不等於當天時
        if (appointmentData && !stringIsEmpty(appointmentData.encounterDate)) {
            getRegsQueryBasicDataClinicRes()
        }

        const inRange = isCurrentDateTimeWithinRange(appointmentData.encounterDate, parseInt(appointmentData.apn))
        if(registrationList) {
            let updatedList;
            let reserveType = appointmentData.reserveType;
            if(inRange) {
                updatedList = registrationList.map(obj => ({
                    ...obj,
                    disabled: obj.justnameNo === "2"
                }));
                //如果原本是預約要改為現掛，原本是電話則不變
                if(appointmentData.reserveType === "2")
                    reserveType = "1"
            }
            else {
                updatedList = registrationList.map(obj => ({
                    ...obj,
                    disabled: obj.justnameNo === "1"
                }));
                //如果原本是現掛要改為預約，原本是電話則不變
                if(appointmentData.reserveType === "1")
                    reserveType = "2"
            }
            setRegistrationList((prev) => updatedList)
            setAppointmentData({...appointmentData, reserveType: reserveType})
            
        }
    }, [appointmentData.encounterDate, appointmentData.apn])

    /**
     * 監聽病例ID變更
     */
    useEffect(() => {
        if (!objectIsEmpty(patientData)) {
            // 設定病例ID
            setAppointmentData({...appointmentData, patientId: patientData.patientId})
            setDisableExtraAddRegsButton(stringIsEmpty(patientData.patientId))
        }
        else {
            setDisableExtraAddRegsButton(true)
        }
        detectAddRegsButtonState()   
    }, [patientData])

    /**
     * 監聽科別  醫生 變化
     */
    useEffect(() => {
        serFilterDivOptions(getDepartmentOptionList())
        serFilterDoctorOptions(getDoctorOptionList())
        detectAddRegsButtonState()

        //判斷有沒有診室
        if (!stringIsEmpty(appointmentData.divNo) && !stringIsEmpty(appointmentData.doctorNo)) {
            setHaveClinic(true)
        }
        else {
            setHaveClinic(false)
        }
    }, [appointmentData.divNo, appointmentData.doctorNo])

    return (
        <InfoCard titles="看診資料"> 
            <div className="flex flex-col text-left text-base space-y-2">
                <div className="space-y-2">
                    <h1 className="text-xl font-bold">掛號方式</h1>
                    {/*掛號別*/}
                    <div className="flex w-full flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">掛號別</label>
                        <select
                            className="text-base text-[#3F3F46] px-2 p-1 border-2 border-[#D4D4D8] rounded-md w-[160px]"
                            value={appointmentData.reserveType} onChange={(e) => handleReserveTypeOnChange(e)}>
                            {
                                baseData && registrationList &&
                                registrationList.map((registration, index) =>
                                    <option 
                                        className={`${registration.disabled?"text-[#d4d4d8]":""}`}
                                        key={index} 
                                        value={registration.justnameNo}
                                        disabled={registration.disabled}>
                                        {registration.justnameName}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                    {/*身份別*/}
                    <div className="flex w-full flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">身份別</label>
                        <select
                            className="text-base px-2 p-1 text-[#3F3F46] border-2 border-[#D4D4D8] rounded-md w-[160px]"
                            value={appointmentData.ptType} onChange={(e) => handlePtTypeOnChange(e)}>
                            {
                                baseData && baseData.pttypeList &&
                                baseData.pttypeList.map((ptType, index) =>
                                    <option key={index} value={ptType.ptType}>
                                        {ptType.typeName}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                    <div className="flex w-full flex-row space-x-4 items-center">
                        <div className="w-1/4 2xl:w-1/5 text-right"></div>
                        <div className="flex flex-row space-x-4">
                            {/*電話預約*/}
                            <div className="flex flex-row space-x-3">
                                <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                                    style={{accentColor: "#38A169"}} checked={appointmentData.phoneRegFlag}
                                    onChange={handlePhoneRegFlagOnChange}/>
                                <label className="text-[#E53E3E] text-base">電話預約</label>
                            </div>
                            {/*補輸*/}
                            <div className="flex flex-row space-x-3">
                                <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                                    style={{accentColor: "#38A169"}} checked={appointmentData.fowardRegFlag}
                                    onChange={handleFowardRegFlagOnChange}/>
                                <label className="text-[#3F3F46] text-base">補輸</label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-col text-left text-base space-y-3">
                    <div className="w-full h-0.5 bg-gray-300 mt-1"></div>
                    <h1 className="text-xl font-bold">掛號</h1>
                    {/*預掛院區*/}
                    <div className="flex w-full flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">預掛院區</label>
                        <select
                            className="text-base w-[160px] px-2 p-1 border-2 text-[#3F3F46] border-[#D4D4D8] rounded-md"
                            value={appointmentData.zone}
                            onChange={(e) => handleZoneOnChange(e)}>
                            {
                                baseData && baseData.zonesetList &&
                                baseData.zonesetList.map((zone, index) =>
                                    <option key={index} value={zone.zone}>{zone.zoneName}</option>
                                )
                            }
                        </select>
                    </div>
                    {/*門診日期*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t("Regs.general.clinicDate")}</label>
                        <BaseInput 
                            className="border-2 w-[160px] px-2 pl-4 p-1 text-[#3F3F46] border-[#D4D4D8] rounded-md"
                            type={InputTypeEnum.Date}
                            //沒有勾選補輸限制選擇日期
                            min={appointmentData.fowardRegFlag? "" : time2String(new Date(), "YYYY-MM-DD")}
                            value={appointmentData.encounterDate}
                            onChange={(e) => handleEncounterDateOnChange(e)}/>
                    </div>
                    {/*時段*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t("Regs.general.timeSlot")}</label>
                        {
                            baseData && baseData.apnList &&
                            baseData.apnList.map((apn, index) => (
                                <div className="flex flex-row space-x-2 text-[#3F3F46] cursor-pointer" key={index}
                                        onClick={() => handleApnOnClick(apn.justnameNo)}>
                                    <input className="checkbox" type="radio" name="apn"
                                            checked={appointmentData.apn.toString() === apn.justnameNo}
                                            onChange={() => {}}/>
                                    <label>{apn.justnameName}</label>
                                </div>
                            ))
                        }
                    </div>
                    {/*科別*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t('general.department')}</label>
                        <div ref={dropdownDepartmentRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3 ">
                            <input
                                className="bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8]"
                                value={appointmentData.divNo}
                                onChange={(e) => handleDepartmentSearchTextOnChange(e)}
                                onFocus={handleDepartmentSearchTextOnFocus}/>
                            <img
                                className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                alt="search-icon"
                                src={searchIcon}
                                role="button"/>
                            {
                                showDivOptions && !arrayIsEmpty(filterDivOptions) && (
                                <div className="bg-white z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                    {/*科別下拉*/}
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            filterDivOptions.map((div, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDepartmentOptionOnClick(div)}>

                                                    <p>{`${div.divNo}`}</p>
                                                    <p>{`${div.divName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                )
                            }
                        </div>
                    </div>
                    {/*醫師*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t('general.doctor')}</label>
                        <div ref={dropdownDoctorRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3">
                            <input
                                className="bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8]"
                                value={appointmentData.doctorNo}
                                onChange={(e) => handleDoctorSearchTextOnChange(e)}
                                onFocus={handleDoctorSearchTextOnFocus}
                            />
                            <img
                                className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                alt="search-icon"
                                src={searchIcon}
                                role="button"/>
                            {
                                showDoctorOptions && !arrayIsEmpty(filterDoctorOptions) > 0 &&
                                <div className="bg-white z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                    {/*醫師下拉*/}
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            filterDoctorOptions.map((doctor, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                    <p className="">{`${(stringIsEmpty(doctor.locumDoctor) || stringIsEmpty(doctor.locumDoctorName))?doctor.doctorNo:doctor.locumDoctor}`}</p>
                                                    <p>{`${(stringIsEmpty(doctor.locumDoctor) || stringIsEmpty(doctor.locumDoctorName))?doctor.doctorName:doctor.locumDoctorName}${getDoctorOptionItemExtendText(doctor)}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/*診間*/}
                    <div className="flex flex-row space-x-4 items-center relative text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t("Regs.general.clinic")}</label>
                        <div>{appointmentData.clinicNo}</div>
                    </div>
                    {/*診室名稱*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t("Regs.general.clinicName")}</label>
                        <div>{appointmentData.clinicName}</div>
                    </div>
                    {/*診室地點*/}
                    <div className="flex flex-row space-x-4 items-center text-[#18181B]">
                        <label className="w-1/4 2xl:w-1/5 text-right">{t("Regs.general.clinicLocation")}</label>
                        <div>{appointmentData.clinicLocation}</div>
                    </div>
                    <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-row space-x-2">
                            {/*當日班表*/}
                            <button className="border-2 border-[#d4d4d8] bg-[#fafafa] hover:bg-[#f4f4f5] active:bg-[#E4E4E7] p-1.5 px-2 font-basic rounded-md"
                                    onClick={handleClinicScheduleOnClick}>
                                當日班表
                            </button>
                            {/*週班表*/}
                            <button className="border-2 border-[#d4d4d8] bg-[#fafafa] hover:bg-[#f4f4f5] active:bg-[#E4E4E7] p-1.5 px-2 font-basic rounded-md"
                                    onClick={handleWeeklyScheduleOnClick}>
                                當週班表
                            </button>
                        </div>
                        <div className="flex justify-end">
                            <button 
                                className={`border-2 border-[#d4d4d8] bg-[#fafafa] p-1.5 px-2 font-basic rounded-md
                                            ${stringIsEmpty(appointmentData.clinicId) ? "text-[#d4d4d8]" : "hover:bg-[#f4f4f5] active:bg-[#E4E4E7]"}`}
                                onClick={handleClinicNoStateOnClick}
                                // onClick={() => setShowCalendar(true)}
                                disabled={stringIsEmpty(appointmentData.clinicId)}>
                                診號狀況
                            </button>
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-gray-300 mt-1"></div>
                    <button className={`${disableAddRegsButton ? "bg-[#D4D4D8]": "bg-[#2B6CB0] hover:bg-[#3182CE] active:bg-[#2C5282]"} text-white text-base font-medium rounded-md p-3 px-6 w-48`}
                            onClick={handleAddRegsOnClick}
                            disabled={disableAddRegsButton}>
                        給診號
                    </button>
                    <div className="flex flex-col justify-center space-y-5 text-2xl pt-2 text-[#18181B]">
                        <h1>
                            {getGiveNumberTimeRangeText()}
                        </h1>
                    </div>
                    <div className="pt-10 flex flex-row text-md space-x-8">
                        <button className={`border-2 border-[#d4d4d8] bg-[#fafafa] p-1.5 px-2 font-basic rounded-md
                                            ${disableExtraAddRegsButton?"text-[#d4d4d8]":"hover:bg-[#f4f4f5] active:bg-[#E4E4E7]"}`}
                                onClick={handleExtraAddRegsOnClick}
                                disabled={disableExtraAddRegsButton}>
                            加掛
                        </button>
                    </div>
                </div>
            </div>
            {
                // 顯示掛號完成的彈窗
                <ShowGiveNumberPopup showPopup={showGiveNumberPopup}
                                     data={giveNumberData}
                                     handleClosePopup={handleCloseGiveNumberPopup}/>
            }
            {   //日班表
                <ClinicSchedule 
                    show={showClinicSchedule}
                    closeClinic={handleClinicScheduleOnClose} 
                    data={clinicOfDayScheduleData}
                    onSelectClinic={onSelectClinic}
                    fowardRegFlag={appointmentData.fowardRegFlag}/>
            }
            {
                <ClinicVisitsNumberStatePopup 
                    show={showClinicNoStatePopup}
                    closePopupButtonOnClick={handleCloseClinicNoStatePopup}
                    clinicId={appointmentData.clinicId}
                />
            }
            {showCalendar && 
                <AppointmentCalendar 
                    apn={appointmentData.apn}
                    choiceDate={appointmentData.encounterDate}
                    giveNumberTimeRangeRecord={{start:appointmentData.giveNumStartTime, end:appointmentData.giveNumEndTime}}
                    patientData={patientData}
                    clinicId={appointmentData.clinicId}
                    onSave={handleAppointmentCalendarOnSave}
                    closePopup={() => setShowCalendar(false)}/>
            }
            {    //週班表
                <WeeklySchedule 
                    show={showWeeklySchedulePopup} 
                    data={weeklyScheduleBaseData}
                    closePopupButtonOnClick={handleWeeklySchedulePopupOnClose}
                    onSelectClinic={onSelectClinic}
                    fowardRegFlag={appointmentData.fowardRegFlag}/>
            }
        </InfoCard>
    )
}

export default AppointmentInfo
