import React, { useState } from 'react'
/**
 * 轉診外掛單機上傳程式
 * @returns {JSX.Element}
 */
const ReferralPluginUpload = () => {
    const [searchParams, setSearchParams] = useState({
        throwDate: '',
        encounterEndDate: '',
        patientId: '',
    })

    /**
     * 更新查詢欄位value
     * @param {object} e input event
     */

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className=" p-2">
                    <div className="date flex flex-row items-center justify-start mr-4">
                        <div className="mr-4">拋轉日期:</div>
                        <input
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={searchParams.throwDate}
                            onChange={(e) => updateSearchParams(e, 'throwDate')}
                            type="date"
                        />
                    </div>
                    <div className="flex space-x-2 mt-2">
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            0.清舊資料
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            1.拋轉頭檔
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            2.OR
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            3.出院病摘
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            4.PATH
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            8.LAB
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            9.SOAP
                        </button>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            手動批次
                        </button>

                        <div className="flex flex-row space-x-3 items-center">
                            <input
                                id=""
                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                type="checkbox"
                                style={{ accentColor: '#38A169' }}
                            />
                            <label
                                className="text-[#3F3F46] text-base"
                                htmlFor=""
                            >
                                2-9重拋180天資料
                            </label>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full flex gap-2">
                    <div className="w-[50%] h-full border border-[#1113] overflow-auto rounded-md"></div>
                    <div className="w-[50%] h-full border border-[#1113] overflow-auto rounded-md"></div>
                </div>
            </div>
        </>
    )
}
export default ReferralPluginUpload
