import {createSlice} from "@reduxjs/toolkit"
import {ToastTypeEnum} from "edah_utils/dist"

/**
 * Login Page Slice
 */
export const ToastMessageSlice = createSlice({
    name: 'toastMessage',
    initialState: {
        //訊息
        message: '',
        //訊息類型
        type: ToastTypeEnum.Success,
        //是否顯示
        show: false,
        //顯示時間
        duration: 3000,
        //是否顯示關閉按鈕
        onClickClose: null
    },
    reducers: {
        /**
         * 顯示ToastMessage
         * @param state {Object}
         * @param action {Object} action
         * @param action.payload.message {string} Message
         * @param action.payload.type {Number}
         * @param action.payload.duration {Number}
         * @param action.payload.onClickClose {Function|null}
         * @return {void}
         */
        showToastMessage(state, action) {
            const {message, type = ToastTypeEnum.Success, duration = 3000, onClickClose = null} = action.payload
            state.message = message
            state.type = type
            state.duration = duration
            state.show = true
            // 如果有設定 onClickClose，則設定 onClickClose
            if (onClickClose !== null) {
                state.onClickClose = onClickClose
            }
        },
        /**
         * 隱藏ToastMessage
         * @param state {Object}
         * @return {void}
         */
        hiddenToastMessage(state) {
            state.message = ''
            state.type = ToastTypeEnum.Success
            state.show = false
            state.onClickClose = null
        }
    }
})
export const {showToastMessage, hiddenToastMessage} = ToastMessageSlice.actions
export default ToastMessageSlice.reducer
