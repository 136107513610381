import React, { useState, useCallback, useEffect } from 'react'
import searchIcon from '../../assets/images/search-interface-symbol.png'
import PropTypes from 'prop-types'
import { BaseInput } from '../Input/BaseInput'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/del_square.svg'
import { ReactComponent as AddBtn } from '../../assets/images/icons/add_circle.svg'
import { ReactComponent as SaveIcon } from '../../assets/images/icons/save_default.svg'
import { ReactComponent as CancelIcon } from '../../assets/images/icons/cancel_default.svg'
import CustomSelect from '../Input/CustomSelect'
import {t} from "i18next";
import logOffDeleteBatches from "../RSIS/LogOffDeleteBatches/LogOffDeleteBatches";

const Content = ({row, field, fieldIndex}) => {
    if (field.render) {
        return field.render(row, field, fieldIndex)
    }
    if (row[field.key] && field.format) {
        return field.format(row[field.key])
    }
    return <div>{row[field.key]}</div>
}
const EditContent = ({row, field = [], fields = [], onChange, rowIndex, onBlur}) => {
        // 可編輯欄位
        const editableField = fields?.find(
            (item) => item.key === field.key && !!item.inputType
        )
        if (!!editableField && row.isEditable) {
            const inputState = editableField.validation
                ? editableField.validation(row[field.key])
                : null
            return editableField.inputType === 'select' ? (
                <CustomSelect
                    value={row[field.mappingKey]}
                    onChange={(e) => onChange({...row, [field.mappingKey]: e.target.value}, rowIndex)}
                    options={editableField.options}
                    nameAndValue={editableField.optionNameAndValue}
                />
            ) : (
                <BaseInput
                    className="w-full h-[40px] px-2 bg-white text-black rounded-[6px] border "
                    type={editableField.inputType}
                    value={row[field.key]}
                    onChange={(e) => onChange({...row, [field.key]: e.target.value}, rowIndex)}
                    inputState={inputState}
                    errorMsg={editableField.errorMsg || ''}
                    onBlur={(e) => onBlur(e, row, field.key)}
                />
            )
        }
}



/**
 * @param {object} props
 * @param {array} props.fields  欄位名稱須包含{key: string, name: string, className?: string, isChecked?: boolean}
 * @param {array} props.dataList 列表資料
 * @param {jsx} props.slotButton  可插入功能按鈕(ex. 匯出excel)
 * @param {boolean} props.isSelectable 是否可選取
 * @param {function} props.toggleAll 全選所有資料
 * @param {function} props.toggleItem 選取單筆資料
 * @param {function} props.handleDataFilter 進階搜尋
 * @param {function} props.rowOnClick 點擊 row callback
 * @param {array} props.editableFields 可編輯欄位
 * @param {function} props.handleEdit 編輯列
 * @param {function} props.handleSave 儲存該列資料
 * @param {function} props.handleCancel 取消編輯
 * @param {function} props.addNewData 新增列
 * @param {function} props.deleteRow 刪除列
 */
const CustomTable = ({
    fields,
    dataList = [],
    slotButton = null,
    isSelectable = false,
    toggleAll = () => {},
    toggleItem = () => {},
    isSingleSelect = false,
    title = null,
    isShoeDataFilter = true,
    rowOnClick,
    isCrud = false,
    isEditMode = false,
    handleEdit = () => {},
    handleSave = () => {},
    handleCancel = () => {},
    addNewData = () => {},
    deleteRow = () => {},
    handleInputOnBlur = () => {},
}) => {
    const [tempDataList, setTempDataList] = useState(null);
    const [innerDataList, setInnerDataList] = useState(null);
    const handleInnerRows = (newRow, index) => {
        const updatedRow = {...newRow, isNew: false};
        innerDataList[index] = updatedRow;
        setTempDataList([...innerDataList]);
        setInnerDataList([...innerDataList]);
    }
    /**
     * 是否全選當頁清單
     */
    const [isSelectAll, setIsSelectAll] = useState(false)

    /**
     * 有無資料
     */
    const [isNoResult, setIsNoResult] = useState(false)

    /**
     * 進階搜尋關鍵字
     */
    const [keyword, setKeyword] = useState('')

    /**
     * table預設10排
     */
    const defaultRows = Array.from({ length: 10 }, () => {
        const row = {
            className: '',
        }
        fields
            .map((field) => field.key)
            .forEach((item) => {
                row[item] = ''
            })

        if (isSelectable) {
            row.isSelected = false
        }

        return row
    })

    /**
     * 針對進階搜尋進行filter
     * @param {string} key 進階搜尋關鍵字
     * @param {array} dataList 列表資料 
     */
    const filterDataList = useCallback((key, dataList) => {
        const lowercasedKeyword = key.toLowerCase()
        const filteredList = dataList?.filter((item) =>
            fields.some((field) => {
                return item[field.key]
                    ?.toString()
                    .toLowerCase()
                    .includes(lowercasedKeyword)
            })
        )
        const noResult = filteredList?.length === 0
        setIsNoResult(noResult)
        setInnerDataList(noResult ? [] : filteredList)
    }, [])

    /**
     * 改動進階搜尋input
     * @param {event} e
     */
    const handleDataFilter = (e) => {
        const value = e.target.value
        setKeyword(value)
        filterDataList(value, tempDataList)
    }

    /**
     * 勾選所有資料
     * @param {event} e
     */
    const handleToggleAll = (isChecked) => {
        setIsSelectAll(isChecked)
        const selectedData = innerDataList.map((item) => ({
            ...item,
            isSelected: isChecked,
        }))
        toggleAll(isChecked, selectedData)
        setInnerDataList(selectedData)
    }

    /**
     * 勾選單筆資料
     * @param {event} e
     */
    const handleToggleItem = (e, selectedItem, index) => {
        const value = e.target.checked
        toggleItem(value, selectedItem, index)
    }

    /**
     * 失去焦點事件
     * @param {event} e
     * @param {object} row 該列資料
     * @param {string} key key
     */
    const handleOnBlur = (e, row, key) => {
        handleInputOnBlur(e, row, key)
    }

    /**
     * 表格內渲染的資料
     * 1. 如果table fields傳入render，則渲染需要插入的元素
     * 2. 如果table fields有傳入format，則將傳入的值做統一格式
     * @param {object} row 單筆資料
     * @param {object} field 表格欄位
     * @param {number} fieldIndex 表格 index
     * @returns {JSX.Element}
     */
    const handleRowOnClick = (row, index) => {
        if (!rowOnClick) {
            return
        }
        rowOnClick(row)
    }

    const handleAddRow = () => {
        const emtypData = {}
        fields.forEach((item) => {
            if (!!item.inputType) {
                emtypData[item.key] = ''
            }
        })
        addNewData(emtypData)
    }

    // 判斷是否全選dataList和檢查傳入的dataList是否有資料
    useEffect(() => {
        if (isSelectable) {
            const isSelectAll = innerDataList?.length
                ? innerDataList.every((item) => item.isSelected)
                : false
            setIsSelectAll(isSelectAll)
        }
    }, [innerDataList, keyword])

    useEffect(() => {
        setTempDataList(dataList)
        setInnerDataList(dataList)
    }, [dataList]);

    return (
        <div className="bg-white shadow rounded border-2 p-2">
            <div className="flex justify-between items-center">
                {isShoeDataFilter && (
                    <div className="flex items-center">
                        {title ? (
                            <h3 className="flex items-center text-[#2B6CB0] font-bold text-left border-l-[5px] pl-1 border-[#2B6CB0] mb-2 mr-3 h-[25px]">
                                {title}
                            </h3>
                        ) : null}
                        {isShoeDataFilter && (
                            <div className="w-[350px] flex items-center relative mb-2">
                                <input
                                    onChange={(e) => handleDataFilter(e)}
                                    className={
                                        'bg-white min-h-10 w-full px-2 text-black rounded-[6px] border py-1 border-gray-300 pl-8'
                                    }
                                    value={keyword}
                                    placeholder="進階搜尋"
                                />

                                <img
                                    className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-2"
                                    src={searchIcon}
                                    alt="search-icon"
                                    role="button"
                                />
                            </div>
                        )}
                    </div>
                )}
                <div>{slotButton}</div>
            </div>
            <div className="max-h-[600px] w-full overflow-auto">
                <table className="table-fixed text-left min-w-full">
                    <thead className="bg-[#e4e4e7] h-[50px] border-collapse text-lg text-[#18181b]">
                        <tr>
                            {isSelectable && (
                                <th className="p-2 border-r border-[#111111]/15 w-[56px] text-center">
                                    <input
                                        type="checkbox"
                                        style={{
                                            accentColor: '#38A169',
                                        }}
                                        className="bg-green-400 checked:bg-green-600 text-green-6002 cursor-pointer"
                                        checked={isSelectAll}
                                        onChange={(e) =>
                                            handleToggleAll(
                                                e.target.checked,
                                                innerDataList
                                            )
                                        }
                                        disabled={isSingleSelect}
                                    />
                                </th>
                            )}
                            {isCrud && (
                                <th className="p-2 border-r border-[#111111]/15 w-[56px] text-center">
                                    <button
                                        className="flex text-green-600 items-center"
                                        onClick={handleAddRow}
                                        disabled={isEditMode}
                                    >
                                        <AddBtn />
                                        <div className="ml-1 text-sm">{t('general.add')}</div>
                                    </button>
                                </th>
                            )}
                            {fields.map((field, fieldIndex) => (
                                <th
                                    key={`th_${field.key}_${fieldIndex}`}
                                    className={`${field.className} p-2 border-r border-[#111111]/15 font-normal`}
                                >
                                    {field.renderHeader
                                        ? field.renderHeader
                                        : field.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {innerDataList?.map((row, index) => (
                            <tr
                                key={index}
                                className={`h-[60px] ${
                                    index % 2 === 0 ? '' : 'bg-[#f4f4f5]'
                                } text-[#18181b] hover:bg-glacierBlue ${
                                    rowOnClick ? 'cursor-pointer' : ''
                                }`}
                                onClick={() => handleRowOnClick(row, index)}
                            >
                                {isSelectable &&
                                    (isNoResult ? (
                                        <td className="p-2 border-r border-[#111111]/15 w-[56px]"></td>
                                    ) : (
                                        <td className="p-2 border-r border-[#111111]/15 text-center">
                                            <input
                                                type="checkbox"
                                                style={{
                                                    accentColor: '#38A169',
                                                }}
                                                className="bg-green-400 checked:bg-green-600 text-green-6002 cursor-pointer"
                                                checked={row.isSelected}
                                                onChange={(e) =>
                                                    handleToggleItem(
                                                        e,
                                                        row,
                                                        index
                                                    )
                                                }
                                            />
                                        </td>
                                    ))}

                                {isCrud && (
                                    <td className="p-2 border-r border-[#111111]/15 text-sm w-[150px]">
                                        {!isNoResult && !row.isEditable && (
                                            <div className="flex items-center">
                                                <button
                                                    className="text-[#007CDB] flex items-center mr-2"
                                                    onClick={() =>
                                                        handleEdit(row)
                                                    }
                                                    disabled={isEditMode}
                                                >
                                                    <EditIcon />
                                                    <div className="ml-1">
                                                        {t('general.edit')}
                                                    </div>
                                                </button>

                                                <button
                                                    className="text-[#007CDB] flex items-center"
                                                    onClick={() =>
                                                        deleteRow(row)
                                                    }
                                                    disabled={isEditMode}
                                                >
                                                    <DeleteIcon />
                                                    <div className="ml-1">
                                                        {t('general.delete')}
                                                    </div>
                                                </button>
                                            </div>
                                        )}
                                        {!isNoResult && row.isEditable && (
                                            <div className="flex items-center">
                                                <button
                                                    className="text-[#007CDB] flex mr-2"
                                                    onClick={() =>
                                                        handleSave(row)
                                                    }
                                                >
                                                    <SaveIcon className="mr-1" />
                                                    <div className="ml-1">
                                                        {t('general.save')}
                                                    </div>
                                                </button>

                                                <button
                                                    className="text-[#007CDB] flex"
                                                    onClick={() =>
                                                        handleCancel(row)
                                                    }
                                                >
                                                    <CancelIcon />
                                                    <div className="ml-1">
                                                        {t('general.cancel')}
                                                    </div>
                                                </button>
                                            </div>
                                        )}
                                    </td>
                                )}
                                {fields.map((field, fieldIndex) => (
                                    <td
                                        key={`cell_${field.key}_${fieldIndex}`}
                                        className={`${
                                            field.tdClassName
                                                ? field.tdClassName
                                                : ''
                                        } break-words p-2 border-r border-[#111111]/15`}
                                    >
                                        {row.isEditable && field.inputType ?
                                            <EditContent row={row} field={field} rowIndex={index} fields={fields} onChange={handleInnerRows} onBlur={handleOnBlur}/>
                                            :
                                            <Content row={row} field={field} fieldIndex={fieldIndex}  />
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

CustomTable.propTypes = {
    fields: PropTypes.array.isRequired,
    dataList: PropTypes.array,
    slotButton: PropTypes.element,
    isSelectable: PropTypes.bool,
    toggleAll: PropTypes.func,
    toggleItem: PropTypes.func,
}

export default CustomTable
