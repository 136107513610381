import React, {useEffect, useState} from "react"
import { BasePopup } from "../../Popup/BasePopup"
import { DefaultButton } from "../../Button/DefaultButton"
import {time2String, InputTypeEnum, RegsWeekModeEnum, arrayIsEmpty, stringIsEmpty} from "edah_utils/dist"
import {t} from "i18next"

const CancelStopClinicPromptPopup = ({ 
    show = false,
    data,
    closePopupButtonOnClick, 
    onConfirmClick
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div
                className="flex flex-col w-full max-h-[calc(80vh-100px)] overflow-y-auto bg-white px-5">
                <table className="w-full text-left">
                    <tbody className=" ">
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4 w-[45%] w-max-[45%] text-right">
                                <p className="text-base font-normal text-[#18181B]">
                                    {t("Regs.general.clinicDate")}
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.encounterDate) ? time2String(data.encounterDate, "YYYY-MM-DD") : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    {t("Regs.general.timeSlot")}
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.apnName) ? data.apnName : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    診室號
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.clinicNo) ? data.clinicNo : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    {t('general.doctor')}
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.doctorName) ? data.doctorName : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    {t('general.department')}
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.divName) ? data.divName : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    停診
                                </p>
                            </td>
                            <td className="p-4">
                                <div className="text-base font-normal flex text-[#18181B]">
                                    <input 
                                        className="mr-2 w-[20px] h-[20px] items-center bg-green-500 checked:bg-green-500 text-green-500"
                                        type="checkbox" 
                                        style={{accentColor: '#38A169'}}
                                        //有停診理由且代班醫師要為空才是停診
                                        checked={!arrayIsEmpty(data) && !stringIsEmpty(data.stopClinicReason) && stringIsEmpty(data.locumDoctor)}/>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-b border-[#111111]/15">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    停代診原因
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.stopClinicReasonName) ? data.stopClinicReasonName : ""}
                                </p>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B] text-right">
                                    代診醫師
                                </p>
                            </td>
                            <td className="p-4">
                                <p className="text-base font-normal text-[#18181B]">
                                    {!arrayIsEmpty(data) && !stringIsEmpty(data.locumDoctorName) ? data.locumDoctorName : ""}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end border-t border-[#111111]/15 px-4 py-2.5">
                <DefaultButton 
                    text={t('general.confirm')}
                    type="primary"
                    onClickFn={() => onConfirmClick(data.clinicId)}
                    />
            </div>
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        setShowPopup(show)
    }, [show])

    return showPopup ?
        <BasePopup
            title={"取消停代診"}
            content={getContent()}
            width={"400px"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default CancelStopClinicPromptPopup