import {
    time2String,
    stringIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"

/**
 * 顯示掛號完成的彈窗
 * @param data {Object} 掛號資料
 * @param showPopup {Boolean} 是否顯示彈窗
 * @param handleClosePopup {Function} 關閉彈窗的函數
 */
export const ShowGiveNumberPopup = ({data, showPopup, handleClosePopup}) => {
    /**
     * 是否是今天的日期
     * @param {String} dateString 日期時間字串
     * @returns 
     */
    const isToday = (dateString) => {
        const givenDate = new Date(dateString);
        const today = new Date();
      
        return (
            givenDate.getFullYear() === today.getFullYear() &&
            givenDate.getMonth() === today.getMonth() &&
            givenDate.getDate() === today.getDate()
        );
    };

    const getTitleContent = () => {
        const startViewTime = time2String(data.startViewDatetime, "HH:mm")

        if(data.viewNo === null) {
            return `預約時間：${startViewTime}`
        }
        return `${data.viewNo}號 ${startViewTime}`
    }

    return (
        showPopup && (
            <div
                className="fixed top-0 left-0 bg-[rgba(0,0,0,0.8)] w-full h-full z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-80 z-10">
                <div className="flex bg-white opacity-100 rounded-md w-3/5 h-auto text-lg py-6 px-4 relative"
                     onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col space-y-6 w-full text-left">
                        <div className="border-b-4 text-3xl py-5 w-full px-4">
                            {isToday(data.encounterDate)?"掛號完成":"預約掛號完成"}
                        </div>
                        <div className="text-center text-2xl font-bold">
                            {getTitleContent()}
                        </div>
                        <div>
                            <div className="text-center">
                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8]">
                                    <div className="w-1/2 text-right pr-10">掛號院區</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {data.zoneName}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8] pt-3">
                                    <div className="w-1/2 text-right pr-10">看診日期</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {data.encounterDate.split(" ")[0]}，{data.apnName}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8] pt-3">
                                    <div className="w-1/2 text-right pr-10">{t('Regs.general.medicalRecordNumber')}</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {data.patientId}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8] pt-3">
                                    <div className="w-1/2 text-right pr-10">{t('general.department')}</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {data.divName}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8] pt-3">
                                    <div className="w-1/2 text-right pr-10">{t("general.doctor")}</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {stringIsEmpty(data.locumDoctorName) ? data.doctorName : data.locumDoctorName}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-2 border-[#D4D4D8] pt-3">
                                    <div className="w-1/2 text-right pr-10">{t("Regs.general.clinicName")}</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        {data.clinicName}
                                    </div>
                                </div>

                                <div className="flex w-2/3 border-b-0 border-[#D4D4D8] pt-5 pb-20">
                                    <div className="w-1/2 text-right pr-10">列印</div>
                                    <div className="w-1/2 pl-5 pb-3 text-left">
                                        <div>
                                            <div className="flex flex-row space-x-3">
                                                <input
                                                    type="checkbox"
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                                    style={{accentColor: "#38A169"}}
                                                    checked
                                                />
                                                <label className="text-[#E53E3E]">
                                                    {isToday(data.encounterDate)?"就診單":"預約單"}
                                                </label>
                                            </div>
                                            {/*<div className="flex flex-row space-x-3">
                                                <input
                                                    type="checkbox"
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                                    style={{accentColor: "#38A169"}}
                                                    checked
                                                />
                                                <label className="text-[#E53E3E]">病歷首頁</label>
                                            </div>
                                            <div className="flex flex-row space-x-3">
                                                <input
                                                    type="checkbox"
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                                    style={{accentColor: "#38A169"}}
                                                    checked
                                                />
                                                <label className="text-[#E53E3E]">借閱單</label>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="absolute right-10 bottom-3 -translate-y-1 bg-[#2B6CB0] p-2 px-6 rounded-md text-white"
                        onClick={handleClosePopup}>
                        {t('general.confirm')}
                    </button>
                </div>
            </div>
        )
    );
};
