import React from 'react'
import {BasePopup} from '../../Popup/BasePopup'

/**
 * 刪除提醒彈窗
 * @param {function} handleDeletePopupClose - 控制彈窗關閉
 * @param {function} handleNotificationDelete - 刪除提醒
 * @param {string} currentKey - 當前提醒的key
 * @return {JSX.Element}
 */
function DeletePopup({handleDeletePopupClose, handleNotificationDelete, currentKey}) {
    return (
        <BasePopup
            title="提醒"
            closePopupButtonOnClick={handleDeletePopupClose}
            width="559px"
            content={
                <div>
                    <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
                    <div className="h-[60px] flex flex-row items-center justify-end">
                        <button onClick={() => handleNotificationDelete(currentKey)}
                                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        >是
                        </button>
                        <button onClick={handleDeletePopupClose}
                                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                        >否
                        </button>
                    </div>
                </div>
            }
        />

    )
}

export default DeletePopup
