import React, {useEffect, useMemo, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {BasePopup} from '../../Popup/BasePopup'
import {BaseInput} from '../../Input/BaseInput'
import {
    ApiErrorStatusEnum,
    generateTimeIntervals,
    InputTypeEnum,
    time2String,
    ToastTypeEnum
} from 'edah_utils/dist'
import {usePopup} from '../PopupProvider'
import {DelSlotBtn} from '../SlotButton'
import {deleteItem} from '../utils'
import DeletePopup from '../Popup/DeletePopup'
import {rsisCheckTimeValid} from "../../../api/v1/RSIS";

// 星期映射表
const weekDays = {
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日'
}

/**
 * 支援日期時間選擇彈窗列表組件
 * @param {array} item 列表資料
 * @param {number} index 索引
 * @param {boolean} selectAll 是否全選
 * @returns
 */
const ListItem = ({
                      item, index, selectAll
                  }) => {
    // 使用上下文
    const {setShowSubDeletePopup, setSubFocusIndex} = usePopup()

    // 刪除列表，彈出刪除彈窗，並設定焦點索引
    const handleOnDelete = () => {
        setShowSubDeletePopup(true)
        setSubFocusIndex(index)
    }

    return (<li key={index}>
        <div><DelSlotBtn onClick={handleOnDelete}/></div>
        <div>{item.startDate}</div>
        <div>{item.startTime}</div>
        <div>{item.endDate}</div>
        <div>{item.endTime}</div>
    </li>)
}

/**
 * 欄位標題
 * @param title {string} 標題
 * @returns {JSX.Element}
 * @constructor
 */
const Title = ({title}) => {
    return (<p className='flex justify-end items-center'>{title}</p>)
}

/**
 * 欄位內容
 * @param content {JSX.Element || string} 內容
 * @param className {string} 自訂樣式
 * @returns {JSX.Element}
 * @constructor
 */
const Content = ({content, className = ''}) => {
    return (<div className={`flex flex-row justify-start items-center pl-2 ${className}`}>{content}</div>)
}

/**
 * 輸入欄位
 * @param title {string} 標題
 * @param content {JSX.Element} 內容
 * @param className {string} 自訂樣式
 * @returns {JSX.Element}
 * @constructor
 */
const InputField = ({title, content, className}) => {
    return (<div className='flex flex-row h-[42px]'>
        <Title title={title}/>
        <Content content={content} className={className}/>
    </div>)
}

/**
 * 取得時間區間選項
 * @return {JSX.Element}
 */
const TimeIntervalsComponent = () => {
    // 產生時間區間
    const timeIntervals = useMemo(() => [...generateTimeIntervals(), "24:00"], [])
    return timeIntervals.map((item, index) => <option key={index} value={item}>{item}</option>)
}

/**
 * 格式化日期字串
 * @param date
 * @returns {string} 格式化後的日期字串
 */
const formatDateString = (date) => {
    return date.toISOString().split('T')[0].replace(/-/g, '-');
};

/**
 * 組合日期和時間
 * @param date
 * @param time
 * @return {string}
 */
function combineDateTime(date, time) {
    // 确保时间字符串至少有4位
    time = time.padStart(4, '0');

    // 提取小时和分钟
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);

    // 组合日期和时间
    return `${date} ${hours}${minutes}:00`;
}

/**
 * 生成時間區段
 * @param {string} startDate 開始日期
 * @param {string} endDate 結束日期
 * @param {string} startTime 開始時間
 * @param {string} endTime 結束時間
 * @param {string} type 類型 ('ALL', 'CUSTOM', 'WEEKLY')
 * @param {object} weekInterval 周區間
 * @param {string} customDate 自訂日期
 * @returns {array} 時間區段數組
 */
const generateSupportTimeIntervals = (startDate, endDate, startTime, endTime, type, weekInterval, customDate) => {
    const intervals = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    // 英文星期映射表
    const dayNameMapping = {
        '星期一': 'monday',
        '星期二': 'tuesday',
        '星期三': 'wednesday',
        '星期四': 'thursday',
        '星期五': 'friday',
        '星期六': 'saturday',
        '星期日': 'sunday'
    }

    // 當持續形式為持續ALL時
    if (type === 'ALL') {
        intervals.push({
            startDate: formatDateString(new Date(start)),
            startTime: startTime,
            endDate: formatDateString(new Date(end)),
            endTime: endTime
        });
        //當持續形式為間斷並重複時
    } else if (type === 'WEEKLY') {
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            const dayName = d.toLocaleDateString('zh-TW', {weekday: 'long'});
            const weekDayKey = dayNameMapping[dayName];
            if (weekInterval[weekDayKey]) {
                let intervalEndDate = new Date(d);
                // 如果開始時間和結束時間相同，結束日期加一天
                if (startTime === endTime) {
                    intervalEndDate.setDate(intervalEndDate.getDate() + 1);
                }
                intervals.push({
                    startDate: formatDateString(d),
                    startTime: startTime,
                    endDate: formatDateString(intervalEndDate),
                    endTime: endTime
                });
            }
        }
        //當持續形式為自行選擇時
    } else if (type === 'CUSTOM' && customDate) {
        const customDateFormatted = new Date(customDate);
        intervals.push({
            startDate: formatDateString(customDateFormatted),
            startTime: startTime,
            endDate: formatDateString(customDateFormatted),
            endTime: endTime
        });
    }

    return intervals;
};

/**
 * 表格欄位
 * @type {string[]}
 */
const fields = ["", "開始日期", "開始時間", "結束日期", "結束時間"]

/**
 * 支援日期時間選擇彈窗
 * @param {array} data 列表資料
 * @param {string} supportNo 單號
 * @param {string} supportUserNo 支援人員編號
 * @param {string} itemVersion 版本
 * @param {function} handleUpdateDataList 更新列表資料
 * @param {function} handleOnClose 關閉彈窗
 * @param {boolean} isEdit 是否為編輯狀態
 * @param {string} userName 使用者名稱
 * @returns {JSX.Element}
 * @constructor
 */
function SupportDateChoice({
                               data,
                               supportNo = '',
                               supportUserNo,
                               itemVersion = '1',
                               handleUpdateDataList,
                               handleOnClose,
                               isEdit = false,
                               userName
                           }) {
    // 列表資料
    const [dataList, setDataList] = useState([])

    // 使用列表組件
    const List = ListItem

    // 支援開始日期
    const [itemStartDate, setItemStartDate] = useState('')

    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState('')

    // 支援開始時間
    const [itemStartTime, setItemStartTime] = useState('00:00')

    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState('00:00')

    // 自行選擇日期
    const [customDate, setCustomDate] = useState('')

    // 自行選擇開始時間
    const [customStartTime, setCustomStartTime] = useState('00:00')

    // 自行選擇結束時間
    const [customEndTime, setCustomEndTime] = useState('00:00')

    // 持續形式及週區間
    const [continueType, setContinueType] = useState('A')

    // 週區間
    const [weekInterval, setWeekInterval] = useState({
        monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false
    })

    // 使用上下文
    const {
        showSubDeletePopup,
        setShowSubDeletePopup,
        showToast,
        subFocusIndex
    } = usePopup()

    // 分頁屬性
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1, pageSize: 10, totalItemSize: 0, totalPageSize: 0, currentPageItemSize: 0, fn: () => {
        }
    })

    /**
     * 檢查是否有重疊的時間區段
     * @param {array} newIntervals 新的時間區段數組
     * @param {array} existingIntervals 已有的時間區段數組
     * @return {boolean} 是否有重疊
     */
    const hasOverlap = (newIntervals, existingIntervals) => {
        const overlaps = [];
        for (let newInterval of newIntervals) {
            for (let existingInterval of existingIntervals) {
                const [newStart, newEnd] = getDateTimeRange(newInterval);
                const [existingStart, existingEnd] = getDateTimeRange(existingInterval);

                if ((existingStart <= newStart && newStart < existingEnd) ||
                    (newStart <= existingStart && existingStart < newEnd)) {
                    overlaps.push({
                        new: newInterval,
                        existing: existingInterval
                    });
                }
            }
        }
        return {
            hasOverlap: overlaps.length > 0,
            overlaps: overlaps
        };
    };
    /**
     * 取得時間區間
     * @param interval
     * @returns {[number,number]}
     */
    const getDateTimeRange = (interval) => {
        const [startHour, startMinute] = interval.startTime.split(':');
        const [endHour, endMinute] = interval.endTime.split(':');
        const startDate = new Date(interval.startDate).setHours(startHour, startMinute, 0, 0);
        const endDate = new Date(interval.endDate).setHours(endHour, endMinute, 0, 0);
        return [startDate, endDate];
    };

    /**
     * 重置日期和時間
     */
    const resetDateTime = () => {
        setItemStartDate('')
        setItemEndDate('')
        setItemStartTime('00:00')
        setItemEndTime('00:00')
        setCustomDate('')
        setCustomStartTime('00:00')
        setCustomEndTime('00:00')
        setContinueType('A')
        setWeekInterval({
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
        })
    }

    /**
     * 取代 24:00 時間
     * @param endDate {String|null} 終止日期
     * @param endTime {String|null} 終止時間
     * @param startDate {String|null} 起始日期
     * @param startTime {String|null} 起始時間
     * @return {Object}
     */
    const getReplaceTime = ({endDate, endTime, startDate, startTime}) => {
        //取得日期時間
        startTime = startTime ? startTime : "";
        startDate = startDate ? `${startDate} ${startTime}:00` : "";
        endTime = endTime ? endTime : "";
        endDate = endDate ? `${endDate} ${endTime}:00` : "";

        //是否超過2400
        if (endTime !== "" && endTime === "24:00") {
            const newDate = new Date(endDate);
            endDate = time2String(newDate, "YYYY-MM-DD");
            //將終止時間設定為隔天0000
            endTime = "00:00";
        }
        if (startTime !== "" && startTime === "24:00") {
            const newDate = new Date(startDate);
            startDate = time2String(newDate, "YYYY-MM-DD");
            //將終止時間設定為隔天0000
            startTime = "00:00";
        }

        return {
            startDate: startDate.split(" ")[0],
            startTime: startTime,
            endTime: endTime,
            endDate: endDate.split(" ")[0],
        };
    };


    /**
     * 新增支援列表資料
     * @return {void}
     */
    const handleOnAdd = () => {
        //當天日期
        const currentDate = new Date(`${time2String(new Date, 'YYYY-MM-DD')} 00:00:00`)
        // 當持續形式不為自行選擇時
        if (continueType !== 'C') {
            // 當起始日期或結束日期或起始時間或結束時間為空時
            if (itemStartDate === '' || itemEndDate === '' || itemStartTime === '' || itemEndTime === '') {
                showToast({message: `${userName}：請填入完整的支援時間！`, type: ToastTypeEnum.Warning});
                return;
            }
            const startTimeInfo = itemStartTime.split(':')
            const endTimeInfo = itemEndTime.split(':')
            const startDate = new Date(itemStartDate).setHours(startTimeInfo[0], startTimeInfo[1], 0, 0)
            const endDate = new Date(itemEndDate).setHours(endTimeInfo[0], endTimeInfo[1], 0, 0)

            //起始日期
            const tempStartDate = new Date(`${time2String(startDate, 'YYYY-MM-DD')} 00:00:00`)
            //結束日期
            const tempEndDate = new Date(`${time2String(endDate, 'YYYY-MM-DD')} 00:00:00`)

            //起始時間起始日期小於當前日期 或 起始時間結束日期小於當前日期
            if ((currentDate.getTime() > tempStartDate.getTime()) || (currentDate.getTime() > tempEndDate.getTime())) {
                showToast({message: '日期不可輸入過往日期!!', type: ToastTypeEnum.Warning})
                return
            }

            // 當結束日期早於起始日期時
            if (endDate <= startDate) {
                showToast({
                    message: `${userName}：支援日期時間不符合，請重新填寫！如需選擇半夜 12 點，請點選 24:00`,
                    type: ToastTypeEnum.Warning
                });
                return
            }

            if (continueType === 'B') { // 當持續形式為間斷並且重複時
                // 若週區間全為false，則不新增
                if (Object.values(weekInterval).every(value => !value)) {
                    showToast({message: '請選擇週區間', type: ToastTypeEnum.Error});
                    return;
                }
            }
        } else if (continueType === 'C') { //自行選擇
            // 將 customStartTime 和 customEndTime 轉換為 Date 物件
            const [startHour, startMinute] = customStartTime.split(':').map(Number);
            const [endHour, endMinute] = customEndTime.split(':').map(Number);

            const startTime = new Date();
            startTime.setHours(startHour, startMinute, 0, 0);

            const endTime = new Date();
            endTime.setHours(endHour, endMinute, 0, 0);

            //起始日期
            const tempCustomDate = new Date(`${time2String(customDate, 'YYYY-MM-DD')} 00:00:00`)
            //自行選擇起始日期小於當前時間
            if (currentDate.getTime() > tempCustomDate.getTime()) {
                showToast({message: '日期不可輸入過往日期!!', type: ToastTypeEnum.Warning})
                return
            }

            // 當自行選擇結束時間早於或等於開始時間時
            if (endTime <= startTime) {
                showToast({
                    message: `${userName}：支援日期時間不符合，請重新填寫！如需選擇半夜 12 點，請點選 24:00`,
                    type: ToastTypeEnum.Error
                });
                return;
            }
        }


        // 新的時間區段array
        let newIntervals = [];
        // 依照持續形式新增時間區段
        if (continueType === 'A') {
            newIntervals = generateSupportTimeIntervals(itemStartDate, itemEndDate, itemStartTime, itemEndTime, 'ALL');
        } else if (continueType === 'B') {
            newIntervals = generateSupportTimeIntervals(itemStartDate, itemEndDate, itemStartTime, itemEndTime, 'WEEKLY', weekInterval);
        } else {
            newIntervals = generateSupportTimeIntervals('', '', customStartTime, customEndTime, 'CUSTOM', {}, customDate);
        }

        // 檢查是否有重疊或重複的時間區段
        const overlapResult = hasOverlap(newIntervals, dataList);
        if (overlapResult.hasOverlap) {
            const overlapMessage = overlapResult.overlaps.map(overlap => {
                const newInterval = overlap.new;
                const existingInterval = overlap.existing;
                return `\n${newInterval.startDate} ${newInterval.startTime} ~ ${newInterval.endDate} ${newInterval.endTime}支援時段重疊，支援人員：${userName}已於${existingInterval.startDate} ${existingInterval.startTime} ~ ${existingInterval.endDate} ${existingInterval.endTime}申請報備支援。\n`;
            }).join('');
            showToast({message: overlapMessage, type: ToastTypeEnum.Error})
            return;
        }
        // 換算 24:00 為 00:00
        const replaceNewIntervals = newIntervals.map(interval => getReplaceTime(interval));
        // 更新時間區段dataList
        setDataList([...dataList, ...replaceNewIntervals]);

        // 重置日期和時間
        resetDateTime()
    };

    /**
     * 刪除列表特定索引資料
     * @return {void}
     */
    const handleOnDelete = () => {
        setDataList(deleteItem(dataList, subFocusIndex))
        setShowSubDeletePopup(false)
    }

    /**
     * 支援開始日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemStartDateOnChange = (e) => setItemStartDate(e.target.value)

    /**
     * 支援開始時間改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemStartTime = (e) => setItemStartTime(e.target.value)

    /**
     * 支援結束日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemEndDateOnChange = (e) => setItemEndDate(e.target.value)

    /**
     * 支援結束時間改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleItemEndTime = (e) => setItemEndTime(e.target.value)

    /**
     * 自行選擇開始日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleCustomDate = (e) => setCustomDate(e.target.value)

    /**
     * 自行選擇開始時間改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleCustomStartTime = (e) => setCustomStartTime(e.target.value)

    /**
     * 自行選擇結束時間改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleCustomEndTime = (e) => setCustomEndTime(e.target.value)

    /**
     * 取得日期和時間
     * @param date
     * @param time
     * @returns {{date: string, time: string}}
     */
    const getDateAndTime = (date, time) => {
        return {
            date: time2String(date, 'YYYY-MM-DD'),
            time: `${time.substring(0, 2)}:${time.substring(2, 4)}`
        }
    }

    /**
     * 取得重疊的時間段
     * @param date
     * @param time
     * @returns [{date: string, time: string}]
     */
    const getOverlapDateAndTimeList = (date, time) => {
        // 結果
        let result = []
        // 檢查data 和 time 長度是否一樣
        date.length === time.length ? date.map((item, index) => {
            //將結果加入result
            result.push(getDateAndTime(item, time[index]))
        }) : []
        return result
    }

    /**
     * 確認按鈕
     * @return {void}
     */
    const handleOnConfirm = async () => {
        // 檢查時間是否為空值
        if (dataList.length === 0) {
            showToast({message: '請新增時間段後展開明細', type: ToastTypeEnum.Warning});
            return;
        }
        // 檢查是否在編輯狀態且時間大於一筆
        if (isEdit && dataList.length > 1) {
            showToast({message: '修改時間只能輸入一筆資料', type: ToastTypeEnum.Warning});
            return;
        }

        try {
            // 使用Promise.all 並行檢查是否有重疊的時間段
            const result = await handleCheckOverlap()
            if (!result.overlaps) {
                handleUpdateDataList(dataList);
                handleOnClose();
            } else {
                const overlapList = result.time.filter(item => item.overlapFlag === true)
                const overlapMessage= `${userName}：${overlapList?.map(item => {
                        // 取得起始日期和時間
                        const {
                            date: inputStartDate,
                            time: inputStartTime
                        } = getDateAndTime(item.itemStartDate, item.itemStartTime)
                        // 取得結束日期和時間
                        const {
                            date: inputEndDate,
                            time: inputEndTime
                        } = getDateAndTime(item.itemEndDate, item.itemEndTime)
                        // 取得起始時間重疊的時間段
                        const overlapStartDate = getOverlapDateAndTimeList(item.dbStartDate, item.dbStartTime)
                        // 取得結束時間重疊的時間段
                        const overlapEndDate = getOverlapDateAndTimeList(item.dbEndDate, item.dbEndTime)
                        // 重疊時間字串
                        let overlapTime = ''
                        // 組合重疊時間字串
                        overlapStartDate.map((item, index) => {
                            overlapTime += `${item.date} ${item.time} ~ ${overlapEndDate[index].date} ${overlapEndDate[index].time}\n`
                        })

                        return `\n${inputStartDate} ${inputStartTime} ~ ${inputEndDate} ${inputEndTime}支援時段重疊，支援人員：${userName}已於${overlapTime}申請報備支援。`
                    })
                    }`;

                showToast({message: overlapMessage, type: ToastTypeEnum.Error});
            }
        } catch (error) {
            console.error('Error checking overlap:', error);
            showToast({message: '檢查過程中發生錯誤', type: ToastTypeEnum.Error});
        }
    }

    /**
     * 檢查伺服器是否有時間重疊
     * @return {Promise<{overlaps: boolean, time: string}>}
     */
    const handleCheckOverlap = async () => {
        try {
            const time = dataList.map((item) => ({
                itemStartDate: `${item.startDate} 00:00:00`,
                itemEndDate: `${item.endDate} 00:00:00`,
                itemStartTime: item.startTime.replace(":", ""),
                itemEndTime: item.endTime.replace(":", "")
            }))
            const res = await rsisCheckTimeValid({
                supportNo: supportNo,
                supportUserNo: supportUserNo,
                itemVersion: itemVersion,
                timeList: time
            });
            if (res.err === ApiErrorStatusEnum.Success && res.data.every(item => item.overlapFlag === false)) {
                return {overlaps: false, time: res.data};
            } else {
                return {overlaps: true, time: res.data};
            }
        } catch (error) {
            console.error('Error in handleCheckOverlap:', error);
            showToast({message: `檢查時間時發生錯誤`, type: ToastTypeEnum.Error});
            return {overlaps: true, time};
        }
    }

    useEffect(() => {
        console.log(data)
        if(data.length > 0) {
            const list = []
            data.map((item, index) => {
                list.push({
                    startDate: item.startDate,
                    startTime: item.startTime.includes(':') ? data[0].startTime : `${data[0].startTime.substring(0, 2)}:${data[0].startTime.substring(2, 4)}`,
                    endDate: item.endDate,
                    endTime: item.endTime.includes(':') ? data[0].endTime : `${data[0].endTime.substring(0, 2)}:${data[0].endTime.substring(2, 4)}`
                })
            })
            setDataList(list)
        }else{
            setDataList([])
        }
    }, []);

    return (
        <BasePopup
            width="1162px"
            title="支援日期時間選擇"
            closePopupButtonOnClick={handleOnClose}
            content={<>
                <div className='flex flex-col px-6 py-4 gap-4'>
                    {/*起始時間*/}
                    <InputField title='起始時間 : ' content={<div className='pl-2 flex flex-row gap-2 items-center'>
                        <BaseInput
                            className="w-[160px] h-10 border-2 border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                            type={InputTypeEnum.Date}
                            value={continueType !== 'C' && itemStartDate}
                            onChange={handleItemStartDateOnChange}
                            disable={continueType === 'C'}
                            max="9999-12-31"
                            min={time2String(new Date(), 'yyyy-MM-DD')}/>
                        <select
                            className='w-[102px] h-[40px] border-2 border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                            value={continueType !== 'C' && itemStartTime}
                            onChange={handleItemStartTime}
                            disabled={continueType === 'C'}
                        >
                            <TimeIntervalsComponent extendTime={true}/>
                        </select>
                        ~
                        <BaseInput
                            className="w-[160px] h-10 border-2 border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                            type={InputTypeEnum.Date}
                            value={continueType !== 'C' && itemEndDate}
                            onChange={handleItemEndDateOnChange}
                            disable={continueType === 'C'}
                            max="9999-12-31"
                            min={time2String(new Date(), 'yyyy-MM-DD')}/>
                        <select
                            className='w-[102px] h-[40px] border-2 border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                            value={continueType !== 'C' && itemEndTime} onChange={handleItemEndTime}
                            disabled={continueType === 'C'}>
                            <TimeIntervalsComponent extendTime={true}/>
                        </select>
                    </div>}/>
                    {/*持續形式及週區間*/}
                    <div className='flex flex-col items-start justify-center gap-4 my-2'>
                        {/*持續All*/}
                        <div className='flex flex-row space-x-4 items-center pl-1'>
                            <input id='A'
                                   type='radio'
                                   name='always'
                                   value='A'
                                   checked={continueType === 'A'}
                                   onChange={(e) => setContinueType(e.target.value)}
                                   className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                            />
                            <label htmlFor='A'
                                   onClick={() => setContinueType('A')}
                                   className={`hover:cursor-pointer ${continueType === 'A' ? 'text-[#2B6CB0]' : 'text-black'}`}
                            >
                                持續(ALL)
                            </label>
                        </div>
                        {/* 間斷並重複及週區間 */}
                        <div className='flex flex-row items-center gap-3 pl-1'>
                            <div className='flex flex-row items-center space-x-4'>
                                <input id='B'
                                       type='radio'
                                       name='loop'
                                       value='B'
                                       checked={continueType === 'B'}
                                       onChange={(e) => setContinueType(e.target.value)}
                                       className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                                />
                                <label htmlFor='B'
                                       className={`hover:cursor-pointer ${continueType === 'B' ? 'text-[#2B6CB0]' : 'text-black'}`}
                                >
                                    間斷並重複
                                </label>
                            </div>
                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                <div key={day} className="flex flex-row space-x-2">
                                    <input id={day}
                                           className="w-5 accent-primary hover:cursor-pointer"
                                           type="checkbox"
                                           disabled={continueType !== 'B'}
                                           checked={continueType === 'B' && weekInterval[day]}
                                           onChange={e => setWeekInterval({
                                               ...weekInterval, [day]: e.target.checked
                                           })}/>
                                    <label className="text-[#3F3F46] text-base hover:cursor-pointer"
                                           htmlFor={day}
                                    >{`${weekDays[day]}`}</label>
                                </div>))}
                        </div>
                    </div>
                    {/* 自行選擇日期 */}
                    <div className='pl-1 flex flex-row items-center space-x-4'>
                        <input id='C'
                               type='radio'
                               name='loop'
                               value='C'
                               checked={continueType === 'C'}
                               onChange={(e) => setContinueType(e.target.value)}
                               className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                        />
                        <label htmlFor='C'
                               onClick={() => setContinueType('C')}
                               className={`hover:cursor-pointer ${continueType === 'C' ? 'text-[#2B6CB0]' : 'text-black'}`}
                        >
                            自行選擇
                        </label>
                        <div className="flex flex-row gap-2 items-center">
                            <BaseInput
                                className="w-[160px] h-10 border-2 border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                                type={InputTypeEnum.Date}
                                value={customDate}
                                disable={continueType === 'B' || continueType === 'A'}
                                onChange={handleCustomDate}
                                min={time2String(new Date(), 'yyyy-MM-DD')}
                                max="9999-12-31"/>
                            <select
                                className='w-[102px] h-[40px] border-2 border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                                value={customStartTime} onChange={handleCustomStartTime}
                                disabled={continueType === 'B' || continueType === 'A'}>
                                <TimeIntervalsComponent extendTime={true}/>
                            </select>
                            <select
                                className='w-[102px] h-[40px] border-2 border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                                value={customEndTime} onChange={handleCustomEndTime}
                                disabled={continueType === 'B' || continueType === 'A'}>
                                <TimeIntervalsComponent extendTime={true}/>
                            </select>
                        </div>
                    </div>
                    {/* 新增按鈕及展開明細 */}
                    <div className='flex flex-row items-center gap-4 mt-2'>
                        {/* 展開明細 */}
                        <button
                            className='border-2 rounded-[6px] border-[#D4D4D8] w-[102px] h-10 hover:bg-primary hover:text-white'
                            onClick={handleOnAdd}>展開明細
                        </button>
                    </div>
                    <CustomTable
                        fields={fields}
                        dataList={dataList}
                        ListItem={List}
                        type='RSISSupportDateChoiceTable'
                        advancedSearch={true}
                        paginationProps={paginationProps}
                    />
                    {/* 確認按鈕 */}
                    <div className='flex flex-row justify-end'>
                        <button
                            className='w-[64px] h-10 border-2 rounded-[6px] bg-primary text-white hover:cursor-pointer'
                            onClick={handleOnConfirm}
                        >
                            確認
                        </button>
                    </div>
                </div>
                {/* 刪除彈窗 */}
                {showSubDeletePopup && (<DeletePopup
                    handleDeletePopupClose={() => setShowSubDeletePopup(false)}
                    handleNotificationDelete={handleOnDelete}
                    currentKey={subFocusIndex}
                />)}
            </>}
        />)
}

export default SupportDateChoice
