import React, { useState } from 'react'
import CustomTable from '../CustomTable'
import { REFERAL_MAPPING_FIELDS } from '../../../constants/referralSystem/referralForm'
import { Pagination } from '../../Pagination/Pagination'
import {t} from "i18next";

const ReferralFormMappingModal = ({ onConfirm, onClose }) => {
    const [patientId, setPatientId] = useState('')
    const [patientName, setPatientName] = useState('')
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })

    const updatePatientId = (e) => {
        const value = e.target.value
        setPatientId(value)
    }

    const updatePatientName = (e) => {
        const value = e.target.value
        setPatientName(value)
    }
    // TODO: 待串接
    const getDataList = () => {}

    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    const onPageSizeChange = (event) => {
        console.log(event.target.value)
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
        })
    }
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.patientId === item.patientId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    return (
        <div className="fixed top-0 left-0 pt-[20px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10 overflow-auto">
            <div className={`w-[80%] bg-white rounded-[6px]`}>
                <div className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    <h3 className="text-[20px]">帶入電子轉診單MAPPING</h3>
                    <button
                        className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                        onClick={onClose}
                    >
                        {t('general.close')}
                    </button>
                </div>

                <div className="p-3">
                    <div className="flex">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="mr-4">{t('general.patientNo')}：</div>
                            <input
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                value={patientId}
                                onChange={(e) => updatePatientId(e)}
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="mr-4">{t('general.username')}：</div>
                            <input
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                value={patientName}
                                onChange={(e) => updatePatientName(e)}
                            />
                        </div>
                        <button className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white">
                            {t('general.query')}
                        </button>
                    </div>
                    <CustomTable
                        isSelectable={true}
                        fields={REFERAL_MAPPING_FIELDS}
                        dataList={dataList}
                        toggleAll={toggleAll}
                        toggleItem={toggleItem}
                        totalPage={filter.page}
                        total={filter.total}
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={filter.pageSize}
                            totalSize={filter.total}
                            currentPage={filter.currentPage}
                            totalPageSize={filter.totalPage}
                            onPageOnChange={onPageOnChange}
                            onPageSizeChange={onPageSizeChange}
                            onPrevPageOnClick={onPrevPageOnClick}
                            onNextPageOnClick={onNextPageOnClick}
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            onClick={onConfirm}
                            className="flex items-center justify-center font-bold px-4 h-10 mt-4 rounded-[6px] bg-[#2B6CB0] text-white"
                        >
                            {t('general.confirm')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralFormMappingModal
