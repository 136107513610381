import { time2String } from 'edah_utils/dist'

/**
 * 防抖動
 * @param {function} func
 * @param {number} delay 延遲時間
 */
const debounce = (func, delay = 500) => {
    let timer

    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, delay)
    }
}

const booleanForDisplay = (value) => {
    if (value === null) {
        return ''
    } else {
        return value ? 'Y' : 'N'
    }
}

/**
 * 取得出生日期與詳細年齡
 * @param birthdate {String} 出生日期
 * @return {String} 年齡
 */
const getBirthDayAndDetailedAge = (birthdate) => {
    const birthDate = new Date(birthdate)
    const today = new Date()

    let ageYears = today.getFullYear() - birthDate.getFullYear()
    let ageMonths = today.getMonth() - birthDate.getMonth()
    let ageDays = today.getDate() - birthDate.getDate()

    // 調整月份和年份
    if (ageDays < 0) {
        ageMonths--
        const previousMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += previousMonth.getDate() // 獲取上個月的天數並加到ageDays
    }

    if (ageMonths < 0) {
        ageYears--
        ageMonths += 12 // 負數月份轉為正，並從年份中減去1
    }

    return `${time2String(
        birthdate,
        'YYYY-MM-DD'
    )}(${ageYears ? ageYears : 0}歲 ${ageMonths ? ageMonths : 0}月 ${ageDays ? ageDays : 0}天)`
}

export { debounce, booleanForDisplay, getBirthDayAndDetailedAge }
