import React, { useEffect, useState } from 'react'

/**
 * @param value {String} 輸入框內容
 * @param className {String} 自訂樣式
 * @param onChange {Function} 輸入框內容改變時觸發
 * @param inputState {Null|Boolean} 輸入框狀態
 * @param errorMsg {String} 欄位檢核錯誤訊息
 */
const CustomSelect = ({
    value,
    options,
    nameAndValue = ['name', 'value'],
    name = '',
    className,
    errorMsg = '',
    inputState = null,
    onChange = () => {},
}) => {
    const defaultSelectStyle =
        'w-full h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

    //input className
    const [customClassName, setCustomClassName] = useState('')

    /**
     * 欄位檢核
     * @return {void}
     */
    const validateInput = () => {
        // 欄位檢核錯誤訊息
        const errorClassName = 'border-[#E53E3E] border-[1px]'
        // 欄位檢核正確訊息
        const successClassName = 'border-[#38A169] border-[1px]'
        let customClassList = [
            className,
            defaultSelectStyle,
            successClassName,
            errorClassName,
        ]

        if (inputState === null) {
            // 欄位為初始狀態(null)
            customClassList = customClassList.filter(
                (item) => item !== errorClassName && item !== successClassName
            )
        } else if (inputState) {
            // 欄位通過檢核
            customClassList = customClassList.filter(
                (item) => item !== errorClassName
            )
        } else {
            // 欄位尚未通過檢核
            customClassList = customClassList.filter(
                (item) => item !== successClassName
            )
        }
        const classNameString = customClassList.join(' ')
        setCustomClassName(classNameString)
    }

    /**
     * 依照傳入的inputState變更input className
     * 若inputState = false 顯示紅色框
     * @return {void}
     */
    useEffect(() => {
        validateInput()
    }, [inputState])

    return (
        <div className="relative">
            <select
                className={customClassName}
                value={value}
                onChange={onChange}
            >
                {options?.map((item) => {
                    return (
                        <option
                            value={item[nameAndValue[1]]}
                            key={`${name}_${item[nameAndValue[1]]}`}
                        >
                            {item[nameAndValue[0]]}
                        </option>
                    )
                })}
            </select>
            {inputState !== null && !inputState && errorMsg ? (
                <div className="absolute text-danger text-left text-xs">
                    {errorMsg}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}
export default CustomSelect
