import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {getLocalStorage, LangEnum, setLocalStorage} from "edah_utils/dist"
import store from "./redux/store"

// import css & scss
import './assets/css/tailwind.scss';
import './assets/css/default.scss';
import './assets/css/nav.scss';
import './assets/css/header.scss'
import './assets/css/state.scss'

// Import i18n
import i18n from "./i18n"
import {I18nextProvider} from "react-i18next"
import {Provider} from "react-redux";

//Get current lang
let lang = getLocalStorage('lang')

//當前語言不存在
if (!lang) {
    lang = LangEnum.Taiwan
    setLocalStorage('lang', lang)
}

i18n.changeLanguage(lang)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
