import React, {useEffect, useState} from 'react'
import {ApiErrorStatusEnum, getLocalStorage, stringIsEmpty, ToastTypeEnum} from 'edah_utils/dist';
import CustomTable from '../RSISCustomTable';
import {BasePopup} from '../../Popup/BasePopup';
import {RSISPersonnelListQueryTable} from '../Constant';
import {gueryEmployeeInfo} from "../../../api/v1/Users";
import {usePopup} from "../PopupProvider";
import {BaseInput} from "../../Input/BaseInput";
import {filterStringIsEmpty, Title} from "../utils";
import {t} from "i18next"

/**
 * 列表組件
 * @param {Object} item 列表項目
 * @param {number} index 列表索引
 * @param {function} handleOnClick 點擊事件
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      handleOnClick,
                  }) => {

    /**
     * 點擊列表項目事件，帶回醫師資料
     * @return {void}
     */
    const handleOnItemClick = () => {
        handleOnClick(item)
    }

    /**
     * 過濾空字串
     * @param value {string} 字串
     * @return {string} 過濾後的字串
     */
    const getValidString = (value) => {
        let str = ''
        stringIsEmpty(value) ? str = '' : str += value
        return str
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleOnItemClick}>
            <div>{item.userNo}</div>
            <div>{item.depNo}</div>
            <div>{item.userName}</div>
            <div>{`${getValidString(item.hospNo)} ${getValidString(item.hospName)}`}</div>
        </li>
    )
}

/**
 * 人員清單查詢彈窗
 * @param {boolean} filterEmp 是否過濾專職人員及非專職人員，預設為false
 * @param {function} handleOnClose 關閉彈窗
 * @param {function} handleOnUpdate 更新事件
 * @return {JSX.Element}
 */
const QueryPersonList = ({filterEmp = false, handleOnClose, handleOnUpdate}) => {
    /**
     * Show Toast
     */
    const {showToast} = usePopup();
    // 醫師代號/工號
    const [doctorNo, setDoctorNo] = useState('');
    // 姓名
    const [name, setName] = useState('');
    // 人員清單
    const [dataList, setDataList] = useState([]);

    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    });

    /**
     * 查詢
     * @return {void}
     */
    const handleOnSearch = () => getCreateUserList()

    /**
     * 員工/醫師代碼改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorNoOnChange = (e) => setDoctorNo(e.target.value)

    /**
     * 姓名改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNameOnChange = (e) => setName(e.target.value)

    /**
     * 過濾專職人員及非專職人員，僅顯示本院院區專職人員及員工，專職人員有執照，員工若為本院及是空字串
     * @param data {Array} 人員列表
     * @return {Array} 過濾後的人員列表
     */
    const getFilterEmployeeList = (data) => {
        // 取得登入醫院院區全名
        const campusId = getLocalStorage('campusId')
        // 返回執登院所符合campusFullName的人員及空字串的員工
        return data.filter((item) => {
            // 若hospNo、hospName為空字串，則表示為本院員工
            return item.hospNo === campusId || (filterStringIsEmpty(item.hospNo) && filterStringIsEmpty(item.hospName))
        })
    }

    /**
     * 取得醫師非醫師人員清單
     * @return {void}
     */
    const getCreateUserList = () => {
        gueryEmployeeInfo({
            userNo: doctorNo,
            userName: name,
            medicalStaff: '',
            activeFlag: true
        }).then((res) => {
            const {err, data, msg} = res
            //執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 如果資料長度為0
                if(data.length === 0) {
                    showToast({message: '查無資料', type: ToastTypeEnum.Warning})
                }
                // 是否過濾專職人員及非專職人員
                if(filterEmp) {
                    // 取得過濾後的人員列表
                    const filteredEmpList = getFilterEmployeeList(data)
                    // 若過濾後的人員列表為空，則顯示提示訊息
                    if(filteredEmpList.length === 0) {
                        showToast({message: t('RSIS.toast.docIDCheck'), type: ToastTypeEnum.Warning})
                    }

                    // 人員列表按照代號由小到大排序
                    const sortedEmpList = filteredEmpList.sort((a, b) => a.userNo - b.userNo)

                    // 設定過濾後的人員列表
                    setDataList(sortedEmpList)
                }else{

                    // 人員列表按照代號由小到大排序
                    const sortedEmpList = data.sort((a, b) => a.userNo - b.userNo)
                    // 設定排序後的人員列表
                    setDataList(sortedEmpList)
                }
            } else { //錯誤時
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    return (
        <BasePopup
            title="人員清單查詢"
            width="1162px"
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='px-6 pb-6'>
                    <div className='flex justify-start items-center mb-4 gap-4'>
                        <div className='flex flex-row items-center'>
                            {/*醫師代號/工號*/}
                            <Title children={'醫師代號/工號：'}/>
                            <BaseInput
                                className='bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300'
                                title={'醫師代號/工號：'} setValue={setDoctorNo} value={doctorNo} extendClass={'w-64'}
                                onChange={handleDoctorNoOnChange}/>
                        </div>
                        {/*姓名*/}
                        <Title children={'姓名：'}/>
                        <BaseInput
                            className='bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300'
                            title={'姓名：'} value={name} setValue={setName} extendClass={'w-64'}
                            onChange={handleNameOnChange}/>
                        <div className="source flex flex-row items-center justify-start mr-4">
                            {/*查詢按鈕*/}
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px] hover:cursor-pointer"
                                onClick={handleOnSearch}>
                                {t('general.query')}
                            </button>
                        </div>
                    </div>
                    <CustomTable
                        fields={RSISPersonnelListQueryTable.key} dataList={dataList} advancedSearch={true}
                        ListItem={(props) => (<ListItem{...props} handleOnClick={handleOnUpdate}/>)}
                        type={'RSISQueryPersonListTable'}
                        paginationProps={paginationProps}/>
                </div>
            }
        />
    )
}

export default QueryPersonList
