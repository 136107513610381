import React, { useEffect, useMemo, useState } from 'react'
import { TRAN_OUT_LIST_FIELDS } from '../../constants/referralSystem/tranOutCase'
import { DATETIME_FORMAT, DATE_FORMAT } from '../../constants/common'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import TranOutCaseModal from '../../components/ReferralSystem/TranOutCaseList/TranOutCaseModal'
import QueryToolsModal from '../../components/ReferralSystem/QueryToolsModal'
import { BasePopup } from '../../components/Popup/BasePopup'
import { Pagination } from '../../components/Pagination/Pagination'
import TranOutNoReplyLetterListModal from '../../components/ReferralSystem/TranOutCaseList/TranOutNoReplyLetterListModal'
import { ReactComponent as ExcelIcon } from '../../assets/images/icons/excel_icon.svg'
import {
    trancaseQueryBasicData,
    trancaseQueryTranOutBySearch,
    trancaseQueryTranOutByEncounterId,
    trancaseEditTranOut,
} from '../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    ToastTypeEnum,
    validDateFormat,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import { BaseInput } from '../../components/Input/BaseInput'

/**
 * 急救責任照顧醫院
 * 0:全部、1: 是急救責任照顧醫院
 */
const AID_FLAG_VALUE = {
    all: 0,
    isAid: 1,
}

/**
 * 轉出單個管作業_清單
 * @return {JSX.Element}
 */
const TranOutCaseList = () => {
    /**
     * Toast Message Hooks
     */
    const showToast = useToast()

    /**
     * 轉出單列表
     */
    const [dataList, setDataList] = useState([])

    /**
     * 轉出單modal
     */
    const [isShowTranOutModal, setIsShowTranOutModal] = useState(false)

    /**
     * 未收到轉回原醫療院所名單modal
     */
    const [isShowNoReceiptLetterModal, setIsShowNoReceiptLetterModal] =
        useState(false)

    // TODO:查詢工具 - 第二階段開發
    const [isShowQueryToolsModal, setIsShowQueryToolsModal] = useState(false)

    /**
     * 檢核狀態
     */
    const [inputState, setInputState] = useState({
        // 轉出日期(起日)
        tranStartDateState: null,
        // 轉出日期(迄日)
        tranEndDateState: null,
    })

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    /**
     * 查詢參數
     */
    const [searchParams, setSearchParams] = useState({
        tranStartDate: '',
        tranEndDate: '',
        patientId: '',
        hospGrade: 'ALL',
        //個管顯示
        acceptFlag: 'ALL',
        //顯示條件
        cancelFlag: 'ALL',
        // 急救責任照顧醫院
        aidGradeFlag: 0,
        ruralType: 'ALL',
    })

    /**
     * 醫療層級下拉選單
     */
    const [hospGradeList, setHospGradeList] = useState([])
    /**
     * 偏鄉離島下拉選單
     */
    const [ruralTypeList, setRuralTypeList] = useState([])
    /**
     * 個管顯示下拉選單
     */
    const [acceptFlagList, setAcceptFlagList] = useState([])
    /**
     * 顯示條件下拉選單
     */
    const [cancelFlagList, setCancelFlagList] = useState([])
    /**
     * 醫師清單
     */
    const [doctorList, setDoctorList] = useState([])
    /**
     * 科別清單
     */
    const [divisionList, setDivisionList] = useState([])
    /**
     * 轉診原因下拉選單(for 轉出單_維護的轉診原因)
     */
    const [tranReasonList, setTranReasonList] = useState([])
    /**
     * 院所代碼
     */
    const [zonesetList, setZonesetList] = useState([])
    /**
     * 高雄地區
     */
    const [zipCodeList, setZipCodeList] = useState([])
    /**
     * 編輯的個案
     */
    const [editCase, setEditCase] = useState({})
    /**
     * 轉診追蹤狀態
     */
    const [outStatusList, setOutStatusList] = useState([])

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTrancaseQueryBasicData = () => {
        trancaseQueryBasicData().then((response) => {
            const { data, err } = response
            if (err === ApiErrorStatusEnum.Success) {
                setHospGradeList(data.hospGradeList)
                setRuralTypeList(data.ruralTypeList)
                setAcceptFlagList(data.acceptFlagList)
                setCancelFlagList(data.cancelFlagList)
                setDoctorList(data.doctorList)
                setDivisionList(data.divList)
                setOutStatusList(data.outStatusList)
                setTranReasonList(data.tranReasonList)
                setZonesetList(data.zonesetList)
                setZipCodeList(data.zipCodeList)
            } else {
                showToast({ message: response.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 更新查詢欄位value
     * @param {object} e input event
     * @param {string} field 欄位名稱
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        if (field === 'aidGradeFlag') {
            const value =
                searchParams.aidGradeFlag === AID_FLAG_VALUE.all
                    ? AID_FLAG_VALUE.isAid
                    : AID_FLAG_VALUE.all
            setSearchParams({
                ...searchParams,
                aidGradeFlag: value,
            })
            return
        }
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 編輯按鈕
     * @param {object} tranOutCase 要編輯的單筆資料
     * @return {JSX.Element}
     */
    const Editor = (tranOutCase) => (
        <button
            className="text-[#007CDB] flex"
            onClick={() => showEditModal(tranOutCase)}
        >
            <EditIcon />
            編輯
        </button>
    )

    /**
     * 匯出EXCEL
     * @returns {void}
     */
    const exportExcel = () => {
        const selectedList = dataList.filter((item) => item.isSelected)
        if (selectedList.length === 0) {
            return
        }
        const dataArray = []
        const fields = TRAN_OUT_LIST_FIELDS.slice(1)
        selectedList.forEach((item) => {
            const data = []
            fields.forEach((field) => {
                const value = field.format
                    ? field.format(item[field.key])
                    : item[field.key]

                data.push(value)
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: fields.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '轉出單個管作業',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     * @return {JSX.Element}
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={exportExcel}
            >
                <ExcelIcon className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 轉出清單 table 顯示欄位
     * @return {void}
     */
    const renderFields = useMemo(() => {
        TRAN_OUT_LIST_FIELDS[0] = {
            ...TRAN_OUT_LIST_FIELDS[0],
            render: Editor,
        }
        return TRAN_OUT_LIST_FIELDS
    })

    /**
     * 查詢轉入單個管作業資料
     * @return {Promise<void>}
     */
    const getTranOutCaseList = (page, pageSize) => {
        // 檢核欄位
        const tranStartDateIsValid = validDateFormat(searchParams.tranStartDate)
        const tranEndDateInvalid = validDateFormat(searchParams.tranEndDate)
        setInputState({
            tranStartDateState: tranStartDateIsValid,
            tranEndDateState: tranEndDateInvalid,
        })
        if (!tranStartDateIsValid || !tranEndDateInvalid) {
            return
        }

        const params = {
            ...searchParams,
            tranStartDate: time2String(
                searchParams.tranStartDate,
                DATETIME_FORMAT
            ),
            tranEndDate: time2String(searchParams.tranEndDate, DATETIME_FORMAT),
            pageNum: page,
            pageSize,
        }
        trancaseQueryTranOutBySearch(params).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                const selectableList = response.data.dataList.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
                setDataList(selectableList)
                setFilter({
                    ...filter,
                    totalItemSize: response.data.totalItemSize,
                    totalPageSize: response.data.totalPageSize,
                    pageSize,
                    pageNum: page,
                })
                if (response.data.dataList.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
            } else {
                showToast({
                    message: response.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const pageNum = Number(page)
        getTranOutCaseList(pageNum, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranOutCaseList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getTranOutCaseList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const pageNum = nextPage > filter.totalPageSize ? firstPage : nextPage
        getTranOutCaseList(pageNum, filter.pageSize)
    }

    /**
     * 開啟轉出單個管作業_維護modal
     * @param {object} tranOutCase 點選編輯的單筆資料
     * @return {Promise<void>}
     */
    const showEditModal = (tranOutCase) => {
        trancaseQueryTranOutByEncounterId({
            encounterDate: tranOutCase.encounterDate,
            encounterId: tranOutCase.encounterId,
            inpOpd: tranOutCase.inpOpd,
            patientId: tranOutCase.patientId,
        }).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                let data = {}
                // 需將回傳值為null改為 empty string 以防轉出單維護視窗輸入框跳錯誤，時間格式改為 YYYY-MM-DD 讓 datepicker 顯示
                Object.keys(response.data).forEach((key) => {
                    switch (key) {
                        case 'outNoteFinishFlag':
                        case 'tranInFinishFlag':
                        case 'outCancerFlag':
                            data[key] = !!response.data[key]
                            break
                        case 'tranDate':
                        case 'outReplyDate':
                        case 'outReplyDate2':
                        case 'outReturnDate':
                        case 'dischargeDate':
                        case 'admissionDate':
                            data[key] = response.data[key]
                                ? time2String(response.data[key], DATE_FORMAT)
                                : ''
                            break
                        default:
                            data[key] =
                                response.data[key] !== null
                                    ? response.data[key]
                                    : ''
                    }
                })
                setEditCase(data)
                setIsShowTranOutModal(true)
            } else {
                showToast({
                    message: response.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 轉出單個管作業_維護modal 存檔
     * @return {Promise<void>}
     */
    const onSave = (caseDetail) => {
        const data = {
            lockVersion: caseDetail.lockVersion, // 版本鎖定
            encounterId: caseDetail.encounterId, //就醫號
            encounterDate: caseDetail.encounterDate, //就醫日期
            inpOpd: caseDetail.inpOpd, // 門住類別(O門診/E急診/I住院)
            patientId: caseDetail.patientId, // 病歷號
            contact: caseDetail.contact, //聯絡人
            contactTel: caseDetail.contactTel, //連絡電話
            contactAddress: caseDetail.contactAddress,
            divNo: caseDetail.divNo, // 就醫科別代碼
            doctorNo: caseDetail.doctorNo, // 醫師代碼
            treatmentCode: caseDetail.treatmentCode, // 診斷代碼
            treatmentCode1: caseDetail.treatmentCode1, // 次診斷代碼1
            treatmentCode2: caseDetail.treatmentCode2, // 次診斷代碼2
            subject: caseDetail.subject, //病情摘要
            clinicInfo: caseDetail.clinicInfo, //檢查及治療摘要
            tranNo: caseDetail.tranNo, //手工轉診單單號
            etranNo: caseDetail.etranNo, //電子轉診單單號
            tranDate: caseDetail.tranDate
                ? time2String(caseDetail.tranDate, DATETIME_FORMAT)
                : '', // 轉診日期
            tranDoctorNo: caseDetail.tranDoctorNo, // 轉診醫師代碼
            tranOutHospNo: caseDetail.tranOutHospNo, // 轉出院所
            tranOutAidBranchNo: caseDetail.tranOutAidBranchNo,
            tranOutDivNo: caseDetail.tranOutDivNo, // 轉診科別代碼
            tranReason: caseDetail.tranReason, // 轉診原因(院內)
            tranReasonRemark: caseDetail.tranReasonRemark, // 轉診原因備註(院內)
            tranPurpose: caseDetail.tranPurpose, //轉診目的代碼
            tranPurposeRemark: caseDetail.tranPurposeRemark, //轉診目的備註
            condition: caseDetail.condition, //病情、1.穩定、2.重症、3.死亡、4.病危自動出院
            otherChoice: caseDetail.otherChoice, //其他、1.外傷、2.需ICU、3.需開刀
            vpnReason: caseDetail.vpnReason, //VPN原因
            otherReason: caseDetail.otherReason, //其他原因
            urgentType: caseDetail.urgentType, //緊急傷病患註記、E:急診、I:加護病房
            urgentReason: caseDetail.urgentReason, //緊急傷病轉診原因
            urgentReasonRemark: caseDetail.urgentReasonRemark, //緊急傷病轉診原因(其他)
            urgentOrderDatetime: caseDetail.urgentOrderDatetime, //緊急傷病開單時間
            orderDatetime: caseDetail.orderDatetime, //開單時間
            uploadDatetime: caseDetail.uploadDatetime, //回覆單上傳日期時間
            outRealOutHospNo: caseDetail.outRealOutHospNo, // 實際轉出院所代碼
            outRealOutDivNo: caseDetail.outRealOutDivNo, // 實際轉出科別代碼
            outReturnDate: caseDetail.outReturnDate
                ? time2String(caseDetail.outReturnDate, DATETIME_FORMAT)
                : '', // 轉診單回收日期
            outReplyDate: caseDetail.outReplyDate
                ? time2String(caseDetail.outReplyDate, DATETIME_FORMAT)
                : '', // 接受對方回覆日期(初步)
            outReplyDate2: caseDetail.outReplyDate2
                ? time2String(caseDetail.outReplyDate2, DATETIME_FORMAT)
                : '', // 接受回覆日期(病歷摘要)
            dischargeDate: caseDetail.dischargeDate
                ? time2String(caseDetail.dischargeDate, DATETIME_FORMAT)
                : '', // 出院日期
            outStatus: caseDetail.outStatus, // 轉診追蹤狀態
            outCancerFlag: !!caseDetail.outCancerFlag, //癌症篩檢
            outRemark: caseDetail.outRemark, // 備註
            admissionDate: caseDetail.admissionDate
                ? time2String(caseDetail.admissionDate, DATETIME_FORMAT)
                : '', // 入院日期
            hospGrade: caseDetail.hospGrade, // 轉出醫院層
        }
        trancaseEditTranOut(data).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
                setIsShowTranOutModal(false)
                getTranOutCaseList(filter.pageNum, filter.pageSize)
            } else {
                //顯示Toast Message
                showToast({ message: response.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 開啟未收到轉回元醫療院所名單modal
     * @param {object} e 點選編輯的單筆資料
     * @return {void}
     */
    const showNoReplyLetterModal = (e) => {
        e.preventDefault()
        setIsShowNoReceiptLetterModal(true)
    }

    /**
     * 開啟查詢工具 - phase 2
     * @param {object} e
     */
    const showQueryToolModal = (e) => {
        e.preventDefault()
        setIsShowQueryToolsModal(true)
    }

    /**
     * 全選資料
     * @param isAllSelected {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 個管顯示改動時
     * @param e {event} event
     * @return {void}
     */
    const handleAcceptFlagOnChange = (e) =>
        setSearchParams({ ...searchParams, acceptFlag: e.target.value })

    /**
     * 顯示條件改動時
     * @param e {event} event
     * @return {void}
     */
    const handleCancelFlagOnChange = (e) =>
        setSearchParams({ ...searchParams, cancelFlag: e.target.value })

    /**
     * 偏鄉離島改動時
     * @param e {event} event
     * @return {void}
     */
    const handleRuralTypeOnChange = (e) =>
        setSearchParams({ ...searchParams, ruralType: e.target.value })

    /**
     * 提交表單
     * @return {void}
     */
    const handleSubmit = () => {
        getTranOutCaseList(1, filter.pageSize)
    }

    /**
     * 第一次執行時取得轉診基礎資料
     */
    useEffect(() => {
        getTrancaseQueryBasicData()
    }, [])

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div>
                <div className="flex flex-wrap p-2">
                    <div className="date flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">
                            轉出日期<span className="text-danger ">*</span>:
                        </div>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranStartDate}
                            onChange={(e) =>
                                updateSearchParams(e, 'tranStartDate')
                            }
                            type="date"
                            inputState={inputState.tranStartDateState}
                            errorMsg="必填"
                        />
                        <span className="m-1">~</span>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranEndDate}
                            onChange={(e) =>
                                updateSearchParams(e, 'tranEndDate')
                            }
                            type="date"
                            inputState={inputState.tranEndDateState}
                            errorMsg="必填"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">病歷號:</div>
                        <input
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type="text"
                            value={searchParams.patientId}
                            onChange={(e) => updateSearchParams(e, 'patientId')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">醫療層級:</div>
                        <select
                            className="w-[120px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={searchParams.hospGrade}
                            onChange={(e) => updateSearchParams(e, 'hospGrade')}
                        >
                            {hospGradeList.map((item) => (
                                <option
                                    key={item.hospGrade}
                                    value={item.hospGrade}
                                >
                                    {item.hospGradeName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">顯示條件:</div>
                        <select
                            className="w-[120px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={searchParams.cancelFlag}
                            onChange={(e) => handleCancelFlagOnChange(e)}
                        >
                            {cancelFlagList.map((item) => (
                                <option
                                    key={item.cancelFlag}
                                    value={item.cancelFlag}
                                >
                                    {item.cancelFlagName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">個管顯示:</div>
                        <select
                            className="w-[120px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={searchParams.acceptFlag}
                            onChange={(e) => handleAcceptFlagOnChange(e)}
                        >
                            {acceptFlagList.map((item) => (
                                <option
                                    key={item.acceptFlag}
                                    value={item.acceptFlag}
                                >
                                    {item.acceptFlagName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start mb-5 mr-2">
                        <div className="mr-4">偏鄉離島:</div>
                        <select
                            className="w-[120px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={searchParams.ruralType}
                            onChange={(e) => handleRuralTypeOnChange(e)}
                        >
                            {ruralTypeList.map((item) => (
                                <option
                                    key={item.ruralType}
                                    value={item.ruralType}
                                >
                                    {item.ruralTypeName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-between p-2 mb-2">
                    <div className="flex flex-wrap items-center">
                        <div className="mr-4">
                            <input
                                type="checkbox"
                                style={{ accentColor: '#38A169' }}
                                id="aidGrade"
                                name="aidGrade"
                                className="mr-2 bg-green-400 checked:bg-green-600 text-green-600"
                                checked={
                                    searchParams.aidGradeFlag ===
                                    AID_FLAG_VALUE.isAid
                                }
                                onChange={(e) =>
                                    updateSearchParams(e, 'aidGradeFlag')
                                }
                            />
                            <label htmlFor="aidGrade">急救責任照顧醫院</label>
                        </div>
                        <button
                            onClick={handleSubmit}
                            className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        >
                            查詢
                        </button>
                    </div>
                    <div className="flex">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={(e) => showNoReplyLetterModal(e)}
                        >
                            列印尚未收到接受轉診醫院回覆名單
                        </button>
                        {/* TODO: 第二階段 */}
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                            onClick={(e) => showQueryToolModal(e)}
                        >
                            查詢工具
                        </button>
                    </div>
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
                slotButton={<ExportExcelBtn />}
            />
            <div className="flex justify-end mt-2">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {isShowTranOutModal && (
                <TranOutCaseModal
                    editCase={editCase}
                    doctorList={doctorList}
                    divisionList={divisionList}
                    outStatusList={outStatusList}
                    tranReasonList={tranReasonList}
                    zipCodeList={zipCodeList}
                    onClose={() => setIsShowTranOutModal(false)}
                    onConfirm={onSave}
                />
            )}

            {/* TODO:查詢工具  -第二階段開發 */}
            {isShowQueryToolsModal && (
                <BasePopup
                    content={<QueryToolsModal />}
                    width="80%"
                    title="轉診查詢工具"
                    closePopupButtonOnClick={() =>
                        setIsShowQueryToolsModal(false)
                    }
                />
            )}

            {isShowNoReceiptLetterModal && (
                <BasePopup
                    content={
                        <TranOutNoReplyLetterListModal
                            outStatusList={outStatusList}
                            zipCodeList={zipCodeList}
                        />
                    }
                    width={'80%'}
                    title="未收到原醫療院所回覆名單"
                    closePopupButtonOnClick={() =>
                        setIsShowNoReceiptLetterModal(false)
                    }
                />
            )}
        </div>
    )
}

export default TranOutCaseList
