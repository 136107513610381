//Import Icon
import {ReactComponent as TipsIcon} from "../../../assets/images/SystemMonitoring/qa_tips.svg"

//Import Function
import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput"
import {
    ApiErrorStatusEnum,
    getLocalStorage,
    InputTypeEnum,
    isNumber,
    PopupModeEnum,
    stringIsEmpty,
    ToastTypeEnum,
    WDISCheckTypeEnum
} from "edah_utils/dist"
import EggSwitch from "../../Button/EggSwitch"
import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {wdisAddServiceMonitor, wdisEditServiceMonitor} from "../../../api/v1/WDIS"
import useToast from "../../../hooks/useToast"
import {ModuleMenuPopup} from "./ModuleMenuPopup";

/**
 *  新增/修改 服務註冊popup
 *  @param show {boolean} 是否顯示
 *  @param popupMode {number} 彈窗模式
 *  @param campusList {Array<Object>} 院區列表
 *  @param popupCloseButtonOnClick {function} 關閉彈窗
 *  @return {JSX.Element}
 */
export const AddEditRegisterServicePopup = ({show, popupMode, campusList, popupCloseButtonOnClick}) => {
    /**
     * 預設資料
     */
    const defaultData = {
        //服務ID
        "serviceId": "",
        //服務名稱
        "serviceName": "",
        //服務簡稱
        "serviceSname": "",
        //服務之系統代號,指 FUNCTION 的系統別
        "serviceSystemNo": "",
        //服務啓用否(0停止/1啟用) 啟用否與刪除之欄位有不同的意思,例如未來可能做定期檢查 服務啟用否 之檢查或清單
        "serviceEnable": 1,
        //醫院代號
        "zone": null,
        //顯示順序
        "sortBy": null,
        //服務註解
        "serviceNote": "",
        //檢核類別: 1:Month/2:Week/3:Day/4:Hour/5:Min/6:Special
        "checkType": WDISCheckTypeEnum.Month,
        //檢查區間
        "checkValue": "",
        //基準時間24HHMM
        "basicTime": "",
        //延遲時間(分)
        "delayMin": "",
        //指定日期時間
        "assignDatetime": "",
        //負責人代號
        "ownerUserNo": "",
        //預計執行時長(分)
        "estimateTime": 5,
        //服務有效否(0無效/1有效)
        "effFlag": 1,
        //服務之來源路徑在服務註册時需取得該服務之位置之參數
        //例如: 取得該服務之狀之URL(目前均預設以GET方式)
        "serviceUrl": "",
        //勿打擾時段-起
        "noAlarmStartTime": "",
        //勿打擾時段-起
        "noAlarmEndTime": "",
        //通知訊息抬頭
        "msgTitle": "",
        //訊息內容 可使用 @模板文字 來取代文本內容
        "msgContent": "",
        //忽略執行錯誤(不報警) 0:正常 1:忽略
        "ignoreErrorFlag": 0,
        //忽略錯誤連續次數
        "ignoreErrorCount": 0,
        //新增人員代號
        "createUser": "",
        //新增日期時間
        "createDatetime": "",
        //修訂人員代號
        "modifyUser": "",
        //修訂人員日期時間
        "modifyDatetime": "",
        //版本鎖定
        "lockVersion": 1
    }

    // Show Toast
    const showToast = useToast()

    // 是否顯示刪除彈窗
    const [isShow, setIsShow] = useState(show)

    //是否顯示ToolTips
    const [showToolTipsPopup, setShowToolTipsPopup] = useState(false)

    //資料
    const [data, setData] = useState(defaultData)

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(popupMode === PopupModeEnum.Add)

    //是否顯示模組選單
    const [showModuleMenuPopup, setShowModuleMenuPopup] = useState(false)

    /**
     * 服務代號改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceIdOnChange = (e) => setData({...data, serviceId: e.target.value})

    /**
     * 服務之系統代號改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceSystemNo = (e) => setData({...data, serviceSystemNo: e.target.value})

    /**
     * 院區改變時
     * @param e {event} event
     * @return {void}
     */
    const handleZoneOnChange = (e) => setData({...data, zone: e.target.value})

    /**
     * 服務啓用否(0停止/1啟用)
     * @return {void}
     */
    const handleServiceEnableOnToggle = () => setData({...data, serviceEnable: !data.serviceEnable})

    /**
     * 服務名稱改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceNameOnChange = (e) => setData({...data, serviceName: e.target.value})

    /**
     * 服務簡稱改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceSnameOnChange = (e) => setData({...data, serviceSname: e.target.value})

    /**
     * 顯示順序改變時
     * @param e {event} event
     * @return {void}
     */
    const handleSortByOnChange = (e) => setData({...data, sortBy: e.target.value})

    /**
     * 顯示順序按下時
     * @param e {event} event
     * @return {void}
     */
    const handleSortByOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key, ctrlKey, metaKey} = e
        // 檢查是否為數字或控制鍵
        const isControlKey = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)

        // 檢查是否為組合鍵 Ctrl + A
        const isShortcutKey = (ctrlKey || metaKey) && ["a", "c", "v", "x"].includes(key.toLowerCase())

        // 如果不是數字或控制鍵
        if (!isNumber(key) && !isControlKey && !isShortcutKey) {
            // 阻止預設事件
            e.preventDefault()
        }
    }

    /**
     * 服務之URL參數改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceUrlOnChange = (e) => setData({...data, serviceUrl: e.target.value})

    /**
     * 服務註解改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceNoteOnChange = (e) => setData({...data, serviceNote: e.target.value})

    /**
     * 服務類型改變時
     * @param e {event} event
     * @return {void}
     */
    const handleCheckTypeOnChange = (e) => setData({...data, checkType: e.target.value})

    /**
     * 檢查區間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleCheckValueOnChange = (e) => setData({...data, checkValue: e.target.value})

    /**
     * ToolTips滑入時
     * @return {void}
     */
    const handleTooltipsButtonOnMouseEnter = () => setShowToolTipsPopup(true)

    /**
     * ToolTips滑出時
     * @return {void}
     */
    const handleTooltipsButtonOnMouseLeave = () => setShowToolTipsPopup(false)

    /**
     * 延遲時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleDelayMinOnChange = (e) => {
        //取得新的值
        let {value} = e.target

        // 如果長度大於5，則只取前5個字元
        if (data.delayMin.length > value.length) {
            if (data.delayMin[data.delayMin.length - 1] === ':') {
                value = value.slice(0, -1)
            }
        }

        // 如果長度大於2且沒有 `:`，則插入 `:`
        if (value.length > 2 && value.indexOf(':') === -1) {
            value = value.slice(0, 2) + ':' + value.slice(2, 4)
        }

        setData({...data, delayMin: value})
    }

    /**
     * 延遲時間按下時
     * @param e {event} event
     * @return {void}
     */
    const handleDelayMinOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key} = e

        // 檢查是否為數字或控制鍵
        const isControlKey = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)

        if (!isNumber(key) && !isControlKey && data.delayMin.length >= 5) {
            // 阻止預設事件
            e.preventDefault()
        }
    }

    /**
     * 起始基準時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleBasicTimeOnChange = (e) => {
        //取得新的值
        let {value} = e.target

        // 如果長度大於5，則只取前5個字元
        if (data.basicTime.length > value.length) {
            if (data.basicTime[data.basicTime.length - 1] === ':') {
                value = value.slice(0, -1)
            }
        }

        // 如果長度大於2且沒有 `:`，則插入 `:`
        if (value.length > 2 && value.indexOf(':') === -1) {
            value = value.slice(0, 2) + ':' + value.slice(2, 4)
        }

        setData({...data, basicTime: value})
    }

    /**
     * 起始基準時間按下時
     * @param e {event} event
     * @return {void}
     */
    const handleBasicTimeOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key} = e

        // 檢查是否為數字或控制鍵
        const isControlKey = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)

        if (!isNumber(key) && !isControlKey && data.basicTime.length >= 5) {
            // 阻止預設事件
            e.preventDefault()
        }
    }

    /**
     * 指定日期改變時
     * @param e {event} event
     * @return {void}
     */
    const handleAssignDatetimeOnChange = (e) => setData({...data, assignDatetime: e.target.value})

    /**
     * 負責人員改變時
     * @param e {event} event
     * @return {void}
     */
    const handleOwnerUserNoOnChange = (e) => setData({...data, ownerUserNo: e.target.value})

    /**
     * 預計執行時長改變時
     * @param e {event} event
     * @return {void}
     */
    const handleEstimateTimeOnChange = (e) => setData({...data, estimateTime: e.target.value})

    /**
     * 預計執行時長按下時
     * @param e {event} event
     * @return {void}
     */
    const handleEstimateTimeOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key} = e

        // 檢查是否為數字或控制鍵
        const isControlKey = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)

        if (!isNumber(key) && !isControlKey) {
            // 阻止預設事件
            e.preventDefault()
        }
    }

    /**
     * 勿打擾時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleNoAlarmStartTimeOnChange = (e) => setData({...data, noAlarmStartTime: e.target.value})

    /**
     * 勿打擾時間按下時
     * @param e {event} event
     * @return {void}
     */
    const handleNoAlarmStartTimeOnKeyDown = (e) => {
        //取得新的值
        let {value} = e.target

        // 如果長度大於5，則只取前5個字元
        if (data.noAlarmStartTime.length > value.length) {
            if (data.noAlarmStartTime[data.noAlarmStartTime.length - 1] === ':') {
                value = value.slice(0, -1)
            }
        }

        // 如果長度大於2且沒有 `:`，則插入 `:`
        if (value.length > 2 && value.indexOf(':') === -1) {
            value = value.slice(0, 2) + ':' + value.slice(2, 4)
        }

        setData({...data, noAlarmStartTime: value})
    }

    /**
     * 勿打擾時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleNoAlarmEndTimeOnChange = (e) => setData({...data, noAlarmEndTime: e.target.value})

    /**
     * 勿打擾時間按下時
     * @param e {event} event
     * @return {void}
     */
    const handleNoAlarmEndTimeOnKeyDown = (e) => {
        //取得新的值
        let {value} = e.target

        // 如果長度大於5，則只取前5個字元
        if (data.noAlarmEndTime.length > value.length) {
            if (data.noAlarmEndTime[data.noAlarmEndTime.length - 1] === ':') {
                value = value.slice(0, -1)
            }
        }

        // 如果長度大於2且沒有 `:`，則插入 `:`
        if (value.length > 2 && value.indexOf(':') === -1) {
            value = value.slice(0, 2) + ':' + value.slice(2, 4)
        }

        setData({...data, noAlarmEndTime: value})
    }

    /**
     * 忽略執行結果為錯誤切換時
     * @return {void}
     */
    const handleIgnoreErrorFlagOnToggle = () => setData({...data, ignoreErrorFlag: data.ignoreErrorFlag ? 0 : 1})

    /**
     * 忽略執行結果為錯誤次數改變時
     * @param e {event} event
     * @return {void}
     */
    const handleIgnoreErrorCountOnChange = (e) => setData({...data, ignoreErrorCount: e.target.value})

    /**
     * 忽略執行結果為錯誤次數按下時
     * @param e {event} event
     * @return {void}
     */
    const handleIgnoreErrorCountOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key} = e

        // 檢查是否為數字或控制鍵
        const isControlKey = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)

        if (!isNumber(key) && !isControlKey) {
            // 阻止預設事件
            e.preventDefault()
        }
    }

    /**
     * 信件主旨改變時
     * @param e {event} event
     * @return {void}
     */
    const handleMsgTitleOnChange = (e) => setData({...data, msgTitle: e.target.value})

    /**
     * 信件內容改變時
     * @param e {event} event
     * @return {void}
     */
    const handleMsgContentOnChange = (e) => setData({...data, msgContent: e.target.value})

    /**
     * 信件內容按下時
     * @param e {event} event
     * @return {void}
     */
    const handleMsgContentOnKeyDown = (e) => {
        // 取得按下的鍵
        const {key} = e
        // 檢查是否為 @
        if (key === '@') {
            // 顯示模組選單
            setShowModuleMenuPopup(true)
        }
    }

    /**
     * 儲存新註冊
     * @return {void}
     */
    const handleSaveEditButtonOnClick = () => {
        // 新增模式
        if (isAddMode) {
            addServiceMonitor()
        } else { // 編輯模式
            editServiceMonitor()
        }
    }

    /**
     * 關閉模組選單
     * @return {void}
     */
    const handleModuleMenuPopupCloseButtonOnClick = () => setShowModuleMenuPopup(false)

    /**
     * 處理模組選單雙擊時
     * @param tagCode {string} 標籤代碼
     * @return {void}
     */
    const handleModuleMenuPopupOnDoubleClick = (tagCode) => {
        // @字元
        const atChar = '@'

        // 不等於空的話
        if (!stringIsEmpty(tagCode) && !stringIsEmpty(data.msgContent)) {
            // 取得最後一個 @ 的位置
            const lastAtIndex = data.msgContent.lastIndexOf(atChar)
            // 如果有找到
            if (lastAtIndex > -1) {
                // 取得 @ 之前的字串
                const beforeAt = data.msgContent.substring(0, lastAtIndex)
                // 設定新的字串
                setData({...data, msgContent: `${beforeAt}${tagCode}`})
            }
        }
    }

    /**
     * 處理API回應
     * @param res {Object} API回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        const {err, msg} = res
        // 取得成功
        if (err === ApiErrorStatusEnum.Success) {
            // 將資料設為預設
            setData(defaultData)
            // 顯示成功
            showToast({message: msg})
        } else { //寫入失敗
            // 顯示錯誤
            showToast({message: msg, type: ToastTypeEnum.Error})
        }
    }

    /**
     * 新增服務資料
     * @return {void}
     */
    const addServiceMonitor = () => {
        wdisAddServiceMonitor(data).then(handleApiResponse)
    }

    /**
     * 編輯服務資料
     * @return {void}
     */
    const editServiceMonitor = () => {
        wdisEditServiceMonitor(data).then(handleApiResponse)
    }

    /**
     * 取得Tooltips區塊
     * @return {JSX.Element}
     */
    const getShowToolTipsPopup = () => showToolTipsPopup ?
        <div
            className="w-[455px] p-4 z-10 h-[300px] absolute top-[250px] right-[40px] rounded-[12px] border-[1px] border-[#D4D4D8] bg-white">
            <div className="flex flex-row items-start justify-start text-left">
                <p className="mr-1">例:</p>
                <p>檢核類別=Month, 檢查區間=5/10/E起始基準時間=08:00
                    檢查區間指的是每月?日,可為多個值,以 / 區隔
                    代表每個月5日,10日與月底(可能為28日or 30日or
                    31日依該月而定)的08:00檢查服務是否正常執行工作
                </p>
            </div>
            <div className="flex flex-row items-start justify-start text-left">
                <p className="mr-1">例:</p>
                <p>檢核類別=Week, 檢查區間=1/3/7起始基準時間=08:00
                    檢查區間指的是星期?其值為 1-7,可為多個值,以 / 區隔
                    代表每星期一, 星期三, 星期日的08:00
                    開始檢查服務是否正常執行工作
                </p>
            </div>
            <div className="flex flex-row items-start justify-start text-left">
                <p className="mr-1">例:</p>
                <p>檢核類別=Day,檢查區間=3,起始基準時間=08:00,
                    檢查區間值只允許單個值,單位為天
                    代表從生效後的08:00檢查一次後每隔3 天的08:00
                    檢查服務是否正常執行工作
                </p>
            </div>
        </div>
        : null

    /**
     * 當 show 改變時，更新 isShow
     */
    useEffect(() => {
        // 更新 isShow
        setIsShow(show)
        // 顯示時
        if (show) {
            // 取得院區
            const campus = getLocalStorage('campus')
            // 將資料設為預設
            setData({...data, zone: campus})
            // 設定是否為新增模式
            setIsAddMode(popupMode === PopupModeEnum.Add)
        } else { // 關閉時
            // 將資料設為預設
            setData(defaultData)
        }
    }, [show])

    const getContent = () =>
        <div className="h-full overflow-y-scroll relative">
            <div className="w-full px-6 py-4">
                <ul className="w-full p-2.5">
                    <style>
                        {`input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                    </style>
                    {/*服務代號*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        {/*服務代號*/}
                        <p className="w-[160px] text-right mr-4 pr-4">服務代號</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.serviceId}
                                   onChange={(e) => handleServiceIdOnChange(e)}/>
                        {/*服務之系統別*/}
                        <select className="w-[144px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                value={data.serviceSystemNo} onChange={handleServiceSystemNo}>
                            <option value="0">服務之系統別</option>
                        </select>
                        {/*院區*/}
                        <p className="w-[54px] text-left mr-1">院區</p>
                        <select className="w-[171px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                                value={data.zone} onChange={handleZoneOnChange}>
                            {
                                campusList.map((item, index) =>
                                    <option key={index} value={item}>{t(`campus.shortName.${item}`)}</option>
                                )
                            }
                        </select>
                        {/*啟用否*/}
                        <p className="w-[48px] text-right">啟用否</p>
                        <EggSwitch onToggle={handleServiceEnableOnToggle} initialState={data.serviceEnable}/>
                    </li>
                    {/*服務名稱*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">服務名稱</p>
                        <BaseInput className="w-[655px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.serviceName}
                                   onChange={handleServiceNameOnChange}/>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        {/*服務簡稱*/}
                        <p className="w-[160px] text-right mr-4 pr-4">服務簡稱</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.serviceSname}
                                   onChange={handleServiceSnameOnChange}/>
                        {/*顯示順序*/}
                        <p className="w-[160px] text-right mr-4 pr-4">顯示順序</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} value={data.sortBy}
                                   onChange={handleSortByOnChange}
                                   onKeyDown={handleSortByOnKeyDown}/>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        {/*服務之URL參數*/}
                        <p className="w-[160px] text-right mr-4 pr-4">服務之URL參數</p>
                        <BaseInput className="w-[655px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.serviceUrl}
                                   onChange={handleServiceUrlOnChange}/>
                    </li>
                    {/*服務註解*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">服務註解</p>
                        <BaseInput className="w-[655px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.serviceNote}
                                   onChange={handleServiceNoteOnChange}/>
                    </li>
                    {/*檢核類別*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">檢核類別</p>
                        <select className="w-[235px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                value={data.checkType}
                                onChange={handleCheckTypeOnChange}>
                            {
                                Object.keys(WDISCheckTypeEnum)
                                    .filter(key => !isNumber(WDISCheckTypeEnum[key]))
                                    .map((item, index) => Number(item) === 0 ?
                                        // 全部
                                        null :
                                        // 其他
                                        <option key={index} value={item}>
                                            {`${item}-${t(`WDIS.CheckTypeEnum.${item}`)}`}
                                        </option>
                                    )
                            }
                        </select>
                    </li>
                    {/*檢查區間*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        {/*檢查區間*/}
                        <p className="w-[160px] text-right mr-4 pr-4">檢查區間</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.checkValue}
                                   onChange={handleCheckValueOnChange}/>
                        {/*ToolTips Button*/}
                        <button className="w-10 mr-6 relative"
                                onMouseEnter={handleTooltipsButtonOnMouseEnter}
                                onMouseLeave={handleTooltipsButtonOnMouseLeave}>
                            <TipsIcon/>
                        </button>
                        <p>(依檢核類別型態而定，請參閱説明)</p>
                    </li>
                    {/*延遲時間*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">延遲時間</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Text} inputMode={'clock'} placeholder={'00:00'}
                                   value={data.delayMin}
                                   onChange={handleDelayMinOnChange}
                                   onKeyDown={handleDelayMinOnKeyDown}/>
                    </li>
                    {/*起始基準時間*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">起始基準時間</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Text} inputMode={'clock'} placeholder={'00:00'}
                                   value={data.basicTime}
                                   onChange={handleBasicTimeOnChange}
                                   onKeyDown={handleBasicTimeOnKeyDown}/>
                    </li>
                    {/*指定日期*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">指定日期</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Date} inputMode={'date'} placeholder={'2024/06/20'}
                                   value={data.assignDatetime} onChange={handleAssignDatetimeOnChange}/>
                    </li>
                    {/*負責人員*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">負責人員</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                   type={InputTypeEnum.Text} inputMode={'search'} placeholder={'--'}
                                   value={data.ownerUserNo} onChange={handleOwnerUserNoOnChange}/>
                    </li>
                    {/*預計執行時長*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">預計執行時長</p>
                        <BaseInput className="w-[196px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                   type={InputTypeEnum.Text} inputMode={'search'} placeholder={'--'}
                                   value={data.estimateTime}
                                   onChange={handleEstimateTimeOnChange}
                                   onKeyDown={handleEstimateTimeOnKeyDown}/>
                        <p className="text-right mr-4 pr-4">分</p>
                    </li>
                    {/*預計執行時長*/}
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">勿打擾時間</p>
                        <BaseInput className="w-[120px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                   type={InputTypeEnum.Text} inputMode={'clock'} placeholder={'08:00'}
                                   value={data.noAlarmStartTime}
                                   onChange={handleNoAlarmStartTimeOnChange}
                                   onKeyDown={handleNoAlarmStartTimeOnKeyDown}/>
                        <p className="text-right mr-4 pr-4">至</p>
                        <BaseInput className="w-[120px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                   type={InputTypeEnum.Text} inputMode={'clock'} placeholder={'23:59'}
                                   value={data.noAlarmEndTime}
                                   onChange={handleNoAlarmEndTimeOnChange}
                                   onKeyDown={handleNoAlarmEndTimeOnKeyDown}/>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        {/*忽略執行結果為錯誤*/}
                        <p className="w-[160px] text-right mr-4 pr-4">忽略執行結果為錯誤</p>
                        <div className="w-[235px] mr-4">
                            <EggSwitch initialState={data.ignoreErrorFlag} onToggle={handleIgnoreErrorFlagOnToggle}/>
                        </div>
                        <p className="w-[160px] text-right mr-4 pr-4">執行錯誤連續次數</p>
                        <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.ignoreErrorCount}
                                   onChange={handleIgnoreErrorCountOnChange}
                                   onKeyDown={handleIgnoreErrorCountOnKeyDown}/>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">信件主旨</p>
                        <BaseInput className="w-[655px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} placeholder={'--'} value={data.msgTitle}
                                   onChange={handleMsgTitleOnChange}/>
                    </li>
                    <li className="flex flex-row items-start justify-start h-[150px] mb-1">
                        <p className="w-[160px] text-right mr-4 pr-4">信件內容</p>
                        <textarea
                            className="w-[655px] h-full pt-2 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            placeholder={'請輸入@以開啟模板選單'} value={data.msgContent}
                            onChange={handleMsgContentOnChange}
                            onKeyDown={handleMsgContentOnKeyDown}/>
                    </li>
                    {
                        // 最近修改日期只顯示在修改模式
                        !isAddMode &&
                        <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                            <p className="w-full text-right">最近修改日期：2023/12/12 14:00:00</p>
                        </li>
                    }
                </ul>
            </div>
            <div
                className="h-[60px] flex flex-row items-center justify-end border-t-[1px] border-[rgba(0,0,0,0.15)]">
                {/*儲存按鈕*/}
                <button
                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                    onClick={handleSaveEditButtonOnClick}>
                    {isAddMode ? t('general.save') : t('general.edit')}
                </button>
            </div>
            {/*Tooltips Popup*/}
            {getShowToolTipsPopup()}
            {/*模組選單popup*/}
            <ModuleMenuPopup show={showModuleMenuPopup} onClose={handleModuleMenuPopupCloseButtonOnClick}
                             onDoubleClick={handleModuleMenuPopupOnDoubleClick}/>
        </div>

    return isShow ?
        <BasePopup title={"服務註冊"} closePopupButtonOnClick={popupCloseButtonOnClick} width={"1008px"}
                   content={getContent()}/> : null

}
