import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { useEffect, useState } from 'react'
import {
    ApiErrorStatusEnum,
    validDateFormat,
    time2String,
    ToastTypeEnum,
    InputTypeEnum,
    arrayIsEmpty,
} from 'edah_utils/dist'
import {
    printTranReplyLetter,
    queryTranInCaseBySearch,
} from '../../api/v1/Rmis'
import { REFERRAL_REPLY_FIELDS } from '../../constants/referralSystem/referralReplyLetter'
import { BaseInput } from '../../components/Input/BaseInput'
import { DATETIME_FORMAT } from '../../constants/common'
import useToast from '../../hooks/useToast'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import { useSelector } from 'react-redux'
import store from '../../redux/store'
import { FORM_TYPE } from '../../constants/referralSystem/print'

/**
 * 轉診回函
 * @returns {JSX.Element}
 */
const ReferralReplyLetter = () => {
    const [encounterDate, setEncounterDate] = useState('')
    const [encouterDateInputState, setEncouterDateInputState] = useState(null)
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })
    const [activeRow, setActiveRow] = useState(null)
    const isPrintMode = useSelector((state) => state.print.isPrintMode)
    const showToast = useToast()

    // 就醫日期
    const handleEncounterDateOnChange = (e) => setEncounterDate(e.target.value)

    // 查詢
    const onClickQuery = () => {
        getTranInReplyLetter(1, filter.pageSize)
    }

    // 取得轉診回函清單
    const getTranInReplyLetter = (pageNum, pageSize) => {
        const encounterDateIsValid = validDateFormat(encounterDate)
        setEncouterDateInputState(encounterDateIsValid)
        if (!encounterDateIsValid) {
            return
        }
        queryTranInCaseBySearch({
            encounterDate: time2String(encounterDate, DATETIME_FORMAT),
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.dataList?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    const handleReplyLetterOnClick = (row) => {
        setActiveRow(row)
    }

    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 點選頁碼
     * @param {number} page 頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getTranInReplyLetter(currentPage, filter.pageSize)
    }

    /**
     * 更新每頁筆數
     * @param {number} page 每頁筆數
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranInReplyLetter(filter.pageNum, pageSize)
    }

    /**
     * 上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getTranInReplyLetter(page, filter.pageSize)
    }

    /**
     * 下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getTranInReplyLetter(page, filter.pageSize)
    }

    /**
     * 明細列印按鈕
     */
    const handlePrintReplyLetter = () => {
        const printData = dataList.filter((item) => item.isSelected)
        // 列印單頁資料
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.replyLetterList,
                printData,
            })
        )
    }

    /**
     * 信封列印按鈕
     */
    const handlePrintEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        // 列印單頁資料
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.replyLetterEnvelopeCover,
                printData,
            })
        )
    }

    /**
     * 標籤列印按鈕
     */
    const handlePrintLabel = () => {
        const printData = dataList.filter((item) => item.isSelected)
        // 列印單頁資料
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.replyLetterLabel,
                printData,
            })
        )
    }

    /**
     * 就醫資料列印(單筆列印/整批列印)
     * @params 是否列印全部資料 (true: 整批列印/ false: 單筆資料)
     */
    const handlePrintEncounterInfo = (isAll) => {
        const params = {
            encounterDate: time2String(encounterDate, DATETIME_FORMAT),
            encounterId: '',
        }
        if (isAll) {
            printTranReplyLetter(params).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    const printData = res.data
                    // 列印全部資料
                    store.dispatch(
                        updatePrintContent({
                            reportType: FORM_TYPE.replyLetterOrder,
                            printData,
                        })
                    )
                } else {
                    showToast({ message: res.msg, type: ToastTypeEnum.Error })
                }
            })
        } else {
            const selectedDataList = dataList.filter((item) => item.isSelected)
            if (selectedDataList.length === 0) {
                return
            }
            const encounterIdList = selectedDataList.map(
                (item) => item.encounterId
            )
            const encounterIdStrings = encounterIdList.join(',')
            params.encounterId = encounterIdStrings
            printTranReplyLetter(params).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    const printData = res.data
                    // 列印單頁資料
                    store.dispatch(
                        updatePrintContent({
                            reportType: FORM_TYPE.replyLetterOrder,
                            printData,
                        })
                    )
                } else {
                    showToast({ message: res.msg, type: ToastTypeEnum.Error })
                }
            })
        }
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex justify-between p-2 items-center">
                    <div className="flex items-center">
                        <div className="date flex flex-row items-center justify-start mr-4">
                            <div className="mr-4">
                                就醫日期<span className="text-danger">*</span>:
                            </div>
                            <BaseInput
                                className="w-[166px] h-10  px-2 bg-white text-black rounded-[6px] border"
                                type={InputTypeEnum.Date}
                                value={encounterDate}
                                onChange={handleEncounterDateOnChange}
                                inputState={encouterDateInputState}
                                errorMsg="必填"
                            />
                        </div>
                        <button
                            onClick={onClickQuery}
                            className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        >
                            查詢
                        </button>
                    </div>

                    <div className="flex">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handlePrintReplyLetter}
                        >
                            明細列印
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={() => handlePrintEncounterInfo(false)}
                        >
                            單筆列印
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={() => handlePrintEncounterInfo(true)}
                        >
                            整批列印
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handlePrintEnvelope}
                        >
                            信封列印
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                            onClick={handlePrintLabel}
                        >
                            標籤列印
                        </button>
                    </div>
                </div>
                <CustomTable
                    fields={REFERRAL_REPLY_FIELDS}
                    dataList={dataList}
                    isSelectable={true}
                    toggleAll={toggleAll}
                    toggleItem={toggleItem}
                    rowOnClick={handleReplyLetterOnClick}
                />
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.totalItemSize}
                        currentPage={filter.pageNum}
                        totalPageSize={filter.totalPageSize}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
                <div className="w-full mt-2 shadow rounded border-2 p-2 h-[200px] text-left">
                    {activeRow?.recipient.split('\n').map((item, index) => {
                        return <p key={`recipient${index}`}>{item}</p>
                    })}
                </div>
            </div>
        </>
    )
}
export default ReferralReplyLetter
