import React from 'react'
import {BasePopup} from '../../Popup/BasePopup'
import {usePopup} from "../PopupProvider";

/**
 * 詢問使用者是否送交衛福部彈窗
 * @param {function} handleOnClose - 控制彈窗關閉
 * @return {JSX.Element}
 */
const SendMOHWConfirm = ({handleOnClose}) => {

    const {
        // 設定變更彈窗開關狀態
        setShowChangeModal,
        // 設定註銷彈窗開關狀態
        setShowLogOffModal,
        // 設定收案確認(個案管理的編輯)彈窗開關狀態
        setShowCaseConfirmation,
        // 設定刪除彈窗開關狀態
        setShowDeletePopup,
        // 當前觸發的按鈕類型
        listItemBtnType
    } = usePopup()

    /**
     * 是按鈕點擊事件
     * return {void}
     */
    const handleOnAgree = () => {
        // 按鈕觸發來源為編輯時，開啟變更彈窗
        listItemBtnType === 'EDIT' && setShowChangeModal(true)
        // 按鈕觸發來源為刪除時，開啟註銷彈窗
        listItemBtnType === 'DELETE' && setShowLogOffModal(true)
        // 關閉本彈窗
        handleOnClose()
    }

    /**
     * 否按鈕點擊事件
     * return {void}
     */
    const handleOnDisagree = () => {
        // 按鈕觸發來源為編輯時，開啟收案確認彈窗
        listItemBtnType === 'EDIT' && setShowCaseConfirmation(true)
        // 按鈕觸發來源為刪除時，開啟刪除彈窗
        listItemBtnType === 'DELETE' && setShowDeletePopup(true)
        // 關閉本彈窗
        handleOnClose()
    }

    return (
        <BasePopup
            title="提醒"
            closePopupButtonOnClick={handleOnClose}
            width="559px"
            content={
                <div>
                    <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否已送交衛福部？</p>
                    <div className="h-[60px] flex flex-row items-center justify-end">
                        <button onClick={handleOnAgree}
                                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px] hover:bg-primary hover:text-white"
                        >是
                        </button>
                        <button onClick={handleOnDisagree}
                                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px] hover:bg-primary hover:text-white"
                        >否
                        </button>
                    </div>
                </div>
            }
        />

    )
}

export default SendMOHWConfirm
