
import React, {useEffect, useState} from 'react';
import addIcon from "../../../../assets/images/icons/add_circle.svg";
import deleteIcon from "../../../../assets/images/icons/del_circle.svg";
import Button from "../../../Button/Button";
import LocalStorageFormSetting from "../../Share/LocalStorageFormSetting";
import {BaseInput} from "../../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import RadioButton from "../../../Input/RadioButton";
import {Header, OptionFive, OptionFour, OptionOne, OptionSix, OptionThree, OptionTwo} from "./formOption";
const InputStyle = "w-[140px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SecondButtonStyle = "bg-gray-50 flex items-center justify-center px-4 h-10 rounded-[6px] border-2"

const Layout = ({children})=> {
    return (
        <div className="p-4 w-full overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md h-full">
            {children}
        </div>
    )
}
/**
 * 分隔線
 * @return {JSX.Element}
 */
const Divider = ()=> {
    return  <div className="border-t-[1px] border-[rgba(0,0,0,0.15)] my-8"></div>
}
/**
 * 標題
 * @return {JSX.Element}
 */
const Title = ({children, extendClass})=> {
    return (
        <div className={`date flex flex-row items-center justify-start ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}

/**
 * BSRS-5評估單
 * @return {JSX.Element}
 */
const BSRS5 = () => {
    /**
     * 初始值
     * @type {{legLong: number, optionFour: number, kneeLong: number, hipLong: number, optionTwo: number, optionThree: number, optionOne: number, armLong: number, optionSix: number, evaluationDate: string, calorie: number, evaluator: string, optionFive: number}}
     */
    const initData = {
        evaluationDate: "", // 評估日期
        evaluator: "", // 評估人員
        optionOne: 0,
        optionTwo: 0,
        optionThree: 0,
        optionFour: 0,
        optionFive: 0,
        optionSix: 0,
        count: 0,
    }

    // 預設表單資料
    const [formData, setFormData] = useState(null);
    /**
     * 更改表單資料
     * @param e
     * @param item
     */
    const handleInputChange = (e, item) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [item]: value
        });
    };
    /**
     * 更改單選表單資料
     * @param value
     * @param item
     */
    const handleRadioChange = (value, item) => {
        setFormData({
            ...formData,
            [item]: value
        });
    };

    /**
     * 新增表單內容
     * @param data
     */
    const handleInitData = (data) =>{
        if(!data){
            setFormData(null)
        }else{
            setFormData({...initData, ...data})
        }
    };
    /**
     * 計算總分
     */
    const getTotalCount = () => {
      return formData.optionOne + formData.optionTwo + formData.optionThree + formData.optionFour + formData.optionFive
    };


    return <Layout>
        {/* 標題 */}
        <div className="flex items-center">
            <div className="w-[8px] h-[20px] bg-[#2B6CB0]"/>
            <div className="ml-2 text-[#2B6CB0] text-[20px]">迷你營養評估</div>
        </div>
        {/* 本地儲存 */}
        <LocalStorageFormSetting name="bsrs5" data={formData} onClickForm={(data)=>handleInitData(data)}/>
        { formData &&  <>
                {/* 評估人員 */}
                <div className="flex justify-between p-2 items-center">
                    <div className="flex items-center">
                        <Title>評估日期：</Title>
                        <BaseInput
                            className={InputStyle}
                            type={InputTypeEnum.Date}
                            value={formData.evaluationDate}
                            onChange={(e)=>{handleInputChange(e,"evaluationDate")}}
                        />
                        <Title extendClass={'ml-4'}>
                            評估人員：
                        </Title>
                        <BaseInput
                            className={InputStyle}
                            type={InputTypeEnum.Text}
                            value={formData.evaluator}
                            onChange={(e)=>{handleInputChange(e,"evaluator")}}
                        />
                    </div>
                </div>
                <div className="relative overflow-x-auto overflow-y-auto h-[55vh]">
                    <table className="whitespace-nowrap bg-white w-full text-sm text-left min-w-full border">
                        <tbody>
                        {/* 基本資料 */}
                        <tr className="w-full  border-b border-gray-200">
                            <td colSpan={8} className="w-full px-6 py-4 ">
                                <div>請圈選最近一個星期(含今天)，對下列各項目造成困擾的嚴重程度(自我感受)</div>
                            </td>
                        </tr>
                        {/* 標題 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">

                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="">
                                    </div>
                                    <div className="flex items-center ml-5 py-2">
                                        {
                                            Header.map((option, index) => (
                                                <div className="w-[100px]">{option.label}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 睡眠困難，譬如難以入睡、易醒或早睡 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">

                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        1. 睡眠困難，譬如難以入睡、易醒或早睡
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionOne}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionOne")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 感覺緊張或不安 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        2. 感覺緊張或不安
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionTwo}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionTwo")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 覺得容易苦惱或動怒 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        3. 覺得容易苦惱或動怒
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionThree}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionThree")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 感覺憂鬱、心情低落 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        4. 感覺憂鬱、心情低落
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionFour}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionFour")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 覺得比不上別人 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        5. 覺得比不上別人
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionFive}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionFive")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* ★有自殺想法 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr]">
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        ★ 有自殺想法
                                    </div>
                                    <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                        <div className=" items-center flex ml-4">
                                            {
                                                OptionOne.map((option, index) => (
                                                    <RadioButton wrapperClassName={"w-[100px] my-2"}
                                                                 mode={formData.optionSix}
                                                                 modeChecked={option.value}
                                                                 handleModeChange={(value) => {
                                                                     handleRadioChange(value, "optionSix")
                                                                 }} label={option.label}/>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 總分 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full">
                                <div className="w-full grid grid-cols-[400px,1fr] py-2">
                                    <div className="flex ml-4">
                                        <Title extendClass="mr-4">檢測結果：1-5題總分</Title>
                                        <BaseInput
                                            className={InputStyle}
                                            type={InputTypeEnum.Text}
                                            value={getTotalCount()}
                                        />
                                    </div>
                                    <div className="flex">
                                        <Title extendClass={'mr-4'}>
                                            ★自殺想法
                                        </Title>
                                        <BaseInput
                                            className={InputStyle}
                                            type={InputTypeEnum.Text}
                                            value={formData.optionSix}
                                        />
                                    </div>
                                </div>
                            </td>

                        </tr>
                        {/* 說明 */}
                        <tr className="w-full bg-white border-b border-gray-200">
                            <td colSpan={8} className="w-full p-4">
                                <div className="mt-2">說明：</div>
                                <div className="mt-4">1. 1至5題之總分：</div>
                                <div className="mt-4">(1) 得分0~5分：身心適應狀況良好。</div>
                                <div className="mt-2">(2) 得分6~9分：輕度情緒困擾，建議找家人或朋友談談，抒發情緒，給予情緒支持。</div>
                                <div className="mt-2">(3) 得分10~14分：中度情緒困擾，建議尋求心理諮商或接受專業諮詢。</div>
                                <div className="mt-2">(4) 得分>15分：重度情緒困擾，需高關懷，建議轉介青神科治療或接受專業輔導。</div>
                                <div className="mt-4">2. ★有自殺想法單項評分：</div>
                                <div className="mt-4">本題為附加題，若前5題總分小於6分，但本題評分為2分以上時，建議轉介至精神科。</div>

                            </td>

                        </tr>

                        </tbody>
                    </table>
                </div>
        </>
        }
    </Layout>
}
export default BSRS5