import React, {useEffect, useState} from "react"
import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import { DefaultButton } from "../../Button/DefaultButton";
import searchIcon from "../../../assets/images/search-interface-symbol.png"
import {time2String, ApiErrorStatusEnum, arrayIsEmpty, ToastTypeEnum, stringIsEmpty, TimeslotEnum, objectIsEmpty} from "edah_utils/dist"
import { regsQueryFirstviewByField } from "../../../api/v1/RegsFirstView"
import { regsAddFirstviewToReg } from "../../../api/v1/Regs";
import { ShowGiveNumberPopup } from "../AppointmentSchedule/ShowGiveNumberPopup";
import { Pagination } from "../../Pagination/Pagination";
import { isCurrentDateTimeWithinRange } from "../utils";
import {t} from "i18next"
import useToast from "../../../hooks/useToast";
/**
 * 初診病人預約名單彈窗
 * @param {Boolean} show 是否顯示彈窗
 * @param {Function} closePopupButtonOnClick 關閉事件
 * @param {Array} fvSearchOptionList 查詢欄位list
 * @param {Array} apnList 時段list
 * @param {Object} importAppointmentData 傳入的預約資料
 * @param {Function} triggerResetAppointment 重置預約資料
 * @returns 
 */
const FirstVisitPatientAppointmentListPopup = ({
    closePopupButtonOnClick, 
    show = false, 
    fvSearchOptionList,
    apnList,
    patientId,
    importAppointmentData,
    triggerResetAppointment
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)
    //
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: '',
        //進階搜尋文字
        advancedText: '',
        //預約日期
        encounterDate: '',
        //時段
        apn: null,
        //查詢欄位
        field: ''
    })
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: '',
        advancedText: '',
        encounterDate: '',
        apn: null,
        field: ''
    })

    /**
     * 預約名單資料
     */
    const [arrayData, setArrayData] = useState(null)
    // 病人資訊清單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([])
    /**
     * 查詢的欄位
     */
    const [searchField, setSearchField] = useState("")
    //是否顯示給診號彈窗
    const [showGiveNumberPopup, setShowGiveNumberPopup] = useState(false)
    //給給診號彈窗資料
    const [giveNumberData, setGiveNumberData] = useState({})
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //Toast Message Hooks
    const showToast = useToast()
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    /**
     * 點擊查詢按鈕事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
    /*    const queryInputObj = {
            encounterDate: time2String(filterEncounterDate, "YYYY-MM-DD 00:00:00"),
            apn: filterApn,
            search:searchText,
            field:searchField
        }*/
        
        searchFirstVisitAppointment(queryInputTmpData, 1, paginationProps.pageSize)
    }

    /**
     * 搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            text: e.target.value
        })
    }

    /**
     * 搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {
        }
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            advancedText: e.target.value
        })
    }

    /**
     * 進階搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {

        }
    }

    /**
     * 處理查詢預約日期變更
     * @param {*} e 
     * @return {void}
     */
    const handleFilterEncounterDateOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            encounterDate: e.target.value
        })
    }

    /**
     * 時段變更
     * @param v {Number} 時段
     * @return {void}
     */
    const handleFilterApnOnClick = (v) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            apn: parseInt(v)
        })
    }

    /**
     * 點擊確認報到按鈕事件
     * @param data {Object} 預約病歷
     * @return {void}
     */
    const handleChickInClick = async (data) => {

        await regsAddFirstviewToReg({
            patientId: patientId,
            firstregId: data.firstregId
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setGiveNumberData(res.data)
                //新增成功
                setTimeout(() => setShowGiveNumberPopup(true), 150)
            } else {
                showToast({message:res.msg, type:ToastTypeEnum.Warning})
            }
        })        
    }

    /**
     * 關閉掛號完成的彈窗
     */
    const handleCloseGiveNumberPopup = () => {
        setGiveNumberData({})
        setShowGiveNumberPopup(false)

        triggerResetAppointment(true)

        closePopupButtonOnClick()
    }

    /**
     * 查詢初診預約
     * @param {Object} queryInputObj 查詢條件
     * @param page 查詢第幾頁
     * @param pageSize 每頁資料筆數
     */
    const searchFirstVisitAppointment = (queryInputObj, page, pageSize) => {

        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        regsQueryFirstviewByField({
            encounterDate: time2String(queryInputObj.encounterDate, "YYYY-MM-DD 00:00:00"),
            apn:  queryInputObj.apn.toString(),
            search: queryInputObj.text,
            field: queryInputObj.field,
            pageSize: pageSize,
            pageNum: page
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setArrayData(res.data.dataList)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                setArrayData([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        })
    }

    /**
     * 欄位下拉選單改變時
     * @param e {Event} 事件
     */
    const handleFieldOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            field: e.target.value
        })
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        searchFirstVisitAppointment(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        searchFirstVisitAppointment(queryInputData, 1, e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        searchFirstVisitAppointment(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        searchFirstVisitAppointment(queryInputData, page, paginationProps.pageSize)
    }
    
    /**
     * 取得彈窗元件
     * @return {JSX.Element} 彈窗元件
     */
    const getContent = () => (
        <div className="px-4 sm:px-8 pb-5 space-y-3">
            <div className="flex items-center text-[16px] space-x-3">
                <div className="flex items-center">
                    <p>預約日期：</p>
                    
                    <BaseInput
                        className="w-[150px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Date}
                        inputMode="date"
                        min={time2String(new Date(), "YYYY-MM-DD")}
                        value={queryInputTmpData.encounterDate}
                        onChange={(e) => handleFilterEncounterDateOnChange(e)}/>
                </div>
                <div className="flex flex-row">
                    <label>時段：</label>
                    {
                        apnList.map((apn, index) => (
                            <div 
                                key={index}
                                className={`flex flex-row space-x-1 mr-[2px] ${queryInputTmpData.apn === apn.justnameNo ? "text-[#2b6cb0]":"text-[#11181c]" } cursor-pointer`}
                                onClick={() => handleFilterApnOnClick(apn.justnameNo)}>
                                <input 
                                    className="text-black"
                                    type="radio"
                                    checked={queryInputTmpData.apn.toString() === apn.justnameNo}
                                    onChange={() => {}}/>
                                <div className={`${queryInputTmpData.apn === apn.justnameNo }`}>{apn.justnameName}</div>
                            </div>
                        ))
                    }
                </div>
                <select 
                    className="pl-2 w-[120px] h-10 border border-[#D4D4D8] rounded-[6px]"
                    onChange={handleFieldOnChange}
                    value={queryInputTmpData.field}>
                    {
                        fvSearchOptionList && fvSearchOptionList.map((item, index) => (
                            <option 
                                key={item.justnameNo}
                                value={item.justnameNo}
                            >
                                {item.justnameName}
                            </option>
                        ))
                    }
                </select>
                <div>
                    <BaseInput
                        className="w-[169px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.text}
                        value={queryInputTmpData.text} 
                        onChange={(e) => onSearchTextOnInputChange(e)}
                        onKeyDown={(e) => onSearchTextOnInputKeyDown(e)}/>
                </div>
                <DefaultButton 
                    text="查詢" 
                    type="primary"
                    onClickFn={handleSearchOnClick}/>
            </div>
            <div className="flex text-[20px]">
                病人IC卡基本資料：{/*張英秀，E0987654321，1988/10/01*/}
            </div>
            <div className="flex space-x-2">
                <button 
                    className="border-[2px] h-10 border-[#D4D4D8] px-[16px] py-[4px] rounded-[4px]"
                >
                    虛擬IC
                </button>
                <button 
                    className="border-[2px] h-10 border-[#D4D4D8] px-[16px] py-[4px] rounded-[4px]"
                >
                    實體IC
                </button>
            </div>
            {/**中間 Data Grid */}
            <div className="px-2 py-2 mt-4 border border-[#d4d4d8] rounded-[6px] space-y-3">
                <div className="flex flex-row items-center">
                    {/*進階搜尋*/}
                    <div className="w-[350px] flex items-center relative">
                        <input
                            className={'bg-white min-h-10 w-full px-2 text-black rounded-[6px] border py-1 border-gray-300 pl-8'}
                            placeholder="進階搜尋"
                            value={queryInputTmpData.advancedText}
                            onChange={(e) => onAdvancedSearchTextOnInputChange(e)}
                            onKeyDown={(e) => onAdvancedSearchTextOnInputKeyDown(e)}/>
                        <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-2.5"
                                src={searchIcon} alt="search-icon" role="button"/>
                    </div>
                </div>
                {/**Data Grid */}
                <div className='border-collapse w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]'>
                    <table className="table-fixed w-full min-w-[1500px] text-left">
                        <thead>
                            <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                                <th className={`w-[127px] ${tableElementDefaultType}`}></th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>看診日期</th>
                                <th className={`w-[74px] ${tableElementDefaultType}`}>{t("Regs.general.timeSlot")}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t("Regs.general.clinic")}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('Regs.general.viewNumber')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('general.doctor')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('Regs.general.medicalRecordNumber')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('general.idNumber')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('general.username')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>{t('general.birthday')}</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>手機</th>
                                <th className={`w-[120px] ${tableElementDefaultType}`}>掛號人員</th>
                                <th className={`w-[190px] ${tableElementDefaultType}`}>掛號日期時間</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !arrayIsEmpty(advSearchArrayData) && advSearchArrayData.map((data, index) => (
                                    <tr 
                                        className={`h-[40px] ${index % 2 === 0 ? '':'bg-[#f4f4f5]'} text-[#18181b]`}
                                        key={index}>
                                        <td className={`${tableElementDefaultType}`}>
                                            <DefaultButton 
                                                type="outline-primary" 
                                                text="完成報到"
                                                disable={!isCurrentDateTimeWithinRange(data.encounterDate, parseInt(data.apn))}
                                                onClickFn={() => handleChickInClick(data)}/>
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>{data.encounterDate?time2String(data.encounterDate, "YYYY-MM-DD"):""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.apn?data.apn:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.clinicName?data.clinicName:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.viewNo?data.viewNo:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.doctorName?data.doctorName:""}</td>
                                        <td className={`${tableElementDefaultType}`}></td>
                                        <td className={`${tableElementDefaultType}`}>{data.idNo?data.idNo:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.patientName?data.patientName:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.birthDate?time2String(data.birthDate, "YYYY-MM-DD"):""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.phonenumber?data.phonenumber:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.createUserName?data.createUserName:""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.createDatetime?data.createDatetime:""}</td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={paginationProps.totalPageSize} 
                                currentPage={paginationProps.currentPage} 
                                pageSize={paginationProps.pageSize}
                                totalSize={paginationProps.totalItemSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPrevPageOnClick={onPaginationPreviousOnClick}
                                onNextPageOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
            </div>
            {
                <ShowGiveNumberPopup
                    showPopup={showGiveNumberPopup}
                    data={giveNumberData}
                    handleClosePopup={handleCloseGiveNumberPopup}
                />
            }
        </div>

    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        if(show) {
            if(!objectIsEmpty(importAppointmentData) && !objectIsEmpty(importAppointmentData.dataList)) {
                //有預約資料傳入時
                //將預約資料寫入
                setArrayData(importAppointmentData.dataList)
                //設定分頁狀態
                setPaginationProps({
                    //當前頁碼
                    currentPage: importAppointmentData.currentPage,
                    //每頁資料筆數
                    pageSize: importAppointmentData.pageSize,
                    //總資料筆數
                    totalItemSize: importAppointmentData.totalItemSize,
                    //總頁碼
                    totalPageSize: importAppointmentData.totalPageSize
                })
                //欄位狀態代入到顯示欄位上
                setQueryInputTmpData({
                    text: importAppointmentData.searchText,
                    advancedText: '',
                    encounterDate: time2String(importAppointmentData.encounterDate, "YYYY-MM-DD"),
                    apn:importAppointmentData.apn,
                    field: importAppointmentData.searchField
                })
                //欄位狀態代入到真正的搜尋欄位上
                setQueryInputData({
                    text: importAppointmentData.searchText,
                    advancedText: '',
                    encounterDate: time2String(importAppointmentData.encounterDate, "YYYY-MM-DD"),
                    apn:importAppointmentData.apn,
                    field: importAppointmentData.searchField
                })
            }
            else {
                const queryCondition = {
                    text: '',
                    advancedText: '',
                    encounterDate: time2String(importAppointmentData.encounterDate, "YYYY-MM-DD"),
                    apn: importAppointmentData.apn,
                    field: 'ALL'
                }
                setQueryInputTmpData(queryCondition)
                searchFirstVisitAppointment(queryCondition, 1, paginationProps.pageSize)
            }
        }
        else {
            //重置輸入框
            const queryCondition = {
                text: '',
                advancedText: '',
                encounterDate: '',
                apn: TimeslotEnum.Morning,//'1',
                field: 'ALL'
            }
            setQueryInputTmpData(queryCondition)
            setQueryInputData(queryCondition)
        }
        setShowPopup(show)
    }, [show])

    /**監聽進階搜尋的文字或者arrayData的變化 */
    useEffect(() => {
        if(arrayData === null) {
            setAdvSearchArrayData(null)
        }
        else {
            const list = getAdvSearchPatientList()
            setAdvSearchArrayData(list)
        }
    },[queryInputTmpData.advancedText, arrayData])

    /**
     * 取得病人進階搜尋後的結果
     * @returns 
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(queryInputTmpData.advancedText)) {
            return arrayData
        }
        else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = queryInputTmpData.advancedText.toLowerCase()
            return arrayData.filter(data => {
                    return (data.encounterDate !== null && time2String(data.encounterDate, "YYYYMMDD").includes(lowerCaseSearchText)) ||
                            (data.apn !== null && data.apn.toString().toLowerCase().includes(lowerCaseSearchText)) || 
                            (data.clinicName !== null && data.clinicName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.viewNo !== null && data.viewNo.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.doctorName !== null && data.doctorName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.idNo !== null && data.idNo.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.patientName !== null && data.patientName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.phonenumber !== null && data.phonenumber.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.birthDate !== null && time2String(data.birthDate, "YYYYMMDD").includes(lowerCaseSearchText)) ||
                            (data.createUserName !== null && data.createUserName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.createDatetime !== null && time2String(data.createDatetime, "YYYYMMDD").includes(lowerCaseSearchText))
                }
            )
        }
    }
    
    return showPopup ?
        <BasePopup 
            title={"初診病人預約名單"} 
            content={getContent()} 
            width={"90%"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default FirstVisitPatientAppointmentListPopup;