import React, { useEffect, useState } from 'react'
import { time2String, objectIsEmpty, ApiErrorStatusEnum } from 'edah_utils/dist'
import DivisionDatalist from '../DivisionDatalist'
import DoctorDatalist from '../DoctorDatalist'
import Button from '../../Button/Button'
import ReferralHospitalModal from '../ReferralHospitalModal'
import { DATE_FORMAT } from '../../../constants/common'
import { t } from 'i18next'
import { queryTranTypeByEncounterId } from '../../../api/v1/Rmis'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import {
    completePrintMode,
    updatePrintContent,
} from '../../../redux/Slice/printSlice'
import store from '../../../redux/store'
import { useSelector } from 'react-redux'
import {getBirthDayAndDetailedAge} from "../utils";

/**
 * 轉診原因: 6.其他
 */
const TRAN_REASON_OTHERS = '6'

/**
 * 轉診單個管作業_維護視窗
 * @param {object} props
 * @param {function} props.onClose 關閉視窗
 * @param {function} props.onConfirm 存檔
 * @param {object} props.editCase 開啟視窗時傳入個案的資料
 * @param {array} props.doctorList 醫師清單
 * @param {array} props.divisionList 科別清單
 * @param {array} props.outStatusList 轉診追蹤狀態
 * @param {array} props.zipCodeList 高雄地區下拉選單
 * @return {JSX.Element}
 */
const TranOutCaseModal = ({
    onClose,
    onConfirm,
    editCase,
    doctorList = [],
    divisionList = [],
    outStatusList = [],
    tranReasonList = [],
    zipCodeList = [],
}) => {
    let initialValue = {
        encounterDate: '',
        // TODO:因為要門診資料但phase1沒有門診，待api確定
        payFeeNo: '', // FIXME:批價代碼
        patientId: '', // 病歷號碼
        patientName: '', // 病人姓名
        genderName: '', // 性別
        birthDate: '', // 生日
        idNo: '', // 身分證號
        divNo: '', // 就醫科別代碼
        divName: '', // 就醫科別
        doctorNo: '', // 醫師代碼
        doctorName: '', // 醫師
        treatmentCode: '', // 診斷代碼
        treatmentName: '', // 診斷名
        treatmentCode1: '', // 診斷代碼1
        treatmentName1: '', // 診斷名1
        treatmentCode2: '', // 診斷代碼2
        treatmentName2: '', // 診斷名2
        tranOutHospNo: '', // 轉出院所代碼
        tranOutHospName: '', // 轉出院所
        tranOutHospGradeName: '', // 轉出院所層名稱
        tranDate: '', // 轉診日期
        tranDoctorNo: '', // 轉診醫師代碼
        tranDoctorName: '', // 轉診醫師
        tranReason: '', // 轉診原因
        tranReasonRemark: '', // 轉診原因備註
        tranOutDivNo: '', // 轉診科別代碼
        tranOutDivName: '', // 轉診科別
        outRealOutHospNo: '', // 實際轉出院所代碼
        outRealOutHospName: '', // 實際轉出院所
        outRealOutDivNo: '', // 實際轉出科別代碼
        outRealOutDivName: '', // 實際轉出科別
        outReplyDate2: '', // 接受回覆日期(病歷摘要)
        outReplyDate: '', // 接受對方回覆日期(初步)
        outReturnDate: '', // 轉診單回收日期
        dischargeDate: '', // 出院日期
        admissionDate: '', // 住院日期
        outCancerFlag: '', // 癌症篩檢
        outStatus: '', // 轉診追蹤狀態
        outRemark: '', // 備註
    }

    // 補列印轉診單 - 列印數量
    const [printSetting, setPrintSetting] = useState({
        copies: 1,
        couplet: 2,
    })

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    // 轉診單資料
    const [caseDetail, setCaseDetail] = useState(initialValue)

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    /**
     * 更新轉診單資料
     * @param {event} e
     * @param {string} field 欄位名稱
     * @return {void}
     */
    const updateCaseDetail = (e, field) => {
        switch (field) {
            case 'outCancerFlag': {
                const value = e.target.checked
                setCaseDetail({
                    ...caseDetail,
                    [field]: value,
                })
                break
            }

            default:
                const value = e.target.value
                setCaseDetail({
                    ...caseDetail,
                    [field]: value,
                })
        }
    }

    // TODO: 列印流程尚未確定
    /**
     * 列印
     * @param {event} e
     * @param {*} field
     * @return {void}
     */
    const updatePrintSetting = (e, field) => {
        const value = e.target.value
        setPrintSetting({ ...printSetting, [field]: value })
    }

    /**
     * 存檔
     * @return {void}
     */
    const handleOnSave = () => {
        onConfirm(caseDetail)
    }

    /**
     * 帶入院所及科別
     * 將tranOutHosp tranOutDiv帶入 outRealOutHosp, outRealOutDiv
     * @return {void}
     */
    const populateWithtranOutHospDiv = () => {
        setCaseDetail((prev) => {
            const newValue = {
                ...prev,
                outRealOutHospNo: prev.tranOutHospNo,
                outRealOutHospName: prev.tranOutHospName,
                outRealOutDivNo: prev.tranOutDivNo,
                outRealOutDivName: prev.tranOutDivName,
            }
            return newValue
        })
    }

    /**
     * 模糊搜尋選單-就醫科別
     * @param {object} division
     * @return {void}
     */
    const selectDivision = (division) => {
        setCaseDetail((prev) => ({
            ...prev,
            divNo: division ? division.divNo : '',
            divName: division ? division.divName : '',
        }))
    }

    /**
     * 模糊搜尋選單-選擇的醫師
     * @param {object} doctor
     * @return {void}
     */
    const selectDoctor = (doctor) => {
        setCaseDetail((prev) => ({
            ...prev,
            doctorNo: doctor ? doctor.doctorNo : '',
            doctorName: doctor ? doctor.doctorName : '',
        }))
    }

    /**
     * 模糊搜尋選單-選擇的轉診科別
     * @param {object} doctor
     * @return {void}
     */
    const selectTranOutDivision = (division) => {
        setCaseDetail((prev) => ({
            ...prev,
            tranOutDivNo: division ? division.divNo : '',
            tranOutDivName: division ? division.divName : '',
        }))
    }

    /**
     * 模糊搜尋選單-選擇的醫師
     * @param {object} doctor
     * @return {void}
     */
    const selectTranOutDoctor = (doctor) => {
        setCaseDetail((prev) => ({
            ...prev,
            tranDoctorNo: doctor ? doctor.doctorNo : '',
            tranDoctorName: doctor ? doctor.doctorName : '',
        }))
    }

    /**
     * 開啟院所查詢視窗
     * @return {void}
     */
    const showReferralHospital = () => {
        setIsShowReferralHospitalModal(true)
    }

    /**
     * 選擇院所
     * @return {void}
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }

        setCaseDetail({
            ...caseDetail,
            outRealOutHospName: hospital.hospName,
            outRealOutHospNo: hospital.hospNo,
        })
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 選擇實際轉出院所科別
     * @param {object} division 科別
     * @return {void}
     */
    const selectOutRealOutDiv = (division) => {
        setCaseDetail((prev) => ({
            ...prev,
            outRealOutDivNo: division ? division.divNo : '',
            outRealOutDivName: division ? division.divName : '',
        }))
    }

    /**
     * 轉診單列印
     * @param value
     */
    const handleTranOutForm = (value) => {
        queryTranTypeByEncounterId({
            encounterId: editCase.encounterId,
            encounterDate: editCase.encounterDate,
            inpOpd: editCase.inpOpd,
            patientId: editCase.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const printData = res.data
                store.dispatch(
                    updatePrintContent({
                        reportType: FORM_TYPE.tranOutForm,
                        printData,
                    })
                )
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * mounting初始化資料，取得醫師、科別清單
     * @return {void}
     */
    useEffect(() => {
        const newValue = { ...initialValue, ...editCase }
        setCaseDetail(newValue)
    }, [editCase])

    return (
        <div className="fixed top-0 left-0 pt-[20px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] overflow-auto">
            <div className={`w-[90%] bg-white rounded-[6px] my-9`}>
                <div className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 m- border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    <h3 className="text-[20px]">轉出單個管作業_維護</h3>
                    <button
                        className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                        onClick={onClose}
                    >
                        {t('general.close')}
                    </button>
                </div>
                <div className="flex justify-end items-center">
                    <div className="bg-[#F4F4F5] flex items-center p-1 mr-2 rounded-[6px]">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handleTranOutForm}
                        >
                            補列印轉診單
                        </button>
                        <div>
                            <input
                                value={printSetting.copies}
                                type="number"
                                className="w-[50px] mx-2 px-2 py-1 rounded-[6px]"
                                onChange={(e) =>
                                    updatePrintSetting(e, 'copies')
                                }
                            />
                            份
                            <input
                                value={printSetting.couplet}
                                type="number"
                                className="w-[50px] mx-2 px-2 py-1 rounded-[6px]"
                                onChange={(e) =>
                                    updatePrintSetting(e, 'couplet')
                                }
                            />
                            聯
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="w-full grid grid-cols-4">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                就醫日期
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.encounterDate
                                    ? time2String(
                                          caseDetail.encounterDate,
                                          DATE_FORMAT
                                      )
                                    : ''}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                批價序號
                            </div>
                            <div className="mr-4 text-right">
                                {/* TODO: 需等門診開發 */}
                                {caseDetail.payFeeNo}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.patientNo')}
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.patientId}
                            </div>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.username')}
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.patientName}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.gender.name')}
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.genderName}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.birthday')}
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.birthDate?getBirthDayAndDetailedAge(caseDetail.birthDate):''}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.idNumber')}
                            </div>
                            <div className="mr-4 text-right">
                                {caseDetail.idNo}
                            </div>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                就醫科別
                            </div>
                            <div className="text-left">
                                {`${caseDetail.divNo}${caseDetail.divName}`}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                {t('general.doctor')}
                            </div>
                            <div className="text-left">
                                {`${caseDetail.doctorNo}${caseDetail.doctorName}`}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="w-[160px] pr-4 text-right col-span-1 row-span-3">
                            診斷碼
                        </div>
                        <div className="w-[calc(100%-160px)]">
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName}
                                    disabled
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName')
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode1}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode1')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName1}
                                    disabled
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName1')
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentCode2}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentCode2')
                                    }
                                    disabled
                                />
                                <input
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                    type="text"
                                    value={caseDetail.treatmentName2}
                                    disabled
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'treatmentName2')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-center">
                        <div className="w-[160px] pr-4 text-right col-span-1 row-span-3  mb-4 ">
                            轉出醫院
                        </div>
                        <div className="grid grid-cols-5 gap-2 flex-1">
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-1 mb-4"
                                type="text"
                                value={caseDetail.tranOutHospNo}
                                disabled
                            />
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                                type="text"
                                value={caseDetail.tranOutHospName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="w-[160px] pr-4 text-right">
                            轉出醫院所層
                        </div>
                        <input
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] col-span-4 mb-4"
                            type="text"
                            value={caseDetail.tranOutHospGradeName}
                            disabled
                        />
                    </div>
                    <div className="w-full flex">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                轉診日期
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.tranDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'tranDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                轉診科別
                            </div>
                            <div>
                                <DivisionDatalist
                                    onSelect={selectTranOutDivision}
                                    value={{
                                        divNo: caseDetail.tranOutDivNo,
                                        divName: caseDetail.tranOutDivName,
                                    }}
                                    divisionList={divisionList}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                轉診醫師
                            </div>
                            <div>
                                <DoctorDatalist
                                    onSelect={selectTranOutDoctor}
                                    value={{
                                        doctorName: caseDetail.tranDoctorName,
                                        doctorNo: caseDetail.tranDoctorNo,
                                    }}
                                    doctorList={doctorList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] pr-4 text-right">
                            轉診原因
                        </div>
                        <select
                            className="w-[200px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                            name="tranReason"
                            id="tranReason"
                            value={caseDetail.tranReason}
                            onChange={(e) => updateCaseDetail(e, 'tranReason')}
                        >
                            <option value=""></option>
                            {tranReasonList.map((item) => (
                                <option
                                    key={item.tranReason}
                                    value={item.tranReason}
                                >
                                    {item.tranReasonName}
                                </option>
                            ))}
                        </select>
                        {caseDetail.tranReason === TRAN_REASON_OTHERS ? (
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] ml-2 w-[calc(100%-360px)]"
                                type="text"
                                value={caseDetail.tranReasonRemark}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'tranReasonRemark')
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="w-full border-b-2 m-4"></div>
                    <div className="w-full grid grid-cols-2">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                實際轉出院所
                            </div>
                            <div className="mb-4">
                                <Button
                                    classNames="bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                    text={'院所查詢'}
                                    onClickFn={showReferralHospital}
                                />
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mb-4 w-[160px] mr-2"
                                type="text"
                                value={caseDetail.outRealOutHospNo}
                                disabled
                            />
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mb-4 flex-1"
                                type="text"
                                value={caseDetail.outRealOutHospName}
                                disabled
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[200px] mr-4 text-right">
                                實際轉出院所科別
                            </div>
                            <div className="flex items-center">
                                <DivisionDatalist
                                    onSelect={selectOutRealOutDiv}
                                    value={{
                                        divNo: caseDetail.outRealOutDivNo,
                                        divName: caseDetail.outRealOutDivName,
                                    }}
                                    divisionList={divisionList}
                                />
                            </div>
                            <button
                                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 ml-2"
                                onClick={populateWithtranOutHospDiv}
                            >
                                帶入院所及科別
                            </button>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                轉診單回收日期
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.outReturnDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outReturnDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[200px] mr-4 text-right">
                                接受對方回覆日期(初步)
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.outReplyDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outReplyDate')
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[200px] mr-4 text-right">
                                接受回覆日期(病歷摘要)
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.outReplyDate2}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outReplyDate2')
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-4 mb-3">
                        <div className="flex items-center">
                            <div className="w-[160px] pr-4 text-right">
                                住院日期
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.admissionDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'admissionDate')
                                }
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="w-[160px] pr-4 text-right">
                                出院日期
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="date"
                                value={caseDetail.dischargeDate}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'dischargeDate')
                                }
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-4">
                        <div className="w-[160px] pr-4 text-right">
                            轉診追蹤狀態
                        </div>
                        <select
                            className="w-[235px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                            onChange={(e) => updateCaseDetail(e, 'outStatus')}
                            value={caseDetail.outStatus}
                        >
                            <option value=""></option>
                            {outStatusList.map((item) => (
                                <option
                                    key={item.outStatusName}
                                    value={item.outStatus}
                                >
                                    {item.outStatusName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full grid grid-cols-4">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                癌症篩檢
                            </div>
                            <input
                                type="checkbox"
                                style={{ accentColor: '#38A169' }}
                                id="outCancerFlag"
                                name="outCancerFlag"
                                className="bg-green-400 checked:bg-green-600 text-green-6002"
                                checked={caseDetail.outCancerFlag}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outCancerFlag')
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="w-[160px] pr-4 text-right">
                                備註
                            </div>
                            <textarea
                                className="w-full mt-2 shadow rounded border-2 p-2 h-[120px]"
                                value={caseDetail.outRemark}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outRemark')
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full border-b-2 mt-3"></div>
                <div className="flex justify-end p-3">
                    <button
                        onClick={handleOnSave}
                        className="flex items-center justify-center font-bold px-4 h-10 mt-4 rounded-[6px] bg-[#2B6CB0] text-white"
                    >
                        {t('general.saveFile')}
                    </button>
                </div>
            </div>
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    zipCodeList={zipCodeList}
                    onConfirm={onSelectHospital}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </div>
    )
}

export default TranOutCaseModal
