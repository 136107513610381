import React, {useEffect, useState, useMemo} from "react"
import {SearchInput} from "../../components/Input/SearchInput"
import {DefaultButton} from "../../components/Button/DefaultButton"
import {
    InputTypeEnum,
    time2String,
    arrayIsEmpty,
    ToastTypeEnum,
    ApiErrorStatusEnum,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"
import {Pagination} from "../../components/Pagination/Pagination"
import useToast from "../../hooks/useToast"
//Import Icon
import {ReactComponent as AddIcon} from '../../assets/images/icons/add_circle.svg';
import {ReactComponent as AddDisableIcon} from '../../assets/images/icons/add_circle_disable.svg';
import {ReactComponent as DeleteBtn} from "../../assets/images/icons/del_square.svg"
import {ReactComponent as DeleteDisableBtn} from "../../assets/images/icons/del_square_disable.svg"
import {ReactComponent as CopyBtn} from '../../assets/images/SystemMonitoring/copy.svg';
import {ReactComponent as SaveBtn} from '../../assets/images/icons/save_default.svg';
import {ReactComponent as CancelBtn} from '../../assets/images/icons/cancel_default.svg';
import {ReactComponent as EditBtn} from "../../assets/images/ICCard/edit_square.svg";
import {ReactComponent as EditDisableBtn} from "../../assets/images/ICCard/edit_square_disable.svg";
import {crudQueryTableData, crudSaveTableData, crudDelTableData} from "../../api/v1/CRUD"
import {DeletePopup} from "../../components/Popup/DeletePopup"


/**
 * 類別維護頁面
 */
const DepartmentMaintenance = () => {
    // 科別維護資料
    const [deptMaintenance, setDeptMaintenance] = useState(null)
    // 進階搜尋清單
    const [advDeptMaintenance, setAdvDeptMaintenance] = useState(null)
    // 新增Array
    const [insertDeptData, setInsertDeptData] = useState(null)
    // pagination控制變數
    const [deptMaintenancePaginationProps, setDeptMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })

    // 類別輸入框搜尋文字
    const [inputTypeSearchText, setInputTypeSearchText] = useState('')
    // 類別搜尋的文字
    const [deptQueryText, setDeptQueryText] = useState('')
    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 維護資料 進階搜尋文字
    const [advDeptSearchText, setAdvDeptSearchText] = useState('')
    // 選擇的科別資料
    const [selectedDeptData, setSelectedDeptData] = useState(null)
    // 編輯的科別資料
    const [editDeptDatas, setEditDeptDatas] = useState(null)
    const [delItemKey, setDelItemKey] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {

        setDeptQueryText(inputTypeSearchText)
        //master
        queryDeptData(inputTypeSearchText, 1, deptMaintenancePaginationProps.pageSize)
    }

    /**
     * 查詢科別維護
     * @param {*} queryText 查詢的文字
     * @param {Number} page 查詢頁碼
     * @param {Number} pageSize 每頁資料筆數
     */
    const queryDeptData = (queryText, page, pageSize) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)
        
        crudQueryTableData({
            //module name
            system: "menu",
            //compare operator
            compare: "==",
            tableName: "Division",
            searchColumn: "divNo",
            value: queryText,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return {...item, key: index}
                }) : []
                setDeptMaintenance(dataWithKeys)

                setDeptMaintenancePaginationProps({
                    ...deptMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                const msg = `取得科別維護資料失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})

                setDeptMaintenance(null)
                setDeptMaintenancePaginationProps({
                    ...deptMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }


    /**
     * 進階搜尋
     */
    const getAdvanceDeptSearchTypeData = () => {
        const tmpArray =
            arrayIsEmpty(deptMaintenance) ? [] :
                deptMaintenance.map((item) => (
                    {
                        //key
                        key: item.key,  //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //科別代號
                        divNo: item.divNo,
                        //科別名稱
                        divName: item.divName,
                        //門診申報科別
                        opdheDivNo: item.opdheDivNo,
                        //住院申報科別
                        inpheDivNo: item.inpheDivNo,
                        //報備支援科別
                        supportDivNo: item.supportDivNo,
                        //國健局科別
                        bhpDivNo: item.bhpDivNo
                    }
                ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advDeptSearchText, ["key"]) //key不要搜尋
        setAdvDeptMaintenance(findedDataArray)
    }


    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onDeptMaintenancePaginationPageOnChange = (page) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)
        queryDeptData(deptQueryText, page, deptMaintenancePaginationProps.pageSize)
    }

    /**
     * 上一頁點擊事件
     */
    const onDeptMaintenancePaginationPreviousOnClick = () => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        const page = (deptMaintenancePaginationProps.currentPage - 1) > 1 ? (deptMaintenancePaginationProps.currentPage - 1) : 1
        queryDeptData(deptQueryText, page, deptMaintenancePaginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onDeptMaintenancePaginationNextOnClick = () => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        const page = (deptMaintenancePaginationProps.currentPage + 1) < deptMaintenancePaginationProps.totalPageSize ? (deptMaintenancePaginationProps.currentPage + 1) : deptMaintenancePaginationProps.totalPageSize
        queryDeptData(deptQueryText, page, deptMaintenancePaginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onDeptMaintenancePaginationPageSizeOnChange = (e) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)
        // 設定每頁資料筆數
        queryDeptData(deptQueryText, 1, e.target.value)
    }


    /**
     * 類別輸入框搜尋文字變更事件
     * @param e {Event} 事件
     */
    const handleInputTypeSearchTextOnChange = (e) => {
        setInputTypeSearchText(e.target.value)
    }

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupCloseOnClick = () => {
        setDelItemKey(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = () => {
        if(!isNaN(delItemKey)) {
            const fundedData = deptMaintenance.find(item => item.key === delItemKey)
            if(!objectIsEmpty(fundedData)) {
                //要刪除的項目存在
                const tableData = [{
                    "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
                    "createUser": fundedData.createUser,
                    "modifyDatetime": stringIsEmpty(fundedData.modifyDatetime) ? null : fundedData.modifyDatetime,
                    "modifyUser": fundedData.modifyUser,
                    "lockVersion": fundedData.lockVersion,
                    "divNo": fundedData.divNo,
                    "divName": fundedData.divName,
                    "opdheDivNo": fundedData.opdheDivNo,
                    "inpheDivNo": fundedData.inpheDivNo,
                    "precedeDivNo": fundedData.precedeDivNo,
                    "bhpDivNo": fundedData.bhpDivNo,
                    "supportDivNo": fundedData.supportDivNo
                }]
                //轉成json格式
                const tableDataJSON = JSON.stringify(tableData)
                //開始刪除
                crudDelTableData({
                    "tableName": "tw.com.hismax.his.entity.menu.Division",
                    "tableData": tableDataJSON
                }).then((res => {
                    if(res.err === ApiErrorStatusEnum.Success){
                        //更新table
                        queryDeptData(deptQueryText, 1, deptMaintenancePaginationProps.pageSize)
                    }else{
                        const msg = `刪除失敗: ${res.msg}`
                        showToast({message:msg, type:ToastTypeEnum.Error})
                    }
                }))
            }
        }

        setDelItemKey(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupCloseOnClick()

    /**
     * 科別項目刪除按鈕點擊事件
     * @param {Number} keyToDel 
     */
    const handleDeptDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItemKey(keyToDel)
        setShowDeletePopup(true)
    }


    /**
     * 進階搜尋輸入框文字變更事件
     * @param {Event} e
     */
    const handleAdvDeptSearchTextOnChange = (e) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        setAdvDeptSearchText(e.target.value)
    }

    /**
     *  新增按鈕點擊事件
     */
    const handleAddDeptTypeBtnOnClick = () => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)
        
        const newType = {
            key: arrayIsEmpty(insertDeptData) ? 0 : insertDeptData.length,
            divNo: '',
            divName: '',
            opdheDivNo: '',
            inpheDivno: '',
            supportDivNo: '',
            bhpDivNo: '',
        }

        const updatedDatas = arrayIsEmpty(insertDeptData) ? [newType] : [...insertDeptData, newType]

        setInsertDeptData(updatedDatas)
    }
    /**
     * 科別新增項目取消按鈕點擊事件
     * @param {Number} keyToCancel 取消的項目key
     */
    const handleDeptInsertCancelBtnOnClick = (keyToCancel) => {
        //移除
        setInsertDeptData(insertDeptData.filter(item => item.key !== keyToCancel))
    }

    /**
     * 編輯按鈕點擊事件
     * @param {Number} key key
     */
    const handleDeptEditBtnOnClick = (e, key) => {
        e.stopPropagation()
        //找出科別資料
        const fundedData = deptMaintenance.find(item => item.key === key)
        if(objectIsEmpty(fundedData)) {
            return
        }

        setSelectedDeptData(fundedData)
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        setEditDeptDatas([
            ...arrayIsEmpty(editDeptDatas)? []:editDeptDatas,
            fundedData
        ])
    }

    /**
     * 科別新增項目儲存按鈕點擊事件
     * @param {Number} keyToSave 儲存的項目key
     */
    const handleDeptInsertSaveBtnOnClick = (keyToSave) => {
        const fundedData = insertDeptData.find(item => item.key === keyToSave)
        if(objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": null,
            "createUser": null,
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": 0,
            "divNo": fundedData.divNo,
            "divName": fundedData.divName,
            "opdheDivNo": fundedData.opdheDivNo,
            "inpheDivNo": fundedData.inpheDivNo,
            "precedeDivNo": fundedData.precedeDivNo,
            "bhpDivNo": fundedData.bhpDivNo,
            "supportDivNo": fundedData.supportDivNo
        }]

        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Division",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //更新table
                queryDeptData(deptQueryText, 1, deptMaintenancePaginationProps.pageSize)
            }else{
                const msg = `新增失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 預新增－[科別代號]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptDivNoTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, divNo: e.target.value } : item
        ))
    }
    /**
     * 預新增－[科別名稱]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptDivNameTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, divName: e.target.value } : item
        ))
    }
    /**
     * 預新增－[門診申報科別]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptOpdheDivNoTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, opdheDivNo: e.target.value } : item
        ))
    }
    /**
     * 預新增－[住院申報科別]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptInpheDivNoTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, inpheDivNo: e.target.value } : item
        ))
    }
    /**
     * 預新增－[報備支援科別]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptSupportDivNoTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, supportDivNo: e.target.value } : item
        ))
    }
    /**
     * 預新增－[國健局科別]輸入框文字變動事件
     * @param {Event} e
     * @param {Number} key
     */
    const handleDeptBhpDivNoTextOnChange = (e, key) => {
        setInsertDeptData(insertDeptData.map(item =>
            item.key === key ? { ...item, bhpDivNo: e.target.value } : item
        ))
    }

    /**
     *
     * @param {*} key
     */
    const handleDeptDataOnClick = (key) => {
        const findedData = deptMaintenance.find(item => item.key === key)
    }

    /**
     * 檢查資料是否被選擇
     * @param {Object} data 
     * @returns 
     */
    const checkDataIsSelected = (data) => {
        if(objectIsEmpty(selectedDeptData)) {
            return false
        }
        if(selectedDeptData.key === data.key) {
            return true
        }
        return false
    }

    /**
     * 科別名稱編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditDeptNameTextOnChange = (e, key) => {
        setEditDeptDatas(editDeptDatas.map(item =>
            item.key === key ? { ...item, divName: e.target.value } : item
        ))
    }

    /**
     * 門診申報科別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditOpdheDeptNoTextOnChange = (e, key) => {
        setEditDeptDatas(editDeptDatas.map(item =>
            item.key === key ? { ...item, opdheDivNo: e.target.value } : item
        ))
    }

    /**
     * 住院申報科別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditInpheDeptNoTextOnChange = (e, key) => {
        setEditDeptDatas(editDeptDatas.map(item =>
            item.key === key ? { ...item, inpheDivNo: e.target.value } : item
        ))
    }

    /**
     * 報備支援科別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditSupportDeptNoTextOnChange = (e, key) => {
        setEditDeptDatas(editDeptDatas.map(item =>
            item.key === key ? { ...item, supportDivNo: e.target.value } : item
        ))
    }

    /**
     * 國健局科別代碼編輯輸入框文字變動事件
     * @param {Event} e 
     * @param {Number} key 
     */
    const handleEditBhpDeptNoTextOnChange = (e, key) => {
        setEditDeptDatas(editDeptDatas.map(item =>
            item.key === key ? { ...item, bhpDivNo: e.target.value } : item
        ))
    }

    /**
     * 科別編輯項目儲存按鈕點擊事件
     * @param {Number} keyToSave 儲存的項目key
     */
    const handleDeptEditSaveBtnOnClick = (keyToSave) => {
        const fundedData = editDeptDatas.find(item => item.key === keyToSave)
        if(objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": null,
            "createUser": null,
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": fundedData.lockVersion,
            "divNo": fundedData.divNo,
            "divName": fundedData.divName,
            "opdheDivNo": fundedData.opdheDivNo,
            "inpheDivNo": fundedData.inpheDivNo,
            "precedeDivNo": fundedData.precedeDivNo,
            "bhpDivNo": fundedData.bhpDivNo,
            "supportDivNo": fundedData.supportDivNo
        }]

        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        crudSaveTableData({
            "tableName": "tw.com.hismax.his.entity.menu.Division",
            "tableData": tableDataJSON
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                //更新table
                queryDeptData(deptQueryText, 1, deptMaintenancePaginationProps.pageSize)
            }else{
                const msg = `儲存失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 科別編輯項目取消按鈕點擊事件
     * @param {Number} keyToCancel 取消的項目key
     */
    const handleDeptEditCancelBtnOnClick = (keyToCancel) => {
        //移除
        setEditDeptDatas(editDeptDatas.filter(item => item.key !== keyToCancel))
    }

    // 編輯按鈕
    const EditSlotBtn = ({disable = false, onClick}) => (
        <button 
            className={`flex items-center text-primary hover:cursor-pointer`}
            disabled={disable}
            onClick={onClick}>
            {
                disable?
                <EditDisableBtn className='w-6 h-6'/> : <EditBtn className='w-6 h-6'/>
            }
            
            <p className={`${disable ? 'text-[#D4D4D8]':''}`}>編輯</p>
        </button>
    );

    // 刪除按鈕
    const DelSlotBtn = ({disable = false, onClick}) => (
        <button 
            className={`flex items-center text-primary hover:cursor-pointer`}
            disabled={disable}
            onClick={onClick}>
            {
                disable?
                <DeleteDisableBtn className='w-6 h-6'/> : <DeleteBtn className='w-6 h-6'/>
            }
            <p className={`${disable ? 'text-[#D4D4D8]':''}`}>刪除</p>
        </button>
    );

    //儲存按鈕
    const SaveIconSlotBtn = ({onClick}) => (
        <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
            <button>
                <SaveBtn className='w-6 h-6 '/>
            </button>
            儲存
        </span>
    );

    //取消按鈕
    const CancelSlotBtn = ({onClick}) => (
        <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
            <button>
                <CancelBtn className='w-6 h-6 '/>
            </button>
            取消
        </span>
    );

    /**
     * 監聽advDeptSearchText, deptMaintenance的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceDeptSearchTypeData()
    }, [advDeptSearchText, deptMaintenance])


    /**
     * 初始
     */
    useMemo(() => {
        handleSearchOnClick()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                {/*類別代碼*/}
                <div className="flex items-center">
                    <p>科別代碼：</p>
                    <SearchInput
                        searchText={inputTypeSearchText}
                        onChange={handleInputTypeSearchTextOnChange}
                    />
                </div>

                {/*查詢*/}
                <DefaultButton
                    type="primary" text={t('general.query')}
                    onClickFn={handleSearchOnClick}/>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*科別維護*/}
                <div
                    className="w-full p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                    <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                        <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                        <div className="text-xl text-[#2B6CB0] mr-3.5">科別維護</div>
                        <SearchInput
                            placeholder="進階搜尋" searchText={advDeptSearchText}
                            onChange={(e) => handleAdvDeptSearchTextOnChange(e)}/>
                    </div>
                    {/*Table*/}
                    <ul className="tableList deptMaintenanceTableItem min-h-[calc(100vh-325px)] max-h-[calc(100vh-325px)] overflow-x-auto overflow-y-auto">
                        <li className="title sticky top-0">
                            <div>
                                {/*新增按鈕*/}
                                <div className='w-[90px]'>
                                    <button
                                        className="flex flex-row items-center gap-1 my-4"
                                        disabled={!arrayIsEmpty(editDeptDatas) || !arrayIsEmpty(insertDeptData)}
                                        onClick={handleAddDeptTypeBtnOnClick}>
                                        {
                                            !arrayIsEmpty(editDeptDatas) || !arrayIsEmpty(insertDeptData) ?
                                            <AddDisableIcon className="w-[33.33px] h-[33.33px]"/> : <AddIcon className="w-[33.33px] h-[33.33px]"/>
                                        }
                                        
                                        <p className={`text-[#38A169] ${!arrayIsEmpty(editDeptDatas) || !arrayIsEmpty(insertDeptData) ? 'text-[#D4D4D8]':''}`}>{t('general.add')}</p>
                                    </button>
                                </div>
                            </div>
                            <div>科別代號</div>
                            <div>科別名稱</div>
                            <div>門診申報科別代碼</div>
                            <div>住院申報科別代碼</div>
                            <div>報備支援科別代碼</div>
                            <div>國健局科別代碼</div>
                        </li>
                        {
                            !arrayIsEmpty(advDeptMaintenance) && advDeptMaintenance.map((item) => {
                                let funddedEditItem = null
                                const haveEditOrNewItem = (!arrayIsEmpty(editDeptDatas) || !arrayIsEmpty(insertDeptData))
                                if(!objectIsEmpty(item) && !arrayIsEmpty(editDeptDatas)) {
                                    funddedEditItem = editDeptDatas.find(data => data.key === item.key)
                                }

                                return (
                                objectIsEmpty(funddedEditItem) ?
                                <li key={item.key}
                                    onClick={() => handleDeptDataOnClick(item.key)}>
                                    {/*操作按鈕*/}
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                            <EditSlotBtn disable={haveEditOrNewItem} onClick={(e) => handleDeptEditBtnOnClick(e, item.key)}/>
                                            <DelSlotBtn disable={haveEditOrNewItem} onClick={() => handleDeptDeleteTypeBtnOnClick(item.key)}/>
                                        </div>
                                    </div>
                                    {/*科別代號*/}
                                    <div>{item.divNo}</div>
                                    {/*科別名稱*/}
                                    <div>{item.divName}</div>
                                    {/*門診申報科別*/}
                                    <div>{item.opdheDivNo}</div>
                                    {/*住院申報科別*/}
                                    <div>{item.inpheDivNo}</div>
                                    {/*報備支援科別*/}
                                    <div>{item.supportDivNo}</div>
                                    {/*國健局科別*/}
                                    <div>{item.bhpDivNo}</div>
                                </li> :
                                <li key={funddedEditItem.key}
                                    className={`${checkDataIsSelected(funddedEditItem) ? "selected" : ""}`}>
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                            <SaveIconSlotBtn onClick={() => handleDeptEditSaveBtnOnClick(item.key)}/>
                                            <CancelSlotBtn onClick={() => handleDeptEditCancelBtnOnClick(item.key)}/>
                                        </div>
                                    </div>
                                    {/*科別代號*/}
                                    <div>{item.divNo}</div>
                                    {/*科別名稱*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.divName}
                                            onChange={(e) => handleEditDeptNameTextOnChange(e, funddedEditItem.key)}/>
                                    </div>
                                    {/*門診申報科別代碼*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.opdheDivNo}
                                            onChange={(e) => handleEditOpdheDeptNoTextOnChange(e, funddedEditItem.key)}/>
                                    </div>
                                    {/*住院申報科別代碼*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.inpheDivNo}
                                            onChange={(e) => handleEditInpheDeptNoTextOnChange(e, funddedEditItem.key)}/>
                                    </div>
                                    {/*報備支援科別代碼*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.supportDivNo}
                                            onChange={(e) => handleEditSupportDeptNoTextOnChange(e, funddedEditItem.key)}/>
                                    </div>
                                    {/*國健局科別代碼*/}
                                    <div>
                                        {/*不用BaseInput是因為tableList內有設定div，此div設定會影響到BaseInput內的div*/}
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md" type="text" value={funddedEditItem.bhpDivNo}
                                            onChange={(e) => handleEditBhpDeptNoTextOnChange(e, funddedEditItem.key)}/>
                                    </div>
                                </li>
                            )})
                        }
                        {
                            //預新增的項目
                            !arrayIsEmpty(insertDeptData) && insertDeptData.map((item) => (
                                <li key={item.key}>
                                    <div>
                                        <div
                                            className='w-[90px] flex flex-row items-center gap-2 my-4 hover:cursor-pointer'>
                                            <SaveIconSlotBtn onClick={() => handleDeptInsertSaveBtnOnClick(item.key)}/>
                                            <CancelSlotBtn onClick={() => handleDeptInsertCancelBtnOnClick(item.key)}/>
                                        </div>
                                    </div>
                                    {/*科別代號*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.divNo}
                                            onChange={(e) => handleDeptDivNoTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*科別名稱*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.divName}
                                            onChange={(e) => handleDeptDivNameTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*門診申報科別*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.opdheDivNo}
                                            onChange={(e) => handleDeptOpdheDivNoTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*住院申報科別*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.inpheDivNo}
                                            onChange={(e) => handleDeptInpheDivNoTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*報備支援科別*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.supportDivNo}
                                            onChange={(e) => handleDeptSupportDivNoTextOnChange(e, item.key)}/>
                                    </div>
                                    {/*國健局科別*/}
                                    <div>
                                        <input
                                            className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-md"
                                            type="text" value={item.bhpDivNo}
                                            onChange={(e) => handleDeptBhpDivNoTextOnChange(e, item.key)}/>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={deptMaintenancePaginationProps.totalPageSize}
                            currentPage={deptMaintenancePaginationProps.currentPage}
                            pageSize={deptMaintenancePaginationProps.pageSize}
                            totalSize={deptMaintenancePaginationProps.totalItemSize}
                            onPageOnChange={(page) => onDeptMaintenancePaginationPageOnChange(page)}
                            onPrevPageOnClick={onDeptMaintenancePaginationPreviousOnClick}
                            onNextPageOnClick={onDeptMaintenancePaginationNextOnClick}
                            onPageSizeChange={onDeptMaintenancePaginationPageSizeOnChange}
                        />
                    </div>
                </div>

            </div>
            {/* 刪除彈窗 */}
            <DeletePopup show={showDeletePopup} content="是否確定刪除?"
                         onClosePopupButtonClick={handleDeletePopupCloseOnClick}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </div>
    )
}

export default DepartmentMaintenance