import React, {useState} from "react"
import {enum2Array, ICCMUploadTabEnum} from "edah_utils/dist"
import {ReturnResultTab} from "./Tab/ReturnResultTab"
import {UploadXMLTab} from "./Tab/UploadXMLTab"
import {FieldMappingTab} from "./Tab/FieldMappingTab"
import {ErrorCodeTab} from "./Tab/ErrorCodeTab"
import {t} from "i18next"

/**
 * IC卡資料上傳資料查詢-ActiveTab
 * @return {JSX.Element}
 */
export const ActiveTab = ({}) => {
    // 定義activeTab狀態
    const [activeTab, setActiveTab] = useState(ICCMUploadTabEnum.ReturnResult)

    /**
     * 取得Tab樣式
     * @param tab {ICCMUploadTabEnum} Tab Enum
     * @return {string}
     */
    const getTabClassName = (tab) => `h-[42px] px-4 py-2 text-[16px] ${activeTab === tab ? 'border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]' : ''}`

    /**
     * 是否顯示Tab Content
     * @param tab {ICCMUploadTabEnum} Tab Enum
     * @return {boolean}
     */
    const getIsShowTabContent = (tab) => activeTab === tab

    /**
     * 點擊Tab時
     * @param tab {ICCMUploadTabEnum} Tab Enum
     * @return {void}
     */
    const handleTabClick = (tab) => setActiveTab(tab)

    return <div className="p-4 bg-white w-[867px] border-[1px] border-[#D4D4D8] rounded-[12px]">
        {/*點擊按鈕切換下方內容*/}
        <div className="hasHpcSwitch mb-4">
            {/*點擊tab切換內容 - 按鈕*/}
            <div className="tabGroup w-full flex flex-row items-center justify-between mb-4 pr-4">
                <div className="flex flex-row items-center justify-start">
                    {
                        // 產生按鈕
                        enum2Array(ICCMUploadTabEnum).map((item, index) =>
                            <button key={index} className={getTabClassName(item.value)}
                                    onClick={() => handleTabClick(item.value)}>
                                {t(`ICCard.page.uploadSearchPage.tab.${item.value}`)}
                            </button>
                        )
                    }
                </div>
            </div>
            {/*回傳結果*/}
            <ReturnResultTab show={getIsShowTabContent(ICCMUploadTabEnum.ReturnResult)}/>

            {/*上傳XML*/}
            <UploadXMLTab show={getIsShowTabContent(ICCMUploadTabEnum.UploadXML)}/>

            {/*欄位對照*/}
            <FieldMappingTab show={getIsShowTabContent(ICCMUploadTabEnum.FieldMapping)}/>

            {/*錯誤代碼*/}
            <ErrorCodeTab show={getIsShowTabContent(ICCMUploadTabEnum.ErrorCode)}/>
        </div>
    </div>
}
