import React, { useState } from 'react'
import CustomTable from './CustomTable'
import {
    ADMISSION_FIELDS,
    ADMISSION_RESERVATION_FIELDS,
    DENTISTRY_FIELDS,
    OPD_FIELDS,
    REPLY_LETTER_FIELDS,
    TRAN_OUT_FIELDS,
} from '../../constants/referralSystem/tranInCase'
import { t } from 'i18next'
import { queryTransTool } from '../../api/v1/Rmis'
import { BaseInput } from '../Input/BaseInput'
import {
    ApiErrorStatusEnum,
    ToastTypeEnum,
    validDateFormat,
    time2String,
} from 'edah_utils/dist'
import { DATETIME_FORMAT } from '../../constants/common'
import useToast from '../../hooks/useToast'

const QueryToolsModal = () => {
    const [searchToolParams, setSearchToolParams] = useState({
        search: '',
        encounterStartDate: '',
        encounterEndDate: '',
        encounterId: '',
    })

    const [inputState, setInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })

    const [queryList, setQueryList] = useState({
        // 預約住院查詢table
        reserveInpList: [],
        // 住院主檔查詢table
        inpList: [],
        // 門診看診主檔查詢table
        opdList: [],
        // 轉診單查詢table
        tranOutList: [],
        // 回覆單查詢table
        tranInList: [],
    })

    const showToast = useToast()

    const updateParams = (e, field) => {
        const value = e.target.value
        setSearchToolParams((prev) => ({ ...prev, [field]: value }))
    }
    const handleQueryOnClick = () => {
        const encounterStartDateIsValid = validDateFormat(
            searchToolParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchToolParams.encounterEndDate
        )
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterEndDateIsValid || !encounterEndDateIsValid) {
            return
        }
        queryTransTool({
            ...searchToolParams,
            encounterStartDate: time2String(
                searchToolParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchToolParams.encounterEndDate,
                DATETIME_FORMAT
            ),
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQueryList({
                    inpList: res.data?.inpList || [],
                    opdList: res.data?.opdList || [],
                    reserveInpList: res.data?.reserveInpList || [],
                    tranInList: res.data?.tranInList || [],
                    tranOutList: res.data?.tranOutList || [],
                })
            } else {
                showToast({
                    message: '預約住院查無資料',
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    return (
        <div className="w-full overflow-auto max-h-[calc(100vh-140px)] p-2">
            <div className="flex">
                <div className="date flex items-center justify-start mb-4 mr-4">
                    <div className="mr-4">
                        就醫日期<span className="text-danger ">*</span>：
                    </div>
                    <BaseInput
                        className="w-[166px] h-10 px-4 rounded-[6px] border"
                        errorMsg="必填"
                        type="date"
                        value={searchToolParams.encounterDateStart}
                        onChange={(e) => updateParams(e, 'encounterStartDate')}
                        inputState={inputState.encounterStartDate}
                    />
                    <span className="m-1">~</span>
                    <BaseInput
                        className="w-[166px] h-10 px-4 rounded-[6px] border"
                        errorMsg="必填"
                        value={searchToolParams.encounterEndDate}
                        onChange={(e) => updateParams(e, 'encounterEndDate')}
                        type="date"
                        inputState={inputState.encounterEndDate}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <div className="mr-4">
                        {`${t('general.patientNo')}/${t('general.idNumber')}`}：
                    </div>
                    <input
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type="text"
                        value={searchToolParams.search}
                        onChange={(e) => updateParams(e, 'search')}
                    />
                </div>
                <div className="date flex items-center justify-start mb-4 mr-4">
                    <div className="mr-4">就醫號:</div>
                    <input
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type="text"
                        value={searchToolParams.encounterId}
                        onChange={(e) => updateParams(e, 'encounterId')}
                    />
                </div>
                <button
                    className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                    onClick={handleQueryOnClick}
                >
                    {t('general.query')}
                </button>
            </div>
            <div className="mb-2">
                <CustomTable
                    title="預約住院查詢"
                    fields={ADMISSION_RESERVATION_FIELDS}
                    dataList={queryList.reserveInpList}
                />
            </div>
            <div>
                <CustomTable
                    title="住院主檔查詢"
                    fields={ADMISSION_FIELDS}
                    dataList={queryList.inpList}
                />
            </div>
            <div className="mb-2">
                <CustomTable
                    title="門診看診主檔查詢"
                    fields={OPD_FIELDS}
                    dataList={queryList.opdList}
                />
            </div>
            <div>
                <CustomTable
                    title="轉診單查詢"
                    fields={TRAN_OUT_FIELDS}
                    dataList={queryList.tranOutList}
                />
            </div>
            <div>
                <CustomTable
                    title="回覆單查詢"
                    fields={REPLY_LETTER_FIELDS}
                    dataList={queryList.tranInList}
                />
            </div>
        </div>
    )
}

export default QueryToolsModal
