import React, { useEffect, useMemo, useState } from 'react'
import { BasePopup } from '../../components/Popup/BasePopup'
import RadioButton from '../../components/Input/RadioButton'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { RETURN_APPOINTMENT } from '../../constants/referralSystem/maintainAreaHeader'
import { BaseInput } from '../../components/Input/BaseInput'
import {
    InputTypeEnum,
    ApiErrorStatusEnum,
    ToastTypeEnum,
} from 'edah_utils/dist'
import Button from '../../components/Button/Button'
import { queryTranInToOpd } from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'

const Layout = ({ children }) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

const InputStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white'

/**
 * 轉診回診清單內容
 * @param patientId
 * @param encounterDate
 * @returns {Element}
 * @constructor
 */
const ReturnAppointmentModalContent = ({
    patientId,
    patientName,
    encounterDate,
    onConfirm,
}) => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    const [medicalRecord, setMedicalRecord] = useState('')
    const [name, setName] = useState('')

    const handleMedicalRecordChange = (e) => setMedicalRecord(e.target.value)
    const handleNameChange = (e) => setName(e.target.value)
    const showToast = useToast()

    const getTranInToOpdList = (pageNum, pageSize) => {
        queryTranInToOpd({ patientId, encounterDate, pageNum, pageSize }).then(
            (res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    const list = res.data?.dataList?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    })
                    if (list.length === 0) {
                        showToast({
                            message: '查無資料',
                            type: ToastTypeEnum.Warning,
                        })
                    }
                    setDataList(list)
                    setFilter({
                        ...filter,
                        total: res.data.totalItemSize,
                        currentPage: pageNum,
                        pageSize,
                        totalPage: res.data.totalPageSize,
                    })
                } else {
                    showToast({ message: res.msg, type: ToastTypeEnum.Error })
                }
            }
        )
    }

    // 確定
    const handleConfirm = () => {
        const data = dataList.filter((item) => item.isSelected)
        onConfirm(data)
    }

    /**
     * 轉出清單 table header
     */
    const renderFields = useMemo(() => {
        return RETURN_APPOINTMENT
    }, [])

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        setDataList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getTranInToOpdList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranInToOpdList(filter.currentPage, pageSize)
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getTranInToOpdList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getTranInToOpdList(page, filter.pageSize)
    }

    useEffect(() => {
        getTranInToOpdList(1, filter.pageSize)
    }, [])

    return (
        <Layout>
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>病歷號：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={medicalRecord || patientId}
                        onChange={handleMedicalRecordChange}
                        disable={true}
                    />
                    <Title extendClass={'ml-4'}>姓名：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={name || patientName}
                        onChange={handleNameChange}
                        disable={true}
                    />
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleItem={toggleItem}
                isSingleSelect={true}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            <div className="px-2 pt-4 pb-4 text-left">
                <div className="mb-2">
                    ※若病人為電子轉診病人，請勾選後帶回。
                </div>
                <div className="mb-2">※打勾處反灰，代表此單已被使用。</div>
                <div className="mb-2">
                    ※取消打勾後，按確定，即可取消使用此單表。
                </div>
            </div>
            <div className="title w-full flex flex-row items-center justify-end py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
                <Button
                    classNames={`${ButtonStyle} mr-2`}
                    text={'確定'}
                    onClickFn={handleConfirm}
                />
            </div>
        </Layout>
    )
}
/**
 * 轉診回診清單
 * @param onClose
 * @param patientId
 * @param encounterDate
 * @returns {Element}
 * @constructor
 */
const ReturnAppointmentModal = ({ onClose, patientInfo, onConfirm }) => {
    return (
        <BasePopup
            title="轉診回診清單"
            width="80%"
            content={
                <ReturnAppointmentModalContent
                    patientId={patientInfo.patientId}
                    patientName={patientInfo.patientName}
                    encounterDate={patientInfo.encounterDate}
                    onConfirm={onConfirm}
                />
            }
            closePopupButtonOnClick={onClose}
        />
    )
}

export default ReturnAppointmentModal
