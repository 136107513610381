import React, { useState, useMemo, useRef, useEffect } from 'react';
import { BaseInput } from "../../components/Input/BaseInput";
import {ReactComponent as EditBtn} from "../../assets/images/ICCard/edit_square.svg";
import {ReactComponent as DeleteBtn} from "../../assets/images/ICCard/delete.svg";
import addIcon from "../../assets/images/icons/add_circle.svg";
import {t} from "i18next";
import SloganEditPopup from "../../components/Regs/MedicalMessageManagement/EditPopup";
import {
    objectIsEmpty, 
    arrayIsEmpty,
    ApiErrorStatusEnum,
    ToastTypeEnum,
    fuzzySearchObjects,
    stringIsEmpty
} from "edah_utils/dist";
import {DeletePopup} from "../../components/Popup/DeletePopup";
import {queryJustNameByCategories} from "../../api/v1/Menu"
import { 
    sloganQueryBySlognTypeAndDoctorNoAndDivNo,
    slognEditData,
    slognAddData,
    slognDelData
 } from '../../api/v1/Slogn';
import { queryAllDoctor, queryAllDivision } from '../../api/v1/Menu';
import useToast from "../../hooks/useToast"
import useOutsideClick from '../../hooks/useOutsideClick';
import { Pagination } from '../../components/Pagination/Pagination';

/**
 * 標語維護頁面組件
 */
const MedicalMessageManagement = () => {

    /**
     * Show Toast
     */
    const showToast = useToast()

    const defaultQueryInputData = {
        category: '',
        doctorCode: '',
        departmentCode: ''
    }

    //控制新增編輯Popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // 編輯標語
    const [editingSlogan, setEditingSlogan] = useState(null);

    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("")
    // 標語類別
    const [sloganType, setSloganType] = useState(null)
    // 醫師清單
    const [doctorList, setDoctorList] = useState(null)
    // 科別清單
    const [departmentList, setDepartmentList] = useState(null)
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)

    // 標語數據清單
    const [sloganData, setSloganData] = useState(null);
    // 標語數據進階搜尋清單
    const [advSearchSloganDataList, setAdvSearchSloganDataList] = useState(null)

    //控制刪除彈窗的顯示狀態
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    //當前準備刪除的標語 ID
    const [deletingSloganId, setDeletingSloganId] = useState(null);

    //當前選中的標語
    const [selectedSlogan, setSelectedSlogan] = useState(null);
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });


    /**
     * 處理標語選擇
     * @param {object} slogan - 被選中的標語對象
     */
    const handleSloganSelect = (slogan) => {
        const findedData = sloganData.find(item => item.key === slogan.key)
        setSelectedSlogan(findedData);
    };


    /**
     * 處理刪除標語的函數
     * @param {number} key - 要刪除的標語的key
     */
    const handleDelete = (key) => {
        //找出要刪除的資料
        const findedData = sloganData.find(item => item.key === key)

        setDeletingSloganId(findedData.slognId);
        setIsDeletePopupOpen(true);
    };

    /**
     * 確認刪除標語的函數
     */
    const confirmDelete = () => {
        slognDelData({
            slognId: deletingSloganId
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: '刪除成功', type: ToastTypeEnum.Success})

                setSelectedSlogan(null)
                setDeletingSloganId(null)

                //重新取標語明細
                querySlogn(queryInputData, 1, paginationProps.pageSize)
            } else { // 取得資料失敗
                setDeletingSloganId(null)
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
        setIsDeletePopupOpen(false);
    };

    /**
     * 取消刪除標語的函數
     */
    const cancelDelete = () => {
        setIsDeletePopupOpen(false);
        setDeletingSloganId(null);
    };

    /**
     * 標語類別的下拉選擇值變動時
     * @return {void}
     */
    const handleSelectCategoryOnChange = (e) => {
        setDisplayQueryInputData({ ...displayQueryInputData, category: e.target.value })
    }

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e) => {
        setDisplayQueryInputData({ ...displayQueryInputData, doctorCode: e.target.value});
    }

    /**
     * 科別代號方框的值變動時
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({ ...displayQueryInputData, departmentCode: e.target.value})
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: `${item.userNo} ${item.userName}`
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (item) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: `${item.divNo} ${item.divName}`
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 處理編輯標語的函數
     * 設置當前編輯的標語並打開彈出窗口
     * @param {object} slogan - 要編輯的標語對象
     */
    const handleEdit = (slogan) => {
        const findedData = sloganData.find(item => item.key === slogan.key)
        setEditingSlogan(findedData);
        setIsPopupOpen(true);
    };

    /**
     * 處理添加新標語的函數
     * 重置編輯狀態並打開彈出窗口
     */
    const handleAdd = () => {
        setEditingSlogan(null);
        setIsPopupOpen(true);
    };

    /**
     * 處理保存標語的函數
     * 根據是否為編輯模式來更新或添加標語
     * @param {object} updatedSlogan - 更新後的標語對象
     */
    const handleSaveSlogan = (updatedSlogan) => {
        if (editingSlogan) {

            slognEditData({
                // 標語類識別碼
                slognId: editingSlogan.slognId,
                // 標語類別
                slognType: updatedSlogan.categoryNo,
                // 標語類別名稱
                slognName:updatedSlogan.name,
                // 醫師代碼
                doctorNo: !stringIsEmpty(updatedSlogan.doctorCode)?updatedSlogan.doctorCode.split(" ")[0]:'',
                // 科別代碼
                divNo: !stringIsEmpty(updatedSlogan.departmentCode)?updatedSlogan.departmentCode.split(" ")[0]:'',
                // 時段
                apn: stringIsEmpty(updatedSlogan.time) ? null : parseInt(updatedSlogan.time),
                // 標語內文
                slognContent:updatedSlogan.content,
                //版本鎖定
                lockVersion:editingSlogan.lockVersion
            }).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 顯示成功訊息
                    showToast({message: '儲存成功', type: ToastTypeEnum.Success})

                    setSelectedSlogan(null)
                    //重新取標語明細
                    querySlogn(queryInputData, 1, paginationProps.pageSize)

                    //關閉編輯彈窗
                    setIsPopupOpen(false);
                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: ToastTypeEnum.Error})
                }
            })
           
        } else {
            let addData = {
                ...updatedSlogan
            }
            //01.單據，02.醫師_時段，03.科別，04.科別_時段，05.醫師_科別_時段
            if(addData.categoryNo != '02' && addData.categoryNo != '04' && addData.categoryNo != '05') {
                addData.time = ''
            }

            if(addData.categoryNo != '02' && addData.categoryNo != '05') {
                addData.doctorCode = ''
            }

            if(addData.categoryNo != '03' && addData.categoryNo != '04' && addData.categoryNo != '05') {
                addData.departmentCode = ''
            }
            slognAddData({
                // 標語類別
                slognType: addData.categoryNo,
                // 標語類別名稱
                slognName:addData.name,
                // 醫師代碼
                doctorNo: !stringIsEmpty(addData.doctorCode)?addData.doctorCode.split(" ")[0]:'',
                // 科別代碼
                divNo: !stringIsEmpty(addData.departmentCode)?addData.departmentCode.split(" ")[0]:'',
                // 時段
                apn: stringIsEmpty(addData.time) ? null : parseInt(addData.time),
                // 標語內文
                slognContent:addData.content
            }).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 顯示成功訊息
                    showToast({message: '新增成功', type: ToastTypeEnum.Success})

                    setSelectedSlogan(null)
                    //重新取標語明細
                    querySlogn(queryInputData, 1, paginationProps.pageSize)

                    //關閉新增彈窗
                    setIsPopupOpen(false);
                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: ToastTypeEnum.Error})
                }
            })
        }
    };

    /**
     * 執行搜索
     */
    const handleSearch = () => {
        // 實現搜索邏輯
        querySlogn(displayQueryInputData, 1, paginationProps.pageSize)
    };

    /**
     * 取得標語類別Array資料
     */
    const getSloganTypeArray = () => {
        queryJustNameByCategories({
            // 標語類別
            categories:"SLOGN_TYPE"
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定標語類別資料
                setSloganType(data)

                //初始設定 '全部'
                setDisplayQueryInputData({
                    ...displayQueryInputData,
                    category: '00'
                })
            } else { // 取得資料失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDoctorOptionList = () => {
        const splitArray = displayQueryInputData.doctorCode ? displayQueryInputData.doctorCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
             return doctorList.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(doctorList, displayQueryInputData.doctorCode)
        }
    }

    /**
     * 取得科別代碼下拉選項
     * @return {Array<Object>} 過濾後的科別清單
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode ? displayQueryInputData.departmentCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
            return departmentList.filter(department => department.divNo.includes(splitArray[0]) && department.divName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(departmentList, displayQueryInputData.departmentCode)
        }
    }

    /**
     * 取得醫師清單
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setDoctorList(data)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別清單
     * @return {void}
     */
    const getQueryAllDivision = () => {
        queryAllDivision({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定科別清單資料
                setDepartmentList(data)
            } else {
                // 清空科別清單
                setDepartmentList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢標語資料
     */
    const querySlogn = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)
        const queryDoctorString = queryInputObj.doctorCode.trim()
        const queryDepartmentString = queryInputObj.departmentCode.trim()
        sloganQueryBySlognTypeAndDoctorNoAndDivNo({
            // 標語類別
            slognType: queryInputObj.category === '00' ? '' : queryInputObj.category,
            // 醫師代碼
            doctorNo: queryDoctorString.indexOf(' ') > 0 ? queryDoctorString.split(' ')[0] : queryDoctorString,
            // 科別代碼
            divNo: queryDepartmentString.indexOf(' ') > 0 ? queryDepartmentString.split(' ')[0] : queryDepartmentString,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = data.dataList ? data.dataList.map((item, index) => {
                    return { ...item, key: index }
                }) : []

                setSloganData(dataWithKeys)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })

            } else { // 取得資料失敗
                setSloganData([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })

                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorDropDown(true)
    }

    /**
     * 科別代號方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentDropDown(true)
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        querySlogn(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        querySlogn(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        querySlogn(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        querySlogn(queryInputData, 1, e.target.value)
    }

    /**
     * 進階搜尋文字改變時
     * @param {Object} e
     */
    const handleAdvanceSearchTextOnChange = (e) => setAdvSearchText(e.target.value)

    /**
     * 檢查資料是否被選擇
     * @param {Object} data 
     * @returns 
     */
    const checkDataIsSelected = (data) => {
        if(objectIsEmpty(selectedSlogan)) {
            return false
        }
        if(selectedSlogan.key === data.key) {
            return true
        }
        return false
    }

    /**
     * 取得標語進階搜尋結果清單
     */
    const getAdvanceSearchSloganData = () => {
        const tmpArray = 
            arrayIsEmpty(sloganData) ? [] :
            sloganData.map((item) => (
            {
                //key
                key : item.key, //這個不要過濾，不在顯示欄位內

                //只copy要進階搜尋的欄位
                //標語類別
                slognTypeName: getSloganNameBySloganType(item.slognType),
                //標語名稱
                slognName: item.slognName,
                //醫師代碼
                doctorNo : item.doctorNo, 
                //醫師姓名
                doctorName : item.doctorName,
                //科別代碼
                divNo : item.divNo, 
                //科別名稱
                divName : item.divName,
                //時段名稱
                apnName : getApnNameByApnId(item.apn)
            })
        )

        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["key"])
        setAdvSearchSloganDataList(findedData) 

        if(!objectIsEmpty(selectedSlogan)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData) ? false : findedData.some(item => item.key === selectedSlogan.key)
            if(!haveSelected) {
                //不存在的話將selected設為null
                setSelectedSlogan(null)
            }
        }
    }

    /**
     * 取得時段名稱
     * @param {Number} apnId 時段id
     * @returns 
     */
    const getApnNameByApnId = (apnId) => {
        if(apnId === null) {
            return ''
        }
        switch(apnId) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return apnId.toString()
    }

    /**
     * 取得標語類別名稱
     * @param {String} apnId 時段id
     * @returns 
     */
    const getSloganNameBySloganType = (type) => {
        if(type !== '01' && type !== '02' && type !== '03' && type !== '04' && type !== '05') {
            return ''
        }

        return t(`Regs.SloganTypeEnum.${type}`)
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        if(arrayIsEmpty(sloganType)) {
            getSloganTypeArray()
        }

        if(arrayIsEmpty(doctorList)) {
            getQueryAllDoctor()
        }
        
        if(arrayIsEmpty(departmentList)) {
            getQueryAllDivision()
        }
    }, [])

    /** 
     * 監聽advSearchText&sloganData
    */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchSloganData()
    }, [advSearchText, sloganData])

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [displayQueryInputData.doctorCode])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode])

    return (
        <div className="p-4 bg-gray-100 w-full">
            {/* 搜索部分 */}
            <div className="mb-4 flex items-center space-x-4">
                {/*標語類別搜尋欄位*/}
                <div className="flex items-center">
                    <label className="mr-2">{t('Regs.MedicalMessageManagement.sloganCategory')}:</label>
                    <select
                        value={displayQueryInputData.category}
                        onChange={handleSelectCategoryOnChange}
                        className="w-[150px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    >
                        <option value='00'>全部</option>
                    {
                        !arrayIsEmpty(sloganType) && sloganType.map((item) => (
                            <option key={item.justnamedNo} value={item.justnamedNo}>{item.justnamedName}</option>
                        ))
                    }
                    </select>
                </div>
                {/*醫師搜尋欄位*/}
                <div className="flex items-center relative">
                    <label className="mr-2">{t('Regs.MedicalMessageManagement.doctorCodeNum')}:</label>
                    <div ref={dropdownDoctorRef} className="flex flex-row w-[186px] items-center relative">
                        <input
                            className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                            value={displayQueryInputData.doctorCode}
                            onChange={(e) => handleInputDoctorNoOnChange(e)}
                            onFocus={handleInputDoctorNoOnFocus}/>
                        {
                            showDoctorDropDown && !arrayIsEmpty(doctorOptionList) && 
                            (
                                <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            doctorOptionList.map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDoctorOptionOnClick(item)}
                                                    >
                                                    <p>{`${item.userNo} ${item.userName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                {/*科別搜尋欄位*/}
                <div className="flex items-center relative">
                    <label className="mr-2">{t('Regs.MedicalMessageManagement.departmentCodeNum')}:</label>
                    <div ref={dropdownDepartmentRef} className="flex flex-row w-[186px] items-center relative">
                        <input
                            className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                            value={displayQueryInputData.departmentCode}
                            onChange={(e) => handleInputDepartmentNoOnChange(e)}
                            onFocus={handleInputDepartmentNoOnFocus}/>
                        {
                            showDepartmentDropDown && !arrayIsEmpty(departmentOptionList) && 
                            (
                                <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            departmentOptionList.map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDepartmentOptionOnClick(item)}
                                                    >
                                                    <p>{`${item.divNo} ${item.divName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <button
                    onClick={handleSearch}
                    className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                >
                    {t('general.query')}
                </button>
            </div>

            {/* 標語維護表格 */}
            <div className="bg-white rounded shadow p-4">
                <div className="flex items-center mb-4">
                    <h3 className="flex items-center justify-start text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                        <span>{t('Regs.MedicalMessageManagement.sloganCategory')}</span>
                    </h3>
                    <BaseInput
                        type="text" value={advSearchText}
                        placeholder={t('general.advancedSearch')} 
                        inputMode="search"
                        className="w-[320px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        onChange={(e) => handleAdvanceSearchTextOnChange(e)}
                    />
                </div>
                <ul className="tableList MMMTableList min-h-[480px] max-h-[480px]">
                    <li className="title">
                        <div>
                            <button className="flex items-center"
                                    onClick={handleAdd}>
                                <div className="!py-[3px] !h-10 !mr-1 !px-0">
                                    <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                                </div>
                                <p className="text-center text-[#38A169]">{t('general.add')}</p>
                            </button>
                        </div>
                        <div>{t('Regs.MedicalMessageManagement.sloganCategory')}</div>
                        <div>{t('Regs.MedicalMessageManagement.sloganName')}</div>
                        <div>{t('Regs.MedicalMessageManagement.doctorCodeNum')}</div>
                        <div>{t('Regs.MedicalMessageManagement.doctorName')}</div>
                        <div>{t('Regs.MedicalMessageManagement.departmentCodeNum')}</div>
                        <div>{t('Regs.MedicalMessageManagement.departmentName')}</div>
                        <div>{t('Regs.general.timeSlot')}</div>
                    </li>
                    {!arrayIsEmpty(advSearchSloganDataList) && advSearchSloganDataList.map(slogan => (
                        <li key={slogan.key}
                            className={`${checkDataIsSelected(slogan) ? "selected" : ""}`}
                            onClick={() => handleSloganSelect(slogan)}>
                            <div className="flex space-x-2">
                                <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"
                                        onClick={() => handleEdit(slogan)}>
                                    <EditBtn className="mr-1"/>{t('general.edit')}
                                </button>
                                <button className="flex flex-row items-center justify-start text-[#2B6CB0]"
                                        onClick={() => handleDelete(slogan.key)}>
                                    <DeleteBtn className="mr-1"/>{t('general.delete')}
                                </button>
                            </div>
                            <div>{slogan.slognTypeName}</div>
                            <div>{slogan.slognName}</div>
                            <div>{slogan.doctorNo}</div>
                            <div>{slogan.doctorName}</div>
                            <div>{slogan.divNo}</div>
                            <div>{slogan.divName}</div>
                            <div>{slogan.apnName}</div>
                        </li>
                    ))}
                </ul>

                {/*分頁*/}
                <div className="flex justify-end w-full mt-1.5">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        currentPage={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page) => onPaginationPageOnChange(page)}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
            </div>

            {/* 標語內文顯示 */}
            <div className="mt-4 bg-white rounded shadow p-4">
                <h3 className="flex items-start justify-start text-[20px] mb-4 mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                    <span>{t('Regs.MedicalMessageManagement.sloganContent')}</span>
                </h3>
                <p className="text-left min-h-[200px] max-h-[200px] overflow-y-auto">
                    {selectedSlogan ? selectedSlogan.slognContent : '請選擇一個標語以查看內容 '}
                </p>
            </div>

            {/*新增編輯popup*/}
            <SloganEditPopup
                isOpen={isPopupOpen}
                sloganTypeList={sloganType}
                doctorList={doctorList}
                departmentList={departmentList}
                onClose={() => setIsPopupOpen(false)}
                onSave={handleSaveSlogan}
                editingSlogan={editingSlogan}
            />

            {/*刪除popup*/}
            <DeletePopup
                content={t('sloganManagement.deleteConfirmation', '確定是否刪除該標語？')}
                show={isDeletePopupOpen}
                onYesButtonClick={confirmDelete}
                onNoButtonClick={cancelDelete}
                onClosePopupButtonClick={cancelDelete}
            />
        </div>
    );
};

export default MedicalMessageManagement;