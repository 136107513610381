import React, {useState} from 'react'
import {DelSlotBtn, EditSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {t} from 'i18next'

/**
 * 支援機構維護表格清單組件
 * @param {Object} item 清單資料
 * @param {number} index 清單索引
 * @param {boolean} isSelectAll 是否包含全選
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                  }) => {
    // 使用上下文
    const {
        setShowQuerySupportOrgModal,
        setShowDeletePopup,
        setShowEditSupportOrgModal,
        setFocusItem
    } = usePopup();

    /**
     * 編輯按鈕點擊事件，顯示編輯modal，並設定焦點索引
     * @return {void}
     */
    const handleEditOnClick = () => {
        setFocusItem(item)
        setShowEditSupportOrgModal(true);
    }

    /**
     * 刪除按鈕點擊事件，顯示刪除popup，並設定焦點索引
     * @return {void}
     */
    const handleDeleteOnClick = () => {
        setShowDeletePopup(true);
        setFocusItem(item);
    }

    /**
     * 渲染列表按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = (item) => {
        return (
            <>
                <EditSlotBtn onClick={handleEditOnClick}/>
                <DelSlotBtn onClick={handleDeleteOnClick}/>
            </>
        )
    }

    return (
        <li key={index}>
            {/*操作*/}
            <div className='flex flex-row gap-2'>
                {renderSlotBtn(item)}
            </div>
            {/*機構型態*/}
            <div>{item.orgType === 1 ? t(`RSIS.orgTypeEnum.${item.orgType}`) : '醫療'}</div>
            {/*機構代碼*/}
            <div>{item.orgNo ? item.orgNo : ''}</div>
            {/*機構名稱*/}
            <div>{item.orgName ? item.orgName : ''}</div>
            {/*機構住址*/}
            <div>{`${item.orgAddrCity ? item.orgAddrCity : ''}${item.orgAddrLocal ? item.orgAddrLocal : ''}${item.orgAddrInfo ? item.orgAddrInfo : ''}`}</div>
        </li>
    )
}

export default ListItem
