//Import css &icons
import {ReactComponent as Avatar} from "../../assets/images/ICCard/Avatar.svg";
import '../../components/ICCard/PatientInfo.scss'
import '../../components/TableList/tableList.scss'

//Import function
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {ApiErrorStatusEnum, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist";
import {BaseInput} from "../../components/Input/BaseInput";
import {iccmCloudencQueryIccmClouden0Data} from "../../api/v1/ICCM";
import {Pagination} from "../../components/Pagination/Pagination";
import useToast from "../../hooks/useToast"
import {SwitchContainer} from "../../components/ICCard/Cloudenc/SwitchContainer";

/**
 * 健保醫療資訊雲端查詢
 * @return {JSX.Element}
 */
const Cloudenc = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()
    // 病歷號碼
    const [patientId, setPatientId] = useState('')
    // 資料
    const [data, setData] = useState([]);
    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)
    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)
    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)
    //總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQueryMedicationInformation()

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 取得用藥資料
     * @return {void}
     */
    const getQueryMedicationInformation = () => {
        iccmCloudencQueryIccmClouden0Data({
            // 病歷號
            patientId: patientId,
            // 當前頁碼
            pageNum: currentPage,
            // 每頁資料筆數
            pageSize: pageSize
        }).then(res => {
            // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
            const {err, msg, data, totalPageSize, totalItemSize} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
                // 設定總資料筆數
                setTotalSize(totalItemSize)
                // 設定總頁數
                setTotalPageSize(totalPageSize)
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行
     * @return {void}
     */
    useEffect(() => {
        getQueryMedicationInformation();
    }, []);

    return (
        <div className="w-full bg-[#FAFAFA]">
            {/*資訊列*/}
            <div className="flex flex-row w-full h-[68px]  pl-4">
                <div className="w-full h-full flex flex-row items-center justify-start">
                    <h2 className=" flex flex-row items-center justify-center text-[#2B6CB0] mr-4">
                        <Avatar class={"mr-2"}/> {t('general.patientNo')}：
                        <BaseInput className="w-[160px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   type={InputTypeEnum.Text}
                                   value={patientId}
                                   onChange={(e) => setPatientId(e.target.value)}
                        />
                    </h2>
                    {/*姓名*/}
                    <div className='flex items-center justify-center h-11 mr-4'>
                        <p className='text-[16px] text-[#2B6CB0]'>{t('general.username')}：ＯＯＯ</p>
                    </div>
                    {/*查詢按鈕*/}
                    <div className="source flex flex-row items-center justify-start mr-4">
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleQueryButtonOnClick}>
                            {t('general.query')}
                        </button>
                    </div>
                </div>
            </div>
            <SwitchContainer patientId={patientId}
                             data={data}
                             totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                             totalSize={totalSize}
                             onPageOnChange={onPaginationPageOnChange}
                             onPreviousOnClick={onPaginationPreviousOnClick}
                             onNextOnClick={onPaginationNextOnClick}
                             onPageSizeChange={onPaginationPageSizeOnChange}
            />
        </div>
    );
}
export default Cloudenc;
