import { BaseInput } from "../../components/Input/BaseInput"
import { useState } from "react"
import {
    InputTypeEnum,
    arrayIsEmpty
} from "edah_utils/dist"
import {t} from 'i18next'
import {ReactComponent as AddBtnIcon} from "../../assets/images/icons/add_circle.svg"
import Pagination from "../../components/Notifications/Pagination/Pagination"

const DoctorInformSearch = () => {
    // 申請人工號
    const [createUser, setCreateUser] = useState('')
    // 醫師工號
    const [supportUserNo, setSupportUserNo] = useState('')
    // 申請起始日期
    const [applyStartDate, setApplyStartDate] = useState('')
    // 申請結束日期
    const [applyEndDate, setApplyEndDate] = useState('')
    // 進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("")
    // 查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null)
    // 進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    
    /**
     * 取得輸入框樣式
     * @param {number} width - 寬度
     * @return {string} - 輸入框樣式
     */
    const getInputClassName = (width = 186) => `w-[${width}px] h-10 bg-white text-black rounded-md border border-gray-300`

    /**
     * 申請人/工號方框改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleCreateUserOnChange = (e) => setCreateUser(e.target.value)

    /**
     * 報備支援醫師/工號方框改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSupportUserNoOnChange = (e) => {setSupportUserNo(e.target.value)}

    /**
     * 報備起始日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleApplyStartDateOnChange = (e) => setApplyStartDate(e.target.value)

    /**
     * 報備結束日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleApplyEndDateOnChange = (e) => setApplyEndDate(e.target.value)

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => {

    }

    /**
     * 進階搜尋文字改變時
     * @param {Object} e
     */
    const handleAdvanceSearchTextOnChange = (e) => setAdvSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
    }

    return (
        <div className="w-full overflow-auto h-[calc(100vh-110px)] max-h-[calc(100vh-110px)] p-2">
            {/* 查詢區塊 */}
            <div className='flex flex-col'>
                {/* 第一列查詢條件 */}
                <div className='flex items-center p-2 gap-6'>
                    <div className="flex flex-row space-x-2 items-center">
                        <div>{`${t('Regs.DoctorInformSearch.applicant')}/${t('Regs.DoctorInformSearch.jobNum')}：`}</div>
                        <BaseInput 
                            className={getInputClassName()} inputMode={InputTypeEnum.Search}
                            type={InputTypeEnum.Text} value={createUser}
                            onChange={handleCreateUserOnChange}/>
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                        <div>{`${t('Regs.DoctorInformSearch.supportDoc')}/${t('Regs.DoctorInformSearch.jobNum')}：`}</div>
                        <BaseInput 
                            className={getInputClassName()} inputMode={InputTypeEnum.Search}
                            type={InputTypeEnum.Text} value={supportUserNo}
                            onChange={handleSupportUserNoOnChange}/>
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                        <div>{`${t('Regs.DoctorInformSearch.byStatus')}：`}</div>
                        <select
                            className="w-[120px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300">

                        </select>
                    </div>
                    <div className="flex items-center gap-1">
                        {/*依申請期間*/}
                        <div className='flex items-center gap-1'>
                            <div>{`${t('Regs.DoctorInformSearch.accordingApplyPeriod')}：`}</div>
                            {/*申請起始日期*/}
                            <BaseInput className={`${getInputClassName(166)} pl-4`}
                                       type={InputTypeEnum.Date} value={applyStartDate} inputMode={InputTypeEnum.Date}
                                       onChange={handleApplyStartDateOnChange}/>
                            ～
                            {/*申請結束日期*/}
                            <BaseInput className={`${getInputClassName(166)} pl-4`}
                                       type={InputTypeEnum.Date} value={applyEndDate} inputMode={InputTypeEnum.Date}
                                       onChange={handleApplyEndDateOnChange}/>
                        </div>
                    </div>
                    {/*查詢按鈕*/}
                    <div className="source flex flex-row items-center justify-start mr-4">
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleQueryOnClick}>
                            {t('general.query')}
                        </button>
                    </div>
                </div>
                {/* 第二列新增按鈕 */}
                <div className="ml-4 mt-2 h-10">
                    {/*新增按鈕*/}
                    <button className="flex text-green-600 text-lg items-center">
                        <AddBtnIcon className="w-[33px] h-[33px]"/>
                        <div className="ml-1">{t('Regs.DoctorInformSearch.addContent')}</div>
                    </button>
                </div>
            </div>
            {/*查詢結果*/}
            <div className="flex flex-col p-2 mt-3 bg-white h-[calc(100%_-_120px)] w-full border-[1px] border-[#D4D4D8] rounded-md">
                <div className="flex flex-row pb-3">
                    {/*進階搜尋*/}
                    <BaseInput 
                        className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                        type="text" 
                        placeholder={t('general.advancedSearch')} 
                        inputMode={'search'}
                        value={advSearchText}
                        onChange={(e) => handleAdvanceSearchTextOnChange(e)}/>
                </div>
                <ul className="tableList doctorInformList overflow-y-auto h-[calc(100%-40px)]">
                    <li className="title">
                        <div>操作</div>
                        <div>申請單號</div>
                        <div>申請人</div>
                        <div>支援醫師代號/工號姓名</div>
                        <div>支援機構<br/>代號名稱</div>
                        <div>支援科別</div>
                        <div>開始日期</div>
                        <div>結束日期</div>
                        <div>開始時間</div>
                        <div>結束時間</div>
                        <div>狀態別</div>
                        <div>衛福部案號版次</div>
                    </li>
                    {
                        !arrayIsEmpty(advSearchDataList) && advSearchDataList.map((data, index) => (
                            <li key={index}>
                                {/*操作*/}
                                <div className="flex">
                                    
                                </div>
                                {/*申請單號*/}
                                <div></div>
                                {/*申請人*/}
                                <div></div>
                                {/*支援醫師代號/工號姓名*/}
                                <div></div>
                                {/*支援機構 代號名稱*/}
                                <div></div>
                                {/*支援科別*/}
                                <div></div>
                                {/*開始日期*/}
                                <div></div>
                                {/*結束日期*/}
                                <div></div>
                                {/*開始時間*/}
                                <div></div>
                                {/*結束時間*/}
                                <div></div>
                                {/*狀態別*/}
                                <div></div>
                                {/*衛福部案號版次*/}
                                <div></div>
                            </li>
                        ))
                    }
                </ul>

                {/*分頁*/}
                <div className="flex justify-end w-full mt-1.5">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        currentPage={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page) => onPaginationPageOnChange(page)}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default DoctorInformSearch