import {getMonthName} from "./CalendarHelper"
import Month from "./Month"
import {arrayIsEmpty} from "edah_utils/dist"

/**
 * 年曆
 * @param {Array[Array<Object>]} yearData 年資料
 * @param {number} year 給定的年份
 * @param {Boolean} disable 失效狀態
 * @param {Array<Object} holidayKindArray 假日類別資料
 * @param {String} selectedHolidayType 選擇的假日類別
 * @param onDateClick {function} 點擊日期的callback
 * @return {JSX.Element}
 */
const CalendarYearly = ({yearData, year, disable, onDateClick, holidayKindArray, selectedHolidayType}) => {
    return (
        <div className="text-center">
            <div className="w-full grid grid-cols-4 gap-5">
                {
                    !arrayIsEmpty(yearData) && yearData.map((monthData, index) =>
                        <div className="h-[280px] align-top" key={(index)}>
                            <div>
                                {/**月份文字 */}
                                <h3 className="p-1.5 border border-[#D7D7D7] bg-[#E9E9E9] text-base text-[#18181B]">
                                    {`${getMonthName(index)} ${year}`}
                                </h3>
                                {/**日曆(月) */}
                                <Month year={year} month={index} disable={disable} monthData={monthData} holidayKindArray={holidayKindArray} selectedHolidayType={selectedHolidayType}
                                       onDateClick={onDateClick}/>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default CalendarYearly;
