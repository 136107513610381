import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import DischargeSummarySearchBar from '../../components/ReferralSystem/DischargeSummary/DischargeSummarySearchBar'
import { DISCHARGE_SUMMARY_FIELDS } from '../../constants/referralSystem/dischargeSummary'
import { Pagination } from '../../components/Pagination/Pagination'
import {
    addApplyDischargenote,
    queryApplyDischargenoteApply,
} from '../../api/v1/Rmis'
import {
    ToastTypeEnum,
    ApiErrorStatusEnum,
    time2String,
    arrayIsEmpty,
    getLocalStorage,
} from 'edah_utils/dist'
import { DATETIME_FORMAT } from '../../constants/common'
import useToast from '../../hooks/useToast'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import { useSelector } from 'react-redux'
import { FORM_TYPE } from '../../constants/referralSystem/print'
import store from '../../redux/store'

/**
 * 出院病摘_申請/列印
 * @returns {JSX.Element}
 */
const ApplyDischargeSummary = () => {
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 查詢條件
    const [searchParams, setSearchParams] = useState({
        // 出院日期(起)
        dischargeStartDate: '',
        // 出院日期(迄)
        dischargeEndDate: '',
        // 身分證號
        idNo: '',
        // 病歷號
        patientId: '',
        // 選項: 核准/未核准
        aprvStatus: '',
    })

    // toast message
    const showToast = useToast()

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const handleQueryOnClick = () => {
        fetchDischargeSummaryList(1, filter.pageSize)
    }

    const fetchDischargeSummaryList = (pageNum, pageSize) => {
        queryApplyDischargenoteApply({
            ...searchParams,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (arrayIsEmpty(res.data)) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                const list = arrayIsEmpty(res.data)
                    ? []
                    : res.data.map((item, index) => {
                          return {
                              ...item,
                              isSelected: false,
                              originIndex: index,
                          }
                      })
                setDataList(list)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 更新查詢條件
     * @param {object} searchParams
     */
    const searchParamsOnChange = (searchParams) => {
        setSearchParams({
            dischargeStartDate: time2String(
                searchParams.startDate,
                DATETIME_FORMAT
            ),
            dischargeEndDate: time2String(
                searchParams.endDate,
                DATETIME_FORMAT
            ),
            idNo: searchParams.idNo,
            patientId: searchParams.patientId,
            aprvStatus: searchParams.aprvStatus,
        })
    }

    /**
     * 點選頁碼
     * @param {number} page
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        fetchDischargeSummaryList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {event}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        fetchDischargeSummaryList(filter.pageNum, pageSize)
    }

    /**
     * 上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        fetchDischargeSummaryList(page, filter.pageSize)
    }

    /**
     * 下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        fetchDischargeSummaryList(page, filter.pageSize)
    }

    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.originIndex === item.originIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 出院病摘申請
     */
    const handleApply = () => {
        const userNo = getLocalStorage('userno')
        const selectedDataList = dataList.filter((item) => item.isSelected)
        if (arrayIsEmpty(selectedDataList)) {
            return
        }
        const applyDate = time2String(new Date(), 'yyyy-MM-DD HH:mm:ss')
        const rmisApplyDischargenoteData = selectedDataList.map((item) => {
            return {
                encounterId: item.encounterID,
                applyDate,
                aprvUserNo: userNo,
            }
        })

        addApplyDischargenote({
            rmisApplyDischargenoteData,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '申請成功', type: ToastTypeEnum.Success })
                fetchDischargeSummaryList(filter.pageNum, filter.pageSize)
            } else {
                showToast({
                    message: `申請失敗: ${res.msg}`,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 出院病摘列印
     */
    const handlePrintList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.dischargeSummary,
                printData,
            })
        )
    }

    // 標籤列印
    const handlePrintLabelList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.dischargeSummaryLabel,
                printData,
            })
        )
    }

    // 信封列印
    const handlePrintEnvelopeCover = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.dischargeSummaryEnvelope,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex justify-between items-start p-2">
                    <DischargeSummarySearchBar
                        isApplyPage={true}
                        handleQuery={handleQueryOnClick}
                        searchParamsOnChange={searchParamsOnChange}
                    />
                    <div>
                        <div className="flex mb-1">
                            <button
                                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                onClick={handleApply}
                            >
                                出院病摘申請
                            </button>
                            <button
                                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                onClick={handlePrintEnvelopeCover}
                            >
                                信封列印
                            </button>
                        </div>
                        <div className="flex">
                            <button
                                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                onClick={handlePrintList}
                            >
                                出院病摘列印
                            </button>
                            <button
                                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                                onClick={handlePrintLabelList}
                            >
                                標籤列印
                            </button>
                        </div>
                    </div>
                </div>
                <CustomTable
                    isSelectable={true}
                    fields={DISCHARGE_SUMMARY_FIELDS}
                    dataList={dataList}
                    toggleAll={toggleAll}
                    toggleItem={toggleItem}
                />
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.totalItemSize}
                        currentPage={filter.pageNum}
                        totalPageSize={filter.totalPageSize}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}

export default ApplyDischargeSummary
