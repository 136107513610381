import React, {useEffect, useMemo, useState, useRef} from "react"
import {DefaultButton} from "../../components/Button/DefaultButton"
import ListCard from "../../components/Regs/WeekMaintenance/ListCard"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    RegsWeekModeEnum,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    TimeslotEnum,
    PopupModeEnum,
    ToastTypeEnum 
} from "edah_utils/dist"
import {
    regsWeekQueryBaseData,
    regsWeekQueryByDivNo,
    regsWeekQueryByDoctorNo,
} from "../../api/v1/Regs"
import {AddWeekMaintenancePopup} from "../../components/Regs/WeekMaintenance/Popup/AddWeekMaintenancePopup"
import useToast from "../../hooks/useToast"
import useOutsideClick from "../../hooks/useOutsideClick"

const WeekMaintenance = () => {
    // 預設周邊要插入資料
    const defaultWeekData = Array.from({length: 7}, (_, i) => i + 1).map((index) => {
            return {
                //星期幾的數值
                weekIndex: index,
                //星期幾
                weekName: t(`general.dateTime.weekRange.${index}`),
                // 早上
                morning: [],
                // 中午
                afternoon: [],
                // 晚上
                night: [],
            }
        }
    )

    // 切換科別或醫生
    const [filterMode, setFilterMode] = useState(RegsWeekModeEnum.Department)
    // 當前選擇的科別或醫生索引
    // 是否顯示下拉選單
    const [showDoctorOrDeptOptions, setShowDoctorOrDeptOptions] = useState(false)
    // 週班中查詢的相關參數初始值
    const [baseData, setBaseData] = useState(null)
    // 所有 醫師 list or 科別 list
    const [allDoctorOrDept, setAllDoctorOrDept] = useState(null)
    // 醫師 或 科別的下拉選單
    const [doctorOrDeptOptionList, setDoctorOrDeptOptionList] = useState(null)
    // 給予ListCard選項使用
    const [listCardOptions, setListCardOptions] = useState(null)
    // 取得週班資料
    const [weekData, setWeekData] = useState(defaultWeekData)
    // 搜尋文字
    const [searchText, setSearchText] = useState("")
    // 避免API重複執行
    const [lock, setLock] = useState(false)
    //是否可以顯示新增週班視窗
    const [canShowAddWeekMaintenancePopup, setCanShowAddWeekMaintenancePopup] = useState(false)
    //第一次進來取得週班資料的flag
    const [hasFindWeekDataInInitial, setHasFindWeekDataInInitial] = useState(false)
    //當前右鍵點擊的區塊
    const [currentRightClick, setCurrentRightClick] = useState({
        week: null,
        timeslot: null,
    })
    // Popup模式
    const [popupMode, setPopupMode] = useState(null)
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向醫師或科別下拉菜單元素
    const dropdownDoctorOrDeptRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorOrDeptRef,
        handler: () => setShowDoctorOrDeptOptions(false),
    });

    /**
     * 下拉選單焦點事件
     */
    const handleInputDoctorOrDeptOnFocus = () => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
        setShowDoctorOrDeptOptions(true)
    }

     /**
     * 隱藏下拉選單
     */
     const handleToggleFilterBlurOptions = () => setTimeout(() => setShowDoctorOrDeptOptions(false), 150)

     /**
      * 設定下拉選單選項
      * @param {Array} list 醫師或科別列表
      * @param {RegsWeekModeEnum} mode 醫師或科別模式
      */
     const setDropDownOptions = (list, mode) => {
        //添加ALL選項設定到開頭
        let updatedList = []
        if(mode === RegsWeekModeEnum.Department) {
            updatedList = [{divNo: "ALL", divName: "ALL"}, ...list]
        }
        else {
            updatedList = [{userNo: "ALL", userName: "ALL"}, ...list]
        }
        
        setAllDoctorOrDept(updatedList)
     }
    /**
     * 模式改變時
     * @param mode {Number}
     */
    const handleModeOnChange = (mode) => {
        //設定模式
        setFilterMode(mode)

        if (mode === RegsWeekModeEnum.Department) {
            //將科別設定在下拉選單
            setDropDownOptions(baseData.divisionList, mode)
            //將醫生給予ListCard選項
            setListCardOptions(baseData.doctorList)
        } else {
            //將醫生設定在下拉選單
            setDropDownOptions(baseData.doctorList, mode)
            //將科別給予ListCard選項
            setListCardOptions(baseData.divisionList)
        }
        //將週班資料回復預設值
        setWeekData(defaultWeekData)
        //清空搜尋文字
        setSearchText('')
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        //重新查詢所有班表
        handleGetWeekData('', mode)
    }

    /**
     * 搜尋條件改變時
     * @event event {Event}
     */
    const handleSearchOnInput = (event) => {
        //搜尋文字存在
        if (event.target.value) {
            setSearchText(event.target.value)
        } else { //搜尋文字不存在
            setSearchText('')
        }
    }

    /**
     * 取得到搜尋的科別或醫生選項
     * @return {Array<Object>}
     */
    const getDoctorOrDeptOptions = () => {
        //取得搜尋文字
        const search = searchText
        if(arrayIsEmpty(allDoctorOrDept)) {
            return []
        }
        //取得科別或醫生選項
        return allDoctorOrDept.filter((item) => {
            //取得科別或醫生名稱
            const name = filterMode === RegsWeekModeEnum.Department ? item.divName : item.userName
            //取得科別或醫生編號
            const no = filterMode === RegsWeekModeEnum.Department ? item.divNo : item.userNo
            //如果搜尋文字存在
            if (search) {
                //搜尋文字存在空白代表使用者用選擇的
                return search.indexOf(' ') >= 0 ?
                    name.includes(search.split(' ')[1]) && no.includes(search.split(' ')[0]) :
                    name.includes(search) || no.includes(search)
            } else { //如果搜尋文字不存在
                //回傳全部
                return true
            }
        })
    }

    /**
     * 取得週班資料
     * @param {Stinrg} filterText 查詢的醫師或科別
     * @param {*} mode 查詢模式
     * @returns 
     */
    const handleGetWeekData = (filterText, mode) => {
        const splitArray = filterText ? filterText.split(' ') : []
        if (!((filterText && splitArray.length === 2) || filterText === "") ) {
            return
        }
        parseWeekData(null)

        //是否科別模式
        const isDepartmentMode = mode === RegsWeekModeEnum.Department
        //編號
        const no = filterText === "" ? "" : splitArray[0]
        //當前模式為科別
        if (isDepartmentMode) {
            regsWeekQueryByDivNo({divNo: no}).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    parseWeekData(res.data)
                }
            })
        } else {
            regsWeekQueryByDoctorNo({doctorNo: no}).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    parseWeekData(res.data)
                }
            })
        }
    }

    /**
     * 將資料還原成週班資料
     * @param result {Array<Object>}
     */
    const parseWeekData = (result) => {
        //如果沒有資料的話
        if (arrayIsEmpty(result)) {
            //將週班資料回復預設值
            setWeekData(defaultWeekData)
        } else { //如果有資料的話
            //先複製預設週班資料
            let tempWeekData = [...defaultWeekData]
            result.forEach(item => {
                //取得星期幾
                const week = parseInt(item.week)
                //取得時段
                const apn = item.apn
                //根據時段去設定週班資料
                switch (apn) {
                    case TimeslotEnum.Morning:
                        tempWeekData[week - 1].morning = [...item.regsWeekList]
                        break
                    case TimeslotEnum.Afternoon:
                        tempWeekData[week - 1].afternoon.push(...item.regsWeekList)
                        break
                    case TimeslotEnum.Night:
                        tempWeekData[week - 1].night.push(...item.regsWeekList)
                        break
                }
            })
            //設定週班資料
            setWeekData(tempWeekData)
        }
    }

    /**
     * 下拉選單值改變時
     * @param option {Object}
     */
    const handleDropdownOnChange = (option) => {
        
        let text = ''
        if(filterMode === RegsWeekModeEnum.Department) {
            text = option.divNo === "ALL" ? '' : `${option.divNo} ${option.divName}`
        }
        else {
            text = option.userNo === "ALL" ? '' : `${option.userNo} ${option.userName}`
        }
        //關閉下拉選單
        setShowDoctorOrDeptOptions(false)
        //設定搜尋文字
        setSearchText(text)
        //取的週班資料
        handleGetWeekData(text, filterMode)
    }

    /**
     * 新增新問診時
     * @param week {Number} 週數
     * @param timeslot {Number} 時段
     */
    const handleOnAddNewAppointment = (week, timeslot) => setCurrentRightClick({week: week, timeslot: timeslot})

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {Number}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode)
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
                //設定日期
                //date: getDateOfWeek(currentRightClick.week),
                //當前選擇醫生
                doctor: filterMode === RegsWeekModeEnum.Doctor ? (searchText ? searchText : null) : null,
                //當前選擇科別
                department: filterMode === RegsWeekModeEnum.Department ? (searchText ? searchText : null) : null
            }

        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setCanShowAddWeekMaintenancePopup(true)
    }

    /**
     * 關閉Popup
     * @return void
     */
    const handleCloseAddWeekMaintenancePopup = () => setCanShowAddWeekMaintenancePopup(false)

    /**
     * 新增成功事件
     */
    const handleCreateSuccess = (msg) => {
        //顯示toast
        showToast({message:`${msg} 新增成功`, type:ToastTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    /**
     * 儲存成功事件
     */
    const handleSaveSuccess = (msg) => {
        //關閉popup
        setCanShowAddWeekMaintenancePopup(false)
        //顯示toast
        showToast({message:`${msg} 儲存成功`, type:ToastTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    /**
     * 刪除成功事件
     */
    const handleDeleteSuccess = (msg) => {
        //關閉popup
        setCanShowAddWeekMaintenancePopup(false)
        //顯示toast
        showToast({message:`${msg} 刪除成功`, type:ToastTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    const getOptionDisplay = (option) => {
        // 當前模式為科別
        let text = ''
        if(filterMode === RegsWeekModeEnum.Department) {
            text = option.divNo === "ALL" ? option.divName : `${option.divNo} ${option.divName}`
        }
        else {
            text = option.userNo === "ALL" ? option.userName : `${option.userNo} ${option.userName}`
        }

        return text
    }

    //第一次執行時先取得到資料
    useMemo(() => {
        //取得週班中查詢的相關參數初始值
        regsWeekQueryBaseData({}).then((res) => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success && baseData === null) {
                //取得資料
                const data = res.data
                //設定資料
                setBaseData(data)
                //預設將科別設定在下拉選單
                setDropDownOptions(data.divisionList, RegsWeekModeEnum.Department)
                //預設將醫生給予ListCard選項
                setListCardOptions(data.doctorList)
            }
        })
    }, [])

    /**
     * 監聽allDoctorOrDept & listCardOptions
     */
    useEffect(() => {
        if(!arrayIsEmpty(allDoctorOrDept) && !arrayIsEmpty(listCardOptions) && !hasFindWeekDataInInitial) {
            //確保醫師跟科別資料都有才能去取得第一次的週班資料
            handleGetWeekData(searchText, filterMode)
            setHasFindWeekDataInInitial(true)
        }
    }, [allDoctorOrDept, listCardOptions])

    /**
     * 監聽搜尋文字改變時
     */
    useEffect(() => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
    }, [searchText])

    return (
        <div className="w-full overflow-x-scroll">
            {/*頂部區塊*/}
            <div className="w-full min-w-[1688px] flex flex-row items-center space-x-8 px-[26px] py-2">
                <div className="flex flex-row justify-between space-x-6 items-center w-full">
                    <div className="flex flex-row">
                        <div className="flex flex-row space-x-4 items-center mr-4">
                            <input
                                id="departmentRadio"
                                name="modeRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                checked={filterMode === RegsWeekModeEnum.Department}
                                type="radio"
                                onChange={() => handleModeOnChange(RegsWeekModeEnum.Department)}
                                style={{cursor: "pointer"}}
                            />
                            <label htmlFor="departmentRadio" style={{cursor: "pointer"}}>
                                {t('general.department')}
                            </label>
                            <input
                                id="doctorRadio"
                                name="modeRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                checked={filterMode === RegsWeekModeEnum.Doctor}
                                type="radio"
                                onChange={() => handleModeOnChange(RegsWeekModeEnum.Doctor)}
                                style={{cursor: "pointer"}}
                            />
                            <label htmlFor="doctorRadio" style={{cursor: "pointer"}}>
                                {t("general.doctor")}
                            </label>
                        </div>
                        <div className="flex flex-row items-center relative w-[180px]" ref={dropdownDoctorOrDeptRef}>
                            <input
                                className={`bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat`}
                                type="text" value={searchText}
                                onChange={(e) => handleSearchOnInput(e)}
                                onFocus={handleInputDoctorOrDeptOnFocus}/>
                            {showDoctorOrDeptOptions && !arrayIsEmpty(doctorOrDeptOptionList) && (
                                /*搜尋或下拉選單*/
                                <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {doctorOrDeptOptionList.map((option, index) => (
                                            <div
                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                key={index}
                                                role="button"
                                                onClick={() => handleDropdownOnChange(option)}
                                            >
                                                {
                                                    getOptionDisplay(option)
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/*周班Content*/}
            <div className="flex flex-col">
                {/** 區塊 - 日期 */}
                <div className="w-full py-1 pl-[72px] pr-[16px]">
                    <div className="w-full min-w-[1457px] flex flex-row gap-2">
                        {weekData.map((week, index) => (
                            <div className="w-full min-w-[220px] flex justify-center" key={index}>
                                <p>{week.weekName}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {/** 區塊 - 早 */}
                <div className="w-full min-w-[1688px] h-[208px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.morning")}
                            </p>
                            <p className="w-12 text-center">08:30</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">12:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Morning}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["morning"]}
                                data={week.morning}
                                isAddAppointment={currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Morning}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}/>
                        ))
                    }
                    <div className="h-[208px] flex items-center">
                        <div
                            className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                    </div>
                </div>
                {/** 區塊 - 中 */}
                <div className="w-full min-w-[1688px] h-[208px] bg-customYellow flex flex-row gap-2 px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.afternoon")}
                            </p>
                            <p className="w-12 text-center">13:30</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">17:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Afternoon}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["afternoon"]}
                                data={week.afternoon}
                                isAddAppointment={currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Afternoon}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}/>
                        ))
                    }
                    <div className="h-[208px] flex items-center">
                        <div
                            className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                    </div>
                </div>
                {/** 區塊 - 晚 */}
                <div className="w-full min-w-[1688px] h-[208px] bg-[#FBF5FF] flex flex-row gap-2 px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.night")}
                            </p>
                            <p className="w-12 text-center">18:00</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">21:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Night}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["night"]}
                                data={week.night}
                                isAddAppointment={currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Night}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}/>
                        ))
                    }
                    <div className="h-[208px] flex items-center">
                        <div className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                        </div>
                    </div>
                </div>
            </div>
            {canShowAddWeekMaintenancePopup &&
                // 新問診Popup
                <AddWeekMaintenancePopup 
                    filterMode={filterMode}
                    mode={popupMode}
                    closePopup={handleCloseAddWeekMaintenancePopup}
                    baseData={baseData}
                    data={selectedData}
                    handleCreateSuccess={handleCreateSuccess}
                    handleSaveSuccess={handleSaveSuccess}
                    handleDeleteSuccess={handleDeleteSuccess}/>
            }
        </div>
    )
}

export default WeekMaintenance
