import {
    InputTypeEnum,
    time2String,
    stringIsEmpty,
    validDateFormat,
} from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'

/**
 * 權限設定 table header
 */
export const PERMISSION_SETTING_HEADER = [
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospName',
        name: '院所名稱',
        className: 'text-sm',
        validation: (value) => !stringIsEmpty(value),
    },
    {
        key: 'applicantDate',
        name: '申請日期',
        className: 'text-sm w-[120px]',
        inputType: InputTypeEnum.Date,
        format: (value) => time2String(value, DATE_FORMAT),
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospAccount',
        name: '帳號',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospPasswd',
        name: '密碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'applicantName',
        name: '聯絡人',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'applicantEmail',
        name: '聯絡人信箱',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
    },
]

export const REFERRAL_CODE_MAINTAIN_HEADER = [
    {
        key: 'codeType',
        name: '類別代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'codeTypeName',
        name: '類別名稱',
        className: 'text-sm',
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'codeNo',
        name: '項目代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'codeNoName',
        name: '項目名稱',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'uploadCodeNo',
        name: '上傳碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
    },
    {
        key: 'effectiveDate',
        name: '開始日期',
        className: 'text-sm',
        inputType: InputTypeEnum.Date,
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'endDate',
        name: '結束時間',
        className: 'text-sm',
        inputType: InputTypeEnum.Date,
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'sortBy',
        name: '排序號碼',
        inputType: InputTypeEnum.Text,
        className: 'text-sm',
    },
]

/**
 * 轉診急救責任醫院
 */
export const EMERGENCY_HOSPITAL_MAINTAIN_HEADER = [
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospName',
        name: '院所名稱',
        className: 'text-sm',
    },
    {
        key: 'aidGradeText',
        name: '等級',
        className: 'text-sm',
        inputType: 'select',
        mappingKey: 'aidGrade',
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
        options: [
            {
                name: '',
                value: '',
            },
            {
                name: 'A 重度',
                value: 'A',
            },
            {
                name: 'B 中度',
                value: 'B',
            },
            {
                name: 'C 一般',
                value: 'C',
            },
        ],
    },
    {
        key: 'branchHospName',
        name: '分院名稱',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
    },
    {
        key: 'effDate',
        name: '開始日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
        inputType: InputTypeEnum.Date,
        validation: (value) => (value ? validDateFormat(value) : true),
    },
    {
        key: 'endDate',
        name: '結束時間',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
        inputType: InputTypeEnum.Date,
        validation: (value) => (value ? validDateFormat(value) : true),
    },
    {
        key: 'sortBy',
        name: '排序號碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
    },
]

/**
 * 策略聯盟維護
 */
export const STRATEGIC_ALLIANCE_MAINTAIN = [
    {
        key: 'action',
        name: '操作',
        className: 'text-sm w-[85px]',
    },
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
    },
    {
        key: 'hospName',
        name: '院所名稱',
        className: 'text-sm',
    },
    {
        key: 'hospGrade',
        name: '等級',
        className: 'text-sm',
    },
    {
        key: 'hospZipCode',
        name: '郵遞區號',
        className: 'text-sm',
    },
    {
        key: 'federalFlag',
        name: '策略聯盟',
        className: 'text-sm',
    },
    {
        key: 'communityFlag',
        name: '醫療群',
        className: 'text-sm',
    },
    {
        key: 'supportFlag',
        name: '支援院所',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv01Name',
        name: '科別 1',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv02Name',
        name: '科別 2',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv03Name',
        name: '科別 3',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv04Name',
        name: '科別 4',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv05Name',
        name: '科別 5',
        className: 'text-sm',
    },
    {
        key: 'effectDate',
        name: '開始日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'endDate',
        name: '結束時間',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'sortBy',
        name: '排序號碼',
        className: 'text-sm',
    },
]

export const LONG_TERM_CARE_CENTER = [
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospName',
        name: '長期照護單位名稱',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospZipCode',
        name: '郵遞區號',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospAddress',
        name: '地址',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
    {
        key: 'hospTel',
        name: '電話',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        validation: (value) => !stringIsEmpty(value),
        errorMsg: '必填',
    },
]

/**
 * 電子轉診清單 table header
 */
export const REFERRAL_LIST_MODAL = [
    {
        key: 'suggestEncounterDivName',
        name: '建議轉診科別',
        className: 'text-sm',
    },
    {
        key: 'hospName',
        name: '開單院所',
        className: 'text-sm',
    },
    {
        key: 'tranPurposeRemark',
        name: '轉診目的',
        className: 'text-sm',
    },
    {
        key: 'effDate',
        name: '有效起日',
        className: 'text-sm',
    },
    {
        key: 'effEndDate',
        name: '有效迄日',
        className: 'text-sm',
    },
    {
        key: 'etranNo',
        name: '電子轉單號碼',
        className: 'text-sm',
    },
]

/**
 * 轉診回診清單
 */
export const RETURN_APPOINTMENT = [
    {
        key: 'encounterDate',
        name: '就醫日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'effDate',
        name: '有效期限',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'hospNoIn',
        name: '轉入院所代碼',
        className: 'text-sm w-[150px]',
    },
    {
        key: 'divName',
        name: '就醫科別',
        className: 'text-sm',
    },
    {
        key: 'doctorName',
        name: '醫師',
        className: 'text-sm w-[100px]',
    },
    {
        key: 'treatmentCode',
        name: '主身份',
        className: 'text-sm',
    },
    {
        key: 'treatmentCode1',
        name: '診斷1',
        className: 'text-sm',
    },
    {
        key: 'treatmentCode2',
        name: '診斷2',
        className: 'text-sm',
    },
    {
        key: 'treatmentCode3',
        name: '診斷3',
        className: 'text-sm',
    },
    {
        key: 'treatmentCode4',
        name: '診斷4',
        className: 'text-sm',
    },
    {
        key: 'treatmentCode5',
        name: '診斷5',
        className: 'text-sm',
    },
]

export const RETURN_HOSPITAL = [
    {
        key: 'custom',
        name: '自訂',
        className: 'text-sm',
    },
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
    },
    {
        key: 'hospName',
        name: '院所名稱',
        className: 'text-sm',
    },
    {
        key: 'hospAddress',
        name: '院所地址',
        className: 'text-sm w-[150px]',
    },
    {
        key: 'federalFlag',
        name: '策略聯盟',
        className: 'text-sm',
    },
    {
        key: 'communityFlag',
        name: '社區醫療',
        className: 'text-sm',
    },
    {
        key: 'supportFlag',
        name: '支援院所',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv01Name',
        name: '推薦科別1',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv02Name',
        name: '推薦科別2',
        className: 'text-sm',
    },
    {
        key: 'suggestDiv03Name',
        name: '推薦科別3',
        className: 'text-sm',
    },
    {
        key: 'postalName',
        name: '醫療群地區',
        className: 'text-sm',
    },
]

export const RURAL_HOSPITAL_FIEDLS = [
    {
        key: 'hospNo',
        name: '院所代碼',
        className: 'text-sm',
        inputType: InputTypeEnum.Text,
        errorMsg: '必填',
        validation: (value) => !stringIsEmpty(value),
    },
    {
        key: 'hospName',
        name: '離島偏鄉醫院名稱',
        className: 'text-sm',
    },
    {
        key: 'ruralTypeName',
        name: '離島偏鄉註記',
        className: 'text-sm min-w-[160px]',
        inputType: 'select',
        // 供CustomTable在編輯模式的時候，下拉選單所選的值可對應到正確的欄位
        mappingKey: 'ruralType',
        validation: (value) => !stringIsEmpty(value),
    },
    {
        key: 'deactiveDate',
        name: '停止日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
        inputType: InputTypeEnum.Date,
    },
    {
        key: 'sortBy',
        name: '排序',
        className: 'text-sm',
    },
]

export const REFERRAL_FORM_UPLOAD_ATTACHMENT = [
    {
        key: 'orderCodeDate',
        name: '醫囑日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'orderCodeName',
        name: '項目',
        className: 'text-sm',
    },
    {
        key: 'reportDate',
        name: '報告日期',
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
]
