import React, {useEffect, useState} from "react"
import {t} from "i18next"
import { BaseInput } from "../Input/BaseInput";
// import exportIcon from "../../assets/images/SystemMonitoring/export.svg";
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist"


const HomePotentialPopup = ({ 
    closePopup
}) => {
    //Table element 的預設type
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"
   
    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    /**
    * 搜尋文字改變時
    * @param e {Event} e
    * @return {void}
    */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
    * 取得模糊匹配後的資料陣列
    * @return {Array<Object>}
    */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

     // Fake Data
     const [dataObject, setDataObject] = useState([
        {
            viewDate:'2023-09-09',
            code:"L02.31",
            name:"Cutaneous"
        },
        {
            viewDate:'2023-09-09',
            code:"L02.31",
            name:"Other"
        }
    ]);

    return (
        <div
            className="fixed top-[-16px] left-0 flex items-center justify-center w-[100vw] h-dvh bg-[rgba(0,0,0,0.3)] z-10">
            <div className="w-[1058px] bg-white rounded-[6px]">
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">看診資料查詢</h3>
                    {/*關閉按鈕*/}
                    <button 
                        className="flex items-center justify-center h-10 text-[18px] text-[#2B6CB0]"
                        onClick={closePopup}
                    >
                        {t('general.close')}
                    </button>
                </div>

                <div className="flex flex-row text-left px-6 pt-3 text-[18px] border-[rgba(0,0,0,0.15)]">
                    <div className="flex justify-center items-center">
                        <span>就醫日期：</span>
                    </div>
                    {/* 就醫日期起 */}
                    <BaseInput type={InputTypeEnum.Date} inputMode={'date'} className="w-[166px] px-2 bg-white text-black rounded-[6px] border border-gray-300" type="date"/>
                    <div className="flex justify-center items-center mx-1">
                        <span>~</span>
                    </div>
                    {/* 就醫日期訖 */}
                    <BaseInput type={InputTypeEnum.Date} inputMode={'date'} className="w-[166px] px-2 bg-white text-black rounded-[6px] border border-gray-300" type="date"/>
                    
                    {/* 查詢btn */}
                    <button className=" ml-4 bg-[#2B6CB0] text-white rounded-[6px]  justify-center h-10 px-4 border-[2px]border-[#2B6CB0]">查詢</button>
                </div>
                <div>
                    {/*列表清單區塊*/}
                    <div className="maintainContainer flex flex-row items-start justify-start my-4 mx-5">
                        <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                            <div className="searchGroup flex flex-row items-center justify-between mb-4">
                                {/*進階搜尋*/}
                                <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                           type="text"
                                           placeholder={`${t('general.advancedSearch')}`}
                                           inputMode={'search'}
                                           value={searchText}
                                           onChange={(e) => handleSearchTextOnChange(e)}/>
                            </div>
            
                            {/*列表清單*/}
                            <ul className="w-full tableList HPPTableList">
                                {/*表單項目標題*/}
                                <li className="title">
                                    <div>看診日期</div>
                                    <div>診斷代碼</div>
                                    <div>診斷名稱</div>
                                </li>
            
                                {!arrayIsEmpty(getFilterData(dataObject)) ? getFilterData(dataObject).map((item, index) =>
                                    <li key={index}>
                                        {/* 看診日期 */}
                                        <div>{item.viewDate}</div>
                                        {/*診斷代碼*/}
                                        <div>{item.code}</div>
                                        {/*診斷名稱*/}
                                        <div>{item.name}</div>
                                        
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    )
}

export default HomePotentialPopup;