import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 查詢 table 中符合條件的資料
 * @param params {Object} params
 * @returns {AxiosPromise}
 */
const crudQueryTableData = (params) => v1Api.request({
    url: '/install/crud/query',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增或儲存 table 資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const crudSaveTableData = (data) => v1Api.request({
    url: '/install/crud/save',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除 table 資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const crudDelTableData = (data) => v1Api.request({
    url: '/install/crud/delete',
    method: HttpMethodEnum.DELETE,
    data
})

export {
    crudQueryTableData,
    crudSaveTableData,
    crudDelTableData
}