import React from "react";

/**
 * 基本資料 Tab Content
 * @param show {boolean} Show Info Content
 * @param isEditable {Boolean} control form editable
 * @return {JSX.Element}
 */
export const InfoContent = ({show, isEditable}) => {
    const [dataValues, setDataValues] = React.useState({})

    /**
     * 控制表單編輯樣式class
     * @return {JSX.Element}
     */
    const getMaintainInfoClass = () => {
        return `maintainInfo ${isEditable ? '' : 'disabled'}`;
    }

    return show ?
        <div>
            <ul  className={getMaintainInfoClass()}>
                <li>
                    <div>姓名</div>
                    <input type="text"
                           value={dataValues.name}
                           onChange={(e) => handleInputChange('name', e.target.value)}
                           disabled/>
                    <div>資料正確否</div>
                    <select name="" id=""
                            value={dataValues.dataCorrect}
                            onChange={(e) => handleInputChange('dataCorrect', e.target.value)}>
                        <option value="true">正確</option>
                        <option value="false">不正確</option>
                    </select>
                </li>
                <li>
                    <div>身分證號</div>
                    <input type="text"
                           value={dataValues.idNumber}
                           onChange={(e) => handleInputChange('idNumber', e.target.value)}/>
                    <div>出生日期</div>
                    <input type="date"
                           value={dataValues.birthDate}
                           onChange={(e) => handleInputChange('birthDate', e.target.value)}/>
                </li>
                <li>
                    <div>上傳資料型態</div>
                    <select name="" id=""
                            value={dataValues.medicalDataFormat}
                            onChange={(e) => handleInputChange('medicalDataFormat', e.target.value)}>
                        <option value="0">健保就醫</option>
                    </select>
                    <div>資料格式</div>
                    <select name="" id=""
                            value={dataValues.dataFormat}
                            onChange={(e) => handleInputChange('dataFormat', e.target.value)}>
                        <option value="0">D-整筆刪除</option>
                    </select>
                </li>
                <li>
                    <div>醫療院所代碼</div>
                    <input type="text"
                           value={dataValues.hospitalCode}
                           onChange={(e) => handleInputChange('hospitalCode', e.target.value)}/>
                    <div>安全模組</div>
                    <input type="text"
                           value={dataValues.securityModule}
                           onChange={(e) => handleInputChange('securityModule', e.target.value)}/>
                </li>
                <li>
                    <div>IC卡卡號</div>
                    <input type="text"
                           value={dataValues.cardNumber}
                           onChange={(e) => handleInputChange('cardNumber', e.target.value)}/>
                    <div>就醫序號</div>
                    <input type="text"
                           value={dataValues.medicalNumber}
                           onChange={(e) => handleInputChange('medicalNumber', e.target.value)}/>
                </li>
                <li>
                    <div>就診日期時間</div>
                    <input type="date"
                           value={dataValues.medicalDateTime}
                           onChange={(e) => handleInputChange('medicalDateTime', e.target.value)}/>
                    <div>註記</div>
                    <select name="" id=""
                            value={dataValues.note}
                            onChange={(e) => handleInputChange('note', e.target.value)}>
                        <option value="0">正常</option>
                    </select>
                </li>
                <li>
                    <div>就醫類別</div>
                    <input className="!w-[140px] mr-2.5" type="text"
                           value={dataValues.medicalType}
                           onChange={(e) => handleInputChange('medicalType', e.target.value)}/>
                    <input className="!w-[500px]" type="text"
                           value={dataValues.medicalDescription}
                           onChange={(e) => handleInputChange('medicalDescription', e.target.value)}
                           disabled/>
                </li>
                <li>
                    <div>寫卡錯誤碼</div>
                    <input className="!w-[140px] mr-2.5" type="text"
                           value={dataValues.errorCode1}
                           onChange={(e) => handleInputChange('errorCode1', e.target.value)}/>
                    <input className="!w-[500px]" type="text"
                           value={dataValues.errorCode2}
                           onChange={(e) => handleInputChange('errorCode2', e.target.value)}
                           disabled/>
                </li>
                <li>
                    <div>新生兒就醫註記</div>
                    <input type="text"
                           value={dataValues.newbornMedicalNote}
                           onChange={(e) => handleInputChange('newbornMedicalNote', e.target.value)}/>
                    <div>新生兒生日</div>
                    <input type="text"
                           value={dataValues.newbornBirthday}
                           onChange={(e) => handleInputChange('newbornBirthday', e.target.value)}/>
                </li>
                <li>
                    <div>新生兒胞胎數</div>
                    <input type="text"
                           value={dataValues.newbornSiblingCount}
                           onChange={(e) => handleInputChange('newbornSiblingCount', e.target.value)}/>
                    <div>被依附者非產婦<br/>之身分證</div>
                    <input type="text"
                           value={dataValues.dependentId}
                           onChange={(e) => handleInputChange('dependentId', e.target.value)}/>
                </li>
                <li>
                    <div>醫事人員身分證號</div>
                    <input type="text"
                           value={dataValues.medicalStaffId}
                           onChange={(e) => handleInputChange('medicalStaffId', e.target.value)}/>
                    <div>床位</div>
                    <input type="text"
                           value={dataValues.bedNumber}
                           onChange={(e) => handleInputChange('bedNumber', e.target.value)}/>
                </li>
                <li>
                    <div>疫苗種類</div>
                    <input type="text"
                           value={dataValues.vaccineType}
                           onChange={(e) => handleInputChange('vaccineType', e.target.value)}/>
                    <div>疫苗批號</div>
                    <input type="text"
                           value={dataValues.vaccineBatchNumber}
                           onChange={(e) => handleInputChange('vaccineBatchNumber', e.target.value)}/>
                </li>
                <li>
                    <div>大便卡</div>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.fecalCard1}
                           onChange={(e) => handleInputChange('fecalCard1', e.target.value)}/>
                    <input className="!w-[142px]" type="text"
                           value={dataValues.fecalCard2}
                           onChange={(e) => handleInputChange('fecalCard2', e.target.value)}/>
                    <div>新增批號</div>
                    <select name="" id=""
                            value={dataValues.newBatchNumber}
                            onChange={(e) => handleInputChange('newBatchNumber', e.target.value)}>
                        <option value="0">--</option>
                    </select>
                </li>
                <li>
                    <div>診斷碼</div>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.diagnosisCode1}
                           onChange={(e) => handleInputChange('diagnosisCode1', e.target.value)}/>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.diagnosisCode2}
                           onChange={(e) => handleInputChange('diagnosisCode2', e.target.value)}/>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.diagnosisCode3}
                           onChange={(e) => handleInputChange('diagnosisCode3', e.target.value)}/>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.diagnosisCode4}
                           onChange={(e) => handleInputChange('diagnosisCode4', e.target.value)}/>
                    <input className="!w-[86px] mr-2.5" type="text"
                           value={dataValues.diagnosisCode5}
                           onChange={(e) => handleInputChange('diagnosisCode5', e.target.value)}/>
                    <input className="!w-[86px]" type="text"
                           value={dataValues.diagnosisCode6}
                           onChange={(e) => handleInputChange('diagnosisCode6', e.target.value)}/>
                </li>
                <li>
                    <div>給付類別</div>
                    <input className="!w-[140px] mr-2.5" type="text"
                           value={dataValues.benefitCategory1}
                           onChange={(e) => handleInputChange('benefitCategory1', e.target.value)}/>
                    <input className="!w-[500px]" type="text"
                           value={dataValues.benefitCategory2}
                           onChange={(e) => handleInputChange('benefitCategory2', e.target.value)}
                           disabled/>
                </li>
                <li>
                    <div>就醫識別碼</div>
                    <input className="!w-[650px] mr-2.5" type="text"
                           value={dataValues.medicalId}
                           onChange={(e) => handleInputChange('medicalId', e.target.value)}/>
                </li>
                <li>
                    <div>安全簽章</div>
                    <input className="!w-[650px] mr-2.5" type="text"
                           value={dataValues.securitySignature}
                           onChange={(e) => handleInputChange('securitySignature', e.target.value)}
                           disabled/>
                </li>
                <li>
                    <div>過敏資料</div>
                    <input className="!w-[650px] mr-2.5" type="text"
                           value={dataValues.allergyData}
                           onChange={(e) => handleInputChange('allergyData', e.target.value)}/>
                </li>
            </ul>
        </div> : null
}
