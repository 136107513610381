import {useEffect, useMemo, useRef, useState} from "react"
import {BaseInput} from "../../components/Input/BaseInput"
import RadioButton from "../../components/Input/RadioButton"
import {SearchInput} from "../../components/Input/SearchInput"
import {DefaultButton} from "../../components/Button/DefaultButton"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty, downloadFile, exportExcelAsBlob,
    fuzzySearchObjects,
    InputTypeEnum,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    ToastTypeEnum
} from "edah_utils/dist"
import {t} from "i18next"
import {Pagination} from "../../components/Pagination/Pagination"
import useToast from "../../hooks/useToast"
import {queryAllDivision} from "../../api/v1/Menu"
import {regsQueryClinicTypeList, regsQueryReg, regsReportGetFile, regsReportPappt} from "../../api/v1/Regs";
import useOutsideClick from "../../hooks/useOutsideClick"
import searchIcon from "../../assets/images/search-interface-symbol.png"
import ExportIcon from "../../assets/images/SystemMonitoring/export.svg";
import PDFPreviewPopup from "../../components/Regs/Popup/PDFPreviewPopup";


const PrintOperation = () => {

    //時段列表
    const apnList = [
        {justnameNo: "1,2,3", justnameName: "全部"},
        {justnameNo: "1", justnameName: "早"},
        {justnameNo: "2", justnameName: "午"},
        {justnameNo: "3", justnameName: "晚"},
    ]

    //掛號別列表
    const reserveTypeList = [
        {justnameNo: "1,2,3,4,5,6,9", justnameName: "全部"},
        {justnameNo: "1", justnameName: "現掛"},
        {justnameNo: "2", justnameName: "預約"},
        {justnameNo: "3", justnameName: "電話"},
        {justnameNo: "4", justnameName: "診間"},
        {justnameNo: "5", justnameName: "網掛"},
        {justnameNo: "6", justnameName: "手機APP"},
        {justnameNo: "9", justnameName: "其他系統"},
    ]

    //列印方式列表
    const printTypeList = [
        {justnameNo: "0", justnameName: "列印"},
        {justnameNo: "1", justnameName: "預覽"},
    ]

    // 預設建立時間起迄:起-今日
    const today = new Date()
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
        //診別
        clinicType: '',
        //掛號別：1.現掛, 2.預約, 3.電話, 4.診間, 5.網掛, 6.手機APP, 9.其他系統
        reserveType: reserveTypeList[0].justnameNo,
        // 科別代碼
        departmentCode: '',
        //診室名稱
        clinicName: '',
        //病歷號
        patientId: '',
        //列印
        printType: printTypeList[0].justnameNo,
    }
    // 診間類別選項
    const [allClinicType, setAllClinicType] = useState(null)
    // 所有科別選項
    const [allDepartment, setAllDepartment] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    // 掛號資料
    const [registrationData, setRegistrationData] = useState(null)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState('')
    // 進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    // 被選取的資料
    const [selectedData, setSelectedData] = useState(null)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 列印勾選選項
    const [checkedValues, setCheckedValues] = useState(
        {
            registration: false,
            emergencyTag: false,
            emergencyTagWristband: false
        }
    )
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });
    //顯示PDF彈窗
    const [showPdfPopup, setShowPdfPopup] = useState(false)
    // PDF Blob
    const [pdfBlob, setPdfBlob] = useState(null)

    /**
     * 取得科別代碼下拉選項
     * @return {Array<Object>}
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode ? displayQueryInputData.departmentCode.split(" ") : []
        if (splitArray.length > 2) {
            return []
        }

        if (splitArray.length === 2) {
            return allDepartment.filter(doctor => doctor.divNo.includes(splitArray[0]) && doctor.divName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(allDepartment, displayQueryInputData.departmentCode)
        }
    }

    /**
     * 開始時間輸入欄位變更事件
     */
    const handleDateStartOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: e.target.value
        })
    }

    /**
     * 結束時間輸入欄位變更事件
     */
    const handleDateEndOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateEnd: e.target.value
        })
    }

    /**
     * 時段下拉選單選擇事件
     * @param {Event} e
     */
    const handleSelectApnOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: e.target.value
        })
    }


    /**
     * 診別下拉選單選擇事件
     * @param {Event} e
     */
    const handleInputClinicTypeOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            clinicType: e.target.value
        })
    }


    /**
     * 掛號別改變時
     * @param {String} reserveType
     */
    const handleRegTypeModeOnChange = (reserveType) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            reserveType: reserveType
        })
    }

    /**
     * 病歷號輸入框值變更事件
     * @param {Event} e
     */
    const handleInputPatientIdOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, patientId: e.target.value});
    }

    /**
     * 診室名稱輸入框值變更事件
     * @param {Event} e
     */
    function handleInputClinicNameOnChange(e) {
        setDisplayQueryInputData({...displayQueryInputData, clinicName: e.target.value})
    }

    /**
     * 列印方式改變時
     * @param {String} printType
     */
    const handlePrintTypeModeOnChange = (printType) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            printType: printType
        })
    }


    /**
     * 科別代碼方框的值變動時
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, departmentCode: e.target.value});
    }

    /**
     * 科別代碼方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param department {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (department) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: `${department.divNo} ${department.divName}`
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message: "起始日期不可大於結束日期", type: ToastTypeEnum.Error})
            return
        }

        //查詢紀錄
        querySearch(displayQueryInputData, 1, paginationProps.pageSize)
    }

    /**
     * 檢查資料是否被選擇
     * @param {Object} data
     * @returns
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(selectedData)) {
            return false
        }
        return selectedData === data
    }

    /**
     * 取得時段的名稱
     * @param {Number} id
     * @returns
     */
    const getApnNameByApnId = (id) => {
        if (id === null) {
            return ''
        }
        switch (id) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return ''
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        querySearch(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        querySearch(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        querySearch(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        querySearch(queryInputData, 1, e.target.value)
    }

    /**
     * 資料點選事件
     * @param e {Event}
     * @param item {item}
     * @return {void}
     */
    const handleDataOnClick = (e, item) => {
        // 取得點擊按鈕
        const {button} = e
        // 左鍵點擊時
        if (button === 0) {
            // 資料
            const data = advSearchDataList.find(d => d === item)
            // 找到資料
            if (data) {
                setSelectedData(item)
            }
        }
    }

    /**
     * 多選框點擊事件
     * @param name
     */
    const handleCheckboxChange = (name) => {
        setCheckedValues(prevState => ({
            ...prevState,
            [name]: !prevState[name]
        }))
    }

    /**
     * 列印作業資料匯出按鈕點擊事件
     * @retuen {void}
     */
    const handleExportPrintOperationQueryExcel = () => {
        // 資料不存在
        if (arrayIsEmpty(registrationData)) {
            // 顯示錯誤訊息
            showToast({message: "資料不存在", type: ToastTypeEnum.Warning})
            return
        }
        // 取得要輸出的資料
        const data = registrationData.map(item => {
            return [
                item.zoneName,
                stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD"),
                item.patientId,
                item.patientName,
                getViewNoFieldContent(item),
                item.divName,
                item.doctorName,
                item.clinicName,
                item.apnName,
                item.fvRvName,
                stringIsEmpty(item.createDatetime) ? '' : time2String(item.createDatetime, "YYYY-MM-DD"),
                item.encounterId, //看診否暫無功能
                item.cancelRegDatetime === null ? '否' : '是'  // 根據 cancelRegDatetime 判斷顯示 "否" 或 "是"
            ]
        })

        // 取得標題
        const titles = [
            "院區",
            "門診日期",
            "病歷號",
            "姓名",
            "診號",
            "科別",
            "醫師",
            "診室名稱",
            "時段",
            "初/複診",
            "掛號日期時間",
            "看診否",
            "退掛",
        ]

        // 取得Sheets
        const sheets = [{
            titles: titles,
            data: data
        }]

        // 取得日期時間
        const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')
        // 檔案名稱
        const fileName = `列印作業報表_${current}`
        // 取得excel
        const excel = exportExcelAsBlob(sheets)
        // 下載檔案
        downloadFile(excel, fileName)
    }

    /**
     * 列印按鈕點擊事件
     * @return {void}
     */
    const handlePrintOnClick = () => getRegsReportPappt()

    /**
     * PDF彈窗關閉時
     * @return {void}
     */
    const handlePdfPopupOnClose = () => {
        //清除資料
        setShowPdfPopup(false)
        setSelectedData(null)
        setPdfBlob(null)
    }

    /**
     * @param {Object} queryInputObj 查詢條件
     * @param {Number} page 第幾頁
     * @param {Number} pageSize 每頁筆數
     * @return {void}
     */
    const querySearch = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        regsQueryReg({
            //起始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            // 科別代碼
            divNo: queryInputObj.departmentCode.indexOf(' ') > 0 ? queryInputObj.departmentCode.split(' ')[0] : queryInputObj.departmentCode,
            // 掛號別
            reserveType: (queryInputObj.reserveType === '1' || queryInputObj.reserveType === '2' || queryInputObj.reserveType === '3' || queryInputObj.reserveType === '4' || queryInputObj.reserveType === '5' || queryInputObj.reserveType === '6' || queryInputObj.reserveType === '9') ? parseInt(queryInputObj.reserveType) : null,
            // 診室名稱
            clinicName: queryInputObj.clinicName,
            //病歷號
            patientId: queryInputObj.patientId,
            //診別
            clinicType: queryInputObj.clinicType === "ALL" ? "" : queryInputObj.clinicType,
            //時段
            apn: (queryInputObj.apn === '1' || queryInputObj.apn === '2' || queryInputObj.apn === '3') ? parseInt(queryInputObj.apn) : null,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                //在資料中加入key值
                const dataWithKeys = data.dataList ? data.dataList.map((item, index) => (
                    {...item, key: index})) : [];

                setRegistrationData(dataWithKeys)

                // auto-select the first data item after fetching
                setSelectedData(arrayIsEmpty(dataWithKeys) ? null : dataWithKeys[0])

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else { // 取得資料失敗
                const msg = `取得列印資料失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
                setRegistrationData(null)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        })
    }

    /**
     * 取得診間類別陣列
     * @return {void}
     */
    const getClinicTypeArray = () => {
        regsQueryClinicTypeList({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定診間類別資料
                const allObj = [{justnameNo: 'ALL', justnameName: '全部'}]
                const updateData = !arrayIsEmpty(data) ? [...allObj, ...data] : [...allObj]
                setAllClinicType(updateData)
                setDisplayQueryInputData({
                    ...displayQueryInputData,
                    clinicType: 'ALL'
                })
            } else { // 取得資料失敗
                setAllClinicType(null)
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getQueryAllDepartment = () => {
        queryAllDivision({}).then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有科別選項
                setAllDepartment(data)
            }
        })
    }

    /**
     * 取得預覽預約單
     * @return {void}
     */
    const getRegsReportPappt = () => {
        //資料不存在時
        if (objectIsEmpty(selectedData)) {
            showToast({message: '尚未選取資料', type: ToastTypeEnum.Warning})
            return
        }

        regsReportPappt({regId: selectedData.regId}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得檔案Url
                const {fileUrl} = data
                //檔案URL存在時
                if (fileUrl) {
                    // 取得檔案名稱
                    const fileName = fileUrl.split('?fileName=')[1]
                    //取得檔案Blob
                    regsReportGetFile({fileName: fileName}).then(res => {
                        //取得成功
                        if (res) {
                            //設定PDF Blob
                            setPdfBlob(res)
                            //顯示PDF Popup
                            setShowPdfPopup(true)
                        } else { //取得失敗
                            showToast({message: "請稍後重新嘗試取得檔案", type: ToastTypeEnum.Warning})
                            //清除資料
                            setPdfBlob(null)
                            //隱藏PDF Popup
                            setShowPdfPopup(false)
                        }
                    })
                }
            } else { //取得失敗
                //顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
                //清除資料
                setPdfBlob(null)
                //隱藏PDF Popup
                setShowPdfPopup(false)
            }
        })
    }

    /**
     * 取得給診號欄位內容
     * @param {Object} registrationItem 掛號項目
     * @returns
     */
    const getViewNoFieldContent = (registrationItem) => {
        if (objectIsEmpty(registrationItem)) {
            return ""
        }

        if (registrationItem.giveNumType === "1") {
            //依診號
            return `${registrationItem.viewNo}`
        } else if (registrationItem.giveNumType === "2") {
            //依時間
            if (!stringIsEmpty(registrationItem.startViewDatetime) && !stringIsEmpty(registrationItem.endViewDatetime)) {
                const start = time2String(registrationItem.startViewDatetime, "HH:mm")
                const end = time2String(registrationItem.endViewDatetime, "HH:mm")

                return `${start} ~ ${end}`
            }
        }

        return ""
    }

    /**
     * 進階搜尋輸入框文字變更事件
     * @param {Event} e
     * @return {void}
     */
    const handleAdvSearchTextOnChange = (e) => {
        setAdvSearchText(e.target.value)
        // 清空選擇要列印的資料
        setSelectedData(null)
    }

    /**
     * 取得進階搜尋後的資料
     * @return {void}
     */
    const getAdvanceSearchData = () => {
        const tmpArray =
            arrayIsEmpty(registrationData) ? [] :
                registrationData.map((item) => (
                    {
                        //reg ID
                        regId: item.regId,
                        //只copy要進階搜尋的欄位
                        //院區
                        zoneName: item.zoneName,
                        //門診日期
                        encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                        //病歷號
                        patientId: item.patientId,
                        //病人姓名
                        patientName: item.patientName,
                        //看診號字串
                        viewNoText: getViewNoFieldContent(item),
                        //科別
                        divName: item.divName,
                        //醫師
                        doctorName: item.doctorName,
                        //診室名稱
                        clinicName: item.clinicName,
                        //時段
                        apnName: item.apnName,
                        //初/複診
                        fvRvName: item.fvRvName,
                        //掛號日期時間
                        createDatetime: time2String(item.createDatetime, "YYYY-MM-DD"),
                        //看診否
                        alreadyView: 'N',
                        //退掛
                        isCancelReg: item.cancelRegDatetime === null ? '否' : '是'
                    })
                )

        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["regId"])
        setAdvSearchDataList(findedData)
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        if (arrayIsEmpty(allDepartment)) {
            getQueryAllDepartment()
        }
        if (arrayIsEmpty(allClinicType)) {
            getClinicTypeArray()
        }

        handleSearchOnClick()
    }, [])

    /**
     * 監聽科別代碼輸入框變化
     * @return {void}
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode])

    /**
     * 監聽advSearchText&filteredDataList的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchData()
    }, [advSearchText, registrationData])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊-上*/}
            <div className="flex flex-row flex-wrap items-center">
                <div className="date flex flex-row items-center justify-start space-x-2.5 mr-3.5">
                    <p className="">{t('ICCard.InpOpdEnum.O')}{t('Regs.general.dateStartAndEnd')}：</p>
                    <div className="flex flex-row items-center">
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={displayQueryInputData.dateStart}
                            type={InputTypeEnum.Date}
                            inputMode={InputTypeEnum.Date}
                            onChange={handleDateStartOnChange}/>
                        <p className="m-1">~</p>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={displayQueryInputData.dateEnd}
                            type={InputTypeEnum.Date}
                            inputMode={InputTypeEnum.Date}
                            onChange={handleDateEndOnChange}/>
                    </div>
                </div>
                <div className="flex items-center mr-3">
                    <p>時段：</p>
                    <select
                        className="h-10 pl-4 border border-[#D4D4D8] rounded-md min-w-[120px]"
                        onChange={handleSelectApnOnChange}
                    >
                        {
                            apnList.map((apn, index) =>
                                <option key={index} value={apn.justnameNo}>{apn.justnameName}</option>
                            )
                        }
                    </select>
                </div>

                <div className="flex items-center mr-3">
                    <p>診別：</p>
                    <select
                        className="h-10 pl-4 border border-[#D4D4D8] rounded-md min-w-[120px]"
                        value={displayQueryInputData.clinicType}
                        onChange={handleInputClinicTypeOnChange}>
                        {
                            !arrayIsEmpty(allClinicType) && allClinicType.map((item, index) =>
                                <option value={item.justnameNo} key={index}>{item.justnameName}</option>
                            )
                        }
                    </select>
                </div>
                {/*掛號別*/}
                <div className="flex flex-row justify-center items-center gap-[12px] text-base font-normal mr-3">
                    <p>掛號別：</p>
                    {
                        reserveTypeList.map((reserveType, index) => (
                            <RadioButton
                                key={index}
                                mode={displayQueryInputData.reserveType}
                                modeChecked={reserveType.justnameNo}
                                handleModeChange={handleRegTypeModeOnChange}
                                label={reserveType.justnameName}
                                value={reserveType.justnameNo}/>
                        ))
                    }
                </div>
            </div>
            {/*Filter區塊-下*/}
            <div className="flex flex-row flex-wrap items-center">
                {/*科別*/}
                <div className="flex items-center mr-2">
                    <p>{t('general.department')}：</p>
                    <div ref={dropdownDepartmentRef}
                         className="flex flex-row w-[180px] space-x-2 items-center relative">
                        <input
                            className="bg-white w-full px-2 p-1 pl-9 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8]"
                            value={displayQueryInputData.departmentCode}
                            onChange={(e) => handleInputDepartmentNoOnChange(e)}
                            onFocus={handleInputDepartmentNoOnFocus}/>
                        <img
                            className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                            alt="search-icon"
                            src={searchIcon}
                            role="button"/>

                        {
                            showDepartmentDropDown && !arrayIsEmpty(departmentOptionList) &&
                            <div
                                className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                    {
                                        departmentOptionList.map((department, index) =>
                                            <div
                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                role="button" key={index}
                                                onClick={() => handleDepartmentOptionOnClick(department)}>
                                                <p className="">{`${department.divNo}`}</p>
                                                <p>{`${department.divName}`}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/*診室名稱*/}
                <div className="flex items-center mr-2">
                    <p>{t('Regs.general.clinicName')}：</p>
                    <SearchInput
                        className="bg-white h-10 w-full px-2 text-black rounded-[6px] border-2 py-1 border-gray-300 pl-8"
                        searchText={displayQueryInputData.clinicName}
                        onChange={handleInputClinicNameOnChange}/>
                </div>
                {/*病歷號*/}
                <div className="flex items-center mr-2">
                    <p>{t('general.patientNo')}：</p>
                    <SearchInput
                        className="bg-white h-10 w-full px-2 text-black rounded-[6px] border-2 py-1 border-gray-300 pl-8"
                        searchText={displayQueryInputData.patientId}
                        onChange={handleInputPatientIdOnChange}/>
                </div>
                {/*查詢*/}
                <DefaultButton
                    type="primary" text={t('general.query')}
                    onClickFn={handleSearchOnClick}/>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*列印單據*/}
                <div
                    className="w-full p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                    <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                        <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                        <div className="text-xl text-[#2B6CB0] mr-3.5">列印單據</div>

                        <div className="flex ml-5 items-center">
                            <input
                                className="mr-2 w-[20px] h-[20px] bg-green-400 checked:bg-green-600 text-green-600"
                                type="checkbox"
                                checked={checkedValues.registration}
                                onChange={() => handleCheckboxChange('registration')}
                                style={{accentColor: '#38A169'}}/>
                            <label onClick={() => handleCheckboxChange('registration')}>掛號單</label>
                            <input
                                className="mx-2 w-[20px] h-[20px] bg-green-400 checked:bg-green-600 text-green-600"
                                type="checkbox"
                                checked={checkedValues.emergencyTag}
                                onChange={() => handleCheckboxChange('emergencyTag')}
                                style={{accentColor: '#38A169'}}/>
                            <label onClick={() => handleCheckboxChange('emergencyTag')}>急診標籤</label>
                            <input
                                className="mx-2 w-[20px] h-[20px] bg-green-400 checked:bg-green-600 text-green-600"
                                type="checkbox"
                                checked={checkedValues.emergencyTagWristband}
                                onChange={() => handleCheckboxChange('emergencyTagWristband')}
                                style={{accentColor: '#38A169'}}/>
                            <label onClick={() => handleCheckboxChange('emergencyTagWristband')}>急診標籤(手環)</label>
                        </div>
                        <div className="mx-2 h-6 w-[3px] mr-2 bg-[#111111]/15"/>

                        <div
                            className="flex flex-row justify-center items-center gap-[12px] text-base font-normal mr-3">
                            <p>列印方式：</p>
                            {
                                printTypeList.map((printType, index) => (
                                    <RadioButton
                                        key={index}
                                        mode={displayQueryInputData.printType}
                                        modeChecked={printType.justnameNo}
                                        handleModeChange={handlePrintTypeModeOnChange}
                                        label={printType.justnameName}
                                        value={printType.justnameNo}/>
                                ))
                            }
                        </div>
                        <button
                            className={`items-center h-10 bg-[#FAFAFA] border-[2px] border-[#D4D4D8] rounded px-4`}
                            onClick={handlePrintOnClick}>
                            {t('general.print')}
                        </button>

                    </div>

                    <div className="searchGroup h-10 flex flex-row items-center justify-between mb-4">
                        <div className="flex flex-row items-center">
                            {/*進階搜尋*/}
                            <BaseInput
                                className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                placeholder={t('general.advancedSearch')}
                                inputMode={'search'}
                                value={advSearchText}
                                onChange={(e) => handleAdvSearchTextOnChange(e)}/>
                        </div>
                        {/*匯出按鈕*/}
                        <button className="flex flex-row justify-center items-center w-[112px] h-10"
                                onClick={handleExportPrintOperationQueryExcel}>
                            <div className="w-10 h-10">
                                <img className="w-full h-full" src={ExportIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">
                                {t('general.export')}
                            </p>
                        </button>
                    </div>
                    {/*Table*/}
                    <ul className="tableList printOperationTableItem  overflow-x-auto overflow-y-auto">
                        <li className="title">
                            <div>{t('Regs.general.campus')}</div>
                            <div>{t("Regs.general.clinicDate")}</div>
                            <div>{t('general.patientNo')}</div>
                            <div>{t('general.username')}</div>
                            <div>{t('Regs.general.viewNumber')}</div>
                            <div>{t('general.department')}</div>
                            <div>{t('general.doctor')}</div>
                            <div>{t("Regs.general.clinicName")}</div>
                            <div>{t('Regs.general.timeSlot')}</div>
                            <div>初/複診</div>
                            <div>掛號日期時間</div>
                            <div>看診否</div>
                            <div>退掛</div>
                        </li>
                        {
                            !arrayIsEmpty(advSearchDataList) && advSearchDataList.map((item, index) => (
                                <li key={index}
                                    className={`${checkDataIsSelected(item) ? "selected" : ""}`}
                                    onClick={(e) => handleDataOnClick(e, item)}>
                                    {/*院區*/}
                                    <div>{item.zoneName}</div>
                                    {/*門診日期*/}
                                    <div>{stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD")}</div>
                                    {/*病歷號*/}
                                    <div>{stringIsEmpty(item.patientId) ? '' : item.patientId}</div>
                                    {/*姓名*/}
                                    <div>{item.patientName}</div>
                                    {/*看診號字串*/}
                                    <div>{item.viewNoText}</div>
                                    {/*科別*/}
                                    <div>{stringIsEmpty(item.divName) ? '' : item.divName}</div>
                                    {/*醫師*/}
                                    <div>{stringIsEmpty(item.doctorName) ? '' : item.doctorName}</div>
                                    {/*診室名稱*/}
                                    <div>{stringIsEmpty(item.clinicName) ? '' : item.clinicName}</div>
                                    {/*時段*/}
                                    <div>{item.apnName}</div>
                                    {/*初/複診*/}
                                    <div>{item.fvRvName}</div>
                                    {/*掛號日期時間*/}
                                    <div>{stringIsEmpty(item.createDatetime) ? '' : time2String(item.createDatetime, "YYYY-MM-DD")}</div>
                                    {/*看診否*/}
                                    <div>{item.alreadyView}</div>
                                    {/*退掛*/}
                                    <div>{item.isCancelReg}</div>
                                </li>
                            ))
                        }
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            currentPage={paginationProps.currentPage}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            onPageOnChange={(page) => onPaginationPageOnChange(page)}
                            onPrevPageOnClick={onPaginationPreviousOnClick}
                            onNextPageOnClick={onPaginationNextOnClick}
                            onPageSizeChange={onPaginationPageSizeOnChange}
                        />
                    </div>
                </div>
            </div>
            {/*PDF Preview Popup*/}
            <PDFPreviewPopup showPopup={showPdfPopup}
                             blob={pdfBlob}
                             closePopupButtonOnClick={handlePdfPopupOnClose}/>
        </div>
    )
}

export default PrintOperation
