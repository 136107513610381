import searchIcon from "../../assets/images/search-interface-symbol.png"
import addIcon from "../../assets/images/icons/add_circle.svg"
import React, { useEffect, useState } from 'react'
import '../../components/TableList/tableList.scss'
import {ApiErrorStatusEnum, objectIsEmpty, InputTypeEnum, ToastTypeEnum, arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist"
import { Pagination } from "../../components/Pagination/Pagination"
import { qtyPerPageArray } from "../../constants"
import CvisListItem from '../../components/CriticalValueManagement/CvisListItem'
import { getCurrentFormattedDateTime } from "../../components/CriticalValueManagement/utils"
import ToastMessage from "../../components/Toasts/ToastMessage"
import {
    queryCvisClassANoReply,
    queryCvisRenotify,
    saveCvisRenotify,
    queryCvisNotifyUnit
} from "../../api/v1/Critical"


import {t} from "i18next";



/**
 * 可編輯列表項目
 * @param index {string} 索引
 * @param option {object} 通報清單物件
 * @param onOptionChange {Function} 選項改變事件
 * @return {JSX.Element}
 */
const EditableListItem = ({index, object, onOptionChange}) => {
    // 初始值
    const [value, setValue] = useState(object.option[0])
    // 選項改變事件
    const handleOptionChange = (event) => {
        // 更新畫面
        setValue(event.target.value)
        // 傳遞事件，影響父元件的通報對象值(object)值
        onOptionChange(index, event.target.value)
    }
    return (
        <li>
            <div>{object.dateTime}</div>
            <div>{object.encounterId}</div>
            <div>{localStorage.getItem("username")}</div>
            <div className='flex flex-row justify-start items-center'>
                    <select
                        className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={value}
                        onChange={handleOptionChange}
                        >
                        {object.option.map((item) => (
                            <option value={item}>{item}</option>
                        ))}
                    </select>
            </div>
        </li>
    )
}

/**
 * A級危急值逾時回覆再通知
 * @return {JSX.Element}
 * */
function CrisisAlertTimeoutReplyAssignmentAPopup() {
    // 通報單位選單
    const [unit, setUnit] = useState([])
    //通報單位
    const [cvisNotifyUnit, setCvisNotifyUnit] = useState('01')
    //A級危急值逾時回覆再通知清單
    const [cvisRenotifyList, setCvisRenotifyList] = useState([])
    //過濾後的清單
    const [filteredList, setFilteredList] = useState([])
    //焦點索引值
    const [focusIndex, setFocusIndex  ] = useState(null)
    //進階搜尋字串
    const [queryString, setQueryString] = useState('')
    //通報對象資料
    const [cvisNotifyObject, setCvisNotifyObject] = useState({})
    //可選擇的通報對象
    const [notifyObjectOption, setNotifyObjectOption] = useState([
        "主治醫師",
        "院醫師",
        "護理站電腦",
        "職務代理人",
        "感染管制師"
    ])
    //待編輯通報對象
    const [editableNotifyObject, setEditalbeNotifyObject] = useState([])
    //通報對象選項值
    const [editableObjectValue, setEditableObjectValue] = useState([])
    //Toast開關
    const [toastShow, setToastShow] = useState(false)
    //Toast訊息
    const [toastMessage, setToastMessage] = useState({
        message: '',
        type: ToastTypeEnum.Success
    })
    //每頁筆數
    const [qtyPerPage, setQtyPerPage] = useState(qtyPerPageArray[0])
    // pagination 控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })
    // 通報對象 pagination 控制變數
    const [notifyObjectPaginationProps, setNotifyObjectPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })

    /**
     * A級危急值逾時回覆再通知表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }

    /**
     * A級危急值逾時回覆再通知表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisClassANoReply(paginationProps.pageNum, newSize);
    }

    /**
     * A級危急值逾時回覆再通知表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }

    /**
     * A級危急值逾時回覆再通知表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }
    
    /**
     * 通報清單表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onNotifyPaginationPageOnChange = (page) => {
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyobject(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 通報清單表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onNotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageSize: newSize
        });
        handleQueryCvisNotifyobject(focusIndex, paginationProps.pageNum, newSize);
    }

    /**
     * 通報清單表格上一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyobject(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 通報清單表格下一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyobject(focusIndex, page, paginationProps.pageSize);
    }
    /**
     * 顯示Toast，帶入訊息及toast類型
     * @param {String} message
     * @param {Number} type
     * @returns {void}
     */
    const handleToastOnShow = (message, type) => {
        console.log("toast show")
        setToastMessage({
            ...toastMessage,
            message: message,
            type: type
        })
        setToastShow(true)
    }

    /**
     * 關閉Toast
     * @returns {void}
     */
    const handleToastOnClose = () => {
        setToastShow(false)
    }


    const handleCvisNotifyUnitOnChange = (event) => {
        setCvisNotifyUnit(event.target.value)
        setFocusIndex(null)
    }

    /**
     * 新增內容
     * @param {Object} event
     */
    const handleAddRenotifyContent = () => {
        if(focusIndex === null){
            handleToastOnShow("請選擇一筆資料！", ToastTypeEnum.Error)
        }else{
            if(!arrayIsEmpty(notifyObjectOption)){
                setEditalbeNotifyObject([...editableNotifyObject, {
                    dateTime: getCurrentFormattedDateTime(),
                    cvisId: filteredList[focusIndex].cvisNotifyId,
                    encounterId: filteredList[focusIndex].encounterId,
                    user: localStorage.getItem("username"),
                    option: notifyObjectOption,
                    type:"vs", //不確定是資料帶入還是可選，先寫死
                    object: notifyObjectOption[0]
                }])
            }else{

            }
        }
    }

    /**
     * 控制可編輯通報對象選項值變動事件
     * @param {Integer} index
     * @param {String} value
     * @returns {void}
     * */
    const handleEditableObjectValueChange = (index, value) => {
        // 取得當前可編輯通報對象物件
        let newEditableNotifyObject = [...editableNotifyObject]
        // 根據索引值，更新對應通報對象值
        newEditableNotifyObject[index].object = value
        // 儲存更新後的通報對象
        setEditalbeNotifyObject(newEditableNotifyObject)
    }

    /**
     * 當列表被點擊時，返回索引的index，然後使用index呼叫取得通報對象清單API
     * @param {Number} index 列表項目索引
     * @returns {void}
     * */
    const handleOnItemFocus = (index) => {
        setFocusIndex(index)
        handleQueryCvisNotifyobject(index)
    }

    /**
    * 取得模糊匹配後的資料陣列
    * @return {Array<Object>}
    */
    const getFilterData = (string) => fuzzySearchObjects(cvisRenotifyList, string)

    /**
     * 控制進階搜尋輸入框變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列，並重置焦點索引值
     * @param {Object} event
     * @returns {void}
     * */
    const handleOnQueryStringChange = (event) => {
        setQueryString(event.target.value)
        setFilteredList(getFilterData(event.target.value))
        setFocusIndex(null)
    }

    /**
     * 批次儲存A級危急值逾時回覆再通知
     * @param {Object} event
     * @returns {void}
     */
    const handleContentSave = () => {
        //根據editableNotifyObject的內容，批次儲存
        const saveList = []
        editableNotifyObject.map((item) => {
            saveList.push({
                cvisNotifyId: item.cvisId,
                cvisObjectType: item.type,
                cvisRenotifyDatetime: item.dateTime,
                cvisNotifyUser: item.user,
                cvisNotifyObject: item.object
            })
        })
        if(arrayIsEmpty(saveList)){
            handleToastOnShow("無可儲存的資料！", ToastTypeEnum.Success)
        }else{
            saveCvisRenotify(saveList).then((res) => {
                if(res.err === ApiErrorStatusEnum.Success){
                    handleToastOnShow("再通知批次儲存成功！", ToastTypeEnum.Success)
                }else{
                    handleToastOnShow("再通知批次儲存失敗！", ToastTypeEnum.Error)
                }})
        }
    }

    /**
     * 根據焦點索引取得再通知紀錄清單
     * @param {Object} index
     * @returns {void}
     * */
    const handleQueryCvisNotifyobject = (index, page, pageSize) => {
        if(focusIndex != index) {
            setEditalbeNotifyObject([])
            if(!arrayIsEmpty(filteredList)){
                queryCvisRenotify({
                    "cvisNotifyId": filteredList[index].cvisNotifyId,
                    "pageNum": page,
                    "pageSize": pageSize
                }).then((res) => {
                    if(res.err === ApiErrorStatusEnum.Success){
                        const data = res.data.dataList
                        //取得通報對象
                        setCvisNotifyObject(data)
                        //取得通報對象選項
                        let option = []
                        data.forEach((item) => {
                            option.push(`${item.cvisNotifyObject} : ${item.cvisNotifyObjectName}`)
                        })
                        setNotifyObjectPaginationProps({
                            ...notifyObjectPaginationProps,
                            totalItemSize: res.data.totalItemSize,
                            totalPageSize: res.data.totalPageSize,
                            currentPageItemSize: res.data.currentPageItemSize,
                            pageNum: page,
                            pageSize: pageSize
                        })
                    }else{
                        if(res.err === 1){
                            handleToastOnShow("查無資料", ToastTypeEnum.Error)
                            setCvisNotifyObject([])
                            setNotifyObjectPaginationProps({
                                ...notifyObjectPaginationProps,
                                totalItemSize: 0,
                                totalPageSize: 0,
                                currentPageItemSize: 0,
                                pageNum: page,
                                pageSize: pageSize
                            })
                        }
                    }
                })
            }
        }
    }

    /**
     * 取得A級危急值逾時回覆再通知清單
     * @param {Object} event
     * @returns {void}
     * */
    const handleQueryCvisClassANoReply = (page, pageSize) => {
        queryCvisClassANoReply({
            "cvisNotifyUnit": cvisNotifyUnit,
            "pageNum": page,
            "pageSize": pageSize
        }).then((res) => {
            if(res.err === ApiErrorStatusEnum.Success){
                const data = res.data.dataList
                setCvisRenotifyList(data)
                setFilteredList(data)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.currentPageItemSize,
                    pageNum: page,
                    pageSize: pageSize
                })
                setCvisNotifyObject([])
                setEditalbeNotifyObject([])
            }else{
                if(res.err===1){
                    handleToastOnShow("查無資料", ToastTypeEnum.Error)
                    setCvisRenotifyList([])
                    setFilteredList([])
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }
            }
        })
    }
    
    // 取得再通知清單
    useEffect(() => {
        handleQueryCvisNotifyobject()
    }, [])

    // 取得A級危急值逾時回覆再通知清單
    useEffect(()=>{
        handleQueryCvisClassANoReply(paginationProps.pageNum, paginationProps.pageSize)
    },[cvisNotifyUnit])
    
    // 取得通報單位資料
    useEffect(()=>{
        queryCvisNotifyUnit().then((res) => {
          if(res.err === ApiErrorStatusEnum.Success){
            setUnit(res.data)
          }else{
            console.error(res)
          }
    })},[])

  return (
    <div className="w-full">
        <div className='w-full flex flex-col gap-4'>
            <div className={`w-full bg-white rounded-[6px]`}>
                {/*標題*/}
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    <h3 className="text-[20px]">{'A級危急值逾時回覆再通知'}</h3>
                </div>
                {<>
                    {/* 主要內容 */}
                    <div className='w-full p-4 flex flex-col gap-4 overflow-x-scroll'>
                    {/* 醫生及回覆資訊 */}
                    <div className='w-full flex flex-row justify-between items-center'>
                        <div className='w-[310px] h-10 flex flex-row justify-start items-center'>
                            <p>通報單位：</p>
                            <select 
                                className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={cvisNotifyUnit}
                                onChange={handleCvisNotifyUnitOnChange}
                            >
                            {!arrayIsEmpty(unit) && unit.map((item, index) => (
                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                        ))}
                        </select>
                        </div>
                    </div>
                    {/* 資料內容 */}
                    <div className=' flex flex-col w-full h-[864px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                        {/* 搜尋欄 */}
                        <div className='max-w-[320px] relative'>
                                <input
                                    className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                    type={InputTypeEnum.Text}
                                    value={queryString}
                                    onChange={handleOnQueryStringChange}
                                    placeholder="進階搜尋"
                                />
                                <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                    <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                </div>
                            </div>
                        {/* 清單表格 */}
                        <div className='min-w-[1664px]'>
                            <ul className='tableList crisisAlertTimeoutReplyAssignmentATable max-h-[736px]'>
                                <li className='title'>
                                    <div>發送日期時間</div>
                                    <div>病歷號</div>
                                    <div>姓名</div>
                                    <div>主治醫師</div>
                                    <div>醫師姓名</div>
                                    <div>護理站代號</div>
                                    <div>護理站GSM</div>
                                    <div>值班人員</div>
                                    <div>即時床號</div>
                                    <div>通報內容</div>
                                </li>
                                {!objectIsEmpty(filteredList) && (
                                    filteredList.map((item, index) => (
                                        <CvisListItem
                                            index={index}
                                            item={item}
                                            type="APopup1"
                                            handleItemOnClick={handleOnItemFocus}
                                        />
                                    ))
                                )}
                            </ul>
                        </div>
                        {/* 分頁 */}
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            currentPage={paginationProps.pageNum}
                            onPageOnChange={onPaginationPageOnChange}
                            onPageSizeChange={onPaginationPageSizeOnChange}
                            onPrevPageOnClick={onPaginationPreviousOnClick}
                            onNextPageOnClick={onPaginationNextOnClick}
                        />
                        {/* 分頁 */}
                        <div className="w-full h-[60px] py-[10px]">
                        <div className="flex flex-row justify-center items-center w-[112px] h-10 hover:cursor-pointer" onClick={handleAddRenotifyContent}>
                            <div className="p-[3.33px] w-10 h-10">
                            <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">新增內容</p>
                        </div>
                        </div>
                        <div className='min-w-[1664px]'>
                            <ul className='tableList crisisAlertTimeoutReplyAddTableItem w-full'>
                                <li className='title'>
                                    <div>通報日期時間</div>
                                    <div>單號</div>
                                    <div>通報人員</div>
                                    <div>通報對象</div>
                                </li>
                                {/* 通報對象資料 */}
                                {!objectIsEmpty(cvisNotifyObject) && (
                                    cvisNotifyObject.map((item, index) => (
                                        <CvisListItem
                                            index={index}
                                            item={item}
                                            type="APopup"
                                        />
                                    ))
                                )}
                                {/* 可編輯通報對象 */}
                                {!objectIsEmpty(editableNotifyObject) && (
                                    editableNotifyObject.map((item, index) => (
                                        <EditableListItem
                                            index={index}
                                            object={item}
                                            onOptionChange={handleEditableObjectValueChange}
                                        />
                                    ))
                                )}
                            </ul>
                        </div>
                        {/* 分頁 */}
                        <Pagination
                            totalPageSize={notifyObjectPaginationProps.totalPageSize}
                            pageSize={notifyObjectPaginationProps.pageSize}
                            totalSize={notifyObjectPaginationProps.totalItemSize}
                            currentPage={notifyObjectPaginationProps.pageNum}
                            onPageOnChange={onNotifyPaginationPageOnChange}
                            onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                            onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                            onNextPageOnClick={onNotifyPaginationNextOnClick}
                        />
                    </div>
                    </div>
                    {/* 儲存按鈕 */}
                    <div className="w-full flex justify-end px-4 py-[10px] border-solid border-[1px]">
                        <button 
                            className="w-[64px] h-10 bg-[#2B6CB0] text-white rounded-[6px] hover:cursor-pointer" 
                            onClick={handleContentSave}
                        >
                            存檔
                        </button>
                    </div>
                    <ToastMessage
                        show={toastShow}
                        message={toastMessage.message}
                        type={toastMessage.type}
                        onClickClose={handleToastOnClose}
                    />
                </>}
            </div>
        </div>
    </div>
  )
}

export default CrisisAlertTimeoutReplyAssignmentAPopup