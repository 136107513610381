import React, { useRef }  from 'react'
import Button from "../Button/Button";
const SecondButtonStyle = "bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
const InputStyle = "w-full px-2 bg-white text-black rounded-[6px] border border-gray-300"

const ExtendBtnTextArea = ({defaultHeight, maxHeight, handleOnChange , value }) => {
    const inputRef = useRef(null);

    const handleExtendTextArea = ()=>{
        // 如果沒有文字或者高度小於目前的欄位高就不動作
        if (!value || inputRef.current.scrollHeight <= maxHeight) {
            return
        }
        if(!!maxHeight && inputRef.current.scrollHeight > maxHeight){
            inputRef.current.style.height = maxHeight + 'px';
        } else{
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    }

    return (
        <div className="flex items-center">
            <textarea rows={defaultHeight || 4} defaultValue={value} className={InputStyle} ref={inputRef} onChange={(e)=>handleOnChange(e)}></textarea>
            <Button classNames={`${SecondButtonStyle} ml-4 w-[80px]`} text={"展開"} onClickFn={handleExtendTextArea}/>
        </div>
    )
}

export default ExtendBtnTextArea