import { BaseInput } from '../Input/BaseInput'
import Button from '../Button/Button'
import {
    InputTypeEnum,
    ToastTypeEnum,
    ApiErrorStatusEnum,
    getLocalStorage,
} from 'edah_utils/dist'
import React, { useEffect, useMemo, useState } from 'react'
import CustomTable from './CustomTable'
import { RETURN_HOSPITAL } from '../../constants/referralSystem/maintainAreaHeader'
import { Pagination } from '../Pagination/Pagination'
import RadioButton from '../Input/RadioButton'
import { BasePopup } from '../Popup/BasePopup'
import {
    queryHospitalInfoList,
    addHospitalinfoSelf,
    delHospitalinfoSelf,
} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'

const Layout = ({ children }) => {
    return (
        <div className="p-2 w-full  max-h-[calc(100%-140px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

/**
 * 策略聯盟、社區醫療、支援院所
 * @param {boolean} value 對應欄位的值
 * @returns {JSX.Element}
 */
const CustomCheckbox = (value) => {
    return (
        <div className="w-full text-center">
            <input
                type="checkbox"
                checked={value}
                disabled
            />
        </div>
    )
}

const InputStyle =
    'w-[120px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const HealthCareOptions = [
    {
        label: '台北',
        value: 1,
    },
    {
        label: '北區',
        value: 2,
    },
    {
        label: '中區',
        value: 3,
    },
    {
        label: '南區',
        value: 4,
    },
    {
        label: '高屏',
        value: 5,
    },
    {
        label: '東區',
        value: 6,
    },
    {
        label: '全部',
        value: '',
    },
]
const HospitalTypeOptions = [
    {
        label: '醫學中心',
        value: 1,
    },
    {
        label: '區域醫院',
        value: 2,
    },
    {
        label: '地區醫院',
        value: 3,
    },
    {
        label: '診所',
        value: 4,
    },
    {
        label: '全部',
        value: '',
    },
]
const QuickSelector = [
    {
        label: '病人居住地區',
        value: 1,
    },
    {
        label: '醫師自訂',
        value: 2,
    },
    {
        label: '義大體系',
        value: 3,
    },
    {
        label: '高屏聯盟',
        value: 4,
    },
    {
        label: '全部',
        value: '',
    },
]

/**
 * 轉診院所查詢
 * @returns {JSX.Element}
 */
const ReferralHospital = ({ onConfirm, zipCodeList = [] }) => {
    // 院所清單
    const [dataList, setDataList] = useState([])

    // pagination
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    // 健保分局
    const [healthCare, setHealthCare] = useState(HealthCareOptions[4].value)

    // 特約類別
    const [hospitalType, setHospitalType] = useState(
        HospitalTypeOptions[4].value
    )

    // 快速查詢
    const [quickSelector, setQuickSelector] = useState(QuickSelector[4].value)

    // 高雄地區
    const [kaohsiungArea, setKaohsiungArea] = useState('')

    // 院所代碼
    const [hospNo, setHospNo] = useState('')

    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')

    // 院所地址
    const [hospitalAddress, setHospitalAddress] = useState('')

    // 選擇院所
    const [selectedHospital, setSelectedHospital] = useState({})

    // toast message
    const showToast = useToast()

    /**
     * 更新院所代碼
     * @param {event} e
     * @returns
     */
    const handleHospNoChange = (e) => setHospNo(e.target.value)

    /**
     * 更新院所名稱
     * @param {event} e
     * @returns
     */
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    /**
     * 更新院所地址
     * @param {event} e
     * @returns
     */
    const handleHospitalAddressChange = (e) =>
        setHospitalAddress(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = () => {
        getHospitalInfoList(1, filter.pageSize)
    }

    /**
     * 自訂按鈕設定
     */
    const handleCustomButtonSetting = async ({ hospNo }) => {
        const userNo = getLocalStorage('userno')
        const res = await addHospitalinfoSelf({
            userNo,
            hospNo,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
            getHospitalInfoList(filter.pageNum, filter.pageSize)
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({ message: msg, type: ToastTypeEnum.Error })
        }
    }

    /**
     * 自訂按鈕取消
     */
    const handleCustomButtonCancel = async ({ hospNo }) => {
        const userNo = getLocalStorage('userno')
        const res = await delHospitalinfoSelf({
            userNo,
            hospNo,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
            getHospitalInfoList(filter.pageNum, filter.pageSize)
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({ message: msg, type: ToastTypeEnum.Error })
        }
    }

    /**
     * 自訂按鈕
     * @returns {Element}
     */
    const CustomButton = (row) => {
        return (
            <div className="flex">
                {!row.hospitalinfoSelfFlag && (
                    <Button
                        classNames={`text-[#2B6CB0] text-[16px] flex mr-2`}
                        text={'自訂'}
                        onClickFn={() =>
                            handleCustomButtonSetting({
                                hospNo: row.hospNo,
                            })
                        }
                    />
                )}
                {row.hospitalinfoSelfFlag && (
                    <Button
                        classNames={`text-[#2B6CB0] text-[16px] flex`}
                        text={'取消'}
                        onClickFn={() =>
                            handleCustomButtonCancel({
                                hospNo: row.hospNo,
                            })
                        }
                    />
                )}
            </div>
        )
    }

    /**
     * 查詢院所清單
     * @param {number} pageNum 當前頁數
     * @param {number} pageSize 每頁筆數
     */
    const getHospitalInfoList = (pageNum, pageSize) => {
        const userNo = getLocalStorage('userno')
        queryHospitalInfoList({
            hospBranch: healthCare, // 健保分局
            hospGrade: hospitalType, // 特約類別
            quickSearch: quickSelector, //TODO: 待後端處理 1:病人居住地,2醫師自訂,3義大體系,4高屏聯盟, ALL
            hospNo: hospNo, // 院所代碼
            hospName: hospitalName, // 院所名稱
            hospAddress: hospitalAddress, // 院所地址
            hospZipCode: kaohsiungArea, // 高雄地區
            userNo,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data.dataList
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                const modifiedList = list.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
                setDataList(modifiedList)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 院所清單 table header
     * 策略聯盟、社區醫療、支援院所插入checkbox
     */
    const renderFields = useMemo(() => {
        return RETURN_HOSPITAL.map((item) => {
            switch (item.key) {
                case 'communityFlag':
                    return {
                        ...item,
                        render: (row) => CustomCheckbox(row.communityFlag),
                    }
                case 'federalFlag':
                    return {
                        ...item,
                        render: (row) => CustomCheckbox(row.federalFlag),
                    }
                case 'supportFlag':
                    return {
                        ...item,
                        render: (row) => CustomCheckbox(row.supportFlag),
                    }
                case 'custom':
                    return {
                        ...item,
                        render: (row) => CustomButton(row),
                    }
                default:
                    return item
            }
        })
    }, [dataList])

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getHospitalInfoList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getHospitalInfoList(filter.pageNum, pageSize)
    }

    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getHospitalInfoList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getHospitalInfoList(page, filter.pageSize)
    }
    /**
     * 更新健保分局選項
     */
    const handleHealthCareChange = (value) => {
        setHealthCare(value)
    }
    /**
     * 更新特約類別選項
     */
    const handleHospitalTypeChange = (value) => {
        setHospitalType(value)
    }

    /**
     * 更新快速選擇選項
     */
    const handleQuickSelectorChange = (value) => {
        setQuickSelector(value)
    }

    /**
     * 更新高雄地區選項
     */
    const handleKaohsiungAreaChange = (e) => {
        setKaohsiungArea(e.target.value)
    }

    /**
     * 健保署地圖式查詢網頁
     */
    const handleOpenHealthCare = (value) => {
        window.open('https://info.nhi.gov.tw/INAE1000/INAE1000S00', '_blank')
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        if (selectedHospital && !isChecked) {
            setSelectedHospital({})
        } else {
            setSelectedHospital({ ...selectedItem, isSelected: true })
        }
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.hospNo === item.hospNo ? isChecked : false,
            }
        })

        setDataList(modifiedList)
    }

    return (
        <Layout>
            {/* 健保分局 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>健保分局：</Title>
                    <div className="flex items-center ml-4">
                        {HealthCareOptions.map((option) => (
                            <RadioButton
                                wrapperClassName={'w-[80px]'}
                                mode={healthCare}
                                modeChecked={option.value}
                                key={`healthCare_${option.label}`}
                                handleModeChange={handleHealthCareChange}
                                label={option.label}
                                name="healthCare"
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <Button
                        classNames={SecondButtonStyle}
                        text={'健保署地圖式查詢網頁'}
                        onClickFn={handleOpenHealthCare}
                    />
                </div>
            </div>
            {/* 特約類別 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>特約類別：</Title>
                    <div className="flex items-center ml-4">
                        {HospitalTypeOptions.map((option, index) => (
                            <RadioButton
                                wrapperClassName={
                                    index === 3 ? 'w-[80px]' : 'w-[120px]'
                                }
                                mode={hospitalType}
                                modeChecked={option.value}
                                handleModeChange={handleHospitalTypeChange}
                                label={option.label}
                                key={`hospitalType_${option.label}`}
                                name="hospitalType"
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/* 快速選擇 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <Title>快速選擇：</Title>
                    <div className="flex items-center ml-4">
                        {QuickSelector.map((option, index) => (
                            <RadioButton
                                wrapperClassName={
                                    index === 0 ? 'w-[150px]' : 'w-[120px]'
                                }
                                mode={quickSelector}
                                modeChecked={option.value}
                                handleModeChange={handleQuickSelectorChange}
                                label={option.label}
                                name="quickSelector"
                                key={`quickSelector_${option.label}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex p-2 items-center w-full">
                <div className="flex items-center">
                    <Title>院所名稱：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                    <Title extendClass={'ml-4'}>院所代碼：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospNo}
                        onChange={handleHospNoChange}
                    />
                    <Title extendClass={'ml-4'}>院所地址：</Title>
                    <BaseInput
                        className={`${InputStyle} w-[230px]`}
                        type={InputTypeEnum.Text}
                        value={hospitalAddress}
                        onChange={handleHospitalAddressChange}
                    />
                    <Title extendClass={'ml-4'}>高雄地區：</Title>

                    <select
                        className={`${SelectStyle} w-[100px]`}
                        value={kaohsiungArea}
                        onChange={(e) => {
                            handleKaohsiungAreaChange(e)
                        }}
                    >
                        <option value=""></option>
                        {zipCodeList.map((item) => (
                            <option
                                key={`kaohsiung_${item.no}`}
                                value={item.no}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>
                    <Button
                        classNames={`ml-4 ${ButtonStyle}`}
                        text={'查詢'}
                        onClickFn={handleClickQuery}
                    />
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                isSingleSelect
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            <div className="w-full border-b-2 mt-3"></div>
            <div className="flex justify-end my-3 mr-2">
                <Button
                    classNames={`ml-4 ${ButtonStyle}`}
                    text={'確定'}
                    onClickFn={() => onConfirm(selectedHospital)}
                />
            </div>
        </Layout>
    )
}

const ReferralHospitalModal = ({
    onConfirm,
    closePopupButtonOnClick,
    zipCodeList,
}) => {
    return (
        <BasePopup
            content={
                <ReferralHospital
                    onConfirm={onConfirm}
                    zipCodeList={zipCodeList}
                />
            }
            title="轉診院所查詢"
            width="80%"
            closePopupButtonOnClick={closePopupButtonOnClick}
        />
    )
}

export default ReferralHospitalModal
