import React, {useCallback, useMemo, useState} from 'react'
import {ReactComponent as AddIcon} from '../../../assets/images/icons/add_circle.svg';
import CustomTable from '../RSISCustomTable';
import ListItem from './ListItem';
import {usePopup} from '../PopupProvider';
import DeletePopup from '../Popup/DeletePopup';
import {ApiErrorStatusEnum, ToastTypeEnum} from "edah_utils/dist"
import {rsisAddMail, rsisDelMail, rsisEditMail, rsisQueryCodeValueCodeType, rsisQueryMail} from "../../../api/v1/RSIS";
import QueryCreateUserList from "../Modal/QueryCreateUserList";
import {t} from "i18next"

// 表格欄位
const fields = [
    "",
    "事件代號",
    "事件說明",
    "員工代號",
    "起始日期",
    "結束日期",
]

const MailListMaintain = () => {
    const [dataList, setDataList] = useState([])
    // 事件代號
    const [mailType, setMailType] = useState([])

    const List = ListItem
    const {
        showDeletePopup,
        setShowDeletePopup,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        setFocusItem,
        focusItem,
        showToast,
    } = usePopup()

    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
    })

    /**
     * 當列表項目更新時
     * @param {object} item 列表項目
     * @return {void}
     */
    const handleItemOnUpdate = (item) => {
        // 設定當前焦點項目
        setFocusItem(item)
        // 關閉彈窗
        setShowQueryCreateUserList(false)
    }

    /**
     * 新增列表項目
     * @return {void}
     */
    const handleAddItemOnClick = () => {
        const newItem = {
            mailListsUUid: `new-${Date.now()}`,
            editable: true,
            mailType: "",
            codeValue1: "",
            userNo: "",
            userName: "",
            effStartDatetime: "",
            effEndDatetime: "",
            memo: "",
            isNew: true
        };
        setDataList(prevDataList => [newItem, ...prevDataList]);
    }

    /**
     * 保存或編輯列表項目
     * @param item
     * @param statusOnChange {function} 編輯成功時改變狀態的回調函數
     * @return {Promise<void>}
     */
    const handleSaveItemOnClick = async (item, statusOnChange) => {
        // 如果是新增的列表項目，則執行onSave
        if (item.isNew) {
            rsisAddMail({
                mailListsUuid: '',
                mailType: item.mailType,
                codeValue1: item.codeValue1,
                userNo: item.userNo,
                userName: item.userName,
                effStartDatetime: `${item.effStartDatetime} 00:00:00`,
                effEndDatetime: `${item.effEndDatetime} 00:00:00`,
                memo: ''
            }).then(res => {
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '保存成功', type: ToastTypeEnum.Success})
                    // 新增成功後，將列表項目狀態改為不可編輯
                    statusOnChange()
                    setDataList([])
                    getQueryMailUser()
                } else {
                    showToast({message: `保存失敗，${msg}`, type: ToastTypeEnum.Error})
                }
            })
        } else {// 如果是編輯的列表項目，則執行onSave成功後退回非編輯狀態
            const data = {
                mailListsUuid: item.mailListsUUid,
                mailType: item.mailType,
                userNo: item.userNo,
                effStartDatetime: `${item.effStartDatetime} 00:00:00`,
                effEndDatetime: `${item.effEndDatetime} 00:00:00`,
                memo: ''
            }
            rsisEditMail(data).then(res => {
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '保存成功', type: ToastTypeEnum.Success})
                    statusOnChange()
                    setDataList([])
                    getQueryMailUser()
                    // setDataList(prevList => prevList.map(i => i.mailListsUUid === item.mailListsUUid ? data : i))
                } else {
                    showToast({message: `保存失敗，${msg}`, type: ToastTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 修改現有列表項目的資料
     * @param {string} id - 列表項目 ID
     * @param {field} string - 列表項目欄位
     * @param {value} string - 列表項目值
     * @return {void}
     */
    const handleItemChange = useCallback((id, field, value) => {
        setDataList(prevDataList => prevDataList.map(item =>
            item.id === id ? {...item, [field]: value} : item
        ))
    }, [])

    /**
     * 取消新增列表項目
     * @param mailListsUUid
     */
    const handleCancelItemOnClick = (mailListsUUid) => {
        setDataList(prevDataList => prevDataList.filter(item => item.mailListsUUid !== mailListsUUid));
    }

    /**
     * 刪除Mail發送人員名單
     * @return {void}
     */
    const handleListItemOnDelete = () => delMailUser()

    /**
     * 刪除Mail發送人員名單
     * @return {void}
     */
    const delMailUser = () => {
        rsisDelMail({mailListsUuid: focusItem.mailListsUUid})
            .then(res => {
                const {err, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    setDataList(prevDataList => prevDataList.filter(item => item.mailListsUUid !== focusItem.mailListsUUid))
                    showToast({message: '刪除成功', type: ToastTypeEnum.Success})
                    setShowDeletePopup(false)
                } else {
                    showToast({message: `刪除失敗，${msg}`, type: ToastTypeEnum.Error})
                }

            })
    }


    /**
     * Mail發送人員名單維護--查詢
     * @return {void}
     */
    const getQueryMailUser = () => {
        rsisQueryMail({
            pageNum: paginationProps.pageNum,
            pageSize: paginationProps.pageSize
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(data)
                setPaginationProps(prev => ({
                    ...prev,
                    totalItemSize: data.length,
                    totalPageSize: 1,
                    currentPageItemSize: data.length
                }))
            } else {
                showToast({message: `取得失敗，${msg}`, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValueCodeType({
            codeType: 'EVENT_MAIL_LIST',
            effFlag: 'Y',
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setMailType(data)
            } else {
                setMailType([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }).catch(error => {
            setMailType([])
            showToast({message: '獲取數據失敗', type: ToastTypeEnum.Error})
        })
    }

    // 第一次執行時
    useMemo(() => {
        // 取得所有基本資料設定
        getPurposeOptions()
        // 取得Mail發送人員名單
        getQueryMailUser()
    }, [])

    return (
        <div className='w-full px-6 py-2'>
            {/*新增按鈕*/}
            <div className='w-[76px] flex flex-row items-center gap-1 my-4 hover:cursor-pointer'
                 onClick={handleAddItemOnClick}>
                <AddIcon className="w-[33.33px] h-[33.33px]"/>
                <p className='text-[#38A169]'>{t('general.add')}</p>
            </div>
            <CustomTable
                advancedSearch={true}
                fields={fields}
                dataList={dataList}
                ListItem={ListItem}
                type="RSISMailListTable"
                paginationProps={paginationProps}
                handleOnListItemSave={handleSaveItemOnClick}
                handleOnListItemCancel={handleCancelItemOnClick}
                handleOnListItemChange={handleItemChange}
                extraDropDownListData={mailType}/>
            {
                showDeletePopup &&
                <DeletePopup
                    handleDeletePopupClose={() => setShowDeletePopup(false)}
                    handleNotificationDelete={handleListItemOnDelete}
                    currentKey={focusItem}/>
            }
            {
                showQueryCreateUserList && (
                    <QueryCreateUserList
                        handleOnClose={() => setShowQueryCreateUserList(false)}
                        handleOnUpdate={handleItemOnUpdate}
                    />
                )
            }
        </div>
    )
}

export default MailListMaintain
