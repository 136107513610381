import React from "react";

function SearchBar({ searchTerm, setSearchTerm }) {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
      <div className="px-5 flex justify-center items-center h-[60px] top-0 sticky bg-[#F4F4F5]">
        <input
            type="text"
            className="w-full h-[27px] px-2 border rounded-[5px] focus:outline-none focus:border-blue-500"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
        />
      </div>
  );
}

export default SearchBar;
