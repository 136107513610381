import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";

/**
 * 工具性日常生活活動量表(IADL)
 * @return {JSX.Element}
 */
const IADL = () => {
    // 預設表單資料
    const [formData, setFormData] = useState({
        // 評估日期
        evaluationDate: "",

        // 評估人員
        evaluator: "",

        // 各項目的分數
        items: {
            // 使用電話能力
            phoneAbility: {score: 0},
            // 購物
            shopping: {score: 0},
            // 準備食物
            prepareFood: {score: 0},
            // 家事維持
            houseKeeping: {score: 0},
            // 清洗衣物
            cleanCloth: {score: 0},
            // 使用交通方式
            communicate: {score: 0},
            // 自行服藥能力
            takeMedicine: {score: 0},
            // 處理財務能力
            handleMoney: {score: 0},
        }
    });

    /**
     * 處理輸入改變事件
     * @param e {Event} 事件物件
     * @param item {String} 項目名稱
     * @return {void}
     **/
    const handleInputChange = (e, item) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            items: {
                ...formData.items,
                [item]: {score: parseInt(value)}
            }
        });
    };

    // 計算總分
    const totalScore = Object.values(formData.items).reduce((sum, item) => sum + item.score, 0);

    // 各項目對應的內容
    const itemDescriptions = {
        phoneAbility: "使用電話能力",
        shopping: "購物",
        prepareFood: "準備食物 (男性不用評)",
        houseKeeping: "家事維持(男性不用評)",
        cleanCloth: "清洗衣物(男性不用評)",
        communicate: "使用交通方式",
        takeMedicine: "自行服藥能力",
        handleMoney: "處理財務能力",
    };

    // 各分數對應的內容
    const scoreDescriptions = {
        // 使用電話能力
        phoneAbility: {
            1: "能自如操作控制",
            2: "能撥少數已知電話號碼",
            3: "能對講但無法撥電話",
            4: "無法使用電話"
        },
        // 購物
        shopping: {
            1: "能自行購物",
            2: "對於少量消費，能自行購物",
            3: "任何購物時，皆須陪伴",
            4: "完全無法去購物"
        },
        // 準備食物
        prepareFood: {
            1: "能獨立自行計劃、準備、烹調適當餐點",
            2: "若能提供食物主要成份時，可準備適當餐點",
            3: "只能煮熟食物或餐點",
            4: "須由他人提供餐點"
        },
        // 家事維持
        houseKeeping: {
            1: "能獨自維持家事或協助下可做粗重工作，如拖地",
            2: "若執行輕鬆工作，如洗碗",
            3: "能執行輕鬆工作但無法維持清潔",
            4: "須別人協助做所有家事",
            5: "完全不參與任何家事工作"
        },
        // 清洗衣物
        cleanCloth: {
            1: "能完成個人衣物清洗",
            2: "能清洗小件衣物，如襪子、手帕",
            3: "完全由他人執行衣物清洗"
        },
        // 使用交通方式
        communicate: {
            1: "能自行使用公共運輸或駕車外出",
            2: "藉由計程車安排外出，但無法使用公共運輸工具",
            3: "由他人陪伴或協助使用公共運輸工具外出",
            4: "由他人陪伴或協助使用計程車或駕車外出。",
            5: "完全無法外出旅遊"
        },
        // 自行服藥能力
        takeMedicine: {
            1: "能自行按時且劑量正確服藥",
            2: "由他人準備好藥物後自行服藥",
            3: "無法自行服藥"
        },
        // 處理財務能力
        handleMoney: {
            1: "能獨立處理財務(如預算、寫支票、至銀行、付租金)及收集维持收入",
            2: "能處理每日消費，但銀行等主要財務處理須協助",
            3: "無法處理錢財"
        }
    };

    return (
        <div className="w-full p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-2.5 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>工具性日常生活活動量表(IADL)</span>
            </h3>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                    value={formData.evaluationDate}
                    onChange={(e) => setFormData({...formData, evaluationDate: e.target.value})}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                    value={formData.evaluator}
                    onChange={(e) => setFormData({...formData, evaluator: e.target.value})}
                />
            </div>

            {/* 各項目評分 */}
            <div className="grid grid-cols-1 gap-4">
                <div className="flex flex-row items-center justify-center">
                    <div className="w-full flex flex-col justify-around mt-2">
                        <ul className="w-[1194px]">
                            <li className="flex flex-row items-center justify-start h-[42px] w-full border-[1px] border-[#111111]/15">
                                <div
                                    className="w-[307px] h-full flex items-center justify-center flex-shrink-0 font-bold border-r-[1px] border-[#111111]/15">項目
                                </div>
                                <div className="flex flex-row items-center justify-start h-full">
                                    <p className="w-[48px] flex items-center justify-center flex-shrink-0 h-full border-r-[1px] border-[#111111]/15">分數</p>
                                    <label className="w-[791px] flex items-center justify-start h-full px-2 border-r-[1px] border-[#111111]/15">日常生活功能量表紀錄單-巴氏量表</label>
                                </div>
                                <p className="w-[48px] text-center font-bold">得分</p>
                            </li>
                            {Object.keys(formData.items).map((item, index) => (
                                <li key={index} className="flex flex-row items-center justify-start border-[1px] border-t-0 border-[#111111]/15">
                                    <div
                                        className="w-[306px] h-full flex items-center justify-start px-4 flex-shrink-0">{itemDescriptions[item]}
                                    </div>
                                    <ul className="border-x border-[#111111]/15">
                                        {Object.keys(scoreDescriptions[item]).map((score) => (
                                            <li>
                                                <div className="flex flex-row items-center justify-start h-full border-t-0 border-[#111111]/15">
                                                    <p className="w-[48px] py-1 flex items-center justify-end pr-2 border-r border-[#111111]/15">{score}</p>
                                                    <label key={score}
                                                           className="w-[791px] flex flex-row items-center justify-start px-2 py-1">
                                                        <input
                                                            className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                                            type="radio"
                                                            name={item}
                                                            value={score}
                                                            checked={formData.items[item].score === parseInt(score)}
                                                            onChange={(e) => handleInputChange(e, item)}
                                                            style={{accentColor: "#2B6CB0"}}
                                                        />
                                                        <span className="ml-2">{scoreDescriptions[item][score]}</span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="w-full text-center font-bold">
                                        {formData.items[item].score}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* 總分顯示 */}
            <div className="w-[1194px] flex flex-row items-center justify-center border-x border-b border-[#D4D4D8]">
                <p className="w-full py-1 border-r border-[#111111]/15">總分</p>
                <p className="w-[48px] py-1">{totalScore}</p>
            </div>

        </div>
    );
};

export default IADL;
