import React, { useState, useEffect, useRef } from 'react';
import { BaseInput} from "../../Input/BaseInput";
import { BasePopup} from "../../Popup/BasePopup";
import { t } from "i18next";
import {InputTypeEnum} from "edah_utils/dist";
import {
    arrayIsEmpty,
    objectIsEmpty,
    multiTermFuzzySearchObjects,
    fuzzySearchObjects,
    stringIsEmpty
} from "edah_utils/dist";
import useOutsideClick from '../../../hooks/useOutsideClick';

/**
 * 標語編輯彈出窗口組件
 * 根據選擇的標語類別顯示不同的表單字段
 *
 * @param {Object} props - 組件屬性
 * @param {boolean} props.isOpen - 控制彈出窗口是否打開
 * @param {function} props.onClose - 關閉彈出窗口的回調函數
 * @param {function} props.onSave - 保存標語數據的回調函數
 * @param {object|null} props.editingSlogan - 正在編輯的標語對象，如果是新增則為 null
 * @param {Array<Object>} sloganTypeList - 標語類別Array
 * @return {JSX.Element|null} 返回彈出窗口組件或 null（當 isOpen 為 false 時）
 */
const SloganEditPopup = ({ 
    isOpen, 
    onClose,
    onSave, 
    editingSlogan, 
    sloganTypeList,
    doctorList,
    departmentList
 }) => {
    // 時段列
    const apnList = [
        {
            no: '1',
            name: t("general.dateTime.timeslot.short.morning")
        },
        {
            no: '2',
            name: t("general.dateTime.timeslot.short.afternoon")
        },
        {
            no: '3',
            name: t("general.dateTime.timeslot.short.night")
        }
    ]
    /**
     * 標語數據狀態
     * @type {[Object, function]} 標語數據對象和更新函數
     */
    const [slogan, setSlogan] = useState({
        categoryNo: '',
        name: '',
        doctorCode: '',
        departmentCode: '',
        time: '',
        content: ''
    });
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });
    
    /**
     * 處理表單輸入變化
     * @param {Event} e - 輸入事件對象
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSlogan(prev => ({ ...prev, [name]: value }));
    };

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setShowDoctorDropDown(true)
    }

    /**
     * 科別代號方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setShowDepartmentDropDown(true)
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        setSlogan({
            ...slogan,
            doctorCode: `${item.userNo} ${item.userName}`
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (item) => {
        setSlogan({
            ...slogan,
            departmentCode: `${item.divNo} ${item.divName}`
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 處理保存操作
     */
    const handleSave = () => {
        onSave(slogan);
        //onClose();
    };

    /**
     * 取得標語類別No 透過標語類別名稱
     * @param {String} name 
     * @returns 
     */
    const getSloganNoBySloganName = (name) => {
        const findedSlogan = sloganTypeList.find(item => item.justnamedName === name)
       return !objectIsEmpty(findedSlogan) ? findedSlogan.justnamedNo : ''
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if(!isOpen) {
            //重置欄位
            setSlogan({
                categoryNo: !arrayIsEmpty(sloganTypeList) ? sloganTypeList[0].justnamedNo: '',
                name: '',
                doctorCode: '',
                departmentCode: '',
                time: '',
                content: ''
            })
        }
        else {
            if (editingSlogan) {
                let doctor = ''
                let department = ''
                //串接醫師代號及醫師名稱
                if(!stringIsEmpty(editingSlogan.doctorNo)) {
                    doctor = editingSlogan.doctorNo
                    if(!stringIsEmpty(editingSlogan.doctorName)) {
                        doctor = `${doctor} ${editingSlogan.doctorName}`
                    }
                }
                //串接科別代碼及科別名稱
                if(!stringIsEmpty(editingSlogan.divNo)) {
                    department = editingSlogan.divNo
                    if(!stringIsEmpty(editingSlogan.divName)) {
                        department = `${department} ${editingSlogan.divName}`
                    }
                }
                setSlogan({
                    categoryNo: editingSlogan.slognType,
                    name: editingSlogan.slognName,
                    doctorCode: doctor,
                    departmentCode: department,
                    time: editingSlogan.apn !== null ? editingSlogan.apn.toString() : '',
                    content: editingSlogan.slognContent
                })
            } else {
                setSlogan({
                    categoryNo: !arrayIsEmpty(sloganTypeList) ? sloganTypeList[0].justnamedNo: '',
                    name: '',
                    doctorCode: '',
                    departmentCode: '',
                    time: '',
                    content: ''
                });
            }
        }
    }, [isOpen])

    /**
     * 根據選擇的類別返回應該顯示的字段
     * @returns {Array} 應該顯示的字段數組
     */
    const getVisibleFields = () => {
        switch (slogan.categoryNo) {
            //單據
            case '01':
                return ['name', 'content'];
            //科別
            case '03':
                return ['name', 'departmentCode', 'content'];
            //科別_時段
            case '04':
                return ['name', 'departmentCode', 'time', 'content'];
            //醫師_時段
            case '02':
                return ['name', 'doctorCode', 'time', 'content'];
            //醫師_科別_時段
            case '05':
                return ['name', 'departmentCode', 'doctorCode', 'time', 'content'];
            default:
                return ['name', 'departmentCode', 'doctorCode', 'time', 'content'];
        }
    };

    /**
     * 取得過濾後的醫師清單
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getFilterDoctorList = () => {
        const splitArray = slogan.doctorCode ? slogan.doctorCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
             return doctorList.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(doctorList, slogan.doctorCode)
        }
    }

    /**
     * 取得過濾後的科別清單
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getFilterDepartmentList = () => {
        const splitArray = slogan.departmentCode ? slogan.departmentCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
            return departmentList.filter(department => department.divNo.includes(splitArray[0]) && department.divName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(departmentList, slogan.departmentCode)
        }
    }

    /**
     * 渲染表單字段
     * @param {string} field - 字段名稱
     * @returns {JSX.Element} 表單字段的 JSX
     */
    const renderField = (field) => {
        switch (field) {
            case 'name':
                return (
                    <div className="flex flex-row items-center justify-start" key={field}>
                        <label className="w-[160px] mr-4 pr-4 text-right">標語名稱</label>
                        <BaseInput
                            className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            name="name"
                            value={slogan.name}
                            onChange={handleChange}
                        />
                    </div>
                );
            case 'departmentCode':
                return (
                    <div className="flex flex-row items-center justify-start" key={field}>
                        <label className="w-[160px] mr-4 pr-4 text-right">{t('Regs.MedicalMessageManagement.departmentCodeNum')}</label>
                        <div className='relative' ref={dropdownDepartmentRef}>
                            <BaseInput
                                className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type={InputTypeEnum.Text}
                                inputMode="search"
                                name="departmentCode"
                                value={slogan.departmentCode}
                                onChange={handleChange}
                                onFocus={handleInputDepartmentNoOnFocus}
                            />
                            {
                                showDepartmentDropDown && !arrayIsEmpty(departmentList) &&
                                (
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                getFilterDepartmentList().map((item, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleDepartmentOptionOnClick(item)}
                                                        >
                                                        <p>{`${item.divNo} ${item.divName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                );
            case 'doctorCode':
                return (
                    <div className="flex flex-row items-center justify-start" key={field}>
                        <label className="w-[160px] mr-4 pr-4 text-right">{t('Regs.MedicalMessageManagement.doctorCodeNum')}</label>
                        <div className='relative' ref={dropdownDoctorRef}>
                            <BaseInput
                                className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type={InputTypeEnum.Text}
                                inputMode="search"
                                name="doctorCode"
                                value={slogan.doctorCode}
                                onChange={handleChange}
                                onFocus={handleInputDoctorNoOnFocus}
                            />
                            {
                                showDoctorDropDown && !arrayIsEmpty(doctorList) &&
                                (
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                getFilterDoctorList().map((item, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleDoctorOptionOnClick(item)}
                                                        >
                                                        <p>{`${item.userNo} ${item.userName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                );
            case 'time':
                return (
                    <div className="flex flex-row items-center justify-start" key={field}>
                        <label className="w-[160px] mr-4 pr-4 text-right">{t('Regs.general.timeSlot')}</label>
                        <div className="flex space-x-4">
                            {apnList.map((apn) => (
                                <label key={apn.no} className="flex items-center">
                                    <input
                                        className="form-radio w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                        style={{accentColor: "#2B6CB0"}}
                                        type="radio"
                                        name="time"
                                        value={apn.no}
                                        checked={slogan.time === apn.no}
                                        onChange={handleChange}
                                    />
                                    {apn.name}
                                </label>
                            ))}
                        </div>
                    </div>
                );
            case 'content':
                return (
                    <div className="flex flex-row items-start justify-start" key={field}>
                        <label className="w-[160px] mr-4 pr-4 text-right">{t('Regs.MedicalMessageManagement.sloganContent')}</label>
                        <textarea
                            className="w-[235px] h-24 px-4 py-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            name="content"
                            value={slogan.content}
                            onChange={handleChange}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    /**
     * 彈出窗口內容
     * @type {JSX.Element}
     */
    const popupContent = (
        <div className="p-6">
            <div className="space-y-4">
                <div className="flex flex-row items-center justify-start">
                    <label className="w-[160px] mr-4 pr-4 text-right">{t('Regs.MedicalMessageManagement.sloganCategory')}</label>
                    <select
                        name="categoryNo"
                        value={slogan.categoryNo}
                        onChange={handleChange}
                        disabled={editingSlogan}
                        className="w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    >
                    {
                        !arrayIsEmpty(sloganTypeList) && sloganTypeList.map((item) => (
                            <option key={item.justnamedNo} value={item.justnamedNo}>{item.justnamedName}</option>
                        ))
                    }
                    </select>
                </div>
                {getVisibleFields().map(renderField)}
            </div>
            <div className="mt-6 flex justify-end space-x-4">
                <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-[#2B6CB0] text-white rounded-[6px]"
                >
                    {t('general.save')}
                </button>
            </div>
        </div>
    );

    return isOpen ?
        <BasePopup
            title={editingSlogan ? t('general.edit') : t('general.add')}
            content={popupContent}
            closePopupButtonOnClick={onClose}
            width="500px"
        /> :
        <></>
};

export default SloganEditPopup;