//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {iccmUploaDrecQueryUploadData} from "../../api/v1/ICCM"
import useToast from "../../hooks/useToast"
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist"
import {ActiveTab} from "../../components/ICCard/UploadSearchPage/ActiveTab"
import {BaseInput} from "../../components/Input/BaseInput"

/**
 * IC卡資料上傳資料查詢
 * @return {JSX.Element}
 */
const UploadSearchPage = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 就醫起始日期
    const [startEncounterDate, setStartEncounterDate] = useState('')

    // 就醫結束日期
    const [endEncounterDate, setEndEncounterDate] = useState('')

    //資料
    const [data, setData] = useState([])

    //搜尋文字
    const [advancedSearch, setAdvancedSearch] = useState('')

    /**
     * 就醫起始日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleStartEncounterDateOnChange = (e) => setStartEncounterDate(e.target.value)

    /**
     * 就醫結束日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEndEncounterDateOnChange = (e) => setEndEncounterDate(e.target.value)

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryUploadData()

    /**
     * 進階搜尋改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchOnChange = (e) => setAdvancedSearch(e.target.value)

    /**
     * 取得篩選資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearch)

    /**
     * 取得IC卡資料上傳查詢
     * @return {void}
     */
    const getQueryUploadData = () => {
        iccmUploaDrecQueryUploadData({
            //就醫起始日期
            startEncounterDate: `${startEncounterDate} 00:00:00`,
            //就醫結束日期
            endEncounterDate: `${endEncounterDate} 23:59:59`
        }).then(res => {
            //狀態 / 資料 / 訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //設定資料
                setData(data)
            } else { //取得失敗
                //清空資料
                setData([])
                //顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">就醫日期</p>
                    {/*就醫起始日期*/}
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} value={startEncounterDate}
                               onChange={handleStartEncounterDateOnChange}/>
                    <p className="m-1">~</p>
                    {/*就醫結束日期*/}
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} value={endEncounterDate}
                               onChange={handleEndEncounterDateOnChange}/>
                </div>
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    {/*查詢按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleQueryOnClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-[891px] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between px-2 mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type={InputTypeEnum.Text} inputMode={InputTypeEnum.Search}
                                   placeholder={t('general.advancedSearch')} value={advancedSearch}
                                   onChange={handleAdvancedSearchOnChange}/>
                    </div>
                    <ul className="tableList icUploadList">
                        <li className="title">
                            <div>序號</div>
                            <div>上傳時間</div>
                            <div>代號</div>
                            <div>下載時間</div>
                            <div>是否成功</div>
                            <div>無效明細筆數</div>
                        </li>
                        {
                            !arrayIsEmpty(getFilterData) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*序號*/}
                                    <div>{item.icUploadrecId}</div>
                                    {/*上傳時間*/}
                                    <div>{item.uploadDateTime}</div>
                                    {/*代號*/}
                                    <div>{item.dataType}</div>
                                    {/*下載時間*/}
                                    <div>{item.downloadDateTime}</div>
                                    {/*是否成功*/}
                                    <div>{item.uploadStatus}</div>
                                    {/*無效明細筆數*/}
                                    <div>{item.invalidRecs}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <ActiveTab/>
            </div>
        </div>
    );
};

export default UploadSearchPage;
