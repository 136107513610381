import TabContainer from "./TabContainer";
import TabItem from "./TabItem";
import React from "react";
import {addTab, setActiveTabId} from "../../redux/Slice/TabSlice";
import store from "../../redux/store"; // 移除 useState 因为它在这个组件中未被使用

function Tab({ tabList, remove, activeTabId }) {
    const handleTabClick = (id) => {
        store.dispatch(setActiveTabId({id}))
        console.log(id); // 如果需要，保留对点击的 Tab id 的记录
    };

  return (
    <div className="w-full h-[42px] bg-[#E4E4E7] flex items-end">
      <ul className="flex overflow-auto">
        {tabList.map((tab) => (
          <li className="flex flex-col" key={tab.id}>
            {" "}
            {/* 修改了这里，把 div 改成了 li */}
            <TabItem
              name={tab.name}
              id={tab.id}
              onClose={() => remove(tab.id)} // 确保这里能正确传递 id
              isActive={tab.id === activeTabId}
              onClick={() => handleTabClick(tab.id)} // 直接使用 handleTabClick 来处理点击事件
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tab;
