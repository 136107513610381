import React, { useEffect, useState } from 'react'
import Button from '../../Button/Button'
import { BaseInput } from '../../Input/BaseInput'
import {
    InputTypeEnum,
    objectIsEmpty,
    ApiErrorStatusEnum,
    ToastTypeEnum,
    stringIsEmpty,
} from 'edah_utils/dist'
import ReferralHospitalModal from '../ReferralHospitalModal'
import DivisionDatalist from '../DivisionDatalist'
import { queryTranCodeList } from '../../../api/v1/Rmis'
import useToast from '../../../hooks/useToast'
import { t } from 'i18next'

const InputStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}
const Layout = ({ children }) => {
    return (
        <div className="ml-4 p-8 w-full h-[calc(100vh-98px)] overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md	">
            {children}
        </div>
    )
}
/**
 * [轉診原因-本院無該檢查]代碼
 */
const TRAN_REASON_NO_REVELANT_TEST = '46'
/**
 * [轉診原因-本院無該治療]代碼
 */
const TRAN_REASON_NO_REVELANT_TREATMENT = '47'
/**
 * [轉診原因-其他]代碼
 */
const TRAN_REASON_OTHER = '6'
/**
 * [VPN原因-其他]代碼
 */
const VPN_REASON_OTHER = '9'
/**
 * 轉診單
 * @param {object} tranOutInfo 轉診單資料
 * @param {array} divisionList 科別下拉選單
 * @param {array} vpnReasonList vpn原因下拉選單
 * @param {funcion} onUpdateData 更新轉診單資料
 * @param {array} zipCodeList 郵遞區號下拉選單
 * @returns {Element}
 * @constructor
 */
const ReferralTurnOutModal = ({
    tranOutInfo,
    divisionList = [],
    vpnReasonList = [],
    onUpdateData,
    zipCodeList = [],
}) => {
    // 轉診單號
    const [tranNo, setTranNo] = useState('')

    // 註銷
    const [cancelFlag, setCancelFlag] = useState(true)

    // 轉出院所
    const [tranOutHospNo, setTranOutHospNo] = useState('')
    const [tranOutHospName, setTranOutHospName] = useState('')

    // 建議轉診科別
    const [tranOutDivNo, setTranOutDivNo] = useState('')
    const [tranOutDivName, setTranDivOutName] = useState('')

    // 建議轉診醫師
    const [tranOutDoctorName, setTranOutDoctorName] = useState('')

    // 轉出原因
    const [tranReason, setTranReason] = useState('')

    // 轉出原因(其他)
    const [tranReasonRemark, setTranReasonRemark] = useState('')

    // 本院無治療或檢查原因
    const [tranReasonRemark2, setTranReasonRemark2] = useState('')

    // 轉出目的
    const [tranPurpose, setTranPurpose] = useState('')

    // 轉出目的(其他)
    const [tranPurposeRemark, setTranPurposeRemark] = useState('')

    // VPN原因-其他原因
    const [otherReason, setOtherReason] = useState('')

    // 基礎資料
    const [baseDropdownData, setBaseDropdownData] = useState({
        // 轉診原因
        tranPurposeList: [],
        // 轉出原因
        tranReasonList: [],
    })

    // VPN原因
    const [vpnReason, setVpnReason] = useState(vpnReasonList[0]?.vpnReason)

    const [tranReasonRemark2InputState, setTranReasonRemark2InputState] =
        useState(null)

    // toast message
    const showToast = useToast()

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    // 更新註銷
    const handleCancelFlagChange = (e) => setCancelFlag(e.target.checked)

    // 更新轉出院所
    const handleTranOutHospNoChange = (e) => setTranOutHospNo(e.target.value)

    // 更新轉出院所名稱
    const handleTranOutHospNameChange = (e) =>
        setTranOutHospName(e.target.value)

    // 更新建議轉診醫師
    const handleTranOutDoctorNameChange = (e) =>
        setTranOutDoctorName(e.target.value)

    // 更新轉出原因(其他)
    const handleTranReasonRemarkChange = (e) =>
        setTranReasonRemark(e.target.value)

    // 更新本院無治療或檢查原因
    const handleTranReasonRemark2Change = (e) =>
        setTranReasonRemark2(e.target.value)

    // 更新轉出目的(其他)
    const handleTranPurposeRemarkChange = (e) =>
        setTranPurposeRemark(e.target.value)

    // 更新vpn原因 - 其他原因
    const handleOtherReasonChange = (e) => setOtherReason(e.target.value)

    // 更新轉出原因
    const handleTranReasonChange = (e) => {
        const value = e.target.value
        const uploadCodeNo =
            baseDropdownData.tranReasonList.find(
                (item) => value === item.codeNo
            )?.uploadCodeNo || ''
        const tranPurposeCode = baseDropdownData.tranPurposeList.find(
            (item) => item.codeNo === uploadCodeNo
        )?.codeNo
        setTranReason(value)
        setTranPurpose(tranPurposeCode)
    }

    // 更新轉診目的
    const handleTranPurposeChange = (e) => setTranPurpose(e.target.value)

    // 更新vpn原因
    const handleVpnReasonChange = (e) => setVpnReason(e.target.value)

    /**
     * 開啟院所查詢視窗
     */
    const showReferralHospital = () => {
        setIsShowReferralHospitalModal(true)
    }

    /**
     * 選擇院所
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setTranOutHospNo(hospital.hospNo)
        setTranOutHospName(hospital.hospName)
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 選擇科別
     * @param {object} division 選擇的科別
     * @returns
     */
    const selectDivision = (division) => {
        if (!division) {
            return
        }
        setTranOutDivNo(division?.divNo)
        setTranDivOutName(division.divName)
    }

    // 轉出原因(本院無治療或檢查原因)欄位狀態: 無狀態:null/檢核通過:true/檢核失敗:false
    useEffect(() => {
        if (
            tranReason !== TRAN_REASON_NO_REVELANT_TEST &&
            tranReason !== TRAN_REASON_NO_REVELANT_TREATMENT
        ) {
            setTranReasonRemark2InputState(null)
        } else {
            setTranReasonRemark2InputState(!stringIsEmpty(tranReasonRemark2))
        }
    }, [tranReason, tranReasonRemark2])

    useEffect(() => {
        Promise.all([
            queryTranCodeList({ codeType: 'TRAN_REASON' }),
            queryTranCodeList({ codeType: 'TRAN_PURPOSE' }),
        ]).then((res) => {
            const [tranReasonRes, tranPurposeRes] = res
            if (
                tranReasonRes.err === ApiErrorStatusEnum.Success &&
                tranPurposeRes.err === ApiErrorStatusEnum.Success
            ) {
                setBaseDropdownData((prev) => ({
                    ...prev,
                    tranPurposeList: tranPurposeRes.data || [],
                    tranReasonList: tranReasonRes.data || [],
                }))
            } else {
                showToast({
                    message: tranReasonRes.msg,
                    type: ToastTypeEnum.Error,
                })

                showToast({
                    message: tranPurposeRes.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }, [])

    useEffect(() => {
        setTranNo(tranOutInfo?.tranNo || '')
        setCancelFlag(!!tranOutInfo?.cancelFlag)
        setTranOutHospNo(tranOutInfo?.tranOutHospNo || '')
        setTranOutHospName(tranOutInfo?.tranOutHospName || '')
        setTranOutDivNo(tranOutInfo?.tranOutDivNo || '')
        setTranDivOutName(tranOutInfo?.tranOutDivName || '')
        setTranOutDoctorName(tranOutInfo?.tranOutDoctorName || '')
        setTranReason(tranOutInfo?.tranReason || '')
        setTranReasonRemark(tranOutInfo?.tranReasonRemark || '')
        setTranReasonRemark2(tranOutInfo?.tranReasonRemark2 || '')
        setTranPurpose(tranOutInfo?.tranPurpose || '')
        setTranPurposeRemark(tranOutInfo?.tranPurposeRemark || '')
        setTranOutDoctorName(tranOutInfo?.tranOutDoctorName || '')
        setVpnReason(tranOutInfo?.vpnReason || vpnReasonList[0]?.vpnReason)
        setOtherReason(tranOutInfo?.otherReason)
    }, [tranOutInfo])

    // 更新資料傳到父層
    useEffect(() => {
        onUpdateData &&
            onUpdateData({
                lockVersion: tranOutInfo.lockVersion,
                encounterId: tranOutInfo.encounterId,
                encounterDate: tranOutInfo.encounterDate,
                inpOpd: tranOutInfo.inpOpd,
                patientId: tranOutInfo.patientId,
                contact: tranOutInfo.contact,
                contactTel: tranOutInfo.contactTel,
                contactAddress: tranOutInfo.contactAddress,
                divNo: tranOutInfo.divNo,
                doctorNo: tranOutInfo.doctorNo,
                treatmentCode: tranOutInfo.treatmentCode,
                treatmentCode1: tranOutInfo.treatmentCode1,
                treatmentCode2: tranOutInfo.treatmentCode2,
                subject: tranOutInfo.subject,
                clinicInfo: tranOutInfo.clinicInfo,
                etranNo: tranOutInfo.etranNo,
                tranDate: tranOutInfo.tranDate,
                tranDoctorNo: tranOutInfo.tranDoctorNo,
                tranOutAidBranchNo: tranOutInfo.tranOutAidBranchNo,
                condition: tranOutInfo.condition,
                otherChoice: tranOutInfo.otherChoice,
                urgentType: tranOutInfo.urgentType,
                urgentReason: tranOutInfo.urgentReason,
                urgentReasonRemark: tranOutInfo.urgentReasonRemark,
                urgentOrderDatetime: tranOutInfo.urgentOrderDatetime,
                orderDatetime: tranOutInfo.orderDatetime,
                uploadDatetime: tranOutInfo.uploadDatetime,
                outRealOutHospNo: tranOutInfo.outRealOutHospNo,
                outRealOutDivNo: tranOutInfo.outRealOutDivNo,
                outReturnDate: tranOutInfo.outReturnDate,
                outReplyDate: tranOutInfo.outReplyDate,
                outReplyDate2: tranOutInfo.outReplyDate2,
                admissionDate: tranOutInfo.admissionDate,
                dischargeDate: tranOutInfo.admissionDate,
                outStatus: tranOutInfo.outStatus,
                outCancerFlag: tranOutInfo.outCancerFlag,
                outRemark: tranOutInfo.outRemark,
                outRemark2: tranOutInfo.outRemark2,
                tranOutAcceptFlag: tranOutInfo.tranOutAcceptFlag,
                idNo: tranOutInfo.idNo,
                outNoteFinishFlag: tranOutInfo.outNoteFinishFlag,
                tranInFinishFlag: tranOutInfo.tranInFinishFlag,

                tranNo: tranNo,
                tranOutHospNo: tranOutHospNo,
                tranOutDivNo: tranOutDivNo,
                tranOutDoctorName: tranOutDoctorName,
                tranReason: tranReason,
                tranReasonRemark: tranReasonRemark,
                tranReasonRemark2: tranReasonRemark2,
                tranPurpose: tranPurpose,
                tranPurposeRemark: tranPurposeRemark,
                otherReason: otherReason,
                cancelFlag: cancelFlag,
                vpnReason: vpnReason,
            })
    }, [
        tranNo,
        tranOutHospNo,
        cancelFlag,
        tranOutHospName,
        tranOutDivNo,
        tranOutDoctorName,
        tranReason,
        tranReasonRemark,
        tranReasonRemark2,
        tranPurpose,
        tranPurposeRemark,
        otherReason,
    ])

    return (
        <Layout>
            {/* 標題 */}
            <div className="flex items-center">
                <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                <div className="ml-4 text-[#2B6CB0] text-[20px]">轉診單</div>
            </div>
            {/* 轉診單號 */}
            <div className="mt-2 grid grid-cols-[130px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉診單號</Title>
                </div>
                <div className="flex items-center">{tranNo}</div>
                <div className={`flex space-x-3 justify-end`}>
                    <input
                        className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                        type="checkbox"
                        style={{ accentColor: '#38A169' }}
                        checked={cancelFlag}
                        onChange={(e) => handleCancelFlagChange(e)}
                    />
                    <label className="text-center leading-[38px]">
                        {t('general.logoff')}
                    </label>
                </div>
            </div>
            {/* 轉出院所 */}
            <div className="mt-2 grid grid-cols-[130px,120px,0.1fr,0.4fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉出院所</Title>
                </div>
                <div className="flex items-center justify-end">
                    <Button
                        classNames={`${SecondButtonStyle} mr-4`}
                        text={'院所查詢'}
                        onClickFn={showReferralHospital}
                    />
                </div>
                <div className="mr-4">
                    <BaseInput
                        className="h-10 text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={tranOutHospNo}
                        onChange={handleTranOutHospNoChange}
                        disable
                    />
                </div>
                <div>
                    <BaseInput
                        className="h-10 text-black rounded-[6px] border border-gray-300 w-[140px]"
                        type={InputTypeEnum.Text}
                        value={tranOutHospName}
                        onChange={handleTranOutHospNameChange}
                        disable
                    />
                </div>
            </div>
            {/* 建議轉診科別 */}
            <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>建議轉診科別</Title>
                </div>
                <div className="flex items-center">
                    <DivisionDatalist
                        divisionList={divisionList}
                        onSelect={selectDivision}
                        value={{
                            divNo: tranOutDivNo || '',
                            divName: tranOutDivName || '',
                        }}
                    />
                </div>
            </div>
            {/* 建議轉診醫師 */}
            <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>建議轉診醫師</Title>
                </div>
                <div className="flex items-center">
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={tranOutDoctorName}
                        onChange={handleTranOutDoctorNameChange}
                    />
                </div>
            </div>
            {/* 轉出原因 */}
            <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉出原因</Title>
                </div>
                <div className="flex items-center">
                    <select
                        className={SelectStyle}
                        value={tranReason}
                        onChange={(e) => {
                            handleTranReasonChange(e)
                        }}
                    >
                        <option value=""></option>
                        {baseDropdownData?.tranReasonList?.map((item) => (
                            <option
                                key={`tran_reason_${item.codeNo}`}
                                value={item.codeNo}
                            >
                                {item.codeNoName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 轉出原因(其他) */}
            {baseDropdownData.tranReasonList.find(
                (item) => tranReason === item.codeNo
            )?.codeNo === TRAN_REASON_OTHER && (
                <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                    <div className="flex items-center justify-end">
                        <Title extendClass={'mr-4'}>轉出原因(其他)</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className={`${InputStyle} w-full`}
                            type={InputTypeEnum.Text}
                            value={tranReasonRemark}
                            onChange={handleTranReasonRemarkChange}
                        />
                    </div>
                </div>
            )}
            {/* 轉出原因(本院無治療或檢查原因) */}
            {(tranReason === TRAN_REASON_NO_REVELANT_TEST ||
                tranReason === TRAN_REASON_NO_REVELANT_TREATMENT) && (
                <div className="mt-2 mb-4 grid grid-cols-[130px,220px,100px,220px,1fr]">
                    <div className="flex items-center justify-end">
                        <Title extendClass={'mr-4'}>本院無治療或檢查原因</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className="w-[200px] h-10 px-2 bg-white text-black rounded-[6px] border"
                            type={InputTypeEnum.Text}
                            value={tranReasonRemark2}
                            onChange={handleTranReasonRemark2Change}
                            inputState={tranReasonRemark2InputState}
                            errorMsg="必填"
                        />
                    </div>
                </div>
            )}
            {/* 轉出目的 */}
            <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>轉出目的</Title>
                </div>
                <div className="flex items-center">
                    <select
                        className={SelectStyle}
                        value={tranPurpose}
                        onChange={(e) => {
                            handleTranPurposeChange(e)
                        }}
                    >
                        <option value=""></option>
                        {baseDropdownData.tranPurposeList.map((item) => (
                            <option
                                key={`tran_purpose_${item.codeNo}`}
                                value={item.codeNo}
                            >
                                {item.codeNoName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 轉出目的(其他) */}
            {baseDropdownData.tranPurposeList.find(
                (item) => tranPurpose === item.codeNo
            )?.codeNoName === '其他' && (
                <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                    <div className="flex items-center justify-end">
                        <Title extendClass={'mr-4'}>轉出目的(其他)</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className={`${InputStyle} w-full`}
                            type={InputTypeEnum.Text}
                            value={tranPurposeRemark}
                            onChange={handleTranPurposeRemarkChange}
                        />
                    </div>
                </div>
            )}
            {/* VPN原因 */}
            <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                <div className="flex items-center justify-end">
                    <Title extendClass={'mr-8'}>VPN原因</Title>
                </div>
                <div className="flex items-center">
                    <select
                        className={SelectStyle}
                        value={vpnReason}
                        onChange={(e) => {
                            handleVpnReasonChange(e)
                        }}
                    >
                        <option value=""></option>
                        {vpnReasonList.map((item) => (
                            <option
                                key={`vpn_${item.vpnReason}`}
                                value={item.vpnReason}
                            >
                                {item.vpnReasonName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 其他原因 */}
            {vpnReasonList.find((item) => vpnReason === item.vpnReason)
                ?.vpnReason === VPN_REASON_OTHER && (
                <div className="mt-2 grid grid-cols-[130px,220px,100px,220px,1fr]">
                    <div className="flex items-center justify-end">
                        <Title extendClass={'mr-4'}>其他原因</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className={`${InputStyle} w-full`}
                            type={InputTypeEnum.Text}
                            value={otherReason}
                            onChange={handleOtherReasonChange}
                        />
                    </div>
                </div>
            )}
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    onConfirm={onSelectHospital}
                    zipCodeList={zipCodeList}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </Layout>
    )
}

export default ReferralTurnOutModal
