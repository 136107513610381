import React, {useEffect, useMemo, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup';
import {isOwnerUser, Title} from '../utils';
import {BaseInput} from '../../Input/BaseInput';
import ChangeRecordQuery from './QueryChangeRecord';
import SupportDateChoice from "./SupportDateChoice";
import {USER_ROLE} from "../userRole";
import {
    getLocalStorage,
    InputTypeEnum,
    objectIsEmpty,
    RsisStepStatusEnum, stringIsEmpty,
    time2String
} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum,
} from "edah_utils/dist"

import {t} from 'i18next'

// import API
import {
    rsisQueryLicenseByDoctor,
    rsisEditMaster,
    rsisMailConfirm,
    rsisQueryCodeValue,
    rsisQueryAgentByUserNo, rsisQueryBaseData
} from '../../../api/v1/RSIS';

import {FuzzySearchInput} from "../FuzzySearchInput";
import QueryPersonList from "./QueryPersonList";
import QuerySupportOrg from "./QuerySupportOrg";
import {checkDoctorLicence} from "../../../api/v1/Menu";

const extendClass = 'w-[160px] justify-end'

// 取得輸入框樣式
const getInputClassName = (width = 186) => `w-[${width}px] h-10 pl-4 bg-white text-black rounded-[6px] border border-gray-300`

/**
 * 報備支援收案確認Popup
 * @param type {string} 彈窗類型
 * @param data {object} 報備支援資料
 * @param doctorList {array} 醫師清單
 * @param orgList {array} 機構清單
 * @param deptList {array} 承辦部門清單
 * @param handleOnUpdate {function} 更新列表事件
 * @param handleOnClose {function} 關閉彈窗事件
 * @return {JSX.Element}
 */
const RSISCaseConfirmation = ({type = 'EDIT', data, doctorList, orgList, deptList, handleOnUpdate, handleOnClose}) => {
    // 批次處理資料清單
    const [dataList, setDataList] = useState([])
    // 代理人下拉清單
    const [agentUserList, setAgentUserList] = useState([])
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState(data.purposeCode ? [data.purposeCode.split(',').filter(Boolean)] : [])
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師編號
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo ? data.supportUserNo : '')
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName ? data.supportUserName : '')
    // 執登科別編號
    const [divNo, setDivNo] = useState(data.divNo)
    // 執登科別名稱
    const [divName, setDivName] = useState(data.divName)
    // 證書字號
    const [licenceId, setLicenceId] = useState(data.licenceId)
    // 職類別
    const [licenceKind, setLicenceKind] = useState(data.licenceKind)
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState(data.licenceKindName)
    // 執登機構代碼
    const [hospNo, setHospNo] = useState(data.hospNo)
    // 執登機構名稱
    const [hospName, setHospName] = useState(data.hospName)


    /** 報備支援內容欄位 */
        // 支援機構Pkey
    const [supportOrgPkey, setSupportOrgPkey] = useState(data.supportOrgPkey ? data.supportOrgPkey : '')
    // 支援機構字串
    const [supportOrgStr, setSupportOrgStr] = useState('')
    // 支援機構代碼
    const [supportOrgNo, setSupportOrgNo] = useState(data.supportOrgNo ? data.supportOrgNo : '')
    // 支援機構名稱
    const [supportOrgName, setSupportOrgName] = useState(data.supportOrgName ? data.supportOrgName : '')
    // 申請單號
    const [supportNo, setSupportNo] = useState(data.supportNo ? data.supportNo : '')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo ? data.supportDivNo : '')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data.supportDivName ? data.supportDivName : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : []);
    // 支援目的門住 如果沒有值則設為門診
    const [purposeCodeExt, setPurposeCodeExt] = useState(data.purposeCodeExt ? data.purposeCodeExt : 1)
    // 變更原因
    const [supportModifyReasonDoc, setSupportModifyReasonDoc] = useState(data.supportModifyReasonDoc ? data.supportModifyReasonDoc : '')
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門
    const [ownerDeptNo, setOwnerDeptNo] = useState(data.ownerDeptNo ? data.ownerDeptNo : '')
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState(data.ownerDeptName ? data.ownerDeptName : '')
    // 代理人員字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState(data.agentUserNo ? data.agentUserNo : '')
    // 代理人員名稱
    const [agentUserName, setAgentUserName] = useState(data.agentUserName ? data.agentUserName : '')
    // 性質
    const [supportProperties, setSupportProperties] = useState(data.supportProperties ? data.supportProperties : '')
    // 執行內容
    const [executeContent, setExecuteContent] = useState(data.executeContent ? data.executeContent : '')
    // 備註
    const [memo, setMemo] = useState(data.memo ? data.memo : '')
    // 支援起始日期
    const [itemStartDate, setItemStartDate] = useState(data.itemStartDate ? data.itemStartDate : '')
    // 支援起始時間
    const [itemStartTime, setItemStartTime] = useState(data.itemStartTime ? data.itemStartTime : '')
    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState(data.itemEndDate ? data.itemEndDate : '')
    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState(data.itemEndTime ? data.itemEndTime : '')
    // 彈窗
    // 顯示醫師人員搜尋彈窗
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 顯示支援機構彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)

    // 使用上下文
    const {
        showToast,
        showChangeRecordModal,
        setShowChangeRecordModal,
        setShowSupportDateChoice,
        showSupportDateChoice,
    } = usePopup()

    /**
     * 支援科別變更時
     * @param item {string} 選取的選項
     * @return {void}
     */
    const handleSupportDivOnChange = (item) => {
        setSupportDivStr(item)
        setSupportDivNo(item.split(' ')[0])
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 承辦部門變更時
     * @param item {string} 選取的選項
     * @return {void}
     */
    const handleOwnerDeptOnChange = (item) => {
        setOwnerDeptStr(item)
        setOwnerDeptNo(item.split(' ')[0])
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員變更時
     * @param item {string} 選取的選項
     * @return {void}
     */
    const handleAgentUserNoChange = (item) => {
        setAgentUserStr(item)
        setAgentUserNo(item.split(' ')[0])
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 收案確認
     * @return {void}
     */
    const handleOnConfirm = () => {
        const data = getSendData('CONFIRM')
        // 更新收案確認資料
        const updatedData = data.map(item => ({
            ...item,
            // 收案確認人員編號
            confirmUserNo: getLocalStorage('userno'),
            // 收案確認日期
            confirmDatetime: time2String(new Date(), 'YYYY-MM-DD HH-mm-ss').split('_')[0].replace(/(\d{4}-\d{2}-\d{2}) (\d{2})-(\d{2})-(\d{2})/, "$1 $2:$3:$4")
        }))
        handleCrisisMailConfirm(updatedData)
    }

    /**
     * 新增報備支援內容
     * @return {void}
     */
    const handleFormSave = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (stringIsEmpty(itemStartDate) && stringIsEmpty(itemEndDate) && stringIsEmpty(itemStartTime) && stringIsEmpty(itemEndTime)) {
            showToast({message: '請增加支援期間時間', type: ToastTypeEnum.Warning})
            return
        }

        // 檢查支援機構是否有值
        if(!supportOrgNo || supportOrgNo === ''){
            showToast({message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        // 檢查支援科別是否有值
        if(!supportDivNo || supportDivNo === ''){
            showToast({message: `支援者：${supportUserName}，「支援科別」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        const data = getSendData()
        saveEditMaster(data)
    }

    /**
     * 更新列表項目
     * @return {void}
     */
    const handleUpdateDataList = (data) => setDataList(data)

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        // 取得醫師清單
        getDoctorData(item.userNo)
        // 取得代理人員
        getAgentUserList(item.userNo)
        // 設定支援醫師代碼
        setSupportUserNo(item.userNo)
        // 設定支援醫師名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 選取支援機構選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleOrgOptionOnClick = (item) => {
        // 設定支援機構代碼/名稱
        setSupportOrgNo(item.orgNo)
        // 設定支援機構名稱
        setSupportOrgName(item.orgName)
        // 關閉彈窗
        setShowOrgDropdown(false)
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序


        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            // 支援目的帶入進來的值是字串，所以要先轉換為array
            // 後續操作都是array，最後儲存時再轉回字串
            setPurposeCode(prevCodes => {
                let prevCodesArray
                // 判斷prevCodes是否為array
                if (!Array.isArray(prevCodes)) prevCodesArray = prevCodes.split(' ').filter(Boolean) // 如果不是array，則轉換為array，並過濾避免空值
                else prevCodesArray = prevCodes // 如果是array，則直接使用

                if (prevCodesArray.includes(codeNo)) {
                    // 如果已經包含該代碼，則移除它
                    return prevCodesArray.filter(code => code !== codeNo);
                } else {
                    // 如果不包含該代碼，則添加它
                    return [...prevCodesArray, codeNo];
                }
            })
        }

        const isChecked = (codeNo) => {
            if (Array.isArray(purposeCode)) {
                return purposeCode.includes(codeNo);
            } else {
                // 如果 purposeCode 是字符串，將其分割成數組
                const codeArray = purposeCode.split(' ').filter(Boolean);
                return codeArray.includes(codeNo);
            }
        }

        return (
            <div className="grid grid-cols-5 gap-4 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-2">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id={item.codeNo}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="purpose"
                                        onClick={() => handlePurposeChange(item.codeNo)}
                                        checked={isChecked(item.codeNo)}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                                </div>
                                <select
                                    className="ml-2 pl-4 w-[96px] h-10 bg-white text-black rounded-[6px] border border-gray-300"
                                    value={purposeCodeExt}
                                    onChange={(e) => setPurposeCodeExt(e.target.value)}>
                                    {renderSubPurposeOptions()}
                                </select>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="flex items-center space-x-2 col-span-1">
                                <input
                                    id={item.codeNo}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="purpose"
                                    onClick={() => handlePurposeChange(item.codeNo)}
                                    checked={isChecked(item.codeNo)}
                                    onChange={() => {
                                    }}
                                />
                                <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    /**
     * 渲染子支援目的選項
     * @return {JSX.Element}
     */
    const renderSubPurposeOptions = () => {
        return subPurposeOptions.map((item) => (
            <option key={item.codeNo} value={item.codeNo}>{item.codeValue1}</option>
        ))
    }

    /**
     * 承辦人員收案確認
     * @return {void}
     */
    const handleCrisisMailConfirm = (data) => {
        rsisMailConfirm(data).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '收案成功', type: ToastTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 儲存修改後的報備支援內容
     * @param data {Array<Object>} 資料
     * @return {void}
     */
    const saveEditMaster = (data) => {
        if (type === 'EDIT') {
            // 執行修改報備支援內容
            rsisEditMaster(data).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '修改成功', type: ToastTypeEnum.Success})
                    handleOnUpdate()
                    handleOnClose()
                } else {
                    showToast({message: msg, type: ToastTypeEnum.Error})
                }
            })
        } else if (type === 'IMPORT') {
            // 取得data array[0]的資料
            handleOnUpdate(data[0])
        }
    }

    /**
     * 建構送出資料
     * @return {Array<Object>} 送出資料
     */
    const getSendData = (type = 'EDIT') => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請新增報備支援內容', type: ToastTypeEnum.Error})
            return
        }

        // 根據支援明細資料產生對應比數的資料
        const sendData = dataList.map(item => {
            // 取得機構物件
            const org = orgList.find(o => {
                if (o.orgNo === supportOrgNo) return o
                else if (o.orgPkey === supportOrgPkey) return o
            })

            if (org) {
                return {
                    supportNo: supportNo,
                    itemVersion: data.itemVersion,
                    supportUserNo: supportUserNo,
                    supportOrgPkey: org.orgPkey,
                    supportOrgName: supportOrgName,
                    supportDivNo: supportDivNo,
                    supportDivName: supportDivName,
                    purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode, // 是否為Array，若是則轉換為字串
                    purposeCodeExt: purposeCodeExt,
                    itemStartDate: `${item.startDate} 00:00:00`,
                    itemStartTime: item.startTime.includes(':') ? item.startTime.split(':')[0] + item.startTime.split(':')[1] : item.startTime,
                    itemEndDate: `${item.endDate} 00:00:00`,
                    itemEndTime: item.endTime.includes(':') ? item.endTime.split(':')[0] + item.endTime.split(':')[1] : item.endTime,
                    supportProperties: supportProperties,
                    executeContent: executeContent,
                    memo: memo,
                    agentUserNo: agentUserNo,
                    stepStatus: item.stepStatus ? item.stepStatus : data.stepStatus,
                    supportModifyStatus: item.supportModifyStatus ? item.supportModifyStatus : data.supportModifyStatus,
                    supportModifyDatetime: item.supportModifyDatetime ? item.supportModifyDatetime : data.supportModifyDatetime,
                    supportModifyReasonCode: item.supportModifyReasonCode ? item.supportModifyReasonCode : data.supportModifyReasonCode,
                    supportModifyReasonDoc: item.supportModifyReasonDoc ? item.supportModifyReasonDoc : data.supportModifyReasonDoc,
                    sendFileName: item.sendFileName ? item.sendFileName : data.sendFileName,
                    sendCaseNo: item.sendCaseNo ? item.sendCaseNo : data.sendCaseNo,
                    sendDatetime: item.sendDatetime ? item.sendDatetime : data.sendDatetime,
                    applyType: item.applyType ? item.applyType : data.applyType,
                    zone: item.zone ? item.zone : data.zone,
                    effFlag: true,
                    ownerDeptNo: ownerDeptNo,
                    changeFieldsDoc: item.changeFieldsDoc ? item.changeFieldsDoc : data.changeFieldsDoc,
                    confirmUserNo: item.confirmUserNo ? item.confirmUserNo : data.confirmUserNo,
                    confirmDatetime: item.confirmDatetime ? item.confirmDatetime : data.confirmDatetime,
                }
            }
        })
        return sendData

    }

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @param userNo {string} 員工/醫師代碼
     * @return {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定執豋資料
                if(data){
                    setDivNo(data.divNo)
                    setDivName(data.divName)
                    setLicenceId(data.licenceId)
                    setLicenceKind(data.licenceKind)
                    setLicenceKindName(data.licenceKindName)
                    setHospNo(data.hospNo)
                    setHospName(data.hospName)
                }else{
                    // 若無執豋資料，則設定空值
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data.supportDivList)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 設定報備支援資料
        if (!objectIsEmpty(data)) {
            setSupportDivStr(`${supportDivNo} ${supportDivName}`)
            setSupportOrgStr(`${supportOrgNo} ${supportOrgName}`)
            setOwnerDeptStr(`${ownerDeptNo} ${ownerDeptName}`)
            setAgentUserStr(`${agentUserNo} ${agentUserName}`)
            // 取得醫師執登資料
            getDoctorData(data.supportUserNo)
            // 取得支援日期資料
            if (!objectIsEmpty(data)) {
                const newDataItems = [{
                    ...data,
                    startDate: time2String(data.itemStartDate, 'YYYY-MM-DD'),
                    endDate: time2String(data.itemEndDate, 'YYYY-MM-DD'),
                    startTime: data.itemStartTime,
                    endTime: data.itemEndTime,
                }]
                setDataList(newDataItems);
            }
        }
        // 取得支援目的選項
        getPurposeOptions()
        // 取得代理人員
        getAgentUserList(data.supportUserNo)
    }, [])

    /**
     * 監聽當支援期間明細日期資料更新時
     * @return {void}
     */
    useEffect(() => {
        if (dataList.length > 0) {
            setItemStartDate(dataList[0].startDate)
            setItemEndDate(dataList[0].endDate)
            setItemStartTime(dataList[0].startTime)
            setItemEndTime(dataList[0].endTime)
        } else {
            setItemStartDate('')
            setItemEndDate('')
            setItemStartTime('')
            setItemEndTime('')
        }
    }, [dataList])

    return (
        <>
            <BasePopup
                title="報備支援收案確認"
                width="1653px"
                closePopupButtonOnClick={handleOnClose}
                content={
                    <div className='px-6'>
                        <div className='flex flex-row justify-end w-full gap-2'>
                            <button
                                className='ml-4 flex items-center justify-center font-base px-4 h-10 rounded-[6px] border-[#D4D4D8] border-[2px] bg-[#FAFAFA]'
                                onClick={() => setShowChangeRecordModal(true)}
                            >
                                查詢變更紀錄
                            </button>
                            <select
                                className='w-[132px] pl-[12px] h-10 bg-white text-black rounded-[6px] border border-gray-300'
                                disabled onChange={() => {
                            }}>
                                <option value="1">{t(`campus.${data.zone}`)}</option>
                            </select>
                            <span
                                className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                        </div>
                        {/* 支援人員 */}
                        <div className='flex flex-col'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    支援人員
                                </h3>
                            </div>
                            <div className='flex flex-col gap-2 mb-6'>
                                {/* 員工/醫師代碼 */}
                                <div className='flex flex-row gap-4 relative'>
                                    <Title extendClass={extendClass}>{'員工/醫師代碼：'} </Title>
                                    <BaseInput className={getInputClassName()}
                                               inputMode={InputTypeEnum.Text}
                                               type={InputTypeEnum.Text}
                                               value={`${supportUserNo ? supportUserNo : ''} ${supportUserName ? supportUserName : ''}`}
                                               onClick={() => setShowDoctorPopup(true)}
                                               onChange={() => {
                                               }}
                                    />
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    { /* 執登科別 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'執登科別：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}</p>
                                    </div>
                                    {/* 證書字號 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'證書字號：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(licenceId) ? licenceId : ''}</p>
                                    </div>
                                    {/* 執登機構 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'執登機構：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}</p>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <Title children={'職別類：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        {/* 報備支援內容 */}
                        <div className='flex flex-col mb-6 mt-6'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    報備支援內容
                                </h3>
                            </div>
                            <div className='w-full flex flex-col gap-2'>
                                {/* 機構代碼/名稱 */}
                                <div className='flex justify-between items-center'>
                                    <div className='flex flex-row items-center gap-4 relative'>
                                        <Title children={'機構代碼/名稱：'} extendClass={extendClass}/>
                                        <BaseInput
                                            className=' ml-2 bg-white min-w-[570px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                                            inputMode={InputTypeEnum.Search}
                                            type={InputTypeEnum.Text}
                                            value={`${supportOrgNo} ${supportOrgName}`}
                                            onClick={() => setShowOrgDropdown(true)}
                                            onChange={() => {
                                            }}
                                        />
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <Title children={'申請單號：'} extendClass={extendClass}/>
                                        <p>{supportNo}</p>
                                    </div>
                                </div>
                                {/* 支援科別 */}
                                <div className='flex justify-between items-center'>
                                    <FuzzySearchInput
                                        title={'支援科別：'}
                                        titleExtendClass={extendClass}
                                        value={supportDivStr}
                                        handleValueChange={handleSupportDivOnChange}
                                        valueKey={['supportDivNo', 'supportDivName']}
                                        list={allDivisionList}
                                        inputExtendClass={'ml-6'}
                                    />
                                </div>
                                {/* 支援目的 */}
                                <div className='flex flex-row items-start gap-4'>
                                    <Title children={'支援目的：'} extendClass='w-[160px] justify-end py-2 h-9'/>
                                    <div className='flex flex-col items-start pl-6 w-full'>
                                        {renderPurposeOptions()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        <div className='flex flex-col gap-2 my-4 mb-4'>
                            <div className='flex flex-row items-center justify-between gap-4'>
                                <div className='flex flex-row items-center gap-4'>
                                    {/* 承辦部門 */}
                                    <FuzzySearchInput
                                        title={'承辦部門：'}
                                        titleExtendClass={extendClass}
                                        value={ownerDeptStr}
                                        handleValueChange={handleOwnerDeptOnChange}
                                        valueKey={['deptNo', 'deptName']}
                                        list={deptList}
                                        inputExtendClass={'ml-6'}
                                    />
                                    {/* 代理人員代號/姓名 */}
                                    <FuzzySearchInput
                                        title={'代理人員代號/姓名：'}
                                        titleExtendClass={extendClass}
                                        value={agentUserStr}
                                        handleValueChange={handleAgentUserNoChange}
                                        valueKey={['agentUserNo', 'agentUserName']}
                                        list={agentUserList}
                                        inputExtendClass={'ml-6'}
                                    />
                                </div>
                                {/* 收件確認按鈕 */}
                                <button
                                    className={`ml-4 flex items-center justify-center font-base px-4 h-10 border-[2px] rounded-[6px] ${data.stepStatus == RsisStepStatusEnum.Confirm ? 'text-[#D4D4D8] border-[#D4D4D8] hover:cursor-not-allowed' : 'text-black border-black hover:bg-primary hover:text-white bg-[#FAFAFA] hover:border-primary hover:cursor-pointer'}`}
                                    onClick={handleOnConfirm}
                                    disabled={data.stepStatus == RsisStepStatusEnum.Confirm}
                                >
                                    收件確認
                                </button>
                            </div>
                            {/* 性質 */}
                            <div className='flex items-center gap-4'>
                                <Title children={'性質：'} extendClass={extendClass}/>
                                <select
                                    className="w-[654px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                    value={supportProperties}
                                    onChange={(e) => setSupportProperties(e.target.value)}
                                >
                                    {kindList.map((item, index) =>
                                        <option key={index} value={item.codeNo}>{item.codeValue1}</option>
                                    )}
                                </select>
                            </div>
                            {/* 執行內容 */}
                            <div className='flex items-center gap-4 w-full'>
                                <Title children={'執行內容：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <BaseInput
                                    className="w-[1385px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                    type={InputTypeEnum.Text}
                                    value={executeContent}
                                    onChange={(e) => setExecuteContent(e.target.value)}
                                />
                            </div>
                            {/* 備註 */}
                            <div className='flex items-center gap-4 w-full'>
                                <Title children={'備註：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <BaseInput
                                    className='w-[1385px] pl-4 h-10 rounded-[6px] border border-gray-300 mx-2 bg-white text-black'
                                    type={InputTypeEnum.Text}
                                    value={memo}
                                    onChange={(e) => setMemo(e.target.value)}
                                />
                            </div>
                            {/* 支援期間明細 */}
                            <div className='flex items-center gap-4'>
                                <Title children={'支援期間：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <button
                                    className="w-[64px] h-10 border-[2px] rounded-[6px] border-primary text-primary ml-2 hover:cursor-pointer"
                                    onClick={() => setShowSupportDateChoice(true)}>編輯
                                </button>
                                <p>{`${itemStartDate.replace(/ 00:00:00/g, '')}~${itemEndDate.replace(/ 00:00:00/g, '')} ${itemStartTime}~${itemEndTime}`}</p>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        {/* 存擋按鈕 */}
                        <div className='w-full flex justify-end my-2'>
                            <button
                                className="flex justify-center items-center w-[64px] ml-4 font-base bg-primary text-white h-10 border-[2px] rounded-[6px]"
                                onClick={handleFormSave}>存檔
                            </button>
                        </div>
                        {showChangeRecordModal && (
                            <ChangeRecordQuery
                                supportNo={data.supportNo}
                                handleOnClose={() => setShowChangeRecordModal(false)}
                                purposeList={[purposeOptions, subPurposeOptions]}
                            />
                        )}
                        {/* 報備支援-支援日期時間選擇 */}
                        {showSupportDateChoice && (
                            <SupportDateChoice
                                userName={supportUserName}
                                data={dataList}
                                supportNo={supportNo}
                                supportUserNo={supportUserNo}
                                itemVersion={data.itemVersion}
                                handleUpdateDataList={handleUpdateDataList}
                                handleOnClose={() => setShowSupportDateChoice(false)}/>
                        )}
                        {/* 醫師搜尋彈窗 */}
                        {showDoctorPopup && (
                            <QueryPersonList
                                filterEmp={true}
                                doctorList={doctorList}
                                handleOnClose={() => setShowDoctorPopup(false)}
                                handleOnUpdate={handleDoctorOptionOnClick}
                            />
                        )}
                        {/* 支援機構彈窗 */}
                        {showOrgDropdown && (
                            <QuerySupportOrg
                                filterOrg={true}
                                orgList={orgList}
                                handleOnClose={() => setShowOrgDropdown(false)}
                                handleOnUpdate={handleOrgOptionOnClick}
                            />
                        )}
                    </div>
                }
            />
        </>
    )
}

export default RSISCaseConfirmation
