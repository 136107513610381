import React, {useEffect, useState} from "react"
import {BasePopup} from '../../Popup/BasePopup'

/**
 * 儲存提醒彈窗
 * @param {function} closePopupButtonOnClick - 控制彈窗關閉
 * @param {function} handleSaveOnClick - 儲存事件
 * @return {JSX.Element}
 */
const SavePromptPopup = ({closePopupButtonOnClick, handleSaveOnClick, show = false}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return showPopup ?
        <BasePopup
            title="提醒"
            closePopupButtonOnClick={closePopupButtonOnClick}
            width="559px"
            content={
                <div>
                    <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">資料異動是否存檔？</p>
                    <div className="h-[60px] flex flex-row items-center justify-end">
                        <button 
                            className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={() => handleSaveOnClick()}
                        >
                            是
                        </button>
                        <button 
                            className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                            onClick={closePopupButtonOnClick}    
                        >
                            否
                        </button>
                    </div>
                </div>
            }
        /> :
        <></>

    
}

export default SavePromptPopup
