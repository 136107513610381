import React, { useEffect, useMemo, useState } from 'react'
import { TRAN_FOREIGN_LIST_FIELDS } from '../../constants/referralSystem/disabilityCertificateList'
import { ReactComponent as Add } from '../../assets/images/ICCard/add_circle.svg'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { ReactComponent as ExcelIcon } from '../../assets/images/icons/excel_icon.svg'
import { BasePopup } from '../../components/Popup/BasePopup'
import CertificateEditorModal from '../../components/ReferralSystem/DisabilityCertificate/CertificateEditorModal'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { BaseInput } from '../../components/Input/BaseInput'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum,
    validDateFormat,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import { t } from 'i18next'
import useToast from '../../hooks/useToast'
import { queryTranForeignBySearch } from '../../api/v1/Rmis'
import {
    completePrintMode,
    updatePrintContent,
} from '../../redux/Slice/printSlice'
import { FORM_TYPE } from '../../constants/referralSystem/print'
import store from '../../redux/store'
import { useSelector } from 'react-redux'
import { addTab } from '../../redux/Slice/TabSlice'
import { LONG_TERM_CARE_CENTER_NO } from '../../constants/menuFuncNo'

/**
 * 病症暨失能診斷證明書
 * @returns {JSX.Element}
 */
const DisabilityCertificateList = () => {
    const [dataList, setDataList] = useState([])

    /**
     * 欄位檢核
     */
    const [inputState, setInputState] = useState({
        tranOutStartDate: null,
        tranOutEndDate: null,
    })

    /**
     * 顯示編輯視窗
     */
    const [isShowCertificateEditorModal, setIsShowCertificateEditorModal] =
        useState(false)

    const [editRow, setEditRow] = useState(null)

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        tranOutStartDate: '',
        tranOutEndDate: '',
        patientId: '',
        hospGrade: '',
        acceptFlag: '',
        condition: '',
        aidGradeFlag: false,
        ruralType: false,
    })

    // toast message
    const showToast = useToast()

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 編輯 or 新增內容
     */
    const [isEdit, setIsEdit] = useState(false)

    /**
     * 更新查詢欄位value
     * @param {object} e input event
     */

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 編輯按鈕
     * @param {object} referralCase 要編輯的單筆資料
     * @returns {JSX.Element}
     */
    const Editor = (referralCase) => (
        <button
            className="text-[#007CDB] flex"
            onClick={() => {
                showEditModal(referralCase)
            }}
        >
            <EditIcon />
            編輯
        </button>
    )

    // TODO:
    const exportExcel = () => {
        const selectedList = dataList.filter((item) => item.isSelected)
        if (selectedList.length === 0) {
            return
        }
        const dataArray = []
        const fields = TRAN_FOREIGN_LIST_FIELDS.slice(1)
        selectedList.forEach((item) => {
            const data = []
            fields.forEach((field) => {
                const value = field.format
                    ? field.format(item[field.key])
                    : item[field.key]

                data.push(value)
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: fields.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '病症暨失能診斷證明書_清單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={exportExcel}
            >
                <ExcelIcon className="mr-1" />
                {t('general.export')}
            </button>
        )
    }

    /**
     * table cell checkbox
     * @param {object} referralCase
     * @returns
     */
    const CusomtCheckbox = (value) => {
        return (
            <input
                className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                type="checkbox"
                style={{ accentColor: '#38A169' }}
                checked={value}
                readOnly
            />
        )
    }

    /**
     * 轉出清單 table 顯示欄位
     */
    const renderFields = useMemo(() => {
        const fields = TRAN_FOREIGN_LIST_FIELDS
        return fields.map((item) => {
            switch (item.key) {
                case 'edit':
                    return {
                        ...item,
                        render: Editor,
                    }
                case 'barthelFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            CusomtCheckbox(!!referralCase.barthelFlag),
                    }
                case 'cdrFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            CusomtCheckbox(!!referralCase.cdrFlag),
                    }
                default:
                    return item
            }
        })
    })

    /**
     * 查詢病症暨失能診斷證明書清單
     */
    const queryDisabilityCertificateList = async (pageNum, pageSize) => {
        const tranOutStartDateIsValid = validDateFormat(
            searchParams.tranOutStartDate
        )
        const tranOutEndDateIsValid = validDateFormat(
            searchParams.tranOutEndDate
        )
        setInputState({
            tranOutStartDate: tranOutStartDateIsValid,
            tranOutEndDate: tranOutEndDateIsValid,
        })
        if (!tranOutStartDateIsValid || !tranOutEndDateIsValid) {
            return
        }
        const result = await queryTranForeignBySearch({
            applicantStartDate: `${searchParams.tranOutStartDate} 00:00:00`,
            applicantEndDate: `${searchParams.tranOutEndDate} 00:00:00`,
            search: searchParams.patientId,
            pageNum: pageNum,
            pageSize: pageSize,
        })

        if (result.err === ApiErrorStatusEnum.Success) {
            const list = result?.data?.dataList || []
            if (arrayIsEmpty(list.length)) {
                showToast({
                    message: '查無資料',
                    type: ToastTypeEnum.Warning,
                })
            }
            const selectableList = result?.data?.dataList?.map((item) => ({
                ...item,
                isSelected: false,
                applyName: item.patientName,
            }))
            setDataList(selectableList)
            setFilter({
                ...filter,
                total: result?.data?.totalItemSize,
                totalPage: result?.data?.totalPageSize,
            })
        } else {
            showToast({
                message: result.msg,
                type: ToastTypeEnum.Error,
            })
        }
    }

    const showCertificateEditorModal = () => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(false)
    }

    const handleQueryOnClick = () => {
        queryDisabilityCertificateList(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        queryDisabilityCertificateList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        queryDisabilityCertificateList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 開啟回覆個管作業_維護modal
     * @param {object} referralCase 點選編輯的單筆資料
     */
    const showEditModal = (referralCase) => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(true)
        setEditRow(referralCase)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.patientId === item.patientId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }
    /**
     * 列印申請人信封
     */
    const handlePrintEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.applyEnvelope,
                printData,
            })
        )
    }

    /**
     * 列印長照信封
     */
    const handlePrintLongTermCareEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.longTermCareEnvelope,
                printData,
            })
        )
    }

    const handleClose = () => {
        setIsShowCertificateEditorModal(false)
        handleQueryOnClick()
    }

    const handleOpenTab = () => {
        store.dispatch(
            addTab({ id: LONG_TERM_CARE_CENTER_NO, name: '長照中心維護' })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between">
                <div className="flex flex-wrap p-2">
                    <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="mr-4">
                            查詢日期
                            <span className="text-danger ">*</span>:
                        </div>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranOutStartDate}
                            onChange={(e) =>
                                updateSearchParams(e, 'tranOutStartDate')
                            }
                            type="date"
                            inputState={inputState.tranOutStartDate}
                            errorMsg="必填"
                        />
                        <span className="m-1">~</span>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranOutEndDate}
                            onChange={(e) =>
                                updateSearchParams(e, 'tranOutEndDate')
                            }
                            type="date"
                            inputState={inputState.tranOutEndDate}
                            errorMsg="必填"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="mr-4">病歷號/身分證號:</div>
                        <input
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type="text"
                            value={searchParams.patientId}
                            onChange={(e) => updateSearchParams(e, 'patientId')}
                        />
                    </div>
                    <button
                        type="submit"
                        className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        onClick={handleQueryOnClick}
                    >
                        查詢
                    </button>
                </div>
                <div className="flex flex-wrap items-center justify-between p-2 mb-2">
                    <div className="flex flex-wrap">
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handleOpenTab}
                        >
                            長照中心維護
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                            onClick={() => handlePrintEnvelope()}
                        >
                            申請人信封
                        </button>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                            onClick={() => handlePrintLongTermCareEnvelope()}
                        >
                            長照信封
                        </button>
                    </div>
                </div>
            </div>
            <button
                className="flex flex-row items-center justify-start text-[#38A169] text-[18px] mb-3"
                onClick={() => showCertificateEditorModal(true)}
            >
                <Add />
                新增內容
            </button>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
                slotButton={<ExportExcelBtn />}
            />
            <div className="flex justify-end mt-2">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {isShowCertificateEditorModal && (
                <BasePopup
                    title="病症暨失能診斷證明書_維護"
                    content={
                        <CertificateEditorModal
                            onClose={handleClose}
                            isEdit={isEdit}
                            applicantNo={editRow?.applicantNo}
                        />
                    }
                    width="80%"
                    closePopupButtonOnClick={() =>
                        setIsShowCertificateEditorModal(false)
                    }
                />
            )}
        </div>
    )
}
export default DisabilityCertificateList
