import React from 'react'
import EntityRelationshipQuery from '../../components/RSIS/EntityRelationship/EntityRelationshipQuery'
import { PopupProvider } from '../../components/RSIS/PopupProvider'

/**
 * 申請與查詢
 * @returns {JSX.Element}
 */
function EntityRelationLayout() {
  return (
    <PopupProvider>
      <EntityRelationshipQuery />
    </PopupProvider>
  )
}

export default EntityRelationLayout