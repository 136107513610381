import {BaseInput} from "../../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import Button from "../../../Button/Button";
import React, {useEffect, useState} from "react";
import {BasePopup} from "../../../Popup/BasePopup";
import {OptionOne} from "../MiniNutritionalAssessment/formOption";
import RadioButton from "../../../Input/RadioButton";
import {OptionChange, OptionColor, OptionExudates, OptionLevel} from "./formOption";
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/del_square.svg'

export const ModalStatus = {
    ADD: "ADD",
    EDIT: "EDIT",
}
const Title = ({children, extendClass})=> {
    return (
        <div className={`flex flex-row items-center justify-start mr-8 ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}
const InputStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SelectStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * 策略聯盟維護表單
 * @returns {JSX.Element}
 */
const WoundRecordForm = ({status, data, onSave, onDelete})=>{

    const initData = {
        date: "",
        change: "",
        wound: "",
        long: 0,
        width: 0,
        depth: 0,
        level: false,
        color: false,
        exudates: false,
        type: "",
        count: "",
        nurse: ""
    }
    // 預設表單資料
    const [formData, setFormData] = useState(initData);
    /**
     * 更改表單資料
     * @param e
     * @param item
     */
    const handleInputChange = (e, item) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [item]: value
        });
    };
    /**
     * 更改單選表單資料
     * @param value
     * @param item
     */
    const handleRadioChange = (value, item) => {
        setFormData({
            ...formData,
            [item]: value
        });
    };
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)


    // 儲存
    const handleSave=()=>{
        onSave && onSave(formData)
    }


    // 刪除單筆資料
    const handleConfirmDelete= () =>{
        onDelete && onDelete()
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = ()=>{
        setShowDeletePopup(true)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }
    useEffect(() => {
        console.log({data})
        setFormData(data?.tableRow || initData)

    }, []);
    return <>
        {/*刪除確認popup*/}
        {showDeletePopup && (
            <BasePopup
                title="提醒"
                closePopupButtonOnClick={handleDeletePopupClose}
                width="559px"
                content={
                    <div>
                        <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
                        <div className="h-[60px] flex flex-row items-center justify-end">
                            <button onClick={handleConfirmDelete}
                                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            >是</button>
                            <button onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                            >否</button>
                        </div>
                    </div>
                }
            />
        )}
        {/*刪除彈窗*/}
        {status === ModalStatus.EDIT && <div className="flex items-center justify-start px-8 pt-8">
            <button
                className="text-[#007CDB] flex"
                onClick={() => handleDeletePopupOpen()}
            >
                <DeleteIcon/>
                <div className="ml-1">刪除</div>
            </button>
        </div>}

        <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8">
            {/* 記錄日期 */}
            <div className="grid  grid-cols-[100px,300px,100px,1fr]">
                <div className='flex items-center '>
                    <Title>記錄日期</Title>
                </div>
                <div className='flex items-center'>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Date}
                        value={formData.date}
                        onChange={(e) => {
                            handleInputChange(e, "date")
                        }}
                    />
                </div>
                <div className='flex items-center'>
                    <Title>護理師</Title>
                </div>
                <div className='flex items-center '>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.nurse}
                        onChange={(e) => {
                            handleInputChange(e, "nurse")
                        }}
                    />
                </div>

            </div>
            {/* 傷口變化 */}
            <div className="mt-2 flex">
                <div className='flex items-center'>
                    <Title>
                        傷口變化
                    </Title>
                </div>
                <div className="flex items-center ml-4">
                    {
                        OptionChange.map((option, index) => (
                            <RadioButton wrapperClassName={"w-[100px]"} mode={formData.change}
                                         modeChecked={option.value}
                                         handleModeChange={(value) => {
                                             handleRadioChange(value, "change")
                                         }} label={option.label}/>
                        ))
                    }
                </div>
            </div>
            {/* 傷口大小 */}
            <div className="mt-2 flex">
                <div className='flex items-center'>
                    <Title>傷口大小</Title>
                </div>
                <div className='flex items-center mr-4'>
                    <Title>長</Title>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={formData.long}
                        onChange={(e) => {
                            handleInputChange(e, "long")
                        }}
                    />
                </div>
                <div className='flex items-center mr-4'>
                    <Title>寬</Title>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={formData.width}
                        onChange={(e) => {
                            handleInputChange(e, "width")
                        }}
                    />
                </div>
                <div className='flex items-center mr-4'>
                    <Title>深</Title>
                    <BaseInput
                        className={`${InputStyle} w-full`}
                        type={InputTypeEnum.Text}
                        value={formData.depth}
                        onChange={(e) => {
                            handleInputChange(e, "depth")
                        }}
                    />
                </div>
            </div>
            {/* 潛行傷口 */}
            <div className="mt-2 flex">
                <div className='flex items-center'>
                    <Title>潛行傷口</Title>
                </div>
                <div className='flex items-center '>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.wound}
                        onChange={(e) => {
                            handleInputChange(e, "wound")
                        }}
                    />
                </div>

            </div>
            {/* 等級 */}
            <div className="mt-2 flex">
                <div className='flex items-center mr-9'>
                    <Title extendClass="mr-4">
                        等級
                    </Title>
                </div>
                <div className="flex items-center ml-4">
                    {
                        OptionLevel.map((option, index) => (
                            <RadioButton wrapperClassName={"w-[100px]"} mode={formData.level}
                                         modeChecked={option.value}
                                         handleModeChange={(value) => {
                                             handleRadioChange(value, "level")
                                         }} label={option.label}/>
                        ))
                    }
                </div>
            </div>
            {/* 傷口顏色 */}
            <div className="mt-4 flex">
                <div className='flex items-center '>
                    <Title>
                        傷口顏色
                    </Title>
                </div>
                <div className="flex items-center ml-4">
                    {
                        OptionColor.map((option, index) => (
                            <RadioButton wrapperClassName={"w-[100px]"} mode={formData.color}
                                         modeChecked={option.value}
                                         handleModeChange={(value) => {
                                             handleRadioChange(value, "color")
                                         }} label={option.label}/>
                        ))
                    }
                </div>
            </div>
            {/* 滲出液 */}
            <div className="mt-4 flex">
                <div className='flex items-center'>
                    <Title extendClass="mr-2">
                        滲出液
                    </Title>
                </div>
                <div className="flex items-center ml-4">
                    {
                        OptionExudates.map((option, index) => (
                            <RadioButton wrapperClassName={index > 1 ? "w-[180px]" : "w-[100px]"} mode={formData.exudates}
                                         modeChecked={option.value}
                                         handleModeChange={(value) => {
                                             handleRadioChange(value, "exudates")
                                         }} label={option.label}/>
                        ))
                    }
                </div>
            </div>
            {/* 換藥方式 */}
            <div className="mt-2 flex">
                <div className='flex items-center'>
                    <Title>換藥方式</Title>
                </div>
                <div className='flex items-center '>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.type}
                        onChange={(e) => {
                            handleInputChange(e, "type")
                        }}
                    />
                </div>

            </div>
            {/* 擦藥次數 */}
            <div className="mt-2 flex">
                <div className='flex items-center'>
                    <Title>擦藥次數</Title>
                </div>
                <div className='flex items-center '>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={formData.count}
                        onChange={(e) => {
                            handleInputChange(e, "count")
                        }}
                    />
                </div>

            </div>
        </div>
        <div className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <Button classNames={`ml-4 ${ButtonStyle}`} text={"存檔"} onClickFn={handleSave}/>
        </div>

    </>
}

export default WoundRecordForm