//Import css
import '../../TableList/tableList.scss';

//Import Function
import React, {useEffect, useMemo, useState} from 'react';
import {
    InputTypeEnum,
    RsisStepStatusEnum,
    time2String,
    stringIsEmpty,
    ApiErrorStatusEnum,
    ToastTypeEnum,
    enum2Array, getLocalStorage
} from 'edah_utils/dist';
import {
    ChangeSlotBtn,
    CopySlotBtn,
    DelSlotBtn,
    EditSlotBtn,
    LofoffSlotBtn,
    ViewSlotBtn,
    SaveIconSlotBtn,
    CancelSlotBtn
} from '../SlotButton';
import {usePopup} from '../PopupProvider'
import {USER_ROLE} from "../userRole";
import {t} from "i18next"
import {rsisQuickEdit} from '../../../api/v1/RSIS'

/**
 * 輸入框組件
 * @param value {String} 輸入框值
 * @param onChange {Function} 輸入框改變事件
 * @return {JSX.Element}
 */
const Input = ({value, onChange}) =>
    <input className="w-[102px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
           type={InputTypeEnum.Text} value={value}
           onChange={onChange}/>

/**
 * RSIS支援與查詢清單組件
 * @param item {Object} 清單資料
 * @param depList {Array} 取得承辦部門清單
 * @param index {Number} 清單索引
 * @param role {String} 使用者角色
 * @param isSelectedAll {Boolean} 是否包含全選
 * @return {JSX.Element}
 */
const ListItem = ({
                      item,
                      depList,
                      index,
                      role = null,
                      isSelectAll = null
                  }) => {
    // 使用上下文
    const {
        setShowCopyModal,
        setShowEditModal,
        setShowChangeModal,
        setShowLogOffModal,
        setShowContentView,
        setShowDeletePopup,
        setFocusIndex,
        showToast
    } = usePopup();
    // 角色權限
    const [userRole, setUserRole] = useState('')
    const [isFastEdit, setIsFastEdit] = useState(false);
    const [stepStatus, setStepStatus] = useState(item.stepStatus);
    const [sendCaseNo, setSendCaseNo] = useState(item.sendCaseNo);
    const [sendFileName, setSendFileName] = useState(item.sendFileName);
    const [originalSendCaseNo, setOriginalSendCaseNo] = useState(item.sendCaseNo);
    const [originalSendFileName, setOriginalSendFileName] = useState(item.sendFileName);
    const [originalStepStatus, setOriginalStepStatus] = useState(item.stepStatus);

    const handleSave = () => {
        const data = {
            supportNo: item.supportNo,
            itemVersion: item.itemVersion,
            supportUserNo: item.supportUserNo,
            supportOrgNo: item.supportOrgNo,
            supportDivNo: item.supportDivNo,
            purposeCode: item.purposeCode,
            purposeCodeExt: item.purposeCodeExt,
            itemStartDate: item.itemStartDate,
            itemEndDate: item.itemEndDate,
            itemStartTime: item.itemStartTime,
            itemEndTime: item.itemEndTime,
            supportProperties: item.supportProperties,
            executeContent: item.executeContent,
            memo: item.memo,
            agentUserNo: item.agentUserNo,
            stepStatus: item.stepStatus,
            supportModifyStatus: item.supportModifyStatus,
            supportModifyDatetime: item.supportModifyDatetime,
            supportModifyReasonCode: item.supportModifyReasonCode,
            supportModifyReasonDoc: item.supportModifyReasonDoc,
            sendFileName: sendFileName,
            sendCaseNo: sendCaseNo, // 更新後的值
            sendDatetime: item.sendDatetime,
            applyType: item.applyType,
            zone: item.zone,
            effFlag: item.effFlag,
            ownerDeptNo: item.ownerDeptNo,
            changeFieldsDoc: item.changeFieldsDoc,
            confirmUserNo: item.confirmUserNo,
            confirmDatetime: item.confirmDatetime,
            exportNo: '', // 更新後的值
        };

        rsisQuickEdit([data]).then(response => {
            const {err, msg} = response;
            if (err === ApiErrorStatusEnum.Success) {
                // 成功處理
                setIsFastEdit(false);
                // 更新 item 的值
                item.stepStatus = stepStatus;
                item.sendCaseNo = sendCaseNo;
                item.sendFileName = sendFileName;
                setIsFastEdit(false);
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error});
            }
        });
    };

    const handleCancel = () => {
        setStepStatus(originalStepStatus);
        setSendCaseNo(originalSendCaseNo);
        setSendFileName(originalSendFileName);
        setIsFastEdit(false)
    };

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @returns {JSX.Element}
     */
    const renderSlotBtn = () => {
        const style = 'flex flex-row gap-2'
        // 是否在快速編輯狀態
        if (isFastEdit) {
            return <span className={style}>
                <SaveIconSlotBtn onClick={handleSave}/>
                <CancelSlotBtn onClick={handleCancel}/>
            </span>
        }

        // 根據狀態顯示對應操作按鈕
        switch (item.stepStatus) {
            // 申請狀態
            case RsisStepStatusEnum.Apply.toString():
                if (item.role === USER_ROLE.DEPT_USER || item.role === USER_ROLE.CREATE_USER || item.role === USER_ROLE.SUPPORT_USER) {
                    return <span className={style}>
                        <CopySlotBtn onClick={() => setShowCopyModal(true)}/>
                        <EditSlotBtn onClick={() => setShowEditModal(true)}/>
                        <DelSlotBtn onClick={() => setShowDeletePopup(true)}/>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                }
                // 確認狀態
            case RsisStepStatusEnum.Confirm.toString():
                if (item.role === USER_ROLE.DEPT_USER) {
                    return <span className={style}>
                        <CopySlotBtn onClick={() => setShowCopyModal(true)}/>
                        <EditSlotBtn onClick={() => setShowEditModal(true)}/>
                        <DelSlotBtn onClick={() => setShowDeletePopup(true)}/>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                }
                // 審核狀態
            case RsisStepStatusEnum.Review.toString():
                if (item.role === USER_ROLE.DEPT_USER || item.role === USER_ROLE.CREATE_USER || item.role === USER_ROLE.SUPPORT_USER) {
                    return <span className={style}>
                        <CopySlotBtn onClick={() => setShowCopyModal(true)}/>
                        <ChangeSlotBtn onClick={() => setShowChangeModal(true)}/>
                        <LofoffSlotBtn onClick={() => setShowLogOffModal(true)}/>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        <ViewSlotBtn onClick={() => setShowContentView(item)}/>
                    </span>
                }
            default:
                return null;
        }
    }

    useEffect(() => {
        // console.log('userRole', userRole)
    }, [userRole]);

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 根據使用者權限顯示對應按鈕 */}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {renderSlotBtn()}
                </span>
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='felx flex-col justify-start items-start'>
                <p className='w-full'>{item.supportNo}</p>
                <p className={'w-full'}>{t(`campus.shortName.${item.zone}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='w-full'>
                <p className='w-full'> {item.createUser}</p>
                <p className='w-full'>{item.createUserName}</p>
            </div>
            {/*支援醫師姓名*/}
            <div className='flex flex-col justify-start items-start'>
                <p className='w-full'> {item.supportUserNo}</p>
                <p className='w-full'>{item.supportUserName}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col justify-start items-start'>
                <p className='w-full'>{item.supportOrgNo}</p>
                <p className='w-full'>{item.supportOrgName}</p>
            </div>
            {/* 支援科別 */}
            <div>
                <p>{`${item.supportDivNo}${item.supportDivName}`}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col justify-start items-start'>
                <p>{`${time2String(item.itemStartDate, 'YYYY-MM-DD')} ${item.itemStartTime.slice(0, 2)+':'+item.itemStartTime.slice(2)}`}</p>
                <p>{`${time2String(item.itemEndDate, 'YYYY-MM-DD')} ${item.itemEndTime.slice(0, 2)+':'+item.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {stringIsEmpty(item.stepStatus) ? 'null' :
                    (isFastEdit ? (
                        <select
                            className="w-[102px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                            value={stepStatus}
                            onChange={(e) => setStepStatus(e.target.value)}
                        >
                            {
                                enum2Array(RsisStepStatusEnum)
                                    .filter(enumItem => enumItem.value !== RsisStepStatusEnum.All)
                                    .map((enumItem, index) => (
                                        <option key={index} value={enumItem.value}>
                                            {t(`RSIS.StepStatusEnum.${enumItem.value}`)}
                                        </option>
                                    ))
                            }
                        </select>
                    ) : t(`RSIS.StepStatusEnum.${item.stepStatus}`))
                }
            </div>
            {/* 變更、註銷狀態，若無變更或註銷則為空值 */}
            <div>{!stringIsEmpty(item.supportModifyStatus) && item.supportModifyStatus != 0 ? t(`RSIS.SupportModifyStatusEnum.${item.supportModifyStatus}`) : ''}</div>
            {/* 衛福部案號 */}
            <div>
                {isFastEdit
                    ? <Input type={InputTypeEnum.Text}
                             value={sendCaseNo}
                             onChange={(e) => setSendCaseNo(e.target.value)}/>
                    : item.sendCaseNo}
            </div>
            {/* 匯出編號 */}
            <div>
                {isFastEdit
                    ? <Input type={InputTypeEnum.Text}
                             value={sendFileName}
                             onChange={(e) => setSendFileName(e.target.value)}/>
                    : item.sendFileName}
            </div>
        </li>
    );
}

export default ListItem;
