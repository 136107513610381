import {BaseInput} from "../../components/Input/BaseInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    fuzzySearchObjects,
    getLocalStorage,
    InputTypeEnum,
    ToastTypeEnum,
    WDISServiceLogOperationEnum
} from "edah_utils/dist"
import React, {useEffect, useState} from "react"
import useToast from "../../hooks/useToast"
import {t} from "i18next"
import {wdisQueryServiceMonitorOperlog, WdisServiceMonitorOperLogQueryBaseData} from "../../api/v1/WDIS"

// 服務LOG查詢頁面
const ServiceLogInquiry = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 取得分院清單
    const campusList = Object.values(CampusEnum)

    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    //服務名稱
    const [serviceId, setServiceId] = useState('')

    //顯示服務代號下拉選單
    const [showServiceIdDropdown, setShowServiceIdDropdown] = useState(false)

    //負責人代號
    const [ownerUserNo, setOwnerUserNo] = useState('')

    //顯示負責人代號下拉選單
    const [showOwnerUserNoDropdown, setShowOwnerUserNoDropdown] = useState(false)

    //執行結果
    const [operResult, setOperResult] = useState(WDISServiceLogOperationEnum.Normal)

    //服務記錄起始日期
    const [logStartDate, setLogStartDate] = useState('')

    //服務記錄起始時間
    const [logStartTime, setLogStartTime] = useState('')

    //服務記錄結束日期
    const [logEndDate, setLogEndDate] = useState('')

    //服務記錄結束時間
    const [logEndTime, setLogEndTime] = useState('')

    //院區
    const [campus, setCampus] = useState([])

    //基礎資料
    const [baseData, setBaseData] = useState({})

    //資料
    const [data, setData] = useState([])

    /**
     * 服務代號改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceIdOnChange = (e) => setServiceId(e.target.value)

    /**
     * 服務代號下拉選單取得焦點時
     * @return {void}
     */
    const handleServiceIdOnFocus = () => setShowServiceIdDropdown(true)

    /**
     * 服務代號下拉選單失去焦點時
     * @return {void}
     */
    const handleServiceIdOnBlur = () => setTimeout(() => setShowServiceIdDropdown(false), 200)

    /**
     * 服務代號下拉選單點擊時
     * @param option {Object} option
     * @return {void}
     */
    const handleServiceIdOptionOnClick = (option) => {
        // 設定服務代號
        setServiceId(option.serviceId)
        // 關閉服務代號下拉選單
        setShowServiceIdDropdown(false)
    }

    /**
     * 負責人代號改變時
     * @param e {event} event
     * @return {void}
     */
    const handleOwnerUserNoOnChange = (e) => setOwnerUserNo(e.target.value)

    /**
     * 負責人代號下拉選單取得焦點時
     * @return {void}
     */
    const handleOwnerUserNoOnFocus = () => setShowOwnerUserNoDropdown(true)

    /**
     * 負責人代號下拉選單失去焦點時
     * @return {void}
     */
    const handleOwnerUserNoOnBlur = () => setTimeout(() => setShowOwnerUserNoDropdown(false), 200)

    /**
     * 負責人代號下拉選單點擊時
     * @param option {Object} option
     * @return {void}
     */
    const handleOwnerUserNoOptionOnClick = (option) => {
        // 設定負責人代號
        setOwnerUserNo(option.ownerUserNo)
        // 關閉負責人代號下拉選單
        setShowOwnerUserNoDropdown(false)
    }

    /**
     * 服務紀錄起始日期改變時
     * @param e {event} event
     * @return {void}
     */
    const handleLogStartDateOnChange = (e) => setLogStartDate(e.target.value)

    /**
     * 服務紀錄起始時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleLogStartTimeOnChange = (e) => setLogStartTime(e.target.value)

    /**
     * 服務紀錄結束日期改變時
     * @param e {event} event
     * @return {void}
     */
    const handleLogEndDateOnChange = (e) => setLogEndDate(e.target.value)

    /**
     * 服務紀錄結束時間改變時
     * @param e {event} event
     * @return {void}
     */
    const handleLogEndTimeOnChange = (e) => setLogEndTime(e.target.value)

    /**
     * 執行結果改變時
     */
    const handleOperResultOnChange = (e) => setOperResult(e.target.value)

    /**
     * 院區改變時
     * @param index {number} index
     * @return {void}
     */
    const handleCampusOnChange = (index) => {
        // 取得當前院區
        const newCampus = [...campus]
        // 設定當前院區值
        newCampus[index] = {...newCampus[index], value: !newCampus[index].value}
        // 設定院區
        setCampus(newCampus)
    }

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryServiceMonitorOperlog()

    /**
     * 搜尋文字改變
     * @param e {event} event
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得篩選資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)

    /**
     * 取得篩選服務代號資料
     * @return {Array<Object>}
     */
    const getFilterServiceData = () => fuzzySearchObjects(baseData.serviceIdAndNameList, ownerUserNo)

    /**
     * 取得篩選負責人資料
     * @return {Array<Object>}
     */
    const getFilterOwnerUserData = () => fuzzySearchObjects(baseData.ownerUserSet, ownerUserNo)

    /**
     * 取得基礎資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisServiceMonitorOperLogQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(data)
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 服務Log查詢
     * @return {void}
     */
    const getQueryServiceMonitorOperlog = () => {
        // 取得分院
        const zone = campus.filter(item => item.value === true).map(item => item.key)
        wdisQueryServiceMonitorOperlog({
            //院區代號
            zone: zone.join(','),
            //服務代號
            serviceId: serviceId,
            //負責人代號
            ownerUserNo: ownerUserNo,
            //操作結果(0正常/1異常)
            // 指當下記錄之程序返回狀態(0正常/1異常)
            operResult: operResult ? 'N' : 'Y',
            //服務紀錄時間--起
            logStartDatetime: `${logStartDate} ${logStartTime}`,
            //服務紀錄時間--訖
            logEndDatetime: `${logEndDate} ${logEndTime}`
        }).then(res => {
            // 取得狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
                // 清空資料
                setData([])
            }
        })
    }

    /**
     * 第一次進入頁面時
     * @return {void}
     */
    useEffect(() => {
        // 取得基礎資料
        getQueryBaseData()
        // 建立院區
        const createCampus = campusList.map(item => ({key: item, value: false}))
        // 取得當前角色
        const currentCampus = getLocalStorage('campus')
        const newCampus = createCampus.map(item => item.key === currentCampus ? {...item, value: true} : item)
        // 設定當前角色
        setCampus(newCampus)
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/*服務代號/名稱*/}
                    <p className="mr-1">服務代號/名稱：</p>
                    <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4">
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            type={InputTypeEnum.Text} inputMode={'search'} value={serviceId}
                            onChange={handleServiceIdOnChange}
                            onFocus={handleServiceIdOnFocus}
                            onBlur={handleServiceIdOnBlur}/>
                        {
                            showServiceIdDropdown && !arrayIsEmpty(baseData.serviceIdAndNameList) &&
                            (
                                <div
                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            getFilterServiceData().map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleServiceIdOptionOnClick(item)}>
                                                    <p>{`${item.serviceId} ${item.serviceName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*負責人員*/}
                    <p className="mr-1">負責人員：</p>
                    <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4">
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text} inputMode={'search'} value={ownerUserNo}
                                   onChange={handleOwnerUserNoOnChange}
                                   onFocus={handleOwnerUserNoOnFocus}
                                   onBlur={handleOwnerUserNoOnBlur}/>
                        {
                            showOwnerUserNoDropdown && !arrayIsEmpty(baseData.ownerUserSet) &&
                            (
                                <div
                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            getFilterOwnerUserData().map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleOwnerUserNoOptionOnClick(item)}>
                                                    <p>{`${item.ownerUserNo} ${item.ownerUserName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*服務記錄起始日期*/}
                    <p className="mr-1">服務記錄時間：</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                               type={InputTypeEnum.Date} inputMode={'date'} value={logStartDate}
                               onChange={handleLogStartDateOnChange}/>
                    {/*服務記錄起始時間*/}
                    <BaseInput
                        className="flex justify-start w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 bg-arrowDown bg-[length:18px_18px] bg-[center_right_8px] bg-no-repeat"
                        type={InputTypeEnum.Text} placeholder="--:--:--" value={logStartTime}
                        onChange={handleLogStartTimeOnChange}/>
                    <style>
                        {`input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {opacity: 0;}`}
                    </style>
                    <p className="mr-4">~</p>
                    {/*服務記錄終止日期*/}
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                               type={InputTypeEnum.Date} inputMode={'date'} value={logEndDate}
                               onChange={handleLogEndDateOnChange}/>
                    {/*服務記錄終止時間*/}
                    <BaseInput
                        className="flex justify-start w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 bg-arrowDown bg-[length:18px_18px] bg-[center_right_8px] bg-no-repeat"
                        type={InputTypeEnum.Text} value={logEndTime} onChange={handleLogEndTimeOnChange}/>
                </div>
                {/*執行結果*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-1">執行結果：</p>
                    <select className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                            value={operResult} onChange={handleOperResultOnChange}>
                        {
                            Object.keys(WDISServiceLogOperationEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISServiceLogOperationEnum[key]}>
                                        {t(`WDIS.OperationEnum.${WDISServiceLogOperationEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                </div>
                {
                    // 顯示院區多選
                    !arrayIsEmpty(campus) && campus.map((item, index) =>
                        <div className="flex flex-row space-x-3 mb-4 mr-4" key={index}>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                                   id={`r${index}`} style={{accentColor: "#38A169"}} checked={campus[index].value}
                                   onChange={() => handleCampusOnChange(index)}/>
                            <label className="cursor-pointer" htmlFor={`r${index}`}>
                                {t(`campus.shortName.${item.key}`)}
                            </label>
                        </div>
                    )
                }
                {/*查詢按鈕*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleQueryOnClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>
            {/*服務表格清單*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    {/*進階搜尋*/}
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <BaseInput className={`w-[320px] h-10 pl-8 border-[2px] border-[#D4D4D8] rounded-[6px]`}
                                   type="text" placeholder={t('general.advancedSearch')} inputMode={'search'}
                                   value={searchText} onChange={handleSearchTextOnChange}/>
                    </div>
                    <ul className="tableList SLTableList">
                        <li className="title">
                            <div>服務代號</div>
                            <div>服務名稱</div>
                            <div>負責人</div>
                            <div>紀錄時間</div>
                            <div>結束時間</div>
                            <div>狀態</div>
                            <div>通知</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*服務代號*/}
                                    <div>{item.serviceId}</div>
                                    {/*服務名稱*/}
                                    <div>{item.serviceName}</div>
                                    {/*負責人*/}
                                    <div>{`${item.ownerUserNo} ${item.ownerUserName}`}</div>
                                    {/*紀錄時間*/}
                                    <div>{item.logDatetime}</div>
                                    {/*結束時間*/}
                                    <div>{item.serviceExecEndDatetime}</div>
                                    {/*狀態*/}
                                    <div>{t(`WDIS.OperationEnum.${item.operResult === 'Y' ? 0 : 1}`)}</div>
                                    {/*通知*/}
                                    <div>{item.notifyMessage}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default ServiceLogInquiry
