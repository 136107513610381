import {BasePopup} from "./BasePopup"
import React, {useEffect, useState} from "react"
import {t} from "i18next"

/**
 * 刪除彈窗
 * @param content {String} 彈窗內容
 * @param show {Boolean} 是否顯示
 * @param onYesButtonClick {function} 確認刪除時的 callback
 * @param onNoButtonClick {function} 取消刪除時的 callback
 * @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 * @return {JSX.Element}
 */
export const DeletePopup = ({content, show, onYesButtonClick, onNoButtonClick, onClosePopupButtonClick}) => {
    // 是否顯示刪除彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 取得 Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">{content}</p>
        <div className="h-[60px] flex flex-row items-center justify-end">
            {/*是*/}
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                onClick={onYesButtonClick}>
                {t('general.yes')}
            </button>
            {/*否*/}
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                onClick={onNoButtonClick}>
                {t('general.no')}
            </button>
        </div>
    </div>

    /**
     * show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return isShow ?
        <BasePopup title={t('general.reminder')} width="559px" closePopupButtonOnClick={onClosePopupButtonClick}
                   content={getContent()}/> : null
}
