import {t} from "i18next"
import React, {useEffect, useState} from "react"

/**
 * PDF Preview Popup
 * @param blob {Blob} Blob
 * @param closePopupButtonOnClick {function|null} 關閉時的方法
 * @param showPopup {Boolean} 是否顯示彈窗
 * @param width {String} 彈窗寬度
 * @param height {String} 彈窗高度
 * @return {JSX.Element}
 */
const PDFPreviewPopup = ({blob, closePopupButtonOnClick, showPopup = false, width = "80%", height = "80%"}) => {
    // 是否顯示彈窗
    const [show, setShow] = useState(showPopup)

    /**
     * 關閉彈窗時
     * @return {void}
     */
    const handleClosePopup = () => {
        // 關閉彈窗
        setShow(false)
        //方法存在時
        if (closePopupButtonOnClick) {
            closePopupButtonOnClick()
        }
    }

    /**
     * 監聽狀態變化
     * @return {void}
     */
    useEffect(() => setShow(showPopup), [showPopup])

    return show && blob ?
        <div
            className="fixed inset-0 flex items-center justify-center w-full h-full bg-[rgba(0,0,0,0.3)] z-50 overflow-auto">
            <div className={`w-[${width}] h-[${height}] bg-white rounded-[6px]`}>
                <div
                    className="title w-full flex flex-row items-center justify-end px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*關閉按鈕*/}
                    <button className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                            onClick={handleClosePopup}>
                        {t('general.close')}
                    </button>
                </div>
                <iframe src={URL.createObjectURL(blob)} width="100%" height="100%" className="rounded-[6px]"/>
            </div>
        </div> :
        <></>
}
export default PDFPreviewPopup
