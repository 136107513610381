import {useEffect, useMemo, useRef, useState} from "react"
import AppointmentPopup from "../../components/Regs/MonthMaintenance/AppointmentPopup"
import {
    regsMonthClinicBaseData,
    regsQueryMonthClinicByDivNo,
    regsQueryMonthClinicByDoctorNo
} from "../../api/v1/RegsMonth"
import {ApiErrorStatusEnum, arrayIsEmpty, PopupModeEnum, RegsWeekModeEnum, TimeslotEnum} from "edah_utils/dist"
import {t} from "i18next"
import ScheduleTimeBox from "../../components/Regs/MonthMaintenance/ScheduleTimeTable/ScheduleTimeBox"
import useOutsideClick from "../../hooks/useOutsideClick"

/**
 * 月班維護
 * @return {JSX.Element}
 */
const MonthMaintenance = () => {
    // 計算總高度
    const totalHeight = window.innerHeight
    // 頂部區塊&日期區塊高度
    const defaultHeight = 120
    const topBlockHeight = 60
    const dateBlockHeight = 40
    // remain height
    const remainingHeight = totalHeight - defaultHeight - topBlockHeight - dateBlockHeight
    //計算時段高度
    const blockHeightPerSection = remainingHeight / 3

    //當前週數
    const [currentDate, setCurrentDate] = useState(null)
    //Viewing Filter -> 醫師 or 科別
    const [filterMode, setFilterMode] = useState(RegsWeekModeEnum.Department)
    //搜尋文字
    const [searchText, setSearchText] = useState("")
    // 所有 醫師 list/科別 list
    const [allDoctorOrDept, setAllDoctorOrDept] = useState(null)
    // 醫師/科別的下拉選單
    const [doctorOrDeptOptionList, setDoctorOrDeptOptionList] = useState(null)
    // 是否顯示Popup
    const [showPopup, setShowPopup] = useState(false)
    // Popup模式
    const [popupMode, setPopupMode] = useState(null)
    //Show/hide 醫師/科別下拉選單
    const [showDoctorOrDeptOptions, setShowDoctorOrDeptOptions] = useState(false)
    // 基礎資料
    const [baseData, setBaseData] = useState(null)
    // 月班資料
    const [data, setData] = useState(null)
    //當前右鍵點擊的區塊
    const [currentRightClick, setCurrentRightClick] = useState({
        week: null,
        timeslot: null,
    })
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null)
    //ref 用於指向醫師或科別下拉菜單元素
    const dropdownDoctorOrDeptRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorOrDeptRef,
        handler: () => setShowDoctorOrDeptOptions(false),
    });

    /**
     * 預設周邊要插入資料
     * @return {Array<Object>}
     */
    const defaultWeekData = () => Array.from({length: 7}, (_, i) => i + 1).map(
        (index) => {
            return {
                //星期幾的數值
                weekIndex: index,
                // 早上
                morning: [],
                // 中午
                afternoon: [],
                // 晚上
                night: [],
            }
        }
    )

    /**
     * 改變日期時
     * @param e {Event}
     * @return {void}
     */
    const handleDateOnChange = (e) => {
        //設定當前日期
        setCurrentDate(e.target.value)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
    }

    /**
     * 下拉選單焦點事件
     * @return {void}
     */
    const handleInputDoctorOrDeptOnFocus = () => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
        setShowDoctorOrDeptOptions(true)
    }

    /**
     * 搜尋文字改變時
     * @param e {Object}
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 模式改變時
     * @param mode {Number}
     * @return {void}
     */
    const handleModeOnChange = (mode) => {
        //設定模式
        setFilterMode(mode)
        //是否科別模式
        const isDepartmentMode = mode === RegsWeekModeEnum.Department
        //設定過濾選項
        setSearchText('')
        //根據科別取得下拉選項
        const options = isDepartmentMode ? baseData.divisionList : baseData.doctorList
        //設定下拉選項
        setAllDoctorOrDept(options)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
    }

    /**
     * 取得過濾選項
     * @param option {Object}
     * @return {string}
     */
    const getFilterOptionContent = (option) => filterMode === RegsWeekModeEnum.Department ?
        //科別代號 科別名稱
        `${option.divNo} ${option.divName}` :
        //醫師代號 醫師名稱
        `${option.userNo} ${option.userName}`

    /**
     * 取得到搜尋的科別或醫生選項
     * @return {Array<Object>}
     */
    const getDoctorOrDeptOptions = () => {
        //搜尋文字為空時
        if (searchText === '') {
            return allDoctorOrDept
        } else { //搜尋文字有數據時
            return allDoctorOrDept.filter(option => {
                //是否科別模式
                const isDepartmentMode = filterMode === RegsWeekModeEnum.Department
                //編號
                const noID = isDepartmentMode ? option.divNo : option.userNo
                //名稱
                const name = isDepartmentMode ? option.divName : option.userName
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //回傳是否符合條件
                return hasSpace ?
                    //有空白時
                    (noID.includes(searchText.split(' ')[0]) && name.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (noID.includes(searchText) || name.includes(searchText))
            })
        }
    }

    /**
     * 選擇過濾選項時
     * @param option {Object}
     * @return void
     */
    const handleFilterOptionOnClick = (option) => {
        //是否科別模式
        const isDepartmentMode = filterMode === RegsWeekModeEnum.Department
        //設定搜尋文字
        setSearchText(isDepartmentMode ? `${option.divNo} ${option.divName}` : `${option.userNo} ${option.userName}`)
    }

    /**
     * 取得月班資料
     * @return {void}
     */
    const getMonthData = () => {
        //是否科別模式
        const isDepartmentMode = filterMode === RegsWeekModeEnum.Department
        //編號
        const no = searchText ? searchText.split(' ')[0] : null
        // 月班模式
        if (isDepartmentMode) {
            //依科別查詢月班表
            regsQueryMonthClinicByDivNo({
                encounterDate: `${getWeekFirstDay()} 00:00:00`,
                divNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        } else { // 醫師模式
            //依醫師查詢月班表
            regsQueryMonthClinicByDoctorNo({
                encounterDate: `${getWeekFirstDay()} 00:00:00`,
                doctorNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        }
    }

    /**
     * 解析月班資料
     * @param data {Array<Object>}
     * @return {void}
     */
    const parserMonthData = (data) => {
        //設定資料
        const newData = [...defaultWeekData()]

        data.forEach(item => {
            //取得星期幾
            let week = parseInt(item.week)
            if (week === 0) week = 7
            //取得時段
            const apn = item.apn
            //根據時段去設定週班資料
            switch (apn) {
                case TimeslotEnum.Morning:
                    newData[week - 1].morning.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Afternoon:
                    newData[week - 1].afternoon.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Night:
                    newData[week - 1].night.push(...item.regsClinicList)
                    break
            }
        })
        //設定資料
        setData(newData)
    }

    /**
     * 關閉Popup
     * @return {void}
     */
    const handleClosePopup = () => setShowPopup(false)

    /**
     * 取得本週日期
     * @return {Array<Object>}
     */
    const getWeekDates = () => {
        //避免第一次進來時沒有日期報錯
        if (!currentDate) {
            return []
        }

        // 分解年份和週數
        const [year, week] = currentDate.split('-W').map(Number)
        // 獲得該年第一天
        const firstDayOfYear = new Date(year, 0, 1)
        // 複製日期以調整
        const firstDayOfWeek = new Date(firstDayOfYear)

        // 計算該年第一天是星期幾 (星期天是0, 星期一是1, ..., 星期六是6)
        const dayOfWeek = firstDayOfYear.getDay()

        // 調整日期到第一週的星期一
        // 如果第一天是星期天 (0)，我們需要向前調整至上一年的12月的最後幾天
        firstDayOfWeek.setDate(1 + ((dayOfWeek === 0 ? -6 : 1 - dayOfWeek) + 7 * (week - 1)))

        // 保存該週的所有日期
        const weekDates = []
        // 生成該週的所有日期 (從星期一到星期日)
        for (let i = 0; i < 7; i++) {
            // 複製日期以調整
            let day = new Date(firstDayOfWeek)
            // 調整日期
            day.setDate(firstDayOfWeek.getDate() + i)
            // 月份從0開始，因此顯示時需要加1
            const month = day.getMonth() + 1
            // 獲得日期
            const date = day.getDate()
            // 格式化月份和日期，確保始終是兩位數格式
            const formattedDate = `${month.toString().padStart(2, '0')}/${date.toString().padStart(2, '0')}`
            // 格式化日期或者直接使用 Date 物件
            weekDates.push(`${formattedDate}(${t(`general.dateTime.weekRange.short.${(i + 1)}`)})`)
        }

        return weekDates
    }

    /**
     * 取得ISO週數
     * @param date {Date}
     * @return {String}
     */
    const getISOWeekByDate = (date) => {
        const current = new Date(date.getTime())
        const dayOfWeek = current.getDay()
        const currentDay = current.getDate()
        const currentYear = current.getFullYear()

        // 設定當前日期為星期日的情況，使其成為星期天 (0) 以符合 ISO 8601，其中星期一是1
        current.setDate(currentDay + (dayOfWeek === 0 ? -6 : 1 - dayOfWeek))

        // 讓年的第一天成為計算基點
        let yearStart = new Date(currentYear, 0, 1)
        const startDayOfWeek = yearStart.getDay()
        yearStart.setDate(yearStart.getDate() + ((startDayOfWeek === 0 ? 1 : 8 - startDayOfWeek)))

        // 第一週可能在去年的最後幾天開始
        if (yearStart > new Date(currentYear, 0, 4)) {
            yearStart.setDate(yearStart.getDate() - 7)
        }
        // 計算週數
        const weekNumber = Math.ceil(((current - yearStart) / (24 * 60 * 60 * 1000) + 1) / 7)

        return `${currentYear}-W${weekNumber}`
    }

    /**
     * 點擊上一週或下一週時
     * @param isIncrement {Boolean} 是否增加
     * @return {void}
     */
    const handleCurrentWeekOnClick = (isIncrement = true) => {
        // 分割成 [YYYY, WW]
        const parts = currentDate.split('-W')
        // 取得年份和週數
        let year = parseInt(parts[0])
        // 週數
        let week = parseInt(parts[1])

        // 計算新的週數
        week += isIncrement ? 1 : -1
        // 假設每年只有52週
        // 處理週數超出範圍
        if (week > 52) {
            year += 1
            week = 1
        } else if (week < 1) { // 週數小於1
            year -= 1
            week = 52
        }

        // 格式化週數保持兩位數
        setCurrentDate(`${year}-W${week.toString().padStart(2, '0')}`)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
    }

    /**
     * 取得週的第一天
     * @return {String}
     */
    const getWeekFirstDay = () => {
        // 如果當前日期不存在，則返回空
        if (!currentDate) {
            return ''
        }

        // 解析年份和週數
        const [year, week] = currentDate.split('-W')
        // 獲得該年第一天
        const firstDayOfYear = new Date(year, 0, 1)
        // 該年第一天是星期幾 (星期天是0, 星期一是1, ..., 星期六是6)
        const firstDayOfYearDayOfWeek = firstDayOfYear.getDay()

        // 計算該年第一週的星期一日期
        // 如果1月1日是星期二至星期六之間，第一週的星期一需要回到上一年12月的某天
        // 如果1月1日是星期日，第一週的星期一是1月2日
        // 如果1月1日是星期一，那麼它就是第一週的星期一
        let firstMonday = new Date(year, 0, 1 - (firstDayOfYearDayOfWeek - 1))
        if (firstDayOfYearDayOfWeek === 0) {
            // 如果1月1日是星期天，則第一週的星期一是1月2日
            firstMonday = new Date(year, 0, 2)
        } else if (firstDayOfYearDayOfWeek > 1) {
            // 如果1月1日是星期二至星期六，回退到上一周
            firstMonday = new Date(year, 0, 2 - firstDayOfYearDayOfWeek)
        }

        // 計算目標週的星期一日期
        const weekMonday = new Date(firstMonday)
        // 添加週數的天數
        weekMonday.setDate(firstMonday.getDate() + (week - 1) * 7)
        return `${weekMonday.getFullYear()}-${(weekMonday.getMonth() + 1).toString().padStart(2, '0')}-${weekMonday.getDate().toString().padStart(2, '0')}`
    }

    /**
     * 取得週的指定星期幾的日期
     * @param dayOfWeek {Number} 星期幾 (0: 星期日, 1: 星期一, ..., 6: 星期六)
     * @return {String}
     */
    function getDateOfWeek(dayOfWeek) {
        // 如果當前日期不存在，則返回空字串
        if (!currentDate) {
            return ''
        }

        // 解析年份和週數
        const [year, week] = currentDate.split('-W')

        // 獲得該年的第一天
        const firstDayOfYear = new Date(year, 0, 1)

        // 該年第一天是星期幾 (星期日是0, 星期一是1, ..., 星期六是6)
        const firstDayOfYearDayOfWeek = firstDayOfYear.getDay()

        // 計算該年第一週的星期一日期
        let firstMonday = new Date(year, 0, 1 - (firstDayOfYearDayOfWeek - 1))
        if (firstDayOfYearDayOfWeek === 0) {
            // 如果1月1日是星期日，則第一週的星期一是1月2日
            firstMonday = new Date(year, 0, 2)
        } else if (firstDayOfYearDayOfWeek > 1) {
            // 如果1月1日是星期二至星期六，則回退到上一周的星期一
            firstMonday = new Date(year, 0, 2 - firstDayOfYearDayOfWeek)
        }

        // 計算目標週的星期一日期
        const weekMonday = new Date(firstMonday)
        weekMonday.setDate(firstMonday.getDate() + (week - 1) * 7)

        // 計算目標週的指定星期幾的日期
        const targetDate = new Date(weekMonday)
        targetDate.setDate(weekMonday.getDate() + dayOfWeek)

        // 格式化日期為YYYY-MM-DD
        return `${targetDate.getFullYear()}/${(targetDate.getMonth() + 1).toString().padStart(2, '0')}/${targetDate.getDate().toString().padStart(2, '0')}`
    }

    /**
     * 新增新問診時
     * @param week {Number} 週數
     * @param timeslot {Number} 時段
     */
    const handleOnAddNewAppointment = (week, timeslot) => setCurrentRightClick({week: week, timeslot: timeslot})

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {Number}
     * @return {void}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode)
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
                //設定日期
                date: getDateOfWeek(currentRightClick.week),
                //當前選擇醫生
                doctor: filterMode === RegsWeekModeEnum.Doctor ? (searchText ? searchText : null) : null,
                //當前選擇科別
                department: filterMode === RegsWeekModeEnum.Department ? (searchText ? searchText : null) : null
            }
        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setShowPopup(true)
    }

    /**
     * 取得月班資料
     * @return {void}
     */
    const handleGetMonthData = () => {
        // 關閉Popup
        setShowPopup(false)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        // 取得月班資料
        getMonthData()
    }

    /**
     * 第一次進來時取得基礎資料
     * @return {void}
     */
    useMemo(() => {
        setData(defaultWeekData())
        // 設定當前週
        setCurrentDate(getISOWeekByDate(new Date()))
        // 取得月班基礎資料
        regsMonthClinicBaseData({}).then(res => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(res.data)
                // 設定科別
                setAllDoctorOrDept(res.data.divisionList)
            }
        })
    }, [])

    /**
     * 當日期改變時
     * @return {void}
     */
    useEffect(() => {
        if (!!currentDate) {
            //取得月班資料
            getMonthData()
        }
    }, [currentDate])

    /**
     * 搜尋文字改變時
     * @return {void}
     */
    useEffect(() => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
        //切割有空白的文字為陣列
        const splitArray = searchText ? searchText.split(' ') : []
        //搜尋文字有空白時
        if (searchText && splitArray.length === 2) {
            //取得月班資料
            getMonthData()
        }
    }, [searchText])

    return (
        <div className="w-full">
            <div className="w-full overflow-x-scroll">
                {/*頂部區塊*/}
                <div className="w-full min-w-[1688px] flex flex-row items-center space-x-8 px-[26px] py-2">
                    <select className="border-2 p-2 rounded-[6px] px-4">
                        <option>屏東義大醫院</option>
                    </select>
                    <div className="flex flex-row space-x-6">
                        <button className="border-2 bg-gray-100 px-4 py-1 text-lg rounded-[6px]"
                                onClick={() => handleCurrentWeekOnClick(false)}>
                            {t('general.dateTime.lastWeek')}
                        </button>
                        <input className="border-2 text-center px-2 rounded-[6px]" type="week" value={currentDate}
                               onChange={(e) => handleDateOnChange(e)}/>
                        <button className="border-2 bg-gray-100 px-4 py-1 text-lg rounded-[6px]"
                                onClick={() => handleCurrentWeekOnClick()}>
                            {t('general.dateTime.nextWeek')}
                        </button>
                    </div>

                    <div className="flex flex-row space-x-6 items-center">
                        <div className="flex flex-row space-x-4 items-center">
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                id="departmentRadio" type="radio" name="filterRadio"
                                onChange={() => handleModeOnChange(RegsWeekModeEnum.Department)}
                                checked={filterMode === RegsWeekModeEnum.Department}/>
                            <label htmlFor="departmentRadio"
                                   style={{cursor: "pointer"}}>{t('general.department')}</label>
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                id="doctorRadio" type="radio" name="filterRadio"
                                checked={filterMode === RegsWeekModeEnum.Doctor}
                                onChange={() => handleModeOnChange(RegsWeekModeEnum.Doctor)}/>
                            <label htmlFor="doctorRadio" style={{cursor: "pointer"}}>{t('general.doctor')}</label>
                        </div>

                        <div className="flex flex-row items-center relative w-[270px]" ref={dropdownDoctorOrDeptRef}>
                            <input
                                className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                                value={searchText}
                                onChange={(e) => handleSearchTextOnChange(e)}
                                onFocus={() => handleInputDoctorOrDeptOnFocus()}/>
                            {
                                showDoctorOrDeptOptions && !arrayIsEmpty(doctorOrDeptOptionList) && (
                                    /*醫師下拉*/
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                doctorOrDeptOptionList.map((option, index) => (
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        key={index} role="button"
                                                        onClick={() => handleFilterOptionOnClick(option)}>
                                                        {getFilterOptionContent(option)}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {/*月班Content*/}
                <div className="flex flex-col w-full min-w-[1688px]">
                    {/**日期區 */}
                    <div className="w-full py-1 pl-[72px] pr-[16px] min-h-[40px] flex items-center mt-[5px]">
                        <div className="w-full min-w-[1457px] flex flex-row gap-2">
                            {
                                getWeekDates().map((date, index) =>
                                    <div className="w-full min-w-[220px] flex justify-center" key={index}>
                                        <p>{date}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/**區塊 - 早上*/}
                    <div className="w-full min-w-[1688px] h-[208px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1  ">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.morning')}</p>
                                <p className="w-12 text-center">08:30</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">12:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                <ScheduleTimeBox
                                    key={index}
                                    list={d.morning}
                                    timeslot={TimeslotEnum.Morning}
                                    week={index}
                                    baseData={baseData}
                                    isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Morning}
                                    onAddAppointment={handleOnAddNewAppointment}
                                    showPopup={handleShowPopup}/>
                            ))
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                    {/**區塊 - 下午 */}
                    <div className="w-full min-w-[1688px] h-[208px] bg-customYellow flex flex-row gap-2 px-4 py-1">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.afternoon')}</p>
                                <p className="w-12 text-center">13:30</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">17:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                <ScheduleTimeBox
                                    key={index}
                                    list={d.afternoon}
                                    timeslot={TimeslotEnum.Afternoon}
                                    week={index}
                                    baseData={baseData}
                                    isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Afternoon}
                                    onAddAppointment={handleOnAddNewAppointment}
                                    showPopup={handleShowPopup}/>
                            ))
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                    {/**區塊 - 晚上 */}
                    <div className="w-full min-w-[1688px] h-[208px] bg-[#FBF5FF] flex flex-row gap-2 px-4 py-1">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.night')}</p>
                                <p className="w-12 text-center">18:00</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">21:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                    <ScheduleTimeBox
                                        key={index}
                                        list={d.night}
                                        timeslot={TimeslotEnum.Night}
                                        week={index}
                                        baseData={baseData}
                                        isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Night}
                                        onAddAppointment={handleOnAddNewAppointment}
                                        showPopup={handleShowPopup}/>
                                )
                            )
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*月班Popup*/}
            {
                showPopup && (
                    <AppointmentPopup
                        mode={popupMode}
                        filterMode={filterMode}
                        baseData={baseData}
                        data={selectedData}
                        closePopup={handleClosePopup}
                        getMonthData={handleGetMonthData}/>
                )
            }
        </div>
    )
}

export default MonthMaintenance
