import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ReactComponent as AddIcon} from '../../../assets/images/icons/add_circle.svg';
import CustomTable from '../RSISCustomTable';
import ListItem from './ListItem';
import {usePopup} from '../PopupProvider';
import DeletePopup from '../Popup/DeletePopup';
import {rsisDelOwnerUser, rsisQueryOwnerUser, rsisAddOwnerUser} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum, ToastTypeEnum} from "edah_utils/dist"
import QueryCreateUserList from "../Modal/QueryCreateUserList";

// JIRA EDAQA-74 305 318

// 表格欄位
const fields = [
    "",
    "人員工號",
    "姓名",
    "部門代號",
    "部門名稱",
]

/**
 * 承辦部門人員維護
 * @returns {JSX.Element}
 */
const ContactPersonMaintain = () => {
    // 表格資料集
    const [dataList, setDataList] = useState([])
    // 列表組件
    const List = ListItem
    // 使用上下文
    const {
        setShowQueryCreateUserList,
        showQueryCreateUserList,
        showDeletePopup,
        setShowDeletePopup,
        focusItem,
        setFocusItem,
        showToast,
    } = usePopup()

    // 分頁參數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    /**
     * 查詢彈窗點擊時，更新列表事件
     * @param {Object} item - 選擇的人員
     * @return {void}
     */
    const handleOnUpdate = (item) => {
        setFocusItem(item)
        // 關閉彈窗
        setShowQueryCreateUserList(false)
    }

    /**
     * 新增列表事件，根據editable狀態新增一個可編輯或不可編輯的列表
     * @return {void}
     */
    const handleAddItemOnClick = () => {
        const newItem = {
            id: Date.now(), // 使用臨時 ID
            editable: true,
            userNo: "",
            userName: "",
            deptNo: "",
            deptName: "",
            isNew: true // 標記為新項目
        };
        setDataList(prevDataList => [newItem, ...prevDataList]);
    }

    /**
     * 儲存列表事件
     * @param {Object} item - 要保存的項目
     * @return {void}
     */
    const handleSaveItemOnClick = (item) => {
        rsisAddOwnerUser(item).then(res => {
            const {err, data, msg} = res
            // 保存成功
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '保存成功', type: ToastTypeEnum.Success})
                // 更新列表中的項目，將其標記為非新項目且不可編輯
                getQueryOwnerUser()
            } else {
                // 保存失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }


    /**
     * 修改現有列表項目中的特定欄位資料
     * @param {string} id - 列表項目 ID
     * @param {field} string - 列表項目欄位
     * @param {value} string - 列表項目值
     * @return {void}
     */
    const handleItemChange = useCallback((id, field, value) => {
        setDataList(prevDataList => prevDataList.map(item =>
            item.id === id ? {...item, [field]: value} : item
        ))
    }, [])

    /**
     * 取消新增列表事件
     * @param {number} index - 要取消的項目索引
     * @return {void}
     */
    const handleCancelItemOnClick = (id) => {
        setDataList(prevDataList => prevDataList.filter(item => item.id !== id));
    }

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = () => delOwnerUser()

    /**
     * 刪除承辦部門人員
     * @return {void}
     */
    const delOwnerUser = () => {
        rsisDelOwnerUser({
            // 人員工號
            useNo: focusItem.userNo,
        }).then(res => {
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                getQueryOwnerUser()
                // 顯示成功訊息
                showToast({message: msg, type: ToastTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦部門人員所有資料
     * @return {void}
     */
    const getQueryOwnerUser = () => {
        rsisQueryOwnerUser({}).then(res => {
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
                // 關閉刪除彈窗
                setShowDeletePopup(false)
            } else { //取得失敗
                //清除列表資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得承辦部門人員所有資料
        getQueryOwnerUser()
    }, [])

    return (
        <div className='px-6 py-2'>
            <div className='w-[76px] flex flex-row items-center gap-1 my-4 hover:cursor-pointer'
                 onClick={handleAddItemOnClick}>
                <AddIcon className="w-[33.33px] h-[33.33px]"/>
                <p className='text-[#38A169]'>新增</p>
            </div>
            <CustomTable
                advancedSearch={true}
                fields={fields}
                dataList={dataList}
                ListItem={ListItem}
                type="RSISContactPersonTable"
                paginationProps={paginationProps}
                handleOnListItemSave={handleSaveItemOnClick}
                handleOnListItemCancel={handleCancelItemOnClick}
                handleOnListItemChange={handleItemChange}
            />
            {/* 刪除彈窗 */}
            {showDeletePopup &&
                <DeletePopup
                    handleDeletePopupClose={() => setShowDeletePopup(false)}
                    handleNotificationDelete={handleListItemOnDelete}
                    currentKey={focusItem}
                />
            }
            {showQueryCreateUserList && (
                <QueryCreateUserList
                    handleOnUpdate={handleOnUpdate}
                    handleOnClose={() => setShowQueryCreateUserList(false)}
                />
            )}
        </div>
    )
}

export default ContactPersonMaintain
