//Import css
import '../../TableList/tableList.scss';

//Import Function
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    ApiErrorStatusEnum,
    enum2Array,
    InputTypeEnum,
    RsisStepStatusEnum, stringIsEmpty,
    time2String,
    ToastTypeEnum
} from 'edah_utils/dist'
import {
    CancelSlotBtn,
    ChangeSlotBtn,
    CopySlotBtn,
    DelSlotBtn,
    EditSlotBtn,
    SaveIconSlotBtn,
    LofoffSlotBtn,
    FastEditSlotBtn
} from '../SlotButton';
import {usePopup} from '../PopupProvider';
import {t} from "i18next"
import {rsisQuickEdit} from "../../../api/v1/RSIS";

/**
 * 輸入框組件
 * @param type {String} 輸入框類型
 * @param width {String} 輸入框寬度
 * @param value {String} 輸入框值
 * @param onChange {Function} 輸入框變更事件
 * @param disabled {Boolean} 是否禁用
 * @return {JSX.Element}
 */
const Input = ({type, width = 'w-[102px]', value, onChange, disabled}) =>
    <input
        className={`${width} h-10 px-2 text-black ${disabled ? 'bg-transparent' : 'bg-white rounded-[6px] border border-gray-300'}`}
        type={type} value={value} onChange={onChange} disabled={disabled}/>

/**
 * RSIS個案管理清單組件
 * @param {Object} item 清單資料
 * @param {Number} index 清單索引
 * @param {String} role 使用者角色
 * @param {boolean} isSelectAll 是否包含全選
 * @param {function} handleOnSelected 選取事件
 * @param {function} handleOnUnSelected 取消選取事件
 * @return {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                      handleOnSelected,
                      handleOnUnSelected,
                      handleOnUpdate
                  }) => {
    // 本地資料
    const [data, setData] = useState(item)
    // 本地狀態別
    const [stepStatus, setStepStatus] = useState(item.stepStatus)
    // 本地衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState(item.sendCaseNo)
    // 本地匯出編號
    const [sendFileName, setSendFileName] = useState(item.sendFileName)
    // 是否快速編輯狀態
    const [isFastEdit, setIsFastEdit] = useState(false)
    // 是否可選取
    const [isDisabled, setIsDisabled] = useState(false)
    // 是否選取
    const [isSelected, setIsSelected] = useState(false)

    // 使用上下文
    const {
        batchSelected,
        setShowCopyModal,
        setShowCaseConfirmation,
        setShowDeletePopup,
        setShowSendMOHWConfirm,
        setShowChangeModal,
        setShowLogOffModal,
        listItemBtnType,
        setListItemBtnType,
        setFocusIndex,
        showToast
    } = usePopup();

    /**
     * 勾選框觸發事件
     * @param {Event} e
     * @return {void}
     */
    const handleOnSelectedChange = (e) => {
        // 使用者尚未選擇批次作業功能方法時，顯示警告訊息
        if (batchSelected === null) showToast({message: '請先選擇批次作業功能', type: ToastTypeEnum.Warning})
        // 更新選取狀態
        setIsSelected(e.target.checked)
        // 呼叫父層選取或取消選取事件
        if (e.target.checked) {
            handleOnSelected(item)
        } else {
            handleOnUnSelected(item)
        }
    }

    /**
     * 編輯按鈕觸發事件
     * @return {void}
     */
    const handleOnEditClick = () => {
        // 判斷是否為急件申請案件
        if(data.applyType === 'E') {
            // 狀態別為確認時，顯示送衛福部彈窗
            if(data.stepStatus === '2') {
                setShowSendMOHWConfirm(true)
                setListItemBtnType('EDIT')
            }else{
                setShowCaseConfirmation(true)
            }
        }else{
            setShowCaseConfirmation(true)
        }
    }

    /**
     * 刪除按鈕觸發事件
     * @return {void}
     */
    const handleOnDeleteClick = () => {
        // 判斷是否為急件申請案件
        if(data.applyType === 'E') {
            setShowSendMOHWConfirm(true)
            setListItemBtnType('DELETE')
        }else{
            setShowDeletePopup(true)
        }
    }

    /**
     * 快速編輯存檔
     * @return {void}
     */
    const handleSave = () => {
        // 複製一個data物件，更新快速編輯後的資料
        const saveData = {
            ...data,
            stepStatus: stepStatus,
            sendCaseNo: sendCaseNo,
            sendFileName: sendFileName
        }
        // 呼叫API，儲存快速編輯後的資料
        rsisQuickEdit([saveData]).then(response => {
            const {err, msg} = response;
            if (err === ApiErrorStatusEnum.Success) {
                // 儲存成功，更新本地資料
                setData({
                    ...data,
                    stepStatus: stepStatus,
                    sendCaseNo: sendCaseNo,
                    sendFileName: sendFileName
                })
                handleOnUpdate()
                setIsFastEdit(false)
                // 顯示成功訊息
                showToast({message: `快速編輯儲存成功，${msg}`, type: ToastTypeEnum.Success});
            } else {
                // 顯示錯誤訊息
                showToast({message: `快速編輯儲存失敗,${msg}`, type: ToastTypeEnum.Error});
            }
        })
    }

    /**
     * 快速編輯取消
     * @return {void}
     */
    const handleCancel = () => {
        // 還原資料
        setStepStatus(data.stepStatus)
        setSendCaseNo(data.sendCaseNo)
        setSendFileName(data.sendFileName)
        // 退出快速編輯狀態
        setIsFastEdit(false)
    }

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        const style = 'flex flex-row gap-2'
        // 是否在快速編輯狀態
        if (isFastEdit) {
            return <span className={style}>
                <SaveIconSlotBtn onClick={handleSave}/>
                <CancelSlotBtn onClick={handleCancel}/>
            </span>
        }
        switch (data.stepStatus) {
            case RsisStepStatusEnum.Apply.toString():
                return (
                    <span className={style}>
                        <CopySlotBtn onClick={() => setShowCopyModal(true)}/>
                        <EditSlotBtn onClick={handleOnEditClick}/>
                        <DelSlotBtn onClick={handleOnDeleteClick}/>
                        <FastEditSlotBtn onClick={() => setIsFastEdit(true)}/>
                    </span>
                );
            case  RsisStepStatusEnum.Confirm.toString():
                return (
                    <span className={style}>
                        <CopySlotBtn onClick={() => setShowCopyModal(true)}/>
                        <EditSlotBtn onClick={handleOnEditClick}/>
                        <DelSlotBtn onClick={handleOnDeleteClick}/>
                        <FastEditSlotBtn onClick={() => setIsFastEdit(true)}/>
                    </span>
                )
            case RsisStepStatusEnum.Review.toString():
                return (
                    <span className={style}>
                        <ChangeSlotBtn onClick={() => setShowChangeModal(true)}/>
                        <LofoffSlotBtn onClick={() => setShowLogOffModal(true)}/>
                        <FastEditSlotBtn onClick={() => setIsFastEdit(true)}/>
                    </span>
                )
            case RsisStepStatusEnum.Invalid.toString():
                return (
                    <span className={style}>
                        <FastEditSlotBtn onClick={() => setIsFastEdit(true)}/>
                    </span>
                )
            default:
                return null;
        }
    }

    // 全選
    useEffect(() => {
        // 批次作業-收案確認，選取所有狀態為申請的案件
        if (batchSelected === '1' && item.stepStatus === '1') setIsSelected(isSelectAll)
        // 批次作業-衛福部送審轉出或退回申請，選取所有狀態為確認且沒有變更或註銷的案件
        if (batchSelected === '2' && item.stepStatus === '2' && stringIsEmpty(item.supportModifyStatus)) {setIsSelected(isSelectAll)}
        if (batchSelected === '3' && item.stepStatus === '2') setIsSelected(isSelectAll)
    }, [isSelectAll]);

    // 批次作業選項改變時，取消選取
    useEffect(() => {
        setIsSelected(false)
        // 收案確認勾選時，不得選取非申請狀態案件
        if (batchSelected === '1') setIsDisabled(data.stepStatus !== '1')
        else if (batchSelected === '2') { // 衛福部送審轉出時，不得選取非確認狀態案件或有變更或註銷的案件
            setIsDisabled(data.stepStatus !== '2' || data.supportModifyStatus === 'M' || data.supportModifyStatus === 'D')
        // 退回申請時，不得選取非確認狀態案件
        }else if (batchSelected === '3') setIsDisabled(data.stepStatus !== '2')
        // 未選擇批次作業功能時，不得選取
        if (batchSelected === null) setIsDisabled(true)
    }, [batchSelected])

    // 當資料變更時，更新本地資料
    useEffect(() => {
        setData(item)
    }, [item])

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 選擇框 */}
            <div>
                <input
                    className="w-5 h-5"
                    type="checkbox"
                    checked={isSelected}
                    onChange={handleOnSelectedChange}
                    disabled={isDisabled}
                />
            </div>
            {/* 根據使用者權限顯示對應按鈕 */}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {renderSlotBtn()}
                </span>
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data.supportNo}</p>
                <p className='w-full'>{t(`campus.shortName.${data.zone}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data.createUser}</p>
                <p className='w-full'>{data.createUserName}</p>
            </div>
            {/* 支援人員 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data.supportUserNo}</p>
                <p className='w-full'>{data.supportUserName}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data.supportOrgNo}</p>
                <p className='w-full'>{data.supportOrgName}</p>
            </div>
            {/* 支援科別 */}
            <div className='flex flex-col'>
                <p className='w-full'>{!stringIsEmpty(data.supportDivNo) ? data.supportDivNo : ''}</p>
                <p className='w-full'>{!stringIsEmpty(data.supportDivName) ? data.supportDivName : ''}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col'>
                <p className='w-full'>{`${time2String(data.itemStartDate, 'YYYY-MM-DD')} ${data.itemStartTime.slice(0, 2) + ':' + data.itemStartTime.slice(2)}`}</p>
                <p className='w-full'>{`${time2String(data.itemEndDate, 'YYYY-MM-DD')} ${data.itemEndTime.slice(0, 2) + ':' + data.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {isFastEdit
                    ? <select
                        className="w-[102px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        onChange={(e) => setStepStatus(e.target.value)}
                        value={stepStatus}
                        disabled={!isFastEdit}
                    >
                        {
                            enum2Array(RsisStepStatusEnum)
                                .filter(item => item.value !== RsisStepStatusEnum.All)
                                .map((item, index) =>
                                    <option key={index} value={item.value}>
                                        {t(`RSIS.StepStatusEnum.${item.value}`)}
                                    </option>
                                )
                        }
                    </select>
                    : t(`RSIS.StepStatusEnum.${data.stepStatus}`)
                }
            </div>
            {/* 變更或者註銷狀態 */}
            <div>{data.supportModifyStatus === 'M' || data.supportModifyStatus === 'D' ? t(`RSIS.SupportModifyStatusEnum.${data.supportModifyStatus}`) : ''}</div>
            {/* 衛福部案號 */}
            <div>
                <Input type={InputTypeEnum.Text}
                       width='w-[160px]'
                       value={!stringIsEmpty(sendCaseNo) ? sendCaseNo : ''}
                       onChange={(e) => setSendCaseNo(e.target.value)}
                       disabled={!isFastEdit}
                />
            </div>
            {/* 匯出編號 */}
            <div>
                <Input type={InputTypeEnum.Text}
                       width='w-[160px]'
                       value={!stringIsEmpty(sendFileName) ? sendFileName : ''}
                       onChange={(e) => setSendFileName(e.target.value)}
                       disabled={!isFastEdit}
                />
            </div>
            {/* 備註 */}
            <div>{data.memo}</div>
        </li>
    );
}

export default ListItem;
