import React from 'react'
import LogOffDeleteBatches from '../../components/RSIS/LogOffDeleteBatches/LogOffDeleteBatches'
import {PopupProvider} from '../../components/RSIS/PopupProvider'

/**
 * 整批變更註銷或刪除
 * @returns {JSX.Element}
 */
const LogOffDeleteBatchesLayout = () => (
    <PopupProvider>
        <LogOffDeleteBatches/>
    </PopupProvider>
)

export default LogOffDeleteBatchesLayout