//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import icons
import {ReactComponent as DetailIcon} from '../../assets/images/ESSI/detail.svg'
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import function
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ChartTypeEnum,
    ColorTypeEnum,
    createDateRange2String,
    fuzzySearchObjects,
    generateRandomColor,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    UserRoleEnum
} from "edah_utils/dist"
import DetailSignPopup from "../../components/ESSI/DetailSignPopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import React, {useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiSignatureRate} from "../../api/v1/ESSI"
import {SignRateChart} from "../../components/ESSI/SignRate/SignRateChart"
import {BaseInput} from "../../components/Input/BaseInput"

/**
 * 簽章率明細與查詢
 * @return {JSX.Element}
 */
const SignRate = () => {
    //Table element 的預設type
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    // 圖表類型
    const [chartType, setChartType] = useState(ChartTypeEnum.BarVertical)

    // 建立時間
    const [startDate, setStartDate] = useState(null)

    // 終止時間
    const [endDate, setEndDate] = useState(null)

    // 初始資料 table
    const [data, setData] = useState([])

    // 初始資料 人員清單
    const [users, setUsers] = useState([])

    //下拉選單 人員
    const [user, setUser] = useState("")

    //明細 arr data 已簽/未簽
    const [detailSignData, setDetailSignData] = useState([])

    //明細 arr data 未簽
    const [detailUnsignData, setDetailUnsignData] = useState([])

    //是否顯示明細popup彈窗
    const [showDetailSignPopup, setShowDetailSignPopup] = useState(false)

    // 是否顯示人員代號選項
    const [showUserDropdown, setShowUserDropdown] = useState(false)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    // role角色>用於判斷是否可以查看他人資料
    const [isSysMaintain, setIsSysMaintain] = useState(false)

    //當前使用者代號
    const [currentUserNo, setCurrentUserNo] = useState()

    /**
     * 圖表切換
     * @param chartType {number} 圖表模式
     * @return {void}
     */
    const handleChartButtonOnClick = (chartType) => setChartType(chartType)

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param e {Event} 事件
     */
    const handleSelectUserOnChange = (e) => setUser(e.target.value)

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserSearchTextOnFocus = () => setShowUserDropdown(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setUser(`${option.userNo} ${option.userName}`)
        }

        // 關閉建立人員下拉選單
        setShowUserDropdown(false)
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 時間起始事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間終止事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 點選明細popup 事件
     * 依照日期為param API 分已簽未簽傳入明細popup
     * @return {void}
     */
    const handleCloseCancelDetailSignPopup = () => setShowDetailSignPopup(false)

    /**
     * 取得過濾後的人員代號選項
     * @return {Array<Object>}
     */
    const getFilterUser = () =>
        // 醫生是否有空格
        user.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(users, user.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(users, user)

    /**
     * 取得過濾後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 取得圖表按鈕樣式
     * @param type {number} 圖表類型
     * @return {string}
     */
    const getChartButtonClassName = (type) => chartType === type ? `py-2 border-b-2 border-[#2B6CB0] text-[#2B6CB0]` : `py-2 border-b-2`

    /**
     *  點擊明細按鈕事件
     *  @param data {Object} 資料
     *  @return {void}
     */
    const handleDetailButtonOnClick = (data) => {
        console.log(data.date)
        // 查詢該日簽章率明細的未簽章及已簽章API
        // res.data.signList
        const detailSign = []
        // res.data.unSignList
        const detailUnsign = []

        setDetailSignData(detailSign)
        setDetailUnsignData(detailUnsign)
        setShowDetailSignPopup(true)
    }

    /**
     * 點擊查詢按鈕事件
     * @return {void}
     */
    const handleSearchButtonOnClick = () => getSignatureRate()

    /**
     * 取得圖表選項
     * @return {Object}
     */
    const getChartOptions = () => ({
        /**
         * Chart Type
         */
        chartType: chartType,
        /**
         * Chart Data
         */
        data: getChartData(),
        /**
         * Secondary Chart Data
         */
        secData: getChartSecData(),
        /**
         * Chart Labels
         */
        labels: getLabels(),
        /**
         * Chart Title
         */
        width: '550px',
        /**
         * Chart Height
         */
        height: '550px',
        /**
         * Color
         */
        color: getChartColors(),
        /**
         * Secondary Color
         */
        secColor: getChartSecColors(),
    })

    /**
     * 取得圖表標籤
     * @return {Array<String>}
     */
    const getLabels = () => data.map(item => time2String(item.date, 'yyyy-MM-DD'))

    /**
     * 取得圖表資料
     * @return {Array<Number>}
     */
    const getChartData = () => data.map(item => item.sign)

    /**
     * 取得圖表顏色
     * @return {Array<String>}
     */
    const getChartColors = () => data.map(_ => generateRandomColor(ColorTypeEnum.Hex))

    /**
     * 取得次要資料
     * @return {Array<Number>|null}
     */
    const getChartSecData = () => chartType === ChartTypeEnum.Pie ? null : data.map((item) => item.unsign)

    /**
     * 取得次要顏色
     * @return {Array<String>|null}
     */
    const getChartSecColors = () => chartType === ChartTypeEnum.Pie ? null : data.map(_ => generateRandomColor(ColorTypeEnum.Hex))

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定人員代號選項
                setUsers(data)
            }
        })
    }

    /**
     * 取得查詢簽章率明細
     * @return {void}
     */
    const getSignatureRate = () => {
        // 正規表示式是否包含一個空白
        const regexWithSpace = /^[^\s]*\s[^\s]*$/
        // 取得Doctor是否有單個空格
        const hasSpace = regexWithSpace.test(user)
        // 取得醫生代號
        const doctorNo = isSysMaintain ?
            // 系統維護人員才有辦法取得所有醫生名單
            (hasSpace ? user.split(' ')[0] : user) :
            // 當前使用者代號
            currentUserNo

        essiSignatureRate({
            // 起始時間
            createStartDate: `${startDate} 00:00:00`,
            // 結束時間
            createEndDate: `${endDate} 23:59:59`,
            // 操作人員
            createUserNo: doctorNo,
        }).then(res => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 取得資料
                const result = res.data
                // 設定資料
                setData(result)
            }
        })
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        // 取得開始與結束時間
        const [startDate, endDate] = createDateRange2String("","",7)
        // 設定開始時間
        setStartDate(startDate)
        // 設定結束時間
        setEndDate(endDate)
        // 取得當前使用者
        const currentUserNo = localStorage.getItem('userno')
        // 設定當前使用者
        setCurrentUserNo(currentUserNo)
        //取得Role資訊
        const roles = JSON.parse(getLocalStorage('role'))
        // 判斷是系統維護人員
        const hasSysMaintain = roles.some(role => role.roleNo === UserRoleEnum.SysMaintain)
        // 設定使用者角色
        setIsSysMaintain(hasSysMaintain)
        // 系統維護人員才有辦法取得所有醫生名單
        if (isSysMaintain) {
            // 取得所有醫生
            getQueryAllDoctor()
        }
        // 查詢所有醫生
        getQueryAllDoctor()
    }, [])

    /**
     * 當開始時間、結束時間、人員代號改變時
     * @return {void}
     */
    useMemo(() => {
        // 開始時間、結束時間皆不為空時
        if (!stringIsEmpty(startDate) || !stringIsEmpty(endDate)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                // 取得簽章率明細
                getSignatureRate()
                // 設定已經取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [startDate, endDate])

    /**
     * 避免點擊建立人員選項時，因CSS點不到問題
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserDropdown(false),
    })

    return (
        <>
            <div className="w-full py-4 px-2 bg-[#FAFAFA]">
                <div className="filterGroup flex flex-row flex-wrap items-center justify-between">
                    <div className="flex flex-row flex-wrap items-center">
                        <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                            <p className="mr-4 w-[100px]">操作人員</p>
                            <div className="flex flex-row space-x-2 items-center relative w-full"
                                 ref={dropdownUserRef}>
                                <input
                                    className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 pl-9"
                                    value={user}
                                    disabled={!isSysMaintain}
                                    onChange={(e) => handleSelectUserOnChange(e)}
                                    onFocus={handleUserSearchTextOnFocus}/>
                                <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                     src={SearchIcon} role="button"/>
                                {
                                    // 建立人員選項存在且為系統維護人員
                                    showUserDropdown && isSysMaintain && (
                                        <div
                                            className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                {
                                                    getFilterUser().map((user, index) =>
                                                        <div
                                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                            role="button" key={index}
                                                            onClick={() => handleUserOptionOnClick(user)}>
                                                            <p>{`${user.userNo} ${user.userName}`}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="date flex flex-row items-center justify-start mb-2 mr-2">
                            <p className="mr-4">開始日期起訖</p>
                            {/*開始時間*/}
                            <BaseInput inputMode="date" type="date" className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   value={startDate} onChange={handleStartDateOnChange}/>
                            <p className="m-1">~</p>
                            {/*終止時間*/}
                            <BaseInput inputMode="date" type="date" className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   value={endDate}  onChange={handleEndDateOnChange}/>
                        </div>
                        {/*查詢按鈕*/}
                        <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                onClick={handleSearchButtonOnClick}>
                                {t('general.query')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row '>
                    <div
                        className="py-2 px-2 min-w-[40%] max-w-[50%] w-[40%] bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                        <div className='w-full h-[75vh]'>
                            <div className="flex flex-row items-center justify-between mb-2">
                                {/*進階搜尋*/}
                                <div>
                                    <BaseInput inputMode="search" className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                           type="text" placeholder={t('general.advancedSearch')}
                                           value={advancedSearchText}
                                           onChange={handleAdvancedSearchTextOnChange}/>
                                </div>
                            </div>
                            <div className='overflow-x-auto overflow-y-auto min-h-[100px] max-h-[45vh] xl:max-h-[70vh]'>
                                <table
                                    className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                                    <thead>
                                    <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                                        <th className={`min-w-[110px] w-[110px] ${tableElementDefaultType}`}>明細</th>
                                        <th className={`min-w-[130px] w-[130px] ${tableElementDefaultType}`}>日期</th>
                                        <th className={`${tableElementDefaultType}`}>已簽</th>
                                        <th className={`${tableElementDefaultType}`}>未簽</th>
                                        <th className={`${tableElementDefaultType}`}>合計</th>
                                        <th className={`min-w-[85px] w-[85px] ${tableElementDefaultType}`}>簽章%</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        !arrayIsEmpty(data) && getFilterData().map((data, index) =>
                                            <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                                key={index}>
                                                <td className={`${tableElementDefaultType}`}>
                                                    {/*明細按鈕*/}
                                                    <div className='flex flex-row items-center justify-start'>
                                                        <button onClick={() => handleDetailButtonOnClick(data)}
                                                                className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                            <DetailIcon className="mr-1"/>明細
                                                        </button>
                                                    </div>
                                                </td>
                                                {/*日期*/}
                                                <td className={`${tableElementDefaultType}`}>
                                                    {time2String(data.date, 'yyyy-MM-DD')}
                                                </td>
                                                {/*已簽*/}
                                                <td className={`${tableElementDefaultType}`}>
                                                    {data.sign}
                                                </td>
                                                {/*未簽*/}
                                                <td className={`${tableElementDefaultType}`}>
                                                    {data.unsign}
                                                </td>
                                                {/*合計*/}
                                                <td className={`${tableElementDefaultType}`}>
                                                    {data.sum}
                                                </td>
                                                {/*合計*/}
                                                <td className={`${tableElementDefaultType}`}>
                                                    {`${data.signrate}%`}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        className="ml-2 pt-2 px-2 min-w-[40%] max-w-[60%] w-[60%] bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                        <div className='w-full'>
                            <div
                                className=' overflow-x-auto overflow-y-auto '>
                                <div className='grid grid-cols-2'>
                                    {/*每日已/未簽*/}
                                    <button
                                        className={getChartButtonClassName(ChartTypeEnum.BarVertical)}
                                        onClick={() => handleChartButtonOnClick(ChartTypeEnum.BarVertical)}>
                                        每日已/未簽
                                    </button>
                                    {/*總簽章率*/}
                                    <button
                                        className={getChartButtonClassName(ChartTypeEnum.Pie)}
                                        onClick={() => handleChartButtonOnClick(ChartTypeEnum.Pie)}>
                                        總簽章率
                                    </button>
                                </div>
                                {/*查詢簽章率明細圖表*/}
                                <SignRateChart chartOptions={getChartOptions()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*DetailSignPopup*/}
            <DetailSignPopup show={showDetailSignPopup} detailSignData={detailSignData}
                             detailUnsignData={detailUnsignData}
                             closePopupButtonOnClick={handleCloseCancelDetailSignPopup}/>
        </>
    )
}
export default SignRate
