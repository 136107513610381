import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { validDateFormat } from 'edah_utils/dist'
import { BaseInput } from '../../Input/BaseInput'
import {t} from 'i18next'
/**
 * 電子轉診單搜尋列
 * @param {function} handleQuery - 查詢按鈕的callback
 * @return {JSX.Element}
 */
const ReferralFormSearchBar = ({
    handleQuery,
    searchParamsOnChange,
    slotElement,
}) => {
    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        // 有效日期(起日)
        effStartDate: '',
        // 有效日期(迄日)
        effEndDate: '',
        // 身分證號
        idNo: '',
    })

    /**
     * 有效日期(起日)輸入框檢核狀態
     */
    const [effStartDateInputState, setEffStartDateInputState] = useState(null)

    /**
     * 有效日期(迄日)輸入框檢核狀態
     */
    const [effEndDateInputState, setEffEndDateInputState] = useState(null)

    /**
     * 更新查詢條件
     * @param {event} event
     * @param {string} fields
     */
    const updateSearchParams = (event, field) => {
        const params = {
            ...searchParams,
            [field]: event.target.value,
        }
        setSearchParams(params)
    }

    const handleQuerOnClick = () => {
        const effStartDateIsValid = validDateFormat(searchParams.effStartDate)
        const effEndDateIsValid = validDateFormat(searchParams.effEndDate)
        setEffStartDateInputState(effStartDateIsValid)
        setEffEndDateInputState(effEndDateIsValid)

        if (!effStartDateIsValid || !effEndDateIsValid) {
            return
        }
        handleQuery()
    }

    useEffect(() => {
        searchParamsOnChange(searchParams)
    }, [searchParams.effStartDate, searchParams.effEndDate, searchParams.idNo])

    return (
        <div className="flex">
            <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                <div className="mr-4">
                    有效日期<span className="text-danger ">*</span>:
                </div>
                <BaseInput
                    className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                    value={searchParams.effStartDate}
                    onChange={(e) => updateSearchParams(e, 'effStartDate')}
                    type="date"
                    inputState={effStartDateInputState}
                    errorMsg="必填"
                />
                <span className="m-1">~</span>
                <BaseInput
                    className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                    value={searchParams.effEndDate}
                    onChange={(e) => updateSearchParams(e, 'effEndDate')}
                    type="date"
                    inputState={effEndDateInputState}
                    errorMsg="必填"
                />
            </div>
            <div className="flex flex-row items-center justify-start mb-4 mr-4">
                <div className="mr-4">{t('general.idNumber')}：</div>
                <input
                    className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                    type="text"
                    value={searchParams.idNo}
                    onChange={(e) => updateSearchParams(e, 'idNo')}
                />
            </div>
            {slotElement}
            <button
                onClick={handleQuerOnClick}
                className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
            >
                {t('general.query')}
            </button>
        </div>
    )
}

ReferralFormSearchBar.propTypes = {
    handleQuery: PropTypes.func,
}
export default ReferralFormSearchBar
