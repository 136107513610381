import React, { useMemo, useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import ReferralFormSearchBar from '../../components/ReferralSystem/ReferralForm/ReferralFormSearchBar'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import ReferralFormMappingModal from '../../components/ReferralSystem/ReferralForm/ReferralFormMappingModal'
import { REFERRAL_FORM_UPLOAD_FIELDS } from '../../constants/referralSystem/referralForm'
import RadioButton from '../../components/Input/RadioButton'
import { BasePopup } from '../../components/Popup/BasePopup'
import AttachmentModal from '../../components/ReferralSystem/ReferralForm/AttachmentModal'
import {
    time2String,
    ToastTypeEnum,
    ApiErrorStatusEnum,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    BlobTypeEnum,
} from 'edah_utils/dist'
import { Pagination } from '../../components/Pagination/Pagination'
import useToast from '../../hooks/useToast'
import { DATETIME_FORMAT } from '../../constants/common'
import { queryTranCaseToTranApiBySearch, tranApiToXml } from '../../api/v1/Rmis'
import { t } from 'i18next'
import Button from '../../components/Button/Button'

/**
 * 電子轉診單_上傳 轉診類別
 */
export const TRAN_TYPE = [
    {
        name: '開立電子轉診單',
        value: '1',
    },
    {
        name: '回覆電子轉診單',
        value: '2',
    },
    {
        name: '轉回或轉至適當院所',
        value: '3',
    },
    {
        name: '刪除電子轉診單',
        value: '8',
    },
    {
        name: '刪除回復電子轉診單',
        value: '7',
    },
    {
        name: '無需轉回註記作業',
        value: '6',
    },
]
// 上傳選項
const SEND_FLAG_LIST = [
    {
        value: '',
        name: '全部',
    },
    {
        value: '0',
        name: '未上傳',
    },
    {
        value: '1',
        name: '已上傳',
    },
]
// 上傳結果選項
const UPLOAD_FLAG_LIST = [
    {
        value: '',
        name: '全部',
    },
    {
        value: '0',
        name: '錯誤',
    },
    {
        value: '1',
        name: '正確',
    },
]

/**
 * 電子轉診單_上傳
 * @returns {JSX.Element}
 */
const ReferralFormUpload = () => {
    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    const showToast = useToast()

    /**
     * 選取的附件
     */
    const [activeAttachment, setActiveAttachment] = useState({})
    /**
     * 電子轉診單mapping視窗
     */
    const [isShowReferralMappingModal, setIsShowReferralMappingModal] =
        useState(false)

    /**
     * 附件視窗
     */
    const [isShowAttachmentModal, setIsShowAttachmentModal] = useState(false)

    /**
     * 電子轉診單清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * 查詢欄位
     */
    const [searchParams, setSearchParams] = useState({
        // 有效日期(起日)
        tranStartDate: '',
        // 有效日期(迄日)
        tranEndDate: '',
        // 身分證號
        idNo: '',
        // 轉診類別
        rmisTranType: TRAN_TYPE[0].value,
        // 上傳選項
        sendFlag: SEND_FLAG_LIST[1].value,
        // 上傳結果
        uploadFlag: '',
    })

    /**
     * 匯出
     * @returns {void}
     */
    const exportText = () => {
        const selectedDataList = dataList.filter((item) => item.isSelected)
        if (selectedDataList.length === 0) {
            return
        }
        const dataArray = []
        selectedDataList.forEach((item) => {
            const data = []
            REFERRAL_FORM_UPLOAD_FIELDS.forEach((field) => {
                switch (field.key) {
                    case 'orderDate':
                    case 'rmisTranApiDate':
                        data.push(field.format(item[field.key])) // 時間格式
                        break
                    default:
                        data.push(item[field.key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: REFERRAL_FORM_UPLOAD_FIELDS.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '電子轉診單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 插入CustomTable的按鈕
     * @returns {JSX.Element} 匯出Text按鈕
     */
    const ExportTextButton = () => {
        return (
            <button
                className="flex items-center justify-center px-4 h-10 rounded-[6px] text-[#38A169]"
                onClick={exportText}
            >
                <ExportText className="mr-1" />
                {t('general.export')}
            </button>
        )
    }

    /**
     * 關閉 帶入電子轉診單MAPPING modal
     */
    const closeReferralMappingModal = () => {
        setIsShowReferralMappingModal(false)
    }

    /**
     * 關閉上傳附件視窗
     */
    const closeAttachmentModal = () => {
        setActiveAttachment({})
        setIsShowAttachmentModal(false)
    }

    /**
     * 開啟上傳附件視窗
     */
    const showAttachmentModal = (referralCase) => {
        setActiveAttachment(referralCase)
        setIsShowAttachmentModal(true)
    }

    /**
     * 附件上傳成功後重新取得電子轉診清單
     */
    const handleUploadAttachmentSuccess = () => {
        closeAttachmentModal()
        getETranList(filter.pageNum, filter.pageSize)
    }

    /**
     * @param {object} referralCase 要上傳附件的轉診資料
     * @returns {JSX.Element} 附件按鈕
     */
    const Attachment = (referralCase) =>
        // "轉診類別" 選為 "回覆電子轉入單"時才顯示附件按鈕
        referralCase.rmisTranType === TRAN_TYPE[1].value && (
            <button
                className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                onClick={() => showAttachmentModal(referralCase)}
                key={referralCase.idNo}
            >
                附件
            </button>
        )

    /**
     * 傳入CustomTable的欄位加上附件按鈕
     */
    const generateFields = useMemo(() => {
        return [
            ...REFERRAL_FORM_UPLOAD_FIELDS,
            {
                key: 'action',
                name: '',
                render: Attachment,
                className: 'w-[100px]',
            },
        ]
    })

    /**
     * 更新轉診類別
     * @param {string} value
     */
    const updateTranType = (value) => {
        setSearchParams({
            ...searchParams,
            rmisTranType: value,
        })
    }

    /**
     * 更新上傳選項
     * @param {event}} e
     */
    const sendFlagOnChange = (e) => {
        const value = e.target.value
        setSearchParams((prev) => ({
            ...prev,
            sendFlag: value,
        }))
        if (value !== SEND_FLAG_LIST[2].value) {
            setSearchParams((prev) => ({
                ...prev,
                uploadFlag: '',
            }))
        }
    }

    /**
     * 更新上傳結果
     * @param {event}} e
     */
    const uploadFlagOnChange = (e) => {
        setSearchParams({
            ...searchParams,
            uploadFlag: e.target.value,
        })
    }

    /**
     * 更新ReferralFormSearchBar裡面的查詢條件
     * @param {object} searchParams
     */
    const searchParamsOnChange = (params) => {
        setSearchParams({
            ...searchParams,
            tranStartDate: time2String(params.effStartDate, DATETIME_FORMAT),
            tranEndDate: time2String(params.effEndDate, DATETIME_FORMAT),
            idNo: params.idNo,
        })
    }

    /**
     * 點選查詢
     */
    const handleQueryETranList = () => {
        getETranList(1, filter.pageSize)
    }

    /**
     * 取得電子轉診單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const getETranList = (pageNum, pageSize) => {
        const params = {
            ...searchParams,
            tranStartDate: time2String(
                searchParams.tranStartDate,
                DATETIME_FORMAT
            ),
            tranEndDate: time2String(searchParams.tranEndDate, DATETIME_FORMAT),
            sendFlag: searchParams.sendFlag,
            uploadFlag: searchParams.uploadFlag,
            pageNum,
            pageSize,
        }
        queryTranCaseToTranApiBySearch(params).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.map((item) => ({
                        ...item,
                        isSelected: false,
                        rmisTranType: searchParams.rmisTranType,
                    })) || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 換頁
     * @param {number} page
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getETranList(currentPage, filter.pageSize)
    }

    /**
     * 更新每頁筆數
     * @param {event} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getETranList(filter.pageNum, pageSize)
    }

    /**
     * 下一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 上一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 上傳附件
     * @returns {Element}
     * @constructor
     */
    const UploadDropdowns = () => {
        return (
            <div className="flex flex-row items-center justify-start mb-4 mr-4">
                <div className="mr-4">上傳選項:</div>
                <select
                    className="w-[80px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] mr-2"
                    value={searchParams.sendFlag}
                    onChange={(e) => sendFlagOnChange(e)}
                >
                    {SEND_FLAG_LIST.map((item) => (
                        <option
                            value={item.value}
                            key={`${item.name}_${item.value}`}
                        >
                            {item.name}
                        </option>
                    ))}
                </select>
                {searchParams.sendFlag === SEND_FLAG_LIST[2].value && (
                    <div className="flex flex-row items-center justify-start">
                        <div className="mr-4">上傳結果:</div>
                        <select
                            className="w-[80px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] mr-2"
                            value={searchParams.uploadFlag}
                            onChange={(e) => uploadFlagOnChange(e)}
                        >
                            {UPLOAD_FLAG_LIST.map((item) => (
                                <option
                                    value={item.value}
                                    key={`${item.name}_${item.value}`}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        )
    }

    /**
     * 上傳按鈕: 點選後下載XML檔案
     */
    const handleUploadOnClick = () => {
        const encounterIdList = dataList
            .filter((item) => item.isSelected)
            .map((item) => ({ encounterId: item.encounterId }))

        tranApiToXml({
            rmisTranType: searchParams.rmisTranType,
            encounterIdData: encounterIdList,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const blob = new Blob([res.data.data], {
                    type: BlobTypeEnum.XML,
                })
                downloadFile(blob, res.data.fileName, FileExtensionsEnum.XML)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between">
                <div>
                    <ReferralFormSearchBar
                        handleQuery={handleQueryETranList}
                        searchParamsOnChange={searchParamsOnChange}
                        slotElement={<UploadDropdowns />}
                    />
                    <div className="flex w-full mb-3">
                        <div className="mr-4">轉診類別:</div>
                        <div className="ml-3 grid grid-cols-3 gap-3">
                            {TRAN_TYPE.map((item) => (
                                <RadioButton
                                    mode={searchParams.rmisTranType}
                                    modeChecked={item.value}
                                    handleModeChange={updateTranType}
                                    label={item.name}
                                    key={item.name}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        text={'上傳'}
                        onClickFn={handleUploadOnClick}
                        classNames="bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
                    />
                </div>
            </div>

            <CustomTable
                fields={generateFields}
                slotButton={<ExportTextButton />}
                dataList={dataList}
                isSelectable={true}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {isShowReferralMappingModal && (
                <ReferralFormMappingModal
                    onConfirm={closeReferralMappingModal}
                    onClose={closeReferralMappingModal}
                />
            )}
            {isShowAttachmentModal && (
                <BasePopup
                    title="電子轉診單_附件上傳"
                    width="80%"
                    content={
                        <AttachmentModal
                            onClose={closeAttachmentModal}
                            onUploadSuccess={handleUploadAttachmentSuccess}
                            info={activeAttachment}
                        />
                    }
                    closePopupButtonOnClick={closeAttachmentModal}
                />
            )}
        </div>
    )
}

export default ReferralFormUpload
