// MenuItem.js
import React, { useState } from "react";

const CommonMenuItem = ({ item, onItemSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasNoChildren = !item.children || item.children.length === 0;
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    if (!item.children || item.children.length === 0) {
      onItemSelected(item.id, item.name);
    }
  };

  return (
    <div className="">
      <div
        className={hasNoChildren ?"cursor-pointer text-left text-[#71717A]":"cursor-pointer text-left text-[#515151]"}
        onClick={handleClick}
      >
        {item.name}
      </div>
      {item.children && (
        <div className="flex justify-start flex-row flex-wrap pb-2">
          {item.children.map((s) => (
            <div key={s.id} className="flex flex-row py-1 text-[#71717A] whitespace-nowrap">
              {s.name}
              &nbsp;/&nbsp;
              {s.children &&
                s.children.map((child) => (
                  <CommonMenuItem
                    key={child.id}
                    item={child}
                    onItemSelected={onItemSelected}
                  />
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommonMenuItem;
