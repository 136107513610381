import React, {useEffect, useState} from 'react'
import {SearchInput} from '../../Input/SearchInput'
import CustomTable from '../RSISCustomTable'
import {MOHWgovFeedbackTable} from '../Constant'
import ListItem from './ListItem'
import {t} from "i18next"
import {rsisQueryOwnerUser, rsisQuerySendLog} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum, getLocalStorage, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist"
import {usePopup} from "../PopupProvider"
import {USER_ROLE} from "../userRole";
import {BaseInput} from "../../Input/BaseInput";

/**
 * 衛福部案號回饋作業
 * @return {JSX.Element}
 */
const MOHWgovFeedback = ({}) => {
    // 使用者角色
    const [role, setRole] = useState(USER_ROLE.DEPT_USER)

    // 表格分頁參數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 取得彈窗
    const {showToast} = usePopup()

    // 表格資料
    const [dataList, setDataList] = useState([])

    // 表格列表組件
    const List = ListItem

    // 匯出編號
    const [sendFileName, setSendFileName] = useState('')

    // 備註
    const [memo, setMemo] = useState('')

    // 衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')

    // 是否為承辦人員
    const [isOwner, setIsOwner] = useState(false)

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQuerySendLog()

    /**
     * 取得查詢衛褔部案號回饋作業
     * @return {void}
     */
    const getQuerySendLog = () => {
        rsisQuerySendLog({
            sendFileName: sendFileName,
            memo: memo,
            sendCaseNo: sendCaseNo,
        }).then(res => {
            // 取得回傳資料
            const {err, msg, data} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(data)
            } else { //取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦人員清單並判斷登入者是否為承辦人員
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得登入者
                const loginUser = getLocalStorage('userno')
                // 判斷是否為承辦人員
                setIsOwner(data.some(item => item.userNo === loginUser))

            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時取得查詢衛褔部案號回饋作業
     */
    useEffect(() => {
        getOwnerUserList()
        getQuerySendLog()
    }, [])

    return (
        <div className="flex flex-col justify-start px-4 py-2 gap-4">
            <div className='flex flex-row items-center justify-start gap-6'>
                {/* 匯出編號 */}
                <div className="flex flex-row items-center justify-start">
                    <p>匯出編號：</p>
                    <BaseInput
                        className='bg-white w-[180px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-2'
                        type={InputTypeEnum.Text}
                        onChange={(e) => setSendFileName(e.target.value)}
                        value={sendFileName}/>
                </div>
                {/* 備註 */}
                <div className="flex flex-row items-center justify-start">
                    <p>備註：</p>
                    <BaseInput
                        className='bg-white w-[180px h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-2'
                        type={InputTypeEnum.Text}
                        onChange={(e) => setMemo(e.target.value)}
                        value={memo}/>
                </div>
                {/* 衛福部案號 */}
                <div className="flex flex-row items-center justify-start">
                    <p>衛福部案號：</p>
                    <BaseInput
                        className='bg-white w-[180px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-2'
                        type={InputTypeEnum.Text}
                        onChange={(e) => setSendCaseNo(e.target.value)}
                        value={sendCaseNo}/>
                </div>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleQueryButtonOnClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                advancedSearch={true}
                fields={MOHWgovFeedbackTable.fields}
                dataList={dataList}
                ListItem={ListItem}
                role={isOwner}
                hasSelectAll={false}
                type="RSISMOHWgovFeedbackTable"
                saveSlotBtn={false}
                exportSlotBtn={false}
                paginationProps={paginationProps}
                handleOnUpdateCallback={getQuerySendLog}
            />
        </div>
    )
}

export default MOHWgovFeedback
