import React, {useEffect, useState} from "react";
import '../../components/TableList/tableList.scss'
import searchIcon from "../../assets/images/search-interface-symbol.png"
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty, InputTypeEnum, arrayIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import { BaseInput } from "../../components/Input/BaseInput";
import CvisListItem from "../../components/CriticalValueManagement/CvisListItem";
import { getCurrentFormattedDateTime, fuseObject, inpOpdToText, cvisNotifyUnitAdapter } from "../../components/CriticalValueManagement/utils";
import ToastMessage from "../../components/Toasts/ToastMessage";
import UsePagination from "../../components/CriticalValueManagement/UsePagination";
import { Pagination } from "../../components/Pagination/Pagination";
import { 
    queryCvisNotifyrec,
    queryCvisResponse,
    queryCvisRenotify,
    cancelCvisNotifyrec,
    queryCvisNotifyobject,
    queryCvisNotifyrecById,
    queryCvisNotifyUnit
} from "../../api/v1/Critical";

import {t} from "i18next"

/**
 * 危急值通報紀錄 => 主頁面
 * @returns {JSX.Element}
 */

const CriticalValuesRecords = () => {

    // 危急值通報單位
    const [ unit, setUnit ] = useState([])
    // 預設查詢表單狀態
    const [cvisQueryData, setCvisQueryData] = useState({
        //通報單位
        cvisNotifyUnit: '01',
        //通報日期起始
        cvisNotifyStartDate: '',
        //通報日期結束
        cvisNotifyEndDate: '',
        //取消狀態
        cvisCancelStuts: '',
        //讀取狀態
        cvisReadStuts: '',
        //回覆狀態
        cvisResponseStatus: '',
        //通報類別
        cvisNotifyType: 'A' //危急值固定為A
    })
    // 顯示查詢結果
    const [showQueryResult, setShowQueryResult] = useState(false)
    // 危急值清單物件
    const [cvisNotifyObject, setcvisNotifyObject] = useState([])
    // 過濾後的危急值清單物件
    const [filteredItems, setFilteredItems] = useState([])
    // 通報清單編號
    const [cvisNotifyListId, setCvisNotifyListId] = useState("")
    // 進階搜尋字串
    const [queryString, setQueryString] = useState("")
    // 通報資料
    const [cvisNotifyData, setCvisNotifyData] = useState({})
    // 通報對象清單
    const [cvisNotifyObjectList, setCvisNotifyObjectList] = useState([])
    // 通報對象清單搜尋字串
    const [cvisNotifyObjectQueryString, setCvisNotifyObjectQueryString] = useState("")
    // 通報對象清單過濾結果
    const [filteredListItems, setFilteredListItems] = useState([])
    // 通報回覆紀錄
    const [cvisNotifyResponse, setCvisNotifyCvisResponse] = useState([])
    // 通報回覆紀錄搜尋字串
    const [cvisNotifyResponseQueryString, setCvisNotifyResponseQueryString] = useState("")
    // 通報回覆紀錄過濾結果
    const [filteredResponseItems, setFilteredResponseItems] = useState([])
    // 逾時回覆再通知紀錄
    const [cvisNotifyRenotify, setNotifyCvisRenotify] = useState([])
    // 逾時回覆再通知紀錄搜尋字串
    const [cvisNotifyRenotifyQueryString, setCvisNotifyRenotifyQueryString] = useState("")
    // 逾時回覆再通知紀錄過濾結果
    const [filteredRenotifyItems, setFilteredRenotifyItems] = useState([])
    // 切換基本資料 醫囑清單 檢核結果
    const [activeTab, setActiveTab] = useState('report');
    // 顯示吐司
    const [showToast, setShowToast] = useState(false)
    // 吐司訊息
    const [toastProps, setToastProps] = useState({
        message: '',
        type: ToastTypeEnum.Success,
    })
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })
    // 通報對象清單pagination
    const [notifyObjectPaginationProps, setNotifyObjectPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })
    // 回覆紀錄pagination
    const [responsePaginationProps, setResponsePaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })
    // 逾時回覆再通知pagination
    const [renotifyPaginationProps, setRenotifyPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })

    // 關閉吐司
    const handleToastOnClose = () => {
        setShowToast(false)
    }
    // 開啟吐司
    const handleToastOnShow = (message, type) => {
        setToastProps({
            ...toastProps,
            message: message,
            type: type
        })
        setShowToast(true)
    }

    /**
     * 通報紀錄查詢按鈕事件
     * @param {number} page 當前頁碼
     * @param {number} pageSize 每頁筆數
     * @returns {void}
     */
    const handleQueryCvisNotifyrec = (page, pageSize) => {
        console.log(page, pageSize)
        if (cvisQueryData.cvisNotifyEndDate && cvisQueryData.cvisNotifyStartDate > cvisQueryData.cvisNotifyEndDate) {
            handleToastOnShow('起始日期不可大於結束日期', ToastTypeEnum.Error)
        }else if(cvisQueryData.cvisNotifyEndDate==="" || cvisQueryData.cvisNotifyStartDate===""){
            handleToastOnShow('請輸入日期', ToastTypeEnum.Error)
        }else{
            queryCvisNotifyrec({
                "cvisNotifyUnit": cvisQueryData.cvisNotifyUnit,                               //Required 通報單位
                "cvisNotifyStartDate": `${cvisQueryData.cvisNotifyStartDate} 00:00:00`,       //Required 通報日期起始
                "cvisNotifyEndDate": `${cvisQueryData.cvisNotifyEndDate} 00:00:00`,           //Required 通報日期結束
                "cvisCancelStuts": cvisQueryData.cvisCancelStuts,                             //Option   取消狀態(空白：全部 1:未取消 2:已取消)
                "cvisReadStuts": cvisQueryData.cvisReadStuts,                                 //Option   讀取狀態(空白：全部 1:未讀 2:已讀)
                "cvisResponseStatus": cvisQueryData.cvisResponseStatus,                       //Option   回覆狀態(空白：全部 1:未回覆 2:已回覆)
                "cvisNotifyType": "A", //cvisQueryData.cvisNotifyType                         //Required 通報類別(A：危急值 B：感染管制)
                "pageNum": page,                                                              //Required 當前頁數
                "pageSize": pageSize,                                                         //Required 每頁顯示筆數
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    const data = res.data.dataList;
                    setcvisNotifyObject(data)
                    setFilteredItems(data)
                    setCvisNotifyListId("0")
                    setShowQueryResult(true)
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: res.data.totalItemSize,
                        totalPageSize: res.data.totalPageSize,
                        currentPageItemSize: res.data.currentPageItemSize,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }else{
                    console.log(res)
                }
            })
        }
    }

    /**
     * 危急值表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 危急值表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisNotifyrec(paginationProps.pageNum, newSize);
    }

    /**
     * 危急值表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 危急值表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onNotifyPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onNotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisNotifyrec(paginationProps.pageNum, newSize);
    }

    /**
     * 通報對象清單表格上一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格下一頁按鈕事件
     * @returns {void}
     * */
    const onNotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 回覆紀錄表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onResponsePaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }
    
    /**
     * 回覆紀錄表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onResponsePaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisNotifyrec(paginationProps.pageNum, newSize);
    }

    /**
     * 回覆紀錄表格上一頁按鈕事件
     * @returns {void}
     * */
    const onResponsePaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 回覆紀錄表格下一頁按鈕事件
     * @returns {void}
     * */
    const onResponsePaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }
    
    /**
     * 回覆紀錄表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
    const onRenotifyPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }
    
    /**
     * 回覆紀錄表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onRenotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisNotifyrec(paginationProps.pageNum, newSize);
    }

    /**
     * 回覆紀錄表格上一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 回覆紀錄表格下一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisNotifyrec(page, paginationProps.pageSize);
    }

    /**
     * 危急值查詢按鈕事件
     * @returns {void}
     */
    const handleOnQuery = () => {
        handleQueryCvisNotifyrec(paginationProps.pageNum, paginationProps.pageSize);
    }

    // 分頁切換事件
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const getTabClass = (tab) => {
        return `h-[42px] px-4 py-2 text-[16px] ${activeTab === tab ? 'border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]' : ''
        }`;
    };

    const [isEditable, setIsEditable] = useState(false);

    const getMaintainInfoClass = () => {
        return `maintainInfo CVRInfo ${isEditable ? '' : 'disabled'} h-[760px]`;
    }

    // 通報單位選項更新
    const handleCvisNotifyUnitOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: e.target.value
        })
    }

    // 通報日期起訖更新
    const handleCvisNotifyStartDateOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyStartDate: e.target.value
        })
    }

    // 通報日期結束更新
    const handleCvisNotifyEndDateOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyEndDate: e.target.value
        })
    }

    // 取消狀態選項更新
    const handleCvisCancelStutsOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisCancelStuts: e.target.value
        })
    }

    // 讀取狀態選項更新
    const handleCvisReadStutsOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisReadStuts: e.target.value
        })
    }

    // 回覆狀態選項更新
    const handleCvisResponseStatusOnChange = (e) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisResponseStatus: e.target.value
        })
    }

    // 取消通報按鈕事件
    const handleCancelCvisNotifyrec = () => {
        cancelCvisNotifyrec({
            cvisNotifyId: cvisNotifyObject[cvisNotifyListId].cvisNotifyId,
            cvisCancelUser: localStorage.getItem("username"),
            cvisCancelDatetime: getCurrentFormattedDateTime()
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                updateNotifyDataTable(cvisNotifyListId)
                handleToastOnShow('取消通報成功', ToastTypeEnum.Success)
                // console.log(res)
            }else{
                handleToastOnShow('取消通報失敗', ToastTypeEnum.Error)
                console.log(res)
            }
        })
    }

    /**
     * 控制Query輸入框內的值變動事件
     * @param {Object} event 
     */
    const handleQueryInputOnChange = (event) => {
        if(event.target.value === ' ')
            setQueryString('');
        else{
            setQueryString(event.target.value)
        }
    }

    /**
     * 通報對象清單搜尋框變動事件
     * @param {Object} event
     * @returns {void}
     */
    const handleCvisNotifyObjectQueryOnChange = (event) => {
        setCvisNotifyObjectQueryString(event.target.value)
    }

    /**
     * 通報回覆紀錄搜尋框變動事件
     * @param {Object} event
     * @returns {void}
     * */
    const handleCvisNotifyResponseQueryOnChange = (event) => {
        setCvisNotifyResponseQueryString(event.target.value)
    }

    /**
     * 逾時回覆再通知紀錄搜尋框變動事件
     * @param {Object} event
     * @returns {void}
     * */
    const handleCvisNotifyRenotifyQueryOnChange = (event) => {
        setCvisNotifyRenotifyQueryString(event.target.value)
    }

    /**
     * 根據流水號更新通報資料
     * @param {index} 流水號
     * @returns {void}
     */
    const updateNotifyDataTable = () => {
        // setCvisNotifyData(cvisNotifyObject[cvisNotifyListId])
        try{
            // 根據通報流水號取得通報資料
            queryCvisNotifyrecById({
                "cvisNotifyId": cvisNotifyObject[cvisNotifyListId].cvisNotifyId
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    // 更新通報資料
                    setCvisNotifyData(res.data)
                    console.log('updateNotifyDataTable', res)
                }else{
                    console.log(res)
                }
            })
        }catch(e){
            console.log(e)
        }

    }

    useEffect(()=>{
        console.log('cvisNotifyData', cvisNotifyData)
    },[cvisNotifyData])

    /**
     * 根據流水號更新通報對象清單
     * @param {index} 流水號 
     * @returns {void}
     */
    function updateNotifyListTable(page, pageSize) {
        try{
            // 根據通報流水號取得通報對象清單
            queryCvisNotifyobject({
                "cvisNotifyId": cvisNotifyObject[cvisNotifyListId].cvisNotifyId,
                "pageNum": page,
                "pageSize": pageSize
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    const data = res.data.dataList;
                    // 更新通報對象清單
                    setCvisNotifyObjectList(data)
                    setFilteredListItems(data)
                    setNotifyObjectPaginationProps({
                        ...notifyObjectPaginationProps,
                        totalItemSize: res.data.totalItemSize,
                        totalPageSize: res.data.totalPageSize,
                        currentPageItemSize: res.data.currentPageItemSize,
                        pageNum: page,
                        pageSize: pageSize
                    })
                }else{
                    if(res.err === 1){
                        setCvisNotifyObjectList([])
                        setFilteredListItems([])
                        setNotifyObjectPaginationProps({
                            ...notifyObjectPaginationProps,
                            totalItemSize: 0,
                            totalPageSize: 0,
                            currentPageItemSize: 0,
                            pageNum: page,
                            pageSize: pageSize
                        })
                    }
                    // console.log(res)
                }
            })
        }catch(e){
            console.log(e)
        }
    }

    /**
     * 根據流水號更新回覆紀錄
     * @param {index} 流水號
     * @returns {void}
     */
    function updateResponseRecordTable() {
        try{
            // 根據通報流水號取得回覆紀錄
            queryCvisResponse({
                "cvisNotifyId": cvisNotifyObject[cvisNotifyListId].cvisNotifyId,
                "pageNum": responsePaginationProps.pageNum,
                "pageSize": responsePaginationProps.pageSize
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    const data = res.data.dataList;
                    // 更新回覆紀錄
                    setCvisNotifyCvisResponse(data)
                    setFilteredResponseItems(data)
                    setResponsePaginationProps({
                        ...responsePaginationProps,
                        totalItemSize: res.data.totalItemSize,
                        totalPageSize: res.data.totalPageSize,
                        currentPageItemSize: res.data.currentPageItemSize
                    })
                }else{
                    if(res.err === 1){
                        setCvisNotifyCvisResponse([])
                        setFilteredResponseItems([])
                        setResponsePaginationProps({
                            ...responsePaginationProps,
                            totalItemSize: 0,
                            totalPageSize: 0,
                            currentPageItemSize: 0
                        })
                        // handleToastOnShow('查無回覆紀錄資料', ToastTypeEnum.Success)
                    }
                    // console.log(res)
                }
            })
        }catch(e){
            console.log(e)
        }
    }

    /**
     * 根據流水號更新逾時回覆再通知紀錄
     * @param {index} 流水號
     * @returns {void}
     */
     function updateCvisRenotifyRecordTable() {
        try{
            // 根據通報流水號取得逾時回覆再通知紀錄
            queryCvisRenotify({
                "cvisNotifyId": cvisNotifyObject[cvisNotifyListId].cvisNotifyId,
                "pageNum": renotifyPaginationProps.pageNum,
                "pageSize": renotifyPaginationProps.pageSize
            }).then(res => {
                if(res.err === ApiErrorStatusEnum.Success) {
                    const data = res.data.dataList;
                    // 更新逾時回覆再通知紀錄
                    setNotifyCvisRenotify(data)
                    setFilteredRenotifyItems(data)
                    setRenotifyPaginationProps({
                        ...renotifyPaginationProps,
                        totalItemSize: res.data.totalItemSize,
                        totalPageSize: res.data.totalPageSize,
                        currentPageItemSize: res.data.currentPageItemSize
                    })
                }else{
                    if(res.err === 1){
                        setNotifyCvisRenotify([])
                        setFilteredRenotifyItems([])
                        setRenotifyPaginationProps({
                            ...renotifyPaginationProps,
                            totalItemSize: 0,
                            totalPageSize: 0,
                            currentPageItemSize: 0
                        })
                        // alert("查無回覆再通知紀錄資料")
                        // handleToastOnShow('查無回覆再通知紀錄資料', ToastTypeEnum.Success)
                    }
                    // console.log(res)
                }
            })
        }catch(e){
            console.log(e)
        }
    }

    /**
     * 當通報紀錄被選取時觸發，更新索引值
     * @param {index} 被選取的通報紀錄索引 
     * @returns {void}
     */
    const handleCvisListItemOnFocus = (index) => {
        if(cvisNotifyListId != index){
            setCvisNotifyListId(index)
        }
    }

    /**
     * 當當前focus的list index更新時，更新通報資料、通報對象清單、回覆紀錄、逾時回覆再通知紀錄
     * @returns {void}
     * */
    useEffect(() => {
        if(cvisNotifyObject[cvisNotifyListId]!=undefined){
            // console.log(cvisNotifyObject[0], cvisNotifyListId)
            setCvisNotifyData(cvisNotifyObject[cvisNotifyListId])
            updateNotifyDataTable()
        }
        updateNotifyListTable(notifyObjectPaginationProps.pageNum, notifyObjectPaginationProps.pageSize)
        updateCvisRenotifyRecordTable()
        updateResponseRecordTable()
    }, [cvisNotifyListId])

    // 初始化fuse通報紀錄
    const notifyObjectFuse = fuseObject(cvisNotifyObject)

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(()=>{
        if(queryString) {
            const result = notifyObjectFuse.search(queryString)
            setFilteredItems(result.map(item => item.item))
        }else{
            setFilteredItems(cvisNotifyObject)
        }
    },[cvisNotifyObject, queryString])

    // 初始化fuse通報對象清單
    const listFuse = fuseObject(cvisNotifyObjectList)

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(()=>{
        if(cvisNotifyObjectQueryString) {
            const result = listFuse.search(cvisNotifyObjectQueryString)
            setFilteredListItems(result.map(item => item.item))
        }else{
            setFilteredListItems(cvisNotifyObjectList)
        }
    },[cvisNotifyObjectList, cvisNotifyObjectQueryString])

    // 初始化fuse危急值回覆紀錄
    const responseFuse = fuseObject(cvisNotifyResponse)

    useEffect(()=>{
        if(cvisNotifyResponseQueryString) {
            const result = responseFuse.search(cvisNotifyResponseQueryString)
            setFilteredResponseItems(result.map(item => item.item))
        }else{
            setFilteredResponseItems(cvisNotifyResponse)
        }
    },[cvisNotifyResponse, cvisNotifyResponseQueryString])


    // 初始化fuse危急值逾時回覆紀錄再通知
    const renotifyFuse = fuseObject(cvisNotifyRenotify)

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(()=>{
        if(cvisNotifyRenotifyQueryString) {
            const result = renotifyFuse.search(cvisNotifyRenotifyQueryString)
            setFilteredRenotifyItems(result.map(item => item.item))
        }else{
            setFilteredRenotifyItems(cvisNotifyRenotify)
        }
    },[cvisNotifyRenotify, cvisNotifyRenotifyQueryString])

    // 取得通報單位選單
    useEffect(() => {
        queryCvisNotifyUnit().then(res => {
            if(res.err === ApiErrorStatusEnum.Success) {
                setUnit(res.data)
                console.log(unit)
            }else{
                console.log(res)
                handleToastOnShow('無法取得通報單位資料',ToastTypeEnum.Error)
            }
        })
    },[])

    useEffect(() => {
        console.log(cvisNotifyData)
    }, [cvisNotifyData])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">通報單位:</p>
                    <select 
                        className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisNotifyUnit}
                        onChange={handleCvisNotifyUnitOnChange}
                    >
                        {!arrayIsEmpty(unit) && unit.map((item, index) => (
                            <option key={index} value={item.cvisNotifyUnitNo}>{item.cvisNotifyUnitName}</option>
                        ))}
                    </select>
                </div>
                {/* 通報日期 */}
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    {/* 通報日期起訖 */}
                    <p className="mr-4">通報日期起訖</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={cvisQueryData.cvisNotifyStartDate}
                        onChange={handleCvisNotifyStartDateOnChange}
                    />
                    {/* 通報日期結束 */}
                    <p className="m-1">~</p>
                    <BaseInput
                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Date}
                        value={cvisQueryData.cvisNotifyEndDate}
                        onChange={handleCvisNotifyEndDateOnChange}
                    />
                </div>
                {/* 取消狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">取消狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisCancelStuts}
                        onChange={handleCvisCancelStutsOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未取消</option>
                        <option value="2">已取消</option>
                    </select>
                </div>
                {/* 讀取狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">讀取狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisReadStuts}
                        onChange={handleCvisReadStutsOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未讀</option>
                        <option value="2">已讀</option>
                    </select>
                </div>
                {/* 回覆狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">回覆狀態</p>
                    <select 
                        className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        value={cvisQueryData.cvisResponseStatus}
                        onChange={handleCvisResponseStatusOnChange}
                    >
                        <option value="">全部</option>
                        <option value="1">未回覆</option>
                        <option value="2">已回覆</option>
                    </select>
                </div>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleOnQuery}    
                    >
                        查詢
                    </button>
                </div>
            </div>
            {showQueryResult && (
                <>
                    {/* 危急值內容 */}
                    <div className="maintainContainer flex flex-row items-start justify-start">
                        <div className="p-4 bg-white w-[calc(50%-16px)] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4 max-h-[848px] overflow-y-clip">
                            {/* 取消通報按鈕 */}
                            <div className="btnGroup flex flex-row mb-4">
                                <button 
                                    className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] rounded-[6px]"
                                    onClick={handleCancelCvisNotifyrec}
                                >
                                    取消通報
                                </button>
                            </div>
                            {/* 進階搜尋 */}
                            <div className='max-w-[320px] relative'>
                                <input
                                    className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                    type={InputTypeEnum.Text}
                                    value={queryString}
                                    onChange={(event) => handleQueryInputOnChange(event)}
                                    // onKeyDown={handleTableQuerySearchInputKeyDown}
                                    placeholder="進階搜尋"
                                />
                                <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                    <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                </div>
                            </div>
                            {/* 危急值紀錄列表 */}
                            <ul className="tableList CVRTableList mt-4 h-[800px]">
                                <li className="title scale-1.1">
                                    <div>通報單位</div>
                                    <div>通報項目</div>
                                    <div>等級</div>
                                    <div>通報日期時間</div>
                                    <div>病歷號</div>
                                    <div>姓名</div>
                                    <div>回覆狀態</div>
                                    <div>讀取</div>
                                    <div>取消</div>
                                    <div>門住</div>
                                    <div>科別</div>
                                    <div>通報對象</div>
                                    <div>通報電話</div>
                                    <div>通報編號</div>
                                </li>
                                {!arrayIsEmpty(filteredItems) && filteredItems.map((item, index) => {
                                    return (
                                        // 列表項目組件
                                        <CvisListItem
                                            type="CvisNotifyRecordList"
                                            index={index}
                                            item={item}
                                            handleItemOnClick={handleCvisListItemOnFocus}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                        {/* 危急值內容-分頁內容 */}
                        <div className="p-4 bg-white w-[50%] border-[1px] border-[#D4D4D8] rounded-[12px] h-[848px]">
                            {/*點擊按鈕切換下方內容*/}
                            <div className="hasHpcSwitch mb-4">
                                {/*點擊tab切換內容 - 按鈕*/}
                                <div className="tabGroup w-full flex flex-row items-center justify-between mb-4 pr-4">
                                    <div className="flex flex-row items-center justify-start">
                                        <button onClick={() => handleTabClick('report')}
                                                className={getTabClass('report')}>
                                            通報資料
                                        </button>
                                        <button onClick={() => handleTabClick('list')}
                                                className={getTabClass('list')}>
                                            通報對象清單
                                        </button>
                                        <button onClick={() => handleTabClick('reply')}
                                                className={getTabClass('reply')}>
                                            回覆紀錄
                                        </button>
                                        <button onClick={() => handleTabClick('outOfTime')}
                                                className={getTabClass('outOfTime')}>
                                            逾時回覆再通知紀錄
                                        </button>
                                    </div>
                                </div>
                                {/*點擊tab切換內容 - 內容*/}
                                {/* 通報資料 */}
                                {activeTab === 'report' && (
                                    <div>
                                        <ul className={getMaintainInfoClass()}>      
                                            <li>
                                                <div>通報單位</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyUnit) ? cvisNotifyUnitAdapter(cvisNotifyObject[cvisNotifyListId].cvisNotifyUnit) : "無資料"}</div>
                                                <div>通報編號</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyId) ? cvisNotifyObject[cvisNotifyListId].cvisNotifyId : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>通報項目</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyItem) ? cvisNotifyData.cvisNotifyItem : "無資料"}</div>
                                                <div>等級</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyClass) ? cvisNotifyData.cvisNotifyClass : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>病例號</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].patientId) ? cvisNotifyData.patientId : "無資料"}</div>
                                                <div>姓名</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].patientName) ? cvisNotifyData.patientName : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>門住</div>
                                                {/* <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].inpOpd) ? inpOpdToText(cvisNotifyObject[cvisNotifyListId].inpOpd) : "無資料"}</div> */}
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].inpOpdName) ? cvisNotifyObject[cvisNotifyListId].inpOpdName : "無資料"}</div>
                                                <div>科別</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].divisionName) ? cvisNotifyData.divisionName : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>通報日期時間</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyDatetime) ? cvisNotifyData.cvisNotifyDatetime : "無資料"}</div>
                                                <div>通報人員</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyUser) ? `${cvisNotifyData.cvisNotifyUser} : ${cvisNotifyData.cvisNotifyUserName}` : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>通報對象</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyObject) ? `${cvisNotifyData.cvisNotifyObject} : ${cvisNotifyData.cvisNotifyObjectName}` : "無資料"}</div>
                                                <div>通報電話</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].userMobile) ? cvisNotifyData.userMobile : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>通報內容</div>
                                                <div className="!w-[650px] mr-2.5">{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisNotifyContent : "無資料"}</div>
                                            </li>
                                            <li>
                                                <div>取消通報日期時間</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisCancelDatetime : "無資料"}</div>
                                                <div>取消通報人員</div>
                                                <div>{!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisCancelUser : "無資料"}</div>
                                            </li>
                                        </ul> 
                                    </div>
                                )}
                                {/* 通報對象清單 */}
                                {activeTab === 'list' && (
                                    <div>
                                        <div className='max-w-[320px] relative'>
                                            <input
                                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                                type={InputTypeEnum.Text}
                                                value={cvisNotifyObjectQueryString}
                                                onChange={(event) => handleCvisNotifyObjectQueryOnChange(event)}
                                                // onKeyDown={handleCvisNotifyObjectQueryOnChange}
                                                placeholder="進階搜尋"
                                            />
                                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                            </div>
                                        </div>
                                        <ul className="tableList CVRRightOneList mt-4">
                                            <li className="title">
                                                <div>類別名稱</div>
                                                <div>通報對象</div>
                                                <div>電話</div>
                                                <div>通報時間</div>
                                                <div>讀取時間</div>
                                            </li>
                                            {!objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredListItems.map((item, index) => {
                                                return (
                                                    // 列表項目組件
                                                    <CvisListItem
                                                        type="CvisNotifyObjectList"
                                                        index={index}
                                                        item={item}
                                                    />
                                                )
                                            })}
                                        </ul>
                                        <Pagination
                                            totalPageSize={notifyObjectPaginationProps.totalPageSize}
                                            pageSize={notifyObjectPaginationProps.pageSize}
                                            totalSize={notifyObjectPaginationProps.totalItemSize}
                                            currentPage={notifyObjectPaginationProps.pageNum}
                                            onPageOnChange={onNotifyPaginationPageOnChange}
                                            onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                                            onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                                            onNextPageOnClick={onNotifyPaginationNextOnClick}
                                        />
                                    </div>
                                )}
                                {/* 回覆紀錄 */}
                                {activeTab === 'reply' && (
                                    <div>
                                        <div className='max-w-[320px] relative'>
                                            <input
                                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                                type={InputTypeEnum.Text}
                                                value={cvisNotifyResponseQueryString}
                                                onChange={(event) => handleCvisNotifyResponseQueryOnChange(event)}
                                                // onKeyDown={handleCvisNotifyObjectQueryOnChange}
                                                placeholder="進階搜尋"
                                            />
                                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                            </div>
                                        </div>
                                        <ul className="tableList CVRRightTwoList mt-4">
                                            <li className="title">
                                                <div>病例號</div>
                                                <div>姓名</div>
                                                <div>主治醫師</div>
                                                <div>回覆日期時間</div>
                                                <div>回覆人員</div>
                                                <div>處理狀態</div>
                                                <div>處理說明</div>
                                            </li>
                                            {!objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredResponseItems.map((item, index) => {
                                                return (
                                                    // 列表項目組件
                                                    <CvisListItem
                                                        type="CvisNotifyResponseList"
                                                        index={index}
                                                        item={item}
                                                    />
                                                )
                                            })}
                                        </ul>
                                        <Pagination
                                            totalPageSize={responsePaginationProps.totalPageSize}
                                            pageSize={responsePaginationProps.pageSize}
                                            totalSize={responsePaginationProps.totalItemSize}
                                            currentPage={responsePaginationProps.pageNum}
                                            onPageOnChange={onResponsePaginationPageOnChange}
                                            onPageSizeChange={onResponsePaginationPageSizeOnChange}
                                            onPrevPageOnClick={onResponsePaginationPreviousOnClick}
                                            onNextPageOnClick={onResponsePaginationNextOnClick}
                                        />
                                    </div>
                                )}
                                {/* 逾時回覆再通知紀錄 */}
                                {activeTab === 'outOfTime' && (
                                    <div>
                                        <div className='max-w-[320px] relative'>
                                            <input
                                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                                type={InputTypeEnum.Text}
                                                value={cvisNotifyRenotifyQueryString}
                                                onChange={(event) => handleCvisNotifyRenotifyQueryOnChange(event)}
                                                // onKeyDown={handleCvisNotifyObjectQueryOnChange}
                                                placeholder="進階搜尋"
                                            />
                                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                                            </div>
                                        </div>
                                        <ul className="tableList CVRRightOneList mt-4 max-h-[800px]">
                                            <li className="title">
                                                <div>通報日期時間</div>
                                                <div>單號</div>
                                                <div>通報人員</div>
                                                <div>通報對象</div>
                                                <div>處理狀態</div>
                                            </li>
                                            {!objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredRenotifyItems.map((item, index) => {
                                                return (
                                                    // 列表項目組件
                                                    <CvisListItem
                                                        type="CvisNotifyRenotifyList"
                                                        index={index}
                                                        item={item}
                                                    />
                                                )
                                            })}
                                        </ul>
                                        <Pagination
                                            totalPageSize={renotifyPaginationProps.totalPageSize}
                                            pageSize={renotifyPaginationProps.pageSize}
                                            totalSize={renotifyPaginationProps.totalItemSize}
                                            currentPage={renotifyPaginationProps.pageNum}
                                            onPageOnChange={onRenotifyPaginationPageOnChange}
                                            onPageSizeChange={onRenotifyPaginationPageSizeOnChange}
                                            onPrevPageOnClick={onRenotifyPaginationPreviousOnClick}
                                            onNextPageOnClick={onRenotifyPaginationNextOnClick}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        currentPage={paginationProps.pageNum}
                        onPageOnChange={onPaginationPageOnChange}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}
                    />
                </>
            )}
            <ToastMessage 
                show={showToast}
                message={toastProps.message}
                type={toastProps.type}
                onClickClose={handleToastOnClose}
            />
        </div>
    )
}
export default CriticalValuesRecords