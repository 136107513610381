import { BaseInput } from '../../Input/BaseInput'
import {
    ApiErrorStatusEnum,
    InputTypeEnum,
    ToastTypeEnum,
    time2String,
    stringIsEmpty,
    objectIsEmpty,
} from 'edah_utils/dist'
import Button from '../../Button/Button'
import React, { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/del_square.svg'
import { BasePopup } from '../../Popup/BasePopup'
import { delTranFederal, editTranFederal } from '../../../api/v1/Rmis'
import { queryAllDivision, queryByHospNo } from '../../../api/v1/Menu'
import useToast from '../../../hooks/useToast'
import DivisionDatalist from '../DivisionDatalist'
import { DATETIME_FORMAT, DATE_FORMAT } from '../../../constants/common'
import { debounce } from '../utils'
import { t } from 'i18next'

export const ModalStatus = {
    ADD: 'ADD',
    EDIT: 'EDIT',
}
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`flex flex-row items-center justify-start mr-8 ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}
const InputStyle =
    'w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SelectStyle =
    'w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const CheckListMap = {
    federalFlag: '策略聯盟',
    communityFlag: '社區醫療群',
    supportFlag: '支援院所',
}
/**
 * 策略聯盟維護表單
 * @returns {JSX.Element}
 */
const StrategicAllianceMaintainForm = ({ data, onClose, status }) => {
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState(data.hospNo || '')

    // 院所名稱
    const [hospitalName, setHospitalName] = useState(data.hospName || '')

    // 郵遞區號
    const [zipCode, setZipCode] = useState(data.hospZipCode || '')

    // 選項們
    const [checkList, setCheckList] = useState({
        federalFlag: data.federalFlag,
        communityFlag: data.communityFlag,
        supportFlag: data.supportFlag,
    })

    const [divList, setDivList] = useState([])

    // 輸入框檢核狀態
    const [hospitalCodeState, setHospitalCodeState] = useState(null)

    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    // 以院所代碼查詢院所名稱
    const handleHospitalCodeInputOnBlur = (e) => {
        const value = e.target.value
        if (!stringIsEmpty(value)) {
            getHospNameByHospNo(value)
        }
    }

    // 以院所代碼查詢院所名稱
    const getHospNameByHospNo = debounce((hospNo) => {
        queryByHospNo({ hospNo }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: ToastTypeEnum.Error,
                    })
                    setHospitalName('')
                    setHospitalCode('')
                    setZipCode('')
                } else {
                    setHospitalName(res.data.hospName || '')
                    setZipCode(res.data.hospZipCode)
                }
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
                setHospitalName('')
                setHospitalCode('')
                setZipCode('')
            }
        })
    })

    const handleCheckboxOnChange = (item) => {
        setCheckList({
            ...checkList,
            [item]: !checkList[item],
        })
    }

    // 科別1
    const [departmentOne, setDepartmentOne] = useState({
        divNo: data.suggestDiv_01 || '',
        divName: data.suggestDiv01Name || '',
    })

    // 科別 2
    const [departmentTwo, setDepartmentTwo] = useState({
        divNo: data.suggestDiv_02 || '',
        divName: data.suggestDiv02Name || '',
    })

    // 科別 3
    const [departmentThree, setDepartmentThree] = useState({
        divNo: data.suggestDiv_03 || '',
        divName: data.suggestDiv03Name || '',
    })

    // 科別 4
    const [departmentFour, setDepartmentFour] = useState({
        divNo: data.suggestDiv_04 || '',
        divName: data.suggestDiv04Name || '',
    })

    // 科別 5
    const [departmentFive, setDepartmentFive] = useState({
        divNo: data.suggestDiv_05 || '',
        divName: data.suggestDiv05Name || '',
    })

    // 開始日期
    const [startDate, setStartDate] = useState(
        time2String(data.effectDate, DATE_FORMAT) || ''
    )

    // 結束日期
    const [endDate, setEndDate] = useState(
        time2String(data.endDate, DATE_FORMAT) || ''
    )

    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 儲存成功 or 失敗 Toast
    const showToast = useToast()

    // 更新開始日期
    const handleUpdateStartDate = (e) => {
        setStartDate(e.target.value)
    }

    // 更新結束日期
    const handleUpdateEndDate = (e) => {
        setEndDate(e.target.value)
    }

    // 更新科別1
    const selectDivision01 = (division) => {
        setDepartmentOne({
            ...departmentOne,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    // 更新科別2
    const selectDivision02 = (division) => {
        setDepartmentTwo({
            ...departmentTwo,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    // 更新科別3
    const selectDivision03 = (division) => {
        setDepartmentThree({
            ...departmentThree,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    // 更新科別4
    const selectDivision04 = (division) => {
        setDepartmentFour({
            ...departmentFour,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    // 更新科別5
    const selectDivision05 = (division) => {
        setDepartmentFive({
            ...departmentFive,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    // 儲存
    const handleSave = () => {
        const hospitalCodeisValid = !stringIsEmpty(hospitalCode)
        setHospitalCodeState(hospitalCodeisValid)
        if (!hospitalCodeisValid) {
            return
        }
        const params = {
            //院所代號
            hospNo: hospitalCode,
            //策略聯盟
            federalFlag: checkList.federalFlag,
            //社區療群
            communityFlag: checkList.communityFlag,
            //支援院所
            supportFlag: checkList.supportFlag,
            //推薦科別01
            suggestDiv_01: departmentOne.divNo,
            //推薦科別02
            suggestDiv_02: departmentTwo.divNo,
            //推薦科別03
            suggestDiv_03: departmentThree.divNo,
            //推薦科別04
            suggestDiv_04: departmentFour.divNo,
            //推薦科別05
            suggestDiv_05: departmentFive.divNo,
            //開始日期
            effectDate: time2String(startDate, DATETIME_FORMAT),
            //結束日期
            endDate: time2String(endDate, DATETIME_FORMAT),
            //排序
            sortBy: data.sortBy,
            lockVersion: data.lockVersion,
        }
        editTranFederal(params).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
                onClose && onClose()
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({ message: msg, type: ToastTypeEnum.Error })
            }
        })
    }

    // 刪除單筆資料
    const handleConfirmDelete = () => {
        delTranFederal({ hospNo: hospitalCode }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '刪除成功', type: ToastTypeEnum.Success })
                handleDeletePopupClose()
                onClose && onClose()
            } else {
                showToast({
                    message: `刪除失敗: ${res.msg}`,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = () => {
        setShowDeletePopup(true)
    }

    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 取得基礎選單資料
     * @return {void}
     */
    const getDivisionList = () => {
        queryAllDivision().then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setDivList(res.data)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    useEffect(() => {
        getDivisionList()
    }, [])

    return (
        <>
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">
                                是否確定刪除？
                            </p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button
                                    onClick={handleConfirmDelete}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >
                                    是
                                </button>
                                <button
                                    onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >
                                    否
                                </button>
                            </div>
                        </div>
                    }
                />
            )}
            {/*刪除彈窗*/}
            {status === ModalStatus.EDIT && (
                <div className="flex items-center justify-end px-8 pt-8">
                    <button
                        className="text-[#007CDB] flex"
                        onClick={() => handleDeletePopupOpen()}
                    >
                        <DeleteIcon />
                        <div className="ml-1">刪除此筆資料</div>
                    </button>
                </div>
            )}

            <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8">
                <div className="grid  grid-cols-[100px,300px,100px,1fr] mb-4">
                    <div className="flex items-center ">
                        <Title>院所代碼</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className="w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border"
                            type={InputTypeEnum.Text}
                            value={hospitalCode}
                            onChange={handleHospitalCodeChange}
                            inputState={hospitalCodeState}
                            onBlur={handleHospitalCodeInputOnBlur}
                            errorMsg="必填"
                            disable={status === ModalStatus.EDIT}
                        />
                    </div>
                    <div className="flex items-center">
                        <Title>院所名稱</Title>
                    </div>
                    <div className="flex items-center">
                        <BaseInput
                            className={InputStyle}
                            type={InputTypeEnum.Text}
                            value={hospitalName}
                            disable={true}
                        />
                    </div>
                </div>
                <div className="mb-4 mt-2 grid  grid-cols-[100px,300px,100px,300px,100px,300px]">
                    <div className="flex items-center">
                        <Title>{t('general.zipCode')}</Title>
                    </div>
                    <div className="flex items-center ">
                        <BaseInput
                            className={InputStyle}
                            type={InputTypeEnum.Text}
                            value={zipCode}
                            disable={true}
                        />
                    </div>
                </div>

                <div className="mt-2 grid grid-cols-[100px,300px,100px,300px,100px,300px]">
                    <div className="flex items-center">
                        <Title extendClass={'ml-5'}>科別 1</Title>
                    </div>
                    <div className="flex items-center">
                        <DivisionDatalist
                            divisionList={divList}
                            onSelect={selectDivision01}
                            clearValue={() =>
                                setDepartmentOne({
                                    divNo: '',
                                    divName: '',
                                })
                            }
                            value={{
                                divNo: departmentOne?.divNo,
                                divName: departmentOne.divName,
                            }}
                        />
                    </div>
                    <div className="flex items-center">
                        <Title extendClass={'ml-5'}>科別 2</Title>
                    </div>
                    <div className="flex items-center">
                        <DivisionDatalist
                            divisionList={divList}
                            onSelect={selectDivision02}
                            clearValue={() =>
                                setDepartmentTwo({
                                    divNo: '',
                                    divName: '',
                                })
                            }
                            value={{
                                divNo: departmentTwo?.divNo,
                                divName: departmentTwo.divName,
                            }}
                        />
                    </div>
                    <div className="flex items-center">
                        <Title extendClass={'ml-5'}>科別 3</Title>
                    </div>
                    <div className="flex items-center">
                        <DivisionDatalist
                            divisionList={divList}
                            onSelect={selectDivision03}
                            clearValue={() =>
                                setDepartmentThree({
                                    divNo: '',
                                    divName: '',
                                })
                            }
                            value={{
                                divNo: departmentThree?.divNo,
                                divName: departmentThree.divName,
                            }}
                        />
                    </div>
                </div>
                <div className="mt-2 grid grid-cols-[100px,300px,100px,300px]">
                    <div className="flex items-center">
                        <Title extendClass={'ml-5'}>科別 4</Title>
                    </div>
                    <div className="flex items-center">
                        <DivisionDatalist
                            divisionList={divList}
                            onSelect={selectDivision04}
                            clearValue={() =>
                                setDepartmentFour({
                                    divNo: '',
                                    divName: '',
                                })
                            }
                            value={{
                                divNo: departmentFour?.divNo,
                                divName: departmentFour.divName,
                            }}
                        />
                    </div>
                    <div className="flex items-center">
                        <Title extendClass={'ml-5'}>科別 5</Title>
                    </div>
                    <div className="flex items-center">
                        <DivisionDatalist
                            divisionList={divList}
                            onSelect={selectDivision05}
                            clearValue={() =>
                                setDepartmentFive({
                                    divNo: '',
                                    divName: '',
                                })
                            }
                            value={{
                                divNo: departmentFive?.divNo,
                                divName: departmentFive.divName,
                            }}
                        />
                    </div>
                </div>
                <div className="mb-4 mt-4 grid grid-cols-[100px,150px,150px,150px] ">
                    <div></div>
                    {Object.keys(checkList).map((item, index) => {
                        return (
                            <div
                                className={`flex space-x-3`}
                                key={index}
                            >
                                <input
                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                    type="checkbox"
                                    style={{ accentColor: '#38A169' }}
                                    checked={checkList[item]}
                                    onChange={() =>
                                        handleCheckboxOnChange(item)
                                    }
                                />
                                <label className="text-base">
                                    {CheckListMap[item]}
                                </label>
                            </div>
                        )
                    })}
                </div>
                <div className="mt-2 grid  grid-cols-[100px,260px,100px,260px]">
                    <div className="flex items-center ">
                        <Title>開始日期</Title>
                    </div>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Date}
                        value={startDate}
                        onChange={handleUpdateStartDate}
                    />
                    <span className="m-1">~</span>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Date}
                        value={endDate}
                        onChange={handleUpdateEndDate}
                    />
                </div>
            </div>
            <div className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
                <Button
                    classNames={`ml-4 ${ButtonStyle}`}
                    text={t('general.saveFile')}
                    onClickFn={handleSave}
                />
            </div>
        </>
    )
}

export default StrategicAllianceMaintainForm
