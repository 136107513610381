import React, {useEffect, useMemo, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup'
import {formatTime, Title} from '../utils';
import {BaseInput} from '../../Input/BaseInput';
import {
    ApiErrorStatusEnum,
    generateTimeIntervals,
    InputTypeEnum,
    stringIsEmpty,
    time2String,
    ToastTypeEnum
} from 'edah_utils/dist';
import {
    rsisCheckTimeValid,
    rsisChangeMaster,
    rsisQueryBaseData,
    rsisQueryCodeValue,
} from "../../../api/v1/RSIS";
import {usePopup} from "../PopupProvider";
import {t} from "i18next";
import {USER_ROLE} from "../userRole";
import {FuzzySearchInput} from "../FuzzySearchInput";
import {checkDoctorLicence} from "../../../api/v1/Menu";

/**
 * 報備支援修改-變更modal
 * @param data {object}  資料
 * @param isOwnUser {boolean} 是否為承辦人員
 * @param handleOnClose {function}  關閉modal
 * @param handleOnUpdate {function} 更新資料callback
 * @return {JSX.Element}
 */
const ChangeModal = ({data, isOwnUser, handleOnClose, handleOnUpdate}) => {
    // 使用者角色
    const [role, setRole] = useState('')
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 員工/醫師代碼
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo)
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName)
    // 支援目的清單
    const [purposeOptions, setPurposeOptions] = useState([])
    // 子支援目的清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindData, setKindData] = useState([])
    // 變更原因
    const [changeReason, setChangeReason] = useState([])
    // 院區
    const [zone, setZone] = useState(data.zone)
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別代號
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 執登機構代號
    const [hospNo, setHospNo] = useState('')
    // 執登機構名稱
    const [hospName, setHospName] = useState('')

    const [purposeCodeBeforChanged, setPurposeCodeBeforChanged] = useState(data.purposeCode)

    /** 變更後內容 */
        // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別編號
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo)
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data.supportDivName)
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : []);
    // 支援目的門住 支援目的更改為預設如果為null則設定為門診，值為1
    const [purposeCodeExt, setPurposeCodeExt] = useState('1')
    // 支援日期區間
    const [itemStartDate, setItemStartDate] = useState(time2String(data.itemStartDate, 'YYYY-MM-DD'))
    const [itemEndDate, setItemEndDate] = useState(time2String(data.itemEndDate, 'YYYY-MM-DD'))
    const [itemStartTime, setItemStartTime] = useState(formatTime(data.itemStartTime))
    const [itemEndTime, setItemEndTime] = useState(formatTime(data.itemEndTime))
    // 備註
    const [memo, setMemo] = useState('')
    // 變更原因
    const [modifyReason, setModifyReason] = useState(data.supportModifyReasonCode ? data.supportModifyReasonCode:'')
    // 其他說明
    const [otherDescription, setOtherDescription] = useState(data.supportModifyReasonDoc ? data.supportModifyReasonDoc:'')

    // 使用上下文
    const {
        showToast,
        setShowChangeRecordModal,
    } = usePopup()

    // 判斷是否可以編輯
    const canEdit = () => data.role === USER_ROLE.DEPT_USER || data.role === USER_ROLE.CREATE_USER

    /**
     * 支援科別更新時
     * @param item {object} 支援科別
     * @return {void}
     */
    const handleOnDivChange = (item) => {
        setSupportDivStr(item)
        setSupportDivNo(item.split(' ')[0])
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            if (!canEdit()) return;
            setPurposeCode(prevCodes => {
                const prevCodesArray = Array.isArray(prevCodes) ? prevCodes : prevCodes.split(' ').filter(Boolean);
                if (prevCodesArray.includes(codeNo)) {
                    return prevCodesArray.filter(code => code !== codeNo);
                } else {
                    return [...prevCodesArray, codeNo];
                }
            });
        };

        const isChecked = (codeNo) => {
            if (Array.isArray(purposeCode)) {
                return purposeCode.includes(codeNo);
            } else {
                // 如果 purposeCode 是字符串，將其分割成數組
                const codeArray = purposeCode.split(',').filter(Boolean);
                return codeArray.includes(codeNo);
            }
        };

        return (
            <div className="grid grid-cols-2 gap-4 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-1">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id={item.codeNo}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="purpose"
                                        checked={isChecked(item.codeNo)}
                                        onClick={() => handlePurposeChange(item.codeNo)}
                                        onChange={() => {
                                        }}
                                        disabled={!canEdit()}
                                    />
                                    <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                                </div>
                                <select
                                    className="ml-2 pl-4 w-[96px] h-10 bg-white text-black rounded-[6px] border border-gray-300"
                                    value={purposeCodeExt}
                                    onChange={(e) => setPurposeCodeExt(e.target.value)}
                                    disabled={!canEdit()}
                                >
                                    {renderSubPurposeOptions()}
                                </select>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="flex items-center space-x-2 col-span-1">
                                <input
                                    id={item.codeNo}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="purpose"
                                    checked={isChecked(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                    disabled={!canEdit()}
                                />
                                <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    /**
     * 渲染子支援目的選項
     * @return {JSX.Element}
     */
    const renderSubPurposeOptions = () => {
        return subPurposeOptions.map((item) => (
            <option key={item.codeNo} value={item.codeNo}>{item.codeValue1}</option>
        ))
    }

    /**
     * 渲染變更前支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptionsBeforeChanged = () => {
        // 將 purposeCode 字符串轉換為數組
        const purposeCodes = purposeCodeBeforChanged.split(',').filter(Boolean);
        // 子支援目的，門診，值班，會診
        const subPurpose = () => {
            if (data.purposeCodeExt === '1') {
                return t('RSIS.subPurposeOptionsEnum.1')
            } else if (data.purposeCodeExt === '2') {
                return t('RSIS.subPurposeOptionsEnum.2')
            } else if (data.purposeCodeExt === '3') {
                return t('RSIS.subPurposeOptionsEnum.3')
            }
        }
        // 根據 codeNo 查找對應的支援目的字串值
        const purposeTexts = purposeCodes.map(codeNo => {
            // 如果有找到對應的支援目的，返回對應的支援目的字串
            const purpose = purposeOptions.find(item => item.codeNo === codeNo);
            if (purpose) {
                // 如果codeNo=10，則返回支援目的(報備為一般支援的情況下)+子支援目的
                if (codeNo === '10') { // 假設 '10' 是 "報備為一般支援，支援目的" 的代碼
                    return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                }
                return purpose.codeValue1;
            }
            return null;
        }).filter(Boolean);

        // 渲染支援目的字串
        return purposeTexts.map((text, index) => (
            <p key={index} className='flex justify-start pl-4 py-[9px]'>{text}</p>
        ));
    };

    /**
     * 取得時間區間選項
     * @return {JSX.Element}
     */
    const TimeIntervalsComponent = () => {
        // 產生時間區間
        const timeIntervals = useMemo(() => generateTimeIntervals(), [])

        return timeIntervals.map((item, index) => <option key={index} value={item}>{item}</option>)
    }

    /**
     * 支援期間起始日期
     * @param e {Event} 事件
     */
    const handleOnStartDate = (e) => canEdit() && setItemStartDate(e.target.value)

    /**
     * 支援期間結束日期
     * @param e {Event} 事件
     */
    const handleOnEndDate = (e) => canEdit() && setItemEndDate(e.target.value)

    /**
     * 支援期間起始時間
     * @param e {Event} 事件
     */
    const handleOnStartTime = (e) => canEdit() && setItemStartTime(e.target.value)

    /**
     * 支援期間結束時間
     * @param e {Event} 事件
     */
    const handleOnEndTime = (e) => canEdit() && setItemEndTime(e.target.value)

    /**
     * 取得醫師執登資料
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定執豋資料
                if(data) {
                    setDivNo(data.divNo)
                    setDivName(data.divName)
                    setLicenceId(data.licenceId)
                    setLicenceKind(data.licenceKind)
                    setLicenceKindName(data.licenceKindName)
                    setHospNo(data.hospNo)
                    setHospName(data.hospName)
                }else { // 若無執豋資料，則設定空值
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得日期時間區間
     * @return {String}
     */
    const getDateTimeRange = () => {
        let str = ''
        //資料存在時
        if (data) {
            //起始和終止日期存在
            if (data.itemStartDate && data.itemEndDate) {
                str += `${time2String(data.itemStartDate, 'YYYY-MM-DD')}~${time2String(data.itemEndDate, 'YYYY-MM-DD')}`
            }

            //起始和終止時間存在
            if (data.itemStartTime && data.itemEndTime) {
                str += ` ${data.itemStartTime}~${data.itemEndTime}`
            }
        }
        return str
    }

    /**
     * 取得送出變更內容資料
     * @return {Object}
     */
    const getSendData = () => {
        return {
            supportNo: data.supportNo,
            itemVersion: data.itemVersion,
            supportUserNo: supportUserNo,
            supportOrgPkey: data.supportOrgPkey,
            supportOrgName: data.supportOrgName,
            supportDivNo: supportDivNo,
            purposeCode: purposeCode.join(','),
            purposeCodeExt: purposeCodeExt,
            itemStartDate: `${itemStartDate} 00:00:00`,
            itemEndDate: `${itemEndDate} 00:00:00`,
            itemStartTime: itemStartTime.split(':')[0] + itemStartTime.split(':')[1],
            itemEndTime: itemEndTime.split(':')[0] + itemEndTime.split(':')[1],
            supportProperties: data.supportProperties,
            executeContent: data.executeContent,
            memo: memo,
            agentUserNo: data.agentUserNo,
            stepStatus: data.stepStatus,
            supportModifyStatus: '',
            supportModifyDatetime: '',
            supportModifyReasonDoc: otherDescription,
            supportModifyReasonCode: modifyReason,
            sendFileName: data.sendFileName,
            sendCaseNo: data.sendCaseNo,
            sendDatetime: data.sendDatetime,
            applyType: data.applyType,
            zone: zone,
            effFlag: data.effFlag,
            ownerDeptNo: data.ownerDeptNo,
            changeFieldsDoc: '',
            confirmUserNo: data.confirmUserNo,
            confirmDatetime: data.confirmDatetime,
        }
    }

    /**
     * 取得日期和時間
     * @param date
     * @param time
     * @returns {{date: string, time: string}}
     */
    const getDateAndTime = (date, time) => {
        return {
            date: time2String(date, 'YYYY-MM-DD'),
            time: `${time.substring(0, 2)}:${time.substring(2, 4)}`
        }
    }

    /**
     * 取得重疊的時間段
     * @param date
     * @param time
     * @returns [{date: string, time: string}]
     */
    const getOverlapDateAndTimeList = (date, time) => {
        // 結果
        let result = []
        // 檢查data 和 time 長度是否一樣
        date.length === time.length ? date.map((item, index) => {
            //將結果加入result
            result.push(getDateAndTime(item, time[index]))
        }) : []
        return result
    }

    /**
     * 送出變更內容表單
     * @return {void}
     */
    const handleOnSubmit = async () => {
        const sendData = getSendData()

        // 檢查支援科別是否有值
        if(!supportDivNo) {
            showToast({message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        // 檢查變更原因是否為其他，若是則檢查是否有輸入其他說明
        if(sendData.supportModifyReasonCode === 'other' && sendData.supportModifyReasonDoc === '') {
            showToast({message: '請輸入其他原因之說明', type: ToastTypeEnum.Warning})
            return
        }

        // 若時間有進行變更
        if (sendData.itemStartDate !== data.itemStartDate || sendData.itemEndDate !== data.itemEndDate || sendData.itemStartTime !== data.itemStartTime || sendData.itemEndTime !== data.itemEndTime) {
            const startTimeInfo = sendData.itemStartTime
            const endTimeInfo = sendData.itemEndTime
            const startDate = new Date(sendData.itemStartDate.split(' ')[0]).setHours(startTimeInfo.slice(0, 2), startTimeInfo.slice(2, 4), 0, 0)
            const endDate = new Date(sendData.itemEndDate.split(' ')[0]).setHours(endTimeInfo.slice(0, 2), endTimeInfo.slice(2, 4), 0, 0)

            // 當結束日期早於起始日期
            if (endDate <= startDate) {
                showToast({message: '結束日期不得早於開始日期', type: ToastTypeEnum.Error})
                return
            }

            try {
                // 檢查時間是否重疊
                const result = await handleCheckOverlap(sendData)

                // 時間段不重疊
                if (!result.overlaps) {
                    if (canEdit()) changeMaster(sendData)
                } else {
                    console.log(result)
                    const overlapList = result.time.filter(item => item.overlapFlag === true)
                    const overlapMessage= `${supportUserName}：${overlapList?.map(item => {
                        // 取得起始日期和時間
                        const {
                            date: inputStartDate,
                            time: inputStartTime
                        } = getDateAndTime(item.itemStartDate, item.itemStartTime)
                        // 取得結束日期和時間
                        const {
                            date: inputEndDate,
                            time: inputEndTime
                        } = getDateAndTime(item.itemEndDate, item.itemEndTime)
                        // 取得起始時間重疊的時間段
                        const overlapStartDate = getOverlapDateAndTimeList(item.dbStartDate, item.dbStartTime)
                        // 取得結束時間重疊的時間段
                        const overlapEndDate = getOverlapDateAndTimeList(item.dbEndDate, item.dbEndTime)
                        // 重疊時間字串
                        let overlapTime = ''
                        // 組合重疊時間字串
                        overlapStartDate.map((item, index) => {
                            overlapTime += `${item.date} ${item.time} ~ ${overlapEndDate[index].date} ${overlapEndDate[index].time}\n`
                        })

                        return `\n${inputStartDate} ${inputStartTime} ~ ${inputEndDate} ${inputEndTime}支援時段重疊，支援人員：${supportUserName}已於${overlapTime}申請報備支援。`
                    })
                    }`;
                    showToast({message: overlapMessage, type: ToastTypeEnum.Error})
                }
            } catch (error) {
                console.error('Error checking overlap:', error)
                showToast({message: '檢查時間重疊時發生錯誤', type: ToastTypeEnum.Error})
            }
        } else {
            if (canEdit()) changeMaster(sendData)
        }


    }

    /**
     * 檢查伺服器是否有時間重疊
     * @param {Object} data - 要檢查的時間項
     * @return {Promise<{overlaps: boolean, time: string}>}
     */
    const handleCheckOverlap = async (data) => {
        try {
            const time = [{
                itemStartDate: data.itemStartDate,
                itemEndDate: data.itemEndDate,
                itemStartTime: data.itemStartTime,
                itemEndTime: data.itemEndTime
            }]
            const res = await rsisCheckTimeValid({
                supportNo: data.supportNo,
                supportUserNo: supportUserNo,
                itemVersion: data.itemVersion,
                timeList: time
            });
            if (res.err === ApiErrorStatusEnum.Success && res.data.every(item => item.overlapFlag === false)) {
                return {overlaps: false, time: res.data};
            } else {
                return {overlaps: true, time: res.data};
            }
        } catch (error) {
            console.error('Error in handleCheckOverlap:', error);
            showToast({message: `檢查時間 ${time} 時發生錯誤`, type: ToastTypeEnum.Error});
            return {overlaps: true, time};
        }
    }

    /**
     * 變更報備支援內容
     * @return {void}
     */
    const changeMaster = (data) => {
        console.log(data)
        rsisChangeMaster([data]).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: msg, type: ToastTypeEnum.Success})
                handleOnUpdate()
                handleOnClose()
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setPurposeOptions(data.filter(item => item.codeType === 'PURPOSE'))
                // 設定子支援目的選項
                setSubPurposeOptions(data.filter(item => item.codeType === 'SUBPURPOSE'))
                // 設定變更原因
                // 若不是承辦人，則取得code_value = "N"的資料，若為承辦人則包含code_value = "Y"的資料，即是全部資料
                isOwnUser ? setChangeReason(data.filter(item => item.codeType === 'CHANGE')) : setChangeReason(data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N'))
            } else {
                setSubPurposeOptions([])
                setChangeReason([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data.supportDivList)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 取得支援目的選項
        getPurposeOptions()
        // 取得醫師執登資料
        getDoctorData(supportUserNo.split(' ')[0])
        setSupportDivStr(`${supportDivNo} ${supportDivName}`)
        // 是否為承辦人員
        if (data.role === USER_ROLE.DEPT_USER) { // 是否為承辦人員
            setRole(USER_ROLE.DEPT_USER)
        } else if (data.role === data.createUser) { // 是否為申請人
            setRole(USER_ROLE.CREATE_USER)
        } else {
            setRole(USER_ROLE.OTHER_USER) // 既不是承辦人員也不是申請人
        }
    }, []);

    return (
        <BasePopup
            title='報備支援變更'
            width='1736px'
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='flex flex-col px-6 gap-4'>
                    {/* 第一列員工搜尋、查詢變更紀錄、狀態 */}
                    <div className='flex justify-between'>
                        <Title extendClass='w-[180px] justify-end pr-4'>{'員工/醫師代碼：'} </Title>
                        <input className='w-[186px] h-10 pl-4 bg-white text-black rounded-[6px] border border-gray-300'
                               type={InputTypeEnum.Text}
                               value={`${supportUserNo} ${supportUserName}`}
                               disabled={true}
                        />
                        <div className='flex flex-row justify-end w-full gap-2'>
                            {role === USER_ROLE.DEPT_USER && (<button
                                className="ml-4 flex items-center justify-center font-base px-4 h-10 rounded-[6px] border-[#D4D4D8] border-[2px] bg-[#FAFAFA]"
                                onClick={() => setShowChangeRecordModal(true)}
                            >
                                查詢變更紀錄
                            </button>)}
                            <select
                                className='w-[132px] pl-[12px] h-10 bg-white text-black rounded-[6px] border border-gray-300'
                                disabled>
                                <option value="1">{`${t(`campus.${zone}`)}`}</option>
                            </select>
                            <span
                                className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                        </div>
                    </div>
                    {/* 第二列資訊 */}
                    <div className='flex flex-row'>
                        {/* 執登機構 */}
                        <Title children='執登機構：'/>
                        <input
                            className='bg-transparent'
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                            disabled={true}
                        />
                        {/* 職類別 */}
                        <Title children='職類別：'/>
                        <input
                            className='bg-transparent'
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                            disabled={true}
                        />
                        {/* 執登科別 */}
                        <Title children='執登科別：'/>
                        <input
                            className='bg-transparent'
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                            disabled={true}
                        />
                        {/* 證書字號 */}
                        <Title children='證書字號：'/>
                        <input
                            className='bg-transparent'
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(licenceId) ? licenceId : ''}
                            disabled={true}
                        />
                    </div>
                    {/* 變更內容區塊 */}
                    <div className='flex flex-row gap-4'>
                        {/* 變更前內容 */}
                        <div className='w-full min-w-[820px] h-[625px] bg-white shadow rounded border-2 px-4'>
                            <div className="flex flex-row w-full py-2">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    變更前內容
                                </h3>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>申請單號：</p>
                                    <p className='flex justify-start pl-4'>{data.supportNo}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>機構代碼/名稱：</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{`${data.supportOrgNo} ${data.supportOrgName}`}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>支援科別：</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{`${data.supportDivNo} ${data.supportDivName}`}</p>
                                </div>
                                <div className='flex flex-row'>
                                    <Title children={'支援目的：'}
                                           extendClass='w-[160px] justify-end mt-[3px] h-9 pr-4'/>
                                    <div className='flex flex-col items-start'>
                                        {renderPurposeOptionsBeforeChanged()}
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>支援日期區間：</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{getDateTimeRange()}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>備註：</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{data.memo}</p>
                                </div>
                            </div>
                            {/* 分隔線 */}
                            <div className='border-b-[1px] border-[rgba(0,0,0,0.15)] my-2'></div>
                            <div className='flex flex-row items-center'>
                                <p className='flex justify-end w-[160px] pr-4 py-[9px]'>變更日期：</p>
                                <p className='flex justify-start pl-4 py-[9px]'>{data.supportModifyDatetime ? data.supportModifyDatetime : ''}</p>
                            </div>
                        </div>
                        {/* 可變更內容 */}
                        <div className='w-full min-w-[820px] h-[625px] bg-white shadow rounded border-2 px-4'>
                            <div className="flex flex-row w-full py-2">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    可變更內容
                                </h3>
                            </div>
                            <div className='flex flex-col gap-4 mb-4'>
                                <div className='flex flex-row items-center'>
                                    <FuzzySearchInput
                                        title={'支援科別：'}
                                        titleExtendClass={'w-[160px] justify-end pr-6'}
                                        value={supportDivStr}
                                        handleValueChange={handleOnDivChange}
                                        valueKey={['supportDivNo', 'supportDivName']}
                                        list={allDivisionList}
                                        inputExtendClass={'ml-4'}
                                    />
                                </div>
                                <div className='flex flex-row items-start'>
                                    <Title children={'支援目的：'}
                                           extendClass='min-w-[160px] justify-end py-2 h-9 pr-6'/>
                                    <div className='flex flex-col pl-4 items-start w-full'>
                                        {renderPurposeOptions()}
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <Title children={'支援日期區間：'}
                                           extendClass='min-w-[160px] justify-end py-2 h-9 pr-6'/>
                                    <div className='px-2 flex flex-row items-center gap-2'>
                                        <BaseInput
                                            className="w-[160px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                                            type={InputTypeEnum.Date}
                                            max="9999-12-31"
                                            value={itemStartDate}
                                            onChange={handleOnStartDate}
                                            disabled={!canEdit()}
                                        />
                                        ~
                                        <BaseInput
                                            className="w-[160px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-10 hover:cursor-pointer"
                                            type={InputTypeEnum.Date}
                                            max="9999-12-31"
                                            value={itemEndDate}
                                            onChange={handleOnEndDate}
                                            disabled={!canEdit()}
                                        />
                                    </div>
                                    <div className='flex flex-row ml-3 gap-2 item-center'>
                                        <select
                                            className='w-[102px] h-[40px] border-[1px] border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                                            value={itemStartTime}
                                            onChange={handleOnStartTime}
                                            disabled={!canEdit()}
                                        >
                                            <TimeIntervalsComponent/>
                                        </select>
                                        <p className='flex items-center'>~</p>
                                        <select
                                            className='w-[102px] h-[40px] border-[1px] border-[#D9D9D9] rounded-[6px] pl-4 hover:cursor-pointer'
                                            value={itemEndTime}
                                            onChange={handleOnEndTime}
                                            disabled={!canEdit()}
                                        >
                                            <TimeIntervalsComponent/>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <Title children={'備註：'} extendClass='min-w-[160px] justify-end py-2 h-9 pr-6'/>
                                    <div className='px-2'>
                                        <input
                                            className='w-[596px] h-[42px] bg-white text-black rounded-[6px] border border-gray-300 px-4'
                                            type={InputTypeEnum.Text}
                                            value={memo}
                                            onChange={(e) => setMemo(e.target.value)}
                                            disabled={!canEdit()}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* 分隔線 */}
                            <div className='border-b-[1px] border-[rgba(0,0,0,0.15)] my-2'></div>
                            <div className='flex flex-col gap-4'>
                                <div className='flex flex-row items-center mt-2'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>變更原因：</p>
                                    <div className='px-2'>
                                        <select
                                            className='w-[186px] h-10 bg-white text-black rounded-[6px] border border-gray-30 pl-4'
                                            value={modifyReason}
                                            onChange={(e) => setModifyReason(e.target.value)}
                                            disabled={!canEdit()}
                                        >
                                            {changeReason.map((item, index) =>
                                                <option key={index} value={item.codeNo}>{item.codeValue1}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>其他說明：</p>
                                    <div className='px-2'>
                                        <input
                                            className='w-[596px] h-[42px] bg-white text-black rounded-[6px] border border-gray-300 px-4'
                                            type={InputTypeEnum.Text}
                                            value={otherDescription}
                                            onChange={(e) => setOtherDescription(e.target.value)}
                                            disabled={!canEdit()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 第三列按鈕 */}
                    <div className='w-full flex flex-row justify-end items-center mb-4'>
                        {/* 取消編輯按鈕 */}
                        <button
                            className='w-[100px] ml-4 flex items-center justify-end font-base px-4 h-10 border-[2px] rounded-[6px] text-primary border-primary hover:cursor-pointer'
                            onClick={handleOnClose}
                        >
                            取消編輯
                        </button>
                        {/* 存擋按鈕 */}
                        <button
                            className={`w-[64px] ml-4 flex items-center justify-end font-base px-4 h-10 rounded-[6px] ${
                                canEdit()
                                    ? 'bg-primary text-white hover:cursor-pointer'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                            onClick={handleOnSubmit}
                            disabled={!canEdit()}
                        >
                            存擋
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default ChangeModal
