//Import icons
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    createDateRange2String,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    ToastTypeEnum,
    time2String
} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import useOutsideClick from "../../hooks/useOutsideClick"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiQueryEmroperLog} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {BaseInput} from "../../components/Input/BaseInput"

/**
 * 操作記錄查詢
 * @return {JSX.Element}
 */
const EmrMaster = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    /**
     * Show Toast
     */
    const showToast = useToast()

    // 建立時間
    const [startDate, setStartDate] = useState(null)

    // 終止時間
    const [endDate, setEndDate] = useState(null)

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    //總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    // 初始資料 table
    const [data, setData] = useState([])

    // 初始資料 人員
    const [user, setUser] = useState('')

    //下拉選單 CRUD
    const [selectCrud, setSelectCrud] = useState("ALL")

    //病人ID
    const [patientId, setPatientId] = useState("")

    // 是否顯示人員代號選項
    const [showUserOptions, setShowUserOptions] = useState(false)

    // 所有人員代號選項
    const [users, setUsers] = useState([])

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserDropdownOnFocus = () => setShowUserOptions(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setUser(`${option.userNo} ${option.userName}`)
        }

        // 關閉建立人員下拉選單
        setShowUserOptions(false)
    }

    /**
     * 取得過濾後的人員代號選項
     * @return {Array<Object>}
     */
    const getFilterUser = () =>
        // 醫生是否有空格
        user.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(users, user.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(users, user)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 時間起始事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間終止事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param e {Event} 事件
     * @return {void}
     */
    const handleUserDropdownOnChange = (e) => setUser(e.target.value)

    /**
     * 欄位下拉選單改變時-CRUD下拉
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSelectCrudOnChange = (e) => setSelectCrud(e.target.value)

    /**
     * 欄位改變時-病人ID
     * @param e {Event} 事件
     * @return {void}
     */
    const handlePatientIdOnChange = (e) => setPatientId(e.target.value)

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

     /**
    * 取得模糊匹配後的資料陣列
    * @return {Array<Object>}
    */
     const getFilterData = (data) => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定人員代號選項
                setUsers(data)
            }
        })
    }

    /**
     * 取得查詢操作記錄
     * @return {void}
     */
    const getQueryEmroPerLog = () => {
        essiQueryEmroperLog({
            // 起訖時間
            createStartDate: `${startDate} 00:00:00`,
            // 終止時間
            createEndDate: `${endDate} 23:59:59`,
            // 操作類型
            logOperCrud: selectCrud,
            //當前頁碼
            pageNum: currentPage,
            //每頁資料筆數
            pageSize: pageSize,
            // 病患ID not required
            pateientId: patientId,
            // logUserNo not required
            logUserNo: user
        }).then(res => {
            // API回傳成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 查無資料
                if (res.data.length === 0 || !res.data) {
                    // 顯示查無資料
                    showToast({type: ToastTypeEnum.Success, message: '查無資料'})
                }

                //設定資料
                setData(res.data)
                //設定總資料筆數
                setTotalSize(res.totalItemSize)
                //設定總頁數
                setTotalPageSize(res.totalPageSize)
            }
        })
    }

    /**
     * 第一次進入時去新增測試資料 人員清單
     * @return {void}
     */
    useMemo(() => {
        //取得日期範圍
        const dateRange = createDateRange2String("","",0)
        // 設定開始時間
        setStartDate(dateRange[0])
        // 設定開始時間
        setEndDate(dateRange[1])
        // 查詢所有醫生
        getQueryAllDoctor()

    }, [])

    /**
     * 監聽當起始時間和結束時間改變時，重新取得查詢操作記錄
     */
    useEffect(() => {
        // 起訖時間不為空
        if (!!startDate && !!endDate) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                //取得查詢操作記錄
                getQueryEmroPerLog()
                // 設定已經取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [startDate, endDate])

    /**
     * 避免點擊建立人員選項時，因CSS點不到問題
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserOptions(false),
    })

    /**
     * 當頁碼或每頁筆數改變時
     * @return {void}
     */
    useEffect(() => {
        // 取得查詢操作記錄
        getQueryEmroPerLog()
    }, [currentPage, pageSize])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-between">
                <div className="flex flex-row flex-wrap items-center">
                    <div className="date flex flex-row items-center justify-start mb-2 mr-2">
                        <p className="mr-4">開始日期起訖</p>
                        {/*開始時間*/}
                        <BaseInput inputMode="date" type="date" className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               value={startDate}  onChange={handleStartDateOnChange}/>
                        <p className="m-1">~</p>
                        {/*結束時間*/}
                        <BaseInput inputMode="date" type="date" className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               value={endDate} onChange={handleEndDateOnChange}/>
                    </div>
                    <div className="source flex flex-row items-center justify-start mb-2 mr-2 w-[300px]">
                        <p className="mr-4 w-[100px]">操作人員</p>
                        <div className="flex flex-row space-x-2 items-center relative w-full" ref={dropdownUserRef}>
                            <input className="w-full h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 pl-9"
                                   value={user}
                                   onChange={(e) => handleUserDropdownOnChange(e)}
                                   onFocus={handleUserDropdownOnFocus}/>
                            <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                 src={SearchIcon} role="button"/>
                            {
                                // 顯示人員代號選項
                                showUserOptions && (
                                    <div
                                        className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                getFilterUser().map((user, index) =>
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        role="button" key={index}
                                                        onClick={() => handleUserOptionOnClick(user)}>
                                                        <p>{`${user.userNo} ${user.userName}`}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/*病人ID*/}
                    <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                        <p className="mr-4">病人ID</p>
                        <input className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type="text" value={patientId}
                               onChange={handlePatientIdOnChange}/>
                    </div>
                    {/*操作類型*/}
                    <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                        <p className="mr-4">操作類型</p>
                        <select className="w-[110px] h-10 pl-3 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                value={selectCrud} onChange={handleSelectCrudOnChange}>
                            <option value="ALL">全部</option>
                            <option value="C">C - 新增</option>
                            <option value="R">R - 簽章</option>
                            <option value="U">U - 維護</option>
                            <option value="D">D - 刪除</option>
                        </select>
                    </div>
                    {/*查詢按鈕*/}
                    <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={getQueryEmroPerLog}>
                            {t('general.query')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                <div className="flex flex-row items-center justify-between mb-2">
                    {/*進階搜尋*/}
                    <div>
                        <BaseInput inputMode="search" type="text" className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]" type="text"
                               placeholder={t('general.advancedSearch')} value={advancedSearchText}
                               onChange={handleAdvancedSearchTextOnChange}/>
                    </div>
                </div>
                <div className='w-full overflow-x-auto overflow-y-auto min-h-[70vh] max-h-[70vh] xl:max-h-[70vh]'>
                    <table
                        className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[70vh] max-h-[70vh] xl:max-h-[70vh]">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`w-[200px] ${tableElementDefaultType}`}>操作時間 <br/> 操作人員</th>
                            <th className={`${tableElementDefaultType}`}>操作類型 <br/> 查詢條件</th>
                            <th className={`${tableElementDefaultType}`}>操作對象<br/> 查詢說明</th>
                            <th className={`w-[200px] ${tableElementDefaultType}`}> 病人資訊</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !arrayIsEmpty(getFilterData(data)) && getFilterData(data).map((data, index) =>
                                <tr
                                    className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                    key={index}>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logDatetime ? time2String(data.logDatetime,'YYYY-MM-DD hh:mm:ss') : ""}<br/>{data.createUserNo ? data.createUserNo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logOperCrud ? data.logOperCrud : ""}<br/>{data.logOperCondition ? data.logOperCondition : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logOperFunId ? data.logOperFunId : ""}<br/>{data.logOtherDoc ? data.logOtherDoc : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.patientId ? data.patientId : ""}
                                    </td>

                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                {/*分頁*/}
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                                totalSize={totalSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPreviousOnClick={onPaginationPreviousOnClick}
                                onNextOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}/>
                </div>
            </div>
        </div>
    )
}
export default EmrMaster
