import {ApiErrorStatusEnum, cleanLocalStorage, getLocalStorage, Http, ModeEnum, setLocalStorage} from "edah_utils/dist"
import store from "../redux/store"
import {hideLoadingPage, showLoadingPage} from "../redux/Slice/LoadingPageSlice"

// 目前環境是否為測試環境
const currentMode = process.env.NODE_ENV === ModeEnum.Development
// 依照目前環境設定不同的baseUrl
const baseUrl = currentMode ? 'https://edah-api.southmax.com' : 'https://edah-api.southmax.com'
// 本地服務的URL
const localServiceUrl = currentMode ? 'https://localhost:33771/v1' : 'https://localhost:33771/v1'

/**
 * v1RequestInterceptor
 * @param config {InternalAxiosRequestConfig<any>}
 */
const v1RequestInterceptor = (config) => {
    //取得Token
    const token = getLocalStorage('token')

    //Token存在的話則將Token加入到Headers
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    //顯示LoadingPage
    store.dispatch(showLoadingPage(true))
    // 記錄開始時間
    config.metadata = {startTime: performance.now()}

    return config
}

/**
 * v1ResponseInterceptor
 * @param response {AxiosResponse}
 */
const v1ResponseInterceptor = (response) => {
    let result
    //取得呼叫的url
    const url = response.request.responseURL
    //取得httpStatusCode
    const httpStatusCode = response.status

    //非200時
    if (httpStatusCode !== 200) {
        //隱藏LoadingPage
        store.dispatch(hideLoadingPage(false))
        return
    } else { //200時
        // IE9時response.data是undefined，因此需要使用response.request.responseText
        if (response.data === undefined) {
            result = JSON.parse(response.request.responseText.data)
        } else {
            result = response.data
        }

        //確保僅有/login進得去
        const regexLoginURL = /\/login($|[^A-Za-z0-9])/;
        // 是登入頁面，則將登入資訊存入localStorage
        if (regexLoginURL.test(url)) {
            //　登入成功
            if (result.err === 0) {
                //取得到回傳資料
                const data = result.data
                //儲存Token
                setLocalStorage("token", data.token)
                //儲存role權限
                setLocalStorage('role', JSON.stringify(data.role))
                //儲存使用者姓名
                setLocalStorage('username', data.userName)
                // 儲存使用者代號
                setLocalStorage('userno', data.userNo)
                // 儲存當前IP
                setLocalStorage('localIP', data.localIP)
            }
        } else { //不為Login URL
            //有錯誤
            if (result.err === 1) {
                //Token逾期或者已登出
                if (result.statusCode === 1010) {
                    //清除LocalStorage
                    cleanLocalStorage()

                    //轉跳到Login
                    window.location.href = `${window.location.protocol}//${window.location.host}/login`
                }
            }
        }
    }

    //隱藏LoadingPage
    store.dispatch(hideLoadingPage(false))
    // 記錄結束時間
    const endTime = performance.now()

    return response.data
}

/**
 * v1ErrorInterceptor
 * @param error {any}
 * @return {Promise<any>}
 */
const v1ErrorInterceptor = (error) => {
    // 隱藏LoadingPage
    store.dispatch(hideLoadingPage(false))
    // 避免出現空白或錯誤畫面
    return Promise.resolve({err: ApiErrorStatusEnum.Error, data: null, msg: "API出錯，請稍後重新再嘗試"})
}

//v1Api
const v1Api = new Http(`${baseUrl}/v1`, v1RequestInterceptor, v1ResponseInterceptor, v1ErrorInterceptor)

/**
 * Local Service API
 */
const v1LocalServiceAPI = new Http(`${localServiceUrl}`, v1RequestInterceptor, v1ResponseInterceptor, v1ErrorInterceptor, 100000, false)

// export
export {v1Api, v1LocalServiceAPI}
