import {useEffect, useState, useRef} from "react"
import {t} from "i18next"
import {
    time2String,
    InputTypeEnum,
    RegsWeekModeEnum,
    arrayIsEmpty,
    stringIsEmpty,
    objectIsEmpty,
    ApiErrorStatusEnum,
    fuzzySearchObjects,
    ToastTypeEnum, exportExcelAsBlob, downloadFile
} from "edah_utils/dist"
import {BaseInput} from "../../components/Input/BaseInput"
import {DefaultButton} from "../../components/Button/DefaultButton"
import {SearchInput} from "../../components/Input/SearchInput"
import ExportIcon from "../../assets/images/SystemMonitoring/export.svg"
import DeleteIcon from '../../assets/images/icons/del_square.svg'
import {Pagination} from "../../components/Pagination/Pagination"
import CancelStopClinicPromptPopup from "../../components/Regs/StopClinic/CancelStopClinicPromptPopup"
import {
    regsQueryStopClinicBaseData,
    regsQueryStopClinicReg,
    regsQueryStopClinicContactHistory,
    regsDelStopClinicContactHistory,
    regsCancelStopClinicAndLocum,
    regsAddStopdregdAndCancelAndCompSave
} from "../../api/v1/RegsStopClinic"
import {
    queryAllDoctor,
    queryAllDivision
} from "../../api/v1/Menu"
import DeleteContactHistoryPromptPopup from "../../components/Regs/StopClinic/DeleteContactHistoryPromptPopup"
import useToast from "../../hooks/useToast"
import useOutsideClick from "../../hooks/useOutsideClick"

/**
 * 停代診頁面
 */
const StopClinic = () => {
    // 預設建立時間起迄:起-今日往前兩周~迄-今日日期
    const today = new Date()
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        //聯絡狀態
        contactStatusMode: "0",
        // 醫師 or 科別 or 病歷號 過濾模式
        filterMode: RegsWeekModeEnum.Doctor,
    }
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("")
    //簡訊文字
    const [messageText, setMessageText] = useState("")
    //Show/hide popup filter options
    const [showFilterOptions, setShowFilterOptions] = useState(false)
    // Viewing Filter Option -> 醫師 list or 科別 list
    const [doctorOrDeptOptions, setDoctorOrDeptOptions] = useState(null)
    // 過濾後的下拉選項
    const [filterDoctorOrDeptOptions, setFilterDoctorOrDeptOptions] = useState(null)
    // 聯絡日期
    const [contactDate, setContactDate] = useState(time2String(today, 'yyyy-MM-DD'))
    // 聯絡時間
    const [contactTime, setContactTime] = useState(time2String(today, 'HH:mm'))
    // 片語搜尋文字
    const [searchPhraseText, setSearchPhraseText] = useState("")
    // 聯絡內容文字
    const [contactContainText, setContactContainText] = useState("")
    // 選擇到的資料
    const [selectedData, setSelectedData] = useState(null)
    // 停代診明細查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null)
    // 停代診明細進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    // 停代診基本資料
    const [baseData, setBaseData] = useState(null)
    // 是否有選擇資料
    const [haveSelectedData, setHaveSelectedData] = useState(false)
    //聯絡紀錄 清單
    const [contactHistoryList, setContactHistoryList] = useState(null)
    //片語資料
    const [phraseData, setPhraseData] = useState(
        [
            {
                no: "1",
                name: "電話未接"
            },
            {
                no: "2",
                name: "稍後再通知"
            },
            {
                no: "3",
                name: "已通知"
            },
            {
                no: "4",
                name: "已改掛"
            },
            {
                no: "5",
                name: "颱風天"
            },
        ]
    )
    //取消停代診提示彈出窗顯示
    const [showCancelStopClinicPrompt, setShowCancelStopClinicPrompt] = useState(false)
    //刪除聯絡紀錄提示彈出窗顯示
    const [showDelContactHistoryPrompt, setShowDelContactHistoryPrompt] = useState(false)
    //刪除聯絡紀錄id
    const [delContactHistoryId, setDelContactHistoryId] = useState("")
    //全部醫師list資料
    const [allDoctorList, setAllDoctorList] = useState([])
    //全部科別list資料
    const [allDivList, setAllDivList] = useState([])
    //退掛原因 代碼
    const [cancelRegReason, setCancelRegReason] = useState(null)
    //診間全部狀態
    const [isAllClinic, setIsAllClinic] = useState(false)
    //是否完成聯絡
    const [isCompleteContact, setIsCompleteContact] = useState(false)
    // 停代診明細 pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向醫生科別下拉菜單元素
    const dropdownDoctorOrDepartRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorOrDepartRef,
        handler: () => setShowFilterOptions(false),
    });

    /**
     * 開始時間輸入欄位變更事件
     */
    const handleDateStartOnChange = (e) => {
        //setDateStart(e.target.value)
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: e.target.value
        })
    }

    /**
     * 結束時間輸入欄位變更事件
     */
    const handleDateEndOnChange = (e) => {
        //setDateEnd(e.target.value)
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateEnd: e.target.value
        })
    }

    /**
     * 聯絡狀態模式改變時
     * @param {Number} mode
     */
    const handleFilterContactStatusModeOnChange = (mode) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            contactStatusMode: mode
        })
    }

    /**
     * 查詢別模式改變時
     * @param {Number} mode
     */
    const handleFilterModeOnChange = (mode) => {
        //設定模式
        //setFilterMode(mode)
        setDisplayQueryInputData({
            ...displayQueryInputData,
            filterMode: mode,
            text: ""
        })
        //判斷是否為醫師或科別
        if (mode !== null && (mode === RegsWeekModeEnum.Department || mode === RegsWeekModeEnum.Doctor)) {
            //是否科別模式
            const isDepartmentMode = mode === RegsWeekModeEnum.Department
            //根據科別取得下拉選項
            const options = isDepartmentMode ? allDivList : allDoctorList
            //設定下拉選項
            setDoctorOrDeptOptions(options)
        } else {
            setDoctorOrDeptOptions([])
        }
    }

    /**
     * 搜尋文字改變時
     * @param {Object} e
     */
    const handleSearchTextOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            text: e.target.value
        })
    }

    /**
     * 進階搜尋文字改變時
     * @param {Object} e
     */
    const handleAdvanceSearchTextOnChange = (e) => setAdvSearchText(e.target.value)

    /**
     * 簡訊文字改變時
     * @param {Object} e
     */
    const handleMessageTextOnChange = (e) => setMessageText(e.target.value)

    /**
     * 聯絡日期輸入欄位值改變時
     * @param {Event} e
     * @returns
     */
    const handleInputContactDateOnChange = (e) => setContactDate(e.target.value)

    /**
     * 聯絡時間輸入欄位值改變時
     * @param {Event} e
     * @returns
     */
    const handleInputContactTimeOnChange = (e) => setContactTime(e.target.value)

    /**
     * 資料項目選擇事件
     * @param {Object} data
     */
    const handleDataOnClick = (data) => {
        const findedData = filteredDataList.find(item => (item.key === data.key))
        setSelectedData((prev) => findedData)

        //重新設定聯絡時間(顯示當前時間)
        const now = new Date()
        setContactDate(time2String(now, 'yyyy-MM-DD'))
        setContactTime(time2String(now, 'HH:mm'))
        //清空聯絡內容
        resetContactContain()
        //清空簡訊
        setMessageText("")
    }

    /**
     * toggle filter options
     * @param {Boolean} input
     * @return void
     */
    const handleToggleFilterOptions = () => {
        setFilterDoctorOrDeptOptions(getFilterOptions())
        setShowFilterOptions(true)
        //setTimeout(async () => setShowFilterOptions(input), 150)
    }

    /**
     * 取得醫師或科別 過濾選項
     */
    const getFilterOptions = () => {
        const searchText = displayQueryInputData.text
        //搜尋文字為空時
        if (searchText === '') {
            return doctorOrDeptOptions
        } else { //搜尋文字有數據時
            return doctorOrDeptOptions.filter(option => {
                //是否科別模式
                const isDepartmentMode = displayQueryInputData.filterMode === RegsWeekModeEnum.Department
                //編號
                const noID = isDepartmentMode ? option.divNo : option.userNo
                //名稱
                const name = isDepartmentMode ? option.divName : option.userName
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //回傳是否符合條件
                return hasSpace ?
                    //有空白時
                    (noID.includes(searchText.split(' ')[0]) && name.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (noID.includes(searchText) || name.includes(searchText))
            })
        }
    }

    /**
     * 選擇醫師或科別的下拉過濾選項時
     * @param {Object} option
     * @return void
     */
    const handleFilterOptionOnClick = (option) => {
        //是否科別模式
        const isDepartmentMode = displayQueryInputData.filterMode === RegsWeekModeEnum.Department
        //設定搜尋文字
        const searchText = isDepartmentMode ? `${option.divNo} ${option.divName}` : `${option.userNo} ${option.userName}`
        setDisplayQueryInputData({
            ...displayQueryInputData,
            text: searchText
        })
        setShowFilterOptions(false)
    }

    /**
     * 取得過濾選項
     * @param {Object} option
     * @return string
     */
    const getFilterOptionContent = (option) => displayQueryInputData.filterMode === RegsWeekModeEnum.Department ?
        //科別代號 科別名稱
        `${option.divNo} ${option.divName}` :
        //醫師代號 醫師名稱
        `${option.userNo} ${option.userName}`

    /**
     * 查詢按鈕點擊事件
     */
    const handleSearchOnClick = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message: "起始日期不可大於結束日期", type: ToastTypeEnum.Error})
            return
        }
        queryStopClinicData(displayQueryInputData, 1, paginationProps.pageSize)
    }

    /**
     * 發簡訊按鈕點擊事件
     */
    const handleSendMessageOnClick = () => {

    }

    /**
     * 聯絡內容 存檔按鈕點擊事件
     */
    const handleSaveContactDetailsOnClick = () => {
        regsAddStopdregdAndCancelAndCompSave({
            //診間key,必填
            clinicId: selectedData.clinicId,
            //-----------------------------掛號key,初診預約key:擇一必填
            //掛號key
            regId: stringIsEmpty(selectedData.regId) ? "" : selectedData.regId,
            //初診預約key
            firstregId: stringIsEmpty(selectedData.regId) ? selectedData.firstregId : "",
            //-----------------------------
            //連絡狀況,選填
            contactStatus: contactContainText,
            ///診間全部 true/false,必填
            allClinicFlag: isAllClinic,
            //是否完成聯絡 true/false
            completeFlag: isCompleteContact,
            //退掛原因代碼，填了代表要退掛,選填
            cancelRegReasoncode: cancelRegReason === "-1" ? null : cancelRegReason
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: "儲存聯絡紀錄成功", type: ToastTypeEnum.Success})
                //更新聯絡紀錄
                getSelectedContactHistory()
                //重新取得停代診明細
                queryStopClinicData(queryInputData, 1, paginationProps.pageSize)
            } else {
                const msg = `儲存聯絡紀錄失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 取消停代診按鈕點擊事件
     */
    const handlesetCancelStopClinicClick = () => {
        setShowCancelStopClinicPrompt(true)
    }

    /**
     * 關閉取消停代診Popup
     */
    const closeCancelStopClinicPromptPopup = () => {
        setShowCancelStopClinicPrompt(false)
    }

    /**
     * 確認取消停代診
     */0
    const confirmCancelStopClinic = (clinicId) => {

        regsCancelStopClinicAndLocum({
            clinicId: clinicId
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: "取消停診成功", type: ToastTypeEnum.Success})
                //重新取得停代診明細
                queryStopClinicData(queryInputData, 1, paginationProps.pageSize)
            } else {
                const msg = `取消停診失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }))
        setShowCancelStopClinicPrompt(false)
    }

    /**
     * 片語搜尋輸入文字變化時
     * @param {Event} e
     * @returns
     */
    const handleSearchPhraseTextOnChange = (e) => setSearchPhraseText(e.target.value)

    /**
     * 聯絡資訊內容文字改變時
     * @param {Event} e
     * @returns
     */
    const handleContactContainTextOnChange = (e) => setContactContainText(e.target.value)

    /**
     * 退掛原因下拉選單改變時
     * @param {Event} e
     * @returns
     */
    const handleCancelRegReasonOnChange = (e) => {
        setCancelRegReason(e.target.value)
    }

    /**
     * 診間全部勾選狀態改變時
     * @returns
     */
    const handleIsAllClinicOnChange = () => setIsAllClinic(!isAllClinic)

    /**
     * 完成聯絡勾選狀態改變時
     * @returns
     */
    const handleIsCompleteContactOnChange = () => setIsCompleteContact(!isCompleteContact)

    /**
     * 片語Item點擊事件
     */
    const handlePhraseOnClick = (phrase) => {
        setContactContainText((prevText) => `${prevText}${phrase}`)
    }

    /**
     * 刪除聯絡紀錄
     * @param {String} regId
     */
    const handleDeleteContactHistoryOnClick = (regId) => {
        //設定要刪除的紀錄id
        setDelContactHistoryId(regId)
        //開啟刪除提示彈窗
        setShowDelContactHistoryPrompt(true)
    }

    /**
     * 關閉刪除聯絡紀錄彈窗
     */
    const handleCloseDelContactHistoryPrompt = () => {
        setShowDelContactHistoryPrompt(false)
    }

    /**
     * 刪除聯絡紀錄動作
     */
    const doDelContactHistory = () => {
        regsDelStopClinicContactHistory({
            stopRegId: delContactHistoryId
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: "刪除成功", type: ToastTypeEnum.Success})
                //取得選擇明細的聯絡紀錄
                getSelectedContactHistory(selectedData)
            } else {
                const msg = `刪除失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }))

        setShowDelContactHistoryPrompt(false)
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        queryStopClinicData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        queryStopClinicData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        queryStopClinicData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        queryStopClinicData(queryInputData, 1, e.target.value)
    }

    const getFuzzySearchPhraseList = () => {
        return fuzzySearchObjects(phraseData, searchPhraseText, ["no"])
    }

    /**
     * 檢查資料是否被選擇
     * @param {Object} data
     * @returns
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(selectedData)) {
            return false
        }
        if (selectedData.key === data.key) {
            return true
        }
        return false
    }

    /**
     * 停代診基本資料取得
     */
    const getStopClinicBasicData = () => {

        regsQueryStopClinicBaseData({}).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData(res.data)
            } else {
                console.error(res)
            }
        }))
    }

    /**
     * 取得停代診明細
     */
    const queryStopClinicData = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        let doctorNo = ""
        let divNo = ""
        let patientId = ""
        if (queryInputObj.filterMode === null) {
            patientId = queryInputObj.text
        } else {
            //醫師或科別時
            //分割字串
            const splitArray = queryInputObj.text ? queryInputObj.text.split(' ') : []
            if (queryInputObj.text && splitArray.length === 2) {
                if (queryInputObj.filterMode === RegsWeekModeEnum.Doctor) {
                    doctorNo = splitArray[0]
                } else {
                    divNo = splitArray[0]
                }
            }
        }
        //切割有空白的文字為陣列
        const splitArray = queryInputObj.text ? queryInputObj.text.split(' ') : []
        //搜尋文字有空白時
        if (queryInputObj.text && splitArray.length === 2) {
        }
        regsQueryStopClinicReg({
            //完成 or 未完成 or 全部
            compFlag: queryInputObj.contactStatusMode === "0" ? false : queryInputObj.contactStatusMode === "1" ? true : null,
            //起始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            //醫師
            doctorNo: doctorNo,
            //科別
            divNo: divNo,
            //病歷號
            patientId: patientId,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data.dataList ? res.data.dataList.map((item, index) => {
                    return {...item, key: index}
                }) : []
                //清空選擇
                setSelectedData(null)
                //清空聯絡內容
                resetContactContain()
                //清空簡訊
                setMessageText("")
                setFilteredDataList(dataWithKeys)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                //清空選擇
                setSelectedData(null)
                //清空聯絡內容
                resetContactContain()
                //清空簡訊
                setMessageText("")
                setFilteredDataList([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
                const msg = `搜尋失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 清空聯絡內容
     */
    const resetContactContain = () => {
        setIsAllClinic(false)
        setIsCompleteContact(false)
        //清空聯絡內容
        setContactContainText("")
        //清空退掛
        setCancelRegReason("-1")
    }

    const getAdvanceSearchStopClinicData = () => {
        const tmpArray =
            arrayIsEmpty(filteredDataList) ? [] :
                filteredDataList.map((item) => (
                    {
                        //key
                        key: item.key, //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //完成與否
                        contactCompFlag: item.contactCompFlag,
                        //門診日期
                        encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                        //病歷號
                        patientId: item.patientId,
                        //看診號
                        viewNo: item.viewNo,
                        //病人姓名
                        patientName: item.patientName,
                        //性別
                        genderName: item.genderName,
                        //出生日期
                        birthDate: time2String(item.birthDate, "YYYY-MM-DD"),
                        //手機

                        //聯絡電話

                        //醫師
                        doctorName: item.doctorName,
                        //科別
                        divName: item.divName,
                        //診室號
                        clinicNo: item.clinicNo,
                        //時段
                        apnName: item.apnName,
                        //停代診原因
                        stopClinicReasonName: item.stopClinicReasonName,
                        //代診醫師
                        locumDoctorName: item.locumDoctorName,
                        //退掛時間
                        cancelRegDatetime: stringIsEmpty(item.cancelRegDatetime) ? "" : time2String(item.cancelRegDatetime, "YYYY-MM-DD HH:mm:ss"),
                        //退掛原因
                        cancelRegReasonName: item.cancelRegReasonName,
                        //退掛人員
                        cancelRegUserName: item.cancelRegUserName
                        //簡訊
                    })
                )

        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["key"])
        setAdvSearchDataList(findedData)

        if (!objectIsEmpty(selectedData)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData) ? false : findedData.some(item => item.key === selectedData.key)
            if (!haveSelected) {
                //不存在的話將selected設為null
                setSelectedData(null)
            }
        }
    }

    /**
     * 取得選擇明細資料的聯絡紀錄
     * @returns
     */
    const getSelectedContactHistory = () => {
        const data = selectedData
        if (objectIsEmpty(data) || (stringIsEmpty(data.regId) && stringIsEmpty(data.firstregId))) {
            setContactHistoryList([])
            return
        }

        regsQueryStopClinicContactHistory({
            regId: !stringIsEmpty(data.regId) ? data.regId : "",
            firstregId: stringIsEmpty(data.regId) ? data.firstregId : ""
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setContactHistoryList(res.data)
            } else {
                setContactHistoryList([])
            }
        }))
    }

    //初始化
    useEffect(() => {
        //取得停代診基本資料
        getStopClinicBasicData()
        //取得所有醫生
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data || []
                //設定醫師選項
                setAllDoctorList(list)
                //預設filter醫師
                setDoctorOrDeptOptions(list)
            } else {
                setAllDoctorList([])
            }
        })
        //取得所有科別
        queryAllDivision().then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data || []
                setAllDivList(list)
            } else {
                setAllDivList([])
            }
        })
        //
    }, [])

    /**
     * 監聽advSearchText&filteredDataList的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchStopClinicData()
    }, [advSearchText, filteredDataList])

    /**
     * 監聽selectedData的變化
     */
    useEffect(() => {
        //設定是否有選擇資料的狀態
        const haveSelected = (!objectIsEmpty(selectedData))
        //取得選擇明細的聯絡紀錄
        getSelectedContactHistory()
        //設定是否已有選擇資料
        setHaveSelectedData(haveSelected)
    }, [selectedData])

    /**
     * 監聽科別或醫生代號輸入框變化
     */
    useEffect(() => {
        setFilterDoctorOrDeptOptions(getFilterOptions())
    }, [displayQueryInputData.text])

    /**
     * 停代診作業資料匯出按鈕點擊事件
     * @return {void}
     */
    const handleStopClinicExcel = () => {
        // 資料不存在
        if (arrayIsEmpty(filteredDataList)) {
            // 顯示錯誤訊息
            showToast({message: "資料不存在", type: ToastTypeEnum.Warning})
            return
        }
        // 取得要輸出的資料
        const data = filteredDataList.map(item => {
            return [
                item.contactCompFlag,
                stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD"),
                item.patientId,
                item.viewNo,
                item.patientName,
                item.genderName,
                stringIsEmpty(item.birthDate) ? '' : time2String(item.birthDate, "YYYY-MM-DD"),
                item.cellPhone, // 暫無資料－手機
                item.telPhone, // 暫無資料－聯絡電話
                item.doctorName,
                item.divName,
                item.clinicNo,
                item.apnName,
                stringIsEmpty(item.locumDoctorName) && item.stopClinicReasonName ? item.stopClinicReasonName : "",
                item.locumDoctorName ? item.locumDoctorName : "",
                stringIsEmpty(item.cancelRegDatetime) ? "" : time2String(item.cancelRegDatetime, "YYYY-MM-DD HH:mm:ss"),
                item.cancelRegReasonName ? item.cancelRegReasonName : "",
                item.cancelRegUserName ? item.cancelRegUserName : "",
                item.message, // 暫無資料－簡訊
            ]
        })

        // 取得標題
        const titles = [
            "完成",
            "門診日期",
            "病歷號",
            "看診號",
            "姓名",
            "性別",
            "出生日期",
            "手機",
            "聯絡電話",
            "醫師",
            "科別",
            "診室號",
            "時段",
            "停代診原因",
            "代診醫師",
            "退掛時間",
            "退掛原因",
            "退掛人員",
            "簡訊",
        ]

        // 取得Sheets
        const sheets = [{
            titles: titles,
            data: data
        }]

        // 取得日期時間
        const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')
        // 檔案名稱
        const fileName = `停代診作業報表_${current}`
        // 取得excel
        const excel = exportExcelAsBlob(sheets)
        // 下載檔案
        downloadFile(excel, fileName)
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-between mb-3">
                <div className="flex flex-row flex-wrap items-center">
                    <div className="date flex flex-row items-center justify-start space-x-2.5 mr-3.5">
                        <p className="">日期起迄：</p>
                        <div className="flex flex-row items-center">
                            <BaseInput
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={displayQueryInputData.dateStart}
                                type={InputTypeEnum.Date}
                                inputMode="date"
                                onChange={handleDateStartOnChange}/>
                            <p className="m-1">~</p>
                            <BaseInput
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={displayQueryInputData.dateEnd}
                                type={InputTypeEnum.Date}
                                inputMode="date"
                                onChange={handleDateEndOnChange}/>
                        </div>
                    </div>
                    <div className="flex flex-row mr-3.5 space-x-3.5 items-center">
                        <p>聯絡狀態：</p>
                        <input
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                            type="radio"
                            id="contact-unfinish"
                            checked={displayQueryInputData.contactStatusMode === "0"}
                            onChange={() => handleFilterContactStatusModeOnChange("0")}/>
                        <label style={{cursor: "pointer"}} htmlFor="contact-unfinish">未完成</label>
                        <input
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                            type="radio"
                            id="contact-finish"
                            onChange={() => handleFilterContactStatusModeOnChange("1")}
                            checked={displayQueryInputData.contactStatusMode === "1"}/>
                        <label style={{cursor: "pointer"}} htmlFor="contact-finish">已完成</label>
                        <input
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                            type="radio"
                            id="contact-all"
                            checked={displayQueryInputData.contactStatusMode === "2"}
                            onChange={() => handleFilterContactStatusModeOnChange("2")}/>
                        <label style={{cursor: "pointer"}} htmlFor="contact-all">全部</label>
                    </div>
                    {/*查詢別*/}
                    <div className="flex flex-row h-10 mr-3.5 space-x-3.5 items-center">
                        <p>查詢別：</p>
                        <div className="flex flex-row space-x-3 items-center">
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                type="radio"
                                id="doctor-mode"
                                checked={displayQueryInputData.filterMode === RegsWeekModeEnum.Doctor}
                                onChange={() => handleFilterModeOnChange(RegsWeekModeEnum.Doctor)}/>
                            <label style={{cursor: "pointer"}} htmlFor="doctor-mode">{t('general.doctor')}</label>
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                type="radio"
                                id="dept-mode"
                                onChange={() => handleFilterModeOnChange(RegsWeekModeEnum.Department)}
                                checked={displayQueryInputData.filterMode === RegsWeekModeEnum.Department}/>
                            <label style={{cursor: "pointer"}} htmlFor="dept-mode">{t('general.department')}</label>
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                type="radio"
                                id="patientId-mode"
                                checked={displayQueryInputData.filterMode === null}
                                onChange={() => handleFilterModeOnChange(null)}/>
                            <label style={{cursor: "pointer"}}
                                   htmlFor="patientId-mode">{t('Regs.general.medicalRecordNumber')}</label>
                        </div>

                        <div className="flex flex-row h-full items-center relative w-[142px]"
                             ref={dropdownDoctorOrDepartRef}>
                            <input
                                className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                                value={displayQueryInputData.text}
                                onChange={(e) => handleSearchTextOnChange(e)}
                                onFocus={() => handleToggleFilterOptions()}/>
                            {
                                showFilterOptions && displayQueryInputData.filterMode !== null && !arrayIsEmpty(filterDoctorOrDeptOptions) && (
                                    /*Option欄位下拉*/
                                    <div
                                        className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                            {
                                                filterDoctorOrDeptOptions.map((option, index) => (
                                                    <div
                                                        className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                        key={index} role="button"
                                                        onClick={() => handleFilterOptionOnClick(option)}
                                                    >
                                                        {getFilterOptionContent(option)}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-start">
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            onClick={handleSearchOnClick}
                        >
                            查詢
                        </button>
                    </div>


                </div>
                <div className="flex flex-row items-end justify-end mr-2 space-x-3.5">
                    <button
                        className="flex flex-row items-center justify-start h-10 bg-[#FAFAFA] text-[#000000] border-[2px] border-[#D4D4D8] rounded px-4"
                        //onClick={handleSignOnClick}
                    >
                        列印停代診明細
                    </button>
                    <button
                        className={`flex flex-row items-center justify-start h-10 bg-[#FAFAFA] ${haveSelectedData ? "text-[#000000]" : "text-[#d4d4d8]"} border-[2px] border-[#D4D4D8] rounded px-4`}
                        onClick={handlesetCancelStopClinicClick}
                        disabled={!haveSelectedData}
                    >
                        取消停代診
                    </button>
                </div>
            </div>
            {/*停代診明細 table*/}
            <div className="flex flex-row items-start justify-start">
                <div
                    className="p-2 bg-white min-h-[600px] max-h-[600px] w-full border-[1px] border-[#D4D4D8] rounded-md">
                    <div className="searchGroup h-10 flex flex-row items-center justify-between mb-4">
                        <div className="flex flex-row items-center">
                            <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                            <p className="text-[#2B6CB0] text-xl mr-3">停代診明細</p>
                            {/*進階搜尋*/}
                            <BaseInput
                                className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                placeholder={t('general.advancedSearch')}
                                inputMode={'search'}
                                value={advSearchText}
                                onChange={(e) => handleAdvanceSearchTextOnChange(e)}/>
                        </div>
                        {/*匯出按鈕*/}
                        <button className="flex flex-row justify-center items-center w-[112px] h-10"
                                onClick={handleStopClinicExcel}>
                            <div className="w-10 h-10">
                                <img className="w-full h-full" src={ExportIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">
                                {t('general.export')}
                            </p>
                        </button>
                    </div>
                    <ul className="tableList stopClinicist overflow-y-auto min-h-[480px] max-h-[480px]">
                        <li className="title">
                            <div>{t('general.done')}</div>
                            <div>{t('Regs.general.clinicDate')}</div>
                            <div>{t('Regs.general.medicalRecordNumber')}</div>
                            <div>{t('Regs.general.viewNumber')}</div>
                            <div>病人姓名</div>
                            <div>{t('general.gender.name')}</div>
                            <div>{t('general.dateOfBirth')}</div>
                            <div>手機</div>
                            <div>聯絡電話</div>
                            <div>{t('general.doctor')}</div>
                            <div>{t('general.department')}</div>
                            <div>診室號</div>
                            <div>{t("Regs.general.timeSlot")}</div>
                            <div>停代診原因</div>
                            <div>代診醫師</div>
                            <div>退掛時間</div>
                            <div>退掛原因</div>
                            <div>退掛人員</div>
                            <div>簡訊</div>
                        </li>
                        {
                            !arrayIsEmpty(advSearchDataList) && advSearchDataList.map((data, index) => (
                                <li
                                    key={index}
                                    className={`${checkDataIsSelected(data) ? "selected" : ""}`}
                                    onClick={() => handleDataOnClick(data)}>
                                    {/*完成*/}
                                    <div>{data.contactCompFlag}</div>
                                    {/*門診日期*/}
                                    <div>{time2String(data.encounterDate, "YYYY-MM-DD")}</div>
                                    {/*病歷號*/}
                                    <div>{data.patientId}</div>
                                    {/*看診號*/}
                                    <div>{data.viewNo}</div>
                                    {/*病人姓名*/}
                                    <div>{data.patientName}</div>
                                    {/*性別*/}
                                    <div>{data.genderName ? data.genderName : ""}</div>
                                    {/*出生日期*/}
                                    <div>{time2String(data.birthDate, "YYYY-MM-DD")}</div>
                                    {/*手機*/}
                                    <div></div>
                                    {/*聯絡電話*/}
                                    <div></div>
                                    {/*醫師*/}
                                    <div>{data.doctorName}</div>
                                    {/*科別*/}
                                    <div>{data.divName}</div>
                                    {/*診室號*/}
                                    <div>{data.clinicNo}</div>
                                    {/*時段*/}
                                    <div>{data.apnName}</div>
                                    {/*停代診原因 (沒有代班醫師才能顯示)*/}
                                    <div>{stringIsEmpty(data.locumDoctorName) && data.stopClinicReasonName ? data.stopClinicReasonName : ""}</div>
                                    {/*代診醫師*/}
                                    <div>{data.locumDoctorName ? data.locumDoctorName : ""}</div>
                                    {/*退掛時間*/}
                                    <div>{stringIsEmpty(data.cancelRegDatetime) ? "" : time2String(data.cancelRegDatetime, "YYYY-MM-DD HH:mm:ss")}</div>
                                    {/*退掛原因*/}
                                    <div>{data.cancelRegReasonName ? data.cancelRegReasonName : ""}</div>
                                    {/*退掛人員*/}
                                    <div>{data.cancelRegUserName ? data.cancelRegUserName : ""}</div>
                                    {/*簡訊*/}
                                    <div></div>
                                </li>
                            ))
                        }
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            currentPage={paginationProps.currentPage}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            onPageOnChange={(page) => onPaginationPageOnChange(page)}
                            onPrevPageOnClick={onPaginationPreviousOnClick}
                            onNextPageOnClick={onPaginationNextOnClick}
                            onPageSizeChange={onPaginationPageSizeOnChange}
                        />
                    </div>
                </div>
            </div>
            {/*簡訊*/}
            <div className="flex flex-row min-h-10">
                <div className="p-2 bg-white w-full border-[1px] border-[#D4D4D8] rounded-md">
                    <div className="flex flex-row items-center">
                        <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                        <p className="text-[#2B6CB0] text-xl mr-3">簡訊</p>
                        <p className="text-[#3F3F46] text-base mr-1">簡訊內容：</p>
                        {/*進階搜尋*/}
                        <BaseInput
                            className="w-[836px] h-10 pl-4 mr-2 border border-[#D4D4D8] rounded-md"
                            type="text"
                            value={messageText}
                            onChange={(e) => handleMessageTextOnChange(e)}/>
                        <button
                            className={`items-center h-10 bg-[#FAFAFA] ${haveSelectedData ? "text-[#000000]" : "text-[#d4d4d8]"} border-[2px] border-[#D4D4D8] rounded px-4`}
                            onClick={handleSendMessageOnClick}
                            disabled={!haveSelectedData}
                        >
                            發簡訊
                        </button>
                    </div>
                </div>
            </div>
            {/*聯絡*/}
            <div className="flex flex-row space-x-2.5 max-h-[400px]">
                {/*聯絡內容*/}
                <div
                    className="w-[45%] p-2 space-y-3 bg-white border-[1px] border-[#D4D4D8] rounded-md overflow-x-auto">
                    <div className="flex flex-x items-center">
                        <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                        <p className="text-[#2B6CB0] text-xl mr-5">聯絡內容</p>

                        <div className="flex mr-2 items-center">
                            <input
                                className="mr-2 w-[20px] h-[20px] bg-green-400 checked:bg-green-600 text-green-600"
                                type="checkbox"
                                checked={isAllClinic}
                                onChange={handleIsAllClinicOnChange}
                                style={{accentColor: '#38A169'}}/>
                            <label>診室全部</label>
                        </div>
                        <div className="h-10 w-[1px] mr-2 bg-[#111111]/15"/>
                        <div className="flex items-center">
                            <label className="mr-2">聯絡狀態：</label>
                            <input
                                className="mr-2 w-[20px] h-[20px] bg-green-400 checked:bg-green-600 text-green-600"
                                type="checkbox"
                                checked={isCompleteContact}
                                onChange={handleIsCompleteContactOnChange}
                                style={{accentColor: '#38A169'}}/>
                            <label>已完成</label>
                        </div>
                    </div>
                    {/*聯絡時間*/}
                    <div className="flex">
                        <p>聯絡時間：</p>
                        <BaseInput
                            className="w-[166px] h-10 px-4 mr-3 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            inputMode="date"
                            value={contactDate}
                            disable={true}
                            onChange={handleInputContactDateOnChange}
                        />
                        <BaseInput
                            className={`w-[150px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4 bg-[length:18px_18px] bg-[center_right_8px] bg-no-repeat`}
                            type={InputTypeEnum.Time}
                            value={contactTime}
                            disable={true}
                            onChange={handleInputContactTimeOnChange}
                        />
                    </div>
                    {/*聯絡內容*/}
                    <div className="flex flex-row h-[164px]">
                        <p>聯絡內容：</p>
                        <textarea
                            className="flex-1 p-3 border-[1px] border-[#D4D4D8] rounded-md resize-none"
                            value={contactContainText}
                            onChange={handleContactContainTextOnChange}>
                        </textarea>
                    </div>
                    {/*退掛原因*/}
                    <div className="flex flex-row">
                        <p>退掛原因：</p>
                        <select
                            className="w-[220px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={cancelRegReason}
                            onChange={(e) => handleCancelRegReasonOnChange(e)}
                        >
                            <option value="-1"></option>
                            {
                                !objectIsEmpty(baseData) && baseData.cancelRegReasonList && baseData.cancelRegReasonList.map((reason, index) => (
                                    <option
                                        key={index}
                                        value={reason.justnameNo}
                                    >
                                        {reason.justnameName}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="justify-end flex">
                        <DefaultButton
                            type="primary"
                            text={t('general.saveFile')}
                            disable={!haveSelectedData}
                            onClickFn={() => handleSaveContactDetailsOnClick()}
                        />
                    </div>
                </div>
                {/*片語*/}
                <div className="flex-1 p-2 space-y-3 bg-white border-[1px] border-[#D4D4D8] rounded-md overflow-x-auto">
                    <div className="flex items-center h-10">
                        <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                        <p className="text-[#2B6CB0] text-xl mr-3">片語</p>
                        <SearchInput
                            className="bg-white h-10 w-full px-2 text-black rounded-[6px] border-2 py-1 border-gray-300 pl-8"
                            value={searchPhraseText}
                            onChange={(e) => handleSearchPhraseTextOnChange(e)}/>
                    </div>
                    <div className="flex flex-wrap">
                        {
                            getFuzzySearchPhraseList().map((data) => (

                                <button
                                    key={data.no}
                                    className={`h-10 border-[2px] w-auto border-[#D4D4D8] ${!haveSelectedData ? "text-[#D4D4D8]" : ""} px-3 py-2 mr-2 mb-2 rounded-[4px]`}
                                    onClick={() => handlePhraseOnClick(data.name)}>
                                    {data.name}
                                </button>
                            ))
                        }
                    </div>
                </div>
                {/*聯絡紀錄*/}
                <div className="w-[33%] p-2 bg-white border-[1px] border-[#D4D4D8] rounded-md overflow-x-auto">
                    <div className="flex flex-row justify-between">
                        <div className="flex items-center">
                            <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                            <p className="text-[#2B6CB0] text-xl mr-3">聯絡紀錄</p>
                        </div>
                        {/*輸出按鈕*/}
                        <button className="flex flex-row items-center">
                            <div className="w-10 h-10">
                                <img
                                    src={ExportIcon}
                                    alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">
                                {t('general.export')}
                            </p>
                        </button>
                    </div>

                    <ul className="">
                        {
                            !arrayIsEmpty(contactHistoryList) && contactHistoryList.map((record, index) => (
                                <div
                                    key={index}
                                    className="minh-[58px] flex rounded-md border-b border-[#D4D4D8]">
                                    <div className="w-[64px] flex justify-center items-center">
                                        <img
                                            src={DeleteIcon}
                                            alt="delIcon"
                                            className="cursor-pointer"
                                            onClick={() => handleDeleteContactHistoryOnClick(record.stopRegId)}/>
                                    </div>
                                    <div>
                                        <div className="flex text-[#18181B] text-base">
                                            <p>{time2String(record.contactDatetime, "YYYY/MM/DD HH:mm")}</p>
                                            <p>{record.contactStatus ? record.contactStatus : ""}</p>
                                        </div>
                                        <div className="flex flex-wrap">
                                            <div className="flex font-normal text-[#A1A1AA] text-base leading-5 text-left">
                                                <p>建立日期時間/人員：</p>
                                            </div>
                                            <div className="flex font-normal text-[#A1A1AA] text-base leading-5 text-left">
                                                <p>{time2String(record.createDatetime, "YYYY/MM/DD HH:mm")}</p>
                                                <p>{record.contactStatus ? record.contactStatus : ""}</p>
                                                <p>/</p>
                                                <p>{record.createUserName ? record.createUserName : ""}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }
                    </ul>
                </div>
            </div>
            {
                <CancelStopClinicPromptPopup
                    show={showCancelStopClinicPrompt}
                    data={selectedData}
                    closePopupButtonOnClick={closeCancelStopClinicPromptPopup}
                    onConfirmClick={confirmCancelStopClinic}/>
            }
            {
                <DeleteContactHistoryPromptPopup
                    show={showDelContactHistoryPrompt}
                    closePopupButtonOnClick={handleCloseDelContactHistoryPrompt}
                    handleDeleteOnClick={doDelContactHistory}/>
            }
        </div>
    )
}

export default StopClinic