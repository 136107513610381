import React from 'react'
import MOHWgovFeedback from '../../components/RSIS/MOHWgovFeedback/MOHWgovFeedback'
import { PopupProvider } from '../../components/RSIS/PopupProvider'

/**
 * 衛福部案號回饋
 * @returns {JSX.Element}
 */
function MOHWgovFeedbackLayout() {
  return (
    <PopupProvider>
      <MOHWgovFeedback />
    </PopupProvider>
  )
}

export default MOHWgovFeedbackLayout