import React, {useState} from "react";
import {DefaultButton} from "../../components/Button/DefaultButton";
import InformationDataGrid from "../../components/CDSS/InformationDataGrid";
import RulePopup from "../../components/CDSS/RulePopup";
import { PopupModeEnum, ApiErrorStatusEnum, stringIsEmpty, objectIsEmpty, ToastTypeEnum, arrayIsEmpty } from "edah_utils/dist"
import { queryByCdssId, delCdssId } from "../../api/v1/CDSS";
import DeleteRuleMessagePopup from "../../components/CDSS/DeleteRuleMessagePopup";
import ToastMessage from "../../components/Toasts/ToastMessage";

const InformationMaintain = () => {
    //fake data
    const informationDataArray = [
        {
            "createDatetime": "2024-04-25 10:24:41",
            "createUser": "edah",
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": 0,
            "cdssId": "RULE9996",
            "category": "系統測試",
            "purpose": "測試 KIE-Server 是否正常",
            "description": "KIE-Server 部署一個 container , 測試呼叫 KIE-Server, kie.containerId=rule9996_1.0.0-SNAPSHOT",
            "ruleSource": "測試",
            "serverType": "KIE-Server",
            "effectiveFlag": false,
            "parameterConstraints": "所有診斷碼",
            "returnResult": "判別是否為小孩"
        }
    ];
    // 是否顯示Popup
    const [showPopup, setShowPopup] = useState(false);
    // Popup模式
    const [popupMode, setPopupMode] = useState(null);
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null);
    //Query 資料
    const [queryData, setQuertData] = useState(null);
    //搜尋文字
    const [searchText, setSearchText] = useState("");
    // 是否顯示刪除的彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    //刪除的CDSSID
    const [deleteDataId, setDeleteDataId] =useState(null);
    /**
     * Toast資料
     */
    const [toast, setToast] = useState(null)

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {String}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode);
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
            }
        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setShowPopup(true);
    }

    /**
     * 顯示刪除的彈窗
     * @param data {Object} 要刪除的Rule
     */
    const handleShowDeletePopup = (data) => {

        if(!objectIsEmpty(data)) {
            setDeleteDataId(data.cdssId);
        }
        //顯示刪除的彈窗
        setShowDeletePopup(true);
    }

    /**
     * 關閉Popup
     * @return void
     */
    const handleClosePopup = () => setShowPopup(false)

    /**
     * 取得CDSS 規則資料
     */
    const handleSuccessCreateRule = () => {
        // 關閉Popup
        setShowPopup(false)

        setToast({
            show:true,
            type:ToastTypeEnum.Success,
            message: popupMode === PopupModeEnum.Modify ? "編輯成功":"新增成功"
        })
    }

    /**
     * 點擊搜尋規則事件
     */
    const handleQuerySearchOnClick = () => {
        queryByCdssId({cdssId:searchText}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQuertData(res.data)

                if(arrayIsEmpty(res.data)) {
                    setToast({
                        show:true,
                        type:ToastTypeEnum.Warning,
                        message: "沒有找到符合的資料"
                    })
                }
            }
            else {
                setQuertData(null);
                setToast({
                    show:true,
                    type:ToastTypeEnum.Error,
                    message: res.msg
                })
            }
        })
    }

    /**
     * 搜尋文字改變時
     * @param e {Object}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 刪除視窗關閉事件
     * @return void
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 刪除事件
     */
    const handleDeleteOnClick = () => {

        if(!stringIsEmpty(deleteDataId))
        {
            delCdssId({
                cdssId: deleteDataId
            }).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 透過API重新取資料
                    setToast({
                        show:true,
                        type:ToastTypeEnum.Success,
                        message: "刪除成功"
                    })

                    handleQuerySearchOnClick()
                }
                else {
                    setToast({
                        show:true,
                        type:ToastTypeEnum.Error,
                        message: res.msg
                    })
                }
            })
            console.log(deleteDataId);
        }

        setShowDeletePopup(false);

        setDeleteDataId(null);
    }

    return (
        <div 
            className="w-full h-[calc(100vh-150px)] pl-[15px] pr-[6px] pt-[25px] overflow-x-auto"
        >
            <div 
                className="w-full h-full min-w-[1366px] rounded-[6px] border border-[#D4D4D8]"
            >
                {/**上方區塊 */}
                <div className="flex flex-row justify-between px-[16px] py-[8px] space-x-6 items-center w-full">
                    <div className="flex flex-row items-center space-x-[8px]">
                        <label className="text-[16px]">查詢條件</label>
                        <select
                            className="w-[144px] h-10 border bg-white rounded-[6px] border-[#D4D4D8] px-[12px]"
                        >
                            <option value="規則代碼">規則代碼</option>
                        </select>
                        <input
                            className='w-[144px] h-10 border bg-white rounded-[6px] px-[12px] border-[#D4D4D8]'
                            type='text'
                            placeholder="輸入查詢值"
                            onChange={(e) => handleSearchTextOnChange(e)}
                        />
                        <DefaultButton type="primary" onClickFn={handleQuerySearchOnClick} text="查詢"/>
                    </div>
                </div>

                {/**分隔線 */}
                <div className="w-full h-[1px] mt-[5px] mb-[8px] bg-[#D4D4D8]"/>

                {/**表格區 */}
                <div className="flex w-full h-[calc(100vh-300px)] overflow-x-auto">
                    <InformationDataGrid
                        arrayData={queryData}
                        showPopup={handleShowPopup}
                        showDeletePopup={handleShowDeletePopup}
                    />
                </div>
            </div>
            {/*規則Popup*/}
            {
                showPopup && (
                    <RulePopup
                        mode={popupMode}
                        data={selectedData}
                        closePopup={handleClosePopup}
                        handleSuccessCreateRule={handleSuccessCreateRule}
                    />
                )
            }
            {
                //刪除彈窗
                <DeleteRuleMessagePopup
                    closePopupButtonOnClick={handleDeletePopupOnClose}
                    show={showDeletePopup}
                    handleDeleteOnClick={handleDeleteOnClick}
                />
            }
            {
                toast && 
                <ToastMessage 
                    message={toast.message}
                    type={toast.type}
                    show={toast.show}
                    onClickClose={() => setToast(null)}/>
            }
        </div>
    )
}

export default InformationMaintain;