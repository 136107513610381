import React, {useEffect, useState} from "react";
import {use} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import addIcon from "../../assets/images/icons/add_circle.svg";
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg"
import exportIcon from "../../assets/images/SystemMonitoring/export.svg";
import {ReactComponent as EditBtn} from "../../assets/images/ICCard/edit_square.svg";
import {ReactComponent as CopyBtn} from "../../assets/images/SystemMonitoring/copy.svg";
import {ReactComponent as DeleteBtn} from "../../assets/images/ICCard/delete.svg";
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist"
import {BasePopup} from "../../components/Popup/BasePopup";

/**
 * 居家評估作業＿收案清單=
 * @return {JSX.Element}
 */
const HomeAssessmentOperations = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    // 選擇個案
    const [showSelectPopup, setShowSelectPopup] = useState(false)

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '女',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '',
            code: '0987',
            level: 'S2',
            rn: '李ＯＯ'
        },
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(C)',
            code: '5D56B',
            level: 'S3',
            rn: '李ＯＯ'
        },
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(A)',
            code: '5D56BAA',
            level: 'S3',
            rn: '李ＯＯ'
        }
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)


    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/*收案人員*/}
                    <p className="mr-1">收案人員:</p>
                    <select className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option key="" value="0">全部</option>
                    </select>

                    {/*家字號*/}
                    <p className="mr-1">家字號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />

                    {/*病歷號*/}
                    <p className="mr-1">病歷號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />

                    {/*級別*/}
                    <p className="mr-1">級別:</p>
                    <select className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option key="" value="0">全部</option>
                        <option key="" value="1">S2</option>
                        <option key="" value="1">S3</option>
                    </select>
                </div>

                {/*查詢送出按鈕*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        查詢
                    </button>
                </div>
            </div>

            {/*新增服務註冊*/}
            <div className="w-full h-[60px] py-[10px]">
                <button className="flex flex-row justify-center items-center w-[112px] h-10"
                >
                    <div className="p-[3.33px] w-10 h-10">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder="進階搜尋"
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList HAOTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div><Check/></div>
                            <div></div>
                            <div></div>
                            <div>家字號</div>
                            <div>病歷號</div>
                            <div>個案姓名</div>
                            <div>性別</div>
                            <div>收案人員</div>
                            <div>收案日期</div>
                            <div>個案動態</div>
                            <div>床號</div>
                            <div>級別</div>
                            <div>護理師</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(dataObject)) ? getFilterData(dataObject).map((item, index) =>
                            <li key={index}>
                                {/*checkBox*/}
                                <div>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                                           type="checkbox"
                                           id={`r${index}`} style={{accentColor: "#38A169"}}/>
                                </div>
                                {/*操作*/}
                                <div>
                                    <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3">
                                        <EditBtn className="mr-1"/>編輯
                                    </button>
                                    <button className="flex flex-row items-center justify-start text-[#2B6CB0]">
                                        <DeleteBtn className="mr-1"/>刪除
                                    </button>
                                </div>
                                {/*表單評估*/}
                                <div>
                                    <button
                                        className="flex items-center justify-center w-full h-10 px-2 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]">
                                        表單評估
                                    </button>
                                </div>
                                {/*家字號*/}
                                <div>{item.fsn}</div>
                                {/*病歷號*/}
                                <div>{item.mrn}</div>
                                {/*個案姓名*/}
                                <div>{item.name}</div>
                                {/*性別*/}
                                <div>{item.gender}</div>
                                {/*收案人員*/}
                                <div>{item.contact}</div>
                                {/*收案日期*/}
                                <div>{item.date}</div>
                                {/*個案動態*/}
                                <div>{item.status}</div>
                                {/*床號*/}
                                <div>{item.code}</div>
                                {/*級別*/}
                                <div>{item.levelType}</div>
                                {/*護理師*/}
                                <div>{item.rn}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

            {/*選擇個案Popup*/}
            {showSelectPopup && (
                <BasePopup title={'選擇個案級別'}/>
            )}
        </div>
    )
}
export default HomeAssessmentOperations