import React from "react"

/**
 * IC卡資料上傳資料查詢-回傳結果
 * @param {boolean} show
 * @return {JSX.Element}
 */
export const ReturnResultTab = ({show}) => {
    return show ?
        <div className="w-[835px] h-[754px] p-4 bg-[#F4F4F5] rounded-[6px]">
            <p className="text-left">
                衛生福利部中央健康保險署特約醫事服務機構－健保IC卡資料上傳作業檢核結果明細表
                <br/><br/>
                特約醫事服務機構： xxxxxxx 義大翳院 <br/>
                --- -- -- -- -- ------- -- -- -- -- ---- -- -- -- ------ -- -- - -- -- ------ -- --
                -- -- ---- -- -- -- <br/>
                上傳申報資訊： 上傳日期／時間［檔案大小］［實際接收筆數］<br/>
                ----- -- -- --------- -- -- -- -- ---- -- -- -- ------ -- -- - -- -- ------ -- -- --
                ------ -- -- -- <br/>
                1111209180102(1207]7 (5220 0 ] <br/>
                一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一 <br/>
                檢核結果： 有效明細［有效醫令］［無效明細］<br/>
                一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一一 <br/>
                6 Y 1 (1576 ][1q4 ] <br/>
                ===================================================== <br/>
                <br/>
                第(1]筆 <br/>
                H00=(1] H01=(A] <br/>
                M01=[001000004763] M02=[000084883416] <br/> M03= [D100236871)
                M04=[0391211] M05=[35070336) <br/> M06=[E102307294]
                M07=[AG] M08=[NULL] <br/> M09=[NULL]
                M10=[NULL] M11=[1111116081011] <br/> M12=[1]
                M13=[NULL] M15=[5G6XHHD100LG'Pl L3MZNB <br/>
            </p>
        </div> : null
}
