import ScheduleAppointment from "./ScheduleAppointment.jsx"
import {t} from "i18next"

/**
 * @param {Array} list 預約列表
 * @param {Number} timeslot 時段
 * @param {Number} week 週數
 * @param {Object} baseData 基本資料
 * @param {Boolean} isAddAppointment 是否顯示新增問診
 * @param {Function} onAddAppointment 新增問診函式
 * @param showPopup
 */
const ScheduleTimeBox = ({
                             list,
                             timeslot,
                             week,
                             baseData,
                             isAddAppointment,
                             onAddAppointment,
                             showPopup
                         }) => {
    /**
     * 顯示編輯視窗
     * @param e {Event} 事件
     * @return {void}
     */
    const handleBlockOnContextMenu = (e) => {
        //防止右鍵點擊時觸發右鍵選單
        e.preventDefault()
        //右鍵點擊時
        if (e.nativeEvent.button === 2) {
            //透過父層函式新增問診
            onAddAppointment(week, timeslot)
        }
    }

    /**
     * 點擊新增問診時
     */
    const handleNewAppointmentOnClick = () => {
        showPopup({
            week: week + 1,
            timeslot: timeslot
        })
    }

    /**
     * 當滑鼠右鍵點擊新問診時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNewAppointmentOnContextMenu = (e) => e.preventDefault()

    return (
        <div
            className="w-full min-w-[220px] min-h-[200px] border-2 bg-white rounded-lg border-gray-300 p-2 flex flex-col items-center py-3 relative scrollbar-w-2 space-y-1.5 group scrollbar scrollbar-track-lightPink scrollbar-track-h-10 scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrolbar-rounded-full overflow-auto"
            onContextMenu={(e) => handleBlockOnContextMenu(e)}>
            <div
                className="flex flex-col w-full h-full space-y-1.5 group scrollbar-thin scrollbar-h-4 scrollbar-track-lightPink scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrolbar-thumb-rounded-full overflow-auto">
                {
                    list.map((appointment, index) => (
                            <ScheduleAppointment
                                key={index}
                                record={appointment}
                                baseData={baseData}
                                showPopup={showPopup}
                            />
                        )
                    )
                }
                {
                    isAddAppointment && (
                        <div className="bg-black w-3/4 mx-auto text-white rounded-lg"
                             onContextMenu={(e) => handleNewAppointmentOnContextMenu(e)}
                             onClick={handleNewAppointmentOnClick}>
                            {t('page.MonthMaintenance.NewAppointment')}
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default ScheduleTimeBox
