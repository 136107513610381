import { DefaultButton } from "../../Button/DefaultButton";
import React, {useEffect, useState} from "react"
import {t} from "i18next"

/**
 * 提示彈出窗
 * @param {String} promptText 提示字串
 * @param {Function} closePopupButtonOnClick 關閉事件
 * @param show {Boolean} 是否顯示彈窗
 * @returns 
 */
const PromptPopup = ({promptText, 
    closePopupButtonOnClick, 
    show = false
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <div
            className="fixed top-[-16px] left-0 flex items-center justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10">
            <div className="w-[500px] bg-white rounded-[6px]">
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">注意</h3>
                </div>
                {/*Content*/}
                <div className="mb-4 px-6 space-y-2">
                    <div className="text-left">
                        {promptText}
                    </div>

                    <div className="text-right">
                        <DefaultButton 
                            type="primary" 
                            text={t('general.confirm')} 
                            onClickFn={closePopupButtonOnClick}/>
                    </div>
                    
                </div>
            </div>
        </div> :
        <></>
    )
}

export default PromptPopup;