import React, { useEffect, useRef, useState } from 'react'
import searchIcon from '../../assets/images/search-interface-symbol.png'
import useOutsideClick from '../../hooks/useOutsideClick'

/**
 * 科別模糊查詢選單
 * @param {function} props.onSelect 選擇科別callback
 * @param {array} props.divisionList 科別清單
 * @param {object} props.value 科別資料 { divNo:string, divName:string }
 * @returns {JSX.Element}
 */
const DivisionDatalist = ({
    divisionList,
    onSelect,
    value = { divNo: '', divName: '' },
    clearValue = () => {},
}) => {
    // 關鍵字
    const [keyword, setKeyword] = useState(`${value.divNo} ${value.divName}`)
    // 科別清單
    const [filteredDivisionList, setFilteredDivisionList] = useState([])
    // 是否顯示科別選單
    const [isShowDivisionDropdown, setIsShowDivisionDropdown] = useState(false)
    // 選擇的科別
    const [selectedDivision, setSelectedDivision] = useState(value)
    // 選單ref
    const dropdownRef = useRef(null)

    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            const selectedDivisionString = selectedDivision
                ? `${selectedDivision.divNo} ${selectedDivision.divName}`
                : null

            if (keyword.trim() !== selectedDivisionString) {
                setKeyword('')
                onSelect(null)
            }
            setIsShowDivisionDropdown(false)
        },
    })

    /**
     * focus input to show dropdown
     * @return {void}
     */
    const handleKeywordOnFocus = () => {
        setIsShowDivisionDropdown(true)
        setFilteredDivisionList(divisionList)
    }

    /**
     * 選擇科別
     * @param {object} item 單筆科別資料
     * @return {void}
     */
    const handleDivisionOnClick = (item) => {
        setKeyword(`${item.divNo} ${item.divName}`)
        onSelect(item)
        setSelectedDivision(item)
        setIsShowDivisionDropdown(false)
    }

    /**
     * 輸入科別搜尋
     * @param {string} value keyword
     * @return {void}
     */
    const updateKeyword = (value) => {
        setKeyword(value)
        setSelectedDivision(null)
    }

    /**
     * 輸入框顯示科別字串(divNo + divName)
     */
    useEffect(() => {
        setKeyword(`${value.divNo} ${value.divName}`)
        const defaultDivision = value.divNo !== '' ? value : null
        setSelectedDivision(defaultDivision)
    }, [value])

    /**
     * 模糊搜尋科別
     */
    useEffect(() => {
        const filteredList = divisionList.filter((item) => {
            const divString = `${item.divNo} ${item.divName}`
            return divString.includes(keyword) || divString.includes(keyword)
        })
        setFilteredDivisionList(filteredList)
        // 當關鍵字清空時，則清空原選定科別的值
        if (!keyword) {
            clearValue()
        }
    }, [keyword])

    return (
        <div className="w-full text-[#18181B]">
            <div
                ref={dropdownRef}
                className="w-full flex flex-row space-x-2 items-center relative"
            >
                <input
                    className="h-10 bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8]"
                    value={keyword}
                    onChange={(e) => updateKeyword(e.target.value)}
                    onFocus={handleKeywordOnFocus}
                />
                <img
                    className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                    alt="search-icon"
                    src={searchIcon}
                    role="button"
                />
                {isShowDivisionDropdown && (
                    <div className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                        {/*科別下拉*/}
                        <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                            {filteredDivisionList.map((item, index) => (
                                <div
                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                    role="button"
                                    key={index}
                                    onClick={() => handleDivisionOnClick(item)}
                                >
                                    <p>{`${item.divNo}`}</p>
                                    <p>{`${item.divName}`}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DivisionDatalist
