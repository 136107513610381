import React, {useEffect, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup'
import {formatTime, isOwnerUser, Title} from '../utils';
import ChangeRecordQuery from './QueryChangeRecord';
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    InputTypeEnum,
    stringIsEmpty,
    time2String,
    ToastTypeEnum
} from 'edah_utils/dist';
import {rsisCancelMaster, rsisQueryCodeValue} from "../../../api/v1/RSIS";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {usePopup} from "../PopupProvider";
import {getCurrentFormattedDateTime} from "../../CriticalValueManagement/utils";

/**
 * 報備支援申請註銷作業-modal
 * @param {Object} data 資料
 * @param {boolean} isOwnUser 是否為承辦人員
 * @param {function} handleOnClose 關閉事件
 * @param {function} handleOnUpdate 更新事件
 * @returns {JSX.Element}
 */
function LogOffModal({data, isOwnUser, handleOnClose, handleOnUpdate}) {
    // 員工/醫師代碼
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo ? data.supportUserNo : '')
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName ? data.supportUserName : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode : '')
    // 支援目的門柱
    const [subPurposeCode, setSubPurposeCode] = useState(data.purposeCodeExt ? data.purposeCodeExt : '')
    // 變更原因
    const [modifyReason, setModifyReason] = useState(data.supportModifyReasonCode ? data.supportModifyReasonCode : '')
    // 變更原因說明
    const [reasonDoc, setReasonDoc] = useState(data.supportModifyReasonDoc ? data.supportModifyReasonDoc : '')
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 執登機構編號
    const [hospNo, setHospNo] = useState('')
    // 執登機構名稱
    const [hospName, setHospName] = useState('')

    // 支援目的選項
    const [purposeOption, setPurposeOption] = useState([])
    // 支援目的門柱選項
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 變更原因選項
    const [changeReason, setChangeReason] = useState([])


    // 使用上下文
    const {
        showChangeRecordModal,
        setShowChangeRecordModal,
        showToast
    } = usePopup()

    /**
     * 提交註銷表單
     * @return {void}
     */
    const handleOnLogOffSubmit = () => {
        // 註銷內容
        logOffContent()
    }

    /**
     * 取得醫師執登資料
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDivNo(data.divNo)
                setDivName(data.divName)
                setLicenceId(data.licenceId)
                setLicenceKind(data.licenceKind)
                setLicenceKindName(data.licenceKindName)
                setHospNo(data.hospNo)
                setHospName(data.hospName)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        const purposeCodes = purposeCode.split(',').filter(Boolean)
        const subPurpose = () => {
            if (subPurposeCode === '1') {
                return '門診'
            } else if (subPurposeCode === '2') {
                return '值班'
            } else if (subPurposeCode === '3') {
                return '會診'
            }
        }
        const purposeTexts = purposeCodes.map(codeNo => {
            const purpose = purposeOption.find(item => item.codeNo === codeNo)
            if (purpose) {
                if (codeNo === '10') {
                    return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                }
                return purpose.codeValue1;
            }
            return null;
        }).filter(Boolean)

        return purposeTexts.map((text, index) => (
            <p key={index} className='flex justify-start pl-4 py-[9px]'>{text}</p>
        ))
    }


    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setPurposeOption(data.filter(item => item.codeType === 'PURPOSE'))
                //取得變更原因清單
                const changeReasonList = isOwnUser
                    ? data.filter(item => item.codeType === 'CHANGE') // 若為承辦人員則取得所有變更原因
                    : data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N') // 若不為承辦人員則取得非承辦人員變更原因
                // 設定變更原因
                setChangeReason(changeReasonList)
                // 變更原因不為空，且變更原因為空時，將第一筆設定給變更原因
                if (!arrayIsEmpty(changeReasonList) && data.supportModifyReasonCode === '') {
                    //將第一筆設定給變更原因
                    setModifyReason(changeReasonList[0].codeNo)
                }
            } else {
                setPurposeOption([])
                setSubPurposeOptions([])
                setChangeReason([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得送出資料
     * @return {void}
     */
    const getSendData = () => {
        return {
            supportNo: data.supportNo,
            itemVersion: data.itemVersion,
            supportUserNo: data.supportUserNo,
            supportUserName: data.supportUserName,
            supportOrgNo: data.supportOrgNo,
            supportOrgName: data.supportOrgName,
            supportDivNo: data.supportDivNo,
            supportDivName: data.supportDivName,
            purposeCode: data.purposeCode,
            purposeCodeExt: data.purposeCodeExt,
            itemStartDate: data.itemStartDate,
            itemEndDate: data.itemEndDate,
            itemStartTime: data.itemStartTime,
            itemEndTime: data.itemEndTime,
            supportProperties: data.supportProperties,
            executeContent: data.executeContent,
            memo: data.memo,
            agentUserNo: data.agentUserNo,
            agentUserName: data.agentUserName,
            stepStatus: data.stepStatus,
            supportModifyStatus: data.supportModifyStatus,
            supportModifyDatetime: getCurrentFormattedDateTime(),
            supportModifyReasonCode: modifyReason,
            supportModifyReasonDoc: reasonDoc,
            sendFileName: data.sendFileName,
            sendCaseNo: data.sendCaseNo,
            sendDatetime: data.sendDatetime,
            applyType: data.applyType,
            zone: data.zone,
            effFlag: data.effFlag,
            ownerDeptNo: data.ownerDeptNo,
            changeFieldsDoc: data.changeFieldsDoc,
            confirmUserNo: data.confirmUserNo,
            confirmDatetime: data.confirmDatetime
        }
    }

    /**
     * 註銷內容
     * @return {void}
     */
    const logOffContent = () => {
        const sendData = getSendData()

        // 檢查變更原因是否為其他，若是則檢查是否有輸入其他說明
        if(sendData.supportModifyReasonCode === 'other' && sendData.supportModifyReasonDoc === '') {
            showToast({message: '請輸入其他原因之說明', type: ToastTypeEnum.Warning})
            return
        }

        rsisCancelMaster([sendData]).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                handleOnUpdate()
                handleOnClose()
                showToast({message: '註銷成功', type: ToastTypeEnum.Success})
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

// 第一次執行時
    useEffect(() => {
        // 取得執登資料
        getDoctorData(supportUserNo.split(' ')[0])
        // 取得支援目的選項
        getPurposeOptions()
    }, []);

    return (
        <BasePopup
            title='報備支援申請註銷作業'
            width='1736px'
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='flex flex-col px-6 gap-4 mb-6'>
                    {/* 第一列員工搜尋、查詢變更紀錄、狀態 */}
                    <div className='flex flex-row'>
                        <Title children='員工/醫師代碼：'/>
                        <input
                            className={'bg-transparent'}
                            type={InputTypeEnum.Text}
                            title='員工/醫師代碼：'
                            value={`${supportUserNo} ${supportUserName}`}
                            disabled={true}
                        />
                    </div>
                    {/* 第二列資訊 */}
                    <div className='flex flex-row'>
                        {/* 執登機構 */}
                        <Title children='執登機構：'/>
                        <input
                            className={'bg-transparent'}
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                            disabled={true}
                        />
                        {/* 職類別 */}
                        <Title children='職類別：'/>
                        <input
                            className={'bg-transparent'}
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                            disabled={true}
                        />
                        {/* 執登科別 */}
                        <Title children='執登科別：'/>
                        <input
                            className={'bg-transparent'}
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                            disabled={true}
                        />
                        {/* 證書字號 */}
                        <Title children='證書字號：'/>
                        <input
                            className={'bg-transparent'}
                            type={InputTypeEnum.Text}
                            value={!stringIsEmpty(licenceId) ? licenceId : ''}
                            disabled={true}
                        />
                    </div>
                    {/* 註銷 */}
                    <div className='flex flex-row gap-4'>
                        <div className='w-full min-w-[820px] min-h-[421px] bg-white shadow rounded border-2 px-4'>
                            <div className="flex flex-row w-full py-2 justify-between">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    註銷
                                </h3>
                                <button
                                    className='w-[64px] ml-4 flex items-center justify-center font-base h-10 border-[2px] rounded-[6px] text-primary border-primary hover:cursor-pointer'
                                    onClick={handleOnLogOffSubmit}
                                >
                                    註銷
                                </button>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>申請單號</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{data.supportNo}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>機構代碼/名稱</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{`${data.supportOrgNo} ${data.supportOrgName}`}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>支援科別</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{`${data.supportDivNo} ${data.supportDivName}`}</p>
                                </div>
                                <div className='flex flex-row items-start'>
                                    <p className='flex justify-end items-start w-[160px] pr-4 py-[9px]'>支援目的</p>
                                    <div className={'flex flex-col'}>
                                        {renderPurposeOptions()}
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>支援日期區間</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{`${time2String(data.itemStartDate, 'YYYY-MM-DD')}~${time2String(data.itemEndDate, 'YYYY-MM-DD')} ${formatTime(data.itemStartTime)}~${formatTime(data.itemEndTime)}`}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>備註</p>
                                    <p className='flex justify-start pl-4 py-[9px]'>{data.memo}</p>
                                </div>
                                <div className='flex flex-row items-center mb-4'>
                                    <p className='flex justify-end w-[160px] pr-4 py-[9px]'>變更原因</p>
                                    <div className='flex flex-row items-center px-3 gap-2'>
                                        <select
                                            className='w-[186px] h-10 bg-white text-black rounded-[6px] border border-gray-30 pl-4'
                                            value={modifyReason}
                                            onChange={(e) => setModifyReason(e.target.value)}
                                        >
                                            {changeReason.map((item, index) =>
                                                <option key={index} value={item.codeNo}>{item.codeValue1}</option>
                                            )}
                                        </select>
                                        <input
                                            className='w-[596px] h-[42px] bg-white text-black rounded-[6px] border border-gray-300 px-4'
                                            type={InputTypeEnum.Text}
                                            value={reasonDoc}
                                            onChange={(e) => setReasonDoc(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 查詢變更紀錄 */}
                    {showChangeRecordModal &&
                        <ChangeRecordQuery handleOnClose={() => setShowChangeRecordModal(false)}/>}
                </div>
            }
        />
    )
}

export default LogOffModal
