import InfoCard from "./InfoCard"
import {t} from "i18next"

/**
 * 病人排檢資訊
 * @param patientData {Array} 病人資訊
 * @param showData {Boolean} 是否顯示資料
 */
const PatientInfo = ({patientData, showData}) => {
    return (
        <InfoCard titles="病人排檢資訊">
            <ul className="tableList appointmentExamList mt-3 overflow-y-auto overflow-x-auto h-[300px] max-h-[300px]">
                <li className="title text-lg text-[#18181b]">
                    <div className="p-1">{t('Regs.general.campus')}</div>
                    <div className="p-1">排檢日期</div>
                    <div className="p-1">{t('general.doctor')}</div>
                    <div className="p-1">{t('general.department')}</div>
                    <div className="p-1">排檢項目</div>
                </li>

                {
                    showData && patientData && patientData.length > 0 && patientData.map((patient, index) => 
                        <li 
                            key={index}
                            className="text-base text-[#18181b]">
                            <div className="p-1">{patient.hospital}</div>
                            <div className="p-1">{patient.date}</div>
                            <div className="p-1">{patient.doctor}</div>
                            <div className="p-1">{patient.department}</div>
                            <div className="p-1">{patient.type}</div>
                        </li>
                    )
                }
            </ul>
        </InfoCard>
    )
}

export default PatientInfo
