import { BaseInput } from '../../components/Input/BaseInput'
import Button from '../../components/Button/Button'
import { InputTypeEnum } from 'edah_utils/dist'
import React, { useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { RURAL_HOSPITAL_FIEDLS } from '../../constants/referralSystem/maintainAreaHeader'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { BasePopup } from '../../components/Popup/BasePopup'
import useDataGrid from '../../hooks/useDataGrid'
import {
    time2String,
    ToastTypeEnum,
    ApiErrorStatusEnum,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
} from 'edah_utils'
import { DATETIME_FORMAT, DATE_FORMAT } from '../../constants/common'
import {
    addTranRural,
    delTranRural,
    editTranRural,
    queryTranRural,
    trancaseQueryBasicData,
} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import { useEffect } from 'react'
import { queryByHospNo } from '../../api/v1/Menu'
import { debounce } from '../../components/ReferralSystem/utils'

/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({ children }) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
/**
 * 標題 component
 * @returns {JSX.Element}
 */
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

/**
 * Style
 */
const InputStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'

const RuralHospitalMaintain = () => {
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataOnChange,
        dataList,
        isEditMode,
    } = useDataGrid()

    /**
     * 離島偏鄉名稱
     */
    const [ruralHospName, setRuralHospName] = useState('')

    /**
     * 院所代碼
     */
    const [ruralHospNo, setRuralHospNo] = useState('')

    /**
     * 刪除popup顯示狀態
     */
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    /**
     * 欲刪除的資料
     */
    const [deletedRow, setDeletedRow] = useState(null)

    /**
     * 是否為新增資料
     */
    const [isAdd, setIsAdd] = useState(false)

    /**
     * 偏鄉離島註記下拉選單
     */
    const [ruralTypeList, setRuralTypeList] = useState([])

    const showToast = useToast()

    /**
     * 更新離島偏鄉醫院名稱
     * @param {event} e
     * @returns
     */
    const ruralHospNameOnChange = (e) => setRuralHospName(e.target.value)

    /**
     * 更新院所代碼
     * @param {event} e
     * @returns
     */
    const ruralHospNoOnChange = (e) => setRuralHospNo(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = () => {
        fetchRuralHospital()
    }

    const fetchRuralHospital = () => {
        queryTranRural({
            hospName: ruralHospName,
            hospNo: ruralHospNo,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                updateDataList(list)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    // 刪除單筆資料
    const handleConfirmDelete = () => {
        delTranRural({ hospNo: deletedRow.hospNo }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '刪除成功', type: ToastTypeEnum.Success })
                deleteRow(deletedRow)
                handleDeletePopupClose()
            } else {
                showToast({ message: '刪除失敗', type: ToastTypeEnum.Error })
            }
        })
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setDeletedRow(tableRow)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 匯出Excel api
     */
    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = RURAL_HOSPITAL_FIEDLS.map((item) => item.key)
        const titles = RURAL_HOSPITAL_FIEDLS.map((item) => item.name)
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'deactiveDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '離島偏鄉維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={handleExportExcel}
            >
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 儲存table資料
     */
    const handleSaveRuralHospital = (tableRow) => {
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.ruralType)
        ) {
            return
        }
        const targetApi = isAdd ? addTranRural : editTranRural
        targetApi({
            hospNo: tableRow.hospNo,
            ruralType: tableRow.ruralType,
            deactiveDate: time2String(tableRow.deactiveDate, DATETIME_FORMAT),
            sortBy: tableRow.sortBy,
            lockVersion: tableRow.lockVersion,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: '儲存成功',
                    type: ToastTypeEnum.Success,
                })
                handleSave(tableRow)
                fetchRuralHospital()
            } else {
                showToast({
                    message: `儲存失敗: ${res.msg}`,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 院所代碼輸入框失焦事件
     * @param {event} e 失焦事件
     * @param {object} row 該列資料
     * @param {string} key 欄位
     */
    const handleInputOnBlur = (e, row, key) => {
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            getHospNameByHospNo(value, row)
        }
    }

    /**
     *  以院所代碼取得院所名稱
     */
    const getHospNameByHospNo = debounce((value, row) => {
        queryByHospNo({ hospNo: value }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: ToastTypeEnum.Error,
                    })
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    // 編輯該列資料
    const editRuralHospital = (row) => {
        handleEdit({
            ...row,
            hospName: row.hospName || '',
            hospNo: row.hospNo || '',
            ruralType: row.ruralType || '',
            ruralTypeName: row.ruralTypeName || '',
            deactiveDate: time2String(row.deactiveDate, DATE_FORMAT) || '',
            sortBy: row.sortBy,
        })
    }

    /**
     * 在table新增一列資料
     */
    const addNewRuralHospital = () => {
        const newHosp = {
            lockVersion: 0,
            hospName: '',
            hospNo: '',
            deactiveDate: '',
            ruralTypeName: '',
            ruralType: ruralTypeList[1].ruralType,
            sortBy: '',
        }
        addNewData(newHosp)
        setIsAdd(true)
    }

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTrancaseQueryBasicData = () => {
        trancaseQueryBasicData().then((response) => {
            const { data, err } = response
            if (err === ApiErrorStatusEnum.Success) {
                setRuralTypeList(data.ruralTypeList)
            } else {
                showToast({ message: response.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    const renderFields = () => {
        const fields = RURAL_HOSPITAL_FIEDLS
        // 移除[全部]選項
        fields[2].options = ruralTypeList.filter(
            (item) => item.ruralType !== 'ALL'
        )
        fields[2].optionNameAndValue = ['ruralTypeName', 'ruralType']
        return fields
    }

    useEffect(() => {
        getTrancaseQueryBasicData()
    }, [])

    return (
        <Layout>
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">
                                是否確定刪除？
                            </p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button
                                    onClick={handleConfirmDelete}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >
                                    是
                                </button>
                                <button
                                    onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >
                                    否
                                </button>
                            </div>
                        </div>
                    }
                />
            )}
            <div className="flex justify-between p-2 items-center">
                {/*搜尋列*/}
                <div className="flex items-center">
                    <Title>院所代碼：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={ruralHospNo}
                        onChange={ruralHospNoOnChange}
                    />
                    <Title extendClass={'ml-4'}>離島偏鄉醫院名稱：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={ruralHospName}
                        onChange={ruralHospNameOnChange}
                    />
                    <Button
                        classNames={`ml-4 ${ButtonStyle}`}
                        text={'查詢'}
                        onClickFn={handleClickQuery}
                    />
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields()}
                dataList={dataList}
                slotButton={<ExportExcelBtn />}
                toggleItem={handleToggleItem}
                toggleAll={handleToggleAll}
                isCrud={true}
                isEditMode={isEditMode}
                handleEdit={editRuralHospital}
                handleSave={handleSaveRuralHospital}
                handleCancel={handleCancel}
                addNewData={addNewRuralHospital}
                deleteRow={handleDeletePopupOpen}
                handleInputOnBlur={handleInputOnBlur}
            />
        </Layout>
    )
}

export default RuralHospitalMaintain
