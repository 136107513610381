import HeaderLayout from "./HeaderLayout";
import Sidebar from "../components/Sidebar/Sidebar";
import {Content} from "../components/Default/Content";
import NotificationItemModal from "../components/Notifications/NotificationItemModal";
import NotificationsModal from "../components/Notifications/NotificationsModal";
import Tab from "../components/Tab/Tab";
import TabContainer from "../components/Tab/TabContainer";
import routes from "../router";
import React, {useState, useEffect} from 'react';
import Print from "../components/Print/Print";
import { useSelector } from "react-redux";
import store from "../redux/store";
import {updatePrintContent} from "../redux/Slice/printSlice";
import {FORM_TYPE} from "../constants/referralSystem/print";
import {addTab, setActiveTabId, updateTabList} from "../redux/Slice/TabSlice";

const DefaultLayout = ({content}) => {
    //當前選中的tab
    const activeTabId = useSelector((state) => state.tab.activeTabId)
    //tab列表
    const tabList = useSelector((state) => state.tab.tabList)
    //sideBar toggle
    const [isOpen, setIsOpen] = useState(true);
    const [toggleMenuOpen, setToggleMenuOpen] = useState(false)
    
    const isPrintMode =  useSelector((state) => state.print.isPrintMode)

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
        setToggleMenuOpen(!toggleMenuOpen);
    };

    /**
     * 新增Tab
     * @param id
     * @param name
     * @return {void}
     */
    const addNewTab = (id, name) => {
        store.dispatch(
            addTab({id, name})
        )
    };

    /**
     * 移除Tab
     * @param id
     * @return {void}
     */
    const removeTab = (id) => {
        const newTabList = tabList.filter((tab) => tab.id !== id);
        store.dispatch(updateTabList(newTabList))

        if (id === activeTabId) {
            store.dispatch(setActiveTabId({id: newTabList.length > 0 ? newTabList[newTabList.length - 1].id : null}))
        }
    };

    /**
     * 根據id查找route
     * @param id
     * @return {null|Element}
     */
    const findRouteElementById = (id) => {
        const route = routes.find((route) => route.id === id);
        return route ? route.element : null;
    };

    //當activeTabId改變時，重新設置activeTabContent
    const activeTabContent = findRouteElementById(activeTabId);
    // NotificationsModal
    const [isNotificationItemOpen, setIsNotificationItemOpen] = useState(false);
    const [notificationItemInfo, setNotificationItemInfo] = useState({message: '', detail: '', triggerTime: ''});

    const [isNotificationViewMoreOpen, setIsNotificationViewMoreOpen] = useState(false);

    const openNotificationItemModal = (message, detail, triggerTime) => {
        setIsNotificationItemOpen(prev => true);
        setNotificationItemInfo({message: message, detail: detail, triggerTime: triggerTime});
    };

    const closeNotificationItemModal = () => {
        setIsNotificationItemOpen(prev => false);
    };

    const openNotificationViewMoreModal = () => {
        setIsNotificationViewMoreOpen(prev => true);
    };

    const closeNotificationViewMoreModal = () => {
        setIsNotificationViewMoreOpen(prev => false);
    }

    return (
        <>
            <div className={`text-center ${isPrintMode ? 'no-print': ''}`}>
                <HeaderLayout openNotifitionItemModal={openNotificationItemModal}
                              openNotifitionViewMoreModal={openNotificationViewMoreModal}/>
                <div className='flex mt-px'>
                    <Sidebar Add={addNewTab} isOpen={isOpen} toggleSidebar={toggleSidebar} />
                    <div className={`flex flex-col ${toggleMenuOpen ? "w-full" : "w-[calc(100%-232px)]"}`}>
                        <Tab
                            tabList={tabList}
                            remove={removeTab}
                            activeTabId={activeTabId}
                        />
                        <Content content={<TabContainer content={activeTabContent}/>}/>
                    </div>
                </div>

            </div>
            <Print />
            {isNotificationViewMoreOpen && <NotificationsModal closeModal={closeNotificationViewMoreModal}/>}
            {isNotificationItemOpen && (<NotificationItemModal closeModal={closeNotificationItemModal}
                                                               message={notificationItemInfo.message}
                                                               detail={notificationItemInfo.detail}
                                                               triggerTime={notificationItemInfo.triggerTime}/>)}
        </>
    );
}
export default DefaultLayout;
