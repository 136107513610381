import React, {useEffect, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup';
import ChangeRecordQuery from './QueryChangeRecord';
import {usePopup} from '../PopupProvider';
import {t} from 'i18next';
import {
    ApiErrorStatusEnum,
    objectIsEmpty, stringIsEmpty,
    ToastTypeEnum
} from "edah_utils/dist"

// import API
import {rsisQueryCodeValue} from '../../../api/v1/RSIS';
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {Title} from "../utils";
import {USER_ROLE} from "../userRole";

/**
 * 報備支援內容檢視彈窗
 * @param {Object} data 報備支援內容資料
 * @param {function} handleOnClose 關閉彈窗
 * @return {JSX.Element}
 */
function RSISContentView({data, handleOnClose}) {
    // 使用者角色
    const [userRole, setUserRole] = useState(data.role)
    // 值登資料
    const [doctorData, setDoctorData] = useState()
    // 查詢變更紀錄
    const [showChangeRecordQuery, setShowChangeRecordQuery] = useState(false)
    // 支援目的選項
    const [purposeOptions, setPurposeOptions] = useState([])
    // 子支援目的選項
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 支援目的選項
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : '')
    // 子支援目的選項
    const [subPurposeCode, setSubPurposeCode] = useState(data.purposeCodeExt ? data.purposeCodeExt : '')
    // 變更原因
    const [changeReason, setChangeReason] = useState([])

    // 使用上下文
    const {
        showToast
    } = usePopup()

    /**
     * 取得醫師資料
     * @retunr {void}
     */
    const getDoctorData = () => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: data.supportUserNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定醫師資料
                if(data){
                    setDoctorData(data)
                }else{
                    // 若無執登資料，則設定空值
                    setDoctorData({
                        divNo: '',
                        divName: '',
                        licenceId: '',
                        hospNo: '',
                        hospName: '',
                        licenceKind: '',
                        licenceKindName: ''
                    })
                }
            } else {
                setDoctorData({
                    divNo: '',
                    divName: '',
                    licenceId: '',
                    hospNo: '',
                    hospName: '',
                    licenceKind: '',
                    licenceKindName: ''
                })
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {

        const subPurpose = () => {
            if (subPurposeCode === '1') {
                return '門診'
            } else if (subPurposeCode === '2') {
                return '值班'
            } else if (subPurposeCode === '3') {
                return '會診'
            }
        }
        // 判斷purposeCode 是否有split成陣列
        if (Array.isArray(purposeCode)) {
            const purposeTexts = purposeCode.map(codeNo => {
                // 比對purposeCode跟purposeOptions的codeNo，找到對應的codeValue1
                const purpose = purposeOptions.find(item => item.codeNo === codeNo)
                // purpose有值
                if (purpose) {
                    // 如果codeNo=10，則返回支援目的(報備為一般支援的情況下)+子支援目的
                    if (codeNo === '10') {
                        return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                    }
                    return purpose.codeValue1;
                }
                return null;
            }).filter(Boolean)

            // 顯示支援目的
            return purposeTexts.map((text, index) => (
                <p key={index} className='flex justify-start pl-4 py-[9px]'>{text}</p>
            ))
        } else if (!stringIsEmpty(purposeCode)) { // 判斷purposeCode沒有split成讖列，額外是否有值，單純字串
            const purpose = purposeOptions.find(item => item.codeNo === purposeCode)
            if (purpose) {
                if (purposeCode === '10') {
                    return <p
                        className='flex justify-start pl-4 py-[9px]'>{`${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`}</p>
                }
                return <p className='flex justify-start pl-4 py-[9px]'>{purpose.codeValue1}</p>
            }
        } else {// purposeCode 為空
            return <p className='flex justify-start pl-4 py-[9px]'>{''}</p>
        }
    }

    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setPurposeOptions(data.filter(item => item.codeType === 'PURPOSE'))
                // 設定子支援目的選項
                setSubPurposeOptions(data.filter(item => item.codeType === 'SUBPURPOSE'))
                // 設定變更原因
                setChangeReason(data.filter(item => item.codeType === 'CHANGE'))
            } else {
                setPurposeOption([])
                setSubPurposeOptions([])
                setChangeReason([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     */
    useEffect(() => {
        // 取得醫師執登資料
        getDoctorData()
        // 取得支援目的選項
        getPurposeOptions()
    }, [])

    return (
        <>
            <BasePopup
                title="報備支援內容顯示"
                width="1236px"
                closePopupButtonOnClick={handleOnClose}
                content={
                    <div className='px-6'>
                        <div className='flex flex-row justify-end gap-2'>
                            <button
                                className={`ml-4 flex items-center justify-center font-base px-4 h-10 rounded-[6px] border-[#D4D4D8] border-[2px] bg-[#FAFAFA] hover:cursor-pointer`}
                                onClick={() => setShowChangeRecordQuery(true)}
                            >
                                查詢變更紀錄
                            </button>
                            <select
                                className='w-[132px] pl-[12px] h-10 bg-white text-black rounded-[6px] border border-gray-300'
                                disabled onChange={() => {
                            }}>
                                <option value="1">{t(`campus.${data.zone}`)}</option>
                            </select>
                            <span
                                className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                        </div>
                        <div className='flex flex-col'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    支援人員
                                </h3>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row h-[42px] items-center gap-4'>
                                    <div className='flex justify-end w-[160px] mr-2'>員工/醫師代碼：</div>
                                    <p className='w-fit'>{`${data.supportUserNo} ${data.supportUserName}`}</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row h-[42px] items-center gap-4'>
                                    <div className='flex justify-end w-[160px] mr-2'>執登機構：</div>
                                    <p>{!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.hospNo) && !stringIsEmpty(doctorData.hospName) ? `${doctorData.hospNo} ${doctorData.hospName}` : ''}</p>
                                    <div className='flex justify-end w-[160px] mr-2'>職類別：</div>
                                    <p>{!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.licenceKind) && !stringIsEmpty(doctorData.licenceKindName) ? `${doctorData.licenceKind} ${doctorData.licenceKindName}` : ''}</p>
                                    <div className='flex justify-end w-[160px]'>執登科別：</div>
                                    <p className='w-[160px]'>{!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.divNo) && !stringIsEmpty(doctorData.divName) ? `${doctorData.divNo} ${doctorData.divName}` : ''}</p>
                                    <div className='flex justify-end w-[160px]'>證書字號：</div>
                                    <p className='w-[160px]'>{!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.licenceId) ? doctorData.licenceId : ''}</p>

                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>支援期間明細：</div>
                                        <p>{`${data.itemStartDate.replace(/ 00:00:00/g, '')}~${data.itemEndDate.replace(/ 00:00:00/g, '')} ${data.itemStartTime}~${data.itemEndTime}`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        {/* 報備支援內容 */}
                        <div className='flex flex-col mt-6 mb-6'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    報備支援內容
                                </h3>
                            </div>
                            <div className='flex flex-col gap-2 '>
                                <div className='flex flex-row w-full h-[42px] items-center justify-end gap-4'>
                                    <div className='flex justify-end w-[160px] mr-2'>申請單號：</div>
                                    <p className='w-[160px] flex justify-start'>{data.supportNo}</p>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>機構代碼：</div>
                                        <p>{`${!stringIsEmpty(data.supportOrgNo) ? data.supportOrgNo : ''} ${!stringIsEmpty(data.supportOrgName) ? data.supportOrgName : ''}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>支援科別：</div>
                                        <p>{`${data.supportDivNo} ${data.supportDivName}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row items-start'>
                                        <Title children={'支援目的：'}
                                               extendClass='w-[160px] justify-end items-start py-2'/>
                                        <div className='flex flex-col pl-1.5'>
                                            {renderPurposeOptions()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>承辦部門：</div>
                                        <p>{`${data.ownerDeptNo} ${data.ownerDeptName}`}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>代理人員：</div>
                                        <p className='w-[160px] flex justify-start'>{`${data.agentUserNo} ${data.agentUserName ? data.agentUserName : ''}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>性質：</div>
                                        <p>{data.supportProperties}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>執行內容：</div>
                                        <p>{data.executeContent}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row h-[42px] items-center gap-4'>
                                        <div className='flex justify-end w-[160px] mr-2'>備註：</div>
                                        <p>{data.memo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showChangeRecordQuery && (
                            <ChangeRecordQuery
                                supportNo={data.supportNo}
                                handleOnClose={() => setShowChangeRecordQuery(false)}
                            />
                        )}
                    </div>
                }
            />
        </>
    )
}

export default RSISContentView
