import { useState, useMemo, useRef, useEffect } from "react"
import { BaseInput } from "../../components/Input/BaseInput"
import RadioButton from "../../components/Input/RadioButton"
import { SearchInput } from "../../components/Input/SearchInput"
import { DefaultButton } from "../../components/Button/DefaultButton"
import {
    InputTypeEnum, 
    time2String,
    arrayIsEmpty,
    ToastTypeEnum,
    fuzzySearchObjects,
    ApiErrorStatusEnum,
    stringIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"
import { Pagination } from "../../components/Pagination/Pagination"
import useToast from "../../hooks/useToast"
import { queryAllDoctor, queryAllDivision } from "../../api/v1/Menu"
import { regsQueryClinic } from "../../api/v1/RegsMonth"
import useOutsideClick from "../../hooks/useOutsideClick"
import searchIcon from "../../assets/images/search-interface-symbol.png"

const ClinicScheduleSearch = () => {
    //時段列表
    const apnList = [
        {
            justnameNo: "1,2,3",  justnameName: "全部"
        },
        {
            justnameNo: "1",  justnameName: "早"
        },
        {
            justnameNo: "2",  justnameName: "午"
        },
        {
            justnameNo: "3",  justnameName: "晚"
        },
    ]
    //日期列表
    const weekList = [
        {justnameNo: "0", justnameName: "全部"},
        {justnameNo: "1", justnameName: "一"},
        {justnameNo: "2", justnameName: "二"},
        {justnameNo: "3", justnameName: "三"},
        {justnameNo: "4", justnameName: "四"},
        {justnameNo: "5", justnameName: "五"},
        {justnameNo: "6", justnameName: "六"},
        {justnameNo: "7", justnameName: "日"},
    ]
    

    // 預設建立時間起迄:起-今日
    const today = new Date()
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text : "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
        //醫師代碼
        doctorCode: '',
        // 科別代碼
        departmentCode: '',
        //星期
        week: weekList[0].justnameNo
    }
    // 所有醫生選項
    const [allDoctor, setAllDoctor] = useState(null)
    // 所有科別選項
    const [allDepartment, setAllDepartment] = useState(null)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    // 目前院區診間班表
    const [currentCampusClinicScheduleArray, setCurrentCampusClinicScheduleArray] = useState(null)
    // 其他院區診間班表
    const [otherCampusClinicScheduleArray, setOtherCampusClinicScheduleArray] = useState(null)
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    // 目前院區 pagination控制變數
    const [currentCampusPaginationProps, setCurrentCampusPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 其他院區 pagination控制變數
    const [otherCampusPaginationProps, setOtherCampusPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });

    /**
     * 取得所有醫生
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有醫生選項
                setAllDoctor(data)
            }
        })
    }

    /**
     * 取得所有科別
     */
    const getQueryAllDepartment = () => {
        queryAllDivision().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有科別選項
                setAllDepartment(data)
            }
        })
    }

    /**
     * 取得醫師下拉選項
     * @returns 
     */
    const getDoctorOptionList = () => {
        const splitArray = displayQueryInputData.doctorCode ? displayQueryInputData.doctorCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
             return allDoctor.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(allDoctor, displayQueryInputData.doctorCode)
        }
    }

    /**
     * 取得科別代碼下拉選項
     * @returns 
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode ? displayQueryInputData.departmentCode.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
            return allDepartment.filter(doctor => doctor.divNo.includes(splitArray[0]) && doctor.divName.includes(splitArray[1]))
       }
       else {
           return fuzzySearchObjects(allDepartment, displayQueryInputData.departmentCode)
       }
    }

    /**
     * 開始時間輸入欄位變更事件
     */
    const handleDateStartOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: e.target.value
        })
    }

    /**
     * 結束時間輸入欄位變更事件
     */
    const handleDateEndOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateEnd: e.target.value
        })
    }

    /**
     * 星期下拉選單選擇事件
     * @param {Event} e 
     */
    const handleSelectWeekOnChange =(e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            week: e.target.value
        })
    }

    /**
     * 時段模式改變時
     * @param {String} apn 
     */
    const handleApnModeOnChange = (apn) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: apn
        })
    }

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e) => {
        setDisplayQueryInputData({ ...displayQueryInputData, doctorCode: e.target.value});
    }

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorDropDown(true)
    }

    /**
     * 選取醫師下拉選項時
     * @param doctor {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (doctor) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: `${doctor.userNo} ${doctor.userName}`
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 科別代碼方框的值變動時
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({ ...displayQueryInputData, departmentCode: e.target.value});
    }

    /**
     * 科別代碼方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param department {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (department) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: `${department.divNo} ${department.divName}`
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message:"起始日期不可大於結束日期", type:ToastTypeEnum.Error})
            return
        }

        //取得當前院區診間班表資料
        queryCurrentCampusClinicSchedule(displayQueryInputData, 1, currentCampusPaginationProps.pageSize)
    }

    const queryCurrentCampusClinicSchedule = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        regsQueryClinic({
            //起始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            //星期
            week: stringIsEmpty(queryInputObj.week) || queryInputObj.week === "0" ? '' : queryInputObj.week,
            // 醫師代碼
            doctorNo: queryInputObj.doctorCode.indexOf(' ') > 0 ? queryInputObj.doctorCode.split(' ')[0] : queryInputObj.doctorCode,
            // 科別代碼
            divNo: queryInputObj.departmentCode.indexOf(' ') > 0 ? queryInputObj.departmentCode.split(' ')[0] : queryInputObj.departmentCode,
            //時段
            apn: (queryInputObj.apn === '1' || queryInputObj.apn === '2' || queryInputObj.apn === '3') ? parseInt(queryInputObj.apn) : null,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定標語類別資料
                setCurrentCampusClinicScheduleArray(data.dataList)

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else { // 取得資料失敗
                setCurrentCampusClinicScheduleArray([])

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得時段的名稱
     * @param {Number} id 
     * @returns 
     */
    const getApnNameByApnId = (id) => {
        if(id === null) {
            return ''
        }
        switch(id) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return ''
    }

    /**
     * 目前院區班表頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onCurrentCampusPaginationPageOnChange = (page) => {
        queryCurrentCampusClinicSchedule(queryInputData, page, currentCampusPaginationProps.pageSize)
    }

    /**
     * 目前院區班表上一頁點擊事件
     */
    const onCurrentCampusPaginationPreviousOnClick = () => {
        const page = (currentCampusPaginationProps.currentPage - 1) > 1 ? (currentCampusPaginationProps.currentPage - 1) : 1
        queryCurrentCampusClinicSchedule(queryInputData, page, currentCampusPaginationProps.pageSize)
    }

    /**
     * 目前院區班表下一頁點擊事件
     */
    const onCurrentCampusPaginationNextOnClick = () => {
        const page = (currentCampusPaginationProps.currentPage + 1) < currentCampusPaginationProps.totalPageSize ? (currentCampusPaginationProps.currentPage + 1) : currentCampusPaginationProps.totalPageSize
        queryCurrentCampusClinicSchedule(queryInputData, page, currentCampusPaginationProps.pageSize)
    }

    /**
     * 目前院區班表每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onCurrentCampusPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        queryCurrentCampusClinicSchedule(queryInputData, 1, e.target.value)
    }

    /**
     * 其他院區班表頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onOtherCampusPaginationPageOnChange = (page) => {
    }

    /**
     * 其他院區班表上一頁點擊事件
     */
    const onOtherCampusPaginationPreviousOnClick = () => {
        const page = (otherCampusPaginationProps.currentPage - 1) > 1 ? (otherCampusPaginationProps.currentPage - 1) : 1
    }

    /**
     * 其他院區班表下一頁點擊事件
     */
    const onOtherCampusPaginationNextOnClick = () => {
        const page = (otherCampusPaginationProps.currentPage + 1) < otherCampusPaginationProps.totalPageSize ? (otherCampusPaginationProps.currentPage + 1) : otherCampusPaginationProps.totalPageSize
    }

    /**
     * 其他院區班表每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onOtherCampusPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
    }

    const getTableDoctorFieldContent = (scheduleData) => {
        let doctorContent = ''
        if(!stringIsEmpty(scheduleData.locumDoctorName)) {
            doctorContent = `${scheduleData.locumDoctorName}[代]`
        }
        else {
            if(!stringIsEmpty(scheduleData.doctorName)) {
                doctorContent = scheduleData.doctorName
                if(scheduleData.stopClinicFlag !== null && scheduleData.stopClinicFlag) {
                    doctorContent = `${doctorContent}[停]`
                }
            }
        }
        return doctorContent
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        if(arrayIsEmpty(allDoctor)) {
            getQueryAllDoctor()
        }
        if(arrayIsEmpty(allDepartment)) {
            getQueryAllDepartment()
        }

        handleSearchOnClick()
    }, [])

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [displayQueryInputData.doctorCode])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                <div className="date flex flex-row items-center justify-start space-x-2.5 mr-3.5">
                    <p className="">{t('Regs.general.dateStartAndEnd')}：</p>
                    <div className="flex flex-row items-center">
                        <BaseInput 
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={displayQueryInputData.dateStart} 
                            type={InputTypeEnum.Date} 
                            inputMode="date"
                            onChange={handleDateStartOnChange}/>
                        <p className="m-1">~</p>
                        <BaseInput 
                            className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={displayQueryInputData.dateEnd} 
                            type={InputTypeEnum.Date} 
                            inputMode="date"
                            onChange={handleDateEndOnChange}/>
                    </div>
                </div>
                <div className="flex items-center mr-3">
                    <p>星期：</p>
                    <select 
                        className="h-10 pl-4 border border-[#D4D4D8] rounded-md min-w-[120px]"
                        onChange={handleSelectWeekOnChange}
                    >
                    {
                        weekList.map((item) => 
                            <option key={item.justnameNo} value={item.justnameNo}>{item.justnameName}</option>
                        )
                    }
                    </select>
                </div>
                <div className="flex flex-row justify-center items-center gap-[12px] text-base font-normal mr-3">
                    <p>{t('Regs.general.timeSlot')}：</p>
                    {
                    apnList.map((apn, index) => (
                        <RadioButton 
                            key={index} 
                            mode={displayQueryInputData.apn}
                            modeChecked={apn.justnameNo} 
                            handleModeChange={handleApnModeOnChange}
                            label={apn.justnameName} 
                            value={apn.justnameNo}/>
                    ))
                }
                </div>
                {/*醫師*/}
                <div className="flex items-center mr-2">
                    <p>{t('general.doctor')}：</p>
                    <div ref={dropdownDoctorRef} className="flex flex-row w-[180px] items-center relative">
                        <input
                            className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                            value={displayQueryInputData.doctorCode}
                            onChange={(e) => handleInputDoctorNoOnChange(e)}
                            onFocus={handleInputDoctorNoOnFocus}/>
                        {
                            /*醫師下拉*/
                            showDoctorDropDown && !arrayIsEmpty(doctorOptionList) && 
                            <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                {
                                    doctorOptionList.map((doctor, index) =>
                                        <div
                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                            role="button" key={index}
                                            onClick={() => handleDoctorOptionOnClick(doctor)}>
                                            <p className="">{`${doctor.userNo}`}</p>
                                            <p>{`${doctor.userName}`}</p>
                                        </div>
                                    )
                                }
                                </div>
                            </div> 
                        }
                    </div>
                </div>
                
                {/*科別*/}
                <div className="flex items-center mr-2">
                    <p>{t('general.department')}：</p>
                    <div ref={dropdownDepartmentRef} className="flex flex-row w-[180px] items-center relative">
                        <input
                            className="bg-white w-full pl-8 h-10 text-[#3F3F46] rounded-md border-2 border-[#D4D4D8] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                            value={displayQueryInputData.departmentCode}
                            onChange={(e) => handleInputDepartmentNoOnChange(e)}
                            onFocus={handleInputDepartmentNoOnFocus}/>
                        {
                            showDepartmentDropDown && !arrayIsEmpty(departmentOptionList) &&
                            <div className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                {
                                    departmentOptionList.map((department, index) => 
                                        <div
                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                            role="button" key={index}
                                            onClick={() => handleDepartmentOptionOnClick(department)}>
                                            <p className="">{`${department.divNo}`}</p>
                                            <p>{`${department.divName}`}</p>
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/*查詢*/}
                <DefaultButton 
                    type="primary" text={t('general.query')}
                    onClickFn={handleSearchOnClick}/>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*系統登錄院區*/}
                <div className="w-1/2 p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                    <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                        <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                        <div className="text-xl text-[#2B6CB0] mr-3.5">系統登錄院區</div>
                        <div className="text-xl">目前院區：屏東義大醫院</div>
                    </div>
                    {/*Table*/}
                    <ul className="tableList clinicScheduleQueryTableItem min-h-[calc(100vh-325px)] max-h-[calc(100vh-325px)] overflow-x-auto overflow-y-auto">
                        <li className="title">
                            <div >{t("Regs.general.clinicDate")}</div>
                            <div >星期</div>
                            <div >{t("Regs.general.timeSlot")}</div>
                            <div >{t('general.department')}</div>
                            <div >{t('general.doctor')}</div>
                            <div >{t("Regs.general.clinicName")}</div>
                            <div >限額數</div>
                            <div >掛號人數</div>
                        </li>
                        {
                            !arrayIsEmpty(currentCampusClinicScheduleArray) && currentCampusClinicScheduleArray.map((item, index) => (
                                <li key={index}>
                                    {/*門診日期*/}
                                    <div>{stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD")}</div>
                                    {/*星期*/}
                                    <div>{stringIsEmpty(item.weekName) ? '' : item.weekName}</div>
                                    {/*時段*/}
                                    <div>{getApnNameByApnId(item.apn)}</div>
                                    {/*科別*/}
                                    <div>{stringIsEmpty(item.divName) ? '' : item.divName}</div>
                                    {/*醫師*/}
                                    <div>{getTableDoctorFieldContent(item)}</div>
                                    {/*診室名稱*/}
                                    <div>{stringIsEmpty(item.clinicName) ? '' : item.clinicName}</div>
                                    {/*限額數*/}
                                    <div>{item.totalRegLimit === null ? '' : item.totalRegLimit}</div>
                                    {/*掛號人數*/}
                                    <div>{item.currentRegCount === null ? '' : item.currentRegCount}</div>
                                </li>
                            ))
                        }
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={currentCampusPaginationProps.totalPageSize}
                            currentPage={currentCampusPaginationProps.currentPage}
                            pageSize={currentCampusPaginationProps.pageSize}
                            totalSize={currentCampusPaginationProps.totalItemSize}
                            onPageOnChange={(page) => onCurrentCampusPaginationPageOnChange(page)}
                            onPrevPageOnClick={onCurrentCampusPaginationPreviousOnClick}
                            onNextPageOnClick={onCurrentCampusPaginationNextOnClick}
                            onPageSizeChange={onCurrentCampusPaginationPageSizeOnChange}
                        />
                    </div>
                </div>
                {/*其他院區*/}
                <div className="w-1/2 p-2 bg-[#FFFFFF] border rounded-md min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                    <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                        <div className="h-[25px] w-[8px] bg-[#2B6CB0] mr-2"/>
                        <div className="text-xl text-[#2B6CB0] mr-3.5">其他院區</div>
                        <select className="h-10 pl-4 border border-[#D4D4D8] rounded-md min-w-[120px]">
                            <option>義大醫院</option>
                        </select>
                    </div>
                    {/*Table*/}
                    <ul className="tableList clinicScheduleQueryTableItem min-h-[calc(100vh-325px)] max-h-[calc(100vh-325px)] overflow-x-auto overflow-y-auto">
                        <li className="title">
                            <div >{t("Regs.general.clinicDate")}</div>
                            <div >星期</div>
                            <div >{t("Regs.general.timeSlot")}</div>
                            <div >{t('general.department')}</div>
                            <div >{t('general.doctor')}</div>
                            <div >{t("Regs.general.clinicName")}</div>
                            <div >限額數</div>
                            <div >掛號人數</div>
                        </li>  
                        {
                            !arrayIsEmpty(otherCampusClinicScheduleArray) && otherCampusClinicScheduleArray.map((item, index) => (
                                <li key={index}>
                                    {/*門診日期*/}
                                    <div>{stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD")}</div>
                                    {/*星期*/}
                                    <div>{stringIsEmpty(item.weekName) ? '' : item.weekName}</div>
                                    {/*時段*/}
                                    <div>{getApnNameByApnId(item.apn)}</div>
                                    {/*科別*/}
                                    <div>{stringIsEmpty(item.divName) ? '' : item.divName}</div>
                                    {/*醫師*/}
                                    <div>{getTableDoctorFieldContent(item)}</div>
                                    {/*診室名稱*/}
                                    <div>{stringIsEmpty(item.clinicName) ? '' : item.clinicName}</div>
                                    {/*限額數*/}
                                    <div>{item.totalRegLimit === null ? '' : item.totalRegLimit}</div>
                                    {/*掛號人數*/}
                                    <div>{item.currentRegCount === null ? '' : item.currentRegCount}</div>
                                </li>
                            ))
                        }     
                    </ul>
                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={otherCampusPaginationProps.totalPageSize}
                            currentPage={otherCampusPaginationProps.currentPage}
                            pageSize={otherCampusPaginationProps.pageSize}
                            totalSize={otherCampusPaginationProps.totalItemSize}
                            onPageOnChange={(page) => onOtherCampusPaginationPageOnChange(page)}
                            onPrevPageOnClick={onOtherCampusPaginationPreviousOnClick}
                            onNextPageOnClick={onOtherCampusPaginationNextOnClick}
                            onPageSizeChange={onOtherCampusPaginationPageSizeOnChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClinicScheduleSearch