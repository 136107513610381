import React, {useEffect, useMemo, useState, useRef} from "react"
import {t} from "i18next"
import { BaseInput } from "../../Input/BaseInput"
import { SearchInput } from "../../Input/SearchInput"
import { DefaultButton } from "../../Button/DefaultButton"
import {
    InputTypeEnum,
    time2String,
    objectIsEmpty,
    arrayIsEmpty,
    fuzzySearchObjects,
    ApiErrorStatusEnum,
    stringIsEmpty ,
    ToastTypeEnum
} from "edah_utils/dist"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { regsQueryRegHistory } from "../../../api/v1/Regs"
import { regsQueryClinicOfTheWeek, queryRegsClinicStatus } from "../../../api/v1/RegsMonth"
import { regsAddReg } from "../../../api/v1/Regs"
import { queryJustNameByCategories } from "../../../api/v1/Menu"
import { Pagination } from "../../Pagination/Pagination"
import useToast from "../../../hooks/useToast"
import { ShowGiveNumberPopup } from "../../Appointment/AppointmentSchedule/ShowGiveNumberPopup"
import CancelAppointmentMessagePopup from "../../Appointment/Popup/CancelAppointmentMessagePopup"
import TimeCalendar from "./TimeCalendar"


/**
 * 
 * @param {Object} patientData 病歷資料
 * @param {Object} baseQueryCondition 查詢條件 
 * @param {Array<Object>} doctorArray 醫生array 
 * @param {Array<Object>} deptArray 科別array 
 * @returns 
 */
const AppointmentRegistrationPopup = ({
    show = false, 
    closePopupButtonOnClick,
    patientData,
    baseQueryCondition,
    doctorArray,
    deptArray
}) => {

    // 是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)
    // 快速塞選的項目
    const quickSelectCandidateArray = [
        {justnameNo: "1", justnameName: "預約下1週"},
        {justnameNo: "2", justnameName: "預約下2週"},
        {justnameNo: "3", justnameName: "預約下3週"},
        {justnameNo: "4", justnameName: "預約下4週"},
        {justnameNo: "5", justnameName: "預約下5週"},
        {justnameNo: "6", justnameName: "預約下6週"},
        {justnameNo: "7", justnameName: "預約下7週"},
        {justnameNo: "8", justnameName: "預約下8週"},
        {justnameNo: "12", justnameName: "預約下12週"},
        {justnameNo: "24", justnameName: "預約下24週"}
    ]
    // 院區列表
    const zonesetList = [
        {
            "zone": "A",
            "zoneName": "義大醫院"
        },
        {
            "zone": "C",
            "zoneName": "義大癌治療醫院"
        },
        {
            "zone": "P",
            "zoneName": "義大屏東"
        },
        {
            "zone": "D",
            "zoneName": "大昌醫院"
        }
    ]
    const [inputData, setInputData] = useState({
        //科別
        div: "",
        //醫師
        doctor: "",
        //天數
        day: 1
    })
    // 日期輸入欄位值
    const [inputDate, setInputDate] = useState(time2String(new Date(), "YYYY-MM-DD"))
    // 日期輸入欄位值
    const [inputZone, setInputZone] = useState(zonesetList[0].zone)
    // 星期資料
    const [weekDates, setWeekDates] = useState([])
    // 週班表資料
    const [weekScheduleData, setWeekScheduleData] = useState([])
    // 顯示診號狀況panel
    const [showViewNoStatePanel, setShowViewNoStatePanel] = useState(false)
    // 選擇的診間
    const [selectedClinic, setSelectedClinic] = useState(null)
    // 診號狀況資料
    const [clinicStatusData, setClinicStatusData] = useState(null)
    // 病人預約資訊清單
    const [appointmentTableData, setAppointmentTableData] = useState([])
    // 病人預約資訊清單進階搜尋結果list
    const [advSearchAppointmentList, setAdvSearchAppointmentList] = useState([])
    // 預約資訊的搜尋文字
    const [appointmentSearchText, setAppointmentSearchText] = useState('')
    // 預約資訊 pagination控制變數
    const [appointmentPaginationProps, setAppointmentPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //給診號彈窗資料
    const [giveNumberData, setGiveNumberData] = useState({})
    //是否顯示給診號彈窗
    const [showGiveNumberPopup, setShowGiveNumberPopup] = useState(false)
    //已給號勾選狀態
    const [checkedGiven, setCheckedGiven] = useState(true)
    //未給號勾選狀態
    const [checkedNotYetGiven, setCheckedNotYetGiven] = useState(true)
    //預約號勾選狀態
    const [checkdAppointment, setCheckdAppointment] = useState(true)
    //科初診勾選狀態
    const [checkdFirstView, setCheckdFirstView] = useState(true)
    //不給號勾選狀態
    const [checkdNoGiven, setCheckdNoGiven] = useState(true)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [deptOptionList, setDeptOptionList] = useState(null)
    //顯示醫師下拉選項
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    //顯示科別下拉選項
    const [showDeptDropDown, setShowDeptDropDown] = useState(false)
    //是否顯示退掛確認彈窗
    const [showCancelAppointmentMessage, setShowCancelAppointmentMessage] = useState(false)
    // 取消掛號資料Array
    const [cancelRegArray, setCancelRegArray] = useState(null)
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDeptDropDown(false),
    });
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 已給號勾選框變更狀態事件
     * @returns 
     */
    const handleGivenOnChange = () => setCheckedGiven(!checkedGiven)

    /**
     * 未給號勾選框變更狀態事件
     * @returns 
     */
    const handleNotYetGivenOnChange = () => setCheckedNotYetGiven(!checkedNotYetGiven)

    /**
     * 預約號勾選框變更狀態事件
     * @returns 
     */
    const handleAppointmentOnChange = () => setCheckdAppointment(!checkdAppointment)

    /**
     * 科初診勾選框變更狀態事件
     * @returns 
     */
    const handleFirstViewOnChange = () => setCheckdFirstView(!checkdFirstView)

    /**
     * 不給號勾選框變更狀態事件
     * @returns 
     */
    const handleNoGivenOnChange = () => setCheckdNoGiven(!checkdNoGiven)

    /**
     * 診號種類背景顏色
     * @param {String} type 種類
     * @returns 
     */
    const givenNoTypeBkColor = (type) => {
        if(type === '*')    //已給號:*
        {
            return 'bg-[#FFFFFF]'
        }

        if(type === 'A') //未給號
        {
            return 'bg-[#FFFFFF]'
        }

        if(type === 'R') //預約號
        {
            return 'bg-[#FFFFFF]'
        }

        if(type === 'F') //科初診
        {
            return 'bg-[#FFFFFF]'
        }

        if(type === 'X') //不給號
        {
            return 'bg-[#FFFFFF]'
        }
        
        return 'bg-[#fafafa]'
    }

    /**
     * 診號背景顏色
     * @param {} viewType 
     * @returns 
     */
    const numberBkColor = (viewType) => {
        if(viewType === '*' && checkedGiven)    //已給號:*
        {
            return 'bg-[#B1B1B1]'
        }

        if(viewType === 'A' && checkedNotYetGiven) //未給號
        {
            return givenNoTypeBkColor(viewType)
        }

        if(viewType === 'R' && checkdAppointment) //預約號
        {
            return givenNoTypeBkColor(viewType)
        }

        if(viewType === 'F' && checkdFirstView) //科初診
        {
            return givenNoTypeBkColor(viewType)
        }

        if(viewType === 'X' && checkdNoGiven) //不給號
        {
            return givenNoTypeBkColor(viewType)
        }
        
        return 'bg-[#fafafa]'
    }

    /**
     * 診號外框顏色
     * @param {} viewType 
     * @returns 
     */
    const numberBorderColor = (viewType) => {
        if(viewType === '*' && checkedGiven)    //已給號:*
        {
            return 'border-[#D4D4D8]'
        }

        if(viewType === 'A' && checkedNotYetGiven) //未給號
        {
            return 'border-[#D4D4D8]'
        }

        if(viewType === 'R' && checkdAppointment) //預約號
        {
            return 'border-[#D4D4D8]'
        }

        if(viewType === 'F' && checkdFirstView) //科初診
        {
            return 'border-[#D4D4D8]'
        }

        if(viewType === 'X' && checkdNoGiven) //不給號
        {
            return 'border-[#D4D4D8]'
        }
        
        return 'border-[#D4D4D8]'
    }

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        if(stringIsEmpty(inputData.day.toString())) {
            showToast({message: '天數不可為空', type: ToastTypeEnum.Error})
            return
        }

        const addDay = parseInt(inputData.day)
        const searchDate = time2String(new Date(baseQueryCondition.encounterDate).setDate(new Date(baseQueryCondition.encounterDate).getDate() + addDay), 'YYYY-MM-DD')
        //查詢週班表
        queryQueryClinicOfTheWeek({
            //科別
            div: inputData.div,
            //醫師
            doctor: inputData.doctor,
            //天數
            day: inputData.day,
            //開診日期
            encounterDate: baseQueryCondition.encounterDate
        },
        searchDate)
    }

    /**
     * 快速篩選項目按鈕點擊事件
     * @param {Object} item 選擇的項目
     */
    const handleQuickSelectItemOnClick = (item) => {
        const week = parseInt(item.justnameNo)
        if(objectIsEmpty(item) || isNaN(week)) {
            return
        }
        const date = new Date(baseQueryCondition.encounterDate)
        const jumpToDate = new Date(date)
        jumpToDate.setDate(date.getDate() + (week * 7))
        const searchDate = time2String(jumpToDate, 'YYYY-MM-DD')
        setInputDate(searchDate)

        //查詢週班表
        queryQueryClinicOfTheWeek({
            //科別
            div: inputData.div,
            //醫師
            doctor: inputData.doctor,
            //天數
            day: 0,
            encounterDate: baseQueryCondition.encounterDate
        }, searchDate)
    }

    /**
     * 院區下拉選單選擇變更事件
     * @param {Event} e 
     */
    const handleZoneSelectOnChange = (e) => {
        setInputZone(e.target.value)
    }

    /**
     * 點擊前一週
     * @return {void}
     */
    const handlePreviousWeekOnClick =() => {
        const date = new Date(inputDate)
        const prevWeekDate = new Date(date)
        prevWeekDate.setDate(date.getDate() - 7)
        const searchDate = time2String(prevWeekDate, 'YYYY-MM-DD')
        setInputDate(searchDate)
        
        //查詢週班表
        queryQueryClinicOfTheWeek({
            //科別
            div: inputData.div,
            //醫師
            doctor: inputData.doctor,
            //天數
            day: inputData.day,
            encounterDate: baseQueryCondition.encounterDate
        }, searchDate)
    }

    /**
     * 點擊下一週
     * @return {void}
     */
    const handleNextWeekOnClick =() => {
        const date = new Date(inputDate)
        const nextWeekDate = new Date(date)
        nextWeekDate.setDate(date.getDate() + 7)
        const searchDate = time2String(nextWeekDate, 'YYYY-MM-DD')
        setInputDate(searchDate)
        
        //查詢週班表
        queryQueryClinicOfTheWeek({
            //科別
            div: inputData.div,
            //醫師
            doctor: inputData.doctor,
            //天數
            day: inputData.day,
            encounterDate: baseQueryCondition.encounterDate
        }, searchDate)
    }

    /**
     * 日期輸入值變動事件
     * @param {Event} e 
     */
    const handleInputDateOnChange = (e) => {
        setInputDate(e.target.value)
    }

    /**
     * 開啟診號狀況panel
     */
    const handleOpenViewNumberStatePanel = () => {
        setShowViewNoStatePanel(true)
    }

    /**
     * 關閉診號狀況panel
     */
    const handleCloseViewNumberStatePanel = () => {
        setShowViewNoStatePanel(false)
    }

    /**
     * 診間點選事件
     */
    const handleClinicOnClick = (disabled, clinic) => {
        if(disabled)
            return

        //選擇診間
        setSelectedClinic({...clinic, giveNumStartTime:null, giveNumEndTime:null}) 

        if(clinic.giveNumType === "1") {    
            // 預約方式為依診號時
            // 取得給號狀況
            queryRegsClinicStatus({
                clinicId: clinic.clinicId,
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    setClinicStatusData(res.data)
                }
                else {
                    setClinicStatusData(null)
                }
            })
        }
        // 展開
        setShowViewNoStatePanel(true)
    }

    const getClinicItemClassName = (timeSlot, index) => {
        let className = 'rounded-md hover:bg-[#63B3ED] text-left cursor-pointer'

        if(!objectIsEmpty(selectedClinic)) {
            if(selectedClinic.timeSlot === timeSlot && selectedClinic.index === index) {
                className = `${className} bg-[#BEE3F8]`
            }
        }
        return className
    }

    /**
     * 新增給診號
     */
    const handleAddRegsOnClick = () => {
        let startViewDatetime = ""
        let endViewDatetime = ""
        if(selectedClinic.giveNumType === "2") {
            if(selectedClinic.giveNumStartTime === null || selectedClinic.giveNumEndTime === null) {
                const msg = `給診失敗: 此診間為依時間給號，尚未設定時間`
                showToast({message:msg, type:ToastTypeEnum.Error})
                return
            }
            // 給號方式依時間
            startViewDatetime = time2String(selectedClinic.giveNumStartTime, "YYYY-MM-DD HH:mm:00")
            endViewDatetime = time2String(selectedClinic.giveNumEndTime, "YYYY-MM-DD HH:mm:00")
        }
        
        //新增預約資料
        regsAddReg({
            "patientId": patientData.patientId, //病例ID
            "zone": inputZone, //院區代碼
            "clinicId": selectedClinic.clinicId, //月班識別碼
            "encounterDate": selectedClinic.encounterDate, //看診日期
            "apn": selectedClinic.apn, //時段
            "divNo": selectedClinic.divNo, //科別
            "doctorNo": selectedClinic.doctorNo, //醫師
            "clinicNo": selectedClinic.clinicNo, //診間號
            "clinicName": selectedClinic.clinicName, //診室名稱
            "ptType": baseQueryCondition.ptType, //身份別
            "reserveType": '4', //掛號別
            "forwardRegFlag": false, //是否可往前掛號,
            "startViewDatetime": startViewDatetime, //掛號看診開始時間 依時間給號時必填
            "endViewDatetime": endViewDatetime //掛號看診結束時間 依時間給號時必填
        }).then(res => {
            //新增成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //設定給診號資料
                setGiveNumberData(res.data)
                //重新取得週班表
                const searchDate = time2String(inputDate, 'YYYY-MM-DD')
                queryQueryClinicOfTheWeek({
                    //科別
                    div: inputData.div,
                    //醫師
                    doctor: inputData.doctor,
                    //天數
                    day: inputData.day,
                    encounterDate: baseQueryCondition.encounterDate
                }, searchDate)
                //重新取得預約資訊Table
                getRegsQueryRegHistory(patientData.patientId, 1, appointmentPaginationProps.pageSize)
                //顯示給診號成功的彈窗
                setShowGiveNumberPopup(true)
                //清除
                setSelectedClinic(null)
            }
            else {
                const msg = `給診失敗: ${res.msg}`
                showToast({message:msg, type:ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDeptOptionList = () => {
        const splitArray = inputData.div ? inputData.div.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
            return deptArray.filter(div => div.divNo.includes(splitArray[0]) && div.divName.includes(splitArray[1]))
       }
       else {
           return fuzzySearchObjects(deptArray, inputData.div)
       }
    }

    /**
     * 取得醫師下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDoctorOptionList = () => {
        const splitArray = inputData.doctor ? inputData.doctor.split(" ") : []
        if(splitArray.length > 2) {
            return []
        }

        if(splitArray.length === 2) {
             return doctorArray.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        }
        else {
            return fuzzySearchObjects(doctorArray, inputData.doctor)
        }
    }

    /**
     * 取得預約清單進階搜尋後的結果
     */
    const getAdvanceSearchAppointmentList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(appointmentSearchText)) {
            return appointmentTableData
        } else { //如果搜尋文字不為空
            const lowerCaseSearchText = appointmentSearchText.toLowerCase()
            return appointmentTableData.filter(data => {
                return (data.zoneName != null && data.zoneName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.encounterDate !== null && time2String(data.encounterDate, "YYYY-MM-DD").includes(lowerCaseSearchText)) ||
                    (data.patientName != null && data.patientName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.doctorName != null && data.doctorName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.divName != null && data.divName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.clinicName != null && data.clinicName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.apnName != null && data.apnName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                    (data.viewNo != null && data.viewNo.toString().toLowerCase().includes(lowerCaseSearchText))

            })
        }
    }

    /**
     * 科別代號方框的值變動時
     * @return {void}
     */
    const handleInputDeptNoOnChange =(e) => {
        setInputData({ ...inputData, div: e.target.value})
    }

    /**
     * 科別代號方框取得焦點時
     * @return {void}
     */
    const handleInputDeptNoOnFocus =(e) => {
        setDeptOptionList(getDeptOptionList())
        setShowDeptDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDeptOptionOnClick = (item) => {
        setInputData({
            ...inputData,
            div: `${item.divNo} ${item.divName}`
        })

        setShowDeptDropDown(false)
    }
    
    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e) => {
        setInputData({ ...inputData, doctor: e.target.value})
    }

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorDropDown(true)
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        setInputData({
            ...inputData,
            doctor: `${item.userNo} ${item.userName}`
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 天數輸入框Input事件
     * @return {void}
     */
    const handleInputDayOnInput = (e) => {
        const value = e.target.value
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '')
    }

    /**
     * 天數輸入框值變更事件
     * @return {void}
     */
    const handleInputDayOnChange = (e) => {
        setInputData({
            ...inputData,
            day: e.target.value
        })
    }

    /**
     * 處理預約資訊查詢方框變更時
     * @param event {Event} event
     * @return void
     */
    const handleSearchAppointmentTextOnChange = (event) => {
        setAppointmentSearchText(event.target.value)
    }

    /**
     * 取得病人預約資訊
     * @param page 查詢第幾頁
     * @param pageSize 每頁資料筆數
     * @return {void}
     */
    const getRegsQueryRegHistory = (patientId, page, pageSize) => {
        regsQueryRegHistory({
            patientId: patientId,
            pageNum: page,
            pageSize: pageSize
        }).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            if (res.err === ApiErrorStatusEnum.Success) {
                const resultData = !objectIsEmpty(data) && !arrayIsEmpty(data.dataList) ? data.dataList.map(item => ({...item, checked: false})) : []
                setAppointmentTableData(resultData)
                setAppointmentPaginationProps({
                    ...appointmentPaginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                setAppointmentTableData([])
                setAppointmentPaginationProps({
                    ...appointmentPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })

                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢當週班表
     * @param {Object} searchInput 查詢條件
     */
    const queryQueryClinicOfTheWeek = (searchInput, searchDate) => {
        setInputDate(searchDate)

        regsQueryClinicOfTheWeek({
            encounterDate: time2String(searchDate, "YYYY-MM-DD 00:00:00"),
            doctorNo: searchInput.doctor ? searchInput.doctor.split(' ')[0] : null,
            divNo: searchInput.div ? searchInput.div.split(' ')[0] : null
        }).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) { 
                setSelectedClinic(null)
                setWeekScheduleData(data)
            }
            else {
                setWeekScheduleData([])

                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onAppointmentPaginationPageOnChange = (page) => {
        getRegsQueryRegHistory(patientData.patientId, page, appointmentPaginationProps.pageSize)
    }

    /**
     * 上一頁點擊事件
     */
    const onAppointmentPaginationPreviousOnClick = () => {
        const page = (appointmentPaginationProps.currentPage - 1) > 1 ? (appointmentPaginationProps.currentPage - 1) : 1
        getRegsQueryRegHistory(patientData.patientId, page, appointmentPaginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onAppointmentPaginationNextOnClick = () => {
        const page = (appointmentPaginationProps.currentPage + 1) < appointmentPaginationProps.totalPageSize ? (appointmentPaginationProps.currentPage + 1) : appointmentPaginationProps.totalPageSize
        getRegsQueryRegHistory(patientData.patientId, page, appointmentPaginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onAppointmentPaginationPageSizeOnChange = (e) => {
        if (arrayIsEmpty(advSearchAppointmentList)) {
            setAppointmentPaginationProps({
                ...appointmentPaginationProps,
                pageSize: e.target.value
            })
            return
        }
        // 設定每頁資料筆數
        getRegsQueryRegHistory(patientData.patientId, 1, e.target.value)
    }

    /**
     * 關閉掛號完成的彈窗
     */
    const handleCloseGiveNumberPopup = () => {
        setGiveNumberData({})
        setShowGiveNumberPopup(false)
    }

    /**
     * 點選退掛按鈕事件
     */
    const handleCancelAppointmentOnClick = (appointment) => {
        const array = []
        array.push(appointment)
        setCancelRegArray(array)
        setShowCancelAppointmentMessage(true)
    }

    /**
     * 關閉退掛Popup
     */
    const handleCloseCancelAppointmentPopup = () => {
        setShowCancelAppointmentMessage(false)
    }

    /**
     * 退掛結果事件
     */
    const handleCancelAppointmentResult = (isSuccessed, msg) => {
        setShowCancelAppointmentMessage(false)
        showToast({message:msg, type:isSuccessed ? ToastTypeEnum.Success : ToastTypeEnum.Warning})
        //重新取得預約資訊Table
        getRegsQueryRegHistory(patientData.patientId, 1, appointmentPaginationProps.pageSize)

        //重新取得週班表
        const searchDate = time2String(inputDate, 'YYYY-MM-DD')
        queryQueryClinicOfTheWeek({
            //科別
            div: inputData.div,
            //醫師
            doctor: inputData.doctor,
            //天數
            day: inputData.day,
            encounterDate: baseQueryCondition.encounterDate
        }, searchDate)
    }

    /**
     * 掛號給號時間行事曆儲存事件
     * @param {Object} timeRangObj 時間區間
     */
    const handleAppointmentCalendarOnSave = (timeRangObj) => {
        setSelectedClinic({
            ...selectedClinic,

            //新增兩個property，用來記錄掛號時間區間
            //給號開始時間
            giveNumStartTime: objectIsEmpty(timeRangObj) ? null : timeRangObj.start,
            //給號結束時間
            giveNumEndTime: objectIsEmpty(timeRangObj) ? null : timeRangObj.end,
        })
    }

    const getGiveNumberTimeRangeText = () => {
        if(!objectIsEmpty(selectedClinic)) {
            const start = time2String(selectedClinic.giveNumStartTime, "HH:mm")
            const end = time2String(selectedClinic.giveNumEndTime, "HH:mm")
            return (stringIsEmpty(start) || stringIsEmpty(end)) ? "" : `${start} ~ ${end}`
        }
        return ""
    }

    /**
     * 取得預約資訊table的醫師欄位內容
     * @param appointmentData {Object} 預約資料
     */
    const getAppointmentTableDoctorFieldContent = (appointmentData) => {
        if(!stringIsEmpty(appointmentData.locumDoctorName)) {
            return `${appointmentData.locumDoctorName}[代]`
        }
        return appointmentData.doctorName
    }

    /**
     * 渲染診間日時段項目
     * @param {String} apn 時段代號
     * @param {Array} clinicArray 診間array
     * @returns 
     */
    const renderClinicOfDayListByApn = (apn, clinicArray) => {
        //找出時段(apn)的班表
        let filteredClinics = arrayIsEmpty(clinicArray) ? null : clinicArray.filter(clinic => clinic.apn === Number(apn))
    
        //判斷是否被選擇
        const isSelected = (data) => {
            if(!objectIsEmpty(data) && !objectIsEmpty(selectedClinic)) {
                if(data.divNo !== selectedClinic.divNo || 
                    data.doctorNo !== selectedClinic.doctorNo ||
                    data.clinicNo !== selectedClinic.clinicNo ||
                    data.apn !== selectedClinic.apn || 
                    data.encounterDate !== selectedClinic.encounterDate) 
                {
                        return false
                }

                return true
            }
            return false
        }
        /**
         * 是否為可以掛號的時段
         */
        const isRegistrationPeriod = (clinic) => {
            const currentDate = new Date()
            const targetDate = new Date(clinic.encounterDate)
            const tmpCurrentDate = new Date(currentDate)

            tmpCurrentDate.setHours(0, 0, 0, 0)
            if(targetDate < tmpCurrentDate) {
                return false
            }

            if(clinic.stopClinicFlag) {
                //停診
                return false
            }

            
            if(Number(time2String(targetDate, "YYYYMMDD")) === Number(time2String(tmpCurrentDate, "YYYYMMDD"))) {
                //同一天
                if(currentDate.getHours() >= 21) {
                    //晚診最後時間後
                    return false
                }

                if(clinic.apn === 1) {
                    //早診時段
                    if(currentDate.getHours() >= 12) {
                        //超過中午12點
                        
                        return false
                    }
                }
                else if(clinic.apn === 2) {
                    //午診時段
                    if(currentDate.getHours() >= 17) {
                        //超過中午12點
                        return false
                    }
                }
            }

            if(clinic.regFullFlag) {
                //額滿
                return false
            }

            return true
        }

        const clinicItemBkColor = (disabled, clinic) => {
            if(disabled) {
                return "bg-[#d4d4d8]"
            }

            if(isSelected(clinic)) {
                return "bg-[#bee3f8]"
            }

            return ""
        }

        /**
         * 取得診室醫生名
         * @param {Object} clinicData 
         * @returns 
         */
        const getDoctorName = (clinicData) => {
            if(!stringIsEmpty(clinicData.locumDoctorName)) {
                return `${clinicData.locumDoctorName} [代]`
            }
            return `${clinicData.doctorName}`
        }

        return (
            <div className="weekContainer w-full p-1 min-w-[107px] max-w-[105px] flex justify-center">
                <div className="w-full h-full p-1 border border-[#D4D4D8] rounded-md text-left overflow-y-auto">
                {
                    !arrayIsEmpty(filteredClinics) && filteredClinics.map((item, itemIndex) => {
                        const disabled = !isRegistrationPeriod(item) 
                        return (
                            <div 
                                key={itemIndex} 
                                className={`${clinicItemBkColor(disabled, item)} text-wrap break-all cursor-pointer`}
                                onClick={() => handleClinicOnClick(disabled, item)}>
                                {getDoctorName(item)}/{item.clinicName}/({item.currentRegCount}/{item.totalRegLimit})
                            </div>
                        )})
                }
                </div>
            </div>
        )
    }
    
    const renderGivenState = () => {
        return (
            <div className="min-w-[550px]" >
                <div className="flex flex-row space-x-4">
                    <div>
                        <label>已掛：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.regCount != null?clinicStatusData.regCount:""}人
                        </label>
                    </div>
                    <div>
                        <label>完診：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.finishCount != null?clinicStatusData.finishCount:""}人
                        </label>
                    </div>
                    <div>
                        <label>待看：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.unfinishCount != null?clinicStatusData.unfinishCount:""}人
                        </label>
                    </div>
                </div>
                <div className="flex flex-row space-x-4 mt-3.5">
                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('*')}`}>
                        <input
                            type="checkbox"
                            checked={checkedGiven}
                            onChange={handleGivenOnChange}
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            style={{accentColor: "#38A169"}}
                        />
                        <label className="text-[#18181b]">已給號</label>
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('A')}`}>
                        <input
                            type="checkbox"
                            checked={checkedNotYetGiven}
                            onChange={handleNotYetGivenOnChange}
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            style={{accentColor: "#38A169"}}
                        />
                        <label className="text-[#18181b]">未給號</label>
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('R')}`}>
                        <input
                            type="checkbox"
                            checked={checkdAppointment}
                            onChange={handleAppointmentOnChange}
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            style={{accentColor: "#38A169"}}
                        />
                        <label className="text-[#18181b]">預約號</label>
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('F')}`}>
                        <input
                            type="checkbox"
                            checked={checkdFirstView}
                            onChange={handleFirstViewOnChange}
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            style={{accentColor: "#38A169"}}
                        />
                        <label className="text-[#18181b]">科初診</label>
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('X')}`}>
                        <input
                            type="checkbox"
                            checked={checkdNoGiven}
                            onChange={handleNoGivenOnChange}
                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            style={{accentColor: "#38A169"}}
                        />
                        <label className="text-[#18181b]">不給號</label>
                    </div>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                {
                    !objectIsEmpty(clinicStatusData) && !arrayIsEmpty(clinicStatusData.clinicStatusList) &&
                    clinicStatusData.clinicStatusList.map((item, index) => (
                        <div
                            key={index}
                            className={`flex w-[100px] h-[40px] border-2 rounded-[4px] justify-center items-center
                                    ${numberBkColor(item.viewTypeEnumCode)} ${numberBorderColor(item.viewTypeEnumCode)}`}>
                            <label className="text-[#4299E1]">{item.viewNo}：</label>
                            <label>{time2String(item.startDateTime, "HH:mm")}</label>

                        </div>
                    ))
                }
                </div>
            </div>
        )
    }

    /**
     * 取得彈窗元件
     * @return {JSX.Element} 彈窗元件
     */
    const getContent = () => (
        <>
            <div className="w-full p-4 pb-2">
                {/*欲掛之病人資訊*/}
                <div className="flex py-2.5 mb-2">
                    <p>門診/出院日期：{time2String(baseQueryCondition.encounterDate, "YYYY-MM-DD")}，{patientData.patientId}，{patientData.patientName}，{patientData.idNo}，{patientData.age}歲，{time2String(patientData.birthDate, "YYYY-MM-DD")}</p>
                </div>
                {/*Filter 區塊*/}
                <div className="flex justify-between">
                    <div className="flex mb-2.5">
                        {/*科別輸入*/}
                        <div className="flex items-center mr-3.5">
                            <p>{t('general.department')}：</p>
                            <div className="flex items-center relative" ref={dropdownDepartmentRef}>
                                <input 
                                    className="w-[170px] h-10 px-4 border border-[#D4D4D8] rounded-md pl-8 bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                                    type="text" value={inputData.div}
                                    onChange={handleInputDeptNoOnChange}
                                    onFocus={handleInputDeptNoOnFocus}/>
                                {
                                    showDeptDropDown && !arrayIsEmpty(deptOptionList) && 
                                    (
                                        <div
                                            className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                {
                                                    deptOptionList.map((item, index) =>
                                                        <div
                                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                            role="button" key={index}
                                                            onClick={() => handleDeptOptionOnClick(item)}
                                                            >
                                                            <p>{`${item.divNo} ${item.divName}`}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {/*醫師輸入*/}
                        <div className="flex items-center mr-3.5">
                            <p>{t('general.doctor')}：</p>
                            <div className=" flex items-center relative" ref={dropdownDoctorRef}>
                                <input 
                                    className="w-[170px] h-10 px-4 border border-[#D4D4D8] rounded-md pl-8 bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                                    type="text" value={inputData.doctor}
                                    onChange={handleInputDoctorNoOnChange}
                                    onFocus={handleInputDoctorNoOnFocus}/>
                                {
                                    showDoctorDropDown && !arrayIsEmpty(doctorOptionList) && 
                                    (
                                        <div
                                            className="bg-white z-10 absolute left-0 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                {
                                                    doctorOptionList.map((item, index) =>
                                                        <div
                                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                            role="button" key={index}
                                                            onClick={() => handleDoctorOptionOnClick(item)}
                                                            >
                                                            <p>{`${item.userNo} ${item.userName}`}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {/*查閱天數*/}
                        <div className="flex items-center mr-3.5">
                            <p>天數：</p>
                            <BaseInput
                                className='bg-white w-[74px] h-10 px-2 text-black rounded-md border py-1 border-gray-300 pl-4'
                                type={InputTypeEnum.Number}  placeholder={""} value={inputData.day} min={0}
                                onInput={handleInputDayOnInput}
                                onChange={handleInputDayOnChange}/>
                        </div>
                        <DefaultButton 
                            type="primary" text={t('general.query')}
                            onClickFn={handleSearchOnClick}/>
                    </div>
                    <button
                        className={`h-10 border-[2px] border-[#D4D4D8] bg-[#fafafa] px-[16px] py-[8px] rounded-[4px] font-medium
                                    ${objectIsEmpty(selectedClinic) ? "text-[#d4d4d8]" : "hover:bg-[#f4f4f5] active:bg-[#E4E4E7]"}`}
                        disabled={objectIsEmpty(selectedClinic)}
                        onClick={handleAddRegsOnClick}>
                        給診號
                    </button>
                </div>
                {/**/}
                <div className="flex flex-row w-full space-x-2 min-h-[calc(100vh-235px)] max-h-[calc(100vh-235px)] overflow-x-auto">
                    <div className="w-[30%]">
                        {/*快速篩選*/}
                        <div className="w-full h-[28%] py-2">
                            <div className="w-full h-full p-2 space-y-3 border border-[#D4D4D8] rounded-md  overflow-y-auto">
                                <div className="flex items-center">
                                    <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                                    <p className="text-[#2B6CB0] text-xl mr-5">快速篩選</p>
                                </div>
                                <div className="grid grid-cols-4 gap-2">
                                {
                                    quickSelectCandidateArray.map((item) => (
                                        <button 
                                            className="p-2 bg-[#FAFAFA] border-[2px] border-[#D4D4D8] rounded-[4px] text-base hover:bg-[#f4f4f5] active:bg-[#E4E4E7]" key={item.justnameNo}
                                            onClick={() => handleQuickSelectItemOnClick(item)}>
                                            {item.justnameName}
                                        </button>
                                    ))
                                }
                                </div>
                            </div>
                        </div>
                        {/*病人排檢資訊*/}
                        <div className="w-full h-[35%] py-2">
                            <div className="w-full h-full p-2 space-y-3 border border-[#D4D4D8] rounded-md overflow-y-auto">
                                <div className="flex items-center">
                                    <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                                    <p className="text-[#2B6CB0] text-xl mr-5">病人排檢資訊</p>
                                    <SearchInput className="h-10 bg-white w-full px-2 text-black rounded-[6px] border py-1 border-gray-300 pl-8" placeholder="進階搜尋"/>
                                </div>

                                <ul className="tableList appointmentRegExamList mt-3 overflow-y-auto overflow-x-auto h-[calc(100%-50px)] max-h-[calc(100%-50px)] overflow-y-auto">
                                    <li className="title text-base text-[#18181b]">
                                        <div className="p-1">{t('Regs.general.campus')}</div>
                                        <div className="p-1">檢查日期</div>
                                        <div className="p-1">時間</div>
                                        <div className="p-1">{t('general.department')}</div>
                                        <div className="p-1">類別</div>
                                        <div className="p-1">檢查項目</div>
                                    </li>
                                { /*   <li>
                                        <div>義大</div>
                                        <div>2024-03-31</div>
                                        <div>10:00</div>
                                        <div>心臟內科</div>
                                        <div>EK</div>
                                        <div>E.K.G.
                                            (Electrocardiography)
                                        </div>
                                    </li>*/}
                                </ul>
                            </div>
                        </div>
                        {/*病人預掛資料*/}
                        <div className="w-full h-[37%] py-2">
                            <div className="w-full h-full p-2 space-y-3 border border-[#D4D4D8] rounded-md overflow-y-auto">
                                <div className="flex items-center">
                                    <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                                    <p className="text-[#2B6CB0] text-xl mr-5">病人預約資訊</p>
                                    <SearchInput 
                                        className="h-10 bg-white w-full px-2 text-black rounded-[6px] border py-1 border-gray-300 pl-8" placeholder="進階搜尋"
                                        searchText={appointmentSearchText}
                                        onChange={(e) => handleSearchAppointmentTextOnChange(e)}/>
                                </div>

                                <ul className="tableList appointmentRegRecordList mt-3 overflow-y-auto overflow-x-auto h-[calc(100%-50px)] max-h-[calc(100%-50px)]">
                                    <li className="title text-base text-[#18181b]">
                                        <div className="p-1">退掛</div>
                                        <div className="p-1">{t('Regs.general.campus')}</div>
                                        <div className="p-1">門診日</div>
                                        <div className="p-1">{t('general.doctor')}</div>
                                        <div className="p-1">{t('general.department')}</div>
                                        <div className="p-1">{t('Regs.general.clinicName')}</div>
                                        <div className="p-1">{t('Regs.general.timeSlot')}</div>
                                        <div className="p-1">看診號</div>
                                    </li>
                                    {
                                        advSearchAppointmentList.map((appointment, index) =>
                                            <li key={appointment.regId}>
                                                <div>
                                                    <button
                                                        className="flex justify-center w-[52px] py-1.5 border-[2px] border-[#2B6CB0] rounded-[6px] text-[14px] text-[#2B6CB0] hover:border-[#3182CE] hover:text-[#3182CE] active:border-[#2C5282] hover:text-[#2C5282]"
                                                        onClick={() => handleCancelAppointmentOnClick(appointment)}>
                                                        退掛
                                                    </button>
                                                </div>
                                                <div>{appointment.zoneName}</div>
                                                <div>{time2String(appointment.encounterDate, "YYYY-MM-DD")}</div>
                                                <div>{getAppointmentTableDoctorFieldContent(appointment)}</div>
                                                <div>{appointment.divName}</div>
                                                <div>{appointment.clinicName}</div>
                                                <div>{appointment.apnName}</div>
                                                <div>{appointment.viewNo !== null ?appointment.viewNo : `${time2String(appointment.startViewDatetime, "HH:mm")}  ~ ${time2String(appointment.endViewDatetime, "HH:mm")}`}</div>
                                            </li>
                                        )
                                    }
                                </ul>

                                {/*分頁*/}
                                <div className="flex justify-end w-full">
                                    <Pagination
                                        totalPageSize={appointmentPaginationProps.totalPageSize}
                                        currentPage={appointmentPaginationProps.currentPage}
                                        pageSize={appointmentPaginationProps.pageSize}
                                        totalSize={appointmentPaginationProps.totalItemSize}
                                        onPageOnChange={(page) => onAppointmentPaginationPageOnChange(page)}
                                        onPrevPageOnClick={onAppointmentPaginationPreviousOnClick}
                                        onNextPageOnClick={onAppointmentPaginationNextOnClick}
                                        onPageSizeChange={onAppointmentPaginationPageSizeOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*醫師週班表*/}
                    <div className="w-[50%] py-1">
                        <div className="w-full h-full p-2 space-y-3 border border-[#D4D4D8] rounded-md  overflow-y-auto">
                            <div className="flex min-w-[810px]">
                                <div className="flex items-center">
                                    <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                                    <p className="text-[#2B6CB0] text-xl mr-5">醫師當週班表</p>
                                </div>
                                <div className="flex">
                                    <select 
                                        className="h-10 pl-4 border border-[#D4D4D8] rounded-md min-w-[166px] text-[#3F3F46] mr-4"
                                        value={inputZone}
                                        onChange={handleZoneSelectOnChange}>
                                    {
                                        !arrayIsEmpty(zonesetList) && zonesetList.map((item) => 
                                        (
                                            <option key={item.zone} value={item.zone}>{item.zoneName}</option>
                                        ))
                                    }
                                    </select>
                                    <button 
                                        className="h-10 p-2 px-3 border-[2px] border-[#D4D4D8] bg-[#FAFAFA] rounded-md mr-2.5 hover:bg-[#f4f4f5] active:bg-[#E4E4E7]"
                                        onClick={handlePreviousWeekOnClick}>
                                        上一週
                                    </button>
                                    <BaseInput 
                                        className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-md mr-2.5"
                                        type={InputTypeEnum.Date} 
                                        value={inputDate}
                                        onChange={handleInputDateOnChange}
                                        inputMode="date"/>
                                    <button 
                                        className="h-10 p-2 px-3 border-[2px] border-[#D4D4D8] bg-[#FAFAFA] rounded-md hover:bg-[#f4f4f5] active:bg-[#E4E4E7]"
                                        onClick={handleNextWeekOnClick}>
                                        下一週
                                    </button>
                                </div>
                            </div>
                            {/*週班Content*/}
                            <div className="flex flex-col min-w-[810px] w-full overflow-x-auto overflow-y-auto">
                                {/*區塊 - 日期*/}
                                <div className="w-full py-1 mb-[7px]">
                                    <div className="w-full flex flex-row">
                                        <div className="min-w-[50px] max-w-[50px]">

                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[0]}(一)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[1]}(二)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[2]}(三)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[3]}(四)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[4]}(五)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[5]}(六)</p>
                                        </div>
                                        <div className="w-full min-w-[107px] max-w-[105px] flex justify-center">
                                            <p>{weekDates[6]}(日)</p>
                                        </div>
                                    </div>
                                </div>
                                {/*區塊 - 早*/}
                                <div className="w-full h-[184px] min-h-[184px] bg-[#EBF8FF] flex flex-row">
                                    <div className="min-w-[50px] max-w-[50px] items-center flex justify-center">
                                        {t('general.dateTime.timeslot.short.morning')}
                                    </div>
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.monList)) ? null : weekScheduleData.monList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.tueList)) ? null : weekScheduleData.tueList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.wedList)) ? null : weekScheduleData.wedList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.thuList)) ? null : weekScheduleData.thuList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.friList)) ? null : weekScheduleData.friList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.satList)) ? null : weekScheduleData.satList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(1, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.sunList)) ? null : weekScheduleData.sunList)
                                    }

                                </div>
                                {/*區塊 - 午*/}
                                <div className="w-full h-[184px] min-h-[184px] bg-[#FEFCE8] flex flex-row">
                                    <div className="min-w-[50px] max-w-[50px] items-center flex justify-center">
                                        {t('general.dateTime.timeslot.short.afternoon')}
                                    </div>
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.monList)) ? null : weekScheduleData.monList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.tueList)) ? null : weekScheduleData.tueList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.wedList)) ? null : weekScheduleData.wedList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.thuList)) ? null : weekScheduleData.thuList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.friList)) ? null : weekScheduleData.friList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.satList)) ? null : weekScheduleData.satList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(2, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.sunList)) ? null : weekScheduleData.sunList)
                                    }
                                </div>
                                {/*區塊 - 晚*/}
                                <div className="w-full h-[184px] min-h-[184px] bg-[#FAF5FF] flex flex-row">
                                    <div className="min-w-[50px] max-w-[50px] items-center flex justify-center">
                                        {t('general.dateTime.timeslot.short.night')}
                                    </div>
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.monList)) ? null : weekScheduleData.monList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.tueList)) ? null : weekScheduleData.tueList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.wedList)) ? null : weekScheduleData.wedList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.thuList)) ? null : weekScheduleData.thuList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.friList)) ? null : weekScheduleData.friList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.satList)) ? null : weekScheduleData.satList)
                                    }
                                    {
                                        renderClinicOfDayListByApn(3, (objectIsEmpty(weekScheduleData) || arrayIsEmpty(weekScheduleData.sunList)) ? null : weekScheduleData.sunList)
                                    }
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/*診號狀況*/}
                    <div className="py-1">
                        <div className={`w-full ${showViewNoStatePanel? "h-full" : ""} p-2 space-y-3 border border-[#D4D4D8] rounded-md  overflow-y-auto`}>
                            <div className="flex justify-between items-center">
                                <div className="flex">
                                    <div className="w-2 bg-[#2B6CB0] h-[25px] mr-2"></div>
                                    <p className="text-[#2B6CB0] text-xl">診號狀況</p>
                                </div>
                                {
                                    !objectIsEmpty(selectedClinic) && selectedClinic.giveNumStartTime !== null && selectedClinic.giveNumEndTime !== null &&
                                    <div className="mx-[10px]">{getGiveNumberTimeRangeText()}</div>
                                }
                                {
                                    showViewNoStatePanel && 
                                    <button
                                        className="text-[#2B6CB0] text-lg hover:text-[#3182CE] active:text-[#2C5282]"
                                        onClick={handleCloseViewNumberStatePanel}>
                                        收合
                                    </button>
                                }
                            </div>
                            {
                                !showViewNoStatePanel? (
                                    <div className="flex flex-row-reverse">
                                        <button
                                            className="text-[#2B6CB0] text-lg hover:text-[#3182CE] active:text-[#2C5282]"
                                            onClick={handleOpenViewNumberStatePanel}>
                                            收合
                                        </button>
                                    </div>
                                ) : (
                                    !objectIsEmpty(selectedClinic) && !stringIsEmpty(selectedClinic.giveNumType) && (
                                        selectedClinic.giveNumType === "2" ?
                                        <TimeCalendar 
                                            apn={selectedClinic.apn}
                                            choiceDate={selectedClinic.encounterDate}
                                            giveNumberTimeRangeRecord={{start:selectedClinic.giveNumStartTime, end:selectedClinic.giveNumEndTime}}
                                            patientData={patientData}
                                            clinicId={selectedClinic.clinicId}
                                            onSave={handleAppointmentCalendarOnSave}/> :
                                        renderGivenState()
                                    )
                                )
                            }
                            
                            
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

    useEffect(() => {
        // 設定是否顯示彈窗狀態
        if (!show) {
            setWeekScheduleData([])
        }
        else {
            setInputData({
                ...inputData,
                //科別
                div: baseQueryCondition.div,
                //醫師
                doctor: baseQueryCondition.doctor,
                //天數
                day: baseQueryCondition.day
            })

            const addDay = parseInt(baseQueryCondition.day)
            const searchDate = time2String(new Date(baseQueryCondition.encounterDate).setDate(new Date(baseQueryCondition.encounterDate).getDate() + addDay), 'YYYY-MM-DD')
            //查詢週班表
            queryQueryClinicOfTheWeek({
                //科別
                div: baseQueryCondition.div,
                //醫師
                doctor: baseQueryCondition.doctor,
                //天數
                day: baseQueryCondition.day,
                encounterDate: baseQueryCondition.encounterDate
            }, searchDate)
            //查詢病人預約資訊
            getRegsQueryRegHistory(patientData.patientId, 1, appointmentPaginationProps.pageSize)
            //查詢病人排檢資訊
        }
        setShowPopup(show)
    }, [show])

    // 使用 useMemo 確保計算只在組件初次渲染時運行
    useMemo(() => {
    }, [])

    /**
     * 監聽inputDate變化
     */
    useEffect(() => {
        const calculateWeekDates = (dateString) => {
            const date = new Date(dateString)
            const dayOfWeek = date.getDay() // 0 (Sunday) to 6 (Saturday)
            const startDate = new Date(date)
            startDate.setDate(date.getDate() - dayOfWeek + 1) // Start from Monday
      
            const dates = []
            for (let i = 0; i < 7; i++) {
              const current = new Date(startDate)
              current.setDate(startDate.getDate() + i)
              dates.push(time2String(current, "MM/DD")) // Convert to YYYY-MM-DD format
            }
            
            return dates
        }
        
        setWeekDates(calculateWeekDates(inputDate))
    }, [inputDate])

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [inputData.doctor])

    /**
     * 監聽科別代號輸入框變化
     */
    useEffect(() => {
        setDeptOptionList(getDeptOptionList())
    }, [inputData.div])

    /**
     * 監聽selectedClinic變化
     */
    useEffect(() => {
        if(objectIsEmpty(selectedClinic)) {
            setClinicStatusData(null)
            setShowViewNoStatePanel(false)
        }
    }, [selectedClinic])

    /**
     * 監聽appointmentTableData跟appointmentSearchText 變化
     */
    useEffect(() => {
        if (appointmentTableData !== null) {
            const list = getAdvanceSearchAppointmentList()
            setAdvSearchAppointmentList(list)
        } else {
            setAdvSearchAppointmentList(null)
        }
    }, [appointmentTableData, appointmentSearchText])

    return showPopup ?
        <div
            className="fixed top-0 left-0 pt-[10px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-50 overflow-scroll">
            <div className={`w-[95%] bg-white rounded-[6px]`}>
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                    {/*標題*/}
                    <h3 className="text-[20px]">診間/出院/科室預約掛號</h3>
                    {/*關閉按鈕*/}
                    <button className="flex items-center justify-center text-[18px] text-[#2B6CB0]"
                            onClick={closePopupButtonOnClick}>
                        {t('general.close')}
                    </button>
                </div>
                {getContent()}
            </div>

            {
                // 顯示掛號完成的彈窗
                <ShowGiveNumberPopup showPopup={showGiveNumberPopup}
                                     data={giveNumberData}
                                     handleClosePopup={handleCloseGiveNumberPopup}/>
            }
            {
                //退掛 視窗
                <CancelAppointmentMessagePopup
                    show={showCancelAppointmentMessage}
                    closePopupButtonOnClick={handleCloseCancelAppointmentPopup}
                    candidateDatas={cancelRegArray}
                    handleCancelResult={handleCancelAppointmentResult}/>
            }
        </div>
        :
        <></>
}

export default AppointmentRegistrationPopup