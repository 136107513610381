import {
    ApiErrorStatusEnum,
    PopupModeEnum,
    RegsMedicalTypeEnum,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    TimeslotEnum,
    RegsWeekModeEnum,
    stringIsEmpty,
    ToastTypeEnum,
    InputTypeEnum,
    arrayIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"
import searchIcon from "../../../../assets/images/search-interface-symbol.png"
import React, {useEffect, useState, useRef} from "react"
import { addRegsWeek, editRegsWeek, delRegsWeek } from "../../../../api/v1/Regs"
import { sloganQueryContentForWeekOrClinic } from "../../../../api/v1/Slogn"
import DeletePromptPopup from "./DeletePromptPopup"
import useToast from "../../../../hooks/useToast"
import { BaseInput } from "../../../Input/BaseInput"
import useOutsideClick from "../../../../hooks/useOutsideClick"

/**
 * 新增週維護彈出視窗
 * @param mode {PopupModeEnum} 新增或修改
 * @param filterMode {Number} 篩選模式
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param handleCreateSuccess {Function} 新增成功時
 * @param handleSaveSuccess {Function} 儲存成功時
 * @param handleDeleteSuccess {Function} 刪除成功時
 */
export const AddWeekMaintenancePopup = ({
                                            mode,
                                            filterMode,
                                            data, 
                                            closePopup,
                                            baseData,
                                            handleCreateSuccess,
                                            handleSaveSuccess,
                                            handleDeleteSuccess
}) => {
    const baseSettings = {
        //
        weekId: data.weekId ? data.weekId : '',
        // 預約最大看診人次
        maxPatient: data.maxPatient ? data.maxPatient.toString() : '100',
        // 現場最大看診人次
        onSiteNumber: data.onSiteNumber ? data.onSiteNumber.toString() : '100',
        // 看診時間(人/分)
        consultationTime: data.consultationTime ? data.consultationTime.toString() : '5',
        // 預約方式
        reservationMethod: data.reservationMethod ? data.reservationMethod : RegsWeekReservationMethodEnum.ClinicNumber,
        // 調病歷
        canGetMedicalRecords: data.canGetMedicalRecords ? data.canGetMedicalRecords : false,
        // 給號方式
        numberGivingMethod: data.numberGivingMethod ? data.numberGivingMethod : "9",
        // 診間開始展班日期
        effectiveDate: data.effectiveDate ? data.effectiveDate : "",
        // 收費別
        chargeType: data.chargeType ? data.chargeType : '',
        // 預約限制
        reservationLimit: data.reservationLimit ? data.reservationLimit : RegsWeekReservationLimitEnum.Normal,
        // 診別
        medicalType: data.medicalType ? data.medicalType : "O",
        // 展班月數
        exhibitionMonth: data.exhibitionMonth ? data.exhibitionMonth : 6,
        // 假日展班
        holidayExhibition: data.holidayExhibition ? data.holidayExhibition : false,
        // 開放網掛/App掛號
        webOrAppBooking: data.webOrAppBooking ? data.webOrAppBooking : false,
        // 開放網掛/App取消看診進度
        webOrAppCancel: data.webOrAppCancel ? data.webOrAppCancel : false,
        //網路預約期限
        onlineBookingDeadline: data.onlineBookingDeadline ? data.onlineBookingDeadline : 30,
        //看診項目
        consultationItems: data.consultationItems ? data.consultationItems : "empty",
        //診間地點
        clinicLocation: data.clinicLocation ? data.clinicLocation : "",
        //注意事項
        notes: data.notes ? data.notes : "",
        //診間標語
        clinicSlogan: data.clinicSlogan ? data.clinicSlogan : "",
        //醫生或科別
        doctorOrDepartment: data.doctorOrDepartment ? data.doctorOrDepartment : null,
        //診室名稱
        clinicName: data.clinicName ? data.clinicName : "",
        //時段
        timeslot: data.timeslot ? data.timeslot : null,
        //星期幾
        week: data.week ? data.week : null,
        // 醫師
        doctor: data.doctor ? data.doctor : "",
        // 科別
        department: data.department ? data.department : "",

        lockVersion: data.lockVersion
    }

    //展班月數Option
    const exhibitionMonthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
    //當前設定
    const [record, setRecord] = useState(baseSettings)

    /**
     * 是否顯示醫生下拉選單
     */
    const [showDoctorDropdown, setShowDoctorDropdown] = useState(false)

    /**
     * 是否顯示科別下拉選單
     */
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false)

    /**
     * 是否顯示收費別下拉選單
     */
    const [showChargeTypeDropdown, setShowChargeTypeDropdown] = useState(false)    
    //是否可以修改醫生欄位
    const [canModifyDoctor, setCanModifyDoctor] = useState(true)
    //是否可以修改科別欄位
    const [canModifyDepartment, setCanModifyDepartment] = useState(true)
    // 顯示刪除提示popup
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropdown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropdown(false),
    });

    /**
     * 搜尋文字改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (e) => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //設定醫生值
            setRecord({...record, doctor: e.target.value})
        }
    }

    /**
     * 醫生選項取得焦點事件
     * @return {void}
     */
    const handleDoctorOptionOnFocus = () => {
         //可以修改醫生時
         if (canModifyDoctor) {
            //根據模式來決定是否顯示下拉選單

            //設定下拉選項
            setDoctorOptionList(getFilterDoctorOptions())
            setShowDoctorDropdown(true)
        }
    }
    /**
     * 醫生選項失去焦點事件
     */
    const handleDoctorOnBlur = () => {
        //可以修改醫生時
        if (canModifyDoctor) {
            setTimeout(() => setShowDoctorDropdown(false), 150)
        }
    }

    /**
     * 醫師選項點擊事件
     * @param option {Object} 選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            let sloganText = ''
            if(!stringIsEmpty(record.department) && record.department.split(' ')[0] !== null &&
                !stringIsEmpty(option.userNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(option.userNo, record.department.split(' ')[0])
            }
            //設定醫生 & 標語
            setRecord({...record, doctor: `${option.userNo} ${option.userName}`, clinicSlogan: sloganText})
            //關閉醫生下拉選單
            setShowDoctorDropdown(false)

            
        }
    }

    /**
     * 取得過濾後的醫生選項
     * @return {Array} 過濾後的醫生選項
     */
    const getFilterDoctorOptions = () => {
        //可以修改醫生時
        if (true) {
            //取得搜尋文字
            const searchText = record.doctor
            // 搜尋文字為空
            if (searchText === '' || searchText === null) {
                return baseData.doctorList
            } else { //搜尋文字不為空
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //分割搜尋文字
                const splitSearchTextArray = searchText.split(' ')

                return baseData.doctorList.filter(doctor => hasSpace ?
                    (doctor.userNo.includes(splitSearchTextArray[0]) || doctor.userName.includes(splitSearchTextArray[1])) :
                    (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
                )
            }
        } else { //不可以修改醫生
            return []
        }
    }

    /**
     * 科別搜尋文字改變時
     * @param e {Event} 事件
     */
    const handleDepartmentSearchTextOnChange = (e) => {
        //可以修改科別時
        if (canModifyDepartment) {
            //設定科別值
            setRecord({...record, department: e.target.value})
        }
    }

    /**
     * 科別選項獲取焦點事件
     */
    const handleDepartmentOnFocus = () =>{
        //可以修改科別時
        if (canModifyDepartment) {
            setDepartmentOptionList(getFilterDepartmentOptions())
            setShowDepartmentDropdown(true)
        }
    }

    /**
     * 科別選項失去焦點事件
     */
    const handleDepartmentOnBlur = () => {
        //可以修改科別時
        if (canModifyDepartment) {
            setTimeout(() => setShowDepartmentDropdown(false), 150)
        }
    }

    const getSloganContentForWeekOrClinic = async (doctoorNo, divNo) => {
        let sloganText = ''
        await sloganQueryContentForWeekOrClinic({
            //醫師代碼
            doctorNo: doctoorNo,
            //科別代碼
            divNo: divNo,
            //時段
            apn: record.timeslot
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //成功
                sloganText = stringIsEmpty(res.data) ? "" : res.data
            }
            else {
                //失敗時
                showToast({message:res.msg, type:ToastTypeEnum.Error})
            }
        })
        return sloganText
    }

    /**
     * 取得科別選項
     * @return {Array} 科別選項
     */
    const getFilterDepartmentOptions = () => {
        //取得搜尋文字
        const searchText = record.department

        //搜尋文字為空
        if (searchText === '' || searchText === null) {
            return baseData.divisionList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.divisionList.filter(department => hasSpace ?
                (department.divNo.includes(splitSearchTextArray[0]) || department.divName.includes(splitSearchTextArray[1])) :
                (department.divNo.includes(searchText) || department.divName.includes(searchText))
            )
        }
    }

    /**
     * 科別選項點擊事件
     * @param option {Object} 選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            const departmentText = `${option.divNo} ${option.divName}`
            let sloganText = ''
            if(!stringIsEmpty(record.doctor) && record.doctor.split(' ')[0] !== null &&
                !stringIsEmpty(option.divNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(record.doctor.split(' ')[0], option.divNo)
            }
            //設定科別
            setRecord({...record, department: departmentText, clinicSlogan: sloganText})
            //關閉科別下拉選單
            setShowDepartmentDropdown(false)
        }
    }

    /**
     * 最大看診人次欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleMaxPatientOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定最大看診人次
            setRecord({...record, maxPatient: value.toString()})
        }
        else {
            setRecord({...record, maxPatient: ''})
        }
    }

    /**
     * 現場最大看診人次欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOnSiteNumberOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定現場最大看診人次
            setRecord({...record, onSiteNumber: value.toString()})
        }
        else {
            setRecord({...record, onSiteNumber: ''})
        }
    }

    /**
     * 看診時間(分/人)欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleConsultationTimeOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定看診時間
            setRecord({...record, consultationTime: value.toString()})
        }
        else {
            setRecord({...record, consultationTime: ''})
        }
    }

    /**
     * 預約方式欄位改變時
     * @param type {Number} 預約方式種類
     * @return {void}
     */
    const handleReservationMethodOnChange = (type) => setRecord({...record, reservationMethod:type})

    /**
     * 給號方式欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNumberGivingMethod = (e) => setRecord({...record, numberGivingMethod: e.target.value})

    /**
     * 預約限制欄位改變時
     * @param type {Number} 預約限制種類
     * @return {void}
     */
    const handleReservationLimitOnChange = (type) => setRecord({...record, reservationLimit: type})

    /**
     * 診室名稱欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicNameOnChange = (e) => setRecord({...record, clinicName: e.target.value})

    /**
     * 診間開始展班日期改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEffectiveDateOnChange = (e) => setRecord({...record, effectiveDate: e.target.value})

    /**
     * 診室地點改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicLocationOnChange = (e) => setRecord({...record, clinicLocation: e.target.value})

    /**
     * 診別欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleMedicalTypeOnChange = (e) => setRecord({...record, medicalType: e.target.value})

    /**
     * 調病歷改變時
     * @return void
     */
    const handleCanGetMedicalRecordsOnChange = () => setRecord({
        ...record,
        canGetMedicalRecords: !record.canGetMedicalRecords
    })

    /**
     * 收費別搜尋文字改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleChargeTypeSearchTextOnChange = (e) => setRecord({...record, chargeType: e.target.value})

    /**
     * 收費別選項獲取焦點事件
     * @return {void}
     */
    const handleChargeTypeSearchTextOnFocus = () => setShowChargeTypeDropdown(true)

    /**
     * 收費別選項失去焦點事件
     * @return {void}
     */
    const handleChargeTypeSearchTextOnBlur = () => setTimeout(() => setShowChargeTypeDropdown(false), 150)

    /**
     * 網掛/APP看診進度改變時
     * @return {void}
     */
    const handleWebOrAppBookingOnChange = () => setRecord({...record, webOrAppBooking: !record.webOrAppBooking})

    /**
     * 取得收費別filter後的資料
     * @return {Array}
     */
    const getFilterChargeTypeOptions = () => {
        //取得搜尋文字
        const searchText = record.chargeType

        //搜尋文字為空
        if (searchText === '') {
            return baseData.cashTypeList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.cashTypeList.filter(cash => hasSpace ?
                (cash.cashType.includes(splitSearchTextArray[0]) || cash.cashName.includes(splitSearchTextArray[1])) :
                (cash.cashType.includes(searchText) || cash.cashName.includes(searchText))
            )
        }
    }

    /**
     * 收費別選項點擊事件
     * @param cash {Object} 選項
     * @return {void}
     */
    const handleChargeTypeOptionOnClick = (option) => {
        // 選項不為空
        if (option !== null) {
            //設定收費別
            setRecord({...record, chargeType: `${option.cashType} ${option.cashName}`})
            //關閉收費別下拉選單
            setShowChargeTypeDropdown(false)
        }
    }

    /**
     * 網掛/APP取消看診進度改變時
     * @return {void}
     */
    const handleWebOrAppCancelOnChange = () => setRecord({...record, webOrAppCancel: !record.webOrAppCancel})

    /**
     * 網掛預約期限改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOnlineBookingDeadlineOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {

            setRecord({...record, onlineBookingDeadline: value})
        }
    }

    /**
     * 備註改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNotesOnChange = (e) => setRecord({...record, notes: e.target.value})

    /**
     * 診間標語改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicSloganOnChange = (e) => setRecord({...record, clinicSlogan: e.target.value})

    /**
     * 看診項目欄位改變時
     * @param e {Event} 事件
     * @returns 
     */
    const handleConsultationItemsOnChange = (e) => setRecord({...record, consultationItems: e.target.value})

    /**
     * 展班月數欄位改變時
     * @param {Event} e 事件 
     * @returns 
     */
    const handleExhibitionMonthOnChange = (e) => setRecord({...record, exhibitionMonth: e.target.value})
    
    /**
     * 假日展班改變時
     * @returns 
     */
    const handleHolidayExhibitionOnChange = () => setRecord({...record, holidayExhibition: !record.holidayExhibition})
    
    /**
     * 點擊新增按鈕
     * @return {void}
     */
    const handleSaveOnClick = () => {
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add
        if (isAdd) {
            if(stringIsEmpty(record.effectiveDate)) {
                //診間開始展班日期為空時
                showToast({message:"診間開始展班日期不可為空", type:ToastTypeEnum.Error})
                return
            }
 
            if(stringIsEmpty(record.doctor) || record.doctor.split(' ')[0] === null) {
                //醫師為空時
                showToast({message:"醫師不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.department) || record.department.split(' ')[0] === null) {
                //科別為空時
                showToast({message:"科別不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.clinicName)) {
                //診室地點為空時
                showToast({message:"診室名稱不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.clinicLocation)) {
                //診室地點為空時
                showToast({message:"診室地點不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.chargeType)) {
                //收費別為空時
                showToast({message:"收費別不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                //收費別為空時
                showToast({message:"預約最大看診人次有誤", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.onSiteNumber) || parseInt(record.onSiteNumber) <= 0) {
                //收費別為空時
                showToast({message:"現場最大看診人次有誤", type:ToastTypeEnum.Error})
                return
            }
            if(stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                //收費別為空時
                showToast({message:"看診時間有誤", type:ToastTypeEnum.Error})
                return
            }

            //儲存醫師週班
            addRegsWeek({
                //診間展班生效日期
                "effectiveDate": `${record.effectiveDate} 00:00:00`,
                //星期1,2,3,4,5,6,7
                "week": `${record.week}`,
                //時段
                "apn": record.timeslot,
                //醫生編號
                "doctorNo": record.doctor.split(' ')[0],
                //診室名稱
                "clinicName": record.clinicName,
                //科別
                "divNo": record.department.split(' ')[0],
                //預約最大看診人次
                "limit": parseInt(record.maxPatient),
                //現場最大看診人次
                "onsiteLimit": parseInt(record.onSiteNumber),
                //看診時間(人/分)
                "slotMin": parseInt(record.consultationTime),
                //預約方式
                "giveNumType": record.reservationMethod.toString(),
                //給號代碼
                "noType": record.numberGivingMethod,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                "sentChartFlag": record.canGetMedicalRecords,
                //診間收費別
                "cashType": record.chargeType.split(' ')[0],
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                "reserveLimitType": record.reservationLimit,
                ////診間類別  O:門診,T:外檢,P,預防保健
                "clinicType": record.medicalType,
                //展班月數
                "tranMonth": record.exhibitionMonth,
                //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                "webappFlag": record.webOrAppBooking,
                ////網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                "showProcessFlag": record.webOrAppCancel,
                //網掛預約期限(天)
                "webapptDay": record.onlineBookingDeadline,
                //診室地點
                "clinicLocation": record.clinicLocation,
                //看診項目
                "clinicMarkNo": record.consultationItems === "empty" ? null:record.consultationItems,
                //週班注意事項
                "weekNotice": record.notes,
                //診間標語
                "clinicSlogan": record.clinicSlogan,
                //假日展班, 0.FALSE , 1.TRUE
                "holidayexclFlag": record.holidayExhibition,
                //鎖定版本
                "lockVersion": 0
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //新增成功
                    const dayStr = getWeekStr(record.week.toString())
                    const timeslotString = getTimeslotString(data.timeslot)
                    const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${record.doctor.split(' ')[1]}`
                    handleCreateSuccess(msg)
                    //欄位值回復預設
                    setRecord(baseSettings)

                    //
                }
                else {
                    //儲存失敗時
                    showToast({message:res.msg, type:ToastTypeEnum.Error})
                    return
                }
            })
        }
        else {
            if(stringIsEmpty(record.effectiveDate)) {
                //診間開始展班日期為空時
                showToast({message:"診間開始展班日期不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.clinicName)) {
                //診室地點為空時
                showToast({message:"診室名稱不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.clinicLocation)) {
                //診室地點為空時
                showToast({message:"診室地點不可為空", type:ToastTypeEnum.Error})
                return
            }

            if(stringIsEmpty(record.chargeType)) {
                //收費別為空時
                showToast({message:"收費別不可為空", type:ToastTypeEnum.Error})
                return
            }
            if(stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                //收費別為空時
                showToast({message:"預約最大看診人次有誤", type:ToastTypeEnum.Error})
                return
            }
            if(stringIsEmpty(record.onSiteNumber) || parseInt(record.onSiteNumber) <= 0) {
                //收費別為空時
                showToast({message:"現場最大看診人次有誤", type:ToastTypeEnum.Error})
                return
            }
            if(stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                //收費別為空時
                showToast({message:"看診時間有誤", type:ToastTypeEnum.Error})
                return
            }
            //更新醫師週班
            editRegsWeek({
                //週班編號
                weekId: record.weekId,
                //診間展班生效日期
                effectiveDate: `${record.effectiveDate} 00:00:00`,
                //星期1,2,3,4,5,6,7
                week: `${record.week}`,
                //時段
                apn: record.timeslot,
                //醫生編號
                doctorNo: record.doctor.split(' ')[0],
                //診室名稱
                clinicName: record.clinicName,
                //科別
                divNo: record.department.split(' ')[0],
                //預約最大看診人次
                limit: parseInt(record.maxPatient),
                //現場最大看診人次
                onsiteLimit: parseInt(record.onSiteNumber),
                //看診時間(人/分)
                slotMin: parseInt(record.consultationTime),
                //預約方式
                giveNumType: record.reservationMethod.toString(),
                //給號代碼
                noType: record.numberGivingMethod,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                sentChartFlag: record.canGetMedicalRecords,
                //診間收費別
                cashType: record.chargeType.split(' ')[0],
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                reserveLimitType: record.reservationLimit,
                ////診間類別  O:門診,T:外檢,P,預防保健
                clinicType: record.medicalType,
                //展班月數
                tranMonth: record.exhibitionMonth,
                //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                webappFlag: record.webOrAppBooking,
                ////網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                showProcessFlag: record.webOrAppCancel,
                //網掛預約期限(天)
                webapptDay: record.onlineBookingDeadline,
                //診室地點
                clinicLocation: record.clinicLocation,
                //看診項目
                clinicMarkNo: record.consultationItems === "empty" ? null: record.consultationItems,
                //週班注意事項
                weekNotice: record.notes,
                //診間標語
                clinicSlogan: record.clinicSlogan,
                //假日展班, 0.FALSE , 1.TRUE
                holidayexclFlag: record.holidayExhibition,

                lockVersion: record.lockVersion
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //新增成功
                    const dayStr = getWeekStr(record.week.toString())
                    const timeslotString = getTimeslotString(data.timeslot)
                    const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${record.doctor.split(' ')[1]}`
                    handleSaveSuccess(msg)
                }
                else {
                    //儲存失敗時
                    showToast({message:res.msg, type:ToastTypeEnum.Error})
                    return
                }
            })
        }
    }

    /**
     * 點擊刪除按鈕
     * @return {void}
     */
    const handleDeleteOnClick = () => {
        setShowDeletePrompt(true)
        
    }

    const handleConfirmDeleteOnClick = () => {
        setShowDeletePrompt(false)

        delRegsWeek({
            weekId: record.weekId
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //刪除成功
                const dayStr = getWeekStr(data.week.toString())
                const timeslotString = getTimeslotString(data.timeslot)
                const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${data.doctor.split(' ')[1]}`
                handleDeleteSuccess(msg)
            }
            else {
                //刪除失敗時
                showToast({message:res.msg, type:ToastTypeEnum.Error})
                return
            }
        })
    }

    const handleCancelDeleteOnClick = () => {
        setShowDeletePrompt(false)
    }

    const getWeekStr = (week) => {
        let dayStr
        switch(week) {
            case "1":
                dayStr = t('general.dateTime.weekRange.1')
                break
            case "2":
                dayStr = t('general.dateTime.weekRange.2')
                break
            case "3":
                dayStr = t('general.dateTime.weekRange.3')
                break
            case "4":
                dayStr = t('general.dateTime.weekRange.4')
                break
            case "5":
                dayStr = t('general.dateTime.weekRange.5')
                break
            case "6":
                dayStr = t('general.dateTime.weekRange.6')
                break
            case "7":
                dayStr = t('general.dateTime.weekRange.7')
                break
        }
        return dayStr
    }

    const getTimeslotString = (timeslot) => {
        let timeslotString = ""
        switch(timeslot) {
            case TimeslotEnum.Morning:
                timeslotString = t('general.dateTime.timeslot.full.morning')
                break
            case TimeslotEnum.Afternoon:
                timeslotString = t('general.dateTime.timeslot.full.afternoon')
                break
            case TimeslotEnum.Night:
                timeslotString = t('general.dateTime.timeslot.full.night')
                break
        }
        return timeslotString
    }

    /**
     * 取得標題的內容
     * @return {String | JSX.Element}
     */
    const getHeaderContent = () => {
        const dayStr = getWeekStr(data.week.toString())
        const timeslotString = getTimeslotString(data.timeslot)

        return (
            mode === PopupModeEnum.Add ?
            <>
                {`${dayStr}，${timeslotString}`}
            </> :
            <>
                {`${dayStr}，${timeslotString}，${t('general.department')}：${data.department}，${t('general.doctor')}：${data.doctor}，診室名稱：${data.clinicName}`}
            </>
        )
    }

    /**
     * 只執行一遍
     */
    useEffect(() => {
        if (mode === PopupModeEnum.Add) {
            // 為新增模式
            //為醫生模式且有待醫生資料
            if (filterMode === RegsWeekModeEnum.Doctor && record.doctor) {
                
                //分割醫生資料
                const splitArray = record.doctor.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.doctorList.findIndex(doctor => doctor.userNo === splitArray[0] && doctor.userName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDoctor(true)
                }
            } else if (filterMode === RegsWeekModeEnum.Department && record.department) { //為科別模式且有待科別資料
                //分割科別資料
                const splitArray = record.department.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.divisionList.findIndex(department => department.divNo === splitArray[0] && department.divName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDepartment(false)
                }
            }
        }
        else {
            // 為修改模式
            // 不給予修改醫生
            setCanModifyDoctor(false)
            // 不給予修改科別
            setCanModifyDepartment(false)
        }
        // 取得掛號基礎資料
        
    }, [])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getFilterDoctorOptions())
    }, [record.doctor])
    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getFilterDepartmentOptions())
    }, [record.department])

    return (
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont">
            <div
                className={`flex ${mode === PopupModeEnum.Add ? 'w-[950px]' : 'w-[1015px]'} bg-[#ffffff] rounded-md text-lg relative`}
                onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-col w-full text-left ">
                    {/**Title */}
                    <div
                        className="h-[60px] px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 justify-start items-center gap-2.5 inline-flex">
                        <div className="grow shrink basis-0 text-black text-xl font-normal leading-7">
                            {mode === PopupModeEnum.Add ? t('components.Appointment.Popup.AddWeekMaintenancePopup.title') : "班表設定"}
                        </div>
                        <div className="text-sky-700 text-lg font-normal leading-7 cursor-pointer"
                             onClick={closePopup}>
                            {t('general.close')}
                        </div>
                    </div>
                    {/**Content */}
                    <div className="px-[24px] py-[16px]">
                        <div className="text-left justify-left flex flex-row justify-between w-full items-center px-5">
                            {
                                mode === PopupModeEnum.Add ?
                                <div className="flex flex-row justify-between text-[16px] items-center space-x-7">
                                    <div className="text-xl">
                                    {
                                        getHeaderContent()
                                    }
                                    </div>
                                    {/*醫師搜尋文字以及下拉式選單*/}
                                    <div className="flex flex-row text-[#18181B] items-center">
                                        <label>{t('general.doctor')}</label>
                                        <div className="px-2 flex flex-row space-x-2 items-center relative" ref={dropdownDoctorRef}>
                                            <input
                                                className={`bg-white w-[full] h-[40px] px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8]`}
                                                value={record.doctor}
                                                onChange={(e) => handleDoctorSearchTextOnChange(e)}
                                                onFocus={handleDoctorOptionOnFocus}
                                                onBlur={handleDoctorOnBlur}/>
                                            <img className="w-4 h-4 absolute top-w-full left-0 translate-x-4"
                                                alt="search-icon" src={searchIcon} role="button"/>
                                            {/*醫生下拉選單*/}
                                            <div
                                                className="bg-white z-10 absolute left-0 top-10 max-h-20 w-full flex flex-col">
                                                {
                                                    showDoctorDropdown && !arrayIsEmpty(doctorOptionList) && (
                                                        <div className="flex flex-col">
                                                            <div
                                                                className=" border-2 bg-white max-h-[150px] overflow-y-scroll">
                                                                {
                                                                    doctorOptionList.map((doctor, index) =>
                                                                        <div
                                                                            className=" bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                            key={index} role="button"
                                                                            onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                                            {`${doctor.userNo} ${doctor.userName}`}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*科別搜尋文字以及下拉式選單*/}
                                    <div className="flex flex-row items-center text-[#18181B]">
                                        <label>{t('general.department')}</label>
                                        <div className="px-2 flex flex-row space-x-2 items-center relative" ref={dropdownDepartmentRef}>
                                            <input
                                                className="bg-white w-full h-[40px] px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8]"
                                                value={record.department}
                                                onChange={(e) => handleDepartmentSearchTextOnChange(e)}
                                                onFocus={handleDepartmentOnFocus}
                                                onBlur={handleDepartmentOnBlur}/>
                                            <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-4"
                                                alt="search-icon" src={searchIcon} role="button"/>
                                            <div
                                                className="bg-white z-10 absolute left-0 top-10 max-h-20 w-64 flex flex-col">
                                                {
                                                    // 科別下拉選單
                                                    showDepartmentDropdown && !arrayIsEmpty(departmentOptionList) && (
                                                        <div className="flex flex-col">
                                                            <div
                                                                className="max-h-[150px] border-2 bg-white overflow-y-scroll">
                                                                {
                                                                    departmentOptionList.map((department, index) =>
                                                                        <div
                                                                            className="bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                            key={index} role="button"
                                                                            onClick={() => handleDepartmentOptionOnClick(department)}>
                                                                            {`${department.divNo} ${department.divName}`}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="items-center text-center w-full text-xl font-normal">
                                    {getHeaderContent()}
                                </div>
                            }
                        </div>
                        <div className="flex flex-row justify-between w-full pt-4 space-x-1 text-[16px]">
                            <div
                                className={`flex flex-row justify-between space-x-2 w-[55%]`}>
                                {/*標籤*/}
                                <div className="flex flex-col text-left w-[35%] space-y-2 px-2">
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        預約最大看診人次
                                    </label>

                                    <label className="flex items-center h-[40px] justify-end" htmlFor="">
                                        現場最大看診人次
                                    </label>

                                    <label className="flex items-center h-[40px] justify-end" htmlFor="time-per-person">
                                        看診時間(分/人)
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                        htmlFor="reservation-method">
                                        預約方式
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                        htmlFor="scheduling-method">
                                        給號方式
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end"
                                        htmlFor="reservation-restriction">
                                        預約限制
                                    </label>
                                    {
                                        mode === PopupModeEnum.Add ? (
                                            <label className="flex items-center h-[40px] justify-end">
                                                診室開始展班日期
                                            </label>
                                        ) : (
                                            <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                                {t("Regs.general.clinicName")}
                                            </label>
                                        )
                                    }
                                    {
                                        mode === PopupModeEnum.Add ? (
                                            <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                                {t("Regs.general.clinicName")}
                                            </label>
                                        ) : (
                                            <label className="flex items-center h-[40px] justify-end">
                                                診間開始展班日期
                                            </label>
                                        )
                                    }
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        {t("Regs.general.clinicLocation")}
                                    </label>
                                    <label className="flex items-center h-[40px] justify-end" htmlFor="max-people">
                                        診別
                                    </label>
                                </div>
                                {/*輸入方框*/}
                                <div className="flex flex-col text-left w-[65%] space-y-2 px-3 justify-center">
                                    {/** 預約最大看診人次 */}
                                    <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                        type="number" id="max-people" value={record.maxPatient} min={0}
                                        onChange={(e) => handleMaxPatientOnChange(e)}/>
                                    {/** 現場最大看診人次 */}
                                    <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                        type="number" id="current-people-count"
                                        value={record.onSiteNumber} min={0}
                                        onChange={(e) => handleOnSiteNumberOnChange(e)}/>
                                    {/**看診時間(分/人) */}
                                    <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                        type="number" id="time-per-person" value={record.consultationTime} min={0}
                                        onChange={(e) => handleConsultationTimeOnChange(e)}/>
                                    {/**預約方式 */}
                                    <div className="flex flex-row space-x-5 items-center h-[40px]">
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] rounded-lg checked:ring-[#286BAF] ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="radio" id="room-number" name="reservationMethodRadio"
                                                onChange={() => handleReservationMethodOnChange(RegsWeekReservationMethodEnum.ClinicNumber)}
                                                checked={record.reservationMethod === RegsWeekReservationMethodEnum.ClinicNumber}/>
                                            <label
                                                className={`${record.reservationMethod === RegsWeekReservationMethodEnum.ClinicNumber ? "text-[#286CB0]" : ""}`}
                                                htmlFor="room-number">
                                                {t('Regs.general.viewNumber')}
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] rounded-lg checked:ring-[#286BAF] ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="radio" id="time" name="reservationMethodRadio"
                                                onChange={() => handleReservationMethodOnChange(RegsWeekReservationMethodEnum.Time)}
                                                checked={record.reservationMethod === RegsWeekReservationMethodEnum.Time}/>
                                            <label
                                                className={`${record.reservationMethod === RegsWeekReservationMethodEnum.Time ? "text-[#286CB0]" : ""}`}
                                                htmlFor="time">
                                                {t('general.time')}
                                            </label>
                                        </div>
                                    </div>
                                    {/**給號方式 */}
                                    <select
                                        className="w-23 py-1 pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                        id="scheduling-method" value={record.numberGivingMethod}
                                        onChange={(e) => handleNumberGivingMethod(e)}>
                                        {
                                            baseData && baseData.noTypeList && baseData.noTypeList.map((option, index) => (
                                                <option key={index} value={option.noType}>{option.typeName}</option>))
                                        }
                                    </select>
                                    {/**預約限制 */}
                                    <div className="flex flex-row space-x-4 h-[40px]">
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] rounded-lg checked:ring-[#286BAF] ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.Normal)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.Normal}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.Normal ? "text-[#286CB0]" : ""}`}>
                                                {t('general.normal')}
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] rounded-lg checked:ring-[#286BAF] ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorOnly)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.DoctorOnly}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.DoctorOnly ? "text-[#286CB0]" : ""}`}>
                                                限醫師
                                            </label>
                                        </div>
                                        <div className="flex flex-row space-x-3 items-center">
                                            <input
                                                className="settings-checkbox appearance-none w-2 h-2 ml-[6px] checked:bg-[#286BAF] rounded-lg checked:ring-[#286BAF] ring-offset-4 ring-2 ring-gray-300 cursor-pointer"
                                                type="checkbox"
                                                onChange={() => handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorSelfOnly)}
                                                checked={record.reservationLimit === RegsWeekReservationLimitEnum.DoctorSelfOnly}/>
                                            <label
                                                className={`${record.reservationLimit === RegsWeekReservationLimitEnum.DoctorSelfOnly ? "text-[#286CB0]" : ""}`}>
                                                限醫師本人
                                            </label>
                                        </div>
                                    </div> 
                                    {/*診間開始展班日期 & 診室名稱*/}
                                    {
                                        mode === PopupModeEnum.Add ? (
                                            <div className="w-full pr-[30px]">
                                                <BaseInput 
                                                    className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] w-full"
                                                    type={InputTypeEnum.Date}
                                                    inputMode="date"
                                                    value={record.effectiveDate}
                                                    onChange={(e) => handleEffectiveDateOnChange(e)}/>
                                            </div>
                                            
                                        ) : (
                                            <input 
                                                className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                                type="text" id="max-people" value={record.clinicName}
                                                onChange={(e) => handleClinicNameOnChange(e)}/>
                                        )
                                    }
                                    {
                                        mode === PopupModeEnum.Add ? (
                                            <input 
                                                className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                                type="text" id="max-people" value={record.clinicName}
                                                onChange={(e) => handleClinicNameOnChange(e)}/>
                                        ) : (
                                            <div className="w-full pr-[30px]">
                                                <BaseInput 
                                                    className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px] w-full"
                                                    type={InputTypeEnum.Date}
                                                    inputMode="date"
                                                    value={record.effectiveDate}
                                                    onChange={(e) => handleEffectiveDateOnChange(e)}/>
                                            </div>
                                        )
                                    }
                                    {/*診室地點*/}
                                    <input className="pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                        type="text" id="max-people" value={record.clinicLocation}
                                        onChange={(e) => handleClinicLocationOnChange(e)}/>
                                    <div className="flex flex-row space-x-4 h-[40px]">
                                        {/*診別*/}
                                        <select className="w-1/2 py-1 pl-3 border border-[#D4D4D8] h-full rounded-[6px]"
                                                id="medical-type" value={record.medicalType}
                                                onChange={(e) => handleMedicalTypeOnChange(e)}>
                                            <option value={RegsMedicalTypeEnum.OutpatientClinic}>門診</option>
                                            <option value={RegsMedicalTypeEnum.MedicalCheckup}>外檢</option>
                                            <option value={RegsMedicalTypeEnum.PreventiveHealthcare}>預防保健</option>
                                        </select>
                                        {/*調病歷*/}
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                            type="checkbox" style={{accentColor: "#38A169"}}
                                            checked={record.canGetMedicalRecords}
                                            onChange={handleCanGetMedicalRecordsOnChange}/>
                                        <label htmlFor="" className="h-full flex items-center">
                                            調病歷
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-[45%] items-start justify-start text-left space-y-2">
                                {/**收費別 */}
                                <div className="flex flex-row w-full justify-between text-center space-x-4 h-[40px]">
                                    <label className="w-[35%] flex justify-end items-center h-full"
                                        htmlFor="reservation-restriction">
                                        收費別
                                    </label>
                                    <div className="flex flex-row px-2 space-x-1 w-[65%] h-[40px] relative">
                                        <input
                                            className="bg-white w-full h-full px-2 pl-12 text-[#3F3F46] rounded-[6px] border border-[#D4D4D8]"
                                            value={record.chargeType}
                                            onChange={(e) => handleChargeTypeSearchTextOnChange(e)}
                                            onFocus={handleChargeTypeSearchTextOnFocus}
                                            onBlur={handleChargeTypeSearchTextOnBlur}/>
                                        <img className="w-4 h-4 absolute top-[11px] left-0 translate-x-4" alt="search-icon"
                                            src={searchIcon} role="button"/>
                                        {/**收費別下拉選單*/}
                                        {
                                            showChargeTypeDropdown && (
                                                <div
                                                    className="bg-white z-10 absolute left-0 top-10 max-h-20 w-64 flex flex-col text-left">
                                                    <div className="flex flex-col">
                                                        <div className="max-h-[200px] border-2 bg-white overflow-y-scroll">
                                                            {
                                                                getFilterChargeTypeOptions().map((cash, index) => (
                                                                    <div
                                                                        className="bg-white border-b-2 px-2 py-1 ml-5 text-sm text-[#3F3F46]"
                                                                        key={index} role="button"
                                                                        onClick={() => handleChargeTypeOptionOnClick(cash)}>
                                                                        {`${cash.cashType} ${cash.cashName}`}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="bg-white text-center border-x-2 border-b-2">
                                                            <button className="text-[#2B6CB0]">
                                                                搜尋目錄
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                {/*空格*/}
                                {
                                    mode === PopupModeEnum.Modify &&
                                    <div className="h-[40px]">

                                    </div>
                                }
                                {/* 網掛/APP*/}
                                <div
                                    className="flex flex-row w-full h-[40px] justify-between items-center space-x-4">
                                    <div className="w-[35%]"></div>
                                    <div className="w-[65%] flex justify-left space-x-2">
                                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                            type="checkbox" checked={record.webOrAppBooking}
                                            style={{accentColor: "#38A169"}}
                                            onChange={handleWebOrAppBookingOnChange}/>
                                        <label htmlFor="max-people" className="">
                                            網掛/APP
                                        </label>
                                    </div>
                                </div>
                                {/*網掛/APP取消看診進度*/}
                                <div
                                    className="flex flex-row w-full h-[40px] justify-between items-center text-left space-x-4">
                                    <div className="w-[35%]"></div>
                                    <div className="flex flex-row w-[65%] space-x-2">
                                        <input
                                            className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                            type="checkbox" checked={record.webOrAppCancel}
                                            style={{accentColor: "#38A169"}}
                                            onChange={handleWebOrAppCancelOnChange}/>
                                        <label htmlFor="reservation-method">
                                            網掛/APP取消看診進度
                                        </label>
                                    </div>
                                </div>
                                {/*網掛預約期限*/}
                                <div
                                    className="flex flex-row w-full justify-between text-center space-x-4 h-[40px]">
                                    <label className="w-[35%] flex justify-end items-center h-full"
                                        htmlFor="reservation-restriction">
                                        網掛預約期限
                                    </label>
                                    <div className="flex flex-row px-2 space-x-1 w-[65%] h-full">
                                        <input className="pl-3 rounded-[6px] border border-[#D4D4D8] w-[100px] h-full"
                                            type="number" value={record.onlineBookingDeadline} min={0}
                                            onChange={(e) => handleOnlineBookingDeadlineOnChange(e)}/>
                                        <label>天</label>
                                    </div>
                                </div>
                                {/*看診項目*/}
                                <div
                                    className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center">
                                        看診項目
                                    </label>
                                    <div className="px-2 w-[65%]">
                                        <select className="py-1 pl-3 border border-[#D4D4D8] h-full w-full rounded-[6px]"
                                                value={record.consultationItems}
                                                onChange={(e) => handleConsultationItemsOnChange(e)}>
                                            {/* 空白項 */}
                                            <option value="empty"></option>
                                        {
                                            baseData && baseData.clinicMarkList && baseData.clinicMarkList.map((option, index) => (
                                                <option key={index} value={option.justnameNo}>{option.justnameName}</option>))
                                        }
                                        </select>
                                    </div>
                                </div>
                                {/*注意事項*/}
                                <div
                                    className="flex flex-row justify-between w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center"
                                        htmlFor="notes text-center">
                                        注意事項
                                    </label>
                                    <div className="px-2 w-[65%]">
                                            <textarea
                                                className="border border-[#D4D4D8] h-full min-h-full px-1 w-full rounded-[6px]"
                                                id="notes" value={record.notes}
                                                onChange={(e) => handleNotesOnChange(e)}/>
                                    </div>
                                </div>
                                <div className="flex flex-row  w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center">展班月數</label>
                                    <div className="flex flex-row items-center space-x-10">
                                        <select
                                            className="w-[100px] py-1 pl-3 border border-[#D4D4D8] h-[40px] rounded-[6px]"
                                            onChange={handleExhibitionMonthOnChange}
                                            value={record.exhibitionMonth}
                                        >
                                        {
                                            exhibitionMonthOptions.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))
                                        }
                                        </select>
                                        <div className="flex flex-row justify-between space-x-5">
                                            <label
                                                className={`"text-[#286CB0]"}`}>
                                                假日展班
                                            </label>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                                type="checkbox"
                                                checked={record.holidayExhibition}
                                                onChange={handleHolidayExhibitionOnChange}
                                                style={{accentColor: "#38A169"}}/>
                                        </div>
                                    </div>
                                </div>
                                {/*診間標語*/}
                                <div
                                    className="flex flex-row  w-full h-[40px] min-h-[40px] text-left space-x-4">
                                    <label className="justify-end w-[35%] h-full flex items-center"
                                        htmlFor="notes text-center">
                                        診間標語
                                    </label>
                                    <div className="px-2 w-[65%]">
                                            <textarea
                                                className="border border-[#D4D4D8] h-full min-h-full px-1 w-full rounded-[6px]"
                                                id="notes" value={record.clinicSlogan}
                                                onChange={(e) => handleClinicSloganOnChange(e)}/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {
                            mode === PopupModeEnum.Modify &&
                            <div className="items-center w-full flex justify-center mt-3">
                                <button
                                    className="bg-[#DC2B2B] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                                    onClick={handleDeleteOnClick}>
                                    {t('general.delete')}
                                </button>
                            </div>
                        }
                       
                    </div>
                    {/**下方區塊(存檔) */}
                    <div
                        className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                        <button
                            className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                            onClick={handleSaveOnClick}>
                            {t('general.saveFile')}
                        </button>
                    </div>
                </div>
            </div>
            {
                //確定刪除提示
                <DeletePromptPopup
                    promptText={getHeaderContent()}
                    show={showDeletePrompt}
                    confirmOnClick={handleConfirmDeleteOnClick}
                    cancelOnClick={handleCancelDeleteOnClick}/>
            }
        </div>
    )
    
}
