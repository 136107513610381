import React, { useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { Pagination } from '../../components/Pagination/Pagination'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { MARS_REPORT_FIELDS } from '../../constants/referralSystem/marsReport'

/**
 * MARS(VPN)報表
 * @returns {JSX.Element}
 */
const MarsReport = () => {
    const [searchParams, setSearchParams] = useState({
        encounterStartDate: '',
        encounterEndDate: '',
        patientId: '',
    })
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })

    /**
     * 插入CustomTable的匯出按鈕
     * @returns {JSX.Element} 匯出Text按鈕
     */
    const ExportTextButton = () => {
        return (
            <button
                className="flex items-center justify-center px-4 h-10 rounded-[6px] text-[#38A169]"
                onClick={exportText}
            >
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 匯出
     */
    const exportText = () => {}

    /**
     * 更新查詢欄位value
     * @param {object} e input event
     */

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    const onPageSizeChange = (event) => {
        console.log(event.target.value)
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
        })
    }
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex justify-between items-center mb-1">
                    <div className="flex items-start p-2">
                        <div className="date flex flex-row items-center justify-start mr-4">
                            <div className="mr-4">就醫日期:</div>
                            <input
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={searchParams.encounterStartDate}
                                onChange={(e) =>
                                    updateSearchParams(e, 'encounterStartDate')
                                }
                                type="date"
                            />
                            <span className="m-1">~</span>
                            <input
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={searchParams.encounterEndDate}
                                onChange={(e) =>
                                    updateSearchParams(e, 'encounterEndDate')
                                }
                                type="date"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mr-4">
                            <div className="mr-4">病歷號:</div>
                            <input
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                type="text"
                                value={searchParams.patientId}
                                onChange={(e) => updateSearchParams(e, 'patientId')}
                            />
                        </div>

                        <button className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white">
                            查詢
                        </button>
                    </div>
                    <div>
                        <button className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2">
                            MARS(VPN)安裝電腦清單
                        </button>
                    </div>
                </div>
                <CustomTable
                    fields={MARS_REPORT_FIELDS}
                    dataList={dataList}
                    totalPage={filter.page}
                    total={filter.total}
                    slotButton={<ExportTextButton />}
                ></CustomTable>
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.total}
                        currentPage={filter.currentPage}
                        totalPageSize={filter.totalPage}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}
export default MarsReport
