import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import searchIcon from "../../../assets/images/search-interface-symbol.png"
import {time2String, ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import { queryPatientByMultiField } from "../../../api/v1/Mris"
import ToastMessage from "../../Toasts/ToastMessage"
import { Pagination } from "../../Pagination/Pagination"
import useToast from "../../../hooks/useToast";

/**
 * 多因素搜尋彈窗
 * @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 * @param show {Boolean} 是否顯示
 * @param icCardData {Object} IC卡資料
 * @param skipAuthReasonList {Array} 跳過稽核原因Array
 * @param confirmPatientData (Function) 確認病人病歷
 * @param handleMultifactorSearchSkipOnClick (Function) 跳過稽核事件
 * @param searchText {String} 帶入搜尋的字串
 */
export const MultifactorSearchPopup = ({
    closePopupButtonOnClick, 
    show = false,
    icCardData,
    skipAuthReasonList,
    confirmPatientData,
    handleMultifactorSearchSkipOnClick,
    searchText
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)
    /**
     * 病歷名單
     */
    const [arrayData, setArrayData] = useState([])
    // 病歷名單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([])
    // 進階搜尋文字狀態
    const [advancedSearchText, setAdvancedSearchText] = useState('')
    //查詢欄位資料(已查詢後)
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: '',
        //生日
        birthDate: '',
        //身分證號
        idNo: '',
        //電話號碼
        phoneNo: '',
        //地址
        address: ''
    })
    //查詢欄位資料(顯示在查詢爛位上的)
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: '',
        birthDate: '',
        idNo: '',
        phoneNo: '',
        address: ''
    })
    /**
     * 選擇的病歷號
     */
    const [selectedPatientData, setSelectedPatientData] = useState(null)
    /**
     * 跳過稽核的原因
     */
    const [skipReason, setSkipReason] = useState("")
    /**
     * 查詢按鈕disable狀態
     */
    const [disableSearchButton, setDisableSearchButton] = useState(true)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 點擊跳過稽核按鈕事件
     */
    const handleSkipCheckOnClick = () => {
        if(objectIsEmpty(icCardData)) {
            handleMultifactorSearchSkipOnClick(queryInputTmpData.text, queryInputTmpData.birthDate, queryInputTmpData.idNo, queryInputTmpData.phoneNo, queryInputTmpData.address)
        }
        else {
            //有ic卡資料就帶入ic卡資料
            handleMultifactorSearchSkipOnClick(icCardData.patientName, icCardData.birthDate, icCardData.idNo, "", "")
        }
    }

    /**
     * 點擊查詢按鈕事件
     */
    const handleSearchOnClick = () => { 
        //搜尋第一頁
        searchPageData(queryInputTmpData, 1, paginationProps.pageSize)
        //清除選擇的病歷號
        setSelectedPatientData(null)
    }

    /**
     * 查詢分頁資料
     * @param {Number} page 第幾頁
     * @param {Number} pageSize 每頁筆數
     */
    const searchPageData = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        queryPatientByMultiField({
            idNo: queryInputObj.idNo,
            birthDate: stringIsEmpty(queryInputObj.birthDate)? "" : `${queryInputObj.birthDate} 00:00:00`,
            patientName:queryInputObj.text,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setArrayData(res.data.dataList)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
                if(arrayIsEmpty(res.data.dataList)) {
                    //顯示toast
                    showToast({message:"沒有找到符合的資料", type:ToastTypeEnum.Warning})
                }
            } else {
                setArrayData([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
                let errMsg = "搜尋出現錯誤"
                if(!stringIsEmpty(res.msg))
                    errMsg = res.msg
                else if(res.statusCode !== null)
                    errMsg = `Error(${res.statusCode})`
                //顯示toast
                showToast({message:errMsg, type:ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 搜尋姓名輸入框文字變更時
     * @param {Event} e 
     * @returns 
     */
    const handleSearchNameTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            text: e.target.value
        })
    }

    /**
     * 搜尋出生日期輸入框文字變更時
     * @param {Event} e 
     * @returns 
     */
    const handleSearchBirthDateTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            birthDate: e.target.value
        })
    }
    
    /**
     * 搜尋身份證號輸入框文字變更時
     * @param {Event} e 
     * @returns 
     */
    const handleSearchIdNoTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            idNo: e.target.value
        })
    }
    
    /**
     * 搜尋電話輸入框文字變更時
     * @param {Event} e 
     * @returns 
     */
    const handleSearchPhoneNoTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            phoneNo: e.target.value
        })
    }
    
    /**
     * 搜尋地址輸入框文字變更時
     * @param {Event} e 
     * @returns 
     */
    const handleSearchAddressTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            address: e.target.value
        })
    }

    /**
     * 病人選擇事件
     * @param {String} patientData 病人病歷資料
     * @returns 
     */
    const handlePatientOnChange = (patientData) => {
        setSelectedPatientData(patientData)
    }

    /**
     * 跳過稽核原因下拉選單改變時
     * @param {Event} e 
     * @returns 
     */
    const handleSkipReasonOnChange =(e) => setSkipReason(e.target.value)

    /**
     * 按下確認帶回按鈕事件
     */
    const handleConfirmOnClick = () => {
        if(objectIsEmpty(selectedPatientData))
            return;
        //將patient data帶回
        confirmPatientData(selectedPatientData)
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        searchPageData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        searchPageData(queryInputData, 1, e.target.value)
        //清除選擇的病歷號
        setSelectedPatientData(null)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        searchPageData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        searchPageData(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 取得病人進階搜尋後的結果
     * @returns 
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(advancedSearchText)) {
            return arrayData
        }
        else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = advancedSearchText.toLowerCase()
            return arrayData.filter(data => {
                    return (data.sameIdNo != null && data.sameIdNo.toString().toLowerCase().includes(lowerCaseSearchText)) || 
                            (data.samePatientName != null && data.samePatientName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.sameBirthDate != null && data.sameBirthDate.toString().toLowerCase().includes(lowerCaseSearchText)) || 
                            (data.patientId !== null && data.patientId.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.patientName !== null && data.patientName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.idNo !== null && data.idNo.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.genderName !== null && data.genderName.toString().toLowerCase().includes(lowerCaseSearchText)) ||
                            (data.birthDate !== null && time2String(data.birthDate, "YYYYMMDD").includes(lowerCaseSearchText))
                }
            )
        }
    }

    /**
     * 取得彈窗元件
     * @return {JSX.Element} 彈窗元件
     */
    const getContent = () => (
        <div className="px-4 sm:px-8">
            {
                icCardData &&
                <div >
                    <p className="text-xl font-normal text-left text-black">
                        {`${icCardData.patientName}，${icCardData.idNo}，${time2String(icCardData.birthDate, "YYYY-MM-DD")}`}
                    </p>
                </div>
            }
            <div className="flex mt-1 max-w-2xl text-sm leading-6 text-gray-500 px-4">
                {t('page.Appointment.popup.MultifactorSearch.tip1')}
            </div>
            <div className="flex py-1 space-x-3 border-b border-[#111111]/15">
                <div className="flex items-center">
                    {t('general.username')}：
                    <BaseInput
                        className="w-[130px] xl:w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Text}
                        value={queryInputTmpData.text}
                        onChange={handleSearchNameTextOnChange}/>
                </div>
                <div className="flex items-center">
                    {t('general.dateOfBirth')}：
                    <BaseInput
                        className="w-[130px] xl:w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Date}
                        inputMode="date"
                        value={queryInputTmpData.birthDate}
                        onChange={handleSearchBirthDateTextOnChange}/>
                </div>
                <div className="flex items-center">
                    {t('general.idNumber')}：
                    <BaseInput
                        className="w-[130px] xl:w-[160px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Text}
                        value={queryInputTmpData.idNo}
                        onChange={handleSearchIdNoTextOnChange}/>
                </div>
                <div className="flex items-center space-x-2">
                    <input 
                        className="bg-green-400 checked:bg-green-600 text-green-600 h-5 w-5" 
                        type="checkbox"
                        style={{accentColor: "#38A169"}}/>
                    <div>{t('page.Appointment.popup.MultifactorSearch.foreigner')}</div>
                </div>

                <button 
                    className={`text-white p-1.5 px-3 rounded-lg fit-content ${disableSearchButton ? "bg-[#D4D4D8]": "bg-[#2B6CB0] hover:bg-[#3182CE] active:bg-[#2C5282]"}`}
                    type="primary"
                    disabled={disableSearchButton}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </button>
            </div>
            <div className="flex mt-1 max-w-2xl text-sm leading-6 text-gray-500 px-4">
                {t('page.Appointment.popup.MultifactorSearch.tip2')}
            </div>
            <div className="flex py-1 space-x-3">
                <div className="flex items-center">
                    市話/手機：
                    <BaseInput
                        className="w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Text}
                        value={queryInputTmpData.phoneNo}
                        onChange={handleSearchPhoneNoTextOnChange}/>
                </div>
                <div className="flex items-center">
                    {t('general.address')}：
                    <BaseInput
                        className="w-[500px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                        type={InputTypeEnum.Text}
                        value={queryInputTmpData.address}
                        onChange={handleSearchAddressTextOnChange}/>
                </div>
            </div>
            {/**中間 Data Grid */}
            <div className="px-2 py-2 mt-4 border border-[#d4d4d8] rounded-[6px] space-y-3">
                <div className="flex flex-row items-center">
                    <div className="h-7 w-2 bg-[#2B6CB0]"></div>
                    <div className="text-[20px] ml-[7px] text-[#2B6CB0]">病歷名單</div>

                    {/*進階搜尋*/}
                    <div className="w-[350px] px-[8px] flex items-center relative">
                        <input
                            className={'bg-white min-h-10 w-full px-2 text-black rounded-[6px] border py-1 border-gray-300 pl-8'}
                            placeholder={t('general.advancedSearch')}
                            value={advancedSearchText}
                            onChange={(e) => onAdvancedSearchTextOnInputChange(e)}/>
                        <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-5"
                                src={searchIcon} alt="search-icon" role="button"/>
                    </div>

                    <button 
                        className="border-2 border-[#d4d4d8] min-h-10 rounded ml-[10px] p-1.5"
                        onClick={handleConfirmOnClick}
                    >
                        {t('general.confirm')}
                    </button>
                </div>
                {/**Data Grid */}
                <div className='border-collapse w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[22vh] xl:max-h-[40vh]'>
                    <table className="table-fixed w-full min-w-[1500px]">
                        <thead>
                            <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-left text-lg text-[#18181b]">
                                <th className="w-[52px] font-normal px-4 border-r border-[#111111]/15"></th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15 tracking-wider">{t('page.Appointment.popup.MultifactorSearch.sameIDNumber')}</th>
                                <th className="w-[100px] font-normal px-4 border-r border-[#111111]/15 tracking-wider">{t('page.Appointment.popup.MultifactorSearch.sameName')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('page.Appointment.popup.MultifactorSearch.sameBirthday')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('page.Appointment.popup.MultifactorSearch.sameTelephone')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('page.Appointment.popup.MultifactorSearch.sameAddress')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('Regs.general.medicalRecordNumber')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('general.username')}</th>
                                <th className="w-[130px] font-normal px-4 border-r border-[#111111]/15">{t('general.idNumber')}</th>
                                <th className="w-[125px] font-normal px-4 border-r border-[#111111]/15">{t('general.dateOfBirth')}</th>
                                <th className="w-[80px] font-normal px-4 border-r border-[#111111]/15">{t('general.gender.name')}</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">電話(市話)</th>
                                <th className="w-[120px] font-normal px-4 border-r border-[#111111]/15">{t('general.mobilePhone')}</th>
                                <th className="w-[400px] font-normal px-4 border-r border-[#111111]/15">{t('general.address')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !arrayIsEmpty(advSearchArrayData) && advSearchArrayData.map((data, index) => (
                                    <tr 
                                        className={`h-[40px] ${index % 2 === 0 ? '':'bg-[#f4f4f5]'} text-left text-[#18181b]`}
                                        key={index}>
                                        <td className="border-r border-[#111111]/15 text-center">
                                            <input 
                                                className="text-black"
                                                type="radio"
                                                checked={!objectIsEmpty(selectedPatientData) && (selectedPatientData.patientId === data.patientId)}
                                                onChange={() => handlePatientOnChange(data)}
                                                />
                                        </td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.sameIdNo?data.sameIdNo:""}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.samePatientName?data.samePatientName:""}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.sameBirthDate?data.sameBirthDate:""}</td>
                                        <td className="px-4 border-r border-[#111111]/15"></td>
                                        <td className="px-4 border-r border-[#111111]/15"></td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.patientId}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.patientName}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.idNo}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{time2String(data.birthDate, "YYYY-MM-DD")}</td>
                                        <td className="px-4 border-r border-[#111111]/15">{data.genderName}</td>
                                        <td className="px-4 border-r border-[#111111]/15"></td>
                                        <td className="px-4 border-r border-[#111111]/15"></td>
                                        <td className="px-4 border-r border-[#111111]/15"></td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
                    
                </div>
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={paginationProps.totalPageSize} 
                                currentPage={paginationProps.currentPage} 
                                pageSize={paginationProps.pageSize}
                                totalSize={paginationProps.totalItemSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPrevPageOnClick={onPaginationPreviousOnClick}
                                onNextPageOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
            </div>

            {/**底下:跳過稽核 */}
            <div className="py-4 text-left">
                跳過稽核原因：
                <select
                    className="h-10 px-2 w-[350px] border border-[#D4D4D8] rounded-[6px]"
                    value={skipReason}
                    onChange={handleSkipReasonOnChange}>
                    {
                        skipAuthReasonList && skipAuthReasonList.map((reason, index) => (
                            <option 
                                key={reason.justnameNo}
                                value={reason.justnameNo}>
                                {reason.justnameName}
                            </option>
                        ))
                    }
                </select>

                <button 
                    className={`border-2 border-[#d4d4d8] min-h-10 rounded ml-[10px] p-1.5 ${disableSearchButton ? "opacity-30":""}`}
                    onClick={handleSkipCheckOnClick}
                    disabled={disableSearchButton}
                >
                    跳過稽核
                </button>
            </div>
        </div>

    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        if (!show) {
            //重置搜尋欄位、Table
            setSelectedPatientData(null)
            setArrayData([])

            setQueryInputTmpData({
                text: '',
                birthDate: '',
                idNo: '',
                phoneNo: '',
                address: ''
            })
        }
        else {
            if(!objectIsEmpty(icCardData)) {
                setQueryInputTmpData({
                    ...queryInputTmpData,
                    text: stringIsEmpty(icCardData.patientName) ? "" : icCardData.patientName,
                    idNo: stringIsEmpty(icCardData.idNo) ? "" : icCardData.idNo,
                    birthDate: stringIsEmpty(icCardData.birthDate) ? "" : time2String(icCardData.birthDate, "YYYY-MM-DD")
                })
            }
            else if(!stringIsEmpty(searchText)) {
                //手機號碼:09開頭後面8個數字
                const regxMobile = /^09\d{8}$/
                //生日: yyyyMMdd 8碼數字
                const regxBirthdate = /^\d{8}$/
                //身分證規則:英文開頭 1碼 + 英文或數字 9碼
                const regxIdNo = /^[A-Za-z][A-Za-z0-9]{9}$/

                if(regxIdNo.test(searchText)) {
                    //是身分證號格式
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        idNo: searchText
                    })
                }
                else if(regxBirthdate.test(searchText)) {
                    //是生日格式
                    //將8碼數字 轉成 YYYY-MM-DD
                    const year = searchText.substring(0, 4);
                    const month = searchText.substring(4, 6);
                    const day = searchText.substring(6, 8);
                    const date = `${year}-${month}-${day}`;
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        birthDate: date
                    })
                } 
                else if(regxMobile.test(searchText)) {
                    //是手機號碼格式
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        phoneNo: searchText
                    })
                }
            }
        }
        setShowPopup(show)
    }, [show])

    /**
     * 監聽arrayData & advancedSearchText變化
     */
    useEffect(() => {
        if(arrayData !== null) {
            const list = getAdvSearchPatientList()
            setAdvSearchArrayData(list)
        }
        else {
            setAdvSearchArrayData(null)
        }
    }, [advancedSearchText, arrayData])

    /**
     * 監聽skipAuthReasonList變化
     */
    useEffect(() => {
        if(!arrayIsEmpty(skipAuthReasonList))
        {
            setSkipReason(skipAuthReasonList[0].justnameNo)
        }
    }, [skipAuthReasonList])

    /**
     * 監聽查詢欄位變化
     */
    useEffect(() => {
        let cnt = 0
        cnt = stringIsEmpty(queryInputTmpData.text) ? cnt : cnt + 1
        cnt = stringIsEmpty(queryInputTmpData.birthDate) ? cnt : cnt + 1
        cnt = stringIsEmpty(queryInputTmpData.idNo) ? cnt : cnt + 1
        if(cnt < 1)
        {
            setDisableSearchButton(true)
        }
        else {
            if(cnt > 1)
            {
                setDisableSearchButton(false)
            }
            else
            {
                setDisableSearchButton(stringIsEmpty(queryInputTmpData.phoneNo) && stringIsEmpty(queryInputTmpData.address))
            }
        }
        
    }, [queryInputTmpData])

    return showPopup ?
        <BasePopup title={t('page.Appointment.popup.MultifactorSearch.title')} content={getContent()}
                   closePopupButtonOnClick={closePopupButtonOnClick}
                   width={"90%"}/> :
        <></>
}
