import {BaseInput} from "../../Input/BaseInput";
import {ApiErrorStatusEnum, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist";
import Button from "../../Button/Button";
import React, {useState} from "react";
import {TRAN_NO_FLAG} from "../../../constants/referralSystem/tranInCase";
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/del_square.svg'
import {BasePopup} from "../../Popup/BasePopup";
import {t} from "i18next";

const Title = ({children, extendClass})=> {
    return (
        <div className={`flex flex-row items-center justify-start mr-8 ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}
const InputStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SelectStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * 長照中心維護表單
 * @returns {JSX.Element}
 */
const LongTermCareCenterForm = ({data})=>{
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState("")
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    // 長期照護單位名稱
    const [longTermCenterName, setLongTermCenterName] = useState( "")
    const handleLongTermCenterNameChange = (e) => setLongTermCenterName(e.target.value)

    // 郵遞區號
    const [zipCode, setZipCode] = useState(data.zipCode || '')
    const [allZipCode, setAllZipCode] = useState([])
    const handleZipCodeChange = (e) => setZipCode(e.target.value)

    // 地址
    const [address, setAddress] = useState( "")
    const handleAddressChange = (e) => setAddress(e.target.value)

    // 電話
    const [phone, setPhone] = useState( "")
    const handlePhoneChange = (e) => setPhone(e.target.value)

    // 儲存
    const handleSave = async ()=>{
        const targetApi = data ? editRmistranCareCenter : addRmistranCareCenter
        const res = await targetApi({
            hospNo:hospitalCode,
            hospName: longTermCenterName,
            hospZipCode: zipCode,
            hospaddress: address,
            hospTelAreaCode: '',
            hospTel: phone
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
        }
        else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message : msg, type:ToastTypeEnum.Error})
        }
    }

    return <>
        <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8">
            <div className="grid  grid-cols-[100px,300px,160px,300px]">
                <div className='flex items-center '>
                    <Title>院所代碼</Title>
                </div>
                <div className='flex items-center'>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                </div>
                <div className='flex items-center'>
                    <Title>
                        長期照護單位名稱
                    </Title>
                </div>
                <div className='flex items-center'>

                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={longTermCenterName}
                        onChange={handleLongTermCenterNameChange}
                    />
                </div>

            </div>
            <div className="mt-2 grid  grid-cols-[100px,300px,160px,300px]">
                <div className='flex items-center'>
                    <Title>{t('general.zipCode')}</Title>
                </div>
                <div className='flex items-center '>
                    <select className={SelectStyle}
                            value={zipCode} onChange={(e) => {
                        handleZipCodeChange
                    }}>
                        {
                            allZipCode.map(item =>
                                <option key={`zip_${item.value}`} value={item.value}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                </div>
                <div className='flex items-center justify-end'>
                    <Title extendClass={"ml-12"}>
                        {t('general.address')}
                    </Title>
                </div>
                <div className='flex items-center '>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={address}
                        onChange={handleAddressChange}
                    />
                </div>

            </div>
            <div className="mt-2 grid  grid-cols-[100px,300px,100px,300px]">
                <div className='flex items-center justify-end'>
                    <Title>
                        {t('general.telephone')}
                    </Title>
                </div>
                <div className='flex items-center'>

                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={phone}
                        onChange={handlePhoneChange}
                    />
                </div>

            </div>

        </div>
        <div
            className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <Button classNames={`ml-4 ${ButtonStyle}`} text={t('general.saveFile')} onClickFn={handleSave}/>
        </div>

    </>
}

export default LongTermCareCenterForm