//Import icons
import {ReactComponent as PdfJsonIcon} from '../../assets/images/ESSI/pdf-json.svg'
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Hooks
import useOutsideClick from "../../hooks/useOutsideClick"

//Import function
import React, {useEffect, useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    createDateRange2String,
    ESSIUDQOptionTypeEnum,
    fuzzySearchObjects,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    ToastTypeEnum,
    UserRoleEnum
} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import PdfJsonPopup from "../../components/ESSI/PdfJsonPopup"
import PinCodePopup from "../../components/ESSI/PinCodePopup"
import SignLoadingPopup from "../../components/ESSI/SignLoadingPopup"
import {t} from "i18next"
import {essiQueryTransRec, essiQueryTransRecByUserNo} from "../../api/v1/ESSI"
import {BaseInput} from "../../components/Input/BaseInput"
import useToast from "../../hooks/useToast"

// 簽章btn> !ESSI_USERCERT.ACCESSCODE> PINCODE POPUP
/**
 * 未簽文件查詢與簽章
 * @return {JSX.Element}
 */
const UnsignedDocumentsQueryPage = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    // 所有醫師選項
    const [doctors, setDoctors] = useState([])

    //當前選擇的Type
    const [radioType, setRadioType] = useState(ESSIUDQOptionTypeEnum.UNSIGNED_24H)

    //醫師選項
    const [doctor, setDoctor] = useState("")

    // 起始時間
    const [startDate, setStartDate] = useState(null)

    // 終止時間
    const [endDate, setEndDate] = useState(null)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //當前使用者編號
    const [currentUserNo, setCurrentUserNo] = useState("")

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    // 總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    //是否顯示PDF/JSON彈窗
    const [showPdfJson, setShowPdfJson] = useState(false)

    //是否顯示PinCode彈窗
    const [showPinCode, setShowPinCode] = useState(false)

    //是否顯示簽章進度
    const [showCancelSignLoading, setShowCancelSignLoading] = useState(false)

    // 是否選擇全部
    const [selectedAll, setSelectedAll] = useState(false)

    // 已選擇資料
    const [selectedData, setSelectedData] = useState([])

    // 是否顯示人員代號選項
    const [showDoctorDropdownOptions, setShowDoctorDropdownOptions] = useState(false)

    // 是否顯示簽章進度
    const [signLoading, setSignLoading] = useState(false)

    // role角色>用於判斷是否可以查看他人資料
    const [isSysMaintain, setIsSysMaintain] = useState(false)

    // 初始資料
    const [data, setData] = useState([])

    //ref 用於指向醫師下拉選單元素
    const doctorDropdownRef = useRef(null)

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param e {Event} 事件
     */
    const handleSelectUserOnChange = (e) => setDoctor(e.target.value)

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorDropdownOnFocus = () => setShowDoctorDropdownOptions(true)

    /**
     * 醫師選項點擊時
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setDoctor(`${option.userNo} ${option.userName}`)
        }
        // 關閉醫師下拉選單
        setShowDoctorDropdownOptions(false)
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        setPageSize(e.target.value)
    }

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 時間開始改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間結束改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 點選PDF JSON按鈕事件
     * @return {void}
     */
    const handleCancelPdfJsonOnClick = () => setShowPdfJson(true)

    /**
     * 關閉PDFJSON彈窗
     * @return {void}
     */
    const handlePdfJsonPopupOnClose = () => setShowPdfJson(false)

    /**
     * 全選事件觸發時
     * @return {void}
     */
    const handleSelectedAllOnClick = () => {
        //更改值後是否為全選
        const isSelectedAll = !selectedAll
        //取得當前的資料
        const arrayData = [...data]
        //將所有資料的selected改為true
        setData(arrayData.map((data) => ({...data, selected: !selectedAll})))
        //設定是否全選
        setSelectedAll(isSelectedAll)
    }

    /**
     * 個別點擊選取事件
     * @param index {Number}
     * @return {void}
     */
    const handleSelectedOnClick = (index) => {
        //新的資料
        const newData = data.map((d, i) => i === index ? {...d, selected: !d.selected} : d)
        //設定新的資料
        setData(newData)
        //是否全選
        const isSelectedAll = newData.every(d => d.selected)
        //設定是否全選
        setSelectedAll(isSelectedAll)
    }

    /**
     * 簽章按鈕點擊時
     * @return {void}
     */
    const handleSignOnClick = () => {
        // 判斷ESSI_USERCERT.ACCESSCODE是否為空，為空請輸入pincode
        setShowPinCode(true)
    }

    /**
     * 關閉pinCode popup
     * @return {void}
     */
    const handleCloseCancelPinCodePopup = () => {
        setShowPinCode(false)
    }

    /**
     * 確認pinCode popup
     * @return {void}
     */
    const handleSavePinCodePopup = () => {
        setShowPinCode(false)
        setShowCancelSignLoading(true)
    }

    /**
     * 關閉signLoadingpopup 簽章進度
     * @return {void}
     */
    const handleCloseCancelSignLoadingPopup = () => {
        setShowCancelSignLoading(false)
    }

    /**
     * Radio改變時
     * @param v {number} 選擇的值
     * @return {void}
     */
    const handleRadioOnChange = (v) => setRadioType(v)

    /**
     * 當搜尋按鈕點擊時
     * @return {void}
     */
    const handleSearchButtonOnClick = () => getUnsignedData()

    /**
     * 取得未簽文件查詢及簽章
     * @return {void}
     */
    const getUnsignedData = () => {
        switch (radioType) {
            case ESSIUDQOptionTypeEnum.UNSIGNED_24H:
                // 取得預設查詢 24 小時未簽文件查詢及簽章
                getQueryTransRecByUserNo()
                break
            case ESSIUDQOptionTypeEnum.CREATED_BY:
                // 查詢未簽文件查詢及簽章
                getQueryTransRec()
                break
        }
        // 避免後續重複取得資料
        hasFirstFetchedData.current = true
    }

    /**
     * 取得預設查詢 24 小時未簽文件查詢及簽章
     * @return {void}
     */
    const getQueryTransRecByUserNo = () => essiQueryTransRecByUserNo({
        //required 目前登入人員代號
        createUserNo: currentUserNo,
        // 當前頁數
        pageNum: currentPage,
        // 每頁筆數
        pageSize: pageSize,
    }).then(handleApiResponse)

    /**
     * 查詢未簽文件查詢及簽章
     * @return {void}
     */
    const getQueryTransRec = () => {
        // 正規表示式是否包含一個空白
        const regexWithSpace = /^[^\s]*\s[^\s]*$/
        // 取得Doctor是否有單個空格
        const hasSpace = regexWithSpace.test(doctor)
        // 取得醫生代號
        const doctorNo = isSysMaintain ?
            // 系統維護人員才有辦法取得所有醫生名單
            (hasSpace ? doctor.split(' ')[0] : doctor) :
            // 醫生代號
            currentUserNo
        essiQueryTransRec({
            //required 目前登入人員代號
            createUserNo: doctorNo,
            //required 查詢開始日期
            createStartDatetime: `${startDate} 00:00:00`,
            //required 查詢結束日期
            createEndDatetime: `${endDate} 23:59:59`,
            // 當前頁數
            pageNum: currentPage,
            // 每頁筆數
            pageSize: pageSize,
        }).then(handleApiResponse)
    }

    /**
     * 處理取得資料
     * @param res {Object} 回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // 取得成功
        if (res === ApiErrorStatusEnum.Success) {
            // 取得資料
            setData(res.data)
            // 設定總筆數
            if (!!res.totalPageSize) {
                onPaginationPageSizeOnChange(res.totalPageSize)
            }
        }
    }

    /**
     * 查詢所有醫生
     * @reutrn {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            const {err, data, msg} = res

            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //設定醫師人員
                setDoctors(data)
            } else { //取得失敗
                //顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得 filter 醫生
     * @return {Array<Object>}
     */
    const getFilterDoctor = () => {
        // 醫生是否有空格
        return doctor.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctors, doctor.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctors, doctor)
    }

    /**
     * 取得進階搜尋後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 是否禁用
     * @return {boolean}
     */
    const isDisabled = () => radioType !== ESSIUDQOptionTypeEnum.CREATED_BY

    /**
     * 是否選取
     * @param type {number} 類型
     * @return {boolean}
     */
    const isChecked = (type) => type === radioType

    /**
     * 第一次執行前時
     * @return {void}
     */
    useMemo(() => {
        //取得當前使用者編號
        const userNo = getLocalStorage('userno')
        // 設定當前使用者編號
        setCurrentUserNo(userNo)
        //取得Role資訊
        const roles = JSON.parse(getLocalStorage('role'))
        // 判斷是系統維護人員
        const hasSysMaintain = roles.some(role => role.roleNo === UserRoleEnum.SysMaintain)
        // 設定使用者角色
        setIsSysMaintain(hasSysMaintain)

        // 系統維護人員才有辦法取得所有醫生名單
        if (hasSysMaintain) {
            // 取得所有醫生
            getQueryAllDoctor()
        }

        //取得日期範圍
        const dateRange = createDateRange2String()
        // 設定起始時間
        setStartDate(time2String(dateRange[0], 'yyyy-MM-DD'))
        // 設定結束時間
        setEndDate(time2String(dateRange[1], 'yyyy-MM-DD'))
    }, [])

    /**
     * 當前使用者編號改變時
     * @return {void}
     */
    useEffect(() => {
        // 當前使用者編號 / 起始時間 / 結束時間不為空
        if (!stringIsEmpty(currentUserNo) && !stringIsEmpty(startDate) && !stringIsEmpty(endDate)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                // 取得未簽文件查詢及簽章
                getUnsignedData()
            }
        }
    }, [currentUserNo, startDate, endDate])

    /**
     * 避免點擊醫師選項時，因CSS點不到問題
     */
    useOutsideClick({
        ref: doctorDropdownRef,
        handler: () => setShowDoctorDropdownOptions(false),
    })

    return (
        <>
            <div className="w-full p-4 bg-[#FAFAFA]">
                <div className="filterGroup flex flex-row flex-wrap items-center justify-between">
                    <div className=" items-center ml-2 flex flex-row">
                        {/*24小時內未簽名單*/}
                        <div className='text-left'>
                            <input
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                id="hour" type="radio" name="filterRadio"
                                checked={isChecked(ESSIUDQOptionTypeEnum.UNSIGNED_24H)}
                                onChange={() => handleRadioOnChange(ESSIUDQOptionTypeEnum.UNSIGNED_24H)}/>
                            <label className="mx-3" htmlFor="hour" style={{cursor: "pointer"}}>24小時內未簽名單</label>
                        </div>
                        {/*建立人員*/}
                        <div className='flex flex-row ml-5 mt-2'>
                            <input
                                className="mt-4 mr-3 appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                id="date" type="radio" name="filterRadio"
                                checked={isChecked(ESSIUDQOptionTypeEnum.CREATED_BY)}
                                onChange={() => handleRadioOnChange(ESSIUDQOptionTypeEnum.CREATED_BY)}/>
                            <div className='flex flex-row'>
                                <div className="source flex flex-row items-center justify-start mb-2 mr-2">
                                    <p className="mr-4 w-[100px]">建立人員</p>
                                    <div className="flex flex-row space-x-2 items-center relative w-full"
                                         ref={doctorDropdownRef}>
                                        <input
                                            className="w-full h-10 px-2 border border-[#D4D4D8] rounded-[6px] mr-4 pl-9"
                                            type="text" disabled={isDisabled() || !isSysMaintain} value={doctor}
                                            onChange={(e) => handleSelectUserOnChange(e)}
                                            onFocus={handleDoctorDropdownOnFocus}/>
                                        <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                             alt="search-icon" src={SearchIcon} role="button"/>
                                        {
                                            showDoctorDropdownOptions && isSysMaintain && (
                                                <div
                                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                        {
                                                            // 取得過濾後的醫生清單
                                                            getFilterDoctor().map((doctor, index) =>
                                                                <div
                                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                    role="button" key={index}
                                                                    onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                                    <p>{`${doctor.userNo} ${doctor.userName}`}</p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="date flex flex-row items-center justify-start mb-2 mr-2">
                                    {/*開始日期起*/}
                                    <p className="mr-4">開始日期起訖</p>
                                    <BaseInput inputMode="date"
                                               className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                               disabled={isDisabled()} value={startDate} type="date"
                                               onChange={(e) => handleStartDateOnChange(e)}/>
                                    {/*開始日期終*/}
                                    <p className="m-1">~</p>
                                    <BaseInput inputMode="date"
                                               className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                               disabled={isDisabled()} value={endDate} type="date"
                                               onChange={(e) => handleEndDateOnChange(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="source flex flex-row items-center justify-start mr-2">
                            {/*查詢按鈕*/}
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                onClick={handleSearchButtonOnClick}>
                                {t('general.query')}
                            </button>
                        </div>
                    </div>
                    <div className="source flex flex-row items-end justify-end mr-4">
                        {/*簽章按鈕*/}
                        <button
                            className="flex flex-row items-center justify-start h-10 bg-[#F5A524] text-white border-[#2B6CB0] rounded-[6px] px-4"
                            onClick={handleSignOnClick}>
                            簽章
                        </button>
                    </div>
                </div>
                <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                    <div className="flex flex-row items-center justify-between mb-2">
                        <div>
                            {/*進階搜尋*/}
                            <BaseInput inputMode="search"
                                       className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                       type="text" placeholder={t('general.advancedSearch')} value={advancedSearchText}
                                       onChange={(e) => handleAdvancedSearchTextOnChange(e)}/>
                        </div>
                        <div>
                            <span>已選取 {selectedData.length} 筆</span>
                        </div>
                    </div>
                    <div className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[65vh]'>
                        <table
                            className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                            <thead>
                            <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                                <th className={`w-[70px] ${tableElementDefaultType}`}
                                    onClick={handleSelectedAllOnClick}>
                                    <input
                                        className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]"
                                        type="checkbox" style={{accentColor: "#38A169"}} value={selectedAll}/>
                                </th>
                                <th className={`${tableElementDefaultType}`}>操作</th>
                                <th className={`${tableElementDefaultType}`}>單據類別 <br/> 表單抬頭</th>
                                <th className={`${tableElementDefaultType}`}>門/急/住 <br/> 病人資訊</th>
                                <th className={`${tableElementDefaultType}`}>狀態</th>
                                <th className={`w-[250px] ${tableElementDefaultType}`}>表單代號<br/> 建立人員/日期</th>
                                <th className={`${tableElementDefaultType}`}>處理狀態<br/>說明</th>
                                <th className={`${tableElementDefaultType}`}>內容<br/>處理明細</th>
                                <th className={`${tableElementDefaultType}`}>Trace Id</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !arrayIsEmpty(data) && getFilterData().map((data, index) => (
                                    <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                        key={index}>
                                        <td className={`${tableElementDefaultType}`}
                                            onClick={() => handleSelectedOnClick(index)}>
                                            <input
                                                className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]"
                                                type="checkbox" style={{accentColor: "#38A169"}} value={data.selected}
                                                onChange={undefined}/>
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {/*閱讀按鈕*/}
                                            <button onClick={() => handleCancelPdfJsonOnClick(data)}
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2">
                                                <PdfJsonIcon className="mr-1"/>閱讀
                                            </button>
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.emrRpttype ? data.emrRpttype : ""}<br/>{data.emrTitle ? data.emrTitle : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.inpOpd ? data.inpOpd : ""}<br/>{data.patientName ? data.patientName : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.transStatus ? data.transStatus : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.formCode ? data.formCode : ""}<br/>{data.createUser ? data.createUser : ""}{data.createDatetime ? data.createDatetime : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.fixStatue ? data.fixStatue : ""}<br/>{data.memo ? data.memo : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.contentType ? data.contentType : ""}<br/>{data.transMemo ? data.transMemo : ""}
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>
                                            {data.transDocId ? data.transDocId : ""}
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end w-full">
                        <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                                    totalSize={totalSize}
                                    onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                    onPreviousOnClick={onPaginationPreviousOnClick}
                                    onNextOnClick={onPaginationNextOnClick}
                                    onPageSizeChange={onPaginationPageSizeOnChange}/>
                    </div>
                </div>
                {/* PDF/JSON 閱讀btn */}
                <PdfJsonPopup show={showPdfJson} closePopupButtonOnClick={handlePdfJsonPopupOnClose}/>
                {/* pinCode彈跳視窗 */}
                <PinCodePopup show={showPinCode} closePopupButtonOnClick={handleCloseCancelPinCodePopup}
                              savePopupButtonOnClick={handleSavePinCodePopup}/>
                {/* 簽章後定時呼叫簽章進度，簽章完成關閉 */}
                <SignLoadingPopup show={showCancelSignLoading}
                                  closePopupButtonOnClick={handleCloseCancelSignLoadingPopup}/>
            </div>
        </>
    )
}
export default UnsignedDocumentsQueryPage
