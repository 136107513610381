import React, {useEffect, useMemo, useState} from 'react'
import {CancelSlotBtn, DelSlotBtn, EditSlotBtn, SaveIconSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {arrayIsEmpty, InputTypeEnum, time2String, ToastTypeEnum,} from 'edah_utils/dist'
import {BaseInput} from "../../Input/BaseInput";
import {rsisQueryMail} from "../../../api/v1/RSIS";

/**
 * Mail警示信箱發送清單列表項目
 * @param item {object} 列表項目
 * @param onSave {function} 保存事件
 * @param onCancel {function} 取消事件
 * @param dropListData {Array<object>} 下拉選單資料
 * @return {JSX.Element}
 * @constructor
 */
const ListItem = ({item, onSave, onCancel, dropListData}) => {
    const [userList, setUserList] = useState([])
    const {setShowDeletePopup, setFocusItem, showToast} = usePopup()
    const [editedItem, setEditedItem] = useState(item)
    const [show, setShow] = useState(false)
    const {setShowQueryCreateUserList, focusItem} = usePopup()

    /**
     * 刪除列表項目
     * @return {void}
     */
    const handleOnDelete = () => {
        setFocusItem(item)
        setShowDeletePopup(true)
    }

    /**
     * 取消編輯
     * @return {void}
     */
    const handleOnCancel = () => {
        if (item.isNew) {
            onCancel(item.mailListsUUid)
        } else {
            setEditedItem(item)
        }
    }

    /**
     * 變更列表狀態為編輯狀態
     * @return {void}
     */
    const handleEdit = () => {
        // 設定為編輯狀態
        setEditedItem(prev => ({
            ...prev,
            editable: true
        }))
    }

    /**
     * 保存列表項目
     * @return {void}
     */
    const handleOnSave = () => {
        const startDate = new Date(time2String(editedItem.effStartDatetime, 'YYYY-MM-DD'))
        const endDate = new Date(time2String(editedItem.effEndDatetime, 'YYYY-MM-DD'))
        // 驗證結束日期是否早於開始日期
        if (endDate < startDate) {
            showToast({message: "結束日期不能早於開始日期", type: ToastTypeEnum.Warning})
            return;
        }
        // 保存列表項目
        onSave(editedItem, () => setEditedItem(prev => ({...prev, editable: false})))
    }

    /**
     * 當員工代號改變時
     * @param item {Object} 員工物件
     * @return {void}
     */
    const handleUserChange = (item) => {
        setEditedItem(prev => ({
            ...prev,
            userNo: item.userNo,
            userName: item.userName
        }))
    }

    const handleInputChange = (field, value) => {
        if (field === 'effStartDatetime' || field === 'effEndDatetime') {
            const formattedDate = formatDateTime(value);
            setEditedItem(prev => {
                const newState = {...prev, [field]: formattedDate};
                return newState;
            })
        } else {
            setEditedItem(prev => {
                const newState = {...prev, [field]: value};
                return newState;
            })
        }
    }

    const handleMailTypeSelect = (selectedMailType) => {
        setEditedItem(prev => {
            const newState = {
                ...prev,
                mailType: selectedMailType.codeNo,
                codeValue1: selectedMailType.codeValue1
            };
            return newState;
        });
        setShow(false);
    }

    /**
     * 當開始日期改變時
     * @param value {string} 開始日期
     * @return {void}
     */
    const handleStartDateChange = (value) => {
        setEditedItem(prev => ({
            ...prev,
            effStartDatetime: value
        }))
    }

    /**
     * 當結束日期改變時
     * @param value {string} 結束日期
     * @return {void}
     */
    const handleEndDateChange = (value) => {
        setEditedItem(prev => ({
            ...prev,
            effEndDatetime: value
        }))
    }

    /**
     * 根據編輯狀態渲染按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        if (editedItem.editable) {
            return (
                <div className='flex flex-row gap-2'>
                    {/* 保存按鈕 */}
                    <SaveIconSlotBtn onClick={handleOnSave}/>
                    {/* 取消按鈕 */}
                    <CancelSlotBtn onClick={handleOnCancel}/>
                </div>
            )
        } else {
            return (
                <div className='flex flex-row gap-2'>
                    {/* 編輯按鈕 */}
                    <EditSlotBtn onClick={handleEdit}/>
                    {/* 刪除按鈕 */}
                    <DelSlotBtn onClick={handleOnDelete}/>
                </div>
            )
        }
    }

    /**
     * 獲取郵件用戶列表
     * @return {Promise<void>}
     */
    const getAllMailUsers = async () => {
        try {
            const response = await rsisQueryMail({});
            if (response.statusCode === 0) {
                setUserList(response.data);
            } else {
                showToast({message: "獲取郵件用戶列表失敗", type: "error"})
            }
        } catch (error) {
            showToast({message: "獲取郵件用戶列表時發生錯誤", type: "error"})
        }
    }

    // 監聽focusItem, 當focusItem改變時，將focusItem的值設定到editedItem
    useEffect(() => {
        if (focusItem) handleUserChange(focusItem)
    }, [focusItem])

    // 第一次執行時
    useMemo(() => {
        getAllMailUsers()
    }, []);

    // 當item改變時，若為新增的ListItem確保初始化時資料為空值
    useEffect(() => {
        if (item.isNew) {
            setEditedItem({
                mailType: "",
                codeValue1: "",
                userNo: "",
                userName: "",
                effStartDatetime: "",
                effEndDatetime: "",
                memo: "",
                ...item
            });
        }
    }, [item]);

    return (
        <li key={item.mailListsUUid}>
            {renderSlotBtn()}
            {
                editedItem.editable ? (
                        <>
                            <div className='flex items-center relative'>
                                <BaseInput
                                    className='bg-white w-[173px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-4'
                                    type={InputTypeEnum.Text}
                                    value={editedItem.mailType}
                                    onChange={(e) => handleInputChange('mailType', e.target.value)}
                                    onFocus={() => setShow(true)}
                                    onBlur={() => setTimeout(() => setShow(false), 200)}/>
                                {
                                    show && !arrayIsEmpty(dropListData) && (
                                        <span
                                            className='absolute flex flex-col bg-white z-10 border-[1px] border-[#d4d4d8] rounded-md w-[256px] top-8 -right-5'>
                                <span className='max-h-[200px] w-full transparent overflow-y-auto px-2.5'>
                                    {
                                        dropListData.map((option, index) =>
                                            <div
                                                key={`${option.mailListsUUid}-${index}`}
                                                className='w-full bg-transparent border-b min-h-10 flex px-4 py-2 text-[#181818] items-center hover:bg-[#BEE3F8] cursor-pointer'
                                                onMouseDown={() => handleMailTypeSelect(option)}>
                                                {option.codeNo}
                                            </div>
                                        )
                                    }
                                </span>
                            </span>
                                    )
                                }
                            </div>
                            <div>
                                <input className="w-full h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                                       disabled={true} type={InputTypeEnum.Text} value={editedItem.codeValue1}
                                       onChange={(e) => handleInputChange('codeValue1', e.target.value)}/>
                            </div>
                            <div>
                                <input
                                    className="w-[180px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2 hover:cursor-pointer"
                                    type={InputTypeEnum.Text}
                                    value={`${editedItem ? `${editedItem.userNo} ${editedItem.userName}` : ''}`}
                                    onClick={(e) => setShowQueryCreateUserList(true)}/>
                            </div>
                            <div>
                                <BaseInput
                                    className="w-[200px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                                    type={InputTypeEnum.Date}
                                    max="9999-12-31" inputMode={InputTypeEnum.Date}
                                    value={editedItem.effStartDatetime ? time2String(editedItem.effStartDatetime, 'YYYY-MM-DD') : ''}
                                    onChange={(e) => handleStartDateChange(e.target.value)}/>
                            </div>
                            <div>
                                <BaseInput
                                    className="w-[200px] h-10 border-[1px] border-[#D4D4D8] rounded-[6px] pl-2"
                                    type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date}
                                    max="9999-12-31"
                                    value={editedItem.effEndDatetime ? time2String(editedItem.effEndDatetime, 'YYYY-MM-DD') : ''}
                                    onChange={(e) => handleEndDateChange(e.target.value)}/>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div>{item.mailType ? item.mailType : ''}</div>
                            <div>{item.codeValue1 ? item.codeValue1 : ''}</div>
                            <div>{`${item.userNo} ${item.userName}`}</div>
                            <div>{item.effStartDatetime ? item.effStartDatetime.split(' ')[0] : ''}</div>
                            <div>{item.effEndDatetime ? item.effEndDatetime.split(' ')[0] : ''}</div>
                        </>
                    )
            }
        </li>
    )
}

export default ListItem