import React, { useState, useMemo, useEffect } from 'react'
import { NO_RECEIPT_LETTER_FIELDS } from '../../../constants/referralSystem/tranOutCase'
import { DATETIME_FORMAT } from '../../../constants/common'
import CustomTable from '../CustomTable'
import { Pagination } from '../../Pagination/Pagination'
import { trancaseQueryNoReplyLetterCaseBySearch } from '../../../api/v1/Rmis'
import {
    validDateFormat,
    ToastTypeEnum,
    ApiErrorStatusEnum,
    time2String,
    objectIsEmpty,
} from 'edah_utils/dist'
import { BaseInput } from '../../Input/BaseInput'
import useToast from '../../../hooks/useToast'
import RadioButton from '../../Input/RadioButton'
import store from '../../../redux/store'
import {
    updatePrintContent,
    completePrintMode,
} from '../../../redux/Slice/printSlice'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import ReferralHospitalModal from '../ReferralHospitalModal'
import Button from '../../Button/Button'

/**
 * 列印尚未收到接受轉診醫院回覆名單
 * @param {object} props
 * @return {JSX.Element}
 */
const TranOutNoReplyLetterListModal = ({
    outStatusList = [],
    zipCodeList = [],
}) => {
    const [searchParams, setSearchParams] = useState({
        tranStartDate: '',
        tranEndDate: '',
        zoneNo: '',
        hospName: '',
    })
    const [dataList, setDataList] = useState([])
    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    const showToast = useToast()

    const [inputState, setInputState] = useState({
        tranStartDate: null,
        tranEndDate: null,
    })

    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 追蹤狀態 Radio buttons
     */
    const StatusRadioButtons = (noReplyCase) => {
        return (
            <div className="flex space-x-4">
                {outStatusList.map((item) => (
                    <RadioButton
                        key={item.outStatusName}
                        mode={noReplyCase.outStatus}
                        modeChecked={item.outStatus}
                        name={`${noReplyCase.encounterId}`}
                        label={item.outStatusName}
                        isDisabled
                    />
                ))}
            </div>
        )
    }

    /**
     * 轉回原醫療院所名單 table header
     */
    const renderFields = useMemo(() => {
        return NO_RECEIPT_LETTER_FIELDS.map((item) => {
            // 追蹤狀態以 radio button 呈現
            if (item.key === 'outStatus') {
                return {
                    ...item,
                    render: StatusRadioButtons,
                }
            } else {
                return item
            }
        })
    }, [])

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getNoReplyLetterList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getNoReplyLetterList(1, pageSize)
    }
    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getNoReplyLetterList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getNoReplyLetterList(page, filter.pageSize)
    }

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 查詢尚未收到接受轉診醫院回復名單
     * @return {Promise<void>}
     */
    const getNoReplyLetterList = (page, pageSize) => {
        // 欄位驗證
        const tranStartDateIsValid = validDateFormat(searchParams.tranStartDate)
        const tranEndDateIsValid = validDateFormat(searchParams.tranEndDate)
        setInputState({
            tranStartDate: tranStartDateIsValid,
            tranEndDate: tranEndDateIsValid,
        })
        if (!tranStartDateIsValid || !tranEndDateIsValid) {
            return
        }

        trancaseQueryNoReplyLetterCaseBySearch({
            tranStartDate: time2String(
                searchParams.tranStartDate,
                DATETIME_FORMAT
            ),
            tranEndDate: time2String(searchParams.tranEndDate, DATETIME_FORMAT),
            hospNo: searchParams.zoneNo,
            pageNum: page,
            pageSize,
        }).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                if (response.data.dataList.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                const list =
                    response.data.dataList.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: response.data.totalItemSize,
                    totalPageSize: response.data.totalPageSize,
                    pageNum: page,
                    pageSize,
                })
            } else {
                showToast({ message: response.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 查詢
     */
    const handleQueryOnClick = () => {
        getNoReplyLetterList(1, filter.pageSize)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 列印
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranOutNoReplyLetterList,
                printData,
            })
        )
    }

    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setSearchParams({
            ...searchParams,
            zoneNo: hospital.hospNo,
            hospName: hospital.hospName,
        })
        setIsShowReferralHospitalModal(false)
    }

    const handleZoneNoOnBlur = (e) => {
        if (!e.target.value) {
            setSearchParams({ ...searchParams, hospName: '', hospNo: '' })
        }
    }

    /**
     * 標籤列印
     */
    const handleLabelPrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranOutNoReplyLetterLabel,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div className="flex">
                    <div className="date flex flex-row items-center justify-start mb-7 mr-4">
                        <div className="mr-4 border-red-200">
                            轉出日期 <span className="text-danger ">*</span>:
                        </div>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranStartDate}
                            type="date"
                            onChange={(e) =>
                                updateSearchParams(e, 'tranStartDate')
                            }
                            inputState={inputState.tranStartDate}
                            errorMsg="必填"
                        />
                        <span className="m-1">~</span>
                        <BaseInput
                            className="w-[166px] h-10 px-4 border-[1px] rounded-[6px]"
                            value={searchParams.tranEndDate}
                            inputState={inputState.tranEndDate}
                            onChange={(e) =>
                                updateSearchParams(e, 'tranEndDate')
                            }
                            type="date"
                            errorMsg="必填"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-7 mr-4">
                        <div className="mr-4 align-middle">院所代碼:</div>
                        <div className="4">
                            <Button
                                classNames="bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                text={'院所查詢'}
                                onClickFn={() =>
                                    setIsShowReferralHospitalModal(true)
                                }
                            />
                        </div>
                        <input
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] w-[160px] mr-2"
                            type="text"
                            value={searchParams.zoneNo}
                            onChange={(e) => updateSearchParams(e, 'zoneNo')}
                            onBlur={(e) => handleZoneNoOnBlur(e)}
                        />
                        <input
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] flex-1"
                            type="text"
                            value={searchParams.hospName}
                            disabled
                        />
                    </div>
                    <button
                        className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        onClick={handleQueryOnClick}
                    >
                        {t('general.query')}
                    </button>
                </div>
                <div className="flex">
                    <button
                        className="bg-gray-100 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                        onClick={handlePrint}
                    >
                        {t('general.print')}
                    </button>
                    <button
                        className="bg-gray-100 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                        onClick={handleLabelPrint}
                    >
                        {t('general.labelPrint')}
                    </button>
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                totalPage={filter.totalPageSize}
                total={filter.totalItemSize}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>

            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    zipCodeList={zipCodeList}
                    onConfirm={onSelectHospital}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </div>
    )
}

export default TranOutNoReplyLetterListModal
