//Import Icon
import {ReactComponent as AddIcon} from '../../../assets/images/icons/add_circle.svg';

// Import Function
import React, {useCallback, useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable';
import ListItem from './ListItem';
import {usePopup} from '../PopupProvider';
import DeletePopup from '../Popup/DeletePopup';
import {
    rsisDelAgent,
    rsisQueryAgent,
    rsisQueryAgentLike,
    rsisAddAgent,
    rsisQueryBaseData,
} from "../../../api/v1/RSIS"
import {
    queryDepartmentByUserNo
} from "../../../api/v1/Menu"
import {t} from "i18next"
import {ApiErrorStatusEnum, getLocalStorage, InputTypeEnum, objectIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import {FuzzySearchInput} from "../FuzzySearchInput";
import QueryPersonList from "../Modal/QueryPersonList";
import QueryCreateUserList from "../Modal/QueryCreateUserList";
import {Title} from "../utils";
import {BaseInput} from "../../Input/BaseInput";

// 表格欄位
const fields = [
    "",
    "支援醫師代碼-姓名",
    "代理人員代號-姓名",
    "代理人員部門",
]

/**
 * 病房代理人員維護
 * @return {JSX.Element}
 */
function WardAgentMaintain() {
    // 醫師資料清單
    const [doctorList, setDoctorList] = useState([])
    // 醫師代號/姓名
    const [userNo, setUserNo] = useState('')

    // 分頁參數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })
    // 搜尋字串
    const [queryString, setQueryString] = useState('')
    // 表格資料集
    const [dataList, setDataList] = useState([])
    // 列表組件
    const List = ListItem
    // 使用上下文
    const {
        showDeletePopup,
        setShowDeletePopup,
        focusIndex,
        setFocusIndex,
        showQueryPersonList,
        setShowQueryPersonList,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        setFocusItem,
        showToast
    } = usePopup()

    /**
     * 重置醫師代號/姓名
     * @return {void}
     */
    const handleResetValue = () => {
        //清空資料
        setUserNo('')
        //取得病房代理人員所有資料
        getQueryAgent()
    }

    /**
     * 醫師代號/姓名變更時
     * @return {void}
     */
    const handleOnChangeUserNo = (item) => {
        getQueryAgentLike(item)
    }

    /**
     * 新增列表事件，根據editable狀態新增一個可編輯或不可編輯的列表
     * @return {void}
     */
    const handleAddItemOnClick = () => {
        const newItem = {
            id: Date.now(), // 使用臨時 ID
            editable: true,
            userNo: "",
            userName: "",
            agentUserNo: "",
            agentUserName: "",
            agentDeptNo: "",
            agentDeptName: "",
            isNew: true // 標記為新項目
        };
        setDataList(prevDataList => [newItem, ...prevDataList ]);
    }

    /**
     * 儲存列表事件
     * @param {Object} item - 要保存的項目
     * @return {void}
     */
    const handleSaveItemOnClick = (item) => {
        rsisAddAgent({
            //UUID_PK
            agentId:'',
            // 支援人員代號
            supportUserNo: item.userNo,
            // 代理人員代號
            agentUserNo: item.agentUserNo,
            // 代理人員部門代號
            agentDeptNo: item.agentDeptNo,
            // 備註
            memo: '',
            // 院區
            zone: getLocalStorage('campus'),
            // 支援人員名稱
            supportUserName: item.userName,
            // 代理人員名稱
            agentUserName: item.agentUserName,
        }).then((res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                getQueryAgent()
                showToast({message: '保存成功', type: ToastTypeEnum.Success});
            } else {
                showToast({message: `${msg}，保存失敗`, type: ToastTypeEnum.Error});
            }
        }))
    }

    /**
     * 取消新增列表事件
     * @param {number} index - 要取消的項目索引
     * @return {void}
     */
    const handleCancelItemOnClick = (id) => {
        setDataList(prevDataList => prevDataList.filter(item => item.id !== id));
    }

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = () => delAgent()

    /**
     * 修改現有列表項目的資料
     * @param {string} id - 列表項目 ID
     * @param {field} string - 列表項目欄位
     * @param {value} string - 列表項目值
     * @return {void}
     */
    const handleItemChange = useCallback((id, field, value) => {
        setDataList(prevDataList => prevDataList.map(item =>
            item.id === id ? {...item, [field]: value} : item
        ))
    }, [])

    /**
     * 醫師資料更動時
     * @param {string} item - 醫師資料
     */
    const handleDoctorChange = (item) => {
        setUserNo(`${item.userNo} ${item.userName}`)
        setShowQueryCreateUserList(false)
        getQueryAgentLike(item.userNo)
    }

    /**
     * 支援醫師資料更動時
     * @param {string} item - 支援醫師資料
     */
    const handleSupportDoctorChange = (item) => {
        setFocusItem(item)
    }

    /**
     * 取得病房代理人員所有資料
     * @return {void}
     */
    const getQueryAgent = () => {
        rsisQueryAgent({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                console.log(data)
                // 設定列表資料
                setDataList(data)
            } else { // 取得失敗
                // 清空列表
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 根據醫師代號/姓名取得病房代理人員資料
     * @return {void}
     */
    const getQueryAgentLike = (item) => {
        rsisQueryAgentLike({
            filterName: 'CODENAME',
            value: item
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
            } else { // 取得失敗
                // 清空列表
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有醫師資料
     * @return {void}
     */
    const getDoctorList = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師資料
                setDoctorList(data.doctorList)
            } else { // 取得失敗
                // 清空醫師資料
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基本資料
     * @return {void}
     */
    const getBasicData = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                const key = Object.keys(data)
            } else { // 取得失敗
                // 清空醫師資料
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除病房代理人員資料
     * @return {void}
     */
    const delAgent = () => {
        rsisDelAgent({
            // 代理人員代號
            PKey: focusIndex
        }).then(res => {
            // 狀態 / 訊息 / 資料
            const {err, msg, data} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 清空資料
                setFocusIndex(null)
                // 重新取得列表資料
                getQueryAgent()
                // 關閉刪除彈窗
                setShowDeletePopup(false)
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得基本資料
        getBasicData()
        // 取得病房代理人員所有資料
        getQueryAgent()
        // 取得所有醫師資料
        getDoctorList()
    }, [])

    return (
        <div className='px-6 py-2'>
            <div className='flex flex-row items-center gap-1 pl-1 pt-4'>
                <div className='flex flex-row items-center gap-1'>
                    <Title extendClass='pl-1'>
                        {'醫師代號/姓名：'}
                    </Title>
                    <BaseInput className='w-[180px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300'
                               inputMode={InputTypeEnum.Text}
                               type={InputTypeEnum.Text}
                               value={userNo}
                               onClick={()=>setShowQueryCreateUserList(true)}/>
                </div>
                {/* 清除搜尋條件 */}
                <button
                    className='className="flex items-center justify-center h-10 px-4 border-[2px] bg-[#90CDF4] text-white rounded-[6px] hover:cursor-pointer'
                    onClick={handleResetValue}
                >{t('general.clearQueryParams')}</button>
            </div>
            <div className='w-[76px] flex flex-row items-center gap-1 my-4 hover:cursor-pointer'
                 onClick={handleAddItemOnClick}>
                <AddIcon className="w-[33.33px] h-[33.33px]"/>
                <p className='text-[#38A169]'>{t('general.add')}</p>
            </div>
            <CustomTable
                advancedSearch={true}
                fields={fields}
                dataList={dataList}
                ListItem={ListItem}
                type="RSISWardAgentMaintainTable"
                paginationProps={paginationProps}
                handleOnListItemSave={handleSaveItemOnClick}
                handleOnListItemCancel={handleCancelItemOnClick}
                handleOnListItemChange={handleItemChange}
            />
            {/* 刪除彈窗 */}
            {showDeletePopup &&
                <DeletePopup
                    handleDeletePopupClose={() => setShowDeletePopup(false)}
                    handleNotificationDelete={handleListItemOnDelete}
                    currentKey={focusIndex}
                />
            }
            {/* 查詢醫師彈窗 */}
            {showQueryCreateUserList && (
                <QueryCreateUserList
                    handleOnClose={() => setShowQueryCreateUserList(false)}
                    handleOnUpdate={handleDoctorChange}
                />
            )}
            {/* 支援醫師查詢彈窗 */}
            {showQueryPersonList && (
                <QueryPersonList
                    handleOnClose={() => setShowQueryPersonList(false)}
                    handleOnUpdate={handleSupportDoctorChange}
                />
            )}

        </div>
    )
}

export default WardAgentMaintain
