import React, { useState, useEffect } from 'react';
import { BasePopup } from "../../Popup/BasePopup";
import moment from 'moment';
import {BaseInput} from "../../Input/BaseInput"; // 假設您使用 moment.js 來處理日期
import { InputTypeEnum, objectIsEmpty, stringIsEmpty, ToastTypeEnum } from "edah_utils/dist";
import useToast from '../../../hooks/useToast';

/**
 * 事件提示視窗組件
 * @param {Object} props - 組件屬性
 * @param {Function} props.onConfirm - 確認回調函數
 * @param {Function} props.onCancel - 取消回調函數
 * @param {Object} props.patientData - 病人資料
 * @param {Object} props.initialSelectTimeRange - 初始選擇的時間區間
 * @param {Object} props.apnTimeRange - 時段時間區間
 * @returns {JSX.Element} 事件提示視窗組件
 */
const CalendarPopup = ({ 
    onConfirm, 
    onCancel, 
    patientData, 
    initialSelectTimeRange,
    apnTimeRange
}) => {
    // 標題
    const [title, setTitle] = useState('');
    // 事件開始時間狀態
    const [startTime, setStartTime] = useState("");
    // 事件結束時間狀態
    const [endTime, setEndTime] = useState("");
    const [selectDate, setSelectDate] = useState("")
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 處理表單提交
     * @param {React.FormEvent} e - 表單提交事件
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const startDateTime = moment(`${selectDate} ${startTime}`, 'YYYY-MM-DD HH:mm').toDate();
        const endDateTime = moment(`${selectDate} ${endTime}`, 'YYYY-MM-DD HH:mm').toDate();
        //檢查start、end時間
        if(startDateTime < apnTimeRange.start || startDateTime > apnTimeRange.end) {
            showToast({message:"起始時間不在時段內", type:ToastTypeEnum.Error})
            return
        }
        if(endDateTime < apnTimeRange.start || endDateTime > apnTimeRange.end) {
            showToast({message:"結束時間不在時段內", type:ToastTypeEnum.Error})
            return
        }
        if(startDateTime >= endDateTime) {
            showToast({message:"起始時間不能大於等於結束時間", type:ToastTypeEnum.Error})
            return
        }

        onConfirm({
            title,
            startTime: startDateTime,
            endTime: endDateTime
        });
    };

    const formatTime = (date) => moment(date).format('HH:mm');

    /**
     * 開始時間輸入框值變更時
     * @param {Event} e 
     */
    const handleInputStartTimeOnChange = (e) => {
        setStartTime(e.target.value)
    }

    /**
     * 結束時間輸入框值變更時
     * @param {Event} e 
     */
    const handleInputEndTimeOnChange = (e) => {
        setEndTime(e.target.value)
    }
    
    const content = (
        <form className="px-4 pb-4" onSubmit={handleSubmit}>
            <p className="text-left mb-4 font-bold text-[24px]">{title}</p>
            <div className="flex flex-row justify-between items-center">
                <BaseInput
                    className="w-[165px] h-[40px] pl-4 mb-4 border rounded"
                    type={InputTypeEnum.Date}
                    value={selectDate}
                    disable={true}
                    onChange={(e) => setSelectDate(e.target.value)}
                />
                <BaseInput
                    className="w-[165px] h-[40px] pl-6 mb-4 border rounded"
                    type={InputTypeEnum.Time}
                    value={startTime}
                    onChange={(e) => handleInputStartTimeOnChange(e)}
                />
                <p className='mb-4'>~</p>
                <BaseInput
                    className="w-[165px] h-[40px] pl-6 mb-4 border rounded"
                    type={InputTypeEnum.Time}
                    value={endTime}
                    onChange={(e) => handleInputEndTimeOnChange(e)}
                />
            </div>
            <div className="flex justify-start">
                <button type="submit" className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">存檔</button>
            </div>
        </form>
    );

    useEffect(() => {
        //設定 日期、時間區間
        setSelectDate(moment(initialSelectTimeRange.start).format('YYYY-MM-DD'))
        setStartTime(moment(initialSelectTimeRange.start).format('HH:mm'))
        setEndTime(moment(initialSelectTimeRange.end).format('HH:mm'))

        //設定title
        if(!objectIsEmpty(patientData)) {
            let titleText = ""
            if(!stringIsEmpty(patientData.patientId)) {
                titleText = `${patientData.patientId} `
            }
            if(!stringIsEmpty(patientData.patientName)) {
                titleText = `${titleText}${patientData.patientName} `
            }
            if(!stringIsEmpty(patientData.genderName)) {
                titleText = `${titleText}${patientData.genderName} `
            }
            setTitle(titleText)
        }
    }, [])

    return (
        <BasePopup
            title="掛號確認"
            content={content}
            closePopupButtonOnClick={onCancel}
            width="575px"
        />
    );
};

export default CalendarPopup;