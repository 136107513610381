import React, { useEffect, useState, useRef } from 'react'
import {t} from "i18next"
import { DefaultButton } from '../../Button/DefaultButton'
import {
    objectIsEmpty,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    arrayIsEmpty,
    stringIsEmpty
} from "edah_utils/dist"
import useOutsideClick from '../../../hooks/useOutsideClick'

/**
 * 批次更新表單
 * @param {Object} previewData 觀看的資料
 * @param {Object} baseData 基礎資料
 * @param {Function}  儲存事件
 * @returns 
 */
const ScheduleBatchesUpdateForm = ({
    previewData, 
    selectableData = null,
    baseData,
    onSave
}) => {
    const defaultModifyData = {
        //預約最大看診人次
        limit : null,
        //現場最大看診人次
        onsiteLimit : null,
        //看診時間
        slotMin : null,
        //預約方式
        giveNumType : null,
        //預約限制
        reserveLimitType : null,
        //收費別
        cashType : null,
        //網掛/APP
        webapptFlag : null,
        //網掛/APP取消看診進度
        showProcessFlag : null,
        //預約網掛天數
        webapptDay : null,
        //診室地點
        clinicLocation : null,
        //注意事項
        weekNotice : null,
        //診間標語
        clinicSlogan : null,
        //代班醫師
        locumDoctor : null,
        //停診
        stopClinicFlag : null,
        //停診原因
        stopClinicReason : null,
        //當日停診公告
        stopClinicBulletinFlag : null,
    }
    //變動的資料
    const [modifyData, setModifyData] = useState(defaultModifyData)
    //現行資料
    const [currentData, setCurrentData] = useState(previewData)

    //當前tab
    const [currentTab, setCurrentTab] = useState("current")
    // tab 文字顏色 、 tab底線顏色
    const [tabColor, setTabColor] = useState(["text-primary", "border-b-primary","text-base", "border-b-base"])
    // 代理醫師下拉選項
    const [locumDoctorOptionList, setLocumDoctorOptionList] = useState(null)
    //是否顯示代班醫生下拉選單
    const [showLocumDoctorDropDown, setShowLocumDoctorDropDown] = useState(false)
    //ref 用於指向代班醫師下拉菜單元素
    const dropdownLocumDoctorRef = useRef(null)
    useOutsideClick({
        ref: dropdownLocumDoctorRef,
        handler: () => setShowLocumDoctorDropDown(false),
    });

    /**
     * 根據搜尋文字過濾代診醫生選項
     * @return {Array}
     */
    const getFilterLocumDoctorOptions = () => {
        const searchText = modifyData.locumDoctor
        // 搜尋文字為空
        if (searchText === '' || searchText === null) {
            return baseData.doctorList
        }
        else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.doctorList.filter(doctor => hasSpace ?
                (doctor.userNo.includes(splitSearchTextArray[0]) && doctor.userName.includes(splitSearchTextArray[1])) :
                (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
            )
        }
    }

    /**
     * 
     * @param {e} currentForm 現行資料tab, updateForm 預異動項目tab
     */
    const handleTableOnChange = (data) => {
        // setCurrentTab(data)
        if(data != currentTab) setCurrentTab(data)
        if(data === "current") {
            setTabColor(["text-primary", "border-b-primary", "text-base", "border-b-base"])
        }else if(data === "update") {
            setTabColor(["text-base", "border-b-base", "text-primary", "border-b-primary"])
        }
    }

    /**
     * 預約最大看診人次改變時
     * @param v 
     */
    const handleMaxPatientOnChange = (v) => {
        setModifyData({
            ...modifyData,
            limit: v
        })
    }

    /**
     * 現場最大看診人次人數改變時
     * @param v
     */
    const handleSiteNumberOnChange = (v) => {
        setModifyData({
            ...modifyData,
            onsiteLimit: v
        })
    }

    /**
     * 預約方式改變時
     * @param v
     */
    const handleReservationMethodOnChange = (v) => {
        setModifyData({
            ...modifyData,
            giveNumType: v.toString()
        })
    }

    /**
     * 預約限制改變時
     * @param v 
     */
    const handleReservationLimitOnChange = (v) => {
        setModifyData({
            ...modifyData,
            reserveLimitType: v
        })
    }

    /**
     * 收費別改變時
     * @param {Event} e 
     */
    const handleCashTypeOnChange = (e) => {
        setModifyData({
            ...modifyData,
            cashType: e.target.value
        })
    }

    /**
     * 通報單位改變時
     * @param v 
     */
    const handleNotificationUnitOnChange = (v) => {
        setModifyData({
            ...modifyData,
            notificationUnit: v
        })
    }

    /**
     * 是否調閱病歷改變時
     * @param v 
     */
    const handleSentChartFlagOnChange = () => {
        setModifyData({
            ...modifyData,
            sentChartFlag: !sentChartFlag
        })
    }

    /**
     * 是否網掛/APP
     * 
     */
    const handleWebAppFlagOnChange = (state) => {
        setModifyData({
            ...modifyData,
            webapptFlag: state
        })
    }

    /**
     * 是否網掛/APP取消看診進度
     * 
     */
    const handleWebOrAppCancelOnChange = (state) => {
        setModifyData({
            ...modifyData,
            showProcessFlag: state
        })
    }

    /**
     * 診室地點改變時
     * @param v
     */
    const handleClinicLocationOnChange = (v) => {
        setModifyData({
            ...modifyData,
            clinicLocation: v
        })
    }

    /**
     * 注意事項改變時
     * @param v
     */
    const handleWeekNoticeOnChange = (v) => {
        setModifyData({
            ...modifyData,
            weekNotice: v
        })
    }

    /**
     * 診間標語改變時
     * @param v
     */
    const handleClinicSloganOnChange = (v) => {
        setModifyData({
            ...modifyData,
            clinicSlogan: v
        })
    }

    /**
     * 停診原因改變時
     * @param v
     */
    const handleStopClinicReasonOnChange = (e) => {
        setModifyData({
            ...modifyData,
            stopClinicReason: e.target.value
        })
    }

    /**
     * 當日停代診公告改變時
     */
    const handleStopClinicBulletinFlagOnChange = () => {
        setModifyData({
            ...modifyData,
            stopClinicBulletinFlag: !modifyData.stopClinicBulletinFlag
        })
    }

    /**
     * 代班醫師變更
     * @param {Event} e 
     */
    const handleLocumDoctorOnChange = (e) => {
        let haveLocumDoctor = !stringIsEmpty(e.target.value)
        //代班醫師要連動停代診公告
        setModifyData({
            ...modifyData,
            locumDoctor: e.target.value,
            stopClinicBulletinFlag: haveLocumDoctor
        })
    }

    /**
     * 代理醫生方框取得焦點時
     * @return {void}
     */
    const handleInputLocumDoctorOnFocus = () => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions())
        setShowLocumDoctorDropDown(true)
    }

    /**
     * 選取代理醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleLocumDoctorOptionOnClick = (item) => {
        if(objectIsEmpty(item)) return
        setModifyData({
            ...modifyData,
            locumDoctor: `${item.userNo} ${item.userName}`,
            stopClinicBulletinFlag: true
        })

        setShowLocumDoctorDropDown(false)
    }

    /**
     * 停診勾選變更
     */
    const handleStopClinicFlagOnChange = () => {
        let checked = !modifyData.stopClinicFlag
        let stopClinicReason = modifyData.stopClinicReason
        if (checked && stringIsEmpty(stopClinicReason)) {
            //勾選停診原因，但停診原因下拉值為空時
            stopClinicReason = baseData.stopClinicReasonList[0].justnameNo
        }
        else if(!checked) {
            stopClinicReason = ""
        }

        setModifyData({
            ...modifyData,
            stopClinicFlag: checked,
            stopClinicBulletinFlag: checked,
            stopClinicReason: stopClinicReason
        })
    }

    /**
     * 網掛預約期限(天)
     * @param {Event} e 
     */
    const handleWenapptDayOnChange = (e) => {
        setModifyData({
            ...modifyData,
            webapptDay: e.target.value
        })
    }

    const handleConsultationTimeOnChange = (time) => {
        setModifyData({
            ...modifyData,
            slotMin: time
        })
    }

    /**
     * 點擊儲存批次更新按鈕事件
     */
    const handleSaveBacthUpdateOnClick = () => {
        let stopReason = null, locumDoctor = null
        if(modifyData.stopClinicFlag !== null && modifyData.stopClinicFlag === true) {
            stopReason = modifyData.stopClinicReason
        }
        if(!stringIsEmpty(modifyData.locumDoctor)) {
            locumDoctor = modifyData.locumDoctor.split(' ')[0]
        }
        
        onSave({
            //預約最大看診人次
            limit : modifyData.limit,
            //現場最大看診人次
            onsiteLimit : modifyData.onsiteLimit,
            //看診時間
            slotMin : modifyData.slotMin,
            //預約方式
            giveNumType : modifyData.giveNumType,
            //預約限制
            reserveLimitType : modifyData.reserveLimitType,
            //收費別
            cashType : modifyData.cashType,
            //網掛/APP
            webapptFlag : modifyData.webapptFlag,
            //網掛/APP取消看診進度
            showProcessFlag : modifyData.showProcessFlag,
            //預約網掛天數
            webapptDay : modifyData.webapptDay,
            //診室地點
            clinicLocation : modifyData.clinicLocation,
            //注意事項
            weekNotice : modifyData.weekNotice,
            //診間標語
            clinicSlogan : modifyData.clinicSlogan,
            //代班醫師
            locumDoctor : locumDoctor,
            //停診
            stopClinicFlag : modifyData.stopClinicFlag,
            //停診原因
            stopClinicReason : stopReason,
            //當日停診公告
            stopClinicBulletinFlag : modifyData.stopClinicBulletinFlag
        })
        //reset異動項目
        setModifyData(defaultModifyData)
    }

    /**
     * 取得預約限制字串
     * @param {String} type 限制類型
     */
    const getReserveLimitTypeString = (type) => {
        switch(type) {
            case RegsWeekReservationLimitEnum.Normal:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`)
            case RegsWeekReservationLimitEnum.DoctorOnly:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`)
            case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`)
        }
        return ''
    }

    /**
     * 取得代班醫師名字
     * @param {String} doctorNo 醫生碼
     */
    const getLocumDoctorName = (doctorNo) => {
        if(doctorNo === 'ALL') {
            return ''
        }
        const findedDoctor = baseData.doctorList.find(item => item.userNo === doctorNo)

        return objectIsEmpty(findedDoctor) ? '' : findedDoctor.userName
    }

    /**
     * 取得收費別字串
     * @param {String} type 收費別
     */
    const getCashTypeString = (type) => {
        const findedCashType = baseData.cashTypeList.find(item => item.cashType === type)

        return objectIsEmpty(findedCashType) ? '' : findedCashType.cashName
    }

    /**
     * 取得停診原因字串
     * @param {String} no 理由碼
     * @returns 
     */
    const getStopClinicReasonName = (no) => {
        const finded = baseData.stopClinicReasonList.find(item => item.justnameNo === no)

        return objectIsEmpty(finded) ? '' : finded.justnameName
    }

    /**
     * 監聽現行資料變動
     */
    useEffect(() => {
        if(previewData !== null) {
            //透過停診原因碼找出停診原因字串
            const findedReason = baseData.stopClinicReasonList.find(item => item.justnameNo === previewData.stopClinicReason)
            const stopClinicReasonText = objectIsEmpty(findedReason) ? "" : findedReason.justnameName
            //透過收費別碼找出收費別字串
            const findedCashType = baseData.cashTypeList.find(item => item.cashType === previewData.cashType)
            const cashTypeName = objectIsEmpty(findedCashType) ? "" : findedCashType.cashName
            setCurrentData({
                ...previewData,
                stopClinicReasonText: stopClinicReasonText,
                cashTypeName: cashTypeName
            })
        }
        else {
            setCurrentData(null)
        }
    }, [previewData])

    /**
     * 監聽selectableData變化
     */
    useEffect(() => {
        if(arrayIsEmpty(selectableData)) {
            //為空或empty
            //reset異動項目
            setModifyData(defaultModifyData)
        }
    }, [selectableData])

    /**
     * 監聽代理醫生輸入框變化
     */
    useEffect(() => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions())
    }, [modifyData.locumDoctor])

  return (
    <div className='w-full min-w-[822px] flex flex-col'>
        {/* tab */}
        <div className='flex flex-row px-[30px] py-3'>
            <button className='w-full min-w-[381px] flex flex-col justify-center items-center text-center' onClick={()=>handleTableOnChange("current")}>
                <div className={`${tabColor[0]}`}>{t('Schedule.scheduleCurrentData')}</div> 
                <div className={` w-full h-[2px] border-b-2 border-solid ${tabColor[1]}`}></div>
            </button>
            <button className='w-full min-w-[381px] flex flex-col justify-center items-center text-center' onClick={()=>handleTableOnChange("update")}>
                <div className={`${tabColor[2]}`}>{t('Schedule.schedulePreUpdateItem')}</div> 
                <div className={` w-full h-[2px] border-b-2 border-solid ${tabColor[3]}`}></div>
            </button>
        </div>
        {/* 現行資料 */}
        { currentTab==="current" && !objectIsEmpty(currentData) &&
            <div className='w-full min-w-[742px] flex flex-col gap-[5px] min-h-[976px]'>
                {/* 預約最大看診人次 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.maximumNumberOfAppointments")}</p>
                    </div>
                    <div className='w-[260px] h-[42px] flex justify-center'>
                        <input
                            className='w-[235px] h-10 border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text' value={currentData.limit}
                            readOnly={true}
                        />
                    </div>
                </div>
                {/* 現場最大看診人次 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.maximumOnsitePatients")}</p>
                    </div>
                    <div className='w-[260px] h-[42px] flex justify-center'>
                        <input
                            className='w-[235px] h-10 border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text' value={currentData.onsiteLimit}
                            readOnly={true}
                        />
                    </div>
                </div>
                {/* 看診時間(分/人) */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.slotTime")}</p>
                    </div>
                    <div className='w-[260px] h-[42px] flex justify-center'>
                        <input
                            className='w-[235px] h-10 border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text' value={currentData.slotMin}
                            readOnly={true}
                        />
                    </div>
                </div>
                {/* 預約方式 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.reservationMethod")}</p>
                    </div>
                    <div className='w-[260px] flex flex-row items-center pl-4 space-x-4'>
                        <>
                            <input
                                id='clinicNumber'
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-gray-600 checked:ring-gray-600 hover:cursor-pointer"
                                type="radio" name="clinicNumber"
                                checked={currentData.giveNumType === RegsWeekReservationMethodEnum.ClinicNumber.toString()}
                                readOnly={true}
                            />
                            <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`)}</label>
                        </>
                        <>
                            <input
                                id='time'
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-gray-600 checked:ring-gray-600 hover:cursor-pointer"
                                type="radio" name="time"
                                checked={currentData.giveNumType === RegsWeekReservationMethodEnum.Time.toString()}
                                readOnly={true}
                            />
                            <label>{t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`)}</label>
                        </>
                    </div>
                </div>
                {/* 預約限制 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.reservationRestriction")}</p>
                    </div>
                    <div className='w-[289px] flex flex-row items-center pl-4 space-x-4'>
                        <>
                            <input
                                id='normal'
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-gray-600 checked:ring-gray-600 hover:cursor-pointer"
                                type="radio" value="normal" name="normal"
                                checked={currentData.reserveLimitType === RegsWeekReservationLimitEnum.Normal}
                                readOnly={true}
                            />
                            <label>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`)}</label>
                        </>
                        <>
                            <input
                                id='doctor'
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-gray-600 checked:ring-gray-600 hover:cursor-pointer"
                                type="radio" value="doctor" name="doctor"
                                checked={currentData.reserveLimitType === RegsWeekReservationLimitEnum.DoctorOnly}
                                readOnly={true}
                            />
                            <label>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`)}</label>
                        </>
                        <>
                            <input
                                id='doctorOnly'
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-gray-600 checked:ring-gray-600 hover:cursor-pointer"
                                type="radio" value="doctorOnly" name="doctorOnly"
                                checked={currentData.reserveLimitType === RegsWeekReservationLimitEnum.DoctorSelfOnly}
                                readOnly={true}
                            />
                            <label>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`)}</label>
                        </>
                    </div>
                </div>
                {/* 收費別 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.cashType")}</p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-center">
                        <select
                            className="w-[235px] h-10 border bg-gray-50 rounded-md border-gray-300 pl-[14px]"
                            readOnly={true}
                            value={currentData.cashType}
                            disabled
                        >
                            <option value={currentData.cashType}>{currentData.cashTypeName}</option>
                        </select>
                    </div>
                </div>
                {/* 調病歷 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'></p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-start items-center pl-3">
                        <input
                            className="form-checkbox text-green-500 h-5 w-5 rounded border-gray-300 focus:ring-green-500"
                            type="checkbox"
                            style={{accentColor: "#4B5563", pointerEvents: "none"}}
                            checked={currentData.sentChartFlag}
                            onChange={() => {
                            }}
                        />
                        <span className="pl-3">{t("Regs.general.reviewMedicalRecords")}</span>
                    </div>
                </div>
                {/* 網掛/APP */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'></p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-start items-center pl-3">
                        <input
                            className="form-checkbox text-green-500 h-5 w-5 rounded border-gray-300 focus:ring-green-500"
                            style={{accentColor: "#4B5563", pointerEvents: "none"}}
                            type="checkbox"
                            checked={currentData.webapptFlag}
                            onChange={() => {
                            }}
                        />
                        <span className="pl-3">{t("Regs.general.webAppointment")}</span>
                    </div>
                </div>
                {/* 網掛/APP取消看診進度 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'></p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-start items-center pl-3">
                        <input
                            className="form-checkbox text-green-500 h-5 w-5 rounded border-gray-300 focus:ring-green-500"
                            type="checkbox"
                            style={{accentColor: "#4B5563", pointerEvents: "none"}}
                            checked={currentData.showProcessFlag}
                            onChange={() => {
                            }}
                        />
                        <span className="pl-3">{t("Regs.general.webAppointmentCancelProcess")}</span>
                    </div>
                </div>


                {/*網掛預約期限 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-2'>
                        <p className=' text-base'>{t("Regs.general.webAppointmentLimit")}</p>
                    </div>
                    <div className='w-[130px] h-[42px] flex justify-center'>
                        <input
                            className='w-[100px] h-10 border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='number'
                            value={currentData.webapptDay}
                            disabled={true}
                        />
                    </div>
                    <div className='h-[42px] flex items-center'>
                        <p>天</p>
                    </div>
                </div>

                {/* 診室地點 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.clinicLocation")}</p>
                    </div>
                    <div className='w-[582px] h-[42px] flex justify-center'>
                        <input
                            className='w-[550px] h-10 border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text'
                            value={currentData.clinicLocation}
                            disabled={true}
                        />
                    </div>
                </div>
                {/* 注意事項 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.notice")}</p>
                    </div>
                    <div className='w-[582px] h-[70px] flex justify-center'>
                        <input
                            className='w-[550px] h-[70px] border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text'
                            value={currentData.weekNotice ? currentData.weekNotice : ""}
                            disabled={true}
                        />
                    </div>
                </div>
                {/* 診間標語 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.clinicSlogan")}</p>
                    </div>
                    <div className='w-[582px] h-[70px] flex justify-center'>
                        <input
                            className='w-[550px] h-[70px] border bg-gray-50 rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='text'
                            value={currentData.clinicSlogan ? currentData.clinicSlogan : ""}
                            disabled={true}
                        />
                    </div>
                </div>
                {/* 代班醫師 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.locumDoctor")}</p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-center">
                        <select
                            className="w-[235px] h-10 border bg-gray-50 rounded-md border-gray-300 pl-[14px]"
                            value={currentData.locumDoctor}
                            readOnly={true}
                            disabled
                        >
                            <option value={currentData.locumDoctor}>{currentData.locumDoctor}</option>
                        </select>
                    </div>
                </div>
                {/* 停診原因 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4 gap-2'>
                        <input
                            className="form-checkbox text-green-500 h-5 w-5 rounded border-gray-300 focus:ring-green-500"
                            type="checkbox"
                            style={{accentColor: "#4B5563", pointerEvents: "none"}}
                            checked={currentData.stopClinicFlag ? currentData.stopClinicFlag : false}
                            onChange={() => {
                            }}
                        />
                        <p className='text-base'>{t("Regs.general.stopClinicReason")}</p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-center">
                        <select
                            className="w-[235px] h-10 border bg-gray-50 rounded-md border-gray-300 pl-[14px]"
                            readOnly={true}
                            value={currentData.stopClinicReason}
                            disabled
                        >
                            <option value={currentData.stopClinicReason}>{currentData.stopClinicReasonText}</option>
                        </select>
                    </div>
                </div>
                {/* 當日停代診公告 */}
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'></p>
                    </div>
                    <div className="w-[260px] h-[42px] flex justify-start items-center pl-3">
                        <input
                            className="form-checkbox text-green-500 h-5 w-5 rounded border-gray-300 focus:ring-green-500"
                            type="checkbox"
                            style={{accentColor: "#4B5563", pointerEvents: "none"}}
                            checked={currentData.stopClinicBulletinFlag}
                            onChange={() => {
                            }}
                        />
                        <span className="pl-3">{t("Regs.general.stopClinicBulletinForTheDay")}</span>
                    </div>
                </div>
            </div>
        }
        {/* 預異動資料 */}
        {currentTab === "update" && !arrayIsEmpty(selectableData) &&
            <div className='w-full min-w-[742px] flex flex-col gap-[5px] min-h-[1083px]'>
                <div className='flex flex-row pl-12 '>
                    <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                        <p className=' text-base'>{t("Regs.general.maximumNumberOfAppointments")}</p>
                    </div>
                    <div className='w-[260px] h-[42px] flex justify-center'>
                        <input
                            className='w-[235px] h-10 border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                            type='number'
                        value={modifyData.limit}
                        onChange={(e)=>handleMaxPatientOnChange(e.target.value)}
                    />
                    {/*Input hide number arrow*/}
                    <style>
                        {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                    </style>
                </div>
            </div>
            {/* 現場最大看診人次 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.maximumOnsitePatients")}</p>
                </div>
                <div className='w-[260px] h-[42px] flex justify-center'>
                    <input
                        className='w-[235px] h-10 border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='number'
                        value={modifyData.onsiteLimit}
                        onChange={(e)=>handleSiteNumberOnChange(e.target.value)}
                    />
                    {/*Input hide number arrow*/}
                    <style>
                        {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                    </style>
                </div>
            </div>
            {/* 看診時間(分/人) */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.slotTime")}</p>
                </div>
                <div className='w-[260px] h-[42px] flex justify-center'>
                    <input
                        className='w-[235px] h-10 border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='number'
                        value={modifyData.slotMin}
                        readOnly={false}
                        onChange={(e)=>handleConsultationTimeOnChange(e.target.value)}
                    />
                    {/*Input hide number arrow*/}
                    <style>
                        {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                    </style>
                </div>
            </div>
            {/* 預約方式 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.reservationMethod")}</p>
                </div>
                <div className='w-[260px] flex flex-row items-center pl-4 space-x-4'>
                    <>
                        <input
                            id='clinicNumber'
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                            type="radio"
                            checked={modifyData.giveNumType === RegsWeekReservationMethodEnum.ClinicNumber.toString()}
                            onChange={()=> handleReservationMethodOnChange(RegsWeekReservationMethodEnum.ClinicNumber)}
                        />
                        <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`)}</label>
                    </>
                    <>
                        <input
                            id='time'
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                            type="radio"
                            checked={modifyData.giveNumType === RegsWeekReservationMethodEnum.Time.toString()}
                            onChange={()=> handleReservationMethodOnChange(RegsWeekReservationMethodEnum.Time)}
                        />
                        <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`)}</label>
                    </>
                </div>
            </div>
            {/* 預約限制 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.reservationRestriction")}</p>
                </div>
                <div className='w-[289px] flex flex-row items-center pl-4 space-x-4'>
                    <>
                        <input
                            id='normal'
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                            type="radio"
                            checked={modifyData.reserveLimitType === RegsWeekReservationLimitEnum.Normal}
                            onClick={()=> handleReservationLimitOnChange(RegsWeekReservationLimitEnum.Normal)}
                        />
                        <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`)}</label>
                    </>
                    <>
                        <input
                            id='doctor'
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                            type="radio"
                            checked={modifyData.reserveLimitType === RegsWeekReservationLimitEnum.DoctorOnly}
                            onClick={()=> handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorOnly)}
                        />
                        <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`)}</label>
                    </>
                    <>
                        <input
                            id='doctorOnly'
                            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                            type="radio"
                            checked={modifyData.reserveLimitType === RegsWeekReservationLimitEnum.DoctorSelfOnly}
                            onClick={()=> handleReservationLimitOnChange(RegsWeekReservationLimitEnum.DoctorSelfOnly)}
                        />
                        <label className=' hover:cursor-pointer'>{t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`)}</label>
                    </>
                </div>
            </div>
            {/* 收費別 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className='text-base'>{t("Regs.general.cashType")}</p>
                </div>
                <div className="w-[260px] h-[42px] flex justify-center">
                    <select
                        className="w-[235px] h-10 border bg-white rounded-md border-gray-300 pl-[14px]"
                        value={modifyData.cashType}
                        onChange={handleCashTypeOnChange}
                    >
                        <option value="" selected disabled hidden></option>
                    {
                        !objectIsEmpty(baseData) && baseData.cashTypeList.map((item, index) => (
                            <option 
                                key={index} 
                                value={item.cashType}
                            >
                                {item.cashName}
                            </option>
                        ))
                    }
                    </select>
                </div>
            </div>
            {/* 網掛/APP */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>開放網掛/APP</p>
                </div>
                <div className="w-[289px] h-[42px] flex justify-start items-center pl-4 space-x-4">
                    <input
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                        type="radio"
                        checked={modifyData.webapptFlag === true}
                        onChange={() => handleWebAppFlagOnChange(true)}
                        />
                    <label className=' hover:cursor-pointer'>{t('general.yes')}</label>
                    <input
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                        type="radio"
                        checked={modifyData.webapptFlag === false}
                        onClick={() => handleWebAppFlagOnChange(false)}
                        />
                    <label className=' hover:cursor-pointer'>{t('general.no')}</label>
                </div>
            </div>
            {/* 網掛/APP取消看診進度 */}
            <div className='flex flex-row pl-[30px] '>
                <div className='w-[182px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.webAppointmentCancelProcess")}</p>
                </div>
                <div className="w-[289px] h-[42px] flex justify-start items-center pl-3 space-x-4">
                    <input
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                        type="radio"
                        checked={modifyData.showProcessFlag === true}
                        onChange={() => handleWebOrAppCancelOnChange(true)}
                        />
                    <label className=' hover:cursor-pointer'>{t('general.yes')}</label>
                    <input
                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-primary checked:ring-primary hover:cursor-pointer"
                        type="radio"
                        checked={modifyData.showProcessFlag === false}
                        onClick={() => handleWebOrAppCancelOnChange(false)}
                        />
                    <label className=' hover:cursor-pointer'>{t('general.no')}</label>
                </div>
            </div>
            {/*網掛預約期限 */}
            <div className='flex flex-row pl-11 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-2'>
                    <p className=' text-base'>{t("Regs.general.webAppointmentLimit")}</p>
                </div>
                <div className='w-[130px] h-[42px] flex justify-center'>
                    <input
                        className='w-[100px] h-10 border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='number'
                        value={modifyData.webapptDay}
                        onChange={handleWenapptDayOnChange}
                    />
                    {/*Input hide number arrow*/}
                    <style>
                        {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                    </style>
                </div>
                <div className='h-[42px] flex items-center'>
                    <p>{t("general.dateTime.day")}</p>
                </div>
            </div>
            {/* 診室地點 */}
            <div className='flex flex-row pl-11 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-2'>
                    <p className=' text-base'>{t("Regs.general.clinicLocation")}</p>
                </div>
                <div className='w-[582px] h-[42px] flex justify-center'>
                    <input
                        className='w-[550px] h-10 border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='text'
                        value={modifyData.clinicLocation}
                        onChange={(e)=>handleClinicLocationOnChange(e.target.value)}
                    />
                </div>
            </div>
            
            {/* 注意事項 */}
            <div className='flex flex-row pl-11 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-2'>
                    <p className=' text-base'>{t("Regs.general.notice")}</p>
                </div>
                <div className='w-[582px] h-[70px] flex justify-center'>
                    <input
                        className='w-[550px] h-[70px] border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='text'
                        value={modifyData.weekNotice}
                        onChange={(e)=>handleWeekNoticeOnChange(e.target.value)}
                        readOnly={false}
                    />
                </div>
            </div>
            {/* 診間標語 */}
            <div className='flex flex-row pl-11 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-2'>
                    <p className=' text-base'>{t("Regs.general.clinicSlogan")}</p>
                </div>
                <div className='w-[582px] h-[70px] flex justify-center'>
                    <input
                        className='w-[550px] h-[70px] border bg-white rounded-md border-[#D4D4D8] pl-[14px] py-2'
                        type='text'
                        value={modifyData.clinicSlogan}
                        onChange={(e)=>handleClinicSloganOnChange(e.target.value)}
                        readOnly={false}
                    />
                </div>
            </div>
            {/* 代班醫師 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'>{t("Regs.general.locumDoctor")}</p>
                </div>
                {/*代班醫師下拉選單*/}
                <div ref={dropdownLocumDoctorRef} className="flex flex-row justify-center w-[260px] h-[42px] items-center relative">
                    <input
                        className="bg-white pl-8 w-[235px] h-10 text-[#3F3F46] rounded-md border border-gray-300 disabled:bg-[#F4F4F5] bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat"
                        value={modifyData.locumDoctor}
                        disabled={modifyData.stopClinicFlag}
                        onChange={(e) => handleLocumDoctorOnChange(e)}
                        onFocus={handleInputLocumDoctorOnFocus}/>
                    {
                        showLocumDoctorDropDown && !arrayIsEmpty(locumDoctorOptionList) &&
                        (
                            <div className="bg-white z-10 absolute left-3 border border-[#d4d4d8] rounded-md top-10 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                    {
                                        locumDoctorOptionList.map((item, index) =>
                                            <div
                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                role="button" key={index}
                                                onClick={() => handleLocumDoctorOptionOnClick(item)}
                                                >
                                                <p>{`${item.userNo} ${item.userName}`}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {/* 停診 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4 gap-2'>
                    <input
                        className="form-checkbox text-green-500 h-5 w-5 rounded border-green-300 focus:ring-green-500"
                        type="checkbox"
                        checked={modifyData.stopClinicFlag}
                        style={{accentColor: "#38A169"}}
                        onChange={handleStopClinicFlagOnChange}
                        disabled={!stringIsEmpty(modifyData.locumDoctor)}
                    />
                    <p className='text-base'>{t("Regs.general.stopClinic")}</p>
                </div>
                {/* 停診原因 */}
                <div className="w-[260px] h-[42px] flex justify-center">
                    <select
                        className="w-[235px] h-10 border bg-white rounded-md border-gray-300 pl-[14px]"
                        value={modifyData.stopClinicReason}
                        disabled={!modifyData.stopClinicFlag}
                        onChange={handleStopClinicReasonOnChange}
                    >
                        <option value="" selected disabled hidden></option>
                    {
                        !arrayIsEmpty(baseData.stopClinicReasonList) && baseData.stopClinicReasonList.map((reason, index) => (
                            <option
                                key={index}
                                value={reason.justnameNo}>
                                {reason.justnameName}
                            </option>
                        ))
                    }
                    </select>
                </div>
            </div>
            {/* 當日停代診公告 */}
            <div className='flex flex-row pl-12 '>
                <div className='w-[160px] h-[42px] flex items-center justify-end pr-4'>
                    <p className=' text-base'></p>
                </div>
                <div className="w-[260px] h-[42px] flex justify-start items-center pl-3">
                    <input
                        className="form-checkbox text-green-500 h-5 w-5 rounded border-green-300 focus:ring-green-500"
                        type="checkbox"
                        style={{accentColor: "#38A169"}}
                        checked={modifyData.stopClinicBulletinFlag}
                        onChange={handleStopClinicBulletinFlagOnChange}
                    />
                    <span className="pl-3">{t("Regs.general.stopClinicBulletinForTheDay")}</span>
                </div>
            </div>
            
            <div className=' border-solid border-t-2 w-full'>
                <div className='w-full h-[42px] mb-2 flex flex-start justify-start items-center text-xl text-primary pl-4 pt-2'>
                    {t('Regs.ScheduleBatchedUpdate.selectedDatasIntendedChangedInformation', {selectedCount: arrayIsEmpty(selectableData) ? "0" : selectableData.length})}
                </div>
                {modifyData.limit !== null &&
                    <div className='flex flex-row justify-start items-center min-w-[422px]'>
                        <p className='min-w-[182px] h-[42px] justify-end items-center'>{t("Regs.general.maximumNumberOfAppointments")}</p>
                        <p className='min-w-[260px] h-[42px] text-start'>{modifyData.limit}</p>
                    </div>
                }
                {modifyData.onsiteLimit != null &&
                    <div className='flex flex-row justify-start items-center min-w-[422px]'>
                        <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.maximumOnsitePatients")}</p>
                        <p className='min-w-[260px] h-[42px] text-start'>{modifyData.onsiteLimit}</p>
                    </div>
                }
                {modifyData.slotMin != null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.slotTime")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>{modifyData.slotMin}</p>
                </div>
                }
                {modifyData.giveNumType !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.reservationMethod")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.giveNumType === RegsWeekReservationMethodEnum.Time.toString()?t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`):t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`)}
                    </p>
                </div>
                }
                {modifyData.reserveLimitType !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.reservationRestriction")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {getReserveLimitTypeString(modifyData.reserveLimitType)}
                    </p>
                </div>
                }
                {modifyData.cashType !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.cashType")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {getCashTypeString(modifyData.cashType)}
                    </p>
                </div>
                }
                {modifyData.webapptFlag !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>開放網掛/APP</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.webapptFlag ? t('general.yes') : t('general.no')}
                    </p>
                </div>
                }
                {modifyData.showProcessFlag !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.webAppointmentCancelProcess")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.showProcessFlag ? t('general.yes') : t('general.no')}
                    </p>
                </div>
                }
                {modifyData.webapptDay !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.webAppointmentLimit")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.webapptDay} {t("general.dateTime.day")}
                    </p>
                </div>
                }
                {modifyData.clinicLocation !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.clinicLocation")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.clinicLocation}
                    </p>
                </div>
                }
                {modifyData.weekNotice !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.notice")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.weekNotice}
                    </p>
                </div>
                }
                {modifyData.clinicSlogan !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.clinicSlogan")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.clinicSlogan}
                    </p>
                </div>
                }
                {modifyData.locumDoctor !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.locumDoctor")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.locumDoctor}
                    </p>
                </div>
                }
                {modifyData.stopClinicFlag !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.stopClinic")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.stopClinicFlag ? t('general.yes') : t('general.no')}
                    </p>
                </div>
                }
                {modifyData.stopClinicReason !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.stopClinicReason")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.stopClinicFlag?getStopClinicReasonName(modifyData.stopClinicReason):''}
                    </p>
                </div>
                }
                {modifyData.stopClinicBulletinFlag !== null &&
                <div className='flex flex-row justify-start items-center min-w-[422px]'>
                    <p className='min-w-[182px] h-[42px] justify-end'>{t("Regs.general.stopClinicBulletinForTheDay")}</p>
                    <p className='min-w-[260px] h-[42px] text-start'>
                        {modifyData.stopClinicBulletinFlag ? t('general.yes') : t('general.no')}
                    </p>
                </div>
                }
                <div className='w-full flex justify-end pr-[32px] pb-2'>
                    <DefaultButton 
                        type="primary" 
                        text={t('general.saveFile')} 
                        disable={arrayIsEmpty(selectableData)}
                        onClickFn={handleSaveBacthUpdateOnClick} />
                </div>
            </div>
        </div>
        }
    </div>
  )
}

export default ScheduleBatchesUpdateForm