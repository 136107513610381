import React from 'react';
import BasicInfo from "../HomeHealthCare/SubSideBar/BasicInfo";
import BarthelIndex from "../HomeHealthCare/Form/BarthelIndex";
import IADL from "../HomeHealthCare/SubSideBar/IADL";
import CaseReceiptList from "../HomeHealthCare/CaseReceiptList";
import {t} from "i18next";
import BasicAssessment from "../HomeHealthCare/Form/BasicAssessment";
import MiniNutritionalAssessment from "../HomeHealthCare/SubSideBar/MiniNutritionalAssessment";
import SkillsAssessment from "../HomeHealthCare/SubSideBar/SkillsAssessment";
import VisitRecord from "../HomeHealthCare/SubSideBar/VisitRecord";
import WoundRecord from "../HomeHealthCare/SubSideBar/WoundRecord";
import CareGiver from "../HomeHealthCare/SubSideBar/CareGiver";
import BSRS5 from "../HomeHealthCare/SubSideBar/BSRS5";

// 創建好相對應的component後即可將其替換掉
const PlaceholderComponent = ({ name }) => <div>{name}</div>;
/**
 * 側邊欄組件
 * @param {Object} props - 組件屬性
 * @param {function} props.onMenuClick - 菜單點擊處理函數
 * @return {JSX.Element}
 */
const SubSidebar = ({ onMenuClick }) => {
    const menuItems = [
        { path: '/basic-info', name: '基本評估', component: BasicAssessment },
        { path: '/barthel-index', name: '巴柯氏量表', component: BarthelIndex },
        { path: '/iadl', name: 'IADL', component: IADL },
        { path: '/visit-record', name: '訪視紀錄', component: VisitRecord },
        { path: '/skills-assessment', name: '技能評估', component: SkillsAssessment },
        { path: '/primary-caregiver-assessment', name: '主要照顧者評估', component: CareGiver },
        { path: '/social-environment-psychological', name: '社會/環境/心理', component: () => <PlaceholderComponent name="社會/環境/心理" /> },
        { path: '/mini-nutrition-assessment', name: '迷你營養評估', component: () => <MiniNutritionalAssessment /> },
        { path: '/supplementary-b-table', name: '補述B表紀錄', component: () => <PlaceholderComponent name="補述B表紀錄" /> },
        { path: '/nursing-plan', name: '護理計劃單', component: () => <PlaceholderComponent name="護理計劃單" /> },
        { path: '/wound-record', name: '傷口紀錄單', component: () => <WoundRecord /> },
        { path: '/bsrs-5-assessment', name: 'BSRS-5評估單', component: () => <BSRS5 /> },
        { path: '/test-query', name: '檢驗查詢', component: () => <PlaceholderComponent name="檢驗查詢" /> },
        { path: '/home-care-medical-record-s2', name: '居家護理病歷表首頁(S2)', component: () => <PlaceholderComponent name="居家護理病歷表首頁(S2)" /> },
        { path: '/hospice-home-medical-record-s3', name: '安寧居家病歷表首頁(S3)', component: () => <PlaceholderComponent name="安寧居家病歷表首頁(S3)" /> },
        { path: '/hospice-patient-staging-scales-s3', name: '安寧居家 Patient Staging Scales(S3)', component: () => <PlaceholderComponent name="安寧居家 Patient Staging Scales(S3)" /> },
        { path: '/hospice-family-tree-s3', name: '安寧居家療護病人「家系圖」(S3)', component: () => <PlaceholderComponent name="安寧居家療護病人「家系圖」(S3)" /> },
        { path: '/hospice-physical-assessment-s3', name: '安寧居家療護病人生理問題評估、計畫及護理記錄(S3)', component: () => <PlaceholderComponent name="安寧居家療護病人生理問題評估、計畫及護理記錄(S3)" /> },
        { path: '/hospice-initial-pain-assessment-s3', name: '安寧居家療護初步疼痛評估表(S3)', component: () => <PlaceholderComponent name="安寧居家療護初步疼痛評估表(S3)" /> },
        { path: '/hospice-continuous-pain-assessment-s3', name: '安寧居家療護持續疼痛評估表(S3)', component: () => <PlaceholderComponent name="安寧居家療護持續疼痛評估表(S3)" /> },
        { path: '/hospice-service-content-s3', name: '安寧居家療護服務內容(S3)', component: () => <PlaceholderComponent name="安寧居家療護服務內容(S3)" /> },
        { path: '/hospice-psychosocial-needs-s3', name: '安寧居家療護心理社會需要評估及照顧紀錄(S3)', component: () => <PlaceholderComponent name="安寧居家療護心理社會需要評估及照顧紀錄(S3)" /> },
        { path: '/hospice-spiritual-needs-s3', name: '安寧居家療護靈性需要評估及輔導計畫(S3)', component: () => <PlaceholderComponent name="安寧居家療護靈性需要評估及輔導計畫(S3)" /> },
        { path: '/hospice-end-of-life-preparation-s3', name: '安寧居家療護善終準備記錄單(S3)', component: () => <PlaceholderComponent name="安寧居家療護善終準備記錄單(S3)" /> },
        { path: '/hospice-initial-grief-assessment-s3', name: '安寧居家療護初步傷慟評估及哀傷輔導記錄(S3)', component: () => <PlaceholderComponent name="安寧居家療護初步傷慟評估及哀傷輔導記錄(S3)" /> },
    ];

    return (
        <div className="w-[300px] mr-4">
            <div className="info w-full bg-white border-[1px] border-[#D4D4D8] rounded-[6px] py-[10px] px-4 mb-4">
                <h3 className="flex items-center justify-start text-[20px] mr-4 mb-2.5 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                    <span>基本資料</span>
                </h3>
                <ul>
                    <li className="flex-row flex items-center justify-start mb-4">
                        <div className="flex flex-row items-center justify-center">
                            <p className="w-[70px] text-right mr-2">家字號:</p>
                            <span className="text-[#6A46C0]">01987</span>
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <p className="w-[70px] text-right mr-2">病歷號:</p>
                            <span className="text-[#6A46C0]">021234</span>
                        </div>
                    </li>
                    <li className="flex-row flex items-center justify-start mb-4">
                        <div className="flex flex-row items-center justify-center">
                            <p className="w-[70px] text-right mr-2">個案姓名:</p>
                            <span className="text-[#6A46C0]">蕭ＯＯ</span>
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <p className="w-[70px] text-right mr-2">性別:</p>
                            <span className="text-[#6A46C0]">男</span>
                        </div>
                    </li>
                    <li className="flex-row flex items-center justify-start mb-4">
                        <div className="flex flex-row items-center justify-center">
                            <p className=" text-right mr-2">出生日期:</p>
                            <span className="text-[#6A46C0]">1971-08-30(54y10m3dd)</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sidebar w-[300px] bg-white border-[1px] border-[#D4D4D8] rounded-[6px] py-[10px] px-4 h-[calc(100vh-320px)] overflow-y-scroll">
                <h3 className="flex items-center justify-start text-[20px] mr-4 mb-2.5 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                    <span>功能表</span>
                </h3>
                <ul>
                    {menuItems.map((item, index) => (
                        <li className="flex items-center justify-start text-left mb-2 h-[40px]" key={index} onClick={() => onMenuClick(item)}>
                            {item.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SubSidebar;
