import { createSlice } from '@reduxjs/toolkit'

export const print = createSlice({
    name: 'print',
    initialState: {
        reportType: '',
        printData: null,
        isPrintMode: false,
    },
    reducers: {
        updatePrintContent(state, action) {
            const { reportType, printData } = action.payload
            state.reportType = reportType
            state.printData = printData
            state.isPrintMode = true
        },
        completePrintMode(state, action) {
            state.isPrintMode = false
        }
    },
})
export const { updatePrintContent, completePrintMode } = print.actions
export default print.reducer
