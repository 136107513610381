import './appointmentPopup.scss'
import React, {useEffect, useState, useRef} from "react"
import {ApiErrorStatusEnum, objectIsEmpty, time2String, stringIsEmpty, arrayIsEmpty, InputTypeEnum, RegsWeekReservationLimitEnum, RegsMedicalTypeEnum } from "edah_utils/dist"
import {regsQueryBasicDataClinicRes, regsQueryClinicOfTheDay} from "../../../../api/v1/Regs"
import searchIcon from "../../../../assets/images/search-interface-symbol.png"
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { BaseInput } from '../../../Input/BaseInput'
import {t} from "i18next"
import DailyScheduleItem from './DailyScheduleItem'

/**
 * 診所班表
 * @param show {Boolean} 顯示
 * @param data {Object} 班表資料
 * @param closeClinic {Function} 關閉診所班表
 * @param onSelectClinic {Function} 診間選事件
 * @param fowardRegFlag {Boolean} 是否為補輸
 */
const ClinicSchedule = ({
                            show = false,
                            data,
                            closeClinic,
                            onSelectClinic,
                            fowardRegFlag
                        }) => {

    //查詢條件
    const [queryInput, setQueryInput] = useState({
        divNo: "",
        //醫生代碼
        doctorNo: "",
        //日期
        encounterDate: "",
        //時段
        apn: "",
        //院區
        zone: "",
    })
    // 分院清單
    const [zonesetList, setZonesetList] = useState((data && data.zonesetList) ? data.zonesetList : [])
    // 診所清單
    const [clinicList, setClinicList] = useState([])
    // 科別清單
    const [divList, setDivList] = useState([])
    // 醫生清單
    const [doctorList, setDoctorList] = useState([])
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 當日班表
    const [queryData, setQueryData] = useState([])
    // 當日班表的日期
    const [queryResultDate, setQueryResultDate] = useState(null)
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)
    // 是否顯示科別選項
    const [showDivOptions, setShowDivOptions] = useState(false)
    // 是否顯示醫師選項
    const [showDoctorOptions, setShowDoctorOptions] = useState(false)
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorOptions(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDivOptions(false),
    });

    /**
     * 點擊前一天
     * @return {void}
     */
    const handlePreviousDayOnClick = () => {
        const date = time2String(new Date(queryInput.encounterDate).setDate(new Date(queryInput.encounterDate).getDate() - 1), 'YYYY-MM-DD')
        
        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone
        //不需要查詢apn
        //    apn: queryInput.apn,
        }

        setQueryInput({
            ...queryInput,
            encounterDate: date
        })

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput)
    }

    /**
     * 點擊後一天
     * @return {void}
     */
    const handleNextDayOnClick = () => {
        const date = time2String(new Date(queryInput.encounterDate).setDate(new Date(queryInput.encounterDate).getDate() + 1), 'YYYY-MM-DD')
        
        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone
        //不需要查詢apn
        //    apn: queryInput.apn,
        }

        setQueryInput({
            ...queryInput,
            encounterDate: date
        })

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput)
    }

    const getBaseDoctorAndDivision = (zone, date) => {
        //取得指定日期的醫生及科別資料
        regsQueryClinicOfTheDay({
            encounterDate: time2String(date, 'YYYY-MM-DD 00:00:00'),
            zone: zone
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) { 
                setDoctorList(res.data.doctorList)
                setDivList(res.data.divList)
                setClinicList(res.data.clinicList)
            }
        })
    }

    /**
     * 查詢當日班表
     * @param {Object} searchInput 查詢條件
     */
    const queryQueryClinicOfTheDay = (searchInput) => {
        regsQueryClinicOfTheDay({
            encounterDate: time2String(searchInput.encounterDate, 'YYYY-MM-DD 00:00:00'),
            doctorNo: searchInput.doctorNo ? searchInput.doctorNo.split(' ')[0] : null,
            divNo: searchInput.divNo ? searchInput.divNo.split(' ')[0] : null,
            apn: !stringIsEmpty(searchInput.apn) ? searchInput.apn : "",
            zone: !stringIsEmpty(searchInput.zone) ? searchInput.zone : ""
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) { 
                setQueryData(res.data)
                setQueryResultDate(searchInput.encounterDate)
            }
        })
    }

    /**
     * 院區改變時
     * @param e {Event}
     * @return {void}
     */
    const handleZoneSelectOnChange = (e) => {
        const zone = e.target.value
        setQueryInput({
            ...queryInput,
            zone: zone,
            //醫師科別重置
            doctorNo: "",
            divNo: "",
        })

        //取得醫師 科別資料
        getBaseDoctorAndDivision(zone, queryInput.encounterDate)
    }

    /**
     * 日期變更事件
     * @param {Event} e 
     */
    const handleDateOnChange = (e) => {
        if(stringIsEmpty(e.target.value)) 
        {
            return
        }
        const year = new Date(e.target.value).getFullYear();

        if(year > 9999)
        {
            return
        }
        const date = time2String(e.target.value, 'YYYY-MM-DD')
        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone
        //不需要查詢apn
        //    apn: queryInput.apn,
        }

        setQueryInput({
            ...queryInput,
            encounterDate: date,
            //醫師科別重置
            //doctorNo: "",
            //divNo: "",
        })

        //取得醫師 科別資料
        getBaseDoctorAndDivision(queryInput.zone, date)

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput)
    }

    /**
     * 診間點選事件
     */
    const handleClinicOnClick = (disabled, clinicData) => {
        if(disabled)
            return
        
        onSelectClinic(queryResultDate, clinicData)
        //關閉popup
        closeClinic()
    }

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const divText = queryInput.divNo.trim()
        const doctorText = queryInput.doctorNo.trim()
        //切割字串
        const splitArray = divText ? divText.split(" ") : []
        //已經有選擇科別
        if (splitArray.length === 2) {
            return divList.filter(div => div.divNo.includes(splitArray[0]) && div.divName.includes(splitArray[1]))
        } else { //透過輸入文字
            // 醫生選項為空時
            if (stringIsEmpty(doctorText)) {
                // 科別選項為空時
                if (stringIsEmpty(divText)) {
                    return divList
                } else { // 科別選項不為空時
                    return divList.filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                }
            } else { // 醫生選項不為空時
                //取得醫生切割字串
                const splitDoctorArray = doctorText.split(" ")
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    if(splitArray.length === 0) {
                        //科別為空
                        //求聯集
                        return divList
                            .filter(div => clinicList.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    else if(splitArray.length === 1) {
                        //科別有1段
                        return divList
                            .filter(div => clinicList.some(clinic => clinic.divNo === div.divNo && clinic.doctorNo === splitDoctorArray[0]))
                            .filter(div => div.divNo.includes(divText) || div.divName.includes(divText))
                            .map(div => new Object({
                                //科別代碼
                                divNo: div.divNo,
                                //科別名稱
                                divName: div.divName,
                            }))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    }

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const divText = queryInput.divNo.trim()
        const doctorText = queryInput.doctorNo.trim()
        //切割字串
        const splitArray = doctorText ? doctorText.split(" ") : []
        //已經有選擇醫師
        if (splitArray.length === 2) {
            return doctorList.filter(doctor => doctor.doctorNo.includes(splitArray[0]) && doctor.doctorName.includes(splitArray[1]))
        } else { //透過輸入文字
            // 科別選項為空時
            if (objectIsEmpty(divText)) {
                // 醫師選項為空時
                if (stringIsEmpty(doctorText)) {
                    return doctorList
                } else { // 醫師選項不為空時
                    return doctorList.filter(doctor => doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText))
                }
            } else { // 科別選項不為空時
                //取得科別切割字串
                const splitDepartmentArray = divText.split(" ")
                //已經有選擇科別
                if (splitDepartmentArray.length === 2) {
                    if(splitArray.length === 0) {
                        //醫生為空
                        //求聯集
                        return doctorList
                            .filter(doctor => clinicList.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .map(doctor => new Object({
                                //科別代碼
                                doctorNo: doctor.doctorNo,
                                //科別名稱
                                doctorName: doctor.doctorName,
                            }))
                    }
                    else if(splitArray.length === 1) {
                        //醫生有1段
                        return doctorList
                            .filter(doctor => clinicList.some(clinic => clinic.doctorNo === doctor.doctorNo && clinic.divNo === splitDepartmentArray[0]))
                            .filter(doctor => doctor.doctorNo.includes(doctorText) || doctor.doctorName.includes(doctorText))
                            .map(doctor => new Object({
                                //科別代碼
                                doctorNo: doctor.doctorNo,
                                //科別名稱
                                doctorName: doctor.doctorName,
                            }))
                    }
                    else {
                        return []
                    }
                } else { //透過輸入文字
                    return []//return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    }

    /**
     * 處理科別搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value

        // 設定科別選項
        setQueryInput({
            ...queryInput,
            divNo: value,
        })
    }

    /**
     * 處理科別搜尋文字框焦點事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDivOptions(true)
    }

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo
            // 科別名稱
            const name = option.divName
            // 設定科別選項
            setQueryInput({
                ...queryInput,
                divNo: `${no} ${name}`,
            })
        }

        setShowDivOptions(false)
    }

    /**
     * 處理醫師搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (e) => {
        //取得值
        const value = e.target.value
        // 設定醫師選項
        setQueryInput({
            ...queryInput, 
            doctorNo: value
        })
    }

    /**
     * 處理醫師搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorSearchTextOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorOptions(true)
    }

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.doctorNo
            // 醫師名稱
            const name = option.doctorName
            // 設定醫師選項
            setQueryInput({
                ...queryInput,
                doctorNo: `${no} ${name}`,
            })
        }

        setShowDoctorOptions(false)
    }

    /**
     * 點擊查詢按鈕事件
     */
    const hadnleSearchOnClick = () => {
        const newQueryInput = {
            encounterDate: queryInput.encounterDate,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone
        //不需要查詢apn
        //    apn: queryInput.apn,
        }

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput)
    }

    /**
     * 渲染時段日班表
     * @param {Number} apn 時段 (1:早 2:午 3:晚)
     * @param {Array} clinicArray 日班表array
     * @returns 
     */
    const renderClinicOfDayListByApn = (apn, clinicArray) => {
        //找出時段(apn)的班表
        let filteredClinics = arrayIsEmpty(clinicArray) ? null : clinicArray.filter(clinic => clinic.apn === Number(apn))

        /**
         * 是否為可以掛號的時段
         */
        const isRegistrationPeriod = (clinic) => {
            const currentDate = new Date()
            const targetDate = new Date(clinic.encounterDate)
            const tmpCurrentDate = new Date(currentDate)
            
            if(fowardRegFlag) {   
                //補輸情況下
                return true
            }

            tmpCurrentDate.setHours(0, 0, 0, 0)
            
            if(targetDate < tmpCurrentDate) {
                return false
            }
            if(clinic.stopClinicFlag) {
                //停診
                return false
            }
            
            if(Number(time2String(targetDate, "YYYYMMDD")) === Number(time2String(tmpCurrentDate, "YYYYMMDD"))) {
                //同一天
                if(currentDate.getHours() >= 21) {
                    //晚診最後時間後
                    return false
                }

                if(clinic.apn === 1) {
                    //早診時段
                    if(currentDate.getHours() >= 12) {
                        //超過中午12點
                        
                        return false
                    }
                }
                else if(clinic.apn === 2) {
                    //午診時段
                    if(currentDate.getHours() >= 17) {
                        //超過中午12點
                        return false
                    }
                }
            }

            if(clinic.regFullFlag) {
                //額滿
                return false
            }

            return true
        }

        return (
            <ul className="scheduleList text-sm">
                {/*控制class去區分類別 額滿給予disable的class*/}
                
                {
                    arrayIsEmpty(filteredClinics) ? (
                        /*如果沒有資料則顯示此區塊*/
                        <div className="nodata flex items-center justify-center text-xl font-normal h-[400px] overflow-y-auto">
                            沒有醫生看診 <br/>
                            請選其他時間
                        </div>
                    ) : (
                        filteredClinics.map((clinic, index) => {
                            let disabled = false
                            disabled = !isRegistrationPeriod(clinic)
                            return (
                                <DailyScheduleItem 
                                    key={index} 
                                    record={clinic} 
                                    disabled={disabled} 
                                    onClickItem={handleClinicOnClick}/>
                            
                            )
                        })
                    )
                }
            </ul>
        )
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        if(show) {
            const firstDoctorNo = (objectIsEmpty(data) || objectIsEmpty(data.doctorNo)) ? "" : data.doctorNo
            const firstDivNo = (objectIsEmpty(data) || objectIsEmpty(data.divNo)) ? "" : data.divNo
            const firstDate = time2String((data != null && data.date) ? data.date : new Date(), 'YYYY-MM-DD')
            const firstZone = (objectIsEmpty(data) || objectIsEmpty(data.zone)) ? "" : data.zone
            const firstApn = (objectIsEmpty(data) || objectIsEmpty(data.apn)) ? "" : data.apn

            setZonesetList((data && data.zonesetList) ? data.zonesetList : [])

            setQueryInput({
                divNo: firstDivNo,
                //醫生代碼
                doctorNo: firstDoctorNo,
                //日期
                encounterDate: firstDate,
                //時段
                apn: firstApn,
                //院區
                zone: firstZone,
            })

            //取得醫師科別基礎資料
            getBaseDoctorAndDivision(firstZone, firstDate)

            //查詢日班表資料
            const newQueryInput = {
                encounterDate: firstDate,
                doctorNo: firstDoctorNo,
                divNo: firstDivNo,
                zone: firstZone
            //不需要查詢apn
            //    apn: queryInput.apn,
            }
            
            queryQueryClinicOfTheDay(newQueryInput)
        }

        setShowPopup(show)
    }, [show])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [queryInput.divNo])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [queryInput.doctorNo])

    return (
        showPopup?
        <div
            className="fixed top-[-16px] left-0 pt-[60px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-10">
            <div className="w-[1016px] bg-white rounded-[6px] overflow-auto max-h-[calc(100vh-90px)] max-w-[calc(100vw-50px)]">
                {/*標題*/}
                <div
                    className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(#111111,0.15)]">
                    <h3 className="text-[20px]">
                        當日班表_選擇掛號
                    </h3>
                    <button onClick={closeClinic}
                            className="flex items-center justify-center h-10 text-[18px] text-[#2B6CB0]">
                        關閉
                    </button>
                </div>
                {/*filter篩選*/}
                <div className="filter flex flex-row items-center justify-start px-6 mb-[22px]">
                    <select className="w-[166px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={queryInput.zone}
                            onChange={(e) => handleZoneSelectOnChange(e)}>
                        {
                            zonesetList.map((zone, index) =>
                                <option key={index} value={zone.zone}>{zone.zoneName}</option>
                            )
                        }
                    </select>

                    {/*科別*/}
                    <div className="flex flex-row items-center justify-start mr-4 space-x-2">
                        <label>{t('general.department')}</label>
                        <div ref={dropdownDepartmentRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3 ">
                            <input
                                className="w-[166px] h-10 pl-9 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                value={queryInput.divNo}
                                onChange={(e) => handleDepartmentSearchTextOnChange(e)}
                                onFocus={handleDepartmentSearchTextOnFocus}/>
                            <img
                                className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                alt="search-icon"
                                src={searchIcon}
                                role="button"/>
                            {
                                showDivOptions && !arrayIsEmpty(departmentOptionList) && (
                                <div className="bg-white z-10 absolute -left-2 border border-[#d4d4d8] bg-white rounded-md top-10 w-[220px] flex flex-col">
                                    {/*科別下拉*/}
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            departmentOptionList.map((div, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDepartmentOptionOnClick(div)}>

                                                    <p>{`${div.divNo}`}</p>
                                                    <p>{`${div.divName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                )
                            }
                        </div>
                    </div>
                    {/*醫師*/}
                    <div className="flex flex-row items-center justify-start mr-4 space-x-2">
                        <label>{t('general.doctor')}</label>
                        <div ref={dropdownDoctorRef} className="flex flex-row w-[160px] space-x-2 items-center relative w-2/3">
                            <input
                                className="w-[166px] h-10 pl-9 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                value={queryInput.doctorNo}
                                onChange={(e) => handleDoctorSearchTextOnChange(e)}
                                onFocus={handleDoctorSearchTextOnFocus}
                            />
                            <img
                                className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1"
                                alt="search-icon"
                                src={searchIcon}
                                role="button"/>
                            {
                                showDoctorOptions && !arrayIsEmpty(doctorOptionList) &&
                                <div className="bg-white z-10 absolute -left-2 border border-[#d4d4d8] bg-white rounded-md top-10 w-[220px] flex flex-col">
                                    {/*醫師下拉*/}
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            doctorOptionList.map((doctor, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleDoctorOptionOnClick(doctor)}>
                                                    <p className="">{`${doctor.doctorNo}`}</p>
                                                    <p>{`${doctor.doctorName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <button
                        className="flex items-center justify-center px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white font-base"
                        onClick={hadnleSearchOnClick}>
                        {t('general.query')}
                    </button>
                </div>
                {/*時間表*/}
                <div className="schedule">
                    <div className="dateTitle relative flex flex-row items-center justify-center mb-4">
                        <button className="h-10 px-4 mr-2 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[4px] font-base"
                                onClick={handlePreviousDayOnClick}>
                            前一天
                        </button>
                        <BaseInput
                            className="w-[168px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-2"
                            type={InputTypeEnum.Date}
                            value={queryInput.encounterDate}
                            inputMode="date"
                            onChange={(e) => handleDateOnChange(e)}/>
                        <button className="h-10 px-4 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[4px] mr-4 font-base"
                                onClick={handleNextDayOnClick}>
                            後一天
                        </button>
                        {/*<button*/}
                        {/*    className="h-10 px-4 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[6px] absolute right-[24px]">查看週班表*/}
                        {/*</button>*/}
                    </div>
                    <div className="scheduleSection w-[900px] mx-auto ">
                        <div className='flex flex-row items-start justify-center'>
                            <div className="morning w-1/3 bg-[#EBF8FF] p-2">
                                <h3 className="mb-3 text-xl font-normal">早8:30~12:00</h3>
                                <div className='h-[510px]'>
                                {
                                    renderClinicOfDayListByApn(1, objectIsEmpty(queryData) ? null : queryData.clinicList)
                                }
                                </div>
                            </div>
                            <div className="afternoon w-1/3 bg-[#FEFCE8] p-2">
                                <h3 className="mb-3 text-xl font-normal">午13:30~17:00</h3>
                                <div className='h-[510px]'>
                                {
                                    renderClinicOfDayListByApn(2, objectIsEmpty(queryData) ? null : queryData.clinicList)
                                }
                                </div>
                            </div>
                            <div className="night w-1/3 bg-[#FAF5FF] p-2">
                                <div className='h-[510px]'>
                                <h3 className="mb-3 text-xl font-normal">晚18:00~21:00</h3>
                                {
                                    renderClinicOfDayListByApn(3, objectIsEmpty(queryData) ? null : queryData.clinicList)
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> :
        <></>
    )
}

export default ClinicSchedule
