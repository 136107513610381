import React from 'react'
import RSISCaseManagement from '../../components/RSIS/CaseManagement/RSISCaseManagement'
import { PopupProvider } from '../../components/RSIS/PopupProvider'
import EntityRelationshipQuery from '../../components/RSIS/EntityRelationship/EntityRelationshipQuery'

/**
 * 個案管理
 * @returns {JSX.Element}
 */
function CaseManagement() {
  return (
    <PopupProvider>
      <RSISCaseManagement />
    </PopupProvider>
  )
}

export default CaseManagement