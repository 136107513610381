import React, { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/del_square.svg'
import {t} from "i18next";
import {
    addTranForeign,
    deleteTranForeign,
    editTranForeign,
    queryTranForeignByApplicantNo
} from "../../../api/v1/Rmis";
import {ApiErrorStatusEnum, InputTypeEnum, time2String, ToastTypeEnum} from "edah_utils/dist";
import useToast from "../../../hooks/useToast";
import {BaseInput} from "../../Input/BaseInput";
import {BasePopup} from "../../Popup/BasePopup";

/**
 * 病症暨失能診斷證明書_維護
 * @param {object} Props
 * @param {function} Props.onConfirm
 * @param {object|null} Props.caseInfo
 * @param {boolean} Props.isEdit 編輯or新增
 * @returns {JSX.Element}
 */
const CertificateEditorModal = ({ onClose, isEdit = false, applicantNo }) => {
    const [caseDetail, setCaseDetail] = useState({
        patientId: '',
        patientName: '',
        birthDate: '',
        idNo: '',
        doctorName: '',
        cancelFlag: '',
        barthelFlag: '',
        barthelScore: '',
        cdrFlag: '',
        hospNoCare: '',
        hospTel: '',
        applicantsUserName: '',
        applicantAdress: '',
        recipientDate: '',
        returnTime: '',
        returnReason: '',
        returnReason2: '',
        returnReason3: '',
        returnReason4: '',
        returnReasonName: '',
        remark: '',
    })

    const [isSaved, setIsSaved] = useState(false)


    const [inputState, setInputState] = useState({
        patientId: null,
        patientName: null,
        birthDate: null,
        idNo: null,
        doctorName: null,
        cancelFlag: null,
        barthelFlag: null,
        barthelScore: null,
        cdrFlag: null,
        hospNoCare: null,
        hospTel: null,
        applicantsUserName: null,
        applicantAdress: null,
        recipientDate: null,
        returnTime: null,
        returnReason: null,
        returnReason2: null,
        returnReason3: null,
        returnReason4: null,
        returnReasonName: null,
        remark: null,
    })
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 儲存成功 or 失敗 Toast
    const showToast = useToast()

    const updateCaseDetail = (e, field) => {
        const value = e.target.value
        setCaseDetail({
            ...caseDetail,
            [field]: value,
        })
    }


    const handleSaveData =  async () => {
        const validateKey = ['applicantDate', 'patientId', 'diagDate', 'recipientDate', 'sendDate']
        setIsSaved(true)
        if(validateKey.every((item)=> validValue(item, true))){
            const row = {
                ...caseDetail,
                diagDate: caseDetail.diagDate? `${caseDetail.diagDate} 00:00:00`: '',
                recipientDate: caseDetail.recipientDate ? `${caseDetail.recipientDate} 00:00:00`: '',
                sendDate: caseDetail.sendDate ? `${caseDetail.sendDate} 00:00:00`: '',
                applicantDate: caseDetail.applicantDate ? `${caseDetail.applicantDate} 00:00:00`: '',
            }
            const targetApi = isEdit ? editTranForeign : addTranForeign
            const res = await targetApi(row)
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
                onClose && onClose()
            }
            else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message : msg, type:ToastTypeEnum.Error})
            }
        }
    }

    const validValue = (key) => {
      return  isSaved ? !!caseDetail[key]  : null
    }
    // 刪除單筆資料
    const handleConfirmDelete= () =>{
        const applicantNoString = JSON.stringify([{applicantNo: applicantNo, lockVersion: caseDetail.lockVersion}])
        deleteTranForeign({ tableName: "tw.com.hismax.his.entity.rmis.RmisTranForeign", tableData: applicantNoString }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '刪除成功', type: ToastTypeEnum.Success })
                onClose && onClose()
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }

            setShowDeletePopup(false)
        })
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = (tableRow)=>{
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    useEffect(() => {
        if (isEdit) {
            queryTranForeignByApplicantNo({applicantNo}).then(
                (res) => {
                    setCaseDetail((prevCaseDetail) => ({
                        ...prevCaseDetail,
                        ...res.data,
                    }))
                }
            )
        }
    }, [isEdit])

    return (

        <div className="w-full overflow-auto max-h-[calc(100vh-140px)] p-2">
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button onClick={handleConfirmDelete}
                                        className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >是</button>
                                <button onClick={handleDeletePopupClose}
                                        className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >否</button>
                            </div>
                        </div>
                    }
                />
            )}
            <div className="flex justify-end items-center">
                <button
                    className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] mr-2 text-[#007CDB]"
                onClick={handleDeletePopupOpen}>
                    <DeleteIcon className="mr-2" />
                    刪除此筆資料
                </button>
            </div>
            <div className="p-3">
                <div className="w-full grid grid-cols-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            申請日期
                        </div>
                        {
                            isEdit ? <div className="w-[160px] mr-4 text-right">{time2String(caseDetail.applicantDate, 'YYYY-MM-DD')}</div> : (
                                <BaseInput
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Date}
                                    value={caseDetail.applicantDate}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'applicantDate')
                                    }
                                    inputState={validValue("applicantDate")}
                                    errorMsg="必填"
                                />
                            )
                        }

                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            {t('general.patientNo')}
                        </div>
                        {
                            isEdit ? <div className="w-[160px] mr-4 text-right">{caseDetail.patientId}</div> : (
                                <BaseInput
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type={InputTypeEnum.Text}
                                    value={caseDetail.patientId}
                                    onChange={(e) => updateCaseDetail(e, 'patientId')}
                                    inputState={validValue("patientId")}
                                    errorMsg="必填"
                                />
                            )
                        }

                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">{t('general.username')}</div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.patientName}
                            onChange={(e) => updateCaseDetail(e, 'patientName')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            {t('general.idNumber')}
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.idNo}
                            onChange={(e) => updateCaseDetail(e, 'idNo')}
                        />
                    </div>
                </div>
                <div className="w-full grid grid-cols-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            就醫科別
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.divName}
                            onChange={(e) => updateCaseDetail(e, 'divName')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">{t('general.doctor')}</div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.doctorName}
                            onChange={(e) => updateCaseDetail(e, 'doctorName')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">{t('general.birthday')}</div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            value= {caseDetail.birthDate
                                    ? time2String(
                                        caseDetail.birthDate,
                                        'YYYY-MM-DD'
                                    )
                                    : ''}
                            onChange={(e) => updateCaseDetail(e, 'birthDate')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <input
                            type="checkbox"
                            style={{ accentColor: '#38A169' }}
                            id="cancelFlag"
                            name="cancelFlag"
                            className="mr-2 bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            value={caseDetail.cancelFlag}
                            onChange={(e) => updateCaseDetail(e, 'cancelFlag')}
                        />
                        <label
                            className="w-[160px] mr-4 text-left"
                            htmlFor="cancelFlag"
                        >
                            註銷
                        </label>
                    </div>
                </div>

                <div className="w-full border-b-2 my-4"></div>

                <div className="w-full grid grid-cols-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            證書日期
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            value={caseDetail.diagDate}
                            onChange={(e) => updateCaseDetail(e, 'diagDate')}
                            inputState={validValue("diagDate")}
                            errorMsg="必填"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            收件日期
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            value={time2String(caseDetail.recipientDate, "YYYY-MM-DD" )}
                            onChange={(e) =>
                                updateCaseDetail(e, 'recipientDate')
                            }
                            inputState={validValue("recipientDate")}
                            errorMsg="必填"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            寄件日期
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            value={time2String(caseDetail.sendDate, "YYYY-MM-DD" )}
                            onChange={(e) => updateCaseDetail(e, 'sendDate')}
                            inputState={validValue("sendDate")}
                            errorMsg="必填"

                        />
                    </div>
                </div>
                <div className="w-full grid grid-cols-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            醫事課人員
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.mpUser}
                            onChange={(e) => updateCaseDetail(e, 'mpUser')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            轉診櫃台人員
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.tranUser}
                            onChange={(e) => updateCaseDetail(e, 'tranUser')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            巴氏量表分數
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.barthelScore}
                            onChange={(e) =>
                                updateCaseDetail(e, 'barthelScore')
                            }
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <input
                            type="checkbox"
                            style={{ accentColor: '#38A169' }}
                            id="barthelFlag"
                            name="barthelFlag"
                            className="mr-2 bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            value={caseDetail.barthelFlag}
                            onChange={(e) => updateCaseDetail(e, 'barthelFlag')}
                        />
                        <label
                            className="w-[160px] mr-4 text-left"
                            htmlFor="barthelFlag"
                        >
                            巴0醫師勾選
                        </label>
                        <input
                            type="checkbox"
                            style={{ accentColor: '#38A169' }}
                            id="cdrFlag"
                            name="cdrFlag"
                            className="mr-2 bg-green-400 checked:bg-green-600 text-green-600 w-5"
                            value={caseDetail.cdrFlag}
                            onChange={(e) => updateCaseDetail(e, 'cdrFlag')}
                        />
                        <label
                            className="w-[160px] mr-4 text-left"
                            htmlFor="cdrFlag"
                        >
                            CDR表
                        </label>
                    </div>
                </div>
                <div className="w-full grid grid-cols-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            長照中心
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.hospNoCare}
                            onChange={(e) => updateCaseDetail(e, 'hospNoCare')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">{t('general.telephone')}</div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.hospTel}
                            onChange={(e) => updateCaseDetail(e, 'hospTel')}
                        />
                    </div>
                </div>

                <div className="w-full border-b-2 my-4"></div>

                <div className="w-full flex mb-4">
                    <div className="w-[160px] mr-4 text-right col-span-1 row-span-3">
                        申請人姓名
                    </div>
                    <BaseInput
                        className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Text}
                        value={caseDetail.applicantsUserName}
                        onChange={(e) =>
                            updateCaseDetail(e, 'applicantsUserName')
                        }
                    />
                </div>

                <div className="w-full flex mb-4">
                    <div className="w-[160px] mr-4 text-right col-span-1 row-span-3">
                        申請人地址
                    </div>
                    <BaseInput
                        className="w-[calc(100%-160px)] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                        type={InputTypeEnum.Text}
                        value={caseDetail.applicantAdress}
                        onChange={(e) => updateCaseDetail(e, 'applicantAdress')}
                    />
                </div>
                <div className="w-full flex mb-4">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            收到退件日
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Date}
                            value={caseDetail.recipientDate}
                            onChange={(e) =>
                                updateCaseDetail(e, 'recipientDate')
                            }
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            退件次數
                        </div>
                        <BaseInput
                            className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.returnTime}
                            onChange={(e) => updateCaseDetail(e, 'returnTime')}
                        />
                    </div>
                </div>
                <div className="w-full flex mb-4">
                    <div className="w-[160px] mr-4 text-right">退件原因</div>
                    <div className="w-[calc(100%-160px)] ">
                        <BaseInput
                            className="w-full mb-4 block h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.returnReason}
                            onChange={(e) =>
                                updateCaseDetail(e, 'returnReason')
                            }
                        />
                        <BaseInput
                            className="w-full mb-4 block h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.returnReason2}
                            onChange={(e) =>
                                updateCaseDetail(e, 'returnReason2')
                            }
                        />
                        <BaseInput
                            className="w-full mb-4 block h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.returnReason3}
                            onChange={(e) =>
                                updateCaseDetail(e, 'returnReason3')
                            }
                        />
                        <BaseInput
                            className="w-full mb-4 block h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            type={InputTypeEnum.Text}
                            value={caseDetail.returnReason4}
                            onChange={(e) =>
                                updateCaseDetail(e, 'returnReason4')
                            }
                        />
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">
                            退件原因說明
                        </div>
                        <textarea
                            className="w-full mt-2 shadow rounded border-2 p-2 h-[120px]"
                            value={caseDetail.returnReasonName}
                            onChange={(e) =>
                                updateCaseDetail(e, 'returnReasonName')
                            }
                        />
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <div className="w-[160px] mr-4 text-right">備註</div>
                        <textarea
                            className="w-full mt-2 shadow rounded border-2 p-2 h-[120px]"
                            value={caseDetail.remark}
                            onChange={(e) => updateCaseDetail(e, 'remark')}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full border-b-2 mt-3"></div>
            <div className="flex justify-end p-3">
                <button
                    onClick={handleSaveData}
                    className="flex items-center justify-center font-bold px-4 h-10 mt-4 rounded-[6px] bg-[#2B6CB0] text-white"
                >
                    {t('general.saveFile')}
                </button>
            </div>
        </div>
    )
}
export default CertificateEditorModal
