import InfoCard from "./InfoCard"

/**
 * 醫療資訊
 * @param medicalInfoString {String} 醫療資訊
 * @param showData {Boolean} 是否顯示資料
 * */
const MedicalInformation = ({medicalInfoString, showData}) => {
    return (
        <InfoCard titles="醫療資訊">
            {/*沒有資料時*/}
            {
                !showData && 
                (<p className="font-globalfont text-[20px] leading-[23px] font-normal text-[#9C9C9C]">
                    請讀取健保IC卡或<br></br> 搜尋病人資訊
                </p>)
            }
            {
                showData && medicalInfoString && (
                    <div className="text-left w-7/8 text-[#3F3F46] max-h-[246px] overflow-y-auto">
                        {medicalInfoString}
                    </div>
                )
            }
        </InfoCard>
    )
}

export default MedicalInformation
