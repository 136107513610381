let firstDayOfWeek = 0; // 定義一週的第一天是星期幾，這裡設定為星期日，以 0 表示
let lastDayOfWeek = 6;  // 定義一週的最後一天是星期幾，這裡設定為星期六，以 6 表示

/**
 * 將指定的月份轉換為對應的名稱
 * @param {number} month - 要轉換的月份，從 0 到 11。
 * @returns {string} - 對應月份的名稱。
 */
export const getMonthName = month => {
    const months = [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
    ];
    return months[month];
  };

/**
 * 生成指定月份的日曆數組
 * @param {number} month - 要生成日曆的月份，從 0 到 11
 * @param {number} year - 要生成日曆的年份
 * @returns {Array<Array<Object>>} - 日曆數組，包含日期對象
 */
export const getCalendarMonth = (month, year) => {
    // 創建指定月份的第一天的日期物件
    let originalStartDate = new Date(year, month, 1);
    // 創建指定月份的最後一天的日期物件
    let originalEndDate = new Date(year, month + 1, 0);
  
    // 取得日曆顯示的第一天
    let actualStartDate = getStartOfCalendar(originalStartDate);
    // 取得日曆顯示的最後一天
    let actualEndDate = getEndOfCalendar(originalEndDate);
  
    // 計算日曆顯示的總天數
    let dayDifference = dateDiff(actualStartDate, actualEndDate);

    let calendar = Array(Math.ceil(dayDifference / 7)).fill().map((_, x) => {
        let week = [];
        Array(7).fill().forEach((_, y) => {
            let currentDate = new Date(actualStartDate.getTime());
            currentDate.setDate(actualStartDate.getDate() + x * 7 + y);
            week.push({
                // 判斷是否是當前月份的日期
                current: currentDate.getMonth() === originalStartDate.getMonth(),
                date: currentDate,
            });
        });
        return week;
    });
    return calendar;
};

/**
 * 計算兩個日期之間的天數差
 * @param {Date} start - 開始日期
 * @param {Date} end - 結束日期
 * @returns {number} - 兩個日期之間的天數差
 */
const dateDiff = (start, end) => {
    const from = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
    const to = new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());
  
    const timeDiff = Math.abs(from.getTime() - to.getTime());
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    let value =  dayDiff + 1;
    if (end.getUTCHours() === 0 && end.getUTCMinutes() === 0 && end.getUTCSeconds() === 0) {
        value -= 1;
    }
    return value;
};

/**
 * 取得日曆的最後一天
 * @param {Date} date - 要計算的日期
 * @returns {Date} - 日曆的最後一天
 */
const getEndOfCalendar = date => {
    let originalEndDate = new Date(date.getTime());
    let actualEndDate = new Date(date.getTime());
  
    if (originalEndDate !== 7 - 1 - firstDayOfWeek) {
        actualEndDate.setDate(
            originalEndDate.getDate() - originalEndDate.getDay() + lastDayOfWeek,
        );
    }
    return actualEndDate;
};

/**
 * 取得日曆的第一天
 * @param {Date} date - 要計算的日期
 * @returns {Date} - 日曆的第一天
 */
const getStartOfCalendar = date => {
    let originalStartDate = new Date(date.getTime());
    let actualStartDate = new Date(date.getTime());
  
    if (originalStartDate.getDay() !== firstDayOfWeek) {
        actualStartDate.setDate(
            originalStartDate.getDate() - originalStartDate.getDay() + firstDayOfWeek,
        );
    }
    return actualStartDate;
};
