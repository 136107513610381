import React, {useEffect, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup'
import {BaseInput} from "../../Input/BaseInput";
import {ApiErrorStatusEnum, arrayIsEmpty, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist";
import Button from "../../Button/Button";
import {rsisCancelMaster, rsisEditMaster, rsisQueryCodeValue} from "../../../api/v1/RSIS";
import useToast from "../../../hooks/useToast";

/**
 * 欄位標題
 * @param title {String}
 * @return {JSX.Element}
 */
const Title = ({title}) => {
    return (
        <p className='flex justify-end items-center'>{title}</p>
    )
}

/**
 * 欄位內容
 * @param content {String}
 * @param className {String}
 * @return {JSX.Element}
 */
const Content = ({content, className = ''}) => {
    return (
        <div className={`flex flex-row justify-start items-center pl-2 ${className}`}>{content}</div>
    )
}
/**
 * Input Style
 */
const InputStyle = "w-[160px] h-10  px-4 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * Button Style
 */
const ButtonStyle = 'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'

/**
 * 欄位組件
 * @param title {String}
 * @param content {String}
 * @param className {String}
 * @return {JSX.Element}
 */
const InputField = ({title, content, className}) =>
    <div className='flex flex-row h-[42px]'>
        <Title title={title}/>
        <Content content={content} className={className}/>
    </div>

/**
 * 整批註銷彈窗
 * @param num {number} 紀錄數
 * @param isOwnUser {boolean} 是否為承辦人員
 * @param handleOnClose {function} 關閉視窗
 * @param handleOnUpdate {function} 更新資料
 * @param selectItems {Array<Object>} 選取項目
 * @return {JSX.Element}
 */
const BatchesLogOff = ({num, isOwnUser, handleOnUpdate, handleOnClose, selectItems}) => {

    // 註銷原因
    const [reason, setReason] = useState({
        field1: '',
        field2: '',
    })
    // 變更原因
    const [changeReasonOptions, setChangeReasonOptions] = useState([])
    //Show Toast
    const showToast = useToast()

    /**
     * 存檔
     * @returns {void}
     */
    const handleOnSave = async () => {

        // 當變更原因為其他時
        if (reason.field1 === 'other' && reason.field2 === '') {
            showToast({message: '請輸入其它原因之說明', type: ToastTypeEnum.Warning})
            return
        }

        // 若沒有選取任何資料的話
        if (num === 0) {
            showToast({message: '請選擇記錄', type: ToastTypeEnum.Error})
            return
        }

        // 若變更原因為執登異動時
        if (reason.field1 === 'licenceChange') {
            // 設定有效否資料為失效 1有效 0失效
            const data = selectItems.map((item) => {
                return {
                    ...item,
                    effFlag: 0
                }
            })
            // 編輯資料
            handleOnEdit(data)
        } else { // 變更原因非執登異動時
            const data = selectItems.map((item) => {
                return {
                    // 單據編號:院區代號(1)+申請日期(YYYYMMDD)+流水號(3)
                    supportNo: item.supportNo,
                    // 項目修改版號, 從1開始
                    itemVersion: item.itemVersion,
                    // 支援人員代號
                    supportUserNo: item.supportUserNo,
                    // 支援機構代號
                    supportOrgPkey: item.supportOrgPkey,
                    // 支援科別代號
                    supportDivNo: item.supportDivNo,
                    // 支援目的代碼
                    purposeCode: item.purposeCode,
                    // 支援目的說明
                    purposeCodeExt: item.purposeCodeExt,
                    // 項目開始日期(YYYYMMDD)
                    itemStartDate: item.itemStartDate,
                    // 項目結束日期((YYYYMMDD)
                    itemEndDate: item.itemEndDate,
                    // 開始時間(24HHMM)
                    itemStartTime: item.itemStartTime,
                    // 結束時間(24HHMM)
                    itemEndTime: item.itemEndTime,
                    // 支援性質
                    supportProperties: item.supportProperties,
                    // 執行內容
                    executeContent: item.executeContent,
                    // 備註
                    memo: item.memo,
                    // 代理人代號
                    agentUserNo: item.agentUserNo,
                    // 狀態(1申請/2確認/3送審/9失效)
                    stepStatus: item.sendDatetime,
                    // 變更/註銷狀態(null空:無/M:變更/D:刪除)
                    supportModifyStatus: item.supportModifyStatus,
                    // 變更/註銷日期
                    supportModifyDatetime: item.supportModifyDatetime,
                    // 變更/註銷原因代號
                    supportModifyReasonCode: reason.field1,
                    // 變更/註銷原因說明
                    supportModifyReasonDoc: reason.field2,
                    // 送審檔案名稱
                    sendFileName: item.sendFileName,
                    // 送審案號
                    sendCaseNo: item.sendCaseNo,
                    // 送審日期
                    sendDatetime: item.sendDatetime,
                    // 申請方式(N:一般 / E:緊急申請)
                    applyType: item.applyType,
                    // 院區
                    zone: item.zone,
                    // 有效否(0/1)
                    effFlag: item.effFlag,
                    // 承辦部門代號
                    ownerDeptNo: item.ownerDeptNo,
                    // 欄位變更說明,以中文標記欄位名稱
                    changeFieldsDoc: item.changeFieldsDoc,
                    // 確認人員
                    confirmUserNo: item.confirmUserNo,
                    // 確認時間
                    confirmDatetime: item.confirmDatetime
                }
            })
            // 註銷資料
            handleLogOff(data)
        }
    }


    /**
     * 註銷
     * @returns {void}
     */
    const handleLogOff = (data) => {
        rsisCancelMaster(data).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                handleOnUpdate()
                showToast({message: '資料已註銷！', type: ToastTypeEnum.Success})
            } else {
                showToast({message: `註銷失敗，${msg}`, type: ToastTypeEnum.Error})
            }
            handleOnClose && handleOnClose()
        })
    }

    /**
     * 編輯
     * @returns {void}
     */
    const handleOnEdit = (data) => {
        rsisEditMaster(data).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                handleOnUpdate()
                showToast({message: '資料已變更為失效！', type: ToastTypeEnum.Success})
            } else {
                showToast({message: `變更失敗，${msg}`, type: ToastTypeEnum.Error})
            }
            handleOnClose && handleOnClose()
        })
    }

    /**
     * 取得變更原因選項
     * @return {void}
     */
    const getChangeReasonOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                //取得變更原因清單
                let changeReasonList
                //是否為承辦人員
                isOwnUser
                    ? changeReasonList = data.filter(item => item.codeType === 'CHANGE')
                    : changeReasonList = (data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N'))
                // 設定變更原因
                setChangeReasonOptions(changeReasonList)
                //陣列不為空
                if (!arrayIsEmpty(changeReasonList)) {
                    //設定變更原因
                    setReason({...reason, field1: changeReasonList[0].codeNo})
                }
            } else {
                setChangeReasonOptions([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        getChangeReasonOptions()
    }, [])

    return (
        <BasePopup
            title="整批註銷"
            closePopupButtonOnClick={handleOnClose}
            width="888px"
            content={
                <div className='flex flex-col px-6 py-4'>
                    <p className='flex justify-start items-center text-red-600'>{`${num}筆記錄選取`}</p>
                    {/* 註銷原因 */}
                    <div className='flex flex-row gap-2'>
                        <InputField title="註銷原因"
                                    content={
                                        <div className='flex flex-row gap-2'>
                                            <div className='flex flex-row gap-2'>
                                                <select className={InputStyle}
                                                        value={reason.field1}
                                                        onChange={(e) => setReason({
                                                            ...reason,
                                                            field1: e.target.value
                                                        })}>
                                                    {
                                                        changeReasonOptions.map((item) =>
                                                            <option key={`result_${item.codeNo}`} value={item.codeNo}>
                                                                {item.codeValue1}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                                <BaseInput
                                                    className={InputStyle}
                                                    type={InputTypeEnum.Text}
                                                    value={reason.field2}
                                                    onChange={(e) => setReason({...reason, field2: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                    }
                        />
                    </div>
                    {/*分隔線*/}
                    <div className='border-[1px] border-[rgba(0,0,0,0.15) my-4'></div>
                    {/*存檔*/}
                    <Button classNames={`w-[100px] ${ButtonStyle}`} text={'存檔'} onClickFn={handleOnSave}/>
                </div>
            }
        />
    )
}

export default BatchesLogOff
