// Import function
import {
    ApiErrorStatusEnum,
    enum2Array,
    fuzzySearchObjects,
    IccmCloudencPageTypeEnum,
    InputTypeEnum,
    multiTermFuzzySearchObjects, ToastTypeEnum
} from "edah_utils/dist"
import {t} from "i18next"
import React, {useState} from "react"
import {MedicationInformation} from "./ActiveSection/MedicationInformation"
import {DentalTreatmentAndSurgicalOperation} from "./ActiveSection/DentalTreatmentAndSurgicalOperation"
import {InspectionAndInspection} from "./ActiveSection/InspectionAndInspection"
import {AllergyMedicine} from "./ActiveSection/AllergyMedicine"
import {SurgeryDetailsRecord} from "./ActiveSection/SurgeryDetailsRecord"
import {InspectionResults} from "./ActiveSection/InspectionResults"
import {InspectionReport} from "./ActiveSection/InspectionReport"
import {DischargeSummary} from "./ActiveSection/DischargeSummary";
import {RehabilitationMedicine} from "./ActiveSection/RehabilitationMedicine";
import {ChineseMedicine} from "./ActiveSection/ChineseMedicine";
import {BaseInput} from "../../Input/BaseInput";
import {Pagination} from "../../Pagination/Pagination";
import {
    iccmCloudencQueryIccmClouden2Data,
    iccmCloudencQueryIccmClouden3Data,
    iccmCloudencQueryIccmClouden4Data,
    iccmCloudencQueryIccmClouden5Data,
    iccmCloudencQueryIccmClouden6Data,
    iccmCloudencQueryIccmClouden7Data,
    iccmCloudencQueryIccmClouden8Data,
    iccmCloudencQueryIccmClouden9Data,
} from '../../../api/v1/ICCM'
import useToast from "../../../hooks/useToast";

/**
 *  Switch Container
 *  @param data {Array<Object>} data
 *  @return {JSX.Element}
 */
export const SwitchContainer = ({data, patientId}) => {
    //當前選取的Tab index
    const [currentActiveTab, setCurrentActiveTab] = useState(IccmCloudencPageTypeEnum.medicationInformation)
    // 搜尋文字
    const [searchText, setSearchText] = useState("")
    // 檢查檢驗資料
    const [inspectionData, setInspectionData] = useState([])
    //手術明細紀錄資料
    const [surgeryData, setSurgeryData] = useState([])
    //牙科處置及手術作業紀錄資料
    const [dentalTreatmentData, setDentalTreatmentData] = useState([])
    //過敏藥資料
    const [allergyMedicineData, setAllergyMedicineData] = useState([])
    //檢驗結果資料
    const [inspectionResultsData, setInspectionResultsData] = useState([])
    //檢驗(查)報告資料
    const [inspectionReportData,setInspectionReportData]=useState([])
    //出院病摘資料
    const [dischargeSummaryData, setDischargeSummaryData] = useState([])
    //復健醫療資料
    const [rehabilitationMedicineData,setRehabilitationMedicineData]=useState([])
    //中醫用藥資料
    const [chineseMedicineData,setChineseMedicineData]=useState([])
    // Show Toast
    const showToast = useToast();
    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)
    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)
    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)
    //總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)


    /**
     * Tab點擊時切換
     * @param activeSection {Number} activeSection
     * @return {void}
     */
    const handleTabOnClick = (activeSection) => {
        //設定當前選取的Tab index
        setCurrentActiveTab(activeSection);

        //切換到檢查檢驗按鈕
        if (activeSection === IccmCloudencPageTypeEnum.inspectionAndInspection) {
            const getQueryInspectionAndInspection = () => {
                iccmCloudencQueryIccmClouden2Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setInspectionData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryInspectionAndInspection();
        }

        //切換到手術明細紀錄
        else if (activeSection === IccmCloudencPageTypeEnum.surgeryDetailsRecord) {
            const getQuerySurgeryDetailsRecord = () => {
                iccmCloudencQueryIccmClouden3Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setSurgeryData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQuerySurgeryDetailsRecord();
        }

        //切換到牙科處置及手術作業紀錄
        else if (activeSection === IccmCloudencPageTypeEnum.dentalTreatmentAndSurgicalOperation) {
            const getQueryDentalTreatmentAndSurgicalOperation = () => {
                iccmCloudencQueryIccmClouden4Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setDentalTreatmentData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryDentalTreatmentAndSurgicalOperation();
        }

        //切換到過敏藥
        else if (activeSection === IccmCloudencPageTypeEnum.allergyMedicine) {
            const getQueryAllergyMedicine = () => {
                iccmCloudencQueryIccmClouden5Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setAllergyMedicineData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryAllergyMedicine();

        }

        //切換到檢驗結果
        else if (activeSection === IccmCloudencPageTypeEnum.inspectionResults) {
            const getQueryInspectionResults = () => {
                iccmCloudencQueryIccmClouden6Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setInspectionResultsData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryInspectionResults();
        }

        //切換到檢驗(查)報告
        else if (activeSection === IccmCloudencPageTypeEnum.inspectionReport) {
            const getQueryInspectionReport = () => {
                iccmCloudencQueryIccmClouden6Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setInspectionReportData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryInspectionReport();
        }

        //切換到出院病摘
        else if (activeSection === IccmCloudencPageTypeEnum.dischargeSummary) {
            const getQueryDischargeSummary = () => {
                iccmCloudencQueryIccmClouden7Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setDischargeSummaryData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryDischargeSummary();
        }

        //切換到復健醫療
        else if (activeSection === IccmCloudencPageTypeEnum.rehabilitationMedicine) {
            const getQueryRehabilitationMedicine = () => {
                iccmCloudencQueryIccmClouden8Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setRehabilitationMedicineData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryRehabilitationMedicine();
        }

        //切換到中醫用藥
        else if (activeSection === IccmCloudencPageTypeEnum.chineseMedicine) {
            const getQueryChineseMedicine = () => {
                iccmCloudencQueryIccmClouden9Data({
                    // 病歷號
                    patientId: patientId,
                    // 當前頁碼
                    pageNum: currentPage,
                    // 每頁資料筆數
                    pageSize: pageSize
                }).then(res => {
                    // 狀態 / 訊息 / 資料 / 總頁數 / 總資料筆數
                    const {err, msg, data, totalPageSize, totalItemSize} = res;
                    // 取得成功
                    if (err === ApiErrorStatusEnum.Success) {
                        // 設定資料
                        setChineseMedicineData(data);
                        // 設定總資料筆數
                        setTotalSize(totalItemSize);
                        // 設定總頁數
                        setTotalPageSize(totalPageSize);
                    } else { // 取得失敗
                        showToast({message: msg, type: ToastTypeEnum.Error});
                    }
                });
            };
            getQueryChineseMedicine();
        }
    };


    /**
     * Get button class
     * @param index {number} index
     * @return {string}
     */
    const getButtonClass = (index) => `flex items-center justify-center px-4 h-10 mr-2 rounded-[6px] whitespace-nowrap ${currentActiveTab === index ? 'bg-[#2B6CB0] text-white' : 'bg-[#F4F4F5] font-bold'}`

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得過濾資料
     * @return {Array<Object>}
     */
    const getFilterData = () =>
        // 字串有空白時，進行多字串模糊搜尋
        searchText.indexOf(' ') > 0 ?
            // 多字串模糊搜尋
            multiTermFuzzySearchObjects(data, searchText.split(' ')) :
            // 單字串模糊搜尋
            fuzzySearchObjects(data, searchText)

    /**
     * 取得區塊
     * @return {JSX.Element}
     */
    const getSection = () => {
        switch (currentActiveTab) {
            //用藥資料
            case IccmCloudencPageTypeEnum.medicationInformation:
                return <MedicationInformation data={getFilterData()}/>
            //檢查檢驗
            case IccmCloudencPageTypeEnum.inspectionAndInspection:
                return <InspectionAndInspection data={inspectionData}/>
            //手術明細紀錄
            case IccmCloudencPageTypeEnum.surgeryDetailsRecord:
                return <SurgeryDetailsRecord data={surgeryData}/>
            //牙科處置及手術作業紀錄
            case IccmCloudencPageTypeEnum.dentalTreatmentAndSurgicalOperation:
                return <DentalTreatmentAndSurgicalOperation data={dentalTreatmentData}/>
            //過敏藥
            case IccmCloudencPageTypeEnum.allergyMedicine:
                return <AllergyMedicine data={allergyMedicineData}/>
            //檢驗結果
            case IccmCloudencPageTypeEnum.inspectionResults:
                return <InspectionResults data={inspectionResultsData}/>
            //檢驗(查)報告
            case IccmCloudencPageTypeEnum.inspectionReport:
                return <InspectionReport data={inspectionReportData}/>
            //出院病摘
            case IccmCloudencPageTypeEnum.dischargeSummary:
                return <DischargeSummary data={dischargeSummaryData}/>
            //復健醫療
            case IccmCloudencPageTypeEnum.rehabilitationMedicine:
                return <RehabilitationMedicine data={rehabilitationMedicineData}/>
            //中醫用藥
            case IccmCloudencPageTypeEnum.chineseMedicine:
                return <ChineseMedicine data={chineseMedicineData}/>
        }
    }

    return (
        <div id="switchContainer" className="m-4 px-6 border-[1px] border-[#D4D4D8] rounded-[12px] py-2 bg-white">
            {/*內容切換按鈕*/}
            <div id="switchTab" className="w-full">
                <div className="flex flex-row justify-start items-center mb-2.5 py-2 overflow-scroll">
                    {
                        enum2Array(IccmCloudencPageTypeEnum).map((item, index) =>
                            <button key={index} onClick={() => handleTabOnClick(item.value)}
                                    className={getButtonClass(item.value)}>
                                {t(`ICCard.cloudencActiveSection.${item.value}`)}
                            </button>
                        )
                    }
                </div>
            </div>
            {/*內容切換內文區*/}
            <div id="switchContain">
                <div className="flex flex-row w-full py-2 mb-4">
                    <h3 className="flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                        <span>{t(`ICCard.cloudencActiveSection.${currentActiveTab}`)}</span>
                    </h3>
                    {/*進階搜尋*/}
                    <BaseInput className="w-[200px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text} inputMode={InputTypeEnum.Search}
                               placeholder={t('general.advancedSearch')} value={searchText}
                               onChange={handleSearchTextOnChange}/>
                </div>
                {/*區塊*/}
                {getSection()}
                <div className="flex w-full justify-end">
                    <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                                totalSize={totalSize}
                                onPageOnChange={onPaginationPageOnChange}
                                onPreviousOnClick={onPaginationPreviousOnClick}
                                onNextOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}/>
                </div>
            </div>
        </div>
    )
}
