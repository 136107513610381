import React, {useState, useMemo} from 'react'
import Fuse from 'fuse.js'
import MenuItem from '../Menu/MenuItem'
import SearchBar from '../Menu/SearchBar'
import SideTab from '../Menu/SideTab'
import CommonMenu from '../Menu/CommonMenu'
import {ReactComponent as ArrowDown} from '../../assets/images/icons/arrow_drop_down.svg'
import {ReactComponent as Arrow} from '../../assets/images/icons/arrow_right.svg'
import {getLocalStorage} from 'edah_utils/dist'
import {querySystemByRole} from '../../api/v1/Menu'
import {REFERRAL_SYSTEM_NO} from '../../constants/systemNo'
import {
    REFERRAL_FORM_DOWNLOAD_NO,
    REFERRAL_FORM_UPLOAD_NO,
    REFERRAL_REPLY_LETTER,
    APPLY_DISCHARGE_SUMMARY_NO,
    APPROVE_DISCHARGE_SUMMARY_NO,
    TRAN_IN_CASE_LIST_NO,
    TRAN_OUT_CASE_LIST_NO,
    TRAN_FOREIGN_LIST_NO, PERMISSION_SETTING_NO, MAINTAIN_NO, REFERRAL_CODE_MAINTAIN_NO, EMERGENCY_HOSPITAL_MAINTAIN_NO,
    OTHERS_NO,
    QUERY_PATIENT_VISIT_LIST_NO,
    STRATEGIC_ALLIANCE_MAINTAIN_NO,
    NO_REFERRAL_REPORT_NO,
    ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
    MARS_REPORT_NO,
    REFERRAL_PLUGIN_UPLOAD_NO, LONG_TERM_CARE_CENTER_NO,
    ADD_REFERRAL_NO,
    RURAL_HOSPITAL_NO,
} from '../../constants/menuFuncNo'

const staticMenuItems = [
    {
        "systemNo": "CVIS",
        "systemName": "危急值",
        "sortBy": null,
        "funList": [
            {
                "funNo": "CVIS-MA-01",
                "funTitle": "危急值通報紀錄",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-02",
                "funTitle": "感染管制通報記錄",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-03",
                "funTitle": "危急值逾時回覆查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-04",
                "funTitle": "危急值通報閉環監控",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-01",
                "funTitle": "危急值通報",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-02",
                "funTitle": "危急值回覆待回覆清單",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-03",
                "funTitle": "A級危急值逾時回覆再通知",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-04",
                "funTitle": "戰情資訊看板",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            }
        ]
    },
    {
        "systemNo": "AP",
        "systemName": "掛號系統",
        "sortBy": null,
        "funList": [
            {
                funNo: '10011-SF-0001',
                funTitle: '掛號作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            {
                funNo: '10011-SF-0002',
                funTitle: '停代診作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            {
                funNo: '10011-SF-0004',
                funTitle: '醫囑預約掛號作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            // New Sidebar for 月班維護
            {
                funNo: '10011-SS-0000',
                funTitle: '排班作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: 'common-1-1',
                        funTitle: '週班維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'common-1-1',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SS-0000-2',
                        funTitle: '月班維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SS-0000',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SS-0000-3',
                        funTitle: '班表批次異動',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SS-0000',
                        nodeFlag: 'false',
                    },
                ],
            },
            {
                funNo: '10011-SM-0003',
                funTitle: '查詢作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: '10011-SM-0003-1',
                        funTitle: '醫師報備查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0003',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0003-2',
                        funTitle: '診間班表查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0003',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0003-3',
                        funTitle: '掛號查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0003',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0003-4',
                        funTitle: '列印作業',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    }
                ]
            }
            ,
            {
                funNo: '10011-SM-0004',
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: '10011-SM-0004-1',
                        funTitle: '給號方式維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0004-2',
                        funTitle: '行事曆維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0004-3',
                        funTitle: '標語維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-SM-0004-4',
                        funTitle: '類別維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    },{
                        funNo: '10011-SM-0004-5',
                        funTitle: '科別維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-SM-0004',
                        nodeFlag: 'false',
                    },
                ]
            }
        ],
    },
    {
        systemNo: 'BP',
        systemName: 'IC模組',
        sortBy: null,
        funList: [
            {
                funNo: '10011-BP-0001',
                funTitle: '主功能',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: '10011-BP-0001-1',
                        funTitle: '病人IC卡資料查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0001',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-BP-0001-2',
                        funTitle: 'IC卡寫卡資料維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0001',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-BP-0001-3',
                        funTitle: 'IC卡資料上傳資料查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0001',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-BP-0001-4',
                        funTitle: '待寫卡清單',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0001',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: '10011-BP-0001-5',
                        funTitle: '健保醫療資訊雲端查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0001',
                        nodeFlag: 'false',
                    },
                ],
            },
            {
                funNo: '10011-BP-0002',
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: '10011-BP-0002-1',
                        funTitle: '重大傷病資料維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '10011-BP-0002',
                        nodeFlag: 'false',
                    },
                ],
            },
        ],
    },
    {
        "systemNo": "CP",
        "systemName": "CDSS系統",
        "sortBy": null,
        "funList": [
            {
                "funNo": "10011-CP-0001",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "10011-CP-0001-1",
                        "funTitle": "CDSS Information維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-CP-0001",
                        "nodeFlag": "false",

                    }
                ]
            }
        ]
    },
    {
        "systemNo": "ES",
        "systemName": "電子簽章",
        "sortBy": null,
        "funList": [
            {
                "funNo": "10011-ES-0001",
                "funTitle": "主功能",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "10011-ES-0001-1",
                        "funTitle": "未簽文件查詢與簽章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false",

                    },
                    {
                        "funNo": "10011-ES-0001-2",
                        "funTitle": "電子病歷查詢及驗章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "10011-ES-0001-3",
                        "funTitle": "醫療機構卡簽章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "10011-ES-0001-4",
                        "funTitle": "操作記錄查詢",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "10011-ES-0001-5",
                        "funTitle": "簽章率明細與查詢 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "10011-ES-0001-6",
                        "funTitle": "24小時簽章率明細與查詢",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0001",
                        "nodeFlag": "false"
                    },
                ]
            },
            {
                "funNo": "10011-ES-0002",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "10011-ES-0002-1",
                        "funTitle": "電子表單類別維護 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0002",
                        "nodeFlag": "false",
                    },
                    {
                        "funNo": "10011-ES-0002-2",
                        "funTitle": "人員憑證維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0002",
                        "nodeFlag": "false",
                    },
                    {
                        "funNo": "10011-ES-0002-3",
                        "funTitle": "代簽人員維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "10011-ES-0002",
                        "nodeFlag": "false",
                    }
                ]
            },
        ]
    },
    {
        "systemNo": "HCIS",
        "systemName": "居家系統",
        "sortBy": null,
        "funList": [
            {
                "funNo": "HCIS-FN-01",
                "funTitle": "居家評估作業_收案清單",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-09",
                "funTitle": "居家潛在個案維護(S1)",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-02",
                "funTitle": "居家評估作業_基本評估(S2)",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-03",
                "funTitle": "居家評估作業作業_表單評估",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-04",
                "funTitle": "安寧居家療護病歷(S3)",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-05",
                "funTitle": "居家評估作業_檢驗查詢-檢驗系統提供",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-06",
                "funTitle": "安寧居家療護心理社會需要評估及照顧記錄(S3)",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-07",
                "funTitle": "居家潛在個案維護_收案清單",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-FN-08",
                "funTitle": "居家潛在個案維護(S1)_基本評估",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-MA-01",
                "funTitle": "居家護理記錄項目維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS-MA-00",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS-MA-02",
                "funTitle": "居家個案已結案狀態維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS-MA-00",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0004",
                "funTitle": "再入院查詢報表",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0004",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0005",
                "funTitle": "急診使用查詢報表",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0005",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0006",
                "funTitle": "皮膚損傷匯出報表",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0006",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0007",
                "funTitle": "藥物到期查詢報表",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0007",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0008",
                "funTitle": "個案查詢報表_新收案",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0008",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_0009",
                "funTitle": "居護個案基本資料API",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_0009",
                "nodeFlag": "false"
            },
            {
                "funNo": "HCIS_SC_1010",
                "funTitle": "居家護理記錄項目維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": "HCIS_SC_1010",
                "nodeFlag": "false"
            },
        ]
    },
    {
        "systemNo": "WDIS",
        "systemName": "系統監控",
        "sortBy": null,
        "funList": [
            {
                "funNo": "WDIS-MA-01",
                "funTitle": "服務資料維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-MA-02",
                "funTitle": "通知人員維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-FN-02",
                "funTitle": "服務狀態查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-QR-01",
                "funTitle": "服務Log查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            }
        ]
    },
    {
        "systemNo": "RSIS",
        "systemName": "報備支援",
        "sortBy": null,
        "funList": [
            {
                "funNo": "RSIS-FN-01",
                "funTitle": "申請與查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-02",
                "funTitle": "整批變更註銷或刪除",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-03",
                "funTitle": "整批匯入作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-04",
                "funTitle": "急件申請",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-05",
                "funTitle": "衛福部案號回饋作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-06",
                "funTitle": "個案管理",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-MA-00",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "RSIS-MA-01",
                        "funTitle": "支援機構維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-03",
                        "funTitle": "承辦部門人員維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-04",
                        "funTitle": "病房代理人維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-05",
                        "funTitle": "特殊權限設定",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-06",
                        "funTitle": "警示信箱發送",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-07",
                        "funTitle": "功能操作說明維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-08",
                        "funTitle": "基本資料設定",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                ]
            }
        ]
    },

    {
        systemNo: REFERRAL_SYSTEM_NO,
        systemName: '轉診系統',
        sortBy: null,
        funList: [
            {
                funNo: REFERRAL_FORM_DOWNLOAD_NO,
                funTitle: '電子轉診單_下載',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: REFERRAL_FORM_UPLOAD_NO,
                funTitle: '電子轉診單_上傳',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: REFERRAL_REPLY_LETTER,
                funTitle: '轉診回函',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: APPLY_DISCHARGE_SUMMARY_NO,
                funTitle: '出院病摘_申請/列印',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: APPROVE_DISCHARGE_SUMMARY_NO,
                funTitle: '出院病摘_核准',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_IN_CASE_LIST_NO,
                funTitle: '轉入單個管作業_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_OUT_CASE_LIST_NO,
                funTitle: '轉出單個管作業_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_FOREIGN_LIST_NO,
                funTitle: '病症暨失能診斷證明書_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: ADD_REFERRAL_NO,
                funTitle: '轉出轉入開立作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: OTHERS_NO,
                funTitle: '其他作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: QUERY_PATIENT_VISIT_LIST_NO,
                        funTitle: '看診清單查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    {
                        funNo: NO_REFERRAL_REPORT_NO,
                        funTitle: '不轉診原因報表',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    {
                        funNo: ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
                        funTitle: '急診品質方案VPN上傳匯出作業',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    // {
                    //     funNo: MARS_REPORT_NO,
                    //     funTitle: 'MARS(VPN)報表',
                    //     funUrl: null,
                    //     iconPath: null,
                    //     sortBy: 1,
                    //     parentFunNo: OTHERS_NO,
                    //     nodeFlag: 'false',
                    // },
                    {
                        funNo: REFERRAL_PLUGIN_UPLOAD_NO,
                        funTitle: '轉診外掛單機上傳程式',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                ]
            },
            {
                funNo: MAINTAIN_NO,
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '10011-SM-0000',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: PERMISSION_SETTING_NO,
                        funTitle: '權限設定(轉診外網)',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: REFERRAL_CODE_MAINTAIN_NO,
                        funTitle: '轉診代碼維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: EMERGENCY_HOSPITAL_MAINTAIN_NO,
                        funTitle: '急救責任醫院維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: STRATEGIC_ALLIANCE_MAINTAIN_NO,
                        funTitle: '策略聯盟維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: LONG_TERM_CARE_CENTER_NO,
                        funTitle: '長照中心維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: RURAL_HOSPITAL_NO,
                        funTitle: '離島偏鄉醫院維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                ],
            },
        ],
    }
]
const commonItems = [
    {
        id: 'common-1',
        name: '週班維護',
        children: [
            {
                id: 'common-1',
                name: '掛號系統',
                children: [
                    {
                        id: 'common-1-1',
                        name: '週排班作業',
                    },
                ],
            },
        ],
    },
    {
        id: 'common-2',
        name: '主功能',
        children: [
            {
                id: 'common-1',
                name: 'IC模組',
                children: [
                    {
                        id: '10011-BP-0001-1',
                        name: '病人IC卡資料查詢',
                    },
                ],
            },
        ],
    },
    {
        id: "common-4",
        name: "危急值管理",
        children: [
            {
                id: "common-4-1",
                name: "危急值管理",
                children: [
                    {
                        id: "common-4-1-3",
                        name: "待回覆提示",
                    },
                ],
            },
        ],
    },
]

function buildTree(items) {
    return items.map((item) => {
        const node = {
            id: item.funNo,
            name: item.funTitle,
        }
        // 根據是否存在funlist來判斷是否有子節點
        if (item.funList && item.funList.length > 0) {
            node.children = buildTree(item.funList)
        }
        return node
    })
}

const TreeMenu = ({Add, isOpen, toggleSidebar}) => {
    const [isCommonOpen, setIsCommonOpen] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    //Menu items
    const [items, setItems] = useState([])

    //避免資料太多導致渲染效率差
    useMemo(() => {
        //取得當前使用者Role
        const role = getLocalStorage('role')
        //使用者Role存在的話
        if (role) {
            //將Role轉成Array
            const roleArray = JSON.parse(role)
            //目前API只能傳入一個角色
            const roleNos = roleArray[0].roleNo

            //將取得到的結果重新組裝新結構
            const data = staticMenuItems.map((system) => ({
                id: system.systemNo,
                name: system.systemName,
                children: buildTree(system.funList),
            }))

            //將結果配置給Items
            setItems(data)
            //取得角色Menu清單
            querySystemByRole({roleNos: roleNos}).then((res) => {
                if (res.err === 0) {
                    //     res.data.map(system => ({
                    //     id: system.systemNo,
                    //     name: system.systemName,
                    //     children: buildTree(system.funList),
                    // }))
                }
            })
        }
    }, [])


    // Function to flatten nested items
    const flattenItems = (items) => {
        return items.reduce((acc, item) => {
            acc.push(item)
            if (item.children) {
                acc.push(...flattenItems(item.children))
            }
            return acc
        }, [])
    }

    const flatItems = useMemo(() => flattenItems(items), [items])

    // Initialize Fuse instance
    const fuse = useMemo(
        () =>
            new Fuse(flatItems, {
                keys: ['name'],
                threshold: 0.3,
            }),
        [flatItems]
    )

    // Filter items based on search term
    const filteredItems = useMemo(() => {
        if (!searchTerm) return items // If no search term, return all items
        return fuse.search(searchTerm).map((result) => result.item) // Map results to item
    }, [fuse, items, searchTerm])

    return (
        <div className="relative">
            <SideTab
                isOpen={isOpen}
                toggleMenu={toggleSidebar}
            />
            <div
                className={`${
                    isOpen ? 'w-[232px]' : 'w-0'
                } bg-[#F4F4F5] shadow-md rounded-lg h-[calc(100vh-56px)] overflow-scroll`}
            >
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                <div className="border-gray-200">
                    <div
                        className="flex justify-start items-center p-2 cursor-pointer"
                        onClick={() => setIsCommonOpen(!isCommonOpen)}
                    >
                        <span className="text-lg">
                            {isCommonOpen ? <ArrowDown/> : <Arrow/>}
                        </span>
                        <span>常用清單</span>
                    </div>
                    {isCommonOpen && (
                        <div className="flex flex-col px-2 pl-10">
                            {commonItems.map((item, index) => (
                                <CommonMenu
                                    key={index}
                                    item={item}
                                    onItemSelected={Add}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="w-full h-px bg-[#d2d2d2] my-4"></div>
                <ul className="menu-items">
                    {filteredItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            item={item}
                            // userRole={userRole}
                            hasChildren={
                                item.children && item.children.length > 0
                            }
                            onItemSelected={Add}
                        />
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default TreeMenu
