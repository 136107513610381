import React, { useEffect, useState } from "react";
import CrisisAlertAwaitReplyListPopup from "../../components/CriticalValueManagement/Modal/CrisisAlertAwaitReplyListPopup";
import CrisisAlertReplyAssignmentPopup from "../../components/CriticalValueManagement/Modal/CrisisAlertReplyAssignmentPopup";


const PendingReplyNotification = () => {
    // 帶入醫師
    const [doctor, setDoctor] = useState('9999')
    // 回覆作業查詢編號
    const [queryItem, setQueryItem] = useState()
    // 回覆待回覆清單
    const [awaitReplyList, setAwaitReplyList] = useState([])
    // 危急值待回覆清單彈窗
    const [awaitListPopup, setAwaitListPopup] = useState(false)
    // 危急值回覆作業彈窗
    const [replyPopup, setReplyPopup] = useState(false)
    // A級危急值逾時回覆再通知彈窗
    const [replyAPopup, setReplyAPopup] = useState(false)

    /**
     * 待回覆清單彈窗開關
     * @returns {void}
     */
    const handleAwaitListPopup = () => {
        setAwaitListPopup(!awaitListPopup)
    }

    /**
     * 控制回覆作業彈窗開關，開啟時同步關閉待回覆清單彈窗
     * @returns {void}
     */
    const handleReplyPopup = () => {
        // 確保目前回覆作業視窗是顯示狀態，回覆作業視窗關閉後，顯示待回覆清單視窗
        // setAwaitListPopup(!awaitListPopup)
        handleAwaitListPopup()
        setReplyPopup(!replyPopup)
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="w-full flex flex-row items-center justify-start mb-4">
                    <p className="mr-5">
                        <span className="mr-5">【2023/09/09】</span>
                        <span className="mr-5">【午】</span>
                        <span className="mr-5">【二樓一般外科2診】</span>
                        <span className="mr-5">【看診醫師：李醫師】</span>
                    </p>
                    <p 
                        className="text-[#E53E3E] mr-4 hover:cursor-pointer"
                        onClick={handleAwaitListPopup}
                    >
                        危急值回覆
                    </p>
                    <button className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] rounded-[6px] ">照會單回覆</button>
                    <button className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] rounded-[6px] ">醫師審核</button>
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">診號:</p>
                    <input className="w-[166px] h-10 px-4 border-[1px] mr-4 border-[#D4D4D8] rounded-[6px]" type="text"/>
                    <button className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] rounded-[6px] ">實體IC</button>
                    <button className="flex items-center justify-center h-10 px-4 mr-5 border-[2px] rounded-[6px] ">虛擬IC</button>
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r1" style={{accentColor: "#38A169"}}/>
                    <label className="cursor-pointer" for="r1">已完成(4)</label>
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r2" style={{accentColor: "#38A169"}}/>
                    <label className="cursor-pointer" for="r2">暫存(4)</label>
                </div>
                <div className="flex flex-row space-x-3 mb-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r3" style={{accentColor: "#38A169"}}/>
                    <label className="cursor-pointer" for="r3">已完成(4)</label>
                </div>
                <div className="w-[1px] h-[40px] bg-[#111111]/15 mx-4 mb-4"></div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r4" style={{accentColor: "#38A169"}}/>
                    <label className="cursor-pointer" for="r4">已報到</label>
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r5" style={{accentColor: "#38A169"}}/>
                    <label className="cursor-pointer" for="r5">未報到</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        查詢
                    </button>
                </div>

            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type="text" placeholder="進階搜尋"/>
                    </div>
                    <ul className="tableList PRNTableList">
                        <li className="title">
                            <div>診號</div>
                            <div>報到</div>
                            <div>看診狀態</div>
                            <div>病例號</div>
                            <div>病患姓名</div>
                            <div>性別</div>
                            <div>年齡</div>
                            <div>身份</div>
                            <div>初/複</div>
                            <div>特殊身份</div>
                        </li>
                        <li>
                            <div>001</div>
                            <div>Y</div>
                            <div>已完成</div>
                            <div>
                                <a className="text-[#2B6CB0] underline" href="">0000123</a>
                            </div>
                            <div>黃ＯＯ</div>
                            <div>男</div>
                            <div>54歲4月9天</div>
                            <div>健保</div>
                            <div>複診</div>
                            <div></div>
                        </li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
            {/* 危急值待回覆清單彈窗 */}
            {awaitListPopup && 
                <CrisisAlertAwaitReplyListPopup
                    doctor={doctor}
                    handlePopupClose={handleAwaitListPopup}
                    handleReplyPopup={handleReplyPopup}
                    handleSetQueryItem={setQueryItem}
                />
            }
            {/* 危急值回覆作業彈窗 */}
            {replyPopup &&
                <CrisisAlertReplyAssignmentPopup
                    handlePopupClose={handleReplyPopup}
                    handleSaveSubmit={handleReplyPopup}
                    doctor={doctor}
                    cvisID={queryItem}
                />
            }
        </div>
    )
}
export default PendingReplyNotification