import { NavLink } from "react-router-dom";
const InfoCard = ({ titles, height, children }) => {
  return (
    <div
      className={`info-card rounded-md px-2 border-2 border-gray-300 bg-white flex flex-col  shadow-lg h-${
        height ? height : "full"
      }`}
    >
      {typeof titles === "object" ? (
        <div className="flex flex-row">
          {titles.map((title) => {
            return (
              <NavLink
                to={`${title.link}`}
                key={title.name}
                className="acive:bg-red-200 relative card-title bg-glacierBlue w-fit text-xl px-4 pt-1 pb-0.5 text-center rounded-t-lg border-gray-200 border-2 border-b-0"
              >
                {title.name}
              </NavLink>
            );
          })}
        </div>
      ) : (
        ""
      )}
      <div className="w-full px-[8px] py-[8px] flex flex-col space-y-4 relative border-t-0 flex-grow">
        <div className="flex flex-row space-x-2">
          <div className="h-full w-2 py-1">
            <div className="bg-[#2B6CB0] h-full">

            </div>
          </div>
          <div className="text-[20px] text-[#2B6CB0]">{titles}</div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default InfoCard;
