import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg"
import exportIcon from "../../assets/images/SystemMonitoring/export.svg";
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist"
import {BasePopup} from "../../components/Popup/BasePopup";
import {end} from "bfj/src/events";

/**
 * 居家護理>皮膚損傷匯出報表
 * @return {JSX.Element}
 */
const SkinDamageReport = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    // 日期範圍
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // 篩選後的數據
    const [filteredData, setFilteredData] = useState([]);

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            patientId: '0801234',
            patientName: '黃ＯＯ',
            recordDate:"2023-09-12",
            skinDamageNo: 'A01',
            skinDamageName: '完整',
            caseDate:'2023-09-12',
            nis:"陳ＯＯ",
            serialNo:"A123485859"
        }
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    /**
     * 日期範圍改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleStartDateChange = (e) => setStartDate(e.target.value);
    const handleEndDateChange = (e) => setEndDate(e.target.value);

    /**
     * 篩選數據
     * @param data {Array} 數據數組
     * @return {Array} 篩選後的數據數組
     */
    const filterDataByDateRange = (data) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const caseDate = new Date(item.caseDate);
            return caseDate >= start && caseDate <= end;
        });
    };

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryClick = () => {
        let filtered = [];
        filtered = filterDataByDateRange(dataObject);
        setFilteredData(fuzzySearchObjects(filtered, searchText));
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/* 查詢日期 */}
                    <p className="mr-1">查詢日期：</p>
                    <BaseInput className=" h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] "
                               type={InputTypeEnum.Date}
                               inputMode={'date'}
                               value={startDate}
                               onChange={handleStartDateChange}
                    />
                    <p className="mx-1">～</p>
                    <BaseInput className=" h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Date}
                               inputMode={'date'}
                               value={endDate}
                               onChange={handleEndDateChange}
                    />
                </div>

                {/*查詢送出按鈕*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleQueryClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                        {/* excel輸出 */}
                        <button
                            className="flex flex-row justify-center items-center w-[112px] h-10"
                        >
                            <div className="w-10 h-10">
                                <img className="w-full h-full" src={exportIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">{t('general.export')}</p>
                        </button>
                    </div>

                    {/*列表清單*/}
                    <ul className=" tableList SDRTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div><Check/></div>
                            <div>病歷號</div>
                            <div>姓名</div>
                            <div>記錄日期</div>
                            <div>皮膚損傷代號</div>
                            <div>皮膚損傷完整名稱</div>
                            <div>收案日期</div>
                            <div>負責護理師</div>
                            <div>流水號</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(filteredData)) ? getFilterData(filteredData).map((item, index) =>
                            <li key={index}>
                                {/*checkBox*/}
                                <div>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                                           type="checkbox"
                                           id={`r${index}`} style={{accentColor: "#38A169"}}/>
                                </div>
                                {/*病歷號*/}
                                <div>{item.patientId}</div>
                                {/*姓名*/}
                                <div>{item.patientName}</div>
                                {/* 記錄日期 */}
                                <div>{item.recordDate}</div>
                                {/*皮膚損傷代號*/}
                                <div>{item.skinDamageNo}</div>
                                {/*皮膚損傷完整名稱*/}
                                <div>{item.skinDamageName}</div>
                                {/* 收案日期 */}
                                <div>{item.caseDate}</div>
                                {/* 負責護理師 */}
                                <div>{item.nis}</div>
                                {/* 流水號 */}
                                <div>{item.serialNo}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default SkinDamageReport