import React from "react";

/**
 * 醫囑清單 Tab Content
 * @param show {boolean} Show List Content
 * @return {JSX.Element}
 */
export const ListContent = ({show}) => {

    return show ?
        <div>
            <ul className="tableList icMaintainList">
                <li className="title">
                    <div>就診日期時間</div>
                    <div>醫令類別</div>
                    <div>診療項目代號</div>
                    <div>診療部位</div>
                    <div>用法</div>
                    <div>天數</div>
                    <div>總量</div>
                </li>
                <li>
                    <div>2023/01/01 15:10:01</div>
                    <div>2023/10/01</div>
                    <div>A006271100:Paran(永信理冒伯樂止痛)</div>
                    <div></div>
                    <div>TID</div>
                    <div>3</div>
                    <div>9</div>
                </li>
                <li>
                    <div>2023/01/01 15:10:01</div>
                    <div>2023/10/01</div>
                    <div>19001C:腹部超音波</div>
                    <div></div>
                    <div>QD</div>
                    <div>1</div>
                    <div>1</div>
                </li>
            </ul>
        </div> : null
}