import { booleanForDisplay } from '../../components/ReferralSystem/utils'
import { DATE_FORMAT } from '../common'
import { time2String } from 'edah_utils'
import i18n from '../../i18n'
export const DISCHARGE_SUMMARY_FIELDS = [
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.encounterDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.admissionDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'dischargeDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.dischargeDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.patientId'),
        className: 'text-sm',
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.patientName'),
        className: 'text-sm',
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.divName'),
        className: 'text-sm',
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.doctorName'),
        className: 'text-sm',
    },
    {
        key: 'tranInHospName',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.tranInHospName'),
        className: 'text-sm',
    },
    {
        key: 'inpchtNo',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.inpchtNo'),
        className: 'text-sm',
    },
    {
        key: 'wholeNo',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.wholeNo'),
        className: 'text-sm',
    },
    {
        key: 'compliteFlag',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.compliteFlag'),
        className: 'text-sm',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'applyDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.applyDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'aprvDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.aprvDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'applyDate',
        name: i18n.t('Rmis.pages.dischargeNote.dichargeNoteTableHeader.printDate'),
        className: 'text-sm',
        format: (value) => time2String(value, DATE_FORMAT),
    },
]

export const APRV_STATUS_LIST = [
    {
        name: '申請中',
        value: 'U',
    },
    {
        name: '不核准',
        value: 'N',
    },
    {
        name: '核准',
        value: 'Y',
    },
    {
        name: '取消核准',
        value: 'C',
    },
]
