import React, {useEffect, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup'
import {BaseInput} from '../../Input/BaseInput'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    InputTypeEnum,
    objectIsEmpty,
    stringIsEmpty,
    TimeslotEnum,
    ToastTypeEnum
} from 'edah_utils/dist'
import {FuzzySearchInput} from "../FuzzySearchInput";
import {Title} from "../utils";
import {rsisAddSpecialRole, rsisEditSpecialRole, rsisQueryClinicForSpacialAuth} from "../../../api/v1/RSIS"
import {usePopup} from "../PopupProvider"
import {t} from "i18next"

const titleWidth = '80px'


/**
 * 報備支援-特殊權限維護彈出視窗
 * @param doctorList {Array<Object>}
 * @param divList {Array<Object>}
 * @param item {Object|Null}
 * @param handleOnClose {function} 彈出視窗關閉方法
 * @param handleOnUpdate {function} 帶入父層傳入的欄位參數資料
 * @return {JSX.Element}
 */
const SpecialPermissionMaintain = ({doctorList, divList, item = null, handleOnClose, handleOnUpdate}) => {
    // 權限ID
    const [specialRoleId, setSpecialRoleId] = useState('')
    // 科別代號
    const [divNo, setDivNo] = useState('')
    // 醫師代號
    const [userNo, setUserNo] = useState('')
    // 時段
    const [mTimeSlot, setMTimeSlot] = useState('')
    // 看診日期
    const [mEncounterDate, setMEncounterDate] = useState('')
    // 診室號
    const [mClinicNo, setMClinicNo] = useState('')
    // 診室清單
    const [clinicList, setClinicList] = useState([])
    // 使用上下文
    const {showToast} = usePopup()

    /**
     * 取得診室名稱
     * @return {void}
     */
    const getQueryClinicForSpacialAuth = () => {
        if (mEncounterDate === '') return
        rsisQueryClinicForSpacialAuth({
            // 看診日期 require
            encounterDate: `${mEncounterDate} 00:00:00`,
            // 醫師代號 option
            doctorNo: userNo.indexOf(' ') > 0 ? userNo.split(' ')[0] : '',
            // 科別代號 option
            divNo: divNo.indexOf(' ') > 0 ? divNo.split(' ')[0] : '',
            // 時段 option，ALL不用填
            apn: mTimeSlot,
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // EDAQA-421 診室資料加入一筆空資料置最上方
                data.unshift({
                    clinicNo: '',
                    clinicName: '',
                    apn: '',
                    doctor: '',
                    divNo: '',
                })
                //診室代號存在
                if (mClinicNo) {
                    //診室物件
                    const clinicObj = data.find(d =>
                        // 文字上有是否空白部分
                        mClinicNo.indexOf(' ') > 0 ?
                            // 有空白部分
                            d.clinicNo === mClinicNo.split(' ')[0] && d.clinicName === mClinicNo.split(' ')[1] :
                            //沒空白部分
                            d.clinicNo === mClinicNo || d.clinicName === mClinicNo)
                    //物件存在時
                    setMClinicNo(clinicObj ? `${clinicObj.clinicNo} ${clinicObj.clinicName}` : '')
                }
                setClinicList(data)
            } else {
                setClinicList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 送出
     * @return {void}
     */
    const handleOnSubmit = () => {
        //判斷item是否有帶入資料，若有帶入則為編輯，若無則為新增
        if (item === null) addSpecialRole()
        else editSpecialRole()
    }

    /**
     * 時段改變時
     * @param e {Event}
     * @return {void}
     */
    const handleTimeslotOnChange = (e) => setMTimeSlot(e.target.value)

    /**
     * 時間改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEncounterDateOnChange = (e) => setMEncounterDate(e.target.value)

    /**
     * 取得提交資料
     * @return {Object}
     */
    const getSubmitData = () => ({
        // special role id
        specialRoleId: specialRoleId,
        // 部門代號
        divNo: divNo.indexOf(' ') > 0 ? divNo.split(' ')[0] : divNo,
        // 人員代號
        userNo: userNo.indexOf(' ') > 0 ? userNo.split(' ')[0] : userNo,
        // 時段
        apn: mTimeSlot,
        // 門診日期
        encountDate: mEncounterDate ? `${mEncounterDate} 00:00:00` : '',
        // 診間代號
        clinicNo: stringIsEmpty(mClinicNo) ? '' : (mClinicNo.indexOf(' ') > 0 ? mClinicNo.split(' ')[0] : mClinicNo),
        // 診間名稱 EDAQA-421 沒有存入診室名稱導致顯示錯誤
        clinicName: stringIsEmpty(mClinicNo) ? '' : (mClinicNo.indexOf(' ') > 0 ? mClinicNo.split(' ')[1] : mClinicNo)
    })

    /**
     * 新增特殊設定權限
     * @return {void}
     */
    const addSpecialRole = () => {
        const data = getSubmitData()
        if (!validData(data)) {
            showToast({message: '至少要選擇一個條件', type: ToastTypeEnum.Warning})
            return
        }
        rsisAddSpecialRole(data).then((res) => {
            //狀態/訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                showToast({message: msg, type: ToastTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 編輯特殊設定權限
     * @return {void}
     */
    const editSpecialRole = () => {
        const data = getSubmitData()
        if (!validData(data)) {
            showToast({message: '至少要選擇一個條件', type: ToastTypeEnum.Warning})
            return
        }
        // 傳送array資料
        rsisEditSpecialRole([data]).then((res) => {
            //狀態/訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                showToast({message: msg, type: ToastTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 資料是否有填最少一筆
     * @param data {Object}
     * @return {Boolean}
     */
    const validData = (data) => !stringIsEmpty(data.divNo) || !stringIsEmpty(data.userNo)
        || !stringIsEmpty(data.encountDate) || !stringIsEmpty(data.clinicNo)

    /**
     * 科別改變時
     * @param div {String} 科別
     * @return {void}
     */
    const handleOnDivOnChange = (div) => setDivNo(div)

    /**
     * 醫師改變時
     * @param doc {String} 科別
     * @return {void}
     */
    const handleDoctorOnChange = (doc) => setUserNo(doc)

    /**
     * 診室名稱改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicNoOnChange = (e) => setMClinicNo(e.target.value)

    /**
     * 監聽encountDate, userNo, divNo, apn改變時重新取得診室資料
     * @return {void}
     */
    useEffect(() => getQueryClinicForSpacialAuth(), [mEncounterDate, userNo, divNo, mTimeSlot])

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        //資料不為空
        if (!objectIsEmpty(item)) {
            setSpecialRoleId(item.specialRoleId)
            if (item.divNo && item.divName) {
                setDivNo(`${item.divNo} ${item.divName}`)
            } else {
                setDivNo("")
            }
            if (item.userNo && item.userName) {
                setUserNo(`${item.userNo} ${item.userName}`)
            } else {
                setUserNo("")
            }
            setMTimeSlot(item.apn)
            if (item.encountDate) {
                setMEncounterDate(item.encountDate.split(' ')[0])
            } else {
                setMEncounterDate("")
            }

            if (item.clinicNo) {
                // 設定診間值
                setMClinicNo(item.clinicNo)
            } else {
                setMClinicNo("")
            }
        }
    }, [])

    return (
        <BasePopup
            title="特殊權限維護"
            width="888px"
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='px-4 py-[10px]'>
                    <div className='w-full flex flex-col gap-4 justify-center items-center'>
                        {/*第一列*/}
                        <div className='flex flex-row gap-10'>
                            <FuzzySearchInput
                                title='科別代號'
                                titleExtendClass={'w-[80px]'}
                                value={divNo ? divNo : ''}
                                list={divList}
                                valueKey={["divNo", "divName"]}
                                handleValueChange={handleOnDivOnChange}
                                inputExtendClass={'w-[170px]'}/>
                            <FuzzySearchInput
                                title='醫師代號'
                                titleExtendClass={'w-[80px]'}
                                list={doctorList}
                                valueKey={['userNo', 'userName']}
                                value={userNo ? userNo : ''}
                                handleValueChange={handleDoctorOnChange}
                                inputExtendClass={'w-[170px]'}/>
                        </div>
                        {/*第二列*/}
                        <div className='flex flex-row items-center gap-10'>
                            <div className='flex flex-row items-center'>
                                <Title children={'時段'} extendClass={`w-[${titleWidth}] justify-end pr-4`}/>
                                <select
                                    className='w-[170px] pl-4 h-[40px] border-[1px] border-[#D9D9D9] rounded-[4px] ml-2'
                                    value={mTimeSlot}
                                    onChange={handleTimeslotOnChange}>
                                    <option value=''>全部</option>
                                    {/*早*/}
                                    <option value={TimeslotEnum.Morning}>
                                        {t('general.dateTime.timeslot.short.morning')}
                                    </option>
                                    {/*午*/}
                                    <option value={TimeslotEnum.Afternoon}>
                                        {t('general.dateTime.timeslot.short.afternoon')}
                                    </option>
                                    {/*晚*/}
                                    <option value={TimeslotEnum.Night}>
                                        {t('general.dateTime.timeslot.short.night')}
                                    </option>
                                </select>
                            </div>
                            <div className='flex flex-row items-center'>
                                <Title children={'看診日期'} extendClass={`w-[${titleWidth}]`}/>
                                <BaseInput
                                    className="w-[170px] ml-2 bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8"
                                    type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} max="9999-12-31"
                                    value={mEncounterDate}
                                    onChange={handleEncounterDateOnChange}/>
                            </div>
                        </div>
                        {/*第三列*/}
                        <div className='flex flex-row items-center mr-[300px]'>
                            <Title children={'診室名稱'} extendClass={`w-[${titleWidth}]`}/>
                            <select className='w-[170px] h-[40px] border-[1px] border-[#D9D9D9] rounded-[4px] ml-2 pl-4'
                                    value={mClinicNo}
                                    onChange={handleClinicNoOnChange}>
                                {
                                    arrayIsEmpty(clinicList)
                                        ? <option value=''></option>
                                        : clinicList.map((item, index) =>
                                            <option key={index} value={`${item.clinicNo} ${item.clinicName}`}>
                                                {`${item.clinicNo} ${item.clinicName}`}
                                            </option>
                                        )
                                }
                            </select>
                        </div>
                    </div>
                    {/* 分隔線 */}
                    <div className='w-full border-[1px] border-[#D9D9D9] mt-[10px]'></div>
                    {/* 按鈕 */}
                    <div className='flex flex-row justify-end gap-2 mt-[10px]'>
                        {/*取消按鈕*/}
                        <button className='w-[80px] h-[40px] bg-[#F0F0F0] rounded-[4px]' onClick={handleOnClose}>
                            {t('general.cancel')}
                        </button>
                        <button className='w-[80px] h-[40px] bg-[#2B6CB0] rounded-[4px] text-white'
                                onClick={handleOnSubmit}>送出
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default SpecialPermissionMaintain
