import React, {useState, useEffect} from "react";
import {BaseInput} from "../Input/BaseInput";
import {
    InputTypeEnum,
    arrayIsEmpty,
    multiTermFuzzySearchObjects,
    fuzzySearchObjects,
    stringIsEmpty
} from "edah_utils/dist";
import {Title} from "./utils";

/**
 * 模糊搜索輸入框
 * @param title {String} 標題
 * @param titleExtendClass {String} 外框樣式
 * @param list {Array<object>} 清單
 * @param valueKey {String[]}  鍵值陣列 [NO, Name]
 * @param value {String} NO值
 * @param handleValueChange {function} 更新事件
 * @param extendClass {String}  搜尋框額外樣式
 * @param inputExtendClass {String}
 * @param dropDownWidth {String}
 * @param mouseDownEvent {function}  選取選項時觸發的事件
 * @param disabled {boolean}
 * @return {JSX.Element}
 */
export const FuzzySearchInput = ({
                                     title = false,
                                     titleExtendClass = 'w-[119px]',
                                     list,
                                     valueKey,
                                     value, // Name
                                     handleValueChange,
                                     extendClass = 'w-64 top-8 -right-5',
                                     inputExtendClass = '',
                                     dropDownWidth = 'w-[225px]',
                                     mouseDownEvent = null,
                                     disabled = false
                                 }) => {
    // 顯示
    const [show, setShow] = useState(false);

    /**
     * 取得過濾後的清單
     * @return {Array<object>} 過濾後的清單
     */
    const getFilterList = () =>
        value.indexOf(' ') > 0
            ? multiTermFuzzySearchObjects(list, value.split(' '))
            : fuzzySearchObjects(list, value);

    /**
     * 輸入框改變時
     * @param e {Event} 事件
     * @returns {void}
     */
    const handleOnChange = (e) => handleValueChange(e.target.value)


    /**
     * 輸入框取得焦點時
     * @returns {void}
     */
    const handleOnFocus = () => setShow(true);

    /**
     * 輸入框失去焦點時
     * @returns {void}
     */
    const handleOnBlur = () => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    };

    /**
     * 選取選項時
     * @param {object} item 選取的選項
     * @returns {void}
     */
    const handleOnMouseDown = (item) => {
        handleValueChange(`${item[valueKey[0]]} ${item[valueKey[1]]}`);
    }

    return (
        <div className='flex flex-row items-center justify-between relative'>
            {title && <Title children={title} extendClass={`${titleExtendClass}`}/>}
            <BaseInput
                className={`${inputExtendClass} ml-2 bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8`}
                inputMode={InputTypeEnum.Search}
                type={InputTypeEnum.Text}
                value={value}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                disabled={disabled}
            />
            {
                show && !arrayIsEmpty(list) && (
                    <div
                        className={`absolute flex flex-col bg-white z-10 border-[1px] border-[#d4d4d8] rounded-md ${extendClass}`}>
                        <div className='max-h-[200px] transparent overflow-y-auto px-2.5'>
                            {
                                getFilterList().map((item, index) => (
                                        <div
                                            className={`${dropDownWidth} bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#181818] items-center hover:bg-[#BEE3F8]`}
                                            role={'button'}
                                            key={index}
                                            onMouseDown={() => handleOnMouseDown(item)}>
                                            <p>{`${item[valueKey[0]]} ${item[valueKey[1]]}`}</p>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};
