import replyIcon from '../../assets/images/icons/reply.svg'
import React, { useState } from 'react'
import {objectIsEmpty, stringIsEmpty, InputTypeEnum, arrayIsEmpty} from "edah_utils/dist"
import { cvisNotifyUnitAdapter, inpOpdToText } from './utils'
import { BaseInput } from '../Input/BaseInput'
import { ButtonStyle } from '../RSIS/utils'

/**
 * 自訂彈出視窗
 * @param id {String} Array Index
 * @param item {Object} 列表項目
 * @param handleItemOnClick {Function} 列表項目選取事件
 * @param handleItemButtonOnClick {Function} 列表項目按鈕點擊事件
 * @return {JSX.Element}
 */
function CvisListItem({
                        type,
                        index=null,
                        item,
                        selected=false,
                        slotButton=null,
                        handleItemOnClick=null,
                        handleOnSelected=null
                      }) {
    //列表項目索引
    const [id, setId] = useState(index)
    //勾選
    const [isSelected, setIsSelected] = useState()
    /**
     * 當列表項目被選取時觸發
     * @return {id}
     */
    const handleListItemOnFocus = () => {
      handleItemOnClick(id)
    }

    /**
     * 根據列表種類返回不同的列表項目
     * @return {JSX.Element}
     */
    const handleListItemType = () => {
      switch(type) {
        // 危急值通報紀錄清單
        case 'CvisNotifyRecordList':
          // 返回列表項目
          return (
              <li
                  key={index}
                  onClick={handleListItemOnFocus}
                  className='hover:cursor-pointer'
              >
                  <div>{item.cvisNotifyUnit ? cvisNotifyUnitAdapter(item.cvisNotifyUnit) : "無資料"}</div>
                  <div>{item.cvisNotifyItem ? item.cvisNotifyItem : "無資料"}</div>
                  <div>{item.cvisNotifyClass ? item.cvisNotifyClass : "無資料"}</div>
                  <div>{item.cvisNotifyDatetime ? item.cvisNotifyDatetime : "無資料"}</div>
                  <div>{item.encounterId ? item.patientId : "無資料"}</div>
                  <div>{item.patientId ? item.patientName : "無資料"}</div>
                  <div>{item.cvisResponseStatus}</div>
                  <div>{item.readStatus === true ? "Y" : "N"}</div>
                  <div>{item.cvisCancelFlag === true ? "Y" : "N"}</div>
                  {/* <div>{item.inpOpd ? inpOpdToText(item.inpOpd) : "無資料"}</div> */}
                  <div>{item.inpOpdName ? item.inpOpdName : "無資料"}</div>
                  <div>{item.divisionName ? item.divisionName : "無資料"}</div>
                  <div>{item.cvisNotifyObject ? item.cvisNotifyObject : "無資料"}</div>
                  <div>{item.userMobile ? item.userMobile : "無資料"}</div>
                  <div>{item.cvisNotifyId ? item.cvisNotifyId : "無資料"}</div>
              </li>
          )
          // 通報對象清單
          case 'CvisNotifyObjectList':
              // 判斷類別
          let notifyObject;
          if(!stringIsEmpty(item.cvisObjectType)){
            if (item.cvisObjectType === 'vs') notifyObject = "主治醫師"
            else if(item.cvisObjectType === 'r') notifyObject = "院醫師"
            else if(item.cvisObjectType === 'sc') notifyObject = "護理站電腦"
            else if(item.cvisObjectType === 'd') notifyObject = "職務代理人"
            else if(item.cvisObjectType === 'i') notifyObject = "感染管制師"
          }
          // 返回列表項目
          return (
            <li key={index}>
              <div>{notifyObject}</div>
              <div>{!stringIsEmpty(item.cvisNotifyObject) && !stringIsEmpty(item.cvisNotifyObjectName) ?
                    `${item.cvisNotifyObject} : ${item.cvisNotifyObjectName}` : "無資料"}
              </div>
              <div>{!stringIsEmpty(item.userMobile) ? item.userMobile : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisNotifyDatetime) ? item.cvisNotifyDatetime : "無資料"}</div>
              <div>{!stringIsEmpty(item.readDatetime) ? item.readDatetime : "無資料"}</div>
            </li>
          )
        // 回覆紀錄
        case 'CvisNotifyResponseList':
          return (
            <li key={index}>
              <div>{!stringIsEmpty(item.patientId) ? item.patientId : "無資料"}</div>
              <div>{!stringIsEmpty(item.patientName) ? item.patientName : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisResponseUserName) ? item.cvisResponseUserName : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisResponseDatetime) ? item.cvisResponseDatetime : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisResponseUser) ? item.cvisResponseUser : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisProcessStatus) ? item.cvisProcessStatus : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisProcessContent) ? item.cvisProcessContent : "無資料"}</div>
            </li>
          )
        case 'CvisNotifyRenotifyList':
          return (
            <li key={index}>
              <div>{!stringIsEmpty(item.cvisRenotifyDatetime) ? item.cvisRenotifyDatetime : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisNotifyId)? item.cvisNotifyId : "無資料" }</div>
              <div>{!stringIsEmpty(item.cvisNotifyUser) ? item.cvisNotifyUser : "無資料"}</div>
              <div>{!stringIsEmpty(item.cvisNotifyObject) ? item.cvisNotifyObject : "無資料"}</div>
              <div>{!stringIsEmpty(item.statusCode) ? item.statusCode : "無資料"}</div>
            </li>
          )
        case 'InflectionControlRecordList':
          return (
              <li
                  key={index}
                  onClick={handleListItemOnFocus}
                  className='hover:cursor-pointer'
              >
                  <div>{item.cvisNotifyUnit ? cvisNotifyUnitAdapter(item.cvisNotifyUnit) : "無資料"}</div>
                  <div>{item.cvisNotifyItem ? item.cvisNotifyItem : "無資料"}</div>
                  <div>{item.cvisNotifyClass ? item.cvisNotifyClass : "無資料"}</div>
                  <div>{item.cvisNotifyDatetime ? item.cvisNotifyDatetime : "無資料"}</div>
                  <div>{item.patientId ? item.patientId : "無資料"}</div>
                  <div>{item.patientName ? item.patientName : "無資料"}</div>
                  <div>{item.readStatus === true ? "Y" : "N"}</div>
                  <div>{item.cvisCancelFlag === true ? "Y" : "N"}</div>
                  {/* <div>{item.inpOpd ? inpOpdToText(item.inpOpd) : "無資料"}</div> */}
                  <div>{item.inpOpdName ? item.inpOpdName : "無資料"}</div>
                  <div>{item.divisionName ? item.divisionName : "無資料"}</div>
                  <div>{item.cvisNotifyObject ? item.cvisNotifyObject : "無資料"}</div>
                  <div>{item.userMobile ? item.userMobile : "無資料"}</div>
                  <div>{item.cvisNotifyId ? item.cvisNotifyId : "無資料"}</div>
              </li>
          )
          case 'TimeoutReplyQuery':

              // 判斷回覆時效 A級:1小時 B級:8小時 C級:24小時
          const replytime = () =>{
            if(!stringIsEmpty(item.cvisNotifyClass)){
              switch (item.cvisNotifyClass) {
                case 'A':
                  return "1";
                case 'B':
                  return "8";
                case 'C':
                  return "24";
                default:
                  return "無資料";
              }
            }
          };

          // 根據回覆時效計算回覆期限
          const adjustTime = (timeString, level) => {
            const date = new Date(timeString);
          
            switch (level) {
              case 'A':
                date.setHours(date.getHours() + 1);
                break;
              case 'B':
                date.setHours(date.getHours() + 8);
                break;
              case 'C':
                date.setHours(date.getHours() + 24);
                break;
              default:
                throw new Error('Invalid level');
            }
          
            const formatDate = (date) => {
              const pad = (num) => (num < 10 ? '0' + num : num);
              return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
            };
          
            return formatDate(date);
          }

          return (
            <li key={index}>
              {/* 等級 */}
              <div>{!stringIsEmpty(item.cvisNotifyClass) ? item.cvisNotifyClass : "無資料"}</div> 
              {/* 發送日期時間 */}
              <div>{!stringIsEmpty(item.cvisNotifyDatetime ) ? item.cvisNotifyDatetime : "無資料"}</div>
              {/* 回覆時效 */}
              <div>{replytime()}</div>
              {/* 回覆期限 */}
              <div>{!stringIsEmpty(item.cvisNotifyDatetime && !stringIsEmpty(item.cvisNotifyClass)) ? adjustTime(item.cvisNotifyDatetime, item.cvisNotifyClass) : "無資料"}</div>
              {/* 病歷號 */}
              <div>{!stringIsEmpty(item.patientId) ? item.patientId : "無資料"}</div>
              {/* 姓名 */}
              <div>{!stringIsEmpty(item.patientName) ? item.patientName : "無資料"}</div>
              {/* 主治醫師 */}
              <div>{!stringIsEmpty(item.cvisNotifyUser) ? item.cvisNotifyUser : "無資料"}</div>
              {/* 醫師姓名 */}
              <div>{!stringIsEmpty(item.cvisNotifyUserName) ? item.cvisNotifyUserName : "無資料"}</div>
              {/* 通報內容 */}
              <div>{!stringIsEmpty(item.cvisNotifyContent) ? item.cvisNotifyContent : "無資料"}</div>
            </li>
          )
        case 'ClosureMonitorList':
          return(
            <li key={index}>
              {/* 通報單位 */}
              <div>{!stringIsEmpty(item.cvisNotifyUnit) ? cvisNotifyUnitAdapter(item.cvisNotifyUnit) : "無資料"}</div>
              {/* 通報項目 */}
              <div>{!stringIsEmpty(item.cvisNotifyItem) ? item.cvisNotifyItem : "無資料"}</div>
              {/* 事件等級 */}
              <div>{!stringIsEmpty(item.cvisNotifyClass) ? item.cvisNotifyClass : "無資料"}</div>
              {/* 通報日期時間 */}
              <div>{!stringIsEmpty(item.cvisNotifyDatetime) ? item.cvisNotifyDatetime : "無資料"}</div>
              {/* 病歷號 */}
              <div>{!stringIsEmpty(item.patientId) ? item.patientId : "無資料"}</div>
              {/* 姓名 */}
              <div>{!stringIsEmpty(item.patientName) ? item.patientName : "無資料"}</div>
              {/* 閉環狀態 */}
              <div>{!stringIsEmpty(item.cvisResponseStatus) ? item.cvisResponseStatus : "無資料"}</div>
              <div className='hover:cursor-pointer text-primary' onClick={handleListItemOnFocus}>
                閱讀
              </div>
            </li>
          )
        case 'ClosureMonitorPopupList':
          return(
            <li key={index}>
              {/* 通報日期時間 */}
              <div>{!stringIsEmpty(item.cvisRenotifyDatetime) ? item.cvisRenotifyDatetime : "無資料"}</div>
              {/* 單號 */}
              <div>{!stringIsEmpty(item.encounterId) ? item.encounterId : "無資料"}</div>
              {/* 通報人員 */}
              <div>{!stringIsEmpty(item.cvisNotifyUser) ? item.cvisNotifyUser : "無資料"}</div>
              {/* 通報對象 */}
              <div>{!stringIsEmpty(item.cvisNotifyObject) ? item.cvisNotifyObject : "無資料"}</div>
            </li>
          )
        case 'AwaitReplyList':
            return(
              <li key={index}>
                <div className='hover:cursor-pointer text-primary' onClick={handleListItemOnFocus}>
                  <img src={replyIcon} alt="replyIcon" role="button" className="w-6 h-6"></img>
                  <p>回覆</p>
                </div>
                {/*通報項目*/}
                <div>{!stringIsEmpty(item.cvisNotifyItem) ? item.cvisNotifyItem : "無資料"}</div>
                {/*事件等級*/}
                <div>{!stringIsEmpty(item.cvisNotifyClass) ? item.cvisNotifyClass : "無資料"}</div>
                {/*通知日期時間*/}
                <div>{!stringIsEmpty(item.cvisNotifyDatetime) ? item.cvisNotifyDatetime : "無資料"}</div>
                {/*病歷號*/}
                <div>{!stringIsEmpty(item.patientId) ? item.patientId : "無資料"}</div>
                {/*姓名*/}
                <div>{!stringIsEmpty(item.patientName) ? item.patientName : "無資料"}</div>
                {/*回復狀態*/}
                <div>{!stringIsEmpty(item.cvisReplyStatus) ? item.cvisReplyStatus : "無資料"}</div>
                {/*回復期限*/}
                <div>{!stringIsEmpty(item.cvisReplyEndDatetime) ? item.cvisReplyEndDatetime : "無資料"}</div>
                {/*狀態*/}
                <div>{!stringIsEmpty(item.statusCode) ? item.statusCode : "無資料"}</div>
              </li>
            )
          case 'AssignmentPopupReplyRecordList':
              let processStatus
              switch(item.cvisProcessStatus) {
                case '0':
                  processStatus = '通知病患或家屬'
                  break
                case '1':
                  processStatus = '持續觀察'
                  break
                case '2':
                  processStatus = '病人通知不到'
                  break
                case '3':
                  processStatus = '通知主治醫師'
                  break
                case '4':
                  processStatus = '其他'
                  break
                case '5':
                  processStatus = '處置說明'
                  break
                default:
                  return '無資料'
              }
            return (
              <li key={index}>
                {/* 0601 陳坤何大哥說依照SRS上的欄位為主 */}
                {/* 病歷號 */}
                <div>{!stringIsEmpty(item.patientId) ? item.patientId : "無資料"}</div>
                {/* 姓名 */}
                <div>{!stringIsEmpty(item.patientName) ? item.patientName : "無資料"}</div>
                {/* 主治醫師 */}
                <div>{!stringIsEmpty(item.doctor) ? item.doctor : "無資料"}</div>
                {/* 回覆日期時間 */}
                <div>{!stringIsEmpty(item.cvisPesponseDatetime) ? item.cvisPesponseDatetime : "無資料"}</div>
                {/* 回覆人員 */}
                <div>{!stringIsEmpty(item.cvisResponseUserName) ? item.cvisResponseUserName : "無資料"}</div>
                {/* 處理狀態 */}
                <div>{processStatus}</div>
                {/* 處理說明 */}
                <div>{!stringIsEmpty(item.cvisProcessContent) ? item.cvisProcessContent : "無資料"}</div>
              </li>
            )
            case 'APopup1':
              return (
                <li key={index} className='hover:cursor-pointer' onClick={()=>handleItemOnClick(index)}>
                  {/* A級危急值逾時回覆再通知 */}
                  {/* 發送日期時間 */}
                  <div>{!stringIsEmpty(item.cvisNotifyDatetime)?item.cvisNotifyDatetime:"無資料"}</div>
                  {/* 病歷號 */}
                  <div>{!stringIsEmpty(item.patientId)?item.patientId:"無資料"}</div>
                  {/* 姓名 */}
                  <div>{!stringIsEmpty(item.patientName)?item.patientName:"無資料"}</div>
                  {/* 主治醫師 */}
                  <div>{!stringIsEmpty(item.cvisNotifyObject) ? item.cvisNotifyObject :"無資料"}</div>
                  {/* 醫師姓名 */}
                  <div>{!stringIsEmpty(item.cvisNotifyObjectName)?item.cvisNotifyObjectName:"無資料"}</div>
                  {/* 護理站代號 */}
                  <div>{!stringIsEmpty(item.nsNo)?item.nsNo:'無資料'}</div>
                  {/* 護理站GSM */}
                  <div>{!stringIsEmpty(item.nsNoGSM)?item.nsNoGSM:'無資料'}</div>
                  {/* 值班人員 */}
                  <div>{!stringIsEmpty(item.cvisNotifyObjectName)?`不確定值班人員是api的哪個值`:'不確定值班人員是api的哪個值'}</div>
                  {/* 即時床位 */}
                  <div>{!stringIsEmpty(item.bedNo)?item.bedNo:"無資料"}</div>
                  {/* 通報內容 */}
                  <div>{!stringIsEmpty(item.cvisNotifyContent)?item.cvisNotifyContent:"無資料"}</div>

                </li>
              )
            case 'APopup':
              return(
                <li key={index}>
                  {/* 再通報日期時間 */}
                  <div>{!stringIsEmpty(item.cvisRenotifyDatetime) ? item.cvisRenotifyDatetime : "無資料"}</div>
                  {/* 單號 */}
                  <div>{!stringIsEmpty(item.encounterId) ? item.encounterId : "無資料"}</div>
                  {/* 通報人員 */}
                  <div>{!stringIsEmpty(item.cvisNotifyUser) ? item.cvisNotifyUser : "無資料"}</div>
                  {/* 通報對象 */}
                  <div>{!stringIsEmpty(item.cvisNotifyObject) ? item.cvisNotifyObject : "無資料"}</div>
                </li>
              )
            case 'RSISMasterQuery':
              return (
                <li key={id}>
                  {/* 空值 */}
                  <div></div>
                  {/* 申請單號、院區名稱 */}
                  <div>202312000110 義大</div>
                  {/* 申請人 */}
                  <div>王大年</div>
                  {/* 支援機構代號、名稱 */}
                  <div>2001 高雄醫學大學</div>
                  {/* 支援科別 */}
                  <div>01內科</div>
                  {/* 報備期間、開始-結束日期 */}
                  <div>2024/01/01-2024/01/01 08:00-18:00</div>
                  {/* 狀態別 */}
                  <div>申請</div>
                  {/* 空值 */}
                  <div></div>
                  {/* 衛福部案號 */}
                  <div>23001011</div>
                  {/* 匯出編號 */}
                  <div>23001011</div>
                </li>
              )
              case 'RSISCaseManagementTable':
              return (
                <li key={id}>
                  {/* 選擇 */}
                  <div>
                    <input className="w-5 h-5"type="checkbox" checked={isSelected}/>
                  </div>
                  {/* 根據使用者權限顯示對應按鈕 */}
                  <div>{slotButton}</div>
                  {/* 申請單號、院區名稱 */}
                  <div><p>202312000110<br />義大</p></div>
                  {/* 申請人 */}
                  <div>王大年</div>
                  {/* 支援機構代號、名稱 */}
                  <div><p>2001<br />高雄醫學大學</p></div>
                  {/* 支援科別 */}
                  <div>01內科</div>
                  {/* 報備期間、開始-結束日期 */}
                  <div><p>2024/01/01-2024/01/01<br />08:00-18:00</p></div>
                  {/* 狀態別 */}
                  <div>
                  <select className="w-[102px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300">
                    <option value="">申請</option>
                    <option value="2">編輯</option>
                    <option value="1">刪除</option>
                  </select>
                  </div>
                  {/* 空值 */}
                  <div></div>
                  {/* 衛福部案號 */}
                  <div>
                    <BaseInput
                      type={InputTypeEnum.Text}
                      value='23001011'
                      onChange={()=>{}}
                      className="w-[102px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                    />
                  </div>
                  {/* 匯出編號 */}
                  <div>
                    <BaseInput
                        type={InputTypeEnum.Text}
                        value={""}
                        onChange={()=>{}}
                        className="w-[102px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                      />
                  </div>
                  {/* 備註 */}
                  <div>
                    <BaseInput
                        type={InputTypeEnum.Text}
                        value={""}
                        onChange={()=>{}}
                        className="w-[102px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
                      />
                  </div>
                </li>
              )
        default:
          return null
      }
    }

  return handleListItemType()
}

export default CvisListItem