import React from 'react'
import SpecialPermissionSetting from '../../components/RSIS/SpecialPermissionSetting/SpecialPermissionSetting'
import { PopupProvider } from '../../components/RSIS/PopupProvider'

/**
 * 特殊權限設定
 * @returns {JSX.Element}
 * */
function SpecialPermissionSettingLayout() {
  return (
    <PopupProvider>
      <SpecialPermissionSetting />
    </PopupProvider>
  )
}

export default SpecialPermissionSettingLayout
