import React, {useEffect, useMemo, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup';
import {isOwnerUser, Title} from '../utils';
import {BaseInput} from '../../Input/BaseInput';
import ChangeRecordQuery from './QueryChangeRecord';
import SupportDateChoice from "./SupportDateChoice";
import {USER_ROLE} from "../userRole";
import {
    getLocalStorage,
    InputTypeEnum,
    objectIsEmpty,
    RsisStepStatusEnum, stringIsEmpty,
    time2String
} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum,
} from "edah_utils/dist"

import {t} from 'i18next'

// import API
import {
    rsisEditMaster,
    rsisQueryCodeValue,
    rsisQueryAgentByUserNo, rsisQueryBaseData, rsisMailConfirm
} from '../../../api/v1/RSIS';

import {FuzzySearchInput} from "../FuzzySearchInput";
import QueryPersonList from "./QueryPersonList";
import QuerySupportOrg from "./QuerySupportOrg";
import {checkDoctorLicence} from "../../../api/v1/Menu";

const extendClass = 'w-[160px] justify-end'

// 取得輸入框樣式
const getInputClassName = (width = 186) => `w-[${width}px] h-10 pl-4 bg-white text-black rounded-[6px] border border-gray-300`

/**
 * 報備支援收案確認Popup
 * @param {string} type 彈窗類型
 * @param {object} data 報備支援資料
 * @param {array} doctorList 醫師清單
 * @param {array} orgList 機構清單
 * @param {array} deptList 承辦部門清單
 * @param {array} zoneList 院區清單
 * @param {array} ownerList 承辦人員清單
 * @param {function} handleOnUpdate 更新列表事件
 * @param {function} handleOnClose 關閉彈窗事件
 * @returns
 */
function EditModal({
                       type = 'EDIT',
                       data,
                       doctorList,
                       orgList,
                       deptList,
                       zoneList,
                       handleOnUpdate,
                       handleOnClose
                   }) {
    // 角色
    const [userRole, setUserRole] = useState(data.role)
    // 批次處理資料清單
    const [dataList, setDataList] = useState([])
    // 代理人下拉清單
    const [agentUserList, setAgentUserList] = useState([])
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState(data.purposeCode ? [data.purposeCode.split(' ').filter(Boolean)] : [])
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo ? data.supportUserNo : '')
    // 支援人員名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName ? data.supportUserName : '')
    // 執登科別編號
    const [divNo, setDivNo] = useState(data.divNo)
    // 執登科別名稱
    const [divName, setDivName] = useState(data.divName)
    // 證書字號
    const [licenceId, setLicenceId] = useState(data.licenceId)
    // 執登機構編號
    const [licenceKind, setLicenceKind] = useState(data.licenceKind)
    // 執登機構名稱
    const [licenceKindName, setLicenceKindName] = useState(data.licenceKindName)
    // 值類別
    const [hospNo, setHospNo] = useState(data.hospNo)
    // 值類別名稱
    const [hospName, setHospName] = useState(data.hospName)


    /** 報備支援內容欄位 */
        // 星期幾
    const [day, setDay] = useState(data.day ? data.day : '')
    // 支援機構Pkey
    const [supportOrgPkey, setSupportOrgPkey] = useState(data.supportOrgPkey ? data.supportOrgPkey : '')
    // 支援機構字串
    const [supportOrgStr, setSupportOrgStr] = useState('')
    // 機構代碼
    const [supportOrgNo, setSupportOrgNo] = useState(data.supportOrgNo ? data.supportOrgNo : data.supportOrgNo)
    // 機構名稱
    const [supportOrgName, setSupportOrgName] = useState(data.supportOrgName ? data.supportOrgName : '')
    // 申請單號
    const [supportNo, setSupportNo] = useState(data.supportNo ? data.supportNo : '')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo ? data.supportDivNo : '')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data.supportDivName ? data.supportDivName : '')
    // 機構類別
    const [supportOrgType, setSupportOrgType] = useState(data.supportOrgType ? data.supportOrgType : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : []);
    // 支援目的門住
    const [purposeCodeExt, setPurposeCodeExt] = useState(!stringIsEmpty(data.purposeCodeExt) ? data.purposeCodeExt : '1')
    // 變更原因
    const [supportModifyReasonDoc, setSupportModifyReasonDoc] = useState(data.supportModifyReasonDoc)
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門
    const [ownerDeptNo, setOwnerDeptNo] = useState(data.ownerDeptNo ? data.ownerDeptNo : '')
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState(data.ownerDeptName ? data.ownerDeptName : '')
    // 代理人員字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState(data.agentUserNo ? data.agentUserNo : '')
    // 代理人員名稱
    const [agentUserName, setAgentUserName] = useState(data.agentUserName ? data.agentUserName : '')
    // 性質
    const [supportProperties, setSupportProperties] = useState(data.supportProperties ? data.supportProperties : '')
    // 執行內容
    const [executeContent, setExecuteContent] = useState(data.executeContent ? data.executeContent : '')
    // 備註
    const [memo, setMemo] = useState(data.memo ? data.memo : '')
    // 支援起始日期
    const [itemStartDate, setItemStartDate] = useState(data.itemStartDate ? data.itemStartDate : '')
    // 支援起始時間
    const [itemStartTime, setItemStartTime] = useState(data.itemStartTime ? data.itemStartTime : '')
    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState(data.itemEndDate ? data.itemEndDate : '')
    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState(data.itemEndTime ? data.itemEndTime : '')
    // 院區
    const [zone, setZone] = useState(data.zone ? data.zone : '')

    // 彈窗
    // 顯示醫師人員搜尋彈窗
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 顯示支援機構彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)

    // 使用上下文
    const {
        showToast,
        showChangeRecordModal,
        setShowChangeRecordModal,
        setShowSupportDateChoice,
        showSupportDateChoice,
    } = usePopup()

    /**
     * 支援科別變更時
     * @param item {string} 支援科別
     * @returns {void}
     */
    const handleSupportDivNoChange = (item) => {
        setSupportDivStr(item)
        setSupportDivNo(item.split(' ')[0])
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 承辦部門變更時
     * @param item {string} 承辦部門
     * @returns {void}
     */
    const handleOwnerDeptNoChange = (item) => {
        setOwnerDeptStr(item)
        setOwnerDeptNo(item.split(' ')[0])
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員變更時
     * @param item {string} 代理人員
     * @returns {void}
     */
    const handleAgentUserNoChange = (item) => {
        setAgentUserStr(item)
        setAgentUserNo(item.split(' ')[0])
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 新增報備支援內容
     * @returns {void}
     */
    const handleFormSave = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請增加支援期間時間', type: ToastTypeEnum.Warning})
            return
        }

        // 檢查支援機構是否有值
        if(!supportOrgNo) {
            showToast({message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        // 檢查支援科別是否有值
        if(!supportDivNo) {
            showToast({message: `支援者：${supportUserName}，「支援科別」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        const data = getSendData()
        saveEditMaster(data)
    }

    /**
     * 更新列表項目
     * @returns {void}
     */
    const handleUpdateDataList = (data) => setDataList(data)

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        // 取得醫師清單
        getDoctorData(item.userNo)
        // 取得代理人員
        getAgentUserList(item.userNo)
        // 設定支援醫師代碼/名稱
        setSupportUserNo(item.userNo)
        // 設定支援醫師/名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 選取支援機構選項時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleOrgOptionOnClick = (item) => {
        // 設定支援機構代碼
        setSupportOrgNo(item.orgNo)
        // 設定支援機構名稱
        setSupportOrgName(item.orgName)
        // 關閉彈窗
        setShowOrgDropdown(false)
    }

    /**
     * 查詢變更紀錄點擊時
     * @returns {void}
     */
    const handleQueryChangeRecordOnClick = () => {
        if (supportNo) setShowChangeRecordModal(true)
        else showToast({message: '申請單號為空，尚未有資料', type: ToastTypeEnum.Warning})
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                if (prevCodes.includes(codeNo)) {
                    return prevCodes.filter(code => code !== codeNo);
                } else {
                    return [...prevCodes, codeNo];
                }
            });
        };

        return (
            <div className="grid grid-cols-5 gap-4 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-1">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id={item.codeNo}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="purpose"
                                        checked={purposeCode.includes(item.codeNo)}
                                        onChange={() => handlePurposeChange(item.codeNo)}
                                    />
                                    <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                                </div>
                                <select
                                    className="ml-2 pl-4 w-[96px] h-10 bg-white text-black rounded-[6px] border border-gray-300"
                                    value={purposeCodeExt}
                                    onChange={(e) => setPurposeCodeExt(e.target.value)}
                                >
                                    {renderSubPurposeOptions()}
                                </select>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="flex items-center space-x-2 col-span-1">
                                <input
                                    id={item.codeNo}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="purpose"
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                />
                                <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    /**
     * 渲染子支援目的選項
     * @return {JSX.Element}
     */
    const renderSubPurposeOptions = () => {
        return subPurposeOptions.map((item) => (
            <option key={item.codeNo} value={item.codeNo}>{item.codeValue1}</option>
        ))
    }

    /**
     * 儲存修改後的報備支援內容
     * @param data {Array<Object>} 資料
     * @return {void}
     */
    const saveEditMaster = (data) => {
        if (type === 'EDIT') {
            // 執行修改報備支援內容
            rsisEditMaster(data).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '修改成功', type: ToastTypeEnum.Success})
                    handleOnUpdate()
                    handleOnClose()
                } else {
                    showToast({message: msg, type: ToastTypeEnum.Error})
                }
            })
        } else if (type === 'IMPORT') {
            // 取得data array[0]的資料
            handleOnUpdate(data[0])
        }
    }

    /**
     * 承辦人員收案確認
     * @return {void}
     */
    const handleMailConfirm = () => {
        const data = getSendData()
        // 更新收案確認資料
        const updatedData = data.map(item => ({
            ...item,
            // 收案確認人員編號
            confirmUserNo: getLocalStorage('userno'),
            // 收案確認日期
            confirmDatetime: time2String(new Date(), 'YYYY-MM-DD HH-mm-ss').split('_')[0].replace(/(\d{4}-\d{2}-\d{2}) (\d{2})-(\d{2})-(\d{2})/, "$1 $2:$3:$4")
        }))
        // 執行收案確認
        rsisMailConfirm(updatedData).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '收案成功', type: ToastTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }



    /**
     * 建構送出資料
     * @returns {Array<Object>} 送出資料
     */
    const getSendData = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請新增報備支援內容', type: ToastTypeEnum.Error})
            return
        }
        // 根據支援明細資料產生對應比數的資料
        const sendData = dataList.map(item => {
            // 取得機構物件
            const org = orgList.find(o => o.orgNo === supportOrgNo && o.orgName === supportOrgName)
            if (org) {
                return {
                    supportNo: supportNo,
                    itemVersion: data.itemVersion,
                    supportUserNo: supportUserNo,
                    supportOrgPkey: org.orgPkey,
                    supportOrgName: supportOrgName,
                    supportDivNo: supportDivNo,
                    supportDivName: supportDivName,
                    purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode, // 是否為Array，若是則轉換為字串
                    purposeCodeExt: purposeCodeExt,
                    itemStartDate: item.startDate ? `${item.startDate} 00:00:00` : '',
                    itemStartTime: item.startTime.includes(':') ? item.startTime.split(':')[0] + item.startTime.split(':')[1] : item.startTime,
                    itemEndDate: item.endDate ? `${item.endDate} 00:00:00` : '',
                    itemEndTime: item.endTime.includes(':') ? item.endTime.split(':')[0] + item.endTime.split(':')[1] : item.endTime,
                    supportProperties: supportProperties,
                    executeContent: executeContent,
                    memo: memo,
                    agentUserNo: agentUserNo,
                    stepStatus: item.stepStatus ? item.stepStatus : data.stepStatus,
                    supportModifyStatus: item.supportModifyStatus ? item.supportModifyStatus : data.supportModifyStatus,
                    supportModifyDatetime: item.supportModifyDatetime ? item.supportModifyDatetime : data.supportModifyDatetime,
                    supportModifyReasonCode: item.supportModifyReasonCode ? item.supportModifyReasonCode : data.supportModifyReasonCode,
                    supportModifyReasonDoc: item.supportModifyReasonDoc ? item.supportModifyReasonDoc : data.supportModifyReasonDoc,
                    sendFileName: item.sendFileName ? item.sendFileName : data.sendFileName,
                    sendCaseNo: item.sendCaseNo ? item.sendCaseNo : data.sendCaseNo,
                    sendDatetime: item.sendDatetime ? item.sendDatetime : data.sendDatetime,
                    applyType: item.applyType ? item.applyType : data.applyType,
                    zone: item.zone ? item.zone : data.zone,
                    effFlag: true,
                    ownerDeptNo: ownerDeptNo,
                    changeFieldsDoc: item.changeFieldsDoc ? item.changeFieldsDoc : data.changeFieldsDoc,
                    confirmUserNo: item.confirmUserNo ? item.confirmUserNo : data.confirmUserNo,
                    confirmDatetime: item.confirmDatetime ? item.confirmDatetime : data.confirmDatetime,
                }
            }
        })
        // 若從整批匯入編輯資料
        if (type === 'IMPORT') {
            // 假如有day的資料，則將其加入到sendData[0]中
            if (day) sendData[0].day = day
            sendData[0].supportUserNo = supportUserNo
            sendData[0].supportUserName = supportUserName
            sendData[0].ownerDeptNo = ownerDeptNo
            sendData[0].ownerDeptName = ownerDeptName
            sendData[0].supportOrgNo = supportOrgNo
            sendData[0].supportOrgName = supportOrgName
            sendData[0].supportDivNo = supportDivNo
            sendData[0].supportDivName = supportDivName
            sendData[0].agentUserNo = agentUserNo
            sendData[0].agentUserName = agentUserName
        }
        return sendData

    }

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @param userNo {string} 員工/醫師代碼
     * @returns {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @retunr {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定執豋資料
                if(data){
                    setDivNo(data.divNo)
                    setDivName(data.divName)
                    setLicenceId(data.licenceId)
                    setLicenceKind(data.licenceKind)
                    setLicenceKindName(data.licenceKindName)
                    setHospNo(data.hospNo)
                    setHospName(data.hospName)
                }else{
                    // 若無執登資料，則設定空值
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /** EDAQA-360
     * 取得DIVISION中 SUPPORT_DIV_NO不為空的資料
     * @returns {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData().then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data.supportDivList)
            } else {
                setAllDivisionList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 設定報備支援資料
        if (!objectIsEmpty(data)) {
            setSupportDivStr(`${supportDivNo} ${supportDivName}`)
            // 承辦部門名稱
            let dn = ''
            // 如果是整批匯入作業時使用編輯
            if (type === 'IMPORT') {
                // 如果有承辦部門No，根據承辦部門No取得承辦部門名稱
                if (data.ownerDeptNo) {
                    dn = deptList.find(item => item.deptNo === data.ownerDeptNo).deptName
                }
                setOwnerDeptName(dn)
            }
            setOwnerDeptStr(`${ownerDeptNo ? ownerDeptNo : ''} ${ownerDeptName ? ownerDeptName : dn}`)
            setAgentUserStr(`${agentUserNo ? agentUserNo : ''} ${agentUserName ? agentUserName : ''}`)
            setSupportOrgStr(`${supportOrgNo ? supportOrgNo : ''} ${supportOrgName ? supportOrgName : ''}`)
            // 取得醫師執登資料
            getDoctorData(data.supportUserNo)
            // 取得支援日期資料
            if (!objectIsEmpty(data)) {
                const newDataItems = [{
                    ...data,
                    startDate: time2String(data.itemStartDate, 'YYYY-MM-DD'),
                    endDate: time2String(data.itemEndDate, 'YYYY-MM-DD'),
                    startTime: data.itemStartTime,
                    endTime: data.itemEndTime,
                }]
                setDataList(newDataItems)
            }
        }
        // 取得支援目的選項
        getPurposeOptions()
        // 取得代理人員
        getAgentUserList(data.supportUserNo)
    }, [])

    // 監聽當支援期間明細日期資料更新時
    useEffect(() => {
        if (dataList.length > 0) {
            setItemStartDate(dataList[0].startDate)
            setItemEndDate(dataList[0].endDate)
            setItemStartTime(dataList[0].startTime)
            setItemEndTime(dataList[0].endTime)
        } else {
            setItemStartDate('')
            setItemEndDate('')
            setItemStartTime('')
            setItemEndTime('')
        }
    }, [dataList])

    useEffect(() => {
        console.log(data)
    }, [data]);

    return (
        <>
            <BasePopup
                title="報備支援修改"
                width="1653px"
                closePopupButtonOnClick={handleOnClose}
                content={
                    <div className='px-6'>
                        <div className='flex flex-row justify-end w-full gap-2'>
                            <button
                                className={`ml-4 flex items-center justify-center font-base px-4 h-10 rounded-[6px] border-[#D4D4D8] border-[2px] bg-[#FAFAFA]`}
                                onClick={handleQueryChangeRecordOnClick}
                            >
                                查詢變更紀錄
                            </button>
                            <select
                                className='w-[132px] pl-[12px] h-10 bg-white text-black rounded-[6px] border border-gray-300'
                                disabled onChange={() => {
                            }}
                            >
                                <option
                                    value={getLocalStorage('campus')}>{t(`campus.${getLocalStorage('campus')}`)}</option>
                            </select>
                            <span
                                className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                        </div>
                        {/* 支援人員 */}
                        <div className='flex flex-col'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    支援人員
                                </h3>
                            </div>
                            <div className='flex flex-col gap-2 mb-6'>
                                {/* 員工/醫師代碼 */}
                                <div className='flex flex-row gap-4 relative'>
                                    <Title extendClass={extendClass}>{'員工/醫師代碼：'} </Title>
                                    <BaseInput className={getInputClassName()}
                                               inputMode={InputTypeEnum.Text}
                                               type={InputTypeEnum.Text}
                                               value={`${supportUserNo} ${supportUserName}`}
                                               onClick={() => setShowDoctorPopup(true)}
                                               onChange={() => {
                                               }}
                                    />
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    {/* 執登機構 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'執登機構：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}</p>
                                    </div>
                                    {/*職類別*/}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'職類別：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}</p>
                                    </div>
                                    { /* 執登科別 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'執登科別：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}</p>
                                    </div>
                                    {/* 證書字號 */}
                                    <div className='flex flex-row items-center'>
                                        <Title children={'證書字號：'} extendClass={extendClass}/>
                                        <p className='flex items-center bg-white min-w-[160px] h-[42px] px-2 text-black pl-4 border-gray-300'>{!stringIsEmpty(licenceId) ? licenceId : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        {/* 報備支援內容 */}
                        <div className='flex flex-col mb-6 mt-6'>
                            <div className="flex flex-row w-full">
                                <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                    報備支援內容
                                </h3>
                            </div>
                            <div className='w-full flex flex-col gap-2'>
                                {/* 機構代碼/名稱 */}
                                <div className='flex justify-between items-center'>
                                    <div className='flex flex-row items-center gap-4 relative'>
                                        <Title children={'機構代碼/名稱：'} extendClass={extendClass}/>
                                        <BaseInput
                                            className=' ml-2 bg-white min-w-[570px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                                            inputMode={InputTypeEnum.Search}
                                            type={InputTypeEnum.Text}
                                            value={`${supportOrgNo} ${supportOrgName}`}
                                            onClick={() => setShowOrgDropdown(true)}/>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <Title children={'申請單號：'} extendClass={extendClass}/>
                                        <p>{supportNo}</p>
                                    </div>
                                </div>
                                {/* 支援科別 */}
                                <div className='flex justify-between items-center'>
                                    <FuzzySearchInput
                                        title={'支援科別：'}
                                        titleExtendClass={extendClass}
                                        value={supportDivStr}
                                        handleValueChange={handleSupportDivNoChange}
                                        valueKey={['supportDivNo', 'supportDivName']}
                                        list={allDivisionList}
                                        inputExtendClass={'ml-6'}
                                    />
                                </div>
                                {/* 支援目的 */}
                                <div className='flex flex-row items-start gap-4'>
                                    <Title children={'支援目的：'} extendClass='w-[160px] justify-end py-2 h-9'/>
                                    <div className='flex flex-col items-start pl-6 w-full'>
                                        {renderPurposeOptions()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        <div className='flex flex-col gap-2 my-4 mb-4'>
                            <div className='flex flex-row items-center justify-between gap-4'>
                                <div className='flex flex-row items-center gap-4'>
                                    {/* 承辦部門 */}
                                    <FuzzySearchInput
                                        title={'承辦部門：'}
                                        titleExtendClass={extendClass}
                                        value={ownerDeptStr}
                                        handleValueChange={handleOwnerDeptNoChange}
                                        valueKey={['deptNo', 'deptName']}
                                        list={deptList}
                                        inputExtendClass={'ml-6'}
                                    />
                                    {/* 代理人員代號/姓名 */}
                                    <FuzzySearchInput
                                        title={'代理人員代號/姓名：'}
                                        titleExtendClass={extendClass}
                                        value={agentUserStr}
                                        handleValueChange={handleAgentUserNoChange}
                                        valueKey={['agentUserNo', 'agentUserName']}
                                        list={agentUserList}
                                        inputExtendClass={'ml-6'}
                                    />
                                </div>
                                {/* 收件確認按鈕 */}
                                <button
                                    className={`ml-4 flex items-center justify-center font-base px-4 h-10 border-[2px] rounded-[6px] ${data.stepStatus == RsisStepStatusEnum.Apply && userRole === USER_ROLE.DEPT_USER
                                        ? 'text-black border-black hover:bg-primary hover:text-white bg-[#FAFAFA] hover:border-primary hover:cursor-pointer'
                                        : 'text-[#D4D4D8] border-[#D4D4D8] cursor-not-allowed'}`}
                                    disabled={!(data.stepStatus == RsisStepStatusEnum.Apply && userRole === USER_ROLE.DEPT_USER)}
                                    onClick={handleMailConfirm}
                                >
                                    收件確認
                                </button>
                            </div>
                            {/* 性質 */}
                            <div className='flex items-center gap-4'>
                                <Title children={'性質：'} extendClass={extendClass}/>
                                <select
                                    className="w-[654px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                    value={supportProperties}
                                    onChange={(e) => setSupportProperties(e.target.value)}
                                >
                                    {kindList.map((item, index) =>
                                        <option key={index} value={item.codeNo}>{item.codeValue1}</option>
                                    )}
                                </select>
                            </div>
                            {/* 執行內容 */}
                            <div className='flex items-center gap-4 w-full'>
                                <Title children={'執行內容：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <BaseInput
                                    className="w-[1385px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                    type={InputTypeEnum.Text}
                                    value={executeContent}
                                    onChange={(e) => setExecuteContent(e.target.value)}
                                />
                            </div>
                            {/* 備註 */}
                            <div className='flex items-center gap-4 w-full'>
                                <Title children={'備註：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <BaseInput
                                    className={`w-[1385px] pl-4 h-10 rounded-[6px] border border-gray-300 mx-2 ${data.stepStatus == RsisStepStatusEnum.Apply && userRole === USER_ROLE.DEPT_USER
                                        ? 'bg-white text-black'
                                        : 'bg-[#F4F5F7] text-gray-300 cursor-not-allowed'}
                                        `}
                                    type={InputTypeEnum.Text}
                                    disable={!(data.stepStatus == RsisStepStatusEnum.Apply && userRole === USER_ROLE.DEPT_USER)}
                                    value={memo}
                                    onChange={(e) => setMemo(e.target.value)}
                                />
                            </div>
                            {/* 支援期間明細 */}
                            <div className='flex items-center gap-4'>
                                <Title children={'支援期間：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                <button
                                    className="w-[64px] h-10 border-[2px] rounded-[6px] border-primary text-primary ml-2 hover:cursor-pointer"
                                    onClick={() => setShowSupportDateChoice(true)}>編輯
                                </button>
                                <p>{`${itemStartDate.replace(/ 00:00:00/g, '')}~${itemEndDate.replace(/ 00:00:00/g, '')} ${itemStartTime}~${itemEndTime}`}</p>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                        {/* 存擋按鈕 */}
                        <div className='w-full flex justify-end my-2'>
                            <button
                                className="flex justify-center items-center w-[64px] ml-4 font-base bg-primary text-white h-10 border-[2px] rounded-[6px]"
                                onClick={handleFormSave}>存檔
                            </button>
                        </div>
                        {/* 變更紀錄彈窗 */}
                        {showChangeRecordModal && (
                            <ChangeRecordQuery
                                supportNo={data.supportNo}
                                dropListData={[purposeOptions, subPurposeOptions]}
                                handleOnClose={() => setShowChangeRecordModal(false)}
                            />
                        )}
                        {/* 報備支援-支援日期時間選擇 */}
                        {showSupportDateChoice && (
                            <SupportDateChoice
                                userName={supportUserName}
                                isEdit={true}
                                data={dataList}
                                supportNo={supportNo}
                                supportUserNo={supportUserNo}
                                itemVersion={data.itemVersion}
                                handleUpdateDataList={handleUpdateDataList}
                                handleOnClose={() => setShowSupportDateChoice(false)}/>
                        )}
                        {/* 醫師搜尋彈窗 */}
                        {showDoctorPopup && (
                            <QueryPersonList
                                filterEmp={true}
                                doctorList={doctorList}
                                handleOnClose={() => setShowDoctorPopup(false)}
                                handleOnUpdate={handleDoctorOptionOnClick}
                            />
                        )}
                        {/* 支援機構彈窗 */}
                        {showOrgDropdown && (
                            <QuerySupportOrg
                                filterOrg={true}
                                orgList={orgList}
                                handleOnClose={() => setShowOrgDropdown(false)}
                                handleOnUpdate={handleOrgOptionOnClick}
                            />
                        )}
                    </div>
                }
            />
        </>
    )
}

export default EditModal
