import React, { useEffect, useState } from 'react'
import { Pagination } from '../Pagination/Pagination'
import { use } from 'i18next'

/**
 * 分頁元件
 * @param {number} PageNum 當前頁數
 * @param {number} PageSize 每頁資料呈現筆數
 * @param {number} TotalItemSize 總筆數
 * @param {number} TotalPageSize 總頁數
 * @param {number} CurrentPageItemSize 當前頁資料筆數
 * @param {function} CallbackFn 當前頁數、每頁資料筆數更新時，執行 CallbackFn
 * @return {JSX.Element}
 */
function UsePagination({
    PageNum,
    PageSize,
    TotalItemSize,
    TotalPageSize,
    CurrentPageItemSize,
    CallbackFn
}) {
    // 當前頁數
    const [currentPage, setCurrentPage] = useState(PageNum)
    // 每頁資料筆數
    const [pageSize, setPageSize] = useState(PageSize)
    // 總頁數
    const [totalPageSize, setTotalPageSize] = useState(TotalPageSize)
    // 總筆數
    const [totalItemSize, setTotalItemSize] = useState(TotalItemSize)
    // 當前頁資料筆數
    const [currentPageItemSize, setCurrentPageItemSize] = useState(CurrentPageItemSize)

    // 當前頁數增加
    const handlePageIncrement = () => {
        setCurrentPage(currentPage => currentPage + 1)
    }

    // 當前頁數減少
    const handlePageDecrement = () => {
        setCurrentPage(currentPage => currentPage - 1)
    }

    /**
     * 監聽當前頁數、每頁資料筆數更新時，執行 CallbackFn
     * props: currentPage, pageSize
     */
    useEffect(() => {
        CallbackFn(currentPage, pageSize)
    },[currentPage, pageSize])

    /**
     * 監聽父層資料更新時，更新子層資料
     * props: TotalPageSize, TotalItemSize, CurrentPageItemSize
     */
    useEffect(() => {
        setTotalPageSize(TotalPageSize)
        setTotalItemSize(TotalItemSize)
        setCurrentPageItemSize(CurrentPageItemSize)
    },[TotalPageSize, TotalItemSize, CurrentPageItemSize])

  return (
    <Pagination
      totalPageSize={totalPageSize}
      currentPageItemSize={currentPageItemSize}
      pageSize={pageSize}
      totalSize={totalItemSize}
      currentPage={currentPage}
      onPageOnChange={setCurrentPage}
      onPageSizeChange={setPageSize}
      onPrevPageOnClick={handlePageDecrement}
      onNextPageOnClick={handlePageIncrement}
    />
  )
}

export default UsePagination