import React from 'react';
import '../../components/ICCard/VHICard.scss'
import { ReactComponent as QRCode} from "../../assets/images/ICCard/qr_code_scanner.svg";

const VHICard = ({ closeVHICard }) => {

    return (
        <div className="vhiCardContainer">
            <div className="vhiCard">
                <div className="title flex flex-row items-center justify-between h-[60px] mb-[30px] px-6 border-b-[1px] border-[rgba(#111,0.15)]">
                    <h3 className="text-[20px]">虛擬健保卡</h3>
                    <a onClick={closeVHICard} className="w-[60px] h-10 flex items-center justify-center cursor-pointer bg-[#FAFAFA] text-[16px] border-[2px] border-[#D4D4D8] rounded-[6px]">
                        關閉
                    </a>
                </div>
                <div className="qrCode flex flex-col items-center justify-start">
                    <h3 className="text-[20px]">請掃描QR Code</h3>
                    <p className="mb-4">(時效：5 分鐘)</p>
                    {/*QR code的位置*/}
                    <QRCode />
                </div>
                <p className="mb-4">或</p>
                <div className="remoteRequest px-[47px] py-9">
                    <h3 className="text-[20px] mb-6">使用遠端請求</h3>
                    <p className="mb-4 text-[18px] text-left">1. 先輸入病人「身分證號」，並點選「產生請求碼」</p>
                    <div className="requestCreate flex flex-row items-center justify-between pb-6 mb-6 border-b-[1px] border-[rgba(#111,0.15)]">
                        <span className="text-[#3F3F46]">身分證號</span>
                        <input type="text" value="A123456789"
                               className="w-[310px] h-10 pl-3 text-[18px] border-[1px] border-[#D4D4D8] rounded-[6px]" />
                        <button className="w-[112px] h-10 flex items-center justify-center cursor-pointer bg-[#FAFAFA] text-[16px] border-[2px] border-[#D4D4D8] rounded-[6px]">
                            產生請求碼
                        </button>
                    </div>
                    <p className="text-[18px] text-left">2. 請民眾登入健保快易通App</p>
                    <p className="text-[18px] text-left">3. 點選虛擬健保卡，點選『同意』，完成後通知您</p>
                    <p className="mb-4 text-[18px] text-left">4. 點選下方按鈕</p>
                    <button className="w-[175px] h-10 text-white bg-[#2B6CB0] rounded-[6px]">確認並取得QRcode</button>
                </div>
                <p className="errorCode text-[18px] text-[#E53E3E]">101 : QRcode超過有效期限</p>
            </div>
        </div>
    )

}

export default VHICard;