import {useState} from "react";
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";

const CareGiver = () => {

    const [formData, setFormData] = useState({
        caregiverStatus: '',
        name: '',
        relationship: '',
        age: '',
        gender: '',
        employmentStatus: '',
        careHoursPerDay: '',
        careDaysPerMonth: '',
        caregiverHealth: '',
        caregiverPsychological: '',
        familyInteraction: '',
        totalCareScore: '',
        caregiverEvaluation: '',
        otherComments: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form submission
        console.log(formData);
    };

    const caregiverHealthOptions = [
        '主要照顧者的健康與過去差不多，沒什麼變化',
        '主要照顧者因照顧個案而感到疲累、身體不適，但不需要看醫生',
        '主要照顧者因照顧個案感到疲累、身體不適，而需要看醫生或接受治療，但仍可繼續照顧',
        '主要照顧者因照顧個案而太過疲累而需要住院，或無法繼續照顧，必須換人',
    ];

    const caregiverPsychologicalOptions = [
        '主要照顧者的心理狀況與過去一樣，沒什麼變化',
        '主要照顧者的情緒偶爾會焦慮、擔心、憂鬱，但不至於影響生活作息',
        '主要照顧者會受到個案影響而情緒變化大甚至需要服用鎮定劑、安眠藥',
        '主要照顧者會因個案影響心理狀況產生精神症狀需要常看醫師或住院',
    ];

    const familyInteractionOptions = [
        '家人的互動關係由於個案的相關問題而更能相互關心或沒什麼改變',
        '家人的關係會因為個案的相關問題而起小衝突，但尚能解決問題，維持和諧關係',
        '家人之間會因個案的相關問題而常發生衝突，有些衝突不易解決，但尚不致嚴重破壞家庭的和諧',
        '家人因個案的相關問題常發生嚴重衝突無法解決而嚴重破壞關係，或常處於緊張狀態',
    ];

    const caregiverEvaluationOptions = [
        '無照護負荷問題',
        '照護品質不佳',
        '家庭照護負荷過重',
        '家屬無照護意願',
    ];

    return (
        <form onSubmit={handleSubmit}>
            <h2>主要照顧者狀況</h2>
            <label>
                <input
                    type="radio"
                    name="caregiverStatus"
                    value="無主要照顧者"
                    checked={formData.caregiverStatus === '無主要照顧者'}
                    onChange={handleChange}
                />
                無主要照顧者
            </label>
            <label>
                <input
                    type="radio"
                    name="caregiverStatus"
                    value="有主要照顧者"
                    checked={formData.caregiverStatus === '有主要照顧者'}
                    onChange={handleChange}
                />
                有主要照顧者
            </label>
            <label>
                <input
                    type="radio"
                    name="caregiverStatus"
                    value="有主要照顧者，不與個案同住"
                    checked={formData.caregiverStatus === '有主要照顧者，不與個案同住'}
                    onChange={handleChange}
                />
                有主要照顧者，不與個案同住
            </label>

            <h3>基本資料</h3>
            <label>
                姓名:
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </label>
            <label>
                與個案關係:
                <input
                    type="text"
                    name="relationship"
                    value={formData.relationship}
                    onChange={handleChange}
                />
            </label>
            <label>
                年齡:
                <input
                    type="text"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                />
            </label>
            <label>
                性別:
                <input
                    type="radio"
                    name="gender"
                    value="男"
                    checked={formData.gender === '男'}
                    onChange={handleChange}
                />
                男
                <input
                    type="radio"
                    name="gender"
                    value="女"
                    checked={formData.gender === '女'}
                    onChange={handleChange}
                />
                女
            </label>

            <h3>目前就業狀況</h3>
            <label>
                <input
                    type="radio"
                    name="employmentStatus"
                    value="無"
                    checked={formData.employmentStatus === '無'}
                    onChange={handleChange}
                />
                無
            </label>
            <label>
                <input
                    type="radio"
                    name="employmentStatus"
                    value="全職"
                    checked={formData.employmentStatus === '全職'}
                    onChange={handleChange}
                />
                全職
            </label>
            <label>
                <input
                    type="radio"
                    name="employmentStatus"
                    value="兼職"
                    checked={formData.employmentStatus === '兼職'}
                    onChange={handleChange}
                />
                兼職(部分時間工作)
            </label>

            <h3>每天照顧時間</h3>
            <label>
                <input
                    type="radio"
                    name="careHoursPerDay"
                    value="全天"
                    checked={formData.careHoursPerDay === '全天'}
                    onChange={handleChange}
                />
                全天
            </label>
            <label>
                <input
                    type="radio"
                    name="careHoursPerDay"
                    value="部分時間"
                    checked={formData.careHoursPerDay === '部分時間'}
                    onChange={handleChange}
                />
                部分時間
            </label>
            <label>
                每日平均 小時:
                <input
                    type="text"
                    name="careDaysPerMonth"
                    value={formData.careDaysPerMonth}
                    onChange={handleChange}
                />
            </label>

            <h3>主要照顧者負荷</h3>
            <h4>主要照顧者身體健康</h4>
            {caregiverHealthOptions.map((option, index) => (
                <label key={index}>
                    <input
                        type="radio"
                        name="caregiverHealth"
                        value={option}
                        checked={formData.caregiverHealth === option}
                        onChange={handleChange}
                    />
                    {option}
                </label>
            ))}

            <h4>主要照顧者心理狀況</h4>
            {caregiverPsychologicalOptions.map((option, index) => (
                <label key={index}>
                    <input
                        type="radio"
                        name="caregiverPsychological"
                        value={option}
                        checked={formData.caregiverPsychological === option}
                        onChange={handleChange}
                    />
                    {option}
                </label>
            ))}

            <h4>家庭的互動關係</h4>
            {familyInteractionOptions.map((option, index) => (
                <label key={index}>
                    <input
                        type="radio"
                        name="familyInteraction"
                        value={option}
                        checked={formData.familyInteraction === option}
                        onChange={handleChange}
                    />
                    {option}
                </label>
            ))}

            <h4>家庭照顧者負荷總計分</h4>
            <label>
                總計分:
                <input
                    type="text"
                    name="totalCareScore"
                    value={formData.totalCareScore}
                    onChange={handleChange}
                />
            </label>

            <h4>照顧者總評</h4>
            {caregiverEvaluationOptions.map((option, index) => (
                <label key={index}>
                    <input
                        type="radio"
                        name="caregiverEvaluation"
                        value={option}
                        checked={formData.caregiverEvaluation === option}
                        onChange={handleChange}
                    />
                    {option}
                </label>
            ))}

            <label>
                其他:
                <input
                    type="text"
                    name="otherComments"
                    value={formData.otherComments}
                    onChange={handleChange}
                />
            </label>

            <button type="submit">提交</button>
        </form>
    )
}
export default CareGiver
