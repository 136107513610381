import React, {useEffect, useState} from "react"
import {BasePopup} from "../../Popup/BasePopup"
import {ApiErrorStatusEnum, RegexIdNoEnum, stringIsEmpty, ToastTypeEnum, validIdNoFormat} from "edah_utils/dist"
import {vhcGetQRCodeByVHCTLMRReqCode, vhcGetVHCTeleMedicineReqCode, vHChisGetRegisterBasic} from "../../../api/v1/ICCM"
import moment from "moment-timezone"
import useToast from "../../../hooks/useToast"
import {t} from "i18next"

/**
 * 虛擬健保卡
 * @return {JSX.Element}
 */
const VirtualHealthInsuranceCardPopup = ({show = false, closePopupButtonOnClick, successToGetIcCard}) => {
    //Show Toast
    const showToast = useToast()
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)
    //錯誤訊息
    const [errMessage, setErrMessage] = useState("")
    //身分證字號
    const [idNo, setIdNo] = useState("")
    //請求代碼
    const [reqCode, setReqCode] = useState("")
    //請求代碼時效
    const [reqCodeExpire, setReqCodeExpire] = useState("")
    //是否Disable掉確認並取得QRCode
    const [disableQRCode, setDisableQRCode] = useState(true)

    /**
     * 產生請求碼按鈕點擊事件
     * @return {void}
     */
    const handleGenerateRequestCodeOnClick = () => {
        //身分證格式錯誤
        if (!validIdNoFormat(idNo, RegexIdNoEnum.Taiwan)) {
            // 顯示錯誤
            showToast({message: "身分證格式錯誤", type: ToastTypeEnum.Error})
            return
        }

        vhcGetVHCTeleMedicineReqCode({idNo: idNo}).then(res => {
            // 資料 / 錯誤
            const {data, err} = res
            if (err === ApiErrorStatusEnum.Success) {
                //responseBody
                const {responseBody} = data
                // 設定錯誤訊息
                if(responseBody.ErrorCode === ApiErrorStatusEnum.Success) {
                    setErrMessage(t('general.success'))
                }
                else {
                    setErrMessage(`${t('general.failed')}：${responseBody.message ? responseBody.message : ''}`)
                }
                // 有錯誤的話
                if (stringIsEmpty(responseBody.ReqCode) && stringIsEmpty(responseBody.ReqCodeExpire) && stringIsEmpty(responseBody.Result)) {
                    // 停用QRCode按鈕
                    setDisableQRCode(true)
                    // 清空資料
                    setReqCode("")
                    setReqCodeExpire("")
                } else if (!stringIsEmpty(responseBody.ReqCode) && !stringIsEmpty(responseBody.ReqCodeExpire)) {
                    // 啟用QRCode按鈕
                    setDisableQRCode(false)
                    // 設定資料
                    setReqCode(responseBody.ReqCode)
                    //將時間轉換成正確的時間格式
                    const dateTime = moment(responseBody.ReqCodeExpire, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
                    setReqCodeExpire(dateTime)
                }
            }
        })
    }

    /**
     * 確認並取得QR Code按鈕點擊事件
     * @return {void}
     */
    const handleConfirmAndGetQRCodeOnClick = () => {
        //不存在的話
        if (stringIsEmpty(reqCode) && stringIsEmpty(reqCodeExpire)) {
            return
        }

        vhcGetQRCodeByVHCTLMRReqCode({
            // 身分證字號
            idNo: idNo,
            // 請求代碼
            reqCode: reqCode
        }).then(res => {
            // 錯誤 / 訊息 / 資料
            const {err, msg, data} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                getIcCardDdata()

                closePopupButtonOnClick()
            } else {
                //顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得ic卡資料
     */
    const getIcCardDdata = () => {
        vHChisGetRegisterBasic({}).then(res => {
            // 錯誤 / 訊息 / 資料
            const {err, msg, data} = res
            if (err === ApiErrorStatusEnum.Success) {
                successToGetIcCard(data, false)
            } else {
                //顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 身分證字號
     * @param e {Event} event
     * @return {void}
     */
    const handleIDNoOnChange = (e) => setIdNo(e.target.value)

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="items-center flex-row justify-center w-full py-6 space-y-4">
                <div
                    className="remoteRequest max-w-[612px] w-full border-[2px] border-[#D4D4D8] rounded-[4px] px-[47px] py-9 mx-auto">
                    {/*使用遠端請求*/}
                    <h3 className="text-[20px] mb-6">使用遠端請求</h3>
                    <p className="mb-4 text-[18px] text-left">1. 先輸入病人「身分證號」，並點選「產生請求碼」</p>
                    <div
                        className="flex flex-row items-center justify-between pb-6 mb-6 border-b-[1px] border-[rgba(#111,0.15)]">
                        <span className="text-[#3F3F46]">身分證號</span>
                        <input className="w-[310px] h-10 pl-3 text-[18px] border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type="text" value={idNo} onChange={handleIDNoOnChange}/>
                        {/*產生請求碼*/}
                        <button
                            className="w-[112px] h-10 flex items-center justify-center cursor-pointer bg-[#FAFAFA] text-[16px] border-[2px] border-[#D4D4D8] rounded-[6px]"
                            onClick={handleGenerateRequestCodeOnClick}>
                            產生請求碼
                        </button>
                    </div>
                    <p className="text-[18px] text-left">2. 請民眾登入健保快易通App</p>
                    <p className="text-[18px] text-left">3. 點選虛擬健保卡，點選『同意』，完成後通知您</p>
                    <p className="mb-4 text-[18px] text-left">4. 點選下方按鈕</p>
                    <button className="w-[175px] h-10 text-white bg-[#2B6CB0] rounded-[6px] disabled:bg-[#D4D4D8]"
                            onClick={handleConfirmAndGetQRCodeOnClick} disabled={disableQRCode}>
                        確認並取得QRCode
                    </button>
                </div>
                <p className="errorCode text-[18px] text-[#E53E3E]">
                    {stringIsEmpty(errMessage) ? "" : errMessage}
                </p>
            </div>
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)

        if(show) {
            setIdNo("")
            setReqCode("")
            setReqCodeExpire("")
            setErrMessage("")
            setDisableQRCode(true)
        }
    }, [show])

    return showPopup ?
        <BasePopup
            title={"虛擬健保卡"}
            content={getContent()}
            width={"800px"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default VirtualHealthInsuranceCardPopup
