import React, { useEffect, useMemo, useState } from 'react'
import { TRAN_BACK_LIST_FIELDS } from '../../../constants/referralSystem/tranInCase'
import CustomTable from '../CustomTable'
import { Pagination } from '../../Pagination/Pagination'
import { BaseInput } from '../../Input/BaseInput'
import { trancaseQueryTranBackCaseBySearch } from '../../../api/v1/Rmis'
import {
    time2String,
    validDateFormat,
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
} from 'edah_utils/dist'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../../constants/common'
import useToast from '../../../hooks/useToast'
import RadioButton from '../../Input/RadioButton'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import PrintTranBackCaseList from '../../Print/PrintTranBackCaseList'
import '../../Print/print.scss'
import store from '../../../redux/store'
import {
    updatePrintContent,
    completePrintMode,
} from '../../../redux/Slice/printSlice'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import ReferralHospitalModal from '../ReferralHospitalModal'
import Button from '../../Button/Button'

/**
 * 列印轉回原醫療院所名單視窗
 * @param {object} Props
 * @returns
 */
const TranBackCaseListModal = ({
    inReplyStatusList = [],
    zipCodeList = [],
}) => {
    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        encounterStartDate: '',
        encounterEndDate: '',
        zoneNo: '',
        hospName: '',
    })

    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    /**
     * 轉回原醫療院所清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * 頁碼
     */
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    /**
     * 輸入框檢核狀態
     */
    const [inputState, setInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const showToast = useToast()

    const InReplyStatusRadioButtons = (tranBackCase) => {
        return (
            <div className="flex space-x-4">
                {inReplyStatusList.map((item) => (
                    <RadioButton
                        key={item.inReplyStatusName}
                        mode={tranBackCase.inReplyStatus}
                        modeChecked={item.inReplyStatus}
                        name={`${tranBackCase.encounterId}`}
                        label={item.inReplyStatusName}
                        isDisabled
                    />
                ))}
            </div>
        )
    }

    /**
     * 轉回原醫療院所名單 table header
     */
    const renderFields = useMemo(() => {
        return TRAN_BACK_LIST_FIELDS.map((item) => {
            // 追蹤狀態以 radio button 呈現
            if (item.key === 'inReplyStatus') {
                return {
                    ...item,
                    render: InReplyStatusRadioButtons,
                }
            } else {
                return item
            }
        })
    }, [])

    /**
     * 取得轉回原醫療院所名單
     */
    const getTranBackList = (pageNum, pageSize) => {
        // 檢核時間欄位
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        trancaseQueryTranBackCaseBySearch({
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            hospNo: searchParams.zoneNo,
            pageNum,
            pageSize,
        }).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.dataList.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 點選查詢
     */
    const handleQueryOnClick = () => {
        getTranBackList(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
        })
    }

    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    const exportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = TRAN_BACK_LIST_FIELDS.map((item) => item.key)
        const titles = TRAN_BACK_LIST_FIELDS.map((item) => item.name)
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'encounterDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else if (key === 'inReplyStatus') {
                    const statusName = inReplyStatusList.filter(
                        (item) => item.inReplyStatus === item.inReplyStatus
                    )[0].inReplyStatusName
                    data.push(statusName)
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '轉回原醫療院所名單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 列印
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranBackCaseList,
                printData,
            })
        )
    }

    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setSearchParams({
            ...searchParams,
            zoneNo: hospital.hospNo,
            hospName: hospital.hospName,
        })
        setIsShowReferralHospitalModal(false)
    }

    const handleZoneNoOnBlur = (e) => {
        if (!e.target.value) {
            setSearchParams({ ...searchParams, hospName: '', hospNo: '' })
        }
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <>
            <div className="p-4 no-print">
                <div className="flex justify-between mb-2">
                    <div className="flex">
                        <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="mr-4">
                                就醫日期<span className="text-danger ">*</span>:
                            </div>
                            <BaseInput
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={searchParams.encounterStartDate}
                                onChange={(e) =>
                                    updateSearchParams(e, 'encounterStartDate')
                                }
                                type="date"
                                inputState={inputState.encounterStartDate}
                                errorMsg="必填"
                            />
                            <span className="m-1">~</span>
                            <BaseInput
                                className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={searchParams.encounterEndDate}
                                onChange={(e) =>
                                    updateSearchParams(e, 'encounterEndDate')
                                }
                                type="date"
                                inputState={inputState.encounterEndDate}
                                errorMsg="必填"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-start mb-4 mr-4">
                            <div className="mr-4 align-middle">院所代碼:</div>
                            <div className="4">
                                <Button
                                    classNames="bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                                    text={'院所查詢'}
                                    onClickFn={() =>
                                        setIsShowReferralHospitalModal(true)
                                    }
                                />
                            </div>
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] w-[160px] mr-2"
                                type="text"
                                value={searchParams.zoneNo}
                                onChange={(e) =>
                                    updateSearchParams(e, 'zoneNo')
                                }
                                onBlur={(e) => handleZoneNoOnBlur(e)}
                            />
                            <input
                                className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] flex-1"
                                type="text"
                                value={searchParams.hospName}
                                disabled
                            />
                        </div>
                        <button
                            className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                            onClick={handleQueryOnClick}
                        >
                            {t('general.query')}
                        </button>
                    </div>
                    <div className="flex">
                        <button
                            className="bg-gray-100 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={handlePrint}
                        >
                            {t('general.print')}
                        </button>
                        <button
                            className="bg-gray-100 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                            onClick={exportExcel}
                        >
                            {t('general.export')}
                        </button>
                    </div>
                </div>
                <CustomTable
                    isSelectable={true}
                    fields={renderFields}
                    dataList={dataList}
                    toggleAll={toggleAll}
                    toggleItem={toggleItem}
                />
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.totalItemSize}
                        currentPage={filter.pageNum}
                        totalPageSize={filter.totalPageSize}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    zipCodeList={zipCodeList}
                    onConfirm={onSelectHospital}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </>
    )
}

export default TranBackCaseListModal
