import React, {useState, useRef} from "react"
import {stringIsEmpty, RegsWeekReservationLimitEnum, RegsMedicalTypeEnum } from "edah_utils/dist"
import {t} from "i18next"

/**
 * 日班表單項
 * @param {Object} record 班表資料
 * @param {Function} onClickItem 班表點擊事件
 * @param {Boolean} disabled 是否可選擇
 * @returns 
 */
const DailyScheduleItem = ({
    record,
    onClickItem,
    disabled
}) => {
    // 是否顯示Record資訊
    const [showRecordInfo, setShowRecordInfo] = useState(false)
    // Record資訊的top位置
    const [adjustedRecordInfoTop, setAdjustedRecordInfoTop] = useState(0)
    // Record資訊的left位置
    const [adjustedRecordInfoLeft, setAdjustedRecordInfoLeft] = useState(0)
    const dataElementRef = useRef(null);

    /**
     * Record滑鼠進入時
     * @param e {Event} 事件
     * @return {void}
     */
    const recordOnMouseEnter = (e) => {
        // 阻止事件
        e.stopPropagation()

        const elementRect = dataElementRef.current.getBoundingClientRect()
        const infoBoxHeight = 478; // 信息框的高度最多為478px
        const infoBoxWidth = 260;  // 信息框的寬度為200px
        const screenHeight = window.innerHeight
        const screenWidth = window.innerWidth

        let top = elementRect.top
        let left = elementRect.right

        // 調整 top 位置，避免超出螢幕底部
        if (top + infoBoxHeight > screenHeight) {
            top = screenHeight - infoBoxHeight
        }

        // 調整 left 位置，避免超出螢幕右側
        if (left + infoBoxWidth > screenWidth) {
            left = elementRect.left - infoBoxWidth//screenWidth - infoBoxWidth
        }
        setAdjustedRecordInfoTop(top)
        setAdjustedRecordInfoLeft(left)

        // 避免已經顯示時再次顯示
        if (!showRecordInfo) {
            // 顯示Record資訊
            setShowRecordInfo(true)
        }

        // 避免已經顯示時再次顯示
        if (!showRecordInfo) {
            // 顯示Record資訊
            setShowRecordInfo(true)
        }
    }

    /**
     * Record滑鼠離開時
     * @param e {Event} 事件
     * @return {void}
     */
    const recordOnMouseLeave = (e) => {
        // 阻止事件
        e.stopPropagation()
        // 避免已經隱藏時再次隱藏
        if (showRecordInfo) {
            // 不顯示Record資訊
            setShowRecordInfo(false)
        }
    }

    /**
     * 取得Record資訊
     * @return {JSX.Element}
     */
    const getRecordInfo = () => {
        // 預約最大看診人次
        const limit = record.limit
        // 現場最大看診人次
        const onSiteLimit = record.onsiteLimit
        // 看診時間(分/人)
        const singleViewMin = record.slotMin
        // 預約方式
        const giveNumType = record.giveNumType
        // 預約方式字串
        const giveNumTypeStr = t(`page.MonthMaintenance.ReservationMethod.${giveNumType}`)
        // 調病歷
        const sentChartFlag = record.sentChartFlag
        // 調病歷字串
        const sentChartFlagStr = sentChartFlag ? t('general.yes') : t('general.no')
        // 給號方式
        const noType = record.noType
        // 給號方式字串
        const noTypeStr = record.typeName
        // 預約限制類型
        const reserveLimitType = record.reserveLimitType
        // 取得預約限制類型字串
        const reserveLimitTypeStr = (type) => {
            //結果
            let result = ''
            switch (type) {
                //一般
                case RegsWeekReservationLimitEnum.Normal:
                    result = '一般'
                    break
                // 限醫生
                case RegsWeekReservationLimitEnum.DoctorOnly:
                    result = '限醫生'
                    break
                //限醫生本人
                case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                    result = '限醫生本人'
                    break
            }

            return result
        }
        // 診別
        const medicalType = record.clinicType
        // 取得診別字串
        const medicalTypeStr = (type) => {
            let result = ''
            switch (type) {
                //門診
                case RegsMedicalTypeEnum.OutpatientClinic:
                    result = '門診'
                    break
                //外檢
                case RegsMedicalTypeEnum.MedicalCheckup:
                    result = '外檢'
                    break
                //預防保健
                case RegsMedicalTypeEnum.PreventiveHealthcare:
                    result = '預防保健'
                    break
            }

            return result
        }

        // 取得看診項目字串
        const medicalItemStr = (type) => {
            let result = ''
            switch (type) {
                //循環醫療
                case 'A':
                    result = '循環醫療'
                    break
                //視訊問診
                case 'B':
                    result = '視訊問診'
                    break
            }

            return result
        }

        // 開放網掛/APP
        const webAppFlag = record.webapptFlag
        // 開放網掛/APP字串
        const webAppFlagStr = webAppFlag ? t('general.yes') : t('general.no')
        // 網掛/App取消看診進度
        const showProcessFlag = record.showProcessFlag
        // 網掛/App取消看診進度字串
        const showProcessFlagStr = showProcessFlag ? t('general.yes') : t('general.no')
        // 網掛預約期限(天)
        const webAppDay = record.webapptDay
        // 網掛預約期限字串
        const webAppDayStr = `${webAppDay}${t('general.dateTime.day')}`
        // 看診項目
        const mediItem = record.clinicMarkNo ? record.clinicMarkNo : ''
        // 診室地點
        const clinicLocation = record.clinicLocation
        // 取得診室地點字串
        const clinicLocationStr = clinicLocation ? clinicLocation : ''
        // 代班醫師
        const locumDoctorStr = record.locumDoctorName ? record.locumDoctorName : ''
        // 停診
        const stopClinicFlag = record.stopClinicFlag
        // 停診字串
        const stopClinicFlagStr = stopClinicFlag ? t('general.yes') : t('general.no')
        // 停代診公告
        const stopClinicBulletinFlag = record.stopClinicBulletinFlag
        // 停代診公告字串
        const stopClinicBulletinFlagStr = stopClinicBulletinFlag ? t('general.yes') : t('general.no')
        // 週班注意事項
        const weekNotice = record.weekNotice ? record.weekNotice : ''
        //科別名稱
        const divName = record.divName
        //診室名稱
        const clinicName = record.clinicName

        return (
            <>
                <p>預約最大看診人次: {limit}</p>
                <p>現場最大看診人次: {onSiteLimit}</p>
                <p>看診時間(分/人): {singleViewMin}</p>
                <p>預約方式: {giveNumTypeStr}</p>
                <p>調病歷: {sentChartFlagStr}</p>
                <p>給號方式: {noTypeStr}</p>
                <p>預約限制: {reserveLimitTypeStr(reserveLimitType)}</p>
                <p>診別: {medicalTypeStr(medicalType)}</p>
                <p>開放掛號/APP: {webAppFlagStr}</p>
                <p>網掛/APP取消看診進度: {showProcessFlagStr}</p>
                <p>網掛預約期限: {webAppDayStr}</p>
                <p>看診項目: {medicalItemStr(mediItem)}</p>
                <p>{t('general.department')}: {divName}</p>
                <p>{t("Regs.general.clinic")}: {clinicName}</p>
                <p>{t("Regs.general.clinicLocation")}: {clinicLocationStr}</p>
                <p>代班醫師: {locumDoctorStr}</p>
                <p>停診: {stopClinicFlagStr}</p>
                <p>停代診公告: {stopClinicBulletinFlagStr}</p>
                <p className="line-clamp-3 text-wrap break-all">注意事項: {weekNotice}</p>
            </>
        )
    }

    return (
        <div ref={dataElementRef}>
            <li 
                onMouseEnter={(e) => recordOnMouseEnter(e)}
                onMouseLeave={(e) => recordOnMouseLeave(e)}
                onClick={() => onClickItem(disabled, record)}
                className={`${disabled ? "disable":"cursor-pointer"}`}
            >
                <div
                    className={`doctor relative flex flex-row items-center justify-start mb-1`}>
                    <p className="name text-sm mr-[30px]">{!stringIsEmpty(record.locumDoctorName) ? record.locumDoctorName : record.doctorName}</p>
                    {/*控制class去區分類別 [代][滿]為一班不用給class, [限本]給onlySelf的class
                    優先順序: 滿 > 限本 > 代
                    */}
                    {
                        record.regFullFlag !== null && record.regFullFlag === 1 ?
                        (<p>[滿]</p>) : (record.reserveLimitType !== null && record.reserveLimitType === "B") ?
                        (<p className='text-[#f5a524]'>[限醫]</p>) : (record.reserveLimitType !== null && record.reserveLimitType === "C") ?
                        (<p className='text-[#f5a524]'>[限本]</p>) : !stringIsEmpty(record.locumDoctorName) ?
                        (<p>[代]</p>) : <></>
                    }
                    <p className="absolute text-sm right-0">{record.currentRegCount}/{record.totalRegLimit}</p>
                </div>
                <div
                    className="department relative flex flex-row items-center justify-start mb-1">
                    {/*診室名稱*/}
                    <p>{record.clinicName}</p>
                    <p className="absolute right-0 called"></p>
                </div>
                {
                    !stringIsEmpty(record.weekNotice) &&
                    <div
                        className="remark relative flex flex-row items-center justify-start mb-1">
                        {/*備註區塊*/}
                        備註:{record.weekNotice}
                    </div>
                }
            </li>
            {
                showRecordInfo && (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="fixed border-4 bg-white z-20 text-left px-4 p-2 w-64 flex flex-col"
                        style={{
                            left: `${adjustedRecordInfoLeft}px`,
                            top: `${adjustedRecordInfoTop}px`,
                        }}>
                        {getRecordInfo()}
                    </div>
                )
            }
        </div>
    )
}

export default DailyScheduleItem