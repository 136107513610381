import React, {useEffect, useState} from "react"
import {t} from "i18next"
import { DefaultButton } from "../../../Button/DefaultButton"
import { BasePopup } from "../../../Popup/BasePopup"

/**
 * 刪除提示彈窗
 * @param {Boolean} show 是否顯示
 * @param {Function} closePopupButtonOnClick 關閉按鈕點擊事件
 * @param {Function} handleDeleteOnClick 刪除事件
 * @returns
 */
const DeletePromptPopup = ({ 
    show = false,
    closePopupButtonOnClick, 
    handleDeleteOnClick,
    message
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="text-left px-6 pt-2 text-lg">
                {message}
            </div>
            <div className="text-left px-6 pb-8 pt-4 text-lg border-b-[1px] border-[rgba(0,0,0,0.15)]">
                是否確定刪除?
            </div>

            <div className="flex py-4 px-6 space-x-3 justify-end">
                <DefaultButton 
                    type="outline-primary" 
                    text="否"
                    onClickFn={closePopupButtonOnClick}
                />
                <DefaultButton 
                    type="primary" 
                    text="是"
                    onClickFn={() => handleDeleteOnClick()}
                />
            </div>
        </>
    )

    return showPopup ?
        <BasePopup
            title={"提醒"}
            content={getContent()}
            width={"560px"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default DeletePromptPopup