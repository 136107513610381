
export const MARS_REPORT_FIELDS = [
    {
        key: 'marsUploadNo',
        name: 'MARS上傳批號',
    },
    {
        key: 'encounterDate',
        name: '就醫日期',
    },
    {
        key: 'payFeeNo',
        name: '批價序號',
    },
    {
        key: 'patientId',
        name:'病歷號',
    },
    {
        key: 'patientName',
        name: '姓名',
    },
    {
        key: 'localid',
        name: 'Localid',
    },
    {
        key: 'nhiid',
        name: 'Nhiid',
    },
    {
        key: 'uploadResult',
        name: '上傳結果',
    },
    {
        key: 'download',
        name: '下載',
    },
]
