import React from 'react'
import {PopupProvider} from '../../components/RSIS/PopupProvider'
import FunctionOperateMaintain from '../../components/RSIS/FunctionOperateMaintain/FunctionOperateMaintain'

/**
 * 功能操作說明維護
 * @returns {JSX.Element}
 */
function FunctionOperateMaintainLayout() {
    return (
        <PopupProvider>
            <FunctionOperateMaintain/>
        </PopupProvider>
    )
}

export default FunctionOperateMaintainLayout
