import searchIcon from "../../../assets/images/search-interface-symbol.png"
import replyIcon from "../../../assets/images/icons/reply.svg"
import '../../TableList/tableList.scss'
import React, { useEffect, useState } from 'react'
import {ApiErrorStatusEnum, objectIsEmpty, InputTypeEnum, fuzzySearchObjects} from "edah_utils/dist"
import { qtyPerPageArray } from "../../../constants"
import { BasePopup } from "../../Popup/BasePopup"
import CvisListItem from "../../CriticalValueManagement/CvisListItem"
import UsePagination from "../../CriticalValueManagement/UsePagination"
import { fuseObject } from "../../CriticalValueManagement/utils"
import {
    countCvisResponseNumber,
    queryCvisResponseByStatus,
} from "../../../api/v1/Critical"

import {t} from 'i18next'


/**
 * @param {doctor} 主治醫師，需帶入查詢未回覆清單及回覆未回覆筆數API
 * @param {handlePopupClose} 待回覆清單彈窗事件 
 * @param {handleReplyPopup} 回覆作業彈窗事件
 * @returns {JSX.Element}
 */
function CrisisAlertAwaitReplyListPopup({doctor="9999", handleSetQueryItem, handlePopupClose, handleReplyPopup}) {
    // 危急值待回覆清單
    const [awaitReplyList, setAwaitReplyList] = useState([])
    // 過濾危急值待回覆清單
    const [filteredItems, setFilteredItems] = useState([])
    // 未回覆筆數
    const [unReplyQty, setUnReplyQty] = useState()
    // 已回覆筆數
    const [repliedQty, setRepliedQty] = useState()
    // 回覆狀態查詢條件
    const [queryStatus, setQueryStatus] = useState('')
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: "1",   //default
        pageSize: "10", //default
        totalItemSize: "",
        totalPageSize: "",
        currentPageItemSize: ''
    })

    const handleReplyWithCvisID = (index) => {
        if(!objectIsEmpty(awaitReplyList[index])) handleSetQueryItem(awaitReplyList[index].cvisNotifyId)
        handleReplyPopup()
    }

    /**
     * 按下Table搜尋按鈕事件
     */
    const handleTableQuerySearchClick = () => {
        if(queryString.trim() === ''){
            setCurrentTablePage(0)
            setTableData([])
            setTotalTablePage(prev => 0)
            setTotalTableQty(prev => 0)
        }
        else {
            setCurrentTablePage(1)
            searchTableData(1)
        }
    }

    /**
     * 控制回覆狀態選擇事件
     * @param {Object} event
     * @returns {void}
     */
    const handleQueryStatusOnChange = (event) => {
        setQueryStatus(event.target.value)
    }

    /**
     * 控制Query輸入框內的值變動事件
     * @param {Object} event 
     */
    const handleQueryInputOnChange = (event) => {
        if(event.target.value === ' ')
            setQueryString('');
        else
            setQueryString(event.target.value.toUpperCase())
    }

    const fuse = fuseObject(awaitReplyList)

    useEffect(()=>{
        if(queryString){
            const result = fuse.search(queryString)
            setFilteredItems(result.map(item => item.item))
        }else{
            setFilteredItems(awaitReplyList)
        }
    }, [queryString, awaitReplyList])

    // 初始化後取得未回覆筆數及已回覆筆數
    useEffect(() => {
        // 取得危急值回覆未回覆筆數
        countCvisResponseNumber({
            "cvisResponseUser": doctor,
        }).then((res => {
            if(res.err === ApiErrorStatusEnum.Success){
                setUnReplyQty(res.data.noReplyNumber)
                setRepliedQty(res.data.repliesNumber)
            }else{
                console.error(res)
            }
        }))
    }, [])

    const handleQueryCvisResponse = () => {
        // 根據狀態取得危急值待回覆清單
        queryCvisResponseByStatus({
            "cvisResponseUser": doctor,                           //doctor,
            "cvisResponseStatus": queryStatus,                    //空白：全部，1：未回覆，2：已回覆,
            "pageNum": paginationProps.pageNum,
            "pageSize": paginationProps.pageSize
        }).then((res) => {
            if(res.err === ApiErrorStatusEnum.Success){
                const data = res.data.dataList
                setAwaitReplyList(data)
                setFilteredItems(data)
                setPaginationProps(prevProps => ({
                    ...prevProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.currentPageItemSize
                }))
            }else{
                if(res.err === 1){
                    setAwaitReplyList([])
                    setFilteredItems([])
                    setPaginationProps(prevProps => ({
                        ...prevProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0
                    }))
                }
            }
        })
    }

    // 回覆狀態監聽事件，當回覆狀態改變呼叫API重新取得清單
    useEffect(() => {
        handleQueryCvisResponse()
    }, [queryStatus])

  return (
    <BasePopup
        title='危急值回覆待回覆清單'
        closePopupButtonOnClick={handlePopupClose}
        content={(
            <div className="w-full p-4">
                {/* 主要內容 */}
                <div className='w-full py-4 flex flex-col gap-4'>
                    {/* 醫生及回覆資訊 */}
                    <div className='w-full flex flex-row justify-between items-center'>
                        <div className='w-[210px] h-10 flex flex-row justify-start items-center'>
                            <p>主治醫師：</p>
                            <button className='w-[126px] h-10 bg-[#F4F4F5] border-[#D4D4D8] border-[1px] rounded-[6px] text-start pl-[14px]'>張醫師</button>
                        </div>
                        <div className='flex flex-row w-[366px] justify-end items-center gap-4'>
                            <select 
                                className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={queryStatus}
                                onChange={handleQueryStatusOnChange}
                            >
                                <option value="">全部</option>
                                <option value="1">未回覆</option>
                                <option value="2">已回覆</option>
                            </select>
                            <p>{`未回覆筆數：${unReplyQty}`}</p>
                            <p>{`已回覆筆數：${repliedQty}`}</p>
                        </div>
                    </div>
                    {/* 資料內容 */}
                    <div className=' flex flex-col w-full h-fit max-h-[864px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                        {/* 搜尋欄 */}
                        <div className='max-w-[320px] relative'>
                            <input
                                className="w-[320px] h-10 pl-7 border-[1px] border-[#D4D4D8] rounded-[6px]" 
                                type={InputTypeEnum.Text}
                                value={queryString}
                                onChange={(event) => handleQueryInputOnChange(event)}
                                placeholder="進階搜尋"
                            />
                            <div className='absolute p-2 top-1/2 left-0 flex flex-row items-center -translate-y-1/2 gap-1'>
                                <img src={searchIcon} alt="searchIcon" role="button" className="w-4 h-4"></img>
                            </div>
                        </div>
                        {/* 清單表格 */}
                        <div className='min-w-[1541px]'>
                            <ul className='tableList crisisAlertAwaitReplyList max-h-[736px]'>
                                <li className='title'>
                                    <div>回覆</div>
                                    <div>通報項目</div>
                                    <div>事件等級</div>
                                    <div>通知日期時間</div>
                                    <div>病歷號</div>
                                    <div>姓名</div>
                                    <div>回復狀態</div>
                                    <div>回復期限</div>
                                    <div>狀態</div>
                                </li>
                                {/* API資料 */}
                                {!objectIsEmpty(awaitReplyList) && filteredItems.map((item, index) => (
                                    <CvisListItem
                                        index={index}
                                        item={item}
                                        type='AwaitReplyList'
                                        handleItemOnClick={handleReplyWithCvisID}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <UsePagination
                        PageNum={paginationProps.pageNum}
                        PageSize={paginationProps.pageSize}
                        TotalItemSize={paginationProps.totalItemSize}
                        TotalPageSize={paginationProps.totalPageSize}
                        CurrentPageItemSize={paginationProps.currentPageItemSize}
                        CallbackFn={handleQueryCvisResponse}
                    />
                </div>
            </div>
        )}
    />
  )
}

export default CrisisAlertAwaitReplyListPopup