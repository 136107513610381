import React, {useEffect, useState} from 'react'
import {BasePopup} from '../../Popup/BasePopup';
import {deleteItem, Title} from '../utils';
import {BaseInput} from '../../Input/BaseInput';
import delIcon from '../../../assets/images/icons/del_circle_gray.svg';
import {DelSlotBtn} from '../SlotButton'
import {getLocalStorage, InputTypeEnum, stringIsEmpty} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import CustomTable from '../RSISCustomTable';
import DeletePopup from '../Popup/DeletePopup';
import SupportDateChoice from "./SupportDateChoice";

import '../../TableList/tableList.scss'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    ToastTypeEnum,
    RsisStepStatusEnum
} from "edah_utils/dist"

import {t} from 'i18next'

// import API
import {
    rsisQueryBaseData,
    rsisQueryAgentByUserNo,
    rsisQueryCodeValue,
    rsisAddMaster,
} from '../../../api/v1/RSIS';


import QuerySupportOrg from "./QuerySupportOrg";
import {FuzzySearchInput} from "../FuzzySearchInput";
import QueryPersonList from "./QueryPersonList";
import {checkDoctorLicence} from "../../../api/v1/Menu";

const extendClass = 'w-[160px] justify-end'

// 表格欄位
const fields = [
    "",
    "開始日期",
    "結束日期",
    "開始時間",
    "結束時間",
]

const getInputClassName = (width = 186) => `w-[${width}px] h-10 pl-4 bg-white text-black rounded-[6px] border border-gray-300`

/**
 * 新增報備支援Popup
 * @param {string} role 使用者角色
 * @param {array} doctorList 醫師清單
 * @param {array} orgList 機構清單
 * @param {array} deptList 承辦部門清單
 * @param {array} zoneList 院區清單
 * @param {function} handleOnUpdate 更新列表資料
 * @param {function} handleOnClose 關閉彈窗事件
 * @returns {JSX.Element}
 */
function AddModal({role, doctorList, orgList, deptList, zoneList, handleOnUpdate, handleOnClose}) {
    // 資料集
    const [dataList, setDataList] = useState([])
    // 員工/醫師代碼下拉清單
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 機構代碼/名稱查詢彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState([]);
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([]);
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師
    const [supportUserNo, setSupportUserNo] = useState('')
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState('')
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 職登機構代碼
    const [hospNo, setHospNo] = useState('')
    // 職登機構名稱
    const [hospName, setHospName] = useState('')

    /** 報備支援內容欄位 */
        // 機構代碼/名稱
    const [supportOrgNo, setSupportOrgNo] = useState('')
    // 機構名稱
    const [supportOrgName, setSupportOrgName] = useState('')
    // 申請單號
    const [supportNo, setSupportNo] = useState('')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState('')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState('')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(['10'])
    // 支援目的門住
    const [purposeCodeExt, setPurposeCodeExt] = useState('1')
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門
    const [ownerDeptNo, setOwnerDeptNo] = useState('')
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState('')
    // 代理人員字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState('')
    // 代理人員姓名
    const [agentUserName, setAgentUserName] = useState('')
    // 性質
    const [supportProperties, setSupportProperties] = useState('')
    // 執行內容
    const [executeContent, setExecuteContent] = useState('')
    // 備註
    const [memo, setMemo] = useState('')
    // 院區
    const [zone, setZone] = useState('A')
    // 員工/醫師狀態
    const [supportUserNoStatus, setSupportUserNoStatus] = useState(null)

    // 使用上下文
    const {
        setShowSupportDateChoice,
        showSupportDateChoice,
        showToast,
        setFocusIndex,
        focusIndex
    } = usePopup()

    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 代理人員清單
    const [agentUserList, setAgentUserList] = useState([])

    /**
     * 列表項目
     * @param item 列表資料
     * @param index 索引
     * @param isSelectAll 是否全選
     * @returns {Element}
     */
    const ListItem = ({item, index, isSelectAll}) => {
        const handleOnClick = () => {
            if (focusIndex === index) return
            else {
                setFocusIndex(index)
            }
        }
        return (
            <li key={index} onClick={handleOnClick}>
                <div><DelSlotBtn onClick={() => setShowDeletePopup(true)}/></div>
                <div>{item.startDate}</div>
                <div>{item.endDate}</div>
                <div>{item.startTime}</div>
                <div>{item.endTime}</div>
            </li>
        )
    }

    /**
     * 更新列表項目
     * @returns {void}
     */
    const handleUpdateDataList = (data) => setDataList(data)


    /**
     * 支援科別更新時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleSupportDivChange = (item) => {
        setSupportDivStr(item)
        setSupportDivNo(!stringIsEmpty(item) ? item.split(' ')[0] : '')
        setSupportDivName(!stringIsEmpty(item) ? item.split(' ')[1] : '')
    }

    /**
     * 承辦部門人員更新時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleOwnerDeptChange = (item) => {
        setOwnerDeptStr(item)
        setOwnerDeptNo(item.split(' ')[0])
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員更新時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleAgentUserNoChange = (item) => {
        setAgentUserStr(item)
        setAgentUserNo(item.split(' ')[0])
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 刪除列表項目
     * @returns {void}
     */
    const deleteRow = () => {
        setDataList(deleteItem(dataList, focusIndex))
        setShowDeletePopup(false)
        setFocusIndex(null)
    }


    /**
     * 新增報備支援內容
     * @returns {void}
     */
    const handleFormSave = () => {
        if (!supportUserNo) {
            setSupportUserNoStatus(false)
            showToast({message: '請選擇支援醫師', type: ToastTypeEnum.Warning})
            return;
        }
        setSupportUserNoStatus(true)

        // 檢查機構代碼是否有值
        if(!supportOrgNo){
            showToast({message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        // 檢查支援科別是否有值
        if(!supportDivNo){
            showToast({message: `支援者：${supportUserName}，「支援科別」為必填欄位，請重新確認！`, type: ToastTypeEnum.Warning})
            return
        }

        if (dataList.length === 0) {
            showToast({message: '請新增支援日期', type: ToastTypeEnum.Warning})
            return
        }

        const sendData = dataList.map(item => {
            //取得機構物件
            const org = orgList.find(o => o.orgNo === supportOrgNo)
            //機構存在的話再回傳
            if (org) {
                return {
                    supportNo: supportNo,
                    itemVersion: 1,
                    supportUserNo: supportUserNo,
                    supportOrgPkey: org.orgPkey,
                    supportOrgNo: supportOrgNo,
                    supportOrgName: supportOrgName,
                    supportDivNo: supportDivNo,
                    supportDivName: supportDivName,
                    purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode,
                    purposeCodeExt: purposeCodeExt,
                    itemStartDate: `${item.startDate} 00:00:00`,
                    itemStartTime: item.startTime.split(':')[0] + item.startTime.split(':')[1],
                    itemEndDate: `${item.endDate} 00:00:00`,
                    itemEndTime: item.endTime.split(':')[0] + item.endTime.split(':')[1],
                    supportProperties: supportProperties,
                    executeContent: executeContent,
                    memo: memo,
                    agentUserNo: agentUserNo,
                    stepStatus: RsisStepStatusEnum.Apply,
                    supportModifyStatus: '',
                    supportModifyDatetime: '',
                    supportModifyReasonCode: '',
                    supportModifyReasonDoc: "",
                    sendFileName: '',
                    sendCaseNo: '',
                    sendDatetime: '',
                    applyType: 'N',
                    zone: getLocalStorage('campus'),
                    effFlag: true,
                    ownerDeptNo: ownerDeptNo,
                    changeFieldsDoc: '',
                    confirmUserNo: '',
                    confirmDatetime: ''
                }
            }
        })
        saveAddMaster(sendData)
    }

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} ListItem選取的選項
     * @returns {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        //取得醫師執登資料
        getDoctorData(item.userNo)
        //取得代理人員代號姓名
        getAgentUserList(item.userNo)
        //設定支援醫師代碼
        setSupportUserNo(item.userNo)
        //設定支援醫師名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 機構代碼/名稱選項被選擇時
     * @param item {Object} 選取的選項
     * @returns {void}
     */
    const handleSupportOrgOptionOnClick = (item) => {
        setSupportOrgNo(item.orgNo)
        setSupportOrgName(item.orgName)
        setShowOrgDropdown(false)
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                if (prevCodes.includes(codeNo)) {
                    return prevCodes.filter(code => code !== codeNo);
                } else {
                    return [...prevCodes, codeNo];
                }
            });
        };

        return (
            <div className="grid grid-cols-5 gap-4 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-1">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id={item.codeNo}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="purpose"
                                        checked={purposeCode.includes(item.codeNo)}
                                        onChange={() => handlePurposeChange(item.codeNo)}
                                    />
                                    <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                                </div>
                                <select
                                    className="ml-2 pl-4 w-[96px] h-10 bg-white text-black rounded-[6px] border border-gray-300"
                                    value={purposeCodeExt}
                                    onChange={(e) => setPurposeCodeExt(e.target.value)}
                                >
                                    {renderSubPurposeOptions()}
                                </select>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="flex items-center space-x-2 col-span-1">
                                <input
                                    id={item.codeNo}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="purpose"
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                />
                                <label htmlFor={item.codeNo}>{item.codeValue1}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    /**
     * 渲染子支援目的選項
     * @return {JSX.Element}
     */
    const renderSubPurposeOptions = () => {
        return subPurposeOptions.map((item) => (
            <option key={item.codeNo} value={item.codeNo}>{item.codeValue1}</option>
        ))
    }

    /**
     * 新增報備支援內容
     * @param data {Array} 新增報備支援內容
     * @returns {void}
     */
    const saveAddMaster = (data) => {
        // 執行儲存報備支援內容
        rsisAddMaster(data).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '新增成功', type: ToastTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @returns {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @retunr {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo:''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                if(data) {
                    setDivNo(data.divNo)
                    setDivName(data.divName)
                    setLicenceId(data.licenceId)
                    setLicenceKind(data.licenceKind)
                    setLicenceKindName(data.licenceKindName)
                    setHospNo(data.hospNo)
                    setHospName(data.hospName)
                    data.supportDivNo && data.divName
                        ? handleSupportDivChange(`${data.supportDivNo} ${data.divName}`)
                        : handleSupportDivChange('')
                }else{
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                    handleSupportDivChange('')
                }
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /** EDAQA-360
     * 取得DIVISION中 SUPPORT_DIV_NO不為空的資料
     * @returns {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData().then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data.supportDivList)
            } else {
                setAllDivisionList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 點擊支援期間明細編輯時
     * @returns {void}
     */
    const handleCheckSupportNo = () => {
        // 判斷是否選擇支援醫師
        if (supportUserNo) {
            setSupportUserNoStatus(true)
            setShowSupportDateChoice(true)
        } else {
            // 顯示提示訊息
            showToast({message: '請選擇支援醫師', type: ToastTypeEnum.Warning})
            // 支援醫師狀態
            setSupportUserNoStatus(false)
        }
    }

    /**
     * 第一次執行時
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 取得支援目的選項
        getPurposeOptions()
    }, [])

    return (
        <>
            <BasePopup
                title="報備支援新增"
                width="1653px"
                closePopupButtonOnClick={handleOnClose}
                content={
                    <div className='px-6'>
                        <div className='flex flex-row justify-between w-full gap-2'>
                            <div className='flex flex-row gap-2'>
                                <Title>{'員工/醫師代碼'} <span className="text-danger ">*</span> :</Title>
                                <BaseInput
                                    inputState={supportUserNoStatus}
                                    className={getInputClassName()}
                                    inputMode={InputTypeEnum.Text}
                                    type={InputTypeEnum.Text}
                                    value={`${supportUserNo} ${supportUserName}`}
                                    onClick={() => setShowDoctorPopup(true)}
                                    errorMsg="必填"
                                />
                            </div>
                            <div className='flex flex-row gap-2'>
                                <select
                                    className='w-[132px] pl-[12px] h-10 bg-white text-black rounded-[6px] border border-gray-300'
                                    disabled>
                                    <option
                                        value={getLocalStorage('campus')}>{t(`campus.${getLocalStorage('campus')}`)}</option>
                                </select>
                                <span
                                    className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.1`)}狀態`}</span>
                            </div>
                        </div>
                        {/* 第二列資訊 */}
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row items-center'>
                                {/* 執登機構 */}
                                <Title children='執登機構：'/>
                                <BaseInput
                                    type={InputTypeEnum.Text}
                                    value={!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                                    disable={true}
                                />
                                {/* 職類別 */}
                                <Title children='職類別：'/>
                                <BaseInput
                                    type={InputTypeEnum.Text}
                                    value={!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                                    disable={true}
                                />
                                {/* 執登科別 */}
                                <Title children='執登科別：'/>
                                <BaseInput
                                    type={InputTypeEnum.Text}
                                    value={!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                                    disable={true}
                                />
                                {/* 證書字號 */}
                                <Title children='證書字號：'/>
                                <BaseInput
                                    type={InputTypeEnum.Text}
                                    value={`${!stringIsEmpty(licenceId) ? licenceId : ''}`}
                                    disable={true}
                                />
                            </div>
                            {/* 刪除、存擋按鈕 */}
                            <div className='flex flex-row gap-2'>
                                <div className='flex flex-row items-center'>
                                    <img className='w-4 h-4 fill-[#D4D4D8] ' src={delIcon}
                                         alt="search-icon" role="button"/>
                                    <button
                                        className='flex items-center justify-center font-base border-black text-[#D4D4D8] hover:cursor-pointer hover:bg-gray-200'
                                        onClick={handleFormSave}>
                                        刪除
                                    </button>
                                </div>
                                <div className='flex my-2'>
                                    <button
                                        className="flex justify-center items-center w-[64px] ml-4 font-base bg-primary text-white h-10 border-[2px] rounded-[6px]"
                                        onClick={handleFormSave}>
                                        存檔
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='w-full min-w-[820px] min-h-[421px] bg-white shadow rounded border-2 px-4'>
                            {/* 報備支援內容 */}
                            <div className='flex flex-col mb-6 mt-6'>
                                <div className="flex flex-row w-full">
                                    <h3 className='flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]'>
                                        報備支援內容
                                    </h3>
                                </div>
                                <div className='w-full flex flex-col gap-2'>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-row items-center gap-4 relative'>
                                            <Title children={'機構代碼/名稱：'} extendClass={extendClass}/>
                                            <BaseInput
                                                className=' ml-2 bg-white min-w-[570px] h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300 pl-8'
                                                inputMode={InputTypeEnum.Search}
                                                type={InputTypeEnum.Text}
                                                value={`${supportOrgNo} ${supportOrgName}`}
                                                onClick={() => setShowOrgDropdown(true)}
                                            />
                                        </div>
                                        <div className='flex flex-row items-center'>
                                            <Title children={'申請單號：'} extendClass={extendClass}/>
                                            <p>{supportNo}</p>
                                        </div>
                                    </div>
                                    {/* 支援科別 */}
                                    <div className='flex justify-between items-center'>
                                        <FuzzySearchInput
                                            title={'支援科別：'}
                                            titleExtendClass={extendClass}
                                            value={supportDivStr}
                                            handleValueChange={handleSupportDivChange}
                                            valueKey={['supportDivNo', 'supportDivName']}
                                            list={allDivisionList}
                                            inputExtendClass={'ml-6'}
                                        />
                                    </div>
                                    {/* 支援目的 */}
                                    <div className='flex flex-row items-start '>
                                        <Title children={'支援目的：'} extendClass='min-w-[160px] justify-end h-9'/>
                                        <div className='flex flex-col items-start pl-6 w-full'>
                                            {renderPurposeOptions()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 分隔線 */}
                            <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                            {/* 第二列表 */}
                            <div className='flex flex-col gap-2 my-4 mb-4'>
                                <div className='flex flex-row items-center justify-between gap-4'>
                                    {/* 承辦部門 */}
                                    <div className='flex flex-row items-center gap-4'>
                                        <FuzzySearchInput
                                            title={'承辦部門：'}
                                            titleExtendClass={extendClass}
                                            value={ownerDeptStr}
                                            handleValueChange={handleOwnerDeptChange}
                                            valueKey={['deptNo', 'deptName']}
                                            list={deptList}
                                            inputExtendClass={'ml-6'}
                                        />
                                        {/* 代理人員代號/姓名 */}
                                        <FuzzySearchInput
                                            title={'代理人員代號/姓名：'}
                                            titleExtendClass={extendClass}
                                            value={agentUserStr}
                                            handleValueChange={handleAgentUserNoChange}
                                            valueKey={['agentUserNo', 'agentUserName']}
                                            list={agentUserList}
                                            inputExtendClass={'ml-6'}
                                        />
                                    </div>
                                </div>
                                {/* 性質 */}
                                <div className='flex items-center gap-4'>
                                    <Title children={'性質：'} extendClass={extendClass}/>
                                    <select
                                        className="w-[654px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                        value={supportProperties}
                                        onChange={(e) => setSupportProperties(e.target.value)}
                                    >
                                        {kindList.map((item, index) =>
                                            <option key={index} value={item.codeNo}>{item.codeValue1}</option>
                                        )}
                                    </select>
                                </div>
                                {/* 執行內容 */}
                                <div className='flex items-center gap-4 w-full'>
                                    <Title children={'執行內容：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                    <BaseInput
                                        className="w-[1385px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                        type={InputTypeEnum.Text}
                                        value={executeContent}
                                        onChange={(e) => setExecuteContent(e.target.value)}
                                    />
                                </div>
                                {/* 備註 */}
                                <div className='flex items-center gap-4 w-full'>
                                    <Title children={'說明：'} extendClass={`${extendClass} min-w-[160px]`}/>
                                    <BaseInput
                                        className="w-[1385px] pl-4 h-10 bg-white text-black rounded-[6px] border border-gray-300 mx-2"
                                        type={InputTypeEnum.Text}
                                        value={memo}
                                        onChange={(e) => setMemo(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* 分隔線 */}
                            <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                            {/* 支援期間明細 */}
                            <div className='flex flex-row justify-start my-2'>
                                <p className='px-2 py-[10px] text-[#71717A] text-sm'>支援期間明細：</p>
                                <button
                                    className={`flex items-center justify-center font-base px-4 h-10 rounded-[6px] border-[2px] bg-[#FAFAFA] ${supportUserNo ? 'text-primary border-primary hover:bg-primary hover:text-white' : 'text-[#D4D4D8] border-[##D4D4D8] cursor-not-allowed'} `}
                                    disabled={!supportUserNo}
                                    onClick={() => handleCheckSupportNo()}>
                                    編輯
                                </button>
                            </div>
                            <CustomTable
                                width="1653px"
                                advancedSearch={true}
                                fields={fields}
                                dataList={dataList}
                                ListItem={ListItem}
                                type={"RSISAddModalTable"}
                                paginationProps={paginationProps}
                            />
                        </div>
                        {/* 刪除彈窗 */}
                        {showDeletePopup && (
                            <DeletePopup
                                handleDeletePopupClose={() => setShowDeletePopup(false)}
                                handleNotificationDelete={deleteRow}
                                currentKey={focusIndex}
                            />
                        )}
                        {/* 報備支援-支援日期時間選擇 */}
                        {showSupportDateChoice && (
                            <SupportDateChoice
                                userName={supportUserName}
                                data={dataList}
                                supportUserNo={supportUserNo}
                                handleUpdateDataList={handleUpdateDataList}
                                handleOnClose={() => setShowSupportDateChoice(false)}/>
                        )}
                        {/* 報備支援-支援機構查詢*/}
                        {showOrgDropdown && (
                            <QuerySupportOrg
                                filterOrg={true}
                                orgList={orgList}
                                handleOnClose={() => setShowOrgDropdown(false)}
                                handleOnUpdate={handleSupportOrgOptionOnClick}
                            />
                        )}
                        {/* 員工/醫師代碼查詢 */}
                        {showDoctorPopup && (
                            <QueryPersonList
                                filterEmp={true}
                                doctorList={doctorList}
                                handleOnClose={() => setShowDoctorPopup(false)}
                                handleOnUpdate={handleDoctorOptionOnClick}
                            />
                        )}
                    </div>
                }
            />
        </>
    )
}

export default AddModal
