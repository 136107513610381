import searchIcon from "../../../assets/images/search-interface-symbol.png"
import addIcon from "../../../assets/images/icons/add_circle.svg"
import React, { useEffect, useState } from 'react'
import '../../TableList/tableList.scss'
import {ApiErrorStatusEnum, objectIsEmpty, InputTypeEnum, stringIsEmpty, ToastTypeEnum, fuzzySearchObjects} from "edah_utils/dist"
import { BasePopup } from "../../Popup/BasePopup"
import { BaseInput } from "../../Input/BaseInput"
import { fuseObject, getCurrentDateForInput } from "../utils"
import ToastMessage from "../../Toasts/ToastMessage"
import CvisListItem from "../CvisListItem"
import { Pagination } from "../../Pagination/Pagination"
import {t} from 'i18next'
import { 
  saveCvisResponse,
  queryCvisResponse,
  queryCvisNotifyrecById
 } from "../../../api/v1/Critical"


/**
 * 回覆作業彈窗
 * @param {data} 帶入預顯示資料及流水號ID，帶入流水號ID查詢回覆紀錄API
 * @param {doctor} 主治醫師，預設9999，目前沒有系統能呼叫此頁面，所以先寫死
 * @param {cvisID} 流水號ID，帶入查詢回覆紀錄API
 * @param {handlePopupClose} 回覆作業彈窗事件
 * @param {handleSaveSubmit} 儲存回覆內容事件
 * @returns {JSX.Element}
 */
function CrisisAlertReplyAssignmentPopup({doctor="9999", cvisID, handlePopupClose, handleSaveSubmit}) {
  const [data, setData] = useState({})
  // 回覆作業內容
  const [replyContent, setReplyContent] = useState({
      //data.cvisId API中的待回覆清單目前沒有測試用資料，所以先寫死
      cvisId: cvisID,
      // 預設取得今日日期
      replyDate: getCurrentDateForInput(),
      replyPerson: doctor,
      status: '',
      content: ''
  })
  // 回覆紀錄清單
  const [replyRecordList, setReplyRecordList] = useState([])
  // 過濾回覆紀錄清單
  const [filteredItems, setFilteredItems] = useState([])
  // 進階搜尋字串
  const [queryString, setQueryString] = useState('')
  // 顯示新增內容
  const [showAddReplyContent, setShowAddReplyContent] = useState(false)
  // 顯示Toast
  const [showToast, setShowToast] = useState(false)
  // Toast訊息
  const [toastMessage, setToastMessage] = useState({
      message: '',
      type: ToastTypeEnum.Success
  })
  // pagination控制變數
  const [paginationProps, setPaginationProps] = useState({
    pageNum: "1",   //default
    pageSize: "10", //default
    totalItemSize: "",
    totalPageSize: "",
    currentPageItemSize: ''
  })

  /**
     * 回覆作業表格指定頁碼變動事件
     * @param {number} page 
     * @returns {void}
     */
  const onPaginationPageOnChange = (page) => {
    setPaginationProps({
        ...paginationProps,
        pageNum: page
    });
    handleCvisResponse(page, paginationProps.pageSize);
  }

  /**
   * 回覆作業表格指定每頁筆數變動事件
   * @param {Object} e
   * @returns {void}
   * */
  const onPaginationPageSizeOnChange = (e) => {
      const newSize = e.target.value;
      setPaginationProps({
          ...paginationProps,
          pageSize: newSize
      });
      handleCvisResponse(paginationProps.pageNum, newSize);
  }

  /**
   * 回覆作業表格上一頁按鈕事件
   * @returns {void}
   * */
  const onPaginationPreviousOnClick = () => {
      const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
      setPaginationProps({
          ...paginationProps,
          pageNum: page
      });
      handleCvisResponse(page, paginationProps.pageSize);
  }

  /**
   * 回覆作業表格下一頁按鈕事件
   * @returns {void}
   * */
  const onPaginationNextOnClick = () => {
      const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
      setPaginationProps({
          ...paginationProps,
          pageNum: page
      });
      handleCvisResponse(page, paginationProps.pageSize);
  }

  /**
   * 控制Toast開啟事件
   * @param {Object} event
   * @returns {void}
   */
  const handleToastOnShow = (message, type) => {
      setToastMessage({
          message: message,
          type: type
      })
      setShowToast(true)
  }

  /**
   * 控制Toast關閉事件
   * @param {Object} event
   * @returns {void}
   */
  const handleToastOnClose = () => {
      setShowToast(false)
  }


  /**
   * 控制回覆日期輸入框內的值變動事件
   * @param {Object} event
   * @returns {void}
   */
  const handleReplyDateOnChange = (event) => {
      setReplyContent({
          ...replyContent,
          replyDate: event.target.value
      })
  }

  /**
   * 控制Query輸入框內的值變動事件
   * @param {Object} event 
   */
  const handleQueryInputOnChange = (event) => {
      if(event.target.value === ' ')
          setQueryString('');
      else
          setQueryString(event.target.value)
  }

  /**
   * 處理狀態選擇事件
   * @param {String} status
   */
  const handleStatusOnChange = (status) => {
      //setStatus(status)
      setReplyContent({
          ...replyContent,
          status: status
      })
  }

  /**
   * 控制新增內容按鈕事件
   * @param {Object} event
   * @returns {void}
   */
  const handleShowAddReplyContent = (event) => {
      setShowAddReplyContent(!showAddReplyContent)
  }

  /**
   * 儲存回覆內容
   * @param {Object} event
   * @returns {void}
   */
  const handleContentOnChange = (event) => {
      setReplyContent({
          ...replyContent,
          content: event.target.value
      })
  }

  /**
   * 呼叫API儲存回覆內容
   * @param {Object} event
   */
  const handleContentSave = () => {
    if(stringIsEmpty(replyContent.cvisId) || stringIsEmpty(replyContent.status) || stringIsEmpty(replyContent.content)){
      handleToastOnShow('請填寫完整資料', ToastTypeEnum.Error)
    }else{
      saveCvisResponse({
        cvisNotifyId: replyContent.cvisId,
        cvisProcessStatus: replyContent.status,
        cvisProcessContent: replyContent.content
      }).then((res) => {
        if(res.err === ApiErrorStatusEnum.Success){
          handleCvisResponse()
          handleToastOnShow('儲存成功', ToastTypeEnum.Success)
        }else{
          handleToastOnShow(res.msg, ToastTypeEnum.Error)
        }
      })
    }
  }

  /**
   * 搜尋按鈕事件
   * @param {Object} event
   */
  const handleSearchClick = () => {
      console.log('Search query:', queryString)
  }

  /**
   * 取得回覆紀錄清單
   * @returns {void}
   * */
  const handleCvisResponse = (page, pageSize) => {
    queryCvisResponse({
      "cvisNotifyId": replyContent.cvisId,
      "pageNum": page,
      "pageSize": pageSize
    }).then((res => {
      if(res.err === ApiErrorStatusEnum.Success){
        const data = res.data.dataList
        data.map((item) => {
          item.doctor = doctor
        })
        setReplyRecordList(data)
        setFilteredItems(data)
        setPaginationProps({
          ...paginationProps,
          totalItemSize: res.data.totalItemSize,
          totalPageSize: res.data.totalPageSize,
          currentPageItemSize: res.data.dataList.length,
          pageNum: page,
          pageSize: pageSize
        })
      }else{
        if(res.err === 1){
          handleToastOnShow(res.msg, ToastTypeEnum.Success)
            console.error(res)
            setReplyRecordList([])
            setFilteredItems([])
            setPaginationProps({
              ...paginationProps,
              totalItemSize: 0,
              totalPageSize: 0,
              currentPageItemSize: 0,
              pageNum: page,
              pageSize: pageSize
            })
        }
      }
    }))
  }

  /**
   * 取得回覆紀錄清單 By ID
   * @returns {void}
   * */
  const handleCvisNotifyrec = () => {
    queryCvisNotifyrecById({
      "cvisNotifyId": cvisID
    }).then((res => {
      if(res.err === ApiErrorStatusEnum.Success){
        const data = res.data
        setData(data)
      }else{
        handleToastOnShow(res.msg, ToastTypeEnum.Error)
      }
    }))
  }


  // 初始化後取得回覆紀錄清單
  useEffect(() => {
    handleCvisNotifyrec()
    handleCvisResponse(paginationProps.pageNum, paginationProps.pageSize)
  }, [])

  const fuse = fuseObject(replyRecordList)

  useEffect(()=>{
    if(queryString){
        const result = fuse.search(queryString)
        setFilteredItems(result.map(item => item.item))
    }else{
        setFilteredItems(replyRecordList)
    }
  }, [queryString, replyRecordList])

return (
  <BasePopup
    title="回覆作業"
    width="1400px"
    closePopupButtonOnClick={handlePopupClose}
    content={(
      <>
        {/* 主要內容 */}
        <div className='w-full bg-white p-4 flex flex-col gap-4 overflow-x-scroll'>
          {/* 通報內容 */}
          <div className='w-full flex flex-row justify-between items-center'>
              <div className='w-full min-w-[734px] h-10 flex flex-row justify-start items-center'>
                  <p>通報內容：</p>
                  <input className='w-[650px] h-10 bg-white border-[#D4D4D8] border-[1px] rounded-[6px] text-start pl-[14px]' value={data.cvisNotifyContent}/>
                  <p className="ml-4 min-w-[256px] text-red-600">{`簡訊回覆期限為：${data.cvisNotifyDatetime}`}</p>
              </div>
          </div>
          {/* 病患資料 */}
          <div className='flex flex-col w-full min-w-[1556px] p-4 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
            {/* 第一列 */}
            <ul className="flex flex-row justify-start items-center h-[42px] bg-[#F4F4F5] gap-2 rounded-[4px]">
              {/* 病歷號 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">病歷號</p>
                <p className="p-2">{data.patientId ? data.patientId : "無資料"}</p>
              </li>
              {/* 姓名 */}
              <li className="w-[287px] flex flex-row justify-start items-center">
                <p className="p-2">姓名</p>
                <p className="p-2">{data.patientName ? data.patientName : "無資料"}</p>
              </li>
              {/* 電話 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">電話</p>
                <p className="p-2">{data.phone ? data.phone : ""}</p>
              </li>
              {/* 行動電話 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">行動電話</p>
                <p className="p-2">{data.mobile ? data.mobile : ""}</p>
              </li>
              {/* 公司電話 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">公司電話</p>
                <p className="p-2">{data.companyPhone ? data.companyPhone : ""}</p>
              </li>
              {/* 分機 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">分機</p>
                <p className="p-2">{data.extension ? data.extension : ""}</p>
              </li>
            </ul>
            {/* 第二列 */}
            <ul className="flex flex-row justify-start items-center h-[42px] bg-[#F4F4F5] gap-2 rounded-[4px]">
              {/* 聯絡人 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">聯絡人</p>
                <p className="p-2">{data.contactPerson ? data.contactPerson : ""}</p>
              </li>
              {/* 電話 */}
              <li className="w-[287px] flex flex-row justify-start items-center">
                <p className="p-2">電話</p>
                <p className="p-2">{data.contactPhone ? data.contactPhone : ""}</p>
              </li>
              {/* 公司電話 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">公司電話</p>
                <p className="p-2">{data.contactCompanyPhone ? data.contactCompanyPhone : ""}</p>
              </li>
              {/* 分機 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
                <p className="p-2">分機</p>
                <p className="p-2">{data.contactExtension ? data.contactExtension : ""}</p>
              </li>
              {/* 空欄位 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
              </li>
              {/* 空欄位 */}
              <li className="w-[240px] flex flex-row justify-start items-center">
              </li>
            </ul>
          </div>
          {/* 資料內容 */}
          <div className="flex flex-row w-full gap-2">
            {/* 回覆紀錄 */}
            <div className="w-full flex flex-col justify-start items-center">
              <div className=' flex flex-col w-full min-w-[673px] h-[664px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                  <div className="flex flex-row w-full">
                    {/* 標題 */}
                    <h3 className="flex items-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                                    {/* {t("general.advancedSetting")} */}
                                    回覆紀錄
                    </h3>
                    {/* 搜尋欄 */}
                    <div className="w-[320px] relative">
                      <input 
                        className="w-[320px] h-10 pl-10 border-[1px] border-[#D4D4D8] rounded-[6px] placeholder-gray-400" 
                        type={InputTypeEnum.Text}
                        value={queryString}
                        onChange={handleQueryInputOnChange}
                        placeholder="進階搜尋"
                      />
                      <button 
                        onClick={handleSearchClick}
                        className="absolute top-1/2 left-3 transform -translate-y-1/2 flex items-center justify-center"
                      >
                        <img src={searchIcon} alt="searchIcon" className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  {/* 清單表格 */}
                  <div className='min-w-[649px] w-full'>
                      <ul className='tableList crisisAlertReplyRecord w-full'>
                          <li className='title'>
                              <div>病歷號</div>
                              <div>姓名</div>
                              <div>主治醫師</div>
                              <div className="flex flex-col justify-start">
                                <p>回覆日期時間</p>
                              </div>
                              <div>回覆人員</div>
                              <div>處理狀態</div>
                              <div>處理說明</div>
                          </li>
                          {/* 回覆紀錄清單 */}
                          {!objectIsEmpty(filteredItems) && filteredItems.map((item, index) => {
                            return (
                              <CvisListItem
                                id={index}
                                item={item}
                                type='AssignmentPopupReplyRecordList'
                              />
                            )
                          })}
                      </ul>
                  </div>
                  {/* 分頁 */}
                  <Pagination
                    totalPageSize={paginationProps.totalPageSize}
                    pageSize={paginationProps.pageSize}
                    totalSize={paginationProps.totalItemSize}
                    currentPage={paginationProps.pageNum}
                    onPageOnChange={onPaginationPageOnChange}
                    onPageSizeChange={onPaginationPageSizeOnChange}
                    onPrevPageOnClick={onPaginationPreviousOnClick}
                    onNextPageOnClick={onPaginationNextOnClick}
                  />
              </div>
            </div>
            {/* 新增內容 */}
            <div className='flex flex-col w-full min-w-[875px] h-[664px] border-[1px] border-[#D4D4D8] rounded-[6px] px-4 justify-between'>
              <div className="w-full">
                {/* 新增內容按鈕 */}
                <div className="w-full h-[60px] py-[10px]">
                  <button 
                    className="flex flex-row justify-center items-center w-[112px] h-10" 
                    onClick={handleShowAddReplyContent}
                  >
                    <div className="p-[3.33px] w-10 h-10">
                      <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增內容</p>
                  </button>
                </div>
                {/* 回覆內容 */}
                {showAddReplyContent && (
                  <>
                    <div className="w-full">
                      {/* 回覆日期及回覆人員 */}
                      <div className="flex flex-row items-center">
                        {/* 回覆日期 */}
                        <div className="flex flex-row justify-end items-center">
                          <p className="w-[140px] h-[42px] flex items-center justify-end pr-4 text-base ">回覆日期時間</p>
                          <BaseInput
                            type={InputTypeEnum.Date}
                            className="w-[235px] h-[42px] border-[#D4D4D8] rounded-[6px] border-[1px] pl-[14px] mx-4 pr-3"
                            value={replyContent.replyDate}
                            onChange={handleReplyDateOnChange}
                          />
                        </div>
                        {/* 回覆人員 */}
                        <div className="flex flex-row justify-end items-center">
                          <p className="w-[140px] h-[42px] flex items-center justify-end pr-4 text-base">回覆人員</p>
                          <BaseInput
                            type={InputTypeEnum.Text}
                            className="w-[235px] h-[42px] border-[#D4D4D8] rounded-[6px] border-[1px] pl-[14px] ml-4 mr-[9px]"
                            value={doctor}
                          />
                        </div>
                        <div className=""></div>
                      </div>
                      {/* 處理狀態 */}
                      <div className="flex flex-row items-start justify-start mt-4 gap-[10px]">
                        <p className="w-[140px] h-[42px] flex items-start justify-end pr-4 text-base ">處理狀態</p>
                        <div className=" flex flex-col w-[172px] h-[204px] px-4 gap-[12px] ">
                            {/* 通知病患或家屬選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="notifyPatientOrFamilyRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0] hover:cursor-pointer"
                                checked={replyContent.status === '0'}
                                type="radio"
                                onChange={() => handleStatusOnChange('0')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '0' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                                htmlFor="notifyPatientOrFamilyRadio"
                              >
                                通知病患或家屬
                              </label>
                            </div>
                            {/* 持續觀察選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="keepObservingRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0]"
                                checked={replyContent.status === '1'}
                                type="radio"
                                onChange={() => handleStatusOnChange('1')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '1' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                                htmlFor="keepObservingRadio"
                              >
                                持續觀察
                              </label>
                            </div>
                            {/* 病人通知不到選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="patientCannotBeNotifiedRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0]"
                                checked={replyContent.status === '2'}
                                type="radio"
                                onChange={() => handleStatusOnChange('2')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '2' ? 'text-[#2B6CB0]' : 'text-black'}`} 
                                htmlFor="patientCannotBeNotifiedRadio"
                              >
                                病人通知不到
                              </label>
                            </div>
                            {/* 通知主治醫師選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="notifyDoctorRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0]"
                                checked={replyContent.status === '3'}
                                type="radio"
                                onChange={() => handleStatusOnChange('3')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '3' ? 'text-[#2B6CB0]' : 'text-black'}`}
                                htmlFor="notifyDoctorRadio"
                              >
                                通知主治醫師
                              </label>
                            </div>
                            {/* 其他選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="otherRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0]"
                                checked={replyContent.status === '4'}
                                type="radio"
                                onChange={() => handleStatusOnChange('4')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '4' ? 'text-[#2B6CB0]' : 'text-black'}`}
                                htmlFor="otherRadio"
                                >
                                  其他
                              </label>
                            </div>
                            {/* 處置說明選項 */}
                            <div className="flex items-center w-[140px] space-x-4">
                              <input
                                id="disposalInstructionsRadio"
                                name="statusRadio"
                                className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-[#2B6CB0] checked:ring-[#2B6CB0]"
                                checked={replyContent.status === '5'}
                                type="radio"
                                onChange={() => handleStatusOnChange('5')}
                                style={{cursor: "pointer"}}
                              />
                              <label 
                                className={`hover:cursor-pointer ${replyContent.status === '5' ? 'text-[#2B6CB0]' : 'text-black'}`}
                                htmlFor="disposalInstructionsRadio"
                              >
                                處置說明
                              </label>
                            </div>
                        </div>
                      </div>
                      {/* 說明 */}
                      <div className="flex flex-row items-center h-[250px] mt-4">
                        <p className="w-[140px] h-[250px] flex items-start justify-end pr-4 text-base ">處理說明</p>
                        <textarea 
                          className="w-[700px] h-[250px] border-[#D4D4D8] rounded-[6px] border-[1px] pl-[14px] mx-4"
                          type={InputTypeEnum.Text}
                          placeholder="請輸入說明"
                          value={replyContent.content}
                          onChange={handleContentOnChange}
                        />
                      </div>
                    </div>
                    {/* 儲存按鈕 */}
                    <div className="w-full flex justify-end p-4">
                        <button 
                          className="w-[64px] h-10 bg-[#2B6CB0] text-white rounded-[6px] hover:cursor-pointer" 
                          onClick={handleContentSave}
                        >
                          存檔
                        </button>
                    </div>
                  </>
                )}
              </div>
              
            </div>
          </div>
        </div>
        <ToastMessage 
          show={showToast}
          message={toastMessage.message}
          type={toastMessage.type}
          onClickClose={handleToastOnClose}
        />
      </>
    )}
  />
)
}

export default CrisisAlertReplyAssignmentPopup