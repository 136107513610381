import { useEffect, useState } from 'react'
import { BaseInput } from '../Input/BaseInput'
import RadioButton from '../Input/RadioButton'
import { BasePopup } from '../Popup/BasePopup'
import {
    ApiErrorStatusEnum,
    stringIsEmpty,
    ToastTypeEnum,
} from 'edah_utils/dist'
import {
    trancaseEditTranIn,
    trancaseQueryTranInByEncounterId,
} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import Button from '../Button/Button'

const NoReferralReasonContent = ({
    patientInfo,
    onClose,
    goReferralTicket,
}) => {
    // false: 填寫不轉診原因/ true: 轉診開立作業
    const [referralFlag, setReferralFlag] = useState(true)
    const [noReferralReason, setNoReferralReason] = useState('')
    const [referralData, setReferralData] = useState({})
    const [reasonInputState, setReasonInputState] = useState(null)

    const showToast = useToast()

    const handleNoReferralReasonOnChange = (e) => {
        setNoReferralReason(e.target.value)
    }

    const saveReferralReason = () => {
        if (referralFlag) {
            goReferralTicket()
            onClose()
        } else {
            const isValid = !stringIsEmpty(noReferralReason)
            setReasonInputState(isValid)
            if (!isValid) {
                return
            }
            trancaseEditTranIn({
                ...referralData,
                notTranBackReason: noReferralReason,
                // 需傳入此次的就醫號碼和就醫日期
                opdEncounterId: patientInfo?.encounterId,
                opdEncounterDate: patientInfo?.encounterDate,
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    showToast({
                        message: '儲存成功',
                        type: ToastTypeEnum.Success,
                    })
                    onClose()
                } else {
                    //顯示Toast Message
                    showToast({ message: res.msg, type: ToastTypeEnum.Error })
                }
            })
        }
    }

    // 不轉診原因 textarea border color
    const inputBorderColor = () => {
        if (reasonInputState === null || referralFlag) {
            return 'border-[#D4D4D8]'
        } else if (reasonInputState) {
            return 'border-[#38A169]'
        } else {
            return 'border-[#E53E3E]'
        }
    }

    useEffect(() => {
        trancaseQueryTranInByEncounterId({
            encounterId: patientInfo?.encounterId,
            encounterDate: patientInfo?.encounterDate,
            inpOpd: patientInfo?.inpOpd,
            patientId: patientInfo?.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setReferralData(res.data)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }, [patientInfo])

    return (
        <div className="p-5 flex flex-col items-center">
            <div className="w-[450px]">
                <p className="text-left">
                    此轉入個案已超過45天，請填寫不轉出原因
                </p>
                <div className="mb-3 w-full">
                    <RadioButton
                        wrapperClassName={'my-2'}
                        mode={referralFlag}
                        modeChecked={true}
                        handleModeChange={() => setReferralFlag(true)}
                        label="轉診開立作業"
                    />
                    <RadioButton
                        wrapperClassName={'my-2'}
                        mode={referralFlag}
                        modeChecked={false}
                        handleModeChange={() => setReferralFlag(false)}
                        label="不轉診原因"
                    />
                    <div className="flex flex-col items-start">
                        <textarea
                            className={`w-full h-[250px] ${inputBorderColor()} rounded-[6px] border-[1px] pl-[14px] mx-4`}
                            value={noReferralReason}
                            onChange={handleNoReferralReasonOnChange}
                            disabled={referralFlag}
                        />
                        {reasonInputState === false && !referralFlag && (
                            <span className="text-danger pl-[14px]">必填</span>
                        )}
                    </div>
                </div>
                <div className="flex justify-center">
                    <Button
                        classNames="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        text="確定"
                        onClickFn={saveReferralReason}
                    />
                    <Button
                        classNames="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                        text="取消"
                        onClickFn={onClose}
                    />
                </div>
            </div>
        </div>
    )
}
const NoReferralReasonModal = ({ onClose, patientInfo, goReferralTicket }) => {
    return (
        <BasePopup
            width={'600px'}
            title="不轉診原因"
            content={
                <NoReferralReasonContent
                    patientInfo={patientInfo}
                    goReferralTicket={goReferralTicket}
                    onClose={onClose}
                />
            }
            closePopupButtonOnClick={onClose}
        />
    )
}
export default NoReferralReasonModal
