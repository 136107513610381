import { BaseInput } from '../../components/Input/BaseInput'
import Button from '../../components/Button/Button'
import {
    InputTypeEnum,
    ApiErrorStatusEnum,
    arrayIsEmpty,
    time2String,
    ToastTypeEnum,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
} from 'edah_utils/dist'
import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import { EMERGENCY_HOSPITAL_MAINTAIN_HEADER } from '../../constants/referralSystem/maintainAreaHeader'
import { ReactComponent as ExportText } from '../../assets/images/icons/text.svg'
import { Pagination } from '../../components/Pagination/Pagination'
import { BasePopup } from '../../components/Popup/BasePopup'
import EmergencyHospitalMaintainForm from '../../components/ReferralSystem/EmergencyHospitalMaintain/EmergencyHospitalMaintainForm'
import {
    queryTranFirstAid,
    editTranFirstAid,
    delTranFirstAi,
} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import useDataGrid from '../../hooks/useDataGrid'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../constants/common'
import { queryByHospNo } from '../../api/v1/Menu'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import { useSelector } from 'react-redux'
import store from '../../redux/store'
import { FORM_TYPE } from '../../constants/referralSystem/print'

/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({ children }) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
/**
 * 標題 component
 * @returns {JSX.Element}
 */
const Title = ({ children, extendClass }) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

/**
 * Style
 */
const InputStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'

/**
 * 急救責任醫院維護
 * @returns {JSX.Element}
 */
const EmergencyHospitalMaintain = () => {
    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // data grid
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        dataOnChange,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
    } = useDataGrid()

    /**
     * 表單狀態
     */
    const [modalInfo, setModalInfo] = useState({
        show: false,
    })

    const [activeRow, setActiveRow] = useState({})

    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState('')
    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')
    // 分院名稱
    const [subHospitalName, setSubHospitalName] = useState('')

    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // toast message
    const showToast = useToast()

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 搜尋列 更新
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)
    const handleSubHospitalNameChange = (e) =>
        setSubHospitalName(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = () => {
        getAidHospitalList(1, filter.pageSize)
    }

    const getAidHospitalList = (pageNum, pageSize) => {
        const params = {
            hospName: hospitalName,
            hospNo: hospitalCode,
            branchHospName: subHospitalName,
            pageNum,
            pageSize,
        }
        queryTranFirstAid(params).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.map((item) => {
                        const aidGradeName =
                            EMERGENCY_HOSPITAL_MAINTAIN_HEADER[2].options.filter(
                                (option) => option.value === item.aidGrade
                            )[0]?.name ?? ''
                        return {
                            ...item,
                            aidGradeText: aidGradeName,
                        }
                    }) || []
                if (arrayIsEmpty(list.length)) {
                    showToast({
                        message: '查無資料',
                        type: ToastTypeEnum.Warning,
                    })
                }
                setFilter({
                    ...filter,
                    pageSize,
                    pageNum,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
                updateDataList(list)
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 刪除單筆資料
     */
    const handleConfirmDelete = () => {
        delTranFirstAi({
            hospNo: activeRow.hospNo,
            recCount: activeRow.recCount,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({ message: '刪除成功', type: ToastTypeEnum.Success })
                deleteRow(activeRow)
                getAidHospitalList(filter.pageNum, filter.pageSize)
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
            setShowDeletePopup(false)
        })
    }

    /**
     * 開啟刪除彈窗
     * @param { object }tableRow
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }

    /**
     * 關閉刪除彈窗
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 匯出Excel api
     */
    const handleExport = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = EMERGENCY_HOSPITAL_MAINTAIN_HEADER.map((item) => item.key)
        const titles = EMERGENCY_HOSPITAL_MAINTAIN_HEADER.map(
            (item) => item.name
        )
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'effDate' || key === 'endDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '急救責任醫院維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getAidHospitalList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        setFilter({
            ...filter,
            pageSize,
        })
        getAidHospitalList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getAidHospitalList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getAidHospitalList(page, filter.pageSize)
    }

    /**
     * 點擊編輯進入編輯模式
     * @param {object} row 當列資料
     */
    const handleEditMode = (row) => {
        handleEdit({
            ...row,
            hospNo: row.hospNo || '',
            aidGrade: row.aidGrade || '',
            branchHospName: row.branchHospName || '',
            sortBy: row.sortBy || '',
            uploadCodeNo: row.uploadCodeNo || '',
            effDate: time2String(row.effDate, DATE_FORMAT) || '',
            endDate: time2String(row.endDate, DATE_FORMAT) || '',
        })
    }

    /**
     * 儲存該列資料
     * @param {object} tableRow
     * @returns {void}
     */
    const saveAidHospital = (tableRow) => {
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.hospName) ||
            stringIsEmpty(tableRow.aidGrade)
        ) {
            return
        }

        const data = {
            lockVersion: tableRow.lockVersion,
            hospNo: tableRow.hospNo,
            hospName: tableRow.hospName,
            aidGrade: tableRow.aidGrade,
            recCount: tableRow.recCount,
            branchHospName: tableRow.branchHospName,
            effDate: time2String(tableRow.effDate, DATETIME_FORMAT),
            endDate: time2String(tableRow.endDate, DATETIME_FORMAT),
            sortBy: tableRow.sortBy,
        }
        editTranFirstAid(data).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                handleSave(tableRow)
                getAidHospitalList(filter.pageNum, filter.pageSize)
                showToast({ message: '儲存成功', type: ToastTypeEnum.Success })
            } else {
                showToast({ message: res.msg, type: ToastTypeEnum.Error })
            }
        })
    }

    /**
     * 新增一列資料
     */
    const addAidHospital = () => {
        const newAidHospital = {
            //急救責任院所代號
            hospNo: '',
            //急救責任醫院等級
            aidGrade: '',
            //急救責任醫院分區名稱
            branchHospName: '',
            //排序
            sortBy: '',
            //開始日期
            effDate: '',
            //結束日期
            endDate: '',
            recCount: 0,
        }
        addNewData(newAidHospital)
    }

    /**
     * table欄位失焦事件
     * @param {event} e 失焦事件
     * @param {object} row 該列資料
     * @param {string} key 欄位
     */
    const handleInputOnBlur = (e, row, key) => {
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            getHospNameByHospNo(value, row)
        }
    }

    /**
     * 防抖動
     * @param {function} func
     * @param {number} delay 延遲毫秒數
     */
    const debounce = (func, delay = 500) => {
        let timer

        return (...args) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    /**
     *  以院所代碼取得院所名稱
     */
    const getHospNameByHospNo = debounce((value, row) => {
        queryByHospNo({ hospNo: value }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: ToastTypeEnum.Error,
                    })
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                showToast({
                    message: res.msg,
                    type: ToastTypeEnum.Error,
                })
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={() => handleExport()}
            >
                <ExportText className="mr-1" />
                匯出
            </button>
        )
    }

    /**
     * 列印按鈕
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.aidHospitalList,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">
                                是否確定刪除？
                            </p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button
                                    onClick={handleConfirmDelete}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >
                                    是
                                </button>
                                <button
                                    onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >
                                    否
                                </button>
                            </div>
                        </div>
                    }
                />
            )}
            <div className="flex justify-between p-2 items-center">
                {/*搜尋列*/}
                <div className="flex items-center">
                    <Title>院所代碼：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    <Title extendClass={'ml-4'}>院所名稱：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                    <Title extendClass={'ml-4'}>分所名稱：</Title>
                    <BaseInput
                        className={InputStyle}
                        type={InputTypeEnum.Text}
                        value={subHospitalName}
                        onChange={handleSubHospitalNameChange}
                    />
                    <Button
                        classNames={`ml-4 ${ButtonStyle}`}
                        text={'查詢'}
                        onClickFn={handleClickQuery}
                    />
                </div>
                <div>
                    <Button
                        classNames={SecondButtonStyle}
                        text={'列印'}
                        onClickFn={handlePrint}
                    />
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={EMERGENCY_HOSPITAL_MAINTAIN_HEADER}
                dataList={dataList}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                totalPage={filter.page}
                total={filter.total}
                slotButton={<ExportExcelBtn />}
                isCrud={true}
                isEditMode={isEditMode}
                handleEdit={handleEditMode}
                handleSave={saveAidHospital}
                handleCancel={handleCancel}
                addNewData={addAidHospital}
                handleInputOnBlur={handleInputOnBlur}
                deleteRow={handleDeletePopupOpen}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            {modalInfo.show && (
                <BasePopup
                    title={'急救責任醫院新增'}
                    width="80%"
                    content={
                        <EmergencyHospitalMaintainForm
                            data={activeRow}
                        ></EmergencyHospitalMaintainForm>
                    }
                    closePopupButtonOnClick={() => {
                        setModalInfo({
                            show: false,
                        })
                    }}
                />
            )}
        </Layout>
    )
}

export default EmergencyHospitalMaintain
