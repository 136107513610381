import '../../components/TableList/tableList.scss'
import './registration.scss'

import React, { useMemo, useState } from "react";

//產生假資料
const generateData = (total) => {
    return Array.from({ length: total }, (_, index) => ({
      id: index + 1,
      time: new Date().setHours(8, index * 3, 0)
    }))
}

const Registration = () => {
    const [isOpen, setIsOpen] = useState(true)
    //產生70筆假資料
    const [isDentist, setDentist] = useState(true)
    
    const items = useMemo(() => generateData(70), [])

    const handleOpenToggle=() => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="w-full overflow-x-scroll">
            <div className="title text-left mb-4 pt-[30px]">
                門診/出院日期 : 2023/09/09，0005557，林先生，T123456789，66歲，1960/05/06
            </div>
            <div className="search flex flex-row items-center justify-start pl-1 mb-[18px]">
                <div className="flex flex-row items-center justify-start mr-4">
                    科別：
                    <input className="w-[142px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text"/>
                </div>
                <div className="flex flex-row items-center justify-start mr-4">
                    醫師：
                    <input className="w-[142px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text"/>
                </div>
                <div className="flex flex-row items-center justify-start mr-4">
                    天數：
                    <input className="w-[74px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text"/>
                </div>
                <button className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white">
                    查詢
                </button>
                <button className="h-10 px-4 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[6px]">給診號</button>
            </div>
            <div className="registrationContain flex flex-row items-start justify-start w-full">
                <div className="quickFilter w-full max-w-[440px] mr-2">
                    <div className="qf border-[1px] border-[#D4D4D8] rounded-[6px] py-4 bg-white mb-2 max-w-[440px]">
                        <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0] mb-[18px]">
                            快速篩選
                        </h3>
                        <div className="btnGroup flex flex-row flex-wrap justify-start items-center px-2 gap-[7px] mb-2">
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下1週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下2週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下3週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下4週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下5週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下6週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下7週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下8週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下12週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">預約下24週</button>
                            <button className="flex items-center justify-center w-[100px] h-10 border-[2px] border-[#D4D4D8] rounded-[6px] bg-[#F4F4F5]">所有診次</button>
                        </div>
                    </div>
                    <div className="sortInfo border-[1px] border-[#D4D4D8] rounded-[6px] py-4 bg-white mb-2 ml-2">
                        <div className="title flex flex-row items-center justify-start mb-[18px]">
                            <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0] mr-2">
                                病人排檢資訊
                            </h3>
                            <input className="w-[200px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text" placeholder="進階搜尋"/>
                        </div>
                        <div className="sortList max-w-[410px] overflow-scroll">
                            <ul className="tableList appointmentInspection">
                                <li className="title">
                                    <div>院區</div>
                                    <div>檢查日期</div>
                                    <div>時間</div>
                                    <div>科別</div>
                                    <div>類別</div>
                                    <div>檢查項目</div>
                                </li>
                                <li>
                                    <div>義大</div>
                                    <div>2024/03/31</div>
                                    <div>10:00~12:00</div>
                                    <div>心臟內科</div>
                                    <div>EKG</div>
                                    <div>E.K.G.
                                        (Electrocardiography)</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="registrationRecord border-[1px] border-[#D4D4D8] rounded-[6px] py-4 bg-white mb-2 ml-2">
                        <div className="title flex flex-row items-center justify-start mb-[18px]">
                            <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                                病人預掛紀錄
                            </h3>
                            <input className="w-[200px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text" placeholder="進階搜尋"/>
                        </div>
                        <div className="registrationRecordList max-w-[410px] overflow-scroll">
                            <ul className="tableList appointmentRegisRecord">
                                <li className="title">
                                    <div>退掛</div>
                                    <div>院區</div>
                                    <div>門診日</div>
                                    <div>醫師</div>
                                    <div>科別</div>
                                    <div>診室名稱</div>
                                    <div>時段</div>
                                    <div>看診號</div>
                                </li>
                                <li>
                                    <div>
                                        <span className="w-[52px] border-[2px] border-[#2B6CB0] rounded-[6px] text-[14px] text-[#2B6CB0]">退掛</span>
                                    </div>
                                    <div>義大</div>
                                    <div>2024/03/31</div>
                                    <div>陳醫師</div>
                                    <div>肝膽腸胃科</div>
                                    <div>肝膽腸胃科</div>
                                    <div>早</div>
                                    <div>22</div>
                                </li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="schedule w-full h-full max-w-[850px] py-4 border-[1px] border-[#D4D4D8] rounded-[6px]">
                    <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0] mb-[18px]">
                        醫師週班表
                    </h3>
                    <div className="filter flex flex-row items-center justify-start ml-2 mb-2.5">
                        <select className="w-[166px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px] mr-4" name="" id="">
                            <option value="">屏東義大醫院</option>
                        </select>
                        <button className="h-10 px-4 mr-2 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[6px]">上一週</button>
                        <input className="w-[168px] h-10 mr-2 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="date"/>
                        <button className="h-10 px-4 bg-[#F4F4F5] border-[2px] border-[#D4D4D8] rounded-[6px]">下一週</button>
                    </div>
                    <div className="weekMaintenance">
                        <ul>
                            <li className="flex flex-row title">
                                <div></div>
                                <div>03/04(一)</div>
                                <div>03/05(二)</div>
                                <div>03/06(三)</div>
                                <div>03/07(四)</div>
                                <div>03/08(五)</div>
                                <div>03/09(六)</div>
                                <div>03/10(日)</div>
                            </li>
                            <li className="morning flex flex-row items-start justify-start py-1 h-[185px] bg-[#EBF8FF]">
                                {/*早上時段*/}
                                <div className="timeSection">早</div>
                                <div className="weekContainer w-full flex flex-row items-start justify-start">
                                    {/*週一*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週二*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週三*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週四*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週五*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週六*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週日*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                </div>

                            </li>
                            <li className="morning flex flex-row items-center justify-start h-[185px] bg-[#FEFCE8]">
                                {/*中午時段*/}
                                <div className="timeSection">中</div>
                                <div className="weekContainer w-full flex flex-row items-start justify-start">
                                    {/*週一*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週二*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週三*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週四*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週五*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週六*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週日*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                </div>

                            </li>
                            <li className="morning flex flex-row items-center justify-start h-[185px] bg-[#FAF5FF]">
                                {/*晚上時段*/}
                                <div className="timeSection">晚</div>
                                <div className="weekContainer w-full flex flex-row items-start justify-start">
                                    {/*週一*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週二*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週三*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週四*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週五*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週六*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                    {/*週日*/}
                                    <div>
                                        <p>敢膽腸胃科/ (10/30)</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='w-full'>
                    {
                        isOpen == true ? (
                            <div className="registrationRecord w-[560px] border-[1px] shrink-0 border-[#D4D4D8] rounded-[6px] py-4 bg-white mb-2 ml-2">
                                <div className="title flex flex-row items-center justify-between mb-[18px]">
                                    <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                                        診號狀況
                                    </h3>
                                    <div className='flex flex-row items-center hover:cursor-pointer' onClick={handleOpenToggle}>
                                        <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0]">
                                            收合
                                        </h3>
                                    </div>
                                </div>
                                <div className='flex flex-col px-4 gap-4'>
                                    <div className='w-full flex flex-row justify-start items-center gap-6'>
                                        <div className=''>{`已掛：${0}人`}</div>
                                        <div className=''>{`完診：${0}人`}</div>
                                        <div className=''>{`待看：${0}人`}</div>
                                    </div>
                                    <div className='w-full flex flex-row justify-center items-center gap-4'>
                                        <input id="already" className='w-5 h-5' checked={true} type="checkbox" name="all"/>
                                        <label htmlFor="already">已給號</label>
                                        <input id="notyet" className='w-5 h-5' checked={true} type="checkbox" name="all"/>
                                        <label htmlFor="notyet">未給號</label>
                                        <input id="register" className='w-5 h-5' checked={true} type="checkbox" name="all"/>
                                        <label htmlFor="register">預約號</label>
                                        <input id="first" className='w-5 h-5' checked={true} type="checkbox" name="all"/>
                                        <label htmlFor="first">科初診</label>
                                        <input id="dont" className='w-5 h-5' checked={true} type="checkbox" name="all"/>
                                        <label htmlFor="dont">不給號</label>
                                    </div>
                                    { isDentist ? (
                                        <div>is dentist</div>
                                    ):(
                                        <div className="flex w-[560px] h-[836px] overflow-y-scroll">
                                            <div className='flex flex-row flex-wrap gap-2'>
                                                {items.map((item) => {
                                                    //取得不包含AM、PM的時間
                                                    const displayTime = new Date(item.time).toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false,
                                                    });
                                                    return (
                                                        <div key={item.id} className="flex flex-row w-[100px] h-[40px] justify-center items-center border-2 rounded">
                                                            <span className="text-sm text-[#2B6CB0]">{`${item.id} :`}</span>
                                                            <span className="text-sm">{displayTime}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ):(
                            <div className="registrationRecord border-[1px] w-[130px] border-[#D4D4D8] rounded-[6px] py-4 bg-white mb-2 ml-2">
                                <div className="title flex flex-col items-start justify-start">
                                    <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                                        診號狀況
                                    </h3>
                                    <div className='w-full flex items-center justify-end hover:cursor-pointer' onClick={handleOpenToggle}>
                                        <h3 className="flex items-center justify-start text-[20px] mr-4 ml-4 pl-2 text-[#2B6CB0]">
                                            收合
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Registration