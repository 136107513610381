import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'
import i18n from "../../i18n/index"

/**
 * 電子轉診單_下載 table header
 */
export const REFERRAL_FORM_FIELDS = [
    {
        key: 'etranNo', //電子轉診單單號
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.etranNo'),
    },
    {
        key: 'idNo', //身分證號
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.idNo')    ,
    },
    {
        key: 'orderDate', //開單日期
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.orderDate'),
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'orderDataUploadDatetime', //開單資料上傳日期
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.orderDataUploadDatetime'),
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'hospName', //開單院所
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.hospName'),
    },
    {
        key: 'tranPurposeContent', //轉診目的
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.tranPurposeContent'),
    },
    {
        key: 'suggestTranDivName', //建議轉診科別
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.suggestTranDivName'),
    },
    {
        key: 'replyStatus', //狀態
        name: i18n.t('Rmis.pages.referralFormDownload.tableHeader.replyStatus'),
    },
]

/**
 * 電子轉診單_上傳 table header
 */
export const REFERRAL_FORM_UPLOAD_FIELDS = [
    {
        key: 'etranNo', //電子轉診單單號
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.etranNo'),
    },
    {
        key: 'idNo', //身分證號
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.idNo'),
    },
    {
        key: 'orderDatetime', //開單日期
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.orderDatetime'),
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'rmisTranApiDate', //開單資料上傳日期
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.rmisTranApiDate'),
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'hospName', //開單院所
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.hospName'),
    },
    {
        key: 'tranPurposeName', //轉診目的
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.tranPurposeName'),
    },
    {
        key: 'tranOutDivName', //建議轉診科別
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.tranOutDivName'),
    },
    {
        key: 'sendFlagName', //狀態
        name: i18n.t('Rmis.pages.referralFormUpload.tableHeader.sendFlagName'),
    },
]

/**
 * 電子轉診單mapping modal table header
 */
// TODO: api 欄位待確認
export const REFERAL_MAPPING_FIELDS = [
    {
        key: 'idNo',
        name: '身分證號',
        className: 'text-sm',
    },
    {
        key: 'createdInstitution',
        name: '開單院所',
        className: 'text-sm',
    },
    {
        key: 'tranPurpose',
        name: '轉診目的',
        className: 'text-sm',
    },
    {
        key: 'tranOutDivNo',
        name: '建議轉診科別',
        className: 'text-sm',
    },
    {
        key: 'startDate',
        name: '有效起日',
        className: 'text-sm',
    },
    {
        key: 'endDate',
        name: '有效迄日',
        className: 'text-sm',
    },
    {
        key: 'etranNo',
        name: '電子轉診單單號',
        className: 'text-sm',
    },
]
