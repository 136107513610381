import React, {useEffect, useState} from "react"
import {stringIsEmpty, ToastTypeEnum} from "edah_utils/dist"
import {useDispatch} from "react-redux"
import {hiddenToastMessage} from "../../redux/Slice/ToastMessageSlice"

/**
 * Toast Message
 * @param message {String} message content
 * @param type {Number} ToastTypeEnum
 * @param duration {Number} duration of toast message
 * @param show {Boolean} show or hide toast message
 * @param onClickClose {Function|null} close button on click event
 */
const ToastMessage = ({message, duration = 3000, type = ToastTypeEnum.Success, show = false, onClickClose = null}) => {
    /**
     * Dispatch
     */
    const dispatch = useDispatch()
    /**
     * 控制吐司關閉狀態
     */
    const [toastShow, setToastShow] = useState(show)

    /**
     * 預設的iconColor、iconPath
     */
    const config = {
        iconColor: [
            'text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200',
            'text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200',
            'text-yellow-500 bg-yellow-100 dark:bg-yellow-800 dark:text-yellow-200',
        ],
        iconPath: [
            "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z",
            "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z",
            "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"
        ]
    }

    /**
     * 關閉按鈕點擊事件
     * @return {void}
     */
    const handleCloseButtonOnClick = () => setToastShow(false)

    /**
     * 監聽show狀態
     * @return {void}
     */
    useEffect(() => {
        // 顯示狀態改變時，設定顯示狀態
        setToastShow(show)
        // 為顯示時
        if (show) {
            // 顯示時間到後自動關閉
            const timer = setTimeout(() => {
                // 如果有設定onClickClose，則執行onClickClose
                if (onClickClose !== null) {
                    onClickClose()
                }

                //設定顯示狀態為false
                setToastShow(false)
                // 關閉Toast
                dispatch(hiddenToastMessage())
            }, duration)

            return () => clearTimeout(timer)
        }
    }, [show, dispatch])


    /**
     * 計算傳入的資料判斷寬度
     * @return {void}
     */
    const calculateWidth = (message) => {
        const baseWidth = 300; // Minimum width in pixels
        const extraWidthPerChar = 7; // Additional width per character
        const maxWidth = 600; // Maximum width in pixels

        const calculatedWidth = baseWidth + message.length * extraWidthPerChar;
        return Math.min(calculatedWidth, maxWidth);
    };

    return toastShow && !stringIsEmpty(message) ? (
        <div id="toast-message"
             className="absolute top-[100px] left-1/2 z-[99] flex items-center w-full p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
             role="alert"
             style={{
                 width: calculateWidth(message),
                 transform: `translateX(-${calculateWidth(message) / 2}px)`,
             }}>
            <div
                className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 ${config.iconColor[type - 1]}`}>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                     viewBox="0 0 20 20">
                    <path d={config.iconPath[type - 1]}/>
                </svg>
            </div>
            <div className="ml-3 text-sm font-normal" style={{whiteSpace: 'pre-wrap'}}>{message}</div>
            <button type="button"
                    onClick={onClickClose || handleCloseButtonOnClick}
                    className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    data-dismiss-target="#toast-message" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
            </button>
        </div>
    ) : null
}

export default ToastMessage
