//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import icons
import {ReactComponent as AddIcon} from '../../assets/images/ICCard/add_circle.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/ICCard/delete.svg'
import {ReactComponent as EditBtnIcon} from "../../assets/images/ICCard/edit_square.svg"

//Import function
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, PopupModeEnum, stringIsEmpty} from "edah_utils/dist"
import React, {useEffect, useState} from "react"
import {Pagination} from "../../components/Pagination/Pagination"
import AddEditEmrTypePopup from "../../components/ESSI/AddEditEmrTypePopup"
import DeleteRuleMessagePopup from "../../components/CDSS/DeleteRuleMessagePopup"
import {essiDelEmrType, essiQueryEmrType} from "../../api/v1/ESSI"
import {t} from "i18next"

/**
 * 電子表單類別維護
 * @return {JSX.Element}
 */
const EmrTypeMaintenance = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    // 初始資料
    const [data, setData] = useState([])

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    // 總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    //是否顯示Add Edit彈窗
    const [showAddEditPopup, setShowAddEditPopup] = useState(false)

    //是否為新增或編輯
    const [popupMode, setPopupMode] = useState(PopupModeEnum.Add)

    // 是否顯示刪除的彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    // 修改的資料 / 新增的資料 / 刪除的資料
    const [newData, setNewData] = useState({})

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const advancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPageSize ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPageSize)

    /**
     * 點擊編輯時
     * @param data {Object} 要編輯的資料
     * @return {void}
     */
    const handleEditButtonOnClick = (data) => {
        // 設定為修改模式
        setPopupMode(PopupModeEnum.Modify)
        // 設定要修改的資料
        setNewData(data)
        // 顯示新增/修改popup
        setShowAddEditPopup(true)
    }

    /**
     * 點選Add按鈕事件
     * @return {void}
     */
    const handleAddButtonOnClick = () => {
        // 顯示新增/修改popup
        setShowAddEditPopup(true)
        // 設定為新增模式
        setPopupMode(PopupModeEnum.Add)
        // 清空資料
        setNewData({})
    }

    /**
     * 關閉新增/修改popup按鈕事件
     * @return {void}
     */
    const handleCloseCancelAddEditPopup = () => {
        setShowAddEditPopup(false)
        // 重新取得所有電子表單類別維護資料
        getQueryEmrType()
    }

    /**
     * 刪除視窗關閉事件
     * @return {void}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 當點擊刪除按鈕時
     * @param data {Object} 要刪除的Rule
     * @return {void}
     */
    const handleDeleteButtonOnClick = (data) => {
        // 設定要刪除的資料
        setNewData(data)
        // 顯示刪除視窗
        setShowDeletePopup(true)
    }

    /**
     * 刪除視窗點擊是按鈕時
     * @return {void}
     */
    const handleDeleteOnClick = () => delEmrType()

    /**
     * 取得進階搜尋後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 取得父項類別名稱
     * @return {Array<String>}
     */
    const getParentNameData = () => [...new Set(data
        .map(item => item.parentEmrRptType)
        .filter(type => !stringIsEmpty(type))
    )]

    /**
     * 刪除該筆電子表單類別維護資料
     * @return {void}
     */
    const delEmrType = () => {
        essiDelEmrType([
            {
                // 表單類別代號
                "emrRptType": newData.emrRptType,
                // 表單名稱
                "rptName": newData.rptName,
                // 顯示順序
                "sortBy": newData.sortBy,
                // 有效否(0/1)
                "effFlag": newData.effFlag,
                // 備註
                "memo": newData.memo,
                // 父項類別代號
                "parentEmrRptType": newData.parentEmrRptType,
            }
        ]).then(res => {
            // 判斷是否刪除成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 清除刪除資料
                setNewData({})
                // 重新取得所有電子表單類別維護資料
                getQueryEmrType()
                //關閉刪除視窗
                setShowDeletePopup(false)
            }
        })
    }

    /**
     * 取得所有電子表單類別維護資料
     * @return {void}
     */
    const getQueryEmrType = () => {
        essiQueryEmrType({
            // 當前頁面
            pageNum: currentPage,
            // 每頁筆數
            pageSize: pageSize,
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(res.data)
                // 設定總筆數
                setTotalSize(res.totalItemSize)
                // 設定總頁數
                setTotalPageSize(res.totalPageSize)
            }
        })
    }

    /**
     * 第一次載入時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有電子表單類別維護資料
        getQueryEmrType()
    }, [])

    /**
     * 當頁碼或每頁筆數改變時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有電子表單類別維護資料
        getQueryEmrType()
    }, [currentPage, pageSize])

    return (
        <>
            <div className="w-full p-4 bg-[#FAFAFA]">
                <div className='mb-2'>
                    <button onClick={() => handleAddButtonOnClick()}
                            className="flex flex-row items-center justify-start text-[#38A169] text-[18px]">
                        <AddIcon/>新增內容
                    </button>
                </div>
                <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                    {/*進階搜尋*/}
                    <div className="flex flex-row items-center justify-between mb-2">
                        <div>
                            <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text" placeholder={t('general.advancedSearch')} value={advancedSearchText}
                                   onChange={advancedSearchTextOnChange}/>
                        </div>
                    </div>
                    <div className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[65vh]'>
                        <table
                            className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                            <thead>
                            <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                                <th className={`${tableElementDefaultType}`}>操作</th>
                                <th className={`${tableElementDefaultType}`}>顯示順序</th>
                                <th className={`${tableElementDefaultType}`}>父層代號</th>
                                <th className={`${tableElementDefaultType}`}>單據代號</th>
                                <th className={`${tableElementDefaultType}`}>單據名稱</th>
                                <th className={`${tableElementDefaultType}`}>說明</th>
                                <th className={`${tableElementDefaultType}`}>有效否</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !arrayIsEmpty(data) && getFilterData().map((data, index) =>
                                    <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                        key={index}>
                                        <td className={`${tableElementDefaultType}`}>
                                            <div className='flex flex-row items-center justify-start'>
                                                {/*編輯按鈕*/}
                                                <button
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
                                                    onClick={() => handleEditButtonOnClick(data)}>
                                                    <EditBtnIcon className="mr-1"/>{t('general.edit')}
                                                </button>
                                                {/*刪除按鈕*/}
                                                <button
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"
                                                    onClick={() => handleDeleteButtonOnClick(data)}
                                                >
                                                    <DeleteIcon className="mr-1"/>{t('general.delete')}
                                                </button>
                                            </div>
                                        </td>
                                        <td className={`${tableElementDefaultType}`}>{data.sortBy}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.parentEmrRptType}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.emrRptType ? data.emrRptType : ""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.rptName ? data.rptName : ""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.memo ? data.memo : ""}</td>
                                        <td className={`${tableElementDefaultType}`}>{data.effFlag ? data.effFlag : ""}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end w-full">
                        <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                                    totalSize={totalSize}
                                    onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                    onPreviousOnClick={onPaginationPreviousOnClick}
                                    onNextOnClick={onPaginationNextOnClick}
                                    onPageSizeChange={onPaginationPageSizeOnChange}/>
                    </div>
                </div>
                {/*新增/修改popup*/}
                <AddEditEmrTypePopup
                    show={showAddEditPopup}
                    data={newData}
                    mode={popupMode}
                    parentNameData={getParentNameData()}
                    closePopupOnClick={handleCloseCancelAddEditPopup}/>
                {/*刪除彈窗*/}
                <DeleteRuleMessagePopup
                    closePopupButtonOnClick={handleDeletePopupOnClose}
                    show={showDeletePopup}
                    handleDeleteOnClick={handleDeleteOnClick}
                />
            </div>
        </>
    )
}
export default EmrTypeMaintenance
