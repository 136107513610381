import {useDispatch} from "react-redux"
import {ToastTypeEnum} from "edah_utils/dist"
import {showToastMessage} from "../redux/Slice/ToastMessageSlice"

/**
 * useToast Hook
 * @return {Function} showToast
 */
const useToast = () => {
    /**
     * dispatch
     */
    const dispatch = useDispatch()

    /**
     * Show Toast
     * @param message {String} message content
     * @param type {Number} ToastTypeEnum
     * @param duration {Number} duration of toast message
     * @param show {Boolean} show or hide toast message
     * @param onClickClose {Function|null} close button on click event
     */
    return ({
                message,
                type = ToastTypeEnum.Success,
                duration = 3000,
                onClickClose = null
            }) => dispatch(showToastMessage({
        message,
        type,
        duration,
        onClickClose
    }))
}

export default useToast
