import React from 'react';

// 使用範例
// const data = [30, 50, 70, 90];
// const secData = [20, 40, 60, 80];
// const labels = ['Jan', 'Feb', 'Mar', 'Apr'];

// <BarChart data={data}
//           secData={secData}
//           width={'500px'}
//           color={'#F5A524'}
//           secColor={'#F5A524'}
//           height={'500px'}
//           labels={labels} />

/**
 * 長條圖
 * @param data {Array} 第一筆長條圖資料
 * @param labels {Array} 長條圖標籤
 * @param width {String} 圖表寬度
 * @param height {String} 圖表高度
 * @param color {String} 第一筆長條圖顏色
 * @param secColor {String} 第二筆長條圖顏色
 * @param secData {Array} 第二筆長條圖資料
 * @return {JSX.Element}
 */
const BarChart = ({ data, labels, width, height, color, secColor = null, secData = [] }) => {

    return (
        <div className={`flex items-end w-[${width}] h-[${height}] border-gray-300`}>
            {data.map((value, index) => (
                <div key={index} className="flex-1 flex flex-col items-center justify-end h-full">
                    <div className="flex flex-row items-end justify-start h-full">
                        {/*data*/}
                        <div className={`one bg-[${color}] w-[17px] h-full mr-1 rounded-full`} style={{ height: `${(value / 100) * 100}%` }}></div>

                        {/*secData 當為空值不顯示第二條長條圖*/}
                        {secData.length > 0 && (
                            <div className={`two bg-[${secColor}] w-[17px] h-full rounded-full`} style={{ height: `${(secData[index] / 100) * 100}%` }}></div>
                        )}
                    </div>
                    {/*標籤*/}
                    <span className="mt-2">{labels[index]}</span>
                </div>
            ))}
        </div>
    );
};

export default BarChart;
