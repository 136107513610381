import React, { useState } from 'react'
import {ApiErrorStatusEnum, ToastTypeEnum} from 'edah_utils/dist';
import CustomTable from '../RSISCustomTable';
import {BasePopup} from '../../Popup/BasePopup';
import {gueryEmployeeInfo} from "../../../api/v1/Users";
import {usePopup} from "../PopupProvider";
import {Title} from "../utils";
import {BaseInput} from "../../Input/BaseInput";

// 表格欄位
const fields = [
    '人員工號',
    '姓名',
    '部門代號',
    '部門名稱'
]

/**
 * 列表組件
 * @param item 列表項目
 * @param index 列表索引
 * @param hasSelectAll 是否全選
 * @param handleOnClick 點擊事件
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      hasSelectAll = false,
                      handleOnUpdate
                  }) => {
    /**
     * 點擊列表項目事件，帶回承辦人員資料
     * @return {void}
     */
    const handleItemOnClick = () => {
        handleOnUpdate(item)
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleItemOnClick}>
            <div>{item.userNo}</div>
            <div>{item.userName}</div>
            <div>{item.depNo}</div>
            <div>{item.depName}</div>
        </li>
    )
}

/**
 * 人員查詢
 * @param {func} handleOnUpdate 設定選擇的人員
 * @param {func} handleOnClose 關閉彈窗
 * @returns {JSX.Element}
 */
function QueryCreateUserList({handleOnUpdate, handleOnClose}) {
    // 資料列表
    const [dataList, setDataList] = useState([])
    // 人員工號
    const [userNo, setUserNo] = useState('')
    // 姓名
    const [name, setName] = useState('')

    // 使用上下文
    const {showToast} = usePopup();

    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    });

    /**
     * 查詢
     * return {void}
     */
    const handleOnSearch = () => {
        getCreateUserList()
    }

    /**
     * 取得申請人員清單
     * @return {void}
     */
    const getCreateUserList = () => {
        gueryEmployeeInfo({
            userNo: userNo,
            userName: name,
            medicalStaff: '',
            activeFlag: true
        }).then((res) => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 如果資料長度為0
                if(data.length === 0) {
                    showToast({message: '查無資料', type: ToastTypeEnum.Warning})
                }
                setDataList(data)
            } else {
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    return (
        <BasePopup
            title="人員查詢"
            width="1162px"
            closePopupButtonOnClick={handleOnClose}
            content={
                <div className='px-6 pb-6'>
                    <div className='flex justify-start items-center mb-4 gap-4'>
                        <div className='flex flex-row items-center'>
                            <Title children={'人員代號/工號：'}/>
                            <BaseInput
                                className='bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300'
                                value={userNo}
                                onChange={(e) => setUserNo(e.target.value)}
                            />
                        </div>
                        <Title children={'姓名：'}/>
                        <BaseInput
                            title={'姓名：'}
                            className=' bg-white h-[42px] px-2 text-black rounded-[6px] border-[1px] py-1 border-gray-300'
                            value={name}
                            setValue={setName}
                            onChange={(e) => setName(e.target.value)}
                            extendClass={'w-64'}
                        />
                        <div className="source flex flex-row items-center justify-start mr-4">
                            <button
                                className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px] hover:cursor-pointer"
                                onClick={handleOnSearch}
                            >
                                查詢
                            </button>
                        </div>
                    </div>
                    <CustomTable
                        hasSelectAll={false}
                        advancedSearch={true}
                        fields={fields}
                        dataList={dataList}
                        ListItem={ListItem}
                        type={'RSISResponiblePersonnalTable'}
                        paginationProps={paginationProps}
                        handleOnUpdateCallback={handleOnUpdate}
                    />
                </div>
            }
        />
    )
}

export default QueryCreateUserList